import { Table } from 'antd';
import React, { useMemo } from 'react';
import { normalBlueprintItems } from 'src/Constants/itemsByBlueprintQuality';
import { FilterByShowcase } from 'src/Types/enums';
import { ITableItem } from 'src/Utills/apiData';
import { useAppSelector } from 'src/hooks/redux';
import { ApiDataStore } from 'src/redux/apiData';
import { ExpPageStore } from 'src/redux/expTable';
import { expTableColumns } from './columns';

export const ExpTable: React.FC = () => {
  const showcaseFilter = useAppSelector(ExpPageStore.Select.showcaseFilter);
  const filterTypes = useMemo(() => showcaseFilter.flatMap((showcase) => FilterByShowcase[showcase]), [showcaseFilter]);

  const dataSource = useAppSelector(ApiDataStore.Select.data);
  const isLoading = useAppSelector(ApiDataStore.Select.isLoading);

  const filters = useAppSelector(ExpPageStore.Select.filters);

  const filteredData = useMemo(() => {
    const {
      onlyNormalBlueprintItems,
      onlyEmptyRequests,
      onlyProfitableRequests,
      onlyProfitableRequestsWithSurcharge,
      onlyProfitableOffers,
      onlyProfitableOffersWithSurcharge,
    } = filters;

    return dataSource
      .filter((item) => filterTypes.includes(item.type))
      .filter((item) => {
        let result = true;

        if (result && onlyNormalBlueprintItems) result = normalBlueprintItems.includes(item.uid);
        if (result && onlyEmptyRequests) result = item.requests.length === 0;
        if (result && onlyProfitableRequests)
          result = item.requests[0]?.price * 1.1 <= item.value || item.requests.length === 0;
        if (result && onlyProfitableRequestsWithSurcharge)
          result = item.requests[0]?.price * 1.1 <= item.value * 2 || item.requests.length === 0;
        if (result && onlyProfitableOffers) result = item.offers[0]?.price <= item.value;
        if (result && onlyProfitableOffersWithSurcharge) result = item.offers[0]?.price <= item.value * 2;

        return result;
      });
  }, [dataSource, filterTypes, filters]);

  return (
    <Table<ITableItem>
      size={'small'}
      rowKey={'uid'}
      dataSource={filteredData}
      loading={isLoading}
      columns={expTableColumns}
      pagination={{ defaultPageSize: 10 }}
    />
  );
};
