import { ReloadOutlined } from '@ant-design/icons';
import { Button, Checkbox, InputNumber, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React from 'react';
import { EnergyTable } from 'src/Components/EnergyTable';
import { ShowcaseFilterButtons } from 'src/Components/FilterButtons/showcaseFilterButtons';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { ApiDataStore } from 'src/redux/apiData';
import { EnergyPageStore } from 'src/redux/energyTable';
import { IEnergyPageFilterKeys } from 'src/redux/energyTable/slice';
import './styles.scss';

export const EnergyPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const refetch = () => {
    dispatch(ApiDataStore.Actions.load());
  };

  const showcaseFilter = useAppSelector(EnergyPageStore.Select.showcaseFilter);
  const minimumEnergy = useAppSelector(EnergyPageStore.Select.minimumEnergy);

  const onSetFilter = (key: IEnergyPageFilterKeys) => (e: CheckboxChangeEvent) =>
    dispatch(EnergyPageStore.Actions.setFilter({ key, state: e.target.checked }));

  const onMinEnergyChange = (value: number | null) => dispatch(EnergyPageStore.Actions.setMinimumEnergy(value ?? 0));

  return (
    <div className={'px-4'}>
      <ShowcaseFilterButtons
        setCheckedList={(list) => dispatch(EnergyPageStore.Actions.setShowcase(list))}
        value={showcaseFilter}
      />

      <div className={'filterCheckboxesWrapper'}>
        <Checkbox onChange={onSetFilter('onlyNormalBlueprintItems')}>Only normal blueprints</Checkbox>

        <div className={'requestsWrapper'}>
          <Checkbox onChange={onSetFilter('onlyEmptyRequests')}>Only empty requests</Checkbox>

          <Checkbox onChange={onSetFilter('onlyProfitableRequests')}>Only profitable requests</Checkbox>
        </div>

        <Checkbox onChange={onSetFilter('onlyProfitableOffers')}>Only profitable offers</Checkbox>
      </div>

      <div className={'w-full flex gap-1 items-center justify-end'}>
        <Checkbox onChange={(e) => dispatch(ApiDataStore.Actions.setAutoReload(e.target.checked))}>
          Refresh every 2 minutes
        </Checkbox>

        <Button icon={<ReloadOutlined />} onClick={() => refetch()} />
      </div>

      <div className={'minimumEnergyInputWrapper'}>
        <Typography.Text>Minimum energy : </Typography.Text>
        <InputNumber value={minimumEnergy} onChange={onMinEnergyChange} />
      </div>

      <EnergyTable />
    </div>
  );
};
