import _ from 'lodash';
import { IApiItem } from 'src/Types/api';
import { ItemType, TradeType } from 'src/Types/enums';
import { ITrade } from 'src/Types/types';
import { enrichedItems } from './items';
import { IAcknowledgedBlueprints } from 'src/Types/profile';

export interface ITableItem {
  uid: string;
  nameEN: string;
  nameRU: string;
  type: ItemType;
  tier: number;
  baseValue: number;
  value: number;
  xp: number;
  discount: number;
  valueMultiplier: number | null;
  isAcknowledged: boolean;

  offers: ITrade[];
  requests: ITrade[];
}

export class ApiDataUtils {
  public static readonly getItemList = (blueprints: IAcknowledgedBlueprints) =>
    enrichedItems.map<[string, ITableItem]>(
      ({ uid, tier, discount, value: baseValue, type, nameEN, nameRU, valueMultiplier, xp }) => {
        const isAcknowledged = blueprints.includes(uid);
        const value = isAcknowledged && valueMultiplier ? Math.round(baseValue * valueMultiplier) : baseValue;

        return [
          uid,
          {
            uid,
            nameEN,
            nameRU,
            type: type as ItemType,
            tier,
            baseValue,
            value,
            xp,
            discount,
            valueMultiplier,
            isAcknowledged,

            offers: [],
            requests: [],
          },
        ];
      },
    );

  public static getGroupedTrades = (data: IApiItem[]): { offers: IApiItem[]; requests: IApiItem[] } => {
    const groupedData = _.groupBy(data, ({ tType }) => tType);
    const offers = groupedData[TradeType.Offer];
    const requests = groupedData[TradeType.Request];

    return { offers, requests };
  };

  public static getTradeListByItem = (data: IApiItem[], tableItem: ITableItem, orderBy: 'desc' | 'asc'): ITrade[] =>
    _.orderBy(
      data
        .filter((item) => item.uid === tableItem.uid)
        .filter(({ goldQty }) => !!goldQty)
        .filter(({ tag1 }) => !tag1)
        .map(({ id, goldQty, goldPrice, updatedAt }) => ({
          id,
          quantity: goldQty,
          price: goldPrice,
          energyPerGold: tableItem.discount / (goldPrice - tableItem.value / 2),
          xpPerGold: tableItem.xp / (goldPrice - tableItem.value),
          lastUpdated: new Date(updatedAt),
        })),
      'price',
      orderBy,
    );
}
