import { Tooltip, Typography, type TableProps } from 'antd';
import classNames from 'classnames';
import { ItemTypeToString } from 'src/Types/enums';
import { ITableItem } from 'src/Utills/apiData';

export const expTableColumns: TableProps<ITableItem>['columns'] = [
  {
    title: 'Item',
    dataIndex: 'nameEN',
    key: 'nameEN',
    width: 160,
    ellipsis: true,
  },
  {
    title: 'Предмет',
    dataIndex: 'nameRU',
    key: 'nameRU',
    width: 160,
    ellipsis: true,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 100,
    ellipsis: true,
    render: (type: ITableItem['type']) => ItemTypeToString(type),
  },
  {
    title: 'T',
    dataIndex: 'tier',
    key: 'tier',
    width: 60,
    ellipsis: true,
    sorter: (a, b) => a.tier - b.tier,
    filters: [...new Array(13)].map((_, idx) => ({
      text: idx + 1,
      value: idx + 1,
    })),
    onFilter: (value, item) => item.tier === value,
  },
  {
    title: 'XP',
    dataIndex: 'xp',
    key: 'xp',
    width: 80,
    ellipsis: true,
    render: (value) => value.toLocaleString(),
    sorter: (a, b) => a.xp - b.xp,
  },
  {
    title: 'Profit x2',
    key: 'profit',
    width: 90,
    ellipsis: true,
    render: (_, record) => {
      const firstOffer = record.offers[0];
      if (!firstOffer) return null;

      const profit = record.value * 2 - firstOffer.price;

      return (
        <Typography.Text
          className={profit > 0 ? 'bg-green-500 bg-opacity-10 p-1 rounded' : ''}
          title={`Buy up to ${(record.value * 2).toLocaleString()}`}
        >
          {profit.toLocaleString()}
        </Typography.Text>
      );
    },
    sorter: (a, b) => {
      const firstAOffer = a.offers[0];
      const firstBOffer = b.offers[0];

      const profitA = firstAOffer ? a.value * 2 - firstAOffer.price : 0;
      const profitB = firstBOffer ? b.value * 2 - firstBOffer.price : 0;

      return profitA - profitB;
    },
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    width: 100,
    ellipsis: true,
    sorter: (a, b) => a.value - b.value,
    render: (_, record) => {
      const isAcknowledged = record.isAcknowledged;

      return (
        <Typography.Text
          title={isAcknowledged ? record.baseValue.toLocaleString() : undefined}
          strong={isAcknowledged}
          className={isAcknowledged ? 'bg-green-200 bg-opacity-50 p-1 rounded' : ''}
        >
          {isAcknowledged ? record.value.toLocaleString() : record.baseValue.toLocaleString()}
        </Typography.Text>
      );
    },
  },
  {
    title: 'Offers',
    dataIndex: 'offers',
    render: (offers: ITableItem['offers']) => (
      <div className={'flex flex-row gap-4'}>
        {offers.slice(0, 2).map(({ id, price, quantity, xpPerGold, lastUpdated }) => (
          <TradeCell key={id} price={price} quantity={quantity} xpPerGold={xpPerGold} lastUpdated={lastUpdated} />
        ))}
      </div>
    ),
    sorter: (a, b) => (a.offers[0]?.xpPerGold ?? 0) - (b.offers[0]?.xpPerGold ?? 0),
  },
  {
    title: 'Requests',
    dataIndex: 'requests',
    render: (requests: ITableItem['requests']) => (
      <div className={'flex flex-row gap-4'}>
        {requests.slice(0, 2).map(({ id, price, quantity, xpPerGold, lastUpdated }) => (
          <TradeCell key={id} price={price} quantity={quantity} xpPerGold={xpPerGold} lastUpdated={lastUpdated} />
        ))}
      </div>
    ),
    sorter: (a, b) => (a.requests[0]?.xpPerGold ?? 0) - (b.requests[0]?.xpPerGold ?? 0),
  },
];

const TradeCell: React.FC<{
  price: number;
  quantity: number;
  xpPerGold: number;
  lastUpdated: Date;
}> = ({ price, xpPerGold, lastUpdated }) => {
  const minutesAgo = ((lastUpdated.getTime() - Date.now()) / 60000) * -1;

  return (
    <Tooltip
      overlay={
        <Typography.Text className={'text-base text-white'}>{minutesAgo.toFixed(2)} minutes ago</Typography.Text>
      }
    >
      <div
        className={classNames('bg-opacity-30 rounded-lg p-2', {
          'bg-green-700': minutesAgo >= 0 && minutesAgo < 1,
          'bg-green-500': minutesAgo >= 1 && minutesAgo < 2,
          'bg-green-300': minutesAgo >= 2 && minutesAgo < 3,
          'bg-orange-500': minutesAgo >= 3 && minutesAgo < 5,
          'bg-orange-300': minutesAgo >= 5 && minutesAgo < 7,
          'bg-red-500': minutesAgo >= 7 && minutesAgo < 10,
          'bg-red-300': minutesAgo >= 10,
        })}
      >
        <div>XP/Price: {xpPerGold.toFixed(2).toLocaleString()}</div>
        <div>Price: {price.toLocaleString()}</div>
      </div>
    </Tooltip>
  );
};
