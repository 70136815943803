import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Showcase } from 'src/Types/enums';

export type IEnergyPageFilterKeys = keyof IEnergyPageState['filters'];

interface IEnergyPageState {
  showcaseFilter: Showcase[];
  filters: {
    onlyNormalBlueprintItems: boolean;
    onlyEmptyRequests: boolean;
    onlyProfitableRequests: boolean;
    onlyProfitableOffers: boolean;
  };
  minimumEnergy: number;
}

const initialState: IEnergyPageState = {
  showcaseFilter: [Showcase.Shelves, Showcase.Outfits, Showcase.Table, Showcase.WeaponRack],
  filters: {
    onlyNormalBlueprintItems: false,
    onlyEmptyRequests: false,
    onlyProfitableRequests: false,
    onlyProfitableOffers: false,
  },
  minimumEnergy: 0,
};

export const energyPageSlice = createSlice({
  name: 'expPage',
  initialState,
  reducers: {
    setShowcase: (state, { payload }: PayloadAction<Showcase[]>) => {
      state.showcaseFilter = payload;
    },
    setFilter: (state, { payload }: PayloadAction<{ key: IEnergyPageFilterKeys; state: boolean }>) => {
      state.filters = { ...state.filters, [payload.key]: payload.state };
    },
    setMinimumEnergy: (state, { payload }: PayloadAction<number>) => {
      state.minimumEnergy = payload;
    },
  },
});
