import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React, { useState } from 'react';
import './styles.scss';
import { Showcase } from 'src/Types/enums';

const CheckboxGroup = Checkbox.Group;

const plainOptions = [
  Showcase.Shelves,
  Showcase.Outfits,
  Showcase.Table,
  Showcase.WeaponRack,
];

interface IShowcaseFilterButtons {
  setCheckedList: (list: Showcase[]) => void;
  value: Showcase[];
}
export const ShowcaseFilterButtons: React.FC<IShowcaseFilterButtons> = (
  props
) => {
  const { setCheckedList, value } = props;

  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const onChange = (list: Showcase[]) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < plainOptions.length);
    setCheckAll(list.length === plainOptions.length);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    setCheckedList(e.target.checked ? plainOptions : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <div className={'wrapper'}>
      <Checkbox
        indeterminate={indeterminate}
        onChange={onCheckAllChange}
        checked={checkAll}
      >
        All Stations
      </Checkbox>

      <CheckboxGroup
        options={plainOptions}
        value={value}
        onChange={(list) => onChange(list as Showcase[])}
      />
    </div>
  );
};
