import { Button, Form, Input } from 'antd';
import React from 'react';
import { ExportProfileButton } from 'src/Components/ExportProfileButton';
import { UploadProfileButton } from 'src/Components/UploadProfileButton';
import { initialProfileValue } from 'src/Constants/profile';
import { IProfile } from 'src/Types/profile';
import { useLocalStorage } from 'usehooks-ts';

export const Profile: React.FC = () => {
  const [profile, saveProfile] = useLocalStorage('profile', initialProfileValue);

  return (
    <Form<IProfile>
      initialValues={profile}
      className={'max-w-md bg-neutral-50 p-4 rounded'}
      layout={'vertical'}
      onFinish={saveProfile}
      key={profile.username}
    >
      <Form.Item name={'username'} label={'UserName'}>
        <Input placeholder={'Your username'} />
      </Form.Item>

      <Form.Item>
        <div className={'flex flex-row justify-between'}>
          <div className={'flex flex-row gap-2'}>
            <ExportProfileButton />
            <UploadProfileButton />
          </div>

          <Button htmlType={'submit'} type={'primary'}>
            Save
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};
