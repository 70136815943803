import { itemList } from 'src/Constants/items';
import { translationsEN } from 'src/Constants/translationsEN';
import { translationsRU } from 'src/Constants/translationsRU';
import { ItemType } from 'src/Types/enums';
import { IEnrichedItem, IItem } from 'src/Types/types';

const getItemUpgradeValueMultiplier = (item: IItem): number | null => {
  const upgrades = [item.upgrade1, item.upgrade2, item.upgrade3, item.upgrade4, item.upgrade5];
  const valueUpgrade = upgrades.find((upgrade) => upgrade?.includes('value*'));
  if (!valueUpgrade) return null;

  const multiplier = valueUpgrade?.replace('value*', '');

  return +multiplier;
};

export const enrichedItems: IEnrichedItem[] = Object.values(itemList)
  .filter(({ type }) => Object.values(ItemType).includes(type as ItemType))
  .map((item) => ({
    ...item,

    nameEN: translationsEN.texts[`${item.uid}_name`],
    nameRU: translationsRU.texts[`${item.uid}_name`],

    valueMultiplier: getItemUpgradeValueMultiplier(item),
  }));
