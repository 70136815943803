import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BasicLayout } from 'src/Pages';
import { Dashboard } from 'src/Pages/Dashboard';
import { EnergyPage } from 'src/Pages/EnergyPage';
import { ExpPage } from 'src/Pages/ExpPage';
import { Profile } from 'src/Pages/Profile';
import { Blueprints } from 'src/Pages/Profile/Blueprints';
import { ProfileLayout } from 'src/Pages/Profile/ProfileLayout';
import { store } from 'src/redux/store';
import './App.css';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <BrowserRouter>
          <BasicLayout>
            <Routes>
              <Route path={'/'} element={<Dashboard />} />
              <Route path={'energy'} element={<EnergyPage />} />
              <Route path={'exp'} element={<ExpPage />} />

              <Route path={'profile'} element={<ProfileLayout />}>
                <Route index element={<Profile />} />
                <Route path={'blueprints'} element={<Blueprints />} />
              </Route>
            </Routes>
          </BasicLayout>
        </BrowserRouter>
      </Provider>
    </QueryClientProvider>
  );
}

export default App;
