import { Layout, Menu } from 'antd';
import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

export const ProfileLayout: React.FC = () => {
  const location = useLocation();

  return (
    <Layout className={'h-full'}>
      <Layout.Sider theme={'light'}>
        <Menu
          className={'h-full'}
          theme={'light'}
          selectedKeys={[location.pathname]}
          items={[
            { key: '/profile', label: <Link to={'/profile'}>Profile</Link> },
            { key: '/profile/blueprints', label: <Link to={'/profile/blueprints'}>Blueprints</Link> },
          ]}
        />
      </Layout.Sider>

      <Layout className={'p-4 bg-neutral-200 m-2 rounded'}>
        <Outlet />
      </Layout>
    </Layout>
  );
};
