import { ReloadOutlined } from '@ant-design/icons';
import { Button, Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import React from 'react';
import { ExpTable } from 'src/Components/ExpTable';
import { ShowcaseFilterButtons } from 'src/Components/FilterButtons/showcaseFilterButtons';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { ApiDataStore } from 'src/redux/apiData';
import { ExpPageStore } from 'src/redux/expTable';
import { IExpPageFilterKeys } from 'src/redux/expTable/slice';

export const ExpPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const refetch = () => {
    dispatch(ApiDataStore.Actions.load());
  };

  const showcaseFilter = useAppSelector(ExpPageStore.Select.showcaseFilter);

  const onSetFilter = (key: IExpPageFilterKeys) => (e: CheckboxChangeEvent) =>
    dispatch(ExpPageStore.Actions.setFilter({ key, state: e.target.checked }));

  return (
    <div className={'px-4'}>
      <ShowcaseFilterButtons
        setCheckedList={(list) => dispatch(ExpPageStore.Actions.setShowcase(list))}
        value={showcaseFilter}
      />

      <div className={'filterCheckboxesWrapper'}>
        <Checkbox onChange={onSetFilter('onlyNormalBlueprintItems')}>Only normal blueprints</Checkbox>

        <div className={'requestsWrapper'}>
          <Checkbox onChange={onSetFilter('onlyEmptyRequests')}>Only empty requests</Checkbox>

          <Checkbox onChange={onSetFilter('onlyProfitableRequests')}>Only profitable requests</Checkbox>

          <Checkbox onChange={onSetFilter('onlyProfitableRequestsWithSurcharge')}>
            Only profitable requests with surcharge
          </Checkbox>
        </div>

        <div className={'offersWrapper'}>
          <Checkbox onChange={onSetFilter('onlyProfitableOffers')}>Only profitable offers</Checkbox>

          <Checkbox onChange={onSetFilter('onlyProfitableOffersWithSurcharge')}>
            Only profitable offers with surcharge
          </Checkbox>
        </div>
      </div>

      <div className={'w-full flex gap-1 items-center justify-end'}>
        <Checkbox onChange={(e) => dispatch(ApiDataStore.Actions.setAutoReload(e.target.checked))}>
          Refresh every 2 minutes
        </Checkbox>

        <Button icon={<ReloadOutlined />} onClick={() => refetch()} />
      </div>

      <ExpTable />
    </div>
  );
};
