export const normalBlueprintItems = [
  'shortsword',
  'longsword',
  'broadsword',
  'cutlass',
  'rapier',
  'katana',
  'scimitar',
  'celesteelblade',
  'newyearsword',
  'falchion',
  'cleaver',
  'lunarsword',
  'vorpalsword',
  't12sword',
  't13sword',
  'spacesword',
  'boysaxe',
  'hatchet',
  'doublebitted',
  'halberd',
  'epicaxe',
  'tomahawk',
  'battleaxe',
  'steelaxe',
  'raideraxe',
  'lunaraxe',
  'executioner',
  'crescentaxe',
  'manticoreaxe',
  't12axe',
  't13axe',
  'kitchenknife',
  'dagger',
  'switchblade',
  'butterflyknife',
  'kris',
  'atlab_boomerang',
  'dirk',
  'guttingknife',
  'trolltooth',
  'misericordia',
  'katar',
  'heartseeker',
  't12dagger',
  't13dagger',
  'club',
  'spikedclub',
  'hammer',
  'morningstar',
  'heavymace',
  'ancienthammer',
  'midnightstar',
  'powderkeg',
  'tenderizer',
  'nautilus',
  'emeraldhammer',
  't12mace',
  't13mace',
  'woodspear',
  'ironspear',
  'bladedspear',
  'ranseur',
  'trident',
  'pike',
  'partisan',
  'championlance',
  'wyvernglaive',
  'stellarspear',
  'feyspear',
  't12spear',
  't13spear',
  'shortbow',
  'longbow',
  'hunterbow',
  'strikerbow',
  'spikedbow',
  'wingedbow',
  'compositebow',
  'yumi',
  'bramblebane',
  'twinstrand',
  'stormrend',
  't12bow',
  't13bow',
  'woodbranch',
  'cane',
  'oakstaff',
  'bo',
  'scepter',
  'wizardstaff',
  'celestialrod',
  'imperialaquila',
  'transcendance',
  'conflux',
  'penumbra',
  't12staff',
  't13staff',
  'collectionstaff',
  'antidote',
  'grass',
  'powder',
  'healingsalve',
  'silverthistle',
  'bloodvine',
  'mandragoraroot',
  'wolfsbane',
  'yggdrasilbranch',
  'wyrmblood',
  'eldritchtendrils',
  't12herb',
  't13herb',
  'tea',
  'smallhealthpotion',
  'smallmanapotion',
  'healthpotion',
  'magicpotion',
  'largehealthpotion',
  'vialofrenewal',
  'epicpotion',
  'purplebomb',
  'nayapotion',
  'cincopotion2',
  'soulfireextract',
  'gaiatonic',
  'starextract',
  't12potion',
  't13potion',
  'scrolloffire',
  'scrollofthunder',
  'scrollofprotection',
  'songofvalor',
  'scrollofice',
  'scrollofinvisibility',
  'scrolloflevitation',
  'tarot',
  'grimoireaeternum',
  'celestialscroll',
  'batscroll',
  'angelscroll',
  'allknowledge',
  't12scroll',
  't13scroll',
  'cuirass',
  'ringmail',
  'scalemail',
  'chainmail',
  'breastplate',
  'halfplate',
  'ronindo',
  'generalplate',
  'berserkerarmor',
  'juggernaut',
  'celestialplate',
  't12heavyarmor',
  't13heavyarmor',
  'leatherarmor',
  'padded',
  'doublet',
  'studded',
  'hide',
  'savagearmor',
  'ninjagarb',
  'windrunnerarmor',
  'dragonarmor',
  'captainarmor',
  'jackalopearmor',
  't12mediumarmor',
  't13mediumarmor',
  'tunic',
  'robe',
  'druidic',
  'disciplerobe',
  'scholartunic',
  'witchrobe',
  'midnightapparel',
  'shamanvestment',
  'astravestimenta',
  'majesticraiments',
  'djinnrobes',
  't12robe',
  't13robe',
  'ironcap',
  'helmet',
  'horned',
  'vikinghelm',
  'knighthelm',
  'paladinhelm',
  'kabuto',
  'generalheaume',
  'berserkerhelm',
  'juggernauthelm',
  'celestialhelm',
  't12helm',
  't13helm',
  'leathercap',
  'hat',
  'featheredhat',
  'tricorn',
  'hood',
  'piratehat',
  'nightcowl',
  'windrunnerhat',
  'atlab_spiritmask',
  'dragonhat',
  'captainhat',
  'jackalopehat',
  't12roguehat',
  't13roguehat',
  'leathercone',
  'blackcowl',
  'laurels',
  'circlet',
  'wizardcone',
  'witchhat',
  'midnighthat',
  'shamanhat',
  'astralhat',
  'tiara',
  'djinnhat',
  't12magehat',
  't13magehat',
  'ironbracers',
  'lightgauntlets',
  'gauntlets',
  'demigauntlets',
  'knightgauntlets',
  'cincogauntlets',
  'paladingauntlets',
  'kote',
  'generalgauntlets',
  'berserkergauntlets',
  'juggernautgauntlets',
  'celestialgauntlets',
  't12gauntlets',
  't13gauntlets',
  'leathergloves',
  'bracers',
  'thiefgloves',
  'studdedleathergloves',
  'vambraces',
  'savagegloves',
  'ninjagloves',
  'windrunnergloves',
  'dragongloves',
  'captaingloves',
  'jackalopegloves',
  't12gloves',
  't13gloves',
  'shinguards',
  'longboots',
  'grieveboots',
  'heavyboots',
  'knightboots',
  'lunargreaves',
  'paladinboots',
  'haidate',
  'generalgreaves',
  'berserkerboots',
  'juggernautboots',
  'cincoboots',
  'celestialboots',
  't12boots',
  't13boots',
  'leatherboots',
  'sandals',
  'softshoes',
  'thiefshoes',
  'elvenshoes',
  'savageshoes',
  'ninjatabi',
  'batshoes',
  'windrunnerboots',
  'dragonshoes',
  'captainshoes',
  'jackalopeshoes',
  't12shoes',
  't13shoes',
  'woodshield',
  'buckler',
  'oakshield',
  'ironshield',
  'kiteshield',
  'largebuckler',
  'towershield',
  'ancestortotem',
  'zealouspavise',
  'prideshield',
  'paragon',
  't12shield',
  't13shield',
  'ironring',
  'doublering',
  'fatring',
  'silverband',
  'noblering',
  'knightsignet',
  'batrachitestone',
  'sulfuritecoil',
  'batring',
  'feyring',
  'borealis',
  'mysteriousring',
  't12ring',
  't13ring',
  'ironamulet',
  'pendant',
  'chain',
  'sunpendant',
  'noblechain',
  'holysymbol',
  'titaniumtorc',
  'magatama',
  'archivistglasses',
  'phylactery',
  'spaceamulet',
  'alimyriad',
  'brisingamen',
  't12amulet',
  't13amulet',
  'baconeggs',
  'baobun',
  'spacemeal',
  'poutine',
  'patechinois',
  'burger',
  'ahipoke',
  'sushi',
  'coconutcurry',
  'kebabs',
  'ramen',
  'lunarmeal',
  't12meal',
  't13meal',
];

export const chestBluepintItems = [
  'grottosword',
  'ruinssword',
  'goldsword',
  'templesword',
  'goldsword2',
  'grottoaxe',
  'pyramidaxe',
  'castleaxe',
  'templeaxe',
  'goldaxe',
  'forestdagger',
  'ruinsdagger',
  'golddagger',
  'templedagger',
  'peakdagger',
  'golddagger2',
  'forestmace',
  'pyramidmace',
  'goldhammer',
  'peakhammer',
  'platinummace',
  'desertspear',
  'goldspear',
  'templespear',
  'goldspear2',
  'riftspear',
  'forestbow',
  'desertbow',
  'goldbow',
  'riftbow',
  'goldstaff',
  'swampstaff',
  'ruinsstaff',
  'castlestaff',
  'goldstaff2',
  'riftstaff',
  'lcogwand',
  'goldcrossbow',
  'goldgun',
  'ruinsherb',
  'goldherb',
  'peakherbs',
  'goldherb2',
  'pyramidpotion',
  'goldpotion',
  'volcanopotion',
  'forestscroll',
  'swampscroll',
  'goldscroll',
  'peakscroll',
  'goldscroll2',
  'riftscroll',
  'swampheavyarmor',
  'pyramidheavyarmor',
  'castleheavyarmor',
  'templearmor',
  'goldplate',
  'riftheavyarmor',
  'desertmediumarmor',
  'goldroguearmor',
  'volcanomedarmor',
  'desertlightarmor',
  'goldvestments',
  'goldrobe2',
  'volcanorobe',
  'swamphelm',
  'ruinshelm',
  'goldhelmet',
  'platinumhelm',
  'forestroguehat',
  'pyramidroguehat',
  'castleroguehat',
  'peakroguehat',
  'goldroguehat',
  'swamphat',
  'goldhat',
  'goldhat2',
  'volcanomagehat',
  'riftmagehat',
  'desertgauntlets',
  'goldgauntlets',
  'goldgauntlets2',
  'grottogloves',
  'desertgloves',
  'goldgloves',
  'volcanogloves',
  'swampboots',
  'goldboots',
  'volcanoboots',
  'goldboots2',
  'forestshoes',
  'ruinsshoes',
  'castleshoe',
  'goldshoes',
  'grottoshield',
  'castleshield',
  'goldshield',
  'goldshield2',
  'goldcloak',
  'grottoring',
  'pyramidring',
  'goldring',
  'templering',
  'goldring2',
  'grottoamulet',
  'goldamulet',
  'peakamulet',
  'goldamulet2',
  'goldgolem',
  'goldmeal',
  'golddessert',
];

export const donateBlueprintItems = [
  'zweihander',
  'herosword',
  'candysword',
  'fallsword',
  'cyclopssword',
  'poloniacutlass',
  'canesword',
  'dragonsword',
  'victoriansword',
  'moonsword',
  'shoprsword',
  'musketeersword',
  'pumpkinsword',
  'exploreraxe',
  'starteraxe',
  'snowaxe',
  'birdaxe',
  'cincoaxe2020',
  'coupleaxe',
  'beeaxe',
  'fallaxe',
  'aegiraxe',
  'chessaxe',
  'valentineaxe',
  'dinoaxe',
  'kunai',
  'schooldagger2020',
  'yamidagger',
  'lunardagger',
  'beachdagger',
  'junimaydagger',
  'sultandagger',
  'siadagger',
  'stpatrickdagger',
  'halloweendagger',
  'atlab_kyoshifan',
  'wiccandagger',
  'mushroomdagger',
  'lunardagger2',
  'libertymace',
  'summermace',
  'newyearmace',
  'troblinmace',
  'arcademace',
  'pumpkinmace',
  'candyhammer',
  'smithhammer',
  'freedommace',
  'mundrahammer',
  'naturehammer',
  'atlab_mace',
  'eastermace',
  'christmasmace',
  'musicmace',
  'pitchfork',
  'easterspear',
  'heavenlyhalberd',
  'celticspear',
  'yetispear',
  'africanspear',
  'fallspear',
  'sylvanspear',
  'thanksgivingspear',
  'circusspear',
  'christmasspear',
  'airshipspear',
  'beachspear',
  'inuitspear',
  'siabow',
  'mundrabow',
  'halloweenbow',
  'westernbow',
  'valentinebow',
  'fallbow',
  'christmasbow',
  'cincobow',
  'newyearbow',
  'frogbow',
  'arcadebow',
  'thanksgivingbow',
  'blossombow',
  'lilustaff',
  'starterstaff',
  'wiccanstaff',
  'leafstaff',
  'mundrastaff',
  'musicstaff',
  'lemonstaff',
  'christmasstaff',
  'yetistaff',
  'valentinestaff',
  'hemmadonstaff',
  'anubisstaff',
  'baton',
  'twirled',
  'backscratcher',
  'steelrod',
  'starrod',
  'christmaswand',
  'sylvan',
  'fallwand2',
  'rubyrod',
  'liluwand',
  'goldwand',
  'flowerwand',
  'fallwand',
  'moonwand',
  'wizardwand',
  'dragoneye',
  'sailorwand',
  't12wand',
  'chesswand',
  'maplewand',
  't13wand',
  'lightcrossbow',
  'handcrossbow',
  'crossbow',
  'heavycrossbow',
  'thanksgivingcrossbow',
  'huntercrossbow',
  'scorpion',
  'troblincrossbow',
  'doublecrossbow',
  'chukonu',
  'dragoncrossbow',
  'triplecrossbow',
  'handballista',
  'batcrossbow',
  'wyrmthrower',
  'titancrossbow',
  't12crossbow',
  'airshipcrossbow',
  't13crossbow',
  'dreamcrossbow',
  'flintlockpistol',
  'pistol',
  'flintlockrifle',
  'huntingrifle',
  'wintergun',
  'blunderbuss',
  'doublebarrel',
  'fruitgun',
  'arcadegun',
  'kenora',
  'gatling',
  'dragonator',
  'barrelgun',
  'hermes',
  'lunargun',
  'disintegratorgun',
  'doggun',
  't12gun',
  'westerngun',
  'christmasgun',
  't13gun',
  'musicgun',
  'christmasflower',
  'thanksgivingherb',
  'harvest',
  'thanksgivingherb2',
  'bouquet',
  'mushroomherb',
  'valentineherb',
  'goldenclover',
  'couplebouquet',
  'circusherb',
  'candyherb',
  'blossomherb',
  'fruitherb',
  'musicherb',
  'dreamherb',
  'scienceexperiment',
  'victorianpotion',
  'shoprpotion',
  'poloniapotion',
  'cincopotion',
  'pumpkinpotion',
  'lemonpotion',
  'carpenterpotion',
  'batpotion',
  'yetipotion',
  'frogpotion',
  'mundrapotion',
  'beachpotion',
  'maplepotion',
  'arcadepotion',
  'correspondence',
  'tomeofknowledge',
  'christmascroll',
  'fireworksscroll',
  'unicornscroll',
  'vipscroll',
  'waxtablet',
  'priestbook',
  'celticscroll',
  'freedomscroll',
  'lunarscroll',
  'anubisscroll',
  'newyearscroll',
  'valentinescroll',
  'centurionarmor',
  'pumpkinheavyarmor',
  'beeheavyarmor',
  'atlab_heavyarmor',
  'lunararmor',
  'oktoberfestplate',
  'christmasheavyarmor',
  'freedomplate',
  'celticheavyarmor',
  'dogheavyarmor',
  'atlab_fireplate',
  'fallheavyarmor',
  'darkknightheavyarmor',
  'cyclopsheavyarmor',
  'shoprheavyarmor',
  'winterarmor',
  'mundraarmor',
  'airshiparmor',
  'pumpkinarmor',
  'batarmor',
  'atlab_armor',
  'smithapron',
  'siaarmor',
  'lunarmedarmor',
  'troblinarmor',
  'catarmor',
  'cincomedarmor',
  'westernarmor',
  'dreammediumarmor',
  'exploreroutfit',
  'tailorarmor',
  'wizardrobe',
  'atlab_robe',
  'valentinerobe',
  'easterarmor2020',
  'arabiantunic',
  'indiadayarmor',
  'moonrobes',
  'lunarrobe',
  'hemmadonrobe',
  'unicornrobe',
  'eaglecowl',
  'dinohelm',
  'couplehelmet',
  'atlab_firehelm',
  'darkknighthelm',
  'gladiatorhelm',
  'beachhelm',
  'shoprhelm',
  'lunarhelmet',
  'centurionhelmet',
  'thanksgivinghelmet',
  'doghelm',
  'valentinehelm',
  'chesshelm',
  'pumpkinhelm',
  'blossomhelm',
  'christmasroguehat',
  'anubisroguehat',
  'valentinehat',
  'farmerhood',
  'musketeerroguehat',
  'oktoberhat',
  'indiadayhat',
  'cincoroguehat',
  'catroguehat',
  'easterroguehat',
  'airshiproguehat',
  'fallroguehat',
  'inuitroguehat',
  'startertiara',
  'bunnyhat',
  'frogmagehat',
  'liluhat',
  'summonerhat',
  'chessmagehat',
  'plaguemask',
  'schoolhat2020',
  'bathat',
  'victorianmagehat',
  'snowmagehat',
  'blossommagehat',
  'lunarmagehat',
  'beachmagehat',
  'pumpkingauntlets',
  'christmasgauntlets',
  'oktoberfestgauntlets',
  'indiadaygauntlets',
  'fruitgauntlets',
  'wintergauntlets',
  'naturegauntlets',
  'fallgauntlets',
  'aegirgauntlets',
  'mushroomgauntlets',
  'darkknightgauntlets',
  'arcadegauntlets',
  'patchworkgloves',
  'lunargloves',
  'eastergloves2020',
  'catgloves',
  'blossomgloves',
  'oktobergloves',
  'vipgloves',
  'birdgloves',
  'halloweengloves',
  'cincogloves',
  'musketeergloves',
  'heroboots',
  'circusboots',
  'dogboots',
  'bfheavyboots',
  'fallboots',
  'bunnyboots',
  'freedomboots',
  'christmasboots',
  'fruitboots',
  'dinoboots',
  'shoprboots',
  'romansandals',
  'flowersandals',
  'moonshoes',
  'blossomshoes',
  'arabianshoes',
  'inuitshoes',
  'cincoshoes2020',
  'lunarboots',
  'birdshoes',
  'westernshoes',
  'thanksgivingshoes',
  'junimayshoes',
  'imperialscutum',
  'argonshield',
  'aegirshield',
  'natureshield',
  'fallshield',
  'stpatrickshield',
  'eastershield2020',
  'dragonshield',
  'beeshield',
  'snowshield',
  'shoprshield',
  'newyearshield',
  'africanshield',
  'beachshield',
  'mundrashield',
  'squirecloak',
  'wanderercloak',
  'druidcloak',
  'empirecloak',
  'batcloak',
  'elvencloak',
  'furcloak',
  'scalecloak',
  'darkknightcloak',
  'bishopcloak',
  'starcloak',
  'frogcloak',
  't12cloak',
  'blossomcloak',
  'junimaycloak',
  't13cloak',
  'beachcloak',
  'valentinering',
  'cincoring2020',
  'hemmadonring',
  'freedomring',
  'lunarring',
  'chosenring',
  'springflower',
  'schoolring2020',
  'winterring',
  'bunnyring',
  'candyring',
  'newyearring',
  'unicornring',
  'africanring',
  'maplering',
  'mundraamulet',
  'medal',
  'stpatrickmedallion',
  'oktoberamulet',
  'cincoamulet',
  'timelocket',
  'dreamamulet',
  'dragonamulet',
  'rudoamulet',
  'priestamulet',
  'vipamulet',
  'troblinamulet',
  'atlab_amulet',
  'wiccanamulet',
  'arcadeamulet',
  'cyclopsamulet',
  'junimayamulet',
  'troblin',
  'yeti',
  'toadwitch',
  'mushgoon',
  'anubis',
  'cyclops',
  'tyrantking',
  'mermaid',
  'harpy',
  'firecrab',
  'voidling',
  'maplemeal',
  'pizza',
  'arcademeal',
  'newyearmeal',
  'cookies',
  'shavedice',
  'taiyaki',
  'cheesecake',
  'valentinedessert',
  'macaron',
  'daifuku',
  'spiralcake',
  'pineapplecake',
  'tuxedocake',
  't12dessert',
  'coconutcake',
  'newyeardessert',
  't13dessert',
];
