import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IApiList } from 'src/Types/api';
import { IAcknowledgedBlueprints } from 'src/Types/profile';
import { ApiDataUtils, ITableItem } from 'src/Utills/apiData';

interface IApiDataState {
  data: ITableItem[];
  isLoading: boolean;

  blueprints: IAcknowledgedBlueprints;

  autoReload: boolean;
}

const getBlueprints = (): IAcknowledgedBlueprints => {
  const item = localStorage.getItem('blueprints');

  if (!item || typeof item !== 'string') {
    localStorage.setItem('blueprints', JSON.stringify([]));
    return [];
  }

  return JSON.parse(item);
};

const initialState: IApiDataState = {
  data: [],
  isLoading: false,
  blueprints: getBlueprints(),

  autoReload: false,
};

export const apiDataSlice = createSlice({
  name: 'apiData',
  initialState,
  reducers: {
    load: (state) => {
      state.data = [];
      state.isLoading = true;
    },
    loadSuccess: (state, { payload }: PayloadAction<IApiList>) => {
      // TODO: Maybe could be better. Will recalculate item value only on refetch
      const itemList = new Map<string, ITableItem>(ApiDataUtils.getItemList(state.blueprints));

      const { offers, requests } = ApiDataUtils.getGroupedTrades(payload.data);

      itemList.forEach((item) => {
        itemList.set(item.uid, {
          ...item,
          offers: ApiDataUtils.getTradeListByItem(offers, item, 'asc'),
          requests: ApiDataUtils.getTradeListByItem(requests, item, 'desc'),
        });
      });

      state.data = [...itemList.values()];
      state.isLoading = false;
    },
    setAutoReload: (state, { payload }: PayloadAction<boolean>) => {
      state.autoReload = payload;
    },
  },
});
