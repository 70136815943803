import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Showcase } from 'src/Types/enums';

export type IExpPageFilterKeys = keyof IExpPageState['filters'];

interface IExpPageState {
  showcaseFilter: Showcase[];
  filters: {
    onlyNormalBlueprintItems: boolean;
    onlyProfitableOffers: boolean;
    onlyEmptyRequests: boolean;
    onlyProfitableRequests: boolean;
    onlyProfitableRequestsWithSurcharge: boolean;
    onlyProfitableOffersWithSurcharge: boolean;
  };
}

const initialState: IExpPageState = {
  showcaseFilter: [Showcase.Shelves, Showcase.Outfits, Showcase.Table, Showcase.WeaponRack],
  filters: {
    onlyNormalBlueprintItems: false,
    onlyProfitableOffers: false,
    onlyEmptyRequests: false,
    onlyProfitableRequests: false,
    onlyProfitableRequestsWithSurcharge: false,
    onlyProfitableOffersWithSurcharge: false,
  },
};

export const expPageSlice = createSlice({
  name: 'expPage',
  initialState,
  reducers: {
    setShowcase: (state, { payload }: PayloadAction<Showcase[]>) => {
      state.showcaseFilter = payload;
    },
    setFilter: (state, { payload }: PayloadAction<{ key: IExpPageFilterKeys; state: boolean }>) => {
      state.filters = { ...state.filters, [payload.key]: payload.state };
    },
  },
});
