import { apiDataSlice } from './slice';
import * as Select from './selectors';
import { loadApiDataSaga } from './sagas';

export const ApiDataStore = {
  Select,
  Actions: apiDataSlice.actions,
  Saga: {
    loadApiDataSaga,
  },
  Slice: apiDataSlice,
};
