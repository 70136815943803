import { Button } from 'antd';
import React from 'react';
import './styles.scss';

export const Dashboard: React.FC = () => {
  return (
    <div className="wrapper">
      <Button href={'/exp'}>Exp table</Button>
      <Button href={'/energy'}>Energy table</Button>
    </div>
  );
};
