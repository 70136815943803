import { Tooltip, Typography, type TableProps } from 'antd';
import classNames from 'classnames';
import { ItemTypeToString } from 'src/Types/enums';
import { ITableItem } from 'src/Utills/apiData';

export const energyTableColumns: TableProps<ITableItem>['columns'] = [
  {
    title: 'Item',
    dataIndex: 'nameEN',
    key: 'nameEN',
    width: 160,
    ellipsis: true,
  },
  {
    title: 'Предмет',
    dataIndex: 'nameRU',
    key: 'nameRU',
    width: 160,
    ellipsis: true,
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    width: 100,
    ellipsis: true,
    render: (type: ITableItem['type']) => ItemTypeToString(type),
  },
  {
    title: 'T',
    dataIndex: 'tier',
    key: 'tier',
    width: 60,
    ellipsis: true,
    sorter: (a, b) => a.tier - b.tier,
    filters: [...new Array(13)].map((_, idx) => ({
      text: idx + 1,
      value: idx + 1,
    })),
    onFilter: (value, item) => item.tier === value,
  },
  {
    title: 'Energy',
    dataIndex: 'discount',
    key: 'discount',
    width: 80,
    ellipsis: true,
    sorter: (a, b) => a.discount - b.discount,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
    width: 80,
    ellipsis: true,
    sorter: (a, b) => a.value - b.value,
  },
  {
    title: 'Offers',
    dataIndex: 'offers',
    render: (offers: ITableItem['offers']) => (
      <div className={'flex flex-row gap-4'}>
        {offers.slice(0, 3).map(({ id, price, quantity, energyPerGold, lastUpdated }) => (
          <TradeCell
            key={id}
            price={price}
            quantity={quantity}
            energyPerGold={energyPerGold}
            lastUpdated={lastUpdated}
          />
        ))}
      </div>
    ),
    sorter: (a, b) => (a.offers[0]?.energyPerGold ?? 0) - (b.offers[0]?.energyPerGold ?? 0),
  },
  {
    title: 'Requests',
    dataIndex: 'requests',
    render: (requests: ITableItem['requests']) => (
      <div className={'flex flex-row gap-4'}>
        {requests.slice(0, 3).map(({ id, price, quantity, energyPerGold, lastUpdated }) => (
          <TradeCell
            key={id}
            price={price}
            quantity={quantity}
            energyPerGold={energyPerGold}
            lastUpdated={lastUpdated}
          />
        ))}
      </div>
    ),
    sorter: (a, b) => (a.requests[0]?.energyPerGold ?? 0) - (b.requests[0]?.energyPerGold ?? 0),
  },
];

const TradeCell: React.FC<{
  price: number;
  quantity: number;
  energyPerGold: number;
  lastUpdated: Date;
}> = ({ price, energyPerGold, lastUpdated }) => {
  const minutesAgo = ((lastUpdated.getTime() - Date.now()) / 60000) * -1;

  return (
    <Tooltip
      overlay={
        <Typography.Text className={'text-base text-white'}>{minutesAgo.toFixed(2)} minutes ago</Typography.Text>
      }
    >
      <div
        className={classNames('bg-opacity-30 rounded-lg p-2', {
          'bg-green-700': minutesAgo >= 0 && minutesAgo < 1,
          'bg-green-500': minutesAgo >= 1 && minutesAgo < 2,
          'bg-green-300': minutesAgo >= 2 && minutesAgo < 3,
          'bg-orange-500': minutesAgo >= 3 && minutesAgo < 5,
          'bg-orange-300': minutesAgo >= 5 && minutesAgo < 7,
          'bg-red-500': minutesAgo >= 7 && minutesAgo < 10,
          'bg-red-300': minutesAgo >= 10,
        })}
      >
        <div>Energy/Price: {energyPerGold.toFixed(2)}</div>
        <div>Price: {price}</div>
      </div>
    </Tooltip>
  );
};
