import { AxiosResponse } from 'axios';
import { put, takeLatest } from 'redux-saga/effects';
import { IApiList } from 'src/Types/api';
import { apiClient } from 'src/Utills/apiClient';
import { ApiDataStore } from 'src/redux/apiData';

function* onFetchApiData() {
  try {
    const response: AxiosResponse<IApiList> = yield apiClient.get<IApiList>('/item/last/all');

    yield put(ApiDataStore.Actions.loadSuccess(response.data));
  } catch (e) {
    console.error(e);
  }
}

export function* loadApiDataSaga() {
  yield takeLatest(ApiDataStore.Actions.load.type, onFetchApiData);
}
