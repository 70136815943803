import { IItem } from 'src/Types/types';

type IItemList = Record<string, IItem>;

export const itemList: IItemList = {
  shortsword: {
    uid: 'shortsword',
    level: 1,
    type: 'ws',
    xp: 135,
    craftXp: 2,
    value: 50,
    tradeMinMaxValue: '15,20,30,45,80;800,900,1500,2300,3800',
    favor: 1,
    time: 15,
    atk: 16,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:kitchenknife',
    upgrade2: 'bp:longsword',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-4',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 15,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  longsword: {
    uid: 'longsword',
    level: 3,
    type: 'ws',
    xp: 455,
    craftXp: 7,
    value: 180,
    tradeMinMaxValue: '50,70,110,160,270;2700,3400,5400,8100,13500',
    favor: 10,
    time: 60,
    atk: 32,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'bp:boysaxe',
    upgrade2: 'bp:broadsword',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-5',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 25,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  broadsword: {
    uid: 'broadsword',
    level: 5,
    type: 'ws',
    xp: 1200,
    craftXp: 18,
    value: 670,
    tradeMinMaxValue: '170,210,340,500,840;8400,10500,17000,25000,42000',
    favor: 16,
    time: 140,
    atk: 52,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 14,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'value*1.25',
    upgrade2: 'bp:cutlass',
    upgrade3: 'time*0.75',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 45,
    suggest: 10,
    speedup: 70,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  grottosword: {
    uid: 'grottosword',
    level: 6,
    type: 'ws',
    xp: 1700,
    craftXp: 26,
    value: 1050,
    tradeMinMaxValue: '460,570,920,1400,2300;23000,28500,46000,69000,115000',
    favor: 21,
    time: 200,
    atk: 64,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 3,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 16,
    resource2: 'wood',
    r2Qty: 4,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-3',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource2-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'natural',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 25,
    surcharge: 55,
    suggest: 10,
    speedup: 90,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'grotto',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  zweihander: {
    uid: 'zweihander',
    level: 7,
    type: 'ws',
    xp: 2350,
    craftXp: 35,
    value: 1250,
    tradeMinMaxValue: '310,390,630,940,1550;15500,19500,31500,47000,78000',
    favor: 23,
    time: 270,
    atk: 76,
    def: 19,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 17,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-3',
    upgrade3: 'time*0.75',
    upgrade4: 'resource1-2',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-9',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 60,
    suggest: 10,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 7,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-10-11',
    shardPrice: 75,
    capriceDelay: 240,
  },
  cutlass: {
    uid: 'cutlass',
    level: 8,
    type: 'ws',
    xp: 3100,
    craftXp: 47,
    value: 2050,
    tradeMinMaxValue: '510,640,1050,1550,2600;25500,32000,51500,77000,130000',
    favor: 27,
    time: 360,
    atk: 88,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 5,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 21,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'shortsword',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'arcanedust',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-4',
    upgrade3: 'bp:rapier',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 35,
    surcharge: 80,
    suggest: 15,
    speedup: 135,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  rapier: {
    uid: 'rapier',
    level: 12,
    type: 'ws',
    xp: 7300,
    craftXp: 110,
    value: 5900,
    tradeMinMaxValue:
      '1500,1850,3000,4400,7400;74000,92000,150000,220000,370000',
    favor: 64,
    time: 990,
    atk: 144,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 41,
    resource2: 'steel',
    r2Qty: 8,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 2,
    c1Tags: null,
    component2: 'redpinecone',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-8',
    upgrade3: 'bp:katana',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 60,
    surcharge: 130,
    suggest: 25,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  herosword: {
    uid: 'herosword',
    level: 14,
    type: 'ws',
    xp: 10000,
    craftXp: 150,
    value: 11000,
    tradeMinMaxValue:
      '2800,3400,5500,8300,14000;140000,170000,275000,415000,690000',
    favor: 119,
    time: 1290,
    atk: 172,
    def: 43,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 60,
    resource2: 'steel',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'cutlass',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'icypearl',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-2',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource2-2',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 80,
    surcharge: 175,
    suggest: 35,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-09-10',
    shardPrice: 120,
    capriceDelay: 240,
  },
  candysword: {
    uid: 'candysword',
    level: 14,
    type: 'ws',
    xp: 10000,
    craftXp: 150,
    value: 15000,
    tradeMinMaxValue:
      '3800,4700,7500,11500,19000;190000,235000,375000,565000,940000',
    favor: 105,
    time: 1590,
    atk: 172,
    def: 0,
    hp: 5,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 54,
    resource2: 'oils',
    r2Qty: 11,
    resource3: null,
    r3Qty: 0,
    component1: 'corrupted',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'livingroots',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-11',
    upgrade3: 'component1-1',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 90,
    surcharge: 200,
    suggest: 40,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 7,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-09-07',
    shardPrice: 120,
    capriceDelay: 240,
  },
  katana: {
    uid: 'katana',
    level: 16,
    type: 'ws',
    xp: 13500,
    craftXp: 203,
    value: 24000,
    tradeMinMaxValue:
      '6000,7500,12000,18000,30000;300000,375000,600000,900000,1500000',
    favor: 173,
    time: 2400,
    atk: 200,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 85,
    resource2: 'steel',
    r2Qty: 21,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-17',
    upgrade3: 'bp:scimitar',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 255,
    suggest: 50,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 18,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  fallsword: {
    uid: 'fallsword',
    level: 18,
    type: 'ws',
    xp: 17500,
    craftXp: 263,
    value: 38500,
    tradeMinMaxValue:
      '9600,12000,19500,29000,48000;480000,600000,965000,1450000,2400000',
    favor: 296,
    time: 3600,
    atk: 230,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 1,
    w3BuildingReq: 5,
    resource1: 'iron',
    r1Qty: 113,
    resource2: 'steel',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: 'redpinecone',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource1-23',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 145,
    surcharge: 320,
    suggest: 65,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-09-08',
    shardPrice: 140,
    capriceDelay: 240,
  },
  scimitar: {
    uid: 'scimitar',
    level: 20,
    type: 'ws',
    xp: 22000,
    craftXp: 330,
    value: 64500,
    tradeMinMaxValue:
      '16000,20000,32000,48000,81000;805000,1000000,1600000,2400000,4050000',
    favor: 498,
    time: 6000,
    atk: 270,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'iron',
    r1Qty: 141,
    resource2: 'steel',
    r2Qty: 36,
    resource3: 'oils',
    r3Qty: 18,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:celesteelblade',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 180,
    surcharge: 405,
    suggest: 80,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  cyclopssword: {
    uid: 'cyclopssword',
    level: 21,
    type: 'ws',
    xp: 24500,
    craftXp: 368,
    value: 100000,
    tradeMinMaxValue:
      '25000,31000,50000,75000,125000;1250000,1550000,2500000,3750000,6250000',
    favor: 502,
    time: 7200,
    atk: 280,
    def: 70,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'iron',
    r1Qty: 155,
    resource2: 'steel',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'ancientmarble',
    c1Qty: 2,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-23',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 225,
    surcharge: 500,
    suggest: 100,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-02-21',
    shardPrice: 160,
    capriceDelay: 0,
  },
  celesteelblade: {
    uid: 'celesteelblade',
    level: 24,
    type: 'ws',
    xp: 32500,
    craftXp: 488,
    value: 195000,
    tradeMinMaxValue:
      '49000,61000,98000,145000,240000;2450000,3050000,4900000,7300000,12000000',
    favor: 753,
    time: 13200,
    atk: 340,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'iron',
    r1Qty: 199,
    resource2: 'steel',
    r2Qty: 51,
    resource3: 'gems',
    r3Qty: 5,
    component1: 'timecrystal',
    c1Qty: 5,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:falchion',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 310,
    surcharge: 690,
    suggest: 140,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 7,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ruinssword: {
    uid: 'ruinssword',
    level: 25,
    type: 'ws',
    xp: 35500,
    craftXp: 533,
    value: 220000,
    tradeMinMaxValue:
      '57000,71000,115000,170000,290000;2850000,3550000,5700000,8550000,14500000',
    favor: 981,
    time: 16200,
    atk: 350,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 8,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'iron',
    r1Qty: 214,
    resource2: 'steel',
    r2Qty: 57,
    resource3: 'mana',
    r3Qty: 6,
    component1: 'overgrownvine',
    c1Qty: 4,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 7,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'unholy',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 330,
    surcharge: 735,
    suggest: 145,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 7,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'ruins',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldsword: {
    uid: 'goldsword',
    level: 26,
    type: 'ws',
    xp: 39000,
    craftXp: 585,
    value: 365000,
    tradeMinMaxValue:
      '91000,115000,185000,270000,460000;4550000,5700000,9150000,13500000,23000000',
    favor: 1146,
    time: 19200,
    atk: 370,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 8,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 13,
    resource1: 'iron',
    r1Qty: 229,
    resource2: 'steel',
    r2Qty: 62,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'tabletfragment',
    c1Qty: 3,
    c1Tags: null,
    component2: 'timecrystal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-46',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-12',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'luxuriousspirit',
    tag: null,
    discount: 315,
    surcharge: 700,
    suggest: 140,
    speedup: 995,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 7,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-01-25',
    shardPrice: 0,
    capriceDelay: 0,
  },
  newyearsword: {
    uid: 'newyearsword',
    level: 26,
    type: 'ws',
    xp: 39000,
    craftXp: 585,
    value: 225000,
    tradeMinMaxValue:
      '56000,70000,115000,170000,280000;2800000,3500000,5650000,8450000,14000000',
    favor: 1099,
    time: 19200,
    atk: 370,
    def: 0,
    hp: 12,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 8,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 13,
    resource1: 'iron',
    r1Qty: 221,
    resource2: 'steel',
    r2Qty: 58,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'timecrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-44',
    upgrade3: 'resource2-12',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 335,
    surcharge: 740,
    suggest: 150,
    speedup: 995,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-23',
    shardPrice: 180,
    capriceDelay: 0,
  },
  falchion: {
    uid: 'falchion',
    level: 28,
    type: 'ws',
    xp: 45500,
    craftXp: 683,
    value: 300000,
    tradeMinMaxValue:
      '75000,94000,150000,230000,380000;3750000,4700000,7500000,11500000,19000000',
    favor: 1375,
    time: 20400,
    atk: 410,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'iron',
    r1Qty: 259,
    resource2: 'steel',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 12,
    component1: 'ectoplasm',
    c1Qty: 4,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'bp:cleaver',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 385,
    surcharge: 850,
    suggest: 170,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  poloniacutlass: {
    uid: 'poloniacutlass',
    level: 29,
    type: 'ws',
    xp: 49000,
    craftXp: 735,
    value: 420000,
    tradeMinMaxValue:
      '105000,130000,210000,320000,530000;5250000,6550000,10500000,16000000,26500000',
    favor: 1777,
    time: 24600,
    atk: 430,
    def: 0,
    hp: 13,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'iron',
    r1Qty: 275,
    resource2: 'steel',
    r2Qty: 77,
    resource3: 'gems',
    r3Qty: 14,
    component1: 'ghostflag',
    c1Qty: 4,
    c1Tags: null,
    component2: 'arcanedust',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 450,
    surcharge: 1000,
    suggest: 200,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-04-21',
    shardPrice: 200,
    capriceDelay: 240,
  },
  canesword: {
    uid: 'canesword',
    level: 29,
    type: 'ws',
    xp: 49000,
    craftXp: 735,
    value: 395000,
    tradeMinMaxValue:
      '99000,125000,200000,300000,490000;4950000,6150000,9900000,15000000,24500000',
    favor: 1777,
    time: 24600,
    atk: 430,
    def: 108,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'iron',
    r1Qty: 275,
    resource2: 'steel',
    r2Qty: 77,
    resource3: 'mana',
    r3Qty: 14,
    component1: 'sewingthread',
    c1Qty: 3,
    c1Tags: null,
    component2: 'overgrownvine',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-83',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 435,
    surcharge: 970,
    suggest: 195,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-06-02',
    shardPrice: 200,
    capriceDelay: 240,
  },
  cleaver: {
    uid: 'cleaver',
    level: 30,
    type: 'ws',
    xp: 53000,
    craftXp: 795,
    value: 605000,
    tradeMinMaxValue:
      '150000,190000,300000,450000,760000;7550000,9450000,15000000,22500000,38000000',
    favor: 1835,
    time: 29400,
    atk: 450,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 290,
    resource2: 'steel',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 17,
    component1: 'sirenshard',
    c1Qty: 5,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:vorpalsword',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 805,
    surcharge: 1790,
    suggest: 360,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 7,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  templesword: {
    uid: 'templesword',
    level: 31,
    type: 'ws',
    xp: 56500,
    craftXp: 848,
    value: 630000,
    tradeMinMaxValue:
      '165000,210000,330000,490000,820000;8250000,10500000,16500000,24500000,41000000',
    favor: 2352,
    time: 34800,
    atk: 470,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 306,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 19,
    component1: 'deepcoral',
    c1Qty: 5,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-2',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-6',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'flood',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 820,
    surcharge: 1825,
    suggest: 365,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 7,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'temple',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  dragonsword: {
    uid: 'dragonsword',
    level: 31,
    type: 'ws',
    xp: 56500,
    craftXp: 848,
    value: 650000,
    tradeMinMaxValue:
      '165000,200000,330000,490000,810000;8150000,10000000,16500000,24500000,40500000',
    favor: 2352,
    time: 34800,
    atk: 470,
    def: 118,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 306,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 19,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: 'overgrownvine',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-92',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-22',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 835,
    surcharge: 1850,
    suggest: 370,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-12-30',
    shardPrice: 250,
    capriceDelay: 240,
  },
  lunarsword: {
    uid: 'lunarsword',
    level: 32,
    type: 'ws',
    xp: 61000,
    craftXp: 915,
    value: 680000,
    tradeMinMaxValue:
      '170000,210000,340000,510000,850000;8500000,10500000,17000000,25500000,42500000',
    favor: 2705,
    time: 42000,
    atk: 480,
    def: 0,
    hp: 15,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 26,
    w2BuildingReq: 22,
    w3BuildingReq: 19,
    resource1: 'iron',
    r1Qty: 322,
    resource2: 'steel',
    r2Qty: 93,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'deepcoral',
    c1Qty: 4,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-97',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'resource2-28',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'tiger',
    tag: null,
    discount: 850,
    surcharge: 1890,
    suggest: 380,
    speedup: 2635,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-01-25',
    shardPrice: 250,
    capriceDelay: 0,
  },
  vorpalsword: {
    uid: 'vorpalsword',
    level: 33,
    type: 'ws',
    xp: 97500,
    craftXp: 1463,
    value: 1400000,
    tradeMinMaxValue:
      '350000,440000,700000,1050000,1750000;17500000,22000000,35000000,52500000,87500000',
    favor: 2736,
    time: 50400,
    atk: 580,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 338,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 23,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12sword',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1045,
    surcharge: 2320,
    suggest: 465,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  victoriansword: {
    uid: 'victoriansword',
    level: 33,
    type: 'ws',
    xp: 97500,
    craftXp: 1463,
    value: 1500000,
    tradeMinMaxValue:
      '380000,470000,750000,1150000,1900000;19000000,23500000,37500000,56500000,94000000',
    favor: 3061,
    time: 50400,
    atk: 580,
    def: 0,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 338,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 23,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1080,
    surcharge: 2395,
    suggest: 480,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-04-06',
    shardPrice: 300,
    capriceDelay: 0,
  },
  moonsword: {
    uid: 'moonsword',
    level: 34,
    type: 'ws',
    xp: 105000,
    craftXp: 1575,
    value: 1650000,
    tradeMinMaxValue:
      '410000,520000,830000,1250000,2050000;20500000,26000000,41500000,62000000,103000000',
    favor: 3479,
    time: 60000,
    atk: 600,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 354,
    resource2: 'steel',
    r2Qty: 103,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.75',
    upgrade2: 'resource2-21',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-4',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-106',
    supgrade2: 'resource3-4',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1130,
    surcharge: 2510,
    suggest: 500,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-09-22',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12sword: {
    uid: 't12sword',
    level: 36,
    type: 'ws',
    xp: 195000,
    craftXp: 2925,
    value: 2600000,
    tradeMinMaxValue:
      '650000,810000,1300000,1950000,3250000;32500000,40500000,65000000,97500000,162500000',
    favor: 4046,
    time: 85800,
    atk: 730,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 387,
    resource2: 'steel',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13sword',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'resource2-34',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1405,
    surcharge: 3120,
    suggest: 625,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  shoprsword: {
    uid: 'shoprsword',
    level: 36,
    type: 'ws',
    xp: 195000,
    craftXp: 2925,
    value: 2800000,
    tradeMinMaxValue:
      '700000,880000,1400000,2100000,3500000;35000000,44000000,70000000,105000000,175000000',
    favor: 4570,
    time: 85800,
    atk: 730,
    def: 183,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'priest',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 390,
    resource2: 'steel',
    r2Qty: 110,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-78',
    upgrade3: 'resource2-17',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-17',
    supgrade3: 'multi+0.15',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1455,
    surcharge: 3235,
    suggest: 645,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-06-15',
    shardPrice: 350,
    capriceDelay: 0,
  },
  goldsword2: {
    uid: 'goldsword2',
    level: 37,
    type: 'ws',
    xp: 210000,
    craftXp: 3150,
    value: 5350000,
    tradeMinMaxValue:
      '1350000,1650000,2700000,4000000,6700000;67000000,83500000,134000000,200500000,334500000',
    favor: 5154,
    time: 102600,
    atk: 760,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 404,
    resource2: 'steel',
    r2Qty: 123,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-81',
    upgrade4: 'resource2-37',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1490,
    surcharge: 3310,
    suggest: 660,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-07-08',
    shardPrice: 0,
    capriceDelay: 0,
  },
  musketeersword: {
    uid: 'musketeersword',
    level: 37,
    type: 'ws',
    xp: 210000,
    craftXp: 3150,
    value: 2900000,
    tradeMinMaxValue:
      '730000,910000,1450000,2200000,3650000;36500000,45500000,72500000,109000000,181500000',
    favor: 5094,
    time: 102600,
    atk: 760,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.07',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 400,
    resource2: 'steel',
    r2Qty: 120,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-80',
    upgrade4: 'resource2-18',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1480,
    surcharge: 3290,
    suggest: 660,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-10-04',
    shardPrice: 350,
    capriceDelay: 0,
  },
  pumpkinsword: {
    uid: 'pumpkinsword',
    level: 37,
    type: 'ws',
    xp: 210000,
    craftXp: 3150,
    value: 2900000,
    tradeMinMaxValue:
      '730000,910000,1450000,2200000,3650000;36500000,45500000,72500000,109000000,181500000',
    favor: 4943,
    time: 102600,
    atk: 760,
    def: 190,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'carpenter',
    worker3: 'master',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'wood',
    r1Qty: 390,
    resource2: 'ironwood',
    r2Qty: 115,
    resource3: 'mana',
    r3Qty: 34,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-7',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-117',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1480,
    surcharge: 3290,
    suggest: 660,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-10-19',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13sword: {
    uid: 't13sword',
    level: 39,
    type: 'ws',
    xp: 235000,
    craftXp: 3525,
    value: 5650000,
    tradeMinMaxValue:
      '1400000,1750000,2850000,4250000,7050000;70500000,88500000,141500000,212000000,353000000',
    favor: 6883,
    time: 146400,
    atk: 930,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'steel',
    r1Qty: 139,
    resource2: 'gems',
    r2Qty: 39,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-28',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 2040,
    surcharge: 4530,
    suggest: 905,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  spacesword: {
    uid: 'spacesword',
    level: 39,
    type: 'ws',
    xp: 235000,
    craftXp: 3525,
    value: 6050000,
    tradeMinMaxValue:
      '1500000,1900000,3050000,4550000,7550000;75500000,94500000,151500000,227000000,378000000',
    favor: 7256,
    time: 146400,
    atk: 930,
    def: 0,
    hp: 29,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 431,
    resource2: 'gems',
    r2Qty: 39,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voideye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.7',
    upgrade2: 'resource2-6',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-5',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-86',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 2105,
    surcharge: 4680,
    suggest: 935,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-16',
    shardPrice: 375,
    capriceDelay: 0,
  },
  boysaxe: {
    uid: 'boysaxe',
    level: 3,
    type: 'wa',
    xp: 540,
    craftXp: 8,
    value: 310,
    tradeMinMaxValue: '90,120,190,280,470;4700,5800,9300,14000,23500',
    favor: 10,
    time: 70,
    atk: 30,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'component1-1',
    upgrade2: 'bp:hatchet',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.5',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 15,
    surcharge: 30,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  hatchet: {
    uid: 'hatchet',
    level: 5,
    type: 'wa',
    xp: 1450,
    craftXp: 22,
    value: 710,
    tradeMinMaxValue: '210,270,430,640,1050;10500,13500,21500,32000,53500',
    favor: 16,
    time: 160,
    atk: 50,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 14,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:doublebitted',
    upgrade3: 'resource1-3',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.5',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 45,
    suggest: 10,
    speedup: 70,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  doublebitted: {
    uid: 'doublebitted',
    level: 7,
    type: 'wa',
    xp: 2850,
    craftXp: 43,
    value: 1300,
    tradeMinMaxValue: '390,490,780,1150,1950;19500,24500,39000,58500,97500',
    favor: 21,
    time: 300,
    atk: 72,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 17,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:halberd',
    upgrade3: 'time*0.75',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'quality+2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-3',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 60,
    suggest: 10,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  exploreraxe: {
    uid: 'exploreraxe',
    level: 8,
    type: 'wa',
    xp: 3800,
    craftXp: 57,
    value: 1750,
    tradeMinMaxValue: '530,660,1050,1600,2600;26500,33000,52500,79000,130000',
    favor: 30,
    time: 400,
    atk: 84,
    def: 21,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 5,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 21,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'resource1-4',
    upgrade3: 'time*0.75',
    upgrade4: 'resource1-4',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-11',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 30,
    surcharge: 70,
    suggest: 15,
    speedup: 135,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-08-13',
    shardPrice: 75,
    capriceDelay: 240,
  },
  halberd: {
    uid: 'halberd',
    level: 10,
    type: 'wa',
    xp: 6100,
    craftXp: 92,
    value: 4500,
    tradeMinMaxValue:
      '1150,1400,2300,3400,5600;56500,70500,115000,170000,280000',
    favor: 42,
    time: 690,
    atk: 108,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 7,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 30,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:tomahawk',
    upgrade4: 'resource1-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 50,
    surcharge: 115,
    suggest: 25,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  grottoaxe: {
    uid: 'grottoaxe',
    level: 11,
    type: 'wa',
    xp: 7450,
    craftXp: 112,
    value: 5250,
    tradeMinMaxValue:
      '1500,1850,3000,4400,7400;74000,92000,150000,220000,370000',
    favor: 56,
    time: 870,
    atk: 124,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 4,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 34,
    resource2: 'iron',
    r2Qty: 17,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-7',
    upgrade4: 'resource2-9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'ember',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 55,
    surcharge: 120,
    suggest: 25,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: 'grotto',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  epicaxe: {
    uid: 'epicaxe',
    level: 11,
    type: 'wa',
    xp: 7450,
    craftXp: 112,
    value: 5750,
    tradeMinMaxValue:
      '1450,1800,2900,4300,7200;72000,90000,145000,215000,360000',
    favor: 56,
    time: 870,
    atk: 124,
    def: 0,
    hp: 4,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 4,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 34,
    resource2: 'iron',
    r2Qty: 17,
    resource3: null,
    r3Qty: 0,
    component1: 'woodbranch',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'webbedwings',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource2-9',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 60,
    surcharge: 130,
    suggest: 25,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-07-21',
    shardPrice: 100,
    capriceDelay: 0,
  },
  tomahawk: {
    uid: 'tomahawk',
    level: 13,
    type: 'wa',
    xp: 10500,
    craftXp: 158,
    value: 10000,
    tradeMinMaxValue:
      '2500,3100,5000,7500,12500;125000,155000,250000,375000,625000',
    favor: 81,
    time: 1380,
    atk: 148,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 49,
    resource2: 'ironwood',
    r2Qty: 10,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: 'elvenwood',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-10',
    upgrade3: 'bp:battleaxe',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 75,
    surcharge: 165,
    suggest: 35,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  starteraxe: {
    uid: 'starteraxe',
    level: 15,
    type: 'wa',
    xp: 14500,
    craftXp: 218,
    value: 12000,
    tradeMinMaxValue:
      '3000,3800,6000,9000,15000;150000,190000,300000,450000,750000',
    favor: 150,
    time: 2160,
    atk: 176,
    def: 44,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 71,
    resource2: 'ironwood',
    r2Qty: 16,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 3,
    c1Tags: null,
    component2: 'redpinecone',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-14',
    upgrade3: 'resource2-3',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-4',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 80,
    surcharge: 180,
    suggest: 35,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 120,
    capriceDelay: 240,
  },
  snowaxe: {
    uid: 'snowaxe',
    level: 15,
    type: 'wa',
    xp: 14500,
    craftXp: 218,
    value: 14500,
    tradeMinMaxValue:
      '3600,4500,7300,11000,18000;180000,225000,365000,545000,905000',
    favor: 138,
    time: 2160,
    atk: 176,
    def: 0,
    hp: 6,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 66,
    resource2: 'steel',
    r2Qty: 14,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-10',
    upgrade3: 'resource1-10',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 90,
    surcharge: 200,
    suggest: 40,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-30',
    shardPrice: 120,
    capriceDelay: 0,
  },
  battleaxe: {
    uid: 'battleaxe',
    level: 17,
    type: 'wa',
    xp: 19000,
    craftXp: 285,
    value: 24000,
    tradeMinMaxValue:
      '6000,7500,12000,18000,30000;300000,375000,600000,900000,1500000',
    favor: 216,
    time: 3300,
    atk: 210,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 1,
    w3BuildingReq: 4,
    resource1: 'wood',
    r1Qty: 99,
    resource2: 'ironwood',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'hatchet',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'mooncrystal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:steelaxe',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 255,
    suggest: 50,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 16,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  birdaxe: {
    uid: 'birdaxe',
    level: 18,
    type: 'wa',
    xp: 21500,
    craftXp: 323,
    value: 40500,
    tradeMinMaxValue:
      '10000,12500,20000,30000,51000;505000,635000,1000000,1500000,2550000',
    favor: 296,
    time: 3000,
    atk: 220,
    def: 0,
    hp: 7,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 1,
    w3BuildingReq: 5,
    resource1: 'wood',
    r1Qty: 113,
    resource2: 'ironwood',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'tomahawk',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'webbedwings',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: 'eagle',
    tag: null,
    discount: 145,
    surcharge: 325,
    suggest: 65,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-05-18',
    shardPrice: 140,
    capriceDelay: 240,
  },
  steelaxe: {
    uid: 'steelaxe',
    level: 20,
    type: 'wa',
    xp: 27000,
    craftXp: 405,
    value: 69000,
    tradeMinMaxValue:
      '17500,22000,35000,52000,86000;865000,1100000,1750000,2600000,4300000',
    favor: 498,
    time: 6600,
    atk: 250,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'wood',
    r1Qty: 141,
    resource2: 'ironwood',
    r2Qty: 36,
    resource3: 'steel',
    r3Qty: 18,
    component1: 'starmetal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'darkscale',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:raideraxe',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-5',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 190,
    surcharge: 420,
    suggest: 85,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pyramidaxe: {
    uid: 'pyramidaxe',
    level: 21,
    type: 'wa',
    xp: 30000,
    craftXp: 450,
    value: 86500,
    tradeMinMaxValue:
      '24000,29000,47000,71000,120000;1200000,1450000,2350000,3550000,5900000',
    favor: 502,
    time: 7800,
    atk: 270,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'wood',
    r1Qty: 155,
    resource2: 'ironwood',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'starmetal',
    c1Qty: 1,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'wild',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 210,
    surcharge: 470,
    suggest: 95,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: 'pyramid',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  raideraxe: {
    uid: 'raideraxe',
    level: 25,
    type: 'wa',
    xp: 44000,
    craftXp: 660,
    value: 210000,
    tradeMinMaxValue:
      '53000,66000,105000,160000,260000;2650000,3300000,5250000,7900000,13000000',
    favor: 896,
    time: 15600,
    atk: 330,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'wood',
    r1Qty: 214,
    resource2: 'ironwood',
    r2Qty: 57,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'ancienthammer',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'ancientmarble',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'bp:executioner',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 320,
    surcharge: 715,
    suggest: 145,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lunaraxe: {
    uid: 'lunaraxe',
    level: 26,
    type: 'wa',
    xp: 47500,
    craftXp: 713,
    value: 230000,
    tradeMinMaxValue:
      '58000,72000,115000,175000,290000;2900000,3600000,5750000,8650000,14500000',
    favor: 1114,
    time: 21600,
    atk: 350,
    def: 88,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 8,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 13,
    resource1: 'iron',
    r1Qty: 220,
    resource2: 'steel',
    r2Qty: 60,
    resource3: 'gems',
    r3Qty: 10,
    component1: 'sharpfang',
    c1Qty: 7,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 7,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-44',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 340,
    surcharge: 750,
    suggest: 150,
    speedup: 995,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-01-10',
    shardPrice: 180,
    capriceDelay: 0,
  },
  executioner: {
    uid: 'executioner',
    level: 27,
    type: 'wa',
    xp: 51500,
    craftXp: 773,
    value: 285000,
    tradeMinMaxValue:
      '71000,89000,145000,210000,360000;3550000,4450000,7150000,10500000,18000000',
    favor: 1224,
    time: 18600,
    atk: 370,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'wood',
    r1Qty: 244,
    resource2: 'ironwood',
    r2Qty: 67,
    resource3: 'gems',
    r3Qty: 13,
    component1: 'ghostflag',
    c1Qty: 3,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:crescentaxe',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-4',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 375,
    surcharge: 830,
    suggest: 165,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  castleaxe: {
    uid: 'castleaxe',
    level: 28,
    type: 'wa',
    xp: 56000,
    craftXp: 840,
    value: 360000,
    tradeMinMaxValue:
      '97000,120000,195000,290000,490000;4850000,6050000,9700000,14500000,24500000',
    favor: 1589,
    time: 22200,
    atk: 380,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 259,
    resource2: 'ironwood',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'sewingthread',
    c1Qty: 4,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-4',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'sacred',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 420,
    surcharge: 930,
    suggest: 185,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'castle',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  cincoaxe2020: {
    uid: 'cincoaxe2020',
    level: 28,
    type: 'wa',
    xp: 56000,
    craftXp: 840,
    value: 415000,
    tradeMinMaxValue:
      '105000,130000,210000,310000,520000;5200000,6500000,10500000,15500000,26000000',
    favor: 1589,
    time: 22200,
    atk: 380,
    def: 95,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 259,
    resource2: 'ironwood',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'ectoplasm',
    c1Qty: 4,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-4',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 450,
    surcharge: 995,
    suggest: 200,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-05-05',
    shardPrice: 200,
    capriceDelay: 0,
  },
  crescentaxe: {
    uid: 'crescentaxe',
    level: 30,
    type: 'wa',
    xp: 65000,
    craftXp: 975,
    value: 645000,
    tradeMinMaxValue:
      '160000,200000,320000,480000,810000;8050000,10000000,16000000,24000000,40500000',
    favor: 1909,
    time: 32400,
    atk: 420,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 290,
    resource2: 'ironwood',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'deepcoral',
    c1Qty: 5,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:manticoreaxe',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 830,
    surcharge: 1845,
    suggest: 370,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  templeaxe: {
    uid: 'templeaxe',
    level: 31,
    type: 'wa',
    xp: 69500,
    craftXp: 1043,
    value: 680000,
    tradeMinMaxValue:
      '175000,220000,350000,530000,890000;8850000,11000000,17500000,26500000,44500000',
    favor: 2451,
    time: 38400,
    atk: 440,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 306,
    resource2: 'ironwood',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: 'overgrownvine',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'primal',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 850,
    surcharge: 1890,
    suggest: 380,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'temple',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldaxe: {
    uid: 'goldaxe',
    level: 32,
    type: 'wa',
    xp: 74500,
    craftXp: 1118,
    value: 1650000,
    tradeMinMaxValue:
      '410000,520000,830000,1250000,2050000;20500000,26000000,41500000,62000000,103000000',
    favor: 2831,
    time: 46200,
    atk: 460,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 26,
    w2BuildingReq: 22,
    w3BuildingReq: 19,
    resource1: 'wood',
    r1Qty: 322,
    resource2: 'ironwood',
    r2Qty: 93,
    resource3: 'gems',
    r3Qty: 29,
    component1: 'tabletjewel',
    c1Qty: 5,
    c1Tags: null,
    component2: 'timecrystal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-64',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 975,
    surcharge: 2170,
    suggest: 435,
    speedup: 2635,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-01-23',
    shardPrice: 0,
    capriceDelay: 0,
  },
  coupleaxe: {
    uid: 'coupleaxe',
    level: 32,
    type: 'wa',
    xp: 74500,
    craftXp: 1118,
    value: 685000,
    tradeMinMaxValue:
      '170000,210000,340000,510000,860000;8550000,10500000,17000000,25500000,43000000',
    favor: 2831,
    time: 46200,
    atk: 460,
    def: 0,
    hp: 14,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 26,
    w2BuildingReq: 22,
    w3BuildingReq: 19,
    resource1: 'wood',
    r1Qty: 322,
    resource2: 'ironwood',
    r2Qty: 93,
    resource3: 'gems',
    r3Qty: 29,
    component1: 'sirenshard',
    c1Qty: 3,
    c1Tags: null,
    component2: 'ghostflag',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-64',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'bear',
    tag: null,
    discount: 855,
    surcharge: 1900,
    suggest: 380,
    speedup: 2635,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-02-23',
    shardPrice: 250,
    capriceDelay: 0,
  },
  manticoreaxe: {
    uid: 'manticoreaxe',
    level: 33,
    type: 'wa',
    xp: 120000,
    craftXp: 1800,
    value: 1450000,
    tradeMinMaxValue:
      '360000,450000,730000,1100000,1800000;18000000,22500000,36500000,54500000,90500000',
    favor: 2889,
    time: 55200,
    atk: 540,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 338,
    resource2: 'ironwood',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12axe',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'chimera',
    tag: null,
    discount: 1060,
    surcharge: 2355,
    suggest: 470,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  beeaxe: {
    uid: 'beeaxe',
    level: 33,
    type: 'wa',
    xp: 120000,
    craftXp: 1800,
    value: 1700000,
    tradeMinMaxValue:
      '430000,530000,850000,1300000,2150000;21500000,26500000,42500000,64000000,106500000',
    favor: 4072,
    time: 55200,
    atk: 540,
    def: 135,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 338,
    resource2: 'ironwood',
    r2Qty: 98,
    resource3: 'oils',
    r3Qty: 73,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-22',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1145,
    surcharge: 2545,
    suggest: 510,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-06-01',
    shardPrice: 300,
    capriceDelay: 240,
  },
  fallaxe: {
    uid: 'fallaxe',
    level: 34,
    type: 'wa',
    xp: 130000,
    craftXp: 1950,
    value: 1800000,
    tradeMinMaxValue:
      '450000,560000,900000,1350000,2250000;22500000,28000000,45000000,67500000,112500000',
    favor: 3564,
    time: 66000,
    atk: 560,
    def: 0,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 354,
    resource2: 'ironwood',
    r2Qty: 103,
    resource3: 'mana',
    r3Qty: 29,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-71',
    upgrade3: 'resource2-21',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1175,
    surcharge: 2615,
    suggest: 525,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-10-05',
    shardPrice: 300,
    capriceDelay: 240,
  },
  aegiraxe: {
    uid: 'aegiraxe',
    level: 34,
    type: 'wa',
    xp: 130000,
    craftXp: 1950,
    value: 1850000,
    tradeMinMaxValue:
      '460000,580000,930000,1400000,2300000;23000000,29000000,46500000,69500000,115500000',
    favor: 3275,
    time: 66000,
    atk: 560,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 320,
    resource2: 'steel',
    r2Qty: 90,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-64',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-18',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1195,
    surcharge: 2650,
    suggest: 530,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-02-23',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12axe: {
    uid: 't12axe',
    level: 36,
    type: 'wa',
    xp: 240000,
    craftXp: 3600,
    value: 2500000,
    tradeMinMaxValue:
      '630000,780000,1250000,1900000,3150000;31500000,39000000,62500000,94000000,156500000',
    favor: 4293,
    time: 94200,
    atk: 690,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'wood',
    r1Qty: 387,
    resource2: 'ironwood',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13axe',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-12',
    supgrade2: 'resource2-34',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1375,
    surcharge: 3060,
    suggest: 610,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  chessaxe: {
    uid: 'chessaxe',
    level: 36,
    type: 'wa',
    xp: 240000,
    craftXp: 3600,
    value: 2750000,
    tradeMinMaxValue:
      '690000,860000,1400000,2050000,3450000;34500000,43000000,69000000,103000000,172000000',
    favor: 4544,
    time: 94200,
    atk: 690,
    def: 0,
    hp: 21,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 370,
    resource2: 'steel',
    r2Qty: 50,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'resource1-93',
    upgrade2: 'resource2-10',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-10',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1440,
    surcharge: 3205,
    suggest: 640,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-07-13',
    shardPrice: 350,
    capriceDelay: 0,
  },
  valentineaxe: {
    uid: 'valentineaxe',
    level: 37,
    type: 'wa',
    xp: 255000,
    craftXp: 3825,
    value: 2950000,
    tradeMinMaxValue:
      '740000,920000,1500000,2200000,3700000;37000000,46000000,74000000,110500000,184500000',
    favor: 6209,
    time: 112800,
    atk: 710,
    def: 178,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 450,
    resource2: 'steel',
    r2Qty: 70,
    resource3: 'gems',
    r3Qty: 46,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-135',
    upgrade3: 'resource2-21',
    upgrade4: 'resource3-14',
    upgrade5: 'value*1.25',
    upgradeBonus: 2,
    supgrade1: 'quality+1',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 1490,
    surcharge: 3315,
    suggest: 665,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-02-07',
    shardPrice: 350,
    capriceDelay: 0,
  },
  dinoaxe: {
    uid: 'dinoaxe',
    level: 37,
    type: 'wa',
    xp: 255000,
    craftXp: 3825,
    value: 3000000,
    tradeMinMaxValue:
      '750000,940000,1500000,2250000,3750000;37500000,47000000,75000000,112500000,187500000',
    favor: 4990,
    time: 112800,
    atk: 710,
    def: 0,
    hp: 22,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'wood',
    r1Qty: 384,
    resource2: 'ironwood',
    r2Qty: 105,
    resource3: 'mana',
    r3Qty: 39,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-96',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-21',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-12',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'dinosaur',
    tag: null,
    discount: 1505,
    surcharge: 3340,
    suggest: 670,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-03-21',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13axe: {
    uid: 't13axe',
    level: 39,
    type: 'wa',
    xp: 285000,
    craftXp: 4275,
    value: 5650000,
    tradeMinMaxValue:
      '1400000,1750000,2850000,4250000,7050000;70500000,88500000,141500000,212000000,353000000',
    favor: 6656,
    time: 160800,
    atk: 870,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'wood',
    r1Qty: 438,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcloth',
    c1Qty: 3,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-88',
    upgrade3: 'resource2-8',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 2040,
    surcharge: 4530,
    suggest: 905,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  firecrabaxe: {
    uid: 'firecrabaxe',
    level: 39,
    type: 'wa',
    xp: 285000,
    craftXp: 8550,
    value: 5950000,
    tradeMinMaxValue:
      '1500000,1850000,3000000,4450000,7450000;74500000,93000000,149000000,223000000,372000000',
    favor: 7498,
    time: 160800,
    atk: 870,
    def: 218,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'cook',
    worker2: 'blacksmith',
    worker3: 'master',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'herbs',
    r1Qty: 444,
    resource2: 'oils',
    r2Qty: 104,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 2,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.7',
    upgrade2: 'resource2-16',
    upgrade3: 'component1-1',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-89',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 2090,
    surcharge: 4640,
    suggest: 930,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-07-25',
    shardPrice: 375,
    capriceDelay: 0,
  },
  kitchenknife: {
    uid: 'kitchenknife',
    level: 1,
    type: 'wd',
    xp: 125,
    craftXp: 2,
    value: 45,
    tradeMinMaxValue: '15,15,30,40,70;700,800,1400,2000,3400',
    favor: 1,
    time: 10,
    atk: 14,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:dagger',
    upgrade3: 'resource1-1',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-1',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 10,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  forestdagger: {
    uid: 'forestdagger',
    level: 3,
    type: 'wd',
    xp: 385,
    craftXp: 6,
    value: 330,
    tradeMinMaxValue: '200,250,400,590,990;9900,12500,20000,29500,49500',
    favor: 9,
    time: 60,
    atk: 30,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 1,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 8,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'value*1.5',
    upgrade2: 'component1-1',
    upgrade3: 'resource1-2',
    upgrade4: 'quality+1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource1-2',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'bubble',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 15,
    surcharge: 30,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'forest',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  dagger: {
    uid: 'dagger',
    level: 3,
    type: 'wd',
    xp: 385,
    craftXp: 6,
    value: 260,
    tradeMinMaxValue: '80,100,160,230,390;3900,4900,7800,11500,19500',
    favor: 9,
    time: 60,
    atk: 30,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 8,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'bp:switchblade',
    upgrade2: 'component1-1',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-4',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 15,
    surcharge: 30,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  kunai: {
    uid: 'kunai',
    level: 5,
    type: 'wd',
    xp: 960,
    craftXp: 14,
    value: 660,
    tradeMinMaxValue: '170,210,330,500,830;8300,10500,16500,25000,41500',
    favor: 16,
    time: 130,
    atk: 50,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 2,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 13,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-7',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 45,
    suggest: 10,
    speedup: 70,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 50,
    capriceDelay: 240,
  },
  switchblade: {
    uid: 'switchblade',
    level: 6,
    type: 'wd',
    xp: 1400,
    craftXp: 21,
    value: 720,
    tradeMinMaxValue: '220,270,430,650,1100;11000,13500,21500,32500,54000',
    favor: 16,
    time: 180,
    atk: 60,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 14,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:butterflyknife',
    upgrade4: 'quality+1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 45,
    suggest: 10,
    speedup: 90,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  butterflyknife: {
    uid: 'butterflyknife',
    level: 10,
    type: 'wd',
    xp: 3950,
    craftXp: 59,
    value: 3650,
    tradeMinMaxValue: '910,1150,1850,2700,4600;45500,57000,91500,135000,230000',
    favor: 38,
    time: 560,
    atk: 108,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 7,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 27,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:kris',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 45,
    surcharge: 105,
    suggest: 20,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  schooldagger2020: {
    uid: 'schooldagger2020',
    level: 12,
    type: 'wd',
    xp: 5850,
    craftXp: 88,
    value: 6600,
    tradeMinMaxValue:
      '1650,2100,3300,5000,8300;82500,105000,165000,250000,415000',
    favor: 63,
    time: 900,
    atk: 136,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 37,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-7',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 60,
    surcharge: 135,
    suggest: 25,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-08-25',
    shardPrice: 100,
    capriceDelay: 240,
  },
  kris: {
    uid: 'kris',
    level: 13,
    type: 'wd',
    xp: 6950,
    craftXp: 104,
    value: 6950,
    tradeMinMaxValue:
      '1750,2200,3500,5200,8700;87000,110000,175000,260000,435000',
    favor: 72,
    time: 1140,
    atk: 148,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 44,
    resource2: 'steel',
    r2Qty: 9,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-9',
    upgrade3: 'bp:dirk',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 65,
    surcharge: 140,
    suggest: 30,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  atlab_boomerang: {
    uid: 'atlab_boomerang',
    level: 14,
    type: 'wd',
    xp: 8150,
    craftXp: 122,
    value: 11000,
    tradeMinMaxValue:
      '2800,3400,5500,8300,14000;140000,170000,275000,415000,690000',
    favor: 105,
    time: 1440,
    atk: 164,
    def: 41,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 54,
    resource2: 'steel',
    r2Qty: 11,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-11',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 80,
    surcharge: 175,
    suggest: 35,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-02',
    shardPrice: 120,
    capriceDelay: 0,
  },
  yamidagger: {
    uid: 'yamidagger',
    level: 15,
    type: 'wd',
    xp: 9400,
    craftXp: 141,
    value: 15000,
    tradeMinMaxValue:
      '3800,4700,7500,11500,19000;190000,235000,375000,565000,940000',
    favor: 133,
    time: 1800,
    atk: 176,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 64,
    resource2: 'steel',
    r2Qty: 15,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 3,
    c1Tags: null,
    component2: 'webbedwings',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-13',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 90,
    surcharge: 200,
    suggest: 40,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-12-27',
    shardPrice: 120,
    capriceDelay: 240,
  },
  dirk: {
    uid: 'dirk',
    level: 17,
    type: 'wd',
    xp: 12500,
    craftXp: 188,
    value: 20500,
    tradeMinMaxValue:
      '5100,6400,10500,15500,26000;255000,320000,515000,770000,1300000',
    favor: 191,
    time: 2700,
    atk: 210,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 1,
    w3BuildingReq: 4,
    resource1: 'iron',
    r1Qty: 89,
    resource2: 'steel',
    r2Qty: 22,
    resource3: null,
    r3Qty: 0,
    component1: 'sharpfang',
    c1Qty: 3,
    c1Tags: null,
    component2: 'arcanedust',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-18',
    upgrade3: 'bp:guttingknife',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-4',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 105,
    surcharge: 235,
    suggest: 45,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lunardagger: {
    uid: 'lunardagger',
    level: 18,
    type: 'wd',
    xp: 14000,
    craftXp: 210,
    value: 31500,
    tradeMinMaxValue:
      '7900,9800,16000,24000,39000;395000,490000,790000,1200000,1950000',
    favor: 259,
    time: 3300,
    atk: 220,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'iron',
    r1Qty: 101,
    resource2: 'steel',
    r2Qty: 26,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 3,
    c1Tags: null,
    component2: 'icypearl',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 130,
    surcharge: 290,
    suggest: 60,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-02-09',
    shardPrice: 140,
    capriceDelay: 240,
  },
  beachdagger: {
    uid: 'beachdagger',
    level: 19,
    type: 'wd',
    xp: 15500,
    craftXp: 233,
    value: 42000,
    tradeMinMaxValue:
      '10500,13000,21000,32000,53000;525000,655000,1050000,1600000,2650000',
    favor: 288,
    time: 4200,
    atk: 240,
    def: 60,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'cook',
    worker3: null,
    w1BuildingReq: 16,
    w2BuildingReq: 12,
    w3BuildingReq: 6,
    resource1: 'herbs',
    r1Qty: 106,
    resource2: 'oils',
    r2Qty: 16,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: 'darkscale',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'resource1-21',
    upgrade2: 'resource1-21',
    upgrade3: 'resource1-21',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 150,
    surcharge: 330,
    suggest: 65,
    speedup: 565,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-06-27',
    shardPrice: 140,
    capriceDelay: 0,
  },
  guttingknife: {
    uid: 'guttingknife',
    level: 21,
    type: 'wd',
    xp: 19500,
    craftXp: 293,
    value: 69500,
    tradeMinMaxValue:
      '17500,22000,35000,52000,87000;870000,1100000,1750000,2600000,4350000',
    favor: 395,
    time: 6600,
    atk: 270,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'iron',
    r1Qty: 140,
    resource2: 'steel',
    r2Qty: 35,
    resource3: null,
    r3Qty: 0,
    component1: 'switchblade',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'evileye',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:trolltooth',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 190,
    surcharge: 420,
    suggest: 85,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  junimaydagger: {
    uid: 'junimaydagger',
    level: 22,
    type: 'wd',
    xp: 21500,
    craftXp: 323,
    value: 71500,
    tradeMinMaxValue:
      '18000,22000,36000,54000,89000;895000,1100000,1800000,2700000,4450000',
    favor: 520,
    time: 7800,
    atk: 280,
    def: 0,
    hp: 9,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'iron',
    r1Qty: 153,
    resource2: 'herbs',
    r2Qty: 40,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 195,
    surcharge: 430,
    suggest: 85,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-04-04',
    shardPrice: 160,
    capriceDelay: 0,
  },
  trolltooth: {
    uid: 'trolltooth',
    level: 24,
    type: 'wd',
    xp: 26000,
    craftXp: 390,
    value: 170000,
    tradeMinMaxValue:
      '43000,53000,85000,130000,210000;2150000,2650000,4250000,6400000,10500000',
    favor: 622,
    time: 12000,
    atk: 320,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'iron',
    r1Qty: 179,
    resource2: 'steel',
    r2Qty: 46,
    resource3: null,
    r3Qty: 0,
    component1: 'overgrownvine',
    c1Qty: 5,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-36',
    upgrade3: 'bp:misericordia',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-9',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 295,
    surcharge: 650,
    suggest: 130,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ruinsdagger: {
    uid: 'ruinsdagger',
    level: 25,
    type: 'wd',
    xp: 28500,
    craftXp: 428,
    value: 205000,
    tradeMinMaxValue:
      '53000,66000,105000,160000,270000;2650000,3300000,5300000,8000000,13500000',
    favor: 805,
    time: 14400,
    atk: 330,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 8,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'iron',
    r1Qty: 192,
    resource2: 'steel',
    r2Qty: 51,
    resource3: null,
    r3Qty: 0,
    component1: 'ancientmarble',
    c1Qty: 5,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-38',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-10',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-4',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'wild',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'hydra',
    tag: 'elemental',
    discount: 320,
    surcharge: 710,
    suggest: 140,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'ruins',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  misericordia: {
    uid: 'misericordia',
    level: 27,
    type: 'wd',
    xp: 33500,
    craftXp: 503,
    value: 285000,
    tradeMinMaxValue:
      '71000,89000,145000,210000,360000;3550000,4450000,7150000,10500000,18000000',
    favor: 938,
    time: 15000,
    atk: 370,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'iron',
    r1Qty: 219,
    resource2: 'steel',
    r2Qty: 60,
    resource3: null,
    r3Qty: 0,
    component1: 'ectoplasm',
    c1Qty: 5,
    c1Tags: null,
    component2: 'ancientmarble',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-44',
    upgrade3: 'bp:katar',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-12',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 375,
    surcharge: 830,
    suggest: 165,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  sultandagger: {
    uid: 'sultandagger',
    level: 29,
    type: 'wd',
    xp: 39000,
    craftXp: 585,
    value: 410000,
    tradeMinMaxValue:
      '105000,130000,210000,310000,510000;5150000,6400000,10500000,15500000,25500000',
    favor: 1580,
    time: 22200,
    atk: 400,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'iron',
    r1Qty: 247,
    resource2: 'steel',
    r2Qty: 69,
    resource3: 'gems',
    r3Qty: 15,
    component1: 'ghostflag',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'time*0.7',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 445,
    surcharge: 990,
    suggest: 200,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-08-12',
    shardPrice: 200,
    capriceDelay: 240,
  },
  golddagger: {
    uid: 'golddagger',
    level: 29,
    type: 'wd',
    xp: 39000,
    craftXp: 585,
    value: 520000,
    tradeMinMaxValue:
      '130000,165000,260000,390000,650000;6500000,8150000,13000000,19500000,32500000',
    favor: 1380,
    time: 22200,
    atk: 400,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'iron',
    r1Qty: 247,
    resource2: 'steel',
    r2Qty: 69,
    resource3: null,
    r3Qty: 0,
    component1: 'tabletfragment',
    c1Qty: 5,
    c1Tags: null,
    component2: 'timecrystal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-14',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'luxuriousspirit',
    tag: null,
    discount: 375,
    surcharge: 835,
    suggest: 165,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-04-18',
    shardPrice: 0,
    capriceDelay: 0,
  },
  katar: {
    uid: 'katar',
    level: 30,
    type: 'wd',
    xp: 42000,
    craftXp: 630,
    value: 500000,
    tradeMinMaxValue:
      '125000,155000,250000,380000,630000;6250000,7800000,12500000,19000000,31500000',
    favor: 1386,
    time: 26400,
    atk: 420,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 261,
    resource2: 'steel',
    r2Qty: 74,
    resource3: null,
    r3Qty: 0,
    component1: 'goldshell',
    c1Qty: 5,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'bp:heartseeker',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-15',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 735,
    surcharge: 1630,
    suggest: 325,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 13,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  templedagger: {
    uid: 'templedagger',
    level: 31,
    type: 'wd',
    xp: 45000,
    craftXp: 675,
    value: 550000,
    tradeMinMaxValue:
      '145000,180000,290000,430000,720000;7250000,9050000,14500000,21500000,36000000',
    favor: 1777,
    time: 31800,
    atk: 440,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 275,
    resource2: 'steel',
    r2Qty: 79,
    resource3: null,
    r3Qty: 0,
    component1: 'sirenshard',
    c1Qty: 5,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-2',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-16',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'nightmare',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 770,
    surcharge: 1710,
    suggest: 340,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'temple',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  siadagger: {
    uid: 'siadagger',
    level: 31,
    type: 'wd',
    xp: 45000,
    craftXp: 675,
    value: 570000,
    tradeMinMaxValue:
      '145000,180000,290000,430000,710000;7150000,8900000,14500000,21500000,35500000',
    favor: 2091,
    time: 31800,
    atk: 440,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 275,
    resource2: 'steel',
    r2Qty: 79,
    resource3: 'gems',
    r3Qty: 20,
    component1: 'deepcoral',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-16',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 785,
    surcharge: 1740,
    suggest: 350,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-11-03',
    shardPrice: 250,
    capriceDelay: 0,
  },
  stpatrickdagger: {
    uid: 'stpatrickdagger',
    level: 32,
    type: 'wd',
    xp: 48500,
    craftXp: 728,
    value: 615000,
    tradeMinMaxValue:
      '155000,190000,310000,460000,770000;7700000,9600000,15500000,23000000,38500000',
    favor: 2018,
    time: 37800,
    atk: 460,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 26,
    w2BuildingReq: 22,
    w3BuildingReq: 19,
    resource1: 'iron',
    r1Qty: 290,
    resource2: 'steel',
    r2Qty: 83,
    resource3: null,
    r3Qty: 0,
    component1: 'goldshell',
    c1Qty: 5,
    c1Tags: null,
    component2: 'livingroots',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-58',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-17',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 810,
    surcharge: 1800,
    suggest: 360,
    speedup: 2635,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-11-27',
    shardPrice: 250,
    capriceDelay: 0,
  },
  heartseeker: {
    uid: 'heartseeker',
    level: 33,
    type: 'wd',
    xp: 77500,
    craftXp: 1163,
    value: 1200000,
    tradeMinMaxValue:
      '300000,380000,600000,900000,1500000;15000000,19000000,30000000,45000000,75000000',
    favor: 2005,
    time: 45000,
    atk: 540,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 304,
    resource2: 'steel',
    r2Qty: 88,
    resource3: null,
    r3Qty: 0,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-91',
    upgrade3: 'bp:t12dagger',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-18',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 970,
    surcharge: 2155,
    suggest: 430,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  peakdagger: {
    uid: 'peakdagger',
    level: 33,
    type: 'wd',
    xp: 77500,
    craftXp: 1163,
    value: 1300000,
    tradeMinMaxValue:
      '330000,420000,660000,1000000,1650000;16500000,21000000,33000000,50000000,83000000',
    favor: 2277,
    time: 45000,
    atk: 540,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 304,
    resource2: 'steel',
    r2Qty: 88,
    resource3: null,
    r3Qty: 0,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-18',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-91',
    supgrade2: 'resource2-18',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'tempest',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 1005,
    surcharge: 2235,
    suggest: 445,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'peak',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  halloweendagger: {
    uid: 'halloweendagger',
    level: 33,
    type: 'wd',
    xp: 77500,
    craftXp: 1163,
    value: 1500000,
    tradeMinMaxValue:
      '380000,470000,750000,1150000,1900000;19000000,23500000,37500000,56500000,94000000',
    favor: 2740,
    time: 45000,
    atk: 540,
    def: 135,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 304,
    resource2: 'steel',
    r2Qty: 66,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-91',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-13',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-13',
    supgrade2: 'resource3-12',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1080,
    surcharge: 2395,
    suggest: 480,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-10-19',
    shardPrice: 300,
    capriceDelay: 0,
  },
  atlab_kyoshifan: {
    uid: 'atlab_kyoshifan',
    level: 34,
    type: 'wd',
    xp: 82500,
    craftXp: 1238,
    value: 1600000,
    tradeMinMaxValue:
      '400000,500000,800000,1200000,2000000;20000000,25000000,40000000,60000000,100000000',
    favor: 2574,
    time: 54000,
    atk: 560,
    def: 0,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 319,
    resource2: 'steel',
    r2Qty: 93,
    resource3: null,
    r3Qty: 0,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-64',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1110,
    surcharge: 2470,
    suggest: 495,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-17',
    shardPrice: 300,
    capriceDelay: 0,
  },
  wiccandagger: {
    uid: 'wiccandagger',
    level: 34,
    type: 'wd',
    xp: 82500,
    craftXp: 1238,
    value: 1750000,
    tradeMinMaxValue:
      '440000,550000,880000,1300000,2200000;22000000,27500000,44000000,65500000,109500000',
    favor: 4550,
    time: 54000,
    atk: 560,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.10',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 319,
    resource2: 'wood',
    r2Qty: 89,
    resource3: 'steel',
    r3Qty: 93,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-64',
    upgrade4: 'resource3-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource2-45',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1160,
    surcharge: 2580,
    suggest: 515,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-03-08',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12dagger: {
    uid: 't12dagger',
    level: 36,
    type: 'wd',
    xp: 155000,
    craftXp: 2325,
    value: 2150000,
    tradeMinMaxValue:
      '540000,670000,1100000,1600000,2700000;27000000,33500000,54000000,80500000,134500000',
    favor: 2872,
    time: 77400,
    atk: 690,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 349,
    resource2: 'steel',
    r2Qty: 102,
    resource3: null,
    r3Qty: 0,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-105',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13dagger',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-20',
    supgrade2: 'resource2-31',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1285,
    surcharge: 2850,
    suggest: 570,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  golddagger2: {
    uid: 'golddagger2',
    level: 36,
    type: 'wd',
    xp: 155000,
    craftXp: 2325,
    value: 4300000,
    tradeMinMaxValue:
      '1100000,1350000,2150000,3250000,5400000;54000000,67000000,107500000,161500000,269000000',
    favor: 3270,
    time: 77400,
    atk: 690,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 349,
    resource2: 'steel',
    r2Qty: 102,
    resource3: null,
    r3Qty: 0,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-105',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-20',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1340,
    surcharge: 2980,
    suggest: 595,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-09-30',
    shardPrice: 0,
    capriceDelay: 0,
  },
  mushroomdagger: {
    uid: 'mushroomdagger',
    level: 37,
    type: 'wd',
    xp: 165000,
    craftXp: 2475,
    value: 2250000,
    tradeMinMaxValue:
      '560000,700000,1150000,1700000,2800000;28000000,35000000,56500000,84500000,140500000',
    favor: 4189,
    time: 92400,
    atk: 710,
    def: 178,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 364,
    resource2: 'steel',
    r2Qty: 111,
    resource3: 'gems',
    r3Qty: 20,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-109',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'resource2-17',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1310,
    surcharge: 2910,
    suggest: 580,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 13,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-05-18',
    shardPrice: 350,
    capriceDelay: 0,
  },
  lunardagger2: {
    uid: 'lunardagger2',
    level: 37,
    type: 'wd',
    xp: 165000,
    craftXp: 2475,
    value: 2600000,
    tradeMinMaxValue:
      '650000,810000,1300000,1950000,3250000;32500000,40500000,65000000,97500000,162500000',
    favor: 3621,
    time: 92400,
    atk: 710,
    def: 0,
    hp: 0,
    eva: '0.05',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 360,
    resource2: 'steel',
    r2Qty: 110,
    resource3: null,
    r3Qty: 0,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-72',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-22',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-22',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 1405,
    surcharge: 3120,
    suggest: 625,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-01-10',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13dagger: {
    uid: 't13dagger',
    level: 39,
    type: 'wd',
    xp: 185000,
    craftXp: 2775,
    value: 5400000,
    tradeMinMaxValue:
      '1350000,1700000,2700000,4050000,6750000;67500000,84500000,135000000,202500000,337500000',
    favor: 5944,
    time: 131400,
    atk: 870,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 394,
    resource2: 'gems',
    r2Qty: 42,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-118',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-8',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-8',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1995,
    surcharge: 4430,
    suggest: 885,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  club: {
    uid: 'club',
    level: 1,
    type: 'wm',
    xp: 135,
    craftXp: 2,
    value: 50,
    tradeMinMaxValue: '15,20,30,45,80;800,900,1500,2300,3800',
    favor: 1,
    time: 15,
    atk: 14,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:woodshield',
    upgrade2: 'bp:spikedclub',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-4',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 15,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  spikedclub: {
    uid: 'spikedclub',
    level: 3,
    type: 'wm',
    xp: 455,
    craftXp: 7,
    value: 220,
    tradeMinMaxValue: '70,80,130,200,330;3300,4100,6600,9900,16500',
    favor: 9,
    time: 50,
    atk: 30,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 8,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'club',
    c1Qty: 1,
    c1Tags: 'common',
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:hammer',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 25,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  forestmace: {
    uid: 'forestmace',
    level: 4,
    type: 'wm',
    xp: 765,
    craftXp: 11,
    value: 530,
    tradeMinMaxValue: '320,400,640,950,1600;16000,20000,32000,47500,79500',
    favor: 13,
    time: 100,
    atk: 40,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 2,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 11,
    resource2: 'herbs',
    r2Qty: 3,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'resource1-2',
    upgrade3: 'component1-1',
    upgrade4: 'time*0.75',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'corrupted',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 20,
    surcharge: 40,
    suggest: 10,
    speedup: 55,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: 'forest',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  hammer: {
    uid: 'hammer',
    level: 5,
    type: 'wm',
    xp: 1200,
    craftXp: 18,
    value: 630,
    tradeMinMaxValue: '190,240,380,570,950;9500,12000,19000,28500,47500',
    favor: 15,
    time: 140,
    atk: 50,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 13,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:morningstar',
    upgrade3: 'time*0.75',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 45,
    suggest: 10,
    speedup: 70,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  libertymace: {
    uid: 'libertymace',
    level: 6,
    type: 'wm',
    xp: 1700,
    craftXp: 26,
    value: 1000,
    tradeMinMaxValue: '300,380,600,900,1500;15000,19000,30000,45000,75000',
    favor: 18,
    time: 200,
    atk: 60,
    def: 0,
    hp: 2,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 14,
    resource2: 'iron',
    r2Qty: 4,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-3',
    upgrade4: 'resource2-2',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 55,
    suggest: 10,
    speedup: 90,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-06-27',
    shardPrice: 75,
    capriceDelay: 0,
  },
  morningstar: {
    uid: 'morningstar',
    level: 8,
    type: 'wm',
    xp: 3100,
    craftXp: 47,
    value: 2700,
    tradeMinMaxValue: '680,840,1350,2000,3400;34000,42000,67500,100000,170000',
    favor: 24,
    time: 360,
    atk: 84,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 5,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 19,
    resource2: 'iron',
    r2Qty: 5,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:heavymace',
    upgrade4: 'resource1-4',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 90,
    suggest: 20,
    speedup: 135,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 7,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  summermace: {
    uid: 'summermace',
    level: 9,
    type: 'wm',
    xp: 3950,
    craftXp: 59,
    value: 4600,
    tradeMinMaxValue:
      '1150,1450,2300,3500,5800;57500,72000,115000,175000,290000',
    favor: 34,
    time: 480,
    atk: 96,
    def: 0,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 23,
    resource2: 'iron',
    r2Qty: 6,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-5',
    upgrade3: 'time*0.75',
    upgrade4: 'resource2-2',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 50,
    surcharge: 115,
    suggest: 25,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-05-19',
    shardPrice: 100,
    capriceDelay: 240,
  },
  heavymace: {
    uid: 'heavymace',
    level: 12,
    type: 'wm',
    xp: 7300,
    craftXp: 110,
    value: 6200,
    tradeMinMaxValue:
      '1550,1950,3100,4700,7800;77500,97000,155000,235000,390000',
    favor: 57,
    time: 990,
    atk: 136,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 37,
    resource2: 'ironwood',
    r2Qty: 7,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 2,
    c1Tags: null,
    component2: 'redpinecone',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-7',
    upgrade3: 'bp:ancienthammer',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-4',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 60,
    surcharge: 130,
    suggest: 25,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  newyearmace: {
    uid: 'newyearmace',
    level: 14,
    type: 'wm',
    xp: 10000,
    craftXp: 150,
    value: 12000,
    tradeMinMaxValue:
      '3000,3800,6000,9000,15000;150000,190000,300000,450000,750000',
    favor: 105,
    time: 1590,
    atk: 164,
    def: 41,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 54,
    resource2: 'ironwood',
    r2Qty: 11,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-11',
    upgrade3: 'resource2-3',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 80,
    surcharge: 180,
    suggest: 35,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-12-28',
    shardPrice: 120,
    capriceDelay: 0,
  },
  ancienthammer: {
    uid: 'ancienthammer',
    level: 16,
    type: 'wm',
    xp: 13500,
    craftXp: 203,
    value: 21000,
    tradeMinMaxValue:
      '5300,6600,10500,16000,26000;265000,330000,525000,790000,1300000',
    favor: 154,
    time: 2400,
    atk: 192,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 8,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 77,
    resource2: 'ironwood',
    r2Qty: 19,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-15',
    upgrade3: 'bp:midnightstar',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 110,
    surcharge: 240,
    suggest: 50,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  troblinmace: {
    uid: 'troblinmace',
    level: 18,
    type: 'wm',
    xp: 17500,
    craftXp: 263,
    value: 40500,
    tradeMinMaxValue:
      '10000,12500,20000,30000,51000;505000,635000,1000000,1500000,2550000',
    favor: 408,
    time: 3600,
    atk: 220,
    def: 55,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'wood',
    r1Qty: 101,
    resource2: 'iron',
    r2Qty: 28,
    resource3: 'ironwood',
    r3Qty: 26,
    component1: 'club',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'sharpfang',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-21',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-14',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 145,
    surcharge: 325,
    suggest: 65,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-07-13',
    shardPrice: 140,
    capriceDelay: 0,
  },
  midnightstar: {
    uid: 'midnightstar',
    level: 20,
    type: 'wm',
    xp: 22000,
    craftXp: 330,
    value: 61500,
    tradeMinMaxValue:
      '15500,19000,31000,46000,77000;770000,960000,1550000,2300000,3850000',
    favor: 392,
    time: 5400,
    atk: 250,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'wood',
    r1Qty: 127,
    resource2: 'ironwood',
    r2Qty: 32,
    resource3: 'steel',
    r3Qty: 9,
    component1: 'morningstar',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'evileye',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-25',
    upgrade3: 'bp:powderkeg',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 180,
    surcharge: 400,
    suggest: 80,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pyramidmace: {
    uid: 'pyramidmace',
    level: 21,
    type: 'wm',
    xp: 24500,
    craftXp: 368,
    value: 84500,
    tradeMinMaxValue:
      '23000,29000,46000,70000,115000;1150000,1450000,2300000,3500000,5800000',
    favor: 444,
    time: 6600,
    atk: 270,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 7,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'wood',
    r1Qty: 140,
    resource2: 'ironwood',
    r2Qty: 35,
    resource3: null,
    r3Qty: 0,
    component1: 'morningstar',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'starmetal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'flaming',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 210,
    surcharge: 465,
    suggest: 95,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'pyramid',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  arcademace: {
    uid: 'arcademace',
    level: 22,
    type: 'wm',
    xp: 27000,
    craftXp: 405,
    value: 105000,
    tradeMinMaxValue:
      '26000,33000,53000,79000,130000;1300000,1650000,2650000,3950000,6550000',
    favor: 426,
    time: 8400,
    atk: 280,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.10',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'iron',
    r1Qty: 130,
    resource2: 'steel',
    r2Qty: 32,
    resource3: null,
    r3Qty: 0,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-26',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 230,
    surcharge: 515,
    suggest: 105,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-09-20',
    shardPrice: 160,
    capriceDelay: 0,
  },
  powderkeg: {
    uid: 'powderkeg',
    level: 25,
    type: 'wm',
    xp: 35500,
    craftXp: 533,
    value: 185000,
    tradeMinMaxValue:
      '46000,58000,93000,140000,230000;2300000,2900000,4650000,6950000,11500000',
    favor: 771,
    time: 16200,
    atk: 330,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'wood',
    r1Qty: 192,
    resource2: 'ironwood',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 6,
    component1: 'overgrownvine',
    c1Qty: 5,
    c1Tags: null,
    component2: 'livingroots',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-38',
    upgrade3: 'bp:tenderizer',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-8',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 305,
    surcharge: 675,
    suggest: 135,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pumpkinmace: {
    uid: 'pumpkinmace',
    level: 26,
    type: 'wm',
    xp: 39000,
    craftXp: 585,
    value: 240000,
    tradeMinMaxValue:
      '60000,75000,120000,180000,300000;3000000,3750000,6000000,9000000,15000000',
    favor: 1011,
    time: 19200,
    atk: 350,
    def: 0,
    hp: 11,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 13,
    resource1: 'wood',
    r1Qty: 206,
    resource2: 'ironwood',
    r2Qty: 56,
    resource3: 'mana',
    r3Qty: 8,
    component1: 'overgrownvine',
    c1Qty: 4,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-41',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-28',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 345,
    surcharge: 765,
    suggest: 155,
    speedup: 995,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-10-28',
    shardPrice: 180,
    capriceDelay: 0,
  },
  candyhammer: {
    uid: 'candyhammer',
    level: 26,
    type: 'wm',
    xp: 39000,
    craftXp: 585,
    value: 260000,
    tradeMinMaxValue:
      '65000,81000,130000,195000,330000;3250000,4050000,6500000,9750000,16500000',
    favor: 1011,
    time: 19200,
    atk: 350,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 8,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 13,
    resource1: 'wood',
    r1Qty: 206,
    resource2: 'ironwood',
    r2Qty: 56,
    resource3: 'mana',
    r3Qty: 8,
    component1: 'ancientmarble',
    c1Qty: 5,
    c1Tags: null,
    component2: 'icypearl',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-41',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-5',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'santa',
    tag: null,
    discount: 360,
    surcharge: 795,
    suggest: 160,
    speedup: 995,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-12-09',
    shardPrice: 180,
    capriceDelay: 0,
  },
  tenderizer: {
    uid: 'tenderizer',
    level: 27,
    type: 'wm',
    xp: 42000,
    craftXp: 630,
    value: 290000,
    tradeMinMaxValue:
      '73000,91000,145000,220000,360000;3650000,4550000,7250000,11000000,18000000',
    favor: 1048,
    time: 16800,
    atk: 370,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'wood',
    r1Qty: 219,
    resource2: 'ironwood',
    r2Qty: 60,
    resource3: 'mana',
    r3Qty: 10,
    component1: 'ghostflag',
    c1Qty: 4,
    c1Tags: null,
    component2: 'darkscale',
    c2Qty: 10,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-44',
    upgrade3: 'bp:nautilus',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-5',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 375,
    surcharge: 835,
    suggest: 165,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  smithhammer: {
    uid: 'smithhammer',
    level: 28,
    type: 'wm',
    xp: 45500,
    craftXp: 683,
    value: 410000,
    tradeMinMaxValue:
      '105000,130000,210000,310000,510000;5150000,6400000,10500000,15500000,25500000',
    favor: 1358,
    time: 20400,
    atk: 380,
    def: 0,
    hp: 12,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 233,
    resource2: 'ironwood',
    r2Qty: 65,
    resource3: 'mana',
    r3Qty: 12,
    component1: 'sewingthread',
    c1Qty: 3,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-47',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-13',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-5',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 445,
    surcharge: 990,
    suggest: 200,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-06-18',
    shardPrice: 200,
    capriceDelay: 240,
  },
  freedommace: {
    uid: 'freedommace',
    level: 29,
    type: 'wm',
    xp: 49000,
    craftXp: 735,
    value: 420000,
    tradeMinMaxValue:
      '105000,130000,210000,320000,530000;5250000,6550000,10500000,16000000,26500000',
    favor: 1566,
    time: 24600,
    atk: 400,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'wood',
    r1Qty: 247,
    resource2: 'ironwood',
    r2Qty: 69,
    resource3: 'gems',
    r3Qty: 14,
    component1: 'ectoplasm',
    c1Qty: 5,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-6',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 450,
    surcharge: 1000,
    suggest: 200,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2020-07-01',
    shardPrice: 200,
    capriceDelay: 0,
  },
  nautilus: {
    uid: 'nautilus',
    level: 30,
    type: 'wm',
    xp: 53000,
    craftXp: 795,
    value: 630000,
    tradeMinMaxValue:
      '160000,195000,320000,470000,790000;7900000,9850000,16000000,23500000,39500000',
    favor: 1625,
    time: 29400,
    atk: 420,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 261,
    resource2: 'ironwood',
    r2Qty: 74,
    resource3: 'mana',
    r3Qty: 17,
    component1: 'deepcoral',
    c1Qty: 5,
    c1Tags: null,
    component2: 'ancientmarble',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-52',
    upgrade3: 'bp:emeraldhammer',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 820,
    surcharge: 1825,
    suggest: 365,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldhammer: {
    uid: 'goldhammer',
    level: 30,
    type: 'wm',
    xp: 53000,
    craftXp: 795,
    value: 1300000,
    tradeMinMaxValue:
      '330000,410000,650000,980000,1650000;16500000,20500000,32500000,49000000,81500000',
    favor: 1814,
    time: 29400,
    atk: 420,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 261,
    resource2: 'ironwood',
    r2Qty: 74,
    resource3: 'mana',
    r3Qty: 17,
    component1: 'tabletjewel',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'time*0.7',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 870,
    surcharge: 1935,
    suggest: 385,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-10-31',
    shardPrice: 0,
    capriceDelay: 0,
  },
  mundrahammer: {
    uid: 'mundrahammer',
    level: 31,
    type: 'wm',
    xp: 56500,
    craftXp: 1696,
    value: 670000,
    tradeMinMaxValue:
      '175000,220000,350000,520000,870000;8700000,11000000,17500000,26000000,43500000',
    favor: 2075,
    time: 34800,
    atk: 440,
    def: 0,
    hp: 14,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'moondragon',
    worker2: 'carpenter',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 275,
    resource2: 'ironwood',
    r2Qty: 79,
    resource3: 'mana',
    r3Qty: 19,
    component1: 'deepcoral',
    c1Qty: 4,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-55',
    upgrade3: 'bp:mundrapotion',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: 'dragon',
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 845,
    surcharge: 1880,
    suggest: 375,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  emeraldhammer: {
    uid: 'emeraldhammer',
    level: 33,
    type: 'wm',
    xp: 97500,
    craftXp: 1463,
    value: 1350000,
    tradeMinMaxValue:
      '340000,420000,680000,1000000,1700000;17000000,21000000,34000000,50500000,84500000',
    favor: 2419,
    time: 50400,
    atk: 540,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 304,
    resource2: 'ironwood',
    r2Qty: 88,
    resource3: 'mana',
    r3Qty: 23,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'bp:t12mace',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1025,
    surcharge: 2280,
    suggest: 455,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  peakhammer: {
    uid: 'peakhammer',
    level: 33,
    type: 'wm',
    xp: 97500,
    craftXp: 1463,
    value: 1550000,
    tradeMinMaxValue:
      '390000,490000,790000,1200000,1950000;19500000,24500000,39500000,59000000,98500000',
    favor: 2702,
    time: 50400,
    atk: 540,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 304,
    resource2: 'ironwood',
    r2Qty: 88,
    resource3: 'mana',
    r3Qty: 23,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-18',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'sacred',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 1095,
    surcharge: 2435,
    suggest: 485,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'peak',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  naturehammer: {
    uid: 'naturehammer',
    level: 34,
    type: 'wm',
    xp: 105000,
    craftXp: 1575,
    value: 1700000,
    tradeMinMaxValue:
      '430000,530000,850000,1300000,2150000;21500000,26500000,42500000,64000000,106500000',
    favor: 3077,
    time: 60000,
    atk: 560,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 319,
    resource2: 'ironwood',
    r2Qty: 93,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'deepcoral',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-64',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1145,
    surcharge: 2545,
    suggest: 510,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-04-20',
    shardPrice: 300,
    capriceDelay: 0,
  },
  atlab_mace: {
    uid: 'atlab_mace',
    level: 34,
    type: 'wm',
    xp: 105000,
    craftXp: 1575,
    value: 1800000,
    tradeMinMaxValue:
      '450000,560000,900000,1350000,2250000;22500000,28000000,45000000,67500000,112500000',
    favor: 3077,
    time: 60000,
    atk: 560,
    def: 0,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 319,
    resource2: 'ironwood',
    r2Qty: 93,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-64',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-10',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1175,
    surcharge: 2615,
    suggest: 525,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-16',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12mace: {
    uid: 't12mace',
    level: 37,
    type: 'wm',
    xp: 210000,
    craftXp: 3150,
    value: 2500000,
    tradeMinMaxValue:
      '630000,780000,1250000,1900000,3150000;31500000,39000000,62500000,94000000,156500000',
    favor: 4080,
    time: 102600,
    atk: 710,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'wood',
    r1Qty: 364,
    resource2: 'ironwood',
    r2Qty: 111,
    resource3: 'mana',
    r3Qty: 34,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-73',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13mace',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'resource2-33',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 1375,
    surcharge: 3060,
    suggest: 610,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  eastermace: {
    uid: 'eastermace',
    level: 37,
    type: 'wm',
    xp: 210000,
    craftXp: 3150,
    value: 2750000,
    tradeMinMaxValue:
      '690000,860000,1400000,2050000,3450000;34500000,43000000,69000000,103000000,172000000',
    favor: 4354,
    time: 102600,
    atk: 710,
    def: 178,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 350,
    resource2: 'steel',
    r2Qty: 100,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-70',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-7',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1440,
    surcharge: 3205,
    suggest: 640,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-04-05',
    shardPrice: 350,
    capriceDelay: 0,
  },
  christmasmace: {
    uid: 'christmasmace',
    level: 38,
    type: 'wm',
    xp: 220000,
    craftXp: 3300,
    value: 2900000,
    tradeMinMaxValue:
      '730000,910000,1450000,2200000,3650000;36500000,45500000,72500000,109000000,181500000',
    favor: 5136,
    time: 122400,
    atk: 730,
    def: 183,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'carpenter',
    worker3: 'priest',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'iron',
    r1Qty: 375,
    resource2: 'steel',
    r2Qty: 110,
    resource3: 'mana',
    r3Qty: 38,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'component2-1',
    upgrade4: 'multi+0.1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-94',
    supgrade2: 'resource2-28',
    supgrade3: 'resource3-10',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: 'santa',
    tag: null,
    discount: 1480,
    surcharge: 3290,
    suggest: 660,
    speedup: 3645,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-12-13',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13mace: {
    uid: 't13mace',
    level: 39,
    type: 'wm',
    xp: 235000,
    craftXp: 3525,
    value: 5550000,
    tradeMinMaxValue:
      '1400000,1750000,2800000,4150000,6950000;69500000,86500000,139000000,208000000,347000000',
    favor: 6140,
    time: 146400,
    atk: 870,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'ironwood',
    r1Qty: 125,
    resource2: 'mana',
    r2Qty: 39,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'mightsigil',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-6',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'resource1-25',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 2020,
    surcharge: 4490,
    suggest: 900,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  musicmace: {
    uid: 'musicmace',
    level: 39,
    type: 'wm',
    xp: 235000,
    craftXp: 3525,
    value: 5850000,
    tradeMinMaxValue:
      '1450000,1850000,2950000,4400000,7300000;73000000,91500000,146500000,219500000,365500000',
    favor: 5700,
    time: 146400,
    atk: 870,
    def: 0,
    hp: 27,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'ironwood',
    r1Qty: 113,
    resource2: 'mana',
    r2Qty: 32,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-5',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-23',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 2070,
    surcharge: 4605,
    suggest: 920,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-02',
    shardPrice: 375,
    capriceDelay: 0,
  },
  platinummace: {
    uid: 'platinummace',
    level: 39,
    type: 'wm',
    xp: 235000,
    craftXp: 3525,
    value: 14350000,
    tradeMinMaxValue:
      '3600000,4500000,7200000,10750000,17950000;179500000,224000000,359000000,538000000,897000000',
    favor: 5823,
    time: 146400,
    atk: 870,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'wood',
    r1Qty: 394,
    resource2: 'ironwood',
    r2Qty: 125,
    resource3: 'mana',
    r3Qty: 39,
    component1: 'platinumjewel',
    c1Qty: 4,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-79',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'time*0.7',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 2390,
    surcharge: 5315,
    suggest: 1065,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 1,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'goldcity3',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-06-09',
    shardPrice: 0,
    capriceDelay: 0,
  },
  woodspear: {
    uid: 'woodspear',
    level: 3,
    type: 'wp',
    xp: 520,
    craftXp: 8,
    value: 190,
    tradeMinMaxValue: '60,70,110,170,290;2900,3600,5700,8600,14500',
    favor: 10,
    time: 70,
    atk: 30,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:ironspear',
    upgrade3: 'resource1-2',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.5',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 25,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ironspear: {
    uid: 'ironspear',
    level: 5,
    type: 'wp',
    xp: 1400,
    craftXp: 21,
    value: 520,
    tradeMinMaxValue: '160,200,310,470,780;7800,9800,15500,23500,39000',
    favor: 16,
    time: 160,
    atk: 50,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 14,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'resource1-3',
    upgrade3: 'bp:bladedspear',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 40,
    suggest: 10,
    speedup: 70,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  bladedspear: {
    uid: 'bladedspear',
    level: 8,
    type: 'wp',
    xp: 3650,
    craftXp: 55,
    value: 1400,
    tradeMinMaxValue: '420,530,840,1250,2100;21000,26500,42000,63000,105000',
    favor: 27,
    time: 390,
    atk: 84,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 5,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 21,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:ranseur',
    upgrade4: 'resource1-4',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 30,
    surcharge: 65,
    suggest: 15,
    speedup: 135,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pitchfork: {
    uid: 'pitchfork',
    level: 9,
    type: 'wp',
    xp: 4650,
    craftXp: 70,
    value: 2400,
    tradeMinMaxValue: '600,750,1200,1800,3000;30000,37500,60000,90000,150000',
    favor: 39,
    time: 520,
    atk: 96,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 26,
    resource2: 'iron',
    r2Qty: 13,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.8',
    upgrade3: 'resource1-5',
    upgrade4: 'time*0.8',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource2-13',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 85,
    suggest: 15,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-09-24',
    shardPrice: 75,
    capriceDelay: 240,
  },
  ranseur: {
    uid: 'ranseur',
    level: 11,
    type: 'wp',
    xp: 7150,
    craftXp: 107,
    value: 4850,
    tradeMinMaxValue:
      '1200,1500,2400,3600,6100;60500,76000,120000,180000,305000',
    favor: 50,
    time: 750,
    atk: 124,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 8,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 34,
    resource2: 'iron',
    r2Qty: 17,
    resource3: null,
    r3Qty: 0,
    component1: 'ironspear',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'arcanedust',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:trident',
    upgrade4: 'resource1-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-3',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 55,
    surcharge: 120,
    suggest: 25,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  trident: {
    uid: 'trident',
    level: 14,
    type: 'wp',
    xp: 12000,
    craftXp: 180,
    value: 11500,
    tradeMinMaxValue:
      '2900,3600,5800,8600,14500;145000,180000,290000,430000,720000',
    favor: 106,
    time: 1710,
    atk: 164,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 60,
    resource2: 'ironwood',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'bp:pike',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 80,
    surcharge: 180,
    suggest: 35,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  easterspear: {
    uid: 'easterspear',
    level: 15,
    type: 'wp',
    xp: 14000,
    craftXp: 210,
    value: 16000,
    tradeMinMaxValue:
      '4000,5000,8000,12000,20000;200000,250000,400000,600000,1000000',
    favor: 150,
    time: 2160,
    atk: 176,
    def: 44,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 71,
    resource2: 'herbs',
    r2Qty: 18,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 6,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-14',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: 'rabbitspirit',
    tag: null,
    discount: 95,
    surcharge: 210,
    suggest: 40,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-04-05',
    shardPrice: 120,
    capriceDelay: 0,
  },
  heavenlyhalberd: {
    uid: 'heavenlyhalberd',
    level: 16,
    type: 'wp',
    xp: 16000,
    craftXp: 240,
    value: 23500,
    tradeMinMaxValue:
      '5900,7300,12000,17500,29000;295000,365000,590000,880000,1450000',
    favor: 297,
    time: 2700,
    atk: 192,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 8,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 85,
    resource2: 'ironwood',
    r2Qty: 21,
    resource3: 'leather',
    r3Qty: 21,
    component1: 'livingroots',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-17',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-4',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-21',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 250,
    suggest: 50,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-01-22',
    shardPrice: 120,
    capriceDelay: 0,
  },
  pike: {
    uid: 'pike',
    level: 17,
    type: 'wp',
    xp: 18500,
    craftXp: 278,
    value: 29000,
    tradeMinMaxValue:
      '7300,9100,14500,22000,36000;365000,455000,725000,1100000,1800000',
    favor: 216,
    time: 3300,
    atk: 210,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'wood',
    r1Qty: 99,
    resource2: 'ironwood',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'sharpfang',
    c1Qty: 4,
    c1Tags: null,
    component2: 'redpinecone',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:partisan',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 125,
    surcharge: 275,
    suggest: 55,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 16,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  desertspear: {
    uid: 'desertspear',
    level: 18,
    type: 'wp',
    xp: 20500,
    craftXp: 308,
    value: 34500,
    tradeMinMaxValue:
      '10500,13500,21000,32000,53000;530000,665000,1050000,1600000,2650000',
    favor: 296,
    time: 3600,
    atk: 220,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 6,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'wood',
    r1Qty: 113,
    resource2: 'ironwood',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'smallmanapotion',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'mooncrystal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-3',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'tidal',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 135,
    surcharge: 300,
    suggest: 60,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: 'desert',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  celticspear: {
    uid: 'celticspear',
    level: 19,
    type: 'wp',
    xp: 23000,
    craftXp: 345,
    value: 54000,
    tradeMinMaxValue:
      '13500,17000,27000,41000,68000;675000,845000,1350000,2050000,3400000',
    favor: 559,
    time: 4800,
    atk: 240,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 16,
    w2BuildingReq: 12,
    w3BuildingReq: 6,
    resource1: 'wood',
    r1Qty: 127,
    resource2: 'ironwood',
    r2Qty: 33,
    resource3: 'leather',
    r3Qty: 32,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: 'livingroots',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-25',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-32',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 170,
    surcharge: 375,
    suggest: 75,
    speedup: 565,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-03-09',
    shardPrice: 140,
    capriceDelay: 240,
  },
  partisan: {
    uid: 'partisan',
    level: 21,
    type: 'wp',
    xp: 29000,
    craftXp: 435,
    value: 90000,
    tradeMinMaxValue:
      '23000,28000,45000,68000,115000;1150000,1400000,2250000,3400000,5650000',
    favor: 518,
    time: 7200,
    atk: 270,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'wood',
    r1Qty: 155,
    resource2: 'ironwood',
    r2Qty: 39,
    resource3: 'oils',
    r3Qty: 10,
    component1: 'ironspear',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'starmetal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'bp:championlance',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 215,
    surcharge: 480,
    suggest: 95,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  yetispear: {
    uid: 'yetispear',
    level: 22,
    type: 'wp',
    xp: 32000,
    craftXp: 480,
    value: 120000,
    tradeMinMaxValue:
      '30000,38000,60000,90000,150000;1500000,1900000,3000000,4500000,7500000',
    favor: 591,
    time: 9600,
    atk: 280,
    def: 70,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'wood',
    r1Qty: 170,
    resource2: 'ironwood',
    r2Qty: 43,
    resource3: null,
    r3Qty: 0,
    component1: 'evileye',
    c1Qty: 4,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'resource2-9',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-9',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 250,
    surcharge: 550,
    suggest: 110,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-08-10',
    shardPrice: 160,
    capriceDelay: 240,
  },
  africanspear: {
    uid: 'africanspear',
    level: 22,
    type: 'wp',
    xp: 32000,
    craftXp: 480,
    value: 130000,
    tradeMinMaxValue:
      '33000,41000,65000,98000,165000;1650000,2050000,3250000,4900000,8150000',
    favor: 591,
    time: 9600,
    atk: 280,
    def: 0,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'wood',
    r1Qty: 170,
    resource2: 'ironwood',
    r2Qty: 43,
    resource3: null,
    r3Qty: 0,
    component1: 'starmetal',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-13',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'resource2-13',
    supgrade3: 'multi+0.1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 255,
    surcharge: 570,
    suggest: 115,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-01-24',
    shardPrice: 160,
    capriceDelay: 0,
  },
  championlance: {
    uid: 'championlance',
    level: 24,
    type: 'wp',
    xp: 38500,
    craftXp: 578,
    value: 180000,
    tradeMinMaxValue:
      '45000,56000,90000,135000,230000;2250000,2800000,4500000,6750000,11500000',
    favor: 771,
    time: 13800,
    atk: 320,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'wood',
    r1Qty: 199,
    resource2: 'ironwood',
    r2Qty: 51,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'timecrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:wyvernglaive',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-5',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 300,
    surcharge: 665,
    suggest: 135,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  fallspear: {
    uid: 'fallspear',
    level: 25,
    type: 'wp',
    xp: 42000,
    craftXp: 630,
    value: 205000,
    tradeMinMaxValue:
      '51000,64000,105000,155000,260000;2550000,3200000,5150000,7700000,13000000',
    favor: 1001,
    time: 17400,
    atk: 330,
    def: 0,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'wood',
    r1Qty: 214,
    resource2: 'ironwood',
    r2Qty: 57,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'overgrownvine',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'component1-1',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource3-8',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 320,
    surcharge: 710,
    suggest: 140,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-09-06',
    shardPrice: 180,
    capriceDelay: 0,
  },
  wyvernglaive: {
    uid: 'wyvernglaive',
    level: 27,
    type: 'wp',
    xp: 49500,
    craftXp: 743,
    value: 290000,
    tradeMinMaxValue:
      '73000,91000,145000,220000,360000;3650000,4550000,7250000,11000000,18000000',
    favor: 1224,
    time: 18000,
    atk: 370,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'wood',
    r1Qty: 244,
    resource2: 'ironwood',
    r2Qty: 67,
    resource3: 'gems',
    r3Qty: 13,
    component1: 'cutlass',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'ghostflag',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:stellarspear',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'cquality1-1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 375,
    surcharge: 835,
    suggest: 165,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  sylvanspear: {
    uid: 'sylvanspear',
    level: 28,
    type: 'wp',
    xp: 54000,
    craftXp: 810,
    value: 420000,
    tradeMinMaxValue:
      '105000,130000,210000,320000,530000;5250000,6550000,10500000,16000000,26500000',
    favor: 1589,
    time: 22200,
    atk: 380,
    def: 0,
    hp: 12,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 259,
    resource2: 'ironwood',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 16,
    component1: 'ancientmarble',
    c1Qty: 4,
    c1Tags: null,
    component2: 'overgrownvine',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'walrus',
    tag: null,
    discount: 450,
    surcharge: 1000,
    suggest: 200,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-07-14',
    shardPrice: 200,
    capriceDelay: 240,
  },
  goldspear: {
    uid: 'goldspear',
    level: 28,
    type: 'wp',
    xp: 54000,
    craftXp: 810,
    value: 600000,
    tradeMinMaxValue:
      '150000,190000,300000,450000,750000;7500000,9400000,15000000,22500000,37500000',
    favor: 1589,
    time: 22200,
    atk: 380,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 259,
    resource2: 'ironwood',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'tabletfragment',
    c1Qty: 5,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'luxuriousspirit',
    tag: null,
    discount: 400,
    surcharge: 890,
    suggest: 180,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-06-13',
    shardPrice: 0,
    capriceDelay: 0,
  },
  stellarspear: {
    uid: 'stellarspear',
    level: 30,
    type: 'wp',
    xp: 62500,
    craftXp: 938,
    value: 605000,
    tradeMinMaxValue:
      '150000,190000,300000,450000,760000;7550000,9450000,15000000,22500000,38000000',
    favor: 1909,
    time: 31800,
    atk: 420,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 290,
    resource2: 'ironwood',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'sirenshard',
    c1Qty: 4,
    c1Tags: null,
    component2: 'timecrystal',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:feyspear',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 805,
    surcharge: 1790,
    suggest: 360,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  templespear: {
    uid: 'templespear',
    level: 30,
    type: 'wp',
    xp: 62500,
    craftXp: 938,
    value: 655000,
    tradeMinMaxValue:
      '170000,210000,340000,510000,850000;8550000,10500000,17000000,25500000,42500000',
    favor: 2128,
    time: 31200,
    atk: 420,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 290,
    resource2: 'ironwood',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'sunpendant',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'goldshell',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-16',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'sacred',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 835,
    surcharge: 1855,
    suggest: 370,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: 'temple',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  thanksgivingspear: {
    uid: 'thanksgivingspear',
    level: 31,
    type: 'wp',
    xp: 67000,
    craftXp: 1005,
    value: 675000,
    tradeMinMaxValue:
      '170000,210000,340000,510000,840000;8450000,10500000,17000000,25500000,42000000',
    favor: 2451,
    time: 37800,
    atk: 440,
    def: 110,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 306,
    resource2: 'ironwood',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 850,
    surcharge: 1885,
    suggest: 375,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-11-18',
    shardPrice: 250,
    capriceDelay: 240,
  },
  feyspear: {
    uid: 'feyspear',
    level: 33,
    type: 'wp',
    xp: 115000,
    craftXp: 1725,
    value: 1200000,
    tradeMinMaxValue:
      '300000,380000,600000,900000,1500000;15000000,19000000,30000000,45000000,75000000',
    favor: 2889,
    time: 54000,
    atk: 540,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 338,
    resource2: 'ironwood',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12spear',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 970,
    surcharge: 2155,
    suggest: 430,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  circusspear: {
    uid: 'circusspear',
    level: 33,
    type: 'wp',
    xp: 115000,
    craftXp: 1725,
    value: 1600000,
    tradeMinMaxValue:
      '400000,500000,800000,1200000,2000000;20000000,25000000,40000000,60000000,100000000',
    favor: 3218,
    time: 54000,
    atk: 540,
    def: 0,
    hp: 17,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 338,
    resource2: 'ironwood',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'ghostflag',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1110,
    surcharge: 2470,
    suggest: 495,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-06-16',
    shardPrice: 300,
    capriceDelay: 0,
  },
  christmasspear: {
    uid: 'christmasspear',
    level: 34,
    type: 'wp',
    xp: 125000,
    craftXp: 1875,
    value: 1800000,
    tradeMinMaxValue:
      '450000,560000,900000,1350000,2250000;22500000,28000000,45000000,67500000,112500000',
    favor: 3671,
    time: 64800,
    atk: 560,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 354,
    resource2: 'ironwood',
    r2Qty: 103,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-71',
    upgrade3: 'time*0.8',
    upgrade4: 'resource2-31',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-10',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: 'santa',
    tag: null,
    discount: 1175,
    surcharge: 2615,
    suggest: 525,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-12-14',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12spear: {
    uid: 't12spear',
    level: 36,
    type: 'wp',
    xp: 230000,
    craftXp: 3450,
    value: 2500000,
    tradeMinMaxValue:
      '630000,780000,1250000,1900000,3150000;31500000,39000000,62500000,94000000,156500000',
    favor: 4293,
    time: 82800,
    atk: 690,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'wood',
    r1Qty: 387,
    resource2: 'ironwood',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'sacred',
    c2Qty: 1,
    c2Tags: 'common',
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13spear',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-12',
    supgrade2: 'resource2-34',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1375,
    surcharge: 3060,
    suggest: 610,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  airshipspear: {
    uid: 'airshipspear',
    level: 36,
    type: 'wp',
    xp: 230000,
    craftXp: 3450,
    value: 2800000,
    tradeMinMaxValue:
      '700000,880000,1400000,2100000,3500000;35000000,44000000,70000000,105000000,175000000',
    favor: 4782,
    time: 92400,
    atk: 690,
    def: 0,
    hp: 21,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'wood',
    r1Qty: 387,
    resource2: 'ironwood',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-6',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-23',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1455,
    surcharge: 3235,
    suggest: 645,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-06-29',
    shardPrice: 350,
    capriceDelay: 0,
  },
  beachspear: {
    uid: 'beachspear',
    level: 37,
    type: 'wp',
    xp: 245000,
    craftXp: 3675,
    value: 2950000,
    tradeMinMaxValue:
      '740000,920000,1500000,2200000,3700000;37000000,46000000,74000000,110500000,184500000',
    favor: 5094,
    time: 111000,
    atk: 710,
    def: 178,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 380,
    resource2: 'fabric',
    r2Qty: 115,
    resource3: 'gems',
    r3Qty: 45,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource2-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-7',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'multi+0.1',
    supgrade2: 'resource1-76',
    supgrade3: 'resource3-9',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1490,
    surcharge: 3315,
    suggest: 665,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-07-26',
    shardPrice: 350,
    capriceDelay: 0,
  },
  goldspear2: {
    uid: 'goldspear2',
    level: 37,
    type: 'wp',
    xp: 245000,
    craftXp: 3675,
    value: 5300000,
    tradeMinMaxValue:
      '1350000,1650000,2650000,4000000,6650000;66500000,83000000,132500000,199000000,331500000',
    favor: 5488,
    time: 111000,
    atk: 710,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'wood',
    r1Qty: 404,
    resource2: 'ironwood',
    r2Qty: 123,
    resource3: 'gems',
    r3Qty: 46,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'deepcoral',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-25',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-14',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1485,
    surcharge: 3295,
    suggest: 660,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-02-17',
    shardPrice: 0,
    capriceDelay: 0,
  },
  t13spear: {
    uid: 't13spear',
    level: 39,
    type: 'wp',
    xp: 275000,
    craftXp: 4125,
    value: 5850000,
    tradeMinMaxValue:
      '1450000,1850000,2950000,4400000,7300000;73000000,91500000,146500000,219500000,365500000',
    favor: 6656,
    time: 157800,
    atk: 870,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'wood',
    r1Qty: 438,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voideye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-88',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-8',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 2070,
    surcharge: 4605,
    suggest: 920,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  riftspear: {
    uid: 'riftspear',
    level: 39,
    type: 'wp',
    xp: 275000,
    craftXp: 4125,
    value: 6050000,
    tradeMinMaxValue:
      '1550000,1900000,3050000,4600000,7650000;76500000,96000000,153500000,230000000,383000000',
    favor: 7238,
    time: 157800,
    atk: 870,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'wood',
    r1Qty: 430,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcloth',
    c1Qty: 3,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-8',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-86',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'torrent',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 2105,
    surcharge: 4680,
    suggest: 935,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: 'rift',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  inuitspear: {
    uid: 'inuitspear',
    level: 39,
    type: 'wp',
    xp: 275000,
    craftXp: 4125,
    value: 6100000,
    tradeMinMaxValue:
      '1550000,1900000,3050000,4600000,7650000;76500000,95500000,152500000,229000000,381500000',
    favor: 7090,
    time: 157200,
    atk: 870,
    def: 0,
    hp: 27,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'master',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'wood',
    r1Qty: 422,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'trident',
    c2Qty: 1,
    c2Tags: 'uncommon',
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-84',
    upgrade3: 'resource2-8',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-2',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: 'kraken',
    tag: null,
    discount: 2115,
    surcharge: 4700,
    suggest: 940,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-30',
    shardPrice: 375,
    capriceDelay: 0,
  },
  shortbow: {
    uid: 'shortbow',
    level: 2,
    type: 'wb',
    xp: 265,
    craftXp: 4,
    value: 220,
    tradeMinMaxValue: '70,80,130,200,330;3300,4100,6600,9900,16500',
    favor: 1,
    time: 40,
    atk: 22,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 7,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'component1-1',
    upgrade2: 'bp:longbow',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-5',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 25,
    suggest: 5,
    speedup: 25,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  forestbow: {
    uid: 'forestbow',
    level: 3,
    type: 'wb',
    xp: 495,
    craftXp: 7,
    value: 410,
    tradeMinMaxValue: '200,260,410,610,1000;10000,13000,20500,30500,51000',
    favor: 11,
    time: 70,
    atk: 32,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 1,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-2',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'quality+1',
    supgrade2: 'multi+0.2',
    supgrade3: 'component1-1',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'breeze',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 15,
    surcharge: 35,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: 'forest',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  longbow: {
    uid: 'longbow',
    level: 4,
    type: 'wb',
    xp: 840,
    craftXp: 13,
    value: 290,
    tradeMinMaxValue: '90,110,170,260,440;4400,5400,8700,13000,22000',
    favor: 13,
    time: 100,
    atk: 42,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 12,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:hunterbow',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-9',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 15,
    surcharge: 30,
    suggest: 5,
    speedup: 55,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  hunterbow: {
    uid: 'hunterbow',
    level: 7,
    type: 'wb',
    xp: 2600,
    craftXp: 39,
    value: 1150,
    tradeMinMaxValue: '350,430,690,1050,1750;17500,21500,34500,52000,86500',
    favor: 21,
    time: 290,
    atk: 76,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 17,
    resource2: 'herbs',
    r2Qty: 4,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:strikerbow',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 60,
    suggest: 10,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  siabow: {
    uid: 'siabow',
    level: 9,
    type: 'wb',
    xp: 4400,
    craftXp: 66,
    value: 2350,
    tradeMinMaxValue: '590,730,1200,1750,2900;29500,36500,59000,88000,145000',
    favor: 39,
    time: 500,
    atk: 104,
    def: 0,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 26,
    resource2: 'leather',
    r2Qty: 13,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-5',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource2-13',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 85,
    suggest: 15,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-07-02',
    shardPrice: 75,
    capriceDelay: 0,
  },
  strikerbow: {
    uid: 'strikerbow',
    level: 10,
    type: 'wb',
    xp: 5500,
    craftXp: 83,
    value: 2800,
    tradeMinMaxValue: '700,880,1400,2100,3500;35000,44000,70000,105000,175000',
    favor: 42,
    time: 660,
    atk: 116,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 7,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 30,
    resource2: 'leather',
    r2Qty: 15,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:spikedbow',
    upgrade4: 'resource1-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 90,
    suggest: 20,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  mundrabow: {
    uid: 'mundrabow',
    level: 12,
    type: 'wb',
    xp: 8150,
    craftXp: 244,
    value: 9700,
    tradeMinMaxValue:
      '4900,6100,9700,14500,24000;245000,305000,485000,730000,1200000',
    favor: 71,
    time: 1050,
    atk: 144,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'moondragon',
    worker2: null,
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 41,
    resource2: 'ironwood',
    r2Qty: 8,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'bp:mundraarmor',
    upgrade4: 'resource1-8',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: 'dragon',
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 75,
    surcharge: 165,
    suggest: 35,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  spikedbow: {
    uid: 'spikedbow',
    level: 13,
    type: 'wb',
    xp: 9650,
    craftXp: 145,
    value: 8300,
    tradeMinMaxValue:
      '2100,2600,4200,6200,10500;105000,130000,210000,310000,520000',
    favor: 81,
    time: 1290,
    atk: 160,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 49,
    resource2: 'ironwood',
    r2Qty: 10,
    resource3: null,
    r3Qty: 0,
    component1: 'longbow',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'livingroots',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'bp:wingedbow',
    upgrade4: 'resource1-10',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 150,
    suggest: 30,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  halloweenbow: {
    uid: 'halloweenbow',
    level: 15,
    type: 'wb',
    xp: 13000,
    craftXp: 195,
    value: 13500,
    tradeMinMaxValue:
      '3400,4200,6800,10000,17000;170000,210000,340000,505000,845000',
    favor: 150,
    time: 2040,
    atk: 188,
    def: 47,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 71,
    resource2: 'ironwood',
    r2Qty: 16,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 1,
    c1Tags: null,
    component2: 'webbedwings',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-14',
    upgrade4: 'resource2-3',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 85,
    surcharge: 190,
    suggest: 40,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-10-19',
    shardPrice: 120,
    capriceDelay: 0,
  },
  wingedbow: {
    uid: 'wingedbow',
    level: 17,
    type: 'wb',
    xp: 17000,
    craftXp: 255,
    value: 27000,
    tradeMinMaxValue:
      '6800,8400,13500,20000,34000;340000,420000,675000,1000000,1700000',
    favor: 216,
    time: 3300,
    atk: 220,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 1,
    w3BuildingReq: 4,
    resource1: 'wood',
    r1Qty: 99,
    resource2: 'ironwood',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: 'glowingmushrooms',
    c2Qty: 7,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:compositebow',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-5',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'eagle',
    tag: null,
    discount: 120,
    surcharge: 270,
    suggest: 55,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  desertbow: {
    uid: 'desertbow',
    level: 18,
    type: 'wb',
    xp: 19500,
    craftXp: 293,
    value: 38500,
    tradeMinMaxValue:
      '11500,14500,23000,35000,58000;580000,725000,1150000,1750000,2900000',
    favor: 296,
    time: 3600,
    atk: 230,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 6,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'wood',
    r1Qty: 113,
    resource2: 'ironwood',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'studdedleathergloves',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'sharpfang',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-3',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'gale',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'eagle',
    tag: 'elemental',
    discount: 145,
    surcharge: 320,
    suggest: 65,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: 'desert',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  compositebow: {
    uid: 'compositebow',
    level: 21,
    type: 'wb',
    xp: 27000,
    craftXp: 405,
    value: 72500,
    tradeMinMaxValue:
      '18000,23000,36000,54000,91000;905000,1150000,1800000,2700000,4550000',
    favor: 732,
    time: 7200,
    atk: 280,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'wood',
    r1Qty: 155,
    resource2: 'ironwood',
    r2Qty: 39,
    resource3: 'herbs',
    r3Qty: 39,
    component1: 'silkscarab',
    c1Qty: 4,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'bp:yumi',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 195,
    surcharge: 430,
    suggest: 85,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  westernbow: {
    uid: 'westernbow',
    level: 22,
    type: 'wb',
    xp: 30000,
    craftXp: 450,
    value: 110000,
    tradeMinMaxValue:
      '28000,34000,55000,83000,140000;1400000,1700000,2750000,4150000,6900000',
    favor: 997,
    time: 9000,
    atk: 300,
    def: 75,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'wood',
    r1Qty: 170,
    resource2: 'ironwood',
    r2Qty: 43,
    resource3: 'iron',
    r3Qty: 50,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'multi+0.1',
    supgrade2: 'resource3-50',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 235,
    surcharge: 525,
    suggest: 105,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-05-31',
    shardPrice: 160,
    capriceDelay: 0,
  },
  yumi: {
    uid: 'yumi',
    level: 25,
    type: 'wb',
    xp: 39500,
    craftXp: 593,
    value: 190000,
    tradeMinMaxValue:
      '48000,59000,95000,145000,240000;2400000,2950000,4750000,7150000,12000000',
    favor: 876,
    time: 15600,
    atk: 350,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'wood',
    r1Qty: 214,
    resource2: 'ironwood',
    r2Qty: 57,
    resource3: 'gems',
    r3Qty: 6,
    component1: 'oakstaff',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'overgrownvine',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'bp:bramblebane',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'cquality1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 310,
    surcharge: 685,
    suggest: 135,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  valentinebow: {
    uid: 'valentinebow',
    level: 26,
    type: 'wb',
    xp: 43000,
    craftXp: 645,
    value: 245000,
    tradeMinMaxValue:
      '61000,77000,125000,185000,310000;3050000,3850000,6150000,9200000,15500000',
    favor: 1146,
    time: 20400,
    atk: 370,
    def: 0,
    hp: 12,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 13,
    resource1: 'wood',
    r1Qty: 229,
    resource2: 'ironwood',
    r2Qty: 62,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'timecrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-46',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-12',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-6',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 345,
    surcharge: 770,
    suggest: 155,
    speedup: 995,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-02-07',
    shardPrice: 180,
    capriceDelay: 240,
  },
  bramblebane: {
    uid: 'bramblebane',
    level: 27,
    type: 'wb',
    xp: 46500,
    craftXp: 698,
    value: 305000,
    tradeMinMaxValue:
      '76000,95000,155000,230000,380000;3800000,4750000,7650000,11500000,19000000',
    favor: 1190,
    time: 16200,
    atk: 390,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'wood',
    r1Qty: 244,
    resource2: 'ironwood',
    r2Qty: 67,
    resource3: 'gems',
    r3Qty: 10,
    component1: 'bloodvine',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'ectoplasm',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:twinstrand',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 385,
    surcharge: 860,
    suggest: 170,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  fallbow: {
    uid: 'fallbow',
    level: 28,
    type: 'wb',
    xp: 50500,
    craftXp: 758,
    value: 425000,
    tradeMinMaxValue:
      '105000,135000,210000,320000,530000;5300000,6650000,10500000,16000000,26500000',
    favor: 1538,
    time: 21600,
    atk: 410,
    def: 103,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 259,
    resource2: 'ironwood',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 12,
    component1: 'ghostflag',
    c1Qty: 4,
    c1Tags: null,
    component2: 'webbedwings',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 450,
    surcharge: 1005,
    suggest: 200,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-09-08',
    shardPrice: 200,
    capriceDelay: 240,
  },
  twinstrand: {
    uid: 'twinstrand',
    level: 30,
    type: 'wb',
    xp: 58500,
    craftXp: 878,
    value: 630000,
    tradeMinMaxValue:
      '160000,195000,320000,470000,790000;7900000,9850000,16000000,23500000,39500000',
    favor: 1835,
    time: 30600,
    atk: 450,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 290,
    resource2: 'ironwood',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 17,
    component1: 'deepcoral',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:stormrend',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 820,
    surcharge: 1825,
    suggest: 365,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  christmasbow: {
    uid: 'christmasbow',
    level: 31,
    type: 'wb',
    xp: 63000,
    craftXp: 945,
    value: 655000,
    tradeMinMaxValue:
      '165000,200000,330000,490000,820000;8200000,10000000,16500000,24500000,41000000',
    favor: 2352,
    time: 36600,
    atk: 470,
    def: 0,
    hp: 15,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 306,
    resource2: 'ironwood',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 19,
    component1: 'sirenshard',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'santa',
    tag: null,
    discount: 835,
    surcharge: 1855,
    suggest: 370,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-12-15',
    shardPrice: 250,
    capriceDelay: 0,
  },
  stormrend: {
    uid: 'stormrend',
    level: 34,
    type: 'wb',
    xp: 115000,
    craftXp: 1725,
    value: 1500000,
    tradeMinMaxValue:
      '380000,470000,750000,1150000,1900000;19000000,23500000,37500000,56500000,94000000',
    favor: 3109,
    time: 63000,
    atk: 600,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 354,
    resource2: 'ironwood',
    r2Qty: 103,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-71',
    upgrade3: 'bp:t12bow',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 1080,
    surcharge: 2395,
    suggest: 480,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldbow: {
    uid: 'goldbow',
    level: 34,
    type: 'wb',
    xp: 115000,
    craftXp: 1725,
    value: 2900000,
    tradeMinMaxValue:
      '730000,910000,1450000,2200000,3650000;36500000,45500000,72500000,109000000,181500000',
    favor: 3479,
    time: 63000,
    atk: 600,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 354,
    resource2: 'ironwood',
    r2Qty: 103,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'tabletjewel',
    c1Qty: 5,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-71',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-21',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1110,
    surcharge: 2470,
    suggest: 495,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-04-17',
    shardPrice: 0,
    capriceDelay: 0,
  },
  cincobow: {
    uid: 'cincobow',
    level: 34,
    type: 'wb',
    xp: 115000,
    craftXp: 1725,
    value: 1750000,
    tradeMinMaxValue:
      '440000,550000,880000,1300000,2200000;22000000,27500000,44000000,65500000,109500000',
    favor: 3458,
    time: 56400,
    atk: 600,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 354,
    resource2: 'ironwood',
    r2Qty: 103,
    resource3: 'mana',
    r3Qty: 24,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'yumi',
    c2Qty: 1,
    c2Tags: 'common',
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-71',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-21',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1160,
    surcharge: 2580,
    suggest: 515,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-05-04',
    shardPrice: 300,
    capriceDelay: 240,
  },
  newyearbow: {
    uid: 'newyearbow',
    level: 35,
    type: 'wb',
    xp: 125000,
    craftXp: 1875,
    value: 1900000,
    tradeMinMaxValue:
      '480000,590000,950000,1450000,2400000;24000000,29500000,47500000,71500000,119000000',
    favor: 3494,
    time: 75600,
    atk: 620,
    def: 155,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'iron',
    r1Qty: 334,
    resource2: 'steel',
    r2Qty: 97,
    resource3: 'gems',
    r3Qty: 27,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'resource1-67',
    upgrade2: 'resource2-29',
    upgrade3: 'time*0.8',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1210,
    surcharge: 2685,
    suggest: 535,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-12-28',
    shardPrice: 300,
    capriceDelay: 0,
  },
  frogbow: {
    uid: 'frogbow',
    level: 35,
    type: 'wb',
    xp: 125000,
    craftXp: 1875,
    value: 1950000,
    tradeMinMaxValue:
      '490000,610000,980000,1450000,2450000;24500000,30500000,49000000,73000000,122000000',
    favor: 3957,
    time: 75600,
    atk: 620,
    def: 0,
    hp: 20,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'wood',
    r1Qty: 371,
    resource2: 'ironwood',
    r2Qty: 108,
    resource3: 'gems',
    r3Qty: 27,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-74',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-22',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1225,
    surcharge: 2720,
    suggest: 545,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-01-12',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12bow: {
    uid: 't12bow',
    level: 37,
    type: 'wb',
    xp: 230000,
    craftXp: 3450,
    value: 2600000,
    tradeMinMaxValue:
      '650000,810000,1300000,1950000,3250000;32500000,40500000,65000000,97500000,162500000',
    favor: 4606,
    time: 107400,
    atk: 760,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'wood',
    r1Qty: 404,
    resource2: 'ironwood',
    r2Qty: 123,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-81',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13bow',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'resource2-37',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1405,
    surcharge: 3120,
    suggest: 625,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  arcadebow: {
    uid: 'arcadebow',
    level: 37,
    type: 'wb',
    xp: 230000,
    craftXp: 3450,
    value: 2900000,
    tradeMinMaxValue:
      '730000,910000,1450000,2200000,3650000;36500000,45500000,72500000,109000000,181500000',
    favor: 4504,
    time: 107400,
    atk: 760,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.07',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 364,
    resource2: 'steel',
    r2Qty: 111,
    resource3: 'mana',
    r3Qty: 32,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'value*1.25',
    upgrade2: 'resource2-22',
    upgrade3: 'time*0.8',
    upgrade4: 'resource1-73',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1480,
    surcharge: 3290,
    suggest: 660,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-09-20',
    shardPrice: 350,
    capriceDelay: 0,
  },
  thanksgivingbow: {
    uid: 'thanksgivingbow',
    level: 38,
    type: 'wb',
    xp: 245000,
    craftXp: 3675,
    value: 3100000,
    tradeMinMaxValue:
      '780000,970000,1550000,2350000,3900000;39000000,48500000,77500000,116500000,194000000',
    favor: 5113,
    time: 128400,
    atk: 780,
    def: 223,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'iron',
    r1Qty: 379,
    resource2: 'steel',
    r2Qty: 120,
    resource3: 'mana',
    r3Qty: 35,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'resource2-36',
    upgrade2: 'resource1-76',
    upgrade3: 'time*0.7',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'resource3-5',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1530,
    surcharge: 3395,
    suggest: 680,
    speedup: 3645,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-11-16',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13bow: {
    uid: 't13bow',
    level: 39,
    type: 'wb',
    xp: 260000,
    craftXp: 3900,
    value: 5600000,
    tradeMinMaxValue:
      '1400000,1750000,2800000,4200000,7000000;70000000,87500000,140000000,210000000,350000000',
    favor: 6656,
    time: 153600,
    atk: 930,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'wood',
    r1Qty: 438,
    resource2: 'gems',
    r2Qty: 39,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcloth',
    c1Qty: 3,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-6',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-88',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 2030,
    surcharge: 4510,
    suggest: 900,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  riftbow: {
    uid: 'riftbow',
    level: 39,
    type: 'wb',
    xp: 260000,
    craftXp: 3900,
    value: 5750000,
    tradeMinMaxValue:
      '1450000,1800000,2900000,4350000,7300000;73000000,91000000,146000000,218500000,364500000',
    favor: 7238,
    time: 150000,
    atk: 930,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'wood',
    r1Qty: 430,
    resource2: 'gems',
    r2Qty: 39,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'mandragoraroot',
    c2Qty: 1,
    c2Tags: 'uncommon',
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-6',
    upgrade3: 'value*1.25',
    upgrade4: 'cquality2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'primeval',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 2055,
    surcharge: 4565,
    suggest: 915,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: 'rift',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  blossombow: {
    uid: 'blossombow',
    level: 40,
    type: 'wb',
    xp: 275000,
    craftXp: 4125,
    value: 5950000,
    tradeMinMaxValue:
      '1500000,1850000,3000000,4450000,7450000;74500000,93000000,149000000,223000000,372000000',
    favor: 8050,
    time: 183000,
    atk: 960,
    def: 0,
    hp: 30,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'wood',
    r1Qty: 424,
    resource2: 'gems',
    r2Qty: 40,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voidcloth',
    c1Qty: 2,
    c1Tags: null,
    component2: 'deepcoral',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-85',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 2090,
    surcharge: 4640,
    suggest: 930,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-04-18',
    shardPrice: 375,
    capriceDelay: 0,
  },
  woodbranch: {
    uid: 'woodbranch',
    level: 1,
    type: 'wt',
    xp: 140,
    craftXp: 2,
    value: 60,
    tradeMinMaxValue: '20,20,35,50,90;900,1100,1800,2700,4500',
    favor: 1,
    time: 15,
    atk: 16,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:shortbow',
    upgrade2: 'bp:cane',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-4',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 15,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  cane: {
    uid: 'cane',
    level: 3,
    type: 'wt',
    xp: 540,
    craftXp: 8,
    value: 200,
    tradeMinMaxValue: '60,80,120,180,300;3000,3800,6000,9000,15000',
    favor: 10,
    time: 70,
    atk: 32,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'bp:oakstaff',
    upgrade2: 'bp:woodspear',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-7',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 25,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  oakstaff: {
    uid: 'oakstaff',
    level: 5,
    type: 'wt',
    xp: 1450,
    craftXp: 22,
    value: 710,
    tradeMinMaxValue: '180,220,360,530,890;8900,11000,18000,26500,44500',
    favor: 16,
    time: 160,
    atk: 52,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 14,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:bo',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 45,
    suggest: 10,
    speedup: 70,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lilustaff: {
    uid: 'lilustaff',
    level: 8,
    type: 'wt',
    xp: 3800,
    craftXp: 57,
    value: 1950,
    tradeMinMaxValue: '610,760,1200,1850,3000;30500,38000,61000,91500,150000',
    favor: 30,
    time: 400,
    atk: 88,
    def: 0,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 21,
    resource2: 'herbs',
    r2Qty: 11,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-4',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource2-11',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: 'owl',
    tag: null,
    discount: 35,
    surcharge: 75,
    suggest: 15,
    speedup: 135,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-07-02',
    shardPrice: 75,
    capriceDelay: 0,
  },
  goldstaff: {
    uid: 'goldstaff',
    level: 8,
    type: 'wt',
    xp: 3800,
    craftXp: 57,
    value: 10000,
    tradeMinMaxValue:
      '2500,3100,5000,7500,12500;125000,155000,250000,375000,625000',
    favor: 30,
    time: 400,
    atk: 88,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 3,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 5,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 21,
    resource2: 'herbs',
    r2Qty: 11,
    resource3: null,
    r3Qty: 0,
    component1: 'tabletfragment',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-4',
    upgrade3: 'resource2-2',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.80',
    supgrade2: 'resource1-2',
    supgrade3: 'multi+0.1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'luxuriousspirit',
    tag: null,
    discount: 55,
    surcharge: 125,
    suggest: 25,
    speedup: 135,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-04-18',
    shardPrice: 0,
    capriceDelay: 0,
  },
  bo: {
    uid: 'bo',
    level: 9,
    type: 'wt',
    xp: 4850,
    craftXp: 73,
    value: 2800,
    tradeMinMaxValue: '700,880,1400,2100,3500;35000,44000,70000,105000,175000',
    favor: 35,
    time: 490,
    atk: 104,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 26,
    resource2: 'iron',
    r2Qty: 6,
    resource3: null,
    r3Qty: 0,
    component1: 'cane',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'arcanedust',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:scepter',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 90,
    suggest: 20,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  starterstaff: {
    uid: 'starterstaff',
    level: 11,
    type: 'wt',
    xp: 7450,
    craftXp: 112,
    value: 4800,
    tradeMinMaxValue:
      '1500,1900,3000,4500,7500;75000,94000,150000,225000,375000',
    favor: 56,
    time: 870,
    atk: 128,
    def: 32,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 34,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-7',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 50,
    surcharge: 115,
    suggest: 25,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 100,
    capriceDelay: 240,
  },
  scepter: {
    uid: 'scepter',
    level: 13,
    type: 'wt',
    xp: 10500,
    craftXp: 158,
    value: 9500,
    tradeMinMaxValue:
      '2400,3000,4800,7100,12000;120000,150000,240000,355000,595000',
    favor: 81,
    time: 1380,
    atk: 160,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 49,
    resource2: 'ironwood',
    r2Qty: 10,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-10',
    upgrade3: 'bp:wizardstaff',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 160,
    suggest: 30,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  swampstaff: {
    uid: 'swampstaff',
    level: 14,
    type: 'wt',
    xp: 12500,
    craftXp: 188,
    value: 13000,
    tradeMinMaxValue:
      '5200,6500,10500,15500,26000;260000,325000,520000,775000,1300000',
    favor: 119,
    time: 1740,
    atk: 172,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 5,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 60,
    resource2: 'ironwood',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-2',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'wild',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 85,
    surcharge: 190,
    suggest: 40,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: 'swamp',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  wiccanstaff: {
    uid: 'wiccanstaff',
    level: 14,
    type: 'wt',
    xp: 12500,
    craftXp: 188,
    value: 18500,
    tradeMinMaxValue:
      '4600,5800,9300,14000,23000;230000,290000,465000,695000,1150000',
    favor: 119,
    time: 1740,
    atk: 172,
    def: 0,
    hp: 5,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 60,
    resource2: 'ironwood',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 4,
    c1Tags: null,
    component2: 'elvenwood',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'component2-1',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 100,
    surcharge: 225,
    suggest: 45,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-03-08',
    shardPrice: 120,
    capriceDelay: 0,
  },
  wizardstaff: {
    uid: 'wizardstaff',
    level: 17,
    type: 'wt',
    xp: 19000,
    craftXp: 285,
    value: 32500,
    tradeMinMaxValue:
      '8100,10000,16500,24000,41000;405000,510000,815000,1200000,2050000',
    favor: 216,
    time: 3300,
    atk: 220,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'wood',
    r1Qty: 99,
    resource2: 'ironwood',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'mooncrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:celestialrod',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 135,
    surcharge: 295,
    suggest: 60,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 18,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  leafstaff: {
    uid: 'leafstaff',
    level: 18,
    type: 'wt',
    xp: 21500,
    craftXp: 323,
    value: 45000,
    tradeMinMaxValue:
      '11500,14000,23000,34000,56000;565000,705000,1150000,1700000,2800000',
    favor: 296,
    time: 4200,
    atk: 230,
    def: 0,
    hp: 0,
    eva: '0.05',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'wood',
    r1Qty: 113,
    resource2: 'ironwood',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 3,
    c1Tags: null,
    component2: 'livingroots',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 12,
    u2Req: 30,
    u3Req: 54,
    u4Req: 85,
    u5Req: 135,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-23',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 155,
    surcharge: 340,
    suggest: 70,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-11-19',
    shardPrice: 140,
    capriceDelay: 240,
  },
  celestialrod: {
    uid: 'celestialrod',
    level: 20,
    type: 'wt',
    xp: 27000,
    craftXp: 405,
    value: 60500,
    tradeMinMaxValue:
      '15000,19000,30000,45000,76000;755000,945000,1500000,2250000,3800000',
    favor: 498,
    time: 6600,
    atk: 270,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'wood',
    r1Qty: 141,
    resource2: 'ironwood',
    r2Qty: 36,
    resource3: 'oils',
    r3Qty: 18,
    component1: 'evileye',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:imperialaquila',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 180,
    surcharge: 395,
    suggest: 80,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  mundrastaff: {
    uid: 'mundrastaff',
    level: 22,
    type: 'wt',
    xp: 33000,
    craftXp: 990,
    value: 77000,
    tradeMinMaxValue:
      '25000,32000,51000,76000,125000;1250000,1600000,2550000,3800000,6300000',
    favor: 765,
    time: 9600,
    atk: 300,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'moondragon',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'wood',
    r1Qty: 170,
    resource2: 'ironwood',
    r2Qty: 43,
    resource3: 'oils',
    r3Qty: 22,
    component1: 'mooncrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'arcanedust',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 12,
    u2Req: 30,
    u3Req: 54,
    u4Req: 85,
    u5Req: 135,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'bp:mundrahammer',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-9',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: 'dragon',
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 200,
    surcharge: 445,
    suggest: 90,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  imperialaquila: {
    uid: 'imperialaquila',
    level: 24,
    type: 'wt',
    xp: 40000,
    craftXp: 600,
    value: 180000,
    tradeMinMaxValue:
      '45000,56000,90000,135000,230000;2250000,2800000,4500000,6750000,11500000',
    favor: 771,
    time: 14400,
    atk: 340,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'wood',
    r1Qty: 199,
    resource2: 'ironwood',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 7,
    component1: 'timecrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:transcendance',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-10',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'eagle',
    tag: null,
    discount: 300,
    surcharge: 665,
    suggest: 135,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ruinsstaff: {
    uid: 'ruinsstaff',
    level: 25,
    type: 'wt',
    xp: 44000,
    craftXp: 660,
    value: 210000,
    tradeMinMaxValue:
      '55000,68000,110000,165000,270000;2750000,3400000,5450000,8200000,13500000',
    favor: 1001,
    time: 15000,
    atk: 350,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 8,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'wood',
    r1Qty: 214,
    resource2: 'ironwood',
    r2Qty: 57,
    resource3: 'mana',
    r3Qty: 8,
    component1: 'circlet',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'timecrystal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'cquality1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'flaming',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'eagle',
    tag: 'elemental',
    discount: 320,
    surcharge: 715,
    suggest: 145,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: 'ruins',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  musicstaff: {
    uid: 'musicstaff',
    level: 25,
    type: 'wt',
    xp: 44000,
    craftXp: 660,
    value: 220000,
    tradeMinMaxValue:
      '55000,69000,110000,165000,280000;2750000,3450000,5500000,8250000,14000000',
    favor: 930,
    time: 17400,
    atk: 350,
    def: 88,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'wood',
    r1Qty: 201,
    resource2: 'ironwood',
    r2Qty: 48,
    resource3: 'mana',
    r3Qty: 8,
    component1: 'ancientmarble',
    c1Qty: 3,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-10',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 330,
    surcharge: 735,
    suggest: 145,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-02',
    shardPrice: 180,
    capriceDelay: 0,
  },
  transcendance: {
    uid: 'transcendance',
    level: 27,
    type: 'wt',
    xp: 51500,
    craftXp: 773,
    value: 300000,
    tradeMinMaxValue:
      '75000,94000,150000,230000,380000;3750000,4700000,7500000,11500000,19000000',
    favor: 1224,
    time: 18000,
    atk: 390,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'wood',
    r1Qty: 244,
    resource2: 'ironwood',
    r2Qty: 67,
    resource3: 'mana',
    r3Qty: 13,
    component1: 'doublering',
    c1Qty: 4,
    c1Tags: 'common',
    component2: 'ghostflag',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:conflux',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-2',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 385,
    surcharge: 850,
    suggest: 170,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  castlestaff: {
    uid: 'castlestaff',
    level: 28,
    type: 'wt',
    xp: 56000,
    craftXp: 840,
    value: 365000,
    tradeMinMaxValue:
      '98000,125000,195000,290000,490000;4900000,6150000,9850000,14500000,24500000',
    favor: 1589,
    time: 22200,
    atk: 410,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 259,
    resource2: 'ironwood',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 16,
    component1: 'ectoplasm',
    c1Qty: 4,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'flood',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 420,
    surcharge: 935,
    suggest: 185,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: 'castle',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lemonstaff: {
    uid: 'lemonstaff',
    level: 28,
    type: 'wt',
    xp: 56000,
    craftXp: 840,
    value: 430000,
    tradeMinMaxValue:
      '110000,135000,220000,320000,540000;5400000,6700000,11000000,16000000,27000000',
    favor: 1589,
    time: 22200,
    atk: 410,
    def: 103,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 259,
    resource2: 'ironwood',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 16,
    component1: 'sewingthread',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 455,
    surcharge: 1010,
    suggest: 200,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-05-19',
    shardPrice: 200,
    capriceDelay: 240,
  },
  conflux: {
    uid: 'conflux',
    level: 30,
    type: 'wt',
    xp: 65000,
    craftXp: 975,
    value: 645000,
    tradeMinMaxValue:
      '160000,200000,320000,480000,810000;8050000,10000000,16000000,24000000,40500000',
    favor: 1909,
    time: 32400,
    atk: 450,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 290,
    resource2: 'ironwood',
    r2Qty: 82,
    resource3: 'mana',
    r3Qty: 22,
    component1: 'sirenshard',
    c1Qty: 5,
    c1Tags: null,
    component2: 'ancientmarble',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:penumbra',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 830,
    surcharge: 1845,
    suggest: 370,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  christmasstaff: {
    uid: 'christmasstaff',
    level: 31,
    type: 'wt',
    xp: 69500,
    craftXp: 1043,
    value: 675000,
    tradeMinMaxValue:
      '170000,210000,340000,510000,840000;8450000,10500000,17000000,25500000,42000000',
    favor: 2451,
    time: 36600,
    atk: 470,
    def: 118,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 306,
    resource2: 'ironwood',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'wizardstaff',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'deepcoral',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'santa',
    tag: null,
    discount: 850,
    surcharge: 1885,
    suggest: 375,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-12-16',
    shardPrice: 250,
    capriceDelay: 240,
  },
  penumbra: {
    uid: 'penumbra',
    level: 33,
    type: 'wt',
    xp: 120000,
    craftXp: 1800,
    value: 1400000,
    tradeMinMaxValue:
      '350000,440000,700000,1050000,1750000;17500000,22000000,35000000,52500000,87500000',
    favor: 2889,
    time: 55200,
    atk: 580,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 338,
    resource2: 'ironwood',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12staff',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1045,
    surcharge: 2320,
    suggest: 465,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  yetistaff: {
    uid: 'yetistaff',
    level: 33,
    type: 'wt',
    xp: 120000,
    craftXp: 1800,
    value: 1650000,
    tradeMinMaxValue:
      '410000,520000,830000,1250000,2050000;20500000,26000000,41500000,62000000,103000000',
    favor: 2812,
    time: 55200,
    atk: 580,
    def: 0,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 300,
    resource2: 'steel',
    r2Qty: 90,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-60',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-18',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1130,
    surcharge: 2510,
    suggest: 500,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-08-10',
    shardPrice: 300,
    capriceDelay: 240,
  },
  valentinestaff: {
    uid: 'valentinestaff',
    level: 34,
    type: 'wt',
    xp: 130000,
    craftXp: 1950,
    value: 1800000,
    tradeMinMaxValue:
      '450000,560000,900000,1350000,2250000;22500000,28000000,45000000,67500000,112500000',
    favor: 3420,
    time: 66000,
    atk: 600,
    def: 150,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 338,
    resource2: 'ironwood',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-68',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1175,
    surcharge: 2615,
    suggest: 525,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-02-08',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12staff: {
    uid: 't12staff',
    level: 36,
    type: 'wt',
    xp: 240000,
    craftXp: 3600,
    value: 2350000,
    tradeMinMaxValue:
      '590000,730000,1200000,1750000,2950000;29500000,36500000,59000000,88000000,147000000',
    favor: 4293,
    time: 94200,
    atk: 730,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'wood',
    r1Qty: 387,
    resource2: 'ironwood',
    r2Qty: 113,
    resource3: 'mana',
    r3Qty: 41,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13staff',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-34',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1335,
    surcharge: 2970,
    suggest: 595,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldstaff2: {
    uid: 'goldstaff2',
    level: 36,
    type: 'wt',
    xp: 240000,
    craftXp: 3600,
    value: 4600000,
    tradeMinMaxValue:
      '1150000,1450000,2300000,3450000,5750000;57500000,72000000,115000000,172500000,287500000',
    favor: 4782,
    time: 94200,
    atk: 730,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'wood',
    r1Qty: 387,
    resource2: 'ironwood',
    r2Qty: 113,
    resource3: 'mana',
    r3Qty: 41,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'resource2-17',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1385,
    surcharge: 3080,
    suggest: 615,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-04-15',
    shardPrice: 0,
    capriceDelay: 0,
  },
  hemmadonstaff: {
    uid: 'hemmadonstaff',
    level: 36,
    type: 'wt',
    xp: 240000,
    craftXp: 3600,
    value: 2550000,
    tradeMinMaxValue:
      '640000,800000,1300000,1900000,3200000;32000000,40000000,64000000,95500000,159500000',
    favor: 4532,
    time: 94200,
    atk: 730,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'wood',
    r1Qty: 380,
    resource2: 'ironwood',
    r2Qty: 105,
    resource3: 'gems',
    r3Qty: 35,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-76',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-32',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1390,
    surcharge: 3090,
    suggest: 620,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-04-19',
    shardPrice: 350,
    capriceDelay: 0,
  },
  anubisstaff: {
    uid: 'anubisstaff',
    level: 37,
    type: 'wt',
    xp: 255000,
    craftXp: 3825,
    value: 2750000,
    tradeMinMaxValue:
      '690000,860000,1400000,2050000,3450000;34500000,43000000,69000000,103000000,172000000',
    favor: 5426,
    time: 112800,
    atk: 760,
    def: 0,
    hp: 24,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 400,
    resource2: 'steel',
    r2Qty: 105,
    resource3: 'mana',
    r3Qty: 46,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-16',
    upgrade3: 'resource1-100',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: 'wolf',
    tag: null,
    discount: 1440,
    surcharge: 3205,
    suggest: 640,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-08-10',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13staff: {
    uid: 't13staff',
    level: 40,
    type: 'wt',
    xp: 305000,
    craftXp: 4575,
    value: 5100000,
    tradeMinMaxValue:
      '1300000,1600000,2550000,3850000,6400000;64000000,79500000,127500000,191500000,319000000',
    favor: 8079,
    time: 192000,
    atk: 960,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'ironwood',
    r1Qty: 144,
    resource2: 'mana',
    r2Qty: 54,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voidcloth',
    c1Qty: 2,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-29',
    upgrade3: 'resource2-8',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-2',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 1940,
    surcharge: 4310,
    suggest: 860,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  riftstaff: {
    uid: 'riftstaff',
    level: 40,
    type: 'wt',
    xp: 305000,
    craftXp: 4575,
    value: 5450000,
    tradeMinMaxValue:
      '1400000,1750000,2750000,4150000,6900000;69000000,86500000,138500000,207500000,345500000',
    favor: 7793,
    time: 192000,
    atk: 960,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'ironwood',
    r1Qty: 135,
    resource2: 'mana',
    r2Qty: 40,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voideye',
    c1Qty: 2,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-27',
    upgrade3: 'resource2-6',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'abyssal',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 2005,
    surcharge: 4450,
    suggest: 890,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: 'rift',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  collectionstaff: {
    uid: 'collectionstaff',
    level: 40,
    type: 'wt',
    xp: 305000,
    craftXp: 4575,
    value: 6000000,
    tradeMinMaxValue:
      '1500000,1900000,3000000,4500000,7500000;75000000,94000000,150000000,225000000,375000000',
    favor: 7491,
    time: 192000,
    atk: 1200,
    def: 300,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'ironwood',
    r1Qty: 130,
    resource2: 'mana',
    r2Qty: 54,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-8',
    upgrade3: 'resource1-26',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 2095,
    surcharge: 4660,
    suggest: 930,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  baton: {
    uid: 'baton',
    level: 3,
    type: 'ww',
    xp: 785,
    craftXp: 12,
    value: 350,
    tradeMinMaxValue: '90,110,180,260,440;4400,5500,8800,13000,22000',
    favor: 11,
    time: 80,
    atk: 36,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 1,
    combo: 9,
    worker1: 'academy',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-2',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-5',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 15,
    surcharge: 35,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  twirled: {
    uid: 'twirled',
    level: 6,
    type: 'ww',
    xp: 3200,
    craftXp: 48,
    value: 1400,
    tradeMinMaxValue: '350,440,700,1050,1750;17500,22000,35000,52500,87500',
    favor: 21,
    time: 270,
    atk: 74,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 2,
    combo: 9,
    worker1: 'academy',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 16,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-3',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 30,
    surcharge: 65,
    suggest: 15,
    speedup: 90,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  backscratcher: {
    uid: 'backscratcher',
    level: 9,
    type: 'ww',
    xp: 7500,
    craftXp: 113,
    value: 3250,
    tradeMinMaxValue: '810,1000,1650,2400,4100;40500,51000,81500,120000,205000',
    favor: 39,
    time: 630,
    atk: 120,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'academy',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 26,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:steelrod',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'quality+2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-4',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 45,
    surcharge: 95,
    suggest: 20,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  steelrod: {
    uid: 'steelrod',
    level: 11,
    type: 'ww',
    xp: 11500,
    craftXp: 173,
    value: 7650,
    tradeMinMaxValue:
      '1900,2400,3800,5700,9600;95500,120000,190000,285000,480000',
    favor: 56,
    time: 1050,
    atk: 152,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'academy',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 34,
    resource2: 'iron',
    r2Qty: 9,
    resource3: null,
    r3Qty: 0,
    component1: 'ironcap',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'arcanedust',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:starrod',
    upgrade4: 'resource1-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 65,
    surcharge: 145,
    suggest: 30,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  starrod: {
    uid: 'starrod',
    level: 14,
    type: 'ww',
    xp: 19500,
    craftXp: 293,
    value: 18500,
    tradeMinMaxValue:
      '4600,5800,9300,14000,23000;230000,290000,465000,695000,1150000',
    favor: 156,
    time: 2160,
    atk: 200,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 60,
    resource2: 'ironwood',
    r2Qty: 12,
    resource3: 'oils',
    r3Qty: 9,
    component1: 'icypearl',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'bp:sylvan',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 100,
    surcharge: 225,
    suggest: 45,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  christmaswand: {
    uid: 'christmaswand',
    level: 15,
    type: 'ww',
    xp: 22500,
    craftXp: 338,
    value: 20000,
    tradeMinMaxValue:
      '5000,6300,10000,15000,25000;250000,315000,500000,750000,1250000',
    favor: 204,
    time: 2700,
    atk: 220,
    def: 55,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 71,
    resource2: 'ironwood',
    r2Qty: 16,
    resource3: 'oils',
    r3Qty: 12,
    component1: 'antidote',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'livingroots',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-14',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'santa',
    tag: null,
    discount: 105,
    surcharge: 230,
    suggest: 45,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-12-16',
    shardPrice: 120,
    capriceDelay: 240,
  },
  sylvan: {
    uid: 'sylvan',
    level: 17,
    type: 'ww',
    xp: 29500,
    craftXp: 443,
    value: 53500,
    tradeMinMaxValue:
      '13500,16500,27000,40000,67000;670000,835000,1350000,2000000,3350000',
    favor: 376,
    time: 4200,
    atk: 260,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 8,
    worker1: 'academy',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'wood',
    r1Qty: 99,
    resource2: 'herbs',
    r2Qty: 25,
    resource3: 'ironwood',
    r3Qty: 25,
    component1: 'mooncrystal',
    c1Qty: 5,
    c1Tags: null,
    component2: 'elvenwood',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:rubyrod',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-6',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 165,
    surcharge: 370,
    suggest: 75,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  fallwand2: {
    uid: 'fallwand2',
    level: 18,
    type: 'ww',
    xp: 33500,
    craftXp: 503,
    value: 71500,
    tradeMinMaxValue:
      '18000,22000,36000,54000,89000;895000,1100000,1800000,2700000,4450000',
    favor: 296,
    time: 4800,
    atk: 280,
    def: 0,
    hp: 9,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'wood',
    r1Qty: 113,
    resource2: 'ironwood',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'baton',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'whitesand',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 195,
    surcharge: 430,
    suggest: 85,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-09-07',
    shardPrice: 140,
    capriceDelay: 0,
  },
  rubyrod: {
    uid: 'rubyrod',
    level: 20,
    type: 'ww',
    xp: 42000,
    craftXp: 630,
    value: 100000,
    tradeMinMaxValue:
      '25000,31000,50000,75000,125000;1250000,1550000,2500000,3750000,6250000',
    favor: 485,
    time: 7800,
    atk: 330,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'wood',
    r1Qty: 141,
    resource2: 'ironwood',
    r2Qty: 36,
    resource3: 'steel',
    r3Qty: 9,
    component1: 'evileye',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:goldwand',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 225,
    surcharge: 500,
    suggest: 100,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  liluwand: {
    uid: 'liluwand',
    level: 21,
    type: 'ww',
    xp: 46500,
    craftXp: 698,
    value: 105000,
    tradeMinMaxValue:
      '26000,33000,53000,79000,130000;1300000,1650000,2650000,3950000,6550000',
    favor: 574,
    time: 9600,
    atk: 350,
    def: 0,
    hp: 11,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'wood',
    r1Qty: 155,
    resource2: 'ironwood',
    r2Qty: 39,
    resource3: 'steel',
    r3Qty: 10,
    component1: 'evileye',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-8',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: 'owl',
    tag: null,
    discount: 230,
    surcharge: 515,
    suggest: 105,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-11-04',
    shardPrice: 160,
    capriceDelay: 240,
  },
  goldwand: {
    uid: 'goldwand',
    level: 24,
    type: 'ww',
    xp: 62500,
    craftXp: 938,
    value: 245000,
    tradeMinMaxValue:
      '61000,77000,125000,185000,310000;3050000,3850000,6150000,9200000,15500000',
    favor: 862,
    time: 16200,
    atk: 410,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'wood',
    r1Qty: 199,
    resource2: 'ironwood',
    r2Qty: 51,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'songofvalor',
    c1Qty: 2,
    c1Tags: 'common',
    component2: 'ancientmarble',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:flowerwand',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: null,
    tag: null,
    discount: 345,
    surcharge: 770,
    suggest: 155,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  flowerwand: {
    uid: 'flowerwand',
    level: 28,
    type: 'ww',
    xp: 87000,
    craftXp: 1305,
    value: 345000,
    tradeMinMaxValue:
      '86000,110000,175000,260000,430000;4300000,5400000,8650000,13000000,21500000',
    favor: 1589,
    time: 27600,
    atk: 490,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 259,
    resource2: 'ironwood',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'ectoplasm',
    c1Qty: 4,
    c1Tags: null,
    component2: 'livingroots',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.6',
    upgrade2: 'resource1-52',
    upgrade3: 'bp:moonwand',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 910,
    suggest: 180,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  fallwand: {
    uid: 'fallwand',
    level: 29,
    type: 'ww',
    xp: 94000,
    craftXp: 1410,
    value: 400000,
    tradeMinMaxValue:
      '100000,125000,200000,300000,500000;5000000,6250000,10000000,15000000,25000000',
    favor: 1847,
    time: 33000,
    atk: 520,
    def: 0,
    hp: 16,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'wood',
    r1Qty: 275,
    resource2: 'ironwood',
    r2Qty: 77,
    resource3: 'gems',
    r3Qty: 19,
    component1: 'ghostflag',
    c1Qty: 3,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.6',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 440,
    surcharge: 975,
    suggest: 195,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-09-09',
    shardPrice: 200,
    capriceDelay: 240,
  },
  moonwand: {
    uid: 'moonwand',
    level: 31,
    type: 'ww',
    xp: 110000,
    craftXp: 1650,
    value: 665000,
    tradeMinMaxValue:
      '165000,210000,330000,500000,830000;8300000,10500000,16500000,25000000,41500000',
    favor: 2451,
    time: 46200,
    atk: 560,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 306,
    resource2: 'ironwood',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'scrollofice',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'sirenshard',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'bp:dragoneye',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 840,
    surcharge: 1870,
    suggest: 375,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  wizardwand: {
    uid: 'wizardwand',
    level: 31,
    type: 'ww',
    xp: 110000,
    craftXp: 1650,
    value: 700000,
    tradeMinMaxValue:
      '175000,220000,350000,530000,880000;8750000,11000000,17500000,26500000,44000000',
    favor: 2451,
    time: 47400,
    atk: 560,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 306,
    resource2: 'ironwood',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: 'timecrystal',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 865,
    surcharge: 1920,
    suggest: 385,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-10-09',
    shardPrice: 250,
    capriceDelay: 240,
  },
  dragoneye: {
    uid: 'dragoneye',
    level: 33,
    type: 'ww',
    xp: 185000,
    craftXp: 2775,
    value: 1700000,
    tradeMinMaxValue:
      '430000,530000,850000,1300000,2150000;21500000,26500000,42500000,64000000,106500000',
    favor: 3218,
    time: 67800,
    atk: 700,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 338,
    resource2: 'ironwood',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12wand',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1145,
    surcharge: 2545,
    suggest: 510,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lcogwand: {
    uid: 'lcogwand',
    level: 33,
    type: 'ww',
    xp: 185000,
    craftXp: 2775,
    value: 3450000,
    tradeMinMaxValue:
      '860000,1100000,1750000,2600000,4300000;43000000,54000000,86500000,129500000,215500000',
    favor: 3218,
    time: 67800,
    atk: 700,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 5,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 338,
    resource2: 'ironwood',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'tabletjewel',
    c1Qty: 5,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1205,
    surcharge: 2680,
    suggest: 535,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-08-07',
    shardPrice: 0,
    capriceDelay: 0,
  },
  sailorwand: {
    uid: 'sailorwand',
    level: 34,
    type: 'ww',
    xp: 200000,
    craftXp: 3000,
    value: 2000000,
    tradeMinMaxValue:
      '500000,630000,1000000,1500000,2500000;25000000,31500000,50000000,75000000,125000000',
    favor: 3263,
    time: 81000,
    atk: 720,
    def: 0,
    hp: 22,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 319,
    resource2: 'steel',
    r2Qty: 93,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-64',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1240,
    surcharge: 2750,
    suggest: 550,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-09-22',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12wand: {
    uid: 't12wand',
    level: 36,
    type: 'ww',
    xp: 375000,
    craftXp: 5625,
    value: 3050000,
    tradeMinMaxValue:
      '760000,950000,1550000,2300000,3800000;38000000,47500000,76500000,114500000,190500000',
    favor: 4782,
    time: 115800,
    atk: 880,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'wood',
    r1Qty: 387,
    resource2: 'ironwood',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13wand',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-34',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1515,
    surcharge: 3370,
    suggest: 675,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  chesswand: {
    uid: 'chesswand',
    level: 36,
    type: 'ww',
    xp: 375000,
    craftXp: 5625,
    value: 3250000,
    tradeMinMaxValue:
      '810000,1000000,1650000,2450000,4050000;40500000,51000000,81500000,122000000,203000000',
    favor: 4255,
    time: 115800,
    atk: 880,
    def: 220,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 349,
    resource2: 'steel',
    r2Qty: 102,
    resource3: 'mana',
    r3Qty: 41,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-70',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-10',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-10',
    supgrade2: 'resource3-8',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1565,
    surcharge: 3475,
    suggest: 695,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-07-13',
    shardPrice: 350,
    capriceDelay: 0,
  },
  maplewand: {
    uid: 'maplewand',
    level: 37,
    type: 'ww',
    xp: 400000,
    craftXp: 6000,
    value: 3350000,
    tradeMinMaxValue:
      '840000,1050000,1700000,2500000,4200000;42000000,52500000,84000000,125500000,209500000',
    favor: 5265,
    time: 138600,
    atk: 910,
    def: 0,
    hp: 28,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: 'cook',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 364,
    resource2: 'steel',
    r2Qty: 111,
    resource3: 'oils',
    r3Qty: 60,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-73',
    upgrade3: 'resource2-33',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-33',
    supgrade2: 'resource3-12',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 1585,
    surcharge: 3525,
    suggest: 705,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-03-08',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13wand: {
    uid: 't13wand',
    level: 40,
    type: 'ww',
    xp: 470000,
    craftXp: 7050,
    value: 6350000,
    tradeMinMaxValue:
      '1600000,2000000,3200000,4750000,7950000;79500000,99000000,159000000,238000000,397000000',
    favor: 8496,
    time: 235800,
    atk: 1150,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'academy',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'wood',
    r1Qty: 446,
    resource2: 'ironwood',
    r2Qty: 144,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voideye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'deepcoral',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-89',
    upgrade3: 'resource2-22',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 2155,
    surcharge: 4790,
    suggest: 960,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lightcrossbow: {
    uid: 'lightcrossbow',
    level: 2,
    type: 'wc',
    xp: 385,
    craftXp: 6,
    value: 210,
    tradeMinMaxValue: '50,70,110,160,260;2600,3300,5300,7900,13000',
    favor: 1,
    time: 50,
    atk: 24,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 1,
    combo: 10,
    worker1: 'engineer',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 8,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-2',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 25,
    suggest: 5,
    speedup: 25,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  handcrossbow: {
    uid: 'handcrossbow',
    level: 4,
    type: 'wc',
    xp: 1400,
    craftXp: 21,
    value: 560,
    tradeMinMaxValue: '140,180,280,420,700;7000,8800,14000,21000,35000',
    favor: 16,
    time: 130,
    atk: 46,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 2,
    combo: 9,
    worker1: 'engineer',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 13,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-3',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 40,
    suggest: 10,
    speedup: 55,
    buyAnimIdOverride: 15,
    questAnimIdOverride: 6,
    slotOverride: 'left_hip',
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  crossbow: {
    uid: 'crossbow',
    level: 7,
    type: 'wc',
    xp: 4400,
    craftXp: 66,
    value: 2000,
    tradeMinMaxValue: '500,630,1000,1500,2500;25000,31500,50000,75000,125000',
    favor: 26,
    time: 370,
    atk: 84,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'engineer',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 19,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:heavycrossbow',
    upgrade4: 'resource1-4',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 35,
    surcharge: 75,
    suggest: 15,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  heavycrossbow: {
    uid: 'heavycrossbow',
    level: 10,
    type: 'wc',
    xp: 9400,
    craftXp: 141,
    value: 5650,
    tradeMinMaxValue:
      '1400,1750,2800,4200,7100;70500,88500,140000,210000,355000',
    favor: 52,
    time: 840,
    atk: 128,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 9,
    worker1: 'engineer',
    worker2: null,
    worker3: null,
    w1BuildingReq: 7,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 33,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:huntercrossbow',
    upgrade4: 'resource1-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 55,
    surcharge: 125,
    suggest: 25,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  thanksgivingcrossbow: {
    uid: 'thanksgivingcrossbow',
    level: 12,
    type: 'wc',
    xp: 14000,
    craftXp: 210,
    value: 9050,
    tradeMinMaxValue:
      '2800,3500,5700,8500,14000;140000,175000,285000,425000,705000',
    favor: 79,
    time: 1350,
    atk: 160,
    def: 0,
    hp: 5,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'engineer',
    worker2: null,
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 45,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 3,
    c1Tags: null,
    component2: 'elvenwood',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-3',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 160,
    suggest: 30,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-11-18',
    shardPrice: 100,
    capriceDelay: 240,
  },
  huntercrossbow: {
    uid: 'huntercrossbow',
    level: 13,
    type: 'wc',
    xp: 16500,
    craftXp: 248,
    value: 15500,
    tradeMinMaxValue:
      '3900,4800,7800,11500,19500;195000,240000,390000,580000,970000',
    favor: 101,
    time: 1650,
    atk: 176,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'engineer',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 54,
    resource2: 'herbs',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'antidote',
    c1Qty: 2,
    c1Tags: 'common',
    component2: 'livingroots',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-11',
    upgrade3: 'bp:scorpion',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-2',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 90,
    surcharge: 205,
    suggest: 40,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  scorpion: {
    uid: 'scorpion',
    level: 17,
    type: 'wc',
    xp: 29500,
    craftXp: 443,
    value: 35000,
    tradeMinMaxValue:
      '8800,11000,17500,26000,44000;440000,545000,875000,1300000,2200000',
    favor: 271,
    time: 4200,
    atk: 240,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 8,
    worker1: 'engineer',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 1,
    w3BuildingReq: 4,
    resource1: 'wood',
    r1Qty: 109,
    resource2: 'ironwood',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'mooncrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'webbedwings',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-22',
    upgrade3: 'bp:doublecrossbow',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 135,
    surcharge: 305,
    suggest: 60,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 16,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  troblincrossbow: {
    uid: 'troblincrossbow',
    level: 19,
    type: 'wc',
    xp: 37500,
    craftXp: 563,
    value: 56500,
    tradeMinMaxValue:
      '14000,17500,28000,42000,71000;705000,885000,1400000,2100000,3550000',
    favor: 398,
    time: 6600,
    atk: 280,
    def: 70,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'engineer',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 16,
    w2BuildingReq: 12,
    w3BuildingReq: 6,
    resource1: 'wood',
    r1Qty: 139,
    resource2: 'ironwood',
    r2Qty: 33,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: 'livingroots',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'component1-1',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-13',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 170,
    surcharge: 380,
    suggest: 75,
    speedup: 565,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-07-14',
    shardPrice: 140,
    capriceDelay: 0,
  },
  doublecrossbow: {
    uid: 'doublecrossbow',
    level: 21,
    type: 'wc',
    xp: 46500,
    craftXp: 698,
    value: 130000,
    tradeMinMaxValue:
      '33000,41000,65000,98000,165000;1650000,2050000,3250000,4900000,8150000',
    favor: 565,
    time: 9000,
    atk: 320,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'engineer',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'wood',
    r1Qty: 171,
    resource2: 'ironwood',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'crossbow',
    c1Qty: 2,
    c1Tags: 'common',
    component2: 'silkscarab',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'bp:chukonu',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 255,
    surcharge: 570,
    suggest: 115,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  chukonu: {
    uid: 'chukonu',
    level: 24,
    type: 'wc',
    xp: 62500,
    craftXp: 938,
    value: 210000,
    tradeMinMaxValue:
      '53000,66000,105000,160000,260000;2650000,3300000,5250000,7900000,13000000',
    favor: 1145,
    time: 17400,
    atk: 380,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'engineer',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'wood',
    r1Qty: 219,
    resource2: 'ironwood',
    r2Qty: 51,
    resource3: 'steel',
    r3Qty: 26,
    component1: 'timecrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-44',
    upgrade3: 'bp:triplecrossbow',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-10',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 320,
    surcharge: 715,
    suggest: 145,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  dragoncrossbow: {
    uid: 'dragoncrossbow',
    level: 25,
    type: 'wc',
    xp: 68000,
    craftXp: 1020,
    value: 230000,
    tradeMinMaxValue:
      '58000,72000,115000,175000,290000;2900000,3600000,5750000,8650000,14500000',
    favor: 1324,
    time: 21600,
    atk: 400,
    def: 0,
    hp: 12,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'engineer',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'wood',
    r1Qty: 235,
    resource2: 'ironwood',
    r2Qty: 57,
    resource3: 'steel',
    r3Qty: 28,
    component1: 'overgrownvine',
    c1Qty: 4,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-47',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-11',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 340,
    surcharge: 750,
    suggest: 150,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-12-30',
    shardPrice: 180,
    capriceDelay: 240,
  },
  triplecrossbow: {
    uid: 'triplecrossbow',
    level: 28,
    type: 'wc',
    xp: 87000,
    craftXp: 1305,
    value: 355000,
    tradeMinMaxValue:
      '89000,110000,180000,270000,440000;4450000,5550000,8900000,13500000,22000000',
    favor: 1775,
    time: 25800,
    atk: 460,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'engineer',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 285,
    resource2: 'ironwood',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'heavycrossbow',
    c1Qty: 3,
    c1Tags: 'common',
    component2: 'sewingthread',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.6',
    upgrade2: 'resource1-57',
    upgrade3: 'bp:handballista',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 415,
    surcharge: 925,
    suggest: 185,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  handballista: {
    uid: 'handballista',
    level: 30,
    type: 'wc',
    xp: 100000,
    craftXp: 1500,
    value: 655000,
    tradeMinMaxValue:
      '165000,200000,330000,490000,820000;8200000,10000000,16500000,24500000,41000000',
    favor: 2374,
    time: 39600,
    atk: 500,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 319,
    resource2: 'ironwood',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'deepcoral',
    c1Qty: 4,
    c1Tags: null,
    component2: 'ancientmarble',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-64',
    upgrade3: 'bp:wyrmthrower',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 835,
    surcharge: 1855,
    suggest: 370,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  batcrossbow: {
    uid: 'batcrossbow',
    level: 30,
    type: 'wc',
    xp: 100000,
    craftXp: 1500,
    value: 690000,
    tradeMinMaxValue:
      '175000,220000,350000,520000,860000;8650000,11000000,17500000,26000000,43000000',
    favor: 2374,
    time: 39600,
    atk: 500,
    def: 125,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 319,
    resource2: 'ironwood',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: 'ghostflag',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-64',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-16',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 855,
    surcharge: 1905,
    suggest: 380,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-10-21',
    shardPrice: 250,
    capriceDelay: 240,
  },
  wyrmthrower: {
    uid: 'wyrmthrower',
    level: 33,
    type: 'wc',
    xp: 185000,
    craftXp: 2775,
    value: 1900000,
    tradeMinMaxValue:
      '480000,590000,950000,1450000,2400000;24000000,29500000,47500000,71500000,119000000',
    favor: 3592,
    time: 67800,
    atk: 650,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 372,
    resource2: 'ironwood',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-74',
    upgrade3: 'bp:t12crossbow',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: 'shark',
    tag: null,
    discount: 1210,
    surcharge: 2685,
    suggest: 535,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldcrossbow: {
    uid: 'goldcrossbow',
    level: 33,
    type: 'wc',
    xp: 185000,
    craftXp: 2775,
    value: 3500000,
    tradeMinMaxValue:
      '880000,1100000,1750000,2650000,4400000;44000000,54500000,87500000,131500000,219000000',
    favor: 3592,
    time: 67800,
    atk: 650,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 372,
    resource2: 'ironwood',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'tabletjewel',
    c1Qty: 5,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-74',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1215,
    surcharge: 2700,
    suggest: 540,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-26',
    shardPrice: 0,
    capriceDelay: 0,
  },
  titancrossbow: {
    uid: 'titancrossbow',
    level: 34,
    type: 'wc',
    xp: 200000,
    craftXp: 3000,
    value: 2100000,
    tradeMinMaxValue:
      '530000,660000,1050000,1600000,2650000;26500000,33000000,52500000,79000000,131500000',
    favor: 4103,
    time: 81000,
    atk: 680,
    def: 0,
    hp: 21,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 390,
    resource2: 'ironwood',
    r2Qty: 103,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-117',
    upgrade3: 'time*0.7',
    upgrade4: 'resource2-31',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1265,
    surcharge: 2815,
    suggest: 565,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-26',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12crossbow: {
    uid: 't12crossbow',
    level: 36,
    type: 'wc',
    xp: 375000,
    craftXp: 5625,
    value: 2950000,
    tradeMinMaxValue:
      '740000,920000,1500000,2200000,3700000;37000000,46000000,74000000,110500000,184500000',
    favor: 5340,
    time: 115800,
    atk: 820,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'wood',
    r1Qty: 426,
    resource2: 'ironwood',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-85',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13crossbow',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-34',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1490,
    surcharge: 3315,
    suggest: 665,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  airshipcrossbow: {
    uid: 'airshipcrossbow',
    level: 36,
    type: 'wc',
    xp: 375000,
    craftXp: 5625,
    value: 3200000,
    tradeMinMaxValue:
      '800000,1000000,1600000,2400000,4000000;40000000,50000000,80000000,120000000,200000000',
    favor: 5227,
    time: 115800,
    atk: 820,
    def: 205,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'wood',
    r1Qty: 420,
    resource2: 'ironwood',
    r2Qty: 110,
    resource3: 'gems',
    r3Qty: 40,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-84',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-28',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: 'shark',
    tag: null,
    discount: 1550,
    surcharge: 3445,
    suggest: 690,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-06-29',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13crossbow: {
    uid: 't13crossbow',
    level: 39,
    type: 'wc',
    xp: 445000,
    craftXp: 6675,
    value: 6150000,
    tradeMinMaxValue:
      '1550000,1900000,3100000,4600000,7700000;77000000,96000000,154000000,230500000,384500000',
    favor: 8218,
    time: 196800,
    atk: 1050,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'wood',
    r1Qty: 482,
    resource2: 'ironwood',
    r2Qty: 139,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voideye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'silverthistle',
    c2Qty: 1,
    c2Tags: 'uncommon',
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-28',
    upgrade3: 'resource1-96',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'cquality2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 2120,
    surcharge: 4715,
    suggest: 945,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  dreamcrossbow: {
    uid: 'dreamcrossbow',
    level: 40,
    type: 'wc',
    xp: 470000,
    craftXp: 7050,
    value: 6750000,
    tradeMinMaxValue:
      '1700000,2100000,3400000,5050000,8450000;84500000,105500000,169000000,253000000,422000000',
    favor: 9429,
    time: 235800,
    atk: 1080,
    def: 0,
    hp: 34,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'wood',
    r1Qty: 491,
    resource2: 'ironwood',
    r2Qty: 144,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voidcloth',
    c1Qty: 3,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-29',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component1-1',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 2220,
    surcharge: 4930,
    suggest: 985,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-07-12',
    shardPrice: 0,
    capriceDelay: 0,
  },
  flintlockpistol: {
    uid: 'flintlockpistol',
    level: 3,
    type: 'wg',
    xp: 730,
    craftXp: 11,
    value: 400,
    tradeMinMaxValue: '100,130,200,300,500;5000,6300,10000,15000,25000',
    favor: 12,
    time: 80,
    atk: 38,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 1,
    combo: 9,
    worker1: 'engineer',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 10,
    resource2: 'wood',
    r2Qty: 5,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-2',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 15,
    surcharge: 35,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pistol: {
    uid: 'pistol',
    level: 5,
    type: 'wg',
    xp: 2000,
    craftXp: 30,
    value: 840,
    tradeMinMaxValue: '210,260,420,630,1050;10500,13000,21000,31500,52500',
    favor: 19,
    time: 190,
    atk: 64,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 2,
    combo: 9,
    worker1: 'engineer',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 15,
    resource2: 'wood',
    r2Qty: 7,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-3',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 50,
    suggest: 10,
    speedup: 70,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  flintlockrifle: {
    uid: 'flintlockrifle',
    level: 8,
    type: 'wg',
    xp: 5400,
    craftXp: 81,
    value: 2450,
    tradeMinMaxValue: '610,770,1250,1850,3100;30500,38500,61500,92000,155000',
    favor: 33,
    time: 470,
    atk: 108,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'engineer',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 23,
    resource2: 'wood',
    r2Qty: 11,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:huntingrifle',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 85,
    suggest: 15,
    speedup: 135,
    buyAnimIdOverride: 16,
    questAnimIdOverride: 12,
    slotOverride: 'weapon_1',
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  huntingrifle: {
    uid: 'huntingrifle',
    level: 11,
    type: 'wg',
    xp: 10500,
    craftXp: 158,
    value: 9200,
    tradeMinMaxValue:
      '2300,2900,4600,6900,11500;115000,145000,230000,345000,575000',
    favor: 61,
    time: 1020,
    atk: 160,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'engineer',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 37,
    resource2: 'wood',
    r2Qty: 17,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:blunderbuss',
    upgrade4: 'resource1-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 160,
    suggest: 30,
    speedup: 225,
    buyAnimIdOverride: 16,
    questAnimIdOverride: 12,
    slotOverride: 'weapon_1',
    soundType: 'hMetal',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  wintergun: {
    uid: 'wintergun',
    level: 12,
    type: 'wg',
    xp: 13000,
    craftXp: 195,
    value: 11500,
    tradeMinMaxValue:
      '2900,3600,5800,8600,14500;145000,180000,290000,430000,720000',
    favor: 79,
    time: 1290,
    atk: 176,
    def: 44,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'engineer',
    worker2: null,
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 45,
    resource2: 'wood',
    r2Qty: 20,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-9',
    upgrade3: 'time*0.75',
    upgrade4: 'resource1-9',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 80,
    surcharge: 180,
    suggest: 35,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-12-02',
    shardPrice: 100,
    capriceDelay: 240,
  },
  blunderbuss: {
    uid: 'blunderbuss',
    level: 15,
    type: 'wg',
    xp: 21000,
    craftXp: 315,
    value: 21000,
    tradeMinMaxValue:
      '5300,6600,10500,16000,26000;265000,330000,525000,790000,1300000',
    favor: 170,
    time: 2700,
    atk: 230,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'engineer',
    worker2: null,
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 79,
    resource2: 'steel',
    r2Qty: 16,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 4,
    c1Tags: null,
    component2: 'elvenwood',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-16',
    upgrade3: 'bp:doublebarrel',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-5',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 110,
    surcharge: 240,
    suggest: 50,
    speedup: 375,
    buyAnimIdOverride: 16,
    questAnimIdOverride: 12,
    slotOverride: 'weapon_1',
    soundType: 'hMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  doublebarrel: {
    uid: 'doublebarrel',
    level: 18,
    type: 'wg',
    xp: 31000,
    craftXp: 465,
    value: 46000,
    tradeMinMaxValue:
      '11500,14500,23000,35000,58000;575000,720000,1150000,1750000,2900000',
    favor: 330,
    time: 4800,
    atk: 290,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'engineer',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 1,
    w3BuildingReq: 5,
    resource1: 'iron',
    r1Qty: 124,
    resource2: 'steel',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'mooncrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-25',
    upgrade3: 'bp:kenora',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 155,
    surcharge: 345,
    suggest: 70,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  fruitgun: {
    uid: 'fruitgun',
    level: 19,
    type: 'wg',
    xp: 34500,
    craftXp: 518,
    value: 56000,
    tradeMinMaxValue:
      '14000,17500,28000,42000,70000;700000,875000,1400000,2100000,3500000',
    favor: 357,
    time: 6000,
    atk: 310,
    def: 0,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'engineer',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 16,
    w2BuildingReq: 1,
    w3BuildingReq: 6,
    resource1: 'herbs',
    r1Qty: 127,
    resource2: 'oils',
    r2Qty: 33,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 5,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-25',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 170,
    surcharge: 380,
    suggest: 75,
    speedup: 565,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-08-24',
    shardPrice: 140,
    capriceDelay: 0,
  },
  arcadegun: {
    uid: 'arcadegun',
    level: 19,
    type: 'wg',
    xp: 34500,
    craftXp: 518,
    value: 58000,
    tradeMinMaxValue:
      '14500,18000,29000,44000,73000;725000,905000,1450000,2200000,3650000',
    favor: 347,
    time: 6000,
    atk: 310,
    def: 93,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'engineer',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 16,
    w2BuildingReq: 1,
    w3BuildingReq: 6,
    resource1: 'herbs',
    r1Qty: 124,
    resource2: 'oils',
    r2Qty: 33,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 5,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-25',
    upgrade3: 'resource2-7',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 175,
    surcharge: 385,
    suggest: 75,
    speedup: 565,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-16',
    shardPrice: 140,
    capriceDelay: 0,
  },
  kenora: {
    uid: 'kenora',
    level: 21,
    type: 'wg',
    xp: 43000,
    craftXp: 645,
    value: 110000,
    tradeMinMaxValue:
      '28000,34000,55000,83000,140000;1400000,1700000,2750000,4150000,6900000',
    favor: 860,
    time: 9000,
    atk: 350,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'engineer',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'iron',
    r1Qty: 171,
    resource2: 'wood',
    r2Qty: 78,
    resource3: 'steel',
    r3Qty: 39,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'bp:gatling',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 235,
    surcharge: 525,
    suggest: 105,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  gatling: {
    uid: 'gatling',
    level: 24,
    type: 'wg',
    xp: 57500,
    craftXp: 863,
    value: 210000,
    tradeMinMaxValue:
      '53000,66000,105000,160000,260000;2650000,3300000,5250000,7900000,13000000',
    favor: 898,
    time: 16800,
    atk: 420,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'engineer',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'iron',
    r1Qty: 219,
    resource2: 'steel',
    r2Qty: 51,
    resource3: null,
    r3Qty: 0,
    component1: 'pistol',
    c1Qty: 4,
    c1Tags: 'common',
    component2: 'overgrownvine',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'component1-2',
    upgrade3: 'bp:dragonator',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-10',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 320,
    surcharge: 715,
    suggest: 145,
    speedup: 860,
    buyAnimIdOverride: 16,
    questAnimIdOverride: 12,
    slotOverride: 'weapon_1',
    soundType: 'hMetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  dragonator: {
    uid: 'dragonator',
    level: 29,
    type: 'wg',
    xp: 86500,
    craftXp: 1298,
    value: 370000,
    tradeMinMaxValue:
      '93000,115000,185000,280000,460000;4650000,5800000,9250000,14000000,23000000',
    favor: 1943,
    time: 30000,
    atk: 530,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'engineer',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'iron',
    r1Qty: 302,
    resource2: 'steel',
    r2Qty: 77,
    resource3: 'gems',
    r3Qty: 11,
    component1: 'ember',
    c1Qty: 2,
    c1Tags: 'common',
    component2: 'ectoplasm',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.6',
    upgrade2: 'resource1-60',
    upgrade3: 'bp:hermes',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 425,
    surcharge: 940,
    suggest: 190,
    speedup: 1220,
    buyAnimIdOverride: 16,
    questAnimIdOverride: 12,
    slotOverride: 'weapon_1',
    soundType: 'hMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  barrelgun: {
    uid: 'barrelgun',
    level: 29,
    type: 'wg',
    xp: 86500,
    craftXp: 1298,
    value: 425000,
    tradeMinMaxValue:
      '105000,135000,210000,320000,530000;5300000,6650000,10500000,16000000,26500000',
    favor: 1943,
    time: 31800,
    atk: 530,
    def: 133,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'engineer',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'iron',
    r1Qty: 302,
    resource2: 'steel',
    r2Qty: 77,
    resource3: 'gems',
    r3Qty: 11,
    component1: 'ghostflag',
    c1Qty: 3,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.6',
    upgrade2: 'resource1-60',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 450,
    surcharge: 1005,
    suggest: 200,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-09-23',
    shardPrice: 200,
    capriceDelay: 240,
  },
  hermes: {
    uid: 'hermes',
    level: 31,
    type: 'wg',
    xp: 100000,
    craftXp: 1500,
    value: 665000,
    tradeMinMaxValue:
      '165000,210000,330000,500000,830000;8300000,10500000,16500000,25000000,41500000',
    favor: 2569,
    time: 45600,
    atk: 580,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 337,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 15,
    component1: 'sirenshard',
    c1Qty: 4,
    c1Tags: null,
    component2: 'ancientmarble',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-67',
    upgrade3: 'bp:disintegratorgun',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 840,
    surcharge: 1870,
    suggest: 375,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lunargun: {
    uid: 'lunargun',
    level: 32,
    type: 'wg',
    xp: 105000,
    craftXp: 1575,
    value: 675000,
    tradeMinMaxValue:
      '170000,210000,340000,510000,840000;8450000,10500000,17000000,25500000,42000000',
    favor: 2933,
    time: 54600,
    atk: 610,
    def: 153,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 26,
    w2BuildingReq: 22,
    w3BuildingReq: 19,
    resource1: 'iron',
    r1Qty: 354,
    resource2: 'steel',
    r2Qty: 93,
    resource3: 'gems',
    r3Qty: 17,
    component1: 'goldshell',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-71',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'ox',
    tag: null,
    discount: 850,
    surcharge: 1885,
    suggest: 375,
    speedup: 2635,
    buyAnimIdOverride: 16,
    questAnimIdOverride: 12,
    slotOverride: 'weapon_1',
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-02-10',
    shardPrice: 250,
    capriceDelay: 240,
  },
  disintegratorgun: {
    uid: 'disintegratorgun',
    level: 33,
    type: 'wg',
    xp: 170000,
    craftXp: 2550,
    value: 1700000,
    tradeMinMaxValue:
      '430000,530000,850000,1300000,2150000;21500000,26500000,42500000,64000000,106500000',
    favor: 3351,
    time: 65400,
    atk: 720,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 372,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 19,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-74',
    upgrade3: 'bp:t12gun',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1145,
    surcharge: 2545,
    suggest: 510,
    speedup: 2790,
    buyAnimIdOverride: 16,
    questAnimIdOverride: 12,
    slotOverride: 'weapon_1',
    soundType: 'hMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldgun: {
    uid: 'goldgun',
    level: 33,
    type: 'wg',
    xp: 170000,
    craftXp: 2550,
    value: 3400000,
    tradeMinMaxValue:
      '850000,1050000,1700000,2550000,4250000;42500000,53000000,85000000,127500000,212500000',
    favor: 3351,
    time: 65400,
    atk: 720,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 372,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 19,
    component1: 'tabletjewel',
    c1Qty: 5,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-74',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1200,
    surcharge: 2665,
    suggest: 535,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-06-12',
    shardPrice: 0,
    capriceDelay: 0,
  },
  doggun: {
    uid: 'doggun',
    level: 33,
    type: 'wg',
    xp: 170000,
    craftXp: 2550,
    value: 2000000,
    tradeMinMaxValue:
      '500000,630000,1000000,1500000,2500000;25000000,31500000,50000000,75000000,125000000',
    favor: 3351,
    time: 65400,
    atk: 720,
    def: 0,
    hp: 23,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 372,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 19,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-74',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'wolf',
    tag: null,
    discount: 1240,
    surcharge: 2750,
    suggest: 550,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-08-25',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12gun: {
    uid: 't12gun',
    level: 37,
    type: 'wg',
    xp: 365000,
    craftXp: 5475,
    value: 3200000,
    tradeMinMaxValue:
      '800000,1000000,1600000,2400000,4000000;40000000,50000000,80000000,120000000,200000000',
    favor: 5588,
    time: 133200,
    atk: 950,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 445,
    resource2: 'steel',
    r2Qty: 123,
    resource3: 'gems',
    r3Qty: 27,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-89',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13gun',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'resource2-37',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1550,
    surcharge: 3445,
    suggest: 690,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  westerngun: {
    uid: 'westerngun',
    level: 37,
    type: 'wg',
    xp: 365000,
    craftXp: 5475,
    value: 3400000,
    tradeMinMaxValue:
      '850000,1050000,1700000,2550000,4250000;42500000,53000000,85000000,127500000,212500000',
    favor: 5301,
    time: 133200,
    atk: 950,
    def: 238,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'wood',
    r1Qty: 430,
    resource2: 'ironwood',
    r2Qty: 115,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-108',
    upgrade3: 'resource2-23',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 1600,
    surcharge: 3550,
    suggest: 710,
    speedup: 3465,
    buyAnimIdOverride: 16,
    questAnimIdOverride: 12,
    slotOverride: 'weapon_1',
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-06-01',
    shardPrice: 350,
    capriceDelay: 0,
  },
  christmasgun: {
    uid: 'christmasgun',
    level: 38,
    type: 'wg',
    xp: 390000,
    craftXp: 5850,
    value: 3500000,
    tradeMinMaxValue:
      '880000,1100000,1750000,2650000,4400000;44000000,54500000,87500000,131500000,219000000',
    favor: 6344,
    time: 159000,
    atk: 980,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'iron',
    r1Qty: 463,
    resource2: 'steel',
    r2Qty: 134,
    resource3: 'gems',
    r3Qty: 30,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'overgrownvine',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-139',
    upgrade4: 'resource2-27',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-3',
    supgrade2: 'resource3-6',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: 'santa',
    tag: null,
    discount: 1620,
    surcharge: 3600,
    suggest: 720,
    speedup: 3645,
    buyAnimIdOverride: 16,
    questAnimIdOverride: 12,
    slotOverride: 'weapon_1',
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-12-14',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13gun: {
    uid: 't13gun',
    level: 39,
    type: 'wg',
    xp: 410000,
    craftXp: 6150,
    value: 6050000,
    tradeMinMaxValue:
      '1500000,1900000,3050000,4550000,7550000;75500000,94500000,151500000,227000000,378000000',
    favor: 8218,
    time: 190200,
    atk: 1170,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 482,
    resource2: 'steel',
    r2Qty: 139,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-96',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'resource2-35',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 2105,
    surcharge: 4680,
    suggest: 935,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  musicgun: {
    uid: 'musicgun',
    level: 39,
    type: 'wg',
    xp: 410000,
    craftXp: 6150,
    value: 6500000,
    tradeMinMaxValue:
      '1650000,2050000,3250000,4900000,8150000;81500000,101500000,162500000,244000000,406500000',
    favor: 8046,
    time: 190200,
    atk: 1170,
    def: 0,
    hp: 36,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'engineer',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 473,
    resource2: 'steel',
    r2Qty: 139,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voideye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-95',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-35',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 2180,
    surcharge: 4845,
    suggest: 970,
    speedup: 3830,
    buyAnimIdOverride: 16,
    questAnimIdOverride: 12,
    slotOverride: 'weapon_1',
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-03',
    shardPrice: 375,
    capriceDelay: 0,
  },
  antidote: {
    uid: 'antidote',
    level: 3,
    type: 'uh',
    xp: 455,
    craftXp: 7,
    value: 180,
    tradeMinMaxValue: '50,70,110,160,270;2700,3400,5400,8100,13500',
    favor: 10,
    time: 60,
    atk: 8,
    def: 0,
    hp: 2,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 9,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'bp:tea',
    upgrade2: 'bp:grass',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.5',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 25,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  grass: {
    uid: 'grass',
    level: 5,
    type: 'uh',
    xp: 1200,
    craftXp: 18,
    value: 700,
    tradeMinMaxValue: '180,220,350,530,880;8800,11000,17500,26500,44000',
    favor: 16,
    time: 140,
    atk: 13,
    def: 0,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 14,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'bp:powder',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.5',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 45,
    suggest: 10,
    speedup: 70,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  powder: {
    uid: 'powder',
    level: 8,
    type: 'uh',
    xp: 3100,
    craftXp: 47,
    value: 2350,
    tradeMinMaxValue: '590,730,1200,1750,2900;29500,36500,59000,88000,145000',
    favor: 27,
    time: 340,
    atk: 21,
    def: 0,
    hp: 5,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 5,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 21,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'antidote',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'webbedwings',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:healingsalve',
    upgrade4: 'resource1-4',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 85,
    suggest: 15,
    speedup: 135,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 4,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  christmasflower: {
    uid: 'christmasflower',
    level: 9,
    type: 'uh',
    xp: 3950,
    craftXp: 59,
    value: 3150,
    tradeMinMaxValue: '790,980,1600,2400,3900;39500,49000,79000,120000,195000',
    favor: 39,
    time: 480,
    atk: 36,
    def: 0,
    hp: 6,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 26,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.8',
    upgrade3: 'resource1-5',
    upgrade4: 'time*0.8',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'santa',
    tag: null,
    discount: 45,
    surcharge: 95,
    suggest: 20,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-12-09',
    shardPrice: 75,
    capriceDelay: 0,
  },
  healingsalve: {
    uid: 'healingsalve',
    level: 11,
    type: 'uh',
    xp: 6100,
    craftXp: 92,
    value: 4100,
    tradeMinMaxValue:
      '1050,1300,2100,3100,5100;51500,64000,105000,155000,255000',
    favor: 50,
    time: 780,
    atk: 31,
    def: 0,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 8,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 34,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:silverthistle',
    upgrade4: 'resource1-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 50,
    surcharge: 110,
    suggest: 20,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  thanksgivingherb: {
    uid: 'thanksgivingherb',
    level: 12,
    type: 'uh',
    xp: 7300,
    craftXp: 110,
    value: 8650,
    tradeMinMaxValue:
      '2700,3400,5400,8100,13500;135000,170000,270000,405000,675000',
    favor: 71,
    time: 930,
    atk: 51,
    def: 0,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 41,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'grass',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'icypearl',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-8',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 155,
    suggest: 30,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-11-17',
    shardPrice: 100,
    capriceDelay: 240,
  },
  silverthistle: {
    uid: 'silverthistle',
    level: 14,
    type: 'uh',
    xp: 10000,
    craftXp: 150,
    value: 10000,
    tradeMinMaxValue:
      '2500,3100,5000,7500,12500;125000,155000,250000,375000,625000',
    favor: 106,
    time: 1590,
    atk: 41,
    def: 0,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 60,
    resource2: 'oils',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: 'elvenwood',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'bp:bloodvine',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 75,
    surcharge: 165,
    suggest: 35,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  harvest: {
    uid: 'harvest',
    level: 15,
    type: 'uh',
    xp: 12000,
    craftXp: 180,
    value: 16000,
    tradeMinMaxValue:
      '4000,5000,8000,12000,20000;200000,250000,400000,600000,1000000',
    favor: 150,
    time: 2040,
    atk: 66,
    def: 0,
    hp: 11,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 71,
    resource2: 'oils',
    r2Qty: 16,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-14',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-14',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 95,
    surcharge: 210,
    suggest: 40,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-09-24',
    shardPrice: 120,
    capriceDelay: 240,
  },
  thanksgivingherb2: {
    uid: 'thanksgivingherb2',
    level: 16,
    type: 'uh',
    xp: 13500,
    craftXp: 203,
    value: 18000,
    tradeMinMaxValue:
      '4500,5600,9000,13500,23000;225000,280000,450000,675000,1150000',
    favor: 297,
    time: 2400,
    atk: 48,
    def: 0,
    hp: 21,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 8,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 85,
    resource2: 'oils',
    r2Qty: 21,
    resource3: 'iron',
    r3Qty: 21,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-17',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-11',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-11',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 100,
    surcharge: 220,
    suggest: 45,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-11-15',
    shardPrice: 120,
    capriceDelay: 0,
  },
  bloodvine: {
    uid: 'bloodvine',
    level: 17,
    type: 'uh',
    xp: 15500,
    craftXp: 233,
    value: 24500,
    tradeMinMaxValue:
      '6100,7700,12500,18500,31000;305000,385000,615000,920000,1550000',
    favor: 216,
    time: 3000,
    atk: 53,
    def: 0,
    hp: 13,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'herbs',
    r1Qty: 99,
    resource2: 'oils',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'sharpfang',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:mandragoraroot',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 255,
    suggest: 50,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  bouquet: {
    uid: 'bouquet',
    level: 18,
    type: 'uh',
    xp: 17500,
    craftXp: 263,
    value: 37000,
    tradeMinMaxValue:
      '9300,11500,18500,28000,46000;465000,580000,925000,1400000,2300000',
    favor: 296,
    time: 3600,
    atk: 83,
    def: 0,
    hp: 14,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'herbs',
    r1Qty: 113,
    resource2: 'oils',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'mooncrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'arcanedust',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 12,
    u2Req: 30,
    u3Req: 54,
    u4Req: 85,
    u5Req: 135,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 140,
    surcharge: 310,
    suggest: 60,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-07-14',
    shardPrice: 140,
    capriceDelay: 240,
  },
  mushroomherb: {
    uid: 'mushroomherb',
    level: 18,
    type: 'uh',
    xp: 17500,
    craftXp: 263,
    value: 35000,
    tradeMinMaxValue:
      '8800,11000,17500,26000,44000;440000,545000,875000,1300000,2200000',
    favor: 287,
    time: 3600,
    atk: 55,
    def: 0,
    hp: 21,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'herbs',
    r1Qty: 110,
    resource2: 'oils',
    r2Qty: 27,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-22',
    upgrade4: 'resource2-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 135,
    surcharge: 305,
    suggest: 60,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-05-17',
    shardPrice: 140,
    capriceDelay: 0,
  },
  mandragoraroot: {
    uid: 'mandragoraroot',
    level: 20,
    type: 'uh',
    xp: 22000,
    craftXp: 330,
    value: 68500,
    tradeMinMaxValue:
      '17000,21000,34000,51000,86000;855000,1050000,1700000,2550000,4300000',
    favor: 379,
    time: 6000,
    atk: 63,
    def: 0,
    hp: 16,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'herbs',
    r1Qty: 141,
    resource2: 'oils',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'silkscarab',
    c1Qty: 4,
    c1Tags: null,
    component2: 'livingroots',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:wolfsbane',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 190,
    surcharge: 420,
    suggest: 85,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  valentineherb: {
    uid: 'valentineherb',
    level: 22,
    type: 'uh',
    xp: 27000,
    craftXp: 405,
    value: 100000,
    tradeMinMaxValue:
      '25000,31000,50000,75000,125000;1250000,1550000,2500000,3750000,6250000',
    favor: 591,
    time: 8400,
    atk: 70,
    def: 0,
    hp: 27,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'herbs',
    r1Qty: 170,
    resource2: 'oils',
    r2Qty: 43,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 6,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'component2-2',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-9',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-2',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 225,
    surcharge: 500,
    suggest: 100,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-02-08',
    shardPrice: 160,
    capriceDelay: 0,
  },
  wolfsbane: {
    uid: 'wolfsbane',
    level: 24,
    type: 'uh',
    xp: 32500,
    craftXp: 488,
    value: 180000,
    tradeMinMaxValue:
      '45000,56000,90000,135000,230000;2250000,2800000,4500000,6750000,11500000',
    favor: 753,
    time: 11400,
    atk: 80,
    def: 0,
    hp: 20,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'herbs',
    r1Qty: 199,
    resource2: 'oils',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 5,
    component1: 'witchrobe',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'timecrystal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:yggdrasilbranch',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 300,
    surcharge: 665,
    suggest: 135,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ruinsherb: {
    uid: 'ruinsherb',
    level: 25,
    type: 'uh',
    xp: 35500,
    craftXp: 533,
    value: 215000,
    tradeMinMaxValue:
      '56000,70000,110000,165000,280000;2800000,3500000,5600000,8350000,14000000',
    favor: 981,
    time: 16200,
    atk: 83,
    def: 0,
    hp: 21,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 8,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'herbs',
    r1Qty: 214,
    resource2: 'oils',
    r2Qty: 57,
    resource3: 'mana',
    r3Qty: 6,
    component1: 'overgrownvine',
    c1Qty: 5,
    c1Tags: null,
    component2: 'icypearl',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'tidal',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 325,
    surcharge: 725,
    suggest: 145,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: 'ruins',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  yggdrasilbranch: {
    uid: 'yggdrasilbranch',
    level: 27,
    type: 'uh',
    xp: 42000,
    craftXp: 630,
    value: 305000,
    tradeMinMaxValue:
      '76000,95000,155000,230000,380000;3800000,4750000,7650000,11500000,19000000',
    favor: 1190,
    time: 16200,
    atk: 93,
    def: 0,
    hp: 23,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'herbs',
    r1Qty: 244,
    resource2: 'oils',
    r2Qty: 67,
    resource3: 'mana',
    r3Qty: 10,
    component1: 'healingsalve',
    c1Qty: 2,
    c1Tags: 'common',
    component2: 'sewingthread',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:wyrmblood',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 385,
    surcharge: 860,
    suggest: 170,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldenclover: {
    uid: 'goldenclover',
    level: 28,
    type: 'uh',
    xp: 45500,
    craftXp: 683,
    value: 370000,
    tradeMinMaxValue:
      '93000,115000,185000,280000,460000;4650000,5800000,9250000,14000000,23000000',
    favor: 1538,
    time: 20400,
    atk: 143,
    def: 0,
    hp: 24,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'herbs',
    r1Qty: 259,
    resource2: 'oils',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 12,
    component1: 'overgrownvine',
    c1Qty: 6,
    c1Tags: null,
    component2: 'arcanedust',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-2',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 425,
    surcharge: 940,
    suggest: 190,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-03-10',
    shardPrice: 200,
    capriceDelay: 240,
  },
  goldherb: {
    uid: 'goldherb',
    level: 29,
    type: 'uh',
    xp: 49000,
    craftXp: 735,
    value: 595000,
    tradeMinMaxValue:
      '150000,185000,300000,450000,740000;7450000,9300000,15000000,22500000,37000000',
    favor: 1777,
    time: 24600,
    atk: 100,
    def: 0,
    hp: 25,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'herbs',
    r1Qty: 275,
    resource2: 'oils',
    r2Qty: 77,
    resource3: 'mana',
    r3Qty: 14,
    component1: 'tabletfragment',
    c1Qty: 5,
    c1Tags: null,
    component2: 'ectoplasm',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'luxuriousspirit',
    tag: null,
    discount: 400,
    surcharge: 885,
    suggest: 175,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-05-16',
    shardPrice: 0,
    capriceDelay: 0,
  },
  wyrmblood: {
    uid: 'wyrmblood',
    level: 31,
    type: 'uh',
    xp: 56500,
    craftXp: 848,
    value: 635000,
    tradeMinMaxValue:
      '160000,200000,320000,480000,790000;7950000,9900000,16000000,24000000,39500000',
    favor: 2103,
    time: 34800,
    atk: 110,
    def: 0,
    hp: 27,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 306,
    resource2: 'oils',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 19,
    component1: 'deepcoral',
    c1Qty: 5,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'bp:eldritchtendrils',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 825,
    surcharge: 1830,
    suggest: 365,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  couplebouquet: {
    uid: 'couplebouquet',
    level: 32,
    type: 'uh',
    xp: 61000,
    craftXp: 915,
    value: 650000,
    tradeMinMaxValue:
      '165000,200000,330000,490000,810000;8150000,10000000,16500000,24500000,40500000',
    favor: 2394,
    time: 40800,
    atk: 173,
    def: 0,
    hp: 29,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 26,
    w2BuildingReq: 22,
    w3BuildingReq: 19,
    resource1: 'wood',
    r1Qty: 290,
    resource2: 'ironwood',
    r2Qty: 93,
    resource3: 'mana',
    r3Qty: 22,
    component1: 'bo',
    c1Qty: 2,
    c1Tags: 'uncommon',
    component2: 'sirenshard',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 835,
    surcharge: 1850,
    suggest: 370,
    speedup: 2635,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-02-24',
    shardPrice: 250,
    capriceDelay: 0,
  },
  eldritchtendrils: {
    uid: 'eldritchtendrils',
    level: 33,
    type: 'uh',
    xp: 97500,
    craftXp: 1463,
    value: 1300000,
    tradeMinMaxValue:
      '330000,410000,650000,980000,1650000;16500000,20500000,32500000,49000000,81500000',
    favor: 2736,
    time: 50400,
    atk: 135,
    def: 0,
    hp: 34,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 338,
    resource2: 'oils',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 23,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12herb',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1005,
    surcharge: 2235,
    suggest: 445,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  peakherbs: {
    uid: 'peakherbs',
    level: 33,
    type: 'uh',
    xp: 97500,
    craftXp: 1463,
    value: 1550000,
    tradeMinMaxValue:
      '390000,490000,790000,1200000,1950000;19500000,24500000,39500000,59000000,98500000',
    favor: 3061,
    time: 50400,
    atk: 135,
    def: 0,
    hp: 34,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 338,
    resource2: 'oils',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 23,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'primal',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 1095,
    surcharge: 2435,
    suggest: 485,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: 'peak',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  circusherb: {
    uid: 'circusherb',
    level: 33,
    type: 'uh',
    xp: 97500,
    craftXp: 1463,
    value: 1700000,
    tradeMinMaxValue:
      '430000,530000,850000,1300000,2150000;21500000,26500000,42500000,64000000,106500000',
    favor: 3061,
    time: 50400,
    atk: 135,
    def: 0,
    hp: 51,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 338,
    resource2: 'oils',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 23,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1145,
    surcharge: 2545,
    suggest: 510,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-06-15',
    shardPrice: 300,
    capriceDelay: 0,
  },
  candyherb: {
    uid: 'candyherb',
    level: 34,
    type: 'uh',
    xp: 105000,
    craftXp: 1575,
    value: 1650000,
    tradeMinMaxValue:
      '410000,520000,830000,1250000,2050000;20500000,26000000,41500000,62000000,103000000',
    favor: 3479,
    time: 60000,
    atk: 210,
    def: 0,
    hp: 35,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 354,
    resource2: 'oils',
    r2Qty: 103,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'ancientmarble',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-71',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-26',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'resource2-26',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1130,
    surcharge: 2510,
    suggest: 500,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-09-08',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12herb: {
    uid: 't12herb',
    level: 36,
    type: 'uh',
    xp: 195000,
    craftXp: 2925,
    value: 2700000,
    tradeMinMaxValue:
      '680000,840000,1350000,2050000,3400000;34000000,42000000,67500000,101500000,169000000',
    favor: 4046,
    time: 85800,
    atk: 173,
    def: 0,
    hp: 43,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'herbs',
    r1Qty: 387,
    resource2: 'oils',
    r2Qty: 113,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13herb',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'resource2-34',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1430,
    surcharge: 3175,
    suggest: 635,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  blossomherb: {
    uid: 'blossomherb',
    level: 36,
    type: 'uh',
    xp: 195000,
    craftXp: 2925,
    value: 3000000,
    tradeMinMaxValue:
      '750000,940000,1500000,2250000,3750000;37500000,47000000,75000000,112500000,187500000',
    favor: 4431,
    time: 85800,
    atk: 173,
    def: 0,
    hp: 64,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'herbs',
    r1Qty: 380,
    resource2: 'oils',
    r2Qty: 110,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'ectoplasm',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-76',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-6',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 1505,
    surcharge: 3340,
    suggest: 670,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-03-23',
    shardPrice: 350,
    capriceDelay: 0,
  },
  goldherb2: {
    uid: 'goldherb2',
    level: 36,
    type: 'uh',
    xp: 195000,
    craftXp: 2925,
    value: 5400000,
    tradeMinMaxValue:
      '1350000,1700000,2700000,4050000,6750000;67500000,84500000,135000000,202500000,337500000',
    favor: 4528,
    time: 85800,
    atk: 173,
    def: 0,
    hp: 43,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'herbs',
    r1Qty: 387,
    resource2: 'oils',
    r2Qty: 113,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1495,
    surcharge: 3325,
    suggest: 665,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-10-28',
    shardPrice: 0,
    capriceDelay: 0,
  },
  fruitherb: {
    uid: 'fruitherb',
    level: 37,
    type: 'uh',
    xp: 210000,
    craftXp: 3150,
    value: 3100000,
    tradeMinMaxValue:
      '780000,970000,1550000,2350000,3900000;39000000,48500000,77500000,116500000,194000000',
    favor: 5154,
    time: 102600,
    atk: 266,
    def: 0,
    hp: 44,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'herbs',
    r1Qty: 404,
    resource2: 'oils',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 34,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'component2-1',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-81',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-25',
    supgrade2: 'resource3-10',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1530,
    surcharge: 3395,
    suggest: 680,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-08-24',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13herb: {
    uid: 't13herb',
    level: 39,
    type: 'uh',
    xp: 235000,
    craftXp: 3525,
    value: 5050000,
    tradeMinMaxValue:
      '1250000,1600000,2550000,3800000,6300000;63000000,79000000,126500000,189500000,315500000',
    favor: 6883,
    time: 146400,
    atk: 218,
    def: 0,
    hp: 55,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'oils',
    r1Qty: 139,
    resource2: 'mana',
    r2Qty: 39,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcloth',
    c1Qty: 3,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-28',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-10',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1930,
    surcharge: 4290,
    suggest: 860,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  musicherb: {
    uid: 'musicherb',
    level: 39,
    type: 'uh',
    xp: 235000,
    craftXp: 3525,
    value: 5450000,
    tradeMinMaxValue:
      '1350000,1700000,2750000,4100000,6800000;68000000,85000000,136500000,204500000,340500000',
    favor: 6396,
    time: 146400,
    atk: 218,
    def: 0,
    hp: 82,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'oils',
    r1Qty: 126,
    resource2: 'mana',
    r2Qty: 34,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-25',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'resource2-7',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 2005,
    surcharge: 4450,
    suggest: 890,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-03',
    shardPrice: 375,
    capriceDelay: 0,
  },
  dreamherb: {
    uid: 'dreamherb',
    level: 40,
    type: 'uh',
    xp: 245000,
    craftXp: 3675,
    value: 5650000,
    tradeMinMaxValue:
      '1400000,1750000,2850000,4250000,7050000;70500000,88500000,141500000,212000000,353000000',
    favor: 6607,
    time: 174600,
    atk: 338,
    def: 0,
    hp: 57,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'oils',
    r1Qty: 115,
    resource2: 'mana',
    r2Qty: 32,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voideye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 15,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'component2-5',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-5',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 2040,
    surcharge: 4530,
    suggest: 905,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-07-11',
    shardPrice: 375,
    capriceDelay: 0,
  },
  tea: {
    uid: 'tea',
    level: 4,
    type: 'up',
    xp: 810,
    craftXp: 12,
    value: 280,
    tradeMinMaxValue: '80,110,170,250,420;4200,5300,8400,12500,21000',
    favor: 13,
    time: 100,
    atk: 0,
    def: 0,
    hp: 5,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 9,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 12,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:smallhealthpotion',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.5',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 15,
    surcharge: 30,
    suggest: 5,
    speedup: 55,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  smallhealthpotion: {
    uid: 'smallhealthpotion',
    level: 7,
    type: 'up',
    xp: 2500,
    craftXp: 38,
    value: 980,
    tradeMinMaxValue: '290,370,590,880,1450;14500,18500,29500,44000,73500',
    favor: 21,
    time: 280,
    atk: 0,
    def: 0,
    hp: 9,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 17,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:smallmanapotion',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.5',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 55,
    suggest: 10,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  smallmanapotion: {
    uid: 'smallmanapotion',
    level: 10,
    type: 'up',
    xp: 5300,
    craftXp: 80,
    value: 3850,
    tradeMinMaxValue: '960,1200,1950,2900,4800;48000,60000,96500,145000,240000',
    favor: 42,
    time: 630,
    atk: 0,
    def: 0,
    hp: 14,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 7,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 30,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 3,
    c1Tags: null,
    component2: 'glowingmushrooms',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:healthpotion',
    upgrade4: 'resource1-6',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 45,
    surcharge: 105,
    suggest: 20,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  scienceexperiment: {
    uid: 'scienceexperiment',
    level: 11,
    type: 'up',
    xp: 6450,
    craftXp: 97,
    value: 4050,
    tradeMinMaxValue:
      '1200,1500,2400,3600,6100;61000,76000,120000,180000,305000',
    favor: 56,
    time: 630,
    atk: 16,
    def: 0,
    hp: 15,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 8,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 34,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'smallhealthpotion',
    c1Qty: 1,
    c1Tags: 'common',
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.8',
    upgrade3: 'resource1-7',
    upgrade4: 'time*0.8',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 50,
    surcharge: 110,
    suggest: 20,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-08-27',
    shardPrice: 75,
    capriceDelay: 240,
  },
  healthpotion: {
    uid: 'healthpotion',
    level: 13,
    type: 'up',
    xp: 9250,
    craftXp: 139,
    value: 7000,
    tradeMinMaxValue:
      '1750,2200,3500,5300,8800;87500,110000,175000,265000,440000',
    favor: 81,
    time: 1290,
    atk: 0,
    def: 0,
    hp: 19,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 8,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 49,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:magicpotion',
    upgrade4: 'resource1-10',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 65,
    surcharge: 140,
    suggest: 30,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  victorianpotion: {
    uid: 'victorianpotion',
    level: 15,
    type: 'up',
    xp: 12500,
    craftXp: 188,
    value: 12000,
    tradeMinMaxValue:
      '3000,3800,6000,9000,15000;150000,190000,300000,450000,750000',
    favor: 150,
    time: 2040,
    atk: 0,
    def: 44,
    hp: 22,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 71,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 2,
    c1Tags: null,
    component2: 'glowingmushrooms',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-11',
    upgrade3: 'time*0.75',
    upgrade4: 'resource1-11',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 80,
    surcharge: 180,
    suggest: 35,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-04-06',
    shardPrice: 100,
    capriceDelay: 240,
  },
  magicpotion: {
    uid: 'magicpotion',
    level: 16,
    type: 'up',
    xp: 14500,
    craftXp: 218,
    value: 13500,
    tradeMinMaxValue:
      '3400,4200,6800,10000,17000;170000,210000,340000,505000,845000',
    favor: 173,
    time: 2400,
    atk: 0,
    def: 0,
    hp: 24,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 8,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 85,
    resource2: 'oils',
    r2Qty: 21,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'bp:largehealthpotion',
    upgrade4: 'resource1-17',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-2',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 85,
    surcharge: 190,
    suggest: 40,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  shoprpotion: {
    uid: 'shoprpotion',
    level: 17,
    type: 'up',
    xp: 16500,
    craftXp: 248,
    value: 24000,
    tradeMinMaxValue:
      '6000,7500,12000,18000,30000;300000,375000,600000,900000,1500000',
    favor: 242,
    time: 3300,
    atk: 0,
    def: 53,
    hp: 26,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'herbalist',
    worker2: null,
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'herbs',
    r1Qty: 99,
    resource2: 'oils',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.7',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-20',
    upgrade4: 'resource2-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.15',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 255,
    suggest: 50,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-06-14',
    shardPrice: 120,
    capriceDelay: 0,
  },
  poloniapotion: {
    uid: 'poloniapotion',
    level: 18,
    type: 'up',
    xp: 18500,
    craftXp: 278,
    value: 25000,
    tradeMinMaxValue:
      '6300,7800,12500,19000,31000;315000,390000,625000,940000,1550000',
    favor: 296,
    time: 4200,
    atk: 28,
    def: 0,
    hp: 28,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'herbs',
    r1Qty: 113,
    resource2: 'oils',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 3,
    c1Tags: null,
    component2: 'arcanedust',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 12,
    u2Req: 30,
    u3Req: 54,
    u4Req: 85,
    u5Req: 135,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-9',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 260,
    suggest: 50,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-04-21',
    shardPrice: 120,
    capriceDelay: 240,
  },
  largehealthpotion: {
    uid: 'largehealthpotion',
    level: 18,
    type: 'up',
    xp: 18500,
    craftXp: 278,
    value: 31500,
    tradeMinMaxValue:
      '7900,9800,16000,24000,39000;395000,490000,790000,1200000,1950000',
    favor: 265,
    time: 4200,
    atk: 0,
    def: 0,
    hp: 28,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'herbs',
    r1Qty: 113,
    resource2: 'oils',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'mooncrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'redpinecone',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'bp:vialofrenewal',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-5',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 130,
    surcharge: 290,
    suggest: 60,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  cincopotion: {
    uid: 'cincopotion',
    level: 19,
    type: 'up',
    xp: 21000,
    craftXp: 315,
    value: 54500,
    tradeMinMaxValue:
      '13500,17000,27000,41000,68000;680000,850000,1350000,2050000,3400000',
    favor: 357,
    time: 4200,
    atk: 0,
    def: 60,
    hp: 30,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 16,
    w2BuildingReq: 12,
    w3BuildingReq: 6,
    resource1: 'herbs',
    r1Qty: 127,
    resource2: 'oils',
    r2Qty: 33,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 5,
    c1Tags: null,
    component2: 'tea',
    c2Qty: 1,
    c2Tags: 'uncommon',
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-25',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 170,
    surcharge: 375,
    suggest: 75,
    speedup: 565,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-05-04',
    shardPrice: 140,
    capriceDelay: 240,
  },
  vialofrenewal: {
    uid: 'vialofrenewal',
    level: 20,
    type: 'up',
    xp: 23500,
    craftXp: 353,
    value: 72500,
    tradeMinMaxValue:
      '18000,23000,36000,54000,91000;905000,1150000,1800000,2700000,4550000',
    favor: 379,
    time: 6000,
    atk: 0,
    def: 0,
    hp: 32,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'herbs',
    r1Qty: 141,
    resource2: 'oils',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'evileye',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:purplebomb',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 195,
    surcharge: 430,
    suggest: 85,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pyramidpotion: {
    uid: 'pyramidpotion',
    level: 21,
    type: 'up',
    xp: 26000,
    craftXp: 390,
    value: 94000,
    tradeMinMaxValue:
      '25000,32000,51000,76000,125000;1250000,1600000,2550000,3800000,6350000',
    favor: 502,
    time: 7200,
    atk: 0,
    def: 0,
    hp: 34,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 7,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'herbs',
    r1Qty: 155,
    resource2: 'oils',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'silkscarab',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'holy',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 220,
    surcharge: 490,
    suggest: 100,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: 'pyramid',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  epicpotion: {
    uid: 'epicpotion',
    level: 22,
    type: 'up',
    xp: 29000,
    craftXp: 435,
    value: 100000,
    tradeMinMaxValue:
      '25000,31000,50000,75000,125000;1250000,1550000,2500000,3750000,6250000',
    favor: 591,
    time: 7800,
    atk: 0,
    def: 70,
    hp: 36,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 7,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'herbs',
    r1Qty: 170,
    resource2: 'oils',
    r2Qty: 43,
    resource3: null,
    r3Qty: 0,
    component1: 'magicpotion',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'mooncrystal',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-34',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-9',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 225,
    surcharge: 500,
    suggest: 100,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-07-21',
    shardPrice: 160,
    capriceDelay: 0,
  },
  purplebomb: {
    uid: 'purplebomb',
    level: 24,
    type: 'up',
    xp: 34500,
    craftXp: 518,
    value: 190000,
    tradeMinMaxValue:
      '48000,59000,95000,145000,240000;2400000,2950000,4750000,7150000,12000000',
    favor: 771,
    time: 13200,
    atk: 40,
    def: 0,
    hp: 40,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'herbs',
    r1Qty: 199,
    resource2: 'oils',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 7,
    component1: 'timecrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:soulfireextract',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-4',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 310,
    surcharge: 685,
    suggest: 135,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pumpkinpotion: {
    uid: 'pumpkinpotion',
    level: 25,
    type: 'up',
    xp: 38000,
    craftXp: 570,
    value: 225000,
    tradeMinMaxValue:
      '56000,70000,115000,170000,280000;2800000,3500000,5650000,8450000,14000000',
    favor: 1001,
    time: 15600,
    atk: 83,
    def: 0,
    hp: 42,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'herbs',
    r1Qty: 214,
    resource2: 'oils',
    r2Qty: 57,
    resource3: 'mana',
    r3Qty: 8,
    component1: 'healthpotion',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'overgrownvine',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-11',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 335,
    surcharge: 740,
    suggest: 150,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-11-19',
    shardPrice: 180,
    capriceDelay: 240,
  },
  nayapotion: {
    uid: 'nayapotion',
    level: 25,
    type: 'up',
    xp: 38000,
    craftXp: 570,
    value: 230000,
    tradeMinMaxValue:
      '58000,72000,115000,175000,290000;2900000,3600000,5750000,8650000,14500000',
    favor: 922,
    time: 16200,
    atk: 0,
    def: 83,
    hp: 42,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 8,
    combo: 7,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'herbs',
    r1Qty: 214,
    resource2: 'oils',
    r2Qty: 57,
    resource3: null,
    r3Qty: 0,
    component1: 'overgrownvine',
    c1Qty: 4,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 340,
    surcharge: 750,
    suggest: 150,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-05-30',
    shardPrice: 180,
    capriceDelay: 0,
  },
  cincopotion2: {
    uid: 'cincopotion2',
    level: 26,
    type: 'up',
    xp: 41500,
    craftXp: 623,
    value: 240000,
    tradeMinMaxValue:
      '60000,75000,120000,180000,300000;3000000,3750000,6000000,9000000,15000000',
    favor: 1168,
    time: 19800,
    atk: 88,
    def: 0,
    hp: 44,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 8,
    combo: 6,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 13,
    resource1: 'herbs',
    r1Qty: 229,
    resource2: 'oils',
    r2Qty: 62,
    resource3: 'mana',
    r3Qty: 10,
    component1: 'overgrownvine',
    c1Qty: 3,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-46',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-12',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 345,
    surcharge: 765,
    suggest: 155,
    speedup: 995,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-03',
    shardPrice: 180,
    capriceDelay: 0,
  },
  soulfireextract: {
    uid: 'soulfireextract',
    level: 27,
    type: 'up',
    xp: 45000,
    craftXp: 675,
    value: 295000,
    tradeMinMaxValue:
      '74000,92000,150000,220000,370000;3700000,4600000,7400000,11000000,18500000',
    favor: 1224,
    time: 16200,
    atk: 0,
    def: 0,
    hp: 46,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'herbs',
    r1Qty: 244,
    resource2: 'oils',
    r2Qty: 67,
    resource3: 'mana',
    r3Qty: 13,
    component1: 'light',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'ectoplasm',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:gaiatonic',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'cquality1-1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 380,
    surcharge: 845,
    suggest: 170,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lemonpotion: {
    uid: 'lemonpotion',
    level: 28,
    type: 'up',
    xp: 48500,
    craftXp: 728,
    value: 405000,
    tradeMinMaxValue:
      '100000,125000,200000,300000,510000;5050000,6350000,10000000,15000000,25500000',
    favor: 1589,
    time: 21000,
    atk: 95,
    def: 0,
    hp: 48,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'herbs',
    r1Qty: 259,
    resource2: 'oils',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 16,
    component1: 'sewingthread',
    c1Qty: 3,
    c1Tags: null,
    component2: 'overgrownvine',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 445,
    surcharge: 985,
    suggest: 195,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-05-19',
    shardPrice: 200,
    capriceDelay: 240,
  },
  carpenterpotion: {
    uid: 'carpenterpotion',
    level: 29,
    type: 'up',
    xp: 52500,
    craftXp: 788,
    value: 410000,
    tradeMinMaxValue:
      '105000,130000,210000,310000,510000;5150000,6400000,10500000,15500000,25500000',
    favor: 1847,
    time: 25200,
    atk: 0,
    def: 100,
    hp: 50,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'wood',
    r1Qty: 275,
    resource2: 'ironwood',
    r2Qty: 77,
    resource3: 'mana',
    r3Qty: 19,
    component1: 'ectoplasm',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 445,
    surcharge: 990,
    suggest: 200,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-07-15',
    shardPrice: 200,
    capriceDelay: 240,
  },
  gaiatonic: {
    uid: 'gaiatonic',
    level: 30,
    type: 'up',
    xp: 56500,
    craftXp: 848,
    value: 620000,
    tradeMinMaxValue:
      '155000,195000,310000,470000,780000;7750000,9700000,15500000,23500000,39000000',
    favor: 1909,
    time: 30000,
    atk: 0,
    def: 0,
    hp: 53,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 290,
    resource2: 'oils',
    r2Qty: 82,
    resource3: 'mana',
    r3Qty: 22,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: 'overgrownvine',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:starextract',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 815,
    surcharge: 1810,
    suggest: 360,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  batpotion: {
    uid: 'batpotion',
    level: 30,
    type: 'up',
    xp: 56500,
    craftXp: 848,
    value: 645000,
    tradeMinMaxValue:
      '160000,200000,320000,480000,810000;8050000,10000000,16000000,24000000,40500000',
    favor: 2128,
    time: 30000,
    atk: 105,
    def: 0,
    hp: 53,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 290,
    resource2: 'oils',
    r2Qty: 82,
    resource3: 'mana',
    r3Qty: 22,
    component1: 'sirenshard',
    c1Qty: 4,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-16',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 830,
    surcharge: 1845,
    suggest: 370,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-10-21',
    shardPrice: 250,
    capriceDelay: 240,
  },
  goldpotion: {
    uid: 'goldpotion',
    level: 31,
    type: 'up',
    xp: 60500,
    craftXp: 908,
    value: 1550000,
    tradeMinMaxValue:
      '390000,480000,780000,1150000,1950000;19500000,24000000,39000000,58000000,97000000',
    favor: 2451,
    time: 36000,
    atk: 0,
    def: 0,
    hp: 55,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 6,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 306,
    resource2: 'oils',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'tabletjewel',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sirenshard',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 950,
    surcharge: 2110,
    suggest: 420,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-12-25',
    shardPrice: 0,
    capriceDelay: 0,
  },
  starextract: {
    uid: 'starextract',
    level: 33,
    type: 'up',
    xp: 105000,
    craftXp: 1575,
    value: 1600000,
    tradeMinMaxValue:
      '400000,500000,800000,1200000,2000000;20000000,25000000,40000000,60000000,100000000',
    favor: 2889,
    time: 51600,
    atk: 0,
    def: 0,
    hp: 68,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 338,
    resource2: 'oils',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12potion',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1110,
    surcharge: 2470,
    suggest: 495,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  yetipotion: {
    uid: 'yetipotion',
    level: 33,
    type: 'up',
    xp: 105000,
    craftXp: 1575,
    value: 1800000,
    tradeMinMaxValue:
      '450000,560000,900000,1350000,2250000;22500000,28000000,45000000,67500000,112500000',
    favor: 3218,
    time: 51600,
    atk: 0,
    def: 135,
    hp: 68,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 338,
    resource2: 'oils',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'sirenshard',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1175,
    surcharge: 2615,
    suggest: 525,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-08-11',
    shardPrice: 300,
    capriceDelay: 0,
  },
  frogpotion: {
    uid: 'frogpotion',
    level: 34,
    type: 'up',
    xp: 110000,
    craftXp: 1650,
    value: 1700000,
    tradeMinMaxValue:
      '430000,530000,850000,1300000,2150000;21500000,26500000,42500000,64000000,106500000',
    favor: 3047,
    time: 61800,
    atk: 140,
    def: 0,
    hp: 71,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 300,
    resource2: 'oils',
    r2Qty: 103,
    resource3: 'mana',
    r3Qty: 34,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'overgrownvine',
    c2Qty: 10,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-21',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1145,
    surcharge: 2545,
    suggest: 510,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-01-11',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12potion: {
    uid: 't12potion',
    level: 36,
    type: 'up',
    xp: 210000,
    craftXp: 3150,
    value: 2500000,
    tradeMinMaxValue:
      '630000,780000,1250000,1900000,3150000;31500000,39000000,62500000,94000000,156500000',
    favor: 4293,
    time: 88200,
    atk: 0,
    def: 0,
    hp: 86,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'herbs',
    r1Qty: 387,
    resource2: 'oils',
    r2Qty: 113,
    resource3: 'mana',
    r3Qty: 41,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13potion',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-34',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 1375,
    surcharge: 3060,
    suggest: 610,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  mundrapotion: {
    uid: 'mundrapotion',
    level: 36,
    type: 'up',
    xp: 210000,
    craftXp: 6300,
    value: 2800000,
    tradeMinMaxValue:
      '710000,880000,1400000,2100000,3550000;35500000,44000000,70500000,106000000,176500000',
    favor: 4782,
    time: 88200,
    atk: 0,
    def: 173,
    hp: 86,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'moondragon',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'herbs',
    r1Qty: 387,
    resource2: 'oils',
    r2Qty: 113,
    resource3: 'mana',
    r3Qty: 41,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'timecrystal',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:mundrashield',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-28',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: 'dragon',
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1455,
    surcharge: 3235,
    suggest: 645,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  volcanopotion: {
    uid: 'volcanopotion',
    level: 37,
    type: 'up',
    xp: 220000,
    craftXp: 3300,
    value: 2600000,
    tradeMinMaxValue:
      '670000,840000,1350000,2000000,3350000;33500000,42000000,67000000,100500000,167500000',
    favor: 5488,
    time: 105600,
    atk: 0,
    def: 0,
    hp: 89,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'herbs',
    r1Qty: 404,
    resource2: 'oils',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 46,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'sirenshard',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'resource1-101',
    upgrade2: 'time*0.8',
    upgrade3: 'resource2-18',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'torrent',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 1405,
    surcharge: 3120,
    suggest: 625,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: 'volcano',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  beachpotion: {
    uid: 'beachpotion',
    level: 37,
    type: 'up',
    xp: 220000,
    craftXp: 3300,
    value: 2850000,
    tradeMinMaxValue:
      '710000,890000,1450000,2150000,3550000;35500000,44500000,71500000,107000000,178000000',
    favor: 5209,
    time: 105600,
    atk: 178,
    def: 0,
    hp: 89,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'sundragon',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'herbs',
    r1Qty: 404,
    resource2: 'oils',
    r2Qty: 123,
    resource3: 'gems',
    r3Qty: 36,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'resource1-81',
    upgrade2: 'resource2-18',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-7',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'time*0.8',
    supgrade2: 'resource2-18',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1465,
    surcharge: 3260,
    suggest: 650,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-07-27',
    shardPrice: 350,
    capriceDelay: 0,
  },
  maplepotion: {
    uid: 'maplepotion',
    level: 38,
    type: 'up',
    xp: 235000,
    craftXp: 3525,
    value: 3000000,
    tradeMinMaxValue:
      '750000,940000,1500000,2250000,3750000;37500000,47000000,75000000,112500000,187500000',
    favor: 6249,
    time: 126000,
    atk: 0,
    def: 183,
    hp: 92,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'cook',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'herbs',
    r1Qty: 421,
    resource2: 'oils',
    r2Qty: 134,
    resource3: 'mana',
    r3Qty: 50,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'resource1-84',
    upgrade2: 'resource1-84',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-10',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'time*0.8',
    supgrade2: 'resource2-27',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1505,
    surcharge: 3340,
    suggest: 670,
    speedup: 3645,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-03-08',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13potion: {
    uid: 't13potion',
    level: 39,
    type: 'up',
    xp: 250000,
    craftXp: 3750,
    value: 5350000,
    tradeMinMaxValue:
      '1350000,1650000,2700000,4000000,6700000;67000000,83500000,134000000,200500000,334500000',
    favor: 6656,
    time: 150600,
    atk: 0,
    def: 0,
    hp: 109,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'herbs',
    r1Qty: 438,
    resource2: 'mana',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-88',
    upgrade3: 'resource2-13',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: 'phoenix',
    tag: null,
    discount: 1985,
    surcharge: 4410,
    suggest: 880,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  arcadepotion: {
    uid: 'arcadepotion',
    level: 39,
    type: 'up',
    xp: 250000,
    craftXp: 3750,
    value: 5550000,
    tradeMinMaxValue:
      '1400000,1750000,2800000,4150000,6950000;69500000,86500000,139000000,208000000,347000000',
    favor: 7164,
    time: 150600,
    atk: 0,
    def: 218,
    hp: 109,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'herbalist',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'herbs',
    r1Qty: 426,
    resource2: 'mana',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voideye',
    c1Qty: 2,
    c1Tags: null,
    component2: 'sirenshard',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'resource1-85',
    upgrade2: 'time*0.8',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-13',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'carbuncle',
    tag: null,
    discount: 2020,
    surcharge: 4490,
    suggest: 900,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-16',
    shardPrice: 375,
    capriceDelay: 0,
  },
  scrolloffire: {
    uid: 'scrolloffire',
    level: 3,
    type: 'us',
    xp: 495,
    craftXp: 7,
    value: 320,
    tradeMinMaxValue: '80,100,160,240,400;4000,5000,8000,12000,20000',
    favor: 10,
    time: 70,
    atk: 44,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 9,
    worker1: 'wizard',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'bp:scrollofthunder',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 15,
    surcharge: 30,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  forestscroll: {
    uid: 'forestscroll',
    level: 4,
    type: 'us',
    xp: 840,
    craftXp: 13,
    value: 690,
    tradeMinMaxValue: '340,430,690,1050,1700;17000,21500,34500,51500,86000',
    favor: 15,
    time: 100,
    atk: 60,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 1,
    combo: 9,
    worker1: 'wizard',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 12,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-2',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'light',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 20,
    surcharge: 45,
    suggest: 10,
    speedup: 55,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: 'forest',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  scrollofthunder: {
    uid: 'scrollofthunder',
    level: 6,
    type: 'us',
    xp: 1900,
    craftXp: 29,
    value: 1050,
    tradeMinMaxValue: '260,330,530,790,1300;13000,16500,26500,39500,65500',
    favor: 19,
    time: 200,
    atk: 96,
    def: 24,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'wizard',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 16,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'cuirass',
    c1Qty: 1,
    c1Tags: 'common',
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:scrollofprotection',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 55,
    suggest: 10,
    speedup: 90,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  scrollofprotection: {
    uid: 'scrollofprotection',
    level: 9,
    type: 'us',
    xp: 4400,
    craftXp: 66,
    value: 2750,
    tradeMinMaxValue: '830,1050,1650,2500,4100;41500,51500,82500,125000,205000',
    favor: 35,
    time: 500,
    atk: 156,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'wizard',
    worker2: null,
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 26,
    resource2: 'leather',
    r2Qty: 13,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:songofvalor',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 90,
    suggest: 20,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 4,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  songofvalor: {
    uid: 'songofvalor',
    level: 12,
    type: 'us',
    xp: 8150,
    craftXp: 122,
    value: 6900,
    tradeMinMaxValue:
      '1750,2200,3500,5200,8600;86500,110000,175000,260000,430000',
    favor: 93,
    time: 1050,
    atk: 220,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 8,
    worker1: 'wizard',
    worker2: null,
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 41,
    resource2: 'leather',
    r2Qty: 20,
    resource3: 'oils',
    r3Qty: 8,
    component1: 'livingroots',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:scrollofice',
    upgrade4: 'resource1-8',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 65,
    surcharge: 140,
    suggest: 30,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  correspondence: {
    uid: 'correspondence',
    level: 13,
    type: 'us',
    xp: 9650,
    craftXp: 145,
    value: 13500,
    tradeMinMaxValue:
      '3400,4200,6800,10000,17000;170000,210000,340000,505000,845000',
    favor: 129,
    time: 1320,
    atk: 240,
    def: 48,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'wizard',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 49,
    resource2: 'leather',
    r2Qty: 25,
    resource3: 'oils',
    r3Qty: 10,
    component1: 'arcanedust',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-10',
    upgrade4: 'resource2-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-2',
    supgrade2: 'component1-2',
    supgrade3: 'multi+0.1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 85,
    surcharge: 190,
    suggest: 40,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-06-02',
    shardPrice: 100,
    capriceDelay: 240,
  },
  scrollofice: {
    uid: 'scrollofice',
    level: 14,
    type: 'us',
    xp: 11500,
    craftXp: 173,
    value: 15000,
    tradeMinMaxValue:
      '3800,4700,7500,11500,19000;190000,235000,375000,565000,940000',
    favor: 156,
    time: 1680,
    atk: 270,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 60,
    resource2: 'leather',
    r2Qty: 30,
    resource3: 'oils',
    r3Qty: 12,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'bp:scrollofinvisibility',
    upgrade4: 'resource1-12',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 90,
    surcharge: 200,
    suggest: 40,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 12,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  swampscroll: {
    uid: 'swampscroll',
    level: 15,
    type: 'us',
    xp: 13000,
    craftXp: 195,
    value: 20500,
    tradeMinMaxValue:
      '7200,9000,14500,22000,36000;360000,450000,725000,1100000,1800000',
    favor: 150,
    time: 2040,
    atk: 290,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 5,
    combo: 8,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 71,
    resource2: 'leather',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-14',
    upgrade4: 'resource2-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'unholy',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 105,
    surcharge: 235,
    suggest: 45,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: 'swamp',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  tomeofknowledge: {
    uid: 'tomeofknowledge',
    level: 15,
    type: 'us',
    xp: 13000,
    craftXp: 195,
    value: 22000,
    tradeMinMaxValue:
      '5500,6900,11000,16500,28000;275000,345000,550000,825000,1400000',
    favor: 223,
    time: 2040,
    atk: 290,
    def: 58,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 71,
    resource2: 'leather',
    r2Qty: 36,
    resource3: 'oils',
    r3Qty: 16,
    component1: 'livingroots',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-14',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-18',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource3-8',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'owl',
    tag: null,
    discount: 110,
    surcharge: 245,
    suggest: 50,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-08-27',
    shardPrice: 120,
    capriceDelay: 240,
  },
  scrollofinvisibility: {
    uid: 'scrollofinvisibility',
    level: 17,
    type: 'us',
    xp: 17000,
    craftXp: 255,
    value: 32500,
    tradeMinMaxValue:
      '8100,10000,16500,24000,41000;405000,510000,815000,1200000,2050000',
    favor: 349,
    time: 3300,
    atk: 340,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'herbs',
    r1Qty: 99,
    resource2: 'leather',
    r2Qty: 49,
    resource3: 'oils',
    r3Qty: 25,
    component1: 'mooncrystal',
    c1Qty: 2,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:scrolloflevitation',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 135,
    surcharge: 295,
    suggest: 60,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 17,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  christmascroll: {
    uid: 'christmascroll',
    level: 18,
    type: 'us',
    xp: 19500,
    craftXp: 293,
    value: 44500,
    tradeMinMaxValue:
      '11000,14000,22000,33000,56000;555000,695000,1100000,1650000,2800000',
    favor: 465,
    time: 4200,
    atk: 370,
    def: 0,
    hp: 12,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'herbs',
    r1Qty: 113,
    resource2: 'leather',
    r2Qty: 45,
    resource3: 'oils',
    r3Qty: 29,
    component1: 'redpinecone',
    c1Qty: 6,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'santa',
    tag: null,
    discount: 155,
    surcharge: 340,
    suggest: 70,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2019-12-09',
    shardPrice: 140,
    capriceDelay: 0,
  },
  scrolloflevitation: {
    uid: 'scrolloflevitation',
    level: 20,
    type: 'us',
    xp: 24500,
    craftXp: 368,
    value: 71000,
    tradeMinMaxValue:
      '18000,22000,36000,53000,89000;890000,1100000,1800000,2650000,4450000',
    favor: 622,
    time: 6000,
    atk: 420,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'herbs',
    r1Qty: 141,
    resource2: 'leather',
    r2Qty: 70,
    resource3: 'oils',
    r3Qty: 36,
    component1: 'scrollofprotection',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'evileye',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:tarot',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 190,
    surcharge: 425,
    suggest: 85,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  fireworksscroll: {
    uid: 'fireworksscroll',
    level: 21,
    type: 'us',
    xp: 27000,
    craftXp: 405,
    value: 115000,
    tradeMinMaxValue:
      '29000,36000,58000,86000,145000;1450000,1800000,2900000,4300000,7200000',
    favor: 791,
    time: 7200,
    atk: 450,
    def: 90,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'herbs',
    r1Qty: 155,
    resource2: 'leather',
    r2Qty: 78,
    resource3: 'oils',
    r3Qty: 39,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-16',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 240,
    surcharge: 535,
    suggest: 105,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2019-06-27',
    shardPrice: 160,
    capriceDelay: 0,
  },
  unicornscroll: {
    uid: 'unicornscroll',
    level: 21,
    type: 'us',
    xp: 27000,
    craftXp: 405,
    value: 130000,
    tradeMinMaxValue:
      '33000,41000,65000,98000,165000;1650000,2050000,3250000,4900000,8150000',
    favor: 791,
    time: 7200,
    atk: 450,
    def: 0,
    hp: 14,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'herbs',
    r1Qty: 155,
    resource2: 'leather',
    r2Qty: 78,
    resource3: 'oils',
    r3Qty: 39,
    component1: 'bubble',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-16',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 255,
    surcharge: 570,
    suggest: 115,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-11-29',
    shardPrice: 160,
    capriceDelay: 0,
  },
  tarot: {
    uid: 'tarot',
    level: 24,
    type: 'us',
    xp: 36000,
    craftXp: 540,
    value: 195000,
    tradeMinMaxValue:
      '49000,61000,98000,145000,240000;2450000,3050000,4900000,7300000,12000000',
    favor: 771,
    time: 13800,
    atk: 530,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'herbs',
    r1Qty: 199,
    resource2: 'oils',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 7,
    component1: 'ancientmarble',
    c1Qty: 4,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:grimoireaeternum',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 310,
    surcharge: 690,
    suggest: 140,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  vipscroll: {
    uid: 'vipscroll',
    level: 25,
    type: 'us',
    xp: 39500,
    craftXp: 593,
    value: 225000,
    tradeMinMaxValue:
      '56000,70000,115000,170000,280000;2800000,3500000,5650000,8450000,14000000',
    favor: 1001,
    time: 16800,
    atk: 560,
    def: 0,
    hp: 17,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'herbs',
    r1Qty: 214,
    resource2: 'oils',
    r2Qty: 57,
    resource3: 'mana',
    r3Qty: 8,
    component1: 'timecrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'livingroots',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 335,
    surcharge: 740,
    suggest: 150,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2021-01-12',
    shardPrice: 180,
    capriceDelay: 240,
  },
  grimoireaeternum: {
    uid: 'grimoireaeternum',
    level: 27,
    type: 'us',
    xp: 46500,
    craftXp: 698,
    value: 320000,
    tradeMinMaxValue:
      '80000,100000,160000,240000,400000;4000000,5000000,8000000,12000000,20000000',
    favor: 1224,
    time: 18000,
    atk: 620,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'herbs',
    r1Qty: 244,
    resource2: 'oils',
    r2Qty: 67,
    resource3: 'mana',
    r3Qty: 13,
    component1: 'sewingthread',
    c1Qty: 5,
    c1Tags: null,
    component2: 'arcanedust',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:celestialscroll',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 395,
    surcharge: 880,
    suggest: 175,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  waxtablet: {
    uid: 'waxtablet',
    level: 28,
    type: 'us',
    xp: 50500,
    craftXp: 758,
    value: 425000,
    tradeMinMaxValue:
      '105000,135000,210000,320000,530000;5300000,6650000,10500000,16000000,26500000',
    favor: 1589,
    time: 21600,
    atk: 640,
    def: 128,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'herbs',
    r1Qty: 259,
    resource2: 'oils',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 16,
    component1: 'ectoplasm',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-6',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 450,
    surcharge: 1005,
    suggest: 200,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2020-07-28',
    shardPrice: 200,
    capriceDelay: 240,
  },
  goldscroll: {
    uid: 'goldscroll',
    level: 29,
    type: 'us',
    xp: 54500,
    craftXp: 818,
    value: 610000,
    tradeMinMaxValue:
      '155000,190000,310000,460000,760000;7650000,9550000,15500000,23000000,38000000',
    favor: 1847,
    time: 25800,
    atk: 670,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'herbs',
    r1Qty: 275,
    resource2: 'oils',
    r2Qty: 77,
    resource3: 'mana',
    r3Qty: 19,
    component1: 'tabletfragment',
    c1Qty: 5,
    c1Tags: null,
    component2: 'ancientmarble',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'luxuriousspirit',
    tag: null,
    discount: 405,
    surcharge: 895,
    suggest: 180,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-09-05',
    shardPrice: 0,
    capriceDelay: 0,
  },
  celestialscroll: {
    uid: 'celestialscroll',
    level: 30,
    type: 'us',
    xp: 58500,
    craftXp: 878,
    value: 620000,
    tradeMinMaxValue:
      '155000,195000,310000,470000,780000;7750000,9700000,15500000,23500000,39000000',
    favor: 1909,
    time: 27000,
    atk: 700,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 290,
    resource2: 'oils',
    r2Qty: 82,
    resource3: 'mana',
    r3Qty: 22,
    component1: 'scrolloflevitation',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'goldshell',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:angelscroll',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 815,
    surcharge: 1810,
    suggest: 360,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  priestbook: {
    uid: 'priestbook',
    level: 30,
    type: 'us',
    xp: 58500,
    craftXp: 878,
    value: 655000,
    tradeMinMaxValue:
      '165000,200000,330000,490000,820000;8200000,10000000,16500000,24500000,41000000',
    favor: 2128,
    time: 30600,
    atk: 700,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 290,
    resource2: 'oils',
    r2Qty: 82,
    resource3: 'mana',
    r3Qty: 22,
    component1: 'sirenshard',
    c1Qty: 4,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-16',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 835,
    surcharge: 1855,
    suggest: 370,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-10-08',
    shardPrice: 250,
    capriceDelay: 0,
  },
  batscroll: {
    uid: 'batscroll',
    level: 31,
    type: 'us',
    xp: 63000,
    craftXp: 945,
    value: 675000,
    tradeMinMaxValue:
      '170000,210000,340000,510000,840000;8450000,10500000,17000000,25500000,42000000',
    favor: 2451,
    time: 36600,
    atk: 730,
    def: 146,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 10,
    combo: 6,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 306,
    resource2: 'oils',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'ectoplasm',
    c1Qty: 6,
    c1Tags: null,
    component2: 'webbedwings',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'component1-1',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-26',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 850,
    surcharge: 1885,
    suggest: 375,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2022-10-10',
    shardPrice: 250,
    capriceDelay: 0,
  },
  celticscroll: {
    uid: 'celticscroll',
    level: 31,
    type: 'us',
    xp: 63000,
    craftXp: 945,
    value: 705000,
    tradeMinMaxValue:
      '175000,220000,350000,530000,880000;8800000,11000000,17500000,26500000,44000000',
    favor: 2451,
    time: 33000,
    atk: 730,
    def: 0,
    hp: 23,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 306,
    resource2: 'oils',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'noblering',
    c1Qty: 2,
    c1Tags: 'uncommon',
    component2: 'sirenshard',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 865,
    surcharge: 1925,
    suggest: 385,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-03-10',
    shardPrice: 250,
    capriceDelay: 0,
  },
  angelscroll: {
    uid: 'angelscroll',
    level: 33,
    type: 'us',
    xp: 110000,
    craftXp: 1650,
    value: 1400000,
    tradeMinMaxValue:
      '350000,440000,700000,1050000,1750000;17500000,22000000,35000000,52500000,87500000',
    favor: 2889,
    time: 52800,
    atk: 910,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 338,
    resource2: 'oils',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12scroll',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1045,
    surcharge: 2320,
    suggest: 465,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  peakscroll: {
    uid: 'peakscroll',
    level: 33,
    type: 'us',
    xp: 110000,
    craftXp: 1650,
    value: 1550000,
    tradeMinMaxValue:
      '390000,490000,790000,1200000,1950000;19500000,24500000,39500000,59000000,98500000',
    favor: 3218,
    time: 52800,
    atk: 910,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 5,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 338,
    resource2: 'oils',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-68',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'blaze',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'phoenix',
    tag: 'elemental',
    discount: 1095,
    surcharge: 2435,
    suggest: 485,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: 'peak',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  freedomscroll: {
    uid: 'freedomscroll',
    level: 34,
    type: 'us',
    xp: 115000,
    craftXp: 1725,
    value: 1600000,
    tradeMinMaxValue:
      '400000,500000,800000,1200000,2000000;20000000,25000000,40000000,60000000,100000000',
    favor: 3047,
    time: 63000,
    atk: 950,
    def: 0,
    hp: 30,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 300,
    resource2: 'fabric',
    r2Qty: 90,
    resource3: 'mana',
    r3Qty: 34,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-60',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-18',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1110,
    surcharge: 2470,
    suggest: 495,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2021-06-30',
    shardPrice: 300,
    capriceDelay: 0,
  },
  lunarscroll: {
    uid: 'lunarscroll',
    level: 34,
    type: 'us',
    xp: 115000,
    craftXp: 1725,
    value: 1750000,
    tradeMinMaxValue:
      '440000,550000,880000,1300000,2200000;22000000,27500000,44000000,65500000,109500000',
    favor: 3671,
    time: 63000,
    atk: 950,
    def: 190,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 354,
    resource2: 'oils',
    r2Qty: 103,
    resource3: 'mana',
    r3Qty: 34,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-71',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-21',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource3-10',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'tiger',
    tag: null,
    discount: 1160,
    surcharge: 2580,
    suggest: 515,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-01-26',
    shardPrice: 300,
    capriceDelay: 0,
  },
  allknowledge: {
    uid: 'allknowledge',
    level: 35,
    type: 'us',
    xp: 125000,
    craftXp: 1875,
    value: 2000000,
    tradeMinMaxValue:
      '500000,630000,1000000,1500000,2500000;25000000,31500000,50000000,75000000,125000000',
    favor: 4167,
    time: 65400,
    atk: 990,
    def: 198,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 11,
    combo: 5,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'herbs',
    r1Qty: 371,
    resource2: 'oils',
    r2Qty: 108,
    resource3: 'mana',
    r3Qty: 36,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'archivistglasses',
    c2Qty: 1,
    c2Tags: 'common',
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-74',
    upgrade4: 'resource2-22',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource3-7',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1240,
    surcharge: 2750,
    suggest: 550,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  t12scroll: {
    uid: 't12scroll',
    level: 37,
    type: 'us',
    xp: 230000,
    craftXp: 3450,
    value: 2700000,
    tradeMinMaxValue:
      '680000,840000,1350000,2050000,3400000;34000000,42000000,67500000,101500000,169000000',
    favor: 4930,
    time: 105000,
    atk: 1200,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'herbs',
    r1Qty: 404,
    resource2: 'oils',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 46,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'batrachitestone',
    c2Qty: 1,
    c2Tags: 'common',
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-121',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13scroll',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-14',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource2-37',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'dinosaur',
    tag: null,
    discount: 1430,
    surcharge: 3175,
    suggest: 635,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  anubisscroll: {
    uid: 'anubisscroll',
    level: 37,
    type: 'us',
    xp: 230000,
    craftXp: 3450,
    value: 2900000,
    tradeMinMaxValue:
      '730000,910000,1450000,2200000,3650000;36500000,45500000,72500000,109000000,181500000',
    favor: 4881,
    time: 107400,
    atk: 1200,
    def: 0,
    hp: 37,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 364,
    resource2: 'steel',
    r2Qty: 111,
    resource3: 'mana',
    r3Qty: 46,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-73',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-22',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource3-9',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1480,
    surcharge: 3290,
    suggest: 660,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-08-09',
    shardPrice: 350,
    capriceDelay: 0,
  },
  newyearscroll: {
    uid: 'newyearscroll',
    level: 38,
    type: 'us',
    xp: 245000,
    craftXp: 3675,
    value: 3000000,
    tradeMinMaxValue:
      '750000,940000,1500000,2250000,3750000;37500000,47000000,75000000,112500000,187500000',
    favor: 5945,
    time: 128400,
    atk: 1240,
    def: 248,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'herbs',
    r1Qty: 421,
    resource2: 'oils',
    r2Qty: 134,
    resource3: 'gems',
    r3Qty: 40,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-84',
    upgrade3: 'time*0.75',
    upgrade4: 'resource3-10',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-20',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource2-20',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1505,
    surcharge: 3340,
    suggest: 670,
    speedup: 3645,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-12-27',
    shardPrice: 350,
    capriceDelay: 0,
  },
  valentinescroll: {
    uid: 'valentinescroll',
    level: 38,
    type: 'us',
    xp: 245000,
    craftXp: 3675,
    value: 3050000,
    tradeMinMaxValue:
      '760000,950000,1550000,2300000,3800000;38000000,47500000,76500000,114500000,190500000',
    favor: 5258,
    time: 128400,
    atk: 1240,
    def: 0,
    hp: 44,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'iron',
    r1Qty: 379,
    resource2: 'steel',
    r2Qty: 120,
    resource3: 'gems',
    r3Qty: 40,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-114',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-30',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource3-12',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1515,
    surcharge: 3370,
    suggest: 675,
    speedup: 3645,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-02-08',
    shardPrice: 350,
    capriceDelay: 0,
  },
  goldscroll2: {
    uid: 'goldscroll2',
    level: 38,
    type: 'us',
    xp: 245000,
    craftXp: 3675,
    value: 5950000,
    tradeMinMaxValue:
      '1500000,1850000,3000000,4450000,7450000;74500000,93000000,149000000,223000000,372000000',
    favor: 5599,
    time: 116400,
    atk: 1240,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'herbs',
    r1Qty: 400,
    resource2: 'oils',
    r2Qty: 130,
    resource3: 'gems',
    r3Qty: 40,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'goldring',
    c2Qty: 1,
    c2Tags: 'common',
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.80',
    upgrade2: 'resource2-26',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1565,
    surcharge: 3480,
    suggest: 695,
    speedup: 3645,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-04-14',
    shardPrice: 0,
    capriceDelay: 0,
  },
  t13scroll: {
    uid: 't13scroll',
    level: 40,
    type: 'us',
    xp: 275000,
    craftXp: 4125,
    value: 5200000,
    tradeMinMaxValue:
      '1300000,1650000,2600000,3900000,6500000;65000000,81500000,130000000,195000000,325000000',
    favor: 7680,
    time: 183000,
    atk: 1520,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'herbs',
    r1Qty: 446,
    resource2: 'mana',
    r2Qty: 54,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voideye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-134',
    upgrade3: 'resource2-11',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1960,
    surcharge: 4350,
    suggest: 870,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  riftscroll: {
    uid: 'riftscroll',
    level: 40,
    type: 'us',
    xp: 275000,
    craftXp: 4125,
    value: 5500000,
    tradeMinMaxValue:
      '1400000,1750000,2800000,4200000,7000000;70000000,87000000,139500000,209500000,349000000',
    favor: 8374,
    time: 183000,
    atk: 1520,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 13,
    combo: 5,
    worker1: 'wizard',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'herbs',
    r1Qty: 440,
    resource2: 'mana',
    r2Qty: 50,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-132',
    upgrade3: 'resource2-10',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'hurricane',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 2010,
    surcharge: 4470,
    suggest: 895,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: 'rift',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  cuirass: {
    uid: 'cuirass',
    level: 1,
    type: 'ah',
    xp: 140,
    craftXp: 2,
    value: 60,
    tradeMinMaxValue: '20,20,35,50,90;900,1100,1800,2700,4500',
    favor: 1,
    time: 15,
    atk: 0,
    def: 8,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:ironcap',
    upgrade2: 'bp:ringmail',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-4',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 15,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ringmail: {
    uid: 'ringmail',
    level: 4,
    type: 'ah',
    xp: 920,
    craftXp: 14,
    value: 430,
    tradeMinMaxValue: '130,160,260,390,650;6500,8100,13000,19500,32500',
    favor: 13,
    time: 110,
    atk: 0,
    def: 22,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 12,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'bp:scalemail',
    upgrade2: 'component1-1',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-9',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 15,
    surcharge: 35,
    suggest: 5,
    speedup: 55,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  scalemail: {
    uid: 'scalemail',
    level: 7,
    type: 'ah',
    xp: 2850,
    craftXp: 43,
    value: 1600,
    tradeMinMaxValue: '480,600,960,1450,2400;24000,30000,48000,72000,120000',
    favor: 21,
    time: 300,
    atk: 0,
    def: 40,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 17,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:chainmail',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'quality+2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 30,
    surcharge: 70,
    suggest: 15,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  chainmail: {
    uid: 'chainmail',
    level: 10,
    type: 'ah',
    xp: 6100,
    craftXp: 92,
    value: 3650,
    tradeMinMaxValue: '910,1150,1850,2700,4600;45500,57000,91500,135000,230000',
    favor: 42,
    time: 600,
    atk: 0,
    def: 62,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 7,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 30,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'ringmail',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'preciousgem',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:breastplate',
    upgrade4: 'resource1-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 45,
    surcharge: 105,
    suggest: 20,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  breastplate: {
    uid: 'breastplate',
    level: 13,
    type: 'ah',
    xp: 10500,
    craftXp: 158,
    value: 8450,
    tradeMinMaxValue:
      '2100,2600,4200,6300,10500;105000,130000,210000,315000,530000',
    favor: 81,
    time: 1380,
    atk: 0,
    def: 84,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 49,
    resource2: 'steel',
    r2Qty: 10,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-10',
    upgrade3: 'bp:halfplate',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 155,
    suggest: 30,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  swampheavyarmor: {
    uid: 'swampheavyarmor',
    level: 14,
    type: 'ah',
    xp: 12500,
    craftXp: 188,
    value: 11500,
    tradeMinMaxValue:
      '4800,6000,9600,14500,24000;240000,300000,480000,720000,1200000',
    favor: 119,
    time: 1740,
    atk: 0,
    def: 92,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 60,
    resource2: 'steel',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-2',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'holy',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 80,
    surcharge: 180,
    suggest: 35,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'swamp',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  centurionarmor: {
    uid: 'centurionarmor',
    level: 16,
    type: 'ah',
    xp: 16500,
    craftXp: 248,
    value: 19000,
    tradeMinMaxValue:
      '4800,5900,9500,14500,24000;240000,295000,475000,715000,1200000',
    favor: 193,
    time: 2700,
    atk: 0,
    def: 105,
    hp: 7,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 8,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 85,
    resource2: 'leather',
    r2Qty: 43,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 3,
    c1Tags: null,
    component2: 'redpinecone',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-17',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 100,
    surcharge: 225,
    suggest: 45,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-07-28',
    shardPrice: 120,
    capriceDelay: 240,
  },
  halfplate: {
    uid: 'halfplate',
    level: 17,
    type: 'ah',
    xp: 19000,
    craftXp: 285,
    value: 23500,
    tradeMinMaxValue:
      '5900,7300,12000,17500,29000;295000,365000,590000,880000,1450000',
    favor: 216,
    time: 3000,
    atk: 0,
    def: 115,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 1,
    w3BuildingReq: 4,
    resource1: 'iron',
    r1Qty: 99,
    resource2: 'steel',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'scalemail',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'sharpfang',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:ronindo',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 250,
    suggest: 50,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pumpkinheavyarmor: {
    uid: 'pumpkinheavyarmor',
    level: 17,
    type: 'ah',
    xp: 19000,
    craftXp: 285,
    value: 28500,
    tradeMinMaxValue:
      '7100,8900,14500,21000,36000;355000,445000,715000,1050000,1800000',
    favor: 242,
    time: 3300,
    atk: 0,
    def: 115,
    hp: 7,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 1,
    w3BuildingReq: 4,
    resource1: 'iron',
    r1Qty: 99,
    resource2: 'herbs',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 6,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 125,
    surcharge: 275,
    suggest: 55,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-10-18',
    shardPrice: 140,
    capriceDelay: 0,
  },
  beeheavyarmor: {
    uid: 'beeheavyarmor',
    level: 18,
    type: 'ah',
    xp: 21500,
    craftXp: 323,
    value: 34500,
    tradeMinMaxValue:
      '8600,11000,17500,26000,43000;430000,540000,865000,1300000,2150000',
    favor: 296,
    time: 4200,
    atk: 31,
    def: 125,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 1,
    w3BuildingReq: 5,
    resource1: 'iron',
    r1Qty: 113,
    resource2: 'steel',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'ringmail',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'sharpfang',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 135,
    surcharge: 300,
    suggest: 60,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-06-01',
    shardPrice: 140,
    capriceDelay: 240,
  },
  ronindo: {
    uid: 'ronindo',
    level: 21,
    type: 'ah',
    xp: 30000,
    craftXp: 450,
    value: 84000,
    tradeMinMaxValue:
      '21000,26000,42000,63000,105000;1050000,1300000,2100000,3150000,5250000',
    favor: 732,
    time: 7800,
    atk: 0,
    def: 150,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'iron',
    r1Qty: 155,
    resource2: 'leather',
    r2Qty: 78,
    resource3: 'steel',
    r3Qty: 39,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'arcanedust',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'bp:generalplate',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-5',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 205,
    surcharge: 460,
    suggest: 90,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pyramidheavyarmor: {
    uid: 'pyramidheavyarmor',
    level: 22,
    type: 'ah',
    xp: 33000,
    craftXp: 495,
    value: 105000,
    tradeMinMaxValue:
      '28000,35000,57000,85000,140000;1400000,1750000,2850000,4250000,7050000',
    favor: 938,
    time: 9000,
    atk: 0,
    def: 160,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'iron',
    r1Qty: 170,
    resource2: 'leather',
    r2Qty: 85,
    resource3: 'steel',
    r3Qty: 43,
    component1: 'scholartunic',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-9',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'tidal',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 230,
    surcharge: 515,
    suggest: 105,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'pyramid',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  atlab_heavyarmor: {
    uid: 'atlab_heavyarmor',
    level: 22,
    type: 'ah',
    xp: 33000,
    craftXp: 495,
    value: 145000,
    tradeMinMaxValue:
      '36000,45000,73000,110000,180000;1800000,2250000,3650000,5450000,9050000',
    favor: 591,
    time: 9600,
    atk: 40,
    def: 160,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'iron',
    r1Qty: 170,
    resource2: 'leather',
    r2Qty: 85,
    resource3: null,
    r3Qty: 0,
    component1: 'silkscarab',
    c1Qty: 4,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-17',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 270,
    surcharge: 600,
    suggest: 120,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-17',
    shardPrice: 160,
    capriceDelay: 0,
  },
  lunararmor: {
    uid: 'lunararmor',
    level: 23,
    type: 'ah',
    xp: 36500,
    craftXp: 548,
    value: 165000,
    tradeMinMaxValue:
      '41000,52000,83000,125000,210000;2050000,2600000,4150000,6200000,10500000',
    favor: 1098,
    time: 12000,
    atk: 0,
    def: 170,
    hp: 11,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 20,
    w2BuildingReq: 16,
    w3BuildingReq: 10,
    resource1: 'iron',
    r1Qty: 184,
    resource2: 'leather',
    r2Qty: 92,
    resource3: 'steel',
    r3Qty: 47,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-37',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-18',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-9',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 290,
    surcharge: 640,
    suggest: 130,
    speedup: 795,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-01-22',
    shardPrice: 160,
    capriceDelay: 0,
  },
  generalplate: {
    uid: 'generalplate',
    level: 24,
    type: 'ah',
    xp: 40000,
    craftXp: 600,
    value: 185000,
    tradeMinMaxValue:
      '46000,58000,93000,140000,230000;2300000,2900000,4650000,6950000,11500000',
    favor: 771,
    time: 14400,
    atk: 0,
    def: 180,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'iron',
    r1Qty: 199,
    resource2: 'steel',
    r2Qty: 51,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'ancientmarble',
    c1Qty: 6,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:berserkerarmor',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 305,
    surcharge: 675,
    suggest: 135,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  oktoberfestplate: {
    uid: 'oktoberfestplate',
    level: 25,
    type: 'ah',
    xp: 44000,
    craftXp: 660,
    value: 225000,
    tradeMinMaxValue:
      '56000,70000,115000,170000,280000;2800000,3500000,5650000,8450000,14000000',
    favor: 1001,
    time: 17400,
    atk: 0,
    def: 190,
    hp: 12,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'iron',
    r1Qty: 214,
    resource2: 'steel',
    r2Qty: 57,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'timecrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 335,
    surcharge: 740,
    suggest: 150,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-10-11',
    shardPrice: 180,
    capriceDelay: 240,
  },
  christmasheavyarmor: {
    uid: 'christmasheavyarmor',
    level: 25,
    type: 'ah',
    xp: 44000,
    craftXp: 660,
    value: 235000,
    tradeMinMaxValue:
      '59000,73000,120000,175000,290000;2950000,3650000,5900000,8800000,14500000',
    favor: 1875,
    time: 17400,
    atk: 48,
    def: 190,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'iron',
    r1Qty: 214,
    resource2: 'leather',
    r2Qty: 130,
    resource3: 'herbs',
    r3Qty: 90,
    component1: 'ancientmarble',
    c1Qty: 5,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-26',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource3-90',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'santa',
    tag: null,
    discount: 340,
    surcharge: 755,
    suggest: 150,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-12-13',
    shardPrice: 180,
    capriceDelay: 0,
  },
  berserkerarmor: {
    uid: 'berserkerarmor',
    level: 27,
    type: 'ah',
    xp: 51500,
    craftXp: 773,
    value: 310000,
    tradeMinMaxValue:
      '78000,97000,155000,230000,390000;3900000,4850000,7750000,11500000,19500000',
    favor: 1224,
    time: 18600,
    atk: 0,
    def: 205,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'iron',
    r1Qty: 244,
    resource2: 'steel',
    r2Qty: 67,
    resource3: 'gems',
    r3Qty: 13,
    component1: 'ghostflag',
    c1Qty: 4,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:juggernaut',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 390,
    surcharge: 865,
    suggest: 175,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  castleheavyarmor: {
    uid: 'castleheavyarmor',
    level: 28,
    type: 'ah',
    xp: 56000,
    craftXp: 840,
    value: 365000,
    tradeMinMaxValue:
      '98000,125000,195000,290000,490000;4900000,6150000,9850000,14500000,24500000',
    favor: 1589,
    time: 22200,
    atk: 0,
    def: 215,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'iron',
    r1Qty: 259,
    resource2: 'steel',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'ghostflag',
    c1Qty: 4,
    c1Tags: null,
    component2: 'livingroots',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'primal',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 420,
    surcharge: 935,
    suggest: 185,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'castle',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  freedomplate: {
    uid: 'freedomplate',
    level: 29,
    type: 'ah',
    xp: 60500,
    craftXp: 908,
    value: 425000,
    tradeMinMaxValue:
      '105000,135000,210000,320000,530000;5300000,6650000,10500000,16000000,26500000',
    favor: 1847,
    time: 27000,
    atk: 0,
    def: 225,
    hp: 14,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'iron',
    r1Qty: 275,
    resource2: 'steel',
    r2Qty: 77,
    resource3: 'gems',
    r3Qty: 19,
    component1: 'sewingthread',
    c1Qty: 4,
    c1Tags: null,
    component2: 'darkscale',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-6',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 450,
    surcharge: 1005,
    suggest: 200,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2020-06-30',
    shardPrice: 200,
    capriceDelay: 0,
  },
  juggernaut: {
    uid: 'juggernaut',
    level: 31,
    type: 'ah',
    xp: 69500,
    craftXp: 1043,
    value: 610000,
    tradeMinMaxValue:
      '155000,190000,310000,460000,760000;7650000,9550000,15500000,23000000,38000000',
    favor: 2200,
    time: 38400,
    atk: 0,
    def: 245,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 306,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'deepcoral',
    c1Qty: 4,
    c1Tags: null,
    component2: 'timecrystal',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'bp:celestialplate',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 810,
    surcharge: 1795,
    suggest: 360,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  templearmor: {
    uid: 'templearmor',
    level: 31,
    type: 'ah',
    xp: 69500,
    craftXp: 1043,
    value: 640000,
    tradeMinMaxValue:
      '165000,210000,330000,500000,840000;8350000,10500000,16500000,25000000,42000000',
    favor: 2451,
    time: 33600,
    atk: 0,
    def: 245,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 306,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'largehealthpotion',
    c1Qty: 2,
    c1Tags: 'common',
    component2: 'goldshell',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'blaze',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 825,
    surcharge: 1835,
    suggest: 365,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'temple',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldplate: {
    uid: 'goldplate',
    level: 31,
    type: 'ah',
    xp: 69500,
    craftXp: 1043,
    value: 1500000,
    tradeMinMaxValue:
      '380000,470000,750000,1150000,1900000;19000000,23500000,37500000,56500000,94000000',
    favor: 2451,
    time: 38400,
    atk: 0,
    def: 245,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 306,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'tabletjewel',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sirenshard',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 935,
    surcharge: 2075,
    suggest: 415,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-11-28',
    shardPrice: 0,
    capriceDelay: 0,
  },
  celticheavyarmor: {
    uid: 'celticheavyarmor',
    level: 31,
    type: 'ah',
    xp: 69500,
    craftXp: 1043,
    value: 685000,
    tradeMinMaxValue:
      '170000,210000,340000,510000,860000;8550000,10500000,17000000,25500000,43000000',
    favor: 2451,
    time: 38400,
    atk: 0,
    def: 245,
    hp: 15,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 306,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'deepcoral',
    c1Qty: 4,
    c1Tags: null,
    component2: 'timecrystal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 855,
    surcharge: 1900,
    suggest: 380,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-03-09',
    shardPrice: 250,
    capriceDelay: 0,
  },
  celestialplate: {
    uid: 'celestialplate',
    level: 33,
    type: 'ah',
    xp: 120000,
    craftXp: 1800,
    value: 1550000,
    tradeMinMaxValue:
      '390000,480000,780000,1150000,1950000;19500000,24000000,39000000,58000000,97000000',
    favor: 2889,
    time: 55200,
    atk: 0,
    def: 305,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 338,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12heavyarmor',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1095,
    surcharge: 2435,
    suggest: 485,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  dogheavyarmor: {
    uid: 'dogheavyarmor',
    level: 33,
    type: 'ah',
    xp: 120000,
    craftXp: 1800,
    value: 1750000,
    tradeMinMaxValue:
      '440000,550000,880000,1300000,2200000;22000000,27500000,44000000,65500000,109500000',
    favor: 3120,
    time: 55200,
    atk: 76,
    def: 305,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 338,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 26,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'ghostflag',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'wolf',
    tag: null,
    discount: 1160,
    surcharge: 2580,
    suggest: 515,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-08-24',
    shardPrice: 300,
    capriceDelay: 0,
  },
  atlab_fireplate: {
    uid: 'atlab_fireplate',
    level: 33,
    type: 'ah',
    xp: 120000,
    craftXp: 1800,
    value: 1800000,
    tradeMinMaxValue:
      '450000,560000,900000,1350000,2250000;22500000,28000000,45000000,67500000,112500000',
    favor: 3240,
    time: 48000,
    atk: 0,
    def: 305,
    hp: 19,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 340,
    resource2: 'steel',
    r2Qty: 100,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'blaze',
    c2Qty: 1,
    c2Tags: 'common',
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.6',
    upgrade2: 'resource1-68',
    upgrade3: 'resource2-20',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 1175,
    surcharge: 2615,
    suggest: 525,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-03',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12heavyarmor: {
    uid: 't12heavyarmor',
    level: 36,
    type: 'ah',
    xp: 240000,
    craftXp: 3600,
    value: 2400000,
    tradeMinMaxValue:
      '600000,750000,1200000,1800000,3000000;30000000,37500000,60000000,90000000,150000000',
    favor: 4293,
    time: 94200,
    atk: 0,
    def: 385,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 387,
    resource2: 'steel',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13heavyarmor',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-28',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: 'phoenix',
    tag: null,
    discount: 1350,
    surcharge: 3000,
    suggest: 600,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  fallheavyarmor: {
    uid: 'fallheavyarmor',
    level: 36,
    type: 'ah',
    xp: 240000,
    craftXp: 3600,
    value: 2800000,
    tradeMinMaxValue:
      '700000,880000,1400000,2100000,3500000;35000000,44000000,70000000,105000000,175000000',
    favor: 4582,
    time: 94200,
    atk: 96,
    def: 385,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'leather',
    r1Qty: 380,
    resource2: 'fabric',
    r2Qty: 110,
    resource3: 'mana',
    r3Qty: 37,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-76',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-22',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1455,
    surcharge: 3235,
    suggest: 645,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-09-07',
    shardPrice: 350,
    capriceDelay: 0,
  },
  darkknightheavyarmor: {
    uid: 'darkknightheavyarmor',
    level: 37,
    type: 'ah',
    xp: 255000,
    craftXp: 3825,
    value: 3000000,
    tradeMinMaxValue:
      '750000,940000,1500000,2250000,3750000;37500000,47000000,75000000,112500000,187500000',
    favor: 5488,
    time: 112800,
    atk: 0,
    def: 400,
    hp: 25,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 404,
    resource2: 'steel',
    r2Qty: 123,
    resource3: 'gems',
    r3Qty: 46,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-18',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-18',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'resource1-162',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1505,
    surcharge: 3340,
    suggest: 670,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-11-01',
    shardPrice: 350,
    capriceDelay: 0,
  },
  cyclopsheavyarmor: {
    uid: 'cyclopsheavyarmor',
    level: 37,
    type: 'ah',
    xp: 255000,
    craftXp: 3825,
    value: 3000000,
    tradeMinMaxValue:
      '750000,940000,1500000,2250000,3750000;37500000,47000000,75000000,112500000,187500000',
    favor: 7086,
    time: 112800,
    atk: 0,
    def: 400,
    hp: 0,
    eva: '0',
    crit: '0.05',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 404,
    resource2: 'steel',
    r2Qty: 123,
    resource3: 'herbs',
    r3Qty: 101,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-61',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1505,
    surcharge: 3340,
    suggest: 670,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-02-22',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13heavyarmor: {
    uid: 't13heavyarmor',
    level: 40,
    type: 'ah',
    xp: 305000,
    craftXp: 4575,
    value: 5200000,
    tradeMinMaxValue:
      '1300000,1650000,2600000,3900000,6500000;65000000,81500000,130000000,195000000,325000000',
    favor: 7680,
    time: 171600,
    atk: 0,
    def: 510,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'iron',
    r1Qty: 446,
    resource2: 'gems',
    r2Qty: 54,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voideye',
    c1Qty: 2,
    c1Tags: null,
    component2: 'nightmare',
    c2Qty: 1,
    c2Tags: 'uncommon',
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-89',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'cquality2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1960,
    surcharge: 4350,
    suggest: 870,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  riftheavyarmor: {
    uid: 'riftheavyarmor',
    level: 40,
    type: 'ah',
    xp: 305000,
    craftXp: 4575,
    value: 5650000,
    tradeMinMaxValue:
      '1450000,1800000,2850000,4300000,7150000;71500000,89500000,143500000,215000000,358000000',
    favor: 8374,
    time: 192000,
    atk: 0,
    def: 510,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'iron',
    r1Qty: 440,
    resource2: 'gems',
    r2Qty: 50,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-88',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-13',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'inferno',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 2040,
    surcharge: 4530,
    suggest: 905,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'rift',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  shoprheavyarmor: {
    uid: 'shoprheavyarmor',
    level: 40,
    type: 'ah',
    xp: 305000,
    craftXp: 4575,
    value: 5750000,
    tradeMinMaxValue:
      '1450000,1800000,2900000,4300000,7200000;72000000,90000000,144000000,215500000,359500000',
    favor: 8191,
    time: 192000,
    atk: 128,
    def: 510,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'iron',
    r1Qty: 431,
    resource2: 'gems',
    r2Qty: 54,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voidcrystal',
    c1Qty: 2,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-14',
    upgrade3: 'resource1-86',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-2',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 2055,
    surcharge: 4565,
    suggest: 915,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-06-13',
    shardPrice: 375,
    capriceDelay: 0,
  },
  leatherarmor: {
    uid: 'leatherarmor',
    level: 1,
    type: 'am',
    xp: 140,
    craftXp: 2,
    value: 60,
    tradeMinMaxValue: '20,20,35,50,90;900,1100,1800,2700,4500',
    favor: 1,
    time: 15,
    atk: 0,
    def: 8,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:padded',
    upgrade2: 'bp:leathercap',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-4',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 15,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  padded: {
    uid: 'padded',
    level: 3,
    type: 'am',
    xp: 515,
    craftXp: 8,
    value: 190,
    tradeMinMaxValue: '60,70,110,170,290;2900,3600,5700,8600,14500',
    favor: 10,
    time: 70,
    atk: 0,
    def: 16,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'bp:doublet',
    upgrade2: 'value*1.5',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-7',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 25,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  doublet: {
    uid: 'doublet',
    level: 6,
    type: 'am',
    xp: 1950,
    craftXp: 29,
    value: 1100,
    tradeMinMaxValue: '280,340,550,830,1400;14000,17000,27500,41500,69000',
    favor: 19,
    time: 220,
    atk: 0,
    def: 32,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 16,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:studded',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 60,
    suggest: 10,
    speedup: 90,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  studded: {
    uid: 'studded',
    level: 9,
    type: 'am',
    xp: 4600,
    craftXp: 69,
    value: 2500,
    tradeMinMaxValue: '630,780,1250,1900,3100;31500,39000,62500,94000,155000',
    favor: 35,
    time: 510,
    atk: 0,
    def: 52,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 26,
    resource2: 'iron',
    r2Qty: 6,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:hide',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 85,
    suggest: 15,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 5,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  winterarmor: {
    uid: 'winterarmor',
    level: 11,
    type: 'am',
    xp: 7000,
    craftXp: 105,
    value: 6000,
    tradeMinMaxValue:
      '1500,1900,3000,4500,7500;75000,94000,150000,225000,375000',
    favor: 56,
    time: 840,
    atk: 16,
    def: 64,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 34,
    resource2: 'iron',
    r2Qty: 9,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 3,
    c1Tags: null,
    component2: 'redpinecone',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-7',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 60,
    surcharge: 130,
    suggest: 25,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-12-01',
    shardPrice: 100,
    capriceDelay: 240,
  },
  hide: {
    uid: 'hide',
    level: 13,
    type: 'am',
    xp: 10000,
    craftXp: 150,
    value: 8900,
    tradeMinMaxValue:
      '2200,2800,4500,6700,11000;110000,140000,225000,335000,555000',
    favor: 81,
    time: 1350,
    atk: 0,
    def: 80,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 49,
    resource2: 'fabric',
    r2Qty: 10,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 3,
    c1Tags: null,
    component2: 'elvenwood',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'bp:savagearmor',
    upgrade4: 'resource1-10',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-4',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 155,
    suggest: 30,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  mundraarmor: {
    uid: 'mundraarmor',
    level: 14,
    type: 'am',
    xp: 12000,
    craftXp: 360,
    value: 16500,
    tradeMinMaxValue:
      '8300,10500,16500,25000,41000;415000,515000,825000,1250000,2050000',
    favor: 119,
    time: 1710,
    atk: 22,
    def: 86,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'moondragon',
    worker2: null,
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 60,
    resource2: 'fabric',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 3,
    c1Tags: null,
    component2: 'webbedwings',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:mundrastaff',
    upgrade4: 'resource1-12',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: 'dragon',
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 95,
    surcharge: 210,
    suggest: 40,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  airshiparmor: {
    uid: 'airshiparmor',
    level: 14,
    type: 'am',
    xp: 12000,
    craftXp: 180,
    value: 18500,
    tradeMinMaxValue:
      '4600,5800,9300,14000,23000;230000,290000,465000,695000,1150000',
    favor: 119,
    time: 1710,
    atk: 0,
    def: 86,
    hp: 0,
    eva: '0.05',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 60,
    resource2: 'fabric',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 8,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'component1-1',
    upgrade4: 'resource1-12',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 100,
    surcharge: 225,
    suggest: 45,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-06-28',
    shardPrice: 120,
    capriceDelay: 0,
  },
  savagearmor: {
    uid: 'savagearmor',
    level: 17,
    type: 'am',
    xp: 18000,
    craftXp: 270,
    value: 24500,
    tradeMinMaxValue:
      '6100,7700,12500,18500,31000;305000,385000,615000,920000,1550000',
    favor: 349,
    time: 3300,
    atk: 0,
    def: 110,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 1,
    w3BuildingReq: 4,
    resource1: 'leather',
    r1Qty: 99,
    resource2: 'herbs',
    r2Qty: 49,
    resource3: 'fabric',
    r3Qty: 25,
    component1: 'sharpfang',
    c1Qty: 3,
    c1Tags: null,
    component2: 'webbedwings',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:ninjagarb',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'lizard',
    tag: null,
    discount: 115,
    surcharge: 255,
    suggest: 50,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  desertmediumarmor: {
    uid: 'desertmediumarmor',
    level: 18,
    type: 'am',
    xp: 20500,
    craftXp: 308,
    value: 33500,
    tradeMinMaxValue:
      '10500,13000,21000,31000,52000;515000,645000,1050000,1550000,2600000',
    favor: 465,
    time: 4200,
    atk: 0,
    def: 115,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 6,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 1,
    w3BuildingReq: 5,
    resource1: 'leather',
    r1Qty: 113,
    resource2: 'herbs',
    r2Qty: 56,
    resource3: 'fabric',
    r3Qty: 29,
    component1: 'horned',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'whitesand',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'wild',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'lizard',
    tag: 'elemental',
    discount: 135,
    surcharge: 295,
    suggest: 60,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'desert',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pumpkinarmor: {
    uid: 'pumpkinarmor',
    level: 18,
    type: 'am',
    xp: 20500,
    craftXp: 308,
    value: 39500,
    tradeMinMaxValue:
      '9900,12500,20000,30000,49000;495000,615000,990000,1500000,2450000',
    favor: 459,
    time: 4200,
    atk: 29,
    def: 115,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'tailor',
    worker2: 'blacksmith',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'leather',
    r1Qty: 113,
    resource2: 'herbs',
    r2Qty: 56,
    resource3: 'iron',
    r3Qty: 28,
    component1: 'mooncrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'webbedwings',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-3',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 145,
    surcharge: 320,
    suggest: 65,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-10-28',
    shardPrice: 140,
    capriceDelay: 0,
  },
  ninjagarb: {
    uid: 'ninjagarb',
    level: 21,
    type: 'am',
    xp: 28500,
    craftXp: 428,
    value: 86000,
    tradeMinMaxValue:
      '22000,27000,43000,65000,110000;1100000,1350000,2150000,3250000,5400000',
    favor: 590,
    time: 7800,
    atk: 0,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'leather',
    r1Qty: 155,
    resource2: 'fabric',
    r2Qty: 39,
    resource3: 'oils',
    r3Qty: 20,
    component1: 'silkscarab',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'bp:windrunnerarmor',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 210,
    surcharge: 465,
    suggest: 95,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  batarmor: {
    uid: 'batarmor',
    level: 22,
    type: 'am',
    xp: 31500,
    craftXp: 473,
    value: 115000,
    tradeMinMaxValue:
      '29000,36000,58000,86000,145000;1450000,1800000,2900000,4300000,7200000',
    favor: 765,
    time: 9000,
    atk: 38,
    def: 150,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'leather',
    r1Qty: 170,
    resource2: 'fabric',
    r2Qty: 43,
    resource3: 'oils',
    r3Qty: 22,
    component1: 'evileye',
    c1Qty: 4,
    c1Tags: null,
    component2: 'darkscale',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 12,
    u2Req: 30,
    u3Req: 54,
    u4Req: 85,
    u5Req: 135,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 240,
    surcharge: 535,
    suggest: 105,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-10-20',
    shardPrice: 160,
    capriceDelay: 240,
  },
  windrunnerarmor: {
    uid: 'windrunnerarmor',
    level: 24,
    type: 'am',
    xp: 37500,
    craftXp: 563,
    value: 205000,
    tradeMinMaxValue:
      '51000,64000,105000,155000,260000;2550000,3200000,5150000,7700000,13000000',
    favor: 1184,
    time: 13800,
    atk: 0,
    def: 170,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'leather',
    r1Qty: 199,
    resource2: 'herbs',
    r2Qty: 99,
    resource3: 'fabric',
    r3Qty: 51,
    component1: 'studded',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'overgrownvine',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:dragonarmor',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 320,
    surcharge: 710,
    suggest: 140,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  atlab_armor: {
    uid: 'atlab_armor',
    level: 25,
    type: 'am',
    xp: 41000,
    craftXp: 615,
    value: 245000,
    tradeMinMaxValue:
      '61000,77000,125000,185000,310000;3050000,3850000,6150000,9200000,15500000',
    favor: 922,
    time: 16800,
    atk: 0,
    def: 175,
    hp: 11,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'leather',
    r1Qty: 214,
    resource2: 'fabric',
    r2Qty: 57,
    resource3: null,
    r3Qty: 0,
    component1: 'doublet',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'timecrystal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-43',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-17',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 345,
    surcharge: 770,
    suggest: 155,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-16',
    shardPrice: 180,
    capriceDelay: 0,
  },
  dragonarmor: {
    uid: 'dragonarmor',
    level: 27,
    type: 'am',
    xp: 48500,
    craftXp: 728,
    value: 320000,
    tradeMinMaxValue:
      '80000,100000,160000,240000,400000;4000000,5000000,8000000,12000000,20000000',
    favor: 1190,
    time: 18000,
    atk: 0,
    def: 195,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'leather',
    r1Qty: 244,
    resource2: 'fabric',
    r2Qty: 67,
    resource3: 'mana',
    r3Qty: 10,
    component1: 'sewingthread',
    c1Qty: 5,
    c1Tags: null,
    component2: 'webbedwings',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:captainarmor',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 395,
    surcharge: 880,
    suggest: 175,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  smithapron: {
    uid: 'smithapron',
    level: 28,
    type: 'am',
    xp: 52500,
    craftXp: 788,
    value: 425000,
    tradeMinMaxValue:
      '105000,135000,210000,320000,530000;5300000,6650000,10500000,16000000,26500000',
    favor: 1538,
    time: 21600,
    atk: 51,
    def: 205,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'leather',
    r1Qty: 259,
    resource2: 'fabric',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 12,
    component1: 'ghostflag',
    c1Qty: 3,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 450,
    surcharge: 1005,
    suggest: 200,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-06-19',
    shardPrice: 200,
    capriceDelay: 240,
  },
  captainarmor: {
    uid: 'captainarmor',
    level: 31,
    type: 'am',
    xp: 65500,
    craftXp: 983,
    value: 600000,
    tradeMinMaxValue:
      '150000,190000,300000,450000,750000;7500000,9400000,15000000,22500000,37500000',
    favor: 2103,
    time: 37800,
    atk: 0,
    def: 235,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 306,
    resource2: 'fabric',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 19,
    component1: 'deepcoral',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'bp:jackalopearmor',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 800,
    surcharge: 1780,
    suggest: 355,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  siaarmor: {
    uid: 'siaarmor',
    level: 31,
    type: 'am',
    xp: 65500,
    craftXp: 983,
    value: 655000,
    tradeMinMaxValue:
      '165000,200000,330000,490000,820000;8200000,10000000,16500000,24500000,41000000',
    favor: 2352,
    time: 37800,
    atk: 59,
    def: 235,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 306,
    resource2: 'fabric',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 19,
    component1: 'goldshell',
    c1Qty: 3,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 835,
    surcharge: 1855,
    suggest: 370,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-11-04',
    shardPrice: 250,
    capriceDelay: 240,
  },
  lunarmedarmor: {
    uid: 'lunarmedarmor',
    level: 32,
    type: 'am',
    xp: 70500,
    craftXp: 1058,
    value: 680000,
    tradeMinMaxValue:
      '170000,210000,340000,510000,850000;8500000,10500000,17000000,25500000,42500000',
    favor: 2705,
    time: 39000,
    atk: 0,
    def: 240,
    hp: 15,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 26,
    w2BuildingReq: 22,
    w3BuildingReq: 19,
    resource1: 'leather',
    r1Qty: 322,
    resource2: 'fabric',
    r2Qty: 93,
    resource3: 'mana',
    r3Qty: 22,
    component1: 'ninjagarb',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'deepcoral',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-64',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 850,
    surcharge: 1890,
    suggest: 380,
    speedup: 2635,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-02-09',
    shardPrice: 250,
    capriceDelay: 0,
  },
  jackalopearmor: {
    uid: 'jackalopearmor',
    level: 33,
    type: 'am',
    xp: 115000,
    craftXp: 1725,
    value: 1400000,
    tradeMinMaxValue:
      '350000,440000,700000,1050000,1750000;17500000,22000000,35000000,52500000,87500000',
    favor: 2736,
    time: 54000,
    atk: 0,
    def: 290,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 23,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12mediumarmor',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1045,
    surcharge: 2320,
    suggest: 465,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldroguearmor: {
    uid: 'goldroguearmor',
    level: 33,
    type: 'am',
    xp: 115000,
    craftXp: 1725,
    value: 3000000,
    tradeMinMaxValue:
      '750000,940000,1500000,2250000,3750000;37500000,47000000,75000000,112500000,187500000',
    favor: 3042,
    time: 54000,
    atk: 0,
    def: 290,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'tabletjewel',
    c1Qty: 5,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1125,
    surcharge: 2505,
    suggest: 500,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-05-15',
    shardPrice: 0,
    capriceDelay: 0,
  },
  troblinarmor: {
    uid: 'troblinarmor',
    level: 33,
    type: 'am',
    xp: 115000,
    craftXp: 1725,
    value: 1650000,
    tradeMinMaxValue:
      '410000,520000,830000,1250000,2050000;20500000,26000000,41500000,62000000,103000000',
    favor: 3995,
    time: 54000,
    atk: 73,
    def: 290,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'carpenter',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 304,
    resource2: 'herbs',
    r2Qty: 169,
    resource3: 'ironwood',
    r3Qty: 88,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-18',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-35',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1130,
    surcharge: 2510,
    suggest: 500,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-07-13',
    shardPrice: 300,
    capriceDelay: 0,
  },
  catarmor: {
    uid: 'catarmor',
    level: 34,
    type: 'am',
    xp: 120000,
    craftXp: 1800,
    value: 1750000,
    tradeMinMaxValue:
      '440000,550000,880000,1300000,2200000;22000000,27500000,44000000,65500000,109500000',
    favor: 3479,
    time: 64200,
    atk: 0,
    def: 300,
    hp: 0,
    eva: '0.05',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 354,
    resource2: 'fabric',
    r2Qty: 103,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-71',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-21',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'cat',
    tag: null,
    discount: 1160,
    surcharge: 2580,
    suggest: 515,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-07-27',
    shardPrice: 300,
    capriceDelay: 240,
  },
  t12mediumarmor: {
    uid: 't12mediumarmor',
    level: 36,
    type: 'am',
    xp: 225000,
    craftXp: 3375,
    value: 2550000,
    tradeMinMaxValue:
      '640000,800000,1300000,1900000,3200000;32000000,40000000,64000000,95500000,159500000',
    favor: 4046,
    time: 84000,
    atk: 0,
    def: 365,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'leather',
    r1Qty: 387,
    resource2: 'fabric',
    r2Qty: 113,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'rhino',
    c2Qty: 1,
    c2Tags: 'uncommon',
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'cquality2-1',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13mediumarmor',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-34',
    supgrade2: 'resource3-9',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1390,
    surcharge: 3090,
    suggest: 620,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  volcanomedarmor: {
    uid: 'volcanomedarmor',
    level: 37,
    type: 'am',
    xp: 240000,
    craftXp: 3600,
    value: 2650000,
    tradeMinMaxValue:
      '680000,850000,1350000,2050000,3400000;34000000,42500000,68500000,102500000,170500000',
    favor: 5154,
    time: 109800,
    atk: 0,
    def: 380,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 404,
    resource2: 'fabric',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 34,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-81',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-31',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'abyssal',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 1420,
    surcharge: 3150,
    suggest: 630,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'volcano',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  cincomedarmor: {
    uid: 'cincomedarmor',
    level: 36,
    type: 'am',
    xp: 225000,
    craftXp: 3375,
    value: 2800000,
    tradeMinMaxValue:
      '700000,880000,1400000,2100000,3500000;35000000,44000000,70000000,105000000,175000000',
    favor: 4528,
    time: 91800,
    atk: 0,
    def: 365,
    hp: 23,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'leather',
    r1Qty: 387,
    resource2: 'fabric',
    r2Qty: 113,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'resource1-77',
    upgrade2: 'resource2-23',
    upgrade3: 'resource3-6',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'time*0.8',
    supgrade2: 'resource2-23',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1455,
    surcharge: 3235,
    suggest: 645,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-05-03',
    shardPrice: 350,
    capriceDelay: 0,
  },
  westernarmor: {
    uid: 'westernarmor',
    level: 37,
    type: 'am',
    xp: 240000,
    craftXp: 3600,
    value: 2900000,
    tradeMinMaxValue:
      '730000,910000,1450000,2200000,3650000;36500000,45500000,72500000,109000000,181500000',
    favor: 5154,
    time: 109800,
    atk: 95,
    def: 380,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 404,
    resource2: 'fabric',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 34,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-81',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-37',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 1480,
    surcharge: 3290,
    suggest: 660,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-05-31',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13mediumarmor: {
    uid: 't13mediumarmor',
    level: 39,
    type: 'am',
    xp: 270000,
    craftXp: 4050,
    value: 5350000,
    tradeMinMaxValue:
      '1350000,1650000,2700000,4000000,6700000;67000000,83500000,134000000,200500000,334500000',
    favor: 6656,
    time: 156600,
    atk: 0,
    def: 465,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'leather',
    r1Qty: 438,
    resource2: 'mana',
    r2Qty: 39,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcloth',
    c1Qty: 3,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-131',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-12',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: 'chimera',
    tag: null,
    discount: 1985,
    surcharge: 4410,
    suggest: 880,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  dreammediumarmor: {
    uid: 'dreammediumarmor',
    level: 39,
    type: 'am',
    xp: 270000,
    craftXp: 4050,
    value: 5600000,
    tradeMinMaxValue:
      '1400000,1750000,2800000,4200000,7000000;70000000,87500000,140000000,210000000,350000000',
    favor: 7201,
    time: 156600,
    atk: 0,
    def: 465,
    hp: 29,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'leather',
    r1Qty: 428,
    resource2: 'mana',
    r2Qty: 39,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcloth',
    c1Qty: 2,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-128',
    upgrade3: 'resource2-12',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-2',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 2030,
    surcharge: 4510,
    suggest: 900,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-07-12',
    shardPrice: 375,
    capriceDelay: 0,
  },
  tunic: {
    uid: 'tunic',
    level: 1,
    type: 'al',
    xp: 135,
    craftXp: 2,
    value: 50,
    tradeMinMaxValue: '15,20,30,45,80;800,900,1500,2300,3800',
    favor: 1,
    time: 15,
    atk: 0,
    def: 7,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'bp:robe',
    upgrade2: 'bp:leatherarmor',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-4',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 15,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  robe: {
    uid: 'robe',
    level: 3,
    type: 'al',
    xp: 455,
    craftXp: 7,
    value: 280,
    tradeMinMaxValue: '80,110,170,250,420;4200,5300,8400,12500,21000',
    favor: 10,
    time: 60,
    atk: 0,
    def: 15,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:druidic',
    upgrade3: 'resource1-2',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 15,
    surcharge: 30,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  druidic: {
    uid: 'druidic',
    level: 6,
    type: 'al',
    xp: 1700,
    craftXp: 26,
    value: 1100,
    tradeMinMaxValue: '280,340,550,830,1400;14000,17000,27500,41500,69000',
    favor: 19,
    time: 200,
    atk: 0,
    def: 30,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 16,
    resource2: 'herbs',
    r2Qty: 8,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:disciplerobe',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 60,
    suggest: 10,
    speedup: 90,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  disciplerobe: {
    uid: 'disciplerobe',
    level: 9,
    type: 'al',
    xp: 3950,
    craftXp: 59,
    value: 2550,
    tradeMinMaxValue: '640,800,1300,1900,3200;32000,40000,64000,95500,160000',
    favor: 35,
    time: 480,
    atk: 0,
    def: 48,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 26,
    resource2: 'herbs',
    r2Qty: 13,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:scholartunic',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 85,
    suggest: 15,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  scholartunic: {
    uid: 'scholartunic',
    level: 13,
    type: 'al',
    xp: 8700,
    craftXp: 131,
    value: 8800,
    tradeMinMaxValue:
      '2200,2800,4400,6600,11000;110000,140000,220000,330000,550000',
    favor: 100,
    time: 1260,
    atk: 0,
    def: 74,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 49,
    resource2: 'fabric',
    r2Qty: 10,
    resource3: 'oils',
    r3Qty: 5,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-10',
    upgrade3: 'bp:witchrobe',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 155,
    suggest: 30,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 11,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  exploreroutfit: {
    uid: 'exploreroutfit',
    level: 14,
    type: 'al',
    xp: 10000,
    craftXp: 150,
    value: 15000,
    tradeMinMaxValue:
      '3800,4700,7500,11500,19000;190000,235000,375000,565000,940000',
    favor: 119,
    time: 1590,
    atk: 21,
    def: 82,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 60,
    resource2: 'fabric',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-12',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.8',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 90,
    surcharge: 200,
    suggest: 40,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-08-13',
    shardPrice: 120,
    capriceDelay: 240,
  },
  tailorarmor: {
    uid: 'tailorarmor',
    level: 15,
    type: 'al',
    xp: 12000,
    craftXp: 180,
    value: 21500,
    tradeMinMaxValue:
      '5400,6700,11000,16000,27000;270000,335000,540000,805000,1350000',
    favor: 150,
    time: 2040,
    atk: 0,
    def: 88,
    hp: 6,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 71,
    resource2: 'fabric',
    r2Qty: 16,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-14',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-3',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 110,
    surcharge: 240,
    suggest: 50,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-06-18',
    shardPrice: 120,
    capriceDelay: 240,
  },
  witchrobe: {
    uid: 'witchrobe',
    level: 17,
    type: 'al',
    xp: 15500,
    craftXp: 233,
    value: 24000,
    tradeMinMaxValue:
      '6000,7500,12000,18000,30000;300000,375000,600000,900000,1500000',
    favor: 279,
    time: 3000,
    atk: 0,
    def: 105,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'leather',
    r1Qty: 99,
    resource2: 'fabric',
    r2Qty: 25,
    resource3: 'oils',
    r3Qty: 12,
    component1: 'robe',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'whitesand',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:midnightapparel',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 255,
    suggest: 50,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  desertlightarmor: {
    uid: 'desertlightarmor',
    level: 18,
    type: 'al',
    xp: 17500,
    craftXp: 263,
    value: 37500,
    tradeMinMaxValue:
      '11500,14000,23000,34000,57000;565000,705000,1150000,1700000,2850000',
    favor: 296,
    time: 3600,
    atk: 0,
    def: 110,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 6,
    combo: 7,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'leather',
    r1Qty: 113,
    resource2: 'fabric',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'sharpfang',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-3',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'holy',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 140,
    surcharge: 315,
    suggest: 65,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'desert',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  wizardrobe: {
    uid: 'wizardrobe',
    level: 19,
    type: 'al',
    xp: 19500,
    craftXp: 293,
    value: 40500,
    tradeMinMaxValue:
      '10000,12500,20000,30000,51000;505000,635000,1000000,1500000,2550000',
    favor: 456,
    time: 4800,
    atk: 30,
    def: 120,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 16,
    w2BuildingReq: 12,
    w3BuildingReq: 6,
    resource1: 'leather',
    r1Qty: 127,
    resource2: 'fabric',
    r2Qty: 33,
    resource3: 'oils',
    r3Qty: 16,
    component1: 'robe',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'whitesand',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-25',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 145,
    surcharge: 325,
    suggest: 65,
    speedup: 565,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-10-08',
    shardPrice: 140,
    capriceDelay: 240,
  },
  midnightapparel: {
    uid: 'midnightapparel',
    level: 20,
    type: 'al',
    xp: 22000,
    craftXp: 330,
    value: 70000,
    tradeMinMaxValue:
      '17500,22000,35000,53000,88000;875000,1100000,1750000,2650000,4400000',
    favor: 379,
    time: 5400,
    atk: 0,
    def: 125,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'leather',
    r1Qty: 141,
    resource2: 'fabric',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'scrolloffire',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:shamanvestment',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'cquality1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 190,
    surcharge: 425,
    suggest: 85,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  atlab_robe: {
    uid: 'atlab_robe',
    level: 21,
    type: 'al',
    xp: 24500,
    craftXp: 368,
    value: 110000,
    tradeMinMaxValue:
      '28000,34000,55000,83000,140000;1400000,1700000,2750000,4150000,6900000',
    favor: 502,
    time: 7200,
    atk: 0,
    def: 135,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'leather',
    r1Qty: 155,
    resource2: 'fabric',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'breeze',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'cquality1-1',
    upgrade2: 'resource1-31',
    upgrade3: 'time*0.75',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 235,
    surcharge: 525,
    suggest: 105,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-02',
    shardPrice: 160,
    capriceDelay: 0,
  },
  shamanvestment: {
    uid: 'shamanvestment',
    level: 25,
    type: 'al',
    xp: 35500,
    craftXp: 533,
    value: 190000,
    tradeMinMaxValue:
      '48000,59000,95000,145000,240000;2400000,2950000,4750000,7150000,12000000',
    favor: 896,
    time: 16200,
    atk: 0,
    def: 165,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'leather',
    r1Qty: 214,
    resource2: 'fabric',
    r2Qty: 57,
    resource3: 'mana',
    r3Qty: 8,
    component1: 'overgrownvine',
    c1Qty: 4,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 7,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'bp:astravestimenta',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-3',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 310,
    surcharge: 685,
    suggest: 135,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  valentinerobe: {
    uid: 'valentinerobe',
    level: 26,
    type: 'al',
    xp: 39000,
    craftXp: 585,
    value: 215000,
    tradeMinMaxValue:
      '54000,67000,110000,160000,270000;2700000,3350000,5400000,8050000,13500000',
    favor: 1756,
    time: 19200,
    atk: 0,
    def: 175,
    hp: 11,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 6,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 13,
    resource1: 'leather',
    r1Qty: 229,
    resource2: 'iron',
    r2Qty: 57,
    resource3: 'fabric',
    r3Qty: 62,
    component1: 'ancientmarble',
    c1Qty: 6,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-46',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-29',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 325,
    surcharge: 725,
    suggest: 145,
    speedup: 995,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-02-07',
    shardPrice: 180,
    capriceDelay: 0,
  },
  goldvestments: {
    uid: 'goldvestments',
    level: 27,
    type: 'al',
    xp: 42000,
    craftXp: 630,
    value: 400000,
    tradeMinMaxValue:
      '100000,125000,200000,300000,500000;5000000,6250000,10000000,15000000,25000000',
    favor: 1118,
    time: 16800,
    atk: 0,
    def: 185,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 8,
    combo: 6,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'leather',
    r1Qty: 214,
    resource2: 'fabric',
    r2Qty: 57,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'tabletfragment',
    c1Qty: 4,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-4',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'luxuriousspirit',
    tag: null,
    discount: 330,
    surcharge: 730,
    suggest: 145,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-11-23',
    shardPrice: 0,
    capriceDelay: 0,
  },
  astravestimenta: {
    uid: 'astravestimenta',
    level: 27,
    type: 'al',
    xp: 42000,
    craftXp: 630,
    value: 335000,
    tradeMinMaxValue:
      '84000,105000,170000,250000,420000;4200000,5250000,8400000,12500000,21000000',
    favor: 1224,
    time: 16800,
    atk: 0,
    def: 185,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'leather',
    r1Qty: 244,
    resource2: 'fabric',
    r2Qty: 67,
    resource3: 'mana',
    r3Qty: 13,
    component1: 'sewingthread',
    c1Qty: 4,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:majesticraiments',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 405,
    surcharge: 900,
    suggest: 180,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  easterarmor2020: {
    uid: 'easterarmor2020',
    level: 28,
    type: 'al',
    xp: 45500,
    craftXp: 683,
    value: 385000,
    tradeMinMaxValue:
      '96000,120000,195000,290000,480000;4800000,6000000,9650000,14500000,24000000',
    favor: 1589,
    time: 20400,
    atk: 48,
    def: 190,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'leather',
    r1Qty: 259,
    resource2: 'fabric',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 16,
    component1: 'ghostflag',
    c1Qty: 3,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 7,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'rabbitspirit',
    tag: null,
    discount: 430,
    surcharge: 960,
    suggest: 190,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-04-07',
    shardPrice: 200,
    capriceDelay: 0,
  },
  arabiantunic: {
    uid: 'arabiantunic',
    level: 29,
    type: 'al',
    xp: 49000,
    craftXp: 735,
    value: 410000,
    tradeMinMaxValue:
      '105000,130000,210000,310000,510000;5150000,6400000,10500000,15500000,25500000',
    favor: 1847,
    time: 24600,
    atk: 0,
    def: 200,
    hp: 13,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'leather',
    r1Qty: 275,
    resource2: 'fabric',
    r2Qty: 77,
    resource3: 'mana',
    r3Qty: 19,
    component1: 'ectoplasm',
    c1Qty: 4,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 445,
    surcharge: 990,
    suggest: 200,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-08-11',
    shardPrice: 200,
    capriceDelay: 240,
  },
  majesticraiments: {
    uid: 'majesticraiments',
    level: 31,
    type: 'al',
    xp: 56500,
    craftXp: 848,
    value: 595000,
    tradeMinMaxValue:
      '150000,185000,300000,450000,740000;7450000,9300000,15000000,22500000,37000000',
    favor: 2200,
    time: 34800,
    atk: 0,
    def: 220,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 306,
    resource2: 'fabric',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'sirenshard',
    c1Qty: 4,
    c1Tags: null,
    component2: 'ghostflag',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'bp:djinnrobes',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 800,
    surcharge: 1775,
    suggest: 355,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  indiadayarmor: {
    uid: 'indiadayarmor',
    level: 31,
    type: 'al',
    xp: 56500,
    craftXp: 848,
    value: 620000,
    tradeMinMaxValue:
      '155000,195000,310000,470000,780000;7750000,9700000,15500000,23500000,39000000',
    favor: 2451,
    time: 34800,
    atk: 0,
    def: 220,
    hp: 14,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 306,
    resource2: 'fabric',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 815,
    surcharge: 1810,
    suggest: 360,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-01-27',
    shardPrice: 250,
    capriceDelay: 240,
  },
  djinnrobes: {
    uid: 'djinnrobes',
    level: 33,
    type: 'al',
    xp: 97500,
    craftXp: 1463,
    value: 1350000,
    tradeMinMaxValue:
      '340000,420000,680000,1000000,1700000;17000000,21000000,34000000,50500000,84500000',
    favor: 2889,
    time: 50400,
    atk: 0,
    def: 270,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12robe',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-68',
    supgrade2: 'resource3-8',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1025,
    surcharge: 2280,
    suggest: 455,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  moonrobes: {
    uid: 'moonrobes',
    level: 33,
    type: 'al',
    xp: 97500,
    craftXp: 1463,
    value: 1550000,
    tradeMinMaxValue:
      '390000,480000,780000,1150000,1950000;19500000,24000000,39000000,58000000,97000000',
    favor: 3218,
    time: 50400,
    atk: 68,
    def: 270,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'sirenshard',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-85',
    upgrade3: 'resource2-25',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1095,
    surcharge: 2435,
    suggest: 485,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-09-21',
    shardPrice: 300,
    capriceDelay: 0,
  },
  lunarrobe: {
    uid: 'lunarrobe',
    level: 34,
    type: 'al',
    xp: 105000,
    craftXp: 1575,
    value: 1700000,
    tradeMinMaxValue:
      '430000,530000,850000,1300000,2150000;21500000,26500000,42500000,64000000,106500000',
    favor: 3479,
    time: 60000,
    atk: 0,
    def: 280,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 354,
    resource2: 'fabric',
    r2Qty: 103,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'resource1-89',
    upgrade2: 'resource2-26',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.7',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'tiger',
    tag: null,
    discount: 1145,
    surcharge: 2545,
    suggest: 510,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-01-25',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12robe: {
    uid: 't12robe',
    level: 37,
    type: 'al',
    xp: 210000,
    craftXp: 3150,
    value: 2450000,
    tradeMinMaxValue:
      '610000,770000,1250000,1850000,3050000;30500000,38500000,61500000,92000000,153000000',
    favor: 4930,
    time: 102600,
    atk: 0,
    def: 355,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 404,
    resource2: 'fabric',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 46,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-18',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13robe',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-121',
    supgrade2: 'resource3-14',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1365,
    surcharge: 3030,
    suggest: 605,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  hemmadonrobe: {
    uid: 'hemmadonrobe',
    level: 37,
    type: 'al',
    xp: 210000,
    craftXp: 3150,
    value: 2600000,
    tradeMinMaxValue:
      '650000,810000,1300000,1950000,3250000;32500000,40500000,65000000,97500000,162500000',
    favor: 4943,
    time: 102600,
    atk: 89,
    def: 355,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 390,
    resource2: 'fabric',
    r2Qty: 115,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-59',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-23',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-10',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1405,
    surcharge: 3120,
    suggest: 625,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-04-20',
    shardPrice: 350,
    capriceDelay: 0,
  },
  goldrobe2: {
    uid: 'goldrobe2',
    level: 37,
    type: 'al',
    xp: 210000,
    craftXp: 3150,
    value: 5000000,
    tradeMinMaxValue:
      '1250000,1550000,2500000,3750000,6250000;62500000,78000000,125000000,187500000,312500000',
    favor: 5488,
    time: 102600,
    atk: 0,
    def: 355,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 404,
    resource2: 'fabric',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 46,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 10,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource2-25',
    upgrade3: 'resource1-81',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1440,
    surcharge: 3205,
    suggest: 640,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-06-10',
    shardPrice: 0,
    capriceDelay: 0,
  },
  volcanorobe: {
    uid: 'volcanorobe',
    level: 38,
    type: 'al',
    xp: 220000,
    craftXp: 3300,
    value: 2500000,
    tradeMinMaxValue:
      '650000,810000,1300000,1950000,3250000;32500000,40500000,64500000,97000000,161500000',
    favor: 6249,
    time: 120600,
    atk: 0,
    def: 365,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'leather',
    r1Qty: 421,
    resource2: 'fabric',
    r2Qty: 134,
    resource3: 'mana',
    r3Qty: 50,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'scrollofice',
    c2Qty: 2,
    c2Tags: 'common',
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-27',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-84',
    supgrade2: 'resource3-15',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'primeval',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 1375,
    surcharge: 3060,
    suggest: 610,
    speedup: 3645,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'volcano',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  unicornrobe: {
    uid: 'unicornrobe',
    level: 38,
    type: 'al',
    xp: 220000,
    craftXp: 3300,
    value: 2750000,
    tradeMinMaxValue:
      '690000,860000,1400000,2050000,3450000;34500000,43000000,69000000,103000000,172000000',
    favor: 5884,
    time: 122400,
    atk: 0,
    def: 365,
    hp: 23,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'leather',
    r1Qty: 421,
    resource2: 'fabric',
    r2Qty: 134,
    resource3: 'gems',
    r3Qty: 38,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-27',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.8',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-27',
    supgrade2: 'resource3-8',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1440,
    surcharge: 3205,
    suggest: 640,
    speedup: 3645,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-11-29',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13robe: {
    uid: 't13robe',
    level: 40,
    type: 'al',
    xp: 245000,
    craftXp: 3675,
    value: 5500000,
    tradeMinMaxValue:
      '1400000,1700000,2750000,4150000,6900000;69000000,86000000,137500000,206500000,344000000',
    favor: 7680,
    time: 174600,
    atk: 0,
    def: 450,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'leather',
    r1Qty: 446,
    resource2: 'mana',
    r2Qty: 54,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voidcloth',
    c1Qty: 3,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-8',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-89',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-67',
    supgrade2: 'resource3-2',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 2010,
    surcharge: 4470,
    suggest: 895,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ironcap: {
    uid: 'ironcap',
    level: 1,
    type: 'hh',
    xp: 140,
    craftXp: 2,
    value: 60,
    tradeMinMaxValue: '20,20,35,50,90;900,1100,1800,2700,4500',
    favor: 1,
    time: 15,
    atk: 0,
    def: 8,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:helmet',
    upgrade3: 'resource1-1',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-3',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 15,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  helmet: {
    uid: 'helmet',
    level: 4,
    type: 'hh',
    xp: 905,
    craftXp: 14,
    value: 350,
    tradeMinMaxValue: '110,130,210,320,530;5300,6600,10500,16000,26500',
    favor: 13,
    time: 110,
    atk: 0,
    def: 23,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 12,
    resource2: 'leather',
    r2Qty: 6,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:horned',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-9',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 15,
    surcharge: 35,
    suggest: 5,
    speedup: 55,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  horned: {
    uid: 'horned',
    level: 7,
    type: 'hh',
    xp: 2800,
    craftXp: 42,
    value: 1250,
    tradeMinMaxValue: '310,390,630,940,1550;15500,19500,31500,47000,78000',
    favor: 21,
    time: 300,
    atk: 0,
    def: 42,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 17,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:vikinghelm',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 60,
    suggest: 10,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  vikinghelm: {
    uid: 'vikinghelm',
    level: 9,
    type: 'hh',
    xp: 4750,
    craftXp: 71,
    value: 3200,
    tradeMinMaxValue: '800,1000,1600,2400,4000;40000,50000,80000,120000,200000',
    favor: 35,
    time: 520,
    atk: 0,
    def: 56,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 26,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:knighthelm',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 45,
    surcharge: 95,
    suggest: 20,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  knighthelm: {
    uid: 'knighthelm',
    level: 13,
    type: 'hh',
    xp: 10500,
    craftXp: 158,
    value: 9450,
    tradeMinMaxValue:
      '2400,3000,4700,7100,12000;120000,150000,235000,355000,590000',
    favor: 81,
    time: 1320,
    atk: 0,
    def: 88,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 49,
    resource2: 'steel',
    r2Qty: 10,
    resource3: null,
    r3Qty: 0,
    component1: 'helmet',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'darkscale',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-10',
    upgrade3: 'bp:paladinhelm',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 160,
    suggest: 30,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  swamphelm: {
    uid: 'swamphelm',
    level: 14,
    type: 'hh',
    xp: 12500,
    craftXp: 188,
    value: 13500,
    tradeMinMaxValue:
      '5400,6700,11000,16000,27000;270000,335000,540000,805000,1350000',
    favor: 119,
    time: 1740,
    atk: 0,
    def: 96,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 60,
    resource2: 'steel',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'tidal',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 85,
    surcharge: 190,
    suggest: 40,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'swamp',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  eaglecowl: {
    uid: 'eaglecowl',
    level: 14,
    type: 'hh',
    xp: 12500,
    craftXp: 188,
    value: 15000,
    tradeMinMaxValue:
      '3800,4700,7500,11500,19000;190000,235000,375000,565000,940000',
    favor: 119,
    time: 1740,
    atk: 0,
    def: 96,
    hp: 6,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 60,
    resource2: 'leather',
    r2Qty: 30,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-12',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.8',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-15',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: 'eagle',
    tag: null,
    discount: 90,
    surcharge: 200,
    suggest: 40,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2019-06-27',
    shardPrice: 120,
    capriceDelay: 0,
  },
  dinohelm: {
    uid: 'dinohelm',
    level: 15,
    type: 'hh',
    xp: 14000,
    craftXp: 210,
    value: 16500,
    tradeMinMaxValue:
      '4100,5200,8300,12500,21000;205000,260000,415000,620000,1050000',
    favor: 148,
    time: 2160,
    atk: 26,
    def: 105,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 70,
    resource2: 'ironwood',
    r2Qty: 16,
    resource3: null,
    r3Qty: 0,
    component1: 'sharpfang',
    c1Qty: 1,
    c1Tags: null,
    component2: 'darkscale',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-18',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'dinosaur',
    tag: null,
    discount: 95,
    surcharge: 210,
    suggest: 40,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-03-21',
    shardPrice: 120,
    capriceDelay: 0,
  },
  paladinhelm: {
    uid: 'paladinhelm',
    level: 17,
    type: 'hh',
    xp: 18500,
    craftXp: 278,
    value: 26000,
    tradeMinMaxValue:
      '6500,8100,13000,19500,33000;325000,405000,650000,975000,1650000',
    favor: 216,
    time: 3300,
    atk: 0,
    def: 120,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'iron',
    r1Qty: 99,
    resource2: 'steel',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'sharpfang',
    c1Qty: 3,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:kabuto',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-4',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 120,
    surcharge: 265,
    suggest: 55,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  couplehelmet: {
    uid: 'couplehelmet',
    level: 18,
    type: 'hh',
    xp: 21000,
    craftXp: 315,
    value: 37500,
    tradeMinMaxValue:
      '9400,11500,19000,28000,47000;470000,585000,940000,1400000,2350000',
    favor: 296,
    time: 4200,
    atk: 0,
    def: 130,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'iron',
    r1Qty: 113,
    resource2: 'steel',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'mooncrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'elvenwood',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'bear',
    tag: null,
    discount: 140,
    surcharge: 315,
    suggest: 65,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-02-23',
    shardPrice: 140,
    capriceDelay: 240,
  },
  atlab_firehelm: {
    uid: 'atlab_firehelm',
    level: 19,
    type: 'hh',
    xp: 23500,
    craftXp: 353,
    value: 39500,
    tradeMinMaxValue:
      '9900,12500,20000,30000,49000;495000,615000,990000,1500000,2450000',
    favor: 357,
    time: 4800,
    atk: 35,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'tailor',
    worker3: null,
    w1BuildingReq: 16,
    w2BuildingReq: 12,
    w3BuildingReq: 6,
    resource1: 'iron',
    r1Qty: 127,
    resource2: 'steel',
    r2Qty: 33,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 3,
    c1Tags: null,
    component2: 'icypearl',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 12,
    u2Req: 30,
    u3Req: 54,
    u4Req: 85,
    u5Req: 135,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-25',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-10',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 145,
    surcharge: 320,
    suggest: 65,
    speedup: 565,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-03',
    shardPrice: 140,
    capriceDelay: 0,
  },
  darkknighthelm: {
    uid: 'darkknighthelm',
    level: 19,
    type: 'hh',
    xp: 23500,
    craftXp: 353,
    value: 40000,
    tradeMinMaxValue:
      '10000,12500,20000,30000,50000;500000,625000,1000000,1500000,2500000',
    favor: 350,
    time: 4800,
    atk: 0,
    def: 140,
    hp: 9,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 16,
    w2BuildingReq: 12,
    w3BuildingReq: 6,
    resource1: 'iron',
    r1Qty: 125,
    resource2: 'steel',
    r2Qty: 30,
    resource3: null,
    r3Qty: 0,
    component1: 'mooncrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'darkscale',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 12,
    u2Req: 30,
    u3Req: 54,
    u4Req: 85,
    u5Req: 135,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-25',
    upgrade3: 'resource2-6',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource2-6',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 145,
    surcharge: 325,
    suggest: 65,
    speedup: 565,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-11-01',
    shardPrice: 140,
    capriceDelay: 0,
  },
  kabuto: {
    uid: 'kabuto',
    level: 21,
    type: 'hh',
    xp: 29500,
    craftXp: 443,
    value: 86000,
    tradeMinMaxValue:
      '22000,27000,43000,65000,110000;1100000,1350000,2150000,3250000,5400000',
    favor: 447,
    time: 7800,
    atk: 0,
    def: 160,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'iron',
    r1Qty: 155,
    resource2: 'steel',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'bp:generalheaume',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 210,
    surcharge: 465,
    suggest: 95,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  gladiatorhelm: {
    uid: 'gladiatorhelm',
    level: 22,
    type: 'hh',
    xp: 32500,
    craftXp: 488,
    value: 115000,
    tradeMinMaxValue:
      '29000,36000,58000,86000,145000;1450000,1800000,2900000,4300000,7200000',
    favor: 591,
    time: 9600,
    atk: 0,
    def: 170,
    hp: 11,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'iron',
    r1Qty: 170,
    resource2: 'steel',
    r2Qty: 43,
    resource3: null,
    r3Qty: 0,
    component1: 'evileye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-9',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 240,
    surcharge: 535,
    suggest: 105,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-07-30',
    shardPrice: 160,
    capriceDelay: 0,
  },
  beachhelm: {
    uid: 'beachhelm',
    level: 22,
    type: 'hh',
    xp: 32500,
    craftXp: 488,
    value: 120000,
    tradeMinMaxValue:
      '30000,38000,60000,90000,150000;1500000,1900000,3000000,4500000,7500000',
    favor: 591,
    time: 9600,
    atk: 43,
    def: 170,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'iron',
    r1Qty: 170,
    resource2: 'steel',
    r2Qty: 43,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 10,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'component1-2',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-2',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 250,
    surcharge: 550,
    suggest: 110,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-07-26',
    shardPrice: 160,
    capriceDelay: 0,
  },
  shoprhelm: {
    uid: 'shoprhelm',
    level: 23,
    type: 'hh',
    xp: 36000,
    craftXp: 540,
    value: 135000,
    tradeMinMaxValue:
      '34000,42000,68000,100000,170000;1700000,2100000,3400000,5050000,8450000',
    favor: 686,
    time: 11400,
    atk: 34,
    def: 180,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 20,
    w2BuildingReq: 16,
    w3BuildingReq: 10,
    resource1: 'iron',
    r1Qty: 184,
    resource2: 'steel',
    r2Qty: 47,
    resource3: null,
    r3Qty: 0,
    component1: 'starmetal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource2-9',
    upgrade3: 'resource1-37',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource2-9',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 260,
    surcharge: 580,
    suggest: 115,
    speedup: 795,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-06-13',
    shardPrice: 160,
    capriceDelay: 0,
  },
  generalheaume: {
    uid: 'generalheaume',
    level: 24,
    type: 'hh',
    xp: 39500,
    craftXp: 593,
    value: 185000,
    tradeMinMaxValue:
      '46000,58000,93000,140000,230000;2300000,2900000,4650000,6950000,11500000',
    favor: 771,
    time: 14400,
    atk: 0,
    def: 190,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'iron',
    r1Qty: 199,
    resource2: 'steel',
    r2Qty: 51,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'ancientmarble',
    c1Qty: 6,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:berserkerhelm',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-10',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 305,
    surcharge: 675,
    suggest: 135,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ruinshelm: {
    uid: 'ruinshelm',
    level: 25,
    type: 'hh',
    xp: 43000,
    craftXp: 645,
    value: 200000,
    tradeMinMaxValue:
      '52000,65000,105000,155000,260000;2600000,3250000,5200000,7800000,13000000',
    favor: 1001,
    time: 17400,
    atk: 0,
    def: 200,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 8,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'iron',
    r1Qty: 214,
    resource2: 'steel',
    r2Qty: 57,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-11',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'holy',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 315,
    surcharge: 700,
    suggest: 140,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'ruins',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lunarhelmet: {
    uid: 'lunarhelmet',
    level: 26,
    type: 'hh',
    xp: 46500,
    craftXp: 698,
    value: 255000,
    tradeMinMaxValue:
      '64000,80000,130000,190000,320000;3200000,4000000,6400000,9550000,16000000',
    favor: 1168,
    time: 21000,
    atk: 0,
    def: 210,
    hp: 13,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 13,
    resource1: 'iron',
    r1Qty: 229,
    resource2: 'steel',
    r2Qty: 62,
    resource3: 'gems',
    r3Qty: 10,
    component1: 'featheredhat',
    c1Qty: 2,
    c1Tags: 'common',
    component2: 'timecrystal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-46',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-12',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-12',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-2',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 355,
    surcharge: 785,
    suggest: 155,
    speedup: 995,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-01-22',
    shardPrice: 180,
    capriceDelay: 0,
  },
  berserkerhelm: {
    uid: 'berserkerhelm',
    level: 27,
    type: 'hh',
    xp: 50500,
    craftXp: 758,
    value: 325000,
    tradeMinMaxValue:
      '81000,100000,165000,240000,410000;4050000,5100000,8150000,12000000,20500000',
    favor: 1224,
    time: 18600,
    atk: 0,
    def: 220,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'iron',
    r1Qty: 244,
    resource2: 'steel',
    r2Qty: 67,
    resource3: 'gems',
    r3Qty: 13,
    component1: 'ghostflag',
    c1Qty: 4,
    c1Tags: null,
    component2: 'darkscale',
    c2Qty: 10,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:juggernauthelm',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-5',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 400,
    surcharge: 885,
    suggest: 175,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  centurionhelmet: {
    uid: 'centurionhelmet',
    level: 28,
    type: 'hh',
    xp: 55000,
    craftXp: 825,
    value: 425000,
    tradeMinMaxValue:
      '105000,135000,210000,320000,530000;5300000,6650000,10500000,16000000,26500000',
    favor: 1589,
    time: 22200,
    atk: 58,
    def: 230,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'iron',
    r1Qty: 259,
    resource2: 'steel',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'sewingthread',
    c1Qty: 4,
    c1Tags: null,
    component2: 'icypearl',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 450,
    surcharge: 1005,
    suggest: 200,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-07-29',
    shardPrice: 200,
    capriceDelay: 240,
  },
  juggernauthelm: {
    uid: 'juggernauthelm',
    level: 30,
    type: 'hh',
    xp: 63500,
    craftXp: 953,
    value: 600000,
    tradeMinMaxValue:
      '150000,190000,300000,450000,750000;7500000,9400000,15000000,22500000,37500000',
    favor: 1909,
    time: 31800,
    atk: 0,
    def: 250,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 290,
    resource2: 'steel',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'deepcoral',
    c1Qty: 5,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:celestialhelm',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 800,
    surcharge: 1780,
    suggest: 355,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  thanksgivinghelmet: {
    uid: 'thanksgivinghelmet',
    level: 31,
    type: 'hh',
    xp: 68500,
    craftXp: 1028,
    value: 675000,
    tradeMinMaxValue:
      '170000,210000,340000,510000,840000;8450000,10500000,17000000,25500000,42000000',
    favor: 2451,
    time: 38400,
    atk: 0,
    def: 260,
    hp: 16,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 306,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: 'webbedwings',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-8',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 850,
    surcharge: 1885,
    suggest: 375,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-11-17',
    shardPrice: 250,
    capriceDelay: 0,
  },
  goldhelmet: {
    uid: 'goldhelmet',
    level: 31,
    type: 'hh',
    xp: 68500,
    craftXp: 1028,
    value: 1650000,
    tradeMinMaxValue:
      '410000,520000,830000,1250000,2050000;20500000,26000000,41500000,62000000,103000000',
    favor: 2451,
    time: 38400,
    atk: 0,
    def: 260,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 306,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'tabletjewel',
    c1Qty: 5,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 975,
    surcharge: 2170,
    suggest: 435,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-02-20',
    shardPrice: 0,
    capriceDelay: 0,
  },
  celestialhelm: {
    uid: 'celestialhelm',
    level: 33,
    type: 'hh',
    xp: 115000,
    craftXp: 1725,
    value: 1200000,
    tradeMinMaxValue:
      '300000,380000,600000,900000,1500000;15000000,19000000,30000000,45000000,75000000',
    favor: 2889,
    time: 54600,
    atk: 0,
    def: 325,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 338,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12helm',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 970,
    surcharge: 2155,
    suggest: 430,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  doghelm: {
    uid: 'doghelm',
    level: 33,
    type: 'hh',
    xp: 115000,
    craftXp: 1725,
    value: 1400000,
    tradeMinMaxValue:
      '350000,440000,700000,1050000,1750000;17500000,22000000,35000000,52500000,87500000',
    favor: 3061,
    time: 54000,
    atk: 81,
    def: 325,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 338,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 23,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'bo',
    c2Qty: 3,
    c2Tags: 'common',
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'wolf',
    tag: null,
    discount: 1045,
    surcharge: 2320,
    suggest: 465,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-08-25',
    shardPrice: 300,
    capriceDelay: 0,
  },
  valentinehelm: {
    uid: 'valentinehelm',
    level: 34,
    type: 'hh',
    xp: 125000,
    craftXp: 1875,
    value: 1750000,
    tradeMinMaxValue:
      '440000,550000,880000,1300000,2200000;22000000,27500000,44000000,65500000,109500000',
    favor: 3671,
    time: 65400,
    atk: 0,
    def: 340,
    hp: 21,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 354,
    resource2: 'steel',
    r2Qty: 103,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-106',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-21',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-7',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1160,
    surcharge: 2580,
    suggest: 515,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-02-09',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12helm: {
    uid: 't12helm',
    level: 36,
    type: 'hh',
    xp: 235000,
    craftXp: 3525,
    value: 2500000,
    tradeMinMaxValue:
      '630000,780000,1250000,1900000,3150000;31500000,39000000,62500000,94000000,156500000',
    favor: 4293,
    time: 93600,
    atk: 0,
    def: 410,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 387,
    resource2: 'steel',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13helm',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-34',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: 'phoenix',
    tag: null,
    discount: 1375,
    surcharge: 3060,
    suggest: 610,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  chesshelm: {
    uid: 'chesshelm',
    level: 36,
    type: 'hh',
    xp: 235000,
    craftXp: 3525,
    value: 2850000,
    tradeMinMaxValue:
      '710000,890000,1450000,2150000,3550000;35500000,44500000,71500000,107000000,178000000',
    favor: 4782,
    time: 93600,
    atk: 103,
    def: 410,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 387,
    resource2: 'steel',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'resource1-97',
    upgrade2: 'resource2-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-10',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1465,
    surcharge: 3260,
    suggest: 650,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-07-12',
    shardPrice: 350,
    capriceDelay: 0,
  },
  pumpkinhelm: {
    uid: 'pumpkinhelm',
    level: 37,
    type: 'hh',
    xp: 250000,
    craftXp: 3750,
    value: 3000000,
    tradeMinMaxValue:
      '750000,940000,1500000,2250000,3750000;37500000,47000000,75000000,112500000,187500000',
    favor: 5204,
    time: 111600,
    atk: 0,
    def: 425,
    hp: 27,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'herbs',
    r1Qty: 400,
    resource2: 'oils',
    r2Qty: 105,
    resource3: 'mana',
    r3Qty: 38,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-100',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-21',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1505,
    surcharge: 3340,
    suggest: 670,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-10-18',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13helm: {
    uid: 't13helm',
    level: 39,
    type: 'hh',
    xp: 280000,
    craftXp: 4200,
    value: 5250000,
    tradeMinMaxValue:
      '1300000,1650000,2650000,3950000,6550000;65500000,82000000,131500000,197000000,328000000',
    favor: 6656,
    time: 159600,
    atk: 0,
    def: 525,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 438,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voideye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-88',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-13',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1965,
    surcharge: 4370,
    suggest: 875,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  blossomhelm: {
    uid: 'blossomhelm',
    level: 39,
    type: 'hh',
    xp: 280000,
    craftXp: 4200,
    value: 5400000,
    tradeMinMaxValue:
      '1350000,1700000,2700000,4050000,6750000;67500000,84500000,135000000,202500000,337500000',
    favor: 7090,
    time: 159600,
    atk: 131,
    def: 525,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 422,
    resource2: 'gems',
    r2Qty: 46,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 2,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-9',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-84',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1995,
    surcharge: 4430,
    suggest: 885,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-04-19',
    shardPrice: 375,
    capriceDelay: 0,
  },
  platinumhelm: {
    uid: 'platinumhelm',
    level: 39,
    type: 'hh',
    xp: 280000,
    craftXp: 4200,
    value: 14150000,
    tradeMinMaxValue:
      '3550000,4400000,7100000,10600000,17700000;177000000,221000000,354000000,530500000,884500000',
    favor: 7034,
    time: 159600,
    atk: 131,
    def: 525,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 438,
    resource2: 'steel',
    r2Qty: 139,
    resource3: 'gems',
    r3Qty: 52,
    component1: 'platinumjewel',
    c1Qty: 4,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-88',
    upgrade3: 'resource2-28',
    upgrade4: 'quality+1',
    upgrade5: 'value*1.25',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 2375,
    surcharge: 5275,
    suggest: 1055,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'goldcity3',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-07-07',
    shardPrice: 0,
    capriceDelay: 0,
  },
  leathercap: {
    uid: 'leathercap',
    level: 1,
    type: 'hm',
    xp: 135,
    craftXp: 2,
    value: 60,
    tradeMinMaxValue: '10,15,25,35,60;600,800,1200,1800,3000',
    favor: 1,
    time: 15,
    atk: 0,
    def: 8,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:leathercone',
    upgrade2: 'bp:hat',
    upgrade3: 'resource1-1',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-3',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 15,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  forestroguehat: {
    uid: 'forestroguehat',
    level: 3,
    type: 'hm',
    xp: 495,
    craftXp: 7,
    value: 270,
    tradeMinMaxValue: '130,170,270,400,670;6700,8400,13500,20000,33500',
    favor: 11,
    time: 70,
    atk: 0,
    def: 16,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 1,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 9,
    resource2: 'herbs',
    r2Qty: 2,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-2',
    upgrade3: 'resource2-2',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-5',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'natural',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 15,
    surcharge: 30,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'forest',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  hat: {
    uid: 'hat',
    level: 3,
    type: 'hm',
    xp: 495,
    craftXp: 7,
    value: 190,
    tradeMinMaxValue: '60,70,110,170,290;2900,3600,5700,8600,14500',
    favor: 10,
    time: 70,
    atk: 0,
    def: 16,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:featheredhat',
    upgrade3: 'resource1-2',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-5',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 25,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  featheredhat: {
    uid: 'featheredhat',
    level: 6,
    type: 'hm',
    xp: 1900,
    craftXp: 29,
    value: 860,
    tradeMinMaxValue: '260,320,520,770,1300;13000,16000,26000,38500,64500',
    favor: 19,
    time: 210,
    atk: 0,
    def: 32,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 16,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:tricorn',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 50,
    suggest: 10,
    speedup: 90,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  tricorn: {
    uid: 'tricorn',
    level: 8,
    type: 'hm',
    xp: 3450,
    craftXp: 52,
    value: 2250,
    tradeMinMaxValue: '560,700,1150,1700,2800;28000,35000,56500,84500,140000',
    favor: 27,
    time: 380,
    atk: 0,
    def: 44,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 5,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 21,
    resource2: 'iron',
    r2Qty: 11,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:hood',
    upgrade4: 'resource1-4',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 35,
    surcharge: 80,
    suggest: 15,
    speedup: 135,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  hood: {
    uid: 'hood',
    level: 12,
    type: 'hm',
    xp: 8150,
    craftXp: 122,
    value: 7400,
    tradeMinMaxValue:
      '1850,2300,3700,5600,9300;92500,115000,185000,280000,465000',
    favor: 64,
    time: 1050,
    atk: 0,
    def: 72,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 41,
    resource2: 'fabric',
    r2Qty: 8,
    resource3: null,
    r3Qty: 0,
    component1: 'leathercap',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'livingroots',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-8',
    upgrade3: 'bp:piratehat',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 65,
    surcharge: 145,
    suggest: 30,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  christmasroguehat: {
    uid: 'christmasroguehat',
    level: 13,
    type: 'hm',
    xp: 9650,
    craftXp: 145,
    value: 13500,
    tradeMinMaxValue:
      '3400,4200,6800,10000,17000;170000,210000,340000,505000,845000',
    favor: 91,
    time: 1320,
    atk: 0,
    def: 80,
    hp: 5,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 49,
    resource2: 'fabric',
    r2Qty: 10,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-10',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-2',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.8',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'santa',
    tag: null,
    discount: 85,
    surcharge: 190,
    suggest: 40,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-12-15',
    shardPrice: 120,
    capriceDelay: 240,
  },
  anubisroguehat: {
    uid: 'anubisroguehat',
    level: 13,
    type: 'hm',
    xp: 9650,
    craftXp: 145,
    value: 15000,
    tradeMinMaxValue:
      '3800,4700,7500,11500,19000;190000,235000,375000,565000,940000',
    favor: 129,
    time: 1320,
    atk: 20,
    def: 80,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 49,
    resource2: 'iron',
    r2Qty: 25,
    resource3: 'fabric',
    r3Qty: 10,
    component1: 'whitesand',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-2',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.8',
    supgrade2: 'resource2-25',
    supgrade3: 'multi+0.1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 90,
    surcharge: 200,
    suggest: 40,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-08-09',
    shardPrice: 120,
    capriceDelay: 0,
  },
  piratehat: {
    uid: 'piratehat',
    level: 16,
    type: 'hm',
    xp: 15000,
    craftXp: 225,
    value: 24000,
    tradeMinMaxValue:
      '6000,7500,12000,18000,30000;300000,375000,600000,900000,1500000',
    favor: 173,
    time: 2700,
    atk: 0,
    def: 100,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 85,
    resource2: 'fabric',
    r2Qty: 21,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: 'elvenwood',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-17',
    upgrade3: 'bp:nightcowl',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-4',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 255,
    suggest: 50,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  valentinehat: {
    uid: 'valentinehat',
    level: 17,
    type: 'hm',
    xp: 17000,
    craftXp: 255,
    value: 40500,
    tradeMinMaxValue:
      '10000,12500,20000,30000,51000;505000,635000,1000000,1500000,2550000',
    favor: 242,
    time: 3300,
    atk: 0,
    def: 110,
    hp: 7,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 8,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 1,
    w3BuildingReq: 4,
    resource1: 'leather',
    r1Qty: 99,
    resource2: 'fabric',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: 'arcanedust',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-3',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 145,
    surcharge: 325,
    suggest: 65,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-02-07',
    shardPrice: 140,
    capriceDelay: 240,
  },
  nightcowl: {
    uid: 'nightcowl',
    level: 20,
    type: 'hm',
    xp: 24500,
    craftXp: 368,
    value: 65000,
    tradeMinMaxValue:
      '16500,20000,33000,49000,81000;815000,1000000,1650000,2450000,4050000',
    favor: 379,
    time: 6000,
    atk: 0,
    def: 135,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'leather',
    r1Qty: 141,
    resource2: 'fabric',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'silkscarab',
    c1Qty: 3,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:windrunnerhat',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 185,
    surcharge: 410,
    suggest: 80,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pyramidroguehat: {
    uid: 'pyramidroguehat',
    level: 21,
    type: 'hm',
    xp: 27000,
    craftXp: 405,
    value: 93000,
    tradeMinMaxValue:
      '25000,32000,50000,76000,125000;1250000,1600000,2500000,3800000,6300000',
    favor: 502,
    time: 6600,
    atk: 0,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'leather',
    r1Qty: 155,
    resource2: 'fabric',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'hood',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-8',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'gale',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 220,
    surcharge: 485,
    suggest: 95,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'pyramid',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  farmerhood: {
    uid: 'farmerhood',
    level: 22,
    type: 'hm',
    xp: 30000,
    craftXp: 450,
    value: 120000,
    tradeMinMaxValue:
      '30000,38000,60000,90000,150000;1500000,1900000,3000000,4500000,7500000',
    favor: 591,
    time: 9000,
    atk: 38,
    def: 150,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'leather',
    r1Qty: 170,
    resource2: 'fabric',
    r2Qty: 43,
    resource3: null,
    r3Qty: 0,
    component1: 'evileye',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-9',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 250,
    surcharge: 550,
    suggest: 110,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-09-24',
    shardPrice: 160,
    capriceDelay: 240,
  },
  windrunnerhat: {
    uid: 'windrunnerhat',
    level: 24,
    type: 'hm',
    xp: 36000,
    craftXp: 540,
    value: 185000,
    tradeMinMaxValue:
      '46000,58000,93000,140000,230000;2300000,2900000,4650000,6950000,11500000',
    favor: 753,
    time: 13800,
    atk: 0,
    def: 170,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'leather',
    r1Qty: 199,
    resource2: 'herbs',
    r2Qty: 50,
    resource3: 'mana',
    r3Qty: 5,
    component1: 'overgrownvine',
    c1Qty: 4,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:dragonhat',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 305,
    surcharge: 675,
    suggest: 135,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  atlab_spiritmask: {
    uid: 'atlab_spiritmask',
    level: 25,
    type: 'hm',
    xp: 39500,
    craftXp: 593,
    value: 245000,
    tradeMinMaxValue:
      '61000,77000,125000,185000,310000;3050000,3850000,6150000,9200000,15500000',
    favor: 863,
    time: 16800,
    atk: 44,
    def: 175,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 8,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'wood',
    r1Qty: 192,
    resource2: 'ironwood',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 6,
    component1: 'timecrystal',
    c1Qty: 5,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-38',
    upgrade3: 'resource2-10',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 345,
    surcharge: 770,
    suggest: 155,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-02',
    shardPrice: 180,
    capriceDelay: 0,
  },
  musketeerroguehat: {
    uid: 'musketeerroguehat',
    level: 25,
    type: 'hm',
    xp: 39500,
    craftXp: 593,
    value: 250000,
    tradeMinMaxValue:
      '63000,78000,125000,190000,310000;3150000,3900000,6250000,9400000,15500000',
    favor: 981,
    time: 15600,
    atk: 0,
    def: 175,
    hp: 15,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'leather',
    r1Qty: 214,
    resource2: 'fabric',
    r2Qty: 57,
    resource3: 'gems',
    r3Qty: 6,
    component1: 'featheredhat',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'cquality1-1',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-43',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-11',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource3-6',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 350,
    surcharge: 780,
    suggest: 155,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-10-04',
    shardPrice: 180,
    capriceDelay: 0,
  },
  dragonhat: {
    uid: 'dragonhat',
    level: 28,
    type: 'hm',
    xp: 50500,
    craftXp: 758,
    value: 320000,
    tradeMinMaxValue:
      '80000,100000,160000,240000,400000;4000000,5000000,8000000,12000000,20000000',
    favor: 1375,
    time: 20400,
    atk: 0,
    def: 205,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'leather',
    r1Qty: 259,
    resource2: 'fabric',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 12,
    component1: 'savagegloves',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'ghostflag',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'bp:captainhat',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 395,
    surcharge: 880,
    suggest: 175,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  castleroguehat: {
    uid: 'castleroguehat',
    level: 29,
    type: 'hm',
    xp: 54500,
    craftXp: 818,
    value: 360000,
    tradeMinMaxValue:
      '97000,120000,195000,290000,490000;4850000,6050000,9700000,14500000,24500000',
    favor: 1777,
    time: 21600,
    atk: 0,
    def: 215,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'leather',
    r1Qty: 275,
    resource2: 'fabric',
    r2Qty: 77,
    resource3: 'mana',
    r3Qty: 14,
    component1: 'wingedbow',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'sewingthread',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'cquality1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'tempest',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 420,
    surcharge: 930,
    suggest: 185,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'castle',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  oktoberhat: {
    uid: 'oktoberhat',
    level: 29,
    type: 'hm',
    xp: 54500,
    craftXp: 818,
    value: 435000,
    tradeMinMaxValue:
      '110000,135000,220000,330000,540000;5450000,6800000,11000000,16500000,27000000',
    favor: 1777,
    time: 25800,
    atk: 54,
    def: 215,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'leather',
    r1Qty: 275,
    resource2: 'fabric',
    r2Qty: 77,
    resource3: 'mana',
    r3Qty: 14,
    component1: 'ghostflag',
    c1Qty: 2,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 455,
    surcharge: 1015,
    suggest: 205,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-09-23',
    shardPrice: 200,
    capriceDelay: 240,
  },
  captainhat: {
    uid: 'captainhat',
    level: 30,
    type: 'hm',
    xp: 58500,
    craftXp: 878,
    value: 605000,
    tradeMinMaxValue:
      '150000,190000,300000,450000,760000;7550000,9450000,15000000,22500000,38000000',
    favor: 1835,
    time: 30600,
    atk: 0,
    def: 225,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 290,
    resource2: 'fabric',
    r2Qty: 82,
    resource3: 'mana',
    r3Qty: 17,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: 'ectoplasm',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:jackalopehat',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 805,
    surcharge: 1790,
    suggest: 360,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  indiadayhat: {
    uid: 'indiadayhat',
    level: 31,
    type: 'hm',
    xp: 63000,
    craftXp: 945,
    value: 625000,
    tradeMinMaxValue:
      '155000,195000,310000,470000,780000;7800000,9750000,15500000,23500000,39000000',
    favor: 2352,
    time: 36600,
    atk: 0,
    def: 235,
    hp: 15,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 306,
    resource2: 'fabric',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 19,
    component1: 'deepcoral',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 815,
    surcharge: 1815,
    suggest: 365,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-01-26',
    shardPrice: 250,
    capriceDelay: 0,
  },
  jackalopehat: {
    uid: 'jackalopehat',
    level: 33,
    type: 'hm',
    xp: 110000,
    craftXp: 1650,
    value: 1250000,
    tradeMinMaxValue:
      '310000,390000,630000,940000,1550000;15500000,19500000,31500000,47000000,78000000',
    favor: 2736,
    time: 52800,
    atk: 0,
    def: 290,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 23,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12roguehat',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'time*0.8',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 990,
    surcharge: 2195,
    suggest: 440,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  peakroguehat: {
    uid: 'peakroguehat',
    level: 33,
    type: 'hm',
    xp: 110000,
    craftXp: 1650,
    value: 1400000,
    tradeMinMaxValue:
      '360000,450000,710000,1050000,1800000;18000000,22500000,35500000,53500000,89500000',
    favor: 3061,
    time: 52800,
    atk: 0,
    def: 290,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 23,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'time*0.8',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'nightmare',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 1045,
    surcharge: 2320,
    suggest: 465,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'peak',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  cincoroguehat: {
    uid: 'cincoroguehat',
    level: 33,
    type: 'hm',
    xp: 110000,
    craftXp: 1650,
    value: 1500000,
    tradeMinMaxValue:
      '380000,470000,750000,1150000,1900000;19000000,23500000,37500000,56500000,94000000',
    favor: 3061,
    time: 52800,
    atk: 73,
    def: 290,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 23,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'time*0.8',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1080,
    surcharge: 2395,
    suggest: 480,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-05-05',
    shardPrice: 300,
    capriceDelay: 0,
  },
  catroguehat: {
    uid: 'catroguehat',
    level: 34,
    type: 'hm',
    xp: 115000,
    craftXp: 1725,
    value: 1650000,
    tradeMinMaxValue:
      '410000,520000,830000,1250000,2050000;20500000,26000000,41500000,62000000,103000000',
    favor: 3479,
    time: 58200,
    atk: 0,
    def: 300,
    hp: 19,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 354,
    resource2: 'fabric',
    r2Qty: 103,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'nightcowl',
    c2Qty: 1,
    c2Tags: 'uncommon',
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-71',
    upgrade3: 'value*1.25',
    upgrade4: 'cquality2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-21',
    supgrade2: 'resource3-5',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'cat',
    tag: null,
    discount: 1130,
    surcharge: 2510,
    suggest: 500,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-07-28',
    shardPrice: 300,
    capriceDelay: 0,
  },
  goldroguehat: {
    uid: 'goldroguehat',
    level: 34,
    type: 'hm',
    xp: 115000,
    craftXp: 1725,
    value: 2900000,
    tradeMinMaxValue:
      '730000,910000,1450000,2200000,3650000;36500000,45500000,72500000,109000000,181500000',
    favor: 3479,
    time: 63000,
    atk: 0,
    def: 300,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 354,
    resource2: 'fabric',
    r2Qty: 103,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'tabletjewel',
    c1Qty: 5,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-71',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-21',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1110,
    surcharge: 2470,
    suggest: 495,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-07-10',
    shardPrice: 0,
    capriceDelay: 0,
  },
  t12roguehat: {
    uid: 't12roguehat',
    level: 36,
    type: 'hm',
    xp: 220000,
    craftXp: 3300,
    value: 2650000,
    tradeMinMaxValue:
      '660000,830000,1350000,2000000,3300000;33000000,41500000,66500000,99500000,165500000',
    favor: 4046,
    time: 90000,
    atk: 0,
    def: 365,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'leather',
    r1Qty: 387,
    resource2: 'fabric',
    r2Qty: 113,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'resource1-77',
    upgrade2: 'resource2-28',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13roguehat',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'time*0.7',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1420,
    surcharge: 3150,
    suggest: 630,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  easterroguehat: {
    uid: 'easterroguehat',
    level: 36,
    type: 'hm',
    xp: 220000,
    craftXp: 3300,
    value: 2850000,
    tradeMinMaxValue:
      '710000,890000,1450000,2150000,3550000;35500000,44500000,71500000,107000000,178000000',
    favor: 4362,
    time: 90000,
    atk: 91,
    def: 365,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 375,
    resource2: 'steel',
    r2Qty: 110,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'quality+1',
    upgrade2: 'time*0.8',
    upgrade3: 'resource1-56',
    upgrade4: 'value*1.25',
    upgrade5: 'resource2-22',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'resource1-56',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'rabbitspirit',
    tag: null,
    discount: 1465,
    surcharge: 3260,
    suggest: 650,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-04-06',
    shardPrice: 350,
    capriceDelay: 0,
  },
  airshiproguehat: {
    uid: 'airshiproguehat',
    level: 37,
    type: 'hm',
    xp: 230000,
    craftXp: 3450,
    value: 3000000,
    tradeMinMaxValue:
      '750000,940000,1500000,2250000,3750000;37500000,47000000,75000000,112500000,187500000',
    favor: 5154,
    time: 107400,
    atk: 0,
    def: 380,
    hp: 24,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 404,
    resource2: 'fabric',
    r2Qty: 123,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource2-37',
    upgrade4: 'resource1-81',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'time*0.8',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 1505,
    surcharge: 3340,
    suggest: 670,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-06-28',
    shardPrice: 350,
    capriceDelay: 0,
  },
  fallroguehat: {
    uid: 'fallroguehat',
    level: 37,
    type: 'hm',
    xp: 230000,
    craftXp: 3450,
    value: 3000000,
    tradeMinMaxValue:
      '750000,940000,1500000,2250000,3750000;37500000,47000000,75000000,112500000,187500000',
    favor: 5094,
    time: 107400,
    atk: 95,
    def: 380,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'wood',
    r1Qty: 400,
    resource2: 'ironwood',
    r2Qty: 115,
    resource3: 'mana',
    r3Qty: 34,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-80',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'time*0.8',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'carbuncle',
    tag: null,
    discount: 1505,
    surcharge: 3340,
    suggest: 670,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-09-06',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13roguehat: {
    uid: 't13roguehat',
    level: 39,
    type: 'hm',
    xp: 260000,
    craftXp: 3900,
    value: 5350000,
    tradeMinMaxValue:
      '1350000,1650000,2700000,4000000,6700000;67000000,83500000,134000000,200500000,334500000',
    favor: 6656,
    time: 153600,
    atk: 0,
    def: 465,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'leather',
    r1Qty: 438,
    resource2: 'mana',
    r2Qty: 39,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voideye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'gracesigil',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-88',
    upgrade3: 'resource2-10',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: 'chimera',
    tag: null,
    discount: 1985,
    surcharge: 4410,
    suggest: 880,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  inuitroguehat: {
    uid: 'inuitroguehat',
    level: 39,
    type: 'hm',
    xp: 260000,
    craftXp: 3900,
    value: 5550000,
    tradeMinMaxValue:
      '1400000,1750000,2800000,4150000,6950000;69500000,86500000,139000000,208000000,347000000',
    favor: 7256,
    time: 153600,
    atk: 0,
    def: 465,
    hp: 29,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'leather',
    r1Qty: 431,
    resource2: 'mana',
    r2Qty: 39,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcloth',
    c1Qty: 2,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-10',
    upgrade3: 'resource1-86',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-2',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: 'walrus',
    tag: null,
    discount: 2020,
    surcharge: 4490,
    suggest: 900,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-30',
    shardPrice: 375,
    capriceDelay: 0,
  },
  leathercone: {
    uid: 'leathercone',
    level: 1,
    type: 'hl',
    xp: 135,
    craftXp: 2,
    value: 60,
    tradeMinMaxValue: '20,20,35,50,90;900,1100,1800,2700,4500',
    favor: 1,
    time: 15,
    atk: 0,
    def: 7,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:blackcowl',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-3',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 15,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  blackcowl: {
    uid: 'blackcowl',
    level: 3,
    type: 'hl',
    xp: 495,
    craftXp: 7,
    value: 190,
    tradeMinMaxValue: '60,70,110,170,290;2900,3600,5700,8600,14500',
    favor: 10,
    time: 70,
    atk: 0,
    def: 15,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:laurels',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-5',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 25,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  laurels: {
    uid: 'laurels',
    level: 6,
    type: 'hl',
    xp: 1900,
    craftXp: 29,
    value: 690,
    tradeMinMaxValue: '210,260,410,620,1050;10500,13000,20500,31000,52000',
    favor: 19,
    time: 210,
    atk: 0,
    def: 29,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 16,
    resource2: 'herbs',
    r2Qty: 4,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:circlet',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 45,
    suggest: 10,
    speedup: 90,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  startertiara: {
    uid: 'startertiara',
    level: 7,
    type: 'hl',
    xp: 2600,
    craftXp: 39,
    value: 1950,
    tradeMinMaxValue: '590,730,1150,1750,2900;29500,36500,58500,88000,145000',
    favor: 23,
    time: 290,
    atk: 0,
    def: 34,
    hp: 2,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 17,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'resource1-3',
    upgrade3: 'time*0.75',
    upgrade4: 'component1-1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'quality+1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 35,
    surcharge: 75,
    suggest: 15,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 75,
    capriceDelay: 240,
  },
  circlet: {
    uid: 'circlet',
    level: 8,
    type: 'hl',
    xp: 3450,
    craftXp: 52,
    value: 2050,
    tradeMinMaxValue: '510,640,1050,1550,2600;25500,32000,51500,77000,130000',
    favor: 27,
    time: 380,
    atk: 0,
    def: 39,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 5,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 21,
    resource2: 'herbs',
    r2Qty: 5,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:wizardcone',
    upgrade4: 'resource1-4',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 35,
    surcharge: 80,
    suggest: 15,
    speedup: 135,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  wizardcone: {
    uid: 'wizardcone',
    level: 12,
    type: 'hl',
    xp: 8150,
    craftXp: 122,
    value: 8000,
    tradeMinMaxValue:
      '2000,2500,4000,6000,10000;100000,125000,200000,300000,500000',
    favor: 93,
    time: 1050,
    atk: 0,
    def: 64,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 41,
    resource2: 'herbs',
    r2Qty: 10,
    resource3: 'fabric',
    r3Qty: 8,
    component1: 'icypearl',
    c1Qty: 2,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-8',
    upgrade3: 'bp:witchhat',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 150,
    suggest: 30,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 9,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  swamphat: {
    uid: 'swamphat',
    level: 13,
    type: 'hl',
    xp: 9650,
    craftXp: 145,
    value: 11000,
    tradeMinMaxValue:
      '4700,5900,9500,14000,24000;235000,295000,475000,710000,1200000',
    favor: 91,
    time: 1320,
    atk: 0,
    def: 70,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 49,
    resource2: 'herbs',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-10',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-2',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'gale',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 80,
    surcharge: 175,
    suggest: 35,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'swamp',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  bunnyhat: {
    uid: 'bunnyhat',
    level: 14,
    type: 'hl',
    xp: 11500,
    craftXp: 173,
    value: 14000,
    tradeMinMaxValue:
      '3500,4400,7000,10500,17500;175000,220000,350000,525000,875000',
    favor: 169,
    time: 1680,
    atk: 19,
    def: 76,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 60,
    resource2: 'herbs',
    r2Qty: 15,
    resource3: 'fabric',
    r3Qty: 12,
    component1: 'icypearl',
    c1Qty: 2,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-3',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'rabbitspirit',
    tag: null,
    discount: 90,
    surcharge: 195,
    suggest: 40,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-03-23',
    shardPrice: 120,
    capriceDelay: 240,
  },
  witchhat: {
    uid: 'witchhat',
    level: 16,
    type: 'hl',
    xp: 15000,
    craftXp: 225,
    value: 18500,
    tradeMinMaxValue:
      '4600,5800,9300,14000,23000;230000,290000,465000,695000,1150000',
    favor: 276,
    time: 2400,
    atk: 0,
    def: 90,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 8,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 85,
    resource2: 'herbs',
    r2Qty: 21,
    resource3: 'fabric',
    r3Qty: 21,
    component1: 'ironamulet',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'whitesand',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-17',
    upgrade3: 'bp:midnighthat',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 100,
    surcharge: 225,
    suggest: 45,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  frogmagehat: {
    uid: 'frogmagehat',
    level: 17,
    type: 'hl',
    xp: 17000,
    craftXp: 255,
    value: 25500,
    tradeMinMaxValue:
      '6400,8000,13000,19000,32000;320000,400000,640000,955000,1600000',
    favor: 242,
    time: 3000,
    atk: 25,
    def: 98,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 8,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'herbs',
    r1Qty: 99,
    resource2: 'leather',
    r2Qty: 99,
    resource3: null,
    r3Qty: 0,
    component1: 'grass',
    c1Qty: 2,
    c1Tags: 'common',
    component2: 'livingroots',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-30',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-30',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 260,
    suggest: 50,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-01-11',
    shardPrice: 140,
    capriceDelay: 0,
  },
  liluhat: {
    uid: 'liluhat',
    level: 18,
    type: 'hl',
    xp: 19500,
    craftXp: 293,
    value: 37000,
    tradeMinMaxValue:
      '9300,11500,18500,28000,46000;465000,580000,925000,1400000,2300000',
    favor: 465,
    time: 4200,
    atk: 0,
    def: 105,
    hp: 7,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'leather',
    r1Qty: 113,
    resource2: 'herbs',
    r2Qty: 28,
    resource3: 'fabric',
    r3Qty: 29,
    component1: 'mooncrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'glowingmushrooms',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-6',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'owl',
    tag: null,
    discount: 140,
    surcharge: 310,
    suggest: 60,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-11-03',
    shardPrice: 140,
    capriceDelay: 240,
  },
  midnighthat: {
    uid: 'midnighthat',
    level: 20,
    type: 'hl',
    xp: 24500,
    craftXp: 368,
    value: 62000,
    tradeMinMaxValue:
      '15500,19500,31000,47000,78000;775000,970000,1550000,2350000,3900000',
    favor: 379,
    time: 6000,
    atk: 0,
    def: 120,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'leather',
    r1Qty: 141,
    resource2: 'fabric',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'silkscarab',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:shamanhat',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 180,
    surcharge: 400,
    suggest: 80,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  summonerhat: {
    uid: 'summonerhat',
    level: 21,
    type: 'hl',
    xp: 27000,
    craftXp: 405,
    value: 105000,
    tradeMinMaxValue:
      '26000,33000,53000,79000,130000;1300000,1650000,2650000,3950000,6550000',
    favor: 502,
    time: 7200,
    atk: 31,
    def: 125,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'leather',
    r1Qty: 155,
    resource2: 'fabric',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'carbuncle',
    tag: null,
    discount: 230,
    surcharge: 515,
    suggest: 105,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-08-13',
    shardPrice: 160,
    capriceDelay: 240,
  },
  chessmagehat: {
    uid: 'chessmagehat',
    level: 21,
    type: 'hl',
    xp: 27000,
    craftXp: 405,
    value: 100000,
    tradeMinMaxValue:
      '25000,31000,50000,75000,125000;1250000,1550000,2500000,3750000,6250000',
    favor: 444,
    time: 7200,
    atk: 0,
    def: 125,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'iron',
    r1Qty: 140,
    resource2: 'steel',
    r2Qty: 35,
    resource3: null,
    r3Qty: 0,
    component1: 'silkscarab',
    c1Qty: 3,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-28',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 225,
    surcharge: 500,
    suggest: 100,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-07-12',
    shardPrice: 160,
    capriceDelay: 0,
  },
  shamanhat: {
    uid: 'shamanhat',
    level: 24,
    type: 'hl',
    xp: 36000,
    craftXp: 540,
    value: 195000,
    tradeMinMaxValue:
      '49000,61000,98000,145000,240000;2450000,3050000,4900000,7300000,12000000',
    favor: 771,
    time: 13200,
    atk: 0,
    def: 150,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'leather',
    r1Qty: 199,
    resource2: 'fabric',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 7,
    component1: 'silverband',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'timecrystal',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:astralhat',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 310,
    surcharge: 690,
    suggest: 140,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldhat: {
    uid: 'goldhat',
    level: 27,
    type: 'hl',
    xp: 46500,
    craftXp: 698,
    value: 435000,
    tradeMinMaxValue:
      '110000,135000,220000,330000,540000;5450000,6800000,11000000,16500000,27000000',
    favor: 1366,
    time: 18000,
    atk: 0,
    def: 170,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 8,
    combo: 6,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'leather',
    r1Qty: 244,
    resource2: 'fabric',
    r2Qty: 67,
    resource3: 'mana',
    r3Qty: 13,
    component1: 'tabletfragment',
    c1Qty: 4,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-13',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'luxuriousspirit',
    tag: null,
    discount: 340,
    surcharge: 760,
    suggest: 150,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-02-22',
    shardPrice: 0,
    capriceDelay: 0,
  },
  astralhat: {
    uid: 'astralhat',
    level: 28,
    type: 'hl',
    xp: 50500,
    craftXp: 758,
    value: 325000,
    tradeMinMaxValue:
      '81000,100000,165000,240000,410000;4050000,5100000,8150000,12000000,20500000',
    favor: 1425,
    time: 19200,
    atk: 0,
    def: 180,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'leather',
    r1Qty: 259,
    resource2: 'fabric',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 16,
    component1: 'scrollofinvisibility',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'sewingthread',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'bp:tiara',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 400,
    surcharge: 885,
    suggest: 175,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  plaguemask: {
    uid: 'plaguemask',
    level: 29,
    type: 'hl',
    xp: 54500,
    craftXp: 818,
    value: 410000,
    tradeMinMaxValue:
      '105000,130000,210000,310000,510000;5150000,6400000,10500000,15500000,25500000',
    favor: 1847,
    time: 24600,
    atk: 48,
    def: 190,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'leather',
    r1Qty: 275,
    resource2: 'fabric',
    r2Qty: 77,
    resource3: 'mana',
    r3Qty: 19,
    component1: 'silverthistle',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'ectoplasm',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.7',
    upgrade2: 'cquality1-1',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-55',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-31',
    supgrade2: 'resource3-4',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'eagle',
    tag: null,
    discount: 445,
    surcharge: 990,
    suggest: 200,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-06-02',
    shardPrice: 200,
    capriceDelay: 240,
  },
  schoolhat2020: {
    uid: 'schoolhat2020',
    level: 29,
    type: 'hl',
    xp: 54500,
    craftXp: 818,
    value: 420000,
    tradeMinMaxValue:
      '105000,130000,210000,320000,530000;5250000,6550000,10500000,16000000,26500000',
    favor: 1847,
    time: 24000,
    atk: 0,
    def: 190,
    hp: 12,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'leather',
    r1Qty: 275,
    resource2: 'fabric',
    r2Qty: 77,
    resource3: 'mana',
    r3Qty: 19,
    component1: 'scrollofice',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'ghostflag',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.7',
    upgrade2: 'cquality1-1',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-55',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 450,
    surcharge: 1000,
    suggest: 200,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-08-26',
    shardPrice: 200,
    capriceDelay: 240,
  },
  tiara: {
    uid: 'tiara',
    level: 30,
    type: 'hl',
    xp: 58500,
    craftXp: 878,
    value: 600000,
    tradeMinMaxValue:
      '150000,190000,300000,450000,750000;7500000,9400000,15000000,22500000,37500000',
    favor: 1909,
    time: 30600,
    atk: 0,
    def: 195,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 290,
    resource2: 'fabric',
    r2Qty: 82,
    resource3: 'mana',
    r3Qty: 22,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:djinnhat',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 800,
    surcharge: 1780,
    suggest: 355,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  bathat: {
    uid: 'bathat',
    level: 30,
    type: 'hl',
    xp: 58500,
    craftXp: 878,
    value: 670000,
    tradeMinMaxValue:
      '170000,210000,340000,500000,840000;8400000,10500000,17000000,25000000,42000000',
    favor: 2128,
    time: 30600,
    atk: 49,
    def: 195,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 290,
    resource2: 'fabric',
    r2Qty: 82,
    resource3: 'mana',
    r3Qty: 22,
    component1: 'sirenshard',
    c1Qty: 3,
    c1Tags: null,
    component2: 'ghostflag',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-16',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 845,
    surcharge: 1880,
    suggest: 375,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-10-20',
    shardPrice: 250,
    capriceDelay: 0,
  },
  djinnhat: {
    uid: 'djinnhat',
    level: 33,
    type: 'hl',
    xp: 110000,
    craftXp: 1650,
    value: 1450000,
    tradeMinMaxValue:
      '360000,450000,730000,1100000,1800000;18000000,22500000,36500000,54500000,90500000',
    favor: 2889,
    time: 52800,
    atk: 0,
    def: 255,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12magehat',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1060,
    surcharge: 2355,
    suggest: 470,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  victorianmagehat: {
    uid: 'victorianmagehat',
    level: 33,
    type: 'hl',
    xp: 110000,
    craftXp: 1650,
    value: 1500000,
    tradeMinMaxValue:
      '380000,470000,750000,1150000,1900000;19000000,23500000,37500000,56500000,94000000',
    favor: 3218,
    time: 52800,
    atk: 0,
    def: 255,
    hp: 16,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1080,
    surcharge: 2395,
    suggest: 480,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-04-07',
    shardPrice: 300,
    capriceDelay: 0,
  },
  snowmagehat: {
    uid: 'snowmagehat',
    level: 34,
    type: 'hl',
    xp: 115000,
    craftXp: 1725,
    value: 1850000,
    tradeMinMaxValue:
      '460000,580000,930000,1400000,2300000;23000000,29000000,46500000,69500000,115500000',
    favor: 3671,
    time: 63000,
    atk: 66,
    def: 265,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 354,
    resource2: 'fabric',
    r2Qty: 103,
    resource3: 'mana',
    r3Qty: 34,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-71',
    upgrade4: 'resource2-21',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-21',
    supgrade2: 'resource3-7',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1195,
    surcharge: 2650,
    suggest: 530,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-12-01',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12magehat: {
    uid: 't12magehat',
    level: 36,
    type: 'hl',
    xp: 220000,
    craftXp: 3300,
    value: 2400000,
    tradeMinMaxValue:
      '600000,750000,1200000,1800000,3000000;30000000,37500000,60000000,90000000,150000000',
    favor: 4293,
    time: 90000,
    atk: 0,
    def: 320,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'leather',
    r1Qty: 387,
    resource2: 'fabric',
    r2Qty: 113,
    resource3: 'mana',
    r3Qty: 41,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13magehat',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-28',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1350,
    surcharge: 3000,
    suggest: 600,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  blossommagehat: {
    uid: 'blossommagehat',
    level: 36,
    type: 'hl',
    xp: 220000,
    craftXp: 3300,
    value: 2700000,
    tradeMinMaxValue:
      '680000,840000,1350000,2050000,3400000;34000000,42000000,67500000,101500000,169000000',
    favor: 4684,
    time: 90000,
    atk: 0,
    def: 320,
    hp: 20,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'leather',
    r1Qty: 380,
    resource2: 'fabric',
    r2Qty: 110,
    resource3: 'mana',
    r3Qty: 41,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.9',
    upgrade2: 'resource1-76',
    upgrade3: 'resource3-10',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-33',
    supgrade2: 'time*0.8',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 1430,
    surcharge: 3175,
    suggest: 635,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-03-22',
    shardPrice: 350,
    capriceDelay: 0,
  },
  goldhat2: {
    uid: 'goldhat2',
    level: 36,
    type: 'hl',
    xp: 220000,
    craftXp: 3300,
    value: 4900000,
    tradeMinMaxValue:
      '1250000,1550000,2450000,3700000,6150000;61500000,76500000,122500000,184000000,306500000',
    favor: 4008,
    time: 90000,
    atk: 0,
    def: 320,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 349,
    resource2: 'steel',
    r2Qty: 102,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-26',
    upgrade3: 'component2-1',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1430,
    surcharge: 3175,
    suggest: 635,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-08-05',
    shardPrice: 0,
    capriceDelay: 0,
  },
  volcanomagehat: {
    uid: 'volcanomagehat',
    level: 37,
    type: 'hl',
    xp: 230000,
    craftXp: 3450,
    value: 2500000,
    tradeMinMaxValue:
      '650000,810000,1300000,1950000,3250000;32500000,40500000,64500000,97000000,161500000',
    favor: 5488,
    time: 107400,
    atk: 0,
    def: 330,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 404,
    resource2: 'fabric',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 46,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-81',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-25',
    supgrade2: 'resource2-18',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'inferno',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'phoenix',
    tag: 'elemental',
    discount: 1375,
    surcharge: 3060,
    suggest: 610,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'volcano',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lunarmagehat: {
    uid: 'lunarmagehat',
    level: 37,
    type: 'hl',
    xp: 230000,
    craftXp: 3450,
    value: 2850000,
    tradeMinMaxValue:
      '710000,890000,1450000,2150000,3550000;35500000,44500000,71500000,107000000,178000000',
    favor: 4558,
    time: 107400,
    atk: 96,
    def: 363,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 364,
    resource2: 'steel',
    r2Qty: 111,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'sirenshard',
    c2Qty: 7,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-73',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-33',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1465,
    surcharge: 3260,
    suggest: 650,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-01-11',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13magehat: {
    uid: 't13magehat',
    level: 39,
    type: 'hl',
    xp: 260000,
    craftXp: 3900,
    value: 5350000,
    tradeMinMaxValue:
      '1350000,1650000,2700000,4000000,6700000;67000000,83500000,134000000,200500000,334500000',
    favor: 6883,
    time: 153600,
    atk: 0,
    def: 410,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'fabric',
    r1Qty: 139,
    resource2: 'mana',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcloth',
    c1Qty: 3,
    c1Tags: null,
    component2: 'sirenshard',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-21',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-8',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1985,
    surcharge: 4410,
    suggest: 880,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  riftmagehat: {
    uid: 'riftmagehat',
    level: 39,
    type: 'hl',
    xp: 260000,
    craftXp: 3900,
    value: 5600000,
    tradeMinMaxValue:
      '1400000,1800000,2850000,4250000,7100000;71000000,89000000,142000000,213000000,355000000',
    favor: 6891,
    time: 153600,
    atk: 0,
    def: 410,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 13,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'fabric',
    r1Qty: 135,
    resource2: 'mana',
    r2Qty: 50,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voideye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'sparksigil',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-20',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-8',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'divine',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 2030,
    surcharge: 4510,
    suggest: 900,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'rift',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  beachmagehat: {
    uid: 'beachmagehat',
    level: 39,
    type: 'hl',
    xp: 260000,
    craftXp: 3900,
    value: 5850000,
    tradeMinMaxValue:
      '1450000,1850000,2950000,4400000,7300000;73000000,91500000,146500000,219500000,365500000',
    favor: 5976,
    time: 122880,
    atk: 0,
    def: 410,
    hp: 26,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'tailor',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'herbs',
    r1Qty: 360,
    resource2: 'oils',
    r2Qty: 70,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'goldshell',
    c1Qty: 12,
    c1Tags: null,
    component2: 'deepcoral',
    c2Qty: 12,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'component2-2',
    upgrade2: 'component2-3',
    upgrade3: 'component1-2',
    upgrade4: 'component1-3',
    upgrade5: 'value*1.25',
    upgradeBonus: 2,
    supgrade1: 'resource2-70',
    supgrade2: 'quality+1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 2070,
    surcharge: 4605,
    suggest: 920,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-06-28',
    shardPrice: 375,
    capriceDelay: 0,
  },
  ironbracers: {
    uid: 'ironbracers',
    level: 1,
    type: 'gh',
    xp: 135,
    craftXp: 2,
    value: 60,
    tradeMinMaxValue: '20,20,35,50,90;900,1100,1800,2700,4500',
    favor: 1,
    time: 15,
    atk: 0,
    def: 7,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:lightgauntlets',
    upgrade3: 'resource1-1',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-3',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 15,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lightgauntlets: {
    uid: 'lightgauntlets',
    level: 4,
    type: 'gh',
    xp: 855,
    craftXp: 13,
    value: 410,
    tradeMinMaxValue: '120,150,250,370,620;6200,7700,12500,18500,31000',
    favor: 13,
    time: 100,
    atk: 0,
    def: 20,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 12,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'component1-1',
    upgrade2: 'bp:gauntlets',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-6',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 15,
    surcharge: 35,
    suggest: 5,
    speedup: 55,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  gauntlets: {
    uid: 'gauntlets',
    level: 7,
    type: 'gh',
    xp: 2650,
    craftXp: 40,
    value: 1300,
    tradeMinMaxValue: '330,410,650,980,1650;16500,20500,32500,49000,81500',
    favor: 21,
    time: 290,
    atk: 0,
    def: 36,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 17,
    resource2: 'leather',
    r2Qty: 9,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:demigauntlets',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 60,
    suggest: 10,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  demigauntlets: {
    uid: 'demigauntlets',
    level: 10,
    type: 'gh',
    xp: 5600,
    craftXp: 84,
    value: 4250,
    tradeMinMaxValue:
      '1050,1350,2100,3200,5300;53000,66500,105000,160000,265000',
    favor: 42,
    time: 590,
    atk: 0,
    def: 54,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 7,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 30,
    resource2: 'leather',
    r2Qty: 15,
    resource3: null,
    r3Qty: 0,
    component1: 'lightgauntlets',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'arcanedust',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-6',
    upgrade3: 'bp:knightgauntlets',
    upgrade4: 'time*0.75',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 50,
    surcharge: 110,
    suggest: 20,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 7,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pumpkingauntlets: {
    uid: 'pumpkingauntlets',
    level: 11,
    type: 'gh',
    xp: 6900,
    craftXp: 104,
    value: 6000,
    tradeMinMaxValue:
      '1500,1900,3000,4500,7500;75000,94000,150000,225000,375000',
    favor: 56,
    time: 840,
    atk: 16,
    def: 62,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 34,
    resource2: 'herbs',
    r2Qty: 9,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-7',
    upgrade3: 'time*0.75',
    upgrade4: 'resource1-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-9',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 60,
    surcharge: 130,
    suggest: 25,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-10-28',
    shardPrice: 100,
    capriceDelay: 0,
  },
  knightgauntlets: {
    uid: 'knightgauntlets',
    level: 14,
    type: 'gh',
    xp: 11500,
    craftXp: 173,
    value: 11500,
    tradeMinMaxValue:
      '2900,3600,5800,8600,14500;145000,180000,290000,430000,720000',
    favor: 106,
    time: 1680,
    atk: 0,
    def: 82,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 60,
    resource2: 'steel',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'bp:paladingauntlets',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 80,
    surcharge: 180,
    suggest: 35,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  christmasgauntlets: {
    uid: 'christmasgauntlets',
    level: 15,
    type: 'gh',
    xp: 13500,
    craftXp: 203,
    value: 16000,
    tradeMinMaxValue:
      '4000,5000,8000,12000,20000;200000,250000,400000,600000,1000000',
    favor: 150,
    time: 2160,
    atk: 0,
    def: 88,
    hp: 6,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 71,
    resource2: 'steel',
    r2Qty: 16,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-14',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.8',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'santa',
    tag: null,
    discount: 95,
    surcharge: 210,
    suggest: 40,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-12-14',
    shardPrice: 120,
    capriceDelay: 0,
  },
  cincogauntlets: {
    uid: 'cincogauntlets',
    level: 16,
    type: 'gh',
    xp: 15500,
    craftXp: 233,
    value: 18500,
    tradeMinMaxValue:
      '4600,5800,9300,14000,23000;230000,290000,465000,695000,1150000',
    favor: 193,
    time: 2700,
    atk: 24,
    def: 96,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 85,
    resource2: 'steel',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 6,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-20',
    upgrade3: 'resource2-5',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 100,
    surcharge: 225,
    suggest: 45,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-05-03',
    shardPrice: 120,
    capriceDelay: 0,
  },
  paladingauntlets: {
    uid: 'paladingauntlets',
    level: 17,
    type: 'gh',
    xp: 17500,
    craftXp: 263,
    value: 22500,
    tradeMinMaxValue:
      '5600,7000,11500,17000,28000;280000,350000,565000,845000,1400000',
    favor: 349,
    time: 3300,
    atk: 0,
    def: 105,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'iron',
    r1Qty: 99,
    resource2: 'herbs',
    r2Qty: 25,
    resource3: 'steel',
    r3Qty: 25,
    component1: 'sharpfang',
    c1Qty: 3,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:kote',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 110,
    surcharge: 245,
    suggest: 50,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  desertgauntlets: {
    uid: 'desertgauntlets',
    level: 18,
    type: 'gh',
    xp: 20000,
    craftXp: 300,
    value: 36000,
    tradeMinMaxValue:
      '11000,14000,22000,33000,56000;555000,695000,1100000,1650000,2800000',
    favor: 296,
    time: 4200,
    atk: 0,
    def: 110,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 6,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'iron',
    r1Qty: 113,
    resource2: 'steel',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-3',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'flaming',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 140,
    surcharge: 310,
    suggest: 60,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'desert',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  oktoberfestgauntlets: {
    uid: 'oktoberfestgauntlets',
    level: 18,
    type: 'gh',
    xp: 20000,
    craftXp: 300,
    value: 48000,
    tradeMinMaxValue:
      '12000,15000,24000,36000,60000;600000,750000,1200000,1800000,3000000',
    favor: 296,
    time: 4200,
    atk: 0,
    def: 110,
    hp: 7,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'iron',
    r1Qty: 113,
    resource2: 'steel',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'thiefgloves',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'mooncrystal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 160,
    surcharge: 355,
    suggest: 70,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-10-11',
    shardPrice: 140,
    capriceDelay: 240,
  },
  kote: {
    uid: 'kote',
    level: 20,
    type: 'gh',
    xp: 25000,
    craftXp: 375,
    value: 69000,
    tradeMinMaxValue:
      '17500,22000,35000,52000,86000;865000,1100000,1750000,2600000,4300000',
    favor: 622,
    time: 6000,
    atk: 0,
    def: 125,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'iron',
    r1Qty: 141,
    resource2: 'leather',
    r2Qty: 70,
    resource3: 'steel',
    r3Qty: 36,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:generalgauntlets',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 190,
    surcharge: 420,
    suggest: 85,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  indiadaygauntlets: {
    uid: 'indiadaygauntlets',
    level: 21,
    type: 'gh',
    xp: 27500,
    craftXp: 413,
    value: 82500,
    tradeMinMaxValue:
      '21000,26000,41000,62000,105000;1050000,1300000,2050000,3100000,5150000',
    favor: 791,
    time: 7800,
    atk: 0,
    def: 135,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'iron',
    r1Qty: 155,
    resource2: 'leather',
    r2Qty: 78,
    resource3: 'steel',
    r3Qty: 39,
    component1: 'evileye',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-16',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-16',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 205,
    surcharge: 460,
    suggest: 90,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-01-26',
    shardPrice: 160,
    capriceDelay: 240,
  },
  fruitgauntlets: {
    uid: 'fruitgauntlets',
    level: 22,
    type: 'gh',
    xp: 30500,
    craftXp: 458,
    value: 91500,
    tradeMinMaxValue:
      '23000,29000,46000,69000,115000;1150000,1450000,2300000,3450000,5700000',
    favor: 884,
    time: 9000,
    atk: 35,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'herbs',
    r1Qty: 160,
    resource2: 'wood',
    r2Qty: 42,
    resource3: 'oils',
    r3Qty: 42,
    component1: 'silkscarab',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource3-11',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-40',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource2-42',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 215,
    surcharge: 480,
    suggest: 95,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-08-23',
    shardPrice: 160,
    capriceDelay: 0,
  },
  generalgauntlets: {
    uid: 'generalgauntlets',
    level: 25,
    type: 'gh',
    xp: 40500,
    craftXp: 608,
    value: 185000,
    tradeMinMaxValue:
      '46000,58000,93000,140000,230000;2300000,2900000,4650000,6950000,11500000',
    favor: 896,
    time: 16800,
    atk: 0,
    def: 165,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'iron',
    r1Qty: 214,
    resource2: 'steel',
    r2Qty: 57,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'ancientmarble',
    c1Qty: 5,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'bp:berserkergauntlets',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-4',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 305,
    surcharge: 675,
    suggest: 135,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  berserkergauntlets: {
    uid: 'berserkergauntlets',
    level: 27,
    type: 'gh',
    xp: 47500,
    craftXp: 713,
    value: 305000,
    tradeMinMaxValue:
      '76000,95000,155000,230000,380000;3800000,4750000,7650000,11500000,19000000',
    favor: 1224,
    time: 18000,
    atk: 0,
    def: 185,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'iron',
    r1Qty: 244,
    resource2: 'steel',
    r2Qty: 67,
    resource3: 'gems',
    r3Qty: 13,
    component1: 'ghostflag',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:juggernautgauntlets',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-4',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 385,
    surcharge: 860,
    suggest: 170,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldgauntlets: {
    uid: 'goldgauntlets',
    level: 28,
    type: 'gh',
    xp: 51500,
    craftXp: 773,
    value: 530000,
    tradeMinMaxValue:
      '135000,165000,270000,400000,660000;6650000,8300000,13500000,20000000,33000000',
    favor: 1589,
    time: 21600,
    atk: 0,
    def: 190,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'iron',
    r1Qty: 259,
    resource2: 'steel',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'tabletfragment',
    c1Qty: 5,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 16,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-8',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'luxuriousspirit',
    tag: null,
    discount: 380,
    surcharge: 840,
    suggest: 170,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-03-21',
    shardPrice: 0,
    capriceDelay: 0,
  },
  juggernautgauntlets: {
    uid: 'juggernautgauntlets',
    level: 30,
    type: 'gh',
    xp: 60000,
    craftXp: 900,
    value: 600000,
    tradeMinMaxValue:
      '150000,190000,300000,450000,750000;7500000,9400000,15000000,22500000,37500000',
    favor: 1909,
    time: 31200,
    atk: 0,
    def: 210,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 290,
    resource2: 'steel',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'deepcoral',
    c1Qty: 5,
    c1Tags: null,
    component2: 'overgrownvine',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:celestialgauntlets',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-4',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 800,
    surcharge: 1780,
    suggest: 355,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  wintergauntlets: {
    uid: 'wintergauntlets',
    level: 31,
    type: 'gh',
    xp: 64500,
    craftXp: 968,
    value: 645000,
    tradeMinMaxValue:
      '160000,200000,320000,480000,810000;8050000,10000000,16000000,24000000,40500000',
    favor: 2451,
    time: 37200,
    atk: 55,
    def: 220,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 306,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'sirenshard',
    c1Qty: 4,
    c1Tags: null,
    component2: 'darkscale',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-4',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 830,
    surcharge: 1845,
    suggest: 370,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-12-01',
    shardPrice: 250,
    capriceDelay: 0,
  },
  celestialgauntlets: {
    uid: 'celestialgauntlets',
    level: 33,
    type: 'gh',
    xp: 110000,
    craftXp: 1650,
    value: 1200000,
    tradeMinMaxValue:
      '300000,380000,600000,900000,1500000;15000000,19000000,30000000,45000000,75000000',
    favor: 2889,
    time: 53400,
    atk: 0,
    def: 270,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 338,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12gauntlets',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 970,
    surcharge: 2155,
    suggest: 430,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  naturegauntlets: {
    uid: 'naturegauntlets',
    level: 33,
    type: 'gh',
    xp: 110000,
    craftXp: 1650,
    value: 1300000,
    tradeMinMaxValue:
      '330000,410000,650000,980000,1650000;16500000,20500000,32500000,49000000,81500000',
    favor: 3218,
    time: 53400,
    atk: 0,
    def: 270,
    hp: 17,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 338,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'ancientmarble',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1005,
    surcharge: 2235,
    suggest: 445,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-04-21',
    shardPrice: 300,
    capriceDelay: 0,
  },
  fallgauntlets: {
    uid: 'fallgauntlets',
    level: 34,
    type: 'gh',
    xp: 120000,
    craftXp: 1800,
    value: 1500000,
    tradeMinMaxValue:
      '380000,470000,750000,1150000,1900000;19000000,23500000,37500000,56500000,94000000',
    favor: 9057,
    time: 63600,
    atk: 70,
    def: 280,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 354,
    resource2: 'leather',
    r2Qty: 304,
    resource3: 'herbs',
    r3Qty: 254,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-71',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-61',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-51',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1080,
    surcharge: 2395,
    suggest: 480,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-10-05',
    shardPrice: 300,
    capriceDelay: 0,
  },
  aegirgauntlets: {
    uid: 'aegirgauntlets',
    level: 34,
    type: 'gh',
    xp: 120000,
    craftXp: 1800,
    value: 1700000,
    tradeMinMaxValue:
      '430000,530000,850000,1300000,2150000;21500000,26500000,42500000,64000000,106500000',
    favor: 3671,
    time: 63600,
    atk: 0,
    def: 280,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 354,
    resource2: 'steel',
    r2Qty: 103,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'sirenshard',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-106',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-31',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1145,
    surcharge: 2545,
    suggest: 510,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-02-22',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12gauntlets: {
    uid: 't12gauntlets',
    level: 37,
    type: 'gh',
    xp: 235000,
    craftXp: 3525,
    value: 2450000,
    tradeMinMaxValue:
      '610000,770000,1250000,1850000,3050000;30500000,38500000,61500000,92000000,153000000',
    favor: 4930,
    time: 108600,
    atk: 0,
    def: 355,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 404,
    resource2: 'steel',
    r2Qty: 123,
    resource3: 'gems',
    r3Qty: 46,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-81',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13gauntlets',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'resource2-31',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: 'phoenix',
    tag: null,
    discount: 1365,
    surcharge: 3030,
    suggest: 605,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  mushroomgauntlets: {
    uid: 'mushroomgauntlets',
    level: 37,
    type: 'gh',
    xp: 235000,
    craftXp: 3525,
    value: 2700000,
    tradeMinMaxValue:
      '680000,840000,1350000,2050000,3400000;34000000,42000000,67500000,101500000,169000000',
    favor: 4794,
    time: 108600,
    atk: 89,
    def: 355,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 360,
    resource2: 'steel',
    r2Qty: 115,
    resource3: 'gems',
    r3Qty: 45,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-54',
    upgrade3: 'resource2-29',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'resource1-54',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1430,
    surcharge: 3175,
    suggest: 635,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-05-17',
    shardPrice: 350,
    capriceDelay: 0,
  },
  goldgauntlets2: {
    uid: 'goldgauntlets2',
    level: 37,
    type: 'gh',
    xp: 235000,
    craftXp: 3525,
    value: 5000000,
    tradeMinMaxValue:
      '1250000,1550000,2500000,3750000,6250000;62500000,78000000,125000000,187500000,312500000',
    favor: 5488,
    time: 108600,
    atk: 0,
    def: 355,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 404,
    resource2: 'steel',
    r2Qty: 123,
    resource3: 'gems',
    r3Qty: 46,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-81',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-31',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1440,
    surcharge: 3205,
    suggest: 640,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-09-02',
    shardPrice: 0,
    capriceDelay: 0,
  },
  darkknightgauntlets: {
    uid: 'darkknightgauntlets',
    level: 38,
    type: 'gh',
    xp: 250000,
    craftXp: 3750,
    value: 2900000,
    tradeMinMaxValue:
      '730000,910000,1450000,2200000,3650000;36500000,45500000,72500000,109000000,181500000',
    favor: 6249,
    time: 129600,
    atk: 0,
    def: 365,
    hp: 23,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'iron',
    r1Qty: 421,
    resource2: 'steel',
    r2Qty: 134,
    resource3: 'gems',
    r3Qty: 50,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-63',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-34',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1480,
    surcharge: 3290,
    suggest: 660,
    speedup: 3645,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-11-02',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13gauntlets: {
    uid: 't13gauntlets',
    level: 39,
    type: 'gh',
    xp: 265000,
    craftXp: 3975,
    value: 5350000,
    tradeMinMaxValue:
      '1350000,1650000,2700000,4000000,6700000;67000000,83500000,134000000,200500000,334500000',
    favor: 6656,
    time: 154800,
    atk: 0,
    def: 435,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 438,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-88',
    upgrade3: 'resource2-10',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1985,
    surcharge: 4410,
    suggest: 880,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  arcadegauntlets: {
    uid: 'arcadegauntlets',
    level: 39,
    type: 'gh',
    xp: 265000,
    craftXp: 3975,
    value: 5500000,
    tradeMinMaxValue:
      '1400000,1700000,2750000,4150000,6900000;69000000,86000000,137500000,206500000,344000000',
    favor: 7275,
    time: 154800,
    atk: 116,
    def: 435,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'jeweler',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 432,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voideye',
    c1Qty: 2,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.5',
    upgrade2: 'resource2-10',
    upgrade3: 'resource1-65',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'resource1-65',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 2010,
    surcharge: 4470,
    suggest: 895,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-16',
    shardPrice: 375,
    capriceDelay: 0,
  },
  leathergloves: {
    uid: 'leathergloves',
    level: 1,
    type: 'gl',
    xp: 135,
    craftXp: 2,
    value: 60,
    tradeMinMaxValue: '20,20,35,50,90;900,1100,1800,2700,4500',
    favor: 1,
    time: 15,
    atk: 0,
    def: 7,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:ironbracers',
    upgrade2: 'bp:bracers',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-4',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 15,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  bracers: {
    uid: 'bracers',
    level: 3,
    type: 'gl',
    xp: 480,
    craftXp: 7,
    value: 180,
    tradeMinMaxValue: '50,70,110,160,270;2700,3400,5400,8100,13500',
    favor: 10,
    time: 60,
    atk: 0,
    def: 14,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:thiefgloves',
    upgrade3: 'resource1-2',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-5',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 25,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  thiefgloves: {
    uid: 'thiefgloves',
    level: 5,
    type: 'gl',
    xp: 1250,
    craftXp: 19,
    value: 720,
    tradeMinMaxValue: '220,270,430,650,1100;11000,13500,21500,32500,54000',
    favor: 16,
    time: 150,
    atk: 0,
    def: 22,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 14,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:studdedleathergloves',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 45,
    suggest: 10,
    speedup: 70,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  grottogloves: {
    uid: 'grottogloves',
    level: 7,
    type: 'gl',
    xp: 2500,
    craftXp: 38,
    value: 1200,
    tradeMinMaxValue: '530,660,1050,1600,2700;26500,33000,53000,79500,135000',
    favor: 23,
    time: 240,
    atk: 0,
    def: 32,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 3,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 17,
    resource2: 'herbs',
    r2Qty: 4,
    resource3: null,
    r3Qty: 0,
    component1: 'bracers',
    c1Qty: 1,
    c1Tags: 'common',
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-3',
    upgrade4: 'time*0.9',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource2-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'breeze',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 25,
    surcharge: 60,
    suggest: 10,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'grotto',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  patchworkgloves: {
    uid: 'patchworkgloves',
    level: 8,
    type: 'gl',
    xp: 3300,
    craftXp: 50,
    value: 2350,
    tradeMinMaxValue: '710,880,1400,2100,3500;35500,44000,70500,105000,175000',
    favor: 30,
    time: 380,
    atk: 0,
    def: 37,
    hp: 0,
    eva: '0.05',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 5,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 21,
    resource2: 'herbs',
    r2Qty: 5,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.8',
    upgrade3: 'resource1-4',
    upgrade4: 'time*0.8',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 85,
    suggest: 15,
    speedup: 135,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-11-19',
    shardPrice: 75,
    capriceDelay: 240,
  },
  studdedleathergloves: {
    uid: 'studdedleathergloves',
    level: 9,
    type: 'gl',
    xp: 4200,
    craftXp: 63,
    value: 3350,
    tradeMinMaxValue: '840,1050,1700,2500,4200;42000,52500,84000,125000,210000',
    favor: 35,
    time: 490,
    atk: 0,
    def: 42,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 26,
    resource2: 'iron',
    r2Qty: 6,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:vambraces',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 45,
    surcharge: 100,
    suggest: 20,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lunargloves: {
    uid: 'lunargloves',
    level: 11,
    type: 'gl',
    xp: 6450,
    craftXp: 97,
    value: 5100,
    tradeMinMaxValue:
      '1300,1600,2600,3800,6400;64000,79500,130000,190000,320000',
    favor: 56,
    time: 810,
    atk: 0,
    def: 54,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'tailor',
    worker2: 'blacksmith',
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 34,
    resource2: 'iron',
    r2Qty: 9,
    resource3: null,
    r3Qty: 0,
    component1: 'ironbracers',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'preciousgem',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'resource1-7',
    upgrade2: 'time*0.75',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 55,
    surcharge: 120,
    suggest: 25,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-01-25',
    shardPrice: 100,
    capriceDelay: 0,
  },
  vambraces: {
    uid: 'vambraces',
    level: 13,
    type: 'gl',
    xp: 9250,
    craftXp: 139,
    value: 8750,
    tradeMinMaxValue:
      '2200,2700,4400,6600,11000;110000,135000,220000,330000,545000',
    favor: 81,
    time: 1290,
    atk: 0,
    def: 66,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 49,
    resource2: 'fabric',
    r2Qty: 10,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-10',
    upgrade3: 'bp:savagegloves',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 155,
    suggest: 30,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  eastergloves2020: {
    uid: 'eastergloves2020',
    level: 14,
    type: 'gl',
    xp: 11000,
    craftXp: 165,
    value: 15500,
    tradeMinMaxValue:
      '3900,4800,7800,11500,19500;195000,240000,390000,580000,970000',
    favor: 119,
    time: 1650,
    atk: 18,
    def: 72,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 60,
    resource2: 'fabric',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'rabbitspirit',
    tag: null,
    discount: 90,
    surcharge: 205,
    suggest: 40,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-04-07',
    shardPrice: 120,
    capriceDelay: 240,
  },
  savagegloves: {
    uid: 'savagegloves',
    level: 16,
    type: 'gl',
    xp: 14500,
    craftXp: 218,
    value: 19000,
    tradeMinMaxValue:
      '4800,5900,9500,14500,24000;240000,295000,475000,715000,1200000',
    favor: 276,
    time: 2400,
    atk: 0,
    def: 84,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 85,
    resource2: 'herbs',
    r2Qty: 21,
    resource3: 'fabric',
    r3Qty: 21,
    component1: 'sharpfang',
    c1Qty: 2,
    c1Tags: null,
    component2: 'livingroots',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-17',
    upgrade3: 'bp:ninjagloves',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'lizard',
    tag: null,
    discount: 100,
    surcharge: 225,
    suggest: 45,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  desertgloves: {
    uid: 'desertgloves',
    level: 18,
    type: 'gl',
    xp: 18500,
    craftXp: 278,
    value: 29000,
    tradeMinMaxValue:
      '9400,11500,18500,28000,47000;470000,585000,935000,1400000,2350000',
    favor: 465,
    time: 4200,
    atk: 0,
    def: 98,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 6,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 1,
    w3BuildingReq: 5,
    resource1: 'leather',
    r1Qty: 113,
    resource2: 'herbs',
    r2Qty: 28,
    resource3: 'fabric',
    r3Qty: 29,
    component1: 'sharpfang',
    c1Qty: 2,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'unholy',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 125,
    surcharge: 275,
    suggest: 55,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'desert',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ninjagloves: {
    uid: 'ninjagloves',
    level: 20,
    type: 'gl',
    xp: 23500,
    craftXp: 353,
    value: 65500,
    tradeMinMaxValue:
      '16500,20000,33000,49000,82000;820000,1000000,1650000,2450000,4100000',
    favor: 379,
    time: 6000,
    atk: 0,
    def: 110,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'leather',
    r1Qty: 141,
    resource2: 'fabric',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'evileye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:windrunnergloves',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 185,
    surcharge: 410,
    suggest: 80,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  catgloves: {
    uid: 'catgloves',
    level: 21,
    type: 'gl',
    xp: 26000,
    craftXp: 390,
    value: 90000,
    tradeMinMaxValue:
      '23000,28000,45000,68000,115000;1150000,1400000,2250000,3400000,5650000',
    favor: 502,
    time: 7200,
    atk: 29,
    def: 115,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'leather',
    r1Qty: 155,
    resource2: 'fabric',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'sharpfang',
    c1Qty: 5,
    c1Tags: null,
    component2: 'thiefgloves',
    c2Qty: 2,
    c2Tags: 'common',
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'cat',
    tag: null,
    discount: 215,
    surcharge: 480,
    suggest: 95,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-07-27',
    shardPrice: 160,
    capriceDelay: 240,
  },
  windrunnergloves: {
    uid: 'windrunnergloves',
    level: 24,
    type: 'gl',
    xp: 34500,
    craftXp: 518,
    value: 175000,
    tradeMinMaxValue:
      '44000,55000,88000,130000,220000;2200000,2750000,4400000,6550000,11000000',
    favor: 771,
    time: 12000,
    atk: 0,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'leather',
    r1Qty: 199,
    resource2: 'fabric',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 7,
    component1: 'hunterbow',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'timecrystal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:dragongloves',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'cquality1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 295,
    surcharge: 655,
    suggest: 130,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  blossomgloves: {
    uid: 'blossomgloves',
    level: 25,
    type: 'gl',
    xp: 38000,
    craftXp: 570,
    value: 180000,
    tradeMinMaxValue:
      '45000,56000,90000,135000,230000;2250000,2800000,4500000,6750000,11500000',
    favor: 850,
    time: 16200,
    atk: 0,
    def: 145,
    hp: 9,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 1,
    w3BuildingReq: 12,
    resource1: 'leather',
    r1Qty: 186,
    resource2: 'fabric',
    r2Qty: 46,
    resource3: 'mana',
    r3Qty: 8,
    component1: 'overgrownvine',
    c1Qty: 3,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'resource2-9',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource1-37',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 300,
    surcharge: 665,
    suggest: 135,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-04-18',
    shardPrice: 180,
    capriceDelay: 0,
  },
  dragongloves: {
    uid: 'dragongloves',
    level: 27,
    type: 'gl',
    xp: 45000,
    craftXp: 675,
    value: 305000,
    tradeMinMaxValue:
      '76000,95000,155000,230000,380000;3800000,4750000,7650000,11500000,19000000',
    favor: 1224,
    time: 17400,
    atk: 0,
    def: 160,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'leather',
    r1Qty: 244,
    resource2: 'fabric',
    r2Qty: 67,
    resource3: 'mana',
    r3Qty: 13,
    component1: 'sewingthread',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:captaingloves',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-13',
    supgrade2: 'component2-4',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 385,
    surcharge: 860,
    suggest: 170,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  oktobergloves: {
    uid: 'oktobergloves',
    level: 28,
    type: 'gl',
    xp: 48500,
    craftXp: 728,
    value: 415000,
    tradeMinMaxValue:
      '105000,130000,210000,310000,520000;5200000,6500000,10500000,15500000,26000000',
    favor: 1589,
    time: 21000,
    atk: 0,
    def: 170,
    hp: 0,
    eva: '0.05',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'leather',
    r1Qty: 259,
    resource2: 'fabric',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 16,
    component1: 'ectoplasm',
    c1Qty: 4,
    c1Tags: null,
    component2: 'overgrownvine',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-14',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 450,
    surcharge: 995,
    suggest: 200,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-09-22',
    shardPrice: 200,
    capriceDelay: 0,
  },
  captaingloves: {
    uid: 'captaingloves',
    level: 30,
    type: 'gl',
    xp: 56500,
    craftXp: 848,
    value: 610000,
    tradeMinMaxValue:
      '155000,190000,310000,460000,760000;7650000,9550000,15500000,23000000,38000000',
    favor: 1909,
    time: 30000,
    atk: 0,
    def: 185,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 290,
    resource2: 'fabric',
    r2Qty: 82,
    resource3: 'mana',
    r3Qty: 22,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: 'ghostflag',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:jackalopegloves',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 810,
    surcharge: 1795,
    suggest: 360,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  vipgloves: {
    uid: 'vipgloves',
    level: 31,
    type: 'gl',
    xp: 60500,
    craftXp: 908,
    value: 655000,
    tradeMinMaxValue:
      '165000,200000,330000,490000,820000;8200000,10000000,16500000,24500000,41000000',
    favor: 2451,
    time: 36000,
    atk: 48,
    def: 190,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 306,
    resource2: 'fabric',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'ghostflag',
    c1Qty: 4,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-17',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 835,
    surcharge: 1855,
    suggest: 370,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-01-12',
    shardPrice: 250,
    capriceDelay: 0,
  },
  goldgloves: {
    uid: 'goldgloves',
    level: 31,
    type: 'gl',
    xp: 60500,
    craftXp: 908,
    value: 1750000,
    tradeMinMaxValue:
      '440000,550000,880000,1300000,2200000;22000000,27500000,44000000,65500000,109500000',
    favor: 2451,
    time: 36000,
    atk: 0,
    def: 190,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 306,
    resource2: 'fabric',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'tabletjewel',
    c1Qty: 5,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1005,
    surcharge: 2230,
    suggest: 445,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-03-20',
    shardPrice: 0,
    capriceDelay: 0,
  },
  jackalopegloves: {
    uid: 'jackalopegloves',
    level: 33,
    type: 'gl',
    xp: 105000,
    craftXp: 1575,
    value: 1300000,
    tradeMinMaxValue:
      '330000,410000,650000,980000,1650000;16500000,20500000,32500000,49000000,81500000',
    favor: 2889,
    time: 51600,
    atk: 0,
    def: 240,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12gloves',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1005,
    surcharge: 2235,
    suggest: 445,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  birdgloves: {
    uid: 'birdgloves',
    level: 33,
    type: 'gl',
    xp: 105000,
    craftXp: 1575,
    value: 1600000,
    tradeMinMaxValue:
      '400000,500000,800000,1200000,2000000;20000000,25000000,40000000,60000000,100000000',
    favor: 3218,
    time: 51600,
    atk: 0,
    def: 240,
    hp: 0,
    eva: '0.05',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: 'eagle',
    tag: null,
    discount: 1110,
    surcharge: 2470,
    suggest: 495,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-05-18',
    shardPrice: 300,
    capriceDelay: 240,
  },
  halloweengloves: {
    uid: 'halloweengloves',
    level: 34,
    type: 'gl',
    xp: 110000,
    craftXp: 1650,
    value: 1700000,
    tradeMinMaxValue:
      '430000,530000,850000,1300000,2150000;21500000,26500000,42500000,64000000,106500000',
    favor: 3564,
    time: 61800,
    atk: 0,
    def: 245,
    hp: 15,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 354,
    resource2: 'fabric',
    r2Qty: 103,
    resource3: 'gems',
    r3Qty: 29,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'ghostflag',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-71',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-21',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1145,
    surcharge: 2545,
    suggest: 510,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-10-20',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12gloves: {
    uid: 't12gloves',
    level: 36,
    type: 'gl',
    xp: 210000,
    craftXp: 3150,
    value: 2400000,
    tradeMinMaxValue:
      '600000,750000,1200000,1800000,3000000;30000000,37500000,60000000,90000000,150000000',
    favor: 4293,
    time: 88200,
    atk: 0,
    def: 300,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'leather',
    r1Qty: 387,
    resource2: 'fabric',
    r2Qty: 113,
    resource3: 'mana',
    r3Qty: 41,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-23',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13gloves',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1350,
    surcharge: 3000,
    suggest: 600,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  volcanogloves: {
    uid: 'volcanogloves',
    level: 37,
    type: 'gl',
    xp: 220000,
    craftXp: 3300,
    value: 2500000,
    tradeMinMaxValue:
      '650000,810000,1300000,1950000,3250000;32500000,40500000,64500000,97000000,161500000',
    favor: 5488,
    time: 105600,
    atk: 0,
    def: 310,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 404,
    resource2: 'fabric',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 46,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-81',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'hurricane',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 1375,
    surcharge: 3060,
    suggest: 610,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'volcano',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  cincogloves: {
    uid: 'cincogloves',
    level: 36,
    type: 'gl',
    xp: 210000,
    craftXp: 3150,
    value: 2600000,
    tradeMinMaxValue:
      '650000,810000,1300000,1950000,3250000;32500000,40500000,65000000,97500000,162500000',
    favor: 4658,
    time: 88200,
    atk: 75,
    def: 300,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 380,
    resource2: 'steel',
    r2Qty: 100,
    resource3: 'gems',
    r3Qty: 40,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-57',
    upgrade4: 'resource2-25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource1-57',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1405,
    surcharge: 3120,
    suggest: 625,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-05-04',
    shardPrice: 350,
    capriceDelay: 0,
  },
  musketeergloves: {
    uid: 'musketeergloves',
    level: 37,
    type: 'gl',
    xp: 220000,
    craftXp: 3300,
    value: 2800000,
    tradeMinMaxValue:
      '700000,880000,1400000,2100000,3500000;35000000,44000000,70000000,105000000,175000000',
    favor: 5320,
    time: 105600,
    atk: 0,
    def: 310,
    hp: 0,
    eva: '0.07',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 404,
    resource2: 'fabric',
    r2Qty: 123,
    resource3: 'gems',
    r3Qty: 40,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-81',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'resource3-10',
    supgrade3: 'resource3-10',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1455,
    surcharge: 3235,
    suggest: 645,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-10-05',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13gloves: {
    uid: 't13gloves',
    level: 39,
    type: 'gl',
    xp: 250000,
    craftXp: 3750,
    value: 5450000,
    tradeMinMaxValue:
      '1350000,1700000,2750000,4100000,6800000;68000000,85000000,136500000,204500000,340500000',
    favor: 6656,
    time: 150600,
    atk: 0,
    def: 380,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'leather',
    r1Qty: 438,
    resource2: 'gems',
    r2Qty: 44,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voideye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-88',
    upgrade3: 'resource2-9',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: 'chimera',
    tag: null,
    discount: 2005,
    surcharge: 4450,
    suggest: 890,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  shinguards: {
    uid: 'shinguards',
    level: 1,
    type: 'bh',
    xp: 135,
    craftXp: 2,
    value: 55,
    tradeMinMaxValue: '15,20,35,50,80;800,1000,1700,2500,4100',
    favor: 1,
    time: 15,
    atk: 0,
    def: 8,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:longboots',
    upgrade2: 'value*1.5',
    upgrade3: 'resource1-1',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-3',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 15,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  longboots: {
    uid: 'longboots',
    level: 3,
    type: 'bh',
    xp: 505,
    craftXp: 8,
    value: 230,
    tradeMinMaxValue: '70,90,140,210,350;3500,4300,6900,10500,17500',
    favor: 10,
    time: 70,
    atk: 0,
    def: 17,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 9,
    resource2: 'leather',
    r2Qty: 5,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'bp:grieveboots',
    upgrade2: 'value*1.5',
    upgrade3: 'resource1-2',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-5',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 25,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  grieveboots: {
    uid: 'grieveboots',
    level: 5,
    type: 'bh',
    xp: 1350,
    craftXp: 20,
    value: 710,
    tradeMinMaxValue: '210,270,430,640,1050;10500,13500,21500,32000,53500',
    favor: 16,
    time: 150,
    atk: 0,
    def: 28,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 14,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:heavyboots',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 45,
    suggest: 10,
    speedup: 70,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  heroboots: {
    uid: 'heroboots',
    level: 6,
    type: 'bh',
    xp: 1900,
    craftXp: 29,
    value: 1200,
    tradeMinMaxValue: '300,380,600,900,1500;15000,19000,30000,45000,75000',
    favor: 21,
    time: 210,
    atk: 0,
    def: 34,
    hp: 2,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 16,
    resource2: 'leather',
    r2Qty: 8,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.8',
    upgrade3: 'resource1-3',
    upgrade4: 'time*0.8',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 60,
    suggest: 10,
    speedup: 90,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-09-10',
    shardPrice: 75,
    capriceDelay: 240,
  },
  heavyboots: {
    uid: 'heavyboots',
    level: 8,
    type: 'bh',
    xp: 3500,
    craftXp: 53,
    value: 2150,
    tradeMinMaxValue: '540,670,1100,1600,2700;27000,33500,54000,80500,135000',
    favor: 27,
    time: 390,
    atk: 0,
    def: 47,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 5,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 21,
    resource2: 'leather',
    r2Qty: 11,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:knightboots',
    upgrade4: 'resource1-4',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 35,
    surcharge: 80,
    suggest: 15,
    speedup: 135,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  circusboots: {
    uid: 'circusboots',
    level: 9,
    type: 'bh',
    xp: 4500,
    craftXp: 68,
    value: 3350,
    tradeMinMaxValue: '840,1050,1700,2500,4200;42000,52500,84000,125000,210000',
    favor: 39,
    time: 490,
    atk: 0,
    def: 54,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 9,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 26,
    resource2: 'leather',
    r2Qty: 13,
    resource3: null,
    r3Qty: 0,
    component1: 'shinguards',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'leatherboots',
    c2Qty: 1,
    c2Tags: 'common',
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-5',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'time*0.75',
    supgrade3: 'multi+0.1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 45,
    surcharge: 100,
    suggest: 20,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-06-15',
    shardPrice: 100,
    capriceDelay: 0,
  },
  knightboots: {
    uid: 'knightboots',
    level: 13,
    type: 'bh',
    xp: 9850,
    craftXp: 148,
    value: 8150,
    tradeMinMaxValue:
      '2000,2500,4100,6100,10000;100000,125000,205000,305000,510000',
    favor: 81,
    time: 1350,
    atk: 0,
    def: 84,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 49,
    resource2: 'steel',
    r2Qty: 10,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-10',
    upgrade3: 'bp:paladinboots',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 150,
    suggest: 30,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  swampboots: {
    uid: 'swampboots',
    level: 14,
    type: 'bh',
    xp: 11500,
    craftXp: 173,
    value: 12000,
    tradeMinMaxValue:
      '5100,6400,10000,15500,26000;255000,320000,510000,765000,1300000',
    favor: 119,
    time: 1650,
    atk: 0,
    def: 92,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 60,
    resource2: 'steel',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'scrolloffire',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'icypearl',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'flaming',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 80,
    surcharge: 180,
    suggest: 35,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'swamp',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lunargreaves: {
    uid: 'lunargreaves',
    level: 15,
    type: 'bh',
    xp: 13500,
    craftXp: 203,
    value: 14500,
    tradeMinMaxValue:
      '3600,4500,7300,11000,18000;180000,225000,365000,545000,905000',
    favor: 150,
    time: 2160,
    atk: 25,
    def: 100,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 5,
    combo: 8,
    worker1: 'blacksmith',
    worker2: null,
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 71,
    resource2: 'steel',
    r2Qty: 16,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 4,
    c1Tags: null,
    component2: 'redpinecone',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-14',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 90,
    surcharge: 200,
    suggest: 40,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-01-25',
    shardPrice: 120,
    capriceDelay: 0,
  },
  paladinboots: {
    uid: 'paladinboots',
    level: 17,
    type: 'bh',
    xp: 17500,
    craftXp: 263,
    value: 23500,
    tradeMinMaxValue:
      '5900,7300,12000,17500,29000;295000,365000,590000,880000,1450000',
    favor: 349,
    time: 3000,
    atk: 0,
    def: 115,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'blacksmith',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'iron',
    r1Qty: 99,
    resource2: 'herbs',
    r2Qty: 25,
    resource3: 'steel',
    r3Qty: 25,
    component1: 'grieveboots',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'mooncrystal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:haidate',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 250,
    suggest: 50,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  dogboots: {
    uid: 'dogboots',
    level: 18,
    type: 'bh',
    xp: 20000,
    craftXp: 300,
    value: 29500,
    tradeMinMaxValue:
      '9200,11500,18500,28000,46000;460000,575000,920000,1400000,2300000',
    favor: 296,
    time: 4200,
    atk: 31,
    def: 125,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'iron',
    r1Qty: 113,
    resource2: 'steel',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 6,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'wolf',
    tag: null,
    discount: 125,
    surcharge: 280,
    suggest: 55,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-08-24',
    shardPrice: 140,
    capriceDelay: 0,
  },
  firecrabboots: {
    uid: 'firecrabboots',
    level: 18,
    type: 'bh',
    xp: 20000,
    craftXp: 300,
    value: 36500,
    tradeMinMaxValue:
      '11500,14500,23000,34000,57000;570000,715000,1150000,1700000,2850000',
    favor: 278,
    time: 3000,
    atk: 0,
    def: 125,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'iron',
    r1Qty: 107,
    resource2: 'steel',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: 'ember',
    c2Qty: 1,
    c2Tags: 'common',
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-6',
    upgrade3: 'value*1.25',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'time*0.8',
    supgrade3: 'multi+0.1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 140,
    surcharge: 310,
    suggest: 60,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-07-25',
    shardPrice: 140,
    capriceDelay: 0,
  },
  haidate: {
    uid: 'haidate',
    level: 20,
    type: 'bh',
    xp: 25000,
    craftXp: 375,
    value: 69000,
    tradeMinMaxValue:
      '17500,22000,35000,52000,86000;865000,1100000,1750000,2600000,4300000',
    favor: 622,
    time: 6000,
    atk: 0,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'iron',
    r1Qty: 141,
    resource2: 'leather',
    r2Qty: 70,
    resource3: 'steel',
    r3Qty: 36,
    component1: 'starmetal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:generalgreaves',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 190,
    surcharge: 420,
    suggest: 85,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  bfheavyboots: {
    uid: 'bfheavyboots',
    level: 22,
    type: 'bh',
    xp: 30500,
    craftXp: 458,
    value: 85000,
    tradeMinMaxValue:
      '27000,33000,53000,80000,135000;1350000,1650000,2650000,4000000,6650000',
    favor: 591,
    time: 9000,
    atk: 0,
    def: 160,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'iron',
    r1Qty: 170,
    resource2: 'gems',
    r2Qty: 5,
    resource3: null,
    r3Qty: 0,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'value*1.25',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 210,
    surcharge: 465,
    suggest: 95,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-26',
    shardPrice: 160,
    capriceDelay: 0,
  },
  generalgreaves: {
    uid: 'generalgreaves',
    level: 24,
    type: 'bh',
    xp: 37000,
    craftXp: 555,
    value: 195000,
    tradeMinMaxValue:
      '49000,61000,98000,145000,240000;2450000,3050000,4900000,7300000,12000000',
    favor: 771,
    time: 13800,
    atk: 0,
    def: 180,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'iron',
    r1Qty: 199,
    resource2: 'steel',
    r2Qty: 51,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'ancientmarble',
    c1Qty: 5,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:berserkerboots',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 310,
    surcharge: 690,
    suggest: 140,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  berserkerboots: {
    uid: 'berserkerboots',
    level: 27,
    type: 'bh',
    xp: 47500,
    craftXp: 713,
    value: 310000,
    tradeMinMaxValue:
      '78000,97000,155000,230000,390000;3900000,4850000,7750000,11500000,19500000',
    favor: 1224,
    time: 16800,
    atk: 0,
    def: 205,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'iron',
    r1Qty: 244,
    resource2: 'steel',
    r2Qty: 67,
    resource3: 'gems',
    r3Qty: 13,
    component1: 'knightboots',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'ghostflag',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'cquality1-1',
    upgrade3: 'bp:juggernautboots',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 390,
    surcharge: 865,
    suggest: 175,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldboots: {
    uid: 'goldboots',
    level: 28,
    type: 'bh',
    xp: 51500,
    craftXp: 773,
    value: 605000,
    tradeMinMaxValue:
      '150000,190000,300000,450000,760000;7550000,9450000,15000000,22500000,38000000',
    favor: 1589,
    time: 21600,
    atk: 0,
    def: 215,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'iron',
    r1Qty: 259,
    resource2: 'steel',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'tabletfragment',
    c1Qty: 5,
    c1Tags: null,
    component2: 'ghostflag',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'luxuriousspirit',
    tag: null,
    discount: 405,
    surcharge: 895,
    suggest: 180,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-08-08',
    shardPrice: 0,
    capriceDelay: 0,
  },
  fallboots: {
    uid: 'fallboots',
    level: 29,
    type: 'bh',
    xp: 55500,
    craftXp: 833,
    value: 410000,
    tradeMinMaxValue:
      '105000,130000,210000,310000,510000;5150000,6400000,10500000,15500000,25500000',
    favor: 1847,
    time: 25800,
    atk: 0,
    def: 225,
    hp: 14,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'iron',
    r1Qty: 275,
    resource2: 'steel',
    r2Qty: 77,
    resource3: 'gems',
    r3Qty: 19,
    component1: 'ghostflag',
    c1Qty: 3,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 445,
    surcharge: 990,
    suggest: 200,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-09-09',
    shardPrice: 200,
    capriceDelay: 420,
  },
  juggernautboots: {
    uid: 'juggernautboots',
    level: 30,
    type: 'bh',
    xp: 60000,
    craftXp: 900,
    value: 625000,
    tradeMinMaxValue:
      '155000,195000,310000,470000,780000;7800000,9750000,15500000,23500000,39000000',
    favor: 1909,
    time: 31200,
    atk: 0,
    def: 235,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 290,
    resource2: 'steel',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'deepcoral',
    c1Qty: 5,
    c1Tags: null,
    component2: 'timecrystal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:celestialboots',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 815,
    surcharge: 1815,
    suggest: 365,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  bunnyboots: {
    uid: 'bunnyboots',
    level: 31,
    type: 'bh',
    xp: 64500,
    craftXp: 968,
    value: 670000,
    tradeMinMaxValue:
      '170000,210000,340000,500000,840000;8400000,10500000,17000000,25000000,42000000',
    favor: 2451,
    time: 37200,
    atk: 61,
    def: 245,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 306,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'rabbitspirit',
    tag: null,
    discount: 845,
    surcharge: 1880,
    suggest: 375,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-03-24',
    shardPrice: 250,
    capriceDelay: 0,
  },
  cincoboots: {
    uid: 'cincoboots',
    level: 31,
    type: 'bh',
    xp: 64500,
    craftXp: 968,
    value: 700000,
    tradeMinMaxValue:
      '175000,220000,350000,530000,880000;8750000,11000000,17500000,26500000,44000000',
    favor: 2451,
    time: 37200,
    atk: 0,
    def: 245,
    hp: 15,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 10,
    combo: 6,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 306,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'sirenshard',
    c1Qty: 5,
    c1Tags: null,
    component2: 'icypearl',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'resource1-61',
    upgrade2: 'component1-1',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 865,
    surcharge: 1920,
    suggest: 385,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-05-03',
    shardPrice: 250,
    capriceDelay: 0,
  },
  celestialboots: {
    uid: 'celestialboots',
    level: 33,
    type: 'bh',
    xp: 110000,
    craftXp: 1650,
    value: 1250000,
    tradeMinMaxValue:
      '310000,390000,630000,940000,1550000;15500000,19500000,31500000,47000000,78000000',
    favor: 2889,
    time: 53400,
    atk: 0,
    def: 305,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 338,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'sirenshard',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12boots',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 990,
    surcharge: 2195,
    suggest: 440,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  freedomboots: {
    uid: 'freedomboots',
    level: 33,
    type: 'bh',
    xp: 110000,
    craftXp: 1650,
    value: 1650000,
    tradeMinMaxValue:
      '410000,520000,830000,1250000,2050000;20500000,26000000,41500000,62000000,103000000',
    favor: 3218,
    time: 53400,
    atk: 0,
    def: 305,
    hp: 19,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 338,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-12',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1130,
    surcharge: 2510,
    suggest: 500,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2021-06-29',
    shardPrice: 300,
    capriceDelay: 0,
  },
  christmasboots: {
    uid: 'christmasboots',
    level: 34,
    type: 'bh',
    xp: 120000,
    craftXp: 1800,
    value: 1800000,
    tradeMinMaxValue:
      '450000,560000,900000,1350000,2250000;22500000,28000000,45000000,67500000,112500000',
    favor: 3671,
    time: 63600,
    atk: 80,
    def: 320,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 354,
    resource2: 'steel',
    r2Qty: 103,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'resource1-71',
    upgrade2: 'resource2-21',
    upgrade3: 'time*0.75',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-10',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'santa',
    tag: null,
    discount: 1175,
    surcharge: 2615,
    suggest: 525,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-12-15',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12boots: {
    uid: 't12boots',
    level: 36,
    type: 'bh',
    xp: 220000,
    craftXp: 3300,
    value: 2550000,
    tradeMinMaxValue:
      '640000,800000,1300000,1900000,3200000;32000000,40000000,64000000,95500000,159500000',
    favor: 4293,
    time: 91200,
    atk: 0,
    def: 385,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 387,
    resource2: 'steel',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13boots',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-28',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: 'phoenix',
    tag: null,
    discount: 1390,
    surcharge: 3090,
    suggest: 620,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  volcanoboots: {
    uid: 'volcanoboots',
    level: 37,
    type: 'bh',
    xp: 235000,
    craftXp: 3525,
    value: 2650000,
    tradeMinMaxValue:
      '680000,850000,1350000,2050000,3400000;34000000,42500000,68500000,102500000,170500000',
    favor: 5488,
    time: 108600,
    atk: 0,
    def: 400,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 404,
    resource2: 'steel',
    r2Qty: 123,
    resource3: 'gems',
    r3Qty: 46,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'resource1-81',
    upgrade2: 'value*1.25',
    upgrade3: 'time*0.75',
    upgrade4: 'resource2-25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'resource3-7',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'divine',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 1420,
    surcharge: 3150,
    suggest: 630,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'volcano',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  fruitboots: {
    uid: 'fruitboots',
    level: 37,
    type: 'bh',
    xp: 235000,
    craftXp: 3525,
    value: 2800000,
    tradeMinMaxValue:
      '700000,880000,1400000,2100000,3500000;35000000,44000000,70000000,105000000,175000000',
    favor: 5259,
    time: 108600,
    atk: 0,
    def: 400,
    hp: 25,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'herbs',
    r1Qty: 400,
    resource2: 'oils',
    r2Qty: 120,
    resource3: 'mana',
    r3Qty: 40,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'resource1-80',
    upgrade2: 'resource2-24',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.75',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-18',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1455,
    surcharge: 3235,
    suggest: 645,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-08-23',
    shardPrice: 350,
    capriceDelay: 0,
  },
  goldboots2: {
    uid: 'goldboots2',
    level: 37,
    type: 'bh',
    xp: 235000,
    craftXp: 3525,
    value: 5250000,
    tradeMinMaxValue:
      '1300000,1650000,2650000,3950000,6550000;65500000,82000000,131500000,197000000,328000000',
    favor: 5488,
    time: 108600,
    atk: 0,
    def: 400,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 404,
    resource2: 'steel',
    r2Qty: 123,
    resource3: 'gems',
    r3Qty: 46,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-25',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-14',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1475,
    surcharge: 3280,
    suggest: 655,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-03-17',
    shardPrice: 0,
    capriceDelay: 0,
  },
  dinoboots: {
    uid: 'dinoboots',
    level: 37,
    type: 'bh',
    xp: 235000,
    craftXp: 3525,
    value: 2900000,
    tradeMinMaxValue:
      '730000,910000,1450000,2200000,3650000;36500000,45500000,72500000,109000000,181500000',
    favor: 5380,
    time: 108600,
    atk: 100,
    def: 400,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 397,
    resource2: 'fabric',
    r2Qty: 105,
    resource3: 'gems',
    r3Qty: 46,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-79',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-21',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'dinosaur',
    tag: null,
    discount: 1480,
    surcharge: 3290,
    suggest: 660,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-03-22',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13boots: {
    uid: 't13boots',
    level: 39,
    type: 'bh',
    xp: 265000,
    craftXp: 3975,
    value: 5450000,
    tradeMinMaxValue:
      '1350000,1700000,2750000,4100000,6800000;68000000,85000000,136500000,204500000,340500000',
    favor: 6656,
    time: 154800,
    atk: 0,
    def: 495,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 438,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcloth',
    c1Qty: 3,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.75',
    upgrade2: 'resource2-8',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-88',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 2005,
    surcharge: 4450,
    suggest: 890,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  shoprboots: {
    uid: 'shoprboots',
    level: 39,
    type: 'bh',
    xp: 265000,
    craftXp: 3975,
    value: 5650000,
    tradeMinMaxValue:
      '1400000,1750000,2850000,4250000,7050000;70500000,88500000,141500000,212000000,353000000',
    favor: 7146,
    time: 154800,
    atk: 0,
    def: 495,
    hp: 31,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'blacksmith',
    worker2: 'master',
    worker3: 'tailor',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 425,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voideye',
    c1Qty: 2,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 10,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-85',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-4',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-10',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 2040,
    surcharge: 4530,
    suggest: 905,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-06-14',
    shardPrice: 375,
    capriceDelay: 0,
  },
  leatherboots: {
    uid: 'leatherboots',
    level: 1,
    type: 'bl',
    xp: 135,
    craftXp: 2,
    value: 60,
    tradeMinMaxValue: '20,20,35,50,90;900,1100,1800,2700,4500',
    favor: 1,
    time: 15,
    atk: 0,
    def: 7,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:shinguards',
    upgrade2: 'bp:sandals',
    upgrade3: 'value*1.5',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-4',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 5,
    surcharge: 15,
    suggest: 5,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  forestshoes: {
    uid: 'forestshoes',
    level: 3,
    type: 'bl',
    xp: 480,
    craftXp: 7,
    value: 250,
    tradeMinMaxValue: '150,190,300,450,750;7500,9400,15000,22500,37500',
    favor: 11,
    time: 60,
    atk: 0,
    def: 15,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 1,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'resource1-2',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-5',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'ember',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 15,
    surcharge: 30,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'forest',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  sandals: {
    uid: 'sandals',
    level: 3,
    type: 'bl',
    xp: 480,
    craftXp: 7,
    value: 180,
    tradeMinMaxValue: '50,70,110,160,270;2700,3400,5400,8100,13500',
    favor: 10,
    time: 60,
    atk: 0,
    def: 15,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 9,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:softshoes',
    upgrade3: 'resource1-2',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-5',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 25,
    suggest: 5,
    speedup: 40,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  softshoes: {
    uid: 'softshoes',
    level: 5,
    type: 'bl',
    xp: 1250,
    craftXp: 19,
    value: 660,
    tradeMinMaxValue: '200,250,400,590,990;9900,12500,20000,29500,49500',
    favor: 16,
    time: 150,
    atk: 0,
    def: 25,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 14,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:thiefshoes',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 45,
    suggest: 10,
    speedup: 70,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  romansandals: {
    uid: 'romansandals',
    level: 6,
    type: 'bl',
    xp: 1800,
    craftXp: 27,
    value: 1150,
    tradeMinMaxValue: '350,430,690,1050,1750;17500,21500,34500,52000,86500',
    favor: 21,
    time: 210,
    atk: 8,
    def: 30,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 16,
    resource2: 'wood',
    r2Qty: 8,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.8',
    upgrade3: 'resource1-3',
    upgrade4: 'time*0.8',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 60,
    suggest: 10,
    speedup: 90,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-07-30',
    shardPrice: 75,
    capriceDelay: 0,
  },
  thiefshoes: {
    uid: 'thiefshoes',
    level: 9,
    type: 'bl',
    xp: 4200,
    craftXp: 63,
    value: 2800,
    tradeMinMaxValue: '700,880,1400,2100,3500;35000,44000,70000,105000,175000',
    favor: 35,
    time: 450,
    atk: 0,
    def: 48,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 26,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'dagger',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'webbedwings',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:elvenshoes',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 90,
    suggest: 20,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  flowersandals: {
    uid: 'flowersandals',
    level: 10,
    type: 'bl',
    xp: 5300,
    craftXp: 80,
    value: 5000,
    tradeMinMaxValue:
      '1250,1550,2500,3800,6300;62500,78000,125000,190000,315000',
    favor: 47,
    time: 630,
    atk: 0,
    def: 54,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 9,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 7,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 30,
    resource2: 'herbs',
    r2Qty: 7,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-6',
    upgrade4: 'resource2-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource1-6',
    supgrade2: 'time*0.75',
    supgrade3: 'multi+0.1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 55,
    surcharge: 120,
    suggest: 25,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-03-24',
    shardPrice: 100,
    capriceDelay: 240,
  },
  elvenshoes: {
    uid: 'elvenshoes',
    level: 12,
    type: 'bl',
    xp: 7800,
    craftXp: 117,
    value: 6950,
    tradeMinMaxValue:
      '1750,2200,3500,5200,8700;87000,110000,175000,260000,435000',
    favor: 93,
    time: 1020,
    atk: 0,
    def: 68,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 41,
    resource2: 'herbs',
    r2Qty: 10,
    resource3: 'fabric',
    r3Qty: 8,
    component1: 'livingroots',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-8',
    upgrade3: 'bp:savageshoes',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 65,
    surcharge: 140,
    suggest: 30,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  moonshoes: {
    uid: 'moonshoes',
    level: 13,
    type: 'bl',
    xp: 9250,
    craftXp: 139,
    value: 13000,
    tradeMinMaxValue:
      '3700,4700,7500,11000,18500;185000,235000,375000,560000,935000',
    favor: 129,
    time: 1290,
    atk: 0,
    def: 74,
    hp: 5,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'tailor',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 49,
    resource2: 'iron',
    r2Qty: 12,
    resource3: 'fabric',
    r3Qty: 10,
    component1: 'mooncrystal',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.2',
    upgrade3: 'resource1-10',
    upgrade4: 'value*1.2',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource2-12',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 85,
    surcharge: 190,
    suggest: 40,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-09-21',
    shardPrice: 120,
    capriceDelay: 0,
  },
  savageshoes: {
    uid: 'savageshoes',
    level: 16,
    type: 'bl',
    xp: 14500,
    craftXp: 218,
    value: 20500,
    tradeMinMaxValue:
      '5100,6400,10500,15500,26000;255000,320000,515000,770000,1300000',
    favor: 276,
    time: 2400,
    atk: 0,
    def: 96,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 85,
    resource2: 'herbs',
    r2Qty: 21,
    resource3: 'fabric',
    r3Qty: 21,
    component1: 'sharpfang',
    c1Qty: 3,
    c1Tags: null,
    component2: 'webbedwings',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-17',
    upgrade3: 'bp:ninjatabi',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'lizard',
    tag: null,
    discount: 105,
    surcharge: 235,
    suggest: 45,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  blossomshoes: {
    uid: 'blossomshoes',
    level: 17,
    type: 'bl',
    xp: 16500,
    craftXp: 248,
    value: 24000,
    tradeMinMaxValue:
      '6000,7500,12000,18000,30000;300000,375000,600000,900000,1500000',
    favor: 305,
    time: 3000,
    atk: 0,
    def: 105,
    hp: 6,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 8,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 1,
    w3BuildingReq: 4,
    resource1: 'leather',
    r1Qty: 99,
    resource2: 'herbs',
    r2Qty: 25,
    resource3: 'oils',
    r3Qty: 12,
    component1: 'sandals',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'icypearl',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource3-6',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 255,
    suggest: 50,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-03-22',
    shardPrice: 140,
    capriceDelay: 0,
  },
  arabianshoes: {
    uid: 'arabianshoes',
    level: 18,
    type: 'bl',
    xp: 18500,
    craftXp: 278,
    value: 36500,
    tradeMinMaxValue:
      '9100,11500,18500,27000,46000;455000,570000,915000,1350000,2300000',
    favor: 465,
    time: 4200,
    atk: 28,
    def: 110,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 1,
    w3BuildingReq: 5,
    resource1: 'leather',
    r1Qty: 113,
    resource2: 'herbs',
    r2Qty: 28,
    resource3: 'fabric',
    r3Qty: 29,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 140,
    surcharge: 310,
    suggest: 60,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-08-11',
    shardPrice: 140,
    capriceDelay: 240,
  },
  inuitshoes: {
    uid: 'inuitshoes',
    level: 18,
    type: 'bl',
    xp: 18500,
    craftXp: 278,
    value: 37500,
    tradeMinMaxValue:
      '9400,11500,19000,28000,47000;470000,585000,940000,1400000,2350000',
    favor: 449,
    time: 4200,
    atk: 0,
    def: 110,
    hp: 7,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 1,
    w3BuildingReq: 5,
    resource1: 'leather',
    r1Qty: 108,
    resource2: 'herbs',
    r2Qty: 28,
    resource3: 'fabric',
    r3Qty: 29,
    component1: 'sharpfang',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource2-6',
    upgrade3: 'resource1-22',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 140,
    surcharge: 315,
    suggest: 65,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-30',
    shardPrice: 140,
    capriceDelay: 0,
  },
  ninjatabi: {
    uid: 'ninjatabi',
    level: 19,
    type: 'bl',
    xp: 21000,
    craftXp: 315,
    value: 57000,
    tradeMinMaxValue:
      '14500,18000,29000,43000,71000;715000,890000,1450000,2150000,3550000',
    favor: 319,
    time: 4800,
    atk: 0,
    def: 120,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 16,
    w2BuildingReq: 12,
    w3BuildingReq: 6,
    resource1: 'leather',
    r1Qty: 127,
    resource2: 'fabric',
    r2Qty: 33,
    resource3: null,
    r3Qty: 0,
    component1: 'sandals',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'evileye',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-25',
    upgrade3: 'bp:windrunnerboots',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 175,
    surcharge: 385,
    suggest: 75,
    speedup: 565,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  batshoes: {
    uid: 'batshoes',
    level: 20,
    type: 'bl',
    xp: 23500,
    craftXp: 353,
    value: 78000,
    tradeMinMaxValue:
      '19500,24000,39000,59000,98000;975000,1200000,1950000,2950000,4900000',
    favor: 425,
    time: 6000,
    atk: 0,
    def: 125,
    hp: 0,
    eva: '0.05',
    crit: '0',
    excl: 'special',
    tier: 7,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'leather',
    r1Qty: 141,
    resource2: 'fabric',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'evileye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'component1-1',
    upgrade3: 'resource1-35',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 200,
    surcharge: 445,
    suggest: 90,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-10-10',
    shardPrice: 160,
    capriceDelay: 0,
  },
  windrunnerboots: {
    uid: 'windrunnerboots',
    level: 24,
    type: 'bl',
    xp: 34500,
    craftXp: 518,
    value: 175000,
    tradeMinMaxValue:
      '44000,55000,88000,130000,220000;2200000,2750000,4400000,6550000,11000000',
    favor: 771,
    time: 13200,
    atk: 0,
    def: 160,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'leather',
    r1Qty: 199,
    resource2: 'fabric',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 7,
    component1: 'overgrownvine',
    c1Qty: 5,
    c1Tags: null,
    component2: 'darkscale',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:dragonshoes',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-4',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 295,
    surcharge: 655,
    suggest: 130,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ruinsshoes: {
    uid: 'ruinsshoes',
    level: 25,
    type: 'bl',
    xp: 38000,
    craftXp: 570,
    value: 220000,
    tradeMinMaxValue:
      '57000,71000,115000,170000,280000;2850000,3550000,5700000,8550000,14000000',
    favor: 1001,
    time: 16200,
    atk: 0,
    def: 165,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 8,
    combo: 7,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'leather',
    r1Qty: 214,
    resource2: 'fabric',
    r2Qty: 57,
    resource3: 'mana',
    r3Qty: 8,
    component1: 'timecrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'gale',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 330,
    surcharge: 735,
    suggest: 145,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'ruins',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  dragonshoes: {
    uid: 'dragonshoes',
    level: 27,
    type: 'bl',
    xp: 45000,
    craftXp: 675,
    value: 320000,
    tradeMinMaxValue:
      '80000,100000,160000,240000,400000;4000000,5000000,8000000,12000000,20000000',
    favor: 1224,
    time: 17400,
    atk: 0,
    def: 185,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'leather',
    r1Qty: 244,
    resource2: 'fabric',
    r2Qty: 67,
    resource3: 'mana',
    r3Qty: 13,
    component1: 'sewingthread',
    c1Qty: 5,
    c1Tags: null,
    component2: 'icypearl',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:captainshoes',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 395,
    surcharge: 880,
    suggest: 175,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  castleshoe: {
    uid: 'castleshoe',
    level: 28,
    type: 'bl',
    xp: 48500,
    craftXp: 728,
    value: 345000,
    tradeMinMaxValue:
      '93000,115000,185000,280000,470000;4650000,5850000,9350000,14000000,23500000',
    favor: 1589,
    time: 21000,
    atk: 0,
    def: 190,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'leather',
    r1Qty: 259,
    resource2: 'fabric',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 16,
    component1: 'ghostflag',
    c1Qty: 3,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'nightmare',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 410,
    surcharge: 910,
    suggest: 180,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'castle',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  cincoshoes2020: {
    uid: 'cincoshoes2020',
    level: 28,
    type: 'bl',
    xp: 48500,
    craftXp: 728,
    value: 425000,
    tradeMinMaxValue:
      '105000,135000,210000,320000,530000;5300000,6650000,10500000,16000000,26500000',
    favor: 1589,
    time: 21000,
    atk: 48,
    def: 190,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'leather',
    r1Qty: 259,
    resource2: 'fabric',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 16,
    component1: 'ghostflag',
    c1Qty: 3,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 450,
    surcharge: 1005,
    suggest: 200,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-05-05',
    shardPrice: 200,
    capriceDelay: 240,
  },
  captainshoes: {
    uid: 'captainshoes',
    level: 30,
    type: 'bl',
    xp: 56500,
    craftXp: 848,
    value: 605000,
    tradeMinMaxValue:
      '150000,190000,300000,450000,760000;7550000,9450000,15000000,22500000,38000000',
    favor: 1909,
    time: 30000,
    atk: 0,
    def: 210,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 290,
    resource2: 'fabric',
    r2Qty: 82,
    resource3: 'mana',
    r3Qty: 22,
    component1: 'deepcoral',
    c1Qty: 4,
    c1Tags: null,
    component2: 'ghostflag',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:jackalopeshoes',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 805,
    surcharge: 1790,
    suggest: 360,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lunarboots: {
    uid: 'lunarboots',
    level: 31,
    type: 'bl',
    xp: 60500,
    craftXp: 908,
    value: 650000,
    tradeMinMaxValue:
      '165000,200000,330000,490000,810000;8150000,10000000,16500000,24500000,40500000',
    favor: 2451,
    time: 36000,
    atk: 0,
    def: 220,
    hp: 14,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 306,
    resource2: 'fabric',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'sirenshard',
    c1Qty: 3,
    c1Tags: null,
    component2: 'deepcoral',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component1-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 835,
    surcharge: 1850,
    suggest: 370,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-02-10',
    shardPrice: 250,
    capriceDelay: 240,
  },
  jackalopeshoes: {
    uid: 'jackalopeshoes',
    level: 33,
    type: 'bl',
    xp: 105000,
    craftXp: 1575,
    value: 1350000,
    tradeMinMaxValue:
      '340000,420000,680000,1000000,1700000;17000000,21000000,34000000,50500000,84500000',
    favor: 2889,
    time: 51600,
    atk: 0,
    def: 270,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12shoes',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1025,
    surcharge: 2280,
    suggest: 455,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  birdshoes: {
    uid: 'birdshoes',
    level: 33,
    type: 'bl',
    xp: 105000,
    craftXp: 1575,
    value: 1600000,
    tradeMinMaxValue:
      '400000,500000,800000,1200000,2000000;20000000,25000000,40000000,60000000,100000000',
    favor: 3218,
    time: 49800,
    atk: 68,
    def: 270,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'wingedbow',
    c2Qty: 2,
    c2Tags: 'common',
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'resource2-20',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: 'eagle',
    tag: null,
    discount: 1110,
    surcharge: 2470,
    suggest: 495,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-05-19',
    shardPrice: 300,
    capriceDelay: 0,
  },
  goldshoes: {
    uid: 'goldshoes',
    level: 33,
    type: 'bl',
    xp: 105000,
    craftXp: 1575,
    value: 2750000,
    tradeMinMaxValue:
      '690000,860000,1400000,2050000,3450000;34500000,43000000,69000000,103000000,172000000',
    favor: 3218,
    time: 51600,
    atk: 0,
    def: 270,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 338,
    resource2: 'fabric',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'tabletjewel',
    c1Qty: 5,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1080,
    surcharge: 2405,
    suggest: 480,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-10-01',
    shardPrice: 0,
    capriceDelay: 0,
  },
  t12shoes: {
    uid: 't12shoes',
    level: 36,
    type: 'bl',
    xp: 210000,
    craftXp: 3150,
    value: 2500000,
    tradeMinMaxValue:
      '630000,780000,1250000,1900000,3150000;31500000,39000000,62500000,94000000,156500000',
    favor: 4293,
    time: 88200,
    atk: 0,
    def: 345,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'leather',
    r1Qty: 387,
    resource2: 'fabric',
    r2Qty: 113,
    resource3: 'mana',
    r3Qty: 41,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13shoes',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-28',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1375,
    surcharge: 3060,
    suggest: 610,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  westernshoes: {
    uid: 'westernshoes',
    level: 36,
    type: 'bl',
    xp: 210000,
    craftXp: 3150,
    value: 2700000,
    tradeMinMaxValue:
      '680000,840000,1350000,2050000,3400000;34000000,42000000,67500000,101500000,169000000',
    favor: 4532,
    time: 88200,
    atk: 0,
    def: 345,
    hp: 21,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'leather',
    r1Qty: 380,
    resource2: 'fabric',
    r2Qty: 110,
    resource3: 'mana',
    r3Qty: 35,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-76',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'resource2-17',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 1430,
    surcharge: 3175,
    suggest: 635,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-06-01',
    shardPrice: 350,
    capriceDelay: 0,
  },
  thanksgivingshoes: {
    uid: 'thanksgivingshoes',
    level: 37,
    type: 'bl',
    xp: 220000,
    craftXp: 3300,
    value: 2900000,
    tradeMinMaxValue:
      '730000,910000,1450000,2200000,3650000;36500000,45500000,72500000,109000000,181500000',
    favor: 5488,
    time: 105600,
    atk: 0,
    def: 355,
    hp: 0,
    eva: '0.07',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 404,
    resource2: 'fabric',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 46,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'resource2-25',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-81',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1480,
    surcharge: 3290,
    suggest: 660,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-11-15',
    shardPrice: 350,
    capriceDelay: 0,
  },
  junimayshoes: {
    uid: 'junimayshoes',
    level: 37,
    type: 'bl',
    xp: 220000,
    craftXp: 3300,
    value: 2950000,
    tradeMinMaxValue:
      '740000,920000,1500000,2200000,3700000;37000000,46000000,74000000,110500000,184500000',
    favor: 5426,
    time: 105600,
    atk: 89,
    def: 355,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 400,
    resource2: 'fabric',
    r2Qty: 118,
    resource3: 'mana',
    r3Qty: 46,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-80',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-24',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: 'carbuncle',
    tag: null,
    discount: 1490,
    surcharge: 3315,
    suggest: 665,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-04-04',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13shoes: {
    uid: 't13shoes',
    level: 39,
    type: 'bl',
    xp: 250000,
    craftXp: 3750,
    value: 5550000,
    tradeMinMaxValue:
      '1400000,1750000,2800000,4150000,6950000;69500000,86500000,139000000,208000000,347000000',
    favor: 6656,
    time: 150600,
    atk: 0,
    def: 435,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'tailor',
    worker2: 'master',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'leather',
    r1Qty: 438,
    resource2: 'mana',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcloth',
    c1Qty: 3,
    c1Tags: null,
    component2: 'gracesigil',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-88',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-8',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: 'chimera',
    tag: null,
    discount: 2020,
    surcharge: 4490,
    suggest: 900,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  woodshield: {
    uid: 'woodshield',
    level: 2,
    type: 'xs',
    xp: 250,
    craftXp: 4,
    value: 110,
    tradeMinMaxValue: '35,40,70,100,170;1700,2100,3300,5000,8300',
    favor: 1,
    time: 30,
    atk: 0,
    def: 12,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 10,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 7,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'bp:buckler',
    upgrade2: 'value*1.5',
    upgrade3: 'resource1-1',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-4',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 10,
    surcharge: 20,
    suggest: 5,
    speedup: 25,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  buckler: {
    uid: 'buckler',
    level: 5,
    type: 'xs',
    xp: 1200,
    craftXp: 18,
    value: 490,
    tradeMinMaxValue: '150,180,290,440,740;7400,9200,14500,22000,37000',
    favor: 16,
    time: 140,
    atk: 0,
    def: 28,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 2,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 14,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'elvenwood',
    c1Qty: 1,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.5',
    upgrade2: 'bp:oakshield',
    upgrade3: 'resource1-3',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 40,
    suggest: 10,
    speedup: 70,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 1,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  oakshield: {
    uid: 'oakshield',
    level: 7,
    type: 'xs',
    xp: 2350,
    craftXp: 35,
    value: 1200,
    tradeMinMaxValue: '360,450,720,1100,1800;18000,22500,36000,54000,90000',
    favor: 21,
    time: 270,
    atk: 0,
    def: 40,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 3,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 17,
    resource2: 'herbs',
    r2Qty: 9,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.5',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:ironshield',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 60,
    suggest: 10,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 3,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ironshield: {
    uid: 'ironshield',
    level: 10,
    type: 'xs',
    xp: 4950,
    craftXp: 74,
    value: 3350,
    tradeMinMaxValue: '840,1050,1700,2500,4200;42000,52500,84000,125000,210000',
    favor: 42,
    time: 630,
    atk: 0,
    def: 62,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 9,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 7,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 30,
    resource2: 'iron',
    r2Qty: 15,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:kiteshield',
    upgrade4: 'resource1-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 45,
    surcharge: 100,
    suggest: 20,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  grottoshield: {
    uid: 'grottoshield',
    level: 11,
    type: 'xs',
    xp: 6100,
    craftXp: 92,
    value: 6100,
    tradeMinMaxValue:
      '1700,2100,3400,5200,8600;86000,105000,170000,260000,430000',
    favor: 56,
    time: 780,
    atk: 0,
    def: 68,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 4,
    combo: 8,
    worker1: 'carpenter',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 34,
    resource2: 'iron',
    r2Qty: 17,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-7',
    upgrade4: 'resource2-9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'light',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 60,
    surcharge: 130,
    suggest: 25,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'grotto',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  kiteshield: {
    uid: 'kiteshield',
    level: 13,
    type: 'xs',
    xp: 8700,
    craftXp: 131,
    value: 9700,
    tradeMinMaxValue:
      '2400,3000,4900,7300,12000;120000,150000,245000,365000,605000',
    favor: 81,
    time: 1260,
    atk: 0,
    def: 84,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 49,
    resource2: 'ironwood',
    r2Qty: 10,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 3,
    c1Tags: null,
    component2: 'webbedwings',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-10',
    upgrade3: 'bp:largebuckler',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 75,
    surcharge: 165,
    suggest: 35,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  imperialscutum: {
    uid: 'imperialscutum',
    level: 14,
    type: 'xs',
    xp: 10000,
    craftXp: 150,
    value: 14500,
    tradeMinMaxValue:
      '3600,4500,7300,11000,18000;180000,225000,365000,545000,905000',
    favor: 169,
    time: 1590,
    atk: 0,
    def: 92,
    hp: 6,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 60,
    resource2: 'iron',
    r2Qty: 30,
    resource3: 'ironwood',
    r3Qty: 12,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-3',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource2-30',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 90,
    surcharge: 200,
    suggest: 40,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'wMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-07-30',
    shardPrice: 120,
    capriceDelay: 0,
  },
  argonshield: {
    uid: 'argonshield',
    level: 15,
    type: 'xs',
    xp: 12000,
    craftXp: 180,
    value: 17500,
    tradeMinMaxValue:
      '4400,5500,8800,13000,22000;220000,275000,440000,655000,1100000',
    favor: 150,
    time: 1920,
    atk: 0,
    def: 100,
    hp: 6,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 71,
    resource2: 'iron',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'pendant',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'icypearl',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-14',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-11',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 100,
    surcharge: 220,
    suggest: 45,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-12-27',
    shardPrice: 120,
    capriceDelay: 240,
  },
  largebuckler: {
    uid: 'largebuckler',
    level: 16,
    type: 'xs',
    xp: 13500,
    craftXp: 203,
    value: 21500,
    tradeMinMaxValue:
      '5400,6700,11000,16000,27000;270000,335000,540000,805000,1350000',
    favor: 221,
    time: 2400,
    atk: 0,
    def: 105,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 8,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 85,
    resource2: 'ironwood',
    r2Qty: 21,
    resource3: 'steel',
    r3Qty: 10,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-17',
    upgrade3: 'bp:towershield',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 110,
    surcharge: 240,
    suggest: 50,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  aegirshield: {
    uid: 'aegirshield',
    level: 17,
    type: 'xs',
    xp: 15500,
    craftXp: 233,
    value: 28000,
    tradeMinMaxValue:
      '7000,8800,14000,21000,35000;350000,440000,700000,1050000,1750000',
    favor: 305,
    time: 3000,
    atk: 29,
    def: 115,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 8,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'wood',
    r1Qty: 99,
    resource2: 'ironwood',
    r2Qty: 25,
    resource3: 'steel',
    r3Qty: 12,
    component1: 'sharpfang',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'resource1-20',
    upgrade2: 'resource2-8',
    upgrade3: 'time*0.75',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 125,
    surcharge: 275,
    suggest: 55,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-02-22',
    shardPrice: 140,
    capriceDelay: 0,
  },
  natureshield: {
    uid: 'natureshield',
    level: 18,
    type: 'xs',
    xp: 17500,
    craftXp: 263,
    value: 36000,
    tradeMinMaxValue:
      '9000,11500,18000,27000,45000;450000,565000,900000,1350000,2250000',
    favor: 376,
    time: 3600,
    atk: 0,
    def: 125,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'wood',
    r1Qty: 113,
    resource2: 'ironwood',
    r2Qty: 29,
    resource3: 'steel',
    r3Qty: 14,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 140,
    surcharge: 310,
    suggest: 60,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-04-20',
    shardPrice: 140,
    capriceDelay: 240,
  },
  towershield: {
    uid: 'towershield',
    level: 20,
    type: 'xs',
    xp: 22000,
    craftXp: 330,
    value: 66000,
    tradeMinMaxValue:
      '16500,21000,33000,50000,83000;825000,1050000,1650000,2500000,4150000',
    favor: 498,
    time: 6000,
    atk: 0,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'wood',
    r1Qty: 141,
    resource2: 'ironwood',
    r2Qty: 36,
    resource3: 'steel',
    r3Qty: 18,
    component1: 'starmetal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'darkscale',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:ancestortotem',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-4',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 185,
    surcharge: 410,
    suggest: 80,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  fallshield: {
    uid: 'fallshield',
    level: 22,
    type: 'xs',
    xp: 27000,
    craftXp: 405,
    value: 105000,
    tradeMinMaxValue:
      '26000,33000,53000,79000,130000;1300000,1650000,2650000,3950000,6550000',
    favor: 938,
    time: 8400,
    atk: 0,
    def: 160,
    hp: 0,
    eva: '0.05',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'wood',
    r1Qty: 170,
    resource2: 'herbs',
    r2Qty: 85,
    resource3: 'ironwood',
    r3Qty: 43,
    component1: 'oakshield',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'resource2-43',
    upgrade2: 'resource1-34',
    upgrade3: 'time*0.75',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-42',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 230,
    surcharge: 515,
    suggest: 105,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-10-05',
    shardPrice: 160,
    capriceDelay: 240,
  },
  ancestortotem: {
    uid: 'ancestortotem',
    level: 24,
    type: 'xs',
    xp: 32500,
    craftXp: 488,
    value: 195000,
    tradeMinMaxValue:
      '49000,61000,98000,145000,240000;2450000,3050000,4900000,7300000,12000000',
    favor: 771,
    time: 12600,
    atk: 0,
    def: 180,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'wood',
    r1Qty: 199,
    resource2: 'ironwood',
    r2Qty: 51,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'tomahawk',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'ancientmarble',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'bp:zealouspavise',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 310,
    surcharge: 690,
    suggest: 140,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  zealouspavise: {
    uid: 'zealouspavise',
    level: 27,
    type: 'xs',
    xp: 42000,
    craftXp: 630,
    value: 320000,
    tradeMinMaxValue:
      '80000,100000,160000,240000,400000;4000000,5000000,8000000,12000000,20000000',
    favor: 1224,
    time: 16800,
    atk: 0,
    def: 205,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'wood',
    r1Qty: 244,
    resource2: 'ironwood',
    r2Qty: 67,
    resource3: 'gems',
    r3Qty: 13,
    component1: 'ghostflag',
    c1Qty: 4,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:prideshield',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-4',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 395,
    surcharge: 880,
    suggest: 175,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  castleshield: {
    uid: 'castleshield',
    level: 28,
    type: 'xs',
    xp: 45500,
    craftXp: 683,
    value: 360000,
    tradeMinMaxValue:
      '97000,120000,195000,290000,490000;4850000,6050000,9700000,14500000,24500000',
    favor: 1589,
    time: 20400,
    atk: 0,
    def: 215,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 259,
    resource2: 'ironwood',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'ectoplasm',
    c1Qty: 4,
    c1Tags: null,
    component2: 'starmetal',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-2',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'blaze',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 420,
    surcharge: 930,
    suggest: 185,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'castle',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  stpatrickshield: {
    uid: 'stpatrickshield',
    level: 28,
    type: 'xs',
    xp: 45500,
    craftXp: 683,
    value: 380000,
    tradeMinMaxValue:
      '95000,120000,190000,290000,480000;4750000,5950000,9500000,14500000,24000000',
    favor: 1589,
    time: 20400,
    atk: 0,
    def: 215,
    hp: 14,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 259,
    resource2: 'ironwood',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'ghostflag',
    c1Qty: 3,
    c1Tags: null,
    component2: 'icypearl',
    c2Qty: 10,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-5',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 430,
    surcharge: 955,
    suggest: 190,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-03-10',
    shardPrice: 200,
    capriceDelay: 0,
  },
  eastershield2020: {
    uid: 'eastershield2020',
    level: 28,
    type: 'xs',
    xp: 45500,
    craftXp: 683,
    value: 400000,
    tradeMinMaxValue:
      '100000,125000,200000,300000,500000;5000000,6250000,10000000,15000000,25000000',
    favor: 1589,
    time: 20400,
    atk: 0,
    def: 215,
    hp: 0,
    eva: '0.05',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 259,
    resource2: 'ironwood',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'sewingthread',
    c1Qty: 3,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'rabbitspirit',
    tag: null,
    discount: 440,
    surcharge: 975,
    suggest: 195,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-04-07',
    shardPrice: 200,
    capriceDelay: 0,
  },
  goldshield: {
    uid: 'goldshield',
    level: 29,
    type: 'xs',
    xp: 49000,
    craftXp: 735,
    value: 600000,
    tradeMinMaxValue:
      '150000,190000,300000,450000,750000;7500000,9400000,15000000,22500000,37500000',
    favor: 1847,
    time: 24600,
    atk: 0,
    def: 225,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'wood',
    r1Qty: 275,
    resource2: 'ironwood',
    r2Qty: 77,
    resource3: 'gems',
    r3Qty: 19,
    component1: 'tabletfragment',
    c1Qty: 6,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.70',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'luxuriousspirit',
    tag: null,
    discount: 400,
    surcharge: 890,
    suggest: 180,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-07-11',
    shardPrice: 0,
    capriceDelay: 0,
  },
  prideshield: {
    uid: 'prideshield',
    level: 30,
    type: 'xs',
    xp: 53000,
    craftXp: 795,
    value: 610000,
    tradeMinMaxValue:
      '155000,190000,310000,460000,760000;7650000,9550000,15500000,23000000,38000000',
    favor: 1909,
    time: 29400,
    atk: 0,
    def: 235,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 290,
    resource2: 'ironwood',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'goldshell',
    c1Qty: 5,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:paragon',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'lion',
    tag: null,
    discount: 810,
    surcharge: 1795,
    suggest: 360,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  dragonshield: {
    uid: 'dragonshield',
    level: 31,
    type: 'xs',
    xp: 56500,
    craftXp: 848,
    value: 655000,
    tradeMinMaxValue:
      '165000,200000,330000,490000,820000;8200000,10000000,16500000,24500000,41000000',
    favor: 2451,
    time: 34800,
    atk: 0,
    def: 245,
    hp: 15,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'blacksmith',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'wood',
    r1Qty: 306,
    resource2: 'ironwood',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'deepcoral',
    c1Qty: 4,
    c1Tags: null,
    component2: 'ghostflag',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 835,
    surcharge: 1855,
    suggest: 370,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-12-29',
    shardPrice: 250,
    capriceDelay: 0,
  },
  paragon: {
    uid: 'paragon',
    level: 33,
    type: 'xs',
    xp: 97500,
    craftXp: 1463,
    value: 1300000,
    tradeMinMaxValue:
      '330000,410000,650000,980000,1650000;16500000,20500000,32500000,49000000,81500000',
    favor: 2889,
    time: 50400,
    atk: 0,
    def: 305,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 338,
    resource2: 'ironwood',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12shield',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-68',
    supgrade2: 'resource3-6',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1005,
    surcharge: 2235,
    suggest: 445,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  beeshield: {
    uid: 'beeshield',
    level: 33,
    type: 'xs',
    xp: 97500,
    craftXp: 1463,
    value: 1700000,
    tradeMinMaxValue:
      '430000,530000,850000,1300000,2150000;21500000,26500000,42500000,64000000,106500000',
    favor: 3218,
    time: 50400,
    atk: 0,
    def: 305,
    hp: 19,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 338,
    resource2: 'ironwood',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1145,
    surcharge: 2545,
    suggest: 510,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-06-02',
    shardPrice: 300,
    capriceDelay: 0,
  },
  snowshield: {
    uid: 'snowshield',
    level: 34,
    type: 'xs',
    xp: 105000,
    craftXp: 1575,
    value: 1900000,
    tradeMinMaxValue:
      '480000,590000,950000,1450000,2400000;24000000,29500000,47500000,71500000,119000000',
    favor: 3564,
    time: 60000,
    atk: 80,
    def: 320,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'blacksmith',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 354,
    resource2: 'ironwood',
    r2Qty: 103,
    resource3: 'mana',
    r3Qty: 29,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-71',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-21',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'resource3-6',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1210,
    surcharge: 2685,
    suggest: 535,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-30',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12shield: {
    uid: 't12shield',
    level: 36,
    type: 'xs',
    xp: 195000,
    craftXp: 2925,
    value: 2500000,
    tradeMinMaxValue:
      '630000,780000,1250000,1900000,3150000;31500000,39000000,62500000,94000000,156500000',
    favor: 4293,
    time: 85800,
    atk: 0,
    def: 385,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'wood',
    r1Qty: 387,
    resource2: 'ironwood',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13shield',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-28',
    supgrade2: 'resource3-8',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1375,
    surcharge: 3060,
    suggest: 610,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  shoprshield: {
    uid: 'shoprshield',
    level: 36,
    type: 'xs',
    xp: 195000,
    craftXp: 2925,
    value: 2700000,
    tradeMinMaxValue:
      '680000,840000,1350000,2050000,3400000;34000000,42000000,67500000,101500000,169000000',
    favor: 4532,
    time: 85200,
    atk: 0,
    def: 385,
    hp: 24,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'wood',
    r1Qty: 380,
    resource2: 'ironwood',
    r2Qty: 110,
    resource3: 'mana',
    r3Qty: 35,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magicpotion',
    c2Qty: 1,
    c2Tags: 'uncommon',
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'cquality2-1',
    upgrade3: 'resource1-76',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-28',
    supgrade2: 'resource3-7',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1430,
    surcharge: 3175,
    suggest: 635,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-06-14',
    shardPrice: 350,
    capriceDelay: 0,
  },
  goldshield2: {
    uid: 'goldshield2',
    level: 36,
    type: 'xs',
    xp: 195000,
    craftXp: 2925,
    value: 5000000,
    tradeMinMaxValue:
      '1250000,1550000,2500000,3750000,6250000;62500000,78000000,125000000,187500000,312500000',
    favor: 4782,
    time: 85800,
    atk: 0,
    def: 385,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'blacksmith',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'wood',
    r1Qty: 387,
    resource2: 'ironwood',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-97',
    upgrade4: 'resource2-28',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1440,
    surcharge: 3205,
    suggest: 640,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-12-23',
    shardPrice: 0,
    capriceDelay: 0,
  },
  newyearshield: {
    uid: 'newyearshield',
    level: 37,
    type: 'xs',
    xp: 210000,
    craftXp: 3150,
    value: 2900000,
    tradeMinMaxValue:
      '730000,910000,1450000,2200000,3650000;36500000,45500000,72500000,109000000,181500000',
    favor: 5426,
    time: 102600,
    atk: 100,
    def: 400,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 400,
    resource2: 'steel',
    r2Qty: 120,
    resource3: 'gems',
    r3Qty: 46,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-80',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-30',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-9',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 1480,
    surcharge: 3290,
    suggest: 660,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-12-28',
    shardPrice: 350,
    capriceDelay: 0,
  },
  africanshield: {
    uid: 'africanshield',
    level: 37,
    type: 'xs',
    xp: 210000,
    craftXp: 3150,
    value: 2800000,
    tradeMinMaxValue:
      '700000,880000,1400000,2100000,3500000;35000000,44000000,70000000,105000000,175000000',
    favor: 5293,
    time: 102600,
    atk: 0,
    def: 400,
    hp: 29,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'wood',
    r1Qty: 404,
    resource2: 'ironwood',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 39,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource3-8',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-81',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource3-8',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1455,
    surcharge: 3235,
    suggest: 645,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-01-24',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13shield: {
    uid: 't13shield',
    level: 39,
    type: 'xs',
    xp: 235000,
    craftXp: 3525,
    value: 5250000,
    tradeMinMaxValue:
      '1300000,1650000,2650000,3950000,6550000;65500000,82000000,131500000,197000000,328000000',
    favor: 6883,
    time: 146400,
    atk: 0,
    def: 495,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'ironwood',
    r1Qty: 139,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-28',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-13',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1965,
    surcharge: 4370,
    suggest: 875,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  beachshield: {
    uid: 'beachshield',
    level: 39,
    type: 'xs',
    xp: 235000,
    craftXp: 3525,
    value: 5500000,
    tradeMinMaxValue:
      '1400000,1700000,2750000,4150000,6900000;69000000,86000000,137500000,206500000,344000000',
    favor: 6670,
    time: 146400,
    atk: 124,
    def: 495,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: 'blacksmith',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'ironwood',
    r1Qty: 131,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 2,
    c1Tags: null,
    component2: 'overgrownvine',
    c2Qty: 10,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-8',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-33',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component2-4',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 2010,
    surcharge: 4470,
    suggest: 895,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-06-27',
    shardPrice: 375,
    capriceDelay: 0,
  },
  mundrashield: {
    uid: 'mundrashield',
    level: 40,
    type: 'xs',
    xp: 245000,
    craftXp: 7350,
    value: 5350000,
    tradeMinMaxValue:
      '1350000,1700000,2700000,4050000,6700000;67000000,84000000,134500000,201500000,336000000',
    favor: 8344,
    time: 174600,
    atk: 0,
    def: 510,
    hp: 38,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'moondragon',
    worker2: 'carpenter',
    worker3: 'jeweler',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'ironwood',
    r1Qty: 144,
    resource2: 'gems',
    r2Qty: 54,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voideye',
    c1Qty: 2,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-29',
    upgrade3: 'resource2-8',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-11',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: 'dragon',
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1985,
    surcharge: 4410,
    suggest: 880,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  squirecloak: {
    uid: 'squirecloak',
    level: 7,
    type: 'xc',
    xp: 3450,
    craftXp: 52,
    value: 2650,
    tradeMinMaxValue: '660,830,1350,2000,3300;33000,41500,66500,99500,165000',
    favor: 26,
    time: 330,
    atk: 0,
    def: 52,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'elven',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 19,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:wanderercloak',
    upgrade4: 'resource1-4',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'quality+1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 90,
    suggest: 20,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  wanderercloak: {
    uid: 'wanderercloak',
    level: 10,
    type: 'xc',
    xp: 7300,
    craftXp: 110,
    value: 8350,
    tradeMinMaxValue:
      '2100,2600,4200,6300,10500;105000,130000,210000,315000,520000',
    favor: 52,
    time: 750,
    atk: 0,
    def: 78,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 9,
    worker1: 'elven',
    worker2: null,
    worker3: null,
    w1BuildingReq: 4,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 33,
    resource2: 'iron',
    r2Qty: 24,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 3,
    c1Tags: null,
    component2: 'redpinecone',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:druidcloak',
    upgrade4: 'resource1-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 155,
    suggest: 30,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  druidcloak: {
    uid: 'druidcloak',
    level: 13,
    type: 'xc',
    xp: 13000,
    craftXp: 195,
    value: 19000,
    tradeMinMaxValue:
      '4800,5900,9500,14500,24000;240000,295000,475000,715000,1200000',
    favor: 144,
    time: 1470,
    atk: 0,
    def: 105,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 54,
    resource2: 'herbs',
    r2Qty: 39,
    resource3: 'fabric',
    r3Qty: 11,
    component1: 'laurels',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'livingroots',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-11',
    upgrade3: 'bp:empirecloak',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 100,
    surcharge: 225,
    suggest: 45,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  empirecloak: {
    uid: 'empirecloak',
    level: 16,
    type: 'xc',
    xp: 20000,
    craftXp: 300,
    value: 41000,
    tradeMinMaxValue:
      '10500,13000,21000,31000,51000;515000,640000,1050000,1550000,2550000',
    favor: 217,
    time: 3000,
    atk: 0,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 8,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 8,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 94,
    resource2: 'fabric',
    r2Qty: 23,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: 'icypearl',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-19',
    upgrade3: 'bp:elvencloak',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 145,
    surcharge: 325,
    suggest: 65,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  batcloak: {
    uid: 'batcloak',
    level: 17,
    type: 'xc',
    xp: 23000,
    craftXp: 345,
    value: 63000,
    tradeMinMaxValue:
      '16000,19500,32000,47000,79000;790000,985000,1600000,2350000,3950000',
    favor: 271,
    time: 3600,
    atk: 38,
    def: 150,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 8,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'leather',
    r1Qty: 109,
    resource2: 'fabric',
    r2Qty: 27,
    resource3: null,
    r3Qty: 0,
    component1: 'mooncrystal',
    c1Qty: 5,
    c1Tags: null,
    component2: 'webbedwings',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'component2-1',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-27',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 180,
    surcharge: 400,
    suggest: 80,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-10-19',
    shardPrice: 140,
    capriceDelay: 0,
  },
  elvencloak: {
    uid: 'elvencloak',
    level: 20,
    type: 'xc',
    xp: 32500,
    craftXp: 488,
    value: 105000,
    tradeMinMaxValue:
      '26000,33000,53000,79000,130000;1300000,1650000,2650000,3950000,6550000',
    favor: 756,
    time: 7200,
    atk: 0,
    def: 185,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'leather',
    r1Qty: 155,
    resource2: 'herbs',
    r2Qty: 113,
    resource3: 'fabric',
    r3Qty: 40,
    component1: 'silkscarab',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'bp:furcloak',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-23',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource3-8',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 230,
    surcharge: 515,
    suggest: 105,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  furcloak: {
    uid: 'furcloak',
    level: 24,
    type: 'xc',
    xp: 48500,
    craftXp: 728,
    value: 165000,
    tradeMinMaxValue:
      '41000,52000,83000,125000,210000;2050000,2600000,4150000,6200000,10500000',
    favor: 954,
    time: 15600,
    atk: 0,
    def: 230,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'leather',
    r1Qty: 219,
    resource2: 'fabric',
    r2Qty: 57,
    resource3: 'gems',
    r3Qty: 6,
    component1: 'timecrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-44',
    upgrade3: 'bp:scalecloak',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-17',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource3-2',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'mammoth',
    tag: null,
    discount: 290,
    surcharge: 640,
    suggest: 130,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  scalecloak: {
    uid: 'scalecloak',
    level: 27,
    type: 'xc',
    xp: 62500,
    craftXp: 938,
    value: 390000,
    tradeMinMaxValue:
      '98000,120000,195000,290000,490000;4900000,6100000,9750000,14500000,24500000',
    favor: 1488,
    time: 18000,
    atk: 0,
    def: 270,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'leather',
    r1Qty: 268,
    resource2: 'fabric',
    r2Qty: 74,
    resource3: 'gems',
    r3Qty: 10,
    component1: 'savagearmor',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'darkscale',
    c2Qty: 10,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'resource1-54',
    upgrade2: 'value*1.25',
    upgrade3: 'bp:bishopcloak',
    upgrade4: 'cquality1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component2-5',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 435,
    surcharge: 965,
    suggest: 195,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  darkknightcloak: {
    uid: 'darkknightcloak',
    level: 28,
    type: 'xc',
    xp: 67500,
    craftXp: 1013,
    value: 440000,
    tradeMinMaxValue:
      '110000,140000,220000,330000,550000;5500000,6900000,11000000,16500000,27500000',
    favor: 1749,
    time: 24600,
    atk: 0,
    def: 280,
    hp: 0,
    eva: '0.05',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'leather',
    r1Qty: 285,
    resource2: 'fabric',
    r2Qty: 79,
    resource3: 'mana',
    r3Qty: 14,
    component1: 'ghostflag',
    c1Qty: 3,
    c1Tags: null,
    component2: 'ectoplasm',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-57',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-16',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 460,
    surcharge: 1025,
    suggest: 205,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-11-02',
    shardPrice: 200,
    capriceDelay: 0,
  },
  bishopcloak: {
    uid: 'bishopcloak',
    level: 30,
    type: 'xc',
    xp: 78500,
    craftXp: 1178,
    value: 615000,
    tradeMinMaxValue:
      '155000,190000,310000,460000,770000;7700000,9600000,15500000,23000000,38500000',
    favor: 2311,
    time: 35400,
    atk: 0,
    def: 310,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'elven',
    worker2: 'priest',
    worker3: 'tailor',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'leather',
    r1Qty: 319,
    resource2: 'fabric',
    r2Qty: 91,
    resource3: 'gems',
    r3Qty: 18,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: 'ancientmarble',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-64',
    upgrade3: 'bp:starcloak',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 810,
    surcharge: 1800,
    suggest: 360,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  starcloak: {
    uid: 'starcloak',
    level: 33,
    type: 'xc',
    xp: 145000,
    craftXp: 2175,
    value: 1650000,
    tradeMinMaxValue:
      '410000,520000,830000,1250000,2050000;20500000,26000000,41500000,62000000,103000000',
    favor: 3471,
    time: 60000,
    atk: 0,
    def: 400,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 372,
    resource2: 'fabric',
    r2Qty: 107,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-74',
    upgrade3: 'bp:t12cloak',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-5',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1130,
    surcharge: 2510,
    suggest: 500,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldcloak: {
    uid: 'goldcloak',
    level: 33,
    type: 'xc',
    xp: 145000,
    craftXp: 2175,
    value: 3200000,
    tradeMinMaxValue:
      '800000,1000000,1600000,2400000,4000000;40000000,50000000,80000000,120000000,200000000',
    favor: 3471,
    time: 60000,
    atk: 0,
    def: 400,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 5,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'leather',
    r1Qty: 372,
    resource2: 'fabric',
    r2Qty: 107,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'tabletjewel',
    c1Qty: 5,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-74',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-21',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'multi+0.1',
    supgrade2: 'resource3-5',
    supgrade3: 'component1-1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1165,
    surcharge: 2585,
    suggest: 515,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-01-21',
    shardPrice: 0,
    capriceDelay: 0,
  },
  frogcloak: {
    uid: 'frogcloak',
    level: 34,
    type: 'xc',
    xp: 155000,
    craftXp: 2325,
    value: 1950000,
    tradeMinMaxValue:
      '490000,610000,980000,1450000,2450000;24500000,30500000,49000000,73000000,122000000',
    favor: 3387,
    time: 72000,
    atk: 0,
    def: 415,
    hp: 0,
    eva: '0.05',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 337,
    resource2: 'oils',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 30,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-67',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-6',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1225,
    surcharge: 2720,
    suggest: 545,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-01-12',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12cloak: {
    uid: 't12cloak',
    level: 36,
    type: 'xc',
    xp: 290000,
    craftXp: 4350,
    value: 2800000,
    tradeMinMaxValue:
      '700000,880000,1400000,2100000,3500000;35000000,44000000,70000000,105000000,175000000',
    favor: 5130,
    time: 103200,
    atk: 0,
    def: 505,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: 'wizard',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'leather',
    r1Qty: 426,
    resource2: 'fabric',
    r2Qty: 124,
    resource3: 'gems',
    r3Qty: 33,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-85',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13cloak',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-31',
    supgrade2: 'resource3-7',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1455,
    surcharge: 3235,
    suggest: 645,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  blossomcloak: {
    uid: 'blossomcloak',
    level: 36,
    type: 'xc',
    xp: 290000,
    craftXp: 4350,
    value: 3100000,
    tradeMinMaxValue:
      '780000,970000,1550000,2350000,3900000;39000000,48500000,77500000,116500000,194000000',
    favor: 5235,
    time: 103200,
    atk: 0,
    def: 505,
    hp: 32,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: 'wizard',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'leather',
    r1Qty: 426,
    resource2: 'fabric',
    r2Qty: 124,
    resource3: 'mana',
    r3Qty: 37,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-64',
    upgrade4: 'resource2-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'resource1-64',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 1530,
    surcharge: 3395,
    suggest: 680,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-03-23',
    shardPrice: 350,
    capriceDelay: 0,
  },
  junimaycloak: {
    uid: 'junimaycloak',
    level: 37,
    type: 'xc',
    xp: 310000,
    craftXp: 4650,
    value: 3150000,
    tradeMinMaxValue:
      '790000,980000,1600000,2350000,3950000;39500000,49000000,79000000,118000000,197000000',
    favor: 5843,
    time: 123000,
    atk: 130,
    def: 520,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: 'wizard',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'leather',
    r1Qty: 445,
    resource2: 'fabric',
    r2Qty: 136,
    resource3: 'gems',
    r3Qty: 36,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-89',
    upgrade4: 'resource2-27',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: 'carbuncle',
    tag: null,
    discount: 1540,
    surcharge: 3420,
    suggest: 685,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-04-05',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13cloak: {
    uid: 't13cloak',
    level: 39,
    type: 'xc',
    xp: 345000,
    craftXp: 5175,
    value: 5750000,
    tradeMinMaxValue:
      '1450000,1800000,2900000,4300000,7200000;72000000,90000000,144000000,215500000,359500000',
    favor: 8218,
    time: 175200,
    atk: 0,
    def: 645,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: 'wizard',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'leather',
    r1Qty: 482,
    resource2: 'fabric',
    r2Qty: 153,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcloth',
    c1Qty: 2,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-96',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-23',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-2',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 2055,
    surcharge: 4565,
    suggest: 915,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  beachcloak: {
    uid: 'beachcloak',
    level: 39,
    type: 'xc',
    xp: 345000,
    craftXp: 5175,
    value: 5950000,
    tradeMinMaxValue:
      '1500000,1850000,3000000,4450000,7450000;74500000,93000000,149000000,223000000,372000000',
    favor: 8218,
    time: 175200,
    atk: 0,
    def: 645,
    hp: 40,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'elven',
    worker2: 'tailor',
    worker3: 'wizard',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'leather',
    r1Qty: 482,
    resource2: 'fabric',
    r2Qty: 148,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcloth',
    c1Qty: 2,
    c1Tags: null,
    component2: 'deepcoral',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-22',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-2',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'resource1-121',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 2090,
    surcharge: 4640,
    suggest: 930,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Cloth',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-06-28',
    shardPrice: 375,
    capriceDelay: 0,
  },
  ironring: {
    uid: 'ironring',
    level: 5,
    type: 'xr',
    xp: 1200,
    craftXp: 18,
    value: 690,
    tradeMinMaxValue: '170,220,350,520,860;8600,11000,17500,26000,43000',
    favor: 16,
    time: 140,
    atk: 29,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 9,
    worker1: 'jeweler',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 14,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-3',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-7',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 45,
    suggest: 10,
    speedup: 70,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  doublering: {
    uid: 'doublering',
    level: 7,
    type: 'xr',
    xp: 2350,
    craftXp: 35,
    value: 1200,
    tradeMinMaxValue: '300,380,600,900,1500;15000,19000,30000,45000,75000',
    favor: 21,
    time: 270,
    atk: 42,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'jeweler',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 17,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-6',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-6',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 60,
    suggest: 10,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  fatring: {
    uid: 'fatring',
    level: 10,
    type: 'xr',
    xp: 4950,
    craftXp: 74,
    value: 3350,
    tradeMinMaxValue: '840,1050,1700,2500,4200;42000,52500,84000,125000,210000',
    favor: 42,
    time: 630,
    atk: 64,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'jeweler',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 30,
    resource2: 'herbs',
    r2Qty: 15,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:silverband',
    upgrade4: 'resource1-6',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'quality+2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 45,
    surcharge: 100,
    suggest: 20,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  grottoring: {
    uid: 'grottoring',
    level: 11,
    type: 'xr',
    xp: 6100,
    craftXp: 92,
    value: 5750,
    tradeMinMaxValue:
      '1600,2000,3200,4800,7900;79500,99000,160000,240000,395000',
    favor: 56,
    time: 780,
    atk: 72,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 3,
    combo: 8,
    worker1: 'jeweler',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 34,
    resource2: 'herbs',
    r2Qty: 17,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-7',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource2-17',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'bubble',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 60,
    surcharge: 130,
    suggest: 25,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'grotto',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  silverband: {
    uid: 'silverband',
    level: 13,
    type: 'xr',
    xp: 8700,
    craftXp: 131,
    value: 6850,
    tradeMinMaxValue:
      '1700,2100,3400,5100,8600;85500,105000,170000,255000,430000',
    favor: 81,
    time: 1260,
    atk: 88,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 8,
    worker1: 'jeweler',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 49,
    resource2: 'herbs',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:noblering',
    upgrade4: 'resource1-10',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 65,
    surcharge: 140,
    suggest: 30,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  valentinering: {
    uid: 'valentinering',
    level: 14,
    type: 'xr',
    xp: 10000,
    craftXp: 150,
    value: 11000,
    tradeMinMaxValue:
      '2800,3400,5500,8300,14000;140000,170000,275000,415000,690000',
    favor: 119,
    time: 1590,
    atk: 96,
    def: 0,
    hp: 6,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'jeweler',
    worker2: null,
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 60,
    resource2: 'herbs',
    r2Qty: 30,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-12',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource1-30',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 80,
    surcharge: 175,
    suggest: 35,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-02-07',
    shardPrice: 100,
    capriceDelay: 240,
  },
  noblering: {
    uid: 'noblering',
    level: 15,
    type: 'xr',
    xp: 12000,
    craftXp: 180,
    value: 14000,
    tradeMinMaxValue:
      '3500,4400,7000,10500,17500;175000,220000,350000,525000,875000',
    favor: 135,
    time: 1920,
    atk: 105,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'jeweler',
    worker2: null,
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 71,
    resource2: 'steel',
    r2Qty: 16,
    resource3: null,
    r3Qty: 0,
    component1: 'ironring',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'icypearl',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-14',
    upgrade3: 'bp:knightsignet',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 90,
    surcharge: 195,
    suggest: 40,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  cincoring2020: {
    uid: 'cincoring2020',
    level: 15,
    type: 'xr',
    xp: 12000,
    craftXp: 180,
    value: 20000,
    tradeMinMaxValue:
      '5000,6300,10000,15000,25000;250000,315000,500000,750000,1250000',
    favor: 150,
    time: 2040,
    atk: 105,
    def: 0,
    hp: 7,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'jeweler',
    worker2: null,
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 71,
    resource2: 'steel',
    r2Qty: 16,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 3,
    c1Tags: null,
    component2: 'glowingmushrooms',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-14',
    upgrade3: 'resource1-14',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 105,
    surcharge: 230,
    suggest: 45,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-05-05',
    shardPrice: 120,
    capriceDelay: 240,
  },
  hemmadonring: {
    uid: 'hemmadonring',
    level: 16,
    type: 'xr',
    xp: 13500,
    craftXp: 203,
    value: 21000,
    tradeMinMaxValue:
      '5300,6600,10500,16000,26000;265000,330000,525000,790000,1300000',
    favor: 193,
    time: 2400,
    atk: 115,
    def: 29,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'jeweler',
    worker2: null,
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 8,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 85,
    resource2: 'steel',
    r2Qty: 21,
    resource3: null,
    r3Qty: 0,
    component1: 'darkscale',
    c1Qty: 3,
    c1Tags: null,
    component2: 'arcanedust',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-17',
    upgrade3: 'resource1-17',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-11',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 110,
    surcharge: 240,
    suggest: 50,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-04-19',
    shardPrice: 120,
    capriceDelay: 0,
  },
  knightsignet: {
    uid: 'knightsignet',
    level: 17,
    type: 'xr',
    xp: 15500,
    craftXp: 233,
    value: 23500,
    tradeMinMaxValue:
      '5900,7300,12000,17500,29000;295000,365000,590000,880000,1450000',
    favor: 349,
    time: 2700,
    atk: 120,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 1,
    w3BuildingReq: 4,
    resource1: 'iron',
    r1Qty: 99,
    resource2: 'herbs',
    r2Qty: 49,
    resource3: 'steel',
    r3Qty: 25,
    component1: 'fatring',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'mooncrystal',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'bp:batrachitestone',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 250,
    suggest: 50,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 15,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  freedomring: {
    uid: 'freedomring',
    level: 18,
    type: 'xr',
    xp: 17500,
    craftXp: 263,
    value: 44500,
    tradeMinMaxValue:
      '11000,14000,22000,33000,56000;555000,695000,1100000,1650000,2800000',
    favor: 296,
    time: 3600,
    atk: 130,
    def: 33,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 1,
    w3BuildingReq: 5,
    resource1: 'iron',
    r1Qty: 113,
    resource2: 'steel',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: 'redpinecone',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'resource2-9',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource1-23',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'eagle',
    tag: null,
    discount: 155,
    surcharge: 340,
    suggest: 70,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2021-06-29',
    shardPrice: 140,
    capriceDelay: 0,
  },
  lunarring: {
    uid: 'lunarring',
    level: 18,
    type: 'xr',
    xp: 17500,
    craftXp: 263,
    value: 46000,
    tradeMinMaxValue:
      '11500,14500,23000,35000,58000;575000,720000,1150000,1750000,2900000',
    favor: 443,
    time: 3600,
    atk: 130,
    def: 0,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 1,
    w3BuildingReq: 5,
    resource1: 'leather',
    r1Qty: 110,
    resource2: 'fabric',
    r2Qty: 27,
    resource3: 'iron',
    r3Qty: 27,
    component1: 'mooncrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-22',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-27',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 155,
    surcharge: 345,
    suggest: 70,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-01-10',
    shardPrice: 140,
    capriceDelay: 0,
  },
  batrachitestone: {
    uid: 'batrachitestone',
    level: 20,
    type: 'xr',
    xp: 22000,
    craftXp: 330,
    value: 59000,
    tradeMinMaxValue:
      '15000,18500,30000,44000,74000;740000,920000,1500000,2200000,3700000',
    favor: 379,
    time: 6000,
    atk: 150,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'iron',
    r1Qty: 141,
    resource2: 'steel',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'evileye',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:sulfuritecoil',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 175,
    surcharge: 390,
    suggest: 80,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pyramidring: {
    uid: 'pyramidring',
    level: 21,
    type: 'xr',
    xp: 24500,
    craftXp: 368,
    value: 88500,
    tradeMinMaxValue:
      '24000,30000,48000,73000,120000;1200000,1500000,2400000,3650000,6050000',
    favor: 502,
    time: 7200,
    atk: 160,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 7,
    combo: 7,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'iron',
    r1Qty: 155,
    resource2: 'steel',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'starmetal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-8',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'unholy',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 215,
    surcharge: 475,
    suggest: 95,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: 'pyramid',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  chosenring: {
    uid: 'chosenring',
    level: 22,
    type: 'xr',
    xp: 27000,
    craftXp: 405,
    value: 120000,
    tradeMinMaxValue:
      '30000,38000,60000,90000,150000;1500000,1900000,3000000,4500000,7500000',
    favor: 765,
    time: 8400,
    atk: 170,
    def: 43,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'iron',
    r1Qty: 170,
    resource2: 'steel',
    r2Qty: 43,
    resource3: 'oils',
    r3Qty: 22,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'icypearl',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-9',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 250,
    surcharge: 550,
    suggest: 110,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-09-10',
    shardPrice: 160,
    capriceDelay: 240,
  },
  sulfuritecoil: {
    uid: 'sulfuritecoil',
    level: 25,
    type: 'xr',
    xp: 35500,
    craftXp: 533,
    value: 185000,
    tradeMinMaxValue:
      '46000,58000,93000,140000,230000;2300000,2900000,4650000,6950000,11500000',
    favor: 896,
    time: 16200,
    atk: 200,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'iron',
    r1Qty: 214,
    resource2: 'steel',
    r2Qty: 57,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'ancientmarble',
    c1Qty: 5,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'bp:feyring',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-4',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 305,
    surcharge: 675,
    suggest: 135,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  batring: {
    uid: 'batring',
    level: 26,
    type: 'xr',
    xp: 39000,
    craftXp: 585,
    value: 205000,
    tradeMinMaxValue:
      '51000,64000,105000,155000,260000;2550000,3200000,5150000,7700000,13000000',
    favor: 1168,
    time: 19200,
    atk: 210,
    def: 0,
    hp: 13,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 8,
    combo: 6,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 13,
    resource1: 'iron',
    r1Qty: 229,
    resource2: 'steel',
    r2Qty: 62,
    resource3: 'gems',
    r3Qty: 10,
    component1: 'timecrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-46',
    upgrade3: 'resource2-19',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 320,
    surcharge: 710,
    suggest: 140,
    speedup: 995,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-10-10',
    shardPrice: 180,
    capriceDelay: 0,
  },
  feyring: {
    uid: 'feyring',
    level: 27,
    type: 'xr',
    xp: 42000,
    craftXp: 630,
    value: 280000,
    tradeMinMaxValue:
      '70000,88000,140000,210000,350000;3500000,4400000,7000000,10500000,17500000',
    favor: 1224,
    time: 16800,
    atk: 220,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'iron',
    r1Qty: 244,
    resource2: 'steel',
    r2Qty: 67,
    resource3: 'gems',
    r3Qty: 13,
    component1: 'ectoplasm',
    c1Qty: 5,
    c1Tags: null,
    component2: 'timecrystal',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-49',
    upgrade3: 'bp:borealis',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 370,
    surcharge: 825,
    suggest: 165,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  springflower: {
    uid: 'springflower',
    level: 28,
    type: 'xr',
    xp: 45500,
    craftXp: 683,
    value: 380000,
    tradeMinMaxValue:
      '95000,120000,190000,290000,480000;4750000,5950000,9500000,14500000,24000000',
    favor: 1589,
    time: 20400,
    atk: 230,
    def: 0,
    hp: 14,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'iron',
    r1Qty: 259,
    resource2: 'steel',
    r2Qty: 72,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'ectoplasm',
    c1Qty: 4,
    c1Tags: null,
    component2: 'mooncrystal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-52',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-4',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 430,
    surcharge: 955,
    suggest: 190,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-03-24',
    shardPrice: 200,
    capriceDelay: 240,
  },
  schoolring2020: {
    uid: 'schoolring2020',
    level: 29,
    type: 'xr',
    xp: 49000,
    craftXp: 735,
    value: 420000,
    tradeMinMaxValue:
      '105000,130000,210000,320000,530000;5250000,6550000,10500000,16000000,26500000',
    favor: 1777,
    time: 24600,
    atk: 240,
    def: 60,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'iron',
    r1Qty: 275,
    resource2: 'steel',
    r2Qty: 77,
    resource3: 'mana',
    r3Qty: 14,
    component1: 'sewingthread',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 450,
    surcharge: 1000,
    suggest: 200,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2020-08-25',
    shardPrice: 200,
    capriceDelay: 240,
  },
  goldring: {
    uid: 'goldring',
    level: 29,
    type: 'xr',
    xp: 49000,
    craftXp: 735,
    value: 550000,
    tradeMinMaxValue:
      '140000,170000,280000,410000,690000;6900000,8600000,14000000,20500000,34500000',
    favor: 1777,
    time: 24600,
    atk: 240,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 9,
    combo: 6,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 24,
    w2BuildingReq: 20,
    w3BuildingReq: 17,
    resource1: 'iron',
    r1Qty: 275,
    resource2: 'steel',
    r2Qty: 77,
    resource3: 'mana',
    r3Qty: 14,
    component1: 'tabletfragment',
    c1Qty: 6,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-55',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'luxuriousspirit',
    tag: null,
    discount: 385,
    surcharge: 855,
    suggest: 170,
    speedup: 1220,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-10-03',
    shardPrice: 0,
    capriceDelay: 0,
  },
  borealis: {
    uid: 'borealis',
    level: 30,
    type: 'xr',
    xp: 53000,
    craftXp: 795,
    value: 625000,
    tradeMinMaxValue:
      '155000,195000,310000,470000,780000;7800000,9750000,15500000,23500000,39000000',
    favor: 1909,
    time: 29400,
    atk: 250,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: 'wizard',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 290,
    resource2: 'steel',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'deepcoral',
    c1Qty: 6,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-58',
    upgrade3: 'bp:mysteriousring',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 815,
    surcharge: 1815,
    suggest: 365,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  templering: {
    uid: 'templering',
    level: 31,
    type: 'xr',
    xp: 56500,
    craftXp: 848,
    value: 650000,
    tradeMinMaxValue:
      '170000,210000,340000,510000,850000;8500000,10500000,17000000,25500000,42500000',
    favor: 2451,
    time: 34800,
    atk: 260,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 6,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: 'wizard',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 306,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'sirenshard',
    c1Qty: 5,
    c1Tags: null,
    component2: 'timecrystal',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-17',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'tempest',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 835,
    surcharge: 1850,
    suggest: 370,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: 'temple',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  winterring: {
    uid: 'winterring',
    level: 31,
    type: 'xr',
    xp: 56500,
    craftXp: 848,
    value: 690000,
    tradeMinMaxValue:
      '175000,220000,350000,520000,860000;8650000,11000000,17500000,26000000,43000000',
    favor: 2451,
    time: 30000,
    atk: 260,
    def: 65,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: 'wizard',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 306,
    resource2: 'steel',
    r2Qty: 87,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'tidal',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'sirenshard',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-17',
    supgrade2: 'resource3-5',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 855,
    surcharge: 1905,
    suggest: 380,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-12-02',
    shardPrice: 250,
    capriceDelay: 240,
  },
  bunnyring: {
    uid: 'bunnyring',
    level: 32,
    type: 'xr',
    xp: 61000,
    craftXp: 915,
    value: 695000,
    tradeMinMaxValue:
      '175000,220000,350000,520000,870000;8700000,11000000,17500000,26000000,43500000',
    favor: 2831,
    time: 42000,
    atk: 270,
    def: 0,
    hp: 17,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: 'wizard',
    w1BuildingReq: 26,
    w2BuildingReq: 22,
    w3BuildingReq: 19,
    resource1: 'iron',
    r1Qty: 322,
    resource2: 'steel',
    r2Qty: 93,
    resource3: 'gems',
    r3Qty: 29,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-64',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-19',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'rabbitspirit',
    tag: null,
    discount: 860,
    surcharge: 1910,
    suggest: 380,
    speedup: 2635,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-03-23',
    shardPrice: 250,
    capriceDelay: 0,
  },
  mysteriousring: {
    uid: 'mysteriousring',
    level: 33,
    type: 'xr',
    xp: 97500,
    craftXp: 1463,
    value: 1400000,
    tradeMinMaxValue:
      '350000,440000,700000,1050000,1750000;17500000,22000000,35000000,52500000,87500000',
    favor: 2889,
    time: 50400,
    atk: 325,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 338,
    resource2: 'steel',
    r2Qty: 98,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'bp:t12ring',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1045,
    surcharge: 2320,
    suggest: 465,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  candyring: {
    uid: 'candyring',
    level: 34,
    type: 'xr',
    xp: 105000,
    craftXp: 1575,
    value: 1700000,
    tradeMinMaxValue:
      '430000,530000,850000,1300000,2150000;21500000,26500000,42500000,64000000,106500000',
    favor: 3077,
    time: 60000,
    atk: 340,
    def: 85,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 319,
    resource2: 'oils',
    r2Qty: 73,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-64',
    upgrade3: 'value*1.25',
    upgrade4: 'component2-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1145,
    surcharge: 2545,
    suggest: 510,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-09-07',
    shardPrice: 300,
    capriceDelay: 240,
  },
  newyearring: {
    uid: 'newyearring',
    level: 34,
    type: 'xr',
    xp: 105000,
    craftXp: 1575,
    value: 1800000,
    tradeMinMaxValue:
      '450000,560000,900000,1350000,2250000;22500000,28000000,45000000,67500000,112500000',
    favor: 3671,
    time: 60000,
    atk: 340,
    def: 0,
    hp: 21,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 354,
    resource2: 'steel',
    r2Qty: 103,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'goldshell',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'resource1-71',
    upgrade2: 'resource2-31',
    upgrade3: 'time*0.8',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1175,
    surcharge: 2615,
    suggest: 525,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2021-12-29',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12ring: {
    uid: 't12ring',
    level: 36,
    type: 'xr',
    xp: 195000,
    craftXp: 2925,
    value: 2450000,
    tradeMinMaxValue:
      '610000,770000,1250000,1850000,3050000;30500000,38500000,61500000,92000000,153000000',
    favor: 4293,
    time: 85800,
    atk: 410,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: 'wizard',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'iron',
    r1Qty: 387,
    resource2: 'steel',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13ring',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-34',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1365,
    surcharge: 3030,
    suggest: 605,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  unicornring: {
    uid: 'unicornring',
    level: 37,
    type: 'xr',
    xp: 210000,
    craftXp: 3150,
    value: 2700000,
    tradeMinMaxValue:
      '680000,840000,1350000,2050000,3400000;34000000,42000000,67500000,101500000,169000000',
    favor: 5154,
    time: 102600,
    atk: 425,
    def: 106,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: 'wizard',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 404,
    resource2: 'steel',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 34,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-81',
    upgrade4: 'time*0.8',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'resource2-25',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1430,
    surcharge: 3175,
    suggest: 635,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-11-30',
    shardPrice: 350,
    capriceDelay: 0,
  },
  africanring: {
    uid: 'africanring',
    level: 37,
    type: 'xr',
    xp: 210000,
    craftXp: 3150,
    value: 2800000,
    tradeMinMaxValue:
      '700000,880000,1400000,2100000,3500000;35000000,44000000,70000000,105000000,175000000',
    favor: 5154,
    time: 102600,
    atk: 425,
    def: 0,
    hp: 29,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: 'wizard',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 404,
    resource2: 'steel',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 34,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-81',
    upgrade4: 'resource3-7',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'resource3-7',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1455,
    surcharge: 3235,
    suggest: 645,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-01-25',
    shardPrice: 350,
    capriceDelay: 0,
  },
  maplering: {
    uid: 'maplering',
    level: 38,
    type: 'xr',
    xp: 220000,
    craftXp: 3300,
    value: 2900000,
    tradeMinMaxValue:
      '730000,910000,1450000,2200000,3650000;36500000,45500000,72500000,109000000,181500000',
    favor: 5703,
    time: 122400,
    atk: 440,
    def: 0,
    hp: 30,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: 'cook',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'leather',
    r1Qty: 410,
    resource2: 'fabric',
    r2Qty: 125,
    resource3: 'mana',
    r3Qty: 38,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 12,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'resource2-25',
    upgrade2: 'component2-1',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-164',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1480,
    surcharge: 3290,
    suggest: 660,
    speedup: 3645,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-03-07',
    shardPrice: 350,
    capriceDelay: 0,
  },
  goldring2: {
    uid: 'goldring2',
    level: 38,
    type: 'xr',
    xp: 220000,
    craftXp: 3300,
    value: 5500000,
    tradeMinMaxValue:
      '1400000,1700000,2750000,4150000,6900000;69000000,86000000,137500000,206500000,344000000',
    favor: 6249,
    time: 122400,
    atk: 440,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: 'wizard',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'iron',
    r1Qty: 421,
    resource2: 'steel',
    r2Qty: 134,
    resource3: 'gems',
    r3Qty: 50,
    component1: 'tabletjewel',
    c1Qty: 5,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-84',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-20',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1510,
    surcharge: 3355,
    suggest: 670,
    speedup: 3645,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-12',
    shardPrice: 0,
    capriceDelay: 0,
  },
  t13ring: {
    uid: 't13ring',
    level: 39,
    type: 'xr',
    xp: 235000,
    craftXp: 3525,
    value: 5000000,
    tradeMinMaxValue:
      '1250000,1550000,2500000,3750000,6250000;62500000,78000000,125000000,187500000,312500000',
    favor: 6656,
    time: 146400,
    atk: 525,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'jeweler',
    worker2: 'master',
    worker3: 'wizard',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 438,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-88',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-8',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'resource2-8',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'hydra',
    tag: null,
    discount: 1920,
    surcharge: 4270,
    suggest: 855,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ironamulet: {
    uid: 'ironamulet',
    level: 4,
    type: 'xa',
    xp: 920,
    craftXp: 14,
    value: 550,
    tradeMinMaxValue: '140,170,280,410,690;6900,8600,14000,20500,34500',
    favor: 10,
    time: 110,
    atk: 0,
    def: 23,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 9,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 9,
    resource2: 'leather',
    r2Qty: 6,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-3',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-3',
    supgrade2: 'multi+0.3',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 20,
    surcharge: 40,
    suggest: 10,
    speedup: 55,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pendant: {
    uid: 'pendant',
    level: 6,
    type: 'xa',
    xp: 2050,
    craftXp: 31,
    value: 1050,
    tradeMinMaxValue: '260,330,530,790,1300;13000,16500,26500,39500,65500',
    favor: 14,
    time: 220,
    atk: 0,
    def: 35,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 9,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 12,
    resource2: 'leather',
    r2Qty: 8,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-4',
    upgrade3: 'time*0.75',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'resource1-4',
    supgrade2: 'multi+0.2',
    supgrade3: 'quality+2',
    su1Cost: 5,
    su2Cost: 5,
    su3Cost: 5,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 25,
    surcharge: 55,
    suggest: 10,
    speedup: 90,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  chain: {
    uid: 'chain',
    level: 8,
    type: 'xa',
    xp: 3800,
    craftXp: 57,
    value: 2450,
    tradeMinMaxValue: '610,770,1250,1850,3100;30500,38500,61500,92000,155000',
    favor: 20,
    time: 400,
    atk: 0,
    def: 49,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 16,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'redpinecone',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:sunpendant',
    upgrade4: 'resource1-3',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 85,
    suggest: 15,
    speedup: 135,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  mundraamulet: {
    uid: 'mundraamulet',
    level: 9,
    type: 'xa',
    xp: 4850,
    craftXp: 146,
    value: 5300,
    tradeMinMaxValue:
      '2700,3300,5300,8000,13500;135000,165000,265000,400000,665000',
    favor: 27,
    time: 520,
    atk: 14,
    def: 56,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'moondragon',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 19,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.8',
    upgrade3: 'bp:mundrabow',
    upgrade4: 'resource1-4',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: 'dragon',
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 55,
    surcharge: 125,
    suggest: 25,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  sunpendant: {
    uid: 'sunpendant',
    level: 11,
    type: 'xa',
    xp: 7450,
    craftXp: 112,
    value: 5950,
    tradeMinMaxValue:
      '1500,1850,3000,4500,7400;74500,93000,150000,225000,370000',
    favor: 37,
    time: 870,
    atk: 0,
    def: 72,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 8,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 26,
    resource2: 'leather',
    r2Qty: 17,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:noblechain',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 60,
    surcharge: 130,
    suggest: 25,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  grottoamulet: {
    uid: 'grottoamulet',
    level: 12,
    type: 'xa',
    xp: 9000,
    craftXp: 135,
    value: 8600,
    tradeMinMaxValue:
      '2400,2900,4700,7100,12000;120000,145000,235000,355000,590000',
    favor: 52,
    time: 1110,
    atk: 0,
    def: 80,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 4,
    combo: 8,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 31,
    resource2: 'leather',
    r2Qty: 20,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-6',
    upgrade4: 'resource2-4',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-10',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'corrupted',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 70,
    surcharge: 155,
    suggest: 30,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: 'grotto',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  medal: {
    uid: 'medal',
    level: 12,
    type: 'xa',
    xp: 9000,
    craftXp: 135,
    value: 9000,
    tradeMinMaxValue:
      '2300,2800,4500,6800,11500;115000,140000,225000,340000,565000',
    favor: 52,
    time: 1110,
    atk: 0,
    def: 80,
    hp: 5,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 31,
    resource2: 'leather',
    r2Qty: 20,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-9',
    upgrade4: 'resource2-4',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'eagle',
    tag: null,
    discount: 70,
    surcharge: 160,
    suggest: 30,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: true,
    nonCraftable: false,
    releaseAt: '2020-06-30',
    shardPrice: 100,
    capriceDelay: 0,
  },
  noblechain: {
    uid: 'noblechain',
    level: 13,
    type: 'xa',
    xp: 10500,
    craftXp: 158,
    value: 10000,
    tradeMinMaxValue:
      '2500,3100,5000,7500,12500;125000,155000,250000,375000,625000',
    favor: 89,
    time: 1380,
    atk: 0,
    def: 88,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 10,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 37,
    resource2: 'leather',
    r2Qty: 25,
    resource3: 'steel',
    r3Qty: 8,
    component1: 'icypearl',
    c1Qty: 2,
    c1Tags: null,
    component2: 'glowingmushrooms',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-7',
    upgrade3: 'bp:holysymbol',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-2',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 75,
    surcharge: 165,
    suggest: 35,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  stpatrickmedallion: {
    uid: 'stpatrickmedallion',
    level: 14,
    type: 'xa',
    xp: 12500,
    craftXp: 188,
    value: 19000,
    tradeMinMaxValue:
      '4800,5900,9500,14500,24000;240000,295000,475000,715000,1200000',
    favor: 122,
    time: 1740,
    atk: 24,
    def: 96,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 45,
    resource2: 'leather',
    r2Qty: 30,
    resource3: 'steel',
    r3Qty: 9,
    component1: 'livingroots',
    c1Qty: 2,
    c1Tags: null,
    component2: 'preciousgem',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-9',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-3',
    supgrade2: 'component2-3',
    supgrade3: 'multi+0.1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 100,
    surcharge: 225,
    suggest: 45,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-03-10',
    shardPrice: 120,
    capriceDelay: 240,
  },
  goldamulet: {
    uid: 'goldamulet',
    level: 16,
    type: 'xa',
    xp: 16500,
    craftXp: 248,
    value: 50000,
    tradeMinMaxValue:
      '12500,15500,25000,38000,63000;625000,780000,1250000,1900000,3150000',
    favor: 210,
    time: 2700,
    atk: 0,
    def: 115,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 5,
    combo: 8,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 64,
    resource2: 'leather',
    r2Qty: 43,
    resource3: 'steel',
    r3Qty: 15,
    component1: 'tabletfragment',
    c1Qty: 1,
    c1Tags: null,
    component2: 'glowingmushrooms',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-13',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-8',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'luxuriousspirit',
    tag: null,
    discount: 120,
    surcharge: 270,
    suggest: 55,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-12-21',
    shardPrice: 0,
    capriceDelay: 0,
  },
  holysymbol: {
    uid: 'holysymbol',
    level: 16,
    type: 'xa',
    xp: 16500,
    craftXp: 248,
    value: 29500,
    tradeMinMaxValue:
      '7400,9200,15000,22000,37000;370000,460000,740000,1100000,1850000',
    favor: 194,
    time: 1920,
    atk: 0,
    def: 115,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 64,
    resource2: 'leather',
    r2Qty: 43,
    resource3: 'steel',
    r3Qty: 15,
    component1: 'ironamulet',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'whitesand',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-13',
    upgrade3: 'bp:titaniumtorc',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'cquality1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 125,
    surcharge: 280,
    suggest: 55,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 17,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  oktoberamulet: {
    uid: 'oktoberamulet',
    level: 17,
    type: 'xa',
    xp: 19000,
    craftXp: 285,
    value: 40500,
    tradeMinMaxValue:
      '10000,12500,20000,30000,51000;505000,635000,1000000,1500000,2550000',
    favor: 269,
    time: 2400,
    atk: 29,
    def: 0,
    hp: 31,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 8,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 74,
    resource2: 'leather',
    r2Qty: 49,
    resource3: 'steel',
    r3Qty: 19,
    component1: 'mooncrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-15',
    upgrade3: 'time*0.9',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource3-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 125,
    surcharge: 325,
    suggest: 55,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-09-22',
    shardPrice: 140,
    capriceDelay: 240,
  },
  cincoamulet: {
    uid: 'cincoamulet',
    level: 18,
    type: 'xa',
    xp: 21500,
    craftXp: 323,
    value: 43000,
    tradeMinMaxValue:
      '11000,13500,22000,32000,54000;540000,670000,1100000,1600000,2700000',
    favor: 298,
    time: 3000,
    atk: 0,
    def: 115,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 8,
    worker1: 'priest',
    worker2: 'carpenter',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 79,
    resource2: 'herbs',
    r2Qty: 28,
    resource3: 'ironwood',
    r3Qty: 19,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: 'livingroots',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-16',
    upgrade3: 'component2-1',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-22',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 125,
    surcharge: 335,
    suggest: 55,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-05-03',
    shardPrice: 140,
    capriceDelay: 0,
  },
  titaniumtorc: {
    uid: 'titaniumtorc',
    level: 21,
    type: 'xa',
    xp: 30000,
    craftXp: 450,
    value: 78500,
    tradeMinMaxValue:
      '19500,25000,39000,59000,98000;980000,1250000,1950000,2950000,4900000',
    favor: 450,
    time: 7800,
    atk: 0,
    def: 160,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'iron',
    r1Qty: 116,
    resource2: 'steel',
    r2Qty: 29,
    resource3: 'oils',
    r3Qty: 20,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'bp:magatama',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 200,
    surcharge: 445,
    suggest: 90,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  timelocket: {
    uid: 'timelocket',
    level: 22,
    type: 'xa',
    xp: 33000,
    craftXp: 495,
    value: 115000,
    tradeMinMaxValue:
      '29000,36000,58000,86000,145000;1450000,1800000,2900000,4300000,7200000',
    favor: 578,
    time: 9600,
    atk: 43,
    def: 170,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'iron',
    r1Qty: 127,
    resource2: 'steel',
    r2Qty: 32,
    resource3: 'oils',
    r3Qty: 22,
    component1: 'silkscarab',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-25',
    upgrade3: 'value*1.25',
    upgrade4: 'time*0.9',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-6',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource3-4',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 240,
    surcharge: 535,
    suggest: 105,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2019-08-27',
    shardPrice: 160,
    capriceDelay: 240,
  },
  dreamamulet: {
    uid: 'dreamamulet',
    level: 22,
    type: 'xa',
    xp: 33000,
    craftXp: 495,
    value: 125000,
    tradeMinMaxValue:
      '31000,39000,63000,94000,155000;1550000,1950000,3150000,4700000,7800000',
    favor: 418,
    time: 9600,
    atk: 0,
    def: 170,
    hp: 11,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'carpenter',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 19,
    w2BuildingReq: 15,
    w3BuildingReq: 9,
    resource1: 'wood',
    r1Qty: 119,
    resource2: 'ironwood',
    r2Qty: 28,
    resource3: 'gems',
    r3Qty: 5,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-24',
    upgrade3: 'resource2-6',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.9',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 250,
    surcharge: 560,
    suggest: 110,
    speedup: 735,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-07-11',
    shardPrice: 160,
    capriceDelay: 0,
  },
  magatama: {
    uid: 'magatama',
    level: 24,
    type: 'xa',
    xp: 40000,
    craftXp: 600,
    value: 200000,
    tradeMinMaxValue:
      '50000,63000,100000,150000,250000;2500000,3150000,5000000,7500000,12500000',
    favor: 553,
    time: 14400,
    atk: 0,
    def: 190,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'iron',
    r1Qty: 149,
    resource2: 'steel',
    r2Qty: 39,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'laurels',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'timecrystal',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-30',
    upgrade3: 'bp:phylactery',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 315,
    surcharge: 700,
    suggest: 140,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  dragonamulet: {
    uid: 'dragonamulet',
    level: 25,
    type: 'xa',
    xp: 44000,
    craftXp: 660,
    value: 245000,
    tradeMinMaxValue:
      '61000,77000,125000,185000,310000;3050000,3850000,6150000,9200000,15500000',
    favor: 715,
    time: 17400,
    atk: 0,
    def: 200,
    hp: 12,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'iron',
    r1Qty: 160,
    resource2: 'steel',
    r2Qty: 42,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'ancientmarble',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-32',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-8',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 345,
    surcharge: 770,
    suggest: 155,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-12-29',
    shardPrice: 180,
    capriceDelay: 240,
  },
  archivistglasses: {
    uid: 'archivistglasses',
    level: 26,
    type: 'xa',
    xp: 47500,
    craftXp: 713,
    value: 260000,
    tradeMinMaxValue:
      '65000,81000,130000,195000,330000;3250000,4050000,6500000,9750000,16500000',
    favor: 739,
    time: 21000,
    atk: 35,
    def: 210,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 8,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 13,
    resource1: 'iron',
    r1Qty: 172,
    resource2: 'steel',
    r2Qty: 46,
    resource3: null,
    r3Qty: 0,
    component1: 'circlet',
    c1Qty: 2,
    c1Tags: 'common',
    component2: 'timecrystal',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-34',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-14',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 360,
    surcharge: 795,
    suggest: 160,
    speedup: 995,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  phylactery: {
    uid: 'phylactery',
    level: 27,
    type: 'xa',
    xp: 51500,
    craftXp: 773,
    value: 305000,
    tradeMinMaxValue:
      '76000,95000,155000,230000,380000;3800000,4750000,7650000,11500000,19000000',
    favor: 884,
    time: 18000,
    atk: 0,
    def: 220,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'iron',
    r1Qty: 183,
    resource2: 'steel',
    r2Qty: 50,
    resource3: 'gems',
    r3Qty: 13,
    component1: 'chain',
    c1Qty: 3,
    c1Tags: 'common',
    component2: 'ectoplasm',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-37',
    upgrade3: 'bp:alimyriad',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-2',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 385,
    surcharge: 860,
    suggest: 170,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  rudoamulet: {
    uid: 'rudoamulet',
    level: 28,
    type: 'xa',
    xp: 56000,
    craftXp: 840,
    value: 415000,
    tradeMinMaxValue:
      '105000,130000,210000,310000,520000;5200000,6500000,10500000,15500000,26000000',
    favor: 1061,
    time: 22200,
    atk: 58,
    def: 230,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'wood',
    r1Qty: 181,
    resource2: 'ironwood',
    r2Qty: 47,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'oakstaff',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'sewingthread',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-36',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-9',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'bear',
    tag: null,
    discount: 450,
    surcharge: 995,
    suggest: 200,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-04-21',
    shardPrice: 200,
    capriceDelay: 240,
  },
  spaceamulet: {
    uid: 'spaceamulet',
    level: 28,
    type: 'xa',
    xp: 56000,
    craftXp: 840,
    value: 315000,
    tradeMinMaxValue:
      '79000,98000,160000,240000,390000;3950000,4900000,7900000,12000000,19500000',
    favor: 1146,
    time: 22200,
    atk: 0,
    def: 230,
    hp: 16,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 9,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'iron',
    r1Qty: 194,
    resource2: 'steel',
    r2Qty: 54,
    resource3: 'gems',
    r3Qty: 16,
    component1: 'ectoplasm',
    c1Qty: 2,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-39',
    upgrade3: 'resource2-11',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 390,
    surcharge: 870,
    suggest: 175,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-16',
    shardPrice: 200,
    capriceDelay: 0,
  },
  alimyriad: {
    uid: 'alimyriad',
    level: 30,
    type: 'xa',
    xp: 65000,
    craftXp: 975,
    value: 610000,
    tradeMinMaxValue:
      '155000,190000,310000,460000,760000;7650000,9550000,15500000,23000000,38000000',
    favor: 1392,
    time: 32400,
    atk: 0,
    def: 250,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'wizard',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 218,
    resource2: 'steel',
    r2Qty: 62,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'goldshell',
    c1Qty: 5,
    c1Tags: null,
    component2: 'sharpfang',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-44',
    upgrade3: 'bp:brisingamen',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 810,
    surcharge: 1795,
    suggest: 360,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  priestamulet: {
    uid: 'priestamulet',
    level: 30,
    type: 'xa',
    xp: 65000,
    craftXp: 975,
    value: 630000,
    tradeMinMaxValue:
      '160000,195000,320000,470000,790000;7900000,9850000,16000000,23500000,39500000',
    favor: 1545,
    time: 32400,
    atk: 63,
    def: 250,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'wizard',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 218,
    resource2: 'steel',
    r2Qty: 62,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'sirenshard',
    c1Qty: 4,
    c1Tags: null,
    component2: 'silkscarab',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-44',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-12',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component2-2',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'light',
    spiritAffinity: null,
    tag: null,
    discount: 820,
    surcharge: 1825,
    suggest: 365,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2020-10-09',
    shardPrice: 250,
    capriceDelay: 0,
  },
  vipamulet: {
    uid: 'vipamulet',
    level: 31,
    type: 'xa',
    xp: 69500,
    craftXp: 1043,
    value: 650000,
    tradeMinMaxValue:
      '165000,200000,330000,490000,810000;8150000,10000000,16500000,24500000,40500000',
    favor: 1781,
    time: 38400,
    atk: 0,
    def: 260,
    hp: 16,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'wizard',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 230,
    resource2: 'steel',
    r2Qty: 66,
    resource3: 'gems',
    r3Qty: 25,
    component1: 'deepcoral',
    c1Qty: 4,
    c1Tags: null,
    component2: 'evileye',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-46',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-13',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 835,
    surcharge: 1850,
    suggest: 370,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-01-13',
    shardPrice: 250,
    capriceDelay: 0,
  },
  brisingamen: {
    uid: 'brisingamen',
    level: 33,
    type: 'xa',
    xp: 120000,
    craftXp: 1800,
    value: 1550000,
    tradeMinMaxValue:
      '390000,480000,780000,1150000,1950000;19500000,24000000,39000000,58000000,97000000',
    favor: 2111,
    time: 55200,
    atk: 0,
    def: 325,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 254,
    resource2: 'steel',
    r2Qty: 73,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-51',
    upgrade3: 'bp:t12amulet',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 1095,
    surcharge: 2435,
    suggest: 485,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  peakamulet: {
    uid: 'peakamulet',
    level: 33,
    type: 'xa',
    xp: 120000,
    craftXp: 1800,
    value: 1700000,
    tradeMinMaxValue:
      '430000,540000,860000,1300000,2150000;21500000,27000000,43000000,65000000,108000000',
    favor: 2340,
    time: 55200,
    atk: 0,
    def: 325,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'iron',
    r1Qty: 254,
    resource2: 'steel',
    r2Qty: 73,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-51',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-15',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: 'flood',
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: 'elemental',
    discount: 1145,
    surcharge: 2545,
    suggest: 510,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: 'peak',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  troblinamulet: {
    uid: 'troblinamulet',
    level: 34,
    type: 'xa',
    xp: 130000,
    craftXp: 1950,
    value: 1800000,
    tradeMinMaxValue:
      '450000,560000,900000,1350000,2250000;22500000,28000000,45000000,67500000,112500000',
    favor: 2397,
    time: 66000,
    atk: 85,
    def: 340,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'carpenter',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 248,
    resource2: 'ironwood',
    r2Qty: 67,
    resource3: 'mana',
    r3Qty: 30,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'livingroots',
    c2Qty: 10,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-50',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-13',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component2-4',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1175,
    surcharge: 2615,
    suggest: 525,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-07-14',
    shardPrice: 300,
    capriceDelay: 0,
  },
  atlab_amulet: {
    uid: 'atlab_amulet',
    level: 34,
    type: 'xa',
    xp: 130000,
    craftXp: 1950,
    value: 1650000,
    tradeMinMaxValue:
      '410000,520000,830000,1250000,2050000;20500000,26000000,41500000,62000000,103000000',
    favor: 2823,
    time: 61800,
    atk: 0,
    def: 340,
    hp: 21,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'carpenter',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'wood',
    r1Qty: 280,
    resource2: 'ironwood',
    r2Qty: 75,
    resource3: 'gems',
    r3Qty: 34,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'gale',
    c2Qty: 1,
    c2Tags: 'common',
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-84',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-23',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-14',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 1130,
    surcharge: 2510,
    suggest: 500,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-11-02',
    shardPrice: 300,
    capriceDelay: 0,
  },
  wiccanamulet: {
    uid: 'wiccanamulet',
    level: 35,
    type: 'xa',
    xp: 135000,
    craftXp: 2025,
    value: 1950000,
    tradeMinMaxValue:
      '490000,610000,980000,1450000,2450000;24500000,30500000,49000000,73000000,122000000',
    favor: 2929,
    time: 79200,
    atk: 88,
    def: 350,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'wizard',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'iron',
    r1Qty: 278,
    resource2: 'steel',
    r2Qty: 81,
    resource3: 'mana',
    r3Qty: 32,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-56',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-16',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1225,
    surcharge: 2720,
    suggest: 545,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-03-09',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12amulet: {
    uid: 't12amulet',
    level: 37,
    type: 'xa',
    xp: 255000,
    craftXp: 3825,
    value: 2300000,
    tradeMinMaxValue:
      '580000,720000,1150000,1750000,2900000;29000000,36000000,57500000,86500000,144000000',
    favor: 3609,
    time: 112800,
    atk: 0,
    def: 425,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'wizard',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 303,
    resource2: 'steel',
    r2Qty: 93,
    resource3: 'gems',
    r3Qty: 46,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-45',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13amulet',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'resource2-23',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1325,
    surcharge: 2940,
    suggest: 590,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldamulet2: {
    uid: 'goldamulet2',
    level: 37,
    type: 'xa',
    xp: 255000,
    craftXp: 3825,
    value: 4650000,
    tradeMinMaxValue:
      '1150000,1450000,2350000,3500000,5800000;58000000,72500000,116500000,174500000,290500000',
    favor: 3993,
    time: 112800,
    atk: 0,
    def: 425,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'wizard',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 303,
    resource2: 'steel',
    r2Qty: 93,
    resource3: 'gems',
    r3Qty: 46,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-61',
    upgrade3: 'resource2-19',
    upgrade4: 'time*0.8',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-9',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1395,
    surcharge: 3095,
    suggest: 620,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-05-13',
    shardPrice: 0,
    capriceDelay: 0,
  },
  arcadeamulet: {
    uid: 'arcadeamulet',
    level: 37,
    type: 'xa',
    xp: 255000,
    craftXp: 3825,
    value: 2650000,
    tradeMinMaxValue:
      '660000,830000,1350000,2000000,3300000;33000000,41500000,66500000,99500000,165500000',
    favor: 3823,
    time: 112800,
    atk: 0,
    def: 425,
    hp: 27,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'wizard',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'iron',
    r1Qty: 300,
    resource2: 'steel',
    r2Qty: 90,
    resource3: 'mana',
    r3Qty: 41,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-45',
    upgrade4: 'resource3-8',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-18',
    supgrade2: 'resource1-45',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1420,
    surcharge: 3150,
    suggest: 630,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-09-21',
    shardPrice: 350,
    capriceDelay: 0,
  },
  cyclopsamulet: {
    uid: 'cyclopsamulet',
    level: 37,
    type: 'xa',
    xp: 255000,
    craftXp: 3825,
    value: 2750000,
    tradeMinMaxValue:
      '690000,860000,1400000,2050000,3450000;34500000,43000000,69000000,103000000,172000000',
    favor: 3142,
    time: 250000,
    atk: 106,
    def: 425,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'wizard',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'herbs',
    r1Qty: 250,
    resource2: 'oils',
    r2Qty: 77,
    resource3: 'mana',
    r3Qty: 41,
    component1: 'gracesigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.9',
    upgrade2: 'time*0.8',
    upgrade3: 'time*0.65',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-12',
    supgrade2: 'resource3-6',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 1440,
    surcharge: 3205,
    suggest: 640,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-02-21',
    shardPrice: 350,
    capriceDelay: 0,
  },
  junimayamulet: {
    uid: 'junimayamulet',
    level: 38,
    type: 'xa',
    xp: 270000,
    craftXp: 4050,
    value: 3000000,
    tradeMinMaxValue:
      '750000,940000,1500000,2250000,3750000;37500000,47000000,75000000,112500000,187500000',
    favor: 4614,
    time: 134400,
    atk: 0,
    def: 440,
    hp: 27,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'wizard',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'herbs',
    r1Qty: 320,
    resource2: 'ironwood',
    r2Qty: 85,
    resource3: 'gems',
    r3Qty: 50,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-48',
    upgrade4: 'resource3-10',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'resource3-8',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: 'carbuncle',
    tag: null,
    discount: 1505,
    surcharge: 3340,
    suggest: 670,
    speedup: 3645,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-04-05',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13amulet: {
    uid: 't13amulet',
    level: 39,
    type: 'xa',
    xp: 285000,
    craftXp: 4275,
    value: 5400000,
    tradeMinMaxValue:
      '1350000,1700000,2700000,4050000,6750000;67500000,84500000,135000000,202500000,337500000',
    favor: 4935,
    time: 160800,
    atk: 0,
    def: 525,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'wizard',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 329,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'sparksigil',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-49',
    upgrade3: 'resource2-8',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 1995,
    surcharge: 4430,
    suggest: 885,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  platinumamulet: {
    uid: 'platinumamulet',
    level: 39,
    type: 'xa',
    xp: 285000,
    craftXp: 4275,
    value: 14250000,
    tradeMinMaxValue:
      '3550000,4450000,7150000,10700000,17800000;178000000,222500000,356500000,534500000,890500000',
    favor: 5116,
    time: 160800,
    atk: 131,
    def: 525,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 13,
    combo: 0,
    worker1: 'priest',
    worker2: 'jeweler',
    worker3: 'wizard',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'iron',
    r1Qty: 329,
    resource2: 'steel',
    r2Qty: 104,
    resource3: 'gems',
    r3Qty: 52,
    component1: 'platinumjewel',
    c1Qty: 4,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-49',
    upgrade3: 'resource3-10',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component1-1',
    supgrade2: 'resource2-21',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 2385,
    surcharge: 5295,
    suggest: 1060,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'pMetal',
    unlock: 0,
    chest: 'goldcity3',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-08-04',
    shardPrice: 0,
    capriceDelay: 0,
  },
  troblin: {
    uid: 'troblin',
    level: 7,
    type: 'xf',
    xp: 4050,
    craftXp: 61,
    value: 3800,
    tradeMinMaxValue: '950,1200,1900,2900,4800;47500,59500,95000,145000,240000',
    favor: 29,
    time: 350,
    atk: 82,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'elven',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 21,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'club',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'redpinecone',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-4',
    upgrade3: 'quality+1',
    upgrade4: 'bp:yeti',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 45,
    surcharge: 105,
    suggest: 20,
    speedup: 110,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  yeti: {
    uid: 'yeti',
    level: 10,
    type: 'xf',
    xp: 8700,
    craftXp: 131,
    value: 9650,
    tradeMinMaxValue:
      '2400,3000,4800,7200,12000;120000,150000,240000,360000,605000',
    favor: 59,
    time: 810,
    atk: 125,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 9,
    worker1: 'elven',
    worker2: null,
    worker3: null,
    w1BuildingReq: 4,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 37,
    resource2: 'leather',
    r2Qty: 15,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 3,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'resource2-3',
    upgrade3: 'resource1-7',
    upgrade4: 'bp:toadwitch',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'time*0.75',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 75,
    surcharge: 165,
    suggest: 35,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  toadwitch: {
    uid: 'toadwitch',
    level: 13,
    type: 'xf',
    xp: 15500,
    craftXp: 233,
    value: 21500,
    tradeMinMaxValue:
      '5400,6700,11000,16000,27000;270000,335000,540000,805000,1350000',
    favor: 220,
    time: 1650,
    atk: 175,
    def: 175,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'elven',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 62,
    resource2: 'leather',
    r2Qty: 25,
    resource3: 'iron',
    r3Qty: 25,
    component1: 'livingroots',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'resource3-25',
    upgrade2: 'resource1-12',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:mushgoon',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 110,
    surcharge: 240,
    suggest: 50,
    speedup: 295,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  mushgoon: {
    uid: 'mushgoon',
    level: 16,
    type: 'xf',
    xp: 24000,
    craftXp: 360,
    value: 48500,
    tradeMinMaxValue:
      '12000,15000,24000,36000,61000;605000,760000,1200000,1800000,3050000',
    favor: 250,
    time: 3300,
    atk: 230,
    def: 0,
    hp: 23,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 8,
    worker1: 'elven',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 106,
    resource2: 'oils',
    r2Qty: 26,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 4,
    c1Tags: null,
    component2: 'glowingmushrooms',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'resource1-21',
    upgrade2: 'resource2-8',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:anubis',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 160,
    surcharge: 355,
    suggest: 70,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 30,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  anubis: {
    uid: 'anubis',
    level: 21,
    type: 'xf',
    xp: 43000,
    craftXp: 645,
    value: 160000,
    tradeMinMaxValue:
      '40000,50000,80000,120000,200000;2000000,2500000,4000000,6000000,10000000',
    favor: 658,
    time: 9000,
    atk: 0,
    def: 320,
    hp: 32,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'elven',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'herbs',
    r1Qty: 194,
    resource2: 'oils',
    r2Qty: 49,
    resource3: null,
    r3Qty: 0,
    component1: 'silkscarab',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'resource1-39',
    upgrade2: 'resource2-15',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:cyclops',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-10',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: 'wolf',
    tag: null,
    discount: 285,
    surcharge: 630,
    suggest: 125,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 40,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  cyclops: {
    uid: 'cyclops',
    level: 24,
    type: 'xf',
    xp: 57500,
    craftXp: 863,
    value: 260000,
    tradeMinMaxValue:
      '65000,81000,130000,195000,330000;3250000,4050000,6500000,9750000,16500000',
    favor: 1121,
    time: 16800,
    atk: 0,
    def: 0,
    hp: 38,
    eva: '0.10',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 0,
    worker1: 'elven',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'herbs',
    r1Qty: 249,
    resource2: 'oils',
    r2Qty: 64,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'circlet',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'timecrystal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'resource1-50',
    upgrade2: 'resource2-19',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:tyrantking',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: 'crab',
    tag: null,
    discount: 360,
    surcharge: 795,
    suggest: 160,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  tyrantking: {
    uid: 'tyrantking',
    level: 27,
    type: 'xf',
    xp: 74000,
    craftXp: 1110,
    value: 380000,
    tradeMinMaxValue:
      '95000,120000,190000,290000,480000;4750000,5950000,9500000,14500000,24000000',
    favor: 1773,
    time: 22200,
    atk: 0,
    def: 445,
    hp: 45,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 0,
    worker1: 'elven',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 22,
    w2BuildingReq: 18,
    w3BuildingReq: 15,
    resource1: 'herbs',
    r1Qty: 305,
    resource2: 'oils',
    r2Qty: 84,
    resource3: 'gems',
    r3Qty: 13,
    component1: 'ectoplasm',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'resource1-61',
    upgrade2: 'resource2-25',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:mermaid',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 430,
    surcharge: 955,
    suggest: 190,
    speedup: 1065,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  mermaid: {
    uid: 'mermaid',
    level: 30,
    type: 'xf',
    xp: 93500,
    craftXp: 1403,
    value: 855000,
    tradeMinMaxValue:
      '210000,270000,430000,640000,1050000;10500000,13500000,21500000,32000000,53500000',
    favor: 2759,
    time: 37200,
    atk: 510,
    def: 510,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 0,
    worker1: 'elven',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 363,
    resource2: 'oils',
    r2Qty: 103,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'trident',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'sirenshard',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'resource1-73',
    upgrade2: 'resource2-31',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:harpy',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'cquality1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 950,
    surcharge: 2110,
    suggest: 420,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 80,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  harpy: {
    uid: 'harpy',
    level: 33,
    type: 'xf',
    xp: 170000,
    craftXp: 2550,
    value: 2300000,
    tradeMinMaxValue:
      '580000,720000,1150000,1750000,2900000;29000000,36000000,57500000,86500000,144000000',
    favor: 4172,
    time: 65400,
    atk: 660,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0.10',
    excl: 'pack',
    tier: 11,
    combo: 0,
    worker1: 'elven',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 423,
    resource2: 'oils',
    r2Qty: 122,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'gracesigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'resource1-85',
    upgrade2: 'resource2-37',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:firecrab',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'air',
    spiritAffinity: null,
    tag: null,
    discount: 1325,
    surcharge: 2940,
    suggest: 590,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 100,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldgolem: {
    uid: 'goldgolem',
    level: 33,
    type: 'xf',
    xp: 170000,
    craftXp: 2550,
    value: 4400000,
    tradeMinMaxValue:
      '1100000,1400000,2200000,3300000,5500000;55000000,69000000,110000000,165000000,275000000',
    favor: 4172,
    time: 65400,
    atk: 660,
    def: 0,
    hp: 66,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 11,
    combo: 0,
    worker1: 'elven',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 423,
    resource2: 'oils',
    r2Qty: 122,
    resource3: 'gems',
    r3Qty: 31,
    component1: 'tabletjewel',
    c1Qty: 5,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'resource1-85',
    upgrade2: 'resource2-37',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-3',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1355,
    surcharge: 3010,
    suggest: 600,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'hMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-12-24',
    shardPrice: 0,
    capriceDelay: 0,
  },
  firecrab: {
    uid: 'firecrab',
    level: 36,
    type: 'xf',
    xp: 345000,
    craftXp: 5175,
    value: 3500000,
    tradeMinMaxValue:
      '880000,1100000,1750000,2650000,4400000;44000000,54500000,87500000,131500000,219000000',
    favor: 6199,
    time: 111600,
    atk: 0,
    def: 835,
    hp: 84,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 0,
    worker1: 'elven',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'herbs',
    r1Qty: 484,
    resource2: 'oils',
    r2Qty: 141,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'resource1-97',
    upgrade2: 'time*0.75',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:voidling',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-12',
    supgrade2: 'resource2-42',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 1620,
    surcharge: 3600,
    suggest: 720,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 150,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  voidling: {
    uid: 'voidling',
    level: 39,
    type: 'xf',
    xp: 410000,
    craftXp: 6150,
    value: 7450000,
    tradeMinMaxValue:
      '1850000,2350000,3750000,5600000,9300000;93000000,116500000,186500000,279500000,465500000',
    favor: 9967,
    time: 190200,
    atk: 1070,
    def: 1070,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 0,
    worker1: 'elven',
    worker2: 'wizard',
    worker3: 'priest',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'herbs',
    r1Qty: 548,
    resource2: 'gems',
    r2Qty: 52,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voidcrystal',
    c1Qty: 2,
    c1Tags: null,
    component2: 'voideye',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'resource1-110',
    upgrade2: 'time*0.75',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-10',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-2',
    supgrade2: 'resource2-10',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 2325,
    surcharge: 5170,
    suggest: 1035,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 250,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  baconeggs: {
    uid: 'baconeggs',
    level: 9,
    type: 'fm',
    xp: 4850,
    craftXp: 146,
    value: 2400,
    tradeMinMaxValue: '600,750,1200,1800,3000;30000,37500,60000,90000,150000',
    favor: 36,
    time: 520,
    atk: 24,
    def: 24,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 9,
    worker1: 'cook',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 27,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'resource1-5',
    upgrade3: 'bp:baobun',
    upgrade4: 'component1-1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'quality+2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 40,
    surcharge: 85,
    suggest: 15,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  baobun: {
    uid: 'baobun',
    level: 11,
    type: 'fm',
    xp: 7450,
    craftXp: 224,
    value: 4500,
    tradeMinMaxValue:
      '1150,1400,2300,3400,5600;56500,70500,115000,170000,280000',
    favor: 53,
    time: 870,
    atk: 31,
    def: 31,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 8,
    worker1: 'cook',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 36,
    resource2: 'wood',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 3,
    c1Tags: null,
    component2: 'elvenwood',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'resource2-18',
    upgrade2: 'value*1.25',
    upgrade3: 'bp:poutine',
    upgrade4: 'resource1-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 50,
    surcharge: 115,
    suggest: 25,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  spacemeal: {
    uid: 'spacemeal',
    level: 12,
    type: 'fm',
    xp: 9000,
    craftXp: 270,
    value: 7600,
    tradeMinMaxValue:
      '1900,2400,3800,5700,9500;95000,120000,190000,285000,475000',
    favor: 75,
    time: 1110,
    atk: 37,
    def: 34,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 4,
    combo: 8,
    worker1: 'cook',
    worker2: null,
    worker3: null,
    w1BuildingReq: 9,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 43,
    resource2: 'wood',
    r2Qty: 43,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 4,
    c1Tags: null,
    component2: 'glowingmushrooms',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'resource2-22',
    upgrade2: 'value*1.25',
    upgrade3: 'resource1-9',
    upgrade4: 'quality+1',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 65,
    surcharge: 145,
    suggest: 30,
    speedup: 260,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-16',
    shardPrice: 100,
    capriceDelay: 0,
  },
  poutine: {
    uid: 'poutine',
    level: 14,
    type: 'fm',
    xp: 12500,
    craftXp: 376,
    value: 10500,
    tradeMinMaxValue:
      '2600,3300,5300,7900,13000;130000,165000,265000,395000,655000',
    favor: 110,
    time: 1740,
    atk: 41,
    def: 41,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 8,
    worker1: 'cook',
    worker2: null,
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 62,
    resource2: 'oils',
    r2Qty: 13,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'bp:burger',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 75,
    surcharge: 170,
    suggest: 35,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Paper',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  maplemeal: {
    uid: 'maplemeal',
    level: 15,
    type: 'fm',
    xp: 14500,
    craftXp: 436,
    value: 10500,
    tradeMinMaxValue:
      '3200,3900,6300,9500,16000;160000,195000,315000,475000,790000',
    favor: 160,
    time: 2160,
    atk: 44,
    def: 66,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'cook',
    worker2: null,
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 75,
    resource2: 'oils',
    r2Qty: 17,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 8,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-15',
    upgrade3: 'value*1.5',
    upgrade4: 'resource1-15',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 75,
    surcharge: 170,
    suggest: 35,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-03-07',
    shardPrice: 120,
    capriceDelay: 0,
  },
  patechinois: {
    uid: 'patechinois',
    level: 15,
    type: 'fm',
    xp: 14500,
    craftXp: 436,
    value: 11000,
    tradeMinMaxValue:
      '3300,4100,6600,9900,16500;165000,205000,330000,495000,825000',
    favor: 150,
    time: 2160,
    atk: 66,
    def: 44,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 5,
    combo: 8,
    worker1: 'cook',
    worker2: null,
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 71,
    resource2: 'oils',
    r2Qty: 15,
    resource3: null,
    r3Qty: 0,
    component1: 'arcanedust',
    c1Qty: 6,
    c1Tags: null,
    component2: 'glowingmushrooms',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'resource1-14',
    upgrade2: 'time*0.75',
    upgrade3: 'value*1.5',
    upgrade4: 'resource1-14',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 80,
    surcharge: 175,
    suggest: 35,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-06-23',
    shardPrice: 120,
    capriceDelay: 0,
  },
  burger: {
    uid: 'burger',
    level: 17,
    type: 'fm',
    xp: 19000,
    craftXp: 570,
    value: 25500,
    tradeMinMaxValue:
      '6400,8000,13000,19000,32000;320000,400000,640000,955000,1600000',
    favor: 229,
    time: 3000,
    atk: 53,
    def: 53,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 8,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'herbs',
    r1Qty: 104,
    resource2: 'oils',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'baconeggs',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'icypearl',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'bp:ahipoke',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource1-31',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource2-5',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 115,
    surcharge: 260,
    suggest: 50,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 18,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pizza: {
    uid: 'pizza',
    level: 18,
    type: 'fm',
    xp: 21500,
    craftXp: 646,
    value: 30500,
    tradeMinMaxValue:
      '7600,9500,15500,23000,38000;380000,475000,765000,1150000,1900000',
    favor: 312,
    time: 4200,
    atk: 83,
    def: 55,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'herbs',
    r1Qty: 118,
    resource2: 'oils',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'livingroots',
    c1Qty: 6,
    c1Tags: null,
    component2: 'elvenwood',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 12,
    u2Req: 30,
    u3Req: 54,
    u4Req: 85,
    u5Req: 135,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-24',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-15',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 130,
    surcharge: 285,
    suggest: 55,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-11-25',
    shardPrice: 140,
    capriceDelay: 0,
  },
  arcademeal: {
    uid: 'arcademeal',
    level: 18,
    type: 'fm',
    xp: 21500,
    craftXp: 646,
    value: 31000,
    tradeMinMaxValue:
      '7800,9700,15500,23000,39000;390000,485000,775000,1150000,1950000',
    favor: 293,
    time: 4200,
    atk: 55,
    def: 83,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'herbs',
    r1Qty: 112,
    resource2: 'oils',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 5,
    c1Tags: null,
    component2: 'glowingmushrooms',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 12,
    u2Req: 30,
    u3Req: 54,
    u4Req: 85,
    u5Req: 135,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-22',
    upgrade3: 'component1-1',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'resource2-15',
    supgrade3: 'multi+0.1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 130,
    surcharge: 285,
    suggest: 55,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-05-16',
    shardPrice: 140,
    capriceDelay: 0,
  },
  ahipoke: {
    uid: 'ahipoke',
    level: 20,
    type: 'fm',
    xp: 27000,
    craftXp: 810,
    value: 77500,
    tradeMinMaxValue:
      '19500,24000,39000,58000,97000;970000,1200000,1950000,2900000,4850000',
    favor: 402,
    time: 6600,
    atk: 63,
    def: 63,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 7,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'herbs',
    r1Qty: 148,
    resource2: 'oils',
    r2Qty: 37,
    resource3: null,
    r3Qty: 0,
    component1: 'evileye',
    c1Qty: 5,
    c1Tags: null,
    component2: 'glowingmushrooms',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-30',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:sushi',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-4',
    supgrade2: 'resource2-7',
    supgrade3: 'multi+0.1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 200,
    surcharge: 445,
    suggest: 90,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  newyearmeal: {
    uid: 'newyearmeal',
    level: 21,
    type: 'fm',
    xp: 30000,
    craftXp: 900,
    value: 90000,
    tradeMinMaxValue:
      '23000,28000,45000,68000,115000;1150000,1400000,2250000,3400000,5650000',
    favor: 533,
    time: 7800,
    atk: 68,
    def: 101,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'herbs',
    r1Qty: 163,
    resource2: 'oils',
    r2Qty: 40,
    resource3: null,
    r3Qty: 0,
    component1: 'antidote',
    c1Qty: 3,
    c1Tags: 'common',
    component2: 'sharpfang',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'component1-1',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-33',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'resource2-20',
    supgrade3: 'multi+0.1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 215,
    surcharge: 480,
    suggest: 95,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-12-27',
    shardPrice: 160,
    capriceDelay: 0,
  },
  sushi: {
    uid: 'sushi',
    level: 24,
    type: 'fm',
    xp: 40000,
    craftXp: 1200,
    value: 195000,
    tradeMinMaxValue:
      '49000,61000,98000,145000,240000;2450000,3050000,4900000,7300000,12000000',
    favor: 807,
    time: 14400,
    atk: 80,
    def: 80,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 7,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'herbs',
    r1Qty: 209,
    resource2: 'oils',
    r2Qty: 52,
    resource3: 'mana',
    r3Qty: 6,
    component1: 'timecrystal',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-42',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:coconutcurry',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-10',
    supgrade2: 'resource3-2',
    supgrade3: 'multi+0.1',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 310,
    surcharge: 690,
    suggest: 140,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  coconutcurry: {
    uid: 'coconutcurry',
    level: 28,
    type: 'fm',
    xp: 56000,
    craftXp: 1680,
    value: 315000,
    tradeMinMaxValue:
      '79000,98000,160000,240000,390000;3950000,4900000,7900000,12000000,19500000',
    favor: 1469,
    time: 19200,
    atk: 95,
    def: 95,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 6,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'herbs',
    r1Qty: 272,
    resource2: 'oils',
    r2Qty: 73,
    resource3: 'mana',
    r3Qty: 13,
    component1: 'bloodvine',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'ectoplasm',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'bp:kebabs',
    upgrade4: 'resource1-54',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'cquality1-1',
    supgrade2: 'resource3-3',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 390,
    surcharge: 870,
    suggest: 175,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  kebabs: {
    uid: 'kebabs',
    level: 31,
    type: 'fm',
    xp: 69500,
    craftXp: 2086,
    value: 670000,
    tradeMinMaxValue:
      '170000,210000,340000,500000,840000;8400000,10500000,17000000,25000000,42000000',
    favor: 2240,
    time: 38400,
    atk: 110,
    def: 110,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 6,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: 'wizard',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 321,
    resource2: 'oils',
    r2Qty: 89,
    resource3: 'mana',
    r3Qty: 20,
    component1: 'grass',
    c1Qty: 2,
    c1Tags: 'common',
    component2: 'deepcoral',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'component1-1',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:ramen',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-96',
    supgrade2: 'resource2-27',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 845,
    surcharge: 1880,
    suggest: 375,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ramen: {
    uid: 'ramen',
    level: 33,
    type: 'fm',
    xp: 120000,
    craftXp: 3600,
    value: 1400000,
    tradeMinMaxValue:
      '350000,440000,700000,1050000,1750000;17500000,22000000,35000000,52500000,87500000',
    favor: 2937,
    time: 55200,
    atk: 135,
    def: 135,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 5,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 355,
    resource2: 'oils',
    r2Qty: 100,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'mightsigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-71',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t12meal',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-20',
    supgrade2: 'resource3-5',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1045,
    surcharge: 2320,
    suggest: 465,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Glass',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lunarmeal: {
    uid: 'lunarmeal',
    level: 34,
    type: 'fm',
    xp: 130000,
    craftXp: 3900,
    value: 1600000,
    tradeMinMaxValue:
      '400000,500000,800000,1200000,2000000;20000000,25000000,40000000,60000000,100000000',
    favor: 3734,
    time: 66000,
    atk: 210,
    def: 140,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 11,
    combo: 5,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: 'wizard',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 372,
    resource2: 'oils',
    r2Qty: 105,
    resource3: 'mana',
    r3Qty: 27,
    component1: 'deepcoral',
    c1Qty: 5,
    c1Tags: null,
    component2: 'sirenshard',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-112',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-32',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component1-1',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 1110,
    surcharge: 2470,
    suggest: 495,
    speedup: 2950,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-01-10',
    shardPrice: 300,
    capriceDelay: 0,
  },
  t12meal: {
    uid: 't12meal',
    level: 36,
    type: 'fm',
    xp: 240000,
    craftXp: 7200,
    value: 2850000,
    tradeMinMaxValue:
      '710000,890000,1450000,2150000,3550000;35500000,44500000,71500000,107000000,178000000',
    favor: 4343,
    time: 94200,
    atk: 173,
    def: 173,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 5,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: 'wizard',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'herbs',
    r1Qty: 407,
    resource2: 'oils',
    r2Qty: 115,
    resource3: 'mana',
    r3Qty: 33,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-81',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13meal',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-29',
    supgrade2: 'resource3-7',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1465,
    surcharge: 3260,
    suggest: 650,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldmeal: {
    uid: 'goldmeal',
    level: 36,
    type: 'fm',
    xp: 240000,
    craftXp: 7200,
    value: 5650000,
    tradeMinMaxValue:
      '1400000,1750000,2850000,4250000,7050000;70500000,88500000,141500000,212000000,353000000',
    favor: 4859,
    time: 94200,
    atk: 173,
    def: 173,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: 'wizard',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'herbs',
    r1Qty: 407,
    resource2: 'oils',
    r2Qty: 115,
    resource3: 'mana',
    r3Qty: 33,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-81',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-23',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1530,
    surcharge: 3400,
    suggest: 680,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-11-25',
    shardPrice: 0,
    capriceDelay: 0,
  },
  t13meal: {
    uid: 't13meal',
    level: 39,
    type: 'fm',
    xp: 285000,
    craftXp: 8550,
    value: 5450000,
    tradeMinMaxValue:
      '1350000,1700000,2750000,4100000,6800000;68000000,85000000,136500000,204500000,340500000',
    favor: 7020,
    time: 160800,
    atk: 218,
    def: 218,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 13,
    combo: 5,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: 'wizard',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'herbs',
    r1Qty: 460,
    resource2: 'mana',
    r2Qty: 42,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voideye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-92',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-6',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-6',
    supgrade2: 'resource3-1',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: 'kraken',
    tag: null,
    discount: 2005,
    surcharge: 4450,
    suggest: 890,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Vegetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  firecrabmeal: {
    uid: 'firecrabmeal',
    level: 39,
    type: 'fm',
    xp: 285000,
    craftXp: 8550,
    value: 5650000,
    tradeMinMaxValue:
      '1400000,1750000,2850000,4250000,7050000;70500000,88500000,141500000,212000000,353000000',
    favor: 7799,
    time: 160800,
    atk: 218,
    def: 326,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'cook',
    worker2: 'herbalist',
    worker3: 'wizard',
    w1BuildingReq: 32,
    w2BuildingReq: 28,
    w3BuildingReq: 25,
    resource1: 'herbs',
    r1Qty: 460,
    resource2: 'mana',
    r2Qty: 36,
    resource3: 'essence',
    r3Qty: 5,
    component1: 'voidcrystal',
    c1Qty: 3,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-5',
    upgrade3: 'value*1.25',
    upgrade4: 'resource3-1',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component1-1',
    supgrade2: 'resource1-92',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'fire',
    spiritAffinity: null,
    tag: null,
    discount: 2040,
    surcharge: 4530,
    suggest: 905,
    speedup: 3830,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-07-26',
    shardPrice: 375,
    capriceDelay: 0,
  },
  cookies: {
    uid: 'cookies',
    level: 9,
    type: 'fd',
    xp: 4200,
    craftXp: 126,
    value: 3000,
    tradeMinMaxValue: '750,940,1500,2300,3800;37500,47000,75000,115000,190000',
    favor: 39,
    time: 600,
    atk: 0,
    def: 0,
    hp: 16,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 3,
    combo: 9,
    worker1: 'baker',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 26,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'glowingmushrooms',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:shavedice',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+2',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 45,
    surcharge: 95,
    suggest: 20,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: true,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  shavedice: {
    uid: 'shavedice',
    level: 11,
    type: 'fd',
    xp: 6450,
    craftXp: 194,
    value: 7200,
    tradeMinMaxValue:
      '1800,2300,3600,5400,9000;90000,115000,180000,270000,450000',
    favor: 56,
    time: 990,
    atk: 0,
    def: 0,
    hp: 20,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 8,
    worker1: 'baker',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 34,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'ironcap',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'preciousgem',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 8,
    u2Req: 20,
    u3Req: 36,
    u4Req: 55,
    u5Req: 85,
    upgrade1: 'value*1.25',
    upgrade2: 'time*0.75',
    upgrade3: 'bp:taiyaki',
    upgrade4: 'resource1-7',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+2',
    su1Cost: 15,
    su2Cost: 15,
    su3Cost: 15,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: null,
    tag: null,
    discount: 65,
    surcharge: 140,
    suggest: 30,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 6,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  taiyaki: {
    uid: 'taiyaki',
    level: 14,
    type: 'fd',
    xp: 11000,
    craftXp: 330,
    value: 16500,
    tradeMinMaxValue:
      '4100,5200,8300,12500,21000;205000,260000,415000,620000,1050000',
    favor: 119,
    time: 2040,
    atk: 0,
    def: 0,
    hp: 27,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 5,
    combo: 8,
    worker1: 'baker',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 11,
    w2BuildingReq: 4,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 60,
    resource2: 'oils',
    r2Qty: 12,
    resource3: null,
    r3Qty: 0,
    component1: 'icypearl',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-12',
    upgrade3: 'bp:cheesecake',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 95,
    surcharge: 210,
    suggest: 40,
    speedup: 335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 10,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  cheesecake: {
    uid: 'cheesecake',
    level: 17,
    type: 'fd',
    xp: 16500,
    craftXp: 496,
    value: 55000,
    tradeMinMaxValue:
      '14000,17000,28000,41000,69000;690000,860000,1400000,2050000,3450000',
    favor: 242,
    time: 3600,
    atk: 0,
    def: 0,
    hp: 34,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 8,
    worker1: 'baker',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'herbs',
    r1Qty: 99,
    resource2: 'oils',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'mooncrystal',
    c1Qty: 4,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-20',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:macaron',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-5',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-1',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 170,
    surcharge: 375,
    suggest: 75,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 18,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  valentinedessert: {
    uid: 'valentinedessert',
    level: 18,
    type: 'fd',
    xp: 18500,
    craftXp: 556,
    value: 70000,
    tradeMinMaxValue:
      '17500,22000,35000,53000,88000;875000,1100000,1750000,2650000,4400000',
    favor: 296,
    time: 4800,
    atk: 0,
    def: 42,
    hp: 37,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 6,
    combo: 7,
    worker1: 'baker',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 15,
    w2BuildingReq: 11,
    w3BuildingReq: 5,
    resource1: 'herbs',
    r1Qty: 113,
    resource2: 'oils',
    r2Qty: 29,
    resource3: null,
    r3Qty: 0,
    component1: 'whitesand',
    c1Qty: 7,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-23',
    upgrade3: 'value*1.25',
    upgrade4: 'component1-1',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'resource1-34',
    su1Cost: 25,
    su2Cost: 25,
    su3Cost: 25,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 190,
    surcharge: 425,
    suggest: 85,
    speedup: 515,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-02-08',
    shardPrice: 140,
    capriceDelay: 0,
  },
  macaron: {
    uid: 'macaron',
    level: 20,
    type: 'fd',
    xp: 23500,
    craftXp: 706,
    value: 99000,
    tradeMinMaxValue:
      '25000,31000,50000,74000,125000;1250000,1550000,2500000,3700000,6200000',
    favor: 425,
    time: 7200,
    atk: 0,
    def: 0,
    hp: 42,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 7,
    worker1: 'baker',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'herbs',
    r1Qty: 141,
    resource2: 'oils',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'silkscarab',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'bp:daifuku',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'resource2-7',
    supgrade2: 'multi+0.1',
    supgrade3: 'component1-1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 225,
    surcharge: 500,
    suggest: 100,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 25,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  daifuku: {
    uid: 'daifuku',
    level: 24,
    type: 'fd',
    xp: 34500,
    craftXp: 1036,
    value: 245000,
    tradeMinMaxValue:
      '61000,77000,125000,185000,310000;3050000,3850000,6150000,9200000,15500000',
    favor: 798,
    time: 16200,
    atk: 0,
    def: 0,
    hp: 53,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 8,
    combo: 7,
    worker1: 'baker',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'herbs',
    r1Qty: 199,
    resource2: 'oils',
    r2Qty: 51,
    resource3: null,
    r3Qty: 0,
    component1: 'ancientmarble',
    c1Qty: 6,
    c1Tags: null,
    component2: 'whitesand',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'resource1-40',
    upgrade2: 'component2-1',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:spiralcake',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-15',
    supgrade2: 'multi+0.1',
    supgrade3: 'component2-2',
    su1Cost: 35,
    su2Cost: 35,
    su3Cost: 35,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 345,
    surcharge: 770,
    suggest: 155,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 35,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  spiralcake: {
    uid: 'spiralcake',
    level: 28,
    type: 'fd',
    xp: 48500,
    craftXp: 1456,
    value: 350000,
    tradeMinMaxValue:
      '88000,110000,175000,260000,440000;4400000,5450000,8750000,13000000,22000000',
    favor: 1589,
    time: 25200,
    atk: 0,
    def: 0,
    hp: 64,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 6,
    worker1: 'baker',
    worker2: 'herbalist',
    worker3: null,
    w1BuildingReq: 23,
    w2BuildingReq: 19,
    w3BuildingReq: 16,
    resource1: 'herbs',
    r1Qty: 259,
    resource2: 'oils',
    r2Qty: 72,
    resource3: 'mana',
    r3Qty: 16,
    component1: 'laurels',
    c1Qty: 1,
    c1Tags: 'uncommon',
    component2: 'overgrownvine',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'value*1.25',
    upgrade3: 'bp:pineapplecake',
    upgrade4: 'resource1-52',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-3',
    supgrade2: 'cquality1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'earth',
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 915,
    suggest: 185,
    speedup: 1140,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Wood',
    unlock: 45,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  pineapplecake: {
    uid: 'pineapplecake',
    level: 31,
    type: 'fd',
    xp: 60500,
    craftXp: 1816,
    value: 735000,
    tradeMinMaxValue:
      '185000,230000,370000,550000,920000;9200000,11500000,18500000,27500000,46000000',
    favor: 2451,
    time: 43800,
    atk: 0,
    def: 0,
    hp: 73,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 6,
    worker1: 'baker',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 306,
    resource2: 'oils',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'goldshell',
    c1Qty: 4,
    c1Tags: null,
    component2: 'sewingthread',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-61',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:tuxedocake',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-5',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 45,
    su2Cost: 45,
    su3Cost: 45,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 885,
    surcharge: 1965,
    suggest: 395,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  tuxedocake: {
    uid: 'tuxedocake',
    level: 33,
    type: 'fd',
    xp: 105000,
    craftXp: 3150,
    value: 1550000,
    tradeMinMaxValue:
      '390000,480000,780000,1150000,1950000;19500000,24000000,39000000,58000000,97000000',
    favor: 3218,
    time: 63000,
    atk: 0,
    def: 0,
    hp: 91,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 11,
    combo: 5,
    worker1: 'baker',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 27,
    w2BuildingReq: 23,
    w3BuildingReq: 20,
    resource1: 'herbs',
    r1Qty: 338,
    resource2: 'oils',
    r2Qty: 98,
    resource3: 'mana',
    r3Qty: 31,
    component1: 'sparksigil',
    c1Qty: 1,
    c1Tags: null,
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-68',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t12dessert',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-6',
    supgrade2: 'component2-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'dark',
    spiritAffinity: null,
    tag: null,
    discount: 1095,
    surcharge: 2435,
    suggest: 485,
    speedup: 2790,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 85,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  t12dessert: {
    uid: 't12dessert',
    level: 36,
    type: 'fd',
    xp: 320000,
    craftXp: 9600,
    value: 3000000,
    tradeMinMaxValue:
      '750000,940000,1500000,2250000,3750000;37500000,47000000,75000000,112500000,187500000',
    favor: 4782,
    time: 107400,
    atk: 0,
    def: 0,
    hp: 114,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'baker',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'herbs',
    r1Qty: 387,
    resource2: 'oils',
    r2Qty: 113,
    resource3: 'mana',
    r3Qty: 41,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-77',
    upgrade3: 'value*1.25',
    upgrade4: 'bp:t13dessert',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-34',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: 'cat',
    tag: null,
    discount: 1505,
    surcharge: 3340,
    suggest: 670,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  coconutcake: {
    uid: 'coconutcake',
    level: 37,
    type: 'fd',
    xp: 335000,
    craftXp: 10050,
    value: 3250000,
    tradeMinMaxValue:
      '810000,1000000,1650000,2450000,4050000;40500000,51000000,81500000,122000000,203000000',
    favor: 5488,
    time: 128400,
    atk: 0,
    def: 118,
    hp: 118,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'baker',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'herbs',
    r1Qty: 404,
    resource2: 'oils',
    r2Qty: 123,
    resource3: 'mana',
    r3Qty: 46,
    component1: 'sparksigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'crabclaw',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'value*1.25',
    upgrade3: 'resource2-18',
    upgrade4: 'resource2-18',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource1-81',
    supgrade2: 'resource3-9',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 1565,
    surcharge: 3475,
    suggest: 695,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-11-25',
    shardPrice: 350,
    capriceDelay: 0,
  },
  golddessert: {
    uid: 'golddessert',
    level: 37,
    type: 'fd',
    xp: 335000,
    craftXp: 10050,
    value: 6000000,
    tradeMinMaxValue:
      '1500000,1900000,3000000,4500000,7500000;75000000,94000000,150000000,225000000,375000000',
    favor: 5488,
    time: 128400,
    atk: 0,
    def: 0,
    hp: 118,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 12,
    combo: 5,
    worker1: 'baker',
    worker2: 'jeweler',
    worker3: 'blacksmith',
    w1BuildingReq: 30,
    w2BuildingReq: 26,
    w3BuildingReq: 23,
    resource1: 'herbs',
    r1Qty: 404,
    resource2: 'oils',
    r2Qty: 123,
    resource3: 'gems',
    r3Qty: 46,
    component1: 'tabletjewel',
    c1Qty: 6,
    c1Tags: null,
    component2: 'magmacore',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource2-18',
    upgrade3: 'value*1.25',
    upgrade4: 'resource1-81',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource2-18',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: 'opulentspirit',
    tag: null,
    discount: 1575,
    surcharge: 3495,
    suggest: 700,
    speedup: 3465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-01-20',
    shardPrice: 0,
    capriceDelay: 0,
  },
  newyeardessert: {
    uid: 'newyeardessert',
    level: 38,
    type: 'fd',
    xp: 355000,
    craftXp: 10650,
    value: 3500000,
    tradeMinMaxValue:
      '880000,1100000,1750000,2650000,4400000;44000000,54500000,87500000,131500000,219000000',
    favor: 6249,
    time: 153000,
    atk: 122,
    def: 0,
    hp: 122,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 5,
    worker1: 'baker',
    worker2: 'priest',
    worker3: 'wizard',
    w1BuildingReq: 31,
    w2BuildingReq: 27,
    w3BuildingReq: 24,
    resource1: 'herbs',
    r1Qty: 421,
    resource2: 'oils',
    r2Qty: 134,
    resource3: 'mana',
    r3Qty: 50,
    component1: 'mightsigil2',
    c1Qty: 1,
    c1Tags: null,
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-84',
    upgrade3: 'resource2-27',
    upgrade4: 'value*1.25',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-10',
    supgrade2: 'multi+0.05',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'gold',
    spiritAffinity: null,
    tag: null,
    discount: 1620,
    surcharge: 3600,
    suggest: 720,
    speedup: 3645,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-12-28',
    shardPrice: 350,
    capriceDelay: 0,
  },
  t13dessert: {
    uid: 't13dessert',
    level: 40,
    type: 'fd',
    xp: 400000,
    craftXp: 12000,
    value: 5850000,
    tradeMinMaxValue:
      '1450000,1850000,2950000,4400000,7300000;73000000,91500000,146500000,219500000,365500000',
    favor: 8496,
    time: 218400,
    atk: 0,
    def: 0,
    hp: 131,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 13,
    combo: 5,
    worker1: 'baker',
    worker2: 'herbalist',
    worker3: 'priest',
    w1BuildingReq: 34,
    w2BuildingReq: 30,
    w3BuildingReq: 27,
    resource1: 'herbs',
    r1Qty: 446,
    resource2: 'oils',
    r2Qty: 144,
    resource3: 'essence',
    r3Qty: 6,
    component1: 'voideye',
    c1Qty: 3,
    c1Tags: null,
    component2: 'icybreeze',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 4,
    u2Req: 10,
    u3Req: 18,
    u4Req: 30,
    u5Req: 45,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-89',
    upgrade3: 'value*1.25',
    upgrade4: 'resource2-22',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-1',
    supgrade2: 'resource2-22',
    supgrade3: 'multi+0.1',
    su1Cost: 100,
    su2Cost: 100,
    su3Cost: 100,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: 'water',
    spiritAffinity: 'shark',
    tag: null,
    discount: 2070,
    surcharge: 4605,
    suggest: 920,
    speedup: 4015,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'lMetal',
    unlock: 200,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  chipped: {
    uid: 'chipped',
    level: 8,
    type: 'xu',
    xp: 3100,
    craftXp: 47,
    value: 4400,
    tradeMinMaxValue: '880,1100,1750,2600,4400;44000,55000,88000,130000,220000',
    favor: 30,
    time: 660,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 0,
    worker1: 'academy',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 21,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'webbedwings',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'time*0.9',
    upgrade2: 'resource1-2',
    upgrade3: 'resource1-4',
    upgrade4: 'time*0.9',
    upgrade5: 'bp:flawed',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'time*0.8',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 50,
    surcharge: 110,
    suggest: 45,
    speedup: 135,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  flawed: {
    uid: 'flawed',
    level: 16,
    type: 'xu',
    xp: 13500,
    craftXp: 203,
    value: 57500,
    tradeMinMaxValue:
      '11500,14500,23000,35000,58000;575000,720000,1150000,1750000,2900000',
    favor: 193,
    time: 4800,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 0,
    worker1: 'academy',
    worker2: null,
    worker3: null,
    w1BuildingReq: 13,
    w2BuildingReq: 8,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 85,
    resource2: 'oils',
    r2Qty: 21,
    resource3: null,
    r3Qty: 0,
    component1: 'starmetal',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.9',
    upgrade2: 'resource1-9',
    upgrade3: 'resource1-17',
    upgrade4: 'resource2-4',
    upgrade5: 'bp:chiseled',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'time*0.8',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 175,
    surcharge: 385,
    suggest: 155,
    speedup: 420,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 30,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  chiseled: {
    uid: 'chiseled',
    level: 24,
    type: 'xu',
    xp: 32500,
    craftXp: 488,
    value: 160000,
    tradeMinMaxValue:
      '32000,40000,64000,96000,160000;1600000,2000000,3200000,4800000,8000000',
    favor: 862,
    time: 15000,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 9,
    combo: 0,
    worker1: 'academy',
    worker2: 'master',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'herbs',
    r1Qty: 199,
    resource2: 'oils',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 7,
    component1: 'chipped',
    c1Qty: 3,
    c1Tags: 'common',
    component2: 'ectoplasm',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-100',
    upgrade3: 'resource2-26',
    upgrade4: 'component1-1',
    upgrade5: 'bp:perfect',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 285,
    surcharge: 630,
    suggest: 250,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  perfect: {
    uid: 'perfect',
    level: 31,
    type: 'xu',
    xp: 140000,
    craftXp: 2100,
    value: 300000,
    tradeMinMaxValue:
      '60000,75000,120000,180000,300000;3000000,3750000,6000000,9000000,15000000',
    favor: 2451,
    time: 75000,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 0,
    worker1: 'academy',
    worker2: 'master',
    worker3: 'sundragon',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 306,
    resource2: 'oils',
    r2Qty: 87,
    resource3: 'mana',
    r3Qty: 25,
    component1: 'obsidian',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 6,
    u2Req: 16,
    u3Req: 28,
    u4Req: 45,
    u5Req: 70,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-92',
    upgrade3: 'resource2-17',
    upgrade4: 'resource3-5',
    upgrade5: 'multi+0.2',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'resource2-17',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 495,
    surcharge: 1105,
    suggest: 440,
    speedup: 2480,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 130,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lessermoon: {
    uid: 'lessermoon',
    level: 9,
    type: 'xm',
    xp: 3950,
    craftXp: 118,
    value: 6700,
    tradeMinMaxValue:
      '1350,1700,2700,4000,6700;67000,84000,135000,200000,335000',
    favor: 39,
    time: 870,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 4,
    combo: 0,
    worker1: 'moondragon',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 26,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'preciousgem',
    c1Qty: 5,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'time*0.9',
    upgrade2: 'resource1-3',
    upgrade3: 'resource1-5',
    upgrade4: 'component1-1',
    upgrade5: 'bp:flawedmoon',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'time*0.8',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 60,
    surcharge: 135,
    suggest: 55,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  flawedmoon: {
    uid: 'flawedmoon',
    level: 17,
    type: 'xm',
    xp: 15500,
    craftXp: 466,
    value: 52000,
    tradeMinMaxValue:
      '10500,13000,21000,31000,52000;520000,650000,1050000,1550000,2600000',
    favor: 242,
    time: 6000,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 7,
    combo: 0,
    worker1: 'moondragon',
    worker2: null,
    worker3: null,
    w1BuildingReq: 14,
    w2BuildingReq: 10,
    w3BuildingReq: 4,
    resource1: 'herbs',
    r1Qty: 99,
    resource2: 'oils',
    r2Qty: 25,
    resource3: null,
    r3Qty: 0,
    component1: 'evileye',
    c1Qty: 4,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.9',
    upgrade2: 'resource1-10',
    upgrade3: 'resource1-20',
    upgrade4: 'resource2-5',
    upgrade5: 'bp:greatermoon',
    upgradeBonus: 1,
    supgrade1: 'component1-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'time*0.8',
    su1Cost: 40,
    su2Cost: 40,
    su3Cost: 40,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 165,
    surcharge: 365,
    suggest: 145,
    speedup: 465,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 30,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  greatermoon: {
    uid: 'greatermoon',
    level: 25,
    type: 'xm',
    xp: 35500,
    craftXp: 1066,
    value: 170000,
    tradeMinMaxValue:
      '34000,43000,68000,100000,170000;1700000,2150000,3400000,5100000,8500000',
    favor: 1001,
    time: 15000,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 10,
    combo: 0,
    worker1: 'moondragon',
    worker2: 'sundragon',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'herbs',
    r1Qty: 214,
    resource2: 'oils',
    r2Qty: 57,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'lessermoon',
    c1Qty: 3,
    c1Tags: 'common',
    component2: 'ghostflag',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.8',
    upgrade2: 'resource1-107',
    upgrade3: 'resource2-29',
    upgrade4: 'component1-1',
    upgrade5: 'bp:perfectmoon',
    upgradeBonus: 2,
    supgrade1: 'resource3-8',
    supgrade2: 'component1-1',
    supgrade3: 'multi+0.1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 435,
    surcharge: 970,
    suggest: 390,
    speedup: 1665,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 60,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  perfectmoon: {
    uid: 'perfectmoon',
    level: 30,
    type: 'xm',
    xp: 79000,
    craftXp: 2370,
    value: 300000,
    tradeMinMaxValue:
      '60000,75000,120000,180000,300000;3000000,3750000,6000000,9000000,15000000',
    favor: 2128,
    time: 75000,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 0,
    worker1: 'moondragon',
    worker2: 'sundragon',
    worker3: 'master',
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 290,
    resource2: 'oils',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'crabclaw',
    c1Qty: 2,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-87',
    upgrade3: 'resource2-16',
    upgrade4: 'resource3-4',
    upgrade5: 'multi+0.2',
    upgradeBonus: 2,
    supgrade1: 'resource3-7',
    supgrade2: 'resource2-16',
    supgrade3: 'multi+0.1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 495,
    surcharge: 1105,
    suggest: 440,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 130,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ember: {
    uid: 'ember',
    level: 9,
    type: 'z',
    xp: 4850,
    craftXp: 73,
    value: 8150,
    tradeMinMaxValue:
      '1650,2000,3300,4900,8200;81500,100000,165000,245000,410000',
    favor: 35,
    time: 520,
    atk: 14,
    def: 10,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: 'wizard',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 26,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'preciousgem',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 3,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:breeze',
    upgrade2: 'time*0.75',
    upgrade3: 'component2-1',
    upgrade4: 'bp:flaming',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'fire+5',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 150,
    suggest: 60,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  flaming: {
    uid: 'flaming',
    level: 20,
    type: 'z',
    xp: 27000,
    craftXp: 405,
    value: 105000,
    tradeMinMaxValue:
      '21000,26000,42000,63000,105000;1050000,1300000,2100000,3150000,5250000',
    favor: 379,
    time: 6600,
    atk: 38,
    def: 25,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'iron',
    r1Qty: 141,
    resource2: 'steel',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'flawed',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'whitesand',
    c2Qty: 10,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'component2-1',
    upgrade4: 'bp:blaze',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'fire+10',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 230,
    surcharge: 515,
    suggest: 205,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  blaze: {
    uid: 'blaze',
    level: 24,
    type: 'z',
    xp: 40000,
    craftXp: 600,
    value: 350000,
    tradeMinMaxValue:
      '70000,88000,140000,210000,350000;3500000,4400000,7000000,10500000,17500000',
    favor: 771,
    time: 14400,
    atk: 48,
    def: 32,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'iron',
    r1Qty: 199,
    resource2: 'steel',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 7,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'timecrystal',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'resource2-10',
    upgrade4: 'bp:inferno',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'fire+15',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 915,
    suggest: 365,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  inferno: {
    uid: 'inferno',
    level: 35,
    type: 'z',
    xp: 135000,
    craftXp: 2025,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 3738,
    time: 79200,
    atk: 89,
    def: 59,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: 'master',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'iron',
    r1Qty: 371,
    resource2: 'steel',
    r2Qty: 108,
    resource3: 'mana',
    r3Qty: 36,
    component1: 'perfect',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'magmacore',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-148',
    upgrade3: 'resource2-43',
    upgrade4: 'resource3-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'fire+25',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 885,
    surcharge: 1970,
    suggest: 790,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  bubble: {
    uid: 'bubble',
    level: 9,
    type: 'z',
    xp: 4850,
    craftXp: 73,
    value: 7400,
    tradeMinMaxValue:
      '1500,1850,3000,4400,7400;74000,92500,150000,220000,370000',
    favor: 35,
    time: 520,
    atk: 14,
    def: 10,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: 'wizard',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 26,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'arcanedust',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 3,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:natural',
    upgrade2: 'time*0.75',
    upgrade3: 'component2-1',
    upgrade4: 'bp:tidal',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'water+5',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 65,
    surcharge: 145,
    suggest: 60,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  tidal: {
    uid: 'tidal',
    level: 20,
    type: 'z',
    xp: 27000,
    craftXp: 405,
    value: 100000,
    tradeMinMaxValue:
      '20000,25000,40000,60000,100000;1000000,1250000,2000000,3000000,5000000',
    favor: 379,
    time: 6600,
    atk: 38,
    def: 25,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'wood',
    r1Qty: 141,
    resource2: 'ironwood',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'flawed',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'mooncrystal',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'component2-1',
    upgrade4: 'bp:flood',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'water+10',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 225,
    surcharge: 500,
    suggest: 200,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  flood: {
    uid: 'flood',
    level: 24,
    type: 'z',
    xp: 40000,
    craftXp: 600,
    value: 350000,
    tradeMinMaxValue:
      '70000,88000,140000,210000,350000;3500000,4400000,7000000,10500000,17500000',
    favor: 771,
    time: 14400,
    atk: 48,
    def: 32,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'wood',
    r1Qty: 199,
    resource2: 'ironwood',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 7,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'ectoplasm',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'resource2-10',
    upgrade4: 'bp:torrent',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'time*0.9',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'water+15',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 915,
    suggest: 365,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  torrent: {
    uid: 'torrent',
    level: 35,
    type: 'z',
    xp: 135000,
    craftXp: 2025,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 3738,
    time: 79200,
    atk: 89,
    def: 59,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: 'master',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'wood',
    r1Qty: 371,
    resource2: 'ironwood',
    r2Qty: 108,
    resource3: 'mana',
    r3Qty: 36,
    component1: 'perfect',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'icybreeze',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-148',
    upgrade3: 'resource2-43',
    upgrade4: 'resource3-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'water+25',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 885,
    surcharge: 1970,
    suggest: 790,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  breeze: {
    uid: 'breeze',
    level: 9,
    type: 'z',
    xp: 4850,
    craftXp: 73,
    value: 8550,
    tradeMinMaxValue:
      '1700,2100,3400,5100,8600;85500,105000,170000,255000,430000',
    favor: 35,
    time: 520,
    atk: 14,
    def: 10,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: 'wizard',
    worker2: null,
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 26,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'webbedwings',
    c2Qty: 7,
    c2Tags: null,
    u1Req: 3,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:light',
    upgrade2: 'time*0.75',
    upgrade3: 'component2-1',
    upgrade4: 'bp:gale',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'air+5',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 155,
    suggest: 60,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 5,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  gale: {
    uid: 'gale',
    level: 20,
    type: 'z',
    xp: 27000,
    craftXp: 405,
    value: 99500,
    tradeMinMaxValue:
      '20000,25000,40000,60000,100000;995000,1250000,2000000,3000000,5000000',
    favor: 379,
    time: 6600,
    atk: 38,
    def: 25,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'leather',
    r1Qty: 141,
    resource2: 'fabric',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'flawed',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'starmetal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'component2-1',
    upgrade4: 'bp:tempest',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'air+10',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 225,
    surcharge: 500,
    suggest: 200,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  tempest: {
    uid: 'tempest',
    level: 24,
    type: 'z',
    xp: 40000,
    craftXp: 600,
    value: 350000,
    tradeMinMaxValue:
      '70000,88000,140000,210000,350000;3500000,4400000,7000000,10500000,17500000',
    favor: 771,
    time: 14400,
    atk: 48,
    def: 32,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'leather',
    r1Qty: 199,
    resource2: 'fabric',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 7,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'sewingthread',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'resource2-10',
    upgrade4: 'bp:hurricane',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'time*0.9',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'air+15',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 915,
    suggest: 365,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  hurricane: {
    uid: 'hurricane',
    level: 35,
    type: 'z',
    xp: 135000,
    craftXp: 2025,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 3738,
    time: 79200,
    atk: 89,
    def: 59,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: 'master',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'leather',
    r1Qty: 371,
    resource2: 'fabric',
    r2Qty: 108,
    resource3: 'mana',
    r3Qty: 36,
    component1: 'perfect',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-148',
    upgrade3: 'resource2-43',
    upgrade4: 'resource3-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'air+25',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 885,
    surcharge: 1970,
    suggest: 790,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  natural: {
    uid: 'natural',
    level: 9,
    type: 'z',
    xp: 4850,
    craftXp: 73,
    value: 9800,
    tradeMinMaxValue:
      '1950,2500,3900,5900,9800;98000,125000,195000,295000,490000',
    favor: 35,
    time: 520,
    atk: 14,
    def: 10,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: 'wizard',
    worker2: null,
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 26,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'livingroots',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 3,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:corrupted',
    upgrade2: 'time*0.75',
    upgrade3: 'component2-1',
    upgrade4: 'bp:wild',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'earth+5',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 75,
    surcharge: 165,
    suggest: 65,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 5,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  wild: {
    uid: 'wild',
    level: 20,
    type: 'z',
    xp: 27000,
    craftXp: 405,
    value: 96500,
    tradeMinMaxValue:
      '19500,24000,39000,58000,97000;965000,1200000,1950000,2900000,4850000',
    favor: 379,
    time: 6600,
    atk: 38,
    def: 25,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'herbs',
    r1Qty: 141,
    resource2: 'oils',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'flawed',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'sharpfang',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'component2-1',
    upgrade4: 'bp:primal',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'earth+10',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 225,
    surcharge: 495,
    suggest: 200,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  primal: {
    uid: 'primal',
    level: 24,
    type: 'z',
    xp: 40000,
    craftXp: 600,
    value: 350000,
    tradeMinMaxValue:
      '70000,88000,140000,210000,350000;3500000,4400000,7000000,10500000,17500000',
    favor: 771,
    time: 14400,
    atk: 48,
    def: 32,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'herbs',
    r1Qty: 199,
    resource2: 'oils',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 7,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'overgrownvine',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'resource2-10',
    upgrade4: 'bp:primeval',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'earth+15',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 915,
    suggest: 365,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  primeval: {
    uid: 'primeval',
    level: 35,
    type: 'z',
    xp: 135000,
    craftXp: 2025,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 3738,
    time: 79200,
    atk: 89,
    def: 59,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: 'master',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'herbs',
    r1Qty: 371,
    resource2: 'oils',
    r2Qty: 108,
    resource3: 'mana',
    r3Qty: 36,
    component1: 'perfect',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-148',
    upgrade3: 'resource2-43',
    upgrade4: 'resource3-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'earth+25',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 885,
    surcharge: 1970,
    suggest: 790,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  light: {
    uid: 'light',
    level: 9,
    type: 'z',
    xp: 4850,
    craftXp: 73,
    value: 9600,
    tradeMinMaxValue:
      '1900,2400,3800,5800,9600;96000,120000,190000,290000,480000',
    favor: 35,
    time: 520,
    atk: 14,
    def: 10,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 26,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'icypearl',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 65,
    u5Req: 100,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-5',
    upgrade3: 'component2-1',
    upgrade4: 'bp:holy',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'light+5',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 75,
    surcharge: 165,
    suggest: 65,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 5,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  holy: {
    uid: 'holy',
    level: 20,
    type: 'z',
    xp: 27000,
    craftXp: 405,
    value: 96500,
    tradeMinMaxValue:
      '19500,24000,39000,58000,97000;965000,1200000,1950000,2900000,4850000',
    favor: 379,
    time: 6600,
    atk: 38,
    def: 25,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'herbs',
    r1Qty: 141,
    resource2: 'oils',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'flawed',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'silkscarab',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'component2-1',
    upgrade4: 'bp:sacred',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'light+10',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 225,
    surcharge: 495,
    suggest: 200,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  sacred: {
    uid: 'sacred',
    level: 24,
    type: 'z',
    xp: 40000,
    craftXp: 600,
    value: 350000,
    tradeMinMaxValue:
      '70000,88000,140000,210000,350000;3500000,4400000,7000000,10500000,17500000',
    favor: 771,
    time: 14400,
    atk: 48,
    def: 32,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'herbs',
    r1Qty: 199,
    resource2: 'oils',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 7,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'ancientmarble',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'resource2-10',
    upgrade4: 'bp:divine',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'light+15',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 915,
    suggest: 365,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  divine: {
    uid: 'divine',
    level: 35,
    type: 'z',
    xp: 135000,
    craftXp: 2025,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 3738,
    time: 79200,
    atk: 89,
    def: 59,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: 'master',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'herbs',
    r1Qty: 371,
    resource2: 'oils',
    r2Qty: 108,
    resource3: 'mana',
    r3Qty: 36,
    component1: 'perfect',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'crabclaw',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-148',
    upgrade3: 'resource2-43',
    upgrade4: 'resource3-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'light+25',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 885,
    surcharge: 1970,
    suggest: 790,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  corrupted: {
    uid: 'corrupted',
    level: 9,
    type: 'z',
    xp: 4850,
    craftXp: 73,
    value: 10500,
    tradeMinMaxValue:
      '2100,2600,4200,6300,10500;105000,130000,210000,315000,525000',
    favor: 35,
    time: 520,
    atk: 14,
    def: 10,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 6,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 26,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'darkscale',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 7,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-5',
    upgrade3: 'component2-1',
    upgrade4: 'bp:unholy',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'dark+5',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 75,
    surcharge: 170,
    suggest: 70,
    speedup: 165,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 5,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  unholy: {
    uid: 'unholy',
    level: 20,
    type: 'z',
    xp: 27000,
    craftXp: 405,
    value: 105000,
    tradeMinMaxValue:
      '21000,26000,42000,63000,105000;1050000,1300000,2100000,3150000,5250000',
    favor: 379,
    time: 6600,
    atk: 38,
    def: 25,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 17,
    w2BuildingReq: 13,
    w3BuildingReq: 7,
    resource1: 'herbs',
    r1Qty: 141,
    resource2: 'oils',
    r2Qty: 36,
    resource3: null,
    r3Qty: 0,
    component1: 'flawed',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'evileye',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-28',
    upgrade3: 'component2-1',
    upgrade4: 'bp:nightmare',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'dark+10',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 230,
    surcharge: 515,
    suggest: 205,
    speedup: 620,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  nightmare: {
    uid: 'nightmare',
    level: 24,
    type: 'z',
    xp: 40000,
    craftXp: 600,
    value: 350000,
    tradeMinMaxValue:
      '70000,88000,140000,210000,350000;3500000,4400000,7000000,10500000,17500000',
    favor: 771,
    time: 14400,
    atk: 48,
    def: 32,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'herbs',
    r1Qty: 199,
    resource2: 'oils',
    r2Qty: 51,
    resource3: 'mana',
    r3Qty: 7,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'ghostflag',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 11,
    u2Req: 28,
    u3Req: 50,
    u4Req: 80,
    u5Req: 125,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'resource2-10',
    upgrade4: 'bp:abyssal',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'time*0.9',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'dark+15',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 915,
    suggest: 365,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  abyssal: {
    uid: 'abyssal',
    level: 35,
    type: 'z',
    xp: 135000,
    craftXp: 2025,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 3738,
    time: 79200,
    atk: 89,
    def: 59,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: 'master',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'herbs',
    r1Qty: 371,
    resource2: 'oils',
    r2Qty: 108,
    resource3: 'mana',
    r3Qty: 36,
    component1: 'perfect',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-148',
    upgrade3: 'resource2-43',
    upgrade4: 'resource3-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'dark+25',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 885,
    surcharge: 1970,
    suggest: 790,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  luxuriouselement: {
    uid: 'luxuriouselement',
    level: 15,
    type: 'z',
    xp: 14500,
    craftXp: 218,
    value: 50000,
    tradeMinMaxValue:
      '10000,12500,20000,30000,50000;500000,625000,1000000,1500000,2500000',
    favor: 150,
    time: 2160,
    atk: 26,
    def: 18,
    hp: 5,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 5,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 71,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'tabletfragment',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.85',
    upgrade2: 'resource1-11',
    upgrade3: 'time*0.85',
    upgrade4: 'resource1-11',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'quality+1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'gold+5',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 120,
    surcharge: 270,
    suggest: 110,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-10-29',
    shardPrice: 0,
    capriceDelay: 0,
  },
  opulentelement: {
    uid: 'opulentelement',
    level: 30,
    type: 'z',
    xp: 65000,
    craftXp: 975,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 2128,
    time: 32400,
    atk: 63,
    def: 42,
    hp: 13,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 0,
    worker1: 'wizard',
    worker2: 'priest',
    worker3: null,
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'herbs',
    r1Qty: 290,
    resource2: 'oils',
    r2Qty: 82,
    resource3: 'mana',
    r3Qty: 22,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'tabletjewel',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-58',
    upgrade3: 'resource2-12',
    upgrade4: 'resource2-12',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 2,
    elements: 'gold+15',
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 765,
    surcharge: 1705,
    suggest: 680,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2021-10-29',
    shardPrice: 0,
    capriceDelay: 0,
  },
  freshspirit: {
    uid: 'freshspirit',
    level: 1,
    type: 'z',
    xp: 140,
    craftXp: 2,
    value: 0,
    tradeMinMaxValue: '0,0,0,0,0;0,0,0,0,0',
    favor: 1,
    time: 15,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 5,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'preciousgem',
    c2Qty: 7,
    c2Tags: null,
    u1Req: 3,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'time*0.75',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1*0.8',
    upgrade4: 'resource1*0.8',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_freshspirit',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 15,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: true,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  turtle: {
    uid: 'turtle',
    level: 10,
    type: 'z',
    xp: 6100,
    craftXp: 92,
    value: 9400,
    tradeMinMaxValue:
      '1900,2400,3800,5600,9400;94000,120000,190000,280000,470000',
    favor: 42,
    time: 690,
    atk: 16,
    def: 11,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 1,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 30,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'preciousgem',
    c2Qty: 7,
    c2Tags: null,
    u1Req: 3,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:wolf',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-6',
    upgrade4: 'bp:rhino',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_turtle',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 160,
    suggest: 65,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  wolf: {
    uid: 'wolf',
    level: 10,
    type: 'z',
    xp: 6100,
    craftXp: 92,
    value: 9200,
    tradeMinMaxValue:
      '1850,2300,3700,5500,9200;92000,115000,185000,275000,460000',
    favor: 42,
    time: 690,
    atk: 16,
    def: 11,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 4,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 30,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'arcanedust',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 3,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:ox',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-6',
    upgrade4: 'bp:owl',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-3',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_wolf',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 70,
    surcharge: 160,
    suggest: 65,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 5,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ox: {
    uid: 'ox',
    level: 10,
    type: 'z',
    xp: 6100,
    craftXp: 92,
    value: 10000,
    tradeMinMaxValue:
      '2000,2500,4000,6000,10000;100000,125000,200000,300000,500000',
    favor: 42,
    time: 690,
    atk: 16,
    def: 11,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 7,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 30,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'livingroots',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 3,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:eagle',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-6',
    upgrade4: 'bp:armadillo',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_ox',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 75,
    surcharge: 165,
    suggest: 65,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 5,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  eagle: {
    uid: 'eagle',
    level: 10,
    type: 'z',
    xp: 6100,
    craftXp: 92,
    value: 9750,
    tradeMinMaxValue:
      '1950,2400,3900,5900,9800;97500,120000,195000,295000,490000',
    favor: 42,
    time: 690,
    atk: 16,
    def: 11,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 7,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 30,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'webbedwings',
    c2Qty: 8,
    c2Tags: null,
    u1Req: 3,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:viper',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-6',
    upgrade4: 'bp:lizard',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_eagle',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 75,
    surcharge: 165,
    suggest: 65,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 5,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  viper: {
    uid: 'viper',
    level: 10,
    type: 'z',
    xp: 6100,
    craftXp: 92,
    value: 11000,
    tradeMinMaxValue:
      '2200,2800,4400,6600,11000;110000,140000,220000,330000,550000',
    favor: 42,
    time: 690,
    atk: 16,
    def: 11,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 7,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'herbs',
    r1Qty: 30,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'darkscale',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 3,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'bp:cat',
    upgrade2: 'time*0.75',
    upgrade3: 'resource1-6',
    upgrade4: 'bp:crab',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_viper',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 80,
    surcharge: 175,
    suggest: 70,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 5,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  cat: {
    uid: 'cat',
    level: 10,
    type: 'z',
    xp: 6100,
    craftXp: 92,
    value: 12000,
    tradeMinMaxValue:
      '2400,3000,4800,7200,12000;120000,150000,240000,360000,600000',
    favor: 42,
    time: 690,
    atk: 16,
    def: 11,
    hp: 3,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 7,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'leather',
    r1Qty: 30,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'icypearl',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 3,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-6',
    upgrade3: 'component2-1',
    upgrade4: 'bp:hippo',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_cat',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 80,
    surcharge: 180,
    suggest: 70,
    speedup: 190,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 5,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  rabbitspirit: {
    uid: 'rabbitspirit',
    level: 11,
    type: 'z',
    xp: 7450,
    craftXp: 112,
    value: 13500,
    tradeMinMaxValue:
      '2700,3400,5400,8100,13500;135000,170000,270000,405000,675000',
    favor: 56,
    time: 870,
    atk: 19,
    def: 12,
    hp: 4,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 4,
    combo: 0,
    worker1: 'priest',
    worker2: null,
    worker3: null,
    w1BuildingReq: 8,
    w2BuildingReq: 1,
    w3BuildingReq: 1,
    resource1: 'wood',
    r1Qty: 34,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'livingroots',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 3,
    u2Req: 18,
    u3Req: 32,
    u4Req: 50,
    u5Req: 80,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-5',
    upgrade3: 'component2-1',
    upgrade4: 'resource1-5',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 10,
    su2Cost: 10,
    su3Cost: 10,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_rabbitspirit',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 85,
    surcharge: 190,
    suggest: 75,
    speedup: 225,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2023-01-10',
    shardPrice: 100,
    capriceDelay: 0,
  },
  luxuriousspirit: {
    uid: 'luxuriousspirit',
    level: 15,
    type: 'z',
    xp: 14500,
    craftXp: 218,
    value: 50000,
    tradeMinMaxValue:
      '10000,12500,20000,30000,50000;500000,625000,1000000,1500000,2500000',
    favor: 150,
    time: 2160,
    atk: 26,
    def: 18,
    hp: 5,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 5,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 12,
    w2BuildingReq: 6,
    w3BuildingReq: 1,
    resource1: 'iron',
    r1Qty: 71,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: 'chipped',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'tabletfragment',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.85',
    upgrade2: 'resource1-11',
    upgrade3: 'time*0.85',
    upgrade4: 'resource1-11',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'quality+1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 20,
    su2Cost: 20,
    su3Cost: 20,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_luxuriousspirit',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 120,
    surcharge: 270,
    suggest: 110,
    speedup: 375,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: 'goldcity',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-02-18',
    shardPrice: 0,
    capriceDelay: 0,
  },
  rhino: {
    uid: 'rhino',
    level: 21,
    type: 'z',
    xp: 30000,
    craftXp: 450,
    value: 110000,
    tradeMinMaxValue:
      '22000,28000,44000,66000,110000;1100000,1400000,2200000,3300000,5500000',
    favor: 447,
    time: 7800,
    atk: 41,
    def: 27,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'iron',
    r1Qty: 155,
    resource2: 'steel',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'flawed',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'sharpfang',
    c2Qty: 10,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'component2-2',
    upgrade4: 'bp:shark',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_rhino',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 235,
    surcharge: 525,
    suggest: 210,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  owl: {
    uid: 'owl',
    level: 21,
    type: 'z',
    xp: 30000,
    craftXp: 450,
    value: 110000,
    tradeMinMaxValue:
      '22000,28000,44000,66000,110000;1100000,1400000,2200000,3300000,5500000',
    favor: 447,
    time: 7800,
    atk: 41,
    def: 27,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'leather',
    r1Qty: 155,
    resource2: 'fabric',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'flawed',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'mooncrystal',
    c2Qty: 9,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'component2-1',
    upgrade4: 'bp:walrus',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_owl',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 235,
    surcharge: 525,
    suggest: 210,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  armadillo: {
    uid: 'armadillo',
    level: 21,
    type: 'z',
    xp: 30000,
    craftXp: 450,
    value: 115000,
    tradeMinMaxValue:
      '23000,29000,46000,69000,115000;1150000,1450000,2300000,3450000,5750000',
    favor: 447,
    time: 7800,
    atk: 41,
    def: 27,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'herbs',
    r1Qty: 155,
    resource2: 'oils',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'flawed',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'whitesand',
    c2Qty: 12,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'component2-2',
    upgrade4: 'bp:mammoth',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_armadillo',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 240,
    surcharge: 535,
    suggest: 215,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lizard: {
    uid: 'lizard',
    level: 21,
    type: 'z',
    xp: 30000,
    craftXp: 450,
    value: 130000,
    tradeMinMaxValue:
      '26000,33000,52000,78000,130000;1300000,1650000,2600000,3900000,6500000',
    favor: 447,
    time: 7800,
    atk: 41,
    def: 27,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'herbs',
    r1Qty: 155,
    resource2: 'oils',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'flawed',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'evileye',
    c2Qty: 7,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'component2-1',
    upgrade4: 'bp:dinosaur',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_lizard',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 255,
    surcharge: 570,
    suggest: 230,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  crab: {
    uid: 'crab',
    level: 21,
    type: 'z',
    xp: 30000,
    craftXp: 450,
    value: 125000,
    tradeMinMaxValue:
      '25000,31000,50000,75000,125000;1250000,1550000,2500000,3750000,6250000',
    favor: 447,
    time: 7800,
    atk: 41,
    def: 27,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'wood',
    r1Qty: 155,
    resource2: 'ironwood',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'flawed',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'silkscarab',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'component2-1',
    upgrade4: 'bp:bear',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_crab',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 250,
    surcharge: 560,
    suggest: 225,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  hippo: {
    uid: 'hippo',
    level: 21,
    type: 'z',
    xp: 30000,
    craftXp: 450,
    value: 130000,
    tradeMinMaxValue:
      '26000,33000,52000,78000,130000;1300000,1650000,2600000,3900000,6500000',
    favor: 447,
    time: 7800,
    atk: 41,
    def: 27,
    hp: 8,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 18,
    w2BuildingReq: 14,
    w3BuildingReq: 8,
    resource1: 'wood',
    r1Qty: 155,
    resource2: 'ironwood',
    r2Qty: 39,
    resource3: null,
    r3Qty: 0,
    component1: 'flawed',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'starmetal',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 10,
    u2Req: 24,
    u3Req: 44,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-31',
    upgrade3: 'component2-1',
    upgrade4: 'bp:lion',
    upgrade5: 'quality+1',
    upgradeBonus: 1,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 30,
    su2Cost: 30,
    su3Cost: 30,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_hippo',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 255,
    surcharge: 570,
    suggest: 230,
    speedup: 675,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 20,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  shark: {
    uid: 'shark',
    level: 24,
    type: 'z',
    xp: 40000,
    craftXp: 600,
    value: 350000,
    tradeMinMaxValue:
      '70000,88000,140000,210000,350000;3500000,4400000,7000000,10500000,17500000',
    favor: 771,
    time: 14400,
    atk: 48,
    def: 32,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'iron',
    r1Qty: 199,
    resource2: 'steel',
    r2Qty: 51,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'ectoplasm',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'component2-1',
    upgrade4: 'bp:kraken',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'time*0.9',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_shark',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 915,
    suggest: 365,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  walrus: {
    uid: 'walrus',
    level: 24,
    type: 'z',
    xp: 40000,
    craftXp: 600,
    value: 350000,
    tradeMinMaxValue:
      '70000,88000,140000,210000,350000;3500000,4400000,7000000,10500000,17500000',
    favor: 771,
    time: 14400,
    atk: 48,
    def: 32,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'leather',
    r1Qty: 199,
    resource2: 'fabric',
    r2Qty: 51,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'ancientmarble',
    c2Qty: 5,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'component2-1',
    upgrade4: 'bp:tarrasque',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_walrus',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 915,
    suggest: 365,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  lion: {
    uid: 'lion',
    level: 24,
    type: 'z',
    xp: 40000,
    craftXp: 600,
    value: 350000,
    tradeMinMaxValue:
      '70000,88000,140000,210000,350000;3500000,4400000,7000000,10500000,17500000',
    favor: 771,
    time: 14400,
    atk: 48,
    def: 32,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'wood',
    r1Qty: 199,
    resource2: 'ironwood',
    r2Qty: 51,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'sewingthread',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'component2-1',
    upgrade4: 'bp:chimera',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'time*0.9',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_lion',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 915,
    suggest: 365,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  bear: {
    uid: 'bear',
    level: 24,
    type: 'z',
    xp: 40000,
    craftXp: 600,
    value: 350000,
    tradeMinMaxValue:
      '70000,88000,140000,210000,350000;3500000,4400000,7000000,10500000,17500000',
    favor: 771,
    time: 14400,
    atk: 48,
    def: 32,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'wood',
    r1Qty: 199,
    resource2: 'ironwood',
    r2Qty: 51,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'overgrownvine',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'component2-1',
    upgrade4: 'bp:phoenix',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_bear',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 915,
    suggest: 365,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  mammoth: {
    uid: 'mammoth',
    level: 24,
    type: 'z',
    xp: 40000,
    craftXp: 600,
    value: 350000,
    tradeMinMaxValue:
      '70000,88000,140000,210000,350000;3500000,4400000,7000000,10500000,17500000',
    favor: 771,
    time: 14400,
    atk: 48,
    def: 32,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'herbs',
    r1Qty: 199,
    resource2: 'oils',
    r2Qty: 51,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'timecrystal',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'component2-1',
    upgrade4: 'bp:carbuncle',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_mammoth',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 915,
    suggest: 365,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  dinosaur: {
    uid: 'dinosaur',
    level: 24,
    type: 'z',
    xp: 40000,
    craftXp: 600,
    value: 350000,
    tradeMinMaxValue:
      '70000,88000,140000,210000,350000;3500000,4400000,7000000,10500000,17500000',
    favor: 771,
    time: 14400,
    atk: 48,
    def: 32,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 11,
    resource1: 'herbs',
    r1Qty: 199,
    resource2: 'oils',
    r2Qty: 51,
    resource3: 'gems',
    r3Qty: 7,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'starmetal',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-40',
    upgrade3: 'component2-1',
    upgrade4: 'bp:hydra',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_dinosaur',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 915,
    suggest: 365,
    speedup: 860,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  dragon: {
    uid: 'dragon',
    level: 25,
    type: 'z',
    xp: 44000,
    craftXp: 1320,
    value: 300000,
    tradeMinMaxValue:
      '60000,75000,120000,180000,300000;3000000,3750000,6000000,9000000,15000000',
    favor: 896,
    time: 17400,
    atk: 50,
    def: 33,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: 'moondragon',
    worker2: null,
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'wood',
    r1Qty: 214,
    resource2: 'ironwood',
    r2Qty: 57,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'elvenwood',
    c2Qty: 1,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'component2-1',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'multi+0.1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_dragon',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 410,
    surcharge: 850,
    suggest: 365,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 50,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: true,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  tiger: {
    uid: 'tiger',
    level: 25,
    type: 'z',
    xp: 44000,
    craftXp: 660,
    value: 375000,
    tradeMinMaxValue:
      '75000,94000,150000,230000,380000;3750000,4700000,7500000,11500000,19000000',
    favor: 1001,
    time: 17400,
    atk: 50,
    def: 33,
    hp: 10,
    eva: '0',
    crit: '0',
    excl: 'special',
    tier: 9,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 21,
    w2BuildingReq: 17,
    w3BuildingReq: 12,
    resource1: 'wood',
    r1Qty: 214,
    resource2: 'ironwood',
    r2Qty: 57,
    resource3: 'gems',
    r3Qty: 8,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'ectoplasm',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 9,
    u2Req: 22,
    u3Req: 40,
    u4Req: 60,
    u5Req: 95,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-43',
    upgrade3: 'component2-1',
    upgrade4: 'resource2-11',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'time*0.9',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_tiger',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 425,
    surcharge: 945,
    suggest: 380,
    speedup: 925,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-01-25',
    shardPrice: 200,
    capriceDelay: 0,
  },
  opulentspirit: {
    uid: 'opulentspirit',
    level: 30,
    type: 'z',
    xp: 65000,
    craftXp: 975,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 2128,
    time: 32400,
    atk: 63,
    def: 42,
    hp: 13,
    eva: '0',
    crit: '0',
    excl: 'chest',
    tier: 10,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: null,
    w1BuildingReq: 25,
    w2BuildingReq: 21,
    w3BuildingReq: 18,
    resource1: 'iron',
    r1Qty: 290,
    resource2: 'steel',
    r2Qty: 82,
    resource3: 'gems',
    r3Qty: 22,
    component1: 'chiseled',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'tabletjewel',
    c2Qty: 2,
    c2Tags: null,
    u1Req: 10,
    u2Req: 26,
    u3Req: 46,
    u4Req: 70,
    u5Req: 110,
    upgrade1: 'time*0.75',
    upgrade2: 'resource1-58',
    upgrade3: 'resource2-12',
    upgrade4: 'resource2-12',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'resource3-4',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 60,
    su2Cost: 60,
    su3Cost: 60,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_opulentspirit',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 765,
    surcharge: 1705,
    suggest: 680,
    speedup: 2335,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: 'goldcity2',
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-02-18',
    shardPrice: 0,
    capriceDelay: 0,
  },
  phoenix: {
    uid: 'phoenix',
    level: 35,
    type: 'z',
    xp: 135000,
    craftXp: 2025,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 3738,
    time: 79200,
    atk: 89,
    def: 59,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: 'master',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'iron',
    r1Qty: 371,
    resource2: 'steel',
    r2Qty: 108,
    resource3: 'gems',
    r3Qty: 36,
    component1: 'perfect',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'magmacore',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-148',
    upgrade3: 'resource2-43',
    upgrade4: 'resource3-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_phoenix',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 885,
    surcharge: 1970,
    suggest: 790,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  hydra: {
    uid: 'hydra',
    level: 35,
    type: 'z',
    xp: 135000,
    craftXp: 2025,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 3738,
    time: 79200,
    atk: 89,
    def: 59,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: 'master',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'herbs',
    r1Qty: 371,
    resource2: 'oils',
    r2Qty: 108,
    resource3: 'gems',
    r3Qty: 36,
    component1: 'perfect',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'obsidian',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-148',
    upgrade3: 'resource2-43',
    upgrade4: 'resource3-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_hydra',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 885,
    surcharge: 1970,
    suggest: 790,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  tarrasque: {
    uid: 'tarrasque',
    level: 35,
    type: 'z',
    xp: 135000,
    craftXp: 2025,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 3738,
    time: 79200,
    atk: 89,
    def: 59,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: 'master',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'wood',
    r1Qty: 371,
    resource2: 'ironwood',
    r2Qty: 108,
    resource3: 'gems',
    r3Qty: 36,
    component1: 'perfect',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'crabclaw',
    c2Qty: 3,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-148',
    upgrade3: 'resource2-43',
    upgrade4: 'resource3-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_tarrasque',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 885,
    surcharge: 1970,
    suggest: 790,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  carbuncle: {
    uid: 'carbuncle',
    level: 35,
    type: 'z',
    xp: 135000,
    craftXp: 2025,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 3738,
    time: 79200,
    atk: 89,
    def: 59,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: 'master',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'leather',
    r1Qty: 371,
    resource2: 'fabric',
    r2Qty: 108,
    resource3: 'gems',
    r3Qty: 36,
    component1: 'perfect',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'crystalegg',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-148',
    upgrade3: 'resource2-43',
    upgrade4: 'resource3-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_carbuncle',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 885,
    surcharge: 1970,
    suggest: 790,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  chimera: {
    uid: 'chimera',
    level: 35,
    type: 'z',
    xp: 135000,
    craftXp: 2025,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 3738,
    time: 79200,
    atk: 89,
    def: 59,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: 'master',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'herbs',
    r1Qty: 371,
    resource2: 'oils',
    r2Qty: 108,
    resource3: 'gems',
    r3Qty: 36,
    component1: 'perfect',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'grimtalon',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-148',
    upgrade3: 'resource2-43',
    upgrade4: 'resource3-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_chimera',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 885,
    surcharge: 1970,
    suggest: 790,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  kraken: {
    uid: 'kraken',
    level: 35,
    type: 'z',
    xp: 135000,
    craftXp: 2025,
    value: 1000000,
    tradeMinMaxValue:
      '200000,250000,400000,600000,1000000;10000000,12500000,20000000,30000000,50000000',
    favor: 3738,
    time: 79200,
    atk: 89,
    def: 59,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: 'master',
    w1BuildingReq: 28,
    w2BuildingReq: 24,
    w3BuildingReq: 21,
    resource1: 'leather',
    r1Qty: 371,
    resource2: 'fabric',
    r2Qty: 108,
    resource3: 'gems',
    r3Qty: 36,
    component1: 'perfect',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'deepcoral',
    c2Qty: 4,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-148',
    upgrade3: 'resource2-43',
    upgrade4: 'resource3-14',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-1',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_kraken',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 885,
    surcharge: 1970,
    suggest: 790,
    speedup: 3120,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 125,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  santa: {
    uid: 'santa',
    level: 36,
    type: 'z',
    xp: 145000,
    craftXp: 2175,
    value: 1150000,
    tradeMinMaxValue:
      '230000,290000,460000,690000,1150000;11500000,14500000,23000000,34500000,57500000',
    favor: 4782,
    time: 94200,
    atk: 92,
    def: 61,
    hp: 18,
    eva: '0',
    crit: '0',
    excl: 'pack',
    tier: 12,
    combo: 0,
    worker1: 'priest',
    worker2: 'wizard',
    worker3: 'master',
    w1BuildingReq: 29,
    w2BuildingReq: 25,
    w3BuildingReq: 22,
    resource1: 'wood',
    r1Qty: 387,
    resource2: 'ironwood',
    r2Qty: 113,
    resource3: 'gems',
    r3Qty: 41,
    component1: 'perfect',
    c1Qty: 1,
    c1Tags: 'common',
    component2: 'goldshell',
    c2Qty: 6,
    c2Tags: null,
    u1Req: 6,
    u2Req: 14,
    u3Req: 26,
    u4Req: 40,
    u5Req: 60,
    upgrade1: 'time*0.7',
    upgrade2: 'resource1-155',
    upgrade3: 'resource2-45',
    upgrade4: 'resource3-16',
    upgrade5: 'quality+1',
    upgradeBonus: 2,
    supgrade1: 'component2-2',
    supgrade2: 'multi+0.1',
    supgrade3: 'quality+1',
    su1Cost: 80,
    su2Cost: 80,
    su3Cost: 80,
    restrict: null,
    reqTags: null,
    tagIndex: 3,
    elements: null,
    skill: 'i_santa',
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 950,
    surcharge: 2110,
    suggest: 845,
    speedup: 3290,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: 'Stone',
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: '2022-12-14',
    shardPrice: 350,
    capriceDelay: 0,
  },
  tabletfragment: {
    uid: 'tabletfragment',
    level: 25,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 20000,
    tradeMinMaxValue:
      '4000,5000,8000,12000,20000;200000,250000,400000,600000,1000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  tabletjewel: {
    uid: 'tabletjewel',
    level: 25,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 80000,
    tradeMinMaxValue:
      '16000,20000,32000,48000,80000;800000,1000000,1600000,2400000,4000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  platinumjewel: {
    uid: 'platinumjewel',
    level: 25,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 500000,
    tradeMinMaxValue:
      '100000,125000,200000,300000,500000;5000000,6250000,10000000,15000000,25000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  elvenwood: {
    uid: 'elvenwood',
    level: 1,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 100,
    tradeMinMaxValue: '20,25,40,60,100;1000,1300,2000,3000,5000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  redpinecone: {
    uid: 'redpinecone',
    level: 3,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 110,
    tradeMinMaxValue: '20,30,45,70,110;1100,1400,2200,3300,5500',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  glowingmushrooms: {
    uid: 'glowingmushrooms',
    level: 8,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 120,
    tradeMinMaxValue: '25,30,50,70,120;1200,1500,2400,3600,6000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 1,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  arcanedust: {
    uid: 'arcanedust',
    level: 5,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 400,
    tradeMinMaxValue: '80,100,160,240,400;4000,5000,8000,12000,20000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  webbedwings: {
    uid: 'webbedwings',
    level: 9,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 450,
    tradeMinMaxValue: '90,110,180,270,450;4500,5600,9000,13500,22500',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  preciousgem: {
    uid: 'preciousgem',
    level: 12,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 500,
    tradeMinMaxValue: '100,130,200,300,500;5000,6300,10000,15000,25000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 2,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  livingroots: {
    uid: 'livingroots',
    level: 11,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 900,
    tradeMinMaxValue: '180,230,360,540,900;9000,11500,18000,27000,45000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  darkscale: {
    uid: 'darkscale',
    level: 15,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 1000,
    tradeMinMaxValue: '200,250,400,600,1000;10000,12500,20000,30000,50000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  icypearl: {
    uid: 'icypearl',
    level: 18,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 1150,
    tradeMinMaxValue: '230,290,460,690,1150;11500,14500,23000,34500,57500',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 3,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  whitesand: {
    uid: 'whitesand',
    level: 13,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 2000,
    tradeMinMaxValue: '400,500,800,1200,2000;20000,25000,40000,60000,100000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  sharpfang: {
    uid: 'sharpfang',
    level: 16,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 2250,
    tradeMinMaxValue: '450,560,900,1350,2300;22500,28000,45000,67500,115000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  mooncrystal: {
    uid: 'mooncrystal',
    level: 19,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 2500,
    tradeMinMaxValue: '500,630,1000,1500,2500;25000,31500,50000,75000,125000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 4,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  evileye: {
    uid: 'evileye',
    level: 20,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 5000,
    tradeMinMaxValue:
      '1000,1250,2000,3000,5000;50000,62500,100000,150000,250000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  silkscarab: {
    uid: 'silkscarab',
    level: 22,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 5500,
    tradeMinMaxValue:
      '1100,1400,2200,3300,5500;55000,69000,110000,165000,275000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  starmetal: {
    uid: 'starmetal',
    level: 24,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 6000,
    tradeMinMaxValue:
      '1200,1500,2400,3600,6000;60000,75000,120000,180000,300000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 5,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ancientmarble: {
    uid: 'ancientmarble',
    level: 21,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 8000,
    tradeMinMaxValue:
      '1600,2000,3200,4800,8000;80000,100000,160000,240000,400000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  overgrownvine: {
    uid: 'overgrownvine',
    level: 23,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 9000,
    tradeMinMaxValue:
      '1800,2300,3600,5400,9000;90000,115000,180000,270000,450000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  timecrystal: {
    uid: 'timecrystal',
    level: 25,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 10000,
    tradeMinMaxValue:
      '2000,2500,4000,6000,10000;100000,125000,200000,300000,500000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 6,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ectoplasm: {
    uid: 'ectoplasm',
    level: 24,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 12500,
    tradeMinMaxValue:
      '2500,3100,5000,7500,12500;125000,155000,250000,375000,625000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  sewingthread: {
    uid: 'sewingthread',
    level: 26,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 15000,
    tradeMinMaxValue:
      '3000,3800,6000,9000,15000;150000,190000,300000,450000,750000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  ghostflag: {
    uid: 'ghostflag',
    level: 28,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 17500,
    tradeMinMaxValue:
      '3500,4400,7000,10500,17500;175000,220000,350000,525000,875000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 7,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  deepcoral: {
    uid: 'deepcoral',
    level: 25,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 20000,
    tradeMinMaxValue:
      '4000,5000,8000,12000,20000;200000,250000,400000,600000,1000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  sirenshard: {
    uid: 'sirenshard',
    level: 27,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 22000,
    tradeMinMaxValue:
      '4400,5500,8800,13000,22000;220000,275000,440000,660000,1100000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  goldshell: {
    uid: 'goldshell',
    level: 29,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 30000,
    tradeMinMaxValue:
      '6000,7500,12000,18000,30000;300000,375000,600000,900000,1500000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 8,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  grimtalon: {
    uid: 'grimtalon',
    level: 30,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 45000,
    tradeMinMaxValue:
      '9000,11500,18000,27000,45000;450000,565000,900000,1350000,2250000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  crystalegg: {
    uid: 'crystalegg',
    level: 32,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 50000,
    tradeMinMaxValue:
      '10000,12500,20000,30000,50000;500000,625000,1000000,1500000,2500000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  icybreeze: {
    uid: 'icybreeze',
    level: 34,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 60000,
    tradeMinMaxValue:
      '12000,15000,24000,36000,60000;600000,750000,1200000,1800000,3000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 9,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  crabclaw: {
    uid: 'crabclaw',
    level: 32,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 80000,
    tradeMinMaxValue:
      '16000,20000,32000,48000,80000;800000,1000000,1600000,2400000,4000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  obsidian: {
    uid: 'obsidian',
    level: 34,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 90000,
    tradeMinMaxValue:
      '18000,23000,36000,54000,90000;900000,1150000,1800000,2700000,4500000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  magmacore: {
    uid: 'magmacore',
    level: 36,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 100000,
    tradeMinMaxValue:
      '20000,25000,40000,60000,100000;1000000,1250000,2000000,3000000,5000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 10,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  voidcrystal: {
    uid: 'voidcrystal',
    level: 38,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 250000,
    tradeMinMaxValue:
      '50000,63000,100000,150000,250000;2500000,3150000,5000000,7500000,12500000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  voideye: {
    uid: 'voideye',
    level: 39,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 275000,
    tradeMinMaxValue:
      '55000,69000,110000,165000,280000;2750000,3450000,5500000,8250000,14000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  voidcloth: {
    uid: 'voidcloth',
    level: 40,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 290000,
    tradeMinMaxValue:
      '58000,73000,115000,175000,290000;2900000,3650000,5800000,8700000,14500000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  mightsigil: {
    uid: 'mightsigil',
    level: 33,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 200000,
    tradeMinMaxValue:
      '40000,50000,80000,120000,200000;2000000,2500000,4000000,6000000,10000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  gracesigil: {
    uid: 'gracesigil',
    level: 33,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 200000,
    tradeMinMaxValue:
      '40000,50000,80000,120000,200000;2000000,2500000,4000000,6000000,10000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  sparksigil: {
    uid: 'sparksigil',
    level: 33,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 200000,
    tradeMinMaxValue:
      '40000,50000,80000,120000,200000;2000000,2500000,4000000,6000000,10000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 11,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  mightsigil2: {
    uid: 'mightsigil2',
    level: 35,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 400000,
    tradeMinMaxValue:
      '80000,100000,160000,240000,400000;4000000,5000000,8000000,12000000,20000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  gracesigil2: {
    uid: 'gracesigil2',
    level: 35,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 400000,
    tradeMinMaxValue:
      '80000,100000,160000,240000,400000;4000000,5000000,8000000,12000000,20000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  sparksigil2: {
    uid: 'sparksigil2',
    level: 35,
    type: 'm',
    xp: 0,
    craftXp: 0,
    value: 400000,
    tradeMinMaxValue:
      '80000,100000,160000,240000,400000;4000000,5000000,8000000,12000000,20000000',
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 12,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 0,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  uncommon: {
    uid: 'uncommon',
    level: 1,
    type: 'z',
    xp: 0,
    craftXp: 0,
    value: 0,
    tradeMinMaxValue: null,
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 0,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 1,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  flawless: {
    uid: 'flawless',
    level: 2,
    type: 'z',
    xp: 0,
    craftXp: 0,
    value: 0,
    tradeMinMaxValue: null,
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 0,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 1,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  epic: {
    uid: 'epic',
    level: 3,
    type: 'z',
    xp: 0,
    craftXp: 0,
    value: 0,
    tradeMinMaxValue: null,
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 0,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 1,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
  legendary: {
    uid: 'legendary',
    level: 4,
    type: 'z',
    xp: 0,
    craftXp: 0,
    value: 0,
    tradeMinMaxValue: null,
    favor: 0,
    time: 0,
    atk: 0,
    def: 0,
    hp: 0,
    eva: '0',
    crit: '0',
    excl: null,
    tier: 0,
    combo: 0,
    worker1: null,
    worker2: null,
    worker3: null,
    w1BuildingReq: 0,
    w2BuildingReq: 0,
    w3BuildingReq: 0,
    resource1: null,
    r1Qty: 0,
    resource2: null,
    r2Qty: 0,
    resource3: null,
    r3Qty: 0,
    component1: null,
    c1Qty: 0,
    c1Tags: null,
    component2: null,
    c2Qty: 0,
    c2Tags: null,
    u1Req: 0,
    u2Req: 0,
    u3Req: 0,
    u4Req: 0,
    u5Req: 0,
    upgrade1: null,
    upgrade2: null,
    upgrade3: null,
    upgrade4: null,
    upgrade5: null,
    upgradeBonus: 0,
    supgrade1: null,
    supgrade2: null,
    supgrade3: null,
    su1Cost: 0,
    su2Cost: 0,
    su3Cost: 0,
    restrict: null,
    reqTags: null,
    tagIndex: 1,
    elements: null,
    skill: null,
    lTag2: null,
    lTag3: null,
    elementAffinity: null,
    spiritAffinity: null,
    tag: null,
    discount: 0,
    surcharge: 0,
    suggest: 0,
    speedup: 0,
    buyAnimIdOverride: 0,
    questAnimIdOverride: 0,
    slotOverride: null,
    soundType: null,
    unlock: 0,
    chest: null,
    firstOfLine: false,
    prohibited: false,
    hasChinaTexture: false,
    nonCraftable: false,
    releaseAt: null,
    shardPrice: 0,
    capriceDelay: 0,
  },
};
