export enum TradeType {
  Offer = 'o',
  Request = 'r',
}

export enum ItemType {
  Sword = 'ws',
  Axe = 'wa',
  Dagger = 'wd',
  Mace = 'wm',
  Spear = 'wp',
  Bow = 'wb',
  Wand = 'ww',
  Sceptre = 'wt',
  Gun = 'wg',
  Crossbow = 'wc',
  HeavyChest = 'ah',
  MediumChest = 'am',
  LightChest = 'al',
  HeavyHelmet = 'hh',
  MediumHelmet = 'hm',
  LightHelmet = 'hl',
  HeavyGloves = 'gh',
  LightGloves = 'gl',
  HeavyBoots = 'bh',
  LightBoots = 'bl',
  Herbal = 'uh',
  Potion = 'up',
  Scroll = 'us',
  Shield = 'xs',
  Ring = 'xr',
  Amulet = 'xa',
  Cape = 'xc',
  Pet = 'xf',
  Food = 'fm',
  Dessert = 'fd',
}

export const ItemTypeToString = (type: ItemType): string =>
  Object.entries(ItemType).find(([, value]) => value === type)?.[0] ?? '-';

export type IShowcase = Record<Showcase, ItemType[]>;

export enum Showcase {
  Shelves = 'shelves',
  Outfits = 'outfits',
  Table = 'table',
  WeaponRack = 'weaponRack',
}

export const FilterByShowcase = {
  [Showcase.Shelves]: [
    ItemType.Herbal,
    ItemType.Potion,
    ItemType.Scroll,
    ItemType.Ring,
    ItemType.Amulet,
    ItemType.Pet,
    ItemType.Food,
    ItemType.Dessert,
    ItemType.Dessert,
  ],
  [Showcase.Outfits]: [
    ItemType.LightHelmet,
    ItemType.MediumHelmet,
    ItemType.HeavyHelmet,
    ItemType.Cape,
    ItemType.LightChest,
    ItemType.MediumChest,
    ItemType.HeavyChest,
    ItemType.HeavyGloves,
    ItemType.LightGloves,
    ItemType.HeavyBoots,
    ItemType.LightBoots,
  ],
  [Showcase.Table]: [
    ItemType.Dagger,
    ItemType.Wand,
    ItemType.Bow,
    ItemType.Gun,
    ItemType.Crossbow,
    ItemType.Shield,
  ],
  [Showcase.WeaponRack]: [
    ItemType.Sword,
    ItemType.Mace,
    ItemType.Axe,
    ItemType.Spear,
    ItemType.Sceptre,
  ],
};
