import { Button, Upload, UploadProps, notification } from 'antd';
import React from 'react';
import { IProfileImport } from 'src/Types/profile';
import { useBlueprints } from 'src/hooks/useBlueprints';
import { useProfile } from 'src/hooks/useProfile';

export const UploadProfileButton: React.FC = () => {
  const [, setProfile] = useProfile();
  const [, setBlueprints] = useBlueprints();

  const handleUpload: UploadProps['beforeUpload'] = (file) => {
    if (!file) {
      notification.warning({ message: 'Failed to import' });
      return false;
    }

    const reader = new FileReader();
    reader.onload = function () {
      const profileString = this.result;
      if (!profileString || typeof profileString !== 'string') {
        notification.error({ message: 'Failed to import', description: 'Failed to read file' });
        return false;
      }

      try {
        const profileImport = JSON.parse(this.result as string) as IProfileImport;
        if (!profileImport.profile) {
          notification.error({ message: 'Failed to import', description: '`username` field is missing' });
          return false;
        }

        setProfile(profileImport.profile);
        setBlueprints(profileImport.blueprints);

        notification.success({ message: `Successfully imported - ${profileImport.profile.username}` });
      } catch (e) {
        notification.error({
          message: 'Failed to import',
          description: `Failed to parse JSON - ${e instanceof Error ? e.message : 'ERROR'}`,
        });
        return false;
      }
    };

    reader.readAsText(file);

    return false;
  };

  return (
    <Upload accept={'application/json'} maxCount={1} beforeUpload={handleUpload} fileList={[]}>
      <Button>Import</Button>
    </Upload>
  );
};
