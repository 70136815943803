import { initialBlueprintsValue } from 'src/Constants/profile';
import { IAcknowledgedBlueprints } from 'src/Types/profile';
import { useLocalStorage } from 'usehooks-ts';

type IBlueprintCheck = (blueprint: string) => boolean;

export const useBlueprints = (): [...ReturnType<typeof useLocalStorage<IAcknowledgedBlueprints>>, IBlueprintCheck] => {
  const [blueprints, setBlueprints] = useLocalStorage('blueprints', initialBlueprintsValue);

  const blueprintSet = new Set(blueprints);
  const checkBlueprint: IBlueprintCheck = (blueprint) => blueprintSet.has(blueprint);

  return [blueprints, setBlueprints, checkBlueprint];
};
