import { Button, Layout, Menu } from 'antd';
import React, { useCallback, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { initialProfileValue } from 'src/Constants/profile';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { ApiDataStore } from 'src/redux/apiData';
import { useLocalStorage } from 'usehooks-ts';

export const BasicLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  const location = useLocation();
  const [profile] = useLocalStorage('profile', initialProfileValue);

  const dispatch = useAppDispatch();
  const autoReload = useAppSelector(ApiDataStore.Select.autoReload);

  const refetch = useCallback(() => dispatch(ApiDataStore.Actions.load()), []);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!autoReload) return;

    const interval = setInterval(() => {
      if (autoReload) refetch();
    }, 120000);

    return () => clearInterval(interval);
  }, [autoReload]);

  return (
    <Layout className={'h-screen'}>
      <Layout.Header className={'flex items-center justify-between gap-4 bg-white'}>
        <Menu
          className={'min-w-0 flex-auto border-b-0 mb-1'}
          mode={'horizontal'}
          theme={'light'}
          selectedKeys={[location.pathname]}
          items={[
            { key: '/exp', label: <Link to={'/exp'}>Exp table</Link> },
            { key: '/energy', label: <Link to={'/energy'}>Energy table</Link> },
          ]}
        />

        <Link to={'/profile'}>
          <Button type={location.pathname.includes('/profile') ? 'primary' : 'default'} className={'btn btn-blue'}>
            {profile?.username || 'Profile'}
          </Button>
        </Link>
      </Layout.Header>

      <Layout.Content>{children}</Layout.Content>
    </Layout>
  );
};
