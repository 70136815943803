import { ITranslations } from 'src/Types/translations';

export const translationsRU: ITranslations = {
  texts: {
    missing_characters: '1234567890abcdefghijknmopqrstuvyxz.₽@€őű₹є',
    item_type_accessory: 'Аксессуар',
    item_type_amulet: 'Амулет',
    item_type_armor: 'Доспех',
    item_type_armorheavy: 'Тяжелый доспех',
    item_type_armorlight: 'Одежда',
    item_type_armormedium: 'Легкий доспех',
    item_type_axe: 'Топор',
    item_type_boots: 'Тяжелая обувь',
    item_type_bow: 'Лук',
    item_type_cloak: 'Плащ',
    item_type_dagger: 'Кинжал',
    item_type_familiar: 'Фамильяр',
    item_type_feet: 'Обувь',
    item_type_bracers: 'Наручи',
    item_type_gauntlets: 'Рукавицы',
    item_type_hands: 'Перчатки',
    item_type_hat: 'Шляпа мага',
    item_type_roguehat: 'Шапка странника',
    item_type_head: 'Головной убор',
    item_type_helmet: 'Шлем',
    item_type_herb: 'Травяное лекарство',
    item_type_mace: 'Булава',
    item_type_potion: 'Зелье',
    item_type_ring: 'Кольцо',
    item_type_scrolls: 'Заклинание',
    item_type_shield: 'Щит',
    item_type_shoes: 'Легкая обувь',
    item_type_spear: 'Копье',
    item_type_staff: 'Посох',
    item_type_sword: 'Меч',
    item_type_wand: 'Волшебная палочка',
    item_type_crossbow: 'Арбалет',
    item_type_gun: 'Ружье',
    item_type_tag: 'Колдовство',
    item_type_rune: 'Руна',
    item_type_weapon: 'Оружие',
    item_type_moonstone: 'Лунный камень',
    item_type_stone: 'Камни',
    item_type_meal: 'Еда',
    item_type_dessert: 'Десерт',
    equipment: 'Снаряжение',
    consumable: 'Расходник',
    decoration: 'Декор',
    customization: 'Внешний вид',
    resource_iron: 'Железо',
    resource_iron_desc: 'Очищенное железо, готовое к ковке.',
    resource_wood: 'Древесина',
    resource_wood_desc: 'Качественная древесина. Так и просит пойти в дело.',
    resource_leather: 'Кожа',
    resource_leather_desc: 'Мягкая кожа. Гарантированное качество от Тамаса.',
    resource_herbs: 'Травы',
    resource_herbs_desc: 'Свежие травы, из которых можно что-нибудь сварить.',
    resource_steel: 'Сталь',
    resource_steel_desc: 'Очищенная сталь, лишенная примесей.',
    resource_ironwood: 'Железное дерево',
    resource_ironwood_desc:
      'Таинственная древесина, по крепости не уступающая металлу.',
    resource_fabric: 'Ткань',
    resource_fabric_desc: 'Мягкая, роскошная ткань, сшитая руками эксперта.',
    resource_oils: 'Масло',
    resource_oils_desc: 'Густое масло с намеком на магию.',
    resource_mana: 'Эфир',
    resource_mana_desc:
      'Магическая сущность, тщательно извлеченная из лей-линий.',
    resource_gems: 'Алмазы',
    resource_gems_desc: 'Чудесные и крайне дорогие драгоценные камни.',
    resource_essence: 'Сущность',
    resource_essence_desc:
      'Конденсированная эктоплазма со здоровой дозой магии.',
    resource_any: 'любой ресурс',
    adventurer: 'Герой',
    hero: 'Защитник',
    base_power: 'Базовая сила',
    critical_format: 'Шанс критического удара: {0}',
    critical_damage_format: 'Критический урон: {0}x',
    aggro_format: 'Уровень угрозы: {0}',
    aggro: 'Угроза',
    attack_short: 'АТК',
    attack: 'Атака',
    stat_attack_desc:
      'Основная наступательная способность. Позволяет наносить урон монстрам, отбирая у них здоровье.',
    attack_format: 'Атака: {0}',
    defense_short: 'ЗАЩ',
    defense: 'Защита',
    crit_bonus: 'Шанс критического удара',
    crit_mult_bonus: 'Критический урон',
    stat_defense_desc:
      'Основной параметр доспехов. Высокая защита снижает получаемый урон.',
    defense_format: 'Защита: {0}',
    power: 'Сила',
    stat_power_desc:
      'Общий показатель силы героя. Для участия в приключениях есть требования к минимальной силе.',
    power_format: 'Сила: {0}',
    evasion_short: 'УКЛ',
    evasion: 'Уклонение',
    stat_evasion_desc:
      'Способность избегать вреда и уклоняться от атак. Не может быть выше {0}.',
    critical: 'Крит. удар',
    workers: 'Мастера',
    health_short: 'ЗДР',
    health: 'Здоровье',
    stat_health_desc:
      'Способность выдерживать урон. При 0 ЗДР герои получают ранение.',
    city: 'Город',
    element: 'Стихия',
    furniture: 'Мебель',
    blueprint: 'Чертеж',
    blueprints: 'Чертежи',
    gems: 'Кристаллы',
    gender_female: 'Женский',
    gender_male: 'Мужской',
    gold: 'Золото',
    heroes: 'Перс.',
    hero_options: 'Действия с героем',
    retire: 'Уволить',
    rename: 'Переименовать',
    character_type_adventurer: 'Герои',
    character_type_hero: 'Защитники',
    character_type_worker: 'Мастера',
    free: 'Бесплатно',
    quest_damage_format: '-{0} ЗДР',
    quest_xp_format: 'Прохождение: {0}',
    bulk_requirement: 'Требования',
    fulfill: 'Выполнить',
    blueprint_min_quality: 'Базовое качество',
    quality_epic: 'Эпическое',
    quality_flawless: 'Безупречное',
    quality_legendary: 'Легендарное',
    quality_common: 'Обычное',
    quality_uncommon: 'Превосходное',
    epic_name: 'Эпическое',
    flawless_name: 'Безупречное',
    legendary_name: 'Легендарное',
    common_name: 'Обычное',
    uncommon_name: 'Превосходное',
    quest: 'Приключения',
    quest_result_miss: 'Промах',
    missing_chest: 'У вас нет никаких сундуков.',
    proficiencies: 'Может использовать',
    special_item_type_adventurer: 'Расходник',
    special_item_type_quest: 'Усиление для приключений',
    special_item_type_quester: 'Предмет для приключений',
    special_item_type_chest: 'Сундук',
    special_item_type_key: 'Ключ',
    special_item_type_roster: 'Персонажи',
    special_item_type_bpunlock: 'Открытие чертежа',
    special_item_type_bpupgrade: 'Улучшение чертежа',
    special_item_type_jackslot: 'Колесо Фортуны',
    blueprint_shard: 'Фрагмент чертежа',
    sell: 'Продать',
    buy: 'Купить',
    available: 'Доступно',
    vibrations: 'Вибрация',
    collect_all: 'Забрать все',
    team: 'Отряд',
    boosts: 'Усиление',
    jackslot: 'Колесо Фортуны',
    content_types: 'Типы содержимого',
    item_detail: 'Сведения о предмете',
    tasks_completed: 'Задание выполнено!',
    report: 'Пожаловаться',
    profile: 'Профиль',
    objectives_title: 'Цели',
    quest_floor_locked_format:
      'Чтобы открыть локацию {0} {1}, нужно пройти: {0} {2}',
    quest_boss_locked_format:
      'Чтобы открыть босса в локации {0}, нужно пройти: {0} {1}',
    required_power: 'Минимальная сила',
    class_locked: 'Класс закрыт',
    compliment_fail: 'Неудача!',
    compliment_succeed: 'Успех!',
    refuse_visitor_warning_title: 'Внимание!',
    refuse_visitor_worker_warning_body: '{0} вернется в город. Вы уверены?',
    refuse_visitor_hero_warning_body:
      'Вы не заработаете монет, но {0} вернется позже. Вы уверены?',
    craft_energy_speedup: 'Ускорить',
    achievements: 'Достижения',
    claim: 'Забрать',
    blueprint_locked: 'Сначала нужно исследовать этот чертеж.',
    name_level_format: 'Требуется {0} {1}-го ур. ',
    building_required: 'Требуемое здание',
    tap_continue: 'Коснитесь для продолжения',
    contact_us: 'Связаться с нами',
    link_device: 'Shop Titans ID',
    connect: 'Подключиться',
    FAQ: 'ЧаВо',
    informations: 'Сведения',
    hire_with_gems: 'Нанять за кристаллы',
    hire_with_gold: 'Нанять за золото',
    chest_summary_title: 'Получено:',
    item_options: 'Действия с предметом',
    discard_item: 'Разобрать',
    in_racks: 'На стойках',
    in_inventory: 'В инвентаре',
    higher_quality: 'Шанс повышения качества',
    craft_progress: 'Прогресс изготовления',
    unlocked: 'Открыто',
    not_unlocked: 'Еще не открыто',
    new_skill_unlocked: 'Открыт новый навык!',
    visitor_buyer_format: '{0} {1}-го ур. хочет купить',
    visitor_seller_format: '{0} {1}-го ур. хочет продать',
    visitor_worker_seller_format: '{0} обращается с особым предложением!',
    visitor_worker_buyer_format: '{0} хочет купить',
    visitor_trender_format: '{0} {1}-го ур. срочно хочет купить',
    visitor_buyer_bonus_sell_format: '{0} {1}-го уровня также хочет',
    visitor_worker_buyer_bonus_sell_format: '{0} также хочет',
    special_capacity: 'Максимум в наборе',
    cannot_store_upgrading_furniture:
      'Нельзя отправить на хранение улучшаемый предмет',
    cannot_start_story_quest:
      'Не соблюдены требования для участия в приключениях',
    champion_in_shop: 'Ожидает в лавке',
    view_offers: 'Посмотреть предложения',
    refill: 'Восполнить',
    refill_resource: 'Восполнить ресурс',
    refill_resources: 'Восполнить ресурсы',
    craft_missing_worker: 'У вас нет этого мастера.',
    craft_missing_resources: 'Не хватает ресурсов',
    craft_missing_components: 'Не хватает компонентов',
    craft_missing_requirements: 'Не соблюдены требования: {0}/{1}',
    craft_slots_full: 'Все ваши производственные ячейки заполнены.',
    click_open: 'Коснитесь, чтобы открыть',
    open_now: 'Открыть сейчас',
    use: 'Использовать',
    open_chest: 'Открыть сундук',
    select_chest: 'Выбрать сундук',
    full_in: 'До полного исцеления: {0}',
    next_goal_in: 'До следующей цели: {0}',
    all: 'Все',
    all_equipment: 'Все снаряжение',
    already_upgrading: 'Этот предмет уже в процессе улучшения.',
    avg_sale_price: 'Стоимость',
    back: 'Назад',
    blueprint_levelup: 'Повышение уровня чертежа!',
    blueprint_notice:
      'Откройте чертеж, чтобы узнать подробнее об этом предмете.',
    cancel: 'Отмена',
    choose_name: 'Выберите имя',
    not_enough_gems: 'Недостаточно кристаллов!',
    complete: 'Завершено',
    complete_quest: 'Статус приключения',
    compliment: 'Поболтать',
    content: 'Содержимое',
    craft: 'Создать',
    craft_started: 'Начато изготовление: {0}',
    crafted: 'Изготовлено',
    crafting_time: 'Время изготовления',
    questing_time: 'Время приключения',
    create: 'Создать',
    customers: 'Клиенты',
    customize: 'Внешность',
    destination: 'Размещение',
    discard: 'Выбросить',
    discard_adventurer_title: 'Уволить героя',
    discard_adventurer_question: 'Вы уверены, что хотите уволить этого героя?',
    disconnect: 'Отключение',
    discount: 'Понизить цену',
    discount_for: 'Понизить на',
    dismiss: 'Отправить назад',
    done: 'Готово',
    empty: 'Пусто!',
    enchant: 'Заколдовать',
    enchant_started: 'Вы начали колдовство над: {0}',
    int_furnitures: 'Место для мебели',
    out_furnitures: 'Уличный декор',
    indeco_furnitures: 'Интерьерный декор',
    download_version_failed: 'Сервер не отвечает.',
    download_failed: 'Загрузка прервана',
    not_enough_space:
      'На вашем устройстве недостаточно места для хранения данных.',
    client_outdated:
      'Ваш клиент не совместим с сервером. Требуется обновление.',
    invalid_stack:
      'Не удалось установить соединение с сервером. Проверьте свое подключение к Интернету.',
    error_checkuser: 'Ошибка запроса.',
    error: 'Ошибка',
    error_code:
      'Утеряно соединение с сервером. Чтобы продолжить игру, подключитесь снова. (Ошибка {0})',
    error_4002: 'Выполнен вход в вашу учетную запись на другом устройстве.',
    error_4003: 'Ваша учетная проверяется одним из наших представителей.',
    error_4004:
      'Ваша учетная запись заблокирована одним из наших представителей. Если вам нужна помощь, свяжитесь со службой поддержки.',
    error_4007: 'Ведутся технические работы на сервере.',
    error_4008: 'Вы были отключены по причине бездействия.',
    error_4097:
      'В связи с трауром по погибшим и пострадавшим от коронавируса обслуживание всех игр Kabam Games в Китае 4 апреля, начиная с 02:00, приостанавливается на один день.',
    error_4099:
      'Утеряно соединение с сервером. Чтобы продолжить игру, подключитесь снова. (Ошибка 4099)',
    server_maintenance: 'Ведутся технические работы на сервере.',
    server_wrong_sim: 'Ваша игра не совместима с сервером.',
    exceptional_craft: 'Шедевр!',
    expand: 'Расширение',
    expand_shop: 'Расширение лавки',
    expansion_in_progress: 'Выполняется расширение',
    favorite: 'Закладка',
    filter_by: 'Отфильтровать: {0}',
    furniture_cant_buy: 'Вы не можете купить этот предмет.',
    furniture_cant_buy_low_level:
      'У вас слишком низкий уровень для покупки этого предмета.',
    furniture_cant_upgrade:
      'У вас недостаточно золота для улучшения этого предмета.',
    furniture_new_level: '{0}: улучшено до {1}-го уровня!',
    furniture_upgrade_finished: 'Улучшение завершено!',
    hazards: 'Местные опасности',
    hire: 'Нанять',
    info: 'Сведения',
    instant_upgrade: 'Мгновенное улучшение',
    inventory: 'Инвентарь',
    max_health: 'Макс. здоровье',
    item: 'Предмет',
    items: 'Предметы',
    invest: 'Вклад',
    item_type: 'Тип предметов',
    join: 'Вступить',
    level_format: 'Уровень {0}',
    level_short_format: 'Ур. {0}',
    level_up: 'Повышение уровня!',
    storage: 'Хранилище',
    lock: 'Заблокировать',
    mastered: 'Все улучшения',
    move: 'Редактировать',
    name_error_message: 'Имя лавочника должно состоять из 3-12 знаков. ',
    new: 'Новинка!',
    blueprint_unlocks: 'Открыто чертежей',
    new_blueprint: 'Чертеж исследован!',
    new_blueprint_unlocked: 'У вас есть {0} из {1} фрагментов чертежа: {2}',
    new_craftable_item: 'Теперь доступно изготовление: {0}',
    new_item: 'Новый предмет',
    no: 'Нет',
    no_item_to_place: 'Предмет не в инвентаре',
    building_tutorial_locked: 'Чтобы открыть, выполняйте задания.',
    contents: 'Содержимое',
    not_enough_energy: 'Недостаточно энергии!',
    not_enough_gold: 'Недостаточно золота.',
    out_of_stock: 'Нет в наличии.',
    or: 'Или',
    purchase: 'Купить',
    purchase_completed: 'Вы купили: {0}',
    owned: 'В наличии',
    quality: 'Качество',
    ready: 'Готово',
    recent: 'Последние',
    reconnect: 'Подключиться снова',
    recruit: 'Нанять',
    recruit_new_adventurer: 'Создание героя',
    refuse: 'Отказать',
    repair: 'Починить',
    repair_question: 'Починить?',
    required_materials: 'Требуемые материалы',
    required_workers: 'Требуемые мастера',
    requires_building_format: 'Требуется {0} {1}-го ур.',
    requires_format: 'Требуется: {0}',
    blueprint_acquire_chest: 'Ищите фрагменты чертежей в сундуках',
    resource_storage: 'Вместимость хранилища',
    retry: 'Повторить попытку',
    rotate: 'Повернуть',
    shop: 'Лавка',
    size: 'Размер',
    sold: 'Продано',
    sort_by: 'Сортировать: {0}',
    sort_highest: 'Уровень',
    exit_game_title: 'Выйти из игры',
    exit_game_question: 'Выйти из игры?',
    spirit: 'Дух',
    start_quest: 'Начать приключение!',
    stats: 'Статистика',
    storage_full: 'Ваш инвентарь полон.',
    storage_full_runestone:
      'Вы не можете иметь больше рунных камней этого типа.',
    storage_full_enchantment: 'Ваш инвентарь колдовства полон.',
    storage_to_chest: '{0} теперь в сундуке',
    storage_to_furniture: '{0} теперь здесь: {1}',
    store: 'Магазин',
    stored_item: '{0} теперь в инвентаре',
    stored_item_no_availability:
      'Так как нет подходящей мебели, {0} теперь в инвентаре.',
    suggest: 'Предложить',
    surcharge: 'Повысить цену',
    time_left_format: 'Осталось: {0}',
    unknown: 'Неизвестно',
    unlock_blueprint_more_info: 'Откройте чертеж, чтобы узнать подробности.',
    upgrade: 'Улучшить',
    upgrade_completed: 'Вы улучшили: {0}',
    upgrade_component_1: 'Компонент 1: {0}',
    upgrade_component_2: 'Компонент 2: {0}',
    upgrade_cost: 'Стоимость улучшения',
    upgrade_quality: 'Качество x{0}',
    upgrade_started: 'Вы начали улучшение: {0}',
    upgrade_time: 'Время улучшения',
    speed_up_furniture_upgrade_title: 'Улучшение мебели',
    speed_up_furniture_upgrade_body:
      '{0} уже в процессе улучшения. Потратить кристаллы, чтобы завершить мгновенно?',
    finish_furniture_upgrade_title: 'Улучшение мебели',
    finish_furniture_upgrade_body:
      'Предмет мебели ({0}) готов к улучшению. Завершите его, прежде чем начать новое улучшение.',
    upgrade_time_display: 'Время -{0}%',
    upgrade_value: 'Стоимость x{0}',
    upgrade_unknown_error: 'Неизвестная ошибка при улучшении',
    xp: 'ОП',
    yes: 'Да',
    upgrade_need_space: 'Недостаточно места для улучшения.',
    level_up_desc: 'Поздравляем! Вы достигли {0}-го уровня!',
    exceptional_craft_desc: 'Создан исключительный предмет!',
    building_level_too_low:
      'Уровень мастера недостаточно высок для этого предмета.',
    storage_overflow:
      'Вам нужно освободить немного места в инвентаре, прежде чем убрать эту стойку.',
    full: 'Заполнено',
    details: 'Сведения',
    resource: 'Ресурс',
    special: 'Особое',
    worker: 'Мастера',
    capacity: 'Вместимость',
    upgrade_requirements: 'Требования для улучшения',
    next_in: 'След. через: {0}',
    ends_in: 'До конца: {0}',
    play_now: 'Играть',
    worker_blacksmith: 'Кузнец',
    worker_carpenter: 'Плотник',
    worker_wizard: 'Чародей',
    worker_master: 'Мастер',
    worker_academy: 'Ученый',
    worker_dragon: 'Дракон',
    worker_elven: 'Призыватель',
    worker_engineer: 'Инженер',
    worker_herbalist: 'Травница',
    worker_jeweler: 'Ювелир',
    worker_priest: 'Жрица',
    worker_tailor: 'Швея',
    worker_sundragon: 'Солнечный дракон',
    worker_moondragon: 'Лунный дракон',
    worker_cook: 'Повар',
    worker_baker: 'Пекарь',
    speed_up: 'Ускорить',
    upgrade_speedup: 'Ускорить',
    missing_bulk_requirements:
      'Заказ не может быть выполнен из-за отсутствия некоторых предметов.',
    max_adventurers_reached: 'Достигнуто максимальное количество героев.',
    invalid_quester: 'Некоторые герои слишком неопытны для этого подземелья.',
    discard_item_question: 'Сколько выбросить?',
    switch_account: 'Переключиться',
    add_credential_user_exists:
      'Уже есть учетная запись, связанная с этим пользователем. Переключиться на нее?',
    platform_connect: 'Подключение к платформе',
    add_hero_slot_desc: '+1 к макс. количеству героев',
    value: 'Стоимость',
    tap_to_speedup: 'Коснитесь для ускорения',
    tap_to_finish: 'Коснитесь для завершения',
    tap_to_collect: 'Коснитесь, чтобы забрать',
    click_to_speedup: 'Нажмите для ускорения',
    click_to_finish: 'Нажмите для завершения',
    click_to_collect: 'Нажмите, чтобы забрать',
    chest_button: 'Добыча',
    energy_tooltip_desc:
      'Торгуясь с клиентами, вы зарабатываете или тратите энергию. Максимальное количество энергии можно увеличить за счет улучшения стоек.',
    shop_size: 'Размер лавки',
    shop_size_tooltip_desc:
      'Свободное место для стоек и корзин. Мебель в подвале также учитывается.',
    gold_tooltip_desc:
      'Золото — это основная валюта лавочника. Чтобы заработать его, продавайте товары клиентам.',
    gems_tooltip_desc:
      'Кристаллы — редкая валюта, необходимая для покупки разных чудесных вещей. Их можно приобрести в лавке кристаллов!',
    cutsene_hire_question: 'Наймешь меня?',
    purchase_nosubvip_desc:
      'Наслаждайтесь следующими преимуществами в течение 30 дней',
    consumable_pack: 'Набор расходников',
    contains: 'Содержит',
    locked_item_sell: 'Продажа заблокированного предмета',
    locked_item_sell_desc:
      'Предмет, который вы хотите продать, заблокирован. Продолжить?',
    building_maxed: 'МАКС. УРОВЕНЬ',
    hp_regen_bonus: 'Скорость восстановления ЗДР',
    quest_locked_format:
      'Чтобы открыть локацию {0}, нужно достичь: {1} {2}-го уровня',
    purchase_success: 'Покупка произведена',
    objective_reroll_title: 'Замена цели',
    objective_reroll_message:
      'Вы уверены, что хотите заменить эту цель? (Осталось попыток замены: {0})',
    objective_rerolls_none_left:
      'Не осталось попыток замены. Следующая будет доступна через {0}',
    token_store_reroll_title: 'Лавка жетонов замены',
    token_store_reroll_message:
      'Вы уверены, что хотите заменить оставшиеся товары в лавке жетонов? (Осталось попыток замены: {0})',
    token_store_rerolls_none_left:
      'Не осталось попыток замены. Следующая будет доступна через {0}',
    token_store_rerolls_none_to_reroll:
      'В лавке жетонов нет товаров для замены.',
    building_levelup_desc: 'Поздравляем! Здание {0} достигло {1}-го уровня!',
    energy_saving_notice: 'Отключено (экономия энергии)',
    customer_level_too_low: 'Нет средств',
    boss: 'Босс',
    expand_instantly: 'Расширить мгновенно',
    craft_slots_all_purchased: 'У вас уже есть все производственные ячейки!',
    quest_slots_all_purchased: 'У вас уже есть все ячейки приключений!',
    component: 'Компонент',
    energy: 'Энергия',
    new_class: 'Новый класс',
    notification_city_help_expand:
      'сообщает вам о помощи с расширением вашей лавки!',
    notification_city_help_furniture_upgrade:
      'сообщает вам о помощи с улучшением вашей мебели!',
    skills: 'Навыки',
    energy_bonus: 'Энергия от продаж',
    energy_cap_bonus: 'Бонусная энергия (%)',
    filter_all: 'Все',
    filter_favorites: 'Закладки',
    filter_items: 'Предметы',
    sort_smart: 'Авто',
    sort_leveldsc: 'Высокий уровень',
    sort_levelasc: 'Низкий уровень',
    trait_counters: 'Необходимые черты',
    locked: 'Заблокировано',
    regen_minute_format: '{0}<size=-22>/мин.</size>',
    enter_name: 'Введите имя',
    no_name: 'Введите допустимое имя',
    invest_format: 'Вложить {0}',
    quest_failed: 'Неудача в приключениях',
    building: 'Здание',
    welcome_shopkeeper_message: 'Займитесь внешним видом своего лавочника!',
    info_customization: 'Редактор аватара',
    leaderboards: 'Списки лидеров',
    privacy_prompt_title: 'Наша политика',
    privacy_prompt_body_01:
      'Условия предоставления услуг и политика конфиденциальности обновлены.',
    privacy_prompt_body_02_settings:
      'Нажмите «OK», чтобы подтвердить свое согласие.',
    privacy_policy: 'Политика конфиденциальности',
    terms_of_service: 'Условия предоставления услуг',
    info_stats: 'Личная статистика',
    ranking_gld: 'Получено золота',
    ranking_stradv: 'Сила героя',
    ranking_prest: 'Престиж лавки',
    ranking_cscore: 'Счет гильдии',
    ranking_netwt: 'Стоимость гильдии',
    info_settings: 'Настройки',
    medals: 'Медали',
    level_too_low:
      'У вас слишком низкий уровень торговца для покупки этого улучшения.',
    adventurer_selector_title: 'Персонажи',
    craft_selector_title: 'Создать',
    furniture_selector_title: 'Мебель',
    inventory_selector_title: 'Инвентарь',
    slots_selector_title: 'Ячейки',
    energy_max: 'Максимум энергии',
    coming_soon: 'Скоро в игре!',
    no_match: 'Нет результатов',
    recommended_power: 'Рекомендуемая сила',
    quest_boosts: 'Усиления для приключений',
    quest_completed: 'Приключение завершено!',
    continue: 'Продолжить',
    new_skill: 'Новый навык!',
    select_quest_boosts: 'Усиления для приключений',
    select_party: 'Выбрать команду',
    select_party_remaining_format: 'Осталось: {0}',
    equip_heroes_notice:
      'Если приключения кажутся сложными, попробуйте дать своим героям снаряжение получше.',
    repair_title: 'Ремонт предметов',
    repair_desc: 'Некоторые предметы повреждены!',
    repair_subtitle: 'Починить предметы?',
    repair_subtitle_2: 'Поврежденные предметы',
    discard_all: 'Выбросить все',
    show_me: 'Показать все',
    warning: 'Внимание!',
    warning_low_power:
      'Ваша команда не обладает необходимой силой для перехода на данный уровень.',
    go_back: 'Назад',
    skins: 'Модели',
    innate_effect: 'Эффект навыка',
    class_innate: 'Врожденный навык',
    equipped_hero_skill: 'Активный лидерский навык',
    hero_effect_party: 'Эффект навыка',
    equip: 'Экипировать',
    story: 'История',
    quest_tier_1: 'Окраина города',
    quest_tier_2: 'Неукрощенные земли',
    quest_tier_3: 'Затерянный мир',
    level_required: 'Требуется ур. {0}',
    no_gear_available_one: 'Предмета типа {0} нет в наличии',
    no_gear_available_multiple: 'Предмета типа {0} или {1} нет в наличии',
    components: 'Компоненты',
    sort_recent: 'Последние',
    sort_favorite: 'Закладка',
    item_set_favorite: '{0} теперь в закладках',
    item_unset_favorite: '{0} больше не в закладках',
    auto_equip: 'Оптимизировать',
    auto_fill_racks: 'Заполнять стойки автоматически?',
    filter_components: 'Компоненты',
    filter_special: 'Особое',
    filter_weapons: 'Оружие',
    filter_armors: 'Доспех',
    filter_armor: 'Доспехи для торса',
    filter_armor2: 'Разные доспехи',
    filter_armors2: 'Разные доспехи',
    filter_accessories: 'Аксессуары',
    unlocks_at_lv: 'Открывается на уровне',
    unlocks_at: 'Доступно на ур.',
    max_furniture_reached: 'Достигнуто максимальное количество мебели',
    confirm: 'Подтвердить',
    time_to_upgrade: 'Время улучшения',
    loading: 'Загрузка',
    ok: 'OK',
    hero_skill: 'Лидерский навык',
    hero_hired: 'Нанят защитник',
    floor_selection: 'Выбор уровня',
    unlock_quest_slot_title: 'Открыть ячейку приключений?',
    unlock_quest_slot_desc: 'Отправляйте больше отрядов в приключения!',
    available_quest_slots: 'Доступные ячейки приключений',
    unlock_with_gems: 'Открыть за кристаллы',
    unlock_with_gold: 'Открыть за золото',
    goto_quests: 'Карта приключений',
    unlock_craft_slot_title: 'Открыть производственную ячейку?',
    unlock_craft_slot_desc: 'Изготавливайте больше предметов одновременно!',
    available_craft_slots: 'Доступные производственные ячейки',
    goto_blueprints: 'Чертежи',
    max_seeds_reached: 'Достигнуто максимальное количество семян.',
    skill_effect: 'Эффект навыка',
    skill_overview: 'Обзор навыков',
    element_requirements: 'Требования по стихиям',
    finish_now: 'Завершить досрочно',
    request_help: 'Попросить помощи',
    expansion_complete: 'Расширение завершено',
    shop_expansion_unlocked: 'Открыто расширение лавки!',
    worker_unlocked_title: 'Открыт мастер',
    worker_unlocked_desc:
      'Новый мастер ({0}) теперь доступен для найма в городе.',
    completed: 'Завершено',
    connecting: 'Подключение',
    sounds: 'Звук',
    music: 'Музыка',
    off: 'Откл.',
    battle_speed_format: 'Скорость боя: {0}',
    battle_speed_normal: 'Обычная',
    battle_speed_fast: 'Быстрая',
    battle_speed_skip: 'Пропустить',
    quality_level_format: 'Качество: {0}',
    quality_level_0: 'Минимальное',
    quality_level_1: 'Низкое',
    quality_level_2: 'Среднее',
    quality_level_3: 'Высокое',
    quality_level_4: 'Максимальное',
    craft_warning_high_quality_title:
      'Использование предметов высокого качества',
    craft_warning_high_quality_desc:
      '— используется в процессе изготовления. Продолжить?',
    give_warning_high_quality_desc: '— будет отдано. Продолжить?',
    give_warnng_high_quality_desc_multiple:
      'Предметы высокого качества будут отданы. Продолжить?',
    hire_adventurer: 'Нанять героя',
    customize_adventurer: 'Внешний вид',
    no_free_storage: 'Недостаточно места в хранилище.',
    furniture_cant_buy_at_capacity: 'Вы больше не можете купить этот предмет.',
    indoors_only: 'Можно размещать только в помещении.',
    customization_summary: 'Обзор',
    discard_item_title: 'Разборка: {0}',
    discard_item_header: 'Будут получены следующие ресурсы:',
    discard_item_body: 'Разобрать предмет: {0}?',
    randomize: 'Случайно',
    members: 'Участники',
    visit_shop: 'Посетить лавку',
    no_quest_slot_available: 'Нет доступных ячеек приключений.',
    craft_speedup_title: 'Сведения о создании',
    craft_speedup: 'Ускорить',
    completion_reward: 'Награда за выполнение',
    close: 'Закрыть',
    storage_capacity: 'Размер инвентаря',
    max_family_furniture_reached: 'Все распродано!',
    max_indoor_furniture_reached:
      'Расширьте свою лавку, чтобы в ней было больше места.',
    max_outdoor_furniture_reached:
      'У вас больше нет места на улице для этого предмета',
    cannot_store_last_counter: 'Прилавок нельзя убрать',
    finished: 'Завершено',
    skip: 'Пропустить',
    skip_confirm_desc: 'Вы уверены, что хотите пропустить вводную часть?',
    bulk_order_cancel_title: 'Отмена заказа',
    bulk_order_cancel_body: 'Защитник вернется позже. Отменить заказ?',
    adv_level_too_low:
      'У героя слишком низкий уровень для использования этого предмета.',
    furniture_cannot_place: 'Недостаточно места в лавке',
    new_adventurer: 'Новый герой',
    add_adventurer_slot: 'Добавить ячейку героя',
    increase_adventurer_capacity: 'Увеличить максимальное кол-во героев',
    increase_max_adventurers: 'Повысить лимит героев',
    increase_max_adventurers_question:
      'Вы уверены, что хотите повысить лимит своих героев?',
    unlock: 'Открыть',
    invalid_character: 'Допускаются только буквы и цифры',
    recruit_heroes_count: 'Наймите защитников: {0}',
    no_more_quest_slots: 'Недостаточно ячеек приключений',
    unlock_adventurer_slot_title: 'Открыть ячейку героя?',
    unlock_adventurer_slot_body:
      'Еще больше героев для отправки в приключения!',
    purchase_selector_title: 'Магазин',
    energy_saving: 'Экономия энергии',
    notifications: 'Уведомления',
    fullscreen: 'Полный экран',
    language_en: 'Английский',
    language_fr: 'Французский',
    disabled: 'Откл.',
    enabled: 'Вкл.',
    edit: 'Дизайн',
    collect: 'Забрать',
    hire_me: 'НАЙМИ МЕНЯ',
    city_help_given: 'Оказана помощь!',
    busy: 'В делах',
    resting: 'Отдыхает',
    your_city: 'Ваша гильдия',
    character_gained_xp: 'Опыт героя {0} повышается до {1}%',
    surcharge_seller: 'Заплатить больше',
    discount_seller: 'Заплатить меньше',
    claim_reward: 'ЗАБРАТЬ НАГРАДУ!',
    connected: 'Подключено',
    ingame_store: 'Магазин',
    purchase_section_featured: 'Рекомендовано',
    purchase_section_offers: 'Специальные предложения',
    subscription: 'Бонусные преимущества',
    subscribed: 'Бонусные преимущества',
    hired: 'Наняли!',
    purchase_section_gems: 'Кристаллы',
    purchase_usd_value_format: 'Стоимость: {0} USD!',
    purchase_tag1: 'Популярное!',
    purchase_tag2: 'Выгодно!',
    premium_worker: 'Особый мастер',
    purchase_tab_gems: 'Кристаллы',
    purchase_tab_specialoffers: 'Специальные предложения',
    purchase_tab_features: 'Рекомендовано',
    new_worker: 'Новый мастер',
    furniture_skins: 'Модели мебели',
    furniture_skin: 'Модель мебели',
    purchase_nosubvip_name: 'Королевский торговец',
    purchase_vip2_name: 'Королевский торговец',
    vip_title_01: 'Оформите подписку и получите титул',
    vip_title_02: 'Королевский торговец',
    vip_perks_title: 'Королевские преимущества',
    vip_perk_1_desc: 'Починка предметов за золото!',
    vip_perk_2_desc: 'Бонусные VIP-награды!',
    vip_perk_3_desc: '+10% кристаллов в наборах!',
    vip_perk_4_desc: 'Более выгодные предложения на «Колесе Фортуны»!',
    vip_perk_5_desc: 'Ранний доступ к предложениям!',
    join_now: 'Купить!',
    vip_price_format: '{0}/месяц',
    repair_with_gold: 'Починить за золото',
    click_continue: 'Нажмите для продолжения',
    purchase_sitems1_name: 'Свитки исследования',
    purchase_sitems2_name: 'Монеты Аргона',
    purchase_sitems3_name: 'Монеты Лилу',
    purchase_sitems4_name: 'Осколки усиления',
    purchase_sitems5_name: 'Монеты Сиа',
    purchase_sitems6_name: 'Монеты Ями',
    purchase_sitems1_desc:
      'Немедленно оправьтесь после любого боя благодаря набору целителя!',
    purchase_sitems2_desc: 'Редкие монеты, используемые для усиления Аргона.',
    purchase_sitems3_desc:
      'Редкие монеты, используемые для найма и усиления Лилу.',
    purchase_sitems4_desc:
      'Сделайте любое приключение более быстрым и выгодным благодаря набору усилений!',
    purchase_sitems5_desc:
      'Редкие монеты, используемые для найма и усиления Сиа.',
    purchase_sitems6_desc:
      'Редкие монеты, используемые для найма и усиления Ями.',
    purchase_section_boosts: 'Наборы предметов',
    notification_channel_PullBack: 'Отчёт по лавке',
    notification_channel_PullBack_desc: 'В лавке ждут клиенты!',
    notification_idle_title: 'Лавка без присмотра',
    notification_idle_body:
      'Клиенты толпятся у двери. Не заставляйте их ждать!',
    notification_channel_ResourcesFull: 'Отчёт о ресурсах',
    notification_channel_ResourcesFull_desc:
      'У вас полно ресурсов! Приходите и изготовьте что-нибудь.',
    notification_resources_complete_title: 'Ресурсы восполнены',
    notification_resources_complete_body:
      'Ваши запасы полны и можно приступать к изготовлению предметов!',
    notification_channel_CraftingComplete: 'Отчёт по изготовлению',
    notification_channel_CraftingComplete_desc: 'Всё изготовлено! Забирайте!',
    notification_crafting_complete_title: 'Работа выполнена',
    notification_crafting_complete_body: 'Предметы ждут, чтобы вы их забрали!',
    notification_channel_QuestingComplete: 'Отчёт о приключениях',
    notification_channel_QuestingComplete_desc:
      'Приключения закончились! Приходите за добычей!',
    notification_questing_complete_title: 'Приключения завершены',
    notification_questing_complete_body: 'Добыча ждет!',
    notification_channel_UpgradingComplete: 'Отчёт об улучшении',
    notification_channel_UpgradingComplete_desc:
      'Мебель улучшена! Улучшите что-нибудь ещё.',
    notification_upgrading_complete_title: 'Улучшение завершено',
    notification_upgrading_complete_body: 'Все улучшения мебели завершены!',
    notification_channel_BossRespawned: 'Отчёт о боссе',
    notification_channel_BossRespawned_desc:
      'Босс вернулся! Приходите сразиться с ним.',
    notification_channel_HeroesRested: 'Отчёт о героях',
    notification_channel_HeroesRested_desc:
      'Герои полностью отдохнули! Отправьте их в приключения.',
    notification_heroesrested_complete_title: 'Все подлечились',
    notification_heroesrested_complete_body:
      'Ваши герои полностью отдохнули и готовы отправляться в путь!',
    notification_channel_DailyBank: 'Отчёт о копилке',
    notification_channel_DailyBank_desc: 'Приходите за копилкой!',
    notification_daily_bank_ready_title: 'Новая копилка',
    notification_daily_bank_ready_body: 'Ваша ежедневная копилка ждет!',
    notification_channel_PetFeed: 'Отчёт о питомцах',
    notification_channel_PetFeed_desc: 'Питомцы голодны! Накормите их.',
    notification_pet_feed_title: 'Питомец хочет есть!',
    notification_pet_feed_body: '{0} ждет чего-нибудь перекусить!',
    notification_pets_feed_title: 'Питомцы голодны!',
    notification_pets_feed_body: 'Питомцы голодны, накормите их!',
    objective_reachlevel: 'Продавайте предметы, чтобы достигнуть {0}-го уровня',
    objective_furniturebuy: 'Купите: {1} x{0}',
    objective_furnitureupgrade: 'Улучшите: {1} x{0}',
    objective_hire: 'Наймите в городе мастера: {0}',
    objective_hirehero: 'Наймите героя',
    objective_craft: 'Изготовьте: {1} x{0}',
    objective_craft_blueprint: 'Изготовьте: {1} x{0}, чтобы открыть: {2}',
    objective_sell: 'Продайте: {1} x{0}',
    objective_craft_gold: 'Изготовьте: {1} на {0} золотых',
    objective_sell_gold: 'Продайте: {1} на {0} золотых',
    objective_questsend: 'Отправьте героев ({0}) в локацию {1}',
    objective_questsend_class:
      'Отправьте героев ({0}) класса {1} в приключения',
    objective_questlevel: 'Достигните {0}-го уровня в локации {1}',
    objective_questfloor: 'Пройдите приключения ({0}) в локации {1}',
    objective_questloot: 'Принесите: {1} x{0} из локации {2}',
    objective_questboss: 'Победите босса в любом подземелье',
    objective_booster: 'Используйте усиление для приключений {0} р.',
    objective_consumable: 'Используйте расходник {0} р.',
    objective_openchest: 'Откройте сундук {0} р.',
    objective_dialog_compliment: 'Поболтайте с {0} клиентами',
    objective_dialog_suggest: 'Предложите другой товар {0} р.',
    objective_dialog_surcharge: 'Повысьте цену для {0} клиентов',
    objective_dialog_discount: 'Понизьте цену для {0} клиентов',
    objective_fusion: 'Выполните синтез {0} р.',
    equip_adventurer_count:
      'Наденьте на героя {0} предм. снаряжения одновременно',
    trade_title: 'Рынок',
    trade_tab_featured: 'Рекомендовано',
    trade_tab_requests: 'Запросы',
    trade_tab_offers: 'Предложения',
    trade_tab_slots: 'Ваши списки',
    trade_create_request: 'Создать запрос',
    trade_create_offer: 'Создать предложение',
    trade_wait_for_data: 'Поиск сделок',
    trade_slot_empty: 'Готово',
    trade_slot_collect: 'Забрать!',
    trade_slot_expired: 'Срок истек',
    trade_slot_cancel_title: 'Отмена сделки',
    trade_slot_cancel_description_offer:
      'Вы уверены, что хотите отменить свою сделку? (Ваши предметы будут возвращены.)',
    trade_slot_cancel_description_request:
      'Вы уверены, что хотите отменить свою сделку? (Ваше золото или кристаллы будут возвращены.)',
    trade_slot_cancel_description_guildRequest:
      'Вы уверены, что хотите отменить свой запрос на вступление в гильдию?',
    trade_detail_gold_price: 'Цена в золоте',
    trade_detail_gems_price: 'Цена в кристаллах',
    trade_detail_gold_gain: 'Прибыль в золоте',
    trade_detail_gems_gain: 'Прибыль в кристаллах',
    trade_detail_missing_item:
      'У вас нет требуемого предмета, или он заблокирован в инвентаре.',
    trade_detail_missing_sitem:
      'У вас нет требуемого предмета, или он заблокирован в инвентаре.',
    trade_detail_higher_quality_title: 'Предмет более высокого качества',
    trade_detail_higher_quality_description:
      'Вы собираетесь использовать предмет более высокого качества, чем требуется. Продолжить?',
    trade_create: 'Создать',
    trade_create_step1: 'Создать',
    trade_create_step2: 'Количество',
    trade_create_step3: 'Цена',
    trade_create_step4: 'Длительность',
    trade_create_step5: 'Подтвердить',
    trade_create_back: 'Назад',
    trade_create_next: 'Далее',
    trade_create_confirm: 'Подтвердить',
    trade_create_request_description: 'Создать запрос',
    trade_create_offer_description: 'Создать предложение',
    trade_create_item_info_quantity: 'Количество:',
    trade_create_item_info_price: 'Цена (за единицу):',
    trade_create_quantity_title: 'Количество',
    trade_create_quantity_inventory: 'В инвентаре:',
    trade_create_quantity_max: 'Максимум:',
    trade_create_quantity_trade: 'Сделки:',
    trade_create_price_title: 'Цена',
    trade_create_price_base: 'Базовая стоимость:',
    trade_create_price_min_trade: 'Мин. стоимость сделки:',
    trade_create_time_title: 'Длительность',
    trade_create_time_tax: 'Налог:',
    trade_create_time_receive: 'Вы получаете: ',
    trade_create_time_pays: 'Вы платите:',
    trade_create_confirm_quantity: 'Количество:',
    trade_create_confirm_price: 'Цена (за единицу):',
    trade_create_confirm_price_tax: 'Цена (за единицу):',
    trade_create_confirm_time: 'Длительность:',
    trade_create_time_format: '{0} ч.',
    trade_create_button_back: 'Назад',
    trade_create_button_next: 'Далее',
    trade_create_button_confirm: 'Подтвердить',
    trade_create_TBD: 'уточняется',
    trade_create_NA: 'Н/Д',
    trade_detail_title_purchase: 'Купить на рынке',
    trade_detail_title_fulfill: 'Выполнить из инвентаря',
    trade_detail_base_value: 'Базовая стоимость:',
    trade_detail_trade: 'Сделки:',
    trade_detail_inventory: 'В инвентаре:',
    trade_detail_or: 'ИЛИ',
    trade_buy_from: 'Купить на рынке',
    trade_slot_purchase_title: 'Покупка торговой ячейки',
    trade_slot_purchase_body: 'Количество торговых ячеек повышается на 1',
    trade_selector_title: 'Рынок',
    trade_inventory_selector_title: 'Рынок',
    trade_slot_fulls: 'Все ваши торговые ячейки заполнены.',
    trade_too_low_level: 'Требуется {0}-й ур.',
    trade_too_low_level_error:
      'Вы должны достичь {0}-го уровня, чтобы торговать за кристаллы.',
    trade_collect: 'Забрать x{0}',
    trade_mine: 'Вы не можете купить свое же предложение!',
    trade_detail_fetching: 'Поиск...',
    trade_detail_processing: 'Обработка...',
    trade_purchase_error:
      'Покупка предмета невозможна из-за произошедшей ошибки.',
    trade_already_gone: 'Этот товар уже куплен другим игроком.',
    trade_data_unavailable:
      'Данные об этой сделке не найдены. Обновите страницу и повторите попытку.',
    trade_lock_unavailable:
      'Покупка предмета невозможна из-за произошедшей ошибки.',
    trade_too_expensive:
      'Сделка отменена из-за выросшей цены. Повторите попытку.',
    trade_listing_unavailable:
      'Список по этой сделке не найден. Обновите страницу и повторите попытку.',
    trade_fulfill_error:
      'Не удалось выполнить запрос на сделку. Обновите страницу и повторите попытку.',
    trade_too_cheap: 'Сделка отменена из-за выросшей цены. Повторите попытку.',
    world_map_title: 'Карта приключений',
    world_map_description: 'Открыто зон: {0}',
    world_map_quest_description: 'Зона приключений {0}',
    world_map_rewards_title: 'Доступные компоненты',
    world_map_send_party: 'Отправить команду',
    jackslot_tickets: 'Вращения колеса',
    select: 'Выбрать',
    help_all: 'Помочь всем',
    tutorial_ok: 'Ясно!',
    jackslot_title: 'Список заданий',
    goals_button: 'Цели',
    tasks: 'Задания',
    jackslot_description:
      'Выполняйте задания, чтобы попытать счастье на «Колесе Фортуны»!',
    jackslot_level: 'Уровень',
    jackslot_spin: 'Вращать',
    jackslot_no_more_spins: 'Истрачены все попытки',
    jackslot_no_more_spins_banned: 'Не осталось билетов',
    jackslot_collect_title: 'Колесо Фортуны',
    jackslot_collect_title_description: 'НАГРАДЫ',
    jackslot_collect_action: 'Забрать',
    jackslot_collect_action_vip: 'Забрать все',
    jackslot_goal_claim: 'ЗАБРАТЬ НАГРАДУ!',
    jackslot_goal_next_title: 'До следующей:',
    rewards: 'Награды',
    language_select: 'Выбрать язык',
    language_change: 'Сменить язык',
    city_creation_date_format: 'Дата создания: {0}',
    city_id_format: 'ID гильдии: {0}',
    chat_tab_world: 'Мировой чат',
    chat_tab_city: 'Чат гильдии',
    chat_flood_notif:
      'Отправлено слишком много сообщений. Последнее проигнорировано.',
    enter_text: 'Введите текст',
    finder: 'Поиск',
    city_search_title: 'Поиск гильдий',
    city_search_create_name: 'Название',
    city_search_create_name_placeholder: 'Введите название...',
    city_search_create_minLevel: 'Мин. уровень',
    city_search_create_private: 'Частный',
    city_search_create_create: 'Создать',
    city_search_item_minLevel: 'Мин. уровень: {0}',
    city_search_item_members: 'Члены гильдии: {0}/{1}',
    city_search_item_action: 'Коснитесь для вступления',
    city_search_option_minLevel_title: 'Мин. уровень',
    city_search_option_filter_title: 'Фильтр по названию',
    city_search_option_filter_placeholder: 'Введите название...',
    city_search_join_title: 'Вступить в гильдию',
    city_search_join_body: 'Вы уверены, что хотите вступить в этот город?',
    city_management_title: 'Управление гильдией',
    city_management_member_level: 'Ур. {0}',
    city_management_tab_options: 'Действия',
    notification_craft_quality: 'создает предмет: {0}',
    notification_hud_craft_quality: 'создает предмет: {1} (качество — {0})',
    notification_level_up: 'достигает <color=#FFDA00FF>{0}-го</color> уровня!',
    notification_blueprint_mastered: 'получает все улучшения для чертежа: {0}',
    notification_blueprint_unlocked: 'открывает чертеж: {0}',
    notification_shop_expand: 'расширяет свою лавку до {0}',
    notification_quest_boss_defeated: 'впервые побеждает босса локации {0}',
    notification_purchase_vip: 'теперь королевский торговец!',
    notification_member_online: 'сейчас в сети.',
    notification_member_offline: 'сейчас не в сети.',
    notification_member_leave: 'покидает город.',
    notification_member_join: 'вступает в город!',
    notification_member_kick: 'изгоняет игрока {0} из города.',
    notification_member_invest_bonus: 'делает большие вложения в здание: {0}',
    notification_member_building_rush: 'ускоряет процесс улучшения: {0}',
    settings_notification_title: 'Настройки уведомлений',
    settings_notification_body:
      'Выберите, какие уведомления будут отправляться вам на телефон.',
    settings_notifications_not_saved_title: 'Несохраненные изменения',
    settings_notifications_not_saved_body:
      'Ваши изменения не будут сохранены. Продолжить?',
    notification_craft: 'Все предметы готовы',
    notification_quest: 'Все приключения завершены',
    notification_resources: 'Корзины для ресурсов полны',
    notification_healing: 'Все герои отдохнули',
    notification_upgrades: 'Все улучшения произведены',
    notification_dailybank: 'Доступна копилка',
    notification_petfeed: 'Питомец хочет есть',
    purchase_craft_slot_unlock: 'Производственная ячейка {0}-{1}',
    purchase_quest_slot_unlock: 'Ячейка приключений {0}-{1}',
    purchase_adventurer_slot_unlock: 'Ячейка героя {0}-{1}',
    upgrade_to_flawless: 'Улучшить до <color=#00DFFFFF>БЕЗУПРЕЧНОГО</color>',
    upgrade_to_epic: 'Улучшить до <color=#D100FFFF>ЭПИЧЕСКОГО</color>',
    upgrade_to_legendary: 'Улучшить до <color=#FF8E00FF>ЛЕГЕНДАРНОГО</color>',
    city_member_count_format: 'Члены гильдии: <color=#FFD100FF>{0}/{1}',
    city_networth_format: 'Стоимость: <color=#FFD100FF>{0}',
    city_rank_format: 'Место: {0}',
    city_help_asked: 'Вы попросили город о помощи!',
    city_council: 'Гильдия',
    city_leader_rank: 'Глава гильдии',
    city_officer_rank: 'Офицер',
    city_member_rank: 'Участник',
    search: 'Поиск',
    settings: 'Настройки',
    rankings: 'Рейтинг',
    help: 'Помощь',
    city_shop: 'Магазин гильдии',
    city_type: 'Статус гильдии',
    city_type_public: 'Открытый для всех',
    city_type_private: 'Частный',
    city_type_invites: 'По приглашениям',
    min_level: 'Мин. уровень',
    language: 'Язык',
    city_language_english: 'Английский',
    city_language_french: 'Французский',
    city_language_international: 'Международный',
    save: 'Сохранить',
    city_rankings: 'Рейтинг гильдий',
    click_to_join: 'Нажмите для вступления',
    tap_to_join: 'Коснитесь для вступления',
    click_for_details: 'Нажмите, чтобы узнать подробнее',
    tap_for_details: 'Коснитесь, чтобы узнать подробнее',
    top_networth: 'Стоимость',
    investments: 'Вложения',
    prestige: 'Престиж',
    top_helper: 'Помощник',
    offer_help: 'Предложение помощи',
    daily_guild_coins: 'Ежедневные монеты гильдии',
    guild_coins: 'Монеты гильдии',
    nobody_asking_for_help: 'Никто сейчас не просит помощи',
    city_finder: 'Поиск гильдий',
    city_top_recommendations: 'Рекомендуемые города',
    search_more: 'Поиск',
    create_city: 'Создать гильдию',
    networth: 'Стоимость',
    helped: 'Оказана помощь',
    last_active: 'Последняя активность',
    low_memory_notice:
      'Объем памяти вашего устройства не позволяет изменить качество.',
    member_help_expand: 'Улучшение лавки до {0}-го ур.',
    member_help_furniture: 'Улучшение мебели ({0}) до {1}-го ур.',
    options: 'Действия',
    promote: 'Повысить',
    demote: 'Понизить',
    kick: 'Изгнать',
    chest_contains_format: 'Содержит {0} из следующего:',
    unlock_quest: 'Открыть приключение',
    unlock_quest_question: 'Открыть приключение: {0}?',
    adv_level_up_desc: 'Поздравляем! {0} достигает {1}-го уровня!',
    last_active_online: 'В сети',
    last_active_ago: '{0} назад',
    goto_quest: 'Перейти: {0}',
    unlock_quest_format: 'Открыть приключение: {0}',
    loading_updating_data: 'Обновление статических данных (<1 МБ)',
    loading_connecting: 'Открываем городские ворота...',
    loading_warmPools: 'Размышляем об уклонах...',
    loading_loadScene: 'Снаряжаем героев...',
    loading_warmUI: 'Будим старейшину Оуэна...',
    loading_downloading: 'Загружаем все интересное...',
    break_bonus: 'Шанс повреждения',
    hero_story_level_requirement:
      'Для следующего приключения требуется защитник {0}-го ранга.',
    hero_story_finished: 'История завершена',
    hero_story_complete_for: 'Завершить за',
    hero_story_requirements: 'Коснитесь для вклада',
    rank_format: 'Ранг: {0}',
    rank_up_for: 'Повысить ранг за',
    quest_setup_consumable: 'Усиление',
    quest_setup_difficulty_easy: 'Сложность: легко',
    quest_setup_difficulty_medium: 'Сложность: нормально',
    quest_setup_difficulty_hard: 'Сложность: трудно',
    quest_setup_difficulty_extreme: 'Сложность: экстрим',
    quest_setup_difficulty_boss: 'Босс',
    quest_setup_begin_explore: 'Исследовать',
    quest_setup_begin_boss: 'Бой с боссом',
    quest_setup_unlock_location: 'Открытие места приключений',
    quest_setup_back_map: 'Карта',
    quest_setup_choose_reward: 'Выбрать зону',
    quest_level_up_desc: '{0} достигает {1}-го уровня!',
    component_bonus: 'Бонус компонентов',
    leaderboard_rank_title: 'Место',
    leaderboard_rank_format: '{0}',
    leaderboard_rank_empty: '-',
    quest_setup_unlocks_level_format: 'Откроется на {0}-м уровне приключений',
    reward_increase: 'Улучшение подземелья',
    quest_notice_boss_unavailable_title: 'Босс недоступен',
    quest_notice_boss_unavailable_busy:
      'Другая команда сейчас сражается с боссом.',
    quest_notice_boss_unavailable_cooldown: 'Будет доступен снова через {0}',
    quest_notice_target_locked_title: 'Неисследованная зона',
    quest_notice_target_locked: 'Требуется {0} {1}-го уровня',
    quest_notice_location_locked_title: 'Неисследованное место',
    quest_notice_location_locked: 'Требуется {0} {1}-го уровня',
    auth_failed: 'Не удалось произвести вход',
    area_unlocked: 'Зона открыта',
    go_again: 'Вернуться',
    new_discovery: 'Обнаружено новое подземелье',
    tokens: 'Жетоны Фортуны',
    currency: 'Валюта',
    tokens_tooltip_desc:
      'Получайте жетоны Фортуны на «Колесе Фортуны» и тратьте их на замечательные призы в магазине!',
    tokens_missing_hire: 'Соберите больше монет защитника, чтобы нанять: {0}!',
    tokens_missing_upgrade:
      'Соберите больше монет защитника, чтобы улучшить: {0}!',
    zoom_in: 'Приблизить камеру',
    zoom_out: 'Отдалить камеру',
    special_item_type_token: 'Монета защитника',
    champion_tokens: 'Монеты защитника',
    upgrade_hero: 'Монеты улучшения',
    story_level_up_title: 'Повышение ранга!',
    story_level_up_desc: 'Поздравляем! {0} достигает {1}-го ранга!',
    locked_item_give: 'Предмет заблокирован',
    locked_item_give_desc:
      'Предмет, который вы хотите отдать, заблокирован. Продолжить?',
    notification_member_invest: 'вкладывает {0} в здание: {1}',
    notification_member_invest_generic: 'делает вложения в здание: {0}',
    rested_in: 'Восстановит силы через',
    healed_in: 'Исцелится через:',
    not_enough_tokens: 'Недостаточно жетонов Фортуны.',
    rest_or_heal_bonus: 'Время отдыха',
    hero_rank_upgrade_desc:
      'Повышение ранга защитника делает его гораздо сильнее. Он даже внешне меняется!',
    sitem_rest_effect_desc: '-{0} от времени отдыха',
    sitem_seed_effect_desc: 'для героя (постоянно)',
    sitem_heal_effect_desc: '-{0} от времени исцеления',
    sitem_repair_effect_desc: 'Выполняется ремонт поврежденного предмета.',
    sitem_quest_desc: 'для команды.',
    sitem_quest_xp_desc: 'для команды/приключений.',
    sitem_quest_loot_desc: '+{0}% компонентов',
    mailbox: 'Почтовый ящик',
    purchase_skin: 'Купить модель',
    next_rank_up: 'Следующий ранг',
    xp_percent_format: '+{0}% ОП',
    crit_percent_format: '+{0}% к шансу крит. удара',
    token_store_vip_message:
      'Звание королевского торговца позволяет сэкономить {0}% на призах!',
    next_in_noformat: 'До следующей:',
    next_available_in_noformat: 'Следующая будет доступна через',
    generic_received: 'Получено: {0} x{1}',
    gems_received: 'Получены кристаллы!',
    item_received: 'Получен предмет!',
    special_item_received: 'Получен предмет!',
    component_received: 'Получены компоненты!',
    resource_received: 'Получены ресурсы!',
    blueprint_fragment_received: 'Получен фрагмент чертежа!',
    daily_prizes: 'Призы «Колеса Фортуны»',
    redeem: 'Получить',
    payouts: 'Выплаты',
    jackpot: 'Джекпот',
    jackslot_no_match: 'Нет совпадений',
    news_title: 'Почтовый ящик',
    news_kind_news: 'Новости',
    news_kind_event: 'Событие',
    news_kind_gift: 'Подарок',
    news_view_details: 'Подробнее',
    news_claim: 'Забрать',
    news_already_claimed: 'Уже получено',
    notification_jackslot_jackpot: 'срывает джекпот на «Колесе Фортуны»!!!',
    city_management_options_saved: 'Настройки гильдии сохранены!',
    tutorial_join_or_create_city: 'Вступите в гильдию или создайте свою!',
    sitem_restheal_family_desc: '-{0}% от времени отдыха/исцеления',
    sitem_xp_effect_desc: 'Дает герою +{0} ОП.',
    xp_detail_format: 'Текущий ОП: {0}/{1} ({2}%)',
    stat_networth_desc:
      'Общее количество золота, заработанное лавочником за его карьеру.',
    stat_investment_desc:
      'Общее количество золота, потраченное лавочником на вложения в город. Эти вложения остаются записанными на лавочника, даже если он меняет гильдию.',
    stat_helped_desc:
      'Количество членов гильдии, которым помог этот лавочник, например посредством ускорения времени улучшения мебели.',
    stat_blueprints_desc:
      'Количество чертежей, для которых этот лавочник получил все улучшения.',
    stat_prestige_desc:
      'Очки престижа, получаемые за счет модного декора. Не влияет на игровой процесс, но высокий престиж позволяет заработать место в списках лидеров.',
    stat_lastactive_desc: 'Последний раз, когда этот лавочник входил в игру.',
    stat_ascensions_desc:
      'Количество звезд усиления, полученных этим лавочником.',
    city_management_no_permission:
      'У вас нет разрешения на изменение этих настроек.',
    notification_bossrespawned: 'Босс доступен',
    low_power_quester:
      'Некоторые из ваших героев слишком слабы для участия в этом приключении.',
    jackslot_spin_auto: 'Автовращение',
    city_kick_player_title: 'Изгнать',
    city_kick_player_question: 'Этот игрок будет удален из гильдии.',
    city_promote_player_title: 'Повысить',
    city_promote_player_question: 'Этот игрок будет повышен до офицера.',
    city_demote_player_title: 'Понизить',
    city_demote_player_question:
      'Вы уверены, что хотите понизить этого игрока?',
    promote_gmswap: 'Назначить главой гильдии',
    city_promote_gmswap_player_title: 'Повысить до главы гильдии',
    city_promote_gmswap_player_question:
      'Этот офицер заменит вас на посту главы гильдии. Вы уверены?',
    purchase_discount_format: 'Скидка<br>{0}%!',
    limited_time_event: 'Временное событие',
    notification_city_help_expand_paid:
      'сообщает об оплате расширения вашей лавки!',
    notification_city_help_furniture_upgrade_paid:
      'сообщает об оплате улучшения вашей мебели!',
    help_with_gems: 'Завершить улучшение',
    help_info_title: 'Помощь члену гильдии',
    available_at_level_format: 'Доступно на {0}-м уровне',
    utils_days_abv: 'д.',
    utils_hours_abv: 'ч.',
    utils_minutes_abv: 'м.',
    utils_seconds_abv: 'с.',
    utils_timespan_ago: '{0} назад',
    utils_justnow: 'Только что',
    welcome_offer: 'Королевский прием',
    quest_setup_difficulty_easy_short: 'Легко',
    quest_setup_difficulty_medium_short: 'Средне',
    quest_setup_difficulty_hard_short: 'Трудно',
    quest_setup_difficulty_extreme_short: 'Экстрим',
    worker_special_engineer1:
      '<line-height=90%><voffset=-10><size=60><sprite name="BreakChance"></size></voffset> -{0} от шанса повреждения предметов',
    worker_special_moondragon01:
      '<line-height=90%><voffset=-10><size=60><sprite name="Fullmoon"></size></voffset> Постоянный доступ к рецептам полнолуния',
    quest_add_consumables: 'Добавить усилители',
    got_it: 'Ясно',
    go: 'В путь',
    reward: 'Награда',
    boss_battle: 'Бой с боссом',
    chance: 'Шанс',
    gold_received: 'Получено золото!',
    purchased: 'Куплено!',
    worker_bonus_title: 'Постоянный бонус',
    bonus: 'Бонус!',
    promote_swap: 'Повысить до главы гильдии',
    ignore_user_title: 'Игнорирование игрока',
    ignore_user_body:
      'Все сообщения данного игрока будут скрыты в этом сеансе игры.',
    max_rank: 'Макс. ранг',
    reroll: 'Заменить',
    locked_item_notice: 'Этот предмет заблокирован.',
    city_join_error_city_full: 'В гильдии нет мест',
    city_join_error_low_level:
      'Вы должны достичь {0}-го уровня, чтобы вступить в эту гильдию.',
    chest_enchanted_odds_desc:
      '<color=#2AE9EDFF>Магическое открытие</color> включает +1 дополнительное гарантированное выпадение <sprite name=Star><color=#C9FCFFFF>РЕДКОГО</color> или <sprite name=Star2><color=#FFD201FF>ЭКСКЛЮЗИВНОГО</color> предмета.',
    chest_enchanted_offer_desc:
      'ГАРАНТИРОВАН хотя бы 1 <sprite name=Star><color=#C9FCFFFF>редкий</color> или <sprite name=Star2><color=#FFD201FF>эксклюзивный</color> предмет',
    chest_exclusive: '<sprite name=Star2>Эксклюзив',
    exclusives: 'Эксклюзивы',
    chest_exclusive_odds: '<sprite name=Star2>Эксклюзивный сундук',
    normal_odds: 'Открыть ключом',
    enchanted_odds: 'Магическое открытие',
    odds: 'Шанс',
    blueprint_discovered_title: 'Обнаружен чертеж!',
    blueprint_discovered_subtitle:
      'Исследуйте этот чертеж, чтобы начать создание!',
    unlock_now: 'Исследовать сейчас',
    worker_level_required: 'Требуемый уровень мастера',
    max_worker_level: 'Макс. уровень мастера',
    max_level: 'Макс. уровень',
    story_requirement_format: 'Требуется {0} {1}-го ранга',
    fetch_user_invalid_version:
      'Просмотр этого профиля невозможен, так как его владелец не обновил приложение Shop Titans до последней версии.',
    error_4009:
      'Ваша учетная запись не совместима с сервером. Требуется обновление клиента.',
    error_4100:
      'Сервер только что обновлен до последней версии. Чтобы продолжить игру, подключитесь снова. (Код 4100)',
    hire_worker_card: 'Наймите: {0}',
    celebration_multicraft_title: 'Несколько предметов!',
    celebration_multicraft_body:
      'Поздравляем, теперь у вас есть {1} ({0} шт.)!',
    trade_equip_empty: 'Нет сделок',
    welcome_back_title: 'С возвращением!',
    welcome_back_description:
      'Привет, лавочник! Вот что тут происходило, пока тебя не было!',
    worker_card_xp_format: 'ОП: {0}<color=#31DD63FF>/{1}</color>',
    hire_name_format: 'Наймите: {0}',
    craft_multi_bonus: 'Шанс изготовить несколько предметов',
    generic_received_short_no_qty: 'Получено: {0}',
    unlock_blueprint_body:
      'Открыть этот чертеж за свитки чертежа ({0} шт.)? (У вас есть {1})',
    bp_unlock_insufficient: 'У вас недостаточно свитков чертежа.',
    trade_equip_selector_title: 'Рынок',
    trade_equip_wait_for_data: 'Поиск сделок',
    equip_from_trade: 'Экипироваться с аукциона',
    equip_trade_shortcut_name: 'Экипироваться с аукциона',
    equip_from_inventory: 'Экипироваться из инвентаря',
    maximum_equip_tier: 'Может носить до уровня',
    buy_from_trade: 'Купить на аукционе',
    wait: 'Подождать',
    trade: 'Рынок',
    unlock_blueprint_question: 'Открытие чертежа',
    bp_unlock_amount_title: 'В наличии',
    bp_unlock_required_amount_title: 'Требуется',
    bp_unlock_balance_amount_title: 'Баланс после открытия',
    hero_tokens_format: 'Монеты защитника ({0})',
    gm_pick_none: 'Нет',
    gm_pick_title: 'Выбор гильдии',
    gm_pick_description:
      'Вложения в строительство, отмеченное выбором гильдии, будут стоить на 10% меньше.',
    gm_pick_cooldown: 'Выбор гильдии может быть повторно изменен через {0}',
    gm_pick_current: 'Текущий выбор гильдии',
    gm_pick_activate: 'Активировать',
    chat_tab_notifications: 'Уведомления',
    rate_us_title: 'Нравится игра?',
    rate_us_description: 'Уделите минутку и оставьте отзыв в магазине!',
    rate_us_not_now: 'Не сейчас',
    rate_us_never: 'Никогда',
    rate_us_rate: 'Оцените нас',
    max: 'Макс.',
    rest_time_reduced_title: 'Сокращение времени отдыха',
    quest_time_reduced_title: 'Сокращение времени приключения',
    quest_xp_bonus_title: 'Бонусный опыт',
    quest_xp_bonus_body: 'Опыт от приключений +{0}%',
    all_characters_fully_rested: 'Ваши персонажи уже отдохнули.',
    city_management_options_no_permission:
      'Только офицеры и главы гильдии могут поменять выбор гильдии.',
    blueprint_special_upgrade_title: 'Чертеж улучшен',
    blueprint_special_upgrade_body: 'Чертеж ({0}) усилен!',
    lost_account_question: 'Уже есть учетная запись?',
    lost_account_unavailable: 'Недоступно',
    lost_account_confirmation_title: 'Восстановление учетной записи',
    lost_account_confirmation_body:
      'Вы хотите восстановить свою учетную запись. Продолжить?',
    error_checkuser_not_found:
      'Учетная запись не найдена (свяжитесь со службой поддержки)',
    chat_banned_notif: 'Вы заблокированы в чате на {0}',
    hero_hired_format: 'Нанят герой: {0}!',
    craft_low_level_worker:
      'У мастера слишком низкий уровень для изготовления этого предмета.',
    city_kick_error_being_kicked: 'Этого участника уже изгнали.',
    city_create_error_being_kicked:
      'Действие не может быть выполнено, потому что вы больше не состоите в этой гильдии.',
    craft_upgrades: 'Улучшения создания',
    special_upgrades: 'Усиления',
    special_upgrade_button_title: 'Усилить',
    upgrades: 'Улучшения',
    supgrade_insufficent: 'Недостаточно осколков для усиления.',
    missing_key: 'У вас нет подходящего ключа для этого сундука.',
    equipment_hold_trade: 'Снаряжение (удерживайте, чтобы открыть рынок)',
    hero_hired_title: 'ЗАЩИТНИК НАНЯТ!',
    hero_hired_desc: '{0} уже может отправляться в приключения!',
    equip_tier: 'Новый ранг предмета',
    complete_upgrade: 'Завершить улучшение',
    confirm_token_purchase_title: 'Награда «Колеса Фортуны»',
    confirm_token_purchase_body: 'Купить: {1} x{0} за жетоны Фортуны ({2})?',
    insufficient_bp_unlock_notice: 'Недостаточно свитков исследования',
    building_invest_format: 'Вложить в здание: {0}',
    building_unlock_format: 'Открыть: {0}',
    blueprint_quality_mult: 'Шанс повышения качества',
    special_item_revivekit1_used: 'Все персонажи восстановили силы!',
    special_item_revivekit2_used: 'Все персонажи воскрешены!',
    guild_choice_timer_format: 'Можно изменить через {0}',
    craft_warning_lock_title: 'Предмет заблокирован',
    give_warning_lock_desc:
      'Этот предмет сейчас заблокирован. Все равно использовать?',
    connecting_platform_title: 'Подключение к платформе',
    connecting_platform_desc: 'Подключение',
    push_notification_trade_complete_title: 'Рынок',
    push_notification_trade_complete_desc: 'Ваша сделка ({0}) завершена!',
    push_notification_default_title_news: 'Новости',
    push_notification_default_body_news:
      'Зайдите в игру, чтобы узнать, что там происходит новенького!',
    push_notification_default_title_event: 'Временное событие',
    push_notification_default_body_event:
      'Зайдите в игру сегодня, чтобы участвовать в событии и выжать из него максимум!',
    push_notification_default_title_gift: 'Подарок',
    push_notification_default_body_gift:
      'Зайдите в игру сегодня и получите бесплатный подарок!',
    push_news_update_title: 'Свежее обновление Shop Titans!',
    push_news_update_desc:
      'Игра Shop Titans обновлена! Зайдите и ознакомьтесь!',
    max_hero_level: 'Макс. уровень героя',
    enchanted_odds_bonus: '+1 <sprite name="Star">',
    building_invest_format_worker_details:
      'Вложить в здание: <size=+8>{0}</size>',
    unlocks_at_format: 'Откроется на ур. {0}',
    story_rewatch_dialog: 'Посмотреть еще раз',
    craft_speed_bonus: 'Ускорение создания',
    purchase_furniture: 'Купить мебель',
    purchase_furniture_body: 'Вы хотите купить такой предмет, как {0}?',
    sitem_use_rest: 'Воскресить персонажей',
    sitem_use_heal: 'Исцелить персонажей',
    sitem_revive_heal_question:
      'У {0} из отдыхающих или раненых персонажей полностью восстановится здоровье. Продолжить?',
    sitem_revive_question:
      'У {0} из отдыхающих персонажей полностью восстановится здоровье. Продолжить?',
    stat_attack_type: 'Параметр атаки',
    stat_defense_type: 'Параметр защиты',
    stat_hp_type: 'Параметр ЗДР',
    char_hp_format: '{0}: ЗДР',
    char_attack_format: '{0}: атака',
    char_defense_format: '{0}: защита',
    hero_tokens_received: 'Получены монеты защитника!',
    daily_offer: 'Ежедневное предложение',
    daily_offer_expired_title: 'Срок предложения истек',
    daily_offer_expired_body: 'Срок ежедневного предложения истек.',
    equip_tier_max: 'Макс. уровень снаряжения',
    menu: 'Меню',
    rank_up: 'Повысить ранг',
    slot_unlocks_up_to: 'До ячейки {0}',
    craft_slot_unlocks: 'Открытие производственных ячеек',
    quest_slot_unlocks: 'Открытие ячеек приключений',
    trade_slot_unlocks: 'Открытие торговых ячеек',
    adventurer_slot_unlocks: 'Открытие ячеек героя',
    notification_trades: 'Завершено сделок',
    notification_news: 'Особое событие',
    furniture_gift: 'Получен подарок!',
    furniture_gift_desc: 'Получен подарок: {0} {1}-го уровня!',
    exclusive: 'Эксклюзив',
    purchase_slot_unlock_format: 'Ячейка {0}',
    furniture_name_type_format: '{0} ({1})',
    exclusive_blueprint: 'Эксклюзивный чертеж',
    exclusive_blueprint_line: 'Серия эксклюзивных чертежей!',
    event_workertrial_name: 'Мастер на пробный срок',
    event_engineertrial_desc:
      'В течение ограниченного времени инженер будет доступен всем игрокам бесплатно!',
    event_scholartrial_desc:
      'В течение ограниченного времени ученый будет доступен всем игрокам бесплатно!',
    event_dragontrial_desc:
      'В течение ограниченного времени лунный дракон бесплатен для всех игроков!',
    event_cooktrial_desc:
      'В течение ограниченного времени повар доступен всем игрокам бесплатно!',
    no_chest: 'Нет сундуков',
    special_card_desc: 'Коснитесь для просмотра',
    daily_offer_upgrade_equipment_quality: 'Улучшение качества',
    upgrade_equipment_quality_desc:
      'Улучшите мощный предмет до следующего уровня качества!',
    daily_offer_upgrade_furniture: 'Скидка на мгновенное улучшение',
    daily_offer_upgrade_furniture_desc:
      'Получите <color=#FFDF00FF>скидку {0}%</color> при улучшении такого предмета, как {1}, с {2}-го до {3}-го уровня!',
    daily_offer_open_chest: 'Особая скидка на сундуки',
    daily_offer_open_chest_desc:
      'Получите <color=#FFDF00FF>скидку {0}%</color>, если откроете {1} сейчас!',
    daily_offer_open_chest_progress: 'Обнаружено: <sprite name=Star2>{0}/{1}',
    daily_offer_banner_title: 'Ежедневное предложение',
    exceptional_multi_craft_desc: 'Создано ДВА исключительных предмета!',
    item_tier_type_format: '{1} {0}-го уровня',
    daily_offer_quality_upgraded_title: 'Предмет улучшен!',
    worker_level_too_low:
      'У мастера слишком низкий уровень для создания этого предмета.',
    subscribe_now: 'Оформите подписку!',
    bp_unlock_tooltip_desc:
      'Свитки исследования используются для открытия новых чертежей. Вы можете их заработать при создании множества предметов или при открытии сундуков.',
    help_gem_discount_desc:
      'Скидка {0}% при завершении улучшения члена гильдии.',
    upgrade_to_uncommon: 'Улучшить до <color=#6CE86CFF>превосходного</color>',
    bp_detail_blueprint: 'Чертеж',
    bp_detail_upgrades: 'Ориентиры',
    bp_detail_ascension: 'Усиление',
    bp_detail_resources: 'Ресурсы',
    bp_detail_workers: 'Мастера',
    bp_detail_craft: 'Создать',
    bp_detail_unlock: 'Открыть',
    bp_detail_upgrade: 'Улучшения за ориентиры',
    bp_detail_upgrade_desc:
      'Достигните ориентиров, чтобы открыть улучшения для этого чертежа.',
    bp_detail_upgrade_info:
      'Получайте дополнительные свитки исследования при каждом улучшении!',
    bp_detail_ascensions: 'Усиления',
    bp_detail_ascensions_desc:
      'Откройте истинный потенциал этого чертежа с помощью осколков усиления!',
    bp_detail_ascend_to: 'Усилить до',
    bp_detail_ascension_maxed: 'Полное усиление!',
    bp_detail_locked: 'Сначала нужно исследовать этот чертеж.',
    bp_detail_stats: 'Параметры',
    stat_skills_desc:
      'Навыки, которые герой может выучить со временем, представляют дополнительные преимущества. Чем лучше класс, тем больше навыков можно выучить (и тем они лучше!).',
    stat_skills_desc2: 'Навыки этого героя улучшены на {0}',
    all_elements: 'все стихии',
    added_on_rack: '{0} теперь на стойке.',
    booster_applied: 'Предмет {0} добавлен как усилитель.',
    blueprint_discovered: 'Обнаружен чертеж',
    preregistrationreward_name: 'Награда за предрегистрацию',
    purchase_preregistrationreward_name: 'Награда за предрегистрацию',
    daily_offer_item_upgrade_desc_format:
      'Улучшите качество предмета {1}, которым владеет {0}: <color=#{2}>{3}</color> --> <color=#{4}>{5}</color>',
    held_by: 'Владеет',
    purchase_confirmation_title: 'Покупка подтверждена!',
    purchase_confirmation_desc: 'После покупки {0} теперь у вас!',
    claim_confirmation_title: 'Получена награда!',
    claim_confirmation_desc: 'После получения {0} теперь у вас!',
    find_in_quest: 'Найти в приключениях',
    heal_card_name: 'Исцеление всех',
    heal_popup_desc: 'Исцеление всех персонажей',
    no_injured_character: 'Нет раненых персонажей.',
    all_character_healed: 'Все персонажи исцелены.',
    city_search_wait_for_invites: 'Поиск приглашений',
    furniture_gift_deco_desc: 'Вы получили: {0}',
    city_invite_player_title: 'Приглашение в гильдию',
    city_invite_player_question:
      'Вы уверены, что хотите пригласить игрока {0} в свою гильдию?',
    city_invite_player_action: 'Вы пригласили игрока {0} в свою гильдию!',
    recruit_new_hero: 'Наймите нового героя!',
    quest_slots_full: 'У вас есть все ячейки приключений!',
    enchant_item_question:
      'Заколдовать предмет <color=#{0}>{1}</color>, используя средство {2}{3}',
    enchant_item_equipped_format: 'Наложено колдовство: {0}{1}',
    skill_survive_lethal: 'Шанс {0} выдержать один смертоносный удар.',
    skill_regen: 'Восстанавливайте {0} ЗДР на каждом ходу.',
    daily_offer_quality_upgraded_desc: '',
    craft_warning_lock_desc:
      'Вы собираетесь использовать заблокированный предмет. Продолжить?',
    survive_lethal: 'Выдержите один смертоносный удар',
    regen: 'ЗДР восстанавливается на каждом ходу',
    enchant_celebration_subtitle: 'Предмет {1} успешно заколдован ({0})!',
    enchant_failure_subtitle: 'Не удалось заколдовать предмет {0}...',
    player_search_title: 'Поиск игроков',
    player_search_call_to_action:
      'Введите имя игрока, чтобы посмотреть результаты (минимум 3 знака)',
    player_search_input_placeholder: 'Введите имя...',
    player_search_wait_for_data: 'Загрузка',
    player_search_location_near: 'Поблизости',
    player_search_location_world: 'Во всем мире',
    player_search_item_more: 'Еще',
    player_search_item_wait_for_data: 'Загрузка',
    trade_banned_notif: 'Вы заблокированы на рынке на {0}',
    element_fire: 'Огонь',
    element_water: 'Вода',
    element_earth: 'Земля',
    element_air: 'Воздух',
    element_dark: 'Тьма',
    element_light: 'Свет',
    element_gold: 'Золото',
    more_details: 'Подробнее',
    element_enchantment: 'Колдовство стихий',
    enchant_celebration_title: 'Предмет заколдован!',
    enchant_failure_title: 'Колдовство не удалось!',
    spirit_enchantment: 'Колдовство духов',
    spirit_skill: 'Навык духа',
    remove_enchantment: 'Расколдовать',
    confirm_removal: 'Подтвердить удаление',
    city_search_input_placeholder: 'Введите имя...',
    city_search_wait_for_data: 'Загрузка',
    city_search_location_near: 'Поблизости',
    city_search_location_world: 'Во всем мире',
    city_search_item_more: 'Еще',
    city_search_item_wait_for_data: 'Загрузка',
    default: 'Базовый',
    skill_upgrades: 'Улучшения навыка',
    requires: 'Требуется',
    next_upgrade: 'Следующее улучшение',
    skill_reroll: 'Заменить навык',
    total: 'Всего',
    reroll_skill_title: 'Замена навыка',
    unequip: 'Убрать из экипировки',
    item_power: 'Сила предмета',
    break_chance: 'Шанс повреждения',
    current_skill: 'Текущий навык',
    city_invite_title: 'Приглашения в гильдию',
    city_invite_wait_for_data: 'Загрузка',
    city_invite_option_accept: 'Открыть',
    city_invite_option_ignore: 'Игнорировать',
    invite_city: 'Пригласить в гильдию',
    hero_skill_tier_upgrade_desc:
      '<color=#FBD218FF>Следующее улучшение:</color>',
    hero_skill_tier_upgrade_value: 'Ранг {0}',
    questing: 'Участвует в приключениях',
    free_rerolls: 'Бесплатные попытки замены',
    skill_stat_for_item_types: 'с',
    aggro_bonus: 'Уровень угрозы',
    count_left_format: 'Осталось: {0}',
    skill_count_format: 'Навыков: {0}',
    skill_rarity_format: '{0} навык',
    skill_slot_odds_format: 'Шанс для {0}-й ячейки навыка',
    worker_special_academy1:
      '<line-height=90%><voffset=-10><size=60><sprite name="XpUpgrade"></size></voffset> +25% к получаемому опыту торговца',
    dont_have_item: '{0} отсутствует в вашем инвентаре.',
    heal_party_title: 'Исцелить всех персонажей',
    heal_character_title: 'Исцелить персонажа {0}',
    heal_party_desc:
      'Все ваши персонажи смогут снова участвовать в приключениях.',
    heal_character_desc: '{0} сможет снова участвовать в приключениях.',
    heal_with_gems: 'Исцелить за кристаллы',
    heal_with_item1: 'Исцелить пером',
    heal_with_item2: 'Исцелить напитком',
    item_remove_tag_title: 'Расколдовывание',
    item_remove_tag_question:
      'Это действие освободит ячейку, но уничтожит колдовство.',
    character_busy:
      'Нельзя выполнить это действие, пока персонаж участвует в приключении.',
    remove_enchant_req_worker:
      'После найма {0} сможет расколдовать этот предмет.',
    purchase_academy1_name: 'Мудрый ученый',
    purchase_academy1_desc: 'Раскройте секреты рунных камней!',
    city_join_error_same_id: 'Вы уже являетесь членом этой гильдии!',
    daily_offer_item_upgrade_noadv_desc_format:
      'Улучшите качество предмета {0}: <color=#{1}>{2}</color> --> <color=#{3}>{4}</color>',
    skill_reroll_result: 'Заменить результат',
    reroll_again: 'Повторить замену',
    filter_enchantments: 'Колдовство',
    item_replace_tag_title: 'Замена колдовства',
    item_replace_tag_question:
      'Первоначальное колдовство будет уничтожено. Вы уверены?',
    item_replace_tag_question_success:
      'Первоначальное колдовство будет развеяно, если новое колдовство окажется успешным. Вы уверены?',
    quality_enchant_desc:
      'Качество колдовства должно соответствовать предмету.',
    purchase_blueprint_name_unlock_format: '{0} (если есть {1})',
    exclusive_blueprint_lines: 'Серия эксклюзивных чертежей!',
    required_quality: 'Требуемое качество:',
    max_capacity_format: 'Макс. вместимость: {0}',
    total_format: 'Всего: {0}',
    max_stack_format: 'Макс. набор: {0}',
    character_healed: 'Персонаж {0} исцелен',
    tag_can_enchant_quality: 'Может заколдовать предметы следующего качества:',
    craft_missing_resource: 'У вас нет требуемой корзины для ресурсов.',
    enchantments: 'Колдовство',
    your_gold_format: 'Ваше золото: {0}',
    upgrade_already_finished: 'Данное улучшение уже произведено.',
    enchant_trade_shortcut_name: 'Заколдовать с аукциона',
    enchant_trade_shortcut_desc: 'Коснитесь для просмотра',
    trade_enchant_selector_title: 'Рынок',
    craft_slots_all_purchased_title: 'Все ячейки в наличии',
    quest_slots_all_purchased_title: 'Все ячейки в наличии',
    trade_slots_all_purchased_title: 'Все ячейки в наличии',
    city_create_error_city_already_exists: 'Эта гильдия уже существует.',
    equip_trade_shortcut_desc: 'Коснитесь для просмотра',
    trade_enchant_empty: 'Нет предметов.',
    furniture_skin_apply_all: 'Применить ко всем',
    unlock_for_format: 'Открыть за {1} {0}',
    confirm_for_format: 'Подтвердить за {1} {0}',
    skin_unlock_desc_format: '{0}: эта модель будет открыта для всех',
    enchant_action: 'Заколдовать',
    change: 'Изменить',
    replace_enchantment: 'Заменить руну',
    report_offensive_name: 'Оскорбительное имя',
    report_offensive_chat: 'Оскорбления в чате',
    report_confirmation: 'На игрока {0} отправлена жалоба.',
    menu_vfx: 'Спецэффекты предметов',
    to_help: 'Помощь',
    show_locked_items: 'Показать заблокированные предметы',
    buy_with_gold: 'Купить за золото',
    buy_with_gems: 'Купить за кристаллы',
    skin_applied: 'Текущая модель',
    furniture_skin_applied_to_all:
      'Модель применяется ко всем соответствующим предметам мебели.',
    floors_and_walls_selector_title: 'Выбор',
    decoration_type_floor: 'Полы',
    decoration_type_wall: 'Обои',
    floors: 'Полы',
    walls: 'Обои',
    applied: 'Применено',
    customize_appearance: 'Внешность',
    customize_clothes: 'Одежда',
    hide_furniture: 'Скрыть мебель',
    show_furniture: 'Показать мебель',
    next: 'Далее',
    apply: 'Применить',
    contract: 'Уменьшить',
    previous_abv: 'Пред.',
    wall_applied: 'Обои применены.',
    floor_applied: 'Пол применен.',
    buy_now: 'Купить',
    avatar_editor: 'Редактор аватара',
    avatar_editor_unlocked_desc:
      'Вы открыли редактор аватара! Пора нарядиться!',
    unlock_skin_title: 'Открытие модели',
    unlock_floor_title: 'Открытие пола',
    unlock_wall_title: 'Открытие обоев',
    unlock_floor_question:
      'Этот пол станет доступным для использования у вас в лавке.',
    unlock_wall_question:
      'Эти обои станут доступными для использования у вас в лавке.',
    apply_all: 'Применить все',
    apply_to_all_question: 'Применить все',
    apply_to_all_floor_desc: 'Этот пол будет применен ко всей лавке.',
    apply_to_all_walls_desc: 'Эти обои будут применены ко всей лавке.',
    first_purchase_title: 'Предложение за первую покупку!',
    first_purchase_description:
      'Получите +{0}% кристаллов за первую покупку каждого набора!',
    first_purchase_percentage: '{0}%',
    first_purchase_purchased: 'Куплено',
    trade_tier_format: 'Уровень торговли {0}',
    new_furniture: 'Новая мебель',
    new_shop_customizations: 'Новые открытые элементы',
    bonus_cap: 'БОНУС!',
    jackslot_spin_auto_banned: 'Быстрое открытие',
    jackslot_tickets_banned: 'Билеты Фортуны',
    jackslot_spin_banned: 'Колесо Фортуны',
    no_rack: 'Предмет не находится на стойке и не может быть предложен.',
    seeds_remaining_format: '{0}/{1}',
    max_level_message: 'Макс. уровень',
    max_level_indication: 'Макс. уровень',
    skin_unlock_counter_desc: 'Эта модель будет открыта для вашего прилавка.',
    enchantable_item_missing: 'У вас нет предметов, которые можно заколдовать.',
    enchant_item_success_chance: 'Шанс {0}',
    enchant_finish: 'Завершить',
    no_booster_to_add: 'У вас больше нет усилителей этого типа.',
    daily_bank: 'Копилка',
    daily_bank_title: 'Отслеживание копилки',
    daily_bank_next_title: 'До следующей копилки:',
    new_daily_bank_ready: 'Доступна новая копилка!',
    daily_bank_description:
      'Зарабатывайте золото, чтобы наполнить копилку и получить награду!',
    daily_bank_claim_tap_text: 'Разбейте копилку, чтобы забрать награду!',
    claim_daily_bank: 'Коснитесь, чтобы РАЗБИТЬ!',
    gold_made_from_sales: 'Золото от продаж',
    score: 'Счет',
    member_settings: 'Настройки участников',
    city_management_invites_no_permission:
      'У вас нет права отправлять приглашения.',
    stat_score_desc:
      'Общий счет, привязанный к вашему личному продвижению по игре. Повышайте его путем открытия всех улучшений для чертежей, покупки улучшений и т. д.',
    guild_rank: 'Ранг гильдии',
    rank_change: 'Изменение ранга',
    rank_info_title: 'Привилегии ранга гильдии',
    rank_info_row_title: 'Ранг',
    rank_info_permission1: 'Изменение настроек гильдии',
    rank_info_permission2: 'Изгнание членов гильдии',
    rank_info_permission3: 'Изменение ранга членов гильдии',
    trade_enchant_wait_for_data: 'Поиск колдовства...',
    trade_listing_title_request: 'Запросы',
    trade_listing_title_offer: 'Предложения',
    trade_listing_recent_offer: 'Последние предложения',
    trade_listing_recent_request: 'Последние запросы',
    trade_listing_search_result: 'Результат поиска',
    trade_show_all_offers: 'Показать все предложения',
    trade_show_all_requests: 'Показать все запросы',
    purchase_token_exchange: 'Обмен',
    gems_desc: 'Редкая валюта, используемая для покупки разных чудесных вещей.',
    trade_slots_info: 'Создавайте и управляйте своими сделками здесь',
    worker_ability: 'Способность мастера',
    worker_ability_master_desc:
      'Позволяет изготавливать множество предметов 5-го уровня и выше',
    worker_ability_sundragon_desc: 'Соединяйте предметы в котле синтеза!',
    booster_removed: 'Предмет {0} удален как усилитель.',
    king_value_mult_format: '<size=-3>Выгода</size><br>х{0}!',
    recruit_hero_format: 'Нанять героя: {0}',
    filter_elements: 'Стихии',
    filter_spirits: 'Духи',
    filter_tier: 'Уровни',
    filter_quality: 'Качество',
    trade_search_title: 'Расширенный поиск',
    unlock_hero_now: 'Открыть сейчас!',
    offer: 'Предложение',
    request: 'Запрос',
    relist: 'Упорядочение списка',
    expire_in: 'До истечения срока:',
    find_player: 'найти игрока',
    invites: 'Предлагает',
    purchase_sitems7_name: 'Монеты Рудо',
    purchase_sitems7_desc:
      'Редкие монеты использовались для вербовки и расширения возможностей Рудо.',
    inventory_enchant_title: 'Выберите Чары',
    customization_summary_remove_all: 'Убрать все',
    customization_cancel_popup_title: 'Отменить изменения',
    customization_cancel_popup_body:
      'Вы уверены? Все несохраненные персональные предпочтения будут утеряны.',
    trade_price_tooltip_notax_offer: 'Ваш список (каждый)',
    trade_price_tooltip_notax_request: 'Ваш список (каждый)',
    trade_price_tooltip_withtax_offer: 'Вы получаете (каждый)',
    trade_price_tooltip_withtax_request: 'Вы платите (каждый)',
    trade_create_quality_title: 'Качество',
    trade_create_confirm_quality: 'Качество:',
    news_empty: 'Нет новостей',
    unlock_blueprint_title: 'Открыть чертеж',
    search_in: 'Поиск:',
    blueprint_found_in_chest_card: 'Находится в: {0}',
    blueprint_found_in_chest_popup: 'Чертеж находится в: {0}. Поискать его?',
    furniture_found_in_chest_popup: 'Мебель находится здесь: {0}.',
    furniture_chest_search: 'Поискать мебель',
    furniture_skin_found_in_chest_popup: 'Модель находится здесь: {0}.',
    furniture_skin_chest_search: 'Поискать модель',
    ascension_detail_upgrade_title: 'Ориентиры',
    ascension_detail_upgrade_desc:
      'Достигните ориентиров усиления, чтобы открыть улучшения для этого типа предметов.',
    ascension_detail_upgrade_info: 'Получайте одноразовые награды за усиление!',
    cannot_store_last_chest: 'Нельзя отправить на хранение последний сундук.',
    city_compatibility_error_version:
      'Ваш клиент не совместим с сервером. Требуется обновление.',
    rarity_rare: 'Редкий',
    rarity_exclusive: 'Эксклюзивный!',
    building_debt_title: 'Долг за здание',
    building_debt_description:
      'На этом здании накопился долг, но его уровень не опустится ниже {0}.',
    expansion_unlocks: 'Открытие расширений',
    expansion_unlocks_up_to: 'До расширения {0}',
    purchase_expansion_unlock_format: 'Расширение {0}',
    ascension_progress_description:
      'Усильте не менее {0} чертежей, чтобы открыть мощные улучшения',
    ascensions: 'Усиления',
    ascension_tracks: 'Следы усиления',
    total_ascensions: 'Всего усилений',
    discount_old_price: 'Старая цена: {0}!',
    customization_received: 'Получен предмет персонализации!',
    offer_has_expired: 'Срок предложения истек!',
    city_join_error_invalid_version:
      'Нельзя вступить в город, потому что ваш игровой клиент не совместим с сервером. Требуется обновление.',
    city_kick_error_invalid_version:
      'Игроков нельзя изгнать, потому что ваш игровой клиент не совместим с сервером. Требуется обновление.',
    furniture_available_in_offer_popup: 'Мебель доступна здесь: {0}',
    view_offer: 'Посмотреть предложение',
    blueprint_available_in_pack_popup:
      'Этот чертеж доступен как часть премиум-набора.',
    blueprint_available_in_pack_card: 'Доступно здесь: {0}',
    customization_purchase: 'Покупки предметов персонализации',
    bundles_left: 'Осталось комплектов: {0}',
    refill_guild_resource: 'Восполнение x{0}',
    guild_coins_tooltip_desc:
      'Монеты гильдии даются за помощь своей гильдии. Их можно потратить на наборы ресурсов или вещмешки.',
    cityboard_currency_tooltip_desc:
      'Гильдия получает очки известности каждую неделю, в зависимости от еженедельного собранных трофеев. Ее можно потратить на приобретение мощных преимуществ для всей гильдии.',
    trade_tab_guild: 'Запросы в гильдию',
    guild_request: 'Запрос в гильдию',
    trade_mine_offer: 'Вы не можете купить свое же предложение!',
    trade_mine_request: 'Вы не можете выполнить свой же запрос!',
    fulfill_request: 'Выполнить запрос',
    trade_create_duration_title: 'Длительность',
    trade_create_enchantment_title: 'Колдовство',
    no_element: 'Нет стихии',
    no_spirit: 'Нет духа',
    bountyboard_title: 'Доска поручений',
    bountyboard_timeleft:
      '<color=#843D65FF>До конца недели:</color><sprite=17>{0}',
    bountyboard_weekly_level:
      'Еженедельная известность <color=#FCD01CFF>{0}-го ур.',
    bountyboard_renown_progress: '{0}<color=#10F7FAFF>/{1}',
    bountyboard_difficulty_1: 'Легко',
    bountyboard_difficulty_2: 'Нормально',
    bountyboard_difficulty_3: 'Трудно',
    bountyboard_accept: 'Принять',
    bountyboard_abandon: 'Отказаться',
    bountyboard_timelimit: 'Время: <sprite=17> {0}',
    bountyboard_reward_title: 'Награда за поручение',
    bountyboard_unlock_info:
      'Откройте еще больше ячеек поручений, купив преимущества гильдии!',
    bountyboard_tooltip_owner: 'Дающий поручение',
    bountyboard_bounty_title: 'Поручение',
    bountyboard_bounty_title_premium: 'Королевское поручение',
    bountyboard_bounty_craft_short: 'Изготовьте: {0}',
    bountyboard_bounty_craft_specific: 'Изготовьте: {1} x{0}',
    bountyboard_bounty_slay_short: 'Рейдовое подземелье',
    bountyboard_bounty_slay_specific: 'Пройди приключения: {1} — {0}.',
    bountyboard_bounty_quest_short: 'Соберите компоненты',
    bountyboard_bounty_quest_specific: 'Соберите: {1} x{0}.',
    bountyboard_bounty_surcharge_short: 'Повысьте цену',
    bountyboard_bounty_surcharge_specific:
      'Повысьте цену для {0} предметов {1}-го уровня или выше.',
    bountyboard_bounty_discount_short: 'Понизьте цену',
    bountyboard_bounty_discount_specific:
      'Понизьте цену для {0} предметов {1}-го уровня или выше.',
    bountyboard_bounty_chest_short: 'Откройте сундуки',
    bountyboard_bounty_chest_specific:
      'Используйте магическое открытие: {1} x{0}.',
    bountyboard_bounty_upgrade_short: 'Улучшите качество предметов',
    bountyboard_bounty_upgrade_specific: 'Улучшите качество {0} предметов.',
    bountyboard_bounty_rushitem_short: 'Ускорьте создание',
    bountyboard_bounty_rushitem_specific:
      'Ускорьте создание {0} предметов {1}-го уровня или выше при помощи кристаллов.',
    bountyboard_bounty_rushupgrade_short: 'Помогите членам гильдии',
    bountyboard_bounty_rushupgrade_specific:
      'Ускорьте улучшение для членов гильдии при помощи кристаллов {0} раз.',
    bounty_board_unlocked_title: 'Доска поручений',
    bounty_board_unlocked_desc:
      'Вы открыли доску поручений! Вносите вклад для своей гильдии, выполняя поручения!',
    goals_title: 'Цели',
    goals_tasks_tab: 'Задачи',
    goals_bounty_tab: 'Поручение',
    goals_tickets_title: 'игральный билеты',
    goals_visit_spin_title_normal: 'Перейти',
    goals_visit_spin_title_banned: 'Перейти',
    goals_visit_spin_desc_normal: 'Колесо Фортуны',
    goals_visit_spin_desc_banned: 'Колесо Фортуны',
    goals_board_title: 'Перейти',
    goals_board_desc: 'Доска поручений',
    goals_bounty_reward_title: 'Награда за поручение',
    goals_bounty_rating: 'Рейтинг поручений',
    goals_bounty_unactive_title: 'Нет поручения!',
    goals_bounty_unactive_desc:
      'Примите поручение на доске поручений и получайте бонусы за монеты гильдии.',
    goals_abandon: 'Отказаться',
    goals_abandon_confirm_title: 'Отказаться',
    goals_abandon_confirm_desc:
      'Вы уверены, что хотите отказаться от этого задания?',
    goals_bounty_claim_call_to_action: 'ЗАБРАТЬ НАГРАДУ!',
    goals_bounty_abandon_cooldown: 'Отказаться снова можно будет через {0}.',
    trade_guild_tab_bottom_desc:
      'Выполняйте запросы членов своей гильдии<br>(активные списки: {0})',
    trade_detail_request_by: 'Запрос делает:',
    offer_help_tab_timers: 'Таймеры',
    offer_help_tab_requests: 'Запросы ({0})',
    trade_create_type: 'Выберите товар для',
    trade_create_title: 'создания списка',
    trade_create_offer_title: 'создания предложения',
    trade_create_offer_desc:
      'Сделайте список предметов на рынке для покупки другими игроками.',
    trade_create_request_title: 'создания запроса',
    trade_create_request_desc:
      'Сделайте запрос на предметы на рынке для выполнения кем угодно.',
    trade_create_guild_request_title: 'создания запроса в гильдию',
    trade_create_guild_request_desc:
      'Сделайте запрос на предметы в вашу гильдию (максимум один список).',
    ranking_global: 'Мир',
    ranking_country: 'Страна',
    ranking_region: 'Регион',
    quest_setup_difficulty: 'Сложность: {0}',
    keybearer_appears_in: 'До вторжения ключеносца:',
    keybearer_remaining_clears: 'Приключения: {0}',
    keybearer_available: 'Доступен ключеносец',
    completed_all_keybearers: 'Все ключеносцы побеждены!',
    not_enough_guild_coins: 'У вас недостаточно монет гильдии.',
    board_info_trophies_title: 'Трофеи',
    board_info_trophies_desc: 'Получайте трофеи за выполнение поручений!',
    board_info_weekly_renown_levels_title: 'Еженедельный прогресс по трофеям',
    board_info_weekly_renown_levels_desc:
      'Трофеи, собранные гильдией, повышают еженедельный прогресс по трофеям.',
    board_info_weekly_renown_rewards_title: 'Получение известности',
    board_info_weekly_renown_rewards_desc:
      'Каждую неделю гильдия получает очки известности в зависимости от еженедельного прогресса по трофеям.',
    board_info_guild_upgrades_boosters_title: 'Преимущества и усиления гильдии',
    board_info_guild_upgrades_boosters_desc:
      'За очки известности офицеры могут приобрести мощные усиления и преимущества для всей гильдии!',
    board_rewards_level: 'Уровень {0}',
    board_rewards_status_passed: 'Пройдено',
    board_rewards_status_next_reward: 'Следующая награда',
    board_rewards_status_current_reward: 'Текущая награда',
    board_info_title: 'Еженедельная известность',
    gcoins_received: 'ПОРУЧЕНИЕ ВЫПОЛНЕНО!',
    gcoins_received_description: 'Получены монеты гильдии: {0}',
    board_endofweek_title: 'ЕЖЕНЕДЕЛЬНАЯ ИЗВЕСТНОСТЬ',
    board_endofweek_desc:
      'На этой неделе ваша гильдия достигла уровня известности',
    board_endofweek_mvp_title: 'Самый ценный игрок',
    board_endofweek_contributors_title: 'Все участники',
    board_endofweek_resetinfo_title: 'Еженедельный прогресс обнулен!',
    board_endofweek_resetinfo_desc:
      'Выполняйте поручения, чтобы участвовать в повышении уровня известности на следующей неделе!',
    city_board_accept_error_already_taken: 'Кто-то уже принял это поручение.',
    city_board_accept_error_invalid_bounty:
      'Эта награда недействительна. Повторите попытку.',
    city_search_enter_guild_name:
      'Введите название гильдии, чтобы посмотреть результаты<br>(минимум 3 знака)',
    city_search_enter_player_name:
      'Введите имя игрока, чтобы посмотреть результаты<br>(минимум 3 знака)',
    event_ends_in_format: 'До завершения события: <sprite name=Timer> {0}',
    event_ends_in: 'До завершения события',
    only_one_guild_trade: 'У вас может быть только один запрос в гильдию.',
    bountyboard: 'Поручения',
    cityupgrades: 'Преимущества гильдии',
    luckybox: 'Вещмешок',
    guild_chest_title: 'Бонусный вещмешок',
    guild_chest_desc: 'Выберите вещмешок, чтобы получить награду!',
    open_guild_chest_title: 'Откройте вещмешок',
    guild_box_claim_text: 'Выберите вещмешок',
    next_reward: 'Следующая награда',
    quest_event_popup_progress_title: 'Награды за событие гильдии',
    quest_event_popup_progress_description:
      'Действуйте сообща со своей гильдией, чтобы собрать чистое золото и заработать замечательные призы!',
    event_ended: 'Это событие завершилось.',
    goldcity_info_header1: 'Временное событие',
    goldcity_info_body1:
      'Появился таинственный Потерянный город золота! Король Рейнгольд дает всем гильдиям средства на сбор сокровищ!',
    goldcity_info_header2: 'Чистое золото',
    goldcity_info_body2:
      'Соберите чистое золото, победив каменного часового, и добавьте его к общему количеству собранного гильдией!',
    goldcity_info_header3: 'Эксклюзивные награды',
    goldcity_info_body3:
      'Чистое золото, собранное гильдией, открывает награды для всех!',
    view_reward: 'Посмотреть награды',
    contributors: 'Участники',
    city_perks_title: 'Преимущества гильдии',
    city_perks_upgrades: 'Преимущества гильдии',
    city_perks_boosts: 'Усиления гильдии',
    city_perks_upgrade_info: 'Коснитесь, чтобы узнать подробнее.',
    city_perks_required_level: 'Требуется {0}-й уровень гильдии',
    city_perks_next: 'Далее: {0}',
    city_upgrade_level_title: 'Уровень гильдии',
    city_upgrade_level_desc:
      'Чем выше уровень гильдии, тем больше открывается преимуществ.',
    city_upgrade_storage_title: 'Вместимость хранилища',
    city_upgrade_storage_desc: 'Сундуки дают +{0} к вместимости хранилища',
    city_upgrade_stacksize_title: 'Размер набора компонентов',
    city_upgrade_stacksize_desc: 'Сундуки дают +{0} к размеру набора',
    city_upgrade_vertrack_title: 'Энергия от вертикальных стоек',
    city_upgrade_vertrack_desc: 'Вертикальные стойки дают +{0} к энергии',
    city_upgrade_mannequin_title: 'Энергия от манекенов',
    city_upgrade_mannequin_desc: 'Манекены дают +{0} к энергии',
    city_upgrade_table_title: 'Энергия от столов',
    city_upgrade_table_desc: 'Столы дают +{0} к энергии',
    city_upgrade_shelves_title: 'Энергия от полок',
    city_upgrade_shelves_desc: 'Полки дают +{0} к энергии',
    city_upgrade_commonres_title: 'Вместимость обычных ресурсов',
    city_upgrade_commonres_desc: 'Корзины дают +{0} к вместимости',
    city_upgrade_uncommonres_title: 'Вместимость необычных ресурсов',
    city_upgrade_uncommonres_desc: 'Корзины дают +{0} к вместимости',
    city_upgrade_rareres_title: 'Вместимость редких ресурсов',
    city_upgrade_rareres_desc: 'Корзины дают +{0} к вместимости',
    city_upgrade_guildchoice_title: 'Ожидание выбора гильдии',
    city_upgrade_guildchoice_desc: 'Ожидание –{0} мин.',
    city_upgrade_bountyboard_title: 'Вместимость доски поручений',
    city_upgrade_bountyboard_desc: '+{0} к доступным поручениям',
    city_upgrade_craftboost_title: 'Усиление для производства',
    city_upgrade_questboost_title: 'Усиление для приключений',
    city_upgrade_resboost_title: 'Усиление для ресурсов',
    city_upgrade_xpboost_title: 'Усиление опыта',
    city_upgrade_boost_cost_desc: 'Стоимость активации - {0}',
    city_upgrade_boost_duration_desc: 'Длительность +{0} мин.',
    city_upgrade_boost_unlock_desc: 'Открывает {0}-й уровень усиления',
    city_upgrade_tradeofferstack_title: 'Увеличение рыночных предложений',
    city_upgrade_tradeofferstack_desc:
      'Размер наборов рыночных предложений +{0}',
    city_upgrade_traderequeststack_title: 'Увеличение рыночных запросов',
    city_upgrade_traderequeststack_desc:
      'Размер наборов рыночных запросов +{0}',
    city_upgrade_commonresregen_title: 'Восстановление обычных ресурсов',
    city_upgrade_commonresregen_desc: 'Восстановление обычных ресурсов +{0}',
    city_upgrade_uncommonresregen_title: 'Восстановление необычных ресурсов',
    city_upgrade_uncommonresregen_desc:
      'Восстановление необычных ресурсов +{0}',
    city_upgrade_rareresregen_title: 'Восстановление редких ресурсов',
    city_upgrade_rareresregen_desc: 'Восстановление редких ресурсов +{0}',
    city_upgrade_coinrefillres_title: 'Вместимость наборов ресурсов',
    city_upgrade_coinrefillres_desc: 'Ежедневные наборы ресурсов +{0}',
    city_boost_craftboost_title: 'Усиление для производства',
    city_boost_craftboost_desc: '+{0} к скорости производства',
    city_boost_questboost_title: 'Усиление для приключений',
    city_boost_questboost_desc: '{0} к скорости приключений и отдыха',
    city_boost_resboost_title: 'Усиление для ресурсов',
    city_boost_resboost_desc: '+{0} к восстановлению ресурсов',
    city_boost_xpboost_title: 'Усиление опыта',
    city_boost_xpboost_desc: 'Бонус +{0} ко всему получаемому опыту',
    quest_event_user_progress_title: 'Прогресс личных вкладов',
    quest_event_user_progress_description:
      'Чтобы получить эту награду, нужно достичь минимального уровня вклада.',
    your_progress: 'Ваш прогресс:',
    city_upgrade_error_member_rank:
      'Только офицеры и главы гильдии могут купить преимущества гильдии.',
    city_boost_error_member_rank:
      'Только офицеры и главы гильдии могут купить преимущества гильдии.',
    city_search_create_gold: 'Создать за золото',
    city_search_create_gems: 'Создать за кристаллы',
    purchase_purchased: 'Куплено!',
    ranking_quest_event: 'Прогресс события',
    notification_bounty_accept: 'принимает поручение.',
    notification_bounty_abandon: 'отказывается от поручения.',
    grand_prize: 'Великолепный приз',
    send_heroes: 'Отправить героев',
    next_keybearer: 'Следующий ключеносец',
    goldcity_guild_coop_event: 'Событие гильдии',
    goldcity_description:
      'Это ограниченное по времени подземелье приносит гильдиям, которые могут бросить вызов его стражу, невероятные награды... в том числе эксклюзивный чертеж!',
    needed_format: 'Требуется {0}',
    ignore_invites_title: 'Игнорировать приглашения',
    ignore_invites_accept_title: 'Включить приглашения',
    ignore_invites_description:
      'Вы уверены? Все приглашения в гильдию, которые вы получите в будущем, будут игнорироваться автоматически.',
    ignore_invites_accept_description:
      'Приглашения в гильдию теперь будут отправляться на этот почтовый ящик.',
    quest_event_guild_change_warning:
      'Вы потеряете весь вклад ({0}), внесенный вами во время события. Вы уверены, что хотите покинуть эту гильдию?',
    bounty_celebration_popup_description: 'За выполнение вы получаете',
    bounty_celebration_popup_level_maxed: 'МАКС. УРОВЕНЬ',
    notification_bounty_accept_overlay: 'Поручение принято!',
    fetch_city_invalid_version:
      'Просмотр этого города невозможен, так как его владелец не обновил приложение Shop Titans до последней версии.',
    furniture_received: 'Получена мебель!',
    city_details_change_settings: 'Изменить настройки',
    quest_event_user_no_rankings:
      'Для просмотра списка лидеров вы должны принимать участие в событии.',
    quest_event_guild_no_rankings:
      'Для просмотра списков лидеров ваша гильдия должна принимать участие в событии.',
    visit_guild: 'Посетить гильдию',
    current_guild: 'Текущая гильдия',
    guild_info: 'Сведения о гильдии',
    goals_bounty_locked_desc:
      'Вы должны достичь {0}-го уровня, чтобы получить доступ к доске поручений.',
    guild_perk_upgrades_desc:
      'Постоянные улучшения, касающиеся всех членов гильдии.',
    guild_perk_boost_desc: 'Мощные временные усиления для всей гильдии.',
    join_guild_low_level_error:
      'Вы должны достичь {0}-го уровня, чтобы вступить в гильдию «{1}».',
    guild_box_open_celebration_title: 'Награда: {0} мешок!',
    luckybox_blue: 'синий',
    luckybox_green: 'зеленый',
    luckybox_red: 'красный',
    event_visitorsellxp_name: 'Повышение опыта торговца',
    push_event_visitorsellxp_name: 'Событие «Опыт торговца»',
    event_visitorsellenergy_name: 'Бонусная энергия',
    push_event_visitorsellenergy_name: 'Событие «Бонусная энергия»',
    event_visitorsurchargeprice_name: 'Выгодные сделки',
    push_event_visitorsurchargeprice_name: 'Событие «Повышение цены»',
    event_visitorsurchargeenergy_name: 'Искусство торговаться',
    push_event_visitorsurchargeenergy_name: 'Событие «Искусство торговаться»',
    event_crafttime_name: 'Быстрое производство',
    push_event_crafttime_name: 'Событие «Ускорение производства»',
    event_craftquality_name: 'Высшее качество',
    push_event_craftquality_name: 'Событие «Повышение качества»',
    event_questtime_name: 'Быстрые приключения',
    push_event_questtime_name: 'Событие «Быстрые приключения»',
    event_questxp_name: 'Повышение опыта в приключениях',
    push_event_questxp_name: 'Событие «Бонусный опыт в приключениях»',
    event_questcomponents_name: 'Ажиотаж вокруг добычи',
    push_event_questcomponents_name: 'Событие «Бонусная добыча»',
    event_adventurerxp_name: 'Повышение опыта героев',
    push_event_adventurerxp_name: 'Событие «Бонусный опыт героев»',
    event_adventurerrest_name: 'Быстрый отдых',
    push_event_adventurerrest_name: 'Событие «Быстрый отдых»',
    event_resourcesregen_name: 'Праздник ресурсов',
    push_event_resourcesregen_name: 'Событие «Производство ресурсов»',
    event_visitorsellxp_desc:
      'В течение ограниченного времени зарабатывайте бонусный опыт на продажах! Привлекайте клиентов!',
    event_visitorsellenergy_desc:
      'В течение ограниченного времени получайте дополнительную энергию за продажи! Пора торговаться!',
    event_visitorsurchargeprice_desc:
      'В течение ограниченного времени предметы с повышенной ценой приносят больше золота! Тратьте энергию!',
    event_visitorsurchargeenergy_desc:
      'В течение ограниченного времени при повышении цены на предметы тратится меньше энергии! Гните свою линию!',
    event_crafttime_desc:
      'В течение ограниченного времени все предметы изготавливаются гораздо быстрее! Заполните свои стойки!',
    event_craftquality_desc:
      'В течение ограниченного времени для всех предметов повышается вероятность более высокого качества! Легендарные вещи перестали быть лишь мечтой!',
    event_questtime_desc:
      'В течение ограниченного времени все приключения завершаются гораздо быстрее! Совершайте набеги на подземелья!',
    event_questxp_desc:
      'В течение ограниченного времени все приключения приносят больше опыта приключений! Пора прокачиваться!',
    event_questcomponents_desc:
      'В течение ограниченного времени во всех приключениях выпадает больше компонентов! Добыча так и прет!',
    event_adventurerxp_desc:
      'В течение ограниченного времени герои и защитники получают больше опыта! Вперед в подземелья!',
    event_adventurerrest_desc:
      'В течение ограниченного времени герои и защитники отдыхают быстрее. Хватит отлынивать!',
    event_resourcesregen_desc:
      'В течение ограниченного времени все ресурсы восполняются быстрее. Ваши корзины готовы к работе?',
    wall_found_in_chest_popup: 'Стена находится здесь: {0}',
    floor_found_in_chest_popup: 'Пол находится здесь: {0}',
    wall_available_in_offer_popup: 'Стена доступна здесь: {0}',
    floor_available_in_offer_popup: 'Пол доступен здесь: {0}',
    adjust_zoom: 'Масштаб',
    vip_loyalty_title_nosub: 'Оформите подписку и получите титул',
    vip_loyalty_title_nosub2: 'КОРОЛЕВСКОГО ТОРГОВЦА',
    vip_loyalty_title_sub: 'Ваши преимущества как',
    vip_loyalty_title_sub2: 'КОРОЛЕВСКОГО ТОРГОВЦА',
    vip_loyalty_perk_title: 'Ежемесячные преимущества',
    vip_loyalty_locked_title:
      'Чем дольше ваша подписка, тем больше вы открываете наград!',
    vip_loyalty_locked_more: 'Скоро будет еще!',
    vip_loyalty_all_unlocked: 'Открыты все награды за верность!',
    vip_loyalty_next_unlock_title:
      '<material="Curse Casual SDF White"><color=#F9DB64FF>До следующей награды:</color></material> <sprite=17>{0}',
    vip_loyalty_repairgold: 'Скидка на ремонт',
    vip_loyalty_repairgold_format:
      'Скидка на ремонт за золото <color=#68EE2DFF>–{0}</color>',
    vip_loyalty_repairgold_format_no_color: 'Скидка на ремонт за золото –{0}',
    vip_loyalty_months: '<color=#FDCF00FF>{0}</color> мес.',
    vip_loyalty_detail_title: 'Награды за верность',
    vip_loyalty_reward_title: 'КОРОЛЕВСКАЯ НАГРАДА',
    vip_loyalty_reward_reward_title: 'Вы получаете:',
    guild_perks_details_init_bountyboard_desc: 'Ячейки доски поручений',
    guild_perks_details_init_guildchoice_desc:
      'Снижение времени ожидания (мин.)',
    guild_perks_details_init_commonres_desc: 'Увеличенная корзина',
    guild_perks_details_init_uncommonres_desc: 'Увеличенная корзина',
    guild_perks_details_init_rareres_desc: 'Увеличенная корзина',
    guild_perks_details_init_vertrack_desc: 'Энергия от стойки',
    guild_perks_details_init_mannequin_desc: 'Энергия от стойки',
    guild_perks_details_init_table_desc: 'Энергия от стойки',
    guild_perks_details_init_shelves_desc: 'Энергия от стойки',
    guild_perks_details_init_storage_desc: 'Место для хранения в сундуке',
    guild_perks_details_init_stacksize_desc: 'Размер набора в сундуке',
    guild_perks_details_permanent_upgrade: 'Постоянное улучшение',
    guild_perks_details_craftboost_subtitle: 'Временное ускорение изготовления',
    guild_perks_details_questboost_subtitle:
      'Временное ускорение приключений и отдыха',
    guild_perks_details_resboost_subtitle:
      'Временное ускорение восстановления ресурсов',
    guild_perks_details_xpboost_subtitle:
      'Временный бонусный опыт (торговца, мастера, героев и от приключений)',
    guild_perks_details_max_boost: 'Макс. уровень усиления',
    guild_perks_details_max_duration: 'Длительность усиления',
    guild_perks_details_cannot_spend_renown:
      'Только офицеры и главы гильдии могут тратить очки известности',
    guild_perks_details_unlock: 'Открыть',
    guild_perks_details_upgrade: 'Улучшить',
    guild_perks_details_unlocks_at: 'Открывается на',
    guild_perks_details_next_perk_available:
      'След. преимущество доступно на {0}-м уровне',
    visitor_bubble_ad_top: 'У Оуэна для вас есть особое предложение!',
    upgrade_ad_skip: 'Смотрите рекламу!',
    upgrade_ad_skip_vip: 'Пропустить',
    upgrade_ad_skip_time_format: 'Пропустить: {0}',
    free_gift:
      '<material="Curse Casual SDF Gold Outline Darker Brown">БЕСПЛАТНО!',
    accept: 'Принять',
    visitor_worker_seller_gift_format: '{0} хочет вручить вам подарок!',
    invalid_city_name: 'Название гильдии должно состоять из {0}-{1} знаков',
    watch_ad: 'Смотреть рекламу',
    watch_ad_vip: 'Забрать',
    guild_settings: 'Настройки гильдии',
    remove_items: 'Убрать предмет',
    item_type_component: 'Компонент',
    city_trade_temporarily_disabled:
      'Запросы в гильдию на компоненты и особые предметы временно недоступны.',
    hero_detail_player_profile_button: '<size=60>Профиль игрока</size><br>{0}',
    hero_detail_user_s_hero: 'Герой игрока {0}',
    quest_normal_timer_tooltip:
      'Время, которое займет это приключение. Чем больше героев будет отправлено, тем быстрее приключение пройдет.',
    quest_boss_timer_tooltip:
      'Время, которое займет этот бой с боссом. Чем больше героев будет отправлено, тем быстрее приключение пройдет.',
    trade_request_overmaxstack:
      'У вас не может быть более {0} предм. такого типа.',
    profanity_detected: 'Название содержит непристойные слова.',
    profanity_pending_detection: 'Подключение к серверу...',
    notification_new_bounties: 'Доступны<br>новые поручения!',
    goldcity_ascension_gp_title: 'Большой приз',
    goldcity_ascension_gp_exclusive_desc:
      'Достигните <color=#FFD301FF>{0}-го уровня</color>, чтобы получить этот эксклюзив ({1})!',
    goldcity_ascension_gp_alternate_desc:
      'Достигните <color=#FFD301FF>{0}-го уровня</color>, чтобы получить этот приз!',
    goldcity_ascension_gp_claimed: 'Получена награда!',
    goals_bounty_rush_bounty: 'Срочное поручение',
    bounty_board_your_bounty: 'Ваше текущее поручение',
    settings_menu_advertisements: 'Показывать рекламные возможности',
    login_event_claim_tap_text: 'Нажмите, чтобы открыть подарок!',
    login_event_lunar_title: 'Календарь посещений на китайский Новый год',
    login_event_lunar_description:
      'Заходите в игру каждый день с <color=#F7C514FF>{0}</color> по <color=#F7C514FF>{1}</color> и получайте подарки!',
    month_format: '{1} {0}',
    month_abv_1: 'янв',
    month_abv_2: 'фев',
    month_abv_3: 'мар',
    month_abv_4: 'апр',
    month_abv_5: 'мая',
    month_abv_6: 'июн',
    month_abv_7: 'июл',
    month_abv_8: 'авг',
    month_abv_9: 'сен',
    month_abv_10: 'окт',
    month_abv_11: 'ноя',
    month_abv_12: 'дек',
    month_1: 'января',
    month_2: 'февраля',
    month_3: 'марта',
    month_4: 'апреля',
    month_5: 'мая',
    month_6: 'июня',
    month_7: 'июля',
    month_8: 'августа',
    month_9: 'сентября',
    month_10: 'октября',
    month_11: 'ноября',
    month_12: 'декабря',
    notification_trade_create:
      'запрашивает: <color={0}>{2}, качество: {1}</color> (x{3})',
    notification_trade_fulfilled: 'выполняет запрос игрока {0}!',
    notification_trade_cancel: 'отменяет свой запрос.',
    player_level_up_gems: 'Кристаллы x{0}',
    player_level_up_tradetier: 'Уровень торговли',
    player_level_up_class: 'Новые классы',
    player_level_up_hero: 'Новый защитник',
    player_level_up_location: 'Новое место',
    player_level_up_quest: 'Новая ячейка приключений',
    player_level_up_craft: 'Новая производственная ячейка',
    player_level_up_adventurer: 'Новая ячейка героя',
    player_level_up_upgrade: 'Новая ячейка улучшения',
    player_level_up_trade: 'Новая торговая ячейка',
    player_level_up_expansion: 'Новое расширение',
    player_level_up_worker: 'Доступен мастер',
    player_level_up_furniture: 'Новая мебель',
    player_level_up_decoration: 'Новый декор',
    player_level_up_detail_gems_title: 'Бонусные кристаллы',
    player_level_up_detail_basementexpansion_title: 'Новое место в подвале',
    player_level_up_detail_basementexpansion_desc:
      'Новое место для подвала можно открыть за <sprite=2><color=#F7C608FF>золото</color>!',
    player_level_up_detail_gems_desc: 'Вы получили <sprite=1>кристаллы!',
    player_level_up_detail_tradetier_title: 'Открыт новый уровень торговли',
    player_level_up_detail_tradetier_desc:
      'На рынке доступны предметы нового уровня!',
    player_level_up_detail_class_title: 'Доступны новые классы героев',
    player_level_up_detail_class_desc:
      'Новые классы героев теперь доступны для найма за <sprite=2><color=#F7C608FF>золото</color>!',
    player_level_up_detail_hero_title: 'Доступен новый защитник',
    player_level_up_detail_hero_desc:
      'Новый защитник может вскоре посетить вашу лавку...',
    player_level_up_detail_location_title: 'Доступно новое место приключений',
    player_level_up_detail_location_desc:
      'Новое место приключений можно открыть за <sprite=2><color=#F7C608FF>золото</color>!',
    player_level_up_detail_quest_title: 'Доступна новая ячейка приключений',
    player_level_up_detail_quest_desc:
      'Дополнительную ячейку приключений можно открыть за <sprite=2><color=#F7C608FF>золото</color>!',
    player_level_up_detail_craft_title:
      'Доступна новая производственная ячейка',
    player_level_up_detail_craft_desc:
      'Дополнительную производственную ячейку можно открыть за <sprite=2><color=#F7C608FF>золото</color>!',
    player_level_up_detail_adventurer_title: 'Доступна новая ячейка героя',
    player_level_up_detail_adventurer_desc:
      'Дополнительную ячейку героя можно открыть за <sprite=2><color=#F7C608FF>золото</color>!',
    player_level_up_detail_upgrade_title: 'Доступна новая ячейка улучшения',
    player_level_up_detail_upgrade_desc:
      'Новую ячейку улучшения можно открыть за <sprite=2><color=#F7C608FF>золото</color>!',
    player_level_up_detail_trade_title: 'Доступна новая торговая ячейка',
    player_level_up_detail_trade_desc:
      'Дополнительную торговую ячейку можно открыть за <sprite=2><color=#F7C608FF>золото</color>!',
    player_level_up_detail_expansion_title: 'Доступен новый уровень расширения',
    player_level_up_detail_expansion_desc:
      'Новый уровень расширения можно открыть за <sprite=2><color=#F7C608FF>золото</color>!',
    player_level_up_detail_worker_title: 'Доступен новый мастер',
    player_level_up_detail_worker_desc:
      'Этого мастера теперь можно нанять за <sprite=2><color=#F7C608FF>золото</color>!',
    player_level_up_detail_furniture_title: 'Доступна новая мебель',
    player_level_up_detail_furniture_desc: 'Новая мебель доступна для покупки!',
    player_level_up_detail_decoration_title:
      'Доступны новые варианты персонализации лавки',
    player_level_up_detail_decoration_desc:
      'Новые варианты персонализации лавки доступны для покупки!',
    player_level_up_difficulty_extreme: 'Сложность: экстрим',
    player_level_up_detail_extremequest_title:
      'Появился новый уровень сложности приключений',
    player_level_up_detail_extremequest_desc:
      'Теперь в приключениях с уровнем сложности «Трудно» можно выбрать уровень «Экстрим»!',
    worker_special_academy_offer:
      '<color=#{0}>+{1}</color> к получаемому опыту торговца',
    worker_special_engineer_offer:
      '<color=#{0}>-{1}</color> от шанса повреждения предметов',
    guild_create_tab_featured: 'Рекомендовано',
    guild_create_tab_guilds: 'Гильдии',
    guild_create_tab_players: 'Игроки',
    guild_info_tab_summary: 'Обзор',
    guild_info_tab_buildings: 'Здания',
    celebration_furniture_space_unlocked: 'Открыто место для мебели!',
    city_friendship_title: 'Дружба гильдии',
    city_friendship_info_desc:
      'Помогайте членам гильдии и повышайте <color=#FFCC00FF>уровень дружбы</color> с каждым из них!',
    city_friendship_info_desc2:
      '<size=+20><voffset=-10><sprite name="Energy"></voffset></size><color=#FFCC00FF>+5 энергии</color> за каждый полученный уровень',
    city_friendship_friendships_desc:
      'Помогайте членам гильдии и повышайте <color=#FFCC00FF>уровень дружбы</color> с каждым из них!',
    city_friendship_bonuses_desc:
      'Помогая членам гильдии, вы повысите <size=+20><voffset=-10><sprite name="Friendship"></voffset></size><color=#FFCC00FF>уровень дружбы</color> с ними.',
    city_friendship_tab_friendships: 'Дружба',
    city_friendship_tab_bonuses: 'Бонусы',
    city_friendship_guildmate: 'Член гильдии',
    city_friendship_acquaintance: 'Знакомый',
    city_friendship_friend: 'Друг',
    city_friendship_bestbud: 'Приятель',
    city_friendship_bff: 'Лучший друг',
    city_friendship_gift_info:
      'Выберите, кому из гильдии подарить <color=#{0}>подарок!</color>',
    city_help_complete_to_receive: 'Завершите для получения',
    friendship_energy_received: 'Получена энергия',
    furniture_upgrade_bottom_text_visiting:
      'Скидка {0}% при завершении улучшения члена гильдии',
    visitor_bubble_giftavatar: '{0} хочет вручить вам подарок!',
    visitor_bubble_giftavatar_title: 'Подарок',
    visitor_bubble_giftavatar_description: 'БЕСПЛАТНО!',
    visitor_bubble_cityfriend: '{0} хочет помочь с уборкой!',
    visitor_bubble_cityfriend_title: 'Чистка мебели от пыли',
    visitor_bubble_cityfriend_description: 'БЕСПЛАТНО!',
    bounty_complete: 'ЗАВЕРШЕНО!',
    complete_expansion: 'Закончить расширение',
    refuse_visitor_giftavatar_warning_body: 'Подарок будет утерян. Продолжить?',
    free_hire: 'Бесплатный найм!',
    free_hire_format: '{0}: наймите бесплатно!',
    gift_claim_tap_text: 'Нажмите, чтобы открыть подарок!',
    no_city_friendship: 'У вас нет друзей :(',
    quest_selector_title: 'Приключения',
    fight_boss_bottom_text_card: 'Бейте босса!',
    unlock_area_bottom_text_card: 'Открыть зону',
    quest_progress_bottom_desc:
      'Для открытия улучшений развивайте места приключений!',
    quest_unlock_requirement_desc: 'Пройдите {0}<br>{1} р.',
    top_bounty: 'Поручение',
    blueprint_mastered: 'Для чертежа получены все улучшения!',
    fill_trade_shortcut_name: 'Заполнить с рынка',
    fill_trade_shortcut_desc: 'Коснитесь для просмотра',
    trade_rack_empty: 'Нет сделок',
    invalid_adventurer_count: 'Не хватает героев, чтобы начать это приключение',
    notification_bounty_complete: 'выполняет поручение!',
    notification_friend_levelup: 'теперь {1} игрока {0}!',
    last_chance: 'Последний шанс!',
    quest_setup_boss_key_drop: 'Может выпасть <color=#FFB72BFF>{0}</color>.',
    power_format_hero_current:
      '<material="Curse Casual SDF White"><color=#9C577BFF>+{0}</color></material>',
    rank_format_hero_max:
      '<material="Curse Casual SDF Yellow Gradient Outline Black"><color=#FFFFFF>{0}</color></material>',
    power_level_up_title: 'Повышение силы!',
    power_level_up_desc: '{0} получает повышение силы!',
    hero_power_upgrade_desc:
      'Усильте своих защитников так, чтобы они вышли за пределы возможного!',
    power_up: 'Повышение силы',
    next_power_up: 'След. повышение силы',
    max_power_up: 'Макс. повышение силы',
    boss_area_unlocked: 'Зона с боссом открыта!',
    quest_consumables_selector_title: 'Выбрать усиление',
    enter_email_title: 'Введите электронный адрес',
    enter_email_description:
      'Вы получите письмо с подтверждением, как только оно будет готово!',
    email_example_placeholder: 'example@example.com',
    generic_send: 'Отправить',
    enter_valid_email: 'Введите допустимый электронный адрес',
    news_capture_email_update: 'Исправить электронный адрес',
    news_capture_email_register: 'Зарегистрируйтесь!',
    news_kind_capture_email: 'Новости',
    blueprint_unlock_require_worker: 'Для открытия этого чертежа требуется {0}',
    view_worker: 'Посмотреть мастера',
    hire_hero: 'Нанять героя',
    heal_all: 'Исцелить всех',
    change_name: 'Сменить имя пользователя',
    enter_new_name: 'Введите новое имя',
    change_name_popup_middle_text:
      'Вы можете сменить имя пользователя один раз бесплатно.<br>Последующие изменения будут стоить 50 кристаллов.',
    friendship_increased: 'Дружба стала крепче!',
    go_to_city: 'Перейти в город',
    you_have_received: 'Получено:',
    rewards_received: 'Получены награды',
    free_username_change_button_text_format: 'Бесплатно (осталось: {0})',
    rename_banned_notif:
      'У вас заблокирована возможность смены имени. (Длительность блокировки: {0})',
    chest_open: 'Открыть сундук',
    visitor_bubble_churn: 'Оуэн хочет вручить вам подарок!',
    visitor_bubble_churn_title: 'Сундук с ключом',
    visitor_bubble_churn_description: 'БЕСПЛАТНО!',
    refuse_visitor_churn_warning_body:
      'Сундук с ключом будет утерян. Продолжить?',
    link_accounts: 'Получите Shop Titans ID!',
    link_account_normal_desc:
      'Если вы хотите играть на нескольких устройствах или перенести учетную запись на новое, понадобится Shop Titans ID. Выберите один из следующих вариантов:',
    link_account_from_device_desc:
      'Скопировать уникальный Shop Titans ID, чтобы перенести эту учетную запись на другое устройство.',
    link_account_to_device_desc:
      'Вставить уникальный Shop Titans ID, чтобы играть на этом устройстве.',
    link_from_device: 'Ссылка с устройства',
    link_to_device: 'Ссылка для этого устройства',
    copy_link: 'Скопируйте ссылку',
    enter_link: 'Введите ссылку',
    error_server_busy:
      'Сервер не смог обработать ваш запрос из-за занятости. Попробуйте снова.',
    error_same_platform: 'Вы уже привязаны к этой платформе.',
    error_invalid_link: 'Недействительный код привязки, или его срок истек.',
    quantity_format_max: '<color=#69FE22FF>{0}</color>',
    craft_missing_item_qty_format:
      '{0}<voffset=-2><size=-15><material=Curse Casual SDF White><color={2}> / {1}</size></color></font></voffset>',
    hero_xp_bonus: 'Опыт героев +{0}%',
    blueprint_line_crossbows: 'Арбалеты',
    blueprint_line_guns: 'Ружья',
    blueprint_line_wands: 'Волшебные палочки',
    blueprint_line_runestones: 'Рунные камни',
    name_empty: 'Введите имя.',
    chest_guaranteed_reward:
      'Гарантированно <sprite name="Star">/<sprite name="Star2">!',
    chest_extra_reward: '<color=#69FE22FF>+1</color> доп. награда',
    area_not_unlocked: 'Приключение закрыто',
    capture_email_registered: 'Регистрация завершена!',
    less_than_one_percent: 'Менее 1%',
    quest_boss_loot_title: 'Добыча в приключениях',
    hud_size_format: 'Размер интерфейса: {0}%',
    hud_size_preset_tiny: 'Крошечный',
    hud_size_preset_verysmall: 'Очень маленький',
    hud_size_preset_small: 'Маленький',
    hud_size_preset_normal: 'Обычный',
    hud_size_preset_large: 'Большой',
    hud_size_preset_verylarge: 'Очень большой',
    hud_size_preset_huge: 'Огромный',
    enchantment_tier1_roman: 'I',
    enchantment_tier2_roman: 'II',
    enchantment_tier3_roman: 'III',
    'failed_identification ': 'Идентификация не выполнена',
    failed_identification_steam:
      'Идентификация не выполнена. Убедитесь, что приложение Steam открыто.',
    failed_identification_epic:
      'Не произведена авторизация. Запустите игру через программу запуска Epic.',
    trade_gems_locked_title: 'Требуется достижение',
    trade_gems_locked_description:
      'Прежде чем использовать кристаллы на рынке, нужно получить это достижение.',
    trade_gems_disabled_untrusted_platform:
      'Использовать рынок с кристаллами можно будет через {0}.',
    trade_gold_over_networth:
      'Для торговли этим предметом ваша стоимость должна быть не ниже {0}. (Текущая стоимость: {1})',
    error_link:
      'Не удалось привязать учетную запись (код может быть недействительным)',
    tap_continue_click: 'Нажмите для продолжения',
    click_open_click: 'Нажмите, чтобы открыть',
    tap_to_speedup_click: 'Нажмите для ускорения',
    tap_to_finish_click: 'Нажмите для завершения',
    tap_to_collect_click: 'Нажмите, чтобы забрать',
    city_search_item_action_click: 'Нажмите для вступления',
    tap_to_join_click: 'Нажмите для вступления',
    tap_for_details_click: 'Нажмите, чтобы узнать подробнее',
    hero_story_requirements_click: 'Нажмите для вклада',
    special_card_desc_click: 'Нажмите для исцеления',
    enchant_trade_shortcut_desc_click: 'Нажмите для просмотра',
    equip_trade_shortcut_desc_click: 'Нажмите для просмотра',
    claim_daily_bank_click: 'Нажмите, чтобы ЗАБРАТЬ!',
    city_perks_upgrade_info_click: 'Нажмите, чтобы узнать подробнее',
    login_event_claim_tap_text_click: 'Нажмите, чтобы открыть подарок!',
    gift_claim_tap_text_click: 'Нажмите, чтобы открыть подарок!',
    fill_trade_shortcut_desc_click: 'Нажмите для просмотра',
    pet: 'Питомец',
    purchase_daily_deal8_name: 'Специальное предложение',
    purchase_daily_deal8_desc: 'Ограниченное специальное предложение',
    pet_options: 'Действия с питомцем',
    visitor_bubble_ad_description_item:
      'Посмотрите рекламу и получите подарок: {0}',
    visitor_bubble_ad_description_item_vip: 'Заберите подарок: {0}!',
    visitor_bubble_ad_description_gems_vip: 'Получите бесплатные кристаллы!',
    event_visitorfrenzy_name: 'Ажиотаж клиентов',
    push_event_visitorfrenzy_name: 'Событие «Ажиотаж клиентов»',
    event_visitorfrenzy_desc:
      'В течение ограниченного времени у вас в лавке будет наплыв клиентов! Все берут номерок!',
    event_multicraft_name: 'Событие «Несколько предметов»',
    event_multicraft_desc:
      'В течение ограниченного времени есть шанс, что при изготовлении получится вдвое больше предметов!',
    push_event_multicraft_name: 'Событие «Несколько предметов»',
    google_subscription_info_title: 'Сведения о подписке',
    google_subscription_info_desc:
      'Если вы захотите отменить вашу подписку, вы можете сделать это в любой момент через Google Play.',
    restock: 'Пополнить',
    city_friendship_dust_title_tap: 'Коснитесь, чтобы протереть пыль!',
    city_friendship_dust_title_click: 'Нажмите, чтобы протереть пыль!',
    city_friendship_dust_action: 'Помочь в лавке!',
    city_friendship_dust_title_pet_tap: 'Коснитесь, чтобы погладить!',
    city_friendship_dust_title_pet_click: 'Нажмите, чтобы погладить!',
    pethouse_bottom_text: 'Счастливые питомцы для активной лавки!',
    pets: 'Питомцы',
    pets_slot_title: 'Взять питомца',
    pets_slot_desc: 'Взять в лавку<br>питомца',
    pets_slot_new_title: 'Добавить ячейку питомца',
    pets_capacity_format: '+{0} мест. для питомцев',
    adopt: 'Взять',
    unlock_pet_slot_title: 'Открыть ячейку питомца?',
    unlock_pet_slot_desc: 'Ещё один питомец вам на радость!',
    hungry: 'Хочет есть',
    feed: 'Покормить',
    green_text_format: '<color=#35FF5CFF>{0}</color>',
    pedigree: 'Родословная',
    pedigree_tab_progression: 'Прогресс',
    pedigree_info_description:
      'Повышение уровня такого питомца, как <material=Curse Casual SDF Yellow Outline Black>{0}</material>, увеличивает бонусную энергию и престиж.',
    pedigree_info_max_prestige: 'Престиж',
    pedigree_info_max_energy: 'Бонусная энергия',
    pedigree_info_hungry_in: 'Захочет есть через',
    remove_pet_button_title: 'Убрать<br>из лавки',
    place_pet_button_title: 'Разместить в лавке',
    remove_pet_title: 'Убрать питомца из лавки',
    remove_pet_desc: 'Вы уверены, что хотите убрать этого питомца из лавки?',
    see_info: 'Посмотреть сведения',
    pet_hud_tooltip_desc:
      'Бонусная <color=#F1C602FF>энергия</color> вашего питомца будет временно снижена, пока он <color=#F1C602FF>голоден</color>.',
    pet_tutorial_rename_error: 'Недействительное имя питомца.',
    pet_tutorial_invalid_pet_choice: 'Такого питомца не не существует.',
    pet_tutorial_no_uid_error: 'Выберите питомца.',
    pet_creation_purchase: 'Покупки',
    pet_creation_title: 'Внешний вид',
    pet_creation_name_placeholder: 'Введите имя',
    pet_creation_done: 'Создать',
    pet_creation_apply: 'Применить',
    pet_creation_cancel: 'Отмена',
    pet_creation_tab_type: 'Вид',
    pet_creation_tab_color: 'Внешность',
    pet_creation_unlock_gold: 'Открыть за золото',
    pet_creation_unlock_gems: 'Открыть за кристаллы',
    pet_creation_error_no_specie:
      'Вы не можете открыть этот вариант, пока не откроется {0}',
    pet_creation_feed_unlock:
      'Прежде чем получить доступ к этому цвету, вам нужно покормить такого питомца, как {0}, не менее <color=#F6D44AFF>{1}</color> раз.',
    petname_error_message: 'Имя питомца должно состоять из 3-12 знаков.',
    pet_unset_favorite: '{0} больше не ваш главный питомец.',
    pet_set_favorite: '{0} теперь главный питомец.',
    pet_slot_unlocks: 'Открытие ячеек питомца',
    unlock_at: 'Открывается на',
    login_event_anniversary_title: 'Календарь четыре годовщины',
    login_event_anniversary_description:
      'Заходи каждый день и получай новый подарок!',
    day: 'день',
    exit: 'Выйти',
    pedigree_progression_description:
      'При кормлении животного такого типа откроются новые варианты внешности!',
    change_need_space:
      'Недостаточно места для нового дома для питомцев. Переместите его туда, где места больше.',
    pet_cant_buy: 'Вы не можете купить этого питомца.',
    pet_customization_cant_buy:
      'Вы не можете купить этот предмет персонализации.',
    quest_event_tower_popup_progress_desc:
      'Отправляйте своих сильнейших героев, чтобы <color=#EE65EDFF>{0}</color> была покорена, а титан побежден!',
    quest_event_tower_popup_progress_complete_desc:
      '<color=#EE65EDFF>{0}</color><br>успешно пройдена!',
    quest_event_tower_next_button_first_title: 'Заберитесь на<br>первый этаж',
    quest_event_tower_next_button_title: 'Заберитесь на<br>следующий этаж',
    quest_event_tower_next_button_last_title: 'Заберитесь на<br>последний этаж',
    quest_event_tower_tier_alpha: 'Башня Альфа',
    quest_event_tower_tier_beta: 'Башня Бета',
    quest_event_tower_tier_gamma: 'Башня Гамма',
    quest_event_tower_tier_delta: 'Башня Дельта',
    quest_event_tower_tier_epsilon: 'Башня Эпсилон',
    quest_event_tower_next_boss: 'Бои до следующего босса',
    reroll_with_item: 'Заменить навыки ({0})',
    use_reroll_item_warning_title: '{0}: предупреждение',
    use_reroll_item_warning_body:
      'Этот предмет можно использовать для замены ячеек навыков до {0} ур. Использовать его в отношении более низкой ячейки?',
    sitem_reroll_effect_desc: 'Можно использовать для замены навыка героя',
    quest_event_tower_reward_soul:
      'Награда: <color=#EDB803FF>душа титана</color>',
    quest_event_tower_titan_alpha: 'Титан Альфа',
    quest_event_tower_titan_beta: 'Титан Бета',
    quest_event_tower_titan_gamma: 'Титан Гамма',
    quest_event_tower_titan_delta: 'Титан Дельта',
    quest_event_tower_titan_epsilon: 'Титан Эпсилон',
    quest_event_climb_tower_floor: 'Заберитесь на {0}-й этаж',
    quest_event_tower_completed: 'Завершено!',
    quest_event_tower_completed_warning:
      'Башня пройдена. Возвращайтесь во время следующего события!',
    quest_event_tower_titan_description:
      'Сразитесь с титаном, чьим местом обитания служит <color=#FFFFFFFF>{0}</color>, чтобы получить душу титана!',
    sitem_titansoul_effect_desc: 'Повысьте класс героя',
    titantower_name: 'Башня титанов',
    titantower_description:
      'Это временное подземелье служит для проверки сильнейших героев. Доберитесь до последнего этажа и обретите внутри невероятную силу!',
    quest_event_tower_visit_tower: 'Посетить башню',
    class_upgrade: 'Повышение класса',
    adv_class_upgrade_desc: 'Поздравляем! {0} теперь {1}!',
    new_skill_slot: 'Новая ячейка навыка',
    yellow_gradient_text_format:
      '<material="Curse Casual SDF Yellow Gradient Outline Black">{0}</material>',
    titantower_info_header1: 'Временное событие',
    titantower_info_header2: 'Множество наград',
    titantower_info_header3: 'Душа титана',
    titantower_info_body1:
      'Все могут исследовать таинственную Башню титанов, которая появляется время от времени и охраняется уникальными монстрами.',
    titantower_info_body2:
      'Сразитесь с обитателями башни и отберите у них сокровище.',
    titantower_info_body3:
      'Победите таинственного титана, чтобы получить душу титана, которую можно использовать для повышения одного героя!',
    ranking_quest_event_tower_floor: '{0}-й этаж',
    titantower_boss_name_format: '{0} {1}',
    titantower_titan_name_format: '{1} игрока <{0}>',
    quest_event_tower_monster_encounter: 'Встреча с монстром',
    no_low_class: 'Нет доступного героя без повышения',
    ranking_tower_0: 'Альфа',
    ranking_tower_1: 'Бета',
    ranking_tower_2: 'Гамма',
    ranking_tower_3: 'Дельта',
    ranking_tower_4: 'Эпсилон',
    ranking_tower_5: 'ужаса',
    titan_vanquished: 'Титан побежден!',
    galaxyMX_paymentfail: 'Платежи не разрешены.',
    quest_event_tower_rush_format: 'Проходится<br><sprite name=Timer>{0}',
    quest_event_tower_complete_quest_button: 'Завершить бой',
    quest_event_tower_floor_in_progress: 'Проходится {0}-й этаж',
    galaxyMX_health:
      'Подходите к игре ответственно и ограничивайте свое время.',
    galaxyMX_healthtitle: 'Внимание!',
    craft_missing_requirements_generic:
      'Вы не отвечаете требованиям для создания этого предмета.',
    sitem_titanspark_effect_desc:
      'Дает новый навык титана или заменяет существующий',
    no_titan: 'Нет доступного улучшенного героя',
    skill_acquired: 'Получен навык',
    skill_renewed: 'Навык заменен',
    points_short: 'оч.',
    teach_skill: 'Обучить навыку',
    titan_skills: 'Навыки титанов',
    use_titan_spark_warning_title: '{0}: использовать?',
    use_titan_spark_warning_body:
      'Вы уверены, что хотите использовать такой предмет, как {0}, в отношении героя по имени {1}?',
    notification_tower_boss_defeated:
      'побеждает такого врага, как {0} ({1}), в локации {2}!',
    notification_tower_titan_defeated: 'побеждает такого врага, как {0}!',
    already_asking: 'Предмет уже запрошен и не может быть предложен.',
    cannot_use_item:
      'Предмет не может быть использован этим клиентом и поэтому не может быть предложен.',
    item_locked: 'Предмет заблокирован и не может быть предложен.',
    skill_upgraded: 'Навык улучшен',
    tutorial_action_not_available: 'Это нельзя делать во время обучения',
    kings_caprice_name: 'Каприз короля',
    kings_caprice: 'Каприз короля',
    quest_event_king_caprices: 'Капризы',
    quest_event_king_antiques: 'Древности',
    quest_event_king_popup_progress_title: 'Каприз короля',
    quest_event_king_popup_progress_description:
      'Изготовьте запрошенные королем предметы, чтобы приобрести <sprite=25><color=#FFDC00>расположение</color> и получить все награды!',
    quest_event_king_start_crafting: 'Начать изготовление',
    quest_event_king_popup_progress_buy: 'Купить<br>древности',
    quest_event_king_craft_item: 'Изготовьте: <color=#FFDC00>{0}</color>',
    quest_event_king_craft_item_type: 'Изготовьте: {0}',
    quest_event_king_craft_item_category: 'Изготовьте: {0} (любого типа)',
    bonus_skill_stat: 'бонус за все предметы',
    quest_event_king_reroll_title: 'Заменить выбор?',
    quest_event_king_reroll_description:
      'Все доступные текущие предметы будут заменены на новую подборку.',
    quest_event_king_items_reroll_in: 'До нового выбора:',
    total_acquired: 'Всего получено:',
    all_acquired: 'Все получено',
    daily_freebie: 'Ежедневный подарок короля',
    daily_freebie_event_expired: 'Срок истек',
    not_enough_shards: 'У вас недостаточно жетонов древностей',
    sitem_limitededition_effect_desc:
      'Используйте эту особую печать, чтобы повышать цену для клиентов во второй раз!',
    special_item_type_limitededition: 'Рекламный товар',
    shard_shop: 'Магазин древностей',
    sort_event: 'Капризы',
    next_refresh_in: 'До следующего обновления:',
    expired_item: 'Срок действия предмета истек.',
    expired: 'Срок истек',
    special_item_type_antiqueshard: 'Древность',
    item_already_purchased: 'Вы уже купили это.',
    steam_chat_title: 'Группа чата в Steam',
    steam_chat_optin_title: 'Записаться',
    steam_chat_request_title: 'Запрос приглашения',
    steam_chat_request_button: 'Запрос',
    steam_chat_optin_yes: 'Да',
    steam_chat_optin_no: 'Нет',
    steam_chat_opt_in_gm: 'Чтобы записаться, нужно быть главой гильдии.',
    steam_chat_opt_in_title: 'Запись в группу чата в Steam',
    steam_chat_opt_in_description:
      'Вы собираетесь записаться в группу чата в Steam. Отписаться после этого не сможете. Продолжить?',
    steam_chat_cannot_opt_out: 'Вы не можете отписаться',
    steam_chat_not_opt_in:
      'Прежде чем вы можете быть приглашены, главе вашей гильдии сначала нужно записаться.',
    steam_chat_server_optin_error: 'Произошла ошибка, попробуйте снова',
    steam_chat_server_optin_success:
      'Вы успешно записались в группу чата в Steam. Все участники приглашены.',
    steam_chat_server_request_error: 'Произошла ошибка, попробуйте снова',
    steam_chat_server_request_success: 'Вы приглашены в группу чата в Steam.',
    steam_chat_server_request_already_requested_once:
      'Вы уже делали запрос на вступление в этом сеансе.',
    requires_royal_merchant: 'Требуется королевский торговец',
    gem_rush_min_lvl_friendship:
      'Нужен как минимум {0}-й уровень дружбы, чтобы ускорить улучшение мебели члена гильдии за кристаллы.',
    sitem_use_attack_seed: 'Использовать зерно атаки',
    sitem_use_defense_seed: 'Использовать зерно защиты',
    sitem_use_hp_seed: 'Использовать зерно ЗДР',
    character_seed_used: '{1} героя {0} повышается до {2}',
    sitem_attack_seed: 'Зерно атаки',
    sitem_defense_seed: 'Зерно защиты',
    sitem_hp_seed: 'Зерно ЗДР',
    character_max_seeds_used: 'Персонаж уже использовал максимум зерен.',
    feed_free_food: 'Покормить бесплатным кормом',
    feed_superior_food: 'Покормить превосходным кормом',
    feed_premium_food: 'Покормить премиальным кормом',
    remove_all_enchants_popup_desc:
      'Любое колдовство у этого предмета будет уничтожено.',
    remove_element_popup_desc: 'Стихия у этого предмета будет уничтожена',
    remove_spirit_popup_desc: 'Дух у этого предмета будет уничтожен',
    remove_enchants_popup_qty_desc: 'Количество для снятия колдовства',
    pet_level_up_desc:
      'Уровень привязанности у питомца {0} повысился до <color=#FECB00FF>{1}-го</color>!',
    pet_level_up_title: 'Повышен уровень питомца!',
    decoration_received: 'Получен декор!',
    trade_enchanted_item_warning:
      'Заколдованными предметами торговать нельзя. Сначала снимите колдовство.',
    quest_event_king_info_limited_time_title:
      'Временное событие изготовления предметов',
    quest_event_king_info_limited_time_desc:
      'Изготовление предметов во исполнение капризов короля дает милость для гильдии. Производственные ячейки, содержащие нужные предметы, приобретают золотой цвет.',
    quest_event_king_info_favor_title:
      'Зарабатывайте <color=#FFCC00FF>милость</color> и забирайте призы',
    quest_event_king_info_favor_desc:
      'Чем больше милости накапливает ваша гильдия, тем больше призов вы можете забрать в качестве наград в рамках события. Доступны некоторые временные предметы, так что не упустите!',
    quest_event_king_info_antiques_title:
      'Покупайте древности за <color=#FFCC00FF>жетоны древностей</color>',
    quest_event_king_info_antiques_desc:
      'Жетоны древностей, заработанные в рамках события, можно потратить на покупку контента прошлых премиальных предложений. Жетоны древностей сохраняются от события к событию.',
    quest_event_king_caprices_info_text:
      'Изготовьте запрошенные королем предметы, чтобы приобрести <sprite=25><color=#F4D500FF>милость</color> и заработать кучу наград! Не медлите, вкусы у него переменчивые!',
    guild_perks_details_init_tradeofferstack_desc:
      'Бонус к размеру наборов предложений',
    guild_perks_details_init_traderequeststack_desc:
      'Бонус к размеру наборов запросов',
    guild_perks_details_init_commonresregen_desc:
      'Ускорение восстановления обычных ресурсов',
    guild_perks_details_init_uncommonresregen_desc:
      'Ускорение восстановления необычных ресурсов',
    guild_perks_details_init_rareresregen_desc:
      'Ускорение восстановления редких ресурсов',
    guild_perks_details_init_coinrefillres_desc:
      'Количество ежедневных восполнений ресурсов увеличено на',
    all_unlocked: 'Все открыто',
    item_type_enchantment: 'Колдовство',
    item_type_element: 'Стихия',
    item_type_spirit: 'Дух',
    basement_selector_title: 'Подвал',
    basement_expansion_unlocked: 'Открыто расширение подвала!',
    member_help_basement_expand: 'Улучшение подвала до {0}-го ур.',
    notification_basement_expand:
      'расширяет свой подвал до <color=#FFDA00FF>{0}</color>-го уровня!',
    expand_basement: 'Расширить подвал',
    basement: 'Подвал',
    store_furniture_title: 'Хранение мебели',
    store_furniture_desc:
      'Мебель, помещенная в подвал, дает преимущества, не занимая при этом места в лавке.',
    store_furniture_store_button: 'Хранить<br>в инвентаре',
    store_furniture_basement_button: 'Хранить<br>в подвале',
    basement_full: 'Недостаточно места в подвале.',
    furniture_space: 'Место для мебели',
    pet_creation_tab_hat: 'Аксессуары',
    basement_unlocks_up_to: 'До подвала {0}',
    basement_unlocks: 'Открыты расширения подвала!',
    purchase_basement_unlock_format: 'Подвал {0}',
    no_more_expansions: 'Расширений больше нет!',
    not_free_basement_expansion:
      'Расширение подвала в рамках обучения уже получено.',
    sitem_seedplus_effect_desc: 'До максимума героя {0}',
    sitem_use_maxhpseed_seed: 'Использовать цветок жизни',
    sitem_use_maxatkseed_seed: 'Использовать цветок силы',
    sitem_use_maxdefseed_seed: 'Использовать цветок сопротивления',
    max_seed: 'Зерно максимума',
    sitem_maxhpseed_seed: 'Цветки',
    sitem_maxatkseed_seed: 'Цветки',
    sitem_maxdefseed_seed: 'Цветки',
    fusion_title_success: 'Синтез выполнен!',
    fusion_title_fail: 'Синтез не выполнен',
    fusion_subtitle_success: 'Синтез ({0}) выполнен!',
    fusion: 'Синтез',
    fusions: 'Синтез',
    fusions_info_popup_dragon_title: 'Мастера-драконы',
    fusions_info_popup_dragon_desc:
      'Солдра отвечает за синтез, а Лундра — за лунные камни. Если нанять обоих, вы откроете для себя всю мощь синтеза.',
    fusions_info_popup_items_title: 'Получайте предметы высокого качества',
    fusions_info_popup_items_desc:
      'Выполняйте синтез двух одинаковых предметов, чтобы добиться более высокого качества. Обычные становятся превосходными, превосходные — безупречными и т. д.',
    fusions_info_popup_moonstone_title: 'Лунные камни',
    fusions_info_popup_moonstone_desc:
      'Для каждого синтеза требуется определенный тип лунного камня. Они могут быть изготовлены лунным драконом или заработаны другими способами.',
    blueprint_line_moonstones: 'Лунные камни',
    storage_full_moonstone:
      'Вы не можете иметь больше лунных камней этого типа.',
    fusion_selector_title: 'Рецепты синтеза',
    fusion_low_level_worker:
      'Уровень мастера слишком низок для выполнения этого синтеза.',
    fusion_success_odds: 'Успех: {0}',
    notification_city_help_basement_expand_paid:
      'сообщает об оплате расширения вашего подвала!',
    notification_city_help_basement_expand:
      'сообщает вам о помощи с расширением вашего подвала!',
    fusion_enchanted_item_warning: 'Предмет заколдован. Уничтожить его?',
    fusion_confirmation_selector_title: 'Выбрать из инвентаря',
    fusion_confirmation_selector_subtitle: 'Предмет ({0}) от {1}-го ранга',
    fusion_confirmation_item_name_quality: '{1} ({0})',
    cross_dressing_disabled_clothes_male:
      'Можно надеть только на мужского персонажа',
    cross_dressing_disabled_clothes_female:
      'Можно надеть только на женского персонажа',
    cross_dressing_disabled_hairstyle_male:
      'Прическа только для мужского персонажа',
    cross_dressing_disabled_hairstyle_female:
      'Прическа только для женского персонажа',
    cross_dressing_disabled_mustache: 'У женского персонажа не может быть усов',
    cross_dressing_disabled_beard: 'У женского персонажа не может быть бороды',
    limit_skill_reroll: 'Заменен максимум кристаллов. До следующей замены: {0}',
    limit_chest_open:
      'Открыт максимум сундуков с кристаллами. До открытия следующего: {0}',
    limit_enchant_item:
      'Заколдован максимум предметов. Снова можно заколдовать через {0}',
    limit_enchant_item_standing:
      'Заколдовывать предметы можно 50 раз в день ({0}/50).',
    pity_reroll_explain:
      'После 20 попыток вы гарантированно получите 1 золотой навык.',
    limit_reroll_explain: 'За кристаллы можно менять 50 раз в день.',
    pity_and_limit_reroll_explain:
      'После 20 попыток вы гарантированно получите 1 золотой навык.<br>За кристаллы можно менять 50 раз в день.',
    pity_and_limit_reroll_explain_standing:
      'После 20 попыток вы гарантированно получите 1 золотой навык ({0}/20).<br>За кристаллы можно менять 50 раз в день ({1}/50).',
    pity_chest_explain:
      'После 10 попыток вы гарантированно получите монеты защитника.',
    limit_chest_explain: 'За кристаллы можно открывать 50 раз в день.',
    pity_and_limit_chest_explain:
      'После 10 попыток вы гарантированно получите монеты защитника.<br>За кристаллы можно открывать 50 раз в день.',
    pity_and_limit_chest_explain_standing:
      'После 10 попыток вы гарантированно получите монеты защитника ({0}/10).<br>За кристаллы можно открывать 50 раз в день ({1}/50).',
    pity_and_limit_chest_explain_standing_opening:
      'За кристаллы можно открывать 50 раз в день ({0}/{1}).',
    fusion_craft_slots_full: 'Недостаточно производственных ячеек.',
    purchase_section_workers: 'Эксклюзивные мастера',
    filter_moonstones: 'Лунные камни',
    guildchat_tab_empty: 'Чат гильдии пуст! Начинайте общаться.',
    notification_tab_empty: 'Нет новых действий. ',
    equip_tab_empty: 'Вам нечем экипироваться. Изготовьте что-нибудь!',
    fusion_detail_worker: 'Мастер',
    fusion_detail_fuse_button: 'Синтез',
    fuse: 'Синтез',
    blueprint_unknown: 'Неизвестный чертеж',
    fusion_started: 'Начат синтез: {1} (качество — {0})',
    fullmoon_event_title: 'Полнолуние',
    fullmoon_event_subtitle: 'Событие «Драконий синтез»',
    fullmoon_event_desc:
      'Полнолуние придает мастерам-драконам силы! Теперь доступен особый синтез!',
    view_info: 'Подробнее',
    start_fusing: 'Начать синтез',
    filter_fullmoon: 'Синтез полнолуния',
    fuse_warning_high_quality_desc:
      '— используется в процессе синтеза. Продолжить?',
    fuse_warning_lock_desc:
      'Вы собираетесь использовать для синтеза заблокированный предмет. Продолжить?',
    fusion_speedup_title: 'О синтезе',
    fusion_speed: 'Скорость синтеза',
    trade_request_overmax_item: 'У вас не может быть более {0} предметов.',
    trade_request_overmax_tag:
      'У вас не может быть более {0} предметов для колдовства.',
    confirm_fusion: 'Начать синтез',
    fusion_unlock_title: 'Открыть синтез',
    fusion_unlock_desc:
      'Наймите <color=#F6CB07FF>лунного дракона</color> или подождите до следующего <color=#F6CB07FF>полнолуния</color>.',
    trade_fusion_empty: 'Пусто',
    fusion_touch_to_complete: 'Для завершения синтеза коснитесь котла!',
    fusion_touch_to_complete_click: 'Для завершения синтеза нажмите на котел!',
    trade_fusion_wait_for_data: 'Поиск сделок',
    fullmoon_info_popup_title: 'Полнолуние',
    fullmoon_info_popup_limited_time_title: 'Временное событие',
    fullmoon_info_popup_limited_time_desc:
      'Во время полнолуния Солдра и Лундра становятся сильнее и получают доступ к особому синтезу!',
    fullmoon_info_popup_fullmoon_recipes_title: 'Рецепты полнолуния',
    fullmoon_info_popup_fullmoon_recipes_desc:
      'В течение события синтез, который обычно является эксклюзивным для Лундры, доступен для всех.',
    fullmoon_info_popup_faster_fusion_recipe_title: 'Быстрый синтез',
    fullmoon_info_popup_faster_fusion_recipe_desc:
      'Игроки, нанявшие Лундру, во время события будут иметь более высокую скорость синтеза.',
    component_storage_full: 'Инвентарь компонентов полон',
    blueprint_line_moondragon: 'Коллекция «{0}»',
    purchase_sitems_argon_name: 'Монеты Аргона',
    purchase_sitems_lilu_name: 'Монеты Лилу',
    purchase_sitems_sia_name: 'Монеты Сиа',
    purchase_sitems_yami_name: 'Монеты Ями',
    purchase_sitems_rudo_name: 'Монеты Рудо',
    purchase_sitems_polonia_name: 'Монеты Полонии',
    purchase_sitems_donovan_name: 'Монеты Донована',
    purchase_sitems_hemma_name: 'Монеты Хеммы',
    purchase_sitems_ashley_name: 'Монеты Эшли',
    purchase_sitems_gold1_name: 'Золото',
    purchase_sitems_gold2_name: 'Золото',
    purchase_sitems_bp1_name: 'Осколки усиления',
    purchase_sitems_bp2_name: 'Осколки усиления',
    purchase_sitems_bp3_name: 'Свитки исследования',
    purchase_sitems_argon_desc: 'Редкие монеты для найма и усиления Аргона.',
    purchase_sitems_lilu_desc: 'Редкие монеты для найма и усиления Лилу.',
    purchase_sitems_sia_desc: 'Редкие монеты для найма и усиления Сиа.',
    purchase_sitems_yami_desc: 'Редкие монеты для найма и усиления Ями.',
    purchase_sitems_rudo_desc: 'Редкие монеты для найма и усиления Рудо.',
    purchase_sitems_polonia_desc: 'Редкие монеты для найма и усиления Полонии.',
    purchase_sitems_donovan_desc:
      'Редкие монеты для найма и усиления Донована.',
    purchase_sitems_hemma_desc: 'Редкие монеты для найма и усиления Хеммы.',
    purchase_sitems_ashley_desc: 'Редкие монеты для найма и усиления Эшли.',
    purchase_sitems_gold1_desc: 'Золото — основная валюта лавочника!',
    purchase_sitems_gold2_desc: 'Золото — основная валюта лавочника!',
    purchase_sitems_bp1_desc: 'Для улучшения чертежей.',
    purchase_sitems_bp2_desc: 'Для улучшения чертежей.',
    purchase_sitems_bp3_desc:
      'Немедленно оправьтесь после любого боя благодаря набору целителя!',
    worldchat_tab_empty: 'Мировой чат пуст. Начни разговор!',
    missing_item_location_general:
      'Этот предмет можно найти в ходе приключений',
    missing_item_location_tot: 'Этот предмет можно найти в Башне титанов',
    missing_item_location_lcog:
      'Этот предмет можно найти в Потерянном городе золота',
    missing_item_location_specific:
      'Этот предмет можно найти в такой локации, как {0}',
    fusion_card_requires_full_moon: 'Требуется полнолуние',
    fuse_more_to_hire_moondragon:
      'Нужно выполнить синтез еще несколько раз, чтобы можно было нанять Лундру и открыть этот чертеж.',
    no_available_fusion: 'Синтез недоступен',
    moonstone_lessermoon_tooltip_desc:
      'Часть лунного камня с небольшими магическими свойствами. Важный компонент синтеза.',
    moonstone_flawedmoon_tooltip_desc:
      'Фрагмент лунного метеорита, замерзший в камне. Важный компонент синтеза.',
    moonstone_greatermoon_tooltip_desc:
      'Лунный цикл в физической форме. Важный компонент синтеза.',
    filter_stones: 'Камни',
    stones: 'Камни',
    unlock_creation_slot_title: 'Открыть производственную ячейку?',
    unlock_creation_slot_desc:
      'Изготавливайте или синтезируйте больше предметов одновременно!',
    available_creation_slots: 'Доступные производственные ячейки',
    share_link_unavailable_region:
      'Функция публикации отключена в вашем регионе.',
    check_user: 'Проверить пользователя',
    pet_customization: 'Персонализация питомца',
    logout_device: 'Выход',
    exclusive_pet_skin: 'Эксклюзивная внешность питомца!',
    settings_device: 'Устройство: {0}',
    trade_tab_guildrequest: 'Гильдия',
    store_furniture_shop_button: 'Разместить<br>в лавке',
    login_event_claim: 'Забрать',
    chest_odds_item_pity: 'Гарантировано снаряжение после',
    chest_odds_component_pity: 'Гарантирован компонент после',
    chest_odds_consumable_pity: 'Гарантирован расходник после',
    chest_odds_bpunlock_pity: 'Гарантированы свитки исследования после',
    chest_odds_bpupgrade_pity: 'Гарантированы осколки усиления после',
    chest_odds_champion_pity: 'Гарантированы монеты защитника после',
    chest_odds_exclusive_pity:
      'Гарантирован эксклюзив после (если не все собрано)',
    you_caps: 'ВЫ',
    points_abv: 'оч.',
    limit_fusion_craft:
      'Достигнут максимум процедур синтеза. До следующего синтеза: {0}',
    limit_fusion_craft_standing: 'Синтезировать можно 50 раз в день ({0}/50).',
    fusion_common_tooltip_description:
      'Любой обычный предмет этого ранга или выше.',
    fusion_uncommon_tooltip_description:
      'Любой превосходный предмет этого ранга или выше.',
    fusion_flawless_tooltip_description:
      'Любой безупречный предмет этого ранга или выше.',
    fusion_epic_tooltip_description:
      'Любой эпический предмет этого ранга или выше.',
    fusion_legendary_tooltip_description:
      'Любой легендарный предмет этого ранга или выше.',
    purchase_store_event_title: 'МАГАЗИН СОБЫТИЯ',
    filter_crystals: 'Компоненты',
    fusion_crystal_selector_bottom_text: 'Синтез компонентов беспроигрышен.',
    fusion_fullmoon_selector_bottom_text: 'Синтез компонентов беспроигрышен.',
    notification_member_promote: 'повышает игрока {1} до звания «{0}»',
    notification_member_demote: 'понижает игрока {1} до звания «{0}»',
    guild_updates_member_join: 'Игрок вступил в гильдию!',
    guild_updates_member_leave: 'Игрок покинул гильдию.',
    guild_updates_member_kick: 'Игрок изгнан из гильдии.',
    guild_updates_member_promote: 'Игрок повышен до звания «{0}»',
    guild_updates_member_demote: 'Игрок понижен до звания «{0}»',
    guild_updates_title: 'Новости гильдии',
    quest_event_component_full: 'У вас максимум компонентов.',
    book_score: 'Коллекционный счет',
    share: 'Опубликовать',
    collection_book: 'Коллекционная книга',
    collection_book_desc:
      'Предложите этой магической книге предметы и получите <color=#FFDA00FF>награды</color> и <color=#FFDA00FF>декоративное снаряжение</color>!',
    collection_book_popup_progress_title: 'Награды за книги',
    collection_book_popup_progress_description:
      'Каждое пожертвование книге повышает ваш <color=#FFDC00>коллекционный счет</color>. За его повышение даются уникальные награды!',
    collection_book_info_popup_craft_title: 'Станьте настоящим мастером',
    collection_book_info_popup_craft_desc:
      'Получите все улучшения для чертежей, чтобы открыть их страницы в <color=#FFDC00>коллекционной книге</color>.',
    collection_book_info_popup_offer_title: 'Завершите энциклопедию',
    collection_book_info_popup_offer_desc:
      'Предложите <color=#FFDC00>коллекционной книге</color> предмет каждого уровня качества, чтобы заполнить страницу.',
    collection_book_info_popup_rewards_title: 'Получить награды',
    collection_book_info_popup_rewards_desc:
      'Предметы, добавленные в книгу, повысят ваш <color=#FFDC00>коллекционный счет</color> и откроют награды!',
    collection_book_info_popup_transmog_title: 'Декоративное снаряжение',
    collection_book_info_popup_transmog_desc:
      'Завершенная запись о чертеже в книге открывает данное снаряжение как <color=#FFDC00>декоративное</color> для героев.',
    fusion_selector_bottom_text:
      'В полнолуние рецепты не могут потерпеть неудачу.',
    donate: 'Пожертвовать',
    inventory_show_inventory_only: 'Показывать только предметы в инвентаре',
    xp_until: 'Опыт, требуемый для {0}-го уровня',
    collection_book_item_detail_stat_title_common: 'Сколько изготовлено',
    collection_book_item_detail_stat_title_uncommon: 'Крупнейшая продажа',
    collection_book_item_detail_stat_title_flawless: 'Заработано золота',
    collection_book_item_detail_stat_title_epic: 'Сколько продано клиентам',
    collection_book_item_detail_stat_title_legendary:
      'Сколько продано на рынке',
    collection_book_unavailable_top_title:
      'Получите все улучшения для этого чертежа, чтобы открыть его страницу!',
    collection_book_unavailable_top_slider_title:
      'Сколько раз требуется изготовить для всех улучшений',
    bountytrophy: 'Трофеи за поручения',
    transmogrification: 'Декоративное снаряжение',
    equipped: 'В экипировке',
    transmog_generic_error_message:
      'Это декоративное снаряжение нельзя поместить в экипировку',
    transmog_celebration_title: 'Страница предмета заполнена!',
    transmog_celebration_desc1: 'Ваша коллекция растет!',
    transmog_celebration_desc2:
      'Для следующих классов появилось новое декоративное снаряжение:',
    transmog_tooltip_info:
      'Герои могут использовать <color=#FFDC00>декоративное снаряжение</color> для изменения своей внешности, при этом сохраняя параметры снаряжения, носимого под ним. Новое <color=#FFDC00>декоративное снаряжение</color> можно открывать при заполнении его страниц в <color=#FFDC00>коллекционной книге</color>.',
    bonus_fusion_speed: 'Бонус к скорости синтеза',
    bonus_fusion_legendary: 'Шанс успешного легендарного синтеза',
    collection_book_unlocked: 'Открыта коллекционная книга!',
    collection_book_unlocked_desc: 'Вы открыли коллекционную книгу!',
    worker_missing: 'Не хватает мастера',
    worker_missing_singular_desc:
      'У вас не хватает мастера, требуемого для этого чертежа, и вы не сможете ничего изготовить. Вы уверены?',
    worker_missing_plurial_desc:
      'У вас не хватает нескольких мастеров, требуемых для этого чертежа, и вы не сможете ничего изготовить. Вы уверены?',
    quest_result_upgrade_hall:
      'Улучшите тренировочный зал, чтобы открыть героев <color=white>{0}-го ур. и выше</color>.',
    collection_book_page_unlocked: 'Открыта страница',
    collection_book_page_unlocked_desc:
      'Теперь можно заполнить страницу в коллекционной книге!',
    open_collection_book: 'Открыть<br>коллекционную книгу',
    transmog_equip_item_customize:
      'Чтобы применить декоративное снаряжение, нужно надеть предмет.',
    donate_one: 'Пожертвовать',
    transmog_adv_busy:
      'Декоративное снаряжение нельзя модифицировать, пока герой участвует в приключениях.',
    transmog_locked_desc:
      'Заполните страницу этого предмета в <color=#FFDC00>коллекционной книге</color>, чтобы открыть его как <color=#FFDC00>декоративное снаряжение</color>.',
    unlock_vanity_gear: 'Открыть декоративное снаряжение',
    city_friendship_info_desc_3:
      '<size=+20><voffset=-10><sprite name="Friendship"></voffset></size><color=#FFCC00FF>Новый титул дружбы</color> через каждые 10 уровней!',
    stat_bounties_desc: 'Общее количество трофеев за выполнение поручений.',
    donate_warning_lock_desc:
      'Вы собираетесь пожертвовать заблокированный предмет. Продолжить?',
    notification_guildchoice_changed:
      'меняет выбор гильдии на следующий: <color=#FFDC00>{0}</color>',
    offer_unlocked_blueprints: 'Серии эксклюзивных чертежей!',
    purchase_bundle1_name: 'Миникомплект предметов!',
    purchase_bundle2_name: 'Крохотный комплект предметов!',
    purchase_bundle3_name: 'Крошечный комплект предметов!',
    purchase_bundle4_name: 'Маленький комплект предметов!',
    purchase_bundle5_name: 'Легкий комплект предметов!',
    purchase_bundle6_name: 'Усиливающий комплект предметов!',
    purchase_bundle7_name: 'Базовый комплект предметов!',
    purchase_bundle8_name: 'Скоростной комплект предметов!',
    purchase_bundle9_name: 'Проворный комплект предметов!',
    purchase_bundle10_name: 'Быстрый комплект предметов!',
    purchase_bundle11_name: 'Ненормальный комплект предметов!',
    purchase_bundle12_name: 'Благотворный комплект предметов!',
    purchase_bundle13_name: 'Усиленный комплект предметов!',
    purchase_bundle14_name: 'Проницательный комплект предметов!',
    purchase_bundle15_name: 'Бесстрашный комплект предметов!',
    purchase_bundle16_name: 'Полезный комплект предметов!',
    purchase_bundle17_name: 'Безжалостный комплект предметов!',
    purchase_bundle18_name: 'Магический комплект предметов!',
    purchase_bundle19_name: 'Чудесный комплект предметов!',
    purchase_bundle20_name: 'Отважный комплект предметов!',
    purchase_bundle21_name: 'Искусный комплект предметов!',
    purchase_bundle22_name: 'Правильный комплект предметов!',
    purchase_bundle23_name: 'Утонченный комплект предметов!',
    purchase_bundle24_name: 'Толстый комплект предметов!',
    purchase_bundle25_name: 'Радостный комплект предметов!',
    purchase_bundle26_name: 'Сочный комплект предметов!',
    purchase_bundle27_name: 'Притягательный комплект предметов!',
    purchase_bundle28_name: 'Выдающийся комплект предметов!',
    purchase_bundle29_name: 'Искрящийся комплект предметов!',
    purchase_bundle30_name: 'Вкусный комплект предметов!',
    purchase_bundle31_name: 'Теплый комплект предметов!',
    purchase_bundle32_name: 'Солидный комплект предметов!',
    purchase_bundle33_name: 'Ослепительный комплект предметов!',
    purchase_bundle34_name: 'Блистающий комплект предметов!',
    purchase_bundle35_name: 'Безупречный комплект предметов!',
    purchase_bundle36_name: 'Удачный комплект предметов!',
    purchase_bundle37_name: 'Величественный комплект предметов!',
    purchase_bundle38_name: 'Громадный комплект предметов!',
    purchase_bundle39_name: 'Благоприятный комплект предметов!',
    purchase_bundle40_name: 'Сверхбольшой комплект предметов!',
    purchase_bundle41_name: 'Энергичный комплект предметов!',
    purchase_bundle42_name: 'Смелый комплект предметов!',
    purchase_bundle43_name: 'Модный комплект предметов!',
    purchase_bundle44_name: 'Огромный комплект предметов!',
    purchase_bundle45_name: 'Массивный комплект предметов!',
    purchase_bundle46_name: 'Великолепный комплект предметов!',
    purchase_bundle47_name: 'Могучий комплект предметов!',
    purchase_bundle48_name: 'Ультра-комплект предметов!',
    purchase_bundle49_name: 'Заряженный комплект предметов!',
    purchase_bundle50_name: 'Боевой комплект предметов!',
    purchase_bundle51_name: 'Сверкающий комплект предметов!',
    purchase_bundle52_name: 'Рыцарственный комплект предметов!',
    purchase_bundle53_name: 'Безграничный комплект предметов!',
    purchase_bundle54_name: 'Гигантский комплект предметов!',
    purchase_bundle55_name: 'Элитный комплект предметов!',
    purchase_bundle56_name: 'Бесподобный комплект предметов!',
    purchase_bundle57_name: 'Громоподобный комплект предметов!',
    purchase_bundle58_name: 'Грандиозный комплект предметов!',
    purchase_bundle59_name: 'Оптимальный комплект предметов!',
    purchase_bundle60_name: 'Необъятный комплект предметов!',
    tier_search_locked:
      'Предметы <color=#FFDC00>{0}-го ранга</color> на рынке откроются на <color=#FFDC00>{1}-м уровне</color>',
    purchase_engineer1_desc: 'Революционные изобретения каждый день!',
    engineer_desc: 'Подожди, увидишь, какие штуки я отжигаю!',
    city_perks_guild_level_desc: 'Открывает новые улучшения',
    trade_slot_guild: 'Гильдия',
    galaxyMX_minortitle: 'Placeholder',
    galaxyMX_minormessage: 'Placeholder',
    galaxyMX_timelimittitle: 'Placeholder2',
    galaxyMX_timelimitmessage: 'Placeholder2',
    china_age_title: 'Placeholder',
    china_age_message: 'Placeholder',
    missing_item_type_adventurer:
      'Этот предмет можно найти во время события «Башня титанов».',
    missing_item_type_adventurer_hero:
      'Этот предмет можно получить особым способом, например во время мероприятий.',
    fusion_missing_worker:
      'У вас нет нужного мастера для выполнения этого синтеза',
    fusion_view_moondragon_fuse_more:
      'Чтобы вызвать лунного дракона, сначала выполните синтез других предметов!',
    can_donate: '<color=#FFDC00>Можно пожертвовать</color>',
    can_donate_collection_book:
      '<color=#874064>Этот предмет </color><color=#FFDC00>можно пожертвовать</color><color=#874064> в коллекционную книгу</color>',
    name_length_error: 'Название должно состоять из {0}–{1} знаков.',
    decoration_type_beam: 'Каркас лавки',
    beams: 'Каркасы лавки',
    unlock_beam_title: 'Покупка каркаса',
    unlock_beam_question: 'Можно будет использовать данный каркас лавки.',
    beam_applied: 'Каркас применен!',
    currently_equipped: 'Сейчас в экипировке',
    daily_deal: 'Ежедневное предложение',
    purchase_virtual_expansion_unlock_format: 'Бонусное расширение {0}',
    virtual_expansion_unlocks: 'Открытие бонусных расширений',
    virtual_expansion_unlocks_up_to: 'До бонусного расширения {0}',
    donate_warning_equipped_title: 'Предмет в экипировке',
    donate_warning_equipped_desc:
      '<color=#FFDC00>{0}</color> сейчас имеет этот предмет в экипировке. Пожертвовать его?',
    donate_warning_enchanted_title: 'Ценный предмет',
    donate_warning_enchanted_desc:
      'Колдовство у этого предмета будет уничтожено при пожертвовании.',
    donate_warning_equipped_enchanted_title: 'Ценный предмет в экипировке',
    donate_warning_equipped_enchanted_desc:
      '<color=#FFDC00>{0}</color> сейчас имеет этот заколдованный предмет в экипировке. Колдовство будет уничтожено при пожертвовании. Пожертвовать его?',
    view_tasks: 'Посмотреть задачи',
    inventory_hide_equipped_items: 'Скрывать предметы в экипировке',
    event_collection_fall_title: 'Осенняя коллекция 2023',
    event_collection_halloween_title: 'Коллекция «Хэллоуин-2023»',
    event_collection_christmas_title: 'Праздничная коллекция 2020',
    event_collection_lunar_title: 'Коллекция «Китайский Новый год 2023»',
    event_collection_valentine_title: 'Коллекция «День святого Валентина 2023»',
    event_collection_stpatrick_title: 'Коллекция «День святого Патрика 2023»',
    event_collection_easter_title: 'Коллекция «Пасха 2023»',
    event_collection_cinco_title: 'Коллекция «Синко де Майо 2023»',
    event_collection_4thofjuly_title: 'Коллекция «4 июля 2023»',
    event_collection_augustus2020_title: 'Коллекция «Август II 2023»',
    event_collection_backtoschool_title: 'Коллекция «Снова в школу 2023»',
    event_collection_arabiannight_title: 'Коллекция султана 2023',
    event_collection_pride_title: 'Коллекция гордости 2023',
    temp_hero_rank_upgrade_desc:
      'Повышение ранга защитника делает его гораздо сильнее.',
    temp_hero_time_info:
      'Этот гостевой защитник доступен лишь временно. Если он вернется в будущем, текущий прогресс будет сохранен.',
    vip_price_format_month: '{0}/месяц',
    vip_price_format_year: '{0}/год',
    vip_price_format_starts_at: '{0}/месяц',
    purchase_nosubvip2_name: 'Королевский торговец',
    purchase_nosubvip2_desc:
      '365 дней королевских преимуществ и мгновенное открытие всех наград за верность!',
    purchase_section_generic: 'Другое',
    unique_copy_desc: 'Декор уникален и продается только в одном экземпляре.',
    blueprint_not_unlocked: 'У вас еще нет этого чертежа.',
    pet_unlocked: 'Открыт питомец!',
    pet_cust_unlocked: 'Открыта персонализация питомца!',
    you_have_unlocked: 'Открыто: {0}',
    worker_special_elven01:
      '<line-height=90%><voffset=-10><size=60><sprite name="Smalltalk"></size></voffset> +5% к шансу успеха во время болтовни.',
    shop_custom: 'Персонализация',
    purchase_gems1_name: 'Набор кристаллов 1',
    purchase_gems2_name: 'Набор кристаллов 2',
    purchase_gems3_name: 'Набор кристаллов 3',
    purchase_gems5_name: 'Набор кристаллов 4',
    purchase_gems6_name: 'Набор кристаллов 5',
    purchase_gems7_name: 'Набор кристаллов 6',
    reroll_skill: 'Заменить навык',
    reroll_skill_desc: '',
    daily_offer_skill_reroller_desc:
      'Данный редкий предмет позволит заменить навык со скидкой <color=#FFDF00FF>{0}%</color>!',
    daily_offer_skill_reroller: 'Специальное предложение по замене',
    champion_name_familiar: '{0} <color=#B86F96><size=-10>и {1}</size></color>',
    compliment_plus: 'Поболтать+',
    blackfriday2021_04_description: 'Особый мастер',
    transmog_tooltip_info_hero:
      'Защитники могут использовать <color=#FFDC00>декоративное снаряжение</color> для изменения внешности своего фамильяра, при этом сохраняя параметры другого. Новое <color=#FFDC00>декоративное снаряжение</color> можно открывать при заполнении его страниц в <color=#FFDC00>коллекционной книге</color>.',
    place_bin: 'Разместить корзину',
    purchase_bin: 'Купить корзину',
    affinity: 'Связь',
    affinity_tooltip_info:
      'Этот предмет получит повышенные бонусы от колдовства ({0}).',
    blueprint_line_familiars: 'Фамильяры',
    blueprint_line_cloaks: 'Плащи',
    low_resource_capacity_notice:
      'В ваши корзины не помещается достаточно ресурсов для создания этого!',
    worker_not_available: '{0}: этого мастера еще нельзя нанять.',
    discard_familiars_title: '{0} возвращается к Иоланде',
    discard_familiars_body: '{0}: вернуть фамильяра?',
    familiars_adv_busy:
      'Фамильяров нельзя модифицировать, пока защитник участвует в приключениях.',
    att_prompt_desc:
      'Ваша информация будет использоваться для предоставления вам персонализированных игровых предложений, наград и захватывающих впечатлений.',
    notification_king_sell:
      'продает королю такой предмет, как <color={0}>{1}</color>, за {2} золотых!',
    building_unlocked: 'Здание открыто!',
    discard_all_items_warning:
      'Некоторые предметы ценны! Вы уверены, что хотите их выбросить?',
    too_high_market_tier_warning:
      'Ваш уровень недостаточно высок для покупки этого предмета на рынке.',
    repair_warning_title: 'Предупреждение о ценном предмете',
    repair_warning_destroy_single:
      'Это ценная вещь. Вы уверены, что хотите ее выбросить?',
    dungeon_unlocked: 'Открыто подземелье!',
    celebration_send_heroes: 'Отправить героев',
    full_adventurers_popup_title: 'Обзор классов героев',
    full_adventurers_popup_topdesc: '',
    full_adventurers_popup_botdesc:
      'Все ячейки героев уже заняты, но можно посмотреть на доступные классы.',
    pet_not_owned: 'Нет питомца',
    pet_not_owned_desc:
      'У вас нет питомца, связанного с этой внешностью. Все равно купить ее?',
    stat_collection_desc:
      'Прогресс по коллекционным книгам, достигнутый этим лавочником.',
    task_info: 'О задании',
    notification_member_promote_inactivity:
      'Игрок повышен до звания «{0}» из-за бездействия предыдущего главы гильдии.',
    notification_member_demote_inactivity:
      'Игрок понижен до звания «{0}» из-за бездействия.',
    guild_updates_member_promote_inactivity:
      'Повышение до звания «{0}» из-за бездействия предыдущего главы гильдии.',
    guild_updates_member_demote_inactivity:
      'Понижение до звания «{0}» из-за бездействия.',
    chest_exclusive_generic_furniture_desc:
      'Предмет мебели для размещения в лавке.',
    chest_exclusive_generic_furnitureskin_desc:
      'Особый вид для предмета мебели.',
    chest_exclusive_generic_wall_desc: 'Обои для размещения в лавке.',
    chest_exclusive_generic_floor_desc: 'Пол для размещения в лавке.',
    chest_exclusive_generic_beam_desc: 'Новая рамка для размещения в лавке.',
    chest_exclusive_generic_customization_desc:
      'Предмет персонализации для ношения.',
    chest_exclusive_generic_pet_itemhead_desc:
      'Предмет персонализации... для питомца!',
    chest_exclusive_generic_pet_itembody_desc:
      'Предмет персонализации... для питомца!',
    chest_exclusive_generic_pet_skin_desc: 'Внешность для питомца.',
    furniture_level_name_format: '{0} {1}-го ур. ({2})',
    price_increase: 'Повышение стоимости',
    craft_time_reduction: 'Сокращение времени',
    craft_resource_reduction: 'Потраченные ресурсы',
    craft_resource_reduction_1: 'Расход обычных ресурсов',
    craft_resource_reduction_2: 'Расход необычных ресурсов',
    craft_resource_reduction_3: 'Расход редких ресурсов',
    craft_component_reduction: 'Потраченные компоненты',
    craft_component_quality_req_reduced: 'Качество компонентов',
    beam_found_in_chest_popup:
      'Этот каркас лавки может найти только в сундуке.',
    stat_skills_desc_hero_element:
      'Стихия {0} этого защитника может наносить урон барьерам монстров того же типа.',
    elemental_barrier_tooltip:
      'У этого монстра есть барьер, снижающий получаемый урон. Уничтожьте его с помощью героев стихии {0}{1}!',
    ascension_reward_surcharge_energy:
      'Снижение расхода энергии на повышение цены ({0})',
    ascension_reward_resource_cost: 'Снижение расхода ресурсов ({0})',
    ascension_reward_worker_xp: 'Повышение опыта мастера ({0})',
    ascension_reward_energy_rush: 'Снижение расхода энергии на ускорение ({0})',
    ascension_reward_surcharge: 'Большее повышение цены ({0})',
    ascension_reward_component_quality_req:
      'Снижение требований к качеству компонентов ({0})',
    ascension_reward_craft_multi_chance:
      'Повышение шанса изготовить несколько предметов ({0})',
    ascension_reward_component_cost: 'Снижение расхода компонентов ({0})',
    ascension_reward_resource_cost_1: 'Снижение расхода обычных ресурсов ({0})',
    ascension_reward_resource_cost_2:
      'Снижение расхода необычных ресурсов ({0})',
    ascension_reward_resource_cost_3:
      'Снижение расхода экзотических ресурсов ({0})',
    ascension_reward_craft_time: 'Сокращение времени изготовления ({0})',
    ascension_reward_quality_chance: 'Больший шанс повышения качества ({0})',
    ascension_reward_value: 'Увеличение стоимости ({0})',
    ascension_reward_resource_regen: 'Увеличение производства ({0})',
    ascension_reward_surcharge_all: 'Большее повышение цены на все предметы',
    ascension_reward_sigil_refund: 'Шанс вернуть средства за знаки за {0}',
    filter_general: 'Содержание',
    rush_all_quests_title: 'Ускорить все приключения',
    rush_all_quests_desc:
      'Больше нет ячеек приключений для покупки. Вместо этого вы можете ускорить все текущие приключения.',
    collection_book_completion: 'Завершение книги',
    collection_book_blueprints_owned: 'Чертежи в наличии',
    collection_book_blueprints_mastered: 'Чертежи со всеми улучшениями',
    collection_book_weapons_completion: 'Завершение оружия',
    collection_book_armor_completion: 'Завершение доспеха',
    collection_book_accessories_completion: 'Завершение аксессуара',
    collection_book_enchantments_completion: 'Завершение колдовства',
    collection_book_blueprint_owned_only: 'Только чертежи в наличии',
    collection_book_general_text:
      'Чтобы пополнить коллекцию, можно пожертвовать в коллекционную книгу по одному предмету каждого качества. По мере заполнения книги открываются отличные награды!',
    label_confirm_purchase: 'Подтвердить покупку',
    label_display_inventory_checkbox: 'Отображать только инвентарь',
    require_tutorial_completion: 'Сначала вам нужно завершить обучение.',
    purchases_disabled_title: 'Покупки отключены',
    purchases_disabled_body:
      'В настоящее время покупки недоступны в вашей стране.',
    dragon_invasion_name: 'Вторжение драконов',
    dragon_invasion_guild_coop_event: 'Событие гильдии',
    dragon_invasion_description:
      'Драконы вторглись! Объединитесь с членами своей гильдии и поднимитесь на борт воздушного корабля, чтобы их прогнать!',
    more_info: 'Подробнее',
    prepare_troops: 'Подготовить воздушный корабль',
    dragon_invasion_alert_popup_title: 'Персонаж занят',
    dragon_invasion_alert_popup_body:
      'Этот персонаж сейчас приписан к воздушному кораблю. Если вы отправите его в приключение, он будет исключен из экипажа.',
    dragon_invasion_celebration_popup_title: 'Драконы побеждены',
    dragon_invasion_celebration_popup_desc:
      'Ваша гильдия победила драконов в количестве {0}!',
    dragon_info_header1: 'Назначьте команду!',
    dragon_info_body1:
      'Выберите персонажей, которые поднимутся на борт воздушного корабля и сразятся с драконами. Сила воздушного корабля — единственное, что здесь имеет значение, и вам помогут все усилители!',
    dragon_info_header2: 'Снабдите войска!',
    dragon_info_body2:
      'Обеспечьте снаряжение для корабельных войск, чтобы усилить воздушный корабль! Каждый предоставленный предмет будет продан по полной стоимости!',
    dragon_info_header3: 'Дайте отпор драконам!',
    dragon_info_body3:
      'На этапе нападения воздушный корабль будет атаковать драконов. За каждого побежденного дракона полагаются награды!',
    dragon_main_menu_phase: 'Этап подготовки',
    dragon_invasion_assault_phase: 'Этап нападения',
    dragon_invasion_preparation_phase: 'Этап подготовки',
    dragon_invasion_airship_crew_title: 'Экипаж воздушного корабля',
    dragon_invasion_enroll_crew_title: 'Назначить команду',
    dragon_invasion_equip_boosters_title: 'Назначить усилители',
    dragon_invasion_supply_tab_title: 'Снабдить',
    dragon_invasion_crew_select_custom_title: 'Выбрать команду',
    dragon_invasion_crew_select_custom_subtitle:
      'Персонажи на воздушном корабле недоступны для приключений.',
    dragon_invasion_boosters_select_custom_title: 'Выбрать усилители',
    dragon_invasion_boosters_select_custom_subtitle:
      'Все усилители дают бонус к силе воздушного корабля.',
    dragon_invasion_supplies_title: 'Припасы',
    dragon_invasion_supplies_guild_effort_title: 'Войска гильдии',
    dragon_invasion_supplies_donation_title: 'Снабжение войск',
    dragon_invasion_supplies_donation_desc:
      'Припасы, переданные войскам, считаются <c1>продажей</c1> по полной стоимости в золоте.',
    dragon_invasion_supplies_donation_tab_desc:
      'Каждый боец может использовать четыре предмета. Более мощные предметы дают большее усиление воздушного корабля.',
    dragon_invasion_supplies_donation_tab_btn: 'Снабдить снаряжением',
    dragon_invasion_supplies_donation_tab_complete_btn: 'Войска готовы',
    dragon_invasion_supplies_total_troops_power: 'Общая сила войск',
    dragon_invasion_tip_crew_text: 'Сила воздушного корабля команды',
    dragon_invasion_tip_booster_text: 'Бонус к силе',
    no_more_slot_in_the_ship: 'Ваша команда заполнена!',
    no_more_slot_booster_in_the_ship: 'Больше нет места для усилителей!',
    dragon_invasion_ship_tab: 'Ваша команда',
    dragon_invasion_guild_tab: 'Команды гильдии',
    dragon_invasion_menu_airship_crew: 'Снаряжение для войск<br>{0}/{1}',
    dragon_invasion_menu_phase_attack_title: 'Этап нападения',
    dragon_invasion_menu_phase_attack_desc:
      'Воздушный корабль в настоящее время атакует! Вы можете начать подготовку войск к следующему этапу нападения!',
    dragon_invasion_menu_airship_crew_fighting: 'Сейчас сражается',
    dragon_invasion_menu_phase_supply_title: 'Этап подготовки',
    dragon_invasion_menu_phase_supply_desc:
      'Соберите отряд и снабдите войска, чтобы подготовить следующее нападение!',
    dragon_invasion_menu_phase_supply_fully_enrolled_and_donated_desc:
      'Экипаж готов! Сядьте поудобнее и ждите следующего этапа нападения!',
    dragon_invasion_menu_power_left: 'Осталось силы воздушного корабля',
    dragon_invasion_current_wave_contribution:
      'Ваш вклад: <color=#12FB63>{0}</color>',
    not_enough_dragon_emblem: 'Недостаточно драконьих знаков!',
    dragon_invasion_popup_progress_description:
      'Накапливайте <color=#E4C704>силу воздушного корабля</color>, чтобы победить вторгшихся драконов!',
    dragon_invasion_total_damage_dealt: 'Нанесено урона',
    quest_event_dragon_preparation_ended:
      'Этап подготовки закончился, и корабль взлетел.',
    trade_donate_from: 'Снабжать с рынка',
    dragons_defeated_slider: 'Победа!',
    dragons_defeated_desc:
      'Все драконы уничтожены, и город в безопасности! Воздушный корабль по-прежнему будет отправляться на патрулирование, чтобы заработать больше очков.',
    dragon_invasion_menu_airship_crew_patrolling: 'Сейчас патрулирует',
    champion_on_ship: 'На воздушном корабле',
    dragon_invasion_menu_power_tooltip:
      'Объединенная сила героев и войск на воздушном корабле. Используется для нанесения урона драконам на этапе нападения.',
    dragon_invasion_donation_sold_notif:
      'Такой предмет, как {0}, продан за {1} зол.',
    dragon_invasion_menu_final_wave_desc:
      'Настало время для последнего этапа нападения! Больше никаких вкладов сделать нельзя!',
    dragon_invasion_menu_personal_contribution: 'Ваш вклад',
    dragon_invasion_menu_airship_power: 'Сила воздушного корабля',
    resource_regen: 'Восстановление ресурсов',
    unlock_track_desc: 'Открытие наград до:',
    gem_sub_price_format_week: '{0} на 7 дней',
    gem_sub_per_day_desc: '100 кристаллов в день',
    gem_sub_per_day_short_desc: 'x100/день',
    gem_sub_detail_desc: 'Получайте кристаллы ежедневно в течение 7 дней!',
    gem_sub_title: 'Дерево кристаллов',
    gem_sub_desc: 'Получайте кристаллы каждый день!',
    gem_sub_days_left: 'Осталось {0} д.',
    gem_sub_celebration_desc: 'Ваше дерево дало несколько кристаллов!',
    day_subscription: 'Подписка на {0} д.',
    offer_has_ended: 'Дерево кристаллов завяло...',
    offer_purchase_again: 'Хотите подписаться снова?',
    last_chance_quest_event_title: 'Последний шанс!',
    last_chance_quest_event_desc:
      'Закрыв это окно, вы завершите событие и откажетесь от оставшихся призов. Вы уверены?',
    dragon_prepare_crew: 'Подготовить экипаж',
    feed_pet_title: 'Покормить питомца',
    pet_food_normal_title: 'Корм для питомцев',
    affection_description_card: 'Привязанность',
    feed_from_market_title: 'Кормить с рынка',
    trade_donation_empty: 'Нет предметов в наличии.',
    trade_donation_wait_for_data: 'Поиск данных о рынке...',
    quest_event_dragon_next_cycle:
      'Список лидеров будет обновлен после следующей волны.',
    enroll_crew: 'Прикомандировать экипаж',
    fresh: 'Свежее!',
    blueprint_line_meals: 'Блюда',
    blueprint_line_desserts: 'Десерты',
    player_brother: 'брат',
    player_sister: 'сестра',
    trade_sort_default: 'Сортировать по умолчанию',
    trade_sort_power: 'Сортировать по силе',
    trade_sort_recent: 'Сортировать по последним',
    trade_sort_quantity: 'Сортировать по количеству',
    trade_sort_value: 'Сортировать по стоимости',
    trade_sort_value_asc: 'Сортировать по стоимости –',
    trade_sort_value_dsc: 'Сортировать по стоимости +',
    trade_sort_value_asc_gold: 'Сортировать по стоимости в золоте (-)',
    trade_sort_value_dsc_gold: 'Сортировать по стоимости в золоте (+)',
    trade_sort_value_asc_gems: 'Сортировать по стоимости в кристаллах (-)',
    trade_sort_value_dsc_gems: 'Сортировать по стоимости в кристаллах (+)',
    trade_sort_tier: 'Сортировать по уровню',
    trade_sort_tier_asc: 'Сортировать по рангу –',
    trade_sort_tier_dsc: 'Сортировать по рангу +',
    trade_sort_energy_asc: 'Сортировать по энергии –',
    trade_sort_energy_dsc: 'Сортировать по энергии +',
    trade_sort_food_xp: 'Сортировать по опыту питомца',
    trade_sort_quality: 'Сортировать по качеству',
    trade_sort_type: 'Сортировать по типу',
    trade_sort_stats: 'Сортировать по параметрам',
    trade_sort_chances: 'Сортировать по % успеха',
    trade_sort_area: 'Сортировать по зоне',
    worker_special_baker1:
      '<line-height=90%><voffset=-10><size=60><sprite name="BakerPower"></size></voffset> Король Рейнгольд делает покупки дважды за визит',
    kabam_cross_connect_title_2lines: 'Kabam\nCross Connect',
    kabam_cross_connect_title: 'Kabam Cross Connect',
    intro_login_to_cross_connect: 'Вход в Cross Connect',
    login_button: 'ВОЙТИ',
    login_label: 'Войти',
    welcome_back_label: 'С возвращением',
    login_to_continue_label:
      'Войдите для продолжения использования существующей учетной записи.',
    continue_btn: 'Продолжить',
    enter_your_email_instruction_label:
      'Введите электронный адрес для получения кода входа.',
    enter_your_email_input_placeholder: 'Укажите электронный адрес',
    reenter_your_email_input_placeholder: 'Повторите электронный адрес',
    one_more_step_label: 'Еще один шаг!',
    submit_button: 'ОТПРАВИТЬ',
    verification_code_sent_label: 'Проверочный код отправлен по адресу:',
    please_enter_code_label: 'Введите код, чтобы продолжить.',
    enter_digit_code_input_placeholder: 'введите код',
    'receive_news_and_exclusive _label':
      'Получайте новости и эксклюзивные предложения от Kabam',
    register_label: 'Регистрация',
    register_button: 'РЕГИСТРАЦИЯ',
    allset_label: 'Все готово!',
    allset_instruction_label:
      'Ваша игровая учетная запись теперь подключена к Kabam Cross Connect. Вы можете вернуться в Kabam Cross Connect в любое время через игровое меню.',
    okay_button: 'OK',
    enter_name_label: 'Введите свое имя Kabam Cross Connect',
    enter_name_instruction_label:
      'Это имя будет доступно другим пользователям. Не указывайте свое настоящее имя.',
    enter_name_input_placeholder: 'Введите имя пользователя',
    cancel_button: 'ОТМЕНА',
    create_new_label: 'Создать новое',
    create_new_instruction:
      'Используйте действительный электронный адрес, чтобы получить проверочный код!',
    load_account_question_label: 'Загрузить существующую учетную запись?',
    confirm_btn: 'Подтвердить',
    load_account_instruction_label:
      'Вы хотите подключить этого пользователя к данному устройству?',
    user_level_label: 'уровень',
    error_label: 'Ошибка',
    error_invalid_code: 'Введен недействительный код.',
    error_exceeded_code_attemps:
      'Превышено количество попыток ввода кода. Повторите попытку позже.',
    error_network:
      'Произошла сетевая ошибка при соединении с нашими серверами. Повторите попытку позже.',
    error_unknown: 'Произошла неизвестная ошибка.',
    no_progression_found_label:
      'В этой учетной записи не обнаружен игровой прогресс. Продолжить?',
    yes_button: 'Да',
    no_button: 'Нет',
    no_progression_found_title_label: 'Прогресс не обнаружен',
    no_progression_found_cannot_continue_label:
      'Мы не смогли найти сохраненную игру для этой учетной записи. Подтвердите и повторите попытку.',
    switch_user_button: 'СМЕНИТЬ ПОЛЬЗОВАТЕЛЯ',
    error_setname_profanity: 'Имя не должно содержать непристойных слов.',
    error_link_account: 'Не удалось привязать учетную запись.',
    create_user: 'Создать пользователя',
    create_label_btn: 'Создать',
    success_label: 'Готово!',
    login_kabam_id_label:
      'Войдите под своим Kabam ID и отслеживайте игровой прогресс на всех устройствах!',
    error_invalid_email_address: 'Недействительный электронный адрес.',
    registration_done_description_label:
      'Пользователь <color=#{0}>{1}</color> подключен к данному устройству. Вы можете получить доступ к своей учетной записи Kabam Cross Connect через меню настроек игры.',
    error_exceeded_requests:
      'Превышено допустимое количество запросов. Повторите попытку позже.',
    flashquest_tries: 'Попытки',
    flashquest_desc_common:
      'Говорят, что этот монстр, помимо прочего, дает много <color=#{0}>опыта</color> вашим героям.',
    flashquest_desc_gems:
      'Говорят, что этот монстр, помимо прочего, дает много <color=#{0}>кристаллов</color> вашим героям.',
    flashquest_desc_boosters:
      'Говорят, что этот монстр, помимо прочего, дает много <color=#{0}>усилителей</color> вашим героям.',
    flashquest_difficulty_1: '<color=#{0}>+</color>',
    flashquest_difficulty_2: '<color=#{0}>++</color>',
    flashquest_difficulty_3: '<color=#{0}>+++</color>',
    tier_x_itemtype: '{1} {0}-го уровня',
    dragon_invasion_menu_airship_crew_number:
      'Экипаж воздушного корабля: <br>{0}/{1}',
    fusion_subtitle_fail:
      'Пепел такого предмета, как {0}, усиливает котел синтеза.',
    fusion_description_fail:
      '<sprite name=FusionBonus> +{0}% к шансу легендарного синтеза при синтезе на следующем {1}-м уровне!',
    legendary_fusion_success_odds:
      '<sprite name=FusionBonus> <color=#FDDC00>{0}</color> шанс успеха (базовое значение: {1})',
    legendary_fusion_success_rate_returns:
      'Шанс успеха возвращается к значению <sprite name=FusionBonus>{0}',
    repair_desc_adv: 'Предмет поврежден!',
    repair_subtitle_2_adv: 'Поврежденный предмет',
    invalid_quester_broken_items:
      '{0} имеет поврежденное снаряжение, которое нужно снять.',
    fuse_warning_enchanted_title: 'Заколдованный предмет',
    fuse_warning_enchanted_desc:
      'Один из выбранных предметов заколдован. Продолжить?',
    craft_warning_enchanted_desc:
      'Один из компонентов предметов заколдован. Продолжить?',
    quest_event_tower_info: 'Одиночное событие',
    titantower_difficulty_popup_title: 'Выберите свое испытание',
    titantower_difficulty_recommended: 'Рекомендуемая',
    titantower_difficulty_completion_chance: 'Предполагаемый шанс успеха:',
    titantower_difficulty_bottom:
      'Выбранная сложность сохранится до конца события.',
    titantower_difficulty_tooltip_3:
      'Ожидаемый шанс успеха против босса башни для таких персонажей, как {0}, {1} и {2}, при использовании сверхусилителя мощи.',
    titantower_difficulty_tooltip_4:
      'Ожидаемый шанс успеха против босса башни для таких персонажей, как {0}, {1}, {2} и {3}, при использовании сверхусилителя мощи.',
    titantower_difficulty_quest_card_bottom: 'Выбрать сложность',
    give_warning_enchanted_desc:
      'Один из выбранных предметов заколдован. Продолжить?',
    player_level_up_flashquest: 'Новое блиц-приключение',
    player_level_up_detail_flashquest_title: 'Новое блиц-приключение',
    player_level_up_detail_flashquest_desc:
      'Теперь время от времени будет появляться новое блиц-приключение!',
    flashquest_title: 'Блиц-приключение!',
    flashquest_completed: 'Блиц-приключение завершено!',
    flashquest_failed: 'Неудача в блиц-приключении!',
    fq_info_popup_desc:
      'Особый босс появился ненадолго! От него можно получить особую добычу, но вы можете бросить ему вызов не более <color=#FFFF00>{0}</color> раз, прежде чем он уйдет!',
    fq_info_popup_subtitle: 'Одиночное испытание',
    fq_info_popup_title: 'Блиц-приключения',
    character_on_ship: '{0} занимается делами на воздушном корабле',
    error_4011:
      'Версия игры с вашей учетной записью отличается от версии на сервере.',
    quest_event_dragon_top_guilds_title: 'Лучшие гильдии',
    quest_event_dragon_top_guilds_top_desc:
      'Состязайтесь с другими гильдиями, чтобы набрать максимум очков за событие!',
    quest_event_dragon_top_guilds_bottom_desc:
      'Когда событие закончится, 10 лучших гильдий получат временный эксклюзивный титул и значок, которыми можно будет похвастаться!',
    quest_event_dragon_top_guilds_bottom: 'Текущий рейтинг:',
    achievement_tab: 'Достижения',
    player_title_tab: 'Титулы',
    celebration_new_title: 'Получен титул!',
    celebration_unlock_new_title_desc: 'Теперь его можно показать всем!',
    edit_player_info_title: 'Об игроке',
    unlock_player_title_tab: 'Требуется {0}-й уровень',
    player_title_tier: '{0}-й уровень',
    player_level_up_playertitle: 'Титулы игроков',
    player_level_up_detail_playertitle_title: 'Доступные титулы игроков',
    player_level_up_detail_playertitle_desc:
      'Теперь вы можете использовать титулы игроков!',
    quest_setup_no_more_slot: 'Нет доступной ячейки',
    settings_account_delete: 'Удалить',
    settings_account_restore: 'Восстановить',
    account_delete_restore_title: 'Удаление учетной записи',
    account_delete_restore_continue: 'Продолжить',
    account_delete_restore_restore: 'Восстановить учетную запись',
    account_delete_restore_description:
      'Ваша учетная запись будет удалена через <color=#FFB72C>{0}</color>.<br>Вся информация, связанная с данной учетной записью, будет удалена навсегда.',
    account_delete_restore_warning: 'Восстановить учетную запись',
    account_delete_title: 'Удаление учетной записи',
    account_delete_cancel: 'Отмена',
    account_delete_delete: 'Удалить учетную запись',
    account_delete_description:
      'Вы уверены, что хотите удалить свою учетную запись?<br>Вся информация, связанная с данной учетной записью, будет удалена через <color=#FFB72C>{0} д.</color> Вы можете отменить эту процедуру, перейдя в меню настроек.',
    account_delete_warning: 'Ваша учетная запись ожидает очереди для удаления.',
    player_level_up_antique_seasonal_delay: 'Новые древности!',
    player_level_up_detail_antiqueseasonaldelay_title: 'Новые древности',
    player_level_up_detail_antiqueseasonaldelay_desc:
      'В магазине древностей теперь доступно больше товаров!',
    title_tooltip_info: '<size=+5>{0} {1} </size>\n<size=-7>{2}</size>',
    city_upgrade_essres_title: 'Вместимость сущности',
    city_upgrade_essres_desc: 'Корзины дают +{0} к вместимости',
    city_upgrade_essresregen_title: 'Восстановление сущности',
    city_upgrade_essresregen_desc: 'Восстановление сущности +{0}',
    china_identity_title: 'Проверка личности',
    china_identity_placeholder_name: 'Введите официальное имя',
    china_identity_placeholder_id: 'Введите идентификационный номер',
    china_privacy_checkbox_text:
      'Пожалуйста, примите наши <color="green"><b><link="https://sdk-api.galaxymx.com/protocol/user_service/">Условия использования</link></b></color> и нашу <color="green"><b><link="https://sdk-api.galaxymx.com/protocol/privacy">Политику конфиденциальности</link></b></color>',
    decline: 'Отклонить',
    regen_hour_format: '{0}<size=-22>/ч.</size>',
    regen_day_format: '{0}<size=-22>/д.</size>',
    guild_perks_details_init_essresregen_desc:
      'Ускорение восстановления сущности',
    guild_perks_details_init_essres_desc: 'Увеличенная корзина',
    change_player_title_title: 'Сменить титул',
    customize_username_title: 'Сменить имя пользователя',
    view_options: 'Посмотреть опции',
    cannot_upgrade_furniture_inventory: 'Нельзя улучшить мебель в инвентаре',
    ascension_reward_sigil2_refund: 'Шанс вернуть истинные знаки за {0}',
    quest_event_no_rankings: 'Рейтинга пока нет!',
    guildmate: 'Член гильдии',
    quest_event_tower_select_tower: 'Выбрать башню',
    privacy_prompt_body_02:
      'Нажмите «Принять», чтобы подтвердить свое согласие.',
    cant_store_in_furniture: 'Нельзя отправить на хранение: {0} ({1})',
    upgrade_class: 'Улучшить класс',
    requested_items: 'Запрошенные предметы',
    adventurer_custom_title: 'Использовать предмет',
    'invalid_free_flashquest_hero ':
      'Bjorn must be in the party for this bonus Flash Quest attempt.',
    bpass_popup_progress_title: 'Пропуск на контент',
    bpass_popup_progress_desc: 'Выполните задания, чтобы получить награды!',
    bpass_currency_tooltip:
      'Ваш накопленный прогресс в рамках текущего пропуска на контент. Собирайте {0}, выполняя задания, и получите еще больше наград!',
    bpass_main_menu_title: 'Пропуск на контент',
    bpass_main_menu_desc:
      'Выполните эти задания, чтобы заработать {0} и открыть награды!',
    bpass_rush_offer_desc: 'Чтобы забрать эту награду, нужно:',
    bpass_rush_offer_gems_title: 'Открыть',
    bpass_info_popup_title: 'Пропуск на контент',
    bpass_info_popup_atasks_title: 'Выполните задания',
    bpass_info_popup_atasks_desc:
      'Выполните задания пропуска на контент, чтобы получить {0}.',
    bpass_info_popup_rewards_title: 'Получите награды',
    bpass_info_popup_rewards_desc:
      'Чем в большем количестве у вас {0}, тем больше наград можно получить.',
    bpass_info_popup_bpo_title: 'Полный доступ',
    bpass_info_popup_bpo_desc:
      'Купите полный доступ, для того, чтобы получить все награды из списка, если у вас их достаточно {0}!',
    bpass_view_battle_pass_button: 'Посмотреть полный доступ',
    bpass_last_chance_rewards_BPO:
      'Это ваш последний шанс обменять кристаллы на {0}!',
    bpass_last_chance_rewards_no_BPO:
      'Это ваш последний шанс купить полный доступ и получить еще больше наград!',
    bpass_last_chance_purchased: 'Полный доступ куплен!',
    bpass_offer_button_active: 'Пропуск на контент',
    bpass_task_login_desc: 'Ежедневный вход',
    bpass_task_buy_market_desc: 'Купите предметы на рынке',
    bpass_task_sell_market_desc: 'Продайте предметы на рынке',
    bpass_task_quests_desc: 'Выполните задания',
    bpass_task_quests_with_desc: 'Выполните задания с такие героем, как {0}',
    bpass_task_quests_specific_desc: 'Выполните задание «{0}»',
    bpass_task_champion_request_desc: 'Выполните запросы защитников',
    bpass_task_champion_request_specific_desc:
      'Выполните запросы защитника {0}',
    bpass_task_guild_request_desc: 'Выполните запросы гильдии',
    bpass_task_gather_components_desc: 'Собирайте компоненты в приключениях',
    bpass_task_gather_components_specific_desc:
      'Соберите такой компонент, как {0}',
    bpass_task_craft_item_desc: 'Изготовьте предметы',
    bpass_task_craft_item_specific_desc: 'Изготовьте: {0}',
    bpass_task_craft_item_quality_desc: 'Изготовьте предметы: {0}',
    bpass_task_craft_item_quality_specific_desc: 'Изготовьте: {1} x{0}',
    bpass_task_craft_item_enchant_desc: 'Изготовьте заколдованные предметы',
    bpass_task_craft_item_specific_enchant_desc:
      'Изготовьте заколдованные предметы: {0}',
    bpass_task_craft_item_enchant_specific_desc:
      'Изготовьте предметы, заколдованные с помощью ресурса «{1}»',
    bpass_task_craft_item_specific_enchant_specific_desc:
      'Изготовьте такие предметы, как {0}, заколдованные с помощью ресурса «{1}»',
    bpass_task_craft_item_enchant_element_desc:
      'Изготовьте предметы, заколдованные с помощью стихий',
    bpass_task_craft_item_enchant_element_specific_desc:
      'Изготовьте такие предметы, как {0}, заколдованные с помощью стихий',
    bpass_task_craft_item_enchant_spirit_desc:
      'Изготовьте предметы, заколдованные с помощью духов',
    bpass_task_craft_item_enchant_spirit_specific_desc:
      'Изготовьте такие предметы, как {0}, заколдованные с помощью духов',
    bpass_task_craft_item_element_desc: 'Создайте стихии',
    bpass_task_craft_item_element_specific_desc:
      'Создайте такую стихию, как {0}',
    bpass_task_craft_item_spirit_desc: 'Создайте духов',
    bpass_task_craft_item_spirit_specific_desc: 'Создайте такого духа, как {0}',
    bpass_task_defeat_bosses_desc: 'Победите боссов',
    bpass_task_defeat_bosses_with_desc:
      'Победите боссов с таким героем, как {0}',
    bpass_task_defeat_bosses_specific_desc: 'Победите босса {0}',
    bpass_task_enchant_desc: 'Заколдуйте предметы',
    bpass_task_enchant_element_desc:
      'Заколдуйте предметы с помощью такой стихии, как {0}',
    bpass_task_enchant_spirit_desc:
      'Заколдуйте предметы с помощью такой стихии, как {0}',
    bpass_task_fusions_desc: 'Выполните синтез',
    bpass_task_earn_gold_desc: 'Заработайте золото за продажу',
    bpass_task_king_sell_desc: 'Продайте королю',
    bpass_task_earn_gold_specific_desc: 'Заработайте золото за продажу ({0})',
    bpass_task_small_talk_desc: 'Успешно поболтайте',
    bpass_task_surcharge_desc: 'Повысьте цену для клиентов',
    bpass_task_discount_desc: 'Понизьте цену для клиентов',
    bpass_task_buy_pack_desc: 'Купите любую часть меганабора',
    bpass_task_open_chest_desc: 'Откройте сундуки',
    bpass_task_open_chest_specific_desc: 'Откройте сундук «{0}»',
    bpass_task_open_chest_gem_desc: 'Откройте сундуки с кристаллами',
    bpass_task_open_chest_gem_specific_desc:
      'Откройте сундук с кристаллами «{0}»',
    bpass_task_bounty_trophies_desc: 'Получите трофеи за поручения',
    bpass_task_resource_desc:
      'Потратьте такой ресурс, как {0}, на изготовление',
    bpass_task_vip_desc: 'Для королевских торговцев',
    bpass_task_itemsell_desc: 'Продайте предметы',
    bpass_task_itemsell_specific_desc: 'Продайте: {0}',
    bpass_task_itemsell_quality_desc: 'Продайте предметы: {0} шт.',
    bpass_task_itemsell_quality_specific_desc: 'Продайте: {1} x{0}',
    bpass_task_itemsell_enchant_desc: 'Продайте заколдованные предметы',
    bpass_task_itemsell_specific_enchant_desc:
      'Продайте заколдованные предметы: {0}',
    bpass_task_itemsell_enchant_specific_desc:
      'Продайте предметы, заколдованные с помощью ресурса «{1}»',
    bpass_task_itemsell_specific_enchant_specific_desc:
      'Продайте такие предметы, как {0}, заколдованные с помощью ресурса «{1}»',
    bpass_task_itemsell_enchant_element_desc:
      'Продайте предметы, заколдованные с помощью стихий',
    bpass_task_itemsell_enchant_element_specific_desc:
      'Продайте такие предметы, как {0}, заколдованные с помощью стихий',
    bpass_task_itemsell_enchant_spirit_desc:
      'Продайте предметы, заколдованные с помощью духов',
    bpass_task_itemsell_enchant_spirit_specific_desc:
      'Продайте такие предметы, как {0}, заколдованные с помощью духов',
    bpass_task_itemsell_element_desc: 'Продайте стихии',
    bpass_task_itemsell_element_specific_desc: 'Продайте стихии: {0} шт.',
    bpass_task_itemsell_spirit_desc: 'Продайте духов',
    bpass_task_itemsell_spirit_specific_desc: 'Продайте духов: {0} шт.',
    bpass_task_pet_food_desc: 'Покормите питомцев',
    bpass_task_pet_food_superior_desc:
      'Покормите питомцев превосходным или премиальным кормом',
    bpass_task_pet_food_premium_desc: 'Покормите питомцев премиальным кормом',
    bpass_task_piggy_claim_desc: 'Разбивайте копилки',
    bpass_access_rewards: 'Получите доступ ко всем наградам',
    bpass_event_atlab2021_name: 'Аватар: Повелитель стихий',
    bpass_offer_atlab2021_title: 'Аватар: Повелитель стихий',
    bpass_offer_atlab2021_desc:
      'Получите еще больше наград <color=#FFDC00>«Аватара: Повелителя стихий»</color> с полным доступом!',
    bpass_offer_atlab2021_timer: 'До конца: {0}',
    bpass_offer_atlab2021_view_tasks: 'Посмотреть все задания',
    bpass_atlab2021_currency: 'Медные монеты',
    bpass_task_atlab2021_login_title: 'Вода. Земля. Огонь. Воздух.',
    bpass_task_atlab2021_craftany_title: 'Наставление Пиандао',
    bpass_task_atlab2021_craftherbs_title: 'Лакомства для Аппы',
    bpass_task_atlab2021_craftspiritss_title: 'Путешествия по миру духов',
    bpass_task_atlab2021_spendiron_title: 'Управление металлом Тоф',
    bpass_task_atlab2021_fusion_title: 'Гармония ци',
    bpass_task_atlab2021_questany_title: 'Приключение!',
    bpass_task_atlab2021_questaang_title: 'Поддержка Аватара в форме',
    bpass_task_atlab2021_defeatboss_title: 'Обучение с бумерангом',
    bpass_task_atlab2021_anycomponents_title: 'Поиск еды Соккой',
    bpass_task_atlab2021_fire_title: 'Учение Зуко',
    bpass_task_atlab2021_smalltalk_title: 'Заводите друзей!',
    bpass_task_atlab2021_sellspears_title: 'Арсенал Народа Огня',
    bpass_task_atlab2021_itemsell_title: 'Оптовая продажа капусты',
    bpass_task_atlab2021_marketsell_title: 'Торговля с высоким риском',
    bpass_task_atlab2021_guildrequest_title: 'Мудрость Айро',
    bpass_task_atlab2021_championrequest_title: 'Эпизод с покупками',
    bpass_task_atlab2021_aangrequest_title: 'Команда Аватара',
    bpass_task_atlab2021_bountytrophies_title: 'Дружеское состязание',
    bpass_task_atlab2021_chest_title: 'Сокровище Ван Ши Тонга',
    bpass_task_atlab2021_craftmoonstones_title: 'Подарок Юи',
    bpass_task_atlab2021_buypack_title: 'Меганабор!',
    bpass_task_atlab2021_vip_title: 'Королевские преимущества',
    bpass_event_lny2022_name: 'Год Тигра',
    bpass_offer_lny2022_title: 'Год Тигра',
    bpass_offer_lny2022_desc:
      'Полный доступ даст еще больше наград <color=#FFDC00>китайского Нового года</color>!',
    bpass_offer_lny2022_timer: 'До конца: {0}',
    bpass_offer_lny2022_view_tasks: 'Посмотреть все задания',
    bpass_lny2022_currency: 'Мандарины',
    bpass_lny2022_pushnotestarted_title:
      '❗ Мероприятие «Китайский Новый год»!',
    bpass_lny2022_pushnotestarted_desc:
      'Появился пропуск на контент «Китайский Новый год»! Откройте эксклюзивные награды!',
    bpass_lny2022_pushnoteending_title:
      '⏰ Мероприятие «Китайский Новый год» скоро закончится!',
    bpass_lny2022_pushnoteending_desc:
      'Для пропуска на контент «Китайский Новый год» осталось всего несколько дней!',
    bpass_task_lny2022_login_title: 'Праздники!',
    bpass_task_lny2022_petfood_title: 'Тигриный пир',
    bpass_task_lny2022_craftany_title: 'За процветание',
    bpass_task_lny2022_craftpotions_title: 'И крепкое здоровье',
    bpass_task_lny2022_sellhelm_title: 'Новогодняя блиц-распродажа',
    bpass_task_lny2022_spendfabric_title: 'Шелковый путь',
    bpass_task_lny2022_superiorcraft_title: 'Превосходный ремесленник',
    bpass_task_lny2022_legendarycraft_title: 'Высшее мастерство',
    bpass_task_lny2022_bountytrophies_title: 'Награда гильдии',
    bpass_task_lny2022_kingsell_title: 'Щедрый король',
    bpass_task_lny2022_fusion_title: 'Воссоединение',
    bpass_task_lny2022_earngold_title: 'Красный конверт',
    bpass_task_lny2022_surcharge_title: 'Золото и нефрит',
    bpass_task_lny2022_marketsell_title: 'Торговец городской ярмарки',
    bpass_task_lny2022_marketbuy_title: 'Посетитель городской ярмарки',
    bpass_task_lny2022_rudorequest_title: 'Крадущийся тигр',
    bpass_task_lny2022_questyami_title: 'Затаившийся дракон',
    bpass_task_lny2022_defeatanyboss_title: 'Изгнание злого духа',
    bpass_task_lny2022_questany_title: 'Тигриная охота',
    bpass_task_lny2022_anycomponents_title: 'Потерянные сокровища',
    bpass_task_lny2022_gemchest_title: 'Чудесные подарки',
    bpass_task_lny2022_buypack_title: 'Меганабор!',
    bpass_task_lny2022_vip_title: 'Королевские преимущества',
    bpass_task_lny2022_piggybankclaim_title: 'Получение содержимого копилки',
    bpass_event_cinco2022_name: 'Синко де Майо 2022',
    bpass_offer_cinco2022_title: 'Синко де Майо',
    bpass_offer_cinco2022_desc:
      'С полным доступом вы получите еще больше наград <color=#FFDC00>Синко де Майо</color>!',
    bpass_offer_cinco2022_timer: 'До конца: {0}',
    bpass_offer_cinco2022_view_tasks: 'Посмотреть все задания',
    bpass_cinco2022_currency: 'Пиньята',
    bpass_cinco2022_pushnotestarted_title: '❗ Событие «Синко де Майо»!',
    bpass_cinco2022_pushnotestarted_desc:
      'Появился пропуск на контент «Синко де Майо»! Откройте эксклюзивные награды!',
    bpass_cinco2022_pushnoteending_title:
      '⏰ Событие «Синко де Майо» скоро закончится!',
    bpass_cinco2022_pushnoteending_desc:
      'Пропуск на контент «Синко де Майо» доступен еще несколько дней!',
    bpass_task_cinco2022_login_title: 'Праздники!',
    bpass_task_cinco2022_piggybank_title: 'Охотники за пиньятами',
    bpass_task_cinco2022_craftany_title: 'Все сойдет',
    bpass_task_cinco2022_craftherbs_title: 'Предложение мистера Палты',
    bpass_task_cinco2022_fusion_title: 'Лунный свет Лундры',
    bpass_task_cinco2022_spendany_title: 'Усердие',
    bpass_task_cinco2022_earngold_title: 'Блестящее золото',
    bpass_task_cinco2022_itemsell_title: 'Уговор есть уговор',
    bpass_task_cinco2022_sellitemquality_title: 'Великолепные товары',
    bpass_task_cinco2022_surcharge_title: 'Сколько-сколько?!',
    bpass_task_cinco2022_smalltalk_title: 'Торговец-эксперт',
    bpass_task_cinco2022_questany_title: 'Путешественники по миру',
    bpass_task_cinco2022_questlocation_title: 'Огромные просторы',
    bpass_task_cinco2022_defeatbosshero_title: 'Злодей возвращается',
    bpass_task_cinco2022_gathercomponent_title: 'Лебединое прикосновение',
    bpass_task_cinco2022_enchantany_title: 'Придадим остроты',
    bpass_task_cinco2022_kingsell_title: 'Эль Капричосо',
    bpass_task_cinco2022_fulfillanychampionrequest_title: 'Вся банда в сборе',
    bpass_task_cinco2022_bountytrophies_title: 'Поднять знамена',
    bpass_task_cinco2022_openanychest_title: 'Ящик с чудесами',
    bpass_task_cinco2022_craftfamiliars_title: 'Настоящие звери',
    bpass_task_cinco2022_buypack_title: 'Меганабор!',
    bpass_task_cinco2022_vip_title: 'Королевские преимущества',
    bpass_event_halloween2022_name: 'Хэллоуин 2022',
    bpass_offer_halloween2022_title: 'Хэллоуин',
    bpass_offer_halloween2022_desc:
      'С полным доступом вы получите еще больше наград на <color=#FFDC00>Хэллоуин</color>!',
    bpass_offer_halloween2022_timer: 'До конца: {0}',
    bpass_offer_halloween2022_view_tasks: 'Посмотреть все задания',
    bpass_halloween2022_currency: 'Конфеты',
    bpass_halloween2022_pushnotestarted_title: '❗ Событие «Хэллоуин»!',
    bpass_halloween2022_pushnotestarted_desc:
      'Появился пропуск на контент «Хэллоуин»! Откройте эксклюзивные награды!',
    bpass_halloween2022_pushnoteending_title:
      '⏰ Событие «Хэллоуин» скоро закончится!',
    bpass_halloween2022_pushnoteending_desc:
      'Пропуск на контент «Хэллоуин» доступен еще несколько дней!',
    news_halloween2022_title: 'Пропуск на контент «Хэллоуин» уже в игре!',
    news_halloween2022_body:
      'Собирайте конфеты, чтобы открыть этот жуткий эксклюзивный контент!',
    bpass_task_halloween2022_login_title: 'Ночь страха',
    bpass_task_halloween2022_piggybank_title: 'Необычная пиньята',
    bpass_task_halloween2022_smalltalk_title: 'Зловещие гости',
    bpass_task_halloween2022_fusion_title: 'Сумеречная ночь',
    bpass_task_halloween2022_craftany_title: 'Припасы для Хэллоуина',
    bpass_task_halloween2022_craftherbs_title: 'Тыквомантия',
    bpass_task_halloween2022_spendiron_title: 'Железо для могильных оград',
    bpass_task_halloween2022_spendwood_title: 'Торговец темной древесиной',
    bpass_task_halloween2022_dark_title: 'Темные делишки',
    bpass_task_halloween2022_questlilu_title: 'Маленькая ведьма',
    bpass_task_halloween2022_fulfillanychampionrequest_title:
      'Выпрашиватели сладостей',
    bpass_task_halloween2022_itemsell_title: 'Сладость... или гадость?',
    bpass_task_halloween2022_surcharge_title: 'Хэллоуинская наценка',
    bpass_task_halloween2022_discount_title: 'Первое ноября',
    bpass_task_halloween2022_bountytrophies_title: 'Благодатная затея',
    bpass_task_halloween2022_openanychest_title: 'Сладкий сюрприз',
    bpass_task_halloween2022_marketsell_title: 'Черный рынок',
    bpass_task_halloween2022_gathercomponent_title: 'Конфетная добыча',
    bpass_task_halloween2022_questlocation_title: 'Охотники за привидениями',
    bpass_task_halloween2022_defeatanyboss_title: 'Приспешники тьмы',
    bpass_task_halloween2022_defeatbosslocation_title: 'Король тьмы',
    bpass_task_halloween2022_craftmoonstones_title: 'Полнолуние',
    bpass_task_halloween2022_earngolditem_title: 'Лучший шоколад',
    bpass_task_halloween2022_buypack_title: 'Меганабор!',
    bpass_task_halloween2022_vip_title: 'Королевские преимущества',
    bpass_event_lny2023_name: 'Год Кролика',
    bpass_offer_lny2023_title: 'Год Кролика',
    bpass_offer_lny2023_desc:
      'Полный доступ даст еще больше наград <color=#FFDC00>китайского Нового года</color>!',
    bpass_offer_lny2023_timer: 'До конца: {0}',
    bpass_offer_lny2023_view_tasks: 'Посмотреть все задания',
    bpass_lny2023_currency: 'Мандарины',
    bpass_lny2023_pushnotestarted_title:
      '❗ Мероприятие «Китайский Новый год»!',
    bpass_lny2023_pushnotestarted_desc:
      'Появился пропуск на контент «Китайский Новый год»! Откройте эксклюзивные награды!',
    bpass_lny2023_pushnoteending_title:
      '⏰ Мероприятие «Китайский Новый год» скоро закончится!',
    bpass_lny2023_pushnoteending_desc:
      'Для пропуска на контент «Китайский Новый год» осталось всего несколько дней!',
    bpass_task_lny2023_login_title: 'Праздники!',
    bpass_task_lny2023_craftmeals_title: 'Пряник',
    bpass_task_lny2023_craftspears_title: 'Кнут',
    bpass_task_lny2023_sellitem_title: 'Торговец талисманами',
    bpass_task_lny2023_surcharge_title: 'Золото и нефрит',
    bpass_task_lny2023_smalltalk_title: 'Торговец-эксперт',
    bpass_task_lny2023_kingsell_title: 'Король красных конвертов',
    bpass_task_lny2023_sellanyelement_title: 'Стихийное снабжение',
    bpass_task_lny2023_sellanyspirit_title: 'Духи предков',
    bpass_task_lny2023_legendarycraft_title: 'Шедевр',
    bpass_task_lny2023_questany_title: 'Полевые исследования',
    bpass_task_lny2023_gathercomponent_title: 'Потерянный десерт из золота',
    bpass_task_lny2023_argonboss_title: 'Местный герой',
    bpass_task_lny2023_spendfabric_title: 'Шелковый путь',
    bpass_task_lny2023_fulfillanychampionrequest_title: 'Особые гости',
    bpass_task_lny2023_bountytrophies_title: 'Сила в единстве',
    bpass_task_lny2023_fusion_title: 'Древняя магия',
    bpass_task_lny2023_openanychest_title: 'Чудесные подарки',
    bpass_task_lny2023_marketsell_title: 'Торговец городской ярмарки',
    bpass_task_lny2023_guildrequest_title: 'Друзья в беде',
    bpass_task_lny2023_craftbunbun_title: 'Любимый Крокро',
    bpass_task_lny2023_craftdessert_title: 'Вкусный пир',
    bpass_task_lny2023_buypack_title: 'Меганабор!',
    bpass_task_lny2023_vip_title: 'Королевские преимущества',
    bpass_event_space2023_name:
      'Пропуск на контент «Космический путешественник»',
    bpass_offer_space2023_title:
      'Пропуск на контент «Космический путешественник»',
    bpass_offer_space2023_desc:
      'С полным доступом вы получите еще больше наград <color=#FFDC00>«Космический путешественник»</color>!',
    bpass_offer_space2023_timer: 'До конца: {0}',
    bpass_offer_space2023_view_tasks: 'Посмотреть все задания',
    bpass_space2023_currency: 'Звезды',
    bpass_space2023_pushnotestarted_title:
      '⏰ Событие «Космический путешественник»!',
    bpass_space2023_pushnotestarted_desc:
      'Появился пропуск на контент «Космический путешественник»! Откройте эксклюзивные награды!',
    bpass_space2023_pushnoteending_title:
      '⏰ Событие «Космический путешественник» скоро закончится!',
    bpass_space2023_pushnoteending_desc:
      'Пропуск на контент «Космический путешественник» доступен еще несколько дней!',
    bpass_task_space2023_login_title: 'Привет, мир!',
    bpass_task_space2023_craftanyenchanted_title: 'Предметы не от мира сего',
    bpass_task_space2023_spendsteel_title: 'Анобтаний',
    bpass_task_space2023_sellany_title: 'Мегакорпорация',
    bpass_task_space2023_surcharge_title: 'Включение гипердвигателя',
    bpass_task_space2023_smalltalk_title: 'Универсальный переводчик',
    bpass_task_space2023_kingsell_title: 'Имперский марш',
    bpass_task_space2023_enchantlight_title: 'Звездная ковка',
    bpass_task_space2023_enchantdark_title: 'Пустотная ковка',
    bpass_task_space2023_questany_title: 'Космическая одиссея',
    bpass_task_space2023_gathercomponent_title: 'Образцы золота',
    bpass_task_space2023_defeatbosslilu_title: 'Составление звездной карты',
    bpass_task_space2023_fulfillanychampionrequest_title: 'Встреча со звездой',
    bpass_task_space2023_bountytrophies_title: 'Охотник за наживой',
    bpass_task_space2023_fusion_title: 'Холодный синтез',
    bpass_task_space2023_openanychest_title: 'Потерянный груз',
    bpass_task_space2023_marketsell_title: 'Торговая федерация',
    bpass_task_space2023_guildrequest_title: 'Галактическое сотрудничество',
    bpass_task_space2023_defeatbosslocation_title: 'Потусторонний ужас',
    bpass_task_space2023_craftsword_title: 'Изящное оружие',
    bpass_task_space2023_craftgun_title: 'Цивилизованный век',
    bpass_task_space2023_craftstarcloak_title: 'Звездное одеяние',
    bpass_task_space2023_buypack_title: 'Меганабор!',
    bpass_task_space2023_vip_title: 'Король галактики',
    bpass_event_bjorn2023_name: 'Пропуск на контент Бьорна',
    bpass_offer_bjorn2023_title: 'Пропуск на контент Бьорна',
    bpass_offer_bjorn2023_desc:
      'С полным доступом вы получите еще больше наград <color=#FFDC00>Бьорна</color>!',
    bpass_offer_bjorn2023_timer: 'До конца: {0}',
    bpass_offer_bjorn2023_view_tasks: 'Посмотреть все задания',
    bpass_bjorn2023_currency: 'Boar Coins',
    bpass_bjorn2023_pushnotestarted_title: '❗ Событие защитников!',
    bpass_bjorn2023_pushnotestarted_desc:
      'Появился пропуск на контент Бьорна! Откройте эксклюзивные награды!',
    bpass_bjorn2023_pushnoteending_title:
      '⏰ Событие защитников скоро закончится!',
    bpass_bjorn2023_pushnoteending_desc:
      'Пропуск на контент Бьорна доступен еще несколько дней!',
    bpass_task_bjorn2023_login_title: 'Восход солнца',
    bpass_task_bjorn2023_piggybank_title: 'Копилка Бамхама',
    bpass_task_bjorn2023_spendany_title: 'Без отходов',
    bpass_task_bjorn2023_spendwood_title: 'Припасы Горзы',
    bpass_task_bjorn2023_smalltalk_title: 'Приветливый клиент',
    bpass_task_bjorn2023_discount_title: 'Приятели-охотники',
    bpass_task_bjorn2023_surcharge_title: 'Гламурный кемпинг',
    bpass_task_bjorn2023_enchantspirit_title: 'Дух природы',
    bpass_task_bjorn2023_enchantwater_title: 'Профилактика лесных пожаров',
    bpass_task_bjorn2023_craftmeal_title: 'Подготовка пайков',
    bpass_task_bjorn2023_sellitem_title: 'Опытный продавец луков',
    bpass_task_bjorn2023_fulfillrudorequest_title: 'Могучий зять',
    bpass_task_bjorn2023_bountytrophies_title: 'Орел-разведчик',
    bpass_task_bjorn2023_gatherany_title: 'Поиск еды',
    bpass_task_bjorn2023_questany_title: 'Великий исследователь',
    bpass_task_bjorn2023_marketsell_title: 'Дикий бизнес',
    bpass_task_bjorn2023_defeatanyboss_title: 'Выживает сильнейший',
    bpass_task_bjorn2023_selldoubleenchanted_title: 'Небо и земля',
    bpass_task_bjorn2023_openanychest_title: 'Дар природы',
    bpass_task_bjorn2023_openanygemchest_title: 'Лучший дар природы',
    bpass_task_bjorn2023_craftfamiliar_title: 'Приятели Бамхама',
    bpass_task_bjorn2023_buypack_title: 'Меганабор!',
    bpass_task_bjorn2023_vip_title: 'Королевские преимущества',
    building_engineer: 'Лаборатория',
    unlock_engineer_worker_question: 'Инженер Роксана',
    worker_engineer_desc:
      'Только Роксана хранит тайну мощных арбалетов и порохового оружия. Ей нравится все взрывать.',
    building_wizard: 'Башня чародея',
    worker_wizard_desc:
      'Бесконечная мудрость Гримара позволяет создавать мощные магические свитки. Правда, он постоянно что-то забывает.',
    building_jeweler: 'Ювелирная мастерская',
    unlock_jeweler_worker_question: 'Ювелир Катарина',
    worker_jeweler_desc:
      'Своими опытными руками Катарина умеет создавать кольца и богато украшенное снаряжение, как никто другой. Правда, она немного скряга.',
    building_tailor: 'Швейная мастерская',
    unlock_tailor_worker_question: 'Швея Джулия',
    worker_tailor_desc:
      'Джулия — специалист по легким доспехам, способный создавать всевозможное снаряжение для проворных героев.',
    building_carpenter: 'Плотницкая мастерская',
    unlock_carpenter_worker_question: 'Плотник Аллан',
    worker_carpenter_desc:
      'Аллан изготавливает различные деревянные предметы, такие как посохи и луки. Он лучший, как он сам утверждает.',
    building_blacksmith: 'Кузница',
    worker_blacksmith_desc:
      'Уоллес специализируется на ковке из металла и может выдать вам все что угодно, от мечей до доспехов. А еще он выдает шутки, но не слишком удачные.',
    building_herbalist: 'Аптека',
    unlock_herbalist_worker_question: 'Травница Марибель',
    worker_herbalist_desc:
      'Марибель разбирается в медицине и умеет готовить магические травы и зелья. У нее есть степень друида — она то, что надо.',
    building_master: 'Дом мастера',
    unlock_master_worker_question: 'Мастер Теодор',
    worker_master_desc:
      'Обширнейшие знания Теодора позволяют ему создавать редчайшее снаряжение великой силы! Обычно он мирно наслаждается тем, что ушел на покой.',
    building_priest: 'Храм',
    unlock_priest_worker_question: 'Жрица Фрейя',
    worker_priest_desc:
      'Священные благословения Фрейи очень важны для многих магических предметов, таких как амулеты. Она лично заведует храмом и сиротским приютом.',
    building_academy: 'Академия',
    worker_academy_desc:
      'Эвелин преподает в академии много различных курсов. Благодаря своим исследованиям она научилась изготавливать волшебные палочки и рунные камни.',
    building_dragon: 'Дракон',
    building_sundragon: 'Башня Солнца',
    worker_sundragon_desc:
      'Для дракона у Солдры довольно мягкий характер. Благодаря мудрости и спокойной манере поведения он очень поднаторел в искусстве превращения.',
    building_moondragon: 'Башня Луны',
    worker_moondragon_desc:
      'Лундра является более пылкой, чем ее брат. Связь с луной позволяет ей использовать скрытую силу лунных камней.',
    building_elven: 'Палатка призывателя',
    worker_elven_desc:
      'Иоланда — мать всех фамильяров. Только те, кого она считает достойными доверия, удостаиваются чести ухаживать за ее магическими яйцами.',
    building_inn: 'Постоялый двор «Изумруд»',
    building_inn_collective_desc:
      'Вложения в постоялый двор «Изумруд» ускорят восстановление героев.',
    building_tavern: 'Таверна',
    building_tavern_collective_desc:
      'Вложения в таверну увеличат количество добычи во время приключений.',
    building_champions: 'Тренировочный зал',
    building_champions_desc_format: '{0}',
    building_champions_pdesc_format: 'Следующий класс',
    building_champions_collective_desc:
      'Вложения в тренировочный зал позволят получить героев высокого уровня.',
    building_townhall: 'Ратуша',
    building_townhall_desc_format: 'Население города: {0}',
    building_townhall_desc:
      'Вложения в ратушу позволят вступить в город большему количеству игроков.',
    building_townhall_desc_2:
      'Вложения в ратушу позволят открыть новые уровни известности гильдии.',
    building_townhall_collective_desc:
      'Вложения в ратушу позволят вступить в город большему количеству игроков.',
    building_fabric: 'Ткацкая фабрика',
    fabric_production: 'Производство ткани',
    building_gems: 'Ювелирный склад',
    gems_production: 'Производство алмазов',
    building_herbs: 'Сад',
    herbs_production: 'Производство трав',
    resource_herbs_worker: 'Садовница',
    building_iron: 'Железный рудник',
    iron_production: 'Производство железа',
    resource_iron_worker: 'Рудокоп',
    building_wood: 'Лесной двор',
    wood_production: 'Производство древесины',
    resource_wood_worker: 'Дровосек',
    building_ironwood: 'Лесопилка',
    ironwood_production: 'Производство железного дерева',
    building_leather: 'Дубильня',
    leather_production: 'Производство кожи',
    resource_leather_worker: 'Дубильщик',
    building_mana: 'Эфирный колодец',
    mana_production: 'Производство эфира',
    building_oils: 'Маслобойный пресс',
    oils_production: 'Производство масла',
    building_steel: 'Плавильня',
    steel_production: 'Производство стали',
    building_cook: 'Ресторан',
    worker_cook_desc:
      'Если вы не станете совать грязные пальцы на кухне куда попало и будете уминать за обе щеки, что вам подают, Туту Мано безмерно вас полюбит! Но остерегайтесь: под скромной внешностью скрывается железный повар...',
    unlock_cook_worker_question: 'Повар Туту Мано',
    building_baker: 'Пекарня',
    worker_baker_desc:
      'Каждое утро Каипо встает ни свет ни заря, чтобы испечь вкусную сдобу и десерты. В пекарне всегда стоит чудесный запах, а секретным ингредиентом является любовь!',
    building_essence: 'Мавзолей',
    worker_essence_desc:
      'Сильный дух, окружающий Мавзолей, собирает сущность из-за покрова. Поскольку Золея проводит большую часть времени в безделье, ее реальный вклад неизвестен.',
    building_worker_desc_format: 'Сокращение времени изготовления: {0}%',
    building_invest_bonus: 'Вложитесь для получения',
    building_market_collective_desc:
      'Вложения в ратушу позволят вступить в город большему количеству игроков.',
    building_locked: 'Здание строится',
    building_unlock_requirements: 'Требования к открытию',
    building_user_level_req: 'Требуется {0}-й уровень торговца',
    building_parent_level_req: 'Требуется {0} {1}-го уровня',
    building_already_unlocked: 'Уже куплено',
    building_purchase_required: 'Требуется покупка',
    no_investment_yet: 'Никто пока не делал вложений.',
    add_worker: 'Нанять мастера',
    crafting_speed: 'Скорость изготовления',
    investors: 'Вкладчики',
    health_per_hour: 'Время отдыха',
    city_population_limit: 'Лимит населения города',
    special_building: 'Особое',
    worker_building: 'Мастера',
    resource_building: 'Ресурс',
    resource_building_desc:
      'Вложения в эти строения ускоряют производство ресурсов.',
    worker_desc:
      'Вложения в строения мастеров ускоряют изготовление ими предметов.',
    hero_unlocked: 'Герой нанят',
    hero_available: 'Доступно',
    hero_locked: 'Требуется {0}-й ур.',
    hero_invest_in_building: 'Вкладывайте средства для открытия',
    max_renown_level: 'Максимальный уровень известности гильдии',
    king_name: 'Рейнгольд',
    elderowen_name: 'Старейшина Оуэн',
    townhall_name: 'Старейшина Оуэн',
    argon_name: 'Аргон',
    champions_name: 'Аргон',
    sia_name: 'Сиа',
    yami_name: 'Ями',
    lilu_name: 'Лилу',
    rudo_name: 'Рудо',
    polonia_name: 'Полония',
    donovan_name: 'Донован',
    hemma_name: 'Гемма',
    ashley_name: 'Эшли',
    atlab_aang_name: 'Аанг',
    atlab_sokka_name: 'Сокка',
    blacksmith_name: 'Уоллес',
    carpenter_name: 'Аллен',
    iron_name: 'Дархан',
    wood_name: 'Горза',
    tailor_name: 'Джулия',
    leather_name: 'Тамас',
    herbs_name: 'Джунипер',
    fabric_name: 'Исмаил',
    steel_name: 'Бром',
    ironwood_name: 'Джог',
    oils_name: 'Беатрис',
    herbalist_name: 'Марибель',
    jeweler_name: 'Катарина',
    wizard_name: 'Гримар',
    priest_name: 'Фрейя',
    master_name: 'Теодор',
    trade_name: 'Лоретта',
    engineer_name: 'Роксана',
    tavern_name: 'Найя',
    casino_name: 'Майя',
    inn_name: 'Оскар',
    academy_name: 'Эвелин',
    mana_name: 'Серена',
    gems_name: 'Мэйли',
    essence_name: 'Золея',
    sundragon_name: 'Солдра',
    moondragon_name: 'Лундра',
    elven_name: 'Иоланда',
    elder_name: 'Старейшина Оуэн',
    monster_forest_boss_name: 'Странный гиблин',
    forest_mob_name: 'Странный гиблин',
    cook_name: 'Туту Мано',
    baker_name: 'Каипо',
    adventurer_name_male001: 'Лобар',
    adventurer_name_male002: 'Рой',
    adventurer_name_male003: 'Лиам',
    adventurer_name_male004: 'Фредерик',
    adventurer_name_male005: 'Уэсли',
    adventurer_name_male006: 'Кенора',
    adventurer_name_male007: 'Денам',
    adventurer_name_male008: 'Барбас',
    adventurer_name_male009: 'Оберит',
    adventurer_name_male010: 'Сайрус',
    adventurer_name_male011: 'Оберик',
    adventurer_name_male012: 'Эрик',
    adventurer_name_male013: 'Логарсон',
    adventurer_name_male014: 'Олаф',
    adventurer_name_male015: 'Рубен',
    adventurer_name_male016: 'Рэнделл',
    adventurer_name_male017: 'Эдриан',
    adventurer_name_male018: 'Регас',
    adventurer_name_male019: 'Нолан',
    adventurer_name_male020: 'Малик',
    adventurer_name_male021: 'Арман',
    adventurer_name_male022: 'Бродерик',
    adventurer_name_male023: 'Оскар',
    adventurer_name_male024: 'Беовульф',
    adventurer_name_male025: 'Эрон',
    adventurer_name_male026: 'Амран',
    adventurer_name_male027: 'Эйден',
    adventurer_name_male028: 'Борик',
    adventurer_name_male029: 'Богрин',
    adventurer_name_male030: 'Ярик',
    adventurer_name_male031: 'Дисмас',
    adventurer_name_male032: 'Рейнольд',
    adventurer_name_male033: 'Айнгар',
    adventurer_name_male034: 'Финиан',
    adventurer_name_male035: 'Фенуэлл',
    adventurer_name_male036: 'Гарет',
    adventurer_name_male037: 'Джошуа',
    adventurer_name_male038: 'Яген',
    adventurer_name_male039: 'Хок',
    adventurer_name_male040: 'Гюнтер',
    adventurer_name_male041: 'Ганс',
    adventurer_name_male042: 'Джулиан',
    adventurer_name_male043: 'Леонар',
    adventurer_name_male044: 'Ллойд',
    adventurer_name_male045: 'Хоган',
    adventurer_name_male046: 'Гаргар',
    adventurer_name_male047: 'Олуэн',
    adventurer_name_male048: 'Робин',
    adventurer_name_male049: 'Родерик',
    adventurer_name_male050: 'Самсон',
    adventurer_name_male051: 'Уоллес',
    adventurer_name_male052: 'Тарлах',
    adventurer_name_male053: 'Сириус',
    adventurer_name_male054: 'Каин',
    adventurer_name_male055: 'Персиваль',
    adventurer_name_male056: 'Натаниэль',
    adventurer_name_male057: 'Вирджил',
    adventurer_name_male058: 'Аарон',
    adventurer_name_male059: 'Кеннет',
    adventurer_name_male060: 'Антуан',
    adventurer_name_male061: 'Уильямс',
    adventurer_name_male062: 'Олдуин',
    adventurer_name_male063: 'Олбин',
    adventurer_name_male064: 'Бодкин',
    adventurer_name_male065: 'Барч',
    adventurer_name_male066: 'Бертрам',
    adventurer_name_male067: 'Мармадюк',
    adventurer_name_male068: 'Манфред',
    adventurer_name_male069: 'Манар',
    adventurer_name_male070: 'Менас',
    adventurer_name_male071: 'Джулиано',
    adventurer_name_male072: 'Джорен',
    adventurer_name_male073: 'Йоппо',
    adventurer_name_male074: 'Хуан',
    adventurer_name_male075: 'Горан',
    adventurer_name_male076: 'Гиральт',
    adventurer_name_male077: 'Лориган',
    adventurer_name_male078: 'Лейф',
    adventurer_name_male079: 'Рейнелл',
    adventurer_name_male080: 'Ирвин',
    adventurer_name_male081: 'Дубан',
    adventurer_name_male082: 'Гэлвен',
    adventurer_name_male083: 'Роак',
    adventurer_name_male084: 'Сабин',
    adventurer_name_male085: 'Грелл',
    adventurer_name_male086: 'Джиллиам',
    adventurer_name_male087: 'Гигакс',
    adventurer_name_male088: 'Лазарин',
    adventurer_name_male089: 'Киро',
    adventurer_name_male090: 'Барч',
    adventurer_name_male091: 'Гварц',
    adventurer_name_male092: 'Алистер',
    adventurer_name_male093: 'Логан',
    adventurer_name_male094: 'Оберон',
    adventurer_name_male095: 'Отелло',
    adventurer_name_male096: 'Харон',
    adventurer_name_male097: 'Ноам',
    adventurer_name_male098: 'Рубеус',
    adventurer_name_male099: 'Мондато',
    adventurer_name_male100: 'Ваган',
    adventurer_name_female001: 'Мисса',
    adventurer_name_female002: 'Сара',
    adventurer_name_female003: 'Энн',
    adventurer_name_female004: 'Вина',
    adventurer_name_female005: 'Виви',
    adventurer_name_female006: 'Вивиан',
    adventurer_name_female007: 'Элиз',
    adventurer_name_female008: 'Эвелин',
    adventurer_name_female009: 'Иштар',
    adventurer_name_female010: 'Айседора',
    adventurer_name_female011: 'Катарина',
    adventurer_name_female012: 'Титания',
    adventurer_name_female013: 'Леанна',
    adventurer_name_female014: 'Миверна',
    adventurer_name_female015: 'Маргарет',
    adventurer_name_female016: 'Морган',
    adventurer_name_female017: 'Наташа',
    adventurer_name_female018: 'Наталия',
    adventurer_name_female019: 'Оливия',
    adventurer_name_female020: 'Офелия',
    adventurer_name_female021: 'Присцилла',
    adventurer_name_female022: 'Селена',
    adventurer_name_female023: 'Найяна',
    adventurer_name_female024: 'Амальда',
    adventurer_name_female025: 'Дороти',
    adventurer_name_female026: 'Макаи',
    adventurer_name_female027: 'Шейла',
    adventurer_name_female028: 'Соня',
    adventurer_name_female029: 'Сония',
    adventurer_name_female030: 'Морриган',
    adventurer_name_female031: 'Фелиция',
    adventurer_name_female032: 'Арисель',
    adventurer_name_female033: 'Денеб',
    adventurer_name_female034: 'Ластра',
    adventurer_name_female035: 'Ломели',
    adventurer_name_female036: 'Адана',
    adventurer_name_female037: 'Адреани',
    adventurer_name_female038: 'Эшна',
    adventurer_name_female039: 'Амельда',
    adventurer_name_female040: 'Мальда',
    adventurer_name_female041: 'Аврелин',
    adventurer_name_female042: 'Черил',
    adventurer_name_female043: 'Ольга',
    adventurer_name_female044: 'Эрина',
    adventurer_name_female045: 'Франческа',
    adventurer_name_female046: 'Айла',
    adventurer_name_female047: 'Джилл',
    adventurer_name_female048: 'Кора',
    adventurer_name_female049: 'Дамия',
    adventurer_name_female050: 'Аделейн',
    adventurer_name_female051: 'Фейла',
    adventurer_name_female052: 'Ималия',
    adventurer_name_female053: 'Кимбра',
    adventurer_name_female054: 'Листа',
    adventurer_name_female055: 'Лэрис',
    adventurer_name_female056: 'Мариса',
    adventurer_name_female057: 'Меррил',
    adventurer_name_female058: 'Тила',
    adventurer_name_female059: 'Вестия',
    adventurer_name_female060: 'Изабель',
    adventurer_name_female061: 'Эйми',
    adventurer_name_female062: 'Афина',
    adventurer_name_female063: 'Амелия',
    adventurer_name_female064: 'Кэлил',
    adventurer_name_female065: 'Катя',
    adventurer_name_female066: 'Дейзи',
    adventurer_name_female067: 'Ирен',
    adventurer_name_female068: 'Лорен',
    adventurer_name_female069: 'Барбара',
    adventurer_name_female070: 'Тамара',
    adventurer_name_female071: 'Эстер',
    adventurer_name_female072: 'Агата',
    adventurer_name_female073: 'Бейли',
    adventurer_name_female074: 'Элеонор',
    adventurer_name_female075: 'Элис',
    adventurer_name_female076: 'Сибилла',
    adventurer_name_female077: 'Глориана',
    adventurer_name_female078: 'Уинифред',
    adventurer_name_female079: 'Жасмин',
    adventurer_name_female080: 'Гвендолин',
    adventurer_name_female081: 'Рианна',
    adventurer_name_female082: 'Маригольд',
    adventurer_name_female083: 'Бесс',
    adventurer_name_female084: 'Хильдегард',
    adventurer_name_female085: 'Эбигейл',
    adventurer_name_female086: 'Элизабет',
    adventurer_name_female087: 'Джуэтта',
    adventurer_name_female088: 'Бриджит',
    adventurer_name_female089: 'Паола',
    adventurer_name_female090: 'Ирис',
    adventurer_name_female091: 'Эсми',
    adventurer_name_female092: 'Сурен',
    adventurer_name_female093: 'Арабелла',
    adventurer_name_female094: 'Тейган',
    adventurer_name_female095: 'Джунипер',
    adventurer_name_female096: 'Кира',
    adventurer_name_female097: 'Дафна',
    adventurer_name_female098: 'Эрин',
    adventurer_name_female099: 'Сабрина',
    adventurer_name_female100: 'Нейла',
    Castle_Boss_name: 'Король-Тиран',
    essence_production: 'Производство сущности',
    story_argon_01_name: 'Тень короля',
    story_argon_01_01: '...И тогда я поразил гиблина своим мечом! Раз — и все!',
    story_argon_01_02: 'Хо-хо! Вот так история, Аргон!',
    story_argon_01_03: 'Ах, пыл молодости...',
    story_argon_01_04:
      'Признаюсь, очень мило с твоей стороны сопровождать меня, старика, на утренней прогулке.',
    story_argon_01_05:
      'Не стоит благодарности! Раз уж я хочу стать рыцарем, то должен помогать нуждающимся!',
    story_argon_01_06:
      'Как твой отец, да? Уверен, мальчик мой, из тебя выйдет прекрасный рыцарь.',
    story_argon_01_07: 'Возможно, даже попадешь в стражу самого короля!',
    story_argon_01_08:
      'А вот и он по рынку бродит, легок на помине! Вот возьми и спроси его!',
    story_argon_01_09:
      'ВАШЕ ВЕЛИЧЕСТВО! Я хочу предложить вам свои услуги в качестве рыцаря!',
    story_argon_01_10:
      'Э-это еще что такое?! Сиа, разберись с этим простолюдином!',
    story_argon_01_11: 'Конечно, мой король.',
    story_argon_01_12: 'Ну что, молодой человек? В чем дело?',
    story_argon_01_13: 'Ух ты! Я, э-э...',
    story_argon_01_14: 'Я рыцарь! Хочу стать Аргоном!',
    story_argon_01_15: 'Ой, погодите...',
    story_argon_01_16: 'А ты смелый парень!',
    story_argon_01_17:
      'Если ты так полон решимости, приходи ко мне на следующей неделе во двор замка.',
    story_argon_01_18:
      'А до этого усердно тренируйся! Я не потерплю лоботряса среди рыцарей короля...',
    story_argon_02_name: 'Череда тренировок',
    story_argon_02_01: 'Аргон, что ты здесь делаешь?',
    story_argon_02_02:
      'Усердно тренируюсь! У меня наконец появился шанс стать рыцарем короля!',
    story_argon_02_03: 'Короля? ...Или Сиа, его таинственной советницы?',
    story_argon_02_04: 'Ее так зовут? И имя тоже красивое!',
    story_argon_02_05: '...',
    story_argon_02_06: 'Ох! Нет! Имеет значение только король!',
    story_argon_02_07: 'Я должен стать рыцарем! Остальное неважно!',
    story_argon_02_08: 'Точно... Дай-ка я исцелю тебе раны.',
    story_argon_02_09: 'Спасибо. Ой! Жжется как-то твое заклинание!',
    story_argon_03_name: 'Две стороны монеты',
    story_argon_03_01: 'Так-так! Сиа может появиться в любой момент!',
    story_argon_03_02: 'Мое обучение окупилось, я готов!',
    story_argon_03_03: 'Но по-прежнему очень нервничаю...',
    story_argon_03_04: 'Ой! Привет! Да, ты, в кустах!',
    story_argon_03_05: '...!',
    story_argon_03_06: 'Тоже хочешь стать рыцарем?',
    story_argon_03_07:
      'Сиа скоро будет здесь! Не нужно прятаться, она очень милая!',
    story_argon_03_08: 'Сиа!..',
    story_argon_03_09: 'Мне нужно идти. Прощай.',
    story_argon_03_10: 'П-постой!',
    story_argon_03_11: 'Ого! Дымовая шашка?!',
    story_argon_03_12: 'Какая странная девушка...',
    story_argon_03_13: 'Молодой человек, вы с кем разговариваете?',
    story_argon_03_14:
      'А, Сиа! Да так, мимо кто-то проходил! Не обращайте внимания!',
    story_argon_03_15: '...Ясно. Что же, похоже, твоя решимость не дрогнула.',
    story_argon_03_16: 'Готов ли ты рисковать жизнью в качестве рыцаря короля?',
    story_argon_03_17:
      'А как же! Т-то есть конечно! Отец будет гордиться мной!',
    story_argon_03_18:
      'Хорошо. И вот тебе первое задание. Грядет одно важное событие...',
    story_argon_04_name: 'С пустыми руками',
    story_argon_04_01: '...',
    story_argon_04_02: 'Ты шутишь, что ли?',
    story_argon_04_03: 'Ты загнал убийцу в угол... но не смог ее остановить.',
    story_argon_04_04:
      'Более того, ты говоришь, что ОТПУСТИЛ ЕЕ, решив, что она хороший человек?!',
    story_argon_04_05: '...',
    story_argon_04_06: 'Что за вздор?',
    story_argon_04_07:
      'Ты ведь понимаешь, что она пыталась убить нашего короля — человека, которого ты поклялся защищать?',
    story_argon_04_08:
      'Такой инцидент ставит под угрозу гораздо большее, чем твой слабый ум способен понять.',
    story_argon_04_09:
      'Ух... Полагаю, последнее слово об увольнении рыцаря должен сказать король...',
    story_argon_04_10:
      '...Но не жди, что я буду высоко о тебе отзываться. Свободен.',
    story_argon_04_11: '...',
    story_argon_04_12: 'Ой-ой...',
    story_argon_04_13: 'Прости, папа.',
    story_argon_05_name: 'Странствующий рыцарь',
    story_argon_05_01: 'Ох...',
    story_argon_05_02: 'Да это же Аргон! Как дела?',
    story_argon_05_03: 'О, привет, Бром... Для меня работа есть?',
    story_argon_05_04: 'Сегодня ничего, господин рыцарь! Чего голову повесил?',
    story_argon_05_05: 'Я теперь просто Аргон. Я... потерял свое звание.',
    story_argon_05_06: 'Ой, какая неприятность...',
    story_argon_05_07:
      '«Что бы ни случилось, нужно продолжать мыслить позитивно! Ты справишься!»',
    story_argon_05_08: 'Я уверен, что удача к тебе совсем скоро вернется!',
    story_argon_05_09: 'Верно... Спасибо, Бром.',
    story_argon_05_10: 'Я... пока что поброжу по городской площади.',
    story_argon_05_11: 'Ох...',
    story_argon_06_name: 'Второй шанс',
    story_argon_06_01:
      'Значит, ваша убийца была в руках у этого... «рыцаря»...',
    story_argon_06_02:
      '...Но он решил ее отпустить, считая, что она хороший человек!',
    story_argon_06_03:
      'Замечательная история! Этот новый рыцарь, похоже, многообещающий парень!',
    story_argon_06_04: 'При всем уважении, мой король...',
    story_argon_06_05:
      'Этот человек не стал останавливать вашу убийцу, руководствуясь лишь интуицией.',
    story_argon_06_06: 'Отличительная черта <c1>паладина</c1>, разве нет?',
    story_argon_06_07: 'Простите?',
    story_argon_06_08:
      'Держать заклятого врага в руках, но видеть происходящее не только как раздор и конфликт...',
    story_argon_06_09:
      '...А потом отпустить его, поскольку настолько уверен в его искуплении, что готов рискнуть собственным званием.',
    story_argon_06_10:
      'Потрясающий поступок, исполненный храбрости и великодушия!',
    story_argon_06_11:
      'Признаться... в таком свете я это не рассматривала, мой король.',
    story_argon_06_12:
      'Но в королевстве уже десятки лет не было никого, кто мечтал бы стать паладином...',
    story_argon_06_13:
      'Мне созвать совет рыцарей для обсуждения этого вопроса?',
    story_argon_06_14: 'Чепуха! Я сам улажу все формальности.',
    story_argon_06_15:
      'Как пожелаете, мой король. Я немедленно приготовлю эскорт.',
    story_argon_06_16: 'В этом нет нужды. Я буду осторожен, как мышь!',
    story_argon_07_name: 'Неприметный король',
    story_argon_07_01:
      'В сторону, крестьяне! Среди вас идет самый обычный работяга!',
    story_argon_07_02:
      'А, король Рейнгольд! Давненько не виделись. Открыть бочку амброзии?',
    story_argon_07_03:
      'Тс-с-с! Я сегодня под прикрытием! Не обращай на меня внимания!',
    story_argon_07_04: 'Да, конечно! Как глупо с моей стороны!',
    story_argon_07_05: 'Эй, ты! Который прячется за прилавком!',
    story_argon_07_06: 'А? Э-э, простите, ваше величество. Я уйду с дороги...',
    story_argon_07_07:
      'Чепуха! Будущий паладин более чем достоин сидеть рядом с моей сиятельной особой!',
    story_argon_07_08: 'Сидеть рядом с... Паладин?!',
    story_argon_07_09:
      'Именно! В своей бесконечной мудрости я ощутил в тебе большой потенциал!',
    story_argon_07_10:
      'Несмотря на твои... необычные методы, сердце твое истинно, а чувство справедливости... восхитительно!',
    story_argon_07_11:
      'В связи с этим королевство считает, что ты годишься для прохождения испытания паладина!',
    story_argon_07_12: 'Испытания паладина... Ух ты!',
    story_argon_07_13:
      'Я все про них знаю! Каждое из них уникально, а его исход зависит от самого паладина...',
    story_argon_07_14:
      'Это должна быть опасная задача, например убить дракона... или уничтожить опасный артефакт...',
    story_argon_07_15:
      'Верно! Подойдет любое достаточно смелое и бескорыстное деяние, совершенное во благо королевства!',
    story_argon_07_16: 'Понимаю! Спасибо, ваше величество! Вы не пожалеете!',
    story_argon_07_17: 'Нет, конечно, я же всегда прав!',
    story_argon_07_18: '...А теперь, раз я уже под прикрытием...',
    story_argon_07_19: 'Эй, ты! Старик! Научи меня играть в карты!',
    story_argon_07_20:
      'В карты? Старик Теодор играет только с высокими ставками...',
    story_argon_08_name: 'Пламя снова разгорелось',
    story_argon_08_01: 'Аргон! Что за спешка?',
    story_argon_08_02: 'Лилу, я буду паладином!',
    story_argon_08_03: 'Паладином! П-поразительно, Аргон! Но как?..',
    story_argon_08_04:
      'Король велел мне пройти испытание! Это единственный способ действительно стать достойным звания паладина.',
    story_argon_08_05: 'Испытание... А ты знаешь, в чем оно состоит?',
    story_argon_08_06: '...',
    story_argon_08_07: 'Ой, нет! Понятия не имею!',
    story_argon_08_08: 'Это должно быть что-то эпичное и славное.',
    story_argon_08_09:
      'Аргон, но ты же такой славный! Повсюду побеждаешь монстров...',
    story_argon_08_10: 'Конечно, но это должно быть что-то большее...',
    story_argon_08_11:
      'Говорят, на пике Мрачный Шпиль водятся пернатые демоны. Может быть, этого хватит...',
    story_argon_08_12: 'Что бы это ни было, я уверена, ты справишься!',
    story_argon_08_13:
      'Спасибо, Лилу... Даже когда я перестал быть рыцарем, ты по-прежнему в меня верила...',
    story_argon_08_14: 'Ой! Д-да это пустяки!',
    story_argon_08_15:
      'В любом случае тебе в приключении понадобится помощь! Я приготовлю для тебя несколько исцеляющих свитков!',
    story_argon_08_16: 'Здорово! За дело!',
    story_argon_09_name: 'Столкновение на Мрачном Шпиле (Аргон)',
    story_argon_09_01: 'Ух... что за отстойное место!',
    story_argon_09_02:
      'Столько лезу вверх и ни одной гарпии не вижу... Даже попал в засаду убийц!',
    story_argon_09_03: 'Они были похожи на ту девушку-ниндзя, правда?',
    story_argon_09_04: 'Хорошо, что Лилу дала мне эти исцеляющие свитки, фух!',
    story_argon_09_05: 'Думаю, здесь я отдохну.',
    story_argon_09_06: 'А?..',
    story_argon_09_07: 'Опять ты.',
    story_argon_09_08: 'У-убийца короля!',
    story_argon_09_09:
      'Должно быть, это судьба. Награда даже получше, чем гарпия!',
    story_argon_09_10: 'Ну же, давай. Я убью тебя на месте.',
    story_argon_10a_name: 'Триумфальное возвращение',
    story_argon_10a_01: 'Король Рейнгольд! Приключение завершено!',
    story_argon_10a_02: 'Чудесно! Какого мерзкого зверя ты убил?',
    story_argon_10a_03: 'Э-э... как раз об этом.',
    story_argon_10a_04: 'Привет.',
    story_argon_10a_05: 'Э-э-это еще что такое?!',
    story_argon_10a_06: 'Стража! Арестуйте их обоих!',
    story_argon_10a_07: 'Ой! Подождите! Она начала жизнь с чистого листа!',
    story_argon_10a_08: 'Н-неужели?',
    story_argon_10a_09: 'Я пришла... извиниться.',
    story_argon_10a_10: 'Мой король, вы, конечно, не поверите...',
    story_argon_10a_11: 'Я могу поручиться за нее! Честью рыцаря!',
    story_argon_10a_12: 'Хорошо! Я выслушаю ее мольбу!',
    story_argon_10a_13: 'Уф... Этого я и боялась.',
    story_argon_10a_14:
      'Стража! Приведите подкрепление и следите за каждым ее шагом!',
    story_argon_10a_15:
      'Аргон, пожалуйста, подожди снаружи, пока мы поговорим с этой... непрошеной гостьей.',
    story_argon_10a_16: 'Сию секунду!',
    story_argon_10a_17: '(Неужели я совершил огромную ошибку?..)',
    story_argon_10b_name: 'Искупитель',
    story_argon_10b_01:
      'Ух... Сегодня мне только этой головной боли не хватало.',
    story_argon_10b_02: 'Леди Сиа! Как все прошло?',
    story_argon_10b_03:
      'Вопреки всему, король Рейнгольд готов простить свою несостоявшуюся убийцу.',
    story_argon_10b_04:
      'Я не столь доверчива, но она поделилась ценной информацией о своем азумском ордене.',
    story_argon_10b_05: 'З-значит...',
    story_argon_10b_06: 'Да, мы примем ее капитуляцию и извинения.',
    story_argon_10b_07:
      'Сейчас она под наблюдением лекарей из-за голода, но должна полностью поправиться.',
    story_argon_10b_08: 'Что касается тебя, паладин Аргон...',
    story_argon_10b_09: 'П-паладин!',
    story_argon_10b_10:
      'Да, устранить угрозу — это уже нечто, но способствовать искуплению заклятого врага королевства...',
    story_argon_10b_11: 'Похоже, король Рейнгольд был прав насчет тебя.',
    story_argon_10b_12:
      'А теперь иди и празднуй, паладин. Я уверена, что совсем скоро ты будешь сильно занят!',
    story_argon_10b_13: 'Да, я пойду! Благодарю!',
    story_argon_10b_14: 'Папа... Надеюсь, ты гордишься мной.',
    story_argon_epilogue_name: 'Эпилог',
    story_argon_epilogue_01: 'П-паладин?! У тебя получилось, Аргон!',
    story_argon_epilogue_02: 'На мгновение я подумал, что все испортил...',
    story_argon_epilogue_03:
      'Но папа был прав. Мне нужно лишь следовать за своим сердцем!',
    story_argon_epilogue_04:
      'Ах... но значит ли это, что тебя отправят в заморскую кампанию?',
    story_argon_epilogue_05:
      'Пока нет. Леди Сиа надеется, что я смогу научить героев становиться паладинами.',
    story_argon_epilogue_06:
      'Ах! Какое облегчение! Хотя мне трудно представить тебя в роли учителя...',
    story_argon_epilogue_07:
      'Разве ты не будешь занят сверхважными паладинскими заданиями?',
    story_argon_epilogue_08:
      'Я приложу все старания! Королевство рассчитывает на меня!',
    story_argon_epilogue_09: 'Привет, благородный паладин!',
    story_argon_epilogue_10:
      'Бром сказал, что ему нужны еще рукавицы плавильщика!',
    story_argon_epilogue_11: 'Да! Сейчас!',
    story_argon_epilogue_12: 'Некоторые вещи никогда не меняются, да?',
    story_argon_epilogue_13: 'Не наш Аргон, это уж точно.',
    story_argon_a_name: 'Башня титанов',
    story_argon_a_01: 'Башня титанов?',
    story_argon_a_02: 'Пожалуйста, расскажите нам о ней!',
    story_argon_a_03:
      'Я слышал, как какие-то герои шептались о ней, но никто никогда ее не видел...',
    story_argon_a_04:
      'Да, Башня титанов — это мистическое место. Никто не знает, существует ли она на самом деле.',
    story_argon_a_05:
      'Рассказывают, что она появляется из ниоткуда, и самые могучие герои проходят испытание, поднимаясь по ней этаж за этажом...',
    story_argon_a_06:
      '...пока башня внезапно не исчезает, стирая воспоминания у любого, кто мог ее видеть.',
    story_argon_a_07: 'С-стирая воспоминания?!',
    story_argon_a_08: 'Странно... А откуда тогда взялась бы легенда?',
    story_argon_a_09: 'Точно! Кто-то же должен был помнить!',
    story_argon_a_10:
      'Говорят, что тех, кто проходит испытание титана, башня считает достойными.',
    story_argon_a_11:
      'Эти герои возвращаются... изменившись после приобретенного опыта и сохранив воспоминания.',
    story_argon_a_12:
      'Точнее, какая-то их часть остается в башне, а какая-то часть башни остается с ними.',
    story_argon_a_13:
      'Хватит на сегодня историй. Спасибо, что послушали стариковские сказки.',
    story_argon_a_14: 'Ух ты! Интересно, существует ли она на самом деле?',
    story_argon_a_15: 'Ага! Я бы с удовольствием исследовал такое подземелье!',
    story_argon_a_16:
      'Но вот что странно... Откуда старейшина Оуэн обо всем этом знает?',
    story_argon_a_17: '...!',
    story_argon_b_name: 'Мы титаны?',
    story_argon_b_01: 'Доброе утро... Аргон.',
    story_argon_b_02: '(Зевает) Доброе...',
    story_argon_b_03: 'У тебя такие же ощущения, да?',
    story_argon_b_04:
      'Ага, я измотан! Как будто участвовал в приключениях всю ночь!',
    story_argon_b_05: 'Смотри, и щит у тебя весь побитый!',
    story_argon_b_06:
      'Что? Ты права! Я мог бы поклясться, что еще вчера он был начищен до блеска...',
    story_argon_b_07: 'Аргон... Как ты думаешь...',
    story_argon_b_08:
      'Эй! Кто-нибудь из вас, сухопутников, хочет купить <c1>поножи Джаггернаута</c1>?',
    story_argon_b_09: 'Нет... особенно 20 штук! Ты где их достала?!',
    story_argon_b_10: 'Ха! Забавно, что спрашиваешь. Понятия не имею.',
    story_argon_b_11:
      'Похоже, я собираю добычу во сне. Видать, пиратской душе не спится, ар-р.',
    story_argon_b_12: 'Вот и странная пиратка туда же...',
    story_argon_b_13:
      'Мы что, все решили пройти испытание башни, а потом нам воспоминания стерли?..',
    story_argon_b_14: 'Значит, мы не были достаточно сильны...',
    story_argon_b_15:
      'Буду усердно тренироваться для следующего раза! Еще посмотрим!',
    story_lilu_00_01:
      'Привет! Должно быть, это о тебе и твоей лавке постоянно говорит Аргон!',
    story_lilu_00_02: 'Я Лилу, ученица мастера Гримара. Приятно познакомиться!',
    story_lilu_00_03:
      'Обычно я занята выполнением поручений для мастера, но...',
    story_lilu_00_04:
      'Если выручишь меня, я точно смогу найти время для пары приключений!',
    story_lilu_00_hire_01:
      'Здорово! Благодаря твоей помощи у меня теперь много свободного времени!',
    story_lilu_00_hire_02: 'Как и обещала, теперь моя очередь помогать тебе!',
    story_lilu_00_hire_03:
      'Я еще новичок в магии, но хочу испробовать несколько исцеляющих заклинаний...',
    story_lilu_00_hire_04: 'Отправляй меня в путь в любое время!',
    story_lilu_01_name: 'Одаренная ученица',
    story_lilu_01_01:
      'Ну что, Поллукс? Что думаешь об этом новом заклинании? Идеальное, да?',
    story_lilu_01_02: 'Да, на этот раз мы точно справимся!',
    story_lilu_01_03:
      'Доброе утро, Лилу! Пришла за еженедельной партией живых корней?',
    story_lilu_01_04: 'Да, спасибо, Марибель!',
    story_lilu_01_05:
      'Ты на этой неделе много заказала! Что твой мастер со всем этим делает?',
    story_lilu_01_06:
      'Честно говоря, половина из этого для меня. Я решила серьезно заняться учебой!',
    story_lilu_01_07: 'Да? И что учишь?',
    story_lilu_01_08:
      'Исцеляющие заклинания! Всю прошедшую неделю лечила порезы и ушибы в тренировочном зале!',
    story_lilu_01_09: 'Уверена, герои рады, когда ты рядом.',
    story_lilu_01_10: '...И Аргон тоже!',
    story_lilu_01_11: '...!',
    story_lilu_01_12: 'М-мне нужно идти! Спасибо!',
    story_lilu_02_name: 'Мрачные вести',
    story_lilu_02_01:
      'Вот, мастер Гримар. Живые корни, присланные на этой неделе.',
    story_lilu_02_02: 'Спасибо, Лола. А вот тебе домашнее задание на сегодня.',
    story_lilu_02_03: 'О! Магический фолиант! Об искусстве исцеления?',
    story_lilu_02_04: '«Злодейский справочник злословия»?',
    story_lilu_02_05:
      'Какая сложная книга! Тут даже есть целый раздел о проклятиях!',
    story_lilu_02_06: '...Проклятиях?',
    story_lilu_02_07: 'Хм... Проклятиях?',
    story_lilu_02_08: 'Мастер?',
    story_lilu_02_09: '35 лет назад в этот самый день... я...',
    story_lilu_02_10: 'Мастер Гримар?!',
    story_lilu_02_11: '...Забыл. А ты кто, дитя мое?',
    story_lilu_02_12: '...Я Лилу, ваша ученица. Можно Лола...',
    story_lilu_02_13: 'Ладно, неважно. Буду изучать эту книгу. Спасибо, Грим.',
    story_lilu_03_name: 'Помутненный рассудок',
    story_lilu_03_01: '...Добавить щепотку серебряной пыли, и... готово!',
    story_lilu_03_02: 'Посмотри, как светится! Неплохо, да, Поллукс?',
    story_lilu_03_03:
      'Если верить «Злодейскому справочнику злословия», это зелье должно...',
    story_lilu_03_04: 'Лола, что за шум? Я вообще-то пытаюсь поспать!',
    story_lilu_03_05:
      'Мастер Гримар! С помощью вашей книги я сварила очень сложное зелье! Оно должно...',
    story_lilu_03_06: 'Что-что?!',
    story_lilu_03_07: 'Тогда давай его сюда! Попробуем!',
    story_lilu_03_08: '...',
    story_lilu_03_09: 'Мастер Гримар?',
    story_lilu_03_10:
      'Лилу! Мне ну-ну-нужно ос-освободиться! Вы-выпусти меня из р-разума этого остолопа!',
    story_lilu_03_11: 'М-мастер Гримар! Вы в порядке?',
    story_lilu_03_12: '...',
    story_lilu_03_13: 'Буэ! Какой-то фруктовый вкус!',
    story_lilu_03_14:
      'Неплохо, Лола. А теперь возвращайся к своим повседневным делам.',
    story_lilu_03_15: '...Это было НЕнормально.',
    story_lilu_04_name: 'Конец королевского рода',
    story_lilu_04_01: 'С мастером Гримаром что-то не так...',
    story_lilu_04_02:
      'Его голос... Казалось, будто кто-то говорил внутри него!',
    story_lilu_04_03:
      'Может быть, мы имеем дело со сложным заклинанием превращения?',
    story_lilu_04_04: 'Или, может быть, даже...',
    story_lilu_04_05: 'Блин, Лилу! На королевском пиру что-то пошло не так!',
    story_lilu_04_06: 'Тамас? Что происходит?',
    story_lilu_04_07: 'Вроде посторонний в замке. Как-то жутковато!',
    story_lilu_04_08:
      'Посторонний!.. И это в первый день Аргона в качестве рыцаря!',
    story_lilu_04_09: 'Спасибо, Тамас, мне нужно идти!',
    story_lilu_04_10: 'О нет!.. Что здесь происходит?!',
    story_lilu_04_11: 'Аргон, где же ты?!',
    story_lilu_04_12: 'А-а-а...',
    story_lilu_04_13: 'КОРОЛЬ РЕЙНГОЛЬД?! Вы в порядке?!',
    story_lilu_04_14: 'А-а-а... Моя драгоценная жизнь... утекает...',
    story_lilu_04_15: 'Плохо дело!..',
    story_lilu_04_16: 'Сосредоточься, Лилу! Вспомни обучение мудреца!',
    story_lilu_04_17: 'Король Рейнгольд! Не шевелитесь! <c1>Исцеление</c1>!',
    story_lilu_04_18:
      'Бесполезно... Пожалуйста... сохраните мой красивый труп...',
    story_lilu_04_19: 'Никакого эффекта...',
    story_lilu_04_20: 'О нет... Может это был... яд?',
    story_lilu_04_21:
      'Так же было и с моими родителями!.. Но в этот раз у меня все получится!',
    story_lilu_04_22: '<c1>Мощное очищение от яда!</c1>',
    story_lilu_04_23: 'А-а-а-а... Что?',
    story_lilu_04_24: 'Король Рейнгольд! Как вы себя чувствуете?',
    story_lilu_04_25:
      'Погодите-ка... Эта чаша защитила вас от сюрикэна! Вы вообще не пострадали!',
    story_lilu_04_26:
      'Ой, правда? Видимо, я отреагировал немного эмоциональнее, чем следовало.',
    story_lilu_04_27: 'Все-таки смерть монарха — это драматическое событие.',
    story_lilu_04_28: 'Ох! Рейнгольд!',
    story_lilu_05_name: 'Разговор о ядах',
    story_lilu_05_01: 'Ух... Вот это пронесло... как бы.',
    story_lilu_05_02:
      'Если бы Рейнгольда действительно отравили... я вряд ли бы смогла ему помочь.',
    story_lilu_05_03:
      'Нужно сосредоточить свои усилия на другом. Чтобы никто не понес потери, как я...',
    story_lilu_05_04: 'Госпожа Сиа!',
    story_lilu_05_05:
      'А, наша звезда! Разбираться с непрошеным гостем отправили рыцаря. Можешь спать спокойно.',
    story_lilu_05_06: '(Рыцаря? Удачи, Аргон!)',
    story_lilu_05_07:
      'Спасибо за... спасение нашего короля, несмотря на обстоятельства.',
    story_lilu_05_08:
      'Да не за что! Но случись такое снова, я не уверена, что смогла бы помочь...',
    story_lilu_05_09: 'Госпожа Сиа, вам известно что-нибудь о ядах?',
    story_lilu_05_10:
      'Конечно. Например, аконит, также как известный как волчья отрава, убивает ужасно эффективно.',
    story_lilu_05_11:
      'Но лично мне больше всего по нраву рицин. Бессимптомный, смертоносный, легкодоступный...',
    story_lilu_05_12: 'Э-э... А о средствах против них?',
    story_lilu_05_13: 'Ой. Я об этом как-то особо и не задумывалась.',
    story_lilu_05_14: '...Забудьте, что я это спрашивала.',
    story_lilu_06_name: 'Учитель и ученик',
    story_lilu_06_01: 'Я вернулась, мастер Гримар!',
    story_lilu_06_02: 'А, с возвращением, Лола.',
    story_lilu_06_03: 'Вы можете в это поверить? Короля чуть не убили!',
    story_lilu_06_04: 'Правда?..',
    story_lilu_06_05:
      'Убийца появился из ниоткуда и запустил в него сюрикэном!',
    story_lilu_06_06:
      'И тогда я поспешила на помощь, когда он умирал на полу!..',
    story_lilu_06_07: 'Э-э... Напомни, ты кто?',
    story_lilu_06_08:
      'Ах да. Ваше состояние... Нужно вернуться к этому вопросу!',
    story_lilu_06_09: 'Возможно, это тоже яд...',
    story_lilu_06_10: 'Поглядим-ка... Гримар очень стар...',
    story_lilu_06_11: 'Как думаешь, Поллукс?',
    story_lilu_06_12: 'Да, нужно порасспрашивать в городе!',
    story_lilu_06_13:
      'Возможно, кто-то из пожилых жителей помнит, как вел себя Гримар, когда был помоложе...',
    story_lilu_07_name: 'Знания предков',
    story_lilu_07_01: 'Мастер Теодор!',
    story_lilu_07_02: 'Это же Лилу! Чем я могу тебе помочь?',
    story_lilu_07_03:
      'А вы не могли бы рассказать мне о Гримаре в молодости?..',
    story_lilu_07_04: '...Раз уж вы, э-э... старый и все такое.',
    story_lilu_07_05: 'Хо-хо-хо! Конечно, конечно.',
    story_lilu_07_06: 'Старый Гримар всегда был немного... эксцентричным.',
    story_lilu_07_07:
      'Но когда я был мелким, помнится, ум у него был куда острее. Даже в шахматах мы были равны.',
    story_lilu_07_08:
      'Но вот после того путешествия в руины Кроноса... он, насколько я помню, всегда чувствовал себя немного не в своей тарелке.',
    story_lilu_07_09: 'Руины Кроноса! Это же такое опасное место!',
    story_lilu_07_10:
      '...Целая цивилизация превратилась в руины из-за исследования магии времени...',
    story_lilu_07_11:
      'Там полно магических аномалий! Что же Гримар заполучил при посещении этого места?',
    story_lilu_07_12:
      'Ты прав, Поллукс, это наша единственная зацепка. Мы должны заняться расследованием!',
    story_lilu_07_13:
      'Но нам понадобится охрана... Попросить Аргона, что ли?..',
    story_lilu_07_14:
      'Нет! Что если вихрь времени внезапно превратит его в старика? Или сделает с ним то же, что и с Гримаром...',
    story_lilu_07_15:
      '...Я могу вспомнить только одного человека, который мог бы нам помочь...',
    story_lilu_07_16: 'Будет нелегко, Поллукс! Но мы должны попытаться!',
    story_lilu_07_17: 'Спасибо, Теодор! Вы очень помогли!',
    story_lilu_07_18: 'Не за что, Лилу! До встречи!',
    story_lilu_07_19: '...',
    story_lilu_07_20: '...Вот ведь умная сова!..',
    story_lilu_08_name: 'Общее дело',
    story_lilu_08_01:
      'Господин Рудо, прошу вас! Не уходите, мне нужно с вами поговорить!',
    story_lilu_08_02: 'Девочка, уходи, я не хочу это слышать.',
    story_lilu_08_03: 'Иди гулять со своей совой куда-то еще!',
    story_lilu_08_04:
      'Я знаю, что вы не любите заклинателей... но мне нужна ваша помощь!',
    story_lilu_08_05:
      'Мне нужно отправиться вглубь руин Кроноса, а сама я пойти не могу! ',
    story_lilu_08_06:
      'Так попроси этого пацана Аргона, он же твой рыцарь в сияющих доспехах.',
    story_lilu_08_07: 'Он... э-э, в данный момент чем-то занят...',
    story_lilu_08_08: 'Значит, вас просить смысла нет...',
    story_lilu_08_09: 'Все верно, девочка.',
    story_lilu_08_10: 'Как жаль, дорогуша.',
    story_lilu_08_11:
      'Я слышала, что повелителя циклопов из руин Кроноса никогда не побеждали в поединке один на один...',
    story_lilu_08_12:
      'Для сопровождения Лилу через руины нужен исключительный воин!',
    story_lilu_08_13: 'Никогда не побеждали, говоришь?',
    story_lilu_08_14: 'Ладно, согласен. Сколько мне придется крушить?',
    story_lilu_08_15: 'Уверена, что много!',
    story_lilu_08_16:
      'Понимаете, мне нужно найти источник потока времени в различных кристаллах и изолировать...',
    story_lilu_08_17: 'Короче. Что надо крушить?',
    story_lilu_08_18: 'Монстров и магические кристаллы.',
    story_lilu_08_19: 'Хорошо, принято. За тобой должок, девочка.',
    story_lilu_08_20: 'Фантастика! Большое спасибо, господин Рудо!',
    story_lilu_08_21:
      'Поблагодаришь после нашего путешествия, девочка. Это тебе не прогулка в парке.',
    story_lilu_08_22:
      'Я залечу вам раны, если монстров, с которыми вы столкнетесь, будет слишком много!',
    story_lilu_08_23: 'Пф. Это вряд ли.',
    story_lilu_08_24: 'И тебе тоже спасибо, Найя!',
    story_lilu_08_25: 'Всегда пожалуйста, дорогуша.',
    story_lilu_09_name: 'Зеркальный мир',
    story_lilu_09_01: 'Я займу циклопов! Иди вперед!',
    story_lilu_09_02: 'Ну же, толстоклопы! Сейчас я вам задам перцу!',
    story_lilu_09_03: 'Ясно! Удачи!',
    story_lilu_09_04:
      'Вот он... Чертог времени. И согласно моим исследованиям...',
    story_lilu_09_05:
      'Этот водоем должен быть соединен с отделившейся временной линией!',
    story_lilu_09_06:
      'С параллельной вселенной, где город Кронос по-прежнему процветает...',
    story_lilu_09_07:
      'Смотри, Поллукс! Если вглядеться в воду... можно что-то увидеть!',
    story_lilu_09_08:
      'Похоже, что жители Кроноса живут обычной жизнью, где-то там...',
    story_lilu_09_09: 'Эй... Этот парень машет нам рукой?',
    story_lilu_09_10: 'Он очень похож на... Гримара?!',
    story_lilu_10a_name: 'Проклятие вечных лет',
    story_lilu_10a_01: 'Гримар!!! Выпейте!',
    story_lilu_10a_02: 'А? Что-то... знакомое.',
    story_lilu_10a_03:
      'Это специальная микстура, приготовленная на воде Кроноса...',
    story_lilu_10a_04:
      '...Стабилизированная и дважды обработанная нитями судьбы?',
    story_lilu_10a_05:
      '...и приведенная в материальную форму с помощью дистиллированной эктоплазмы.',
    story_lilu_10a_06: 'С щепоткой глубоководного коралла для вкуса!',
    story_lilu_10a_07: 'Впечатляющая работа, Лилу. Учителю есть чем гордиться.',
    story_lilu_10a_08: 'Г-гримар... Вы снова в порядке! Сработало!',
    story_lilu_10a_09:
      'Какие необычные ощущения... будто туман только что покинул мой разум...',
    story_lilu_10a_10:
      'Я примерно представляю, как работает зелье, но... есть кое-чего я не понимаю.',
    story_lilu_10a_11:
      'Мы видели вас в водоеме! В отделившейся временной линии!',
    story_lilu_10a_12: 'Хе-хе! Правда? У меня есть теория на этот счет...',
    story_lilu_10a_13:
      'Поллукс, дорогой. Ты не можешь слетать за стариком Оуэном? Полагаю, многое нужно объяснить...',
    story_lilu_10b_name: 'Теория о разделении времени',
    story_lilu_10b_01: 'Мой старый друг! Вот это история!',
    story_lilu_10b_02:
      'Мне самой трудно в это поверить... Гримар — выживший из города Кроноса?',
    story_lilu_10b_03:
      'Когда заклинание времени пошло наперекосяк, весь город был переброшен в другую временную линию.',
    story_lilu_10b_04:
      'Я помню тот день, словно это было вчера. Целый город исчез в мгновение ока!',
    story_lilu_10b_05: 'Среди руин мы нашли лишь одиноко бродящего ребенка...',
    story_lilu_10b_06: 'Это был Гримар? Трудно представить вас обоих детьми...',
    story_lilu_10b_07: 'Хе-хе! Мы быстро подружились, верно?',
    story_lilu_10b_08:
      'Это да. Я учился, чтобы стать чародеем и самому изучить это странное происшествие.',
    story_lilu_10b_09:
      'Если глаза Лилу не обманули, то я верю, что другой Гримар существует.',
    story_lilu_10b_10:
      'Гримар, которого мы видели... Значит, вы все же существуете в другой временной линии?!',
    story_lilu_10b_11:
      'Похоже на то. Сначала не было никаких последствий, но...',
    story_lilu_10b_12:
      'По мере того как оба Гримара росли, наши личности и жизненный опыт стали постепенно различаться.',
    story_lilu_10b_13:
      'И растущий магический парадокс все больше обременял ваш разум...',
    story_lilu_10b_14:
      'Понимаю! Выпив зелье из руин Кроноса, вы устанавливаете связь с другой временной линией!',
    story_lilu_10b_15:
      'Такова моя теория. Уверен, другой Гримар будет за это благодарен.',
    story_lilu_10b_16:
      'Ох... Тут сразу не разобраться. Думаю, мне нужно поспать... Ты идешь, Поллукс?',
    story_lilu_10b_17:
      'Что касается нас, старина Грим, нам многое нужно наверстать!',
    story_lilu_10b_18:
      'Говори за себя, старый хрыч! Я никогда не чувствовал себя моложе!',
    story_lilu_epilogue_name: 'Эпилог',
    story_lilu_epilogue_01:
      'Привет, Гримар! Вот новый рецепт. Должно действовать немного дольше!',
    story_lilu_epilogue_02:
      'Замечательное улучшение! Родители бы тобой гордились.',
    story_lilu_epilogue_03: 'Если бы я начала изучать алхимию чуть раньше...',
    story_lilu_epilogue_04:
      'У меня такие же чувства. Если бы я полностью контролировал свои возможности...',
    story_lilu_epilogue_05:
      '...Все в порядке. Я решила сосредоточиться на будущем. Чтобы стать лучше и спасти других от этой участи.',
    story_lilu_epilogue_06: '...Уверена, мои родители хотели бы именно этого.',
    story_lilu_epilogue_07: 'Ты так выросла... Я едва узнаю тебя.',
    story_lilu_epilogue_08: 'Вообще-то... кто ты такая?',
    story_lilu_epilogue_09: 'О нет! Эффект уже проходит! Мне лучше...',
    story_lilu_epilogue_10:
      'Хе-хе-хе! Да шучу я. У меня хорошее предчувствие на этот счет.',
    story_lilu_epilogue_11:
      'А теперь, пока у меня в голове ясно, не хочешь ли немного поучиться магии?',
    story_lilu_epilogue_12: 'У учителя припасено еще несколько трюков!',
    story_lilu_epilogue_13: 'О! Как насчет полиморфии? Я жажду узнать об этом!',
    story_lilu_a_name: 'Вековой копитель',
    story_lilu_a_01:
      'Гримар, а ты не говорил, что у тебя есть вторая <c1>коллекционная книга</c1>!',
    story_lilu_a_02: '<c1>Коллекционная книга</c1>?.. Что-то знакомое...',
    story_lilu_a_03:
      'Ого! Да здесь полно информации! <c1>Дамоклов меч</c1>... <c1>Янтарная цитадель</c1>...',
    story_lilu_a_04: '<c1>Великая палочка Гримара</c1>?.. Это ты ее назвал?',
    story_lilu_a_05: 'Так много страниц! Вот так коллекционер!',
    story_lilu_a_06: 'Да уж, было времечко!',
    story_lilu_a_07: 'Это ты, Солдра, старый друг?',
    story_lilu_a_08: 'Ого! Ребята, вы знакомы?',
    story_lilu_a_09:
      'Конечно. Молодой Гримар был просто влюблен в свою коллекционную книгу.',
    story_lilu_a_10:
      'Кажется, она позволила ему получить престижное звание среди людей-единомышленников... «Ботан», что ли?',
    story_lilu_a_11: 'Ой...',
    story_lilu_a_12:
      'Мы познакомились, когда он искал редкости, и вместе создали <c1>котел синтеза</c1>.',
    story_lilu_a_13:
      'Он день и ночь напролет синтезировал сотни предметов... а в полнолуние еще больше!',
    story_lilu_a_14: 'Столько усилий... а его коллекция все еще не завершена!',
    story_lilu_a_15:
      'Клянусь, я исцелю вас, мастер Гримар! Вы должны завершить дело своей жизни!',
    story_lilu_a_16:
      'Время жестоко по отношению к человечеству, это безусловно. Желаю тебе удачи в приключениях, малыш.',
    story_sia_00_01: 'Так-так-так... Что тут у нас?',
    story_sia_00_02:
      'В нашем городе появился новый торговец, да? Меня зовут Сиа. Очень рада встрече.',
    story_sia_00_03:
      'Будучи советницей нашего уважаемого короля, я хотела бы лично поприветствовать тебя в его королевстве.',
    story_sia_00_04: 'И раз уж я здесь... Я как раз кое-что ищу...',
    story_sia_00_hire_01: 'В последнее время ты мне во многом помогаешь.',
    story_sia_00_hire_02:
      'В знак нашей дружбы я предлагаю тебе свои услуги в качестве защитника.',
    story_sia_00_hire_03:
      'Я разбираюсь в ценностях, которые, несомненно, окажутся для тебя более чем полезными...',
    story_sia_01_name: 'Первые шаги короля',
    story_sia_01_01: 'Сиа! Я принял решение!',
    story_sia_01_02: 'Да, мой король?',
    story_sia_01_03: 'Сегодня я хочу отправиться за покупками!',
    story_sia_01_04: 'Отличная идея, ваше величество.',
    story_sia_01_05:
      'Но я вот смотрю ваше расписание, и сегодня вы, похоже, должны встретиться с чужеземными сановниками...',
    story_sia_01_06: 'Ох, что за скука! А мы не можем просто отослать их?',
    story_sia_01_07:
      'Помните, мой король, что, хотя вы и молоды, вы все-таки король. Ваше слово — закон.',
    story_sia_01_08:
      '...Ты абсолютно права! Тогда покупки! Сановники могут и подождать!',
    story_sia_01_09:
      'Ваша решимость достойна восхищения. Мне вас сопровождать?',
    story_sia_01_10:
      'Конечно! Я больше никому не доверяю защиту своей прекрасной особы.',
    story_sia_02_name: 'Везде свои люди',
    story_sia_02_01:
      '...И поэтому у меня есть причины считать, что жизни нашего короля, возможно, угрожает опасность.',
    story_sia_02_02:
      'Ясно. Покушение на жизнь короля выгодно нескольким королевствам-соперникам.',
    story_sia_02_03:
      'Подозрительные личности мне не попадались, но, уверен, Найе, хозяйке таврены, известно больше.',
    story_sia_02_04:
      'Спасибо, старейшина. Я признательна вам за мудрость и патриотизм.',
    story_sia_02_05:
      'Не стоит благодарности. Я житель этого королевства, и мой долг — заботиться о его процветании.',
    story_sia_02_06:
      'Пусть Рейнгольд молод и глуп, но из него получится прекрасный король... со временем.',
    story_sia_02_07:
      'А мой долг — позаботиться о том, чтобы он до этого дожил!',
    story_sia_02_08:
      'Так-так, нечасто мы видим у нас в таверне истинную голубую кровь!',
    story_sia_02_09: 'Прекрати паясничать, Найя, ты знаешь, кто я.',
    story_sia_02_10:
      'Конечно! Сиа Леонель, дар нашему королю от императора Керна...',
    story_sia_02_11: 'Чтобы служить советницей, но и следить за ним, верно?',
    story_sia_02_12: '...!',
    story_sia_02_13: '...А ты, я смотрю, везде суешь свой нос.',
    story_sia_02_14: 'Тогда скажи мне, длинноносая Найя...',
    story_sia_02_15:
      'Тебе в таверне в последнее время не попадались странные личности?',
    story_sia_02_16:
      'У нас тут полно странных личностей! Подвыпившие орки, лавочники...',
    story_sia_02_17:
      'Но страннее всех выглядит девушка, что сидит вон там в углу.',
    story_sia_02_18: '...!',
    story_sia_02_19: 'Сбежала...',
    story_sia_02_20: 'Азумская шпионка! ...Или убийца.',
    story_sia_02_21: 'Нужно все время быть на чеку...',
    story_sia_03_name: 'Незваная гостья',
    story_sia_03_01:
      'Мы собрали вас здесь сегодня, чтобы отпраздновать невероятную щедрость короля.',
    story_sia_03_02:
      'В последние месяцы он пожертвовал несколько миллионов золотых из собственной казны...',
    story_sia_03_03:
      '...нескольким местным храмам, школам и сиротским приютам.',
    story_sia_03_04:
      'На этот пир приглашаются все, поэтому расскажите своим друзьям и родным.',
    story_sia_03_05: 'Да здравствует король Рейнгольд!',
    story_sia_03_06: 'Тиран Рейнгольд, твоему правлению пришел конец!',
    story_sia_03_07: 'А-а-а...',
    story_sia_03_08: 'Сюрикэн!.. Азумская шпионка!',
    story_sia_03_09: 'Стража! Оцепить замок! Не дайте никому уйти!',
    story_sia_03_10: 'Аргон, беги за убийцей!',
    story_sia_03_11: 'Сию секунду!',
    story_sia_03_12:
      'Ах ты немытый шут! Король ранен! Ищи целителя немедленно!',
    story_sia_03_13: 'Фу, как грубо.',
    story_sia_03_14: '(У меня все входы были под максимальным наблюдением...)',
    story_sia_03_15: '(...Кто эта девушка?)',
    story_sia_04_name: 'Опаленные письма',
    story_sia_04_01:
      'Длинноносая Найя, мне кажется, ты должна мне кое-что объяснить.',
    story_sia_04_02:
      'Ой, госпожа Леонель... Мне кажется, это ВЫ должны мне кое-что объяснить.',
    story_sia_04_03: 'Прошу прощения?',
    story_sia_04_04:
      'Я перехватила несколько ОЧЕНЬ интересных писем. Отправленных вами, как я полагаю.',
    story_sia_04_05: 'Может, вместе почитаем?',
    story_sia_04_06: '...!',
    story_sia_04_07:
      'Давайте посмотрим... Печать Керна, адресовано лично императору Керна?',
    story_sia_04_08:
      'Ну и ну! Я-то надеялась на скандальную любовную историю, но, полагаю, измена тоже сойдет.',
    story_sia_04_09: 'Н-найя, прошу тебя, прекрати!',
    story_sia_04_10: 'О! А вот самое пикантное!',
    story_sia_04_11:
      '«Король Рейнгольд — круглый дурак, чье присутствие на троне крайне выгодно для вас».',
    story_sia_04_12:
      '«Если оставить его в живых, это повредит его королевству даже больше, чем смерть правителя».',
    story_sia_04_13: '...',
    story_sia_04_14:
      'Такие недобрые слова звучат как-то совсем неподобающе для его советницы!',
    story_sia_04_15: 'Длинноносая... Найя... Ты за это заплатишь!',
    story_sia_04_16:
      'Возможно, когда-нибудь. Но пока что заплатить придется вам. И, подозреваю, кругленькую сумму.',
    story_sia_04_17: '...До шантажа решила опуститься?',
    story_sia_04_18: 'Это ведь такой маленький грех по сравнению с изменой!',
    story_sia_04_19:
      'С другой стороны, если вы планировали причинить вред нашему королю...',
    story_sia_04_20:
      'Пф. Хорошо. Назови свою цену. И молись, чтобы моя шпионская сеть никогда не обхитрила твою, Найя.',
    story_sia_04_21: 'Буду ждать с нетерпением!',
    story_sia_05_name: 'Агент Свон',
    story_sia_05_01:
      '«...События приняли крутой поворот, когда на короля Рейнгольда было произведено покушение.',
    story_sia_05_02:
      'Похоже, за покушением стоит Азума. Дальнейшим расследованием займется агент Свон».',
    story_sia_05_03: 'Сиа! Что ты делаешь?',
    story_sia_05_04: 'Не обращайте внимания, просто письмо пишу.',
    story_sia_05_05: '(Вернее, доклад императору Керна...)',
    story_sia_05_06: 'Очень хорошо! Меня ждут несколько чужеземных сановников.',
    story_sia_05_07:
      'Возможно, они немного... обижены на поход по магазинам в прошлый раз.',
    story_sia_05_08:
      'Я бы совсем не отказался от твоей помощи для разрядки обстановки!',
    story_sia_05_09: 'Конечно. Буду через минуту, мой король.',
    story_sia_05_10:
      'Если сообщить императору Керна о покушении, это может привести к ужасным последствиям...',
    story_sia_05_11:
      'Мне... нужно еще время подумать. Это королевство... еще не готово противостоять Керну.',
    story_sia_05_12:
      'Ради всеобщей безопасности... полагаю, я могу опустить эту деталь...',
    story_sia_05_13:
      '«Все идет по плану. Агенту Свону не о чем докладывать в этом месяце».',
    story_sia_06_name: 'Роза и заноза',
    story_sia_06_01:
      'Сиа! К тебе гость! Кстати, парень вполне царственного вида.',
    story_sia_06_02: 'Царственного, мой король? Это ведь не...',
    story_sia_06_03: 'Леди Сиа.',
    story_sia_06_04: 'Донован...',
    story_sia_06_05:
      'Мой король, вас ждет срочное дело в сокровищнице. Я бы порекомендовала немедленно им заняться.',
    story_sia_06_06:
      'О, уже пришла последняя партия чистого золота? Жду не дождусь!',
    story_sia_06_07: 'Что за дурак этот король, да?',
    story_sia_06_08:
      'Какая нелегкая принесла тебя в королевство Рейнгольда, алхимик?',
    story_sia_06_09: 'Как всегда, сразу к сути дела, агент Свон.',
    story_sia_06_10:
      'Я просто в отпуске по личным обстоятельствам. С разрешения императора Керна, конечно.',
    story_sia_06_11:
      'Нюхаю цветы, пробую зелья... Отпуск алхимика, так сказать.',
    story_sia_06_12: 'И изо всех мест ты выбрал это?',
    story_sia_06_13:
      'Похоже, в этом отсталом королевстве немало искусных алхимиков.',
    story_sia_06_14:
      'Хотя если обнаружится, что преданность лебедя пошатнулась...',
    story_sia_06_15:
      'Ты высказался, Донован. Можешь идти и наслаждаться своим... отпуском.',
    story_sia_06_16:
      'Как пожелаете, леди Сиа. Было приятно пообщаться, как всегда.',
    story_sia_06_17: 'Очередная заноза в заднице.',
    story_sia_07_name: 'Незнакомка из города золота',
    story_sia_07_01: 'Донован? Надеюсь, я не прерываю... шпионство.',
    story_sia_07_02: 'О! Леди Сиа!',
    story_sia_07_03: 'Нет, совсем нет! Я просто осматриваюсь.',
    story_sia_07_04:
      'Не стоит быть таким скрытным. В Потерянном городе золота рады видеть всех.',
    story_sia_07_05:
      'Однако следует помнить, что любое <c1>чистое золото</c1>, найденное в нем, принадлежит королю Рейнгольду.',
    story_sia_07_06: 'За свой вклад храбрые путешественники получают награды.',
    story_sia_07_07: 'Ясно. Весьма... щедрый правитель.',
    story_sia_07_08:
      'Из <c1>чистого золота</c1> затем чеканят монеты, что приводит к резкой инфляции валюты...',
    story_sia_07_09:
      'Верно. Я вижу, вы перехватываете мои письма к императору.',
    story_sia_07_10: 'Мне нравится быть в курсе.',
    story_sia_07_11:
      'Раз уж вы здесь, сделайте полезное дело и отнесите немного <c1>чистого золота</c1> в замок.',
    story_sia_07_12: 'Конечно. Все что угодно для моей леди.',
    story_sia_07_13:
      'Постарайтесь не сломать на обратном пути ноготь, дорогой Донован.',
    story_sia_08_name: '',
    story_sia_08_01: '',
    story_sia_08_02: '',
    story_sia_08_03: '',
    story_sia_08_04: '',
    story_sia_08_05: '',
    story_sia_08_06: '',
    story_sia_08_07: 'Discussionne with Naya',
    story_sia_08_08: '',
    story_sia_08_09: '',
    story_sia_08_10: '',
    story_sia_08_11: '',
    story_sia_08_12: '',
    story_sia_08_13: '',
    story_sia_09_name: '',
    story_sia_09_01: 'Dear Donovan. I have a request, if you would.',
    story_sia_09_02: 'Oh? Let\'s hear it then.',
    story_sia_09_03:
      'Go to the Emperor. Tell him Agent Swan is dead. A honorable, tragic death dutifully serving the Empire.',
    story_sia_09_04: 'P-pardon me?',
    story_sia_09_05: 'I\'m quitting, Donovan. Changing side, if you will.',
    story_sia_09_06:
      'And you\'ll be the one to break the news. And support my story, of course.',
    story_sia_09_07: 'P-preposterous! This is high treason! I would never...',
    story_sia_09_08:
      'I believe you will, Donovan. Unless, of course, you\'d like the world to learn the recipe of your elixir of immortality?',
    story_sia_09_09:
      'Ah, is that the extend of your bluff, Sia? I\'ve never told anyone of this recipe. There is no way-',
    story_sia_09_10:
      'What was it again? Wings of a century-old mayfly... water from the Styx...?',
    story_sia_09_11: 'T-this is...',
    story_sia_09_12:
      'There were so many ingredients... wasn\'t there also a dash of wine, for the taste?',
    story_sia_09_13:
      'S-silence! SILENCE! I do not know by which dark powers you\'ve learned of this...',
    story_sia_09_14: 'It would seem you\'ve underestimated my spy network.',
    story_sia_09_15: '...But I will play your little game. This time.',
    story_sia_09_16: '',
    story_sia_09_17: '',
    story_sia_09_18: '',
    story_sia_10_name: 'Posthumous Assist',
    story_sia_a_name: 'Каприз короля',
    story_sia_a_01: 'Мой король.',
    story_sia_a_02: 'Милая Сиа! Чем могу помочь?',
    story_sia_a_03:
      'У вас в последнее время, случайно, не было этих ваших приступов капризности?',
    story_sia_a_04: 'Н-нет! Нет, конечно! Никогда!',
    story_sia_a_05:
      'Странно. Согласно нашим гроссбухам, хранилище №36 до краев заполнено <c1>кунаями</c1>.',
    story_sia_a_06:
      'Мне ведь не нужно напоминать его величеству, что это чрезвычайно редкий предмет.',
    story_sia_a_07: 'У-уверен, этому есть совершенно логи...',
    story_sia_a_08:
      'Кроме того, многие из редчайших древностей королевства были замечены в руках у простого люда.',
    story_sia_a_09: 'Ваша щедрость похвальна...',
    story_sia_a_10:
      '...но, пожалуйста, воздержитесь от раздачи бесценных артефактов из-за перепадов настроения.',
    story_sia_a_11:
      'Пф. Хорошо! С сегодняшнего дня я становлюсь другим человеком!',
    story_sia_a_12: 'Спасибо за понимание. Позвольте откланяться.',
    story_sia_a_13: 'Моя воля сильна! Я не нуждаюсь в материальных благах!',
    story_sia_a_14:
      'В этом помещении не помешает несколько <c1>королевских клинков</c1>, правда?',
    story_yami_00_01: 'Хм. Вот ты как раз подойдешь.',
    story_yami_00_02: 'Мне нужно вот что. Не подведи меня.',
    story_yami_00_hire_01: '...Отлично. Я могу сделать за тебя грязную работу.',
    story_yami_00_hire_02: 'Это временный союз. Не путай его с дружбой.',
    story_yami_01_name: 'Азумская странница',
    story_yami_01_01: 'Хи-хи-хи! Что у нас здесь?',
    story_yami_01_02: 'Пф. Поди прочь, урод.',
    story_yami_01_03: 'Плохие у тебя манеры, милочка! И одежда странная!',
    story_yami_01_04: '...',
    story_yami_01_05:
      'Ага! От тебя несет Азумой, восточным королевством людей!',
    story_yami_01_06: 'А сюда-то тебя чего занесло, милочка?',
    story_yami_01_07:
      'Вооружена до зубов, скупа на слова... Наемная убийца, небось?',
    story_yami_01_08: '...',
    story_yami_01_09:
      'Хи-хи! Я так и знал! Глупые людишки вечно убивают друг друга!',
    story_yami_01_10: '...Ты прав. Я прибыла сюда, чтобы убить короля-тирана.',
    story_yami_01_11:
      'Что ты за убийца, если болтаешь всем об этом? Мне-то зачем говорить?',
    story_yami_01_12: 'Потому что ты умрешь и никому не расскажешь. Прощай.',
    story_yami_01_13: 'Ай!',
    story_yami_01_14: '...Король Рейнгольд, ты следующий.',
    story_yami_02_name: 'Будь охотником или добычей',
    story_yami_02_01: '...В замке серьезная система безопасности.',
    story_yami_02_02: 'Пароли меняются каждый день...',
    story_yami_02_03: 'Стража патрулирует без привязки к графику...',
    story_yami_02_04: 'Мне нужно быть...',
    story_yami_02_05: 'Привет! Да, ты, в кустах!',
    story_yami_02_06: '...!',
    story_yami_02_07: 'Тоже хочешь стать рыцарем?',
    story_yami_02_08:
      'Сиа скоро будет здесь! Не нужно прятаться, она очень милая!',
    story_yami_02_09: 'Сиа!..',
    story_yami_02_10: 'Мне нужно идти. Прощай.',
    story_yami_02_11: 'П-постой!',
    story_yami_02_12: 'Ого! Дымовая шашка?!',
    story_yami_02_13: '...Оторвалась от этого дурака.',
    story_yami_02_14: 'Если Сиа здесь... моя задача усложняется.',
    story_yami_02_15: 'Но это нужно сделать. Я не могу потерпеть неудачу.',
    story_yami_02_16: 'Ради Ордена... и ради своей сестры.',
    story_yami_03_name: 'Поворотная точка',
    story_yami_03_01:
      'Уважаемые жители моего королевства! Спасибо, что пришли на мой роскошный пир!',
    story_yami_03_02:
      'Это также прекрасная возможность для всех вас лицезреть меня, красавца! Не упустите этот шанс!',
    story_yami_03_03: '(Напыщенный тиран. Как это типично.)',
    story_yami_03_04:
      'Э-э, да. Спасибо за эту поучительную речь, ваше величество.',
    story_yami_03_05:
      'Мы собрали вас здесь сегодня, чтобы отпраздновать невероятную щедрость короля.',
    story_yami_03_06:
      'В последние месяцы он пожертвовал несколько миллионов золотых из собственной казны...',
    story_yami_03_07:
      '...нескольким местным храмам, школам и сиротским приютам.',
    story_yami_03_08:
      'Участвовать в празднике приглашаются все, поэтому расскажите своим друзьям и родным.',
    story_yami_03_09: 'Да здравствует король Рейнгольд!',
    story_yami_03_10: '(...Тираны так не поступают.)',
    story_yami_03_11:
      '(...Нет, Орден не стал бы лгать. Должно быть, это какая-то уловка.)',
    story_yami_03_12: 'Тиран Рейнгольд, твоему правлению пришел конец!',
    story_yami_03_13: 'Сюрикэн!.. Азумская убийца!',
    story_yami_03_14: 'Я промахнулась?! Это невозможно!',
    story_yami_03_15:
      'Стража! Блокировать все выходы! Схватить эту женщину сию секунду!',
    story_yami_04_name: 'Отражение',
    story_yami_04_01: '(Я колебалась... И потерпела неудачу.)',
    story_yami_04_02: '(Неужели Орден солгал?..)',
    story_yami_04_03: '(Это неважно. Сейчас я сама по себе.)',
    story_yami_04_04: 'Наконец-то я тебя нашел!',
    story_yami_04_05: 'Глупый рыцарь!',
    story_yami_04_06: 'Эй! Я не драться пришел!',
    story_yami_04_07: 'Я на твои уловки не попадусь, рейнгольдовский пес!',
    story_yami_04_08: '...Ты же нарочно промахнулась, да?',
    story_yami_04_09: '...!',
    story_yami_04_10:
      'Я видел тебя на пиру. Как мне показалось, ты пожалела о том, что бросила этот сюрикэн.',
    story_yami_04_11:
      'Король не плохой человек, понимаешь? Иногда он ведет себя странно, но старается по максимуму!',
    story_yami_04_12:
      'Не знаю, кто тебе за это заплатил, но вряд ли они хорошие ребята...',
    story_yami_04_13:
      'Может быть, вернешься в замок и извинишься? Уверен, Рейнгольд...',
    story_yami_04_14: '...Извиниться? Что за глупость?',
    story_yami_04_15:
      'Мне нужно о многом подумать. Не иди за мной, если тебе твоя жизнь дорога.',
    story_yami_04_16: '...Она не плохой человек, я в этом уверен.',
    story_yami_04_17: 'Теперь нужно доложить обо всем Сиа...',
    story_yami_04_18: 'Ох и устроят мне разнос...',
    story_yami_05_name: 'При лунном свете',
    story_yami_05_01:
      '<sprite tint=1 name=Note1>Солнечный свет в кронах ив, сияние прошлых веков<sprite tint=1 name=Note2>...',
    story_yami_05_02:
      '<sprite tint=1 name=Note2>На Эльфийской поляне мы... Ай!',
    story_yami_05_03:
      'Холодный укол, ощущаемый тобой, — это мое лезвие на твоей шее.',
    story_yami_05_04:
      'У меня есть вопросы. Если ответишь честно, то, возможно, я оставлю тебя в живых.',
    story_yami_05_05: 'А-а-а! Д-да! Что уго... Ой!',
    story_yami_05_06: 'Тихо.',
    story_yami_05_07:
      'Глупец, которого вы называете королем... Расскажи мне о нем.',
    story_yami_05_08: 'К-король Рейнгольд? Он правитель здешних земель...',
    story_yami_05_09:
      'Хотя он довольно эксцентричен, нельзя отрицать его добродетель и щедрость.',
    story_yami_05_10:
      '...Ему не хватает опыта, но его новая советница Сиа с лихвой это компенсирует!',
    story_yami_05_11:
      'Невозможно!.. От твоих слов смердит трусостью, но они звучат искренне.',
    story_yami_05_12: 'Этот город! В нем есть оракул?',
    story_yami_05_13: 'Ага! Да! Эльфийка Серена, там, на холме!',
    story_yami_05_14:
      'Таинственная незнакомка... Могу ли я иметь честь узнать ваше им... Ой!',
    story_yami_05_15:
      'Несколько дней назад я бы убила тебя, не задумываясь. Не разбрасывайся удачей.',
    story_yami_05_16:
      'Возвращайся к своей пресной жизни и забудь обо всем, что здесь произошло.',
    story_yami_05_17: '...Фух! Какая волнительная встреча!',
    story_yami_05_18:
      'Дева в черном... Встретимся ли мы еще, хотелось бы мне знать...',
    story_yami_06_name: 'Разбитая пешка',
    story_yami_06_01: '...Черный ветер выдает присутствие Преследующей.',
    story_yami_06_02: '...Как?!',
    story_yami_06_03:
      'Походка Преследующей легка как перышко, но тяжелое сердце не скрыть от третьего глаза.',
    story_yami_06_04: '...Хватит шарад, оракул. Мне нужны ответы.',
    story_yami_06_05: 'Об Ордене... О моей семье.',
    story_yami_06_06:
      'Торговка смертью требует наставлений. Что она предлагает взамен?',
    story_yami_06_07: 'Твою жизнь.',
    story_yami_06_08: '...Так тому и быть.',
    story_yami_06_09:
      '<c1>Азумский Орден когда-то занимался устранением несправедливости...</c1>',
    story_yami_06_10:
      '<c1>...Защитники ордена стали рабами тех, кто платит больше всех...</c1>',
    story_yami_06_11:
      '<c1>...Ради невыполнимой задачи в жертву приносится пешка...</c1>',
    story_yami_06_12:
      '<c1>...Успех или неудача, но Орден выходит победителем.</c1>',
    story_yami_06_13: '...Невозможно.',
    story_yami_06_14: 'Посланник сказал свое слово.',
    story_yami_06_15: '...НЕВОЗМОЖНО!',
    story_yami_06_16: '...',
    story_yami_06_17:
      'Тот, кто ступает в тени, со временем должен искупить вину перед светом.',
    story_yami_07_name: 'Разбитая пешка',
    story_yami_07_01: 'Нужно скорее собираться и идти.',
    story_yami_07_02: 'Привет, таинственная незнакомка.',
    story_yami_07_03: 'Трактирщик! Я доплатила тебе за молчание.',
    story_yami_07_04:
      'Не беспокойся, на постоялом дворе «Изумруд» мы в чужие дела не лезем!',
    story_yami_07_05:
      'Однако, у нас тут несколько недоброго вида посетителей, которые, похоже, ищут тебя.',
    story_yami_07_06: '(Блюстители из ордена? Так скоро?)',
    story_yami_07_07:
      'Они не представились. Мутные какие-то ребята. Твои друзья?',
    story_yami_07_08:
      'Больше нет. Скажи им, что я умерла. Такое вполне могло случиться.',
    story_yami_07_09:
      'Покидаешь нас в спешке, да? Я думал, что так и будет, и приготовил кое-какие припасы.',
    story_yami_07_10:
      'Спасибо, трактирщик. Но там, куда я иду, они мне не понадобятся.',
    story_yami_08_name: 'Путь в один конец',
    story_yami_08_01: 'На пик Мрачный Шпиль, говоришь?',
    story_yami_08_02:
      'Да, я видела похожую на ниндзя девушку, подходящую под твое описание. Похоже, она была в спешке.',
    story_yami_08_03: 'Превосходно. Мы наконец-то выследили убийцу.',
    story_yami_08_04: 'Я соберу патруль и...',
    story_yami_08_05: 'Ты уверена? Не думаю, что это необходимо.',
    story_yami_08_06: 'Почему бы и нет, Горза?',
    story_yami_08_07:
      'Одиночный поход на пик Мрачный Шпиль — это самоубийство. И эта девушка не взяла с собой никаких припасов.',
    story_yami_08_08:
      'Не может быть, чтобы она этого не знала. Даже Рудо не настолько глуп, чтобы идти туда одному.',
    story_yami_08_09: 'Вот как? Спасибо, Горза, я приму это к сведению.',
    story_yami_08_10: 'Полагаю, эта проблема решится сама собой, но...',
    story_yami_08_11: 'Остается горькое послевкусие. Что ты затеяла, убийца?',
    story_yami_09_name: 'Столкновение на Мрачном Шпиле (Ями)',
    story_yami_09_01:
      'Должно быть, это судьба. Награда даже получше, чем гарпия!',
    story_yami_09_02: 'Ну же, давай. Я убью тебя на месте.',
    story_yami_09_03: 'Не больно-то убедительны твои слова...',
    story_yami_09_04: 'Ты прав. Мне уже на все наплевать.',
    story_yami_09_05:
      'Если ты действительно хочешь моей смерти, то оставь меня умирать здесь.',
    story_yami_09_06:
      'Я бы никогда не оставил кого-то умирать! Я отведу тебя обратно к королю Рейнгольду!',
    story_yami_09_07: 'Я знаю, он все поймет! Я понесу тебя, если понадобится!',
    story_yami_09_08: '...',
    story_yami_09_09: 'Давай, опля!',
    story_yami_09_10:
      'Держись, ты же сплошная кожа да кости! Когда ты в последний раз ела?',
    story_yami_09_11: '...',
    story_yami_09_12: 'Перекуси на обратном пути, дорога предстоит нелегкая!',
    story_yami_10_name: 'В тылу врага',
    story_yami_10_01:
      'Стража! Приведите подкрепление и следите за каждым ее шагом!',
    story_yami_10_02:
      'Аргон, пожалуйста, подожди снаружи, пока мы поговорим с этой... непрошеной гостьей.',
    story_yami_10_03: 'Сию секунду!',
    story_yami_10_04: 'А теперь говори, убийца.',
    story_yami_10_05: 'Пф, именно так. Меня наняли для убийства тирана.',
    story_yami_10_06:
      'Или я так думала. Похоже, он всего лишь шут, не замышляющий ничего плохого.',
    story_yami_10_07: 'Ш-шут?!',
    story_yami_10_08: 'Что ваш народ имеет против нашего короля?',
    story_yami_10_09:
      'Мой орден поражает кого-то только во имя справедливости...',
    story_yami_10_10:
      'Но, похоже, это была ложь. И после моего провала на меня тоже начнется охота.',
    story_yami_10_11: 'Я... прошу прощения. Я приму праведное... наказание...',
    story_yami_10_12: 'Ух...',
    story_yami_10_13: 'Она упала в обморок!',
    story_yami_10_14: 'Измучилась и изголодалась...',
    story_yami_10_15: 'Стража! Вызовите лучших лекарей к ее постели!',
    story_yami_10_16:
      'Отнеситесь к ней с уважением, подобающим королевской гостье.',
    story_yami_epilogue_name: 'Эпилог',
    story_yami_epilogue_01: 'Уф... Уф... Как же высоко... до тебя лезть...',
    story_yami_epilogue_02: 'Теперь это мой дом.',
    story_yami_epilogue_03:
      'Почему бы не поселиться в городе? Тебя же помиловали.',
    story_yami_epilogue_04:
      'Мой клан, скорее всего, считает меня мертвой. Пусть так оно и остается.',
    story_yami_epilogue_05:
      'Понимаю. Я прослежу, чтобы тебя регулярно снабжали припасами.',
    story_yami_epilogue_06: 'Теперь что касается твоего следующего задания...',
    story_yami_epilogue_07: 'Опять головорезы из империи?',
    story_yami_epilogue_08:
      'Верно. Как ты знаешь, его величество приводит в ярость множество завистников.',
    story_yami_epilogue_09:
      'Дополнительную информацию о своих целях найдешь в этом документе.',
    story_yami_epilogue_10: 'Будет сделано.',
    story_yami_epilogue_11: 'Мне тяжело это говорить, но я тебе верю.',
    story_yami_epilogue_12:
      'Мне пора возвращаться. Хотя перспектива спуска меня не радует...',
    story_yami_epilogue_13: 'Сиа... Рейнгольд...',
    story_yami_epilogue_14: 'Спасибо вам. От всего сердца.',
    story_rudo_00_01: 'Эй, ты! Позови-ка мне управляющего этой лавкой, добро?',
    story_rudo_00_02: '...Это ТЫ здесь хозяин? Такая мелочь?',
    story_rudo_00_03: 'А-ха-ха! Неплохо, совсем неплохо.',
    story_rudo_00_04: 'Ну ладно, лавочник... Я Рудо. Покажи, что у тебя есть.',
    story_rudo_00_hire_01:
      'Привет, малыш. Слышал, что ты между делом участвуешь в приключениях.',
    story_rudo_00_hire_02:
      'Как-то здесь слишком тихо. Может, отправишь меня туда, где я могу выпустить пар?',
    story_rudo_00_hire_03:
      '...Давай без бумажной волокиты. Просто покажи мне, кого тебе надо сокрушить.',
    story_rudo_01_name: 'Человек-гора',
    story_rudo_01_01:
      'Бр-р-р... Моя одежда не подходит для такой холодины! Напомни, почему мы здесь?',
    story_rudo_01_02:
      'У местных кристаллов есть чудесные свойства! Гримар сказал мне...',
    story_rudo_01_03:
      'Постой, Лилу! Смотри! Там какой-то человек дерется с йети!',
    story_rudo_01_04: 'Человек? Да он огромный, как гора!',
    story_rudo_01_05: 'И он... побеждает йети?',
    story_rudo_01_06: 'Уф... Это десятый.',
    story_rudo_01_07: 'Впечатляющий бой, сэр! Вы были великолепны!',
    story_rudo_01_08: 'А?',
    story_rudo_01_09: 'Господин... вы ранены! Позвольте мне вас исцелить!',
    story_rudo_01_10: '<c1>Исце</c1>...',
    story_rudo_01_11: 'НАЗАД!',
    story_rudo_01_12: 'Я в порядке. Само заживет.',
    story_rudo_01_13: 'Малыши не должны шляться в таких местах. Идите домой.',
    story_rudo_01_14: 'Ого, он что, так ненавидит магию?',
    story_rudo_01_15: 'Нет. В его глазах я увидела... страх.',
    story_rudo_02_name: 'Уведомление о выселении',
    story_rudo_02_01: 'Боюсь, я не понимаю...',
    story_rudo_02_02:
      'Ха! Тогда я скажу проще, чтобы до твоих старых мозгов дошло!',
    story_rudo_02_03:
      'Чародей, что живет в этом городе, — буйный сумасшедший, и его следует вышвырнуть ради безопасности всех нас.',
    story_rudo_02_04: 'Ой-ой... какие резкие слова...',
    story_rudo_02_05:
      'Буквально вчера он сказал мне приглядывать за Тимофеем...',
    story_rudo_02_06:
      '...Мол, Тим бредит, ничего не соображает и должен находиться под наблюдением.',
    story_rudo_02_07: 'Но он же и есть Тимофей!',
    story_rudo_02_08: 'Хм, да, понятно.',
    story_rudo_02_09:
      'Несмотря на его... «состояние», Тимофей Гримар — неотъемлемая часть этого города.',
    story_rudo_02_10:
      'Он столь же полезен, как и безобиден. Я не вижу причин для тревоги.',
    story_rudo_02_11: 'Мастера Гримара выгоняют? Что за дела?!',
    story_rudo_02_12: 'Не лезь в это, девчонка.',
    story_rudo_02_13:
      'Нет! Мастер Гримар — могучий чародей, но он никогда бы не причинил вреда никому!',
    story_rudo_02_14:
      'Ха! Ладно! Если этот город взлетит на воздух, не говорите, что я вас не предупреждал.',
    story_rudo_02_15: 'Вот ведь возмутитель спокойствия.',
    story_rudo_02_16: 'Лучше я буду за ним присматривать...',
    story_rudo_03_name: 'Искророжденные',
    story_rudo_03_01: 'Простите... Что это вы ворчите?',
    story_rudo_03_02:
      'Проклятье. Почему в этом городе так много этих проклятых заклинателей?..',
    story_rudo_03_03: 'А, господин Рудо, я полагаю.',
    story_rudo_03_04: 'Не пытайся подружиться со мной, чародейка.',
    story_rudo_03_05:
      'Я жрица Богини, мои способности не такие, как у чародеев.',
    story_rudo_03_06:
      'Я поклялась, что посвящу жизнь исцелению и защите других.',
    story_rudo_03_07:
      'Я на это не куплюсь. Все вы, искророжденные, одинаковые.',
    story_rudo_03_08: 'Вам известно об Искре?..',
    story_rudo_03_09: 'Пф. Забудь, что я что-то сказал. Я ухожу отсюда.',
    story_rudo_03_10: '...Ты слышала, Лилу?',
    story_rudo_03_11: 'Ага, все до единого слова!',
    story_rudo_03_12: 'Ты помнишь мои уроки об Искре?',
    story_rudo_03_13:
      'Конечно! Только рожденные с Искрой могут контролировать и создавать магические заклинания.',
    story_rudo_03_14:
      'Однако это явление, как правило, неизвестно широкой общественности.',
    story_rudo_03_15:
      'Все верно! Чего и следовало ожидать от моей самой умной ученицы!',
    story_rudo_03_16: 'Хотя тут возникает вопрос... Откуда он об этом знает?..',
    story_rudo_03_17: 'Хм... Ты думаешь, что...',
    story_rudo_04_name: 'Круши и хватай',
    story_rudo_04_01: 'Йо-хо-хо! Капитан Полония в этот раз сорвала куш!',
    story_rudo_04_02: 'Целая королевская гробница, готовая к разграблению!',
    story_rudo_04_03: 'Потише, морячка. Я занят.',
    story_rudo_04_04:
      'Но не тут-то было... ведь заросший берсерк РАЗНЕС НА КУСКИ ВСЮ ДОБЫЧУ!',
    story_rudo_04_05:
      'Ты чего творишь, дружище, чтоб тебя акулы сожрали? Это же стоит кучу дублонов!',
    story_rudo_04_06: 'Древние вещи.',
    story_rudo_04_07: 'Магические.',
    story_rudo_04_08: 'И, вероятно, проклятые.',
    story_rudo_04_09:
      'Я разношу все вдребезги, чтобы избавить кого-то от неприятностей.',
    story_rudo_04_10:
      'Если этот город слишком глуп, чтобы видеть проблему, то я позабочусь об этом сам!',
    story_rudo_04_11:
      'Эй, дружище! Этот посох, похоже, РЕАЛЬНО ценный. Ты же не собираешься...',
    story_rudo_04_12: 'Пф.',
    story_rudo_04_13: 'Ай!',
    story_rudo_04_14: 'Клянусь кракеном! Ну не надо ломать об колено!',
    story_rudo_04_15:
      'Хотя, похоже, ломательным оказался сам посох. На нем ни царапины!',
    story_rudo_04_16: 'Как твоя нога, дружище?',
    story_rudo_04_17: 'Нормально. Но этот посох крепче, чем кажется.',
    story_rudo_04_18:
      'И по какой-то причине я не могу его бросить. Будто он... приклеился к руке.',
    story_rudo_04_19:
      'Я не корабельный хирург, но, видать, тебе прилетело хорошенькое проклятие, дружище.',
    story_rudo_04_20: 'Не мешало бы тебе у доктора провериться.',
    story_rudo_04_21: 'Конечно, это проклятая магия. Это ВСЕГДА она.',
    story_rudo_04_22: 'Ладно. Я ухожу. Можешь доломать все остальное.',
    story_rudo_04_23: 'Да-да, дружище!',
    story_rudo_04_24:
      'Хотя тут скорее взлом с проникновением, ведь пират отправляется грабить...',
    story_rudo_05_name: 'Проклятия и вспышки гнева',
    story_rudo_05_01: 'Как это, не можешь разбить?!',
    story_rudo_05_02:
      'Я лишь хотела сказать, мой яростный друг, что я не могу разбить эту реликвию.',
    story_rudo_05_03:
      'Какая тогда польза от адамантинового молота, если он не может разбить этот ничтожный посох?!',
    story_rudo_05_04:
      'Ну, не такой уж он и ничтожный... Как по мне, это чистая небесталь.',
    story_rudo_05_05:
      'В любом случае здесь явно замешано что-то еще, вероятно, магия...',
    story_rudo_05_06: 'КОНЕЧНО, магия.',
    story_rudo_05_07: 'Вечно эта проклятая магия.',
    story_rudo_05_08:
      'Похоже, тебе это и так было известно! Почему бы тогда не обратиться к тому, кто разбирается в таких вещах, а не ко мне?',
    story_rudo_05_09:
      'Магам нельзя доверять... Совершенно. Мой клан это знает.',
    story_rudo_05_10: 'Ну, вряд ли у тебя здесь есть выбор, сынок.',
    story_rudo_05_11:
      'Кроме того, как можно было не доверять милой Лилу? Она же прелесть!',
    story_rudo_05_12:
      'Она же маг, да? Значит, она такая же, как и все остальные.',
    story_rudo_05_13: 'Опасная. Злобная. МЕРЗКАЯ.',
    story_rudo_05_14:
      'Ну, она, вероятно, единственная, кто сможет помочь, если ты хочешь хоть когда-то избавиться от этого посоха.',
    story_rudo_05_15: 'Проклятье...',
    story_rudo_a_name: 'Жесткая любовь',
    story_rudo_a_01: 'Горза!',
    story_rudo_a_02: 'Горза!<sprite tint=1 name=Note2>',
    story_rudo_a_03: 'Привет, Джунипер. И Мэйли! Как дела?',
    story_rudo_a_04:
      'Мы с Мэйли разговаривали о ее будущем прекрасном принце...',
    story_rudo_a_05: 'Ой, я жду не дождусь!',
    story_rudo_a_06:
      'И у нас возник вопрос... а как ТЫ познакомилась со своим?',
    story_rudo_a_07: 'С этим большим и тупым олухом Рудо? Ха!',
    story_rudo_a_08:
      'Может быть, по нему и не скажешь, но этот мужчина умеет разговаривать с женщинами.',
    story_rudo_a_09: 'Дайте-ка подумать... Вот как все было...',
    story_rudo_a_10: 'Выпью-как на посошок...',
    story_rudo_a_11:
      'Рудо... А ты никогда не думал о том, чтобы завести семью?',
    story_rudo_a_12: 'Не лезь не в свое дело, Найя. Нет у меня на это времени.',
    story_rudo_a_13:
      'Любовь наносит удар, когда меньше всего этого ожидаешь! Женщина твоей мечты может войти сюда в любую минуту...',
    story_rudo_a_14:
      'Вот еще! Мне нужна женщина, которая сможет завалить меня в борцовском поединке на землю.',
    story_rudo_a_15: 'Это вызов, здоровяк?',
    story_rudo_a_16: 'А ты проверь, зубастая!',
    story_rudo_a_17: 'Горза, пожалуйста, опусти стул...',
    story_rudo_a_18: 'Как тебе такое, баранья башка? Еще хочешь?',
    story_rudo_a_19: 'РА-А-А-А-А!!!',
    story_rudo_a_20: 'Ого!',
    story_rudo_a_21:
      'А через неделю мы поженились. Такой кровавой свадьбы я никогда не видела.',
    story_rudo_a_22: '...',
    story_rudo_a_23: '...',
    story_rudo_a_24: 'Н-наверное, нам стоит кого-то другого спросить.',
    story_polonia_00_01: 'Привет, сухопутник!',
    story_polonia_00_02:
      'Ходят слухи, что ты втихую ведешь дела на моей территории.',
    story_polonia_00_03:
      'Не повезло тебе, салага. У капитана Полонии повсюду уши!',
    story_polonia_00_04:
      'Плати своему капитану дань, а не то пущу тебя по доске!',
    story_polonia_00_hire_01: 'Привет, хранитель добычи!',
    story_polonia_00_hire_02:
      'С сегодняшнего дня я, капитан Полония, повышаю тебя до старпома!',
    story_polonia_00_hire_03:
      'Разнюхивай для своего капитана возможности грабежа повыгоднее. Я на тебя рассчитываю.',
    story_polonia_00_hire_04: 'Добро пожаловать к нам на борт, дружище!',
    story_polonia_01_name: 'Прославленный cухопутник',
    story_polonia_01_01: 'Йо-хо-хо! Сухопутники!',
    story_polonia_01_02:
      'Мечтали когда-нибудь о разудалых приключениях и пиратстве на морских просторах?',
    story_polonia_01_03:
      'Я, капитан Полония Дель Монте, нанимаю команду для своего корабля!',
    story_polonia_01_04:
      'Я дочь легендарного пирата Баросса Дель Монте и могу обещать вам добычу и приключения!',
    story_polonia_01_05: 'Капитан Полония, верно?',
    story_polonia_01_06:
      'Ага. Стало интересно? Жеребец вроде тебя пришелся бы весьма кстати.',
    story_polonia_01_07: 'Ж-жеребец?!',
    story_polonia_01_08: 'Э-э, не совсем! Я Аргон, учусь на рыцаря!',
    story_polonia_01_09:
      'Пиратство незаконно, поэтому... пожалуйста, сдавайтесь и уходите с миром!',
    story_polonia_01_10: 'Три тысячи чертей! Проклятый рыцарь...',
    story_polonia_01_11:
      'Э-э, видишь ли, я законный пират, как этот ваш... сухопутник Робин Гуд.',
    story_polonia_01_12: '...Я забираю у богатых и отдаю бедным!',
    story_polonia_01_13:
      'О! Это довольно благородно. Вопросов нет, продолжайте!',
    story_polonia_01_14: '...Фух.',
    story_polonia_01_15: 'Сегодня тоже не везет, да?',
    story_polonia_01_16: 'А-а, жизнь в открытом море — штука сложная.',
    story_polonia_02_name: 'История Дель Монте',
    story_polonia_02_01: 'Полония, сегодня вербовать больше никого не будешь?',
    story_polonia_02_02:
      'Это бесполезно. Я никудышный капитан пиратов и не достойна носить имя Дель Монте...',
    story_polonia_02_03: 'Этот Баросс Дель Монте... он твой дедушка, да?',
    story_polonia_02_04:
      'Ага! Легенда из легенд! Сейчас для нас, пиратов, настали тяжелые времена...',
    story_polonia_02_05:
      'На всех торговых судах в наши дни есть причудливые магические средства обнаружения, что позволяют легко избежать нас, морских волков.',
    story_polonia_02_06:
      'Но Баросс был не таков! Несмотря на магию, он атаковал из тумана незамеченным!',
    story_polonia_02_07:
      'Забирал все ценные вещи, оставляя жертвам только свой раскатистый фирменный смех...',
    story_polonia_02_08:
      'Даже когда все остальные пираты ушли на покой, он продолжал вселять ужас на море еще десятки лет!',
    story_polonia_02_09: 'Вполне достойное наследие!',
    story_polonia_02_10:
      'Ага! И после каждого путешествия он всегда находил время навестить внучку и поведать ей о своих приключениях...',
    story_polonia_02_11:
      '«Поднять черный флаг! Огонь из пушек! Все на корм, готовимся грабить!»',
    story_polonia_02_12: 'Почему на корм, а не на корму?',
    story_polonia_02_13:
      '...Р-р-р! Проклятье! Я всего лишь сухопутная крыса в модном костюмчике, которая усердно пытается говорить как настоящий пират.',
    story_polonia_02_14: 'И что это за пират без корабля?!',
    story_polonia_02_15: 'Без корабля? Может быть, с этого и следует начать.',
    story_polonia_02_16: 'А?',
    story_polonia_02_17: 'Капитан без корабля нескоро наберет команду!',
    story_polonia_02_18:
      'Но если тебе нужен корабль, думаю, я знаю, кто тебе может помочь.',
    story_polonia_02_19: '...Правда?',
    story_polonia_02_20:
      'Ну то есть, клянусь Веселым Роджером, спасибо тебе, трактирщица! Капитан Полония в долгу перед тобой!',
    story_polonia_02_21: 'Поверь мне, я очень на это надеюсь.',
    story_polonia_03_name: 'Дело корабела',
    story_polonia_03_01: 'Ты хочешь, чтобы я построил целый корабль?!',
    story_polonia_03_02: 'Ага. Сможешь?',
    story_polonia_03_03:
      'Еще бы! Это будет мой шедевр! Дай-ка взглянуть на чертежи.',
    story_polonia_03_04: 'Хм... они довольно сложные. Что это за каракули?',
    story_polonia_03_05: 'Мой почерк. Я не умею читать.',
    story_polonia_03_06:
      'А, тогда понятно. Однако этих чертежей более чем достаточно.',
    story_polonia_03_07:
      'Йо-хо-хо! Это будет величайший корабль из тех, что бороздили моря после Баросса и его «Бонавентуры»!',
    story_polonia_03_08:
      'Но тут возникает вопрос финансов. Ты точно можешь себе это позволить?',
    story_polonia_03_09:
      'Дублоны не проблема. Построй корабль, а я принесу тебе добычу.',
    story_polonia_04_name: 'Капитанская доля',
    story_polonia_04_01:
      'А-а, у этих йети полно добычи! Я мигом заплачу за свой корабль!',
    story_polonia_04_02: 'Так, дорогуша, я здесь. Чего надобно?',
    story_polonia_04_03: 'Для тебя я капитан Полония, салага!',
    story_polonia_04_04:
      'Конечно, дорогуша капитан. Чего ты хочешь, чтобы я сделал?',
    story_polonia_04_05:
      'Ты притащил свою телегу. Я обчищу этих йети до нитки, а ты повезешь добычу.',
    story_polonia_04_06:
      'Дорогуша капитан, ты будешь грабить этих прекрасных мохнатиков?',
    story_polonia_04_07:
      'Ага. По какой-то причине у них всегда полно ценных вещей вроде боевых молотов и составных луков.',
    story_polonia_04_08: 'А что насчет шкур, дорогуша капитан? Отдашь их мне?',
    story_polonia_04_09:
      'Знай свое место, салага! Пираты грабят, а не убивают!',
    story_polonia_04_10: 'А вот это зря.',
    story_polonia_04_11:
      'Совсем нет. Если оставляешь жертв живыми, они насобирают еще добычи, которую можно забрать позже. Это экономически выгодно!',
    story_polonia_04_12: 'Продолжай работать, салага, если хочешь свою долю!',
    story_polonia_04_13: 'Да-да, дорогуша капитан.',
    story_polonia_05_name: 'Спуск на воду',
    story_polonia_05_01: 'Узри же шедевральную работу всей моей жизни!',
    story_polonia_05_02: 'Он... красивый. Прямо на слезу прошибло.',
    story_polonia_05_03:
      'Правда? Мне пришлось кое-что убрать, например пушки в вороньем гнезде. Странно было настаивать на их размещении там...',
    story_polonia_05_04: 'Но, думаю, все получилось вполне хорошо, верно ведь?',
    story_polonia_05_05: 'Верно, дружище. Вот тебе дублон, заслужил.',
    story_polonia_05_06:
      'Конечно, заслужил! Но хочу сказать кое-что напоследок. Я хоть и мастер-плотник, но в тканях не разбираюсь.',
    story_polonia_05_07:
      '...А без паруса твой корабль из порта в скором времени не выберется!',
    story_polonia_05_08:
      'Твой капитан и так может тобой гордиться, дружище. А парус у меня есть.',
    story_polonia_05_09:
      'Ух ты! Вот это произведение искусства! Еще и магический! Откуда он?',
    story_polonia_05_10:
      'Достался от дедушки Баросса. Тот самый, что был на его корабле «Бонавентуре»!',
    story_polonia_05_11:
      'Понятно! Замечательный завершающий штрих для этого судна!',
    story_polonia_05_12: 'Что ж, капитан Полония, «Великолепный Аллан» твой!',
    story_polonia_05_13: 'Ты назвал его «Великолепный Аллан»? Погоди-ка...',
    story_polonia_05_14: 'Фигура на носу... это же ты! Еще и голый!',
    story_polonia_05_15:
      'Ха-ха! Думаю, лучшей фигуры для такого совершенного корабля не сыскать!',
    story_polonia_06_name: 'Наследие в море',
    story_polonia_06_01: 'Мой король, у меня ужасная новость.',
    story_polonia_06_02:
      'О нет! Неужели в Потерянном городе золота закончилось золото?!',
    story_polonia_06_03: 'Нет, к счастью, не такие ужасная.',
    story_polonia_06_04:
      'Однако, боюсь, назойливый пират грабит ваш личный торговый флот.',
    story_polonia_06_05:
      '...Кража оценивается более чем в 100 триллионов золотых.',
    story_polonia_06_06:
      'Клянусь богиней! А как же современная система магического обнаружения пиратов у моего флота?',
    story_polonia_06_07:
      'Похоже, совершенно неэффективна. Как и сам великий Баросс, этот корабль ускользает даже от нашей лучшей магии...',
    story_polonia_06_08:
      '...Отбирая у вас золото, прежде чем ускользнуть в море.',
    story_polonia_06_09: 'Какое неслыханное варварство! А что с командой?',
    story_polonia_06_10:
      'Их щадят, мой король. Если они не оказывают сопротивления.',
    story_polonia_06_11:
      'Тогда золото — это небольшая цена. Я буду питаться крестьянской пищей, пока не верну свое состояние!',
    story_polonia_06_12: '...Сколько это займет?',
    story_polonia_06_13: 'Приблизительно два дня, мой король.',
    story_polonia_06_14: 'Воистину темные времена...',
    story_polonia_07_name: 'Отпуск на берегу',
    story_polonia_07_01:
      'Йо-хо-хо! Капитан Полония на берегу! Выпивка за мой счет!',
    story_polonia_07_02:
      'Полония! Или мне следует обращаться... капитан Полония? Полагаю, «дела» у вас идут хорошо?',
    story_polonia_07_03:
      'Можешь поклясться своими сухопутными прелестями, что так и есть! В этих морях можно много чего награбить!',
    story_polonia_07_04:
      'Где бы ни был мой корабль, дедушкины паруса всегда ловят ветер, такая вот невиданная вещь!',
    story_polonia_07_05:
      'Уверена, Баросс бы гордился. И что вы намерены делать с этим... нечестно нажитым добром?',
    story_polonia_07_06:
      'Как всякий хороший пират, я его закопала то там, то сям вместе со своей командой.',
    story_polonia_07_07: 'О-о-о! Карты сокровищ! Какая прелесть!',
    story_polonia_07_08:
      'Да, мы, пираты, такие. Сберегаем кое-что на случай холодной зимы, будто сухопутные белки.',
    story_polonia_07_09: 'А остальное?',
    story_polonia_07_10: 'Я отдала его милой девушке в храме.',
    story_polonia_07_11:
      'Пожертвование в сиротский приют? Я и не думала, что вы филантроп.',
    story_polonia_07_12: 'Филан-кто?',
    story_polonia_07_13: 'Неважно. Что вам принести, капитан?',
    story_polonia_07_14:
      'Мое любимое, варево морского волка! И для любого сухопутника, считающего, что его не вырвет...',
    story_polonia_07_15:
      'Следующая кружка за мой счет! Бароссы снова в море! Йо-хо-хо!',
    story_polonia_a_name: 'Без задних ног',
    story_polonia_a_01: 'Привет, милая девушка. Ты ведь целительница, да?',
    story_polonia_a_02: 'О да! Я Фрейя, жрица этого хра...',
    story_polonia_a_03: 'Отруби мне ногу.',
    story_polonia_a_04: 'Что-что?',
    story_polonia_a_05: 'Я попросила отрубить мне ногу.',
    story_polonia_a_06: 'Святые небеса, нет! Как можно такое просить?',
    story_polonia_a_07:
      'Я пират! У капитана пиратов должна быть деревянная нога, черт возьми!',
    story_polonia_a_08: 'Отруби мне ногу и залатай ее своей чудной магией!',
    story_polonia_a_09:
      'Я отказываюсь. Я поклялась Богине никогда не причинять вред человеческому существу.',
    story_polonia_a_10:
      'Ох уж эти бесполезные сухопутники! Ладно! Тогда и дублонов от меня не получишь!',
    story_polonia_a_11: '...Какая странная девушка.',
    story_polonia_b_name: 'Со всех ног',
    story_polonia_b_01: 'Эй, привет!',
    story_polonia_b_02: 'А?',
    story_polonia_b_03: 'Отруби мне ногу.',
    story_polonia_b_04: 'Конечно.',
    story_polonia_b_05: 'Я сказала... Постой, ты не против?',
    story_polonia_b_06:
      'Нет, конечно. Только держи ногу вот так. Сейчас мой старый топор все сделает.',
    story_polonia_b_07: 'Эй, погоди!',
    story_polonia_b_08: 'Красиво не будет, но ты ведь крутая девчонка, верно?',
    story_polonia_b_09:
      'Не забудь хорошо наложить жгут. Мы же не хотим, чтобы ты истекла кровью.',
    story_polonia_b_10: 'Стой, дружище! Не торопись!',
    story_polonia_b_11:
      'Я... Мой корабль скоро уплывает! М-мне надо на него успеть! До встречи, дружище!',
    story_polonia_b_12: '...Ха! Люди. Никогда не дают скучать.',
    story_donovan_00_01: 'Приветствую уважаемого лавочника.',
    story_donovan_00_02:
      'Вы довольно значимая экономическая сила в этих краях. Я много о вас слышал.',
    story_donovan_00_03:
      'Ой, как же грубо с моей стороны! Я Донован, имперский алхимик Керна.',
    story_donovan_00_04:
      'Думаю, это начало прекрасного сотрудничества, согласны?',
    story_donovan_00_hire_01: 'И как же вовремя! Я заскучал в отпуске.',
    story_donovan_00_hire_02:
      'Ваши герои будут рады, что я с ними, я уверен в этом.',
    story_donovan_00_hire_03:
      'Я умею раскрывать лучшие качества у всего, будь то ингредиенты или воины.',
    story_donovan_00_hire_04:
      'Мои умения и знания к вашим услугам, уважаемый лавочник.',
    story_donovan_01_name: 'Оригинальный рецепт',
    story_donovan_01_01:
      'Добро пожаловать в «Лекарственную лавку Марибель». Чем могу помочь?',
    story_donovan_01_02:
      'Леди Марибель, да? Я хотел бы лично похвалить ваше последнее творение.',
    story_donovan_01_03:
      'Как изобретатель зелья возобновления, скажу, что ваш модифицированный чертеж воистину гениален!',
    story_donovan_01_04:
      'Простое добавление щепотки туманоцвета — это потрясающее улучшение!',
    story_donovan_01_05:
      'Погоди-ка... Вы хотите сказать, что вы тот самый Донован? Не может быть!',
    story_donovan_01_06:
      'Чертеж зелья возобновления был изобретен около 700 лет назад...',
    story_donovan_01_07: 'Даже будучи эльфом, вы не выглядите старше 150!',
    story_donovan_01_08: 'Вы слишком добры, леди Марибель.',
    story_donovan_01_09: 'Скажем так, не все мои чертежи в продаже...',
    story_donovan_01_10:
      'Но я уверен, что такой мастер-алхимик, как вы, без труда бы их выучил.',
    story_donovan_01_11: 'А теперь можно ли мне взглянуть на этот туманоцвет?',
    story_donovan_02_name: 'Изобильный край',
    story_donovan_02_01:
      'Замечательно... В этих краях даже обычные яблоки имеют превосходное качество.',
    story_donovan_02_02: 'Интересно, как такое возможно?..',
    story_donovan_02_03: 'Если кто и мог бы это объяснить, то это Джунипер!',
    story_donovan_02_04:
      'Ой, простите! Я не хотела помешать вашему разговору с этим... деревом.',
    story_donovan_02_05:
      'О, юная леди в широкополой шляпе со своей верной совой...',
    story_donovan_02_06: 'Несомненно, вы госпожа Лилу.',
    story_donovan_02_07:
      'Все верно! Да и вы, сэр Донован, очевидно, не нуждаетесь в представлении!',
    story_donovan_02_08:
      'Я много слышал об одаренной молодой ученице в этом городе и тоже рад знакомству.',
    story_donovan_02_09: 'Вы что-то говорили, госпожа Лилу?..',
    story_donovan_02_10:
      'Ах да! Джунипер постоянно говорит, что земля вокруг города полна жизни...',
    story_donovan_02_11:
      'Это, по ее словам, одна из причин, почему она так любит заниматься здесь садоводством.',
    story_donovan_02_12:
      'В любом случае, я направлялась в пещеры Авроры. Приятно было познакомиться, сэр Донован!',
    story_donovan_02_13: 'Надеюсь, мы скоро встретимся снова.',
    story_donovan_02_14: 'Хм... Возможно, эта юная леди что-то знает...',
    story_donovan_02_15:
      'Керн... истощен? Похоже, в этом королевстве во всем гораздо больше жизненных сил.',
    story_donovan_02_16:
      'Следует нанести визит этой садовнице. Если здешние ингредиенты помощнее...',
    story_donovan_02_17: '...я должен этим воспользоваться.',
    story_donovan_03_name: 'Под маской',
    story_donovan_03_01: 'Вызывали меня, госпожа Сиа?',
    story_donovan_03_02: 'А, Лилу. Спасибо, что пришла так быстро.',
    story_donovan_03_03: 'Полагаю, ты уже познакомилась с Донованом?',
    story_donovan_03_04:
      'О да! Он же легенда среди алхимиков! Я так рада, что мы встретились!',
    story_donovan_03_05: 'Веди себя с ним крайне осторожно.',
    story_donovan_03_06:
      'Ч-что?! Но он же такой джентльмен! И столько всего знает...',
    story_donovan_03_07:
      'Внешность может быть обманчива. За его молодым лицом скрывается хитрый тысячелетний гений.',
    story_donovan_03_08:
      'Тысячелетний?! Это неслыханно даже для эльфов! Вы думаете, что...',
    story_donovan_03_09:
      'Да. Некоторые считают, что, возможно, он открыл какой-то эликсир бессмертия.',
    story_donovan_03_10:
      'Ого! Я бы не прочь добавить такой рецепт в свой гримуар!',
    story_donovan_03_11:
      'Как и многие правители. Подозреваю, сам император Керна пляшет под его дудку.',
    story_donovan_03_12: 'Политика — такая сложная штука...',
    story_donovan_03_13: 'Но спасибо! Я буду осторожна рядом с ним!',
    story_donovan_03_14: 'Я знала, что ты поймешь.',
    story_donovan_03_15:
      'Что касается меня, я позабочусь о том, чтобы он держался подальше от нашего... впечатлительного короля.',
    story_donovan_03_16: 'Да... так, вероятно, к лучшему.',
    story_donovan_a_name: 'Сделка за десять золотых',
    story_donovan_a_01: 'Эй, дружок с зельями! Минутки не найдется?',
    story_donovan_a_02: 'Ух... бродяги.',
    story_donovan_a_03: 'Кому обязан такой... честью?',
    story_donovan_a_04: 'Я — Тамас, кожевник.',
    story_donovan_a_05:
      'Я слышал, дружище, ты можешь сварганить крутой эликсир бессмертия. А мне можно бутылочку?',
    story_donovan_a_06: 'Понятия не имею, где вы такое услышали.',
    story_donovan_a_07:
      'Даже если бы это была правда, вы можете быть уверены, что я не стал бы отдавать что-либо столь ценное.',
    story_donovan_a_08: 'Я дам тебе за него десять золотых.',
    story_donovan_a_09:
      'Исключено. За этот рецепт велись войны! Императоры со всего мира умоляют дать им хотя бы попробовать!',
    story_donovan_a_10:
      'Какой ты неуступчивый, дружище. Последнее предложение — пятнадцать золотых.',
    story_donovan_a_11:
      'Тебе запах кожи мозги затуманил, бродяга? Я бы не расстался с этим рецептом даже за все золото мира!',
    story_donovan_a_12:
      'Мне пора идти. Прошу больше со мной никогда не разговаривать.',
    story_donovan_a_13: 'Вот зараза, чуть-чуть не хватило.',
    story_donovan_a_14: 'Видать, придется снова искать угощение для овец...',
    story_hemma_00_01: 'Так-так...',
    story_hemma_00_02:
      'Насколько мы поняли, именно с тобой нужно говорить, если нужно сделать что-то в этих краях...',
    story_hemma_00_03:
      'Не задавай слишком много вопросов, и мы в долгу не останемся. ',
    story_hemma_00_hire_01: 'Замечательно.',
    story_hemma_00_hire_02: 'Мы тебя не разочаруем.',
    story_hemma_00_hire_03:
      'Наши способности, в сочетании с крепкими спутниками, просто потрясающие.',
    story_hemma_00_hire_04:
      'Результаты все скажут сами за себя, мы в этом уверены.',
    story_hemma_01_name: 'Нависшая тень',
    story_hemma_01_01:
      '...и тогда гиблин пал, но до этого успел нанести несколько ударов моим спутникам.',
    story_hemma_01_02: 'Пожалуйста, позаботься об их исцелении.',
    story_hemma_01_03: 'Конечно. Я в спешном порядке обработаю их раны.',
    story_hemma_01_04:
      'Привет! Я слышала, что в городе появился новый заклинатель.',
    story_hemma_01_05: 'Я Лилу! Рада с вами познакомиться!',
    story_hemma_01_06: 'Ты права, юная Лилу. Нас зовут Хемма. ',
    story_hemma_01_07: '«Нас»?..',
    story_hemma_01_08:
      'Да. Некромантия не проходит бесследно для тех, кто ее практикует...',
    story_hemma_01_09:
      'Часть тебя остается за Покровом, а другие сущности приходят из-за него...',
    story_hemma_01_10: 'Эти способности... нас изменили. К лучшему, конечно.',
    story_hemma_01_11: 'Некромантия, говорите?! А это разве не... опасно?',
    story_hemma_01_12:
      'Чрезвычайно. Но нас больше не удовлетворяют простые фокусы.',
    story_hemma_01_13:
      'А теперь позволь откланяться, нам нужно быть в другом месте. Прощай.',
    story_hemma_01_14:
      'Некромант... Это довольно темная магия, Поллукс! Но я впервые вижу ее настоящего практика!',
    story_hemma_01_15: 'Кажется, она не такая уж плохая... Верно?',
    story_hemma_02_name: 'Любой ценой',
    story_hemma_02_01: 'Некромант?..',
    story_hemma_02_02: 'Не знаю даже, что обо всем этом думать...',
    story_hemma_02_03:
      'Вы правы, что беспокоитесь. Некромантия — опасное искусство, поглощающее менее значительных существ...',
    story_hemma_02_04: 'Но мы в любом случае таковыми не являемся.',
    story_hemma_02_05:
      'А как насчет путешественников, которые отправляются с вами? Вы не высасываете из них жизнь, чтобы усилить свои заклинания?',
    story_hemma_02_06: 'Только с их согласия. Эффекты при этом не постоянны.',
    story_hemma_02_07: 'И мы щедро платим их за услуги.',
    story_hemma_02_08: 'Полагаю, вы все-таки делаете добро...',
    story_hemma_02_09:
      'Хорошо. Полагаю, вы можете стать официально одобренным защитником нашего города.',
    story_hemma_02_10:
      'Ваше одобрение нам было не нужно... но мы вас благодарим.',
    story_hemma_02_11: 'А что привело вас в наш город?',
    story_hemma_02_12: '...',
    story_hemma_02_13: 'Мы просто ищем знания и силу. ',
    story_hemma_02_14: 'Есть... задача, которую мы должны выполнить.',
    story_hemma_02_15: 'Как туманно...',
    story_hemma_02_16: 'И так оно и останется. Прощайте.',
    story_hemma_02_17: '...',
    story_hemma_02_18: 'Сколько печали в этих глазах...',
    story_hemma_03_name: 'Враг внутри',
    story_hemma_03_01:
      'Незрелые живые корни и суперплотная жуткая эктоплазма. Что-нибудь еще?',
    story_hemma_03_02: 'Это все. За остальным зайдем в лавку.',
    story_hemma_03_03: 'Спасибо, приходите еще!',
    story_hemma_03_04: '<c4>Не забудь о мрачных когтях.</c4>',
    story_hemma_03_05: 'Ах да. Пару мрачных когтей, пожалуйста.',
    story_hemma_03_06: 'Что это сейчас было?..',
    story_hemma_03_07: 'Не обращай внимания. Просто пассажир, так сказать.',
    story_hemma_03_08: '<c4>Выпусти-меня-выпусти-меня-выпусти-меня.</c4>',
    story_hemma_03_09: 'Уф. Да успокойся ты!',
    story_hemma_03_10: 'Сдачу можешь оставить себе. Прощай.',
    story_hemma_03_11: 'Спасибо за совет... и кошмары.',
    story_hemma_04_name: 'Экзорцизм',
    story_hemma_04_01: 'Не волнуйся, Хемма. Я здесь! Со мной Фрейя!',
    story_hemma_04_02:
      'Хемма, да? Прошу, расслабься. Наполнись силой экзорцизма...',
    story_hemma_04_03: 'Что-то мы не помним, чтобы просили о твоей помощи.',
    story_hemma_04_04:
      '<c4>Отвалите, детишки. Я никуда уходить не собираюсь.</c4>',
    story_hemma_04_05: 'А-а-а-а! Оно захватывает контроль! Быстрее, Фрейя!',
    story_hemma_04_06: 'Я... не могу! Что-то блокирует мои молитвы...',
    story_hemma_04_07: 'Это мы. Прекратите.',
    story_hemma_04_08: 'Ты... добровольно носишь в себе этого злого духа?!',
    story_hemma_04_09: 'Признаюсь, со временем он немного сошел с ума.',
    story_hemma_04_10: '<c4>Это я здесь жертва! Выпустите меня!</c4>',
    story_hemma_04_11: 'Ух. Скорее грубый, чем злой, уверяю вас.',
    story_hemma_04_12:
      'Это дело тебя не касается. Прошу, не вмешивайся, Фрейя.',
    story_hemma_04_13:
      'Ох уж эти некроманты... вечно играют с силами, которые не могут понять!',
    story_hemma_04_14:
      'Все нормально, Фрейя! Я уверена, она знает, что делает!',
    story_hemma_04_15: 'Да?..',
    story_hemma_05_name: 'Два разума в одном теле',
    story_hemma_05_01:
      '<c4>Похоже, секрет снова раскрыт. Готова покинуть город?</c4>',
    story_hemma_05_02:
      'Это все ТЫ. Веди ты себя потише, все было бы гораздо проще.',
    story_hemma_05_03:
      'Невзирая ни на что, мы пока не уходим. Этот город подает надежды.',
    story_hemma_05_04:
      '<c4>Ладно. Как насчет того, чтобы выпустить меня хоть раз?</c4>',
    story_hemma_05_05:
      'С-совершенно исключено. Тебя и так трудно держать под контролем.',
    story_hemma_05_06: '<c4>Выпусти-выпусти-выпусти.</c4>',
    story_hemma_05_07: 'Я делаю это ради тебя, Клод.',
    story_hemma_05_08: 'Прошу, помни это.',
    story_hemma_06_name: 'Лживое наследие',
    story_hemma_06_01: 'Фэрлин!',
    story_hemma_06_02: 'Да?',
    story_hemma_06_03:
      'Я так и знала! Хемма — ненастоящее имя! Ты чудовище, Фэрлин!',
    story_hemma_06_04: 'О-о! Да ты хитрая маленькая плутовка.',
    story_hemma_06_05: 'Это правда. Похоже, ты хорошо навела справки.',
    story_hemma_06_06:
      'Возможно, ты также слышала об Ариэль Безумной? Или об Астрид Падшей?',
    story_hemma_06_07:
      'Я о них читала... но они исчезли многие десятилетия назад! Не может же быть, что они...',
    story_hemma_06_08:
      'Это мы, конечно. К сожалению, мы сталкиваемся с серьёзными гонениями, и от имён приходится отказываться...',
    story_hemma_06_09: 'Ты чудовище! Тебя нужно остановить!',
    story_hemma_06_10:
      'И как ты намерена это сделать? «Хемма» невиновна. Кто поверит в твою дикую историю?',
    story_hemma_06_11: 'Вот разве что ты сама меня остановишь...',
    story_hemma_06_12: 'Пф!',
    story_hemma_07_name: 'Сопряжение душ',
    story_hemma_07_01: 'Все шныряешь?',
    story_hemma_07_02: 'Я слежу за тобой. Ты и твои подозрительные монологи...',
    story_hemma_07_03:
      'Ах да. Познакомься с Клодом, нашим мужем. Бывали у него времена и получше.',
    story_hemma_07_04: '<c4>Выпустите меня!</c4>',
    story_hemma_07_05: 'Что с ним случилось?..',
    story_hemma_07_06:
      'Ужасный несчастный случай, связанный с некромантией. Глупая ошибка привела его на край гибели.',
    story_hemma_07_07:
      'Мы на земле творим такие грехи, что загробная жизнь для нас, некромантов — ужасающая перспектива.',
    story_hemma_07_08:
      'Мы не могли спасти его тело, но сделали всё возможное... и спасли его душу.',
    story_hemma_07_09:
      'Сопряжение душ? Это заклинание требует многолетних исследований для подготовки соответствующего сосуда...',
    story_hemma_07_10:
      'Так и есть. С учётом обстоятельств, нам пришлось импровизировать.',
    story_hemma_07_11:
      'Импровизировать? Ты же не хочешь сказать, что... привязала душу Клода к себе?!',
    story_hemma_07_12: 'Тело не выдержит две души! Результатом стала бы...',
    story_hemma_07_13:
      'Сокрушающая разум боль. Безумие. Хуже, чем ты можешь себе представить.',
    story_hemma_07_14: 'Хемма... Почему?.. Как?..',
    story_hemma_07_15: 'Все просто, Лилуша. У нас нет права на... ошибку.',
    story_hemma_08_name: 'Поручение столетия',
    story_hemma_08_01:
      'Душа слишком сильна, её не изгнать... Может, привязать её к чему-то другому?',
    story_hemma_08_02:
      'Ты ужасно сговорчивая. У тебя появился интерес к некромантии?',
    story_hemma_08_03: 'Нет! Я ненавижу тебя и всё, что ты сделала!',
    story_hemma_08_04:
      'Насколько я понимаю, если этот Клод вырвется на свободу, здесь никому не поздоровится.',
    story_hemma_08_05:
      'Я помогу тебе исправить это безобразие, но на этом всё!',
    story_hemma_08_06:
      'Благородное чувство. Но чем может помочь простой ребёнок?',
    story_hemma_08_07:
      'Подготовка надлежащего сосуда потребует десятилетий исследований и материалов...',
    story_hemma_08_08: 'Это не проблема. Узри же.',
    story_hemma_08_09: 'Ой! Откуда у тебя всё это?.. Даже чёрные алмазы!',
    story_hemma_08_10:
      'Прошедшие десятилетия не были потрачены впустую. Наше время истекает.',
    story_hemma_08_11:
      'Осталось найти подходящее место для проведения ритуала. Мы боимся, что такого может и не быть.',
    story_hemma_08_12:
      'Правильно. У души должна быть духовная связь с местом, иначе привязка не получится.',
    story_hemma_08_13:
      '<c4>Или можно просто отпустить старого доброго Клода Фавиуса на свободу! Он заведёт много друзей!</c4>',
    story_hemma_08_14: 'Погоди... Ты сказала «Фавиус»?',
    story_hemma_09_name: 'Замок с привидениями',
    story_hemma_09_01: 'Какое восхитительное место с привидениями.',
    story_hemma_09_02:
      'Ужасное место. Ни одному здравомыслящему человеку не надо сюда приходить.',
    story_hemma_09_03: 'Но самое главное: согласно моим исследованиям...',
    story_hemma_09_04:
      '...много веков назад здесь правил безумный король из рода Фавиусов.',
    story_hemma_09_05:
      'Может быть, Клод — его дальний родственник? Надеюсь, он не был причастен к гибели королевства...',
    story_hemma_09_06: 'Мы чувствуем это. Мы связаны с этим местом. Это оно.',
    story_hemma_09_07:
      'Мы в большом долгу перед тобой, Лилу. Но теперь ты должна оставить нас в покое.',
    story_hemma_09_08: 'Хемма... Удачи.',
    story_hemma_10_name: 'Пока смерть не разлучит нас',
    story_hemma_10_01:
      'Тёмное уединённое место, полное привидений и некромантской энергии...',
    story_hemma_10_02: 'Как долго мы искали такое место?',
    story_hemma_10_03: 'Теперь нужно найти подходящий сосуд...',
    story_hemma_10_04: 'Не нужно слов. Мы прекрасно знаем, чего ты желаешь.',
    story_hemma_10_05: '...',
    story_hemma_10_06:
      'Доспехи самого безумного короля, навечно привязанные к этому месту.',
    story_hemma_10_07: '<c1>Сопряжение душ!</c1>',
    story_hemma_10_08: '...',
    story_hemma_10_09: 'Ух... Мы это чувствуем... или я?..',
    story_hemma_10_10: 'Я забыла это чувство одиночества...',
    story_hemma_10_11:
      'Я страдала целую вечность, чтобы спасти тебя, дорогой. Потому что знаю, что ты поступил бы точно так же.',
    story_hemma_10_12:
      'Отважные искатели приключений обязательно придут. Сразись с ними: резня исцелит твою измученную сущность.',
    story_hemma_10_13: '...',
    story_hemma_10_14:
      'Прощай, любимый. Но только смерть разлучит нас по-настоящему.',
    story_hemma_epilogue_name: 'Эпилог',
    story_hemma_epilogue_01:
      '<c4>ГЛУПЦЫ-Ы-Ы!!! ВЫ СМЕЕТЕ СТУПАТЬ НА МОИ ЗЕМЛИ?</c4>',
    story_hemma_epilogue_02: 'Какой неприятный враг!',
    story_hemma_epilogue_03: '<c4>МУА-ХА-ХА-ХА! НИЧТОЖНЫЕ СМЕРТНЫЕ!</c4>',
    story_hemma_epilogue_04: 'Леди Сиа! Рудо! Отступаем!',
    story_hemma_epilogue_05: 'Никогда! Я не уступлю ему ни пяди!',
    story_hemma_epilogue_06: 'Я нашла выход. Быстрее!',
    story_hemma_epilogue_07:
      'Тьфу! Я ещё вернусь, банка ты консервная! Слышишь меня?',
    story_hemma_epilogue_08:
      '<c4>ВОЗВРАЩАЙТЕСЬ, КОГДА СМОЖЕТЕ ДАТЬ ОТПОР!</c4>',
    story_hemma_epilogue_09:
      '<c4>СКАЖИТЕ СВОИМ ВЕЛИЧАЙШИМ ГЕРОЯМ, ЧТО КОРОЛЬ-ТИРАН ЖДЁТ!</c4>',
    story_hemma_a_name: 'Среди бессмертных',
    story_hemma_a_01: 'Незрелые живые корни? Интересный выбор.',
    story_hemma_a_02: 'А что такого?',
    story_hemma_a_03:
      'Живые корни — это мощные реагенты для многих заклинаний, связанных с молодостью и продолжительностью жизни.',
    story_hemma_a_04: 'Не алхимик ли ты? Не долголетия ли ищещь?',
    story_hemma_a_05: 'Ты спрашиваешь возраст у дамы? Как... неприлично.',
    story_hemma_a_06:
      'Мы не алхимик. Кровавые жертвоприношения гораздо более эффективны.',
    story_hemma_a_07:
      'А, некромантия! Сфера деятельности, заслуживающая уважения, но неправильно понимаемая другими.',
    story_hemma_a_08:
      'Что касается нашего возраста... то мы давным-давно перестали считать.',
    story_hemma_a_09:
      'Это мне определенно знакомо. После первых нескольких веков десятилетия так и летят!',
    story_hemma_a_10: 'Становится одиноко среди всех этих однодневок, правда?',
    story_hemma_a_11:
      'Нам не одиноко. А тебе не стоит совать нос в дела некроманта.',
    story_hemma_a_12: 'Конечно. И желания нет вмешиваться. Прощай!',
    story_hemma_a_13: 'Странная некромантка... Мы раньше не встречались?',
    story_hemma_a_14: 'Фэрлин?..',
    story_ashley_00_01:
      'Эй, я новенькая в городе. Ты не знаешь хорошее место, где самураю можно переночевать?',
    story_ashley_00_02: 'Постоялый двор «Изумруд»? Подойдет.',
    story_ashley_00_03: 'Меня зовут... Эшли. Да. Так меня зовут.',
    story_ashley_00_04: 'Раз уж я здесь, можно и захватить что-нибудь, да?',
    story_ashley_00_hire_01:
      'Думаю, я останусь здесь чуть подольше. Хотя становится скучновато.',
    story_ashley_00_hire_02:
      'Не хочешь отправить меня в приключение, босс? Самое сложное из тех, что у тебя есть.',
    story_ashley_00_hire_03:
      'Отправь меня на верную смерть, и я вернусь еще более сильной.',
    story_ashley_00_hire_04: 'Хе-хе. Это прозвучало довольно круто, да?',
    story_ashley_01_name: 'Необычный самурай',
    story_ashley_01_01: 'Настоящий самурай!.. Жду не дождусь!',
    story_ashley_01_02: 'Леди самурай! Я Аргон! Учусь на рыца...',
    story_ashley_01_03: 'Ои. Я Эшли. Как дела?',
    story_ashley_01_04: 'О-ои?',
    story_ashley_01_05: 'Ноги со стола, пожалуйста!',
    story_ashley_01_06: 'Пф. Ладно.',
    story_ashley_01_07: 'Ну? Что тебе нужно?',
    story_ashley_01_08:
      'Э-э... А разве самураи обычно не более организованные?',
    story_ashley_01_09:
      'Что? Да, ребята дома были теми еще занудами в этом отношении.',
    story_ashley_01_10:
      'Теперь я свободна. Просто ронин. Самурай без господина.',
    story_ashley_01_11: 'Ясно... О, точно!',
    story_ashley_01_12:
      'У меня же было запланировано приключение с Лилу в замке с привидениями, но...',
    story_ashley_01_13:
      'Кое-что наметилось в тренировочном зале! Можешь подменить меня?',
    story_ashley_01_14: 'Конечно. Но будешь мне должен, рыцарик.',
    story_ashley_02_name: 'Вооружена и готова',
    story_ashley_02_01: 'Ты Лилу, да?',
    story_ashley_02_02: 'Да, именно так! Рада знакомству!',
    story_ashley_02_03: 'Ну что же, давай начнем.',
    story_ashley_02_04: 'П-постой! Подожди!',
    story_ashley_02_05: 'Мы еще не подготовились! Зелья, пайки и...',
    story_ashley_02_06: 'Да не надо нам этого всего.',
    story_ashley_02_07: 'Выживем или умрем. Другого не дано.',
    story_ashley_02_08: 'Это... Нет! Это слишком опрометчиво!',
    story_ashley_02_09: 'Я иду. Сюда, да?',
    story_ashley_02_10: 'Нет! Это Воющий лес...',
    story_ashley_02_11: 'Э-э... Удачи?',
    story_ashley_03_name: 'На краю жизни',
    story_ashley_03_01: 'Это все, на что вы, монстры, способны? НУ ЖЕ!',
    story_ashley_03_02: 'Эшли! Прошу, помедленнее! Я не поспеваю с исцелением!',
    story_ashley_03_03: 'Это... ПУСТЯКИ!!!',
    story_ashley_03_04: 'Уф... хорошее барахло, да?',
    story_ashley_03_05: 'Шутишь?! Ты чуть не погибла!',
    story_ashley_03_06:
      'Неудивительно, что ты потеряла руку, если так себя ведешь!',
    story_ashley_03_07: 'Ои. То было другое дело.',
    story_ashley_03_08: 'Такую ошибку... я больше не повторю.',
    story_ashley_03_09: 'Пф. В этот раз обойдешься без обезболивающих.',
    story_ashley_03_10: 'Сама виновата. Соберись с духом.',
    story_ashley_03_11: 'Ха-ха... ха... я это вынесу...',
    story_ashley_03_12: 'Ай! Ой-ой-ой...',
    story_ashley_a_name: 'Сплошное фамильярничанье',
    story_ashley_a_01: 'Ои. Что это тут за крошечные монстры?',
    story_ashley_a_02: 'Это мои дорогие фамильяры. Добро пожаловать.',
    story_ashley_a_03:
      'Достойные защитники могут прийти и взять одного из них в качестве верного спутника.',
    story_ashley_a_04: 'О. Питомцы, да? Я поняла.',
    story_ashley_a_05:
      'Хорошо. Ты, зеленый. Буду звать тебя <c1>Хитрюга</c1>. Пойдем со мной.',
    story_ashley_a_06: 'Ты милый, <c1>Снежок</c1>. Я понесу тебя.',
    story_ashley_a_07: 'Э-э... Боюсь, что...',
    story_ashley_a_08: 'Ух ты, какая уродливая птица!',
    story_ashley_a_09:
      'Буду звать тебя <c1>Воробушек</c1>. Ты тоже можешь пойти со мной.',
    story_ashley_a_10: 'Ясно... Желаю всем хорошо провести время.',
    story_bjorn_00_01:
      'Would you look at that! An entire shop sprouted while I was gone!',
    story_bjorn_00_02:
      'It looks like you\'ve made good friends with Brom and Gorza, too! That makes me glad!',
    story_bjorn_00_03: 'What d\'you think, Bamham? Tusky, right?',
    story_bjorn_00_04:
      'I think Bamham likes you, city dweller. Guess we\'ll stay for some shopping!',
    story_bjorn_00_hire_01: 'Har har! Consider us hired, then!',
    story_bjorn_00_hire_02:
      'If you need help going through the wilds or charting an unknown place...',
    story_bjorn_00_hire_03:
      'You can count on the Tusked Two! Flash Quests will be done in a Flash! Har har!',
    story_bjorn_01_name: 'Dad\'s Home',
    story_bjorn_01_01: 'Hi kids! Dad\'s home!',
    story_bjorn_01_02: 'Dad!',
    story_bjorn_01_03: '...Dad.',
    story_bjorn_01_04: 'What\'s with the long face, Gorza?',
    story_bjorn_01_05: 'You\'ve been gone for months!',
    story_bjorn_01_06:
      'That\'s the Ranger\'s life! The great outdoors, far away from home.',
    story_bjorn_01_07: 'Pfft. It wouldn\'t hurt to stop by more often.',
    story_bjorn_01_08:
      'I wish I could, but the Cinderlake Volcano tends to keep a man busy!',
    story_bjorn_01_09:
      'The Volcano? I thought you said you were investigating Bleakspire Peaks...',
    story_bjorn_01_10: '...Did I?',
    story_bjorn_01_11: 'I guess I got it mixed up! Har har!',
    story_bjorn_01_12:
      'Anyway, I brought souvenirs for you two! Come take a look!',
    story_bjorn_01_13: 'Oh! I hope there\'s a Crushy Plushie!',
    story_bjorn_a_name: 'Best Bud Bamham',
    story_bjorn_a_01: 'Hey kids, you mind watching over Bamham for a bit?',
    story_bjorn_a_02: 'That nasty boar? Not a chance. Keep him with you.',
    story_bjorn_a_03: 'No offense dad, but he\'s kind of... crazy?',
    story_bjorn_a_04: 'Aw, don\'t say that! He\'s cute as a button!',
    story_bjorn_a_05:
      'Dad, he goes on a murderous rampage whenever you set him down.',
    story_bjorn_a_06:
      'That time was an accident. He just got a lil\' bit too excited.',
    story_bjorn_a_07: 'What about all the other times?',
    story_bjorn_a_08:
      'Those were... also accidents. Baby Bamham wouldn\'t hurt a fly!',
    story_bjorn_a_09: 'He would, and he\'d definitely enjoy it too.',
    story_bjorn_a_10:
      'Look, dad, you\'re better off carrying that thing with you when you\'re in town.',
    story_bjorn_a_11: '...For everyone\'s safety.',
    story_bjorn_a_12: 'Alright, alright. I get it. It\'s ok Bamham, I love you.',
    story_bjorn_a_13: 'Woah woah woah! Steady Bamham!',
    story_bjorn_02_name: '',
    story_bjorn_02_01: '',
    story_bjorn_02_02: '',
    story_bjorn_02_03: '',
    story_bjorn_02_04: '',
    story_bjorn_02_05: '',
    story_bjorn_02_06: '',
    story_bjorn_02_07: '',
    story_bjorn_02_08: '',
    story_bjorn_02_09: '',
    story_bjorn_02_10: '',
    story_bjorn_02_11: '',
    story_bjorn_03_name: '',
    story_bjorn_03_01: '',
    story_bjorn_03_02: '',
    story_bjorn_03_03: '',
    story_bjorn_03_04: '',
    story_bjorn_03_05: '',
    story_bjorn_03_06: '',
    story_bjorn_03_07: '',
    story_bjorn_03_08: '',
    story_bjorn_03_09: '',
    story_atlab_aang_00_01: 'Привет! Это ты здесь лавочник?',
    story_atlab_aang_00_02: 'Вся эта лавка твоя? Ух ты!',
    story_atlab_aang_00_03: 'Ой, прости! Я Аватар Аанг!',
    story_atlab_aang_00_04:
      'Мы с друзьями катались на моем летающем зубре, но, кажется, где-то свернули не туда...',
    story_atlab_aang_00_05: 'Какие интересные штуки! Сколько за них?',
    story_atlab_aang_00_hire_01:
      'Мы останемся здесь на некоторое время. Моему зубру Аппе нужно отдохнуть...',
    story_atlab_aang_00_hire_02:
      'Но милый человек на постоялом дворе «Изумруд» сказал мне, что мы можем остаться на постой бесплатно, если поможем тебе!',
    story_atlab_aang_00_hire_03:
      'С нетерпением жду этого! Я уверен, что Сокка тоже поможет, если ты сможешь его найти!',
    story_atlab_aang_farewell_01: 'Лавочник! Аппа полностью отдохнул!',
    story_atlab_aang_farewell_02: 'Значит... Видимо, пришло время прощаться...',
    story_atlab_aang_farewell_03:
      'Эй, не унывай! Мы можем вернуться в любое время!',
    story_atlab_aang_farewell_04: 'Точно! Ты помнишь дорогу, Сокка?',
    story_atlab_aang_farewell_05: 'Э-э... Я не обратил внимания...',
    story_atlab_aang_farewell_06:
      'Л-ладно! У нас есть важные аватарские дела, к которым мы должны вернуться!',
    story_atlab_aang_farewell_07: 'Эй, Момо! Мы уходим!',
    story_atlab_aang_farewell_08: 'Момо! Момо?..',
    story_atlab_aang_farewell_09: 'Тебе здесь нравится, да? Ладно, хорошо.',
    story_atlab_aang_farewell_10:
      'Лавочник, ты не против присмотреть за Момо некоторое время?',
    story_atlab_aang_farewell_11: 'Я вернусь за тобой позже, Момо. Обещаю!',
    story_atlab_aang_farewell_12: 'Пойдем, Сокка. Спасибо за все, лавочник!',
    story_atlab_aang_farewell_13: 'Пока!',
    story_atlab_aang_farewell_14: 'Давай, Аппа! Ип-ип!',
    story_atlab_aang_01_name: 'Судьбоносный поворот не туда',
    story_atlab_aang_01_01:
      'Прости, малыш, я не все понял. Ты не против повторить свою историю с самого начала?',
    story_atlab_aang_01_02: 'Простите! Позвольте начать сначала!',
    story_atlab_aang_01_03: 'Я Аватар Аанг.',
    story_atlab_aang_01_04:
      'Мы с друзьями путешествовали на моем летающем зубре...',
    story_atlab_aang_01_05:
      'Во время полета мы, должно быть, свернули не туда, потому что оказались здесь!',
    story_atlab_aang_01_06: 'Тот огромный бык снаружи? Он летает?',
    story_atlab_aang_01_07: 'Да! Его зовут Аппа. Он дружелюбный!',
    story_atlab_aang_01_08: 'Выглядит измотанным. За вами кто-то гонится, да?',
    story_atlab_aang_01_09: 'О, э-э...',
    story_atlab_aang_01_10:
      'Эй, не волнуйся. Под моей крышей вас никто не обидит. Оскар обещает.',
    story_atlab_aang_01_11:
      'Вот что, ребята, не хотели бы вы остаться здесь на постой бесплатно?',
    story_atlab_aang_01_12: 'Бесплатно? Правда?!',
    story_atlab_aang_01_13:
      'Начнем с того, что ваши странные деньги, похоже, здесь ничего не стоят.',
    story_atlab_aang_01_14:
      'Но не могу же я позволить кучке детей спать на улице, верно?',
    story_atlab_aang_01_15: 'С-спасибо, господин Оскар!',
    story_atlab_aang_01_16:
      'Ты выглядишь довольно способным. Как насчет в обмен на постой помочь моему другу лавочнику?',
    story_atlab_aang_01_17: 'Конечно! Большое спасибо!',
    story_atlab_aang_02_name: 'Управление огнем?',
    story_atlab_aang_02_01: 'Привет, Лилу! Познакомься с моим новым приятелем!',
    story_atlab_aang_02_02:
      'Привет! Никогда не видела таких татуировок, как у тебя...',
    story_atlab_aang_02_03:
      'Я Аанг! Я никогда не видел такой шляпы, как у тебя!',
    story_atlab_aang_02_04: 'Давай, Аанг, покажи ей свой трюк!',
    story_atlab_aang_02_05: 'Вот... этот?',
    story_atlab_aang_02_06:
      'Как ты это сделал? Я не почувствовала никакой магии!',
    story_atlab_aang_02_07:
      'Управление воздухом! Я также могу управлять землей и водой!',
    story_atlab_aang_02_08: 'Невероятно! Погоди, дай записать...',
    story_atlab_aang_02_09:
      'Возможно, это ци? Никогда не видела, чтобы кто-то проделывал такое раньше...',
    story_atlab_aang_02_10:
      'Верно! Не каждый может это сделать, нужно родиться со способностью и научиться ею владеть!',
    story_atlab_aang_02_11:
      'Ты сказал, что можешь управлять воздухом, землей и водой... а как насчет огня?',
    story_atlab_aang_02_12: 'Э-э... Я... Я еще не освоил управление огнем...',
    story_atlab_aang_02_13:
      'У меня были проблемы с поиском учителя по управлению огнем...',
    story_atlab_aang_02_14: 'Неужели? А я немного знаю магию огня!',
    story_atlab_aang_02_15:
      'Это не совсем то же самое, но, возможно, тебе будет приятно посмотреть, как она работает!',
    story_atlab_aang_02_16: 'А... конечно. Спасибо, Лилу.',
    story_atlab_aang_03_name: 'Повелитель стихий',
    story_atlab_aang_03_01:
      'Аанг, мой мальчик. Как тебе нравится городская жизнь?',
    story_atlab_aang_03_02:
      'Старейшина Оуэн! Все здорово! Спасибо за гостеприимство.',
    story_atlab_aang_03_03:
      'Всегда пожалуйста. Но скажи мне, мой мальчик, где твои родители?',
    story_atlab_aang_03_04: 'А... У меня больше нет... родителей.',
    story_atlab_aang_03_05:
      'Моя семья... все Воздушные кочевники были убиты Народом Огня.',
    story_atlab_aang_03_06: 'Остался только я один.',
    story_atlab_aang_03_07: 'Батюшки! Мои соболезнования, бедное дитя...',
    story_atlab_aang_03_08:
      'Если тебе некуда возвращаться, может, поселишься со своими друзьями здесь?',
    story_atlab_aang_03_09:
      'Уверен, что в городе будут рады, если вы останетесь. Вы уже довольно популярны!',
    story_atlab_aang_03_10:
      'Спасибо за щедрое предложение, старейшина... но я не могу его принять.',
    story_atlab_aang_03_11:
      'Прямо сейчас Хозяин Огня Озай уже планирует свой следующий шаг.',
    story_atlab_aang_03_12:
      'Когда комета Созина вновь озарит небо, Народ Огня завоюет мой мир...',
    story_atlab_aang_03_13:
      'Царство Земли... Племя Воды... Их постигнет та же участь, что и Воздушных кочевников.',
    story_atlab_aang_03_14:
      'Я, как Аватар, единственный, кто может его остановить! Но я не готов.',
    story_atlab_aang_03_15: 'Какое ужасное бремя приходится нести ребенку...',
    story_atlab_aang_03_16:
      'Как бы то ни было, знай, что наш город полностью вас поддерживает.',
    story_atlab_aang_03_17:
      'Спасибо, старейшина. Вы все уже так много сделали для нас!',
    story_atlab_aang_03_18:
      'Мы обязательно навестим вас, когда все закончится!',
    story_atlab_aang_04_name: 'Знамение Созина',
    story_atlab_aang_04_01: 'Аватар. Наши пути наконец пересеклись.',
    story_atlab_aang_04_02: 'Кто ты? Откуда ты меня знаешь?',
    story_atlab_aang_04_03:
      'Присутствие Аватара сияет подобно маяку для третьего глаза.',
    story_atlab_aang_04_04: 'А, гадание... Я пас!',
    story_atlab_aang_04_05: 'Ты, кого много, но кто еще не совсем цел...',
    story_atlab_aang_04_06: 'Тебя ждет жестокая битва с Владыкой Огня.',
    story_atlab_aang_04_07: 'Хозяин Огня Озай... Ты знаешь о нем?',
    story_atlab_aang_04_08:
      'Под взглядом кометы земля будет разорвана на части, а поля — сожжены в пепел!',
    story_atlab_aang_04_09: 'Я это знаю! Но что мне делать?',
    story_atlab_aang_04_10: 'Течение судьбы нельзя нарушить.',
    story_atlab_aang_04_11: '...',
    story_atlab_aang_04_12:
      'Но смелые могут потянуть за ниточки и сбить его с пути.',
    story_atlab_aang_04_13:
      'Ключевое значение могут обрести маловероятный союзник и хрупкий союз.',
    story_atlab_aang_04_14: 'Спасибо, гадалка... Я подумаю об этом.',
    story_atlab_aang_04_15: '...',
    story_atlab_aang_04_16:
      'Будь храбрым, Аватар. Оставайся верным себе, и ты победишь.',
    story_atlab_sokka_00_01: 'Ух ты, Аанг не шутил! В этой лавке полно добра!',
    story_atlab_sokka_00_02: 'Булавы, мечи, копья...',
    story_atlab_sokka_00_03:
      'Лавочник, мы с тобой точно станем большими друзьями.',
    story_atlab_sokka_00_04:
      'Конечно, если ты назначишь вот за это хорошую цену.',
    story_atlab_sokka_00_hire_01:
      'Добро пожаловать в команду Аватара, лавочник.',
    story_atlab_sokka_00_hire_02:
      'Как новичку в нашей команде, тебе нужно предоставить нам несколько выгодных сделок!',
    story_atlab_sokka_00_hire_03:
      'Думаю, я могу время от времени собирать для тебя материалы.',
    story_atlab_sokka_00_hire_04:
      'Но мне нужно присматривать за Аангом, хорошо?',
    story_atlab_sokka_01_name: 'Великий воин Сокка',
    story_atlab_sokka_01_01: 'А как насчет... этого?',
    story_atlab_sokka_01_02: 'О, милый трюк!',
    story_atlab_sokka_01_03: 'Пф. Он постоянно так делает!',
    story_atlab_sokka_01_04: 'Привет. Сокка, верно? Я Аргон!',
    story_atlab_sokka_01_05:
      'О, привет. Если тебе нужны волшебные трюки, это к Аангу.',
    story_atlab_sokka_01_06:
      'О нет. Я не так много знаю о магии. Или об управлении стихиями.',
    story_atlab_sokka_01_07:
      'Судя по твоему снаряжению, ты похож на грозного воина.',
    story_atlab_sokka_01_08: 'Г-грозного воина?',
    story_atlab_sokka_01_09:
      'Т-так и есть! Я Сокка, великий воин команды Аватара!',
    story_atlab_sokka_01_10:
      'Я так и знал! Наверняка ты знаешь кучу невероятных историй!',
    story_atlab_sokka_01_11: 'Еще бы! Например, во время осады Агна Кел\'а...',
    story_atlab_sokka_01_12: 'И вот так моя тогдашняя девушка стала луной.',
    story_atlab_sokka_01_13: 'Л-луной?!',
    story_atlab_sokka_01_14: 'Да. Но тогда флот Народа Огня...',
    story_atlab_sokka_01_15:
      '...и бах! Я вырубил эту гигантскую сову в библиотеке.',
    story_atlab_sokka_01_16: 'Ого! Но разве эта сова не была могучим духом?',
    story_atlab_sokka_01_17:
      'Верно! Но команда Аватара всегда побеждает, когда в ней Сокка!',
    story_atlab_sokka_01_18: 'Кстати говоря, где Аанг?',
    story_atlab_sokka_01_19: 'О, он ушел около часа назад.',
    story_atlab_sokka_01_20:
      'Ой! Я совсем потерял счет времени. Прости, Аргон, надо бежать!',
    story_atlab_sokka_02_name: 'Великий кузнец Сокка',
    story_atlab_sokka_02_01: 'Усердно трудишься, Сокка?',
    story_atlab_sokka_02_02:
      'Привет, Уоллес. Спасибо, что дал воспользоваться твоими инструментами!',
    story_atlab_sokka_02_03:
      'Нет проблем! Не ожидал я, что в своем возрасте ты такой мастер!',
    story_atlab_sokka_02_04:
      'Когда только ты в группе не являешься повелителем стихий, приходится приобретать всевозможные навыки.',
    story_atlab_sokka_02_05:
      'Не каждый день мне удается использовать такие прекрасные инструменты...',
    story_atlab_sokka_02_06: 'Обслуживание будет проведено в мгновение ока!',
    story_atlab_sokka_02_07: 'Меч, который ты точишь... Где ты его взял?',
    story_atlab_sokka_02_08: 'Это мой космический меч! Я сам его выковал!',
    story_atlab_sokka_02_09: 'Из звездного металла? Неплохо, парень!',
    story_atlab_sokka_02_10: 'Это должно быть... что-то неземное!',
    story_atlab_sokka_02_11: 'А то! Другого такого меча нет!',
    story_atlab_sokka_02_12:
      'Эй! Простите, что прерываю, но не хочет ли кто-нибудь из вас, славных ребят, помочь мне починить корабль?',
    story_atlab_sokka_02_13:
      'Он сегодня совсем угрюмый. Напоролся на какие-то странные железные шары и сел на мель...',
    story_atlab_sokka_02_14: 'Получилось разбитое корыто!',
    story_atlab_sokka_02_15:
      'Эй, нет проблем, я из Племени Воды! Посмотрю, что можно сделать. Извини, Уоллес, мне пора!',
    story_atlab_sokka_02_16: 'Я пока починю остальное твое снаряжение. Удачи!',
    story_atlab_sokka_03_name: 'Великий штурман Сокка',
    story_atlab_sokka_03_01:
      'Вот так, хотя бы благополучно доберешься до порта.',
    story_atlab_sokka_03_02: 'Отличная починка. Солидная!',
    story_atlab_sokka_03_03:
      'Твой руль держится за счет выловленных из воды досок и кусков моей рубашки.',
    story_atlab_sokka_03_04:
      'Что тут солидного? Обычный вариант починки для Сокки.',
    story_atlab_sokka_03_05:
      'Йо-хо-хо! А как вышло, что сухопутник вроде тебя чинит корабли?',
    story_atlab_sokka_03_06:
      'Жизнь Племени Воды связана с лодками и рыбалкой. Я ходил под парусом еще до того, как научился ходить ногами.',
    story_atlab_sokka_03_07:
      'Совсем недавно я даже разработал чертежи субмарины, работающей на энергии от управления водой!',
    story_atlab_sokka_03_08: 'Это какой-то сухопутный язык. Ты это о чем?',
    story_atlab_sokka_03_09:
      'Это... э-э, металлический корабль, который ходит под водой.',
    story_atlab_sokka_03_10:
      'Похоже, ты просто отправляешь морских волков на дно в шикарной коробке.',
    story_atlab_sokka_03_11:
      'Ты сейчас сказал «крайне опасный неиспытанный прототип»?',
    story_atlab_sokka_03_12: 'А... не совсем...',
    story_atlab_sokka_03_13: 'Ты мне нравишься. Пойдем со мной, новенький.',
    story_atlab_sokka_03_14:
      'Йо-хо-хо, назад в море! Поднять якоря, пока сюда не добрались охотники за наживой!',
    story_atlab_sokka_03_15:
      'Ар-р, на этот раз лучше увернуться от плавучих железных шаров.',
    story_atlab_sokka_04_name: 'Великий изобретатель Сокка',
    story_atlab_sokka_04_01:
      'Замечательно! Врать не буду, чертеж у тебя немного кустарный...',
    story_atlab_sokka_04_02:
      'Но если чуток попахать, думаю, эта субмарина заработает!',
    story_atlab_sokka_04_03: 'Гениально, правда? Я так и знал!',
    story_atlab_sokka_04_04:
      'Хотя нужно не забывать, что управлять ею смогут только повелители стихии воды.',
    story_atlab_sokka_04_05: 'Это все детали. Мы что-нибудь придумаем.',
    story_atlab_sokka_04_06:
      'Только подумай! С нею мы сможем исследовать Затонувший храм!',
    story_atlab_sokka_04_07:
      'Ах да, воздуха она держит немного, поэтому заплывы лучше делать короткими.',
    story_atlab_sokka_04_08: 'Ты слушаешь?',
    story_atlab_sokka_04_09: 'Не опирайся на этот большой железный шар.',
    story_atlab_sokka_04_10: 'А?.. Ого! А почему?',
    story_atlab_sokka_04_11:
      'Мой прототип морской бомбы. Не трогай, она взрывоопасна.',
    story_atlab_sokka_04_12: 'Ой...',
    story_atlab_sokka_04_13:
      'В любом случае, дай знать, как идет работа над прототипом.',
    story_atlab_sokka_04_14:
      'Если все получится... мы найдем им применение в будущем.',
    story_leather_00_01:
      'Привет, дружище. Сегодня утром я проснулся со странной идеей.',
    story_leather_00_02: 'Думаю, пора мне стать защитником, дружище.',
    story_leather_00_03: 'Так мне сказала кожа, дружище.',
    story_leather_alt_00_01: 'Эй, дружище, я снова защитник.',
    story_leather_alt_00_02: 'Новый год, новый я, верно?',
    story_leather_00_hire_01: 'Ладненько, дружище! Поехали!',
    story_leather_00_hire_02: 'Так что там с этими приключениями?',
    story_leather_farewell_01:
      'Эй, дружище. Думаю, защитником мне быть надоело.',
    story_leather_farewell_02:
      'Это ж гораздо больше обязанностей, чем я думал, дружище. У меня на кожу едва хватает времени!',
    story_leather_farewell_03:
      'Может быть, я попробую в следующем году, дружище. До встречи в городе!',
    story_leather_01_name: 'Кожа и дерево',
    story_leather_01_01: 'Спасибо за помощь, дружище.',
    story_leather_01_02: 'Нет проблем, Тамас! Как много кожи!',
    story_leather_01_03: 'Сегодня у меня большая добыча. Смотри под ноги.',
    story_leather_01_04: 'Ага... тут неровно... Ой!',
    story_leather_01_05: 'Ой! Ай!',
    story_leather_01_06: 'Прости, Тамас! Ты в порядке?',
    story_leather_01_07: 'Чуток ударился башкой, дружище. Думаю, я в норме.',
    story_leather_01_08: 'У меня тут промелькнула гениальная идея, дружище...',
    story_leather_01_09: 'Надо идти, дружище. Это важно.',
    story_leather_01_10: 'Э-э, конечно! Рад был помочь!',
    story_leather_01_11: 'Но что мне делать со всей этой разбросанной кожей?..',
    story_leather_02_name: 'Как предсказано в пророчестве',
    story_leather_02_01:
      'Так я и ударился башкой, дружище. Вот тут небольшая шишка.',
    story_leather_02_02: 'Тебя слушают, но помочь не могут.',
    story_leather_02_03:
      'Божественные потоки эфира показывают, но не исцеляют.',
    story_leather_02_04:
      'Это все равно ведь магия, да? Давай, дружище, коснись своими магическими руками.',
    story_leather_02_05: 'Здесь нет...',
    story_leather_02_06: '!!!',
    story_leather_02_07: 'Эта... судьба... непреодолима...',
    story_leather_02_08:
      'Точно сработало, дружище? У меня все еще малость кружится голова.',
    story_leather_02_09:
      'Прошу прощения. Возникло новое видение, требующее медитации.',
    story_leather_02_10:
      'Найди Любимца Богини, чтобы излечить... раны. Нужно немедленно уйти.',
    story_leather_02_11:
      'Ладно, дружище. Погуляю, и все пройдет. В последнее время я чувствую себя довольно сильным.',
    story_leather_03_name: 'Союз защитников',
    story_leather_03_01: 'Итак, в городе появился, э-э, новый защитник.',
    story_leather_03_02: 'Йо-хо-хо! Надеюсь, он красавчик!',
    story_leather_03_03: 'Привет, дружищи.',
    story_leather_03_04: 'Ракушки мне в глотку! Ты издеваешься?',
    story_leather_03_05: 'Тамас?! Когда ты...',
    story_leather_03_06:
      'Решил побыть защитником, дружище. Вроде не так уж и сложно.',
    story_leather_03_07: 'Э-э... Тамас, а расскажи-ка, чем ты занимаешься.',
    story_leather_03_08:
      'Колочу монстров, дружище. А еще я могу учуять кожаные вещи за много миль.',
    story_leather_03_09:
      'Эй! Красть всякую всячину — это МОЯ фишка! Придумай свою!',
    story_leather_03_10:
      'Я ничего не краду, дружище. Люди просто оставляют вещи там и сям.',
    story_leather_03_11: 'Скоро здесь будет интересно!',
    story_leather_03_12: 'Ар-р, наверно, ты права...',
    story_leather_04_name: 'Невоспетый герой',
    story_leather_04_01: 'Мой король, у меня необычный доклад.',
    story_leather_04_02: 'Как всегда, я весь внимание! Продолжай!',
    story_leather_04_03:
      'Герои и караваны сообщают, что дороги стали безопаснее, чем когда-либо.',
    story_leather_04_04: 'Монстры носа не кажут и якобы даже боятся нападать.',
    story_leather_04_05: 'Великолепная новость!',
    story_leather_04_06:
      'Однако мы не можем понять причину такого странного поведения.',
    story_leather_04_07:
      'Не менее странным является и внезапный обвал цен на рынке кожаных товаров...',
    story_leather_04_08:
      'Я уверен, это совпадение! Давайте пировать и праздновать в честь такой хорошей новости!',
    story_leather_04_09: 'Возможно, мой король... возможно.',
    story_king_00_01:
      'Любезный лавочных дел мастер, у меня к тебе скромнейшая просьба.',
    story_king_00_02:
      'Отведи меня в подземелья, чтобы я своими глазами увидел их величие!',
    story_king_00_03:
      'Мои земли слишком долго не были исследованы. Я лично займусь этим вопросом!',
    story_king_00_04:
      'Но прежде чем мы пойдем... Мое внимание случайно привлекли эти великолепные предметы!',
    story_king_alt_00_01:
      'Лавочных дел мастер! Твой любимый защитник вернулся!',
    story_king_alt_00_02:
      'Стены замка не могут удержать короля, чье сердце жаждет приключений!',
    story_king_alt_00_03: 'В путь! В подземелья!',
    story_king_00_hire_01: 'С бумагами разобрались!',
    story_king_00_hire_02: 'В путь! В подземелья!',
    story_king_farewell_01: 'Король Рейнгольд...',
    story_king_farewell_02:
      'Ох... Похоже, мои служебные обязанности меня настигли!',
    story_king_farewell_03: 'Точнее сказать, завалили. Сановники ждут.',
    story_king_farewell_04: 'Прощай, лавочных дел мастер! Я еще вернусь!',
    story_king_farewell_05: 'Нет, это вряд ли.',
    story_king_01_name: 'Королевский суд',
    story_king_01_01: 'Королевский суд открыт! Просители, заходите!',
    story_king_01_02: 'Пф.',
    story_king_01_03:
      'Что может сделать королевство Рейнгольда для своих подданных, большой... человек-медведь?',
    story_king_01_04:
      'Я думаю, что нужно запретить всю магию и выгнать заклинателей.',
    story_king_01_05: 'О... это... э-э...',
    story_king_01_06: 'Мы... учтем ваше предложение! Следующий!',
    story_king_01_07:
      'Приве-е-ет! Думаю, мы должны объявить каждый день ДНЕМ ПИНЬЯТЫ!',
    story_king_01_08: 'Какая великолепная идея! Мы даже могли бы...',
    story_king_01_09: '...',
    story_king_01_10:
      '...потратить немало времени на обсуждение этого вопроса! Спасибо, что пришли!',
    story_king_01_11: 'Сиа, сколько еще просителей сегодня?',
    story_king_01_12: 'Пятьдесят пять, мой король.',
    story_king_01_13: 'Ох!',
    story_king_02_name: 'Золотое сердце глупца',
    story_king_02_01: 'Простите, это место занято?',
    story_king_02_02: 'Пришел испортить мне выходной, Донован?',
    story_king_02_03:
      'Обижаешь, Сиа. Я просто общаюсь с таким же гражданином империи, как я.',
    story_king_02_04: 'Как поживает наш глупый король в эти дни?',
    story_king_02_05:
      'Донован, ты теряешь свой шпионский нюх. Рейнхольд далеко не такой глупец, каким ты его считаешь.',
    story_king_02_06:
      'Хотя он и улыбается, он многим жертвует ради своего народа.',
    story_king_02_07: 'Этому мог бы поучиться император... НАШ император.',
    story_king_02_08:
      'До сих пор моя роль заключалась в том, чтобы не дать ему погубить себя во имя своего народа.',
    story_king_02_09: 'Правда? Очаровательно.',
    story_king_02_10:
      'Да, он глупец с золотым сердцем. Но ты недооцениваешь его на свой страх и риск.',
    story_king_03_name: 'Внеурочное время короля',
    story_king_03_01: 'Ого! Эта груда докладов меньше не становится?',
    story_king_03_02: 'Похоже, нас ждет еще одна бессонная ночка.',
    story_king_03_03: 'Спасибо, что помогаешь мне в этом, дорогая Сиа!',
    story_king_03_04:
      'Ничего страшного, мой король. Переходим к следующему докладу...',
    story_king_03_05:
      'Фермеры из Саутбурга недоплатили налоги, сославшись на плохой урожай.',
    story_king_03_06: 'Кажется, это уже во второй раз...',
    story_king_03_07:
      'Отправь нескольких моих придворных чародеев провести расследование. Возможно, на их земли наводили порчу.',
    story_king_03_08: 'Весьма великодушное решение.',
    story_king_03_09: 'И снова прости им налоги. Этого хотел бы мой отец!',
    story_king_03_10: 'Хорошо. Я считаю, что король Кинан поступил бы так же.',
    story_king_03_11:
      'Корона, которую он оставил после себя, действительно тяжела...',
    story_king_03_12: 'Что говорится в следующем докладе?',
    story_king_03_13:
      'Здесь говорится о напряженности на границе с соседней империей...',
    story_king_04_name: 'Прыжки в тени',
    story_king_04_01: '...',
    story_king_04_02: 'Мой король...',
    story_king_04_03: 'Ай!',
    story_king_04_04:
      'П-пожалуйста, объявляй о своем присутствии заранее, если хочешь подкрасться ко мне сзади!',
    story_king_04_05: 'Нападение той ниндзя не дает вам покоя, да?',
    story_king_04_06: 'Конечно! Я был на волосок от смерти!',
    story_king_04_07: 'Мне удвоить стражу?',
    story_king_04_08:
      'В этом... нет необходимости. Но, пожалуйста, в будущем не ходи так тихо!',
    story_king_04_09: 'Как пожелаете, мой король.',
    story_king_04_10: 'Ах, как хорошо быть дома... Что?',
    story_king_04_11: 'А-а-а!!! Н-ниндзя?!',
    story_king_04_12: 'Прошу прощения. Похоже, мы промахнулись.',
    story_king_04_13: 'Теневая транслокация иногда бывает несколько неточной.',
    story_king_04_14:
      'П-пожалуйста, воздержитесь от ТЕНЕВОЙ ТЕЛЕПОРТАЦИИ в моем тронном зале!',
    story_king_04_15: 'К-король Рейнгольд? Вы в порядке?',
    story_king_04_16:
      'Да! Пожалуйста, проведи эту... жуткую посетительницу к выходу.',
    story_king_04_17:
      'Хорошо. Я позабочусь о том, чтобы в будущем замок был надежно защищен от подобных вторжений.',
    story_king_04_18:
      'Мы нашли довольно занимательную путевую точку, поэтому еще вернемся.',
    story_king_04_19: 'Надеюсь, что нет!',
    flower02_name: 'Цветочная кадка',
    lamp01_name: 'Стоячий факел',
    animalfur03_name: 'Ковер из звериного меха',
    animalfur02_name: 'Ковер из дикого меха',
    animalfur01_name: 'Пушистый ковер',
    goldcarpet_name: 'Роскошный ковер',
    androidrug_name: 'Ковер с шестерней',
    applerug_name: 'Ковер с яблоком',
    carpetsony_name: 'Техноковер',
    magiccarpet_name: 'Волшебный ковер',
    shaggyrug_name: 'Ковер с помпонами',
    semicirclemat_name: 'Мягкий полуковер',
    redcarpet_name: 'Красный ковер',
    redcarpet_name_plural: 'Красные ковры',
    welcomemat_name: 'Дверной половик',
    welcomemat_name_plural: 'Дверные половики',
    carpet_semicircle_name: 'Ковер в форме полумесяца',
    carpet_semicircle_name_plural: 'Ковры в форме полумесяца',
    carpet_square_name: 'Квадратный ковер',
    carpet_square_name_plural: 'Квадратные ковры',
    carpet_rect_name: 'Прямоугольный ковер',
    carpet_rect_name_plural: 'Прямоугольные ковры',
    carpet_circ_name: 'Круглый ковер',
    carpet_circ_name_plural: 'Круглые ковры',
    carpet_largerect_name: 'Большой прямоугольный ковер',
    carpet_largerect_name_plural: 'Большие прямоугольные ковры',
    carpet_largecirc_name: 'Большой круглый ковер',
    carpet_largecirc_name_plural: 'Большие круглые ковры',
    valentinemat_name: 'Романтический ковер',
    clovercarpet_name: 'Ковер с клевером',
    bunnycarpet_name: 'Ковер с кроликом',
    chickcarpet_name: 'Ковер с цыпленком',
    starredcarpet_name: 'Звездный ковер',
    christmasrug01_name: 'Ковер с веселыми цветами',
    christmasrug02_name: 'Праздничный ковер',
    christmasrug03_name: 'Пряничный ковер',
    christmasrug04_name: 'Ковер с красной омелой',
    christmasrug05_name: 'Ковер с золотой омелой',
    rosecarpet_name: 'Романтический ковер с розами',
    cactusrug_name: 'Кактусовый ковер',
    anniversaryrug01_name: 'Подарочный ковер',
    anniversaryrug02_name: 'Ковер с воздушными шариками',
    anniversaryrug03_name: 'Ковер с хлопушками',
    priderug01_name: 'Современная гордость',
    priderug02_name: 'Традиционная гордость',
    priderug03_name: 'Гордость трансгендеров',
    priderug04_name: 'Гордость небинаров',
    priderug05_name: 'Гордость асексуалов',
    priderug06_name: 'Гордость пансексуалов',
    priderug07_name: 'Гордость лесбиянок',
    priderug08_name: 'Гордость геев',
    priderug09_name: 'Гордость бисексуалов',
    priderug10_name: 'Гордость интерсексуалов',
    aegirrug_name: 'Ковер Тора',
    chessrug01_name: 'Шахматная доска',
    chessrug02_name: 'Шахматное поле',
    ruinsrug_name: 'Ковер Кроноса',
    bookshelf_name: 'Книжный шкаф',
    carpet_owl01_name: 'Ковер «Полночная сова»',
    carpet_owl02_name: 'Ковер «Ночная сова»',
    magicclock_name: 'Солнечные часы',
    grandfatherclock_name: 'Дедушкины часы',
    flower01_name: 'Цветок хоста',
    pancake_name: 'Ковер в виде блина',
    partitions_name: 'Перегородка',
    partitions02_name: 'Витражная стена',
    couch01_name: 'Шикарный диван',
    chair03_name: 'Шикарное кресло',
    chair02_name: 'Кресло',
    chair_name: 'Деревянный стул',
    globe_name: 'Глобус астронома',
    bench_name: 'Скамья',
    bench02_name: 'Крепкая скамья',
    bin_fabric_desc:
      'Удобная корзина для хранения вашего личного запаса ткани.',
    bin_fabric_name: 'Корзина для ткани',
    bin_gems_desc: 'Контейнер для хранения вашего личного запаса алмазов.',
    bin_gems_name: 'Корзина для алмазов',
    bin_herbs_desc: 'Удобная стойка для хранения вашего личного запаса трав.',
    bin_herbs_name: 'Сушилка для трав',
    bin_iron_desc: 'Контейнер для хранения вашего личного запаса железа.',
    bin_iron_name: 'Корзина для железа',
    bin_iwood_desc:
      'Стойка для хранения вашего личного запаса железного дерева.',
    bin_iwood_name: 'Корзина для железного дерева',
    bin_leather_desc: 'Ящик для хранения вашего личного запаса кожи.',
    bin_leather_name: 'Корзина для кожи',
    bin_mana_desc:
      'Переносной колодец для хранения вашего личного запаса эфира.',
    bin_mana_name: 'Корзина для эфира',
    bin_essence_desc:
      'Странное устройство, настроенное на потусторонних духов. Привлекает и привязывает их к месту для применения в материальных благах.',
    bin_essence_name: 'Маяк сущности',
    bin_oils_desc:
      'Надежный резервуар для хранения вашего личного запаса масла.',
    bin_oils_name: 'Резервуар для масла',
    bin_steel_desc: 'Удобная тачка для хранения вашего личного запаса стали.',
    bin_steel_name: 'Корзина для стали',
    bin_wood_desc: 'Контейнер для хранения вашего личного запаса древесины.',
    bin_wood_name: 'Корзина для древесины',
    bin_hoard_name: 'Драконий клад',
    bin_hoard_desc:
      'Впечатляющий контейнер, наделенный драконьей силой. Может вмещать все типы ресурсов!',
    carpet02_b_name: 'Полуковер с узорами',
    carpet02_e_name: 'Ковер с узорами',
    carpet03_c_name: 'Королевский ковер',
    carpet03_d_name: 'Королевский суперковер',
    carpet04_a_name: 'Строгий ковер',
    carpet04_b_name: 'Строгий полуковер',
    catstatue_name: 'Манящий кот',
    dogstatue_name: 'Верный пес',
    chest_name: 'Сундук',
    chest_desc:
      'Здесь хранятся все предметы вашей лавки. Улучшайте его, чтобы получить еще больше места.',
    chest_name_plural: 'Сундуки',
    counter_desc:
      'Поверхность, на которой заключаются сделки, а золото переходит из рук в руки.',
    counter_name: 'Прилавок',
    exoticplant_name: 'Экзотическое растение',
    fireplace03_name: 'Грубо сделанный камин',
    fireplace02_name: 'Великолепный камин',
    fireplace_name: 'Камин',
    fountain_name: 'Колодец',
    fountain01_name: 'Фонтанчик',
    furniture_type_bin: 'Корзина',
    furniture_type_chest: 'Сундук',
    furniture_type_counter: 'Прилавок',
    furniture_type_decoration: 'Декор',
    furniture_type_floordeco: 'Напольный декор',
    furniture_type_outdoordeco: 'Уличный декор',
    furniture_type_walldeco: 'Настенный декор',
    furniture_type_rack: 'Стойка',
    vertrack_name: 'Вертикальная стойка',
    vertrack_desc:
      'Демонстрационный стенд для нескольких крупных видов оружия.',
    vertrack_name_plural: 'Вертикальная стойка',
    largeplant_name: 'Большое растение',
    mailbox01_name: 'Почтовый ящик',
    mailbox02_name: 'Модный почтовый ящик',
    mannequin_name: 'Манекен',
    mannequin_desc:
      'Простая деревянная стойка для демонстрации одежды или доспехов.',
    mannequin_name_plural: 'Манекены',
    candelabra_name: 'Канделябр',
    obelisk_name: 'Обелиск',
    pillar01_name: 'Столб',
    pillar02_name: 'Резной столб',
    plant_name: 'Растение',
    rich_counter_name: 'Модный прилавок',
    rich_table_name: 'Модный стол',
    shelves_name: 'Полки',
    shelves_desc:
      'Крепкие полки, на которых удобно помещается множество маленьких предметов.',
    shelves_name_plural: 'Полки',
    Storage: 'Хранилище',
    streetlamp_name: 'Уличный фонарь',
    bowbanner01_name: 'Флаг с луком',
    gunbanner01_name: 'Флаг с пистолетом',
    swordbanner01_name: 'Флаг с мечом',
    table_desc:
      'Плоская поверхность, на которой помещается множество предметов различного вида.',
    table_name: 'Стол',
    table_name_plural: 'Столы',
    torch_name: 'Факел',
    vase_name: 'Ваза',
    halfpartition_name: 'Низкая балюстрада',
    halfpartition02_name: 'Низкая перегородка',
    signframe01_name: 'Вывеска лавки',
    signpost01_name: 'Столб с указателями',
    totempole01_name: 'Тотемный столб',
    fireworkcannon_name: 'Пушка для фейерверков',
    emperorbust_name: 'Бюст императора',
    tent_name: 'Палатка исследователя',
    campfire_name: 'Костер',
    mystictome_name: 'Мистический фолиант',
    savepoint_name: 'Точка сохранения',
    scarecrow_name: 'Пугало',
    oktoberfestbarrel_name: 'Бочка Октоберфеста',
    witchpot_name: 'Зловредное варево',
    witchpotpurple_name: 'Стигийское варево',
    bluecandlebig_name: 'Большая свеча души',
    bluecandlesmall_name: 'Свеча души',
    coffin_name: 'Выкопанный гроб',
    tombstone_name: 'Надгробие',
    pumpkin_name: 'Классическая тыква',
    pumpkincat_name: 'Тыква-кошка',
    pumpkincute_name: 'Милая тыква',
    pumpkinwink_name: 'Подмигивающая тыква',
    pumpkinevil_name: 'Злая тыква',
    argonbanner_name: 'Знамя доблести',
    yamiscreen_name: 'Святилище они',
    cornucopia_name: 'Рог изобилия',
    snowman_name: 'Снеговик',
    sled_name: 'Сани Санты',
    christmascane_name: 'Карамельная трость',
    christmasgifts_name: 'Куча подарков',
    soldier_name: 'Щелкунчик',
    christmastree_name: 'Новогодняя елка',
    christmasball_red_name: 'Красный шарик',
    christmasball_green_name: 'Зеленый шарик',
    christmasball_blue_name: 'Синий шарик',
    christmasball_orange_name: 'Оранжевый шарик',
    christmasball_purple_name: 'Фиолетовый шарик',
    cookietable_name: 'Стол с печеньем',
    interiorchristmastree_name: 'Новогодняя елка',
    gingerbreadhouse_name: 'Пряничный домик',
    christmasfireplace_name: 'Украшенный камин',
    luckymouseapple_name: 'Мышь удачи (монета)',
    luckymouseandroid_name: 'Мышь удачи (ямб)',
    lunarpartitions_name: 'Китайская ширма',
    lunartable_name: 'Китайский чайный сервиз',
    lunarchair_name: 'Китайский стул',
    lunarstool_name: 'Китайский табурет',
    lunaryearlampost01_name: 'Китайский фонарь A',
    lunaryearlampost02_name: 'Китайский фонарь B',
    lunaryearlanterns01_name: 'Набор фонарей A',
    lunaryearlanterns02_name: 'Набор фонарей B',
    dancingdragon_purple_name: 'Фиолетовый танцующий лев',
    dancingdragon_blue_name: 'Синий танцующий лев',
    dancingdragon_red_name: 'Красный танцующий лев',
    dancingdragon_yellow_name: 'Желтый танцующий лев',
    ratballoons_name: 'Шарики «Крыса богатства»',
    lunarfireworks_name: 'Новогодний фейерверк',
    lunargong_name: 'Китайский гонг',
    cupidfountain_name: 'Фонтан Купидона',
    valentinetarget_name: 'Мишень свахи',
    gemtable_name: 'Драгоценная музыкальная шкатулка',
    luggage_name: 'Чемодан купца',
    merchantdesk_name: 'Письменный стол купца',
    merchantsafe_name: 'Сейф купца',
    cloverchair_name: 'Стул святого Патрика',
    cloverdrawer_name: 'Тумбочка святого Патрика',
    clovercabinet_name: 'Шкаф святого Патрика',
    goldcauldron_name: 'Горшок золота',
    buriedgoldcauldron_name: 'Тайник лепрекона',
    cloverpot_name: 'Удача в горшке',
    koala_name: 'Коала для обнимашек',
    flowerlattice_name: 'Цветущий забор',
    springarch_name: 'Цветочная арка',
    chocobunny_name: 'Шоколадный кролик',
    eggsbasket_name: 'Пасхальная корзина',
    bunnytable_name: 'Кроличий стол',
    chicklamp_name: 'Цыплячья лампа',
    bunnylamp_name: 'Кроличья лампа',
    bunnychair_name: 'Кроличий стул',
    chicksofa_name: 'Цыплячий диван',
    easterpartition_name: 'Пасхальная панель',
    bunnymailbox_name: 'Почтовый кролик',
    chickmailbox_name: 'Почтовый цыпленок',
    egglamp01_name: 'Фонарь из синего яйца',
    egglamp02_name: 'Фонарь из розового яйца',
    egglamp03_name: 'Фонарь из зеленого яйца',
    egglamp04_name: 'Фонарь из желтого яйца',
    egglamp05_name: 'Фонарь из фиолетового яйца',
    carrotcrop_name: 'Морковный урожай',
    eastercabinet_name: 'Шкаф Остары',
    chocolatechicken_name: 'Цыпленок из какао',
    easterbouquet_name: 'Букет пасхальных яиц',
    cloverboots_name: 'Клеверный сапог',
    legendarysword_name: 'Легендарный меч',
    flowerwagon_name: 'Цветущая тачка',
    flowerwheel_name: 'Цветочное колесо',
    victorianlamp_name: 'Изящная лампа',
    victoriancouch_name: 'Изящный диван для отдыха',
    picnicset_name: 'Набор для пикника',
    swingset_name: 'Качели-скамейка с цветами',
    poloniaglobe_name: 'Глобус Девяти морей',
    rudobeartotem_name: 'Тотем старого медведя',
    carriage_name: 'Подарочная карета короля',
    carriage_desc: 'Подарок от короля Рейнгольда.',
    guitarcactus_name: 'Поющий кактус',
    dancingcactus_name: 'Танцующий кактус',
    cincopartition_name: 'Перегородка «Чинко»',
    tacocouch_name: 'Диван «Тако»',
    tacochair_name: 'Кресло «Тако»',
    tacopillow_name: 'Подушка «Тако»',
    cincotable_name: 'Стол «Чинко»',
    cincostool_name: 'Табурет «Чинко»',
    romanbed_name: 'Имперская кровать',
    marblepool_name: 'Имперская ванна',
    romantable_name: 'Стол императора',
    romancolumn_name: 'Имперская колонна',
    craftingtable_name: 'Чудесный верстак Аллана',
    carnivorousplant_name: 'Бела карнивора',
    schooldesk_name: 'Школьная парта',
    schoolboard_name: 'Классная доска',
    schoollocker_name: 'Шкафчики академии',
    schoolcontender_name: 'Вешалка академии',
    magiclamp_name: 'Волшебная лампа',
    flyingcarpet_name: 'Ковер-самолет',
    falldresser_name: 'Осенний шкаф',
    fallbed_name: 'Кровать времен года',
    oktoberbar_name: 'Сидровый прилавок',
    telescope_name: 'Звездная обсерватория',
    crystalball_name: 'Шар предсказаний',
    pumpkincandelabra_name: 'Тыквенный канделябр',
    hauntedmirror_name: 'Зеркало с призраком',
    thanksgivingoven_name: 'Печь изобилия',
    thanksgivingtable_name: 'Стол воссоединения',
    liluchair_name: 'Место Поллукса',
    siavanity_name: 'Ослепительное тщеславие',
    cincopinata_name: 'Мегапиньята',
    sombrerolamp_name: 'Лампа-сомбреро',
    nestchair_name: 'Удобное гнездо',
    popcorncart_name: 'Тележка с попкорном',
    honeypot_name: 'Горшочек меда',
    troblinplush_name: 'Плюшевый гиблин',
    fusioncauldron_name: 'Котел синтеза',
    freedomballoons_name: 'Вертушки и шарики',
    kingbust_name: 'Памятник величественному монарху',
    kingbust_desc: 'Подарок от короля Рейнгольда.',
    grottoplushie_name: 'Плюшевый йети',
    catchair_name: 'Кошачье место',
    cattable_name: 'Рыбный стол',
    dogcoffeetable_name: 'Стол с собачьими лапами',
    dogbed_name: 'Собачья кровать',
    wafflecouch_name: 'Вафельный диван',
    gummachine_name: 'Автомат со жвачками',
    moonbanner_name: 'Флаг Усаги',
    mochitable_name: 'Стол с моти',
    atlab_paisho_name: 'Стол для пай-шо с чаем',
    atlab_firethrone_name: 'Трон хозяина Озая',
    christmastrain_name: 'Поезд на Северный полюс',
    newyearballoons_name: 'Воздушные шары 2022',
    toadwitchplushie_name: 'Плюшевая жаба-ведьма',
    lunarcenser_name: 'Китайская курильница',
    dumplingtable_name: 'Стол с пельменями',
    valentinechair_name: 'Романтический стул',
    valentinepartition_name: 'Романтическая перегородка',
    valentineplant01_name: 'Розовый куст (красный)',
    valentineplant02_name: 'Розовый куст (розовый)',
    valentineplant03_name: 'Розовый куст (желтый)',
    wiccanbroom_name: 'Заколдованная метла',
    vikingboat_name: 'Викингский драккар',
    blossombonsai_name: 'Цветущий бонсай',
    blossomdresser_name: 'Комод в стиле ханами',
    burritotable_name: 'Стол Кукурузного короля',
    alchemytable_name: 'Исследование Донована',
    cactusplant_name: 'Кактус в горшке',
    epicllama_name: 'Пиньята-лама',
    mushgoonplushie_name: 'Плюшевый грибандит',
    desertthrone_name: 'Грибандитский трон',
    mechanicalbull_name: 'Бык для родео',
    westernbarrel_name: 'Салунная бочка',
    birthdaycake_name: 'Торт на день рождения',
    chessqueen_name: 'Ферзь',
    chessbishop_name: 'Слон',
    chesspawn_name: 'Пешка',
    chessking_name: 'Король',
    chessknight_name: 'Конь',
    chessrook_name: 'Ладья',
    chessqueen_name_plural: 'Ферзи',
    chessbishop_name_plural: 'Слоны',
    chesspawn_name_plural: 'Пешки',
    chessking_name_plural: 'Короли',
    chessknight_name_plural: 'Кони',
    chessrook_name_plural: 'Ладьи',
    anubisplushie_name: 'Плюшевый Анубис',
    anubispyramid_name: 'Великая пирамида',
    watermeloncouch_name: 'Диван «Арбуз»',
    juicebox_name: 'МЕГАпакет сока',
    taikodrum_name: 'Барабан тайко',
    arcademachine_name: 'Автомат с игрой CrabPow!',
    homeconsole_name: 'Консоль с ретро-ТВ',
    trainingdummy_name: 'Тренировочный манекен',
    floatingpumpkins_name: 'Тыквенная пентаграмма',
    halloweentable_name: 'Страшный стол с конфетами',
    halloweenchair_name: 'Трон владыки вампиров',
    thanksgivingdrum_name: 'Традиционные инструменты',
    thanksgivingpartition_name: 'Кожаная перегородка',
    knightplushie_name: 'Плюшевый тиран',
    icetable_name: 'Стол для ледового дворца',
    snowglobe_name: 'Снежный шар',
    newyeargramophone_name: 'Граммофон',
    bunnychair2_name: 'Кроличья подушка',
    layingbunny_name: 'Мандариновый страж',
    layingbunny_nobowl_name: 'Сонный страж',
    tangerinetree_name: 'Мандарин-бонсай',
    newyearballoons2_name: 'Воздушные шары 2023',
    wd_africanmask_name: 'Маска для ритуала дама',
    africanstool_name: 'Резной табурет',
    heartbed_name: 'Кровать в форме сердца',
    floatingenveloppe_name: 'Теплое любовное письмо',
    cyclopsplushie_name: 'Плюшевый циклоп',
    mapledispenser_name: 'Дозатор кленового сиропа',
    dinoplushie_name: 'Плюшиозавр',
    dinoskull_name: 'Череп трицератопса',
    chickplushie_name: 'Пухлый цыпленок',
    blossompartition_name: 'Цветущая перегородка',
    zengarden_name: 'Сад дзен',
    speakers_name: 'Колонки',
    piano_name: 'Рояль',
    harp_name: 'Арфа',
    clawmachine_name: 'Кран-машина',
    spacechair_name: 'Адмиральское кресло',
    mooseplushie_name: 'Могучий лось',
    shoprthrone_name: 'Трон титана',
    firecrabplushie_name: 'Плюшевый крушитель',
    cloudbed_name: 'Постель для спокойного сна',
    sleepingmobile_name: 'Лунный мобиль',
    boarplushie_name: 'Плюшевый Бамхам',
    boarcouch_name: 'Кожаный диван',
    sultanlampost_name: 'Пустынный фонарь',
    arabiancactus_name: 'Кактус Керна',
    sultanpartition_name: 'Ширма султана',
    sultancouch_name: 'Диван султана',
    camelstatue_name: 'Статуя одногорбого верблюда',
    goldcamelstatue_name: 'Золотой дромедар',
    oktoberhorn_name: 'Горный рог',
    gravehand_name: 'Неглубокая могила',
    ghostrock_name: 'Камень духа',
    hauntedtree_name: 'Темный древолюд',
    pumpkinlamppost_name: 'Тыквенные фонари',
    skulltentaclepot_name: 'Жуткий череп',
    pumpkintentaclepot_name: 'Тыква с щупальцами',
    mushroomstool01_name: 'Желтая поганка',
    mushroomstool02_name: 'Красная поганка',
    mushroomstool03_name: 'Синяя поганка',
    mushroomstool04_name: 'Фиолетовая поганка',
    mushroomstool05_name: 'Оранжевая поганка',
    glowingfungus_name: 'Светящийся гриб',
    lemonadestand_name: 'Лимонадный киоск',
    kidpool_name: 'Детский бассейн',
    julybbq_name: 'Летний гриль',
    starredbox_name: 'Звездный декор',
    freedomflowers_name: 'Цветы свободы',
    freedomtable_name: 'Звездный стол',
    freedomchair_name: 'Звездный стул',
    freedomcouch_name: 'Полосатый диван',
    starredlantern_name: 'Звездные фонари',
    americanflag_name: 'Звездный флаг',
    freedomstatue_name: 'Фигура свободы',
    colosseum_name: 'Миниатюрный Колизей',
    romanshrub_name: 'Имперский куст',
    skis_name: 'Веселые лыжи',
    sled02_name: 'Бревенчатые сани',
    christmasplant01_name: 'Букет из сосновых шишек',
    christmasplant02_name: 'Веселая зелень',
    christmasbench_name: 'Скамья радости',
    giftspile_name: 'Груда подарков',
    deerlog_name: 'Оленье бревно',
    christmaselfdecoration_name: 'Помощник Санты',
    northpolepost_name: 'Полярный столб',
    santadecoration_name: 'Случай с сугробом',
    dragongong_name: 'Гонг Солдры',
    dragoncrystal_name: 'Риф из лунных камней Лундры',
    lootbarrel_name: 'Джекпот',
    vipjukebox_name: 'Золотые ноты',
    elephantidol_name: 'Мраморный слон',
    pillows_name: 'Пышные подушки',
    bambooplant_name: 'Саженцы бамбука',
    bambootable_name: 'Бамбуковая композиция',
    couplenightstand_name: 'Дикая тумбочка',
    couplebearstatue_name: 'Фигура кадьяка',
    oxstatue01_name: 'Бык Фортуны',
    oxstatue02_name: 'Бык монет удачи',
    cincopicnictable_name: 'Стол для пикника «Синко»',
    birdhouse_name: 'Скворечник',
    circustent_name: 'Цирковой шатер',
    apiary_name: 'Улей занятых пчел',
    troblintrap_name: 'Хитроумная ловушка',
    freedombanner_name: 'Флаг с орлом',
    grottoicevein_name: 'Скала с драгоценными камнями',
    swaggydonut_name: 'Вывеска «Модные пончики»',
    spookytree_name: 'Полый дуб',
    falltrunkchair_name: 'Пень с грибами',
    atlab_cabbagecart_name: 'Тележка с капустой',
    atlab_koipond_name: 'Пруд с кои-духами',
    atlab_appa_name: 'Сонный Аппа',
    atlab_firebanner_name: 'Флаг огня',
    atlab_waterbanner_name: 'Флаг воды',
    atlab_airbanner_name: 'Флаг воздуха',
    atlab_earthbanner_name: 'Флаг земли',
    atlab_rockhut_name: 'Каменная хижина Тоф',
    swamphouse_name: 'Дом Бабы-Яги',
    lunarmailbox_name: 'Китайский почтовый ящик',
    tigerstatue01_name: 'Статуя тигра (монета)',
    tigerstatue02_name: 'Статуя тигра (узел)',
    tigerstatue03_name: 'Статуя тигра (ямб)',
    carrothouse_name: 'Дом Крокро',
    cactusstand_name: 'Папи Какти и семья',
    dancingavocado_name: 'Мистер Палта',
    airshipcannon_name: 'Антидраконья пушка',
    beachwhale_name: 'Кит в прибое',
    falllanterns_name: 'Ворота с тётинами',
    halloweenfence_name: 'Дряхлый забор',
    knightcastle_name: 'Самая темная башня',
    newyearclock_name: 'Новогодний Крошечный Бен',
    bunnystatue_name: 'Статуя кролика',
    bunnystatue_lantern_name: 'Фонарь',
    bunnystatue_envelope_name: 'Красный конверт',
    bunnystatue_coin_name: 'Монета',
    bunnystatue_knot_name: 'Узел',
    mapletaffystand_name: 'Стойка с кленовыми ирисками',
    fairyfountain_name: 'Фонтан феи',
    wateringcan_name: 'Лейка',
    ufo_name: 'НЛО',
    icefishing_name: 'Рыболовная лунка',
    igloo_name: 'Иглу',
    lifeguardchair_name: 'Стул спасателя',
    volleyballnet_name: 'Волейбольная сетка',
    troblinstatue_name: 'Статуя гиблина',
    troblinstatue_name_plural: 'Статуи гиблина',
    troblinstatue_bow_name: 'Гиблин с луком',
    troblinstatue_club_name: 'Гиблин с дубиной',
    troblinstatue_stick_name: 'Гиблин с палкой',
    troblinstatue_potion_name: 'Гиблинское зелье',
    volcano_name: 'Вулкан Пепельного озера',
    sleepingbag_name: 'Комплект для кемпинга',
    tacklebox_name: 'Рыболовные снасти',
    wd_books_name: 'Книжная полка',
    wd_catframe_name: 'Портрет миссис Рейнбоу',
    wd_catclock_name: 'Кошачьи часы',
    wd_chandelier_name: 'Медное бра',
    wd_flowerpot_name: 'Цветочный горшок',
    wd_flowers1_name: 'Висячие розы (красные)',
    wd_flowers2_name: 'Висячие розы (голубые)',
    wd_lantern_name: 'Фонарь',
    wd_mooseantlers_name: 'Оленьи рога',
    wd_porthole_name: 'Иллюминатор',
    wd_roundwindow_name: 'Круглое окно',
    wd_stainedglass_name: 'Витраж',
    wd_window_name: 'Деревянное окно',
    wd_fallwreath_name: 'Богатый венок',
    wd_halloweenframe_name: 'Паутина в рамке',
    wd_snowflakes_name: 'Снежинки',
    wd_christmaswreath_name: 'Рождественский венок',
    wd_newyearclock_name: 'Новогодние часы',
    wd_lunarwalldeco_name: 'Узел удачи',
    wd_easterclock_name: 'Часы Крокро',
    wd_wiccan_name: 'Языческий оберег',
    wd_crystals_name: 'Кристаллы пустоты',
    wd_airshipwheel_name: 'Штурвал «Великолепного Аллана»',
    wd_freedomwalldeco_name: 'Бумажная поделка на 4 июля',
    wd_crabclock_name: 'Крабовые часы',
    wd_talaverasun_name: 'Солнце в стиле талавера',
    wd_musketeeremblem_name: 'Эмблема мушкетера',
    wd_icemirror_name: 'Замёрзшее зеркало',
    wd_christmaslantern_name: 'Фонарь с Северного полюса',
    wd_neonsign_name: 'Неоновая вывеска',
    wd_prideheart_name: 'Гордое сердце',
    wd_beachposter01_name: 'Эльфийский закат',
    wd_beachposter02_name: 'Бессмертное лето',
    wd_portraitargon_name: 'Портрет Аргона',
    wd_portraitargon_name_plural: 'Портреты Аргона',
    wd_portraitlilu_name: 'Портрет Лилу',
    wd_portraitsia_name: 'Портрет Сиа',
    wd_portraityami_name: 'Портрет Ями',
    wd_portraitrudo_name: 'Портрет Рудо',
    wd_portraitpolonia_name: 'Портрет Полонии',
    wd_portraitdonovan_name: 'Портрет Донована',
    wd_portraithemma_name: 'Портрет Геммы',
    wd_portraitashley_name: 'Портрет Эшли',
    wd_portraitbjorn_name: 'Bjorn Portrait',
    statue_blacksmith_name: 'Статуя Уоллеса',
    statue_carpenter_name: 'Статуя Аллена',
    statue_herbalist_name: 'Статуя Марибель',
    statue_tailor_name: 'Статуя Джулии',
    statue_herbs_name: 'Статуя Джунипер',
    statue_innkeeper_name: 'Статуя Оскара',
    statue_miner_name: 'Статуя Дархана',
    statue_orc_lady_name: 'Статуя Горзы',
    statue_tanner_name: 'Статуя Тамаса',
    statue_wizard_name: 'Статуя Гримара',
    statue_mana_name: 'Статуя Серены',
    statue_ironwood_name: 'Статуя Джога',
    statue_steel_name: 'Статуя Брома',
    statue_elderowen_name: 'Статуя старейшины Оуэна',
    statue_priest_name: 'Статуя Фрейи',
    statue_oil_name: 'Статуя Беатрис',
    statue_casinogirl_name: 'Статуя Майи',
    statue_fabric_name: 'Статуя Исмаила',
    statue_master_name: 'Статуя Теодора',
    statue_academy_name: 'Статуя Эвелин',
    statue_sundragon_name: 'Статуя Солдры',
    firepit_name: 'Костровая чаша',
    decoration_wall_basicLayout_name: 'Обычная стена',
    decoration_wall_wood_name: 'Выцветший кирпич',
    decoration_wall_nicewood_name: 'Бледные полосы',
    decoration_wall_roughstone_name: 'Ромбовидный рисунок',
    decoration_wall_squarestone_name: 'Синяя панель',
    decoration_wall_brick_name: 'Деревянная панель',
    decoration_wall_dualstone_name: 'Изысканный сапфир',
    decoration_wall_finebrick_name: 'Изысканный изумруд',
    decoration_wall_halloween01_name: 'Закатные кошки',
    decoration_wall_halloween02_name: 'Рассветные кошки',
    decoration_wall_halloween03_name: 'Закатные тыквы',
    decoration_wall_halloween04_name: 'Рассветные тыквы',
    decoration_wall_halloween05_name: 'Закатные летучие мыши',
    decoration_wall_halloween06_name: 'Сумеречный лес',
    decoration_wall_halloween07_name: 'Закатная тыквомышь',
    decoration_wall_halloween08_name: 'Синие кошки',
    decoration_wall_halloween09_name: 'Зеленые кошки',
    decoration_wall_halloween010_name: 'Желтые кошки',
    decoration_wall_thanksgiving01_name: 'Осенний рисунок',
    decoration_wall_thanksgiving02_name: 'Желудевая арка',
    decoration_wall_christmas01_name: 'Снегопад',
    decoration_wall_christmas02_name: 'Подарочная упаковка',
    decoration_wall_christmas03_name: 'Украшения',
    decoration_wall_lunar01_name: 'Пагода',
    decoration_wall_lunar02_name: 'Монета удачи',
    decoration_wall_lunar03_name: 'Красная мышь удачи',
    decoration_wall_lunar04_name: 'Зеленая мышь удачи',
    decoration_wall_lunar05_name: 'Лиловая мышь удачи',
    decoration_wall_lunar06_name: 'Фиолетовая мышь удачи',
    decoration_wall_lunar07_name: 'Синяя мышь удачи',
    decoration_wall_valentine01_name: 'Фреска с Купидоном',
    decoration_wall_valentine02_name: 'Струны страсти',
    decoration_wall_stpatrick01_name: 'Клевер на удачу',
    decoration_wall_stpatrick02_name: 'Золотой бант',
    decoration_wall_stpatrick03_name: 'Милезский мотив',
    decoration_wall_easter01_name: 'Мистер Кролик',
    decoration_wall_easter02_name: 'Цыплячий узор',
    decoration_wall_easter03_name: 'Пасхальные яйца',
    decoration_wall_easter04_name: 'Пасхальные ленты',
    decoration_wall_easter05_name: 'Арка Крокро',
    decoration_wall_polonia_name: 'Обои Девяти морей',
    decoration_wall_cincodemayo01_name: 'Фреска «Чинко»',
    decoration_wall_cincodemayo02_name: 'Бумага «Чинко»',
    decoration_wall_cincodemayo03_name: 'Колючий узор',
    decoration_wall_summer01_name: 'У моря',
    decoration_wall_victorian01_name: 'Викторианские цветы',
    decoration_wall_wallace_name: 'Мастерская Уоллеса',
    decoration_wall_4thofjuly01_name: 'Звездная',
    decoration_wall_4thofjuly02_name: 'Звездная (большая)',
    decoration_wall_4thofjuly03_name: 'Полосатые звезды',
    decoration_wall_herbalist01_name: 'Решетка Марибель',
    decoration_wall_augustus2020_01_name: 'Золотая фреска',
    decoration_wall_arabiannight01_name: 'Лучшее от султана',
    decoration_wall_arabiannight02_name: 'Пустынная роза',
    decoration_wall_arabiannight03_name: 'Последняя ночь',
    decoration_wall_school01_name: 'Охранная фреска',
    decoration_wall_autumn01_name: 'Грибная зелень',
    decoration_wall_autumn02_name: 'Темная поганка',
    decoration_wall_autumn03_name: 'Золотые грибы',
    decoration_wall_oktoberfest01_name: 'Народный рисунок',
    decoration_wall_wizard01_name: 'Небесный рисунок',
    decoration_wall_sia01_name: 'Драгоценность Керна',
    decoration_wall_thanksgiving03_name: 'Изобильная индейка',
    decoration_wall_thanksgiving04_name: 'Пернатый узор',
    decoration_wall_thanksgiving05_name: 'Перья на ветру',
    decoration_wall_winter01_name: 'Снежный полог',
    decoration_wall_christmas04_name: 'Веселый узор',
    decoration_wall_christmas05_name: 'Венок из омелы',
    decoration_wall_christmas06_name: 'Колокольчики',
    decoration_wall_christmas07_name: 'Золотая омела',
    decoration_wall_sundragon01_name: 'Рисунок Солдры',
    decoration_wall_moondragon_name: 'Рисунок Лундры',
    decoration_wall_vip01_name: 'Удачный рисунок',
    decoration_wall_india01_name: 'Изображения лотоса',
    decoration_wall_couple01_name: 'Сад деревянных роз',
    decoration_wall_lunar2021_01_name: 'Китайский Новый год',
    decoration_wall_victorianspring01_name: 'Изящный весенний узор',
    decoration_wall_earthday01_name: 'Фреска Геи',
    decoration_wall_cincodemayo04_name: 'Пименто «Синко»',
    decoration_wall_cincodemayo05_name: 'Пименто «Синко» (мягкий)',
    decoration_wall_cincodemayo06_name: 'Маракасы «Синко»',
    decoration_wall_birds01_name: 'Щебечущие деревья',
    decoration_wall_bee01_name: 'Улей занятых пчел',
    decoration_wall_circus01_name: 'Цирковой шатер',
    decoration_wall_4thofjuly04_name: 'Звездная (волнистая)',
    decoration_wall_troblin01_name: 'Рисунок гиблина',
    decoration_wall_yeti01_name: 'Счастливый йети',
    decoration_wall_cat01_name: 'Рыбный трофей',
    decoration_wall_dog01_name: 'Ценная кость',
    decoration_wall_candy01_name: 'Вкуснотища',
    decoration_wall_moon01_name: 'Лунная Усаги',
    decoration_wall_hispanic01_name: 'Стены с лепниной',
    decoration_wall_halloween011_name: 'Сплошная паутина',
    decoration_wall_autumn04_name: 'Лягушки-приятели',
    decoration_wall_atlab_wall01_name: 'Стена Хозяина Огня',
    decoration_wall_atlab_wall02_name: 'Стены Ба Синг Се',
    decoration_wall_christmas08_name: 'Окна хижины',
    decoration_wall_christmas09_name: 'Праздничные огни хижины',
    decoration_wall_winter02_name: 'Веранда зимней хижины',
    decoration_wall_winter03_name: 'Освещение хижины',
    decoration_wall_newyear01_name: 'Новогодний фейерверк',
    decoration_wall_frog01_name: 'Жабий узор',
    decoration_wall_lunar2022_01_name: 'Год Тигра',
    decoration_wall_valentine03_name: 'Романтическая плитка',
    decoration_wall_wiccan01_name: 'Викканская луна',
    decoration_wall_wiccan02_name: 'Викканские ночи',
    decoration_wall_aegir01_name: 'Стена Эгира',
    decoration_wall_blossom01_name: 'Перегородка сёдзи',
    decoration_wall_easter06_name: 'Сад Крокро',
    decoration_wall_lcog01_name: 'Роскошная стена',
    decoration_wall_lcog02_name: 'Люксовая стена',
    decoration_wall_cincodemayo07_name: 'Стиль уипиль',
    decoration_wall_hemmadon01_name: 'Шторы духовных уз',
    decoration_wall_desert01_name: 'Грибандитский рисунок',
    decoration_wall_western01_name: 'Пыльный горизонт',
    decoration_wall_shopr01_name: 'Покрытые зельем стены',
    decoration_wall_shopr02_name: 'Покрытые титаном стены',
    decoration_wall_airship01_name: 'Паруса воздушного корабля',
    decoration_wall_chess01_name: 'Шахматная стена',
    decoration_wall_beach01_name: 'Стена песчаного замка',
    decoration_wall_anubis01_name: 'Иероглиф',
    decoration_wall_fruit01_name: 'Фруктовый узор',
    decoration_wall_autumn05_name: 'Раздвижная дверь',
    decoration_wall_arcade01_name: 'Разбитый кирпич',
    decoration_wall_musketeer01_name: 'Величественный коридор',
    decoration_wall_halloween012_name: 'Призрачный забор',
    decoration_wall_castle01_name: 'Рисунок тирана',
    decoration_wall_thanksgiving06_name: 'Вышитый иглами дикобраза узор',
    decoration_wall_ice01_name: 'Дворец Ледяной королевы',
    decoration_wall_christmas10_name: 'Мятная колонна',
    decoration_wall_newyear02_name: 'Стена в стиле ар-деко',
    decoration_wall_lunar2023_01_name: 'Год Кролика',
    decoration_wall_lunar2023_02_name: 'Красные фонари',
    decoration_wall_lunar2023_03_name: 'Кроличий узор',
    decoration_wall_african01_name: 'Стена с узорами боголанфини',
    decoration_wall_valentine04_name: 'Романтический шоколад',
    decoration_wall_valentine05_name: 'Розовый сад',
    decoration_wall_ruins01_name: 'Узор циклопа',
    decoration_wall_maple01_name: 'Сочащийся сироп',
    decoration_wall_dino01_name: 'Окаменелость',
    decoration_wall_dino02_name: 'Узор юрского периода',
    decoration_wall_junimay01_name: 'Узор фей',
    decoration_wall_blossom02_name: 'Пейзаж в стиле ханами',
    decoration_wall_music01_name: 'Стена высоких нот',
    decoration_wall_music02_name: 'Музыкальная стена',
    decoration_wall_inuit01_name: 'Стена «Истинный север»',
    decoration_wall_arcade02_name: 'Левое смотровое окно',
    decoration_wall_arcade03_name: 'Правое смотровое окно',
    decoration_wall_arcade04_name: 'Безрамочное смотровое окно',
    decoration_wall_arcade05_name: 'Смотровое окно',
    decoration_wall_shopr03_name: 'Королевская стена',
    decoration_wall_volcano01_name: 'Рисунок сокрушкраба',
    decoration_wall_dream01_name: 'Мечты',
    decoration_wall_boar01_name: 'Охота на Бамхама',
    decoration_wall_boar02_name: 'Охота на кабана',
    decoration_wall_camping01_name: 'Дикий лес',
    decoration_floor_basicLayout_name: 'Обычная стена',
    decoration_floor_wood_name: 'Ясень',
    decoration_floor_nicewood_name: 'Твердая древесина',
    decoration_floor_roughstone_name: 'Грубый камень',
    decoration_floor_squarestone_name: 'Зеленая панель',
    decoration_floor_brick_name: 'Фиолетовая панель',
    decoration_floor_dualstone_name: 'Узорчатый зеленый',
    decoration_floor_finebrick_name: 'Узорчатый голубой',
    decoration_floor_dualbrick_name: 'Коричневая клетка',
    decoration_floor_multibrick_name: 'Розовая клетка',
    decoration_floor_floor11_name: 'Серая клетка',
    decoration_floor_floor12_name: 'Светлый цветочный',
    decoration_floor_floor13_name: 'Темный цветочный',
    decoration_floor_floor14_name: 'Синий',
    decoration_floor_floor15_name: 'Красный',
    decoration_floor_floor16_name: 'Розовый',
    decoration_floor_floor17_name: 'Голубой пузырчатый',
    decoration_floor_floor18_name: 'Красный пузырчатый',
    decoration_floor_floor19_name: 'Красный ромбовидный',
    decoration_floor_floor20_name: 'Зеленый ромбовидный',
    decoration_floor_halloween01_name: 'Закатная кошка',
    decoration_floor_halloween02_name: 'Темный полумесяц',
    decoration_floor_halloween03_name: 'Рассветная кошка',
    decoration_floor_halloween04_name: 'Оранжевая луна',
    decoration_floor_halloween05_name: 'Закатная тыква',
    decoration_floor_halloween06_name: 'Жвачная плитка',
    decoration_floor_halloween07_name: 'Рассветная тыква',
    decoration_floor_halloween08_name: 'Конфетная плитка',
    decoration_floor_halloween09_name: 'Скрипучие половицы',
    decoration_floor_halloween010_name: 'Золотая тыквомышь',
    decoration_floor_halloween011_name: 'Синяя кошка',
    decoration_floor_halloween012_name: 'Синие луны',
    decoration_floor_halloween013_name: 'Зеленая кошка',
    decoration_floor_halloween014_name: 'Зеленые луны',
    decoration_floor_halloween015_name: 'Желтая кошка',
    decoration_floor_halloween016_name: 'Желтые луны',
    decoration_floor_thanksgiving01_name: 'Осенний ромбовидный',
    decoration_floor_thanksgiving02_name: 'Клетка в сепии',
    decoration_floor_thanksgiving03_name: 'Маленький желудь',
    decoration_floor_thanksgiving04_name: 'Большой желудь',
    decoration_floor_christmas01_name: 'Снежинка',
    decoration_floor_christmas02_name: 'Подарочная упаковка',
    decoration_floor_christmas03_name: 'Венок',
    decoration_floor_lunar01_name: 'Китайская плитка',
    decoration_floor_lunar02_name: 'Красная мышь удачи',
    decoration_floor_lunar03_name: 'Зеленая мышь удачи',
    decoration_floor_lunar04_name: 'Лиловая мышь удачи',
    decoration_floor_lunar05_name: 'Фиолетовая мышь удачи',
    decoration_floor_lunar06_name: 'Синяя мышь удачи',
    decoration_floor_valentine01_name: 'Бронзовое сердце',
    decoration_floor_valentine02_name: 'Бронзовая табличка',
    decoration_floor_stpatrick01_name: 'Плитка с клевером',
    decoration_floor_stpatrick02_name: 'Плитка с золотым бантом',
    decoration_floor_stpatrick03_name: 'Клеверное поле',
    decoration_floor_easter01_name: 'Бирюзовая пастель',
    decoration_floor_easter02_name: 'Сиреневая пастель',
    decoration_floor_easter03_name: 'Желтая пастель',
    decoration_floor_easter04_name: 'Пастельный пол',
    decoration_floor_polonia_name: 'Плитка Девяти морей',
    decoration_floor_cincodemayo01_name: 'Голубая плитка',
    decoration_floor_cincodemayo02_name: 'Бордовая плитка',
    decoration_floor_cincodemayo03_name: 'Дюны',
    decoration_floor_summer01_name: 'Пляж',
    decoration_floor_victorian01_name: 'Элегантные ромбы',
    decoration_floor_wallace_name: 'Пол кузницы',
    decoration_floor_4thofjuly01_name: 'Серебряная звезда (красный)',
    decoration_floor_4thofjuly02_name: 'Серебряная звезда (синий)',
    decoration_floor_4thofjuly03_name: 'Серебряная звезда (белый)',
    decoration_floor_4thofjuly04_name: 'Серебряная рамка (красный)',
    decoration_floor_4thofjuly05_name: 'Серебряная рамка (синий)',
    decoration_floor_4thofjuly06_name: 'Серебряная рамка (белый)',
    decoration_floor_4thofjuly07_name: 'Золотая звезда (красный)',
    decoration_floor_4thofjuly08_name: 'Золотая звезда (синий)',
    decoration_floor_4thofjuly09_name: 'Золотая звезда (белый)',
    decoration_floor_4thofjuly010_name: 'Золотая рамка (красный)',
    decoration_floor_4thofjuly011_name: 'Золотая рамка (синий)',
    decoration_floor_4thofjuly012_name: 'Золотая рамка (белый)',
    decoration_floor_herbalist01_name: 'Луг Аллана',
    decoration_floor_augustus2020_01_name: 'Золотая плитка',
    decoration_floor_arabiannight01_name: 'Узор султана',
    decoration_floor_arabiannight02_name: 'Плитка «Пустынная роза»',
    decoration_floor_arabiannight03_name: 'Плитка «Пустынное небо»',
    decoration_floor_school01_name: 'Плитка академии',
    decoration_floor_autumn01_name: 'Булыжник',
    decoration_floor_autumn02_name: 'Золотой камень',
    decoration_floor_oktoberfest01_name: 'Плитка с народным рисунком',
    decoration_floor_wizard01_name: 'Небесная плитка',
    decoration_floor_sia01_name: 'Драгоценный след',
    decoration_floor_leaves01_name: 'Разбросанные листья',
    decoration_floor_leaves02_name: 'Комплект листьев',
    decoration_floor_leaves03_name: 'Поздний урожай',
    decoration_floor_leaves04_name: 'Натюрморт',
    decoration_floor_thanksgiving05_name: 'Золотое перо',
    decoration_floor_winter01_name: 'Свежий снегопад',
    decoration_floor_christmas04_name: 'Красная омела',
    decoration_floor_christmas05_name: 'Зеленая омела',
    decoration_floor_christmas06_name: 'Праздничный дух',
    decoration_floor_sundragon01_name: 'Плитка Солдры',
    decoration_floor_moondragon01_name: 'Плитка Лундры',
    decoration_floor_vip01_name: 'Ромбы Фортуны',
    decoration_floor_india01_name: 'Золотой мрамор',
    decoration_floor_couple01_name: 'Деревянная роза',
    decoration_floor_lunar2021_01_name: 'Год Быка',
    decoration_floor_victorianspring01_name: 'Изящная весенняя плитка',
    decoration_floor_earthday01_name: 'Плитка Геи',
    decoration_floor_cincodemayo04_name: 'Пименто «Синко»',
    decoration_floor_cincodemayo05_name: 'Пименто «Синко» (мягкий)',
    decoration_floor_cincodemayo06_name: 'Пол «Синко»',
    decoration_floor_birds01_name: 'Щебечущие следы',
    decoration_floor_bee01_name: 'Соты',
    decoration_floor_circus01_name: 'Цирковая арена',
    decoration_floor_4thofjuly013_name: 'Премиальная звезда',
    decoration_floor_troblin01_name: 'Кустовой подлесок',
    decoration_floor_yeti01_name: 'Скользкий лед',
    decoration_floor_cat01_name: 'Отпечаток кошачьей лапы',
    decoration_floor_dog01_name: 'Отпечаток собачьей лапы',
    decoration_floor_candy01_name: 'Вафельный пол',
    decoration_floor_moon01_name: 'Плитка «Лунное прикосновение»',
    decoration_floor_hispanic01_name: 'Плитка Сальтильо',
    decoration_floor_halloween017_name: 'Призрачный пол',
    decoration_floor_autumn03_name: 'Листовой подлесок',
    decoration_floor_atlab_floor01_name: 'Пол Хозяина Огня',
    decoration_floor_atlab_floor02_name: 'Полы Ба Синг Се',
    decoration_floor_christmas07_name: 'Зимняя хижина',
    decoration_floor_winter02_name: 'Заснеженный ковер',
    decoration_floor_newyear01_name: 'Новогодний ковер',
    decoration_floor_frog01_name: 'Болотные кувшинки',
    decoration_floor_lunar2022_01_name: 'Год Тигра',
    decoration_floor_valentine03_name: 'Романтический узор',
    decoration_floor_aegir01_name: 'Плитка Эгира',
    decoration_floor_wiccan01_name: 'Викканские луга',
    decoration_floor_blossom01_name: 'Коврик татами',
    decoration_floor_easter05_name: 'Морковь с яйцами',
    decoration_floor_lcog01_name: 'Роскошный пол',
    decoration_floor_lcog02_name: 'Люксовый пол',
    decoration_floor_cincodemayo07_name: 'Плитка «Пуэбла»',
    decoration_floor_hemmadon01_name: 'Плитка духовных уз',
    decoration_floor_desert01_name: 'Песчаный край',
    decoration_floor_western01_name: 'Пыльные земли',
    decoration_floor_shopr01_name: 'Треугольная плитка',
    decoration_floor_airship01_name: 'Драконье небо',
    decoration_floor_airship02_name: 'Чистое небо',
    decoration_floor_beach01_name: 'Песок с ракушками',
    decoration_floor_anubis01_name: 'Плитка гробницы',
    decoration_floor_fruit01_name: 'Персиковый пол',
    decoration_floor_autumn04_name: 'Обрезной клен',
    decoration_floor_arcade01_name: 'Ретро-ковер',
    decoration_floor_musketeer01_name: 'Величественный пол',
    decoration_floor_halloween019_name: 'Ведьмино дерево',
    decoration_floor_halloween018_name: 'Ведьмино дерево (пентаграмма)',
    decoration_floor_castle01_name: 'Плитка тирана',
    decoration_floor_thanksgiving06_name: 'Вышитый иглами дикобраза ковер',
    decoration_floor_ice01_name: 'Кристалл Ледяной королевы',
    decoration_floor_christmas08_name: 'Мятный пол',
    decoration_floor_newyear02_name: 'Пол в стиле ар-деко',
    decoration_floor_lunar2023_01_name: 'Китайский Новый год',
    decoration_floor_lunar2023_02_name: 'Кроличья плитка',
    decoration_floor_african01_name: 'Пол с узорами боголанфини',
    decoration_floor_valentine04_name: 'Плитка-сердечки',
    decoration_floor_valentine05_name: 'Рассыпанные розы',
    decoration_floor_ruins01_name: 'Кирпичи Кроноса',
    decoration_floor_maple01_name: 'Липкий сироп',
    decoration_floor_dino01_name: 'Место раскопок',
    decoration_floor_junimay01_name: 'Луга фей',
    decoration_floor_arcade02_name: 'Пол «Игровой зал»',
    decoration_floor_inuit01_name: 'Пол «Истинный север»',
    decoration_floor_music01_name: 'Клавиши фортепиано',
    decoration_floor_music02_name: 'Пол арпеджио',
    decoration_floor_blossom02_name: 'Песок сада дзен',
    decoration_floor_shopr02_name: 'Королевский пол',
    decoration_floor_volcano01_name: 'Пол — это лава',
    decoration_floor_dream01_name: 'Сумеречная плитка',
    decoration_floor_boar01_name: 'Кожаные ремни',
    decoration_beam_beam01_defaultwood_name: 'Обычный',
    decoration_beam_beam02_classicwood_name: 'Модное дерево',
    decoration_beam_beam03_roman_name: 'Имперский',
    decoration_beam_beam04_egyptian_name: 'Гробница фараона',
    decoration_beam_beam05_victorian_name: 'Викторианский',
    decoration_beam_beam06_moroccan_name: 'Витиеватый',
    decoration_beam_beam07_asian_name: 'Восточный',
    decoration_beam_beam08_lcog_name: 'Потерянный город золота',
    counter_shopr_name: 'Прилавок титана',
    chessqueen_black_name: 'Черный ферзь',
    chessbishop_black_name: 'Черный слон',
    chesspawn_black_name: 'Черная пешка',
    chessking_white_name: 'Белый король',
    chessknight_white_name: 'Белый конь',
    chessrook_white_name: 'Белая ладья',
    chessrug02_white_name: 'Белое шахматное поле',
    clocktower_name: 'Крошечный Бен',
    victorianbed_name: 'Пышная кровать',
    tailorloom_name: 'Первый ткацкий станок Джулии',
    wallaceanvil_name: 'Любимая наковальня Уоллеса',
    curvebed_name: 'Изысканная кровать',
    fleurdelys_name: 'Флер-де-Лис',
    smallfleurdelys_name: 'Малыш де-Лис',
    decoration_floor_desc:
      'Напольный декор, когда-то найденный в сезонном наборе.',
    decoration_wall_desc:
      'Стенной декор, когда-то найденный в сезонном наборе.',
    decoration_outdoor_desc:
      'Уличный декор, когда-то найденный в сезонном наборе.',
    decoration_indoor_desc:
      'Декор для лавки, когда-то найденный в сезонном наборе.',
    decoration_floordeco_desc: 'Ковер, когда-то найденный в сезонном наборе.',
    decoration_walldeco_desc:
      'Стенной декор, когда-то найденный в сезонном наборе.',
    decoration_beams_desc:
      'Каркас лавки, когда-то найденный в сезонном наборе.',
    normal_pet_food_name: 'Корм',
    superior_pet_food_name: 'Превосходный корм',
    premium_pet_food_name: 'Премиальный корм',
    pet_family_cat: 'Кошка',
    pet_family_dog: 'Собака',
    pet_family_unicorn: 'Единорог',
    pet_family_chicken: 'Цыпленок',
    pet_family_hamster: 'Хомяк',
    pet_family_frog: 'Лягушка',
    pet_family_ox: 'Бык',
    pet_family_atlab_momo: 'Момо',
    pet_family_tiger: 'Тигр',
    pet_family_bunny: 'Кролик',
    pet_family_desc: 'Питомец, когда-то найденный в предыдущем наборе!',
    pet_custom_skin_cat01_name: 'Черная',
    pet_custom_skin_cat02_name: 'Полосатая',
    pet_custom_skin_cat03_name: 'Черно-белая',
    pet_custom_skin_cat04_name: 'Тигр',
    pet_custom_skin_cat05_name: 'Сиамская',
    pet_custom_skin_cat06_name: 'Уголек',
    pet_custom_skin_cat07_name: 'Шура',
    pet_custom_skin_cat08_name: 'Эспер',
    pet_custom_skin_cat09_name: 'Черепаховая',
    pet_custom_skin_cat10_name: 'Снежок',
    pet_custom_skin_cat11_name: 'Бесовка',
    pet_custom_skin_dog01_name: 'Веймаранер',
    pet_custom_skin_dog02_name: 'Золотистый ретривер',
    pet_custom_skin_dog03_name: 'Бигль',
    pet_custom_skin_dog04_name: 'Бернская овчарка',
    pet_custom_skin_dog05_name: 'Далматин',
    pet_custom_skin_dog06_name: 'Гроули',
    pet_custom_skin_dog07_name: 'Лабрадор',
    pet_custom_skin_dog08_name: 'Пучи-Блю',
    pet_custom_skin_dog09_name: 'Старина',
    pet_custom_skin_dog10_name: 'Меченый',
    pet_custom_skin_dog11_name: 'Звездочет',
    pet_custom_skin_chicken01_name: 'Леггорн',
    pet_custom_skin_chicken02_name: 'Джерси',
    pet_custom_skin_chicken03_name: 'Орпингтон',
    pet_custom_skin_chicken04_name: 'Пепельная',
    pet_custom_skin_chicken05_name: 'Рейнгольд',
    pet_custom_skin_chicken06_name: 'Золотой гусь',
    pet_custom_skin_chicken07_name: 'Королевская',
    pet_custom_skin_chicken08_name: 'Биоопасность',
    pet_custom_skin_chicken09_name: 'Плимутрок',
    pet_custom_skin_chicken10_name: 'Несушка',
    pet_custom_skin_chicken11_name: 'Курица «Синко»',
    pet_custom_skin_hamster01_name: 'Ричард',
    pet_custom_skin_hamster02_name: 'Дасти',
    pet_custom_skin_hamster03_name: 'Пельмешек',
    pet_custom_skin_hamster04_name: 'Бэтхом',
    pet_custom_skin_hamster05_name: 'Расти',
    pet_custom_skin_hamster06_name: 'Пушистик',
    pet_custom_skin_hamster07_name: 'Яблочко',
    pet_custom_skin_hamster08_name: 'Шиворот-Навыворот',
    pet_custom_skin_hamster09_name: 'Звездная Пыль',
    pet_custom_skin_hamster10_name: 'Маленький лучадор',
    pet_custom_skin_unicorn01_name: 'Леденец',
    pet_custom_skin_unicorn02_name: 'Сладкая Вата',
    pet_custom_skin_unicorn03_name: 'Лакрица',
    pet_custom_skin_unicorn04_name: 'Меренга',
    pet_custom_skin_unicorn05_name: 'Шербет',
    pet_custom_skin_unicorn06_name: 'Пинки',
    pet_custom_skin_unicorn07_name: 'Флаффи-Шай',
    pet_custom_skin_unicorn08_name: 'Комета',
    pet_custom_skin_unicorn09_name: 'Кекс',
    pet_custom_skin_unicorn10_name: 'Бриллиантовый жеребец',
    pet_custom_skin_frog01_name: 'Классическая',
    pet_custom_skin_frog02_name: 'Бирюзовая',
    pet_custom_skin_frog03_name: 'Древесная лягушка',
    pet_custom_skin_frog04_name: 'Тигровая лягушка',
    pet_custom_skin_frog05_name: 'Пятипалый свистун',
    pet_custom_skin_frog06_name: 'Томат',
    pet_custom_skin_frog07_name: 'Отравленный дротик',
    pet_custom_skin_frog08_name: 'Лотос',
    pet_custom_skin_frog09_name: 'Золотой Принц',
    pet_custom_skin_frog10_name: 'Мистер Квак',
    pet_custom_skin_ox01_name: 'Хайленд',
    pet_custom_skin_ox02_name: 'Ангус',
    pet_custom_skin_ox03_name: 'Герефорд',
    pet_custom_skin_ox04_name: 'Гну',
    pet_custom_skin_ox05_name: 'Голштин',
    pet_custom_skin_ox06_name: 'Метель',
    pet_custom_skin_ox07_name: 'Стелла',
    pet_custom_skin_ox08_name: 'Дейзи',
    pet_custom_skin_ox09_name: 'Луна',
    pet_custom_skin_ox10_name: 'Брызга',
    pet_custom_skin_ox11_name: 'Блонди',
    pet_custom_skin_ox12_name: 'Огонек',
    pet_custom_skin_ox13_name: 'Аппа',
    pet_custom_skin_tiger01_name: 'Классический',
    pet_custom_skin_tiger02_name: 'Белый тигр',
    pet_custom_skin_tiger03_name: 'Саванна',
    pet_custom_skin_tiger04_name: 'Сиреневый',
    pet_custom_skin_tiger05_name: 'Ядовитый',
    pet_custom_skin_tiger06_name: 'Сёдзё',
    pet_custom_skin_tiger07_name: 'Вулканический',
    pet_custom_skin_tiger08_name: 'Астральный',
    pet_custom_skin_tiger09_name: 'Ветроход',
    pet_custom_skin_tiger10_name: 'Бенгальский',
    pet_custom_skin_tiger11_name: 'Новогодний',
    pet_custom_skin_tiger12_name: 'Живая статуя',
    pet_custom_skin_bunny01_name: 'Белый',
    pet_custom_skin_bunny02_name: 'Черный',
    pet_custom_skin_bunny03_name: 'Пятнистый',
    pet_custom_skin_bunny04_name: 'Шоколадный',
    pet_custom_skin_bunny05_name: 'Багз',
    pet_custom_skin_bunny06_name: 'Садовый',
    pet_custom_skin_bunny07_name: 'Коричный',
    pet_custom_skin_bunny08_name: 'Пепельный',
    pet_custom_skin_bunny09_name: 'Китайский',
    pet_custom_skin_bunny10_name: 'Пасхальный',
    pet_custom_skin_bunny11_name: 'Золотой',
    pet_custom_skin_bunny12_name: 'Страна чудес',
    pet_custom_skin_atlab_momo01_name: 'Момо',
    furniture_type_pethouse: 'Жилище питомца',
    catbed_name: 'Кошачья постель',
    doghouse_name: 'Собачья будка',
    unicornroundpen_name: 'Загон для единорога',
    hamsterwheel_name: 'Колесо для хомяка',
    chickencoop_name: 'Курятник',
    frogpond_name: 'Лягушачий пруд',
    oxroundpen_name: 'Пастбище быков',
    atlab_momohouse_name: 'Дом Момо',
    tigerhouse_name: 'Тигриное логово',
    bunnyhouse_name: 'Кроличий домик',
    pet_custom_itemHead_custom_hat_shopkeeper_p_name: 'Шляпа купца',
    pet_custom_itemHead_custom_hat_bucket_p_name: 'Крохотное ведерко',
    pet_custom_itemHead_custom_eyewear_aviatorglasses_p_name: 'Крутые очки',
    pet_custom_itemHead_custom_eyewear_stpatrickglasses_p_name: 'Очки удачи',
    pet_custom_itemHead_custom_hat_farmer_p_name: 'Шляпа фермера',
    pet_custom_itemHead_custom_eyewear_sunglasses_p_name: 'Солнечные очки',
    pet_custom_itemHead_custom_eyewear_valentine_p_name: 'Романтические очки',
    pet_custom_itemHead_custom_hat_laurels_p_name: 'Король джунглей',
    pet_custom_itemHead_custom_hat_christmas_p_name: 'Помощник Санты',
    pet_custom_itemHead_custom_hat_bow_p_name: 'Милый бант',
    pet_custom_itemHead_custom_hat_flower_p_name: 'Тропическая красавица',
    pet_custom_itemHead_custom_eyewear_monocles_p_name: 'Дорогой монокль',
    pet_custom_itemHead_custom_hat_halo_p_name: 'Нимб',
    pet_custom_itemHead_custom_hat_king_p_name: 'Корона хорошего мальчика',
    height: 'Рост',
    body_type: 'Тип',
    complexion: 'Сложение',
    hair_style: 'Прическа',
    hat_toggle: 'Шапка',
    hair_color: 'Цвет волос',
    facial_hair: 'Черты лица',
    mustache: 'Усы',
    beard: 'Борода',
    expression: 'Выражение лица',
    eye_color: 'Цвет глаз',
    attire_color: 'Одежда',
    top: 'Верх',
    bottom: 'Низ',
    hat: 'Шапка',
    gloves: 'Перчатки',
    boots: 'Обувь',
    eyewear: 'Предметы для лица',
    handheld: 'Аксессуары',
    customization_type_itembody: 'Верхняя часть тела',
    customization_type_itempants: 'Нижняя часть тела',
    customization_type_itemhead: 'Головные уборы',
    customization_type_itemhands: 'Перчатки',
    customization_type_itemfeet: 'Обувь',
    customization_type_itemface: 'Предмет для лица',
    customization_type_itemweapon: 'Предмет для рук',
    customization_type_haircut: 'Стрижка',
    customization_type_mustache: 'Усы',
    customization_type_beard: 'Борода',
    color: 'Цвет',
    avatareditor_style_tab: 'Стиль',
    avatareditor_color_tab: 'Цвет',
    avatareditor_color1_tab: 'Цвет 1',
    avatareditor_color2_tab: 'Цвет 2',
    unlock_customization_title_format: 'Открыть: {0}',
    customization_haircut_bald: 'Лысина',
    customization_haircut_Male01_Hair01: 'Героическая',
    customization_haircut_Male01_Hair02: 'Развевающиеся на ветру',
    customization_haircut_Male01_Hair03: 'Гребень',
    customization_haircut_Female01_Hair01: 'Непослушные',
    customization_haircut_Female01_Hair02: 'Пучок',
    customization_haircut_Female01_Hair03: 'Пикабу',
    customization_haircut_Female01_Hair04: 'Короткие косы',
    customization_haircut_Male01_Hair04: 'Зачес',
    customization_haircut_Male01_Hair05: 'Лощеная',
    customization_haircut_Female01_Hair05: 'Перья',
    customization_haircut_Female01_Hair06: 'Каре',
    customization_haircut_Male01_Hair06: 'Горшок',
    customization_haircut_Pompadour: 'Помпадур',
    customization_haircut_Loosefro: 'Свободное афро',
    customization_haircut_Ponytail: 'Конский хвост',
    customization_haircut_Asymmetricbob: 'Асимметричное каре',
    customization_haircut_Princesswaves: 'Локоны принцессы',
    customization_haircut_summonerhair: 'Прическа призывателя',
    customization_haircut_dreadlocks: 'Дреды',
    customization_haircut_twintail: 'Два хвостика',
    customization_haircut_classicfringe: 'Классическая чёлка',
    customization_haircut_frohawk: 'Афро-ирокез',
    customization_haircut_boxbraidhalfup: 'Расты',
    customization_beard_no_beard: 'Без бороды',
    customization_beard_male01_Beard01: 'Ширма',
    customization_beard_male01_Beard02: 'Эспаньолка',
    customization_beard_male01_Beard03: 'Почтенная',
    customization_beard_male01_Beard04: 'Заполнено',
    customization_beard_male01_Beard05: 'Козлиная бородка',
    customization_beard_santabeard: 'Борода Санты',
    customization_beard_tannerbeard: 'Борода Тамаса',
    customization_mustache_no_mustache: 'Без усов',
    customization_mustache_male01_Mustache01: 'Стильные',
    customization_mustache_male01_Mustache02: 'Профессорские',
    customization_mustache_male01_Mustache03: 'Фу Манчу',
    customization_mustache_movember_01: 'Папины',
    customization_mustache_movember_02: 'Жиденькие',
    customization_mustache_movember_03: 'Шеврон',
    customization_mustache_movember_04: 'Закрученные вверх',
    customization_mustache_movember_05: 'Злодейские',
    customization_mustache_movember_06: 'Щеточкой',
    customization_mustache_santamustache: 'Усы Санты',
    customization_mustache_tannermustache: 'Усы Тамаса',
    no_hat: 'Без шапки',
    shopkeeper_hat: 'Шляпа купца',
    customization_itemhead_custom_hat_bunnyears: 'Кроличьи уши',
    customization_itemhead_custom_hat_merchant: 'Великий торговец',
    customization_itemhead_custom_hat_dundee: 'Данди',
    customization_itemhead_custom_hat_farmer: 'Шапка водопроводчика',
    customization_itemhead_custom_hat_icecream: 'Мороженое',
    customization_itemhead_custom_hat_bandana: 'Бандана',
    customization_itemhead_custom_hat_halo: 'Нимб',
    customization_itemhead_custom_hat_pirate: 'Шляпа корсара',
    customization_itemhead_custom_hat_wings: 'Крылья ангела',
    customization_itemhead_custom_hat_horns: 'Рога демона',
    customization_itemhead_custom_hat_cloud: 'Дождливый день',
    customization_itemhead_custom_hat_engineer: 'Шляпа Роксаны',
    customization_itemhead_custom_hat_bucket: 'Большое ведерко',
    customization_itemhead_custom_hat011_freedom: 'Шапка Сэма',
    customization_itemhead_custom_hat_laurels: 'Лавры императора',
    customization_itemhead_custom_hat_graduation: 'Шапка выпускника',
    customization_itemhead_custom_hat_oktoberfest: 'Шлаппе',
    customization_itemhead_custom_hat_halloween: 'Разумная шляпа',
    customization_itemhead_custom_hat_pumpkincat: 'Кошка-фонарь',
    customization_itemhead_custom_hat_pumpkincute: 'Милый фонарь',
    customization_itemhead_custom_hat_pumpkinevil: 'Злой фонарь',
    customization_itemhead_custom_hat_pumpkinwink: 'Подмигивающий фонарь',
    customization_itemhead_custom_hat_pumpkin: 'Неотесанная тыква',
    customization_itemhead_custom_hat_yamibandana: 'Головная повязка ниндзя',
    customization_itemhead_custom_hat_king: 'Роскошная королевская корона',
    customization_itemhead_custom_hat_dogears: 'Собачьи уши',
    customization_itemhead_custom_hat_catears: 'Кошачьи уши',
    customization_itemhead_custom_hat_christmas: 'Шапка Санты',
    customization_itemhead_custom_hat_christmaself: 'Шапка эльфа',
    customization_itemhead_custom_hat_snowman: 'Голова снеговика',
    customization_itemhead_custom_hat_soldier: 'Голова Щелкунчика',
    customization_itemhead_custom_hat_lunar: 'Китайская шляпа',
    customization_itemhead_custom_hat_valentine: 'Романтический берет',
    customization_itemhead_custom_hat_stpatrick: 'Цилиндр лепрекона',
    customization_itemhead_custom_hat_stpatrickberet: 'Шапка святого Патрика',
    customization_itemhead_custom_hat_flowercrown: 'Цветочный венок',
    customization_itemhead_custom_hat_tanner: 'Шапочка Тамаса',
    customization_itemhead_custom_hat_bunnycap: 'Кроличья шапка',
    customization_itemhead_custom_hat_rudobearhat: 'Капюшон старого медведя',
    customization_itemhead_custom_hat_mariachi: 'Шляпа в стиле мариачи',
    customization_itemhead_custom_hat_poloniafeatherhat: 'Шляпа Девяти морей',
    customization_itemhead_custom_hat_freedomcoiffe: 'Капюшон свободы',
    customization_itemhead_custom_hat_sylvancoiffe: 'Лесной головной убор',
    customization_itemhead_custom_hat_sultanhat: 'Тюрбан султана',
    customization_itemhead_custom_hat_fallhat: 'Осенняя корона',
    customization_itemhead_custom_hat_oktoberhat: 'Альпийская шляпа',
    customization_itemhead_custom_hat_batwings: 'Крылья летучей мыши',
    customization_itemhead_custom_hat_thanksgivinghat:
      'Чепчик ко Дню благодарения',
    customization_itemhead_custom_hat_siacirclet: 'Обруч Сиа',
    customization_itemhead_custom_hat_sombrero: 'Сомбреро',
    customization_itemhead_custom_hat_victorian: 'Элегантный цилиндр',
    customization_itemhead_custom_hat_tailorhat: 'Панамка Джулии',
    customization_itemhead_custom_hat_nutcracker: 'Шапка игрушечного солдатика',
    customization_itemhead_custom_hat_christmashat: 'Капюшон помощника Санты',
    customization_itemhead_custom_hat_reindeer: 'Оленьи рога',
    customization_itemhead_custom_hat_dragonmask: 'Маска дракона',
    customization_itemhead_custom_hat_bullhorn: 'Рога быка',
    customization_itemhead_custom_hat_mundracrown:
      'Корона Лундры с лунными камнями',
    customization_itemhead_custom_hat_bunnyonesie: 'Кроличий капюшон',
    customization_itemhead_custom_hat_flowerhat: 'Цветущий чепчик',
    customization_itemhead_custom_hat_partyhat: 'Клоунский колпак',
    customization_itemhead_custom_hat_beehood: 'Худи «Пчела-жужжалка»',
    customization_itemhead_custom_hat_grotto: 'Шапка с ушами йети',
    customization_itemhead_custom_hat_catonesie: 'Кошачий капюшон',
    customization_itemhead_custom_hat_dogonesie: 'Собачий капюшон',
    customization_itemhead_custom_hat_candycrown: 'Конфетная корона',
    customization_itemhead_custom_hat_spiderhat: 'Дружок-паучок',
    customization_itemhead_custom_hat_atlab_toph: 'Головная повязка Тоф',
    customization_itemhead_custom_hat_atlab_aang: 'Стрела Аанга',
    customization_itemhead_custom_hat_atlab_katarahair: 'Волосы Катары',
    customization_itemhead_custom_hat_atlab_zukohair: 'Волосы Зуко',
    customization_itemhead_custom_hat_snowhat: 'Шапка-ушанка',
    customization_itemhead_custom_hat_deeronesie: 'Олений капюшон',
    customization_itemhead_custom_hat_swamp: 'Шляпа жабы-ведьмы',
    customization_itemhead_custom_hat_tigerears: 'Тигриные уши',
    customization_itemhead_custom_hat_tiger: 'Тигриная толстовка',
    customization_itemhead_custom_hat_chickhat: 'Куриная шапка',
    customization_itemhead_custom_hat_wiccan: 'Викканская корона',
    customization_itemhead_custom_hat_hemma: 'Тиара Хеммы',
    customization_itemhead_custom_hat_mushroom: 'Шапка грибандита',
    customization_itemhead_custom_hat_western: 'Ковбойская шляпа',
    customization_itemhead_custom_hat_cakehat: 'Шляпа-торт',
    customization_itemhead_custom_hat_headscarf: 'Головной платок',
    customization_itemhead_custom_hat_anubis: 'Голова Анубиса',
    customization_itemhead_custom_hat_pineapple: 'Ананасовая голова',
    customization_itemhead_custom_hat_pumpkinhat: 'Тыквенная шляпка',
    customization_itemhead_custom_hat_plaguemask: 'Маска чумного доктора',
    customization_itemhead_custom_hat_cookingpot: 'Большой горшок',
    customization_itemhead_custom_hat_thanksgiving:
      'Головная повязка с вышивкой',
    customization_itemhead_custom_hat_unicorn: 'Тиара единорога',
    customization_itemhead_custom_hat_christmasgift: 'Сюрприз лавочника',
    customization_itemhead_custom_hat_newyearwig: 'Парик с банданой',
    customization_itemhead_custom_hat_lunarbeanie: 'Кроличья шапочка',
    customization_itemhead_custom_hat_valentinehat: 'Шляпа влюбленного принца',
    customization_itemhead_custom_hat_juniper: 'Шляпка Джунипер',
    customization_itemhead_custom_hat_dinoonesie: 'Динозавровый капюшон',
    customization_itemhead_custom_hat_arcadehelm: 'VR-шлем Craft Punk',
    customization_itemhead_custom_hat_spacehelm: 'Космический шлем',
    customization_itemhead_custom_hat_cspacehelm: 'Космический шлем',
    customization_itemhead_custom_hat_fishermanhat: 'Шляпа рыбака',
    no_tunic: 'Без туники',
    shopkeeper_tunic: 'Туника купца',
    customization_itembody_custom_top_apronrobe: 'Платье с фартуком',
    customization_itembody_custom_top_mariner: 'Рубаха моряка',
    customization_itembody_custom_top_victorian: 'Элегантный жилет',
    customization_itembody_custom_top_farmer: 'Наряд фермера',
    customization_itembody_custom_top_strawsuit: 'Соломенная рубаха',
    customization_itembody_custom_top_king: 'Восхитительное одеяние Рейнгольда',
    customization_itembody_custom_top_santa: 'Костюм Санты',
    customization_itembody_custom_top_christmasskirt: 'Костюм миссис Клаус',
    customization_itembody_custom_top_christmaself: 'Уродливый свитер',
    customization_itembody_custom_top_lunar: 'Китайская рубаха',
    customization_itembody_custom_top_stpatrickoutfit: 'Наряд святого Патрика',
    customization_itembody_custom_top_stpatrick: 'Куртка лепрекона',
    customization_itembody_custom_top_stpatrickskirt: 'Платье святого Патрика',
    customization_itembody_custom_top_easterchickvest: 'Цыплячий жилет',
    customization_itembody_custom_top_shoptitans: 'Рубаха Shop Titans',
    customization_itembody_custom_top_toga: 'Имперская тога',
    customization_itembody_custom_top_schooltop1: 'Форма академии (свободная)',
    customization_itembody_custom_top_schooltop2: 'Форма академии',
    customization_itembody_custom_top_schooltop3: 'Рубашка академии',
    customization_itembody_custom_top_fallcoat: 'Сезонный плащ',
    customization_itembody_custom_top_falljacket: 'Куртка из осенних листьев',
    customization_itembody_custom_top_oktobersuit: 'Ледерхозе',
    customization_itembody_custom_top_priestcloak: 'Шаль Фрейи',
    customization_itembody_custom_top_batcoat: 'Плащ безлунной ночи',
    customization_itembody_custom_top_mummyoutfit: 'Проклятие фараона',
    customization_itembody_custom_top_thanksgivingshirt: 'Изобильная рубаха',
    customization_itembody_custom_top_wintercoat: 'Теплая парка',
    customization_itembody_custom_top_nutcracker: 'Форма игрушечного солдатика',
    customization_itembody_custom_top_mariachi: 'Жилет в стиле мариачи',
    customization_itembody_custom_top_summerfloater: 'Солнечный круг',
    customization_itembody_custom_top_christmasdress: 'Елочное платье',
    customization_itembody_custom_top_mayaoutfit: 'Форма «Колеса Фортуны»',
    customization_itembody_custom_top_indiadayshirt: 'Лехенга-чоли',
    customization_itembody_custom_top_indiafrock: 'Шервани',
    customization_itembody_custom_top_coupletunic: 'Дикая туника',
    customization_itembody_custom_top_kilt: 'Милезское облачение',
    customization_itembody_custom_top_bunnyonesie: 'Кроличий комбинезон',
    customization_itembody_custom_top_springdress: 'Изящное весеннее платье',
    customization_itembody_custom_top_cincodress: 'Китайская поблана',
    customization_itembody_custom_top_birdcoat: 'Пальто из перьев',
    customization_itembody_custom_top_beeshirt: 'Костюм «Пчела-жужжалка»',
    customization_itembody_custom_top_troblinrags: 'Лохмотья гиблина',
    customization_itembody_custom_top_freedomsuit: 'Президентский наряд',
    customization_itembody_custom_top_hockeyjersey: 'Свитер Shop Titans',
    customization_itembody_custom_top_furcoat: 'Меховое пальто',
    customization_itembody_custom_top_grotto: 'Меховая пижама йети',
    customization_itembody_custom_top_catonesie: 'Кошачий комбинезон',
    customization_itembody_custom_top_dogonesie: 'Собачий комбинезон',
    customization_itembody_custom_top_candydress: 'Вкусное платье',
    customization_itembody_custom_top_moonkimono: 'Праздничное кимоно',
    customization_itembody_custom_top_atlab_katara: 'Наряд Катары',
    customization_itembody_custom_top_atlab_zuko: 'Доспех Зуко',
    customization_itembody_custom_top_atlab_toph: 'Наряд Тоф',
    customization_itembody_custom_top_atlab_aang: 'Наряд Аанга',
    customization_itembody_custom_top_deeronesie: 'Олений комбинезон',
    customization_itembody_custom_top_newyeardress: 'Платье-чарльстон',
    customization_itembody_custom_top_lunardress: 'Китайский новогодний наряд',
    customization_itembody_custom_top_valentineoutfit:
      'Смокинг родственной души',
    customization_itembody_custom_top_blossomshirt: 'Хаори',
    customization_itembody_custom_top_eastershirt: 'Восточная нарядная рубашка',
    customization_itembody_custom_top_wiccan: 'Викканское одеяние',
    customization_itembody_custom_top_aegir: 'Наряд ярла',
    customization_itembody_custom_top_cactusonesie: 'Кактусовый комбинезон',
    customization_itembody_custom_top_donovan: 'Наряд Донована',
    customization_itembody_custom_top_cincoshirt: 'Креповая рубашка с цветами',
    customization_itembody_custom_top_western: 'Ковбойский наряд',
    customization_itembody_custom_top_shoprshirt: 'Рубаха третьей годовщины',
    customization_itembody_custom_top_airship: 'Наряд капитана',
    customization_itembody_custom_top_hockeyjersey2: 'Квебекский свитер',
    customization_itembody_custom_top_beach: 'Гидрокостюм',
    customization_itembody_custom_top_anubis: 'Наряд Анубиса',
    customization_itembody_custom_top_bananashirt: 'Банановая рубашка',
    customization_itembody_custom_top_musketeer: 'Рубаха мушкетера',
    customization_itembody_custom_top_pumpkindress: 'Тыквенное платье',
    customization_itembody_custom_top_bakerapron: 'Фартук пекаря',
    customization_itembody_custom_top_christmascoat: 'Праздничное пальто',
    customization_itembody_custom_top_lunarbunnydress: 'Кроличье платье',
    customization_itembody_custom_top_newyearshirt: 'Новогодняя рубаха',
    customization_itembody_custom_top_african: 'Платье шука',
    customization_itembody_custom_top_valentinecoat: 'Наряд влюбленного принца',
    customization_itembody_custom_top_cyclops: 'Наряд императора',
    customization_itembody_custom_top_maple: 'Канадийская форма',
    customization_itembody_custom_top_dinoonesie: 'Динозавровый комбинезон',
    customization_itembody_custom_top_maylee: 'Наряд Мэйли',
    customization_itembody_custom_top_tanner: 'Фартук Тамаса',
    customization_itembody_custom_top_parka: 'Парка',
    customization_itembody_custom_top_spacesuit: 'Космический скафандр',
    customization_itembody_custom_top_prideshirt: 'Рубаха гордости',
    customization_itembody_custom_top_lifeguard: 'Жилет спасателя',
    customization_itembody_custom_top_shoprjacket: 'Куртка титана',
    customization_itembody_custom_top_campingvest: 'Рыболовный жилет',
    no_shoes: 'Босые ноги',
    customization_itemfeet_custom_boots_mariner: 'Сапоги моряка',
    customization_itemfeet_custom_boots_victorian: 'Элегантные сапоги',
    customization_itemfeet_custom_boots_king: 'Шикарные стильные ботинки',
    customization_itemfeet_custom_boots_christmas: 'Башмаки Санты',
    customization_itemfeet_custom_boots_christmaself: 'Башмаки эльфа',
    customization_itemfeet_custom_boots_lunar: 'Китайские башмаки',
    customization_itemfeet_custom_boots_stpatrick: 'Сапоги святого Патрика',
    customization_itemfeet_custom_boots_leprechaun: 'Башмаки лепрекона',
    customization_itemfeet_custom_boots_maryjane: 'Туфли святого Патрика',
    customization_itemfeet_custom_boots_romansandal: 'Имперские сандалии',
    customization_itemfeet_custom_boots_schoolboot: 'Обувь академии',
    customization_itemfeet_custom_boots_mariachi: 'Сапоги в стиле мариачи',
    customization_itemfeet_custom_boots_nutcracker:
      'Сапоги игрушечного солдатика',
    customization_itemfeet_custom_boots_coupleboots: 'Обувь кадьяка',
    customization_itemfeet_custom_boots_natureshoes: 'Салатные тапочки',
    customization_itemfeet_custom_boots_cincosandals: 'Уарачес «Синко»',
    customization_itemfeet_custom_boots_freedomboots: 'Сапоги патриота',
    customization_itemfeet_custom_boots_moonshoes: 'Гэта',
    customization_itemfeet_custom_boots_valentineshoes: 'Ангельские кроссовки',
    customization_itemfeet_custom_boots_thanksgiving: 'Мокасины',
    customization_itemfeet_custom_boots_bunnyslippers: 'Кроличьи тапочки',
    customization_itemfeet_custom_boots_african: 'Традиционные сандалии',
    customization_itemfeet_custom_boots_sneakers: 'Космические кроссовки',
    customization_itemfeet_custom_boots_mukluks: 'Муклуки',
    customization_itemfeet_custom_boots_boarslippers: 'Тапочки Бамхама',
    no_gloves: 'Без перчаток',
    customization_itemhands_custom_gloves_basic: 'Перчатки купца',
    customization_itemhands_custom_gloves_compass: 'Компас моряка',
    customization_itemhands_custom_gloves_victorian: 'Элегантные перчатки',
    customization_itemhands_custom_gloves_king: 'Блестящее кольцо короля',
    customization_itemhands_custom_gloves_christmas: 'Рукавицы Санты',
    customization_itemhands_custom_gloves_shoprclaws: 'Драгоценные когти',
    customization_itemgloves_custom_gloves_shoprclaws: 'Драгоценные когти',
    customization_itemhands_custom_gloves_crabmittens: 'Крабовые рукавицы',
    customization_itemgloves_custom_gloves_crabmittens: 'Крабовые рукавицы',
    no_pants: 'Неприлично',
    customization_itempants_custom_pants_victorian: 'Элегантные штаны',
    customization_itempants_custom_pants_christmaself: 'Штаны эльфа',
    customization_itempants_custom_pants_lunar: 'Китайские штаны',
    customization_itempants_custom_pants_stpatrickshorts: 'Шорты лепрекона',
    customization_itempants_custom_pants_stpatrickleggings: 'Лосины лепрекона',
    customization_itempants_custom_pants_schoolpants1: 'Юбка академии',
    customization_itempants_custom_pants_mariachi: 'Штаны в стиле мариачи',
    customization_itempants_custom_pants_schoolpants2: 'Штаны академии',
    customization_itempants_custom_pants_falljeans: 'Сезонные джинсы',
    customization_itempants_custom_pants_nutcracker:
      'Штаны игрушечного солдатика',
    no_face: 'Ничего',
    customization_itemface_custom_eyewear_eyepatch: 'Пиратская глазная повязка',
    customization_itemface_custom_eyewear_monocles: 'Элегантный монокль',
    customization_itemface_custom_eyewear_pixelshades: 'Пиксельные очки',
    customization_itemface_custom_eyewear_dognose: 'Собачий нос',
    customization_itemface_custom_eyewear_catnose: 'Кошачий нос',
    customization_itemface_custom_eyewear_valentine: 'Романтические очки',
    customization_itemface_custom_eyewear_stpatrickglasses: 'Клеверный вид',
    customization_itemface_custom_eyewear_aviatorglasses: 'Очки авиатора',
    customization_itemface_custom_eyewear_wintergoggles: 'Зимние очки',
    customization_itemface_custom_eyewear_birdmask: 'Совиная маска',
    customization_itemface_custom_eyewear_clown: 'Пищащий нос',
    customization_itemface_custom_eyewear_batglasses: 'Очки с летучими мышами',
    customization_itemface_custom_eyewear_aviator: 'Очки авиатора',
    customization_itemface_custom_eyewear_watergoggles: 'Очки для плавания',
    customization_itemface_custom_eyewear_cyclops: 'Глаз циклопа',
    customization_itemface_custom_eyewear_bjorneyepatch:
      'Глазная повязка Бьорна',
    no_weapon: 'С пустыми руками',
    customization_itemweapon_custom_handheld_broom: 'Корабельная швабра',
    customization_itemweapon_custom_handheld_cane: 'Трость джентльмена',
    customization_itemweapon_custom_handheld_pixelsword: 'Ретро-клинок',
    customization_itemweapon_custom_handheld_argonsword: 'Меч рыцаря',
    customization_itemweapon_custom_handheld_king:
      'Великолепный скипетр процветания',
    customization_itemweapon_custom_handheld_candycane: 'Карамельная трость',
    customization_itemweapon_custom_handheld_sparkler: 'Бенгальский огонь',
    customization_itemweapon_custom_handheld_academywand: 'Палочка Эвелин',
    customization_itemweapon_custom_handheld_flowerbouquet: 'Весенний букет',
    customization_itemweapon_custom_handheld_freedomtorch: 'Свет свободы',
    customization_itemweapon_custom_handheld_backscratcher: 'Спиночес Аллана',
    customization_itemweapon_custom_handheld_magiclamp: 'Волшебная лампа',
    customization_itemweapon_custom_handheld_schoolbag: 'Рюкзак академии',
    customization_itemweapon_custom_handheld_magnifyingglass:
      'Великолепная лупа',
    customization_itemweapon_custom_handheld_lilustaff: 'Посох Лилу',
    customization_itemweapon_custom_handheld_dragonunity: 'Равновесие Солдры',
    customization_itemweapon_custom_handheld_viptoken: 'Монета Фортуны',
    customization_itemweapon_custom_handheld_cincoguitar: 'Гитара',
    customization_itemweapon_custom_handheld_watergun: 'Пистолет вер. I',
    customization_itemweapon_custom_handheld_pocketwatch:
      'Элегантные карманные часы',
    customization_itemweapon_custom_handheld_wallacehammer:
      'Любимый молот Уоллеса',
    customization_itemweapon_custom_handheld_duster: 'Цветная метелка',
    customization_itemweapon_custom_handheld_horseshoe: 'Подкова удачи',
    customization_itemweapon_custom_handheld_fan: 'Изящный веер',
    customization_itemweapon_custom_handheld_troblinstick: 'Палка гиблина',
    customization_itemweapon_custom_handheld_atlab_staff:
      'Воздушный планер Аанга',
    customization_itemweapon_custom_handheld_snowball: 'Снежок',
    customization_itemweapon_custom_handheld_partypopper: 'Хлопушка',
    customization_itemweapon_custom_handheld_swamp: 'Ложка жабы-ведьмы',
    customization_itemweapon_custom_handheld_blossomfan: 'Утива',
    customization_itemweapon_custom_handheld_horn: 'Кубок из рога',
    customization_itemweapon_custom_handheld_maracas: 'Маракас',
    customization_itemweapon_custom_handheld_pickaxe: 'Старая добрая кирка',
    customization_itemweapon_custom_handheld_waterbag: 'Бурдюк для воды',
    customization_itemweapon_custom_handheld_hockeystick: 'Хоккейная клюшка',
    customization_itemweapon_custom_handheld_musketeer: 'Меч мушкетера',
    customization_itemweapon_custom_handheld_spatula: 'Отличная лопатка',
    customization_itemweapon_custom_handheld_icewand: 'Ледяной посох',
    customization_itemweapon_custom_handheld_trumpet: 'Труба',
    customization_itemweapon_custom_handheld_maplesyrup: 'Кленовый залп',
    customization_itemweapon_custom_handheld_lifefloat: 'Спасательный буй',
    customization_expression_expr_neutral: 'Нейтральное',
    customization_expression_expr_happy: 'Веселое',
    customization_expression_expr_angry: 'Свирепое',
    customization_eyecolor_grey: 'Серый',
    customization_eyecolor_oxleygreen: 'Зеленый',
    customization_eyecolor_brown: 'Карий',
    customization_eyecolor_darkbrown: 'Темно-карий',
    customization_eyecolor_bastille: 'Черный',
    customization_eyecolor_poloblue: 'Светло-голубой',
    customization_eyecolor_springgreen: 'Травянистый',
    customization_eyecolor_mariner: 'Синий',
    customization_eyecolor_red: 'Красный',
    customization_eyecolor_maroon: 'Темно-бордовый',
    customization_eyecolor_orange: 'Рыжий',
    customization_eyecolor_blazeorange: 'Огненно-оранжевый',
    customization_eyecolor_yellow: 'Желтый',
    customization_eyecolor_lightgreen: 'Светло-зеленый',
    customization_eyecolor_malachite: 'Малахитовый',
    customization_eyecolor_malibu: 'Малибу',
    customization_eyecolor_teal: 'Зелено-голубой',
    customization_eyecolor_violet: 'Фиолетовый',
    customization_eyecolor_purple: 'Пурпурный',
    customization_eyecolor_pink: 'Розовый',
    customization_eyecolor_blind: 'Слепой',
    customization_haircolor_blonde: 'Светлый',
    customization_haircolor_santabeard: 'Белоснежная',
    customization_haircolor_orange: 'Рыжий',
    customization_haircolor_darkbrown: 'Темно-каштановый',
    customization_haircolor_red: 'Красный',
    customization_haircolor_blackgrey: 'Черный с сединой',
    customization_haircolor_darkgrey: 'Темная седина',
    customization_haircolor_gold: 'Золотистый',
    customization_haircolor_brown: 'Каштановый',
    customization_haircolor_shakespeare: 'Шекспировский',
    customization_haircolor_blue: 'Синий',
    customization_haircolor_maroon: 'Темно-бордовый',
    customization_haircolor_white: 'Белый',
    customization_haircolor_lightgreen: 'Светло-зеленый',
    customization_haircolor_purple: 'Пурпурный',
    customization_haircolor_violet: 'Фиолетовый',
    customization_haircolor_aquamarine: 'Морская волна',
    customization_haircolor_teal: 'Зелено-голубой',
    customization_haircolor_malachite: 'Малахитовый',
    customization_haircolor_hitpink: 'Ярко-розовый',
    customization_haircolor_rose: 'Розовый',
    customization_haircolor_blazeorange: 'Огненно-оранжевый',
    customization_skincolor_caucasian1: 'Светлый',
    customization_skincolor_caucasian2: 'Белый',
    customization_skincolor_caucasian3: 'Персиковый',
    customization_skincolor_caucasian4: 'Кожа 4',
    customization_skincolor_dark1: 'Загорелый',
    customization_skincolor_dark2: 'Светло-коричневый',
    customization_skincolor_dark3: 'Коричневый',
    customization_skincolor_dark4: 'Темный',
    customization_skincolor_asian1: 'Бежевый',
    customization_skincolor_asian2: 'Оливковый',
    customization_skincolor_asian3: 'Золотистый',
    customization_skincolor_asian4: 'Кожа 12',
    customization_skincolor_extra1: 'Кожа 13',
    customization_skincolor_extra2: 'Кожа 14',
    customization_skincolor_extra3: 'Кожа 15',
    customization_skincolor_extra4: 'Кожа 16',
    customization_skincolor_elfskin: 'Эльф',
    customization_skincolor_drow: 'Дроу',
    customization_skincolor_orcskin: 'Орк',
    customization_skincolor_undead: 'Нежить',
    customization_skincolor_redskin: 'Ифрит',
    customization_skincolor_nightcrawler: 'Фея',
    customization_skincolor_iridescent: 'Переливающийся',
    customization_skincolor_pearl: 'Жемчужный',
    customization_skincolor_simpson: 'Весенняя зелень',
    customization_skincolor_genie: 'Джинн',
    customization_skincolor_pinkrose: 'Розовый',
    customization_skincolor_aquatic: 'Водяной',
    customization_skincolor_obsidian: 'Обсидиановый',
    customization_skincolor_dryad: 'Дриада',
    customization_shirtcolor_tan_shirtClr: 'Бронзовый',
    customization_shirtcolor_darkgrey_shirtClr: 'Темно-серый',
    customization_shirtcolor_beige_shirtClr: 'Бежевый',
    customization_shirtcolor_blackgrey_shirtClr: 'Серо-черный',
    customization_shirtcolor_red_shirtClr: 'Красный',
    customization_shirtcolor_orange_shirtClr: 'Оранжевый',
    customization_shirtcolor_deepgold_shirtClr: 'Темно-золотой',
    customization_shirtcolor_yellow_shirtClr: 'Желтый',
    customization_shirtcolor_lime_shirtClr: 'Лаймовый',
    customization_shirtcolor_darkgreen_shirtClr: 'Темно-зеленый',
    customization_shirtcolor_huntergreen_shirtClr: 'Охотничий зеленый',
    customization_shirtcolor_turquoise_shirtClr: 'Бирюзовый',
    customization_shirtcolor_blue_shirtClr: 'Голубой',
    customization_shirtcolor_darkblue_shirtClr: 'Синий',
    customization_shirtcolor_purple_shirtClr: 'Пурпурный',
    customization_shirtcolor_violet_shirtClr: 'Фиолетовый',
    customization_shirtcolor_salmon_shirtClr: 'Лососевый',
    customization_shirtcolor_pink_shirtClr: 'Розовый',
    customization_shirtcolor_brown_shirtClr: 'Коричневый',
    customization_shirtcolor_darkbrown_shirtClr: 'Темно-коричневый',
    customization_shirtcolor_grey_shirtClr: 'Серый',
    customization_shirtcolor_hotpink_shirtClr: 'Ярко-розовый',
    customization_shirtcolor_christmasgreen_shirtClr: 'Рождественский зеленый',
    customization_vestcolor_tan_vestClr: 'Бронзовый',
    customization_vestcolor_darkgrey_vestClr: 'Темно-серый',
    customization_vestcolor_beige_vestClr: 'Бежевый',
    customization_vestcolor_blackgrey_vestClr: 'Серо-черный',
    customization_vestcolor_red_vestClr: 'Красный',
    customization_vestcolor_orange_vestClr: 'Оранжевый',
    customization_vestcolor_deepgold_vestClr: 'Темно-золотой',
    customization_vestcolor_yellow_vestClr: 'Желтый',
    customization_vestcolor_lime_vestClr: 'Лаймовый',
    customization_vestcolor_darkgreen_vestClr: 'Темно-зеленый',
    customization_vestcolor_huntergreen_vestClr: 'Охотничий зеленый',
    customization_vestcolor_turquoise_vestClr: 'Бирюзовый',
    customization_vestcolor_blue_vestClr: 'Голубой',
    customization_vestcolor_darkblue_vestClr: 'Синий',
    customization_vestcolor_purple_vestClr: 'Пурпурный',
    customization_vestcolor_violet_vestClr: 'Фиолетовый',
    customization_vestcolor_salmon_vestClr: 'Лососевый',
    customization_vestcolor_pink_vestClr: 'Розовый',
    customization_vestcolor_brown_vestClr: 'Коричневый',
    customization_vestcolor_darkbrown_vestClr: 'Темно-коричневый',
    customization_vestcolor_grey_vestClr: 'Серый',
    customization_vestcolor_hotpink_vestClr: 'Ярко-розовый',
    customization_vestcolor_christmasgreen_vestClr: 'Рождественский зеленый',
    customization_hatcolor_tan_hatClr: 'Бронзовый',
    customization_hatcolor_darkgrey_hatClr: 'Темно-серый',
    customization_hatcolor_beige_hatClr: 'Бежевый',
    customization_hatcolor_blackgrey_hatClr: 'Серо-черный',
    customization_hatcolor_red_hatClr: 'Красный',
    customization_hatcolor_orange_hatClr: 'Оранжевый',
    customization_hatcolor_deepgold_hatClr: 'Темно-золотой',
    customization_hatcolor_yellow_hatClr: 'Желтый',
    customization_hatcolor_lime_hatClr: 'Лаймовый',
    customization_hatcolor_darkgreen_hatClr: 'Темно-зеленый',
    customization_hatcolor_huntergreen_hatClr: 'Охотничий зеленый',
    customization_hatcolor_turquoise_hatClr: 'Бирюзовый',
    customization_hatcolor_blue_hatClr: 'Голубой',
    customization_hatcolor_darkblue_hatClr: 'Синий',
    customization_hatcolor_purple_hatClr: 'Пурпурный',
    customization_hatcolor_violet_hatClr: 'Фиалковый',
    customization_hatcolor_salmon_hatClr: 'Оранжево-розовая',
    customization_hatcolor_pink_hatClr: 'Розовый',
    customization_hatcolor_brown_hatClr: 'Каштановый',
    customization_hatcolor_darkbrown_hatClr: 'Темно-каштановый',
    customization_hatcolor_grey_hatClr: 'Серый',
    customization_hatcolor_hotpink_hatClr: 'Ярко-розовый',
    customization_hatcolor_christmasgreen_hatClr: 'Рождественский зеленый',
    customization_pantscolor_tan_pantsClr: 'Бронзовые',
    customization_pantscolor_darkgrey_pantsClr: 'Темная седина',
    customization_pantscolor_beige_pantsClr: 'Бежевый',
    customization_pantscolor_blackgrey_pantsClr: 'Серо-черный',
    customization_pantscolor_red_pantsClr: 'Красный',
    customization_pantscolor_orange_pantsClr: 'Оранжевый',
    customization_pantscolor_deepgold_pantsClr: 'Темно-золотой',
    customization_pantscolor_yellow_pantsClr: 'Желтый',
    customization_pantscolor_lime_pantsClr: 'Лаймовый',
    customization_pantscolor_darkgreen_pantsClr: 'Темно-зеленый',
    customization_pantscolor_huntergreen_pantsClr: 'Охотничий зеленый',
    customization_pantscolor_turquoise_pantsClr: 'Бирюзовый',
    customization_pantscolor_blue_pantsClr: 'Голубой',
    customization_pantscolor_darkblue_pantsClr: 'Синий',
    customization_pantscolor_purple_pantsClr: 'Пурпурный',
    customization_pantscolor_violet_pantsClr: 'Фиолетовый',
    customization_pantscolor_salmon_pantsClr: 'Лососевый',
    customization_pantscolor_pink_pantsClr: 'Розовый',
    customization_pantscolor_brown_pantsClr: 'Коричневый',
    customization_pantscolor_darkbrown_pantsClr: 'Темно-коричневый',
    customization_pantscolor_grey_pantsClr: 'Серый',
    customization_pantscolor_hotpink_pantsClr: 'Ярко-розовый',
    customization_pantscolor_christmasgreen_pantsClr: 'Рождественский зеленый',
    customization_glovescolor_tan_glovesClr: 'Бронзовый',
    customization_glovescolor_darkgrey_glovesClr: 'Темно-серый',
    customization_glovescolor_beige_glovesClr: 'Бежевый',
    customization_glovescolor_blackgrey_glovesClr: 'Серо-черный',
    customization_glovescolor_red_glovesClr: 'Красный',
    customization_glovescolor_orange_glovesClr: 'Оранжевый',
    customization_glovescolor_deepgold_glovesClr: 'Темно-золотой',
    customization_glovescolor_yellow_glovesClr: 'Желтый',
    customization_glovescolor_lime_glovesClr: 'Лаймовый',
    customization_glovescolor_darkgreen_glovesClr: 'Темно-зеленый',
    customization_glovescolor_christmasgreen_glovesClr:
      'Рождественский зеленый',
    customization_glovescolor_huntergreen_glovesClr: 'Охотничий зеленый',
    customization_glovescolor_turquoise_glovesClr: 'Бирюзовый',
    customization_glovescolor_blue_glovesClr: 'Голубой',
    customization_glovescolor_darkblue_glovesClr: 'Синий',
    customization_glovescolor_purple_glovesClr: 'Пурпурный',
    customization_glovescolor_violet_glovesClr: 'Фиолетовый',
    customization_glovescolor_salmon_glovesClr: 'Лососевый',
    customization_glovescolor_pink_glovesClr: 'Розовый',
    customization_glovescolor_brown_glovesClr: 'Коричневый',
    customization_glovescolor_darkbrown_glovesClr: 'Темно-коричневый',
    customization_glovescolor_grey_glovesClr: 'Серый',
    customization_glovescolor_hotpink_glovesClr: 'Ярко-розовый',
    customization_shoescolor_tan_shoesClr: 'Бронзовый',
    customization_shoescolor_darkgrey_shoesClr: 'Темно-серый',
    customization_shoescolor_beige_shoesClr: 'Бежевый',
    customization_shoescolor_blackgrey_shoesClr: 'Серо-черный',
    customization_shoescolor_red_shoesClr: 'Красный',
    customization_shoescolor_orange_shoesClr: 'Оранжевый',
    customization_shoescolor_deepgold_shoesClr: 'Темно-золотой',
    customization_shoescolor_yellow_shoesClr: 'Желтый',
    customization_shoescolor_lime_shoesClr: 'Лаймовый',
    customization_shoescolor_darkgreen_shoesClr: 'Темно-зеленый',
    customization_shoescolor_christmasgreen_shoesClr: 'Рождественский зеленый',
    customization_shoescolor_huntergreen_shoesClr: 'Охотничий зеленый',
    customization_shoescolor_turquoise_shoesClr: 'Бирюзовый',
    customization_shoescolor_blue_shoesClr: 'Голубой',
    customization_shoescolor_darkblue_shoesClr: 'Синий',
    customization_shoescolor_purple_shoesClr: 'Пурпурный',
    customization_shoescolor_violet_shoesClr: 'Фиолетовый',
    customization_shoescolor_salmon_shoesClr: 'Лососевый',
    customization_shoescolor_pink_shoesClr: 'Розовый',
    customization_shoescolor_brown_shoesClr: 'Коричневый',
    customization_shoescolor_darkbrown_shoesClr: 'Темно-коричневый',
    customization_shoescolor_grey_shoesClr: 'Серый',
    customization_shoescolor_hotpink_shoesClr: 'Ярко-розовый',
    customization_itemhead_desc:
      'Головной убор, когда-то найденный в сезонном наборе.',
    customization_itembody_desc:
      'Верхняя часть одежды лавочника, входящая в сезонный набор.',
    customization_itemfeet_desc:
      'Обувь лавочника, когда-то найденная в сезонном наборе.',
    customization_itempants_desc:
      'Нижняя часть одежды лавочника, когда-то найденная в сезонном наборе!',
    customization_itemweapon_desc:
      'Аксессуар лавочника, когда-то найденный в сезонном наборе.',
    customization_itemhands_desc:
      'Аксессуар лавочника для рук, когда-то найденный в сезонном наборе.',
    customization_itemface_desc:
      'Аксессуар лавочника для лица, когда-то найденный в сезонном наборе.',
    pet_custom_desc: 'Аксессуар питомца, когда-то найденный в сезонном наборе.',
    customization_itemhead_custom_hat_hachimaki: 'Хатимаки',
    customization_itemhead_custom_hat_healthhearts: 'Шапка здоровья',
    customization_itemweapon_custom_handheld_pixelgun: 'Ретро-бластер',
    customization_itempants_custom_pants_hakama: 'Штаны хакама',
    customization_itembody_custom_top_sukeban: 'Наряд сукэбан',
    customization_itempants_custom_pants_sukeban: 'Штаны сукэбан',
    customization_itemhead_custom_hat_headphones: 'Наушники',
    customization_itemface_custom_eyewear_sleepingmask: 'Маска для сна',
    customization_itembody_custom_top_pyjamas: 'Пижама',
    customization_itemhead_custom_hat_crabbeanie: 'Крабовая шапочка',
    cutscene4_01: 'А, новый торговец! Я ждал тебя!',
    cutscene4_02:
      'Я старейшина <c1>Оуэн</c1>. Позволь мне проводить тебя в твою новую лавку.',
    cutscene4_03:
      'Многое предстоит сделать, чтобы вернуть ее в рабочее состояние!',
    tutorial_counter1_01: 'Вот мы и на месте!',
    tutorial_counter1_02: 'Хм... Давненько здесь никого не было...',
    tutorial_counter2_01: 'Посмотрим, что скрывается под всей этой пылью.',
    tutorial_counter3_01: 'Кхе-кхе! Отлично!',
    tutorial_rack1_01: 'Эту стойку тоже не мешало бы протереть от пыли.',
    tutorial_rack2_01: 'Великолепно. Теперь чем бы еще оружие создавать...',
    tutorial_rack2_02:
      'О! Похоже, твоя лавка попалась на глаза городскому кузнецу!',
    tutorial_rack3_01: 'Всем доброе утро! Это ты, что ли, новый торговец?',
    tutorial_rack3_02:
      'Вот тебе от меня приветственный подарок. С пылу с жару, прямо из кузни!',
    tutorial_rack4_01: 'Ну же, попробуй!',
    tutorial_rack5_01: 'Великолепно. Уоллес, дальше бери дело в свои руки.',
    tutorial_rack5_02: 'За мной не заржавеет!',
    tutorial_bin1_01:
      'Вот это <c1>корзина для железа</c1>. Хорошенько протри ее от пыли!',
    tutorial_slots1_01:
      'Я рудокоп тутошний. Буду тебе то и дело присылать <c1>железо</c1>.',
    tutorial_slots1_02:
      'Не хочешь сварганить что-нибудь еще из этого <c1>железа</c1>?',
    tutorial_slots1_03: 'Не бойся, будет человеческого размера!',
    tutorial_slots3_01: 'Этот доспех отправился прямиком в <c1>инвентарь</c1>!',
    tutorial_slots3_02:
      'Похоже, не уместился на <c1>вертикальной стойке</c1>...',
    tutorial_slots3_03: 'Тут поможет <c1>манекен</c1>. Попробуй!',
    tutorial_slots4_01: 'Вот так-то лучше!',
    tutorial_sell1_01: 'Смотри-ка, а вот и твой первый клиент!',
    tutorial_sell1_02: 'Наверняка хочет купить только что изготовленный меч!',
    tutorial_levelup1_01: 'Отлично! Ты делаешь большие успехи в торговле!',
    tutorial_levelup1_02:
      'Изготавливай и продавай товары, чтобы <c1>достигнуть 2-го уровня</c1>. Я понаблюдаю отсюда!',
    tutorial_levelup1_03: 'Вот тебе пара предметов для начала.',
    tutorial_tailor1_01:
      'Еще один довольный клиент! Высокий уровень торговца позволит открыть множество вещей.',
    tutorial_tailor1_02:
      'Кстати, пока что мы можем создавать только металлические предметы...',
    tutorial_tailor1_03: 'Это там Джулия, что ли?',
    tutorial_tailor2_01: 'Хороший выбор. Ты не пожалеешь.',
    tutorial_tailor2_02:
      'Джулия! Ты как раз вовремя! Не против немного помочь нашему новому лавочнику?',
    tutorial_tailor2_03:
      'Конечно, с удовольствием. Ты уже оставляешь нас, Уоллес?',
    tutorial_tailor2_04:
      'Извините, ребятки. Надолго оставлять кузню без присмотра нельзя!',
    tutorial_tailor2_05: 'Она у меня... горячая! Ха-ха!',
    tutorial_tailor2_06: 'Ох, Уоллес! Ты неисправим!',
    tutorial_leatherbin1_01:
      'Так, на чем мы остановились? Ой! А где твоя <c1>корзина для кожи</c1>?',
    tutorial_leatherbin1_02:
      'Без запасов <c1>кожи</c1> я не смогу ничего шить...',
    tutorial_leatherbin1_03:
      'Уверена, <c1>дубильщик</c1> не будет против, если мы позаимствуем у него.',
    tutorial_leatherbin2_01: 'Чудесно. Не забудь поблагодарить его потом!',
    tutorial_craftslot1_01: 'А теперь я покажу тебе, что я умею!',
    tutorial_craftslot2_01:
      'Что, только одна <c1>производственная ячейка</c1>? Так не пойдет!',
    tutorial_craftslot2_02:
      'Позволь мне тебе помочь. <c1>Производственные ячейки</c1> имеют первостепенную важность для любого торговца!',
    tutorial_craftslot3_01:
      'Ну же, воспользуйся новоприобретенной <c1>производственной ячейкой</c1>!',
    tutorial_leave1_01:
      'Что ж, похоже, тут все в порядке. Думаю, дальше ты справишься и без меня.',
    tutorial_leave1_02:
      'Не забывай выполнять <c1>особые задания</c1>. За них можно получить хорошие награды.',
    tutorial_leave2_01:
      'Вернусь-ка я в свою мастерскую. Приходи как-нибудь поболтать!',
    tutorial_adventurers1_01:
      'Для некоторых мощных предметов требуются <c1>особые компоненты</c1>, которые можно найти только в <c1>приключениях</c1>.',
    tutorial_adventurers1_02:
      'Правда, для этого тебе понадобится отряд храбрых <c1>героев</c1>...',
    tutorial_adventurers1_03: '<c1>Аргон</c1> вроде был где-то неподалеку.',
    story_argon_00_01: 'Добрый день! Я Аргон, учусь на рыцаря!',
    story_argon_00_02:
      'Я патрулирую город и помогаю тем, кто в этом нуждается. Тебе моя помощь не нужна?',
    story_argon_00_03:
      '<c1>Компоненты из подземелий</c1>? Конечно! Можешь рассчитывать на меня!',
    story_argon_00_04:
      'Для начала мне нужен товарищ. Давай я проведу тебя в <c1>тренировочный зал</c1>!',
    tutorial_hire_adventurers1_01:
      'Добро пожаловать в <c1>тренировочный зал</c1>! Здесь самые разные <c1>герои</c1> ищут работу!',
    tutorial_hire_adventurers1_02:
      'Как насчет <c1>вора</c1>? Их можно нанять бесплатно!',
    tutorial_forest1_01: 'Мы готовы! В путь!',
    tutorial_open_chest1_01: 'Во время приключения мы нашли <c1>сундук</c1>!',
    tutorial_open_chest1_02:
      'Я слышал, что в них хранятся потрясающие сокровища, но открыть их можно только <c1>волшебным ключом</c1>...',
    tutorial_open_chest1_03:
      'Как ты думаешь, <c1>кристаллы</c1> помогут? Давай попробуем!',
    tutorial_open_chest2_01:
      'Довольно тяжелый, да? Вот, используй мои <c1>кристаллы</c1>.',
    tutorial_open_chest3_01: 'Ого! <c1>Редкий чертеж</c1>!',
    tutorial_open_chest3_02:
      'Эти чертежи <c1>очень ценные</c1>, но их можно найти только в сундуках.',
    tutorial_open_chest3_03:
      'Если у тебя нет кристаллов, ключи иногда бывают у <c1>боссов подземелий</c1>.',
    tutorial_open_chest3_04:
      'Ну что ж, вот несколько ключей! Отправь меня в <c1>приключение</c1> на поиски других сундуков!',
    tutorial_equip1_01:
      'Я видел, как вы с Аргоном открыли этот сундук. Впечатляющая добыча!',
    tutorial_equip1_02:
      'А попробуй-ка экипироваться только что найденным <c1>легендарным предметом</c1>!',
    tutorial_equip1_03:
      'В обязанности торговца входит надлежащее снаряжение <c1>героев</c1>!',
    tutorial_equip2_01: 'Идеально подошло! Удачи в следующем приключении!',
    tutorial_equip3_01:
      'Погоди минутку. После вашего приключения этот юноша Аргон выглядел довольно <c1>уставшим</c1>.',
    tutorial_equip3_02:
      'Он не виноват, работа у него тяжелая! Может быть, дашь ему найденный тобой <c1>напиток выносливости</c1>?',
    tutorial_craft_elvenwood1_01:
      'Раз у тебя теперь есть требуемая <c1>эльфийская древесина</c1>, можем попытаться изготовить эту <c1>мощную мантию</c1>!',
    tutorial_upgradefurniture1_01: 'Привет! Как идут дела?',
    tutorial_upgradefurniture1_02:
      'Некоторый опыт у тебя уже имеется, так что неплохо было бы <c1>улучшить свою мебель</c1>!',
    tutorial_upgradefurniture2_01:
      'Ты также можешь попросить других игроков, чтобы они <c1>помогли</c1> все ускорить!',
    tutorial_upgradefurniture3_01:
      'Отлично! Высокий уровень <c1>максимальной энергии</c1> — это ключ к хорошим сделкам!',
    tutorial_upgradefurniture3_02:
      'На этот раз я тебе помог, но в будущем тебе для этого понадобятся приятели.',
    tutorial_upgradefurniture3_03:
      'Не хочешь вступить в <c1>гильдию</c1>? Объединение с <c1>другими игроками</c1> дает кучу преимуществ!',
    tutorial_join_city1_01:
      'Совместные вложения, помощь друг другу, просто общение друзей... Причин не вступать в гильдию нет!',
    tutorial_join_city1_02:
      'Если вдруг передумаешь, всегда можно уйти позже. Так что особо об этом не беспокойся!',
    tutorial_join_city2_01:
      'Похоже, у тебя уже есть целая гильдия друзей, ждущих тебя!',
    tutorial_join_city3_01: 'Вот теперь ты в деле!',
    tutorial_join_city3_02:
      'Только не забывай, что присоединяешься к <c1>настоящим игрокам</c1>, поэтому веди себя прилично! В команде исполняются любые мечты!',
    tutorial_deco1_01:
      'Ой-ой-ой! Лавка у тебя какая-то... обычная. А где же твой <c1>декор</c1>?',
    tutorial_deco1_02:
      'Немного зелени явно поможет оживить помещение. Пожалуйста, займись этим!',
    tutorial_deco2_01: 'Так-то лучше. Уверена, твои клиенты это тоже оценят!',
    tutorial_trade1_01:
      'Ой, тут такая новость! Наконец-то открылся <c1>рынок</c1>!',
    tutorial_trade2_01:
      'Добро пожаловать на <c1>рынок</c1>! Здесь можно торговать с игроками <c1>со всего мира</c1>!',
    tutorial_trade2_02:
      'Может быть, приобретешь что-нибудь невероятное. Кто знает?',
    tutorial_expand1_01: 'Как-то у тебя здесь тесновато!',
    tutorial_expand1_02:
      'Давно пора заняться <c1>расширением лавки</c1>! Как думаешь?',
    tutorial_expand2_01:
      'Да, дорого, но подумай, сколько места будет для стоек и корзин!',
    tutorial_exceptional_craft1_01:
      'Замечательно, правда? Если позволишь мне над ним поработать, я сделаю его еще лучше.',
    tutorial_exceptional_craft2_01:
      '<c1>Предметы высокого качества</c1> намного более мощные и ценные. Следи за их появлением!',
    tutorial_table1_01:
      'Этот предмет не помещается на стойках, которые есть у тебя сейчас.',
    tutorial_table1_02: 'Поглядим-ка, можем ли мы это исправить!',
    tutorial_table2_01:
      'Вот так. На <c1>столе</c1> можно разместить почти все, что не вмещается на вертикальные стойки и манекены.',
    tutorial_shelf1_01:
      'Похоже, этот предмет <c1>слишком мал</c1> для имеющихся стоек.',
    tutorial_shelf1_02: 'Не беспокойся, я знаю, что тебе нужно!',
    tutorial_shelf2_01:
      'Вот. Такие маленькие предметы лучше выглядят на <c1>полках</c1>!',
    tutorial_jackslot1_01:
      'О, тебе удалось заработать <c1>билет Фортуны</c1>! Давай я покажу, как им пользоваться.',
    tutorial_jackslot2_01: 'Вот мы и на месте! Вращай!',
    tutorial_jackslot3_01:
      'Какая удача! Обязательно загляни в магазин за призом!',
    tutorial_jackslot3_02:
      'За <c1>ежедневные задания</c1> можно получить еще больше <c1>билетов Фортуны</c1>. Следи за их появлением!',
    tutorial_surcharge1_01:
      'Привет еще раз! Тебе никогда не доводилось <c1>торговаться</c1> с клиентами?',
    tutorial_surcharge2_01:
      'Толковый торговец может извлечь из этого выгоду. Покажи-ка свои навыки!',
    tutorial_discount1_01:
      'Изнурительно, да? Ты можешь <c1>понизить цену</c1> на предмет, чтобы восстановить немного <c1>энергии</c1>.',
    tutorial_discount2_01:
      'Попробуй <c1>понизить цену</c1> для этого удачливого клиента.',
    tutorial_bp_unlock1_01:
      'Ах да! Самые продвинутые чертежи требуют исследования, прежде чем ими можно пользоваться.',
    tutorial_bp_unlock1_02:
      'Это можно сделать с помощью <c1>свитков исследования</c1>!',
    tutorial_bp_unlock1_03:
      'Вот, я принесла тебе несколько штук. Попробуй провести <c1>исследование</c1>.',
    tutorial_bp_unlock2_01:
      'Оставь себе остальные <c1>свитки исследования</c1>. Они тебе еще пригодятся!',
    tutorial_explain_counter_title: 'Прилавок торговца',
    tutorial_explain_counter_desc: 'Самая важная часть любой лавки!',
    tutorial_explain_racks_title: 'Стойки для предметов',
    tutorial_explain_racks_desc:
      'Ты показываешь товары, клиенты покупают. Демонстрация — важная вещь!',
    tutorial_explain_bin_title: 'Корзины для хранения',
    tutorial_explain_bin_desc:
      'Храни ресурсы в своей лавке для дальнейшего использования. Важная вещь для всех предметов!',
    tutorial_link1_01: 'Ну что, все усердно трудишься?',
    tutorial_link1_02: 'Я смотрю, ты расширяешься без устали!',
    tutorial_link1_03:
      'Если хочешь сохранить весь прогресс, нужно <c1>подключить учетную запись</c1>!',
    tutorial_link2_01:
      'Подключившись к своей платформе, ты ни за что не потеряешь учетную запись!',
    tutorial_link2_02:
      'Вот так все просто, и всегда полезно иметь запасной план!',
    tutorial_press_sidemenu: 'Коснись <c1>«Меню»</c1>.',
    tutorial_press_sidemenu_click: 'Нажми на <c1>«Меню»</c1>.',
    tutorial_press_platform:
      'Коснись кнопки <c1>«Подключиться»</c1>, чтобы подключиться к своей платформе!',
    tutorial_introduce_quest_event_goldcity1_01:
      'Трудолюбивый буржуа! Это я, король Рейнгольд!',
    tutorial_introduce_quest_event_goldcity2_01:
      '<c1>Специальное предложение</c1>? Боюсь, не в этот раз. Но у меня есть потрясающая новость!',
    tutorial_introduce_quest_event_goldcity2_02:
      'Таинственный <c1>Потерянный город золота</c1> появился вновь!',
    tutorial_introduce_quest_event_goldcity2_03:
      'В этом легендарном подземелье полно богатств! Золотая возможность для находчивых светских львов вроде меня!',
    tutorial_introduce_quest_event_goldcity2_04:
      'В общем, ты и твоя гильдия просто ОБЯЗАНЫ его разграбить. Ваш король требует этого!',
    tutorial_introduce_quest_event_goldcity2_05:
      'Принеси мне <c1>чистое золото</c1>, найденное внутри, и получишь достойную награду! Я же все-таки щедрый монарх.',
    tutorial_introduce_quest_event_titantower1_01:
      'Л-лавочник! Важная новость!',
    tutorial_introduce_quest_event_titantower2_01:
      'Как тебе такое? Из ниоткуда появилась огромная башня!',
    tutorial_introduce_quest_event_titantower2_02:
      'Ее называют <c1>Башней титанов</c1>... Нужно ее исследовать!',
    tutorial_introduce_quest_event_titantower2_03:
      'Представь, что может ждать нас наверху... Разве это не потрясающе?',
    tutorial_introduce_quest_event_titantower2_04: 'Ну же, идем!',
    tutorial_introduce_quest_event_dragon1_01:
      'Привет, хранитель добычи! Это ужасно! К нам вторглись драконы!',
    tutorial_introduce_quest_event_dragon2_01:
      'Чешуйчатым тварям, наверно, нужны королевские сокровищницы...',
    tutorial_introduce_quest_event_dragon2_02:
      'Я бы присоединилась к ним, если бы король не нанял меня сражаться с ними, ха-ха!',
    tutorial_introduce_quest_event_dragon2_03:
      'Роксана подсуетилась с моим кораблем, чтобы он летал по небу. Ты отвечаешь за снаряжение моего экипажа!',
    tutorial_introduce_quest_event_dragon2_04:
      'Мы скоро взлетаем. Займемся каперством!',
    tutorial_introduce_quest_event_king1_01:
      'Раскатайте красную ковровую дорожку, ибо пришел король Рейнгольд!',
    tutorial_introduce_quest_event_king2_01:
      'Я известен как очень щедрый правитель, но сегодня я должен попросить о такой же бесконечной любезности и тебя.',
    tutorial_introduce_quest_event_king2_02:
      'Видишь ли, я проснулся сегодня утром с ужасной болезнью...',
    tutorial_introduce_quest_event_king2_03:
      '...той, которая требует все собрать! Приобрести! Накопить! Я должен иметь все!',
    tutorial_introduce_quest_event_king2_04:
      'Сделай так, чтобы мой королевский арсенал распирало от невероятных товаров, и заработаешь мою <c1>королевскую милость</c1>!',
    tutorial_introduce_quest_event_king2_05:
      'Благодаря этой милости ты сможешь получить некоторые из моих ценнейших <c1>древностей</c1>!',
    tutorial_introduce_quest_event_king2_06:
      'А теперь займись изготовлением! От этого зависит мое выживание!',
    tutorial_basement_intro1_01: 'Здорово, друг мой.',
    tutorial_basement_intro1_02:
      'Я смотрю, в лавке у тебя народу битком. Подсобить не надо?',
    tutorial_basement_intro1_03:
      'Если пыльный старый подвал отсюда никуда не делся, я могу там для тебя покопать.',
    tutorial_basement_intro2_01:
      'Теперь можешь оставлять стойки и корзины внизу, где они будут все так же служить тебе!',
    tutorial_basement_intro2_02:
      'Но не забывай: <c1>добавить стоек и корзин не получится</c1>. Ты просто уберешь их, чтоб не мешали.',
    tutorial_basement_intro2_03:
      'Такие правила у общества лавочников. Зато будет больше места для декора.',
    tutorial_basement_intro3_01:
      'Вот так. Если нужно еще покопать, друг гном к твоим услугам.',
    loginevent_lunar_01:
      'Ну что, празднуешь Китайский Новый год? Открываешь <c1>красные конверты</c1>?',
    loginevent_lunar_02:
      'В моем были деньги на карманные расходы — 60 триллионов золотых!',
    loginevent_lunar_03: 'Что? Тебе конверты не дарили? Возмутительно!',
    loginevent_lunar_04:
      'Хорошо! Ты можешь взять несколько штук в моей личной сокровищнице <c1>кристаллов</c1>.',
    loginevent_lunar_05: 'Просто заходи каждый день и получай щедрые подарки!',
    tutorial_enchanting1_01:
      'А, дружище. Вижу, тебе удалось найти... <c1>рунный камень</c1>, верно?',
    tutorial_enchanting1_02:
      'Их можно использовать для создания мощного <c1>колдовства</c1>. Дай-ка я покажу.',
    tutorial_enchanting2_01:
      'Великолепно. У тебя тут мощная <c1>стихийная руна</c1>.',
    tutorial_enchanting2_02:
      'Что же там дальше?.. Ах да, заколдовывание предмета!',
    tutorial_enchanting2_03: 'Вот, попробуй заколдовать это кольцо.',
    tutorial_enchanting3_01:
      'Чудесно. Заколдованные предметы гораздо более мощные и ценные.',
    tutorial_enchanting3_02:
      'Я слышал, что особое усиление героев возможно при помощи стихий... но все остальное я забыл.',
    tutorial_enchanting3_03:
      'Ладно. Удачи теперь в колдовстве. Вот тебе несколько рунных камней для начала.',
    tutorial_enchantingA1_01: 'Привет! Это у тебя... <c1>рунный камень</c1>?',
    tutorial_enchantingA1_02:
      'Тогда мои молитвы услышаны! С его помощью можно создать мощное <c1>колдовство</c1>!',
    tutorial_enchantingA1_03: 'С благословения Богини я все тебе покажу.',
    tutorial_enchantingA2_01:
      'Очень хорошо! Эта <c1>руна духа</c1> полна жизни! Так и рвется в бой!',
    tutorial_enchantingA2_02:
      'Не будем заставлять ее ждать. Попробуй заколдовать с ее помощью это кольцо.',
    tutorial_enchantingA3_01:
      'Хорошо! Заколдованные предметы гораздо более мощные и ценные.',
    tutorial_enchantingA3_02:
      'Предметы с духами также дают героям <c1>особые навыки</c1>, но подробностей я не знаю...',
    tutorial_enchantingA3_03:
      'Я купила еще несколько <c1>рунных камней</c1>. Полагаю, Богиня хочет отдать их тебе.',
    tutorial_enchantingA3_04:
      'Будь благословенны ты и твоя работа. До свидания!',
    tutorial_collection_book_sub1_01: 'Эй! Есть кто-нибудь дома?',
    tutorial_collection_book_sub2_01:
      'Я нашла кое-что классное и хочу показать тебе!',
    tutorial_collection_book_sub2_02:
      'Погоди, у тебя же еще нет <c1>всех улучшений для какого-нибудь чертежа</c1>?',
    tutorial_collection_book_sub2_03:
      'Ой... Значит, я не могу тебе дать <c1>найденную мной классную книгу</c1>...',
    tutorial_collection_book_sub2_04:
      'Но это не проблема! Тебе нужно <c1>создавать предмет по рецепту раз за разом</c1>, чтобы улучшать его!',
    tutorial_collection_book_sub2_05:
      'Я вернусь, когда у тебя все получится! Удачи!',
    tutorial_collection_book1_01: 'И это снова я!',
    tutorial_collection_book2_01:
      'Я нашла кое-что классное на чердаке у Гримара!',
    tutorial_collection_book2_02:
      'Какой-то магический фолиант о коллекционировании предметов... Нужен такой?',
    tutorial_collection_book2_03: 'Так я и думала! Возьми!',
    tutorial_collection_book3_01:
      'Это магическая <c1>коллекционная книга</c1>! Давай, открой ее!',
    tutorial_collection_book4_01:
      'Насколько я понимаю... как только ты получаешь <c1>все улучшения для чертежа</c1>, в книге появляется новая страница.',
    tutorial_collection_book4_02:
      'После появления страницы можно жертвовать экземплярами такого предмета, чтобы добавить его в коллекцию книги!',
    tutorial_collection_book4_03:
      'Похоже, книга принимает только по <c1>одному экземпляру</c1> предмета каждого уровня качества от <c1>обычного</c1> до <c1>легендарного</c1>!',
    tutorial_collection_book4_04:
      'Я принесла тебе предмет, так что можно попробовать!',
    tutorial_collection_book5_01:
      'Теперь твоя коллекция на один шаг ближе к завершению!',
    tutorial_collection_book5_02:
      'Если продолжишь в том же духе, тебя, вероятно, будут ждать какие-то классные награды! Все-таки это магическая книга!',
    tutorial_dust_counter: 'Коснись <c1>прилавка</c1>, чтобы протереть пыль.',
    tutorial_dust_rack: 'Коснись <c1>стойки</c1>, чтобы протереть пыль.',
    tutorial_press_crafting: 'Коснись кнопки <c1>«Создать»</c1>.',
    tutorial_press_crafting_card: 'Коснись <c1>карточки изготовления</c1>.',
    tutorial_wait_crafting:
      'Подожди, пока на таймере истечет время, затем коснись карточки.',
    tutorial_dust_bin: 'Коснись <c1>корзины</c1>, чтобы убрать пыль.',
    tutorial_press_bin_bubble:
      'Коснись <c1>облачка</c1>, чтобы собрать ресурсы.',
    tutorial_press_sell_bubble:
      'Коснись <c1>облачка</c1>, чтобы поговорить с клиентом.',
    tutorial_press_bubble_hero:
      'Коснись <c1>облачка</c1>, чтобы поговорить с Аргоном.',
    tutorial_press_bubble_dragon_invasion:
      'Коснитесь <c1>облачка</c1>, чтобы поговорить с Полонией.',
    tutorial_press_bubble_blacksmith:
      'Коснись <c1>облачка</c1>, чтобы поговорить с кузнецом Уоллесом.',
    tutorial_press_sell_item: 'Коснись кнопки <c1>«Продать»</c1>.',
    tutorial_press_city: 'Коснись кнопки <c1>«Город»</c1>.',
    tutorial_press_buildings: 'Коснись <c1>швейной мастерской</c1>.',
    tutorial_press_carpenter: 'Коснись кнопки <c1>«Нанять»</c1>.',
    tutorial_press_shop: 'Коснись кнопки <c1>«Лавка»</c1>.',
    tutorial_press_furniture: 'Коснись кнопки <c1>«Мебель»</c1>.',
    tutorial_press_hire: 'Коснись кнопки <c1>«Нанять»</c1>.',
    tutorial_press_weapon: 'Коснись ячейки <c1>оружия</c1>.',
    tutorial_press_hire_adventurer: 'Коснись кнопки <c1>«Нанять»</c1>.',
    tutorial_press_bubble_tailor:
      'Коснись <c1>облачка</c1>, чтобы поговорить со швеей Джулией.',
    tutorial_press_quest: 'Коснись кнопки <c1>«Приключения»</c1>.',
    tutorial_press_questing_card:
      'Коснись кнопки <c1>«Эльфийская древесина»</c1>.',
    tutorial_press_hire_board_back:
      'Коснись кнопки <c1>«Назад»</c1>, чтобы вернуться в город.',
    tutorial_press_forest: 'Коснись кнопки <c1>«Отправить команду»</c1>.',
    tutorial_press_quest_slot: 'Коснись пустой <c1>ячейки героя</c1>.',
    tutorial_press_adventurer: 'Коснись <c1>карточки героя</c1>.',
    tutorial_press_adventurer_heal: 'Коснись кнопки <c1>«Исцелить»</c1>.',
    tutorial_press_adventurer_hpdrink: 'Коснись <c1>напитка выносливости</c1>.',
    tutorial_press_start_quest: 'Коснись кнопки <c1>«Начать приключение»</c1>,',
    tutorial_press_craft_slot: 'Коснись кнопки <c1>«+»</c1>.',
    tutorial_purchase_craft_slot: 'Купи <c1>производственную ячейку</c1>.',
    tutorial_speedup_quest:
      'Приключения занимают время... <c1>Ускорь</c1> их, чтобы помочь нам!',
    tutorial_collect_quest: 'Коснись кнопки <c1>«Забрать»</c1>.',
    tutorial_press_adventurers: 'Коснись кнопки <c1>«Перс.»</c1>',
    tutorial_press_armor: 'Коснись кнопки <c1>«Доспехи»</c1>.',
    tutorial_press_back: 'Коснись кнопки <c1>«Назад»</c1>.',
    tutorial_press_rack: 'Коснись <c1>стойки</c1>, чтобы посмотреть действия.',
    tutorial_press_info: 'Коснись кнопки <c1>«Сведения»</c1>.',
    tutorial_press_city_search: 'Коснись кнопки <c1>«Поиск гильдий»</c1>.',
    tutorial_show_furniture_slot_types:
      'Это типы предметов, которые можно размещать на стойке.',
    tutorial_press_vert_rack: 'Коснись <c1>вертикальной стойки</c1>.',
    tutorial_press_generic: 'Коснись: <c1>{0}</c1>.',
    tutorial_press_upgrade: 'Коснись кнопки <c1>«Улучшить»</c1>.',
    tutorial_press_argon: 'Коснись <c1>Аргона</c1>.',
    tutorial_press_edit: 'Коснись кнопки <c1>«Дизайн»</c1>.',
    tutorial_press_trade: 'Коснись кнопки <c1>«Рынок»</c1>.',
    tutorial_press_plant: 'Коснись <c1>растения</c1>.',
    tutorial_press_chest: 'Коснись кнопки <c1>«Сундук»</c1>.',
    tutorial_press_expand: 'Коснись кнопки <c1>«Расширение»</c1>.',
    tutorial_press_open_chest:
      'Коснись кнопки <c1>«Магическое открытие»</c1>. Это за мой счет!',
    tutorial_press_city_management: 'Коснись кнопки <c1>«Гильдия»</c1>.',
    tutorial_press_hire_argon: 'Коснись кнопки <c1>«Золото»</c1>.',
    tutorial_press_jackslot: 'Коснись кнопки <c1>«Задания»</c1>.',
    tutorial_press_spin: 'Коснись кнопки <c1>«Вращать»</c1>.',
    tutorial_press_objective: 'Коснись кнопки <c1>«Задания»</c1>.',
    tutorial_press_surcharge: 'Коснись кнопки <c1>«Повысить цену»</c1>.',
    tutorial_press_discount: 'Коснись кнопки <c1>«Понизить цену»</c1>.',
    tutorial_press_help: 'Коснись облачка <c1>«Помощь»</c1>.',
    tutorial_press_bubble_king:
      'Теперь можешь коснуться моего <c1>облачка</c1>.',
    tutorial_press_store: 'Коснись кнопки <c1>«Магазин»</c1>.',
    tutorial_press_crafting_unlock: 'Коснись <c1>карточки чертежа</c1>.',
    tutorial_press_unlock_blueprint:
      'Коснись кнопки <c1>«Подтвердить»</c1>, чтобы открыть чертеж.',
    tutorial_press_forest_location: 'Коснись <c1>Воющего леса</c1>.',
    tutorial_speedup_craft:
      'Коснись <c1>облачка изготовления</c1> для ускорения.',
    tutorial_ascension1_01:
      'Вы только посмотрите! Тебе удалось найти <c1>осколки усиления</c1>!',
    tutorial_ascension1_02:
      'Это самое крутое, что мне встречалось! Кроме меня, конечно же!',
    tutorial_ascension1_03: 'Давай я покажу, как этим пользоваться!',
    tutorial_ascension2_01:
      'Вот так! Этот чертеж короткого меча выглядит очень эффективным!',
    tutorial_ascension2_02:
      '<c1>Осколки усиления</c1> позволяют улучшать твои любимые чертежи еще больше, чем обычно.',
    tutorial_ascension2_03:
      'Это улучшение за мой счет, но тебе стоит попробовать сделать это и со своим любимым чертежом!',
    tutorial_ascension2_04: 'Мне очень хочется увидеть, что ты там придумаешь!',
    tutorial_press_ascension_tab: 'Коснись кнопки <c1>«Усиление»</c1>.',
    tutorial_press_ascend: 'Коснись кнопки <c1>«Усилить»</c1>.',
    tutorial_press_inventory: 'Коснись кнопки <c1>«Инвентарь»</c1>.',
    tutorial_press_fatring: 'Коснись облачка <c1>Уоллеса</c1>.',
    tutorial_press_bubble_generic:
      'Коснись <c1>облачка</c1>, чтобы поговорить с таким персонажем, как {0}.',
    tutorial_press_bubble_engineer:
      'Коснись <c1>облачка</c1>, чтобы поговорить с инженером.',
    tutorial_press_bubble_scholar:
      'Коснись <c1>облачка</c1>, чтобы поговорить с ученым.',
    press_bubble_moondragon:
      'Коснись <c1>облачка</c1>, чтобы поговорить с Лундрой.',
    press_bubble_moondragon_click:
      'Нажми на <c1>облачко</c1>, чтобы поговорить с Лундрой.',
    tutorial_press_book_entry: 'Коснись <c1>записи о предмете</c1>.',
    tutorial_press_book_entry_click: 'Нажми на <c1>запись о предмете</c1>.',
    tutorial_press_donate: 'Коснись кнопки <c1>«Пожертвовать»</c1>.',
    tutorial_press_donate_click: 'Нажми кнопку <c1>«Пожертвовать»</c1>.',
    tutorial_press_inventory_item:
      'Коснись <c1>предмета</c1> для пожертвования.',
    tutorial_press_inventory_item_click:
      'Нажми на <c1>предмет</c1> для пожертвования.',
    tutorial_press_book: 'Коснись <c1>коллекционной книги</c1>.',
    tutorial_press_book_click: 'Нажми на <c1>коллекционную книгу</c1>.',
    craft_idle_reminder_01:
      'Твои мастера ничем не заняты. Почему бы не <c1>создать</c1> что-нибудь?',
    sell_idle_reminder_01:
      'Этот <c1>клиент</c1> уже давно ждет у прилавка... Невежливо заставлять людей ждать!',
    inventory_idle_reminder_01:
      'Похоже, твой инвентарь полон! Если не хватает места, можешь попробовать <c1>разобрать</c1> какие-то предметы.',
    tutorial_goal_level_2:
      'Продавай предметы, чтобы достигнуть <c1>2-го уровня</c1>',
    objectives_01_title: 'Подготовка стола',
    objectives_02_title: 'Добавляем немного зелени',
    objectives_03_title: 'Корзина получше',
    objectives_04_title: 'Манеры торговца',
    objectives_05_title: 'Палки и камни',
    objectives_06_title: 'Подходящая ветка',
    objectives_07_title: 'Исследователи леса',
    objectives_08_title: 'Побольше места',
    objectives_09_title: 'Традиционная медицина',
    objective_questsend_party:
      'Отправьте в Воющий лес полную команду из 3 героев',
    objective_questreachfloor: 'Пройдите {0}-й уровень Воющего леса',
    equip_adventure_count: 'Экипируйте героя 3 предметами снаряжения.',
    dialog_thankyou: 'Спасибо!',
    dialog_hello: 'Привет!',
    dialog_amazing: 'Потрясающе!',
    dialog_hmmm: 'Хм-м-м...',
    dialog_really: 'Неужели?!',
    dialog_perhaps: 'Возможно.',
    visitor_thought_counterbusy: 'Столько народу у прилавка!',
    visitor_thought_counterbusy_02: 'Дел у лавочника невпроворот...',
    visitor_thought_counterbusy_03: 'У прилавка очередь...',
    visitor_thought_nopathtocounter: 'Проход к прилавку закрыт!',
    visitor_thought_nopathtocounter_02: 'До прилавка не проберешься!',
    visitor_thought_nopathtocounter_03: 'Не могу добраться до прилавка...',
    visitor_thought_itemclassmismatch: 'Это я использовать не могу...',
    visitor_thought_itemclassmismatch_02: 'Я не знаю, как этим пользоваться.',
    visitor_thought_itemclassmismatch_03: 'Не совсем мой стиль.',
    visitor_thought_itemleveltoolow: 'Как-то слабовато...',
    visitor_thought_itemleveltoolow_02: 'Это ниже моего уровня.',
    visitor_thought_itemleveltoolow_03: 'Хотелось бы что-то посильнее...',
    visitor_thought_itemleveltoohigh: 'У меня силы не хватает!',
    visitor_thought_itemleveltoohigh_02: 'Слишком мощно для меня!',
    visitor_thought_itemleveltoohigh_03: 'Вот это я себе позволить не могу!',
    visitor_thought_nocounter: 'Где тут прилавок?',
    visitor_thought_nocounter_02: 'Я не могу найти прилавок...',
    visitor_thought_nocounter_03: 'Где платить?',
    visitor_thought_noitems: 'На стойке пусто!',
    visitor_thought_noitems_02: 'Здесь ничего нет...',
    visitor_thought_noitems_03: 'В наличии, что ли, нет?',
    visitor_thought_decoclassmismatch: 'Фи...',
    visitor_thought_decoclassmismatch_02: 'Неинтересно.',
    visitor_thought_decoclassmismatch_03: 'Скука какая.',
    visitor_thought_noshopkeeperatcounter: 'Где лавочник?',
    visitor_thought_noshopkeeperatcounter_02: 'Кто здесь главный?',
    visitor_thought_noshopkeeperatcounter_03: 'Не могу найти лавочника...',
    visitor_thought_shoptoocrowded: 'Здесь слишком тесно!',
    visitor_thought_shoptoocrowded_02: 'Слишком много клиентов...',
    visitor_thought_shoptoocrowded_03: 'Слишком много народу!',
    visitor_thought_didntfindanything: 'Ничего не могу найти...',
    visitor_thought_didntfindanything_02: 'Для меня здесь ничего нет.',
    visitor_thought_didntfindanything_03: 'Не могу найти ничего интересного...',
    visitor_thought_foundinterestingitem: 'Идеально!',
    visitor_thought_foundinterestingitem_02: 'То, что мне нужно!',
    visitor_thought_foundinterestingitem_03: 'Вот это подойдет.',
    visitor_thought_inspectdecorationpositive: 'Красота!',
    visitor_thought_inspectdecorationpositive_02: 'Мне нравится!',
    visitor_thought_inspectdecorationpositive_03: 'Ого!',
    visitor_thought_yield: 'Ой!',
    visitor_thought_yield_02: 'Прошу прощения!',
    visitor_thought_dustfurniture: 'Вот так.',
    visitor_thought_dustfurniture_02: 'Чистота и красота.',
    visitor_thought_dustfurniture_03: 'Отлично!',
    visitor_thought_dustfurniture_04: 'Когда тут последний раз пыль вытирали?!',
    visitor_thought_dustfurniture_05: '*Кхе-кхе*',
    visitor_thought_petinteraction: 'Милашка!',
    visitor_thought_petinteraction_02: 'Эй, привет!',
    visitor_thought_petinteraction_03: 'Глядите, какой малыш!',
    visitor_thought_petinteraction_04: 'Милота!',
    visitor_thought_petinteraction_05: 'Как тебя зовут, приятель?',
    visitor_thought_petinteraction_halloween: 'Зомби... питомец...',
    visitor_thought_petinteraction_halloween_02: 'Мозг?..',
    visitor_thought_petinteraction_dog: 'Какая милая собака!',
    visitor_thought_petinteraction_dog_02: 'Собачка!',
    visitor_thought_petinteraction_dog_03: 'Иди сюда, собачка!',
    visitor_thought_petinteraction_dog_04: 'Дай лапу! Хорошая собака!',
    visitor_thought_petinteraction_dog_halloween: 'Хороший... мальчик...',
    visitor_thought_petinteraction_cat: 'Нужно погладить пушистика...',
    visitor_thought_petinteraction_cat_02: 'Котик!',
    visitor_thought_petinteraction_cat_halloween: 'Гладить... пузико...',
    visitor_thought_petinteraction_chicken: 'Цыпленок!',
    visitor_thought_petinteraction_chicken_02: 'Выглядит аппетитно...',
    visitor_thought_petinteraction_chicken_halloween: 'Птичий мозг...',
    visitor_thought_petinteraction_hamster: 'Ого! Крошка!',
    visitor_thought_petinteraction_hamster_02: 'Хомячок! Какой милый!',
    visitor_thought_petinteraction_hamster_halloween: 'Мозг... маленький...',
    visitor_thought_petinteraction_unicorn: 'Ого! Единорог!',
    visitor_thought_petinteraction_unicorn_02: 'Это детеныш?',
    visitor_thought_petinteraction_unicorn_halloween: 'Аргх... Святой!..',
    visitor_thought_petinteraction_frog: 'Лягушка!',
    visitor_thought_petinteraction_frog_02: 'Ее можно трогать?',
    visitor_thought_petinteraction_frog_halloween: 'Скользкий...',
    visitor_thought_petinteraction_ox: 'Вот это сила!',
    visitor_thought_petinteraction_ox_02: 'О! Какая удача!',
    visitor_thought_petinteraction_ox_halloween: 'Обнимашный...',
    visitor_thought_petinteraction_atlab_momo: 'Крылатый лемур?!',
    visitor_thought_petinteraction_atlab_momo_02: 'Привет, Момо!',
    visitor_thought_petinteraction_atlab_momo_halloween: 'Момо?..',
    visitor_thought_petinteraction_tiger: 'Настоящий тигр?!',
    visitor_thought_petinteraction_tiger_02: 'Крошечный тигр!',
    visitor_thought_petinteraction_tiger_halloween: 'Гладить... пузико...',
    dialog_thankyou_halloween: 'Спасибо...',
    dialog_hello_halloween: 'Мозги?..',
    dialog_amazing_halloween: 'Мозги!..',
    dialog_hmmm_halloween: '...',
    dialog_really_halloween: 'Р-р-р...',
    dialog_perhaps_halloween: 'Мозги?..',
    visitor_thought_counterbusy_halloween: 'Много зомби...',
    visitor_thought_counterbusy_halloween_02: 'Мозги... в делах...',
    visitor_thought_nopathtocounter_halloween: 'Не добраться... до мозгов...',
    visitor_thought_itemclassmismatch_halloween: 'Нельзя... использовать...',
    visitor_thought_itemleveltoolow_halloween: 'Не... мозги...',
    visitor_thought_itemleveltoohigh_halloween: 'Слишком сильное...',
    visitor_thought_nocounter_halloween: 'Прилавок?..',
    visitor_thought_nocounter_halloween_02: 'Прилавок... Где?..',
    visitor_thought_noitems_halloween: 'Нет мозгов...',
    visitor_thought_decoclassmismatch_halloween: 'Нет...',
    visitor_thought_noshopkeeperatcounter_halloween: 'Мозги... Где?..',
    visitor_thought_shoptoocrowded_halloween: 'Много зомби...',
    visitor_thought_didntfindanything_halloween: 'Нет мозгов...',
    visitor_thought_didntfindanything_halloween_02: 'Р-р-р...',
    visitor_thought_foundinterestingitem_halloween: 'Да... мозги...',
    visitor_thought_foundinterestingitem_halloween_02: 'Мозги...',
    visitor_thought_foundinterestingitem_halloween_03: 'Купить...',
    visitor_thought_inspectdecorationpositive_halloween: '<3...',
    visitor_thought_inspectdecorationpositive_halloween_02: 'Нравится...',
    visitor_thought_inspectdecorationpositive_halloween_03: 'Да...',
    visitor_thought_yield_halloween: 'Зомби... двигайся...',
    visitor_thought_yield_halloween_02: 'Извините...',
    dialog_thankyou_aprilfools: 'Спасибо, дружище.',
    dialog_hello_aprilfools: 'Как дела, дружище?',
    dialog_amazing_aprilfools: 'Это просто чума, дружище.',
    dialog_hmmm_aprilfools: 'Что-то не то, дружище.',
    dialog_really_aprilfools: 'Дружище, это вымогательство!',
    dialog_perhaps_aprilfools: 'Дружище, вполне можно.',
    visitor_thought_counterbusy_aprilfools: 'Прилавок завален, дружище.',
    visitor_thought_nopathtocounter_aprilfools:
      'Не могу найти прилавок, дружище.',
    visitor_thought_itemclassmismatch_aprilfools:
      'Это я использовать не могу, дружище.',
    visitor_thought_itemleveltoolow_aprilfools: 'Это слишком дешево, дружище.',
    visitor_thought_itemleveltoohigh_aprilfools:
      'Дружище, вот это я себе позволить не могу.',
    visitor_thought_nocounter_aprilfools: 'Дружище, где тут прилавок?',
    visitor_thought_noitems_aprilfools: 'Дружище, здесь ничего нет!',
    visitor_thought_decoclassmismatch_aprilfools: 'Что-то не то, дружище.',
    visitor_thought_noshopkeeperatcounter_aprilfools:
      'Дружище... Где лавочник?',
    visitor_thought_shoptoocrowded_aprilfools:
      'Слишком много Тамасов, дружище.',
    visitor_thought_didntfindanything_aprilfools: 'Я ухожу, дружище.',
    visitor_thought_didntfindanything_aprilfools_02: 'Ладно, я ухожу отсюда.',
    visitor_thought_foundinterestingitem_aprilfools: 'Вот это хорошо, дружище.',
    visitor_thought_inspectdecorationpositive_aprilfools:
      'Мне это нравится, дружище.',
    visitor_thought_yield_aprilfools: 'Прости, дружище.',
    visitor_thought_petinteraction_aprilfools: 'Классный питомец, дружище.',
    visitor_thought_petinteraction_aprilfools_02:
      'Хотел бы я иметь питомца, дружище.',
    visitor_thought_petinteraction_aprilfools_dog: 'Дай лапу, дружище!',
    visitor_thought_petinteraction_aprilfools_cat: 'Какая милота, дружище.',
    visitor_thought_petinteraction_aprilfools_chicken:
      'Будем приятелями, дружище.',
    visitor_thought_petinteraction_aprilfools_hamster:
      'Что здесь делает Ричард?',
    visitor_thought_petinteraction_aprilfools_unicorn:
      'Ты просто чудо, дружище.',
    visitor_thought_petinteraction_aprilfools_frog: 'Прыгай, дружище.',
    visitor_thought_petinteraction_aprilfools_ox: 'Хорошо выглядишь, дружище.',
    visitor_thought_petinteraction_aprilfools_atlab_momo:
      'Странный питомец, дружище.',
    visitor_thought_petinteraction_aprilfools_tiger:
      'Ого! Какой свирепый, дружище.',
    dialog_blacksmith_greetings:
      'Добро пожаловать в кузню! Тут жарко, но ты не обращай внимания!',
    dialog_blacksmith_greetings_02: 'Прекрасный день для кузнечных дел, да?',
    dialog_blacksmith_investgold: 'Спасибо! Любая малость пригодится!',
    dialog_blacksmith_investgold_02: 'Ты не пожалеешь!',
    dialog_blacksmith_investgems: 'Ого! Настрой у тебя серьезный!',
    dialog_blacksmith_investgems_02: 'Так держать!',
    dialog_blacksmith_underupgrade:
      'Извини, у нас ремонт — посторонним вход воспрещен. Таковы уж правила!',
    dialog_blacksmith_underupgrade_02:
      'Если снести вон ту стену, можно еще одну наковальню поставить...',
    dialog_blacksmith_afterupgrade:
      'Мехи вновь работают на полную катушку — давай-ка что-нибудь смастерим!',
    dialog_blacksmith_afterupgrade_02:
      'С ремонтом покончено. У меня теперь просто обалденная наковальня!',
    visit_blacksmith_reminder_00_01:
      'Лавочник! Твоя гильдия какая-то сонная, верно?',
    visit_blacksmith_reminder_00_02:
      'Трудно будет развивать дело, когда в гильдии столько неактивных участников...',
    visit_blacksmith_reminder_00_03:
      'Возможно, лучше поискать новую! Не волнуйся, <c1>свои вложения ты не потеряешь!</c1>',
    visit_blacksmith_reminder_01_01: 'Лавочник! Как поживает твоя гильдия?',
    visit_blacksmith_reminder_01_02:
      'Если ее участники стали неактивными, возможно, пришла пора найти новую!',
    visit_blacksmith_reminder_01_03:
      'Не нужно беспокоиться о своих вложениях, <c1>они переходят за тобой из гильдии в гильдию!</c1>',
    visit_blacksmith_reminder_02_01:
      'Лавочник! Какова сейчас активность в твоей гильдии?',
    visit_blacksmith_reminder_02_02:
      '<c1>Полноценная активная гильдия</c1> необходима для успеха и быстрого прогресса!',
    visit_blacksmith_reminder_02_03:
      'Если гильдия кажется тебе слишком медленной, ты всегда можешь рассмотреть возможность вступления в другую!',
    visit_blacksmith_reminder_02_04:
      'Не нужно беспокоиться о своих вложениях, <c1>они перейдут за тобой, если сменишь гильдию!</c1>',
    visit_blacksmith_quality_00_01:
      'Только посмотри на это — какая красота! Прямо хоть имя давай.',
    visit_blacksmith_quality_00_02: 'Вот только дети ревновать будут! Ха-ха!',
    visit_blacksmith_quality_christmas_00_01:
      'С праздниками, дружище! Отмечаешь в одиночку?',
    visit_blacksmith_quality_christmas_00_02:
      'Если у тебя нет семьи, которую ты хочешь навестить, то можешь прийти в дом Уоллеса и праздновать здесь!',
    visit_blacksmith_bulk_00_01:
      'Мой молот сегодня в ударе, а кузня просто жжет! Ха-ха!',
    visit_blacksmith_bulk_00_02:
      'Смотри, сколько я всего сделал! Бери, не стесняйся!',
    visit_blacksmith_bulk_christmas_00_01:
      'С праздниками, дружище! Отмечаешь в одиночку?',
    visit_blacksmith_bulk_christmas_00_02:
      'Если у тебя нет семьи, которую ты хочешь навестить, то можешь прийти в дом Уоллеса и праздновать здесь!',
    dialog_blacksmith_chat:
      'Аллен — отличный парень. Его ждет великое будущее!',
    dialog_blacksmith_chat_02:
      'Думаешь, Дархан такой уж раздражительный? Как по мне, для гнома он очень даже веселый! Ха!',
    dialog_blacksmith_chat_03:
      'Помню, как выковал Аргону его первый меч, будто это вчера было. У него в руках игрушки ломались просто моментально!',
    dialog_blacksmith_chat_04:
      'Когда-то я целыми ночами в кузне работал, но нынче я человек семейный. И не жалею!',
    dialog_blacksmith_chat_05:
      'Если уболтаешь моего старика, Теодора, в кузню вернуться — не пожалеешь. Он что угодно выковать может!',
    dialog_blacksmith_chat_06:
      'Бром — парень честный, но не шибко общительный. Дай ему шанс.',
    dialog_blacksmith_chat_07:
      'Горза каждый день топоры ломает... Что она вообще с ними делает?',
    dialog_blacksmith_chat_08:
      'Почему железо вечно такое злое? У него низкая точка кипения, ха!',
    dialog_blacksmith_chat_09:
      'Почему пугало выиграло приз? Оно выделялось в своей области!',
    dialog_blacksmith_chat_10:
      'Шутка о строительстве?.. Да ну, она слишком длинная будет.',
    dialog_blacksmith_chat_11: 'Обожаю лопаты. К ним не подкопаешься.',
    dialog_blacksmith_chat_12:
      'На твоем месте я бы суши не ел. От них бывает сушняк.',
    dialog_blacksmith_chat_13:
      'Хорошая защита снижает получаемый в поисках приключений урон. Воинам она ой как пригодится!',
    dialog_blacksmith_chat_14:
      'Как поживает твой питомец? У нашего все отлично!',
    dialog_blacksmith_bounty_craft:
      'Ты производишь впечатление искусного мастера.',
    dialog_blacksmith_bounty_slay:
      'Я хоть и владею топором, но топорную работу не люблю.',
    dialog_blacksmith_bounty_quest:
      'Я хоть и владею топором, но топорную работу не люблю.',
    dialog_blacksmith_bounty_surcharge:
      'Ставлю перед тобой высокую цель — следить за повышением цены!',
    dialog_blacksmith_bounty_discount:
      'Я никогда бы не осмелился сбрасывать со счетов твое умение торговаться!',
    thanks_blacksmith_00_01:
      'Пойду-ка я назад в кузницу. А ты продолжай в том же духе!',
    thanks_blacksmith_christmas_00_01:
      'Вот тебе маленький подарок. Не забудь о моем предложении!',
    dialog_carpenter_greetings: 'Аллен. Плотник я. Лучше не сыщешь.',
    dialog_carpenter_greetings_02: 'Подбираешь себе что-нибудь из ряда вон?',
    dialog_carpenter_unhiredgreetings:
      'Интересует самое лучшее? Тогда ты по адресу.',
    dialog_carpenter_unhiredgreetings_02:
      'Я могу смастерить из дерева все, что угодно. Луки, посохи, щиты — только скажи.',
    dialog_carpenter_hirequestion: 'Так в чем дело?',
    dialog_carpenter_investgold: 'Конечно.',
    dialog_carpenter_investgold_02: 'Уж я-то этого достоин. Поверь.',
    dialog_carpenter_investgems: 'Прекрасный выбор.',
    dialog_carpenter_investgems_02: 'В уме тебе не откажешь.',
    dialog_carpenter_underupgrade:
      'Спасибо, что заглядываешь, но у меня ремонт идет.',
    dialog_carpenter_underupgrade_02:
      'Часть «B» вставляется в ячейку «C»?.. Почему инструкция на шведском?',
    dialog_carpenter_afterupgrade:
      'Я закончил с ремонтом мастерской. Неплохо, а? Плотницкое качество!',
    visit_carpenter_quality_00_01:
      'Тебе надо это увидеть. Только не волнуйся. Это просто НЕЧТО. Я от себя такого не ожидал.',
    visit_carpenter_quality_christmas_00_01:
      'Дружище! У меня тут рождественский подарок, который ты, несомненно, ждешь!',
    visit_carpenter_quality_christmas_00_02: 'Не терпится открыть, да?',
    visit_carpenter_quality_christmas_00_03:
      'Понимаю! Можешь сделать это прямо сейчас! Я никому не скажу.',
    visit_carpenter_bulk_00_01:
      'У меня для тебя кое-что есть. Качество, конечно же, гарантирую.',
    visit_carpenter_bulk_christmas_00_01:
      'Дружище! У меня тут рождественский подарок, который ты, несомненно, ждешь!',
    visit_carpenter_bulk_christmas_00_02: 'Не терпится открыть, да?',
    visit_carpenter_bulk_christmas_00_03:
      'Понимаю! Можешь сделать это прямо сейчас! Я никому не скажу.',
    dialog_carpenter_chat: 'Уоллес мне как отец.',
    dialog_carpenter_chat_02:
      'Я тут занялся изготовлением оперения для стрел... Э?.. Так ты стрелами не торгуешь?',
    dialog_carpenter_chat_03:
      'Живые корни постоянно прогрызают забор сада Джунипер. Попробовать, что ли, железное дерево?..',
    dialog_carpenter_chat_04:
      'Волшебные посохи я сам делать научился. А вот с палочками, увы, проблемы.',
    dialog_carpenter_chat_05:
      'Ищешь ключи? Их можно у боссов подземелий найти, но не всегда. Ах да, в «Колесе Фортуны» они, говорят, тоже есть.',
    dialog_carpenter_chat_06:
      'Критический урон монстров пробивает броню. Воины это просто ненавидят.',
    dialog_carpenter_chat_07:
      'Старый мастер ни на чем не специализируется, но даже мне не обойтись без его помощи при изготовлении высококлассного снаряжения.',
    dialog_carpenter_chat_08:
      'Нам, мастеровым, для развития навыков нужно больше места. Не забывай вкладываться в расширение наших мастерских.',
    dialog_carpenter_chat_09:
      'Иногда я работаю с завязанными глазами. С таким талантищем, как у меня, без этого становится скучно.',
    dialog_carpenter_chat_10:
      'Волосы Эвелин напоминают о созревшем эльфийском дереве. Хоть бери и делай статую!.. Только не говори ей, что я такое сказал.',
    dialog_carpenter_chat_11:
      'Я построил дом для Мэйли. Было сложно не использовать железо, но для меня нет ничего невозможного!',
    dialog_carpenter_bounty_craft:
      'Ха! Думаешь, у тебя получится лучше, чем у меня? Докажи это!',
    dialog_carpenter_bounty_slay:
      'Это поручение только для самых настоящих героев.',
    dialog_carpenter_bounty_quest:
      'Это поручение только для самых настоящих героев.',
    dialog_carpenter_bounty_surcharge:
      'Товар стоит ровно столько, сколько покупатель за него заплатит!',
    dialog_carpenter_bounty_discount:
      'Товар стоит ровно столько, сколько покупатель за него заплатит!',
    thanks_carpenter_00_01:
      'Всегда пожалуйста. Перед моими изделиями еще никто не устоял.',
    thanks_carpenter_christmas_00_01:
      'Идеально, да? Меня благодарить не нужно!',
    dialog_iron_greetings: 'Давай быстрее, мне в шахту надо.',
    dialog_iron_greetings_02: 'Железа надо?',
    dialog_iron_investgold: 'Э, неплохо, неплохо. Для людей.',
    dialog_iron_investgold_02: 'Да, эт настоящее золото.',
    dialog_iron_investgems: 'Клянусь бородой, тебе везет!',
    dialog_iron_investgems_02: 'Ценный кристалл, да.',
    dialog_iron_underupgrade: 'Копаю я. Поможешь? Нет? Ну и вали!',
    dialog_iron_underupgrade_02: 'Слишком глубоко копать не буду...',
    dialog_iron_underupgrade_03: 'За кирки, ребята! Копаем до упора!',
    dialog_iron_afterupgrade: 'Мусора пока полно, но шахта опять в работе.',
    dialog_iron_afterupgrade_02:
      'Ох и горячо там, внизу. Интересно, как глубоко удастся пройти...',
    visit_iron_00_01: 'У меня тут партия железа. Наткнулся на жилу. Берешь?',
    visit_iron_christmas_00_01:
      'Рождество, тьфу! Эльфы то, эльфы се. А про гномов никто не вспоминает.',
    visit_iron_christmas_00_02:
      'Возьми мое железо. И не забывай, что тебе дал его гном!',
    dialog_iron_chat:
      'Не стоит недооценивать снежки йети. Эти пакостники в них камни кладут.',
    dialog_iron_chat_02:
      'Драконы что-то последнее время разлетались. Не к добру это.',
    dialog_iron_chat_03:
      'Эта свеча — вроде часов. А так мы, гномы, в темноте видим прекрасно.',
    dialog_iron_chat_04:
      'Не забывай улучшать корзину для железа. Если она полна, железо впустую уходит.',
    dialog_iron_chat_05:
      'Тут недалеко гномья крепость была. Копнули глубоковато, так теперь там одни демоны кругом.',
    dialog_iron_chat_06:
      'У этого Уоллеса вместо мозгов наковальня! Пусть себе по балде молотом постучит!',
    dialog_iron_chat_07:
      'Работал как-то с шестью товарищами на одну принцессу. Ох и любила она поспать...',
    dialog_iron_chat_08: 'Сверху вниз смотришь, а?',
    dialog_iron_chat_09:
      'Папаша мой частенько говаривал, мол, шахта — родина искусств.',
    dialog_iron_chat_10:
      'Вы, люди, шибко высокие для доспехов. Напрасная трата железа!',
    dialog_iron_chat_11:
      'Думаешь, у гномов ноги коротки? Да мы прирожденные бегуны!',
    dialog_iron_chat_12:
      'Только рыцари доспехи носят чин-чином. Прям крепости ходячие.',
    dialog_iron_chat_13:
      'При виде повелителя у меня слезы наворачиваются... Вот это настоящие доспехи.',
    dialog_iron_chat_14:
      'Ну и где прикажете гному найти чего-нибудь перекусить? Я проголодался что твой оборотень!',
    dialog_iron_chat_15:
      'Аргх! У меня в руднике живет эльф! Кто в этом городе следит за порядком?!',
    dialog_iron_chat_16: 'Железо для фей — что твой яд. Как они живут-то?',
    dialog_iron_chat_17: 'Эта пиратка точно потешается над моей манерой речи.',
    dialog_iron_chat_18:
      'Ох! Теперь у нас среди соседей и драконы. Сумасшедший город в сумасшедшем мире.',
    dialog_iron_bounty_craft:
      'Твои ресурсы только пыль собирают. Используй их с умом.',
    dialog_iron_bounty_slay: 'Тут шастают монстры. Ниче хорошего...',
    dialog_iron_bounty_quest: 'Тут шастают монстры. Ниче хорошего...',
    dialog_iron_bounty_surcharge:
      'Проклятые герои... Никакого почтения к железу. Преподай им урок за меня.',
    dialog_iron_bounty_discount:
      'У тебя все какое-то дорогое! Научись заключать взаимовыгодные сделки.',
    thanks_iron_00_01: 'Пойду дальше копать.',
    thanks_iron_christmas_00_01:
      'Чертовы эльфы Санты... Хотел бы я для разнообразия посмотреть, как они сами себе добывают железо.',
    dialog_wood_greetings: 'Ты как тростинка прям. Не голодаешь, часом?',
    dialog_wood_greetings_02: 'А-а?',
    dialog_wood_greetings_03: 'Силушка нужна? Туточки я.',
    dialog_wood_investgold: 'Хочешь добавить мне груза? Нет проблем!',
    dialog_wood_investgold_02: 'Отлично!',
    dialog_wood_investgems: 'Золотишком хвастаешься, а? В своем праве.',
    dialog_wood_investgems_02: 'А ты ничего.',
    dialog_wood_underupgrade: 'Не до разговоров. У меня тренировка.',
    dialog_wood_underupgrade_02: 'Я тренируюсь! Загляни попозже.',
    dialog_wood_afterupgrade:
      'Посмотри на эту красоту. У деревьев никаких шансов.',
    dialog_wood_afterupgrade_02:
      'Как думаешь, сколько я сейчас отожму? Спорим, потяну 400 кило?',
    dialog_wood_afterupgrade_03:
      'С тренировками на сегодня все. Ты глянь, глянь!',
    visit_wood_christmas_00_01: 'С праздниками. Здесь немного холодно, да?',
    visit_wood_christmas_00_02:
      'Я обхожу город и проверяю, чтобы у всех было достаточно дров.',
    visit_wood_00_01:
      'Эй, послушай-ка! Аллен у меня слишком много сегодня заказал, не хочешь меня чуть разгрузить?',
    visit_wood_01_01: 'Знаешь, дрова таскать — прекрасная тренировочка...',
    visit_wood_01_02:
      '...Но золото мне по весу нравится больше. Как насчет поменяться?',
    dialog_wood_chat:
      'Я в курсе, что Исмаил — эльф, но ему надо бы взяться за тренировки. Не дай бог ветер дунет — он же надвое переломится!',
    dialog_wood_chat_02:
      'Приседания и овсянка. Это — главное. Приседания и овсянка!',
    dialog_wood_chat_03:
      'Бром форму теряет. Все плавит и плавит что-то вместо того, чтоб тяжести поднимать.',
    dialog_wood_chat_04: 'Как тебе мои дельтовидные мышцы, а?',
    dialog_wood_chat_05:
      'Не забывай о разминках. Сидеть часами в лавке за прилавком вредно.',
    dialog_wood_chat_06:
      'При подъеме тяжестей, пусть даже в своей лавке, главное — правильный подход. Используй ноги, а не спину!',
    dialog_wood_chat_07:
      'Аргон-то, когда никто не видит, тренируется только так. Просто никому не рассказывает.',
    dialog_wood_chat_08:
      'Фамильяры?.. Тьфу! Если тебе в драке нужна помощь, нечего в эту драку соваться!',
    dialog_wood_chat_09: 'Где этот дубина Рудо? Он обещал к шести быть здесь.',
    dialog_wood_chat_10:
      'Зачем нанимать кого-то еще, когда есть варвары? Без воплей и кровищи хорошей драки не бывает!',
    dialog_wood_chat_11:
      'Хороший варвар может выжать лежа 800 фунтов. Хороший вождь может выжать столько же, да еще и вес варвара добавить.',
    dialog_wood_chat_12:
      'У меня сегодня занятие по орочьей литературе. Ты придешь?',
    dialog_wood_chat_13: 'Ишь ты! Чудотворцы! Оружие или магия... Выбирай!',
    dialog_wood_chat_14:
      'Лундра, да? Хорошая девочка, смелая. Мне это нравится.',
    dialog_wood_chat_15:
      'Отец сказал, что скоро вернется домой. Что-то мне этого не хочется.',
    dialog_wood_bounty_craft:
      'Чтобы навыки не терялись, важны регулярные тренировки.',
    dialog_wood_bounty_slay:
      'Несмотря на твою худосочность, думаю, ты с этим справишься.',
    dialog_wood_bounty_quest:
      'Несмотря на твою худосочность, думаю, ты с этим справишься.',
    dialog_wood_bounty_surcharge: 'Ты умеешь подбирать слова, да?',
    dialog_wood_bounty_discount: 'Ты умеешь подбирать слова, да?',
    thanks_wood_00_01: 'А ты ничего.',
    thanks_wood_christmas_00_01: 'Вот твоя доля. Согревайся!',
    dialog_tailor_greetings: 'Безупречный стиль, как всегда.',
    dialog_tailor_greetings_02:
      'Хочешь что-то подправить или заказать что-то новое?',
    dialog_tailor_unhiredgreetings:
      'Ты ведь лавкой заправляешь, верно? Рада наконец с тобой познакомиться.',
    dialog_tailor_hirequestion: 'Мои умения к твоим услугам.',
    dialog_tailor_investgold: 'Благодарю.',
    dialog_tailor_investgems: 'Как мило. Благодарю.',
    dialog_tailor_investgems_02: 'Сочту за честь.',
    dialog_tailor_underupgrade:
      'О, как ты не вовремя! У меня тут настоящий кавардак...',
    dialog_tailor_afterupgrade:
      'Я чуть обновила свою скромную мастерскую. Вот, смотри!',
    visit_tailor_quality_00_01:
      'Мне хотелось бы показать тебе одну из моих лучших работ.',
    visit_tailor_quality_00_02:
      'Ты сразу отличишь настоящее качество, не так ли?',
    visit_tailor_bulk_00_01:
      'Ох, слушай, тут у меня всякая одежда с неверными размерами...',
    visit_tailor_bulk_00_02:
      'Мне она бесполезна, но, думаю, тебе удастся найти покупателя.',
    visit_tailor_quality_christmas_00_01:
      'Эта холодная погода идеально подходит для того, чтобы вязать возле камина, верно?',
    visit_tailor_quality_christmas_00_02:
      'Вот, я сделала кое-что для тебя. С Рождеством!',
    visit_tailor_bulk_christmas_00_01:
      'Эта холодная погода идеально подходит для того, чтобы вязать возле камина, верно?',
    visit_tailor_bulk_christmas_00_02:
      'Вот, я сделала кое-что для тебя. С Рождеством!',
    thanks_tailor_christmas_00_01: 'С праздниками и с Новым годом тоже!',
    thanks_tailor_00_01: 'Благодарю.',
    dialog_tailor_chat:
      'Мой главный заказчик — наш король. Мне кажется, он ни разу не надевал один и тот же костюм даже дважды.',
    dialog_tailor_chat_02:
      'Я, кстати, еще и парикмахер. Если вдруг решишь сменить прическу, обращайся. Моя лучшая работа — это Эвелин!',
    dialog_tailor_chat_03:
      'Свобода движений, вес, защита... Создание легких доспехов — задача непростая.',
    dialog_tailor_chat_04:
      'Исмаил — настоящий джентльмен. Если остальные эльфы отличаются такими же безупречными манерами, я совсем не прочь посетить их страну.',
    dialog_tailor_chat_05:
      'Согласись, ощущения от прикосновения к атласу поистине неповторимы.',
    dialog_tailor_chat_06:
      'Легкие доспехи не сравнить с тяжелыми по уровню защиты, но у странников весьма развиты навыки уклонения.',
    dialog_tailor_chat_07:
      'Плащ сшить несложно. Впрочем, я слышала, что у эльфов они получаются бесподобными.',
    dialog_tailor_chat_08:
      'Любой герой способен нанести врагу огромный критический урон, но лучше всего с этим справляются мушкетеры и монахи.',
    dialog_tailor_chat_09:
      'Вязаные вещи Иоланды не имеют себе равных. А бабушке-то тысяча лет!',
    dialog_tailor_bounty_craft:
      'У меня случился творческий кризис. Сможешь принести мне вдохновение?',
    dialog_tailor_bounty_slay: 'Мне нужен герой! Быстрый, побывавший в боях...',
    dialog_tailor_bounty_quest:
      'Твои герои в этих краях известны. Могу я попросить их о помощи?',
    dialog_tailor_bounty_surcharge:
      'Прошло много времени с тех пор, как я учила тебя торговаться... Покажи мне свои успехи!',
    dialog_tailor_bounty_discount:
      'Прошло много времени с тех пор, как я учила тебя торговаться... Покажи мне свои успехи!',
    dialog_leather_greetings: 'Привет! Как дела?',
    dialog_leather_greetings_02: 'Привет! Я — Тамас, кожевник.',
    dialog_leather_investgold: 'Спасибо, дружище.',
    dialog_leather_investgold_02: 'Точно, дружище.',
    dialog_leather_investgold_03: 'Ого, дружище, да я богат!',
    dialog_leather_investgems: 'Ух ты... Спасибо, дружище.',
    dialog_leather_investgems_02: 'Дружище, откуда у тебя все это?',
    dialog_leather_investgems_03: 'Слышь, да я прям миллиардер, а?',
    dialog_leather_underupgrade: 'Не сейчас, дружище. Я мастерскую обновляю.',
    dialog_leather_underupgrade_02:
      'Вот прикидываю новую планировку. Как тебе, дружище?',
    dialog_leather_afterupgrade: 'Готово, дружище. Чуешь запах свежей кожи?',
    dialog_leather_afterupgrade_02: 'Как тебе новое оформление, дружище?',
    dialog_leather_chat:
      'Дружище, надо что-то делать с Горзой и ее вырубкой. Деревья спасать надо.',
    dialog_leather_chat_02:
      'Исмаил — ну реально странный парень. Но мне он нравится.',
    dialog_leather_chat_03:
      'У Джога крутые усы. Как думаешь, может, мы с ним сдружимся на этой почве, а?',
    dialog_leather_chat_04:
      'Хотел бы я жить с йети. Гладил бы их мех целыми днями.',
    dialog_leather_chat_05:
      'Иногда я наведываюсь в пещеры Авроры за большими сосульками. Надеюсь, когда-нибудь я стану королем йети...',
    dialog_leather_chat_06:
      'Лучшая корчма в окрестностях — «Изумруд». Я там всегда двойные порции заказываю.',
    dialog_leather_chat_07:
      'Контроль качества в кожевенном деле — дело серьезное. У меня ты найдешь только самое лучшее.',
    dialog_leather_chat_08: 'Дружище, где моя телега?',
    dialog_leather_chat_09:
      'Привет, дружище! Ну, где мой товар? Ох, прости, дружище, перепутал тебя с кое-кем.',
    dialog_leather_chat_10:
      'Овцы Исмаила постоянно на моем газоне пасутся. Там даже траву стричь не нужно!',
    dialog_leather_chat_11:
      'Дружище, ты только не говори никому, но мне кажется, я — заклинатель овец. Они все время возвращаются!',
    dialog_leather_chat_12:
      'Я пошел к Серене, чтобы она мне погадала, а она попросила меня уйти. Грубая она какая-то.',
    dialog_leather_chat_13: 'Ух ты, у нас в городе драконы! Два дракона!',
    dialog_leather_bounty_craft: 'Теперь моя очередь просить о доставке.',
    dialog_leather_bounty_slay:
      'Там такие странные монстры бывают... Можно мне шкуру?',
    dialog_leather_bounty_quest:
      'Я знаю местечко, где можно найти много чего нового, дружище.',
    dialog_leather_bounty_surcharge: 'В этом мире человек человеку волк.',
    dialog_leather_bounty_discount:
      'Приятелям можно устроить сделку и повыгоднее, дружище.',
    visit_leather_00_01:
      'Привет, дружище, вот твой товар. Еще один тюк кожи, верно?',
    visit_leather_01_01:
      'Эй, дружище, я тут решил попробовать прямые продажи. Кожа не нужна?',
    visit_leather_christmas_00_01: 'Эй, дружище, с праздниками!',
    visit_leather_christmas_00_02:
      'Твоя лавка готова к встрече Санты? Обязательно приготовь печенье и молоко.',
    visit_leather_aprilfools_00_01: 'Странные у тебя клиенты, дружище.',
    visit_leather_aprilfools_00_02:
      'Все время говорят «дружище». Странно это, дружище.',
    visit_leather_aprilfools_01_01:
      'Эй, дружище, да тут вечеринка! По какому случаю?',
    visit_leather_aprilfools_01_02: 'День дураков? Странно это, дружище.',
    visit_leather_backup_00_01: 'Эй, дружище, у тебя есть чего?',
    visit_leather_al_00_01: 'Эта одежда для меня чересчур, дружище.',
    visit_leather_al_00_02: 'Чародеи такое носят? Вот чудаки, дружище.',
    visit_leather_am_00_01: 'Кожа здесь — это нечто, дружище.',
    visit_leather_am_00_02:
      'Я мог бы носить такое день напролет. Может, так и сделаю.',
    visit_leather_bl_00_01:
      'Какие сапоги... Взгляни на эту тонкую работу с кожей. Толщина подобрана со вкусом.',
    visit_leather_bl_00_02:
      'Дружище, здесь даже водяной знак есть. Хорошая вещь.',
    visit_leather_gl_00_01:
      'Сразу видно, что использована моя кожа, дружище. Первоклассная вещь.',
    visit_leather_hl_00_01: 'Классная шапка, дружище. Можно мне ее взять?',
    visit_leather_hm_00_01: 'Мне нравится этот запах, дружище. Я беру.',
    visit_leather_xc_00_01:
      'В таком я чувствую себя злодеем, дружище. Точно куплю.',
    thanks_leather_00_01: 'Спасибо, дружище!',
    thanks_leather_01_01: 'Спасибо, дружище. И не забывай о чаевых.',
    thanks_leather_christmas_00_01:
      'Надеюсь, я получу от Санты прототип пулемета Гатлинга.',
    thanks_leather_aprilfools_00_01:
      'Спасибо, дружище. Но смотри в оба. Не доверяю я всем этим дружищам.',
    thanks_leather_aprilfools_01_01:
      'Спасибо, дружище. Постарайся не путать меня с этими чудаками.',
    additional_sale_leather_00_01:
      'Ух ты, чувак, что это? Пахнет почти так же хорошо, как кожа!',
    dialog_herbalist_greetings:
      'Добро пожаловать в «Лекарственную лавку Марибель».',
    dialog_herbalist_greetings_02: 'Привет! Ищешь что-то конкретное?',
    dialog_herbalist_greetings_03:
      'И снова здравствуй. Хочешь что-то заказать?',
    dialog_herbalist_unhiredgreetings:
      'О, ты, кажется, новичок у нас! Лекарства не нужны?',
    dialog_herbalist_unhiredgreetings_02:
      'Я — травница, делаю сильные тоники и зелья из волшебных трав.',
    dialog_herbalist_hirequestion: 'Поработаем вместе?',
    dialog_herbalist_investgold: 'Спасибо!',
    dialog_herbalist_investgold_02: 'На черный день!',
    dialog_herbalist_investgems: 'Мудрое решение!',
    dialog_herbalist_investgems_02: 'Ух ты! Спасибо!',
    dialog_herbalist_underupgrade: 'Извини, лавка закрыта!',
    dialog_herbalist_underupgrade_02:
      'Ты уж извини, что все так затянулось. Законы о продаже трав — штука серьезная...',
    dialog_herbalist_afterupgrade: '«Лекарственная лавка Марибель» открыта.',
    dialog_herbalist_chat:
      'Не ешь грибы, если вдруг попадутся. Микология — штука не для любителей!',
    dialog_herbalist_chat_02:
      'Может, по ней и не скажешь, но Джунипер о растениях знает даже больше меня.',
    dialog_herbalist_chat_03:
      'До сих пор испытываю отвращение, когда работаю с живыми корнями. Я же не одна такая, да?',
    dialog_herbalist_chat_04:
      'Помни: напитки выносливости здоровый сон не заменяют.',
    dialog_herbalist_chat_05:
      'В моих зельях только натуральные ингредиенты. Природный вкус и никакого глютена!',
    dialog_herbalist_chat_06:
      'Тебе Лилу на глаза не попадалась? Ее заказ прибыл.',
    dialog_herbalist_chat_07:
      'Друиды прекрасно разбираются в травах. Как думаешь, может, я смогу стать одной из них?',
    dialog_herbalist_chat_08:
      'Мне бы хотелось как-нибудь поговорить с архидруидом. Их знания в травоведении не имеют себе равных.',
    dialog_herbalist_chat_09:
      'Порошок фей мне бы не помешал... Как думаешь, не грубо будет попросить у Мэйли?',
    dialog_herbalist_bounty_craft:
      'Никогда не переставай изготавливать предметы. Не теряй навыков!',
    dialog_herbalist_bounty_slay:
      'В последнее время подземелья стали более опасными, чем обычно. Сможешь разобраться?',
    dialog_herbalist_bounty_quest:
      'Еще вроде бы не сезон, и я сомневаюсь насчет свежести этих ингредиентов...',
    dialog_herbalist_bounty_surcharge:
      'В лекарственной лавке особое событие. Можешь добавить рекламные проспекты к продаваемым товарам?',
    dialog_herbalist_bounty_discount:
      'В лекарственной лавке особое событие. Можешь добавить рекламные проспекты к продаваемым товарам?',
    visit_herbalist_quality_00_01:
      'Сегодня у меня для тебя есть особое предложение.',
    visit_herbalist_quality_00_02:
      'В течение ограниченного времени предлагаем наши лучшие товары!',
    visit_herbalist_quality_christmas_00_01:
      'С Рождеством! У меня для тебя подарок!',
    visit_herbalist_quality_christmas_00_02: 'Не-а, сначала закрой глаза!',
    visit_herbalist_bulk_00_01:
      'На этой неделе в «Лекарственной лавке Марибель» распродажа!',
    visit_herbalist_bulk_00_02: 'Дешевые товары на развес. Не интересует?',
    visit_herbalist_bulk_christmas_00_01:
      'С Рождеством! У меня для тебя подарок!',
    visit_herbalist_bulk_christmas_00_02: 'Не-а, сначала закрой глаза!',
    thanks_herbalist_00_01: 'Забирай — это все твое. И спасибо!',
    thanks_herbalist_christmas_00_01: 'Нравится? С праздниками!',
    dialog_herbs_greetings: 'Приве-е-ет!',
    dialog_herbs_greetings_02: 'Добро пожаловать в сад! У нас весело!',
    dialog_herbs_greetings_03:
      'Еще немного — и пора будет собирать урожай! Не могу дождаться!',
    dialog_herbs_investgold: 'Ого! Спасибо!',
    dialog_herbs_investgold_02: 'Золотишко! Ура!',
    dialog_herbs_investgems: 'Просто СУПЕР!',
    dialog_herbs_investgems_02: 'Это мне? ПРАВДА?! Ты просто чудо!',
    dialog_herbs_underupgrade:
      'По колено в грязи! Только так можно что-то вырастить!',
    dialog_herbs_underupgrade_02:
      'Извини! Я вся в перегное измазана — не до грибов сейчас!',
    dialog_herbs_afterupgrade:
      'Смотри-ка! Смотри! У нас теперь есть крокусы! И тюльпаны!',
    dialog_herbs_afterupgrade_02: 'Сад теперь похож на джунгли! Вот здорово!',
    dialog_herbs_chat:
      'Тебе не кажется странным, что тут дождь никогда не идет? Просто удивительно!',
    dialog_herbs_chat_02:
      'Будь одним целым с природой — работай в саду босиком!',
    dialog_herbs_chat_03:
      'Тамас все упрашивает меня высадить эти его травки. Ну уж нет!',
    dialog_herbs_chat_04:
      'Тебе доводилось видеть, как Горза рубит лес? «БАМ! ТАРАРАМ!» Одним взмахом! Настоящее безумие!',
    dialog_herbs_chat_05:
      'Мне всегда хотелось поближе рассмотреть грибандитов... но они такие злые на вид!',
    dialog_herbs_chat_06:
      'Не забывай о заборе для грядки с живыми корнями, а то они выползут. Я не шучу!',
    dialog_herbs_chat_07:
      'Гримар за доставку неплохо платит — дорога окупается сторицей!',
    dialog_herbs_chat_08:
      'Минибосса не доводилось встретить? Они сущие проныры! И злые, к тому же!',
    dialog_herbs_chat_09:
      'НИНДЗЯ! Это так круто! Будь я лавочником, наняла бы СОТНЮ НИНДЗЯ!',
    dialog_herbs_chat_10:
      'Сэнсэи круче ниндзя... Но я никогда их не видел! Погодите, в этом-то и смысл, да?',
    dialog_herbs_chat_11:
      'Мэйли наконец-то решила заселиться! И драгоценности с собой захватила!',
    dialog_herbs_chat_12:
      'Железо КРАЙНЕ ТОКСИЧНО для фей вроде Мэйли! Будь осторожнее рядом с ней!',
    dialog_herbs_chat_13: 'Пришел странный дракон и похвалил мои цветы! КРУТО!',
    dialog_herbs_chat_14: 'БАБУШКИНЫ КОНФЕТЫ! Обязательно навести Иоланду!',
    dialog_herbs_bounty_craft: 'Ой! Ой! У меня есть запрос! Форму я заполнила!',
    dialog_herbs_bounty_slay:
      'Мою игровую площадку ЗАХВАТИЛИ! Можешь что-нибудь с этим поделать? Ну пожа-а-а-алуйста!',
    dialog_herbs_bounty_quest:
      'Вокруг просто валяется куча компонентов! Нужно их хватать! БЫСТРО!',
    dialog_herbs_bounty_surcharge: 'Пора зарабатывать ДЕНЬГИ! МНОГО ДЕНЕГ!',
    dialog_herbs_bounty_discount:
      'Скидки — это такая СТРАТЕГИЯ! Так всегда Теодор говорит!',
    visit_herbs_00_01: 'Приве-е-ет! Хорошая погодка, а?',
    visit_herbs_00_02: 'Даже СЛИШКОМ хорошая. У меня растения с ума посходили!',
    visit_herbs_00_03: 'У меня переизбыток трав! Так что налетай — подешевело!',
    visit_herbs_christmas_00_01: 'С РОЖДЕСТВОМ!',
    visit_herbs_christmas_00_02:
      'Обожаю это говорить! Поэтому хожу по домам и говорю это ВСЕМ!',
    visit_herbs_christmas_00_03:
      'А вот и твой рождественский подарок... Травы! Сама упаковала!',
    thanks_herbs_00_01: 'ОТЛИЧНО! А теперь за работу!',
    thanks_herbs_christmas_00_01: 'Отлично! Следующая – Катарина! До встречи!',
    dialog_fabric_greetings: 'О, приветствую! Неужто тебя мое пение приманило?',
    dialog_fabric_greetings_02:
      'Впечатляющий выход. Я бы даже сказал, драматический.',
    dialog_fabric_greetings_03: 'Мое почтение!',
    dialog_fabric_investgold: 'Что же, благодарю.',
    dialog_fabric_investgold_02: 'Ха! Художника не подкупишь!',
    dialog_fabric_investgems:
      'Бальзам для моей души. Богатство — помеха для искусства!',
    dialog_fabric_investgems_02: 'Поистине неожиданно.',
    dialog_fabric_underupgrade:
      'Прими мои извинения — я занят, слежу за подготовкой новой шелковой фермы. Загляни, пожалуйста, попозже.',
    dialog_fabric_afterupgrade: 'Вот и мои новые строения. Разве не прелесть?',
    dialog_fabric_chat:
      'Моя родная эльфийская роща всегда полнилась песнями и танцами. Иногда я по ней скучаю.',
    dialog_fabric_chat_02:
      'Как бы я хотел вновь посетить пещеры Авроры! Как же они прекрасны!',
    dialog_fabric_chat_03:
      'Эти мои овцы — особая порода, выведенная эльфами. Очень разборчивы в еде, но ты только потрогай их шерсть!',
    dialog_fabric_chat_04: 'Стихи любишь?',
    dialog_fabric_chat_05: '...меж солнцем и луной мерцанье звезд манит...',
    dialog_fabric_chat_06:
      'Местный народец иногда бывает... откровенно бескультурным. По-моему, только Джулия меня действительно понимает. Ну и ты, конечно.',
    dialog_fabric_chat_07:
      'Шутки Уоллеса ужасны! Омерзительны! Настоящее оскорбление для искусства! Как он только их выдумывает?',
    dialog_fabric_chat_08:
      'У моих овец возникла странная привязанность к Тамасу. Я этого так не оставлю!',
    dialog_fabric_chat_09:
      'О, незнакомка в черном! Когда же ты вернешься, чтобы вновь сразить меня наповал?',
    dialog_fabric_chat_10:
      'Неуловимая незнакомка, что рыщет во тьме... Терзаемая яростью, не знающая доброты...',
    dialog_fabric_chat_11:
      'Чудотворцы владеют оружием и магией с одинаковым изяществом. Уж точно они отличаются от других.',
    dialog_fabric_chat_12:
      'Только лучшие из чудотворцев могут мечтать о том, чтобы стать рыцарями чар.',
    dialog_fabric_chat_13:
      'Родословная прекрасной Серены гораздо богаче, чем у обычного эльфа. Даже, признаюсь, чем у меня.',
    dialog_fabric_chat_14:
      'Аватары солнца и луны поселились в нашем городе. Он поистине благословлен, даже если не считать Тамаса.',
    dialog_fabric_bounty_craft:
      'Давай, дружище. Покажи им, что такое настоящее искусство!',
    dialog_fabric_bounty_slay:
      'Товарищество, приключения, звон стали о сталь... То, о чем рассказывают в легендах! Разве не так?',
    dialog_fabric_bounty_quest:
      'За городскими воротами полно чудесных сокровищ. Если ты не против, хотелось бы что-то получить.',
    dialog_fabric_bounty_surcharge:
      'У тебя репутация красноречивого человека. Сможешь ее подтвердить?',
    dialog_fabric_bounty_discount:
      'У тебя репутация красноречивого человека. Сможешь ее подтвердить?',
    visit_fabric_00_01:
      'Мое почтение! Я, Исмаил, рисковал жизнью и здоровьем ради того, чтобы изготовить это для тебя!',
    visit_fabric_christmas_00_01:
      'В лесу родилась елочка, в лесу она росла<sprite tint=1 name=Note1>...',
    visit_fabric_christmas_00_02:
      'Песни у людей примитивные, но, признаюсь, я ею очарована.',
    visit_fabric_christmas_00_03:
      'Можешь взять в подарок эту великолепную ткань. Я в праздничном настроении!',
    thanks_fabric_00_01: 'И тысячи благодарностей недостаточно! До встречи!',
    thanks_fabric_christmas_00_01: 'Прощай, дружище. И с праздниками тебя!',
    dialog_steel_greetings: 'А! Здравствуй.',
    dialog_steel_greetings_02: 'Ох, а я тебя и не заметил.',
    dialog_steel_investgold: 'О, спасибо.',
    dialog_steel_investgems: 'Это мне? Ой, кажется, мне пыль в глаза попала...',
    dialog_steel_underupgrade:
      'Ой, извини, литейная пока закрыта. Надеюсь, это не доставит тебе особых неудобств.',
    dialog_steel_underupgrade_02: 'Выйди-ка отсюда пока, а? Тут небезопасно.',
    dialog_steel_afterupgrade: 'Плавильная печь снова заработала.',
    dialog_steel_afterupgrade_02:
      'Заходи, заходи! Мне только что подняли потолок плавильной!',
    dialog_steel_chat:
      'Я в литейной всем заправляю сам. Здесь всегда работы невпроворот, но люди вечно отвлекают...',
    dialog_steel_chat_02:
      'Та девушка-ниндзя сегодня заглядывала. Неразговорчивая такая, да?',
    dialog_steel_chat_03: 'Чем там нынче Аргон занимается? Героизмом?',
    dialog_steel_chat_04:
      'Ты как, усилителями пользуешься? В бою с боссами они просто незаменимы.',
    dialog_steel_chat_05:
      'Плавильную явно не для орков строили. Я все время головой о потолок ударяюсь...',
    dialog_steel_chat_06:
      'Эти герои-воришки неплохо с кинжалами обращаются, а?',
    dialog_steel_chat_07:
      'Ловкачи — это нечто! С такими героями мне связываться не хотелось бы!',
    dialog_steel_chat_08:
      'Э-э... А этот здоровый Рудо до сих пор поблизости ошивается? Фу-х! Ох и страшен же он на вид!',
    dialog_steel_chat_09:
      'По ночам, когда я выглядываю в окно, я вижу, как Серена смотрит на звезды... Эта бедняжка хоть когда-нибудь спит?',
    dialog_steel_chat_10:
      'Представляешь? Отец решил меня навестить! Жду с нетерпением!',
    dialog_steel_bounty_craft: 'Горожане в тебя верят!',
    dialog_steel_bounty_slay: 'Ты же не испугаешься парочки монстров, верно?',
    dialog_steel_bounty_quest: 'Обязательно прихвати мешки, чтобы все унести!',
    dialog_steel_bounty_surcharge:
      'Нет ничего постыдного в небольшой прибыли, правда?',
    dialog_steel_bounty_discount:
      'Если у тебя золотое сердце, то эта награда для тебя!',
    visit_steel_00_01:
      'Привет! Ты что-то все время в работе, я и подумал, вдруг тебе еще сталь нужна?',
    visit_steel_christmas_00_01: 'Бр-р-р! Холодно, да? Надо бы еще укутаться!',
    visit_steel_christmas_00_02: 'Но я хотел отдать тебе вот это.',
    thanks_steel_00_01: 'Так держать! Горожане в тебя верят!',
    thanks_steel_christmas_00_01:
      'Вернусь-ка я в теплую плавильню. С праздниками!',
    dialog_ironwood_greetings: 'Это срочно? У меня перерыв.',
    dialog_ironwood_greetings_02: 'Хорошо, я слушаю.',
    dialog_ironwood_greetings_03: 'Да, да. Тут я, тут.',
    dialog_ironwood_investgold: 'Ну вот, придется за книгой учета идти...',
    dialog_ironwood_investgold_02: 'Хорошо, спасибо. Это все?',
    dialog_ironwood_investgems: 'А вот на это у меня время найдется.',
    dialog_ironwood_underupgrade:
      'Придется работать сверхурочно, чтобы модернизировать лесопилку... Ну и морока.',
    dialog_ironwood_afterupgrade: 'Уф, кажется, готово. Пора прикорнуть...',
    dialog_ironwood_afterupgrade_02:
      'Лесопилка вновь заработала. Нанять, что ли, еще кого на ней пахать...',
    dialog_ironwood_chat:
      'Было бы здорово, если б Горза чуть угомонилась. С ее скоростью лесопилка просто не справляется с нагрузкой.',
    dialog_ironwood_chat_02:
      'Все еще в одиночку лавкой занимаешься? Научись распределять обязанности.',
    dialog_ironwood_chat_03:
      'Восьмичасовой рабочий день — это все железная пята буржуазии. Тамас совершенно прав.',
    dialog_ironwood_chat_04:
      'Дом Серены — это просто ведь куча камней, да? Где она спит вообще?',
    dialog_ironwood_bounty_craft:
      'Пришло время завладеть средствами производства.',
    dialog_ironwood_bounty_slay:
      'Если монстр атакует первым, это самооборона. Запомни это.',
    dialog_ironwood_bounty_quest: 'Как говорится, что нашел, то мое.',
    dialog_ironwood_bounty_surcharge:
      'Клиент всегда прав, но силу убеждения никто не отменял.',
    dialog_ironwood_bounty_discount:
      'Клиент всегда прав, но силу убеждения никто не отменял.',
    visit_ironwood_00_01:
      'Слушай, мне надо это все дотащить на тот конец города и мне ну очень лень этим заниматься.',
    visit_ironwood_00_02: 'Давай ты у меня это дело купишь?',
    visit_ironwood_christmas_00_01: 'Праздник духовной близости и щедрости...',
    visit_ironwood_christmas_00_02:
      'Хорошее время, чтобы поразмышлять об этике в наших делах, да?',
    visit_ironwood_christmas_00_03:
      'Не хочется ведь визита духа прошлого Рождества, верно?',
    thanks_ironwood_00_01:
      'Отлично. Заодно на обратном пути прикорну где-нибудь.',
    thanks_ironwood_christmas_00_01:
      'Это за мой счет. Хороших и поучительных тебе праздников.',
    dialog_oils_greetings: 'Да не стой на проходе, заходи!',
    dialog_oils_greetings_02: 'Чаю не желаешь?',
    dialog_oils_investgold: 'Спасибо!',
    dialog_oils_investgold_02: 'Тратишь карманные, дорогуша?',
    dialog_oils_investgems: 'Вот это богатство!',
    dialog_oils_investgems_02: 'Я тебя обожаю!',
    dialog_oils_underupgrade: 'О нет, только не это! Кто-то пролил масло!',
    dialog_oils_afterupgrade:
      'Я попросила Аллена улучшить кое-что в маслобойном прессе. Как тебе?',
    dialog_oils_chat:
      'Знаешь детишек Уоллеса? Они такие милашки! Только представь, даже Дархан с ними посидеть не отказывается!',
    dialog_oils_chat_02:
      'О, большого Брома бояться не стоит — он прекрасно воспитан! В отличие от его сестры, Горзы...',
    dialog_oils_chat_03:
      'Знаешь что-нибудь о фамильярах? Я слыхала, есть люди, которые учат всяких милых зверушек сражаться вместе с ними.',
    dialog_oils_chat_04:
      'Чтобы вызвать на поединок самурая, нужно быть глупцом! Первый удар у них всегда попадает в цель!',
    dialog_oils_chat_05:
      'Мое масло тоже обладает терапевтическими свойствами. Хочешь попробовать?',
    dialog_oils_chat_06:
      'У меня на родине, в Керне, самые сильные мужчины купаются в масле... Ой-ой! Опять я разболталась!',
    dialog_oils_chat_07:
      'Говорят, Рудо — эксперт по броне... Просто на бедного мальчика ничего не налезает!',
    dialog_oils_chat_08:
      'Сондра отлично разбирается во всевозможных изысканных чаях. И он истинный джентльмен!',
    dialog_oils_bounty_craft:
      'Я не могу найти это на рынке... Можешь обеспечить поставку?',
    dialog_oils_bounty_slay:
      'Я хочу увидеть твоих самых сильных героев в действии!',
    dialog_oils_bounty_quest:
      'В такой прекрасный день... нужно заниматься сбором компонентов!',
    dialog_oils_bounty_surcharge:
      'Умение торговаться - это искусство. И оно тебе не чуждо, насколько я знаю!',
    dialog_oils_bounty_discount:
      'Умение торговаться - это искусство. И оно тебе не чуждо, насколько я знаю!',
    visit_oils_00_01:
      'Дорогуша, у меня такие проблемы... Один из моих клиентов вдруг отменил крупный заказ на масло!',
    visit_oils_00_02:
      'И теперь мне негде это масло хранить. Не хочешь его у меня выкупить?',
    visit_oils_christmas_00_01:
      'Маленький эльф Санты усердно трудится, как я вижу!',
    visit_oils_christmas_00_02:
      'Не забывай время от времени делать перерыв. Рождество наступает для всех!',
    thanks_oils_00_01: 'Спасибо! Ты меня просто спасаешь!',
    thanks_oils_christmas_00_01:
      'С праздниками! Не забывай писать своим родным!',
    dialog_priest_greetings: 'Добро пожаловать в сей скромный храм Богини.',
    dialog_priest_greetings_02: 'Склоним же головы в молитве.',
    dialog_priest_unhiredgreetings: 'Добро пожаловать в храм.',
    dialog_priest_unhiredgreetings_02:
      'Я — лишь скромная служительница Богини, но Ее благословение способно даровать амулетам великую силу.',
    dialog_priest_unhiredgreetings_03:
      'Она также наделила меня способность помещать духов этого мира в магические руны...',
    dialog_priest_hirequestion: 'Примешь ли ты Богиню в своем сердце?',
    dialog_priest_investgold: 'Да пребудет с тобой благословение Богини.',
    dialog_priest_investgold_02:
      'Помни: пожертвования церкви не облагаются налогом!',
    dialog_priest_investgold_03: 'Сироты искренне тебе благодарны.',
    dialog_priest_investgold_04: 'Благословенны будь лавочники...',
    dialog_priest_investgold_05: 'Весьма щедрое пожертвование. Благодарю тебя.',
    dialog_priest_investgems: 'О!..',
    dialog_priest_investgems_02:
      'Какая щедрость!.. Благодарю тебя от имени Богини.',
    dialog_priest_underupgrade: 'Каждый кирпич — с молитвой...',
    dialog_priest_underupgrade_02:
      'Может, теперь мы сможем позволить себе витражи...',
    dialog_priest_afterupgrade:
      'Двери храма вновь открыты для всех. Добро пожаловать.',
    dialog_priest_afterupgrade_02: 'И вновь храм озарен светом Богини.',
    dialog_priest_chat: 'Придешь ли ты на сегодняшнюю мессу?',
    dialog_priest_chat_02:
      'Каждый из этих амулетов получил благословение Богини... Обращайся с ними бережно и аккуратно.',
    dialog_priest_chat_03:
      'Уоллес сегодня сделал новые игрушки для сирот. Да благословит его Богиня!',
    dialog_priest_chat_04: 'Вот бы Бром не был таким закрытым...',
    dialog_priest_chat_05:
      'Ружья — страшные инструменты войны... Думаю, Богиня против их использования.',
    dialog_priest_chat_06:
      'Мы, духовные лица, дали обет не брать в руки клинков... А вот дубинки никто не запрещал!',
    dialog_priest_chat_07:
      'Я обучила Лилу кое-какой исцеляющей магии. Она не просто одарена, она — гений.',
    dialog_priest_chat_08:
      'Рунные камни можно иногда найти в приключениях, но только ученый Эвелин знает, как их изготавливать.',
    dialog_priest_chat_09:
      'Предметы можно заколдовывать при помощи духов этого мира, чтобы даровать им чудесные свойства.',
    dialog_priest_chat_10:
      'Серена такая... уникальная, да? И, должно быть, одинокая...',
    dialog_priest_chat_11:
      'Гробницу бога солнца защищают могучие стражи, отпугивающие грабителей могил.',
    dialog_priest_bounty_craft:
      'Я хотела бы поучиться у профессионала. Можно мне посмотреть?',
    dialog_priest_bounty_slay:
      'Когда дикая природа угрожает верующим, нужно идти в крестовый поход...',
    dialog_priest_bounty_quest:
      'Если в твоем сердце есть вера, Богиня сможет привести тебя к спасению.',
    dialog_priest_bounty_surcharge:
      'Забирать у богатых и отдавать бедным — благородное дело.',
    dialog_priest_bounty_discount:
      'Немного щедрости может сделать этот мир лучше.',
    visit_priest_bulk_00_01:
      'Мое почтение... Я собираю пожертвования для храма.',
    visit_priest_bulk_00_02:
      'Не желаешь ли и ты помочь? Дарителям полагаются различные подарки!',
    visit_priest_quality_00_01:
      'Твоя доброта не знает границ! Прошлой ночью мне пришло... откровение.',
    visit_priest_quality_00_02:
      'Сама Богиня создала моими руками предмет, наделенный великой силой!',
    visit_priest_quality_00_03:
      'Мне кажется, Она хотела бы, чтобы предмет сей достался тебе. Возьмешь ли ты его?',
    visit_priest_quality_christmas_00_01:
      '...В этот святой вечер важно думать о тех, кем мы особенно дорожим.',
    visit_priest_quality_christmas_00_02:
      'Я сделала это при лунном свете. Возьмешь?',
    visit_priest_bulk_christmas_00_01:
      '...В этот святой вечер важно думать о тех, кем мы особенно дорожим.',
    visit_priest_bulk_christmas_00_02:
      'Я сделала это при лунном свете. Возьмешь?',
    thanks_priest_00_01: 'Благодарю тебя от имени Богини.',
    thanks_priest_christmas_00_01:
      'Я помолюсь о тебе Богине. Пусть она дарует тебе здоровье и постоянную удачу.',
    dialog_master_greetings: 'Приветствую.',
    dialog_master_greetings_02: 'Здравствуй.',
    dialog_master_greetings_03: 'Неплохой сегодня денек, а?',
    dialog_master_unhiredgreetings:
      'А, вот и ты. Я знал, что когда-нибудь мы с тобой наконец встретимся.',
    dialog_master_unhiredgreetings_02:
      'Местные зовут меня Мастером. Мои обширнейшие знания позволяют мне создавать редчайшее снаряжение великой силы!',
    dialog_master_hirequestion:
      'Хочешь, чтобы я забыл о заслуженном отдыхе, а?',
    dialog_master_investgold: 'Пойдет.',
    dialog_master_investgems: 'Хе-хе. Неплохо, неплохо.',
    dialog_master_underupgrade:
      'Я тут инструменты чищу. Может, заглянешь попозже?',
    dialog_master_afterupgrade:
      'Ты как раз вовремя — я только что закончил с переоборудованием.',
    dialog_master_chat:
      'Когда-то давно я был местным кузнецом. Нынче-то этим мой сын занимается, Уоллес.',
    dialog_master_chat_02:
      'Любишь настольные игры? Можно сыграть в «Олигополию», если у тебя найдется часок. Или часика три.',
    dialog_master_chat_03: 'Как насчет партейки в шахматы? Я тебя научу.',
    dialog_master_chat_04:
      'Инженер — шумная соседка, а? Впрочем, девушка она хорошая.',
    dialog_master_chat_05:
      'В подземельях иногда попадаются особенно сильные монстры... Если не путаю, их минибоссами кличут.',
    dialog_master_chat_06:
      'Молодежь и их хитроумные «автоматические шахматы»... Чем их обычные не устраивают?',
    dialog_master_chat_07:
      'Тебе уже доводилось заколдовывать предметы? Я слышал, что среди молодежи это сейчас последний писк моды.',
    dialog_master_chat_08:
      'В этих магических сундуках иногда бывают редкие и мощные чертежи стихий. Как бы мне хотелось заполучить такой!',
    dialog_master_bounty_craft:
      'Неожиданный конкурс изготовителей! Ты не против такого испытания?',
    dialog_master_bounty_slay:
      'Слишком легко для тебя? Попробуй с завязанными глазами!',
    dialog_master_bounty_quest:
      'В свое время мне было известно о тайном месте сбора там. Почему бы тебе не проверить?',
    dialog_master_bounty_surcharge:
      'Когда торгуешься, это поединок воли и ума. У тебя есть необходимые навыки?',
    dialog_master_bounty_discount:
      'Когда торгуешься, это поединок воли и ума. У тебя есть необходимые навыки?',
    visit_master_quality_00_01:
      'Приветствую. У меня для тебя есть кое-что невероятное. Отдам со скидкой.',
    visit_master_bulk_00_01:
      'Только послушай: я тут на днях с Уоллесом соревновался в мастерстве...',
    visit_master_bulk_00_02:
      'Я, конечно же, победил, но теперь у меня целая гора ненужных мне вещей. Не хочешь себе забрать?',
    visit_master_quality_christmas_00_01: 'Хо-хо-хо! С Рождеством, дитя мое!',
    visit_master_quality_christmas_00_02:
      'Я не настоящий Санта Клаус, но подарок тебе я все-таки принес!',
    visit_master_bulk_christmas_00_01: 'Хо-хо-хо! С Рождеством, дитя мое!',
    visit_master_bulk_christmas_00_02:
      'Я не настоящий Санта Клаус, но подарок тебе я все-таки принес!',
    thanks_master_00_01: 'Вот и ладно. Увидимся!',
    thanks_master_christmas_00_01:
      'Обещаю, что не расскажу Санте, как ты постоянно повышаешь цену.',
    dialog_wizard_greetings: 'Привет... Мы знакомы?',
    dialog_wizard_greetings_02: 'Тебе Лола на глаза не попадалась?',
    dialog_wizard_unhiredgreetings: 'Некоторые зовут меня... Тимом.',
    dialog_wizard_unhiredgreetings_02:
      'Я — один из величайших чародеев и умею создавать невероятно мощные заклинания и призывать стихии.',
    dialog_wizard_unhiredgreetings_03:
      '...Или свитки? Может, гримуары?.. Надо бы мне это записать. Впрочем, неважно!',
    dialog_wizard_hirequestion: 'Мои знания — к твоим услугам.',
    dialog_wizard_investgold: 'О, спасибо. Сколько, говоришь?',
    dialog_wizard_investgold_02:
      'У меня нет сдачи... А! Хочешь вложить деньги?',
    dialog_wizard_investgems:
      'Какие замечательные у тебя камушки... Самоцветы, говоришь?',
    dialog_wizard_underupgrade:
      'Я ищу редкую книгу для своей библиотеки магии... Что-то такое о некоем братстве. У тебя такой нет?',
    dialog_wizard_afterupgrade:
      'Сегодня я почему-то чувствую себя сильнее... Интересно, что я такое вчера делал?',
    dialog_wizard_afterupgrade_02:
      'Тут все какое-то другое... Что вчера такое случилось?',
    dialog_wizard_chat: 'Тебе мой посох на глаза не попадался?',
    dialog_wizard_chat_02:
      'Тот паренек, Артур, заглядывал с какой-то важной просьбой... Или это Аргон был?',
    dialog_wizard_chat_03:
      'Я — Тимофей Гримар, великий чародей и заклинатель. Мне известны многие тайны.',
    dialog_wizard_chat_04:
      'В городе появилась новая башня? Неужто к нам перебрался еще один чародей?',
    dialog_wizard_chat_05: 'Э? Фамильяры? Звучит как-то знакомо...',
    dialog_wizard_chat_06:
      'Некогда — давным-давно — я заколдовывал предметы, накладывая на них силы элементов. Или мне кажется?',
    dialog_wizard_chat_07:
      'Я где-то потерял свой дневник... Не попадался? Синий такой.',
    dialog_wizard_chat_08:
      'А, рунные камни. Кажется, я находил их в тех чудных сундуках...',
    dialog_wizard_bounty_craft: 'Хм... Это должно пригодиться... Я так думаю.',
    dialog_wizard_bounty_slay: 'В этих местах бродит опасный монстр...',
    dialog_wizard_bounty_quest:
      'Я не могу вспомнить название, но было немного похоже на... это?',
    dialog_wizard_bounty_surcharge: 'Надеюсь, ты умеешь обращаться с золотом?',
    dialog_wizard_bounty_discount: 'Надеюсь, ты умеешь обращаться с золотом?',
    visit_wizard_quality_00_01:
      'Библиотекарь, любезный, нельзя ли сдать вот эту исключительнейшую вещицу на хранение?',
    visit_wizard_quality_00_02:
      'Стоп-стоп-стоп, это же не библиотека... Сколько, говоришь, ты дашь?',
    visit_wizard_quality_christmas_00_01:
      'Странный старик живет на Северном полюсе... Делает игрушки для послушных детей...',
    visit_wizard_quality_christmas_00_02:
      'Может быть, он тоже чародей? Тайна... Настоящая тайна...',
    visit_wizard_quality_christmas_00_03:
      'О, приветствую тебя. Похоже, мои блуждания привели меня сюда.',
    visit_wizard_quality_christmas_00_04:
      'Рождественский подарок? Полагаю, можно тебе его дать.',
    visit_wizard_bulk_00_01:
      'Фрейя, вот та вещица, которая тебе была нужна... Секундочку, где это я?',
    visit_wizard_bulk_christmas_00_01:
      'Странный старик живет на Северном полюсе... Делает игрушки для послушных детей...',
    visit_wizard_bulk_christmas_00_02:
      'Может быть, он тоже чародей? Тайна... Настоящая тайна...',
    visit_wizard_bulk_christmas_00_03:
      'О, приветствую тебя. Похоже, мои блуждания привели меня сюда.',
    visit_wizard_bulk_christmas_00_04:
      'Рождественский подарок? Полагаю, можно тебе его дать.',
    thanks_wizard_00_01: 'Благодарю. Пора на поиски своего дома...',
    thanks_wizard_christmas_00_01:
      'Возможно, у меня получится связаться с этим странным стариком. Как же это делают дети?..',
    dialog_jeweler_greetings: 'Без рассматривания витрин впустую, пожалуйста.',
    dialog_jeweler_greetings_02: 'Время — деньги. Не будем его тратить зря.',
    dialog_jeweler_greetings_03: 'Поговорим о делах.',
    dialog_jeweler_unhiredgreetings:
      'Итак, что мы имеем? Эта искорка в твоих глазах... Вкус к роскоши, стилю и дорогим вещам...',
    dialog_jeweler_unhiredgreetings_02:
      'Людям твоего положения подходят лишь самые дорогие кольца.',
    dialog_jeweler_hirequestion: 'Мои цены более чем скромные.',
    dialog_jeweler_investgold: 'Все точно. Благодарю.',
    dialog_jeweler_investgold_02: 'Люблю блеск золота.',
    dialog_jeweler_investgems:
      'Тебе удалось меня заинтересовать. Я вся внимание.',
    dialog_jeweler_investgems_02: 'А ты знаешь, как умаслить ювелира.',
    dialog_jeweler_investgems_03:
      'Лазурные алмазы, круглая огранка, 25 каратов... Где тебе удалось раздобыть такую красоту?',
    dialog_jeweler_underupgrade:
      'Я тут совершенствую охранные системы. Ловушки и все такое. Все честно и законно.',
    dialog_jeweler_afterupgrade:
      'Магазин открыт. Только не трогай дорогие вещи, там ловушки спрятаны.',
    dialog_jeweler_chat:
      'Даже не думай играть с Теодором в шахматы. Он тебя разобьет в пух и прах, как и всех местных.',
    dialog_jeweler_chat_02:
      'Самоцветы особенно восприимчивы к наложению на них волшебных чар. Как по мне, у магии прекрасный вкус.',
    dialog_jeweler_chat_03:
      'Далеко не все способны воспользоваться магическими кольцами и амулетами — они требуют немалых физических и духовных сил.',
    dialog_jeweler_chat_04:
      'Ох уж эта ниндзя... Если увижу ее шныряющей вокруг моего магазина — ей не поздоровится.',
    dialog_jeweler_chat_05:
      'Не доверяю я Дархану. Копает он без всякого надзора со стороны... Уверена, все лучшее себе оставляет.',
    dialog_jeweler_chat_06:
      'Ты и представить себе не можешь, как тяжко жить по соседству с этой... которая инженер. Взрывы круглые сутки!',
    dialog_jeweler_chat_07:
      'Богатые покупатели в мелкую лавчонку заглядывать не станут. Расширяйся, иначе дорогие вещи не продашь.',
    dialog_jeweler_chat_08:
      'Глядишь, так к тебе и сам король Рейнгольд заглянет, а уж лучше покупателя и пожелать нельзя.',
    dialog_jeweler_chat_09:
      'Эта фея обосновалась... у меня на заднем дворе. Да поможет мне богиня!',
    dialog_jeweler_chat_10:
      'Лунные камни, да? Мне бы очень хотелось их заполучить.',
    dialog_jeweler_bounty_craft:
      'Я скоро ожидаю важного сдвига на рынке. Это поручение взаимовыгодно.',
    dialog_jeweler_bounty_slay:
      'Я скоро ожидаю поставку драгоценностей. Позаботься о безопасности дорог.',
    dialog_jeweler_bounty_quest:
      'В моем деле не помешал бы небольшой дефицит этих компонентов.',
    dialog_jeweler_bounty_surcharge:
      'Спрос и предложение — это две стороны очень ценной монеты.',
    dialog_jeweler_bounty_discount:
      'В моем словаре нет слова «скидка». Считай это профессиональным любопытством.',
    visit_jeweler_quality_00_01:
      'Слушай, тебе надо это видеть. Когда ко мне попала эта штуковина...',
    visit_jeweler_quality_00_02:
      '...Я тут же поняла, что тебя она обязательно заинтересует. Верно?',
    visit_jeweler_quality_christmas_00_01:
      'Пф. «Сезон подарков»... Что за фарс!',
    visit_jeweler_quality_christmas_00_02:
      'Однако ты надежный деловой партнер.',
    visit_jeweler_quality_christmas_00_03:
      'Полагаю, я могу тебе дать вот это... бесплатно. Но пусть это останется между нами.',
    visit_jeweler_bulk_00_01:
      'У меня тут завалялись золотые брелки и прочие безделушки, которые все никак не уйдут.',
    visit_jeweler_bulk_00_02:
      'Из тебя неплохой торговец. Возможно, тебе удастся добиться успеха там, где не удалось мне?',
    visit_jeweler_bulk_christmas_00_01: 'Пф. «Сезон подарков»... Что за фарс!',
    visit_jeweler_bulk_christmas_00_02: 'Однако ты надежный деловой партнер.',
    visit_jeweler_bulk_christmas_00_03:
      'Полагаю, я могу тебе дать вот это... бесплатно. Но пусть это останется между нами.',
    thanks_jeweler_00_01: 'Отлично. Пересчитаю-ка деньги.',
    thanks_jeweler_christmas_00_01:
      'Я буду ждать подарок с моим именем на нем в знак благодарности.',
    dialog_champions_greetings: 'Аргон готов к выполнению задания!',
    dialog_champions_greetings_02:
      'Привет! Не поделишься ли былинами о меркантилизме?',
    dialog_champions_investgold: 'О да! Спасибо тебе большое!',
    dialog_champions_investgems: 'Ух ты! Благодарю за доверие!',
    dialog_champions_investgems_02: 'Я тебя не подведу!',
    dialog_champions_underupgrade:
      '100 отжиманий, 100 седов, 100 приседаний, бег на 10 километров — героям надо всегда быть в форме!',
    dialog_champions_afterupgrade: 'Ого, кажется, я кое-чему у тебя научился!',
    dialog_champions_afterupgrade_02:
      'Мы разработали новую систему упражнений. Тренировочный зал вновь открыт для всех желающих!',
    dialog_champions_chat:
      'Как дела? Может есть какие приключения для настоящих героев?',
    dialog_champions_chat_02:
      'Я тут волонтером подвизаюсь — тренирую будущих героев. Обучая других, многому учишься и сам!',
    dialog_champions_chat_03:
      'Воины — одни из самых выносливых героев. Они защищают остальных, вызывая огонь на себя.',
    dialog_champions_chat_04:
      'Странники, может, и не выглядят сильными бойцами, но они прекрасно уклоняются от ударов врагов и часто наносят критический урон.',
    dialog_champions_chat_05:
      'Заклинатели, конечно, не выделяются выносливостью, зато никто не сравнится с ними по силе атаки.',
    dialog_champions_chat_06:
      'У каждого героя есть свой стиль боя — даже у тех, что принадлежат к одному классу. Да и кто знает, какие они изучат навыки?..',
    dialog_champions_chat_07:
      'Некоторые навыки героев встречаются куда реже, чем прочие... И, думаю, изученные позже навыки окажутся сильнее и полезнее начальных.',
    dialog_champions_chat_08:
      'Попробуй вооружить заклинателей чарами — именно они дают этим классам столь впечатляющую мощь!',
    dialog_champions_chat_09:
      'Если приключение окажется слишком сложным, попробуй использовать усилители. Иногда даже защитникам нужна помощь.',
    dialog_champions_chat_10:
      'Стать настоящим героем ох как непросто, но я пройду этот путь до конца!',
    dialog_champions_chat_11:
      'На днях живой корень пробрался к нам в дом через окно — ох и перепугались же все!',
    dialog_champions_chat_12:
      'Не упускай монеты защитников — с их помощью можно завести новые знакомства!',
    dialog_champions_chat_13:
      'Среди героев ходят слухи о... душе титана. Говорят, она может раскрыть истинный потенциал в ком угодно!',
    dialog_champions_bounty_craft:
      'Герои могли бы воспользоваться чем-нибудь из этого!',
    dialog_champions_bounty_slay:
      'Герои-новички готовы к своей первой вылазке. Покажешь им окрестности?',
    dialog_champions_bounty_quest:
      'Можно отправляться навстречу приключениям! Как насчет этих компонентов?',
    dialog_champions_bounty_surcharge: 'Ты сможешь, дружище! Я верю в тебя!',
    dialog_champions_bounty_discount:
      'Героям иногда не помешает небольшая скидка!',
    dialog_townhall_greetings: 'Ах, молодежь... Постой-ка, да послушай.',
    dialog_townhall_greetings_02:
      'А? Э? Что-что? Слух у меня все хуже и хуже...',
    dialog_townhall_greetings_03: 'Как ты бодро шагаешь!',
    dialog_townhall_investgold: 'Хорошо... Даже очень хорошо!',
    dialog_townhall_investgold_02: 'О! Пенсия!',
    dialog_townhall_investgems: 'Ах, эта бьющая ключом молодость...',
    dialog_townhall_underupgrade:
      'А, это ты. В городе строятся несколько новых лавок!',
    dialog_townhall_afterupgrade:
      'Мы расширили торговый квартал. Если вдруг знаешь кого из торговцев, смело приглашай их в наш город.',
    dialog_townhall_afterupgrade_02: 'В ратуше вновь кипит жизнь!',
    dialog_townhall_chat:
      'Для использования качественного снаряжения героям необходимы тренировки. Безопасность прежде всего!',
    dialog_townhall_chat_02:
      'Вот смотрю я на тебя — деньги вкладываешь, друзьями обзаводишься — и даже гордость испытываю.',
    dialog_townhall_chat_03:
      'В городе последнее время замечают некую загадочную ниндзя... ты ее, часом, не знаешь?',
    dialog_townhall_chat_04:
      'Как идут дела в твоей гильдии? Кстати, не забывай иногда вкладываться в развитие города.',
    dialog_townhall_chat_05:
      'Если ты решишь сменить гильдию, все твои вложения сохранятся — беспокоиться о них не стоит.',
    dialog_townhall_chat_06:
      'Странные золотые монеты собираешь? Кажется, их очень любят защитники.',
    dialog_townhall_chat_07:
      'Великие волшебники весьма разборчивы и не станут работать на первого встречного, но их сила атаки поистине поражает.',
    dialog_townhall_chat_08:
      'Тебе же доводилось слышать о чернокнижниках? Они приводят в ужас даже волшебников... Но такая сила дается дорогой ценой.',
    dialog_townhall_chat_09:
      'Город явно оживился с появлением нового ресторана и пекарни!',
    dialog_townhall_bounty_craft: 'Выслушаешь просьбу старика?',
    dialog_townhall_bounty_slay:
      'Эти монстры доставляют неприятности честным горожанам. Можешь разобраться с этим?',
    dialog_townhall_bounty_quest:
      'Как же это сюда попало? В технологиях я разбираюсь плохо...',
    dialog_townhall_bounty_surcharge: 'Таково искусство выгодной торговли...',
    dialog_townhall_bounty_discount:
      'Припоминаю время, когда меч сквайра стоил всего 1 золотой!',
    churn_00_01: 'Ага, лавочник!',
    churn_00_02:
      'Поскольку ты в последнее время много работаешь, горожане скинулись тебе на подарок.',
    churn_00_03: 'Не стесняйся, открывай!',
    dialog_tavern_greetings: 'Твое любимое место вон там, дорогуша.',
    dialog_tavern_greetings_02: 'О чем болтают на улицах?',
    dialog_tavern_greetings_03: 'Что тебе принести?',
    dialog_tavern_greetings_04: 'Тебе как обычно?',
    dialog_tavern_greetings_05: 'Сегодня в меню твое любимое рагу!',
    dialog_tavern_greetings_06: 'А, привет! Обожди-ка минутку!',
    dialog_tavern_greetings_07: 'Порцию супа из грибандитов? Сей момент!',
    dialog_tavern_investgold: 'Благодарю.',
    dialog_tavern_investgold_02: 'Спасибо, дорогуша.',
    dialog_tavern_investgems: 'Пусть это останется между нами.',
    dialog_tavern_investgems_02: 'Другого я от тебя и не ожидала.',
    dialog_tavern_underupgrade: 'У нас ремонт, так что внутри сущий кавардак.',
    dialog_tavern_underupgrade_02:
      'В таверне ремонт, так что сегодня мы обслуживаем всех снаружи.',
    dialog_tavern_afterupgrade: 'Заходи! У нас новое меню!',
    dialog_tavern_afterupgrade_02:
      'Добро пожаловать в нашу обновленную таверну!',
    dialog_tavern_chat:
      'Говорят, если улучшить стойки, у тебя будет больше энергии. Это правда?',
    dialog_tavern_chat_02: 'Йети из пещер Авроры такие милые, правда?',
    dialog_tavern_chat_03:
      'Горза-то некогда была великой воительницей, знаешь? По-моему, быть лесорубом ей нравится куда больше, пусть она это и отрицает.',
    dialog_tavern_chat_04:
      'Пещеры Авроры пользуются в округе большой популярностью в качестве места для свиданий. Ночью они выглядят просто феерично!',
    dialog_tavern_chat_05:
      'При наличии достаточных средств я могу посылать экспедиции на исследование подземелий. Так тебе будет проще во время приключений!',
    dialog_tavern_chat_06:
      'Горза, как наберется немного, иногда начинает буянить. Хорошо, что ее брат куда спокойнее.',
    dialog_tavern_chat_07:
      'На поиски приключений можно отправить и неполную команду, но им понадобится больше времени.',
    dialog_tavern_chat_08:
      'Знаешь мою сестру, Майю? Она «Колесом Фортуны» заправляет.',
    dialog_tavern_chat_09:
      'Монахи — чудаки какие-то, а? Оружия и доспехов не носят, и все кулаками молотят.',
    dialog_tavern_chat_10:
      'Ты не поверишь, но... на днях мне довелось видеть Великого магистра! Представить не могу, через какие испытания должен пройти монах...',
    dialog_tavern_chat_11:
      'Не знаю, как Иоланда это делает, но она умудряется воспитывать монстров и при этом не расставаться с вязанием.',
    dialog_tavern_bounty_craft:
      'Я подслушала кое-что интересное в таверне. Ты можешь извлечь из этого выгоду!',
    dialog_tavern_bounty_slay:
      'Там бродит несколько уродов, которым лучше исчезнуть, верно ведь?',
    dialog_tavern_bounty_quest:
      'Мне только что шепнули насчет особо изобильных мест в этом регионе.',
    dialog_tavern_bounty_surcharge:
      'Подмигнуть и улыбнуться — вот и все, что нужно.',
    dialog_tavern_bounty_discount: 'Главное в том, чтобы клиенты возвращались!',
    visit_tavern_00_01:
      'Привет! Сюрприз, а? Отлучилась ненадолго к тебе заглянуть.',
    visit_tavern_00_02: 'Но вообще у меня для тебя кое-что есть...',
    visit_tavern_01_01: 'Привет, дорогуша. Не скучаешь по мне, а?',
    visit_tavern_01_02:
      'Сегодня у меня для тебя есть кое-что особенное. Подвыпившие посетители иногда оставляют на чай самые странные вещи...',
    visit_tavern_christmas_00_01:
      'Я обожаю зимние праздники! Снег, украшения...',
    visit_tavern_christmas_00_02:
      '...И конечно же, куча народу в таверне, которая усердно дает чаевые!',
    visit_tavern_christmas_00_03:
      'Полагаю, можно дать тебе подарок. Это же все-таки Рождество!',
    thanks_tavern_00_01: 'Пусть это будет между нами!',
    thanks_tavern_01_01:
      'Думаю, не стоит об этой сделке никому рассказывать, а? Пусть она будет нашим общим секретом!',
    thanks_tavern_christmas_00_01: 'С праздниками и с Новым годом!',
    dialog_inn_greetings:
      'Со всеми этими героями, зачастившими в город, дела в таверне идут просто отлично.',
    dialog_inn_greetings_02: 'О, здравствуй! Заходи, заходи!',
    dialog_inn_greetings_03: 'А я знал, что ты заглянешь!',
    dialog_inn_investgold:
      'Прими благодарности от имени нашего постоялого двора!',
    dialog_inn_investgold_02: 'От тебя всегда самые щедрые чаевые, ха-ха-ха!',
    dialog_inn_investgold_03: 'Великолепно! Прямо по семейному рецепту!',
    dialog_inn_investgems: 'Ха-ха-ха! Давненько я этих камушков не видал!',
    dialog_inn_investgems_02:
      'О, они напоминают мне о старом добром времени, когда я еще был искателем приключений!',
    dialog_inn_underupgrade:
      'Наверно, в этот раз стоит посоветоваться насчет обоев с Сиа...',
    dialog_inn_underupgrade_02:
      'Запомни мои слова: как закончим, сам король захочет у нас поселиться!',
    dialog_inn_afterupgrade:
      'Постоялый двор открыт. Может, в следующий раз поставим джакузи! Ха-ха-ха!',
    dialog_inn_afterupgrade_02:
      'Новые комнаты, новые кровати — добро пожаловать в новый «Изумруд»!',
    dialog_inn_chat:
      'Предводителем йети становится тот из них, который найдет самую большую сосульку. Удивительные они создания!',
    dialog_inn_chat_02:
      'Летающий остров — настоящая загадка. Насколько я знаю, там пока никто еще не бывал.',
    dialog_inn_chat_03:
      'Последнее время у меня на постоялом дворе проживает некая таинственная личность. Даже имени не знаю.',
    dialog_inn_chat_04:
      'Чтобы привлечь покупателей, нужна лавка покрупнее! И побольше всякой всячины на продажу на прилавках!',
    dialog_inn_chat_05:
      'Защитники по-настоящему сильны. Им даже снаряжение не нужно!',
    dialog_inn_chat_06:
      'Часто слышу, как Исмаил за работой поет — как раз напротив. И поет он отлично.',
    dialog_inn_chat_07:
      'Знаешь, когда-то давно я был варваром. Бьюсь об заклад, у меня где-то мой старый топор завалялся...',
    dialog_inn_chat_08:
      'У этой феи Мэйли впечатляющая коллекция монет. Как драконий клад, только все старательно разложено!',
    dialog_inn_chat_09:
      'Руины Кроноса? Я слышал от бабушки, что когда-то это была столица великой империи... А потом бац — и все мигом исчезло!',
    dialog_inn_chat_10:
      'В последнее время такая шумиха поднялась из-за Потерянного города золота. А тебе доводилось о нем слышать?',
    dialog_inn_chat_11:
      'Ресторан Туту Мано привел к жесткой конкуренции! Надеюсь, ты дашь шанс нашему постоялому двору!',
    dialog_inn_bounty_craft:
      'Я обожал такое, когда отправлялся в приключения. Не изготовишь для меня немного в память о старых днях?',
    dialog_inn_bounty_slay:
      'Я сделал крупную ставку, что ты справишься. Не подведи меня!',
    dialog_inn_bounty_quest:
      'Постоялый двор сейчас переполнен героями. Можешь сделать мне одолжение и организовать несколько экспедиций?',
    dialog_inn_bounty_surcharge:
      'У меня есть несколько клиентов, у которых денег больше, чем ума. Хочешь, я отправлю их к тебе?',
    dialog_inn_bounty_discount:
      'Ты предложишь моим посетителям скидку, а я отправлю их к тебе! Как насчет такой сделки?',
    visit_inn_00_01:
      'О, дружище, я тут откопал свои старые приключенческие припасы.',
    visit_inn_00_02:
      'Но они мне нынче ни к чему. Может, пригодятся в подарок кому из героев?',
    visit_inn_christmas_00_01: 'Ах, наконец-то тишина и спокойствие!',
    visit_inn_christmas_00_02:
      'Во время праздников на постоялом дворе «Изумруд» довольно людно!',
    visit_inn_christmas_00_03:
      'Я решил прийти сюда, чтобы немного передохнуть и поболтать, если ты не против.',
    visit_inn_christmas_00_04: 'Ой, можешь забрать вот это.',
    thanks_inn_00_01: 'Всегда приятно иметь с тобой дело!',
    thanks_inn_01_01:
      'Благодарствую. Заглядывай в «Изумруд», если что. Для тебя наши двери всегда широко открыты!',
    thanks_inn_02_01: 'Спасибо! Пойду-ка обратно на постоялый двор. Увидимся!',
    thanks_inn_christmas_00_01: 'Пойду-ка я назад. С Рождеством!',
    dialog_mana_greetings:
      'И вот ты предо мной... как и предсказано в пророчестве.',
    dialog_mana_greetings_02: 'Твое присутствие — добрый знак.',
    dialog_mana_greetings_03: 'Встреча, несущая благо.',
    dialog_mana_investgold: '...Отплати за открытость той же монетой...',
    dialog_mana_investgems: 'Удивительнейшая аура щедрости!..',
    dialog_mana_investgems_02: 'Звезды не ошиблись!',
    dialog_mana_underupgrade:
      '...Я работаю с энергетическими линиями. Прошу, загляни позже.',
    dialog_mana_underupgrade_02:
      'В ближайшем будущем я предвижу... появление улучшенного эфирного колодца...',
    dialog_mana_afterupgrade:
      'Течение эфира возобновилось. Воспользуйся им с умом.',
    dialog_mana_afterupgrade_02: 'Эфир без помех течет к жилищу твоему.',
    dialog_mana_chat: 'Видеть суть вещей — и дар, и проклятие.',
    dialog_mana_chat_02:
      'Кудесника окружает сильная магия... но принадлежит она ему лишь частично.',
    dialog_mana_chat_03:
      'Копатель испытывает судьбу каждым ударом своей кирки... Негоже играть с энергетическими линиями.',
    dialog_mana_chat_04:
      'Судьба приготовила юному рыцарю и его решимости немало испытаний.',
    dialog_mana_chat_05:
      'Преследующей стоит поберечься: тот, кем движет месть, легко может потерять себя.',
    dialog_mana_chat_06: '...Эфирный колодец не место для туристов.',
    dialog_mana_chat_07:
      'Обратись к небесам и попроси звезды указать тебе путь... или же обрати взор внутрь себя и поищи мудрость там.',
    dialog_mana_chat_08:
      'Я лишь вестница воли судьбы... Маяк среди бурного ливня возможных грядущих событий.',
    dialog_mana_chat_09:
      'Пусть пророчества не затуманивают твой разум. Рука судьбы доведет тебя до места назначения.',
    dialog_mana_chat_10:
      'Империя, что играет с нитями самого времени... Гордость стала пеплом, спесь превратилась в руины.',
    dialog_mana_chat_11:
      'Потерянный город золота... Эфемерный парадокс в сумерках миров.',
    dialog_mana_bounty_craft:
      'Обильное богатство ждет... если торговец воспользуется этой возможностью.',
    dialog_mana_bounty_slay:
      'Падение тирана предсказано. Судьба на твоей стороне, но соблюдай осторожность.',
    dialog_mana_bounty_quest:
      'Вон там полно чудес природы, ждущих того, у кого храброе сердце и умелые руки.',
    dialog_mana_bounty_surcharge:
      'В повседневном заключен скрытый потенциал, который можно заметить опытным глазом.',
    dialog_mana_bounty_discount:
      'Судьба позаботится о том, чтобы щедрая рука была своевременно вознаграждена.',
    visit_mana_christmas_00_01:
      'Дух подарков приближается, согревая сердца людей и эльфов.',
    visit_mana_christmas_00_02: 'Я обязана, если торговец позволит.',
    visit_mana_00_01: 'Судьба привела меня сюда, ибо нуждаешься ты в помощи...',
    visit_mana_01_01:
      'Эфир волнуется, ища прибежища в твоих закромах. Примешь ли ты его?',
    thanks_mana_christmas_00_01:
      'Я предвижу, что с наступлением следующего года торговца ожидают богатство и новые возможности.',
    thanks_mana_00_01: 'Нити судьбы неразрывны.',
    thanks_mana_01_01: 'Неясно будущее, но мы еще увидимся... Я знаю точно.',
    dialog_gems_greetings:
      'Это же торговый человек! Привет!<sprite tint=1 name=Note1>',
    dialog_gems_greetings_02: 'Привет! Монеты есть?!',
    dialog_gems_greetings_03: 'Я здесь!<sprite tint=1 name=Note2>',
    dialog_gems_investgold: 'Человеческая валюта!<sprite tint=1 name=Note1>',
    dialog_gems_investgold_02:
      'Ого! Так много монет!<sprite tint=1 name=Note2>',
    dialog_gems_investgems:
      'Кристаллы — это мое любимое!<sprite tint=1 name=Note1> Откуда ты это знаешь?',
    dialog_gems_investgems_02:
      'Спасибо!<sprite tint=1 name=Note1><sprite tint=1 name=Note2>',
    dialog_gems_underupgrade:
      'Вся в делах, вся в делах! Приходи попозже!<sprite tint=1 name=Note2>',
    dialog_gems_afterupgrade:
      'У меня теперь так много алмазов!<sprite tint=1 name=Note1> Вот, возьми!<sprite tint=1 name=Note2>',
    dialog_gems_chat:
      '«Йоу, как делишки?»<sprite tint=1 name=Note1> Тамас научил меня этому человеческому приветствию!<sprite tint=1 name=Note2>',
    dialog_gems_chat_02:
      'У людей такие замечательные поселения! В них такая бурная деятельность!<sprite tint=1 name=Note1>',
    dialog_gems_chat_03:
      'Люди всегда приветствуют друг друга и спрашивают, как дела, а отвечают всегда одинаково... Почему?',
    dialog_gems_chat_04:
      'Это море? Оно никогда не унимается!<sprite tint=1 name=Note2>',
    dialog_gems_chat_05:
      'Обменивать блестящие монеты на предметы... В городе все играют в эту игру?<sprite tint=1 name=Note2>',
    dialog_gems_chat_06:
      'Тут есть какой-то глупыш в золотой короне!<sprite tint=1 name=Note1> Смешной, правда?<sprite tint=1 name=Note2>',
    dialog_gems_chat_07:
      'Я видела людей в железной одежде! Как такое возможно?!',
    dialog_gems_chat_08:
      'А люди празднуют мудреный вторник?.. А веселую пятницу?',
    dialog_gems_chat_09:
      'Разве не утомительно везде ходить пешком?<sprite tint=1 name=Note2>',
    dialog_gems_chat_10:
      'Играть в короля вроде весело! А когда моя очередь?<sprite tint=1 name=Note1>',
    dialog_gems_bounty_craft:
      'Ух ты!<sprite tint=1 name=Note1> Мне всегда хотелось рассмотреть такое поближе!<sprite tint=1 name=Note2>',
    dialog_gems_bounty_slay: 'Ура! Экскурсия! <sprite tint=1 name=Note1>',
    dialog_gems_bounty_quest:
      'Мои чувства пришли в трепет! Здесь неподалеку скрыты какие-то ресурсы!<sprite tint=1 name=Note1>',
    dialog_gems_bounty_surcharge:
      'Это вот так можно получить человеческую валюту?<sprite tint=1 name=Note2> Вроде бы легко!',
    dialog_gems_bounty_discount:
      'Играть роль торговца вроде бы интересно! Научи меня этому!<sprite tint=1 name=Note2>',
    visit_gems_00_01:
      'Привет-привет!<sprite tint=1 name=Note2> Я принесла тебе алмазов!<sprite tint=1 name=Note1>',
    visit_gems_00_02:
      '...Катарина сказала мне всегда менять их на монеты. У тебя есть?<sprite tint=1 name=Note1>',
    visit_gems_01_01:
      'Торговый человек! Я слышала, Джунипер регулярно доставляет тебе травы...',
    visit_gems_01_02:
      'Мне тоже!<sprite tint=1 name=Note1> Купи у меня алмазы!<sprite tint=1 name=Note2>',
    visit_gems_christmas_00_01:
      'Привет! С днем рождения!<sprite tint=1 name=Note2>',
    visit_gems_christmas_00_02:
      'Что, Рождество? Это же вечеринка, да? А в чем разница?',
    visit_gems_christmas_00_03:
      'Я так и думала! Вот тебе подарок ко дню рождения!<sprite tint=1 name=Note1>',
    thanks_gems_00_01:
      'Спасибо! Торговать так весело!<sprite tint=1 name=Note1>',
    thanks_gems_01_01:
      'У тебя было столько монет?! Тебе надо делиться ими почаще!<sprite tint=1 name=Note2>',
    thanks_gems_christmas_00_01:
      'Ура! Жду не дождусь своего дня рождения!<sprite tint=1 name=Note1>',
    trial_engineer_01: 'О, привет! А я тебя ищу!',
    trial_engineer_02:
      'Сегодня мне хочется проявить щедрость, поэтому на какое-то время я предлагаю свои услуги бесплатно.',
    trial_engineer_03:
      'Другими словами, ты можешь создавать <c1>ружья</c1> и <c1>арбалеты</c1>!',
    trial_engineer_04:
      'Я даже повожусь с твоими предметами, чтобы добавить <c1>значительное снижение шанса повреждения</c1>.',
    trial_engineer_05:
      'Не надо меня благодарить. Я делаю все это во имя науки!',
    dialog_engineer_greetings: 'Хочешь что-нибудь подорвать?',
    dialog_engineer_unhiredgreetings: 'Изменились планы?',
    dialog_engineer_unhiredgreetings_02:
      'Мощь <c1>ружей</c1> и <c1>арбалетов</c1> — в твоих руках!',
    dialog_engineer_unhiredgreetings_03:
      'И не забывай — моя халтурка <c1>защищает вещи от поломок</c1>!',
    dialog_engineer_hirequestion: 'Науке не откажешь!',
    dialog_engineer_investgold: 'Не откажусь.',
    dialog_engineer_investgems: 'Ух ты, как блестит!',
    dialog_engineer_underupgrade:
      'Я кое-чем в лаборатории занимаюсь. Ничего не трогай — особенно всякие необычные штуки.',
    dialog_engineer_afterupgrade:
      'Лаборатория — теперь огнеупорная — вновь в работе.',
    dialog_engineer_chat: '...Щепотка серы и... бабах! Работает, как часы.',
    dialog_engineer_chat_02: 'Хочешь на шум пожаловаться?',
    dialog_engineer_chat_03:
      'Разница между развлечением и занятием наукой в том, что во втором случае ты все записываешь.',
    dialog_engineer_chat_04:
      'Ну как моя халтурка? Если какая-то вещь еще сломалась — считай, это моя заслуга!',
    dialog_engineer_chat_05: '«Бабах» или «бум»? Лично я предпочитаю «бум».',
    dialog_engineer_chat_06:
      'Академия? Кучка ботанов! Учишься всему, когда что-то делаешь, а не читаешь!',
    dialog_engineer_chat_07: 'Ружье... стреляющее ружьями?.. Гениально!',
    dialog_engineer_chat_08:
      'Мои ружья безопасны на 35%! Показателя лучше ты нигде не найдешь!',
    dialog_engineer_chat_09:
      'Слишком много боеприпасов лежит без дела? Попробуй прототип пулемета Гатлинга!',
    dialog_engineer_chat_10:
      'Летающий остров? Это на 10 миллиардов процентов невозможно с научной точки зрения. Поверь мне, я пыталась.',
    dialog_engineer_bounty_craft:
      'Я думала о потенциальных улучшениях для этого чертежа. Попробуешь?',
    dialog_engineer_bounty_slay:
      'Пришло время для полевых испытаний! Я знаю отличное место, где полно живых целей...',
    dialog_engineer_bounty_quest:
      'Пришло время для полевых испытаний! Я знаю отличное место, где полно живых целей...',
    dialog_engineer_bounty_surcharge:
      'Вперед, займись этим. А я буду делать заметки.',
    dialog_engineer_bounty_discount:
      'Только на ружья скидок не делай. Мои творенья достойны полной цены!',
    visit_engineer_quality_00_01:
      'Только посмотри на эту пушку! Она прямо излучает боевой потенциал!',
    visit_engineer_quality_00_02: 'По-моему, ее пора как следует испытать, а?',
    visit_engineer_bulk_00_01:
      'Слушай, у меня в лаборатории валяются всякие опытные образцы — тебе не нужны, часом?',
    visit_engineer_bulk_christmas_00_01: 'Насчет полевых испытаний...',
    visit_engineer_bulk_christmas_00_02:
      'Что, праздники? У науки не бывает выходных.',
    visit_engineer_bulk_christmas_00_03:
      'А-а, ладно, можешь забрать бесплатно!',
    visit_engineer_quality_christmas_00_01: 'Насчет полевых испытаний...',
    visit_engineer_quality_christmas_00_02:
      'Что, праздники? У науки не бывает выходных.',
    visit_engineer_quality_christmas_00_03:
      'А-а, ладно, можешь забрать бесплатно!',
    thanks_engineer_00_01: 'Ну, пора мне назад к кульману!',
    thanks_engineer_christmas_00_01:
      'С праздниками и все такое. А я обратно в лабораторию!',
    trial_academy_01: 'Добрый день! У меня хорошие новости!',
    trial_academy_02:
      'В течение ограниченного времени академия предоставляет бесплатные услуги торговцам...',
    trial_academy_03:
      'Это значит, что ты сможешь изготавливать <c1>волшебные палочки</c1> и даже <c1>рунные камни</c1>!',
    trial_academy_04:
      'А также получишь <c1>шанс изготовить несколько предметов с помощью заклинаний</c1>! Разве не здорово?',
    trial_academy_05: 'Буду рад поработать вместе!',
    dialog_academy_greetings: 'Да? Прежде чем говорить, нужно поднять руку.',
    dialog_academy_greetings_02: 'Прошу занять место — звонок уже прозвучал.',
    dialog_academy_unhiredgreetings: 'Тебе удалось обдумать мое предложение?',
    dialog_academy_unhiredgreetings_02:
      'Благодаря моим продвинутым урокам ты будешь получать <c1>бонусный опыт</c1>.',
    dialog_academy_unhiredgreetings_03:
      'С моей помощью ты сможешь изготавливать <c1>мощные палочки</c1> или <c1>рунные камни</c1> для колдовства.',
    dialog_academy_unhiredgreetings_04:
      'Хочешь освоить следующий уровень колдовства?',
    dialog_academy_investgold:
      'Не следует думать, что так можно повысить оценки!',
    dialog_academy_investgold_02: 'Ой! Дай-ка я возьму свои счеты!',
    dialog_academy_investgold_03: 'Вклад в образование — это вклад в будущее!',
    dialog_academy_investgems: 'Хм... Пять с плюсом.',
    dialog_academy_underupgrade: 'Урок уже начался. Приходите позже!',
    dialog_academy_afterupgrade: 'Каникулы закончились! Понравилось отдыхать?',
    dialog_academy_chat:
      'Мои волосы ведь не похожи на эльфийское дерево... или похожи?',
    dialog_academy_chat_02:
      'Здесь в академии я много чего преподаю. Заклинательство, создание рун... даже поэзию!',
    dialog_academy_chat_03:
      'Эта Лилу... Она посещает все занятия до единого. Даже факультативные!',
    dialog_academy_chat_04:
      'Тебе не приходило в голову начать преподавать в академии экономику? Похоже, ты в этом деле эксперт.',
    dialog_academy_chat_05:
      'Волшебная палочка — вещь капризная. Если чародей ей не понравился, работать не будет!',
    dialog_academy_chat_06:
      'Каким бы хвастуном Аллан ни был, но оценки у него совсем не фонтан.',
    dialog_academy_chat_07:
      'Уроки Гримара — это нечто! Хотя он иногда бывает ненадежным...',
    dialog_academy_chat_08:
      'В данный момент я работаю над несколькими диссертациями. Моя 12-я докторская уже не за горами!',
    dialog_academy_chat_09:
      'Я автор книги «Злодейский справочник злословия». Не доводилось о такой слышать?',
    dialog_academy_chat_10:
      'Академия предлагает совместную программу обучения с тренировочным залом. Героям нужно быть и умными тоже!',
    dialog_academy_chat_11:
      'Заколдованные предметы становятся более ценными. И уж точно гораздо более мощными!',
    dialog_academy_chat_12:
      'Феи никогда не осмеливаются выходить за пределы лесов... Почему же Мэйли покинула свой?',
    dialog_academy_chat_13:
      'Бабушка Иоланда? Должно быть, она могущественная, раз создает жизнь с помощью магии.',
    dialog_academy_bounty_craft:
      'Вот сегодняшний экзамен. Он не составит труда, если ты знаешь материал.',
    dialog_academy_bounty_slay:
      'Я организовываю несколько экскурсий со своим классом. Можешь нас сопровождать?',
    dialog_academy_bounty_quest:
      'Мне совершенно необходимо изучить это в естественной среде обитания!',
    dialog_academy_bounty_surcharge:
      'Мне нужен эксперт по взаимодействию с клиентами для дружественной оценки.',
    dialog_academy_bounty_discount:
      'У меня есть интересная теория микроэкономики, которую я хочу проверить...',
    visit_academy_00_01:
      'Добрый день! Я пришла за твоей не сданной вовремя домашней работой...',
    visit_academy_00_02: 'Да шучу я! Вот тебе несколько предметов от меня!',
    visit_academy_01_01:
      'Я как раз закончила изучать этот исключительный предмет.',
    visit_academy_01_02: 'Академии он больше не нужен. Не хочешь купить?',
    visit_academy_christmas_00_01:
      'С праздниками! Проводишь каникулы в свое удовольствие?',
    visit_academy_christmas_00_02:
      'Я принесла подарки для своих любимых учеников. Вот твой!',
    thanks_academy_00_01: 'Спасибо! И не забудь сделать домашнюю работу!',
    thanks_academy_01_01: 'Благодарю тебя от имени академии.',
    thanks_academy_christmas_00_01:
      'Сейчас сезон гриппа, так что не забывай регулярно мыть руки!',
    dialog_sundragon_greetings:
      'Мои драконьи чувства обострились... Я слышу звон золотых монет?',
    dialog_sundragon_greetings_02:
      'В башне Солнца рады приветствовать любое человеческое дитя.',
    dialog_sundragon_greetings_03: 'Что беспокоит тебя, дитя мое?',
    dialog_sundragon_greetings_04: 'Говори. Солнечный дракон слушает.',
    dialog_sundragon_investgold: 'Золотые монеты, да? Подойдет!',
    dialog_sundragon_investgold_02: 'Благодарю.',
    dialog_sundragon_investgems: 'Равновесие восстановлено.',
    dialog_sundragon_investgems_02:
      'Я на очень строгой диете, но все равно спасибо!',
    dialog_sundragon_underupgrade:
      'Приношу свои извинения, башня Солнца сейчас собирает солнечный свет.',
    dialog_sundragon_afterupgrade:
      'Башня Солнца претерпела некоторые изменения, что должно происходить со всем.',
    dialog_sundragon_chat: 'Что может быть лучше, чем загорать на солнце?',
    dialog_sundragon_chat_02:
      'Я ощущаю прекрасный фэн-шуй от городской площади. Это не твоя лавка?',
    dialog_sundragon_chat_03:
      'Я должен похвалить Джулию за эту чудесную одежду. На драконов шить нелегко.',
    dialog_sundragon_chat_04: 'Слава солнцу.',
    dialog_sundragon_chat_05:
      'Золото, деньги... Человеческая жизнь была бы проще, если бы вы просто спали на своих сокровищах, а не тратили их!',
    dialog_sundragon_chat_06:
      'Лундра почти не понимает принципы синтеза, но ее дикие эксперименты привели к ошеломляющим открытиям.',
    dialog_sundragon_chat_07:
      'Благословение Лундры столь же мощное, как и само полнолуние. Тебе следует завоевать ее доверие, хотя это и нелегко.',
    dialog_sundragon_chat_08:
      'Не знаю, откуда у Лундры неприязнь к боссам, но это чувство настолько сильное, что пропитывает снаряжение, которое она создает!',
    dialog_sundragon_chat_09:
      'Хотя по поведению Лундры и не скажешь, но она старше. Правда, всего на несколько тысяч лет.',
    dialog_sundragon_chat_10:
      'Как тебе котел синтеза? Таких в мире не так уж и много.',
    dialog_sundragon_chat_11:
      'Магическая книга, которая сама записывает информацию о полученных предметах... Неплохо, да?',
    dialog_sundragon_chat_12:
      'Красные драконы и им подобные — это свирепые звери, движимые алчностью. Процветание этого города может привлечь их внимание...',
    dialog_sundragon_bounty_craft:
      'Я не прочь использовать несколько прекрасных предметов для котла синтеза...',
    dialog_sundragon_bounty_slay:
      'Сильное нарушение равновесия поразило эту экосистему...',
    dialog_sundragon_bounty_quest:
      'Человеческое дитя, тебя ждет величайшее испытание.',
    dialog_sundragon_bounty_surcharge: 'Смиренный человек забирает.',
    dialog_sundragon_bounty_discount: 'Смиренный человек дает.',
    visit_sundragon_bulk_00_01:
      'Приветствую, человеческое дитя. Что думаешь об этой подборке предметов?',
    visit_sundragon_bulk_00_02: 'Думаю, у них большой потенциал для синтеза!',
    visit_sundragon_bulk_christmas_00_01: 'Бр-р-р! Здесь холодно!',
    visit_sundragon_bulk_christmas_00_02:
      'Тем не менее, я хотел принести тебе вот это! С праздником... как там его?',
    thanks_sundragon_00_01: 'Слава солнцу.',
    thanks_sundragon_01_01: 'Благодарю.',
    thanks_sundragon_christmas_00_01:
      'А, с Рождеством! Я еще не привык к этим человеческим обычаям...',
    dialog_moondragon_greetings: 'Кто посмел разбудить меня?',
    dialog_moondragon_greetings_02: 'Сначала дар, потом болтовня.',
    dialog_moondragon_greetings_03:
      'Что привело тебя сюда, теплокровное создание?',
    dialog_moondragon_greetings_04: 'Ты в моих владениях. Ступай осторожно.',
    dialog_moondragon_investgold: 'Дар принят.',
    dialog_moondragon_investgold_02: 'Подходящее подношение.',
    dialog_moondragon_investgems:
      'Это будет про запас. Может быть, позже попробую.',
    dialog_moondragon_investgems_02: 'Как они приятно блестят.',
    dialog_moondragon_underupgrade:
      'Лунная башня сейчас собирает лунную энергию. Посетителям вход воспрещен.',
    dialog_moondragon_afterupgrade:
      'Вытирай ноги, я тут только что прибралась.',
    dialog_moondragon_chat:
      'А тебе не приходило в голову побеспокоить моего глупого братца?',
    dialog_moondragon_chat_02:
      'Мое появление очаровало тебя, словно восход луны? Очень хорошо понимаю.',
    dialog_moondragon_chat_03: 'Ночь принадлежит лунным драконам. Помни это.',
    dialog_moondragon_chat_04:
      'Чешуя у тебя какая-то тусклая. Попробуй поесть кристаллов.',
    dialog_moondragon_chat_05:
      'Луна дает силу, о которой можно только мечтать. Просто... не проси меня об элементарном синтезе.',
    dialog_moondragon_chat_06:
      'Солдра зациклился на всем старом. Истинный прогресс достигается, если что-то делать, и лишь ИНОГДА записывать.',
    dialog_moondragon_chat_07: 'Ненавижу боссов.',
    dialog_moondragon_chat_08:
      'Я создаю вещи, вдохновляясь самым прекрасным, что есть в мире. Ни к чему другому мои когти не прикасаются.',
    dialog_moondragon_chat_09:
      'У меня нет ничего общего с этими гигантскими летающими ящерицами, которых вы называете драконами. До нас, небесных драконов, им очень далеко.',
    dialog_moondragon_bounty_craft: 'Великая Лундра требует дар.',
    dialog_moondragon_bounty_slay: 'Эти ребята мне не нравятся. Проучи их.',
    dialog_moondragon_bounty_quest:
      'Отлупи хорошенько босса подземелья, пока ты там.',
    dialog_moondragon_bounty_surcharge: 'Я называю это налогом Лундры.',
    dialog_moondragon_bounty_discount: 'Это больше в стиле Солдры.',
    visit_moondragon_00_01: 'Привет, теплокровное создание.',
    visit_moondragon_00_02: 'У меня есть кое-что лишнее, а взамен я хочу дар.',
    visit_moondragon_christmas_00_01: 'Привет, теплокровное создание.',
    visit_moondragon_christmas_00_02:
      'У меня есть кое-что лишнее... Что? Святая ночь?',
    visit_moondragon_christmas_00_03:
      'Мне нравится, как это звучит. Можешь забрать это бесплатно!',
    thanks_moondragon_00_01: 'Прекрасный выбор.',
    thanks_moondragon_01_01: 'Такой дар подойдет.',
    thanks_moondragon_christmas_00_01: 'Одевайся тепло, теплокровное создание.',
    dialog_elven_greetings: 'Добро пожаловать! Пожалуйста, вытри ноги.',
    dialog_elven_greetings_02: 'Закрой за собой дверь. Малыши могут улизнуть.',
    dialog_elven_greetings_03: 'Заходи, лавочник. Ты хорошо питаешься?',
    dialog_elven_investgold: 'Ой, не надо было!',
    dialog_elven_investgold_02: 'Это так приятно.',
    dialog_elven_investgems: 'О-о!',
    dialog_elven_investgems_02: 'Чудесно.',
    dialog_elven_underupgrade:
      'Ой, прости. У меня сейчас полно дел, так как вокруг бегают все эти фамильяры.',
    dialog_elven_afterupgrade:
      'С возвращением! У фамильяров теперь гораздо больше места для игр.',
    dialog_elven_chat: 'Ты хорошо заботишься о моих детях?',
    dialog_elven_chat_02: 'Помни: бери из приюта, не покупай!',
    dialog_elven_chat_03:
      'Монстр — уничижительное слово. Они такие же создания, как и мы.',
    dialog_elven_chat_04:
      'Донован — капризный ребенок. Ему следует для разнообразия понаслаждаться жизнью.',
    dialog_elven_chat_05:
      'Иногда тут может быть шумновато. Фамильяры — ребята энергичные.',
    dialog_elven_chat_06:
      'Герои могут держать фамильяров только дома. На приключения я пускаю фамильяров только с защитниками.',
    dialog_elven_chat_07:
      'Яйца фамильяров вылупляются лишь у тех, кому фамильяры доверяют. Их инстинкт бесподобен.',
    dialog_elven_chat_08:
      'Гиблины — это маленькие проказники. Никогда не своди с них глаз.',
    dialog_elven_chat_09: 'У меня нет любимых фамильяров. Разве что йети.',
    dialog_elven_bounty_craft: 'У бабушки Иоланды есть к тебе просьба.',
    dialog_elven_bounty_slay:
      'Большие совсем не милые. Ты знаешь, что это значит.',
    dialog_elven_bounty_quest: 'У бабушки Иоланды есть к тебе просьба.',
    dialog_elven_bounty_surcharge: 'Ты заслуживаешь скидку.',
    dialog_elven_bounty_discount: 'У бабушки Иоланды есть к тебе просьба.',
    visit_elven_quality_00_01:
      'Получилось весьма симпатично. Уверена, что тебе это пригодится.',
    visit_elven_quality_christmas_00_01:
      'С праздником, лавочник! Прими подарок от любящей бабушки!',
    visit_elven_bulk_00_01:
      'Боюсь, у меня сейчас слишком много дел. Можешь взять вот это?',
    visit_elven_bulk_christmas_00_01:
      'С праздником, лавочник! Прими подарок от любящей бабушки!',
    thanks_elven_00_01: 'Я верю, что ты хорошо о них позаботишься.',
    thanks_elven_christmas_00_01: 'С праздником, лавочник.',
    dialog_essence_greetings: 'Какой сейчас год? (Зевает)',
    dialog_essence_greetings_02: 'Ох... еще пять минут. Ну пожалуйста.',
    dialog_essence_investgold:
      'Подсчет всего этого... займет целую вечность. Я доверюсь тебе.',
    dialog_essence_investgold_02: 'О... это большие деньги.',
    dialog_essence_investgems:
      'Они будут отлично смотреться... на моей могиле...',
    dialog_essence_investgems_02: 'Спасибо... Я их обожаю...',
    dialog_essence_underupgrade:
      'Пусть по виду и не скажешь... но я очень занята. Пожалуйста... приходи позже.',
    dialog_essence_afterupgrade:
      'Глоток свежего воздуха... для Мавзолея. Я забыла, каково это.',
    dialog_essence_chat: 'С-священный символ?.. Страшно...',
    dialog_essence_chat_02: 'Я забыла, какой была... моя прежняя жизнь.',
    dialog_essence_chat_03:
      'Я не помню, чтобы когда-нибудь видела... молодого Оуэна...',
    dialog_essence_chat_04:
      'Иногда я прогуливаюсь... и заглядываю в чужие дома.',
    dialog_essence_chat_05:
      'А в чем смысл... дверей? Почему бы не... проходить сквозь стены?',
    dialog_essence_chat_06:
      'Я проспала... и пропустила путешествие в загробный мир. Надеюсь... ты не против, что я тут торчу.',
    dialog_essence_chat_07:
      'Эта некромантка... постоянно смотрит на меня. У меня мурашки по коже...',
    dialog_essence_chat_08:
      'Истории о привидениях... довольно страшные. Пожалуйста, будь осторожнее.',
    dialog_essence_chat_09:
      'Этот господин... Донован. Он пытался поцеловать мне руку, но...',
    dialog_essence_chat_10:
      'Ты можешь поверить... что призраки должны платить за аренду жилья?',
    dialog_essence_bounty_craft: 'Жаль... что я не смогу это использовать...',
    dialog_essence_bounty_slay: 'Загробная жизнь... не так уж плоха.',
    dialog_essence_bounty_quest: 'Я буду наблюдать издалека... незримо.',
    dialog_essence_bounty_surcharge:
      'Не перестарайся... или я буду тебя преследовать...',
    dialog_essence_bounty_discount: 'Спать на работе... нормально.',
    visit_essence_00_01: 'Что?.. Я заснула... и меня принесло сюда ветром.',
    visit_essence_00_02: 'Хочешь... немного сущности?',
    visit_essence_christmas_00_01: 'Я дух... прошлого Рождества.',
    visit_essence_christmas_00_02:
      'А... Я репетирую... пьесу для рождественской вечеринки.',
    visit_essence_christmas_00_03: 'Не знаю, почему... я получила эту роль.',
    thanks_essence_00_01: 'Спасибо... Надеюсь, ветер усилится...',
    thanks_essence_01_01: 'Спасибо... Пойду поброжу... в другом месте.',
    thanks_essence_christmas_00_01: 'Я лучше подожду... Хэллоуина...',
    dialog_cook_greetings: 'Здравствуй, хранитель лавки!',
    dialog_cook_greetings_02: 'Чувствуй себя как дома. Ужин скоро.',
    dialog_cook_greetings_03: 'Алоха! Добро пожаловать.',
    dialog_cook_unhiredgreetings:
      'Я Туту Мано, владелица ресторана. Лучшего в городе и за рубежом!',
    dialog_cook_unhiredgreetings_02:
      'У меня особое предложение: я могу готовить для тебя блюда.',
    dialog_cook_hirequestion: 'Хочешь доставку еды в лавку?',
    dialog_cook_investgold: 'Махало.',
    dialog_cook_investgems: 'Бабушка гордится тобой.',
    dialog_cook_underupgrade: 'Кухня сейчас закрыта. Прими мои извинения.',
    dialog_cook_afterupgrade:
      'Кухня обновлена. Ножи острые. Веди себя за столом прилично.',
    dialog_cook_chat: 'Приплыли с Каипо издалека. Это хороший город.',
    dialog_cook_chat_02: 'Каипо — мой внук. Очень искусный. Учился у лучших!',
    dialog_cook_chat_03:
      'Затонувший храм — опасное место, но очень-очень хорошие ингредиенты.',
    dialog_cook_chat_04:
      'Полония — странная девушка. Не шибко умная, но превосходная морячка.',
    dialog_cook_chat_05:
      'Как суперострый карри? Есть и другой рецепт, для слабаков.',
    dialog_cook_chat_06:
      'Рудо придет сегодня? Нужно заказать еще ингредиентов...',
    dialog_cook_chat_07: 'Хочешь пожаловаться? Я выслушаю. Но нож острый.',
    dialog_cook_chat_08:
      'Старейшина Оуэн — кожа да кости. Плохо в таком возрасте.',
    dialog_cook_bounty_craft: 'Пусть бабушка гордится тобой.',
    dialog_cook_bounty_slay:
      'Незваный гость в подземелье или на кухне — разница невелика.',
    dialog_cook_bounty_quest: 'Нужны особые ингредиенты.',
    dialog_cook_bounty_surcharge:
      'Знай цену товарам. Это обязанность лавочника.',
    dialog_cook_bounty_discount: 'Довольный клиент всегда возвращается.',
    visit_cook_quality_00_01:
      'Это особенное блюдо, приготовлено с особой любовью.',
    visit_cook_bulk_00_01: 'Хочешь есть, дитя мое? Я принесу еду. Много еды.',
    visit_cook_quality_christmas_00_01:
      'Особое праздничное блюдо для тебя. В нем много любви.',
    visit_cook_bulk_christmas_00_01:
      'Я — бабушка, ты — дитя. Приносить еду на праздники — это традиция.',
    visit_cook_bulk_christmas_00_02:
      'Делись с клиентами, если ты добрый лавочник.',
    thanks_cook_00_01: 'Махало. Я вернусь.',
    thanks_cook_christmas_00_01:
      'Праздники — хорошее время для угощений. Я вернусь.',
    dialog_baker_greetings: 'Алоха тебе! Добро пожаловать!',
    dialog_baker_greetings_02: 'У меня скоро будут свежие кексы из печи!',
    dialog_baker_greetings_03:
      'Привет! Наверняка тебя сюда приманил вкусный хало-хало!',
    dialog_baker_unhiredgreetings: 'Еще раз алоха, {brother}!',
    dialog_baker_unhiredgreetings_02:
      'Мое предложение в силе! Мы можем заполнить эти полки <c1>вкусными угощениями</c1>, перед которыми не устоит ни один клиент!',
    dialog_baker_unhiredgreetings_03:
      'Бьюсь об заклад, что <c1>модный король</c1> тоже будет <c1>покупать больше</c1>!',
    dialog_baker_hirequestion: 'Как тебе такое сладкое сотрудничество?',
    dialog_baker_investgold: 'Махало, махало!',
    dialog_baker_investgold_02: 'Спасибо!',
    dialog_baker_investgold_03:
      'Будущее за десертом. Ты, {brother}, тоже так считаешь!',
    dialog_baker_investgems: 'Чудесно! Махало!',
    dialog_baker_underupgrade:
      'Извини, {brother}! Пекарня «Бонбон» на сегодня закрыта!',
    dialog_baker_afterupgrade: 'Мои печи разогреты! Пекарня снова в деле!',
    dialog_baker_chat: 'Мой дедушка — водяной. Разве не видно?',
    dialog_baker_chat_02:
      'Туту Мано — моя бабушка! Вид немного жутковатый, но не принимай это близко к сердцу.',
    dialog_baker_chat_03:
      'Бабушка все еще изучает местный язык, поэтому, пожалуйста, будь с ней терпеливее!',
    dialog_baker_chat_04:
      'Город здесь оживленный! Если сравнить, мой родной город очень спокойный!',
    dialog_baker_chat_05:
      'Макароны, торты, пироги, печенье... С десертами никогда не бывает скучно.',
    dialog_baker_chat_06:
      'Десерты — это хорошо, но правильное питание тоже важно.',
    dialog_baker_chat_07:
      'Мэйли так поглощает десерты, что кажется, будто феи получают энергию только из сахара...',
    dialog_baker_chat_08:
      'Сестра Фрейя — моя постоянная клиентка. Правда, она пытается это скрыть!',
    dialog_baker_bounty_craft:
      'Лучше лавочника, чтобы попросить о помощи, нет, {brother}.',
    dialog_baker_bounty_slay: 'Ты не против испачкать руки?',
    dialog_baker_bounty_quest: 'У меня есть для тебя подходящее задание!',
    dialog_baker_bounty_surcharge: 'Немного крема сверху никогда не помешает!',
    dialog_baker_bounty_discount: 'Подумай о том, чтобы подсластить сделку!',
    visit_baker_quality_00_01:
      'Сегодня у меня есть кое-что особенное. Результат определенно превзошел мои ожидания!',
    visit_baker_bulk_00_01:
      'Алоха, {brother}! Могу ли я заинтересовать тебя вкусным угощением?',
    visit_baker_quality_christmas_00_01: 'Ой-ой-ой! Как же там холодно! Ух!',
    visit_baker_quality_christmas_00_02:
      'Не возражаешь, если я немного погреюсь? Вот тебе кое-что за это!',
    visit_baker_bulk_christmas_00_01: 'Алоха! С Рождеством!',
    visit_baker_bulk_christmas_00_02: 'Какой же праздник без горы угощений?',
    thanks_baker_00_01:
      'У меня не было сомнений, что ты это оценишь, {brother}.',
    thanks_baker_01_01: 'Махало, {brother}.',
    thanks_baker_christmas_00_01: 'Теплого тебе Рождества, {brother}!',
    visit_elder_00_01: 'А, дружище! Останься ненадолго и послушай.',
    visit_elder_01_01: 'Добрый день! Послушаешь историю старика?',
    visit_elder_02_01: 'Прекрасный день, да? Как у тебя идут дела?',
    thanks_elder_00_01:
      'Как же летит время! Мне нужно идти. Спасибо, что умеешь уважить старика.',
    thanks_elder_01_01:
      'И вот так Горза добралась до... Ой! Похоже, я забыл о времени.',
    thanks_elder_01_02: 'Прекрасно поболтали. Надеюсь, скоро увидимся.',
    gift_00_01:
      'Вы только посмотрите! Милый подарок... но твоего имени на нем нет!',
    gift_00_02:
      'Кто же из членов твоей гильдии получит его? Иметь любимцев не возбраняется!',
    gift_01_01: 'У меня остался один подарок, который ищет владельца...',
    gift_01_02:
      'Но тебе его открывать нельзя. Нужно отправить его члену гильдии!',
    gift_02_01: 'Нет ничего лучше, чем дарить подарки, правда?',
    gift_02_02:
      'Малое доброе дело, а сколько от него радости! Что посеешь, то и пожнёшь!',
    gift_02_03: 'Хотя Катарина обычно не соглашается с последней фразой.',
    gift_03_01: 'Похоже, у тебя много друзей!',
    gift_03_02:
      'Как насчет того, чтобы отправить подарок, чтобы сделать дружбу ещё крепче?',
    dialog_king_bounty_craft:
      'Моему королевству совершенно необходимо больше таких чудесных вещей!',
    dialog_king_bounty_upgrade:
      'Скоро я почту твою лавку своим присутствием. Позаботься о выполнении моих исключительных требований!',
    dialog_king_bounty_rushitem:
      'Ожидание — удел плебеев. Твой король требует немедленных действий!',
    dialog_king_bounty_rushupgrade:
      'Приятно видеть, когда мои подданные действуют сообща!',
    dialog_king_bounty_chest:
      'Несказанное богатство, только руку протяни! Чего же ты ждешь?',
    visit_king_00_01:
      'Да, иногда неплохо прогуляться по городу, посмотреть, как живут простые люди.',
    visit_king_00_02:
      'Я куплю это в качестве сувенира. Назови свою цену. Не стесняйся.',
    visit_king_01_01: 'Я обязан заполучить эту вещь. Заплачу любые деньги!',
    visit_king_02_01: 'Хм... Да. Прекрасная лавка.',
    visit_king_02_02:
      'Пожалуй, снизойду до покупки. Сдачу можешь оставить себе.',
    visit_king_03_01:
      'Я, прославленный король этой страны, — известный законодатель мод!',
    visit_king_03_02:
      'И провозглашаю этот предмет достойным моего внимания... и денег!',
    visit_king_04_01: 'Искусная выделка этой вещицы привлекла мое внимание!',
    visit_king_04_02: 'Продай ее мне, и мои слуги тебя озолотят.',
    visit_king_05_01:
      'Я нашел нечто совершенно потрясающее! Настолько великолепное, что подходит даже мне!',
    visit_king_05_02:
      'Считать золото — не в моих привычках. Возьми столько, сколько сочтешь нужным!',
    visit_king_06_01:
      'Мои шпионы доложили мне о том, что в сей лавке продается некий легендарный предмет!',
    visit_king_06_02:
      'Подобная вещь не должна попасть в руки простолюдина. Она нужна мне!',
    visit_king_07_01: 'Ты из числа моих самых верных слуг, не так ли?',
    visit_king_07_02:
      '...В таком случае прошу: ни слова Сиа об этой покупке. Сколько ты хочешь за молчание?',
    visit_king_08_01:
      'Недавно Сиа рассказала мне о «составлении бюджета». Поистине удивительная наука!',
    visit_king_08_02:
      'Полагаю, этот предмет укладывается в мой, э-э, «бюджет». Сколько, говоришь, он стоит?',
    visit_king_ah_00_01: 'Замечательная вещь! Я прикажу слугам отнести ее!',
    visit_king_al_00_01:
      'Эта изысканная одежда достойна короля! А я, к счастью, король!',
    visit_king_am_00_01:
      'Отменное качество Джулии видно с первого взгляда! Я возьму все!',
    visit_king_bh_00_01:
      'Эти латные сапоги меня заинтриговали. Пакет не нужен, я буду носить их по дому!',
    visit_king_bl_00_01:
      'Какая эластичная кожа! Я возьму их в свой дом, полный любви!',
    visit_king_gh_00_01:
      'Мой интерес разгорелся! Заверни их для меня сию же минуту!',
    visit_king_gl_00_01:
      'Не будем тянуть время! Заверни эти роскошные рукавицы как можно скорее!',
    visit_king_hh_00_01:
      'Уоллес действительно превзошел себя! Я просто обязан заполучить эти шлемы!',
    visit_king_hl_00_01:
      'Эти головные уборы соответствуют моему статусу славного короля. Вполне естественно, что я должен их приобрести.',
    visit_king_hm_00_01: 'Шляпы всегда в моде. Никакие расходы не страшны!',
    visit_king_uh_00_01:
      'Какой аромат! Эти травы, несомненно, выращены из исключительных семян!',
    visit_king_up_00_01: 'Решено! Я заберу весь твой запас зелий!',
    visit_king_us_00_01:
      'Несомненно, в этих свитках хранятся исключительно ценные сведения! Их место в королевской библиотеке!',
    visit_king_wa_00_01:
      'Это последняя вещь в моем королевском списке продуктов! Ворох топоров!',
    visit_king_wb_00_01:
      'Мой доблестный лавочных дел мастер, я должен попросить у тебя самые дорогие луки. Не подведи меня!',
    visit_king_wc_00_01:
      'Я возьму эти арбалеты. Пришли счет в казначейство замка!',
    visit_king_wd_00_01:
      'Сиа давно заслужила роскошный подарок! Хотя, надо признать, вкусы у нее необычные...',
    visit_king_wg_00_01: '...',
    visit_king_wg_00_02:
      '...Как ты оцениваешь эффективность этого оружия против ниндзя?',
    visit_king_wm_00_01: 'Эне, бене, рес, квинтер, финтер, жес...',
    visit_king_wm_00_02:
      'Какими бы странными ни были эти булавы, я возьму их с собой!',
    visit_king_wp_00_01:
      'Этому сброду, который я называю стражей, не помешали бы палки получше!',
    visit_king_ws_00_01:
      'Это, несомненно, легендарные мечи, достойные такого короля, как я!',
    visit_king_wt_00_01:
      'Я должен заполучить эти посохи! Цена меня не волнует!',
    visit_king_ww_00_01: 'Чудесно выполненные палочки! Они будут моими!',
    visit_king_ww_00_02: '...Разрешение на волшебную палочку? О чем это ты?',
    visit_king_xa_00_01:
      'Эти магические амулеты созвучны моему королевскому духу! Я возьму их!',
    visit_king_xc_00_01:
      'Я желаю облачиться в лучшие шелка. Что у тебя есть для меня, прекрасный лавочных дел мастер?',
    visit_king_xf_00_01:
      'Какие чудесные создания! Я приглашаю вас всех на экскурсию в мой замок!',
    visit_king_xr_00_01:
      'Они выглядят достойными украсить мои пальцы. Разумеется, это большая честь!',
    visit_king_xs_00_01:
      'Эти щиты исполнены доблести! Пылиться в лавке — слишком мрачная участь для них!',
    visit_king_backup_00_01:
      'Это я! Если намереваешься заставить меня ждать, гони эту мысль прочь!',
    thanks_king_00_01:
      'Прекрасно! Рад, что могу положиться на своих подданных.',
    thanks_king_01_01: 'Великолепно. Вот пустой чек!',
    thanks_king_02_01: 'Договорились! Только не растрать все в одном месте.',
    additional_sale_king_00_01: 'Великолепно. Вот пустой чек!',
    additional_sale_king_00_02: 'Но погоди-ка... что это за вкусный запах?',
    additional_sale_king_01_01:
      'Прекрасно! Рад, что могу положиться на своих подданных.',
    additional_sale_king_01_02: '...Но я не могу уйти на голодный желудок!',
    visit_argon_backup_00_01:
      'Привет! Мне нужны кое-какие предметы! Поможешь с ними?',
    visit_argon_ws_00_01: 'Привет, у меня к тебе просьба!',
    visit_argon_ws_00_02:
      'Мне нужны запасные мечи для тренировок. Попрочнее! И чтобы выглядели подходящими для героя, если можно!',
    visit_argon_xs_00_01: 'Доброго тебе дня!',
    visit_argon_xs_00_02:
      'Тренировочному залу нужны щиты для героев-новичков. Поможешь?',
    visit_argon_xs_halloween_00_01: 'Ого! Что так много клиентов?',
    visit_argon_xs_halloween_00_02:
      'Дружище! Если у тебя будут проблемы с зомби... ты знаешь, кого позвать!',
    visit_argon_wa_00_01: 'И снова здравствуй! Как дела?',
    visit_argon_wa_00_02:
      'Найя поручила мне весьма ответственное задание: найти внушительное оружие для украшения таверны!',
    visit_argon_wa_00_03:
      'Как будущий рыцарь, я обязан помогать людям, и потому мне, в свою очередь, нужна твоя помощь.',
    visit_argon_wa_halloween_00_01:
      'Странные у тебя какие-то клиенты, дружище!',
    visit_argon_wa_halloween_00_02:
      'Эти ребята как-то подозрительно одержимы... мозгами. Тебя это правда не смущает?',
    visit_argon_ah_00_01: 'Здравствуй! Найдется минутка для меня?',
    visit_argon_ah_00_02:
      'Я тут тренировочные манекены одеваю, у тебя лишних доспехов не завалялось?',
    visit_argon_bh_00_01:
      'Добрый день! Я с парой новичков собираюсь в экспедицию в Шепчущую топь.',
    visit_argon_bh_00_02:
      'Нам понадобится несколько пар чистых сапог, чтобы там пройти!',
    visit_argon_hh_00_01: 'Мне нужна твоя помощь!',
    visit_argon_hh_00_02:
      'Хочу пожертвовать сиротам при храме несколько шлемов. Детишки обожают играть в героев!',
    visit_argon_hh_00_03:
      'Ну и полезно это в смысле обучения будет. И, э-э, безопаснее, чем те топоры...',
    visit_argon_hh_halloween_00_01: 'С Хэллоуином, дружище!',
    visit_argon_hh_halloween_00_02:
      'Мне бы несколько шлемов. Лучшей защиты от зомби нет!',
    visit_argon_gh_00_01: 'Мое почтение! Я к тебе по поручению Брома!',
    visit_argon_gh_00_02:
      'Ему нужны подбитые войлоком рукавицы плавильщика. Самый большой размер!',
    thanks_argon_00_01: 'Я знал, что могу на тебя положиться! Спасибо!',
    thanks_argon_01_01: 'Благодарю тебя! Берегись, зло, — Аргон идет!',
    thanks_argon_02_01: 'А-ха! Дрожите, злодеи, — Аргон уже идет по ваши души!',
    thanks_argon_03_01: 'Задание выполнено! Увидимся позже!',
    additional_sale_argon_00_01: 'Ой! Я сегодня пропустил обед! Можно мне это?',
    additional_sale_argon_01_01:
      'Дружище! Тебе тоже захотелось заняться готовкой?',
    visit_lilu_backup_00_01:
      'Это снова я! Мне кое-какие вещи нужны, дай-ка проверю список покупок...',
    visit_lilu_wt_00_01: 'Привет! Гляжу, трудишься в поте лица?',
    visit_lilu_wt_00_02:
      'Не знаю, как мастер Гримар это делает, но он опять посох сломал.',
    visit_lilu_wt_00_03:
      'Можешь ему новый раздобыть? Или даже пару-тройку? И попрочнее!',
    visit_lilu_wt_halloween_00_01:
      'Д-день добрый... А почему тут повсюду зомби?',
    visit_lilu_wt_halloween_00_02: 'Быстрее, дай мне посох! Лучше несколько!',
    visit_lilu_ww_00_01: 'День добрый, у меня важный заказ.',
    visit_lilu_ww_00_02:
      'У тебя волшебные палочки есть? Посохом зелья размешивать не очень удобно...',
    visit_lilu_al_00_01: 'Я планирую поход в Безжизненную пустошь.',
    visit_lilu_al_00_02:
      'Увы, мои одеяния не подходят для тамошней жары. Не найдется ли у тебя чего-нибудь полегче?',
    visit_lilu_hl_00_01: 'У меня к тебе немного странная просьба...',
    visit_lilu_hl_00_02:
      'Поллукс, моя сова, просто обожает прятаться в моей шляпе, но она мне нужна как головной убор, понимаешь?',
    visit_lilu_hl_00_03:
      'Не можешь ли продать парочку шляп, чтобы Поллуксу было где прятаться?',
    visit_lilu_uh_00_01: 'Привет! Мне нужна твоя помощь с домашним заданием!',
    visit_lilu_uh_00_02:
      'Я тут практикуюсь в изготовлении зелья по новому рецепту, но некоторых ингредиентов не хватает...',
    visit_lilu_uh_halloween_00_01: 'БУ!',
    visit_lilu_uh_halloween_00_02:
      '...Не очень страшно, да? Тебя так просто не напугаешь!',
    visit_lilu_us_00_01: 'Привет! Найдется минутка?',
    visit_lilu_us_00_02:
      'Мне надо бы заклинаний новых, но Гримар весь день спит...',
    visit_lilu_us_halloween_00_01: 'Сладость или гадость!',
    visit_lilu_us_halloween_00_02:
      'По-прежнему никаких конфет? Тогда подойдет парочка заклинаний!',
    thanks_lilu_00_01: 'Спасибо! До скорого!',
    thanks_lilu_01_01: 'Здорово! До свидания!',
    thanks_lilu_02_01: 'Обслуживание на пять! Я обязательно оставлю отзыв!',
    additional_sale_lilu_00_01: 'Ой, я тоже должна это попробовать!',
    additional_sale_lilu_01_01: 'Погоди! Дай мне это тоже!',
    visit_sia_backup_00_01: 'О, слушай... Я кое-что ищу...',
    visit_sia_wd_00_01: 'Приветствую. Рада снова тебя видеть.',
    visit_sia_wd_00_02:
      'Мне нужны кое-какие... инструменты. Все на благо королевства. Не волнуйся, все совершенно честно и невинно!',
    visit_sia_wd_halloween_00_01: '...Обслуживаешь мертвых клиентов?',
    visit_sia_wd_halloween_00_02:
      'Я полагаю, ты понимаешь, какие санитарные проблемы это за собой влечет?',
    visit_sia_wc_00_01:
      'Дорогуша, мне нужно что-нибудь, что поможет избавиться от, кхм, вредителей.',
    visit_sia_wc_00_02:
      'Желательно как можно тише и без следов. Полагаю, у тебя найдется то, что мне нужно?',
    visit_sia_wg_00_01: 'Ну что, моя посылка еще не доставлена?',
    visit_sia_wg_00_02:
      'Мне хотелось бы, чтобы она была в непримечательной упаковке, если это возможно.',
    visit_sia_bl_00_01: 'Дорогуша, у меня такая проблема...',
    visit_sia_bl_00_02:
      'Мои туфли, если начистоту, совсем вышли из моды! Уверена, ты точно мне с этим поможешь.',
    visit_sia_bl_halloween_00_01: 'Добрый вечер. Мне, пожалуйста, как обычно.',
    visit_sia_bl_halloween_00_02:
      '...Желательно что-нибудь, чего зомби не касались.',
    visit_sia_xa_00_01:
      'И снова здравствуй, дорогуша. Мне бы хотелось добавить что-то такое эдакое к моему гардеробу.',
    visit_sia_xa_00_02:
      'Что-нибудь ослепительное, завораживающее, сражающее наповал...',
    visit_sia_xr_00_01:
      'И снова здравствуй, дорогуша. Мне бы хотелось добавить что-то такое эдакое к моему гардеробу.',
    visit_sia_xr_00_02:
      'Что-нибудь ослепительное, завораживающее, сражающее наповал...',
    visit_sia_xr_halloween_00_01:
      'Эта бесконечная ночь... Очень изматывает, да?',
    visit_sia_xr_halloween_00_02:
      'Может быть, пора взять отпуск и вернуться в Керн...',
    thanks_sia_00_01:
      'Неужто на тебя и правда можно положиться? До скорой встречи...',
    thanks_sia_01_01: 'Итак, до следующего раза...',
    thanks_sia_02_01:
      'О, это просто великолепно! Ты прекрасно разбираешься в моде.',
    additional_sale_sia_00_01: 'Хм-м-м... Попробовать, что ли?',
    additional_sale_sia_01_01:
      'Полагаю, я тоже позволю себе поддаться искушению.',
    visit_yami_backup_00_01: 'Вот список. Мне нужно все это. Прямо сейчас.',
    visit_yami_wd_00_01: 'Мне нужно оружие. И быстро.',
    visit_yami_wd_00_02: 'Спили серийные номера. Это для твоего же блага.',
    visit_yami_wb_00_01:
      'Взялась за крупный контракт. Дистанция и осмотрительность решают все.',
    visit_yami_wb_00_02: 'Не задавай лишних вопросов.',
    visit_yami_wb_halloween_00_01: 'Пф. Зомби.',
    visit_yami_wb_halloween_00_02: 'Мертвые должны знать свое место.',
    visit_yami_hm_00_01: 'Мне нужна маскировка. Что-нибудь практичное.',
    visit_yami_bl_00_01:
      'Тише. Веди себя, как обычно. И принеси мне несколько пар обуви.',
    visit_yami_bl_00_02: 'Мне нужно будет замаскировать следы.',
    visit_yami_bl_halloween_00_01: '...Эта проклятая ночь мне подходит.',
    visit_yami_bl_halloween_00_02: 'Объятья ночи — прекрасный союзник.',
    visit_yami_uh_00_01:
      'Мне необходимы следующие товары. Держи это в тайне. И только попробуй меня подвести.',
    visit_yami_up_00_01:
      'Мне необходимы следующие товары. Держи это в тайне. И только попробуй меня подвести.',
    visit_yami_up_halloween_00_01:
      '...Сладость или гадость? Не трать мое время зря.',
    thanks_yami_00_01: 'Забудь, что эта сделка состоялась. Ясно? Вот и хорошо.',
    thanks_yami_01_01: 'Ты приносишь пользу. Продолжай в том же духе.',
    thanks_yami_02_01: 'Отлично. И забудь об этом.',
    thanks_yami_03_01: 'Меня здесь не было.',
    additional_sale_yami_00_01:
      'Мне тоже требуется продовольствие. Что у тебя есть?',
    additional_sale_yami_01_01:
      'Я не могу здесь задерживаться. Завершай продажу побыстрее.',
    visit_rudo_backup_00_01:
      'Эй, малыш, у тебя еще открыто? Мне кое-что нужно.',
    visit_rudo_wm_00_01:
      'Эй, малыш! Хороший день, чтобы проломить пару черепов, верно?',
    visit_rudo_wm_00_02:
      'Дай-ка мне взглянуть на разные булавы, дубинки, палицы... То, что ПОТЯЖЕЛЕЕ.',
    visit_rudo_wm_halloween_00_01: 'Сладость или гадость, малыш.',
    visit_rudo_wm_halloween_00_02:
      'Что, я уже стар для всех этих «сладостей или гадостей»?',
    visit_rudo_wm_halloween_00_03: 'Ха-ха-ха! Возможно, возможно.',
    visit_rudo_wp_00_01:
      'Слушай, а зубочистки у тебя есть? Только качественные, мне нужны покрепче.',
    visit_rudo_wa_00_01:
      'Поторопись. Притащи мне самые большие топоры, что у тебя есть.',
    visit_rudo_wa_halloween_00_01: 'Прочь с дороги, гнилые зомби!',
    visit_rudo_wa_halloween_00_02:
      'Эй, ты же не собираешься обслуживать этих ребят раньше меня?',
    visit_rudo_wa_01_01: 'Эй, дружище, помоги-ка!',
    visit_rudo_wa_01_02:
      'Я подыскиваю подарок для моей Горзы... Что-нибудь сильное и изящное.',
    visit_rudo_hh_00_01:
      'Эй, малыш, ты врезаешься во врагов головой, чтобы заставить их подчиниться?',
    visit_rudo_hh_00_02:
      'А я врезаюсь. И чтобы так и продолжалось, мне нужно больше шлемов.',
    visit_rudo_bh_00_01:
      'Страх — это мощное оружие. Когда я иду, враги в панике разбегаются.',
    visit_rudo_bh_00_02:
      'Секрет в громких и тяжелых шагах... И репутации в придачу! А-ха-ха!',
    visit_rudo_xa_00_01: 'Пф. А моего размера у тебя доспехов нет?',
    visit_rudo_xa_00_02:
      'Ладно. Дай мне один из этих крошечных амулетов. Мне нужна защита от магии.',
    visit_rudo_xa_halloween_00_01:
      '...Воздух снаружи проклят. Тут явно виновата какая-то мерзкая магия.',
    visit_rudo_xa_halloween_00_02: 'Поторопись и принеси мне талисманы!',
    visit_rudo_up_00_01:
      'Гляди веселее. Мне нужно выпить. Самое крепкое, что у тебя есть!',
    thanks_rudo_00_01: 'Пойдет. Спасибо.',
    thanks_rudo_01_01: 'Хм. Неплохо.',
    thanks_rudo_02_01:
      'Не узковато ли, как думаешь? Ай, ладно, вот твои монеты.',
    thanks_rudo_03_01:
      'Отлично. Отправь меня поскорее в приключение. У меня руки чешутся подраться!',
    additional_sale_rudo_00_01:
      'Я такой голодный, что и лошадь бы съел. Есть чего для меня?',
    additional_sale_rudo_01_01: 'О, Горза хочет, чтобы я взял и вот это.',
    visit_polonia_backup_00_01:
      'За работу, салага. Твоему капитану кое-что нужно.',
    visit_polonia_ws_00_01:
      'Хранитель добычи, моей команде нужны новые абордажные сабли.',
    visit_polonia_ws_00_02:
      'Соленый морской ветер жесток по отношению к железу...',
    visit_polonia_ws_halloween_00_01:
      'У тебя тут трупы разгуливают. Не беспокоят, хранитель добычи?',
    visit_polonia_ws_halloween_00_02:
      'Я бы хотела, чтобы мои прекрасные мозги остались при мне, да.',
    visit_polonia_wg_00_01:
      'Привет, хранитель добычи. Я слышала, что у тебя появились новые пушки.',
    visit_polonia_wg_00_02:
      'Покажи, что у тебя есть, и, возможно, я оставлю тебя в живых! Ха-ха!',
    visit_polonia_wg_halloween_00_01:
      'А-а-а, сушу захватывают проклятые морские волки!',
    visit_polonia_wg_halloween_00_02:
      'Есть только одно средство от этих мерзких кусак. У тебя есть то, что мне нужно?',
    visit_polonia_wt_00_01: 'Дружище, мне нужны твои чудные посохи.',
    visit_polonia_wt_00_02:
      'Да, пираты не пользуются посохами. Но я делаю то, что хочу, ведь пираты — свободные люди!',
    visit_polonia_am_00_01: 'За работу, дружище. Мне нужны новые доспехи.',
    visit_polonia_am_00_02: 'Снять с меня мерки? Ха! Помечтай, прохвост!',
    visit_polonia_gl_00_01:
      'Палец так и чешется нажать на курок, дружище. Покажи лучшие перчатки у тебя в ассортименте.',
    visit_polonia_xa_00_01:
      'Морские волки — ребята суеверные, хранитель добычи. И я не исключение!',
    visit_polonia_xa_00_02:
      'Снабди меня амулетами на удачу, чтобы успокоить команду.',
    visit_polonia_xa_halloween_00_01:
      'Привет, хранитель добычи. Эти сухопутные крысы у тебя в лавке выглядят не очень.',
    visit_polonia_xa_halloween_00_02: 'По-моему, у твоей команды жуткая цинга.',
    thanks_polonia_00_01: 'Хорошая работа, дружище. Вот твои дублоны.',
    thanks_polonia_01_01: 'Йо-хо-хо! Ты тоже скоро станешь морским волком.',
    thanks_polonia_02_01: 'Команда споет песню в твою честь.',
    thanks_polonia_03_01: 'Хм... Где бы это закопать...',
    thanks_polonia_04_01: 'Ха! За такое и умереть не жаль!',
    thanks_polonia_05_01: 'Капитанская доля!',
    additional_sale_polonia_00_01:
      'За работу, дружище. Твоему капитану нужны харчи.',
    additional_sale_polonia_01_01:
      'По-моему, это может помочь от цинги. Я возьму.',
    visit_donovan_backup_00_01: 'Уважаемый лавочник! Мне нужны твои товары.',
    visit_donovan_wd_00_01:
      'Уважаемый лавочник, я эльф, пользующийся большой известностью... а успех порождает зависть.',
    visit_donovan_wd_00_02:
      'Так как у меня нет личной охраны, моя безопасность вызывает беспокойство.',
    visit_donovan_wd_00_03:
      'Пожалуйста, исправь это. Моя жизнь в твоих умелых руках.',
    visit_donovan_wd_halloween_00_01:
      'Ты что, в открытую занимаешься некромантией?',
    visit_donovan_wd_halloween_00_02:
      'Вот уж действительно странное королевство...',
    visit_donovan_ww_00_01:
      'Уважаемый лавочник, у тебя есть в наличии волшебные палочки?',
    visit_donovan_ww_00_02:
      'Похоже, мои собственные... не подходят для работы с местными ингредиентами.',
    visit_donovan_ww_00_03:
      'Алхимия требует максимальной точности. Я не буду оставлять ничего на волю случая.',
    visit_donovan_al_00_01:
      'Я был бы признателен за новый наряд. Что-нибудь подобающее моему званию, конечно.',
    visit_donovan_al_00_02:
      'Удобство, стиль и легкость в движении — обязательные условия!',
    visit_donovan_al_00_03:
      'Стоимость не имеет значения. Император денег не жалеет.',
    visit_donovan_uh_00_01:
      'Я выяснил в самый ужасный момент, что мне не хватает важнейшего ингредиента...',
    visit_donovan_uh_00_02:
      'Пожалуйста, поторопись. Мой эксперимент ограничен по времени.',
    visit_donovan_uh_halloween_00_01:
      'Чем бы ни болели твои посетители, я заражаться не хочу.',
    visit_donovan_uh_halloween_00_02:
      'Прошу тебя, поспеши, я хотел бы уйти как можно скорее.',
    visit_donovan_up_00_01:
      'Мне хочется изведать алхимический потенциал этого королевства.',
    visit_donovan_up_00_02:
      'Покажи свои лучшие товары. Твоя репутация на кону.',
    visit_donovan_up_halloween_00_01:
      'Мне правда интересно, что нашло на твоих клиентов.',
    visit_donovan_up_halloween_00_02:
      'Раз уж я здесь, может быть, мне тоже попробовать?..',
    visit_donovan_xr_00_01:
      'Кажется, мои аксессуары не совсем в моде в этом... отдаленном королевстве.',
    visit_donovan_xr_00_02:
      'Я доверяю тебе снабжение меня вещами по последней моде, уважаемый лавочник.',
    thanks_donovan_00_01: 'Большое спасибо, уважаемый лавочник.',
    thanks_donovan_01_01: 'Это отлично подойдет.',
    thanks_donovan_02_01:
      'Император проследит, чтобы тебя должным образом вознаградили.',
    thanks_donovan_03_01: 'Спасибо, друг Керна.',
    thanks_donovan_04_01:
      'Лучшее обслуживание за десятки лет. В буквальном смысле.',
    additional_sale_donovan_00_01:
      'Я очень спешу. Можно мне и вот это завернуть?',
    additional_sale_donovan_01_01:
      'Похоже, это может соперничать с кухней империи. Я бы хотел попробовать.',
    visit_hemma_backup_00_01:
      'Нам нравится твоя мастерская работа. Что у тебя есть в продаже?',
    visit_hemma_wt_00_01:
      'Не хочется тебе это говорить... Но иногда мы теряем контроль над нежитью, которую создаем. ',
    visit_hemma_wt_00_02:
      'И нет ничего лучше в таких ситуациях, чем тупой предмет.',
    visit_hemma_wt_00_03: 'Чем крепче, тем лучше.',
    visit_hemma_wt_halloween_00_01: 'Впечатляюще ты обращаешься с зомби.',
    visit_hemma_wt_halloween_00_02: 'Как это, они были созданы не тобой? ',
    visit_hemma_ww_00_01:
      'Мы не то чтобы любим волшебные палочки... но они используются в разных ритуалах.',
    visit_hemma_ww_00_02:
      'У тебя в продаже есть что-нибудь, на что нам стоит потратить свое время?',
    visit_hemma_al_00_01: 'Способности, применяемые нами, опасны.',
    visit_hemma_al_00_02:
      'Наш последний ритуал прошел не так, как было запланировано, и одежда, которую мы обычно носим, не выжила.',
    visit_hemma_al_00_03: 'Есть что-нибудь в продаже для нас?',
    visit_hemma_uh_00_01: 'Приветствую!',
    visit_hemma_uh_00_02:
      'Нам нужны прекрасные благовония для сжигания при свете луны. Можешь обеспечить?',
    visit_hemma_uh_halloween_00_01:
      'Значит, для обслуживания клиентов из нежити тебе нужно только...',
    visit_hemma_uh_halloween_00_02:
      'О чем ты?.. Ты не хочешь, чтобы они оставались нежитью?',
    visit_hemma_us_00_01: 'Мы отчаянно нуждаемся в магическом обновлении.',
    visit_hemma_us_00_02: 'В мощном, само собой.',
    visit_hemma_us_halloween_00_01:
      'Это место пронизывает некромантская энергия...',
    visit_hemma_us_halloween_00_02:
      'Она нас притянула. Мы должны признать, что это интересная стратегия ведения дел.',
    visit_hemma_xa_00_01:
      'Для нас совсем не редкость иметь дело с могучими духами. Иногда слишком могучими.',
    visit_hemma_xa_00_02:
      'В такие времена мы всегда рады, что не экономили на хорошем талисмане.',
    thanks_hemma_00_01: 'Благодарствую, лавочник.',
    thanks_hemma_01_01: 'Мы довольны. Спасибо тебе.',
    thanks_hemma_02_01: 'Вот это прекрасно подойдет. Большое спасибо.',
    thanks_hemma_04_01: 'Мы благодарим тебя.',
    additional_sale_hemma_00_01:
      'Прежде чем мы уйдем, можем ли мы побеспокоить тебя еще одной просьбой?',
    additional_sale_hemma_01_01:
      'Мы не можем пройти мимо такого восхитительного лакомства. Можно?',
    visit_ashley_backup_00_01: 'Эй, босс, я пришла за своим заказом.',
    visit_ashley_ws_00_01: 'Это... меч из легенд!..',
    visit_ashley_ws_00_02: 'Да я просто прикалываюсь. Но его возьму.',
    visit_ashley_ws_01_01: 'Эй, босс, я возьму мечи, которые обычно заказываю.',
    visit_ashley_ws_01_02:
      'Должна сказать, что дома вещи ломались не так часто.',
    visit_ashley_ws_02_01:
      'Не так уж много здесь катан, да? Правда, это не твоя вина.',
    visit_ashley_ws_02_02:
      'Я могу свести тебя с парнем по имени Масамунэ, если хочешь.',
    visit_ashley_ws_halloween_00_01:
      'Что это вокруг за цзянши? Выглядят довольно проворными.',
    visit_ashley_ws_halloween_00_02: 'Ладно, не моя проблема. Мечи есть?',
    visit_ashley_wp_00_01: 'Пф. Копья.',
    visit_ashley_wp_00_02:
      'Не знаю, почему асигару их так любят. Надо бы узнать.',
    visit_ashley_wb_00_01: 'Я отстаю в обучении стрельбе из лука.',
    visit_ashley_wb_00_02: 'А... Я просто куплю вот это и завтра потренируюсь.',
    visit_ashley_wg_00_01:
      'Я слышала, что в последнее время стали модными фитильные замки.',
    visit_ashley_wg_00_02: 'Что предложишь мне, босс?',
    visit_ashley_wg_halloween_00_01: 'Сейчас сезон зомби, да?',
    visit_ashley_wg_halloween_00_02: 'Ты знаешь, что будет дальше...',
    visit_ashley_ah_00_01:
      'Босс, я ищу доспехи, чтобы лучше смешаться с местными.',
    visit_ashley_ah_00_02:
      'Надеюсь, руку не потеряю. Я могу отдать только одну.',
    visit_ashley_hh_00_01: 'Думаю, я возьму вот этот. Выглядит прочно.',
    visit_ashley_hh_00_02: 'Нельзя экономить на защите головы, да?',
    visit_ashley_hh_halloween_00_01: 'Эй, босс. Этот шлем продается?',
    visit_ashley_hh_halloween_00_02:
      'Что с этими клиентами? У тебя проблемы с ёкаями?',
    visit_ashley_gh_00_01: 'Ага, я повсюду искала такую рукавицу.',
    visit_ashley_gh_00_02: 'Правда, мне нужна всего одна. Как насчет скидки?',
    thanks_ashley_00_01: 'Спасибо, босс.',
    thanks_ashley_01_01: 'Да! Вот это я понимаю!',
    thanks_ashley_02_01: 'Прекрасная вещь. Я возьму ее у тебя.',
    thanks_ashley_03_01: 'Хорошая сделка. Вот увидишь, я жаловаться не буду.',
    additional_sale_ashley_00_01:
      'Ого, здесь чем-то вкусно пахнет. Не томи меня!',
    additional_sale_ashley_01_01:
      'Мое самурайское чутье не обманешь. От меня съестное не спрячешь.',
    visit_bjorn_backup_00_01: 'We\'re here! Do you have our order?',
    visit_bjorn_wa_00_01:
      'Hmm... Gorza said she needed some new axes. What do you have in stock, city dweller?',
    visit_bjorn_wb_00_01:
      'City dweller, any bows in stock rated for big game hunting?',
    visit_bjorn_wb_00_02: 'I\'m talking Manticores, not sewer rats! Har har!',
    visit_bjorn_wb_halloween_00_01:
      'W-what\'s happening here? Zombie invasion!?',
    visit_bjorn_wb_halloween_00_02:
      'Come on, Bamham! We\'ve got an ecological disaster on our hands!',
    visit_bjorn_wg_00_01:
      'Guns... I don\'t get this newfangled technology. You can\'t even recover the bullets!',
    visit_bjorn_wg_00_02:
      'It\'d be easier if Bamham didn\'t keep eating the gunpowder!',
    visit_bjorn_am_00_01:
      'Haven\'t had a change of clothes in a week! Got anything my size, city dweller?',
    visit_bjorn_am_00_02: '...I think I\'m better off burning these...',
    visit_bjorn_al_00_01: 'These are some Tusky pajamas! I\'ll take some!',
    visit_bjorn_al_halloween_00_01: 'These zombies are unusually friendly.',
    visit_bjorn_al_halloween_00_02:
      'Har har! Maybe they don\'t bother me \'cuz I\'m green?',
    visit_bjorn_xa_00_01:
      'City Dweller, we\'d like a bundle of amulets. Tingly, noisy ones.',
    visit_bjorn_xa_00_02: 'It keeps bears away! Har har!',
    visit_bjorn_xc_00_01:
      'We\'re headed on a secret mission to the Blightspire Peaks... and it\'s cold up there!',
    visit_bjorn_xc_00_02:
      'I\'ll need big cloaks for me, and small ones for the \'ham!',
    visit_bjorn_bl_00_01: 'Supple. Breathable. Comfortable. Durable.',
    visit_bjorn_bl_00_02:
      'A ranger lives and dies by his boots. Do you have anything that lives up to these standards?',
    visit_bjorn_fm_00_01:
      'I\'m picking up lunch for me and my son. Pack it tight, Brom\'s a big eater!',
    thanks_bjorn_00_01: 'Tusky service! Thanks, city dweller!',
    thanks_bjorn_01_01: 'Come on, Bamham! The trail calls!',
    thanks_bjorn_02_01: 'Thanks, city dweller! See you around!',
    thanks_bjorn_03_01: 'Har har! Tusky service! Say thanks, Bamham!',
    additional_sale_bjorn_00_01:
      'Thanks! I think Bamham would like a snack, too.',
    additional_sale_bjorn_01_01:
      'Oh no...! Bamham smelled something delicious!',
    additional_sale_bjorn_01_02: 'Can we have it? For everyone\'s safety?',
    visit_atlab_aang_backup_00_01:
      'Привет, лавочник! Я пришел за своим заказом!',
    visit_atlab_aang_wt_00_01:
      'Меня интересуют эти посохи... Что они могут делать?',
    visit_atlab_aang_wt_00_02: 'Магия, да? Стоит попробовать!',
    visit_atlab_aang_al_00_01: 'Лавочник! У тебя есть какое-нибудь одеяние?',
    visit_atlab_aang_al_00_02:
      'Да! Вот это! А есть на несколько размеров меньше?',
    visit_atlab_aang_hl_00_01: 'Говоришь, это шляпы чародея?',
    visit_atlab_aang_hl_00_02:
      'Не знаю, кто такие чародеи, но шляпы у них красивые!',
    visit_atlab_aang_uh_00_01: 'Привет! А у тебя есть цветы? Это для Катары...',
    visit_atlab_aang_uh_00_02: 'Н-нет! Все совсем не так!',
    visit_atlab_aang_uh_01_01:
      'Эти травы... Я бы хотел купить угощение для Аппы!',
    visit_atlab_aang_uh_01_02:
      'Странные на вид... Но, думаю, будь они опасны, он бы не стал их есть.',
    visit_atlab_aang_us_00_01:
      'Эти свитки продаются? Выглядят довольно интересно!',
    visit_atlab_aang_us_00_02: 'Подожди... Я не умею читать на этом языке!',
    visit_atlab_aang_xa_00_01:
      'Эти прекрасные амулеты напоминают мне о Гияцо...',
    visit_atlab_aang_xa_00_02: 'Могу я их купить? Ну пожалуйста.',
    thanks_atlab_aang_00_01:
      'Вряд ли мои деньги здесь чего-то стоят... Надеюсь, хоть эти монеты защитника подойдут!',
    thanks_atlab_aang_01_01: 'Сокка будет в восторге, когда их увидит!',
    thanks_atlab_aang_02_01: 'Спасибо! Я поделюсь со своими друзьями!',
    thanks_atlab_aang_03_01: 'Спасибо, лавочник!',
    additional_sale_atlab_aang_00_01:
      'Я увидел вон там кое-что интересное. Это местная кухня?',
    additional_sale_atlab_aang_01_01: 'Ой! Можно мне лакомство для Аппы?',
    visit_atlab_sokka_backup_00_01: 'Привет, я вернулся. Есть что-то для меня?',
    visit_atlab_sokka_wa_00_01:
      'Ух!.. Неужели этим действительно сражаются? Такое все тяжелое!',
    visit_atlab_sokka_wa_00_02: 'Насколько сильны эти герои?!',
    visit_atlab_sokka_ws_00_01: 'Хм-м-м... Неплохо, неплохо.',
    visit_atlab_sokka_ws_00_02:
      'Не так хорош, как мой космический меч, но я его возьму.',
    visit_atlab_sokka_wd_00_01: 'Хорошие у тебя кинжалы. А мачете есть?',
    visit_atlab_sokka_wd_00_02: 'Немного вычурно, но, думаю, сойдет.',
    visit_atlab_sokka_wm_00_01:
      'А эти модные палицы смогут сравниться с моей верной дубинкой?',
    visit_atlab_sokka_wm_00_02: 'Сейчас проверю!',
    visit_atlab_sokka_wp_00_01:
      'Какой впечатляющий ассортимент металлических копий...',
    visit_atlab_sokka_wp_00_02: 'Ты же не поставляешь оружие Народу Огня?',
    visit_atlab_sokka_wb_01_01: 'Обычно я больше люблю бумеранги, но...',
    visit_atlab_sokka_wb_01_02: 'Теперь у меня есть шанс попробовать вот это!',
    visit_atlab_sokka_wb_01_03: 'Ты ведь продаешь стрелы, да?',
    visit_atlab_sokka_wt_00_01: 'Эти посохи немного похожи на посох Аанга, да?',
    visit_atlab_sokka_wt_00_02:
      'Я не повелитель стихий, но знаю несколько ловких приемов!',
    visit_atlab_sokka_ww_00_01:
      'Так, я озадачен. Как вообще сражаться такими крошечными палочками?',
    visit_atlab_sokka_ww_00_02: 'Подожди! Ничего не говори! Я сам разберусь!',
    visit_atlab_sokka_wc_00_01: 'Эй, лавочник! У тебя есть в наличии арбалеты?',
    visit_atlab_sokka_wc_00_02: 'Их так много... Не могу выбрать!',
    thanks_atlab_sokka_00_01: 'Спасибо за сделку!',
    thanks_atlab_sokka_01_01:
      'Ну и лицо будет у Аанга, когда я покажу ему это!',
    thanks_atlab_sokka_02_01: 'В пределах моего бюджета! Наверное...',
    thanks_atlab_sokka_03_01: 'Мое племя с удовольствием бы на это взглянуло!',
    additional_sale_atlab_sokka_00_01: 'Ого, выглядит ВКУСНО! Можно мне?',
    default_fallback_00_01: '[Missing dialogue data]',
    default_fallback_00_02: '[Missing dialogue data]',
    tutorial_dust_counter_click:
      'Нажми на <c1>прилавок</c1>, чтобы протереть пыль.',
    tutorial_dust_rack_click: 'Нажми на <c1>стойку</c1>, чтобы протереть пыль.',
    tutorial_press_crafting_click: 'Нажми кнопку <c1>«Создать»</c1>.',
    tutorial_press_crafting_card_click:
      'Нажми на <c1>карточку изготовления</c1>.',
    tutorial_wait_crafting_click:
      'Подожди, пока на таймере истечет время, а затем нажми на карточку.',
    tutorial_dust_bin_click: 'Нажми на <c1>корзину</c1>, чтобы убрать пыль.',
    tutorial_press_bin_bubble_click:
      'Нажми на <c1>облачко</c1>, чтобы собрать ресурсы.',
    tutorial_press_sell_bubble_click:
      'Нажми на <c1>облачко</c1>, чтобы поговорить с клиентом.',
    tutorial_press_bubble_hero_click:
      'Нажми на <c1>облачко</c1>, чтобы поговорить с Аргоном.',
    tutorial_press_bubble_dragon_invasion_click:
      'Нажмите на <c1>облачко</c1>, чтобы поговорить с Полонией.',
    tutorial_press_bubble_blacksmith_click:
      'Нажми на <c1>облачко</c1>, чтобы поговорить с кузнецом Уоллесом.',
    tutorial_press_sell_item_click: 'Нажми кнопку <c1>«Продать»</c1>.',
    tutorial_press_city_click: 'Нажми кнопку <c1>«Город»</c1>.',
    tutorial_press_buildings_click: 'Нажми на <c1>швейную мастерскую</c1>.',
    tutorial_press_carpenter_click: 'Нажми кнопку <c1>«Нанять»</c1>.',
    tutorial_press_shop_click: 'Нажми кнопку <c1>«Лавка»</c1>.',
    tutorial_press_furniture_click: 'Нажми кнопку <c1>«Мебель»</c1>.',
    tutorial_press_hire_click: 'Нажми кнопку <c1>«Нанять»</c1>.',
    tutorial_press_weapon_click: 'Нажми на ячейку <c1>оружия</c1>.',
    tutorial_press_hire_adventurer_click: 'Нажми кнопку <c1>«Нанять»</c1>.',
    tutorial_press_bubble_tailor_click:
      'Нажми на <c1>облачко</c1>, чтобы поговорить со швеей Джулией.',
    tutorial_press_quest_click: 'Нажми кнопку <c1>«Приключения»</c1>.',
    tutorial_press_questing_card_click:
      'Нажми кнопку <c1>«Эльфийская древесина»</c1>.',
    tutorial_press_hire_board_back_click:
      'Нажми кнопку <c1>«Назад»</c1>, чтобы вернуться в город.',
    tutorial_press_forest_click: 'Нажми кнопку <c1>«Отправить команду»</c1>.',
    tutorial_press_quest_slot_click: 'Нажми на пустую <c1>ячейку героя</c1>.',
    tutorial_press_adventurer_click: 'Нажми на <c1>карточку героя</c1>.',
    tutorial_press_adventurer_heal_click: 'Нажми кнопку <c1>«Исцелить»</c1>.',
    tutorial_press_adventurer_hpdrink_click:
      'Нажми на <c1>напиток выносливости</c1>.',
    tutorial_press_start_quest_click:
      'Нажми кнопку <c1>«Начать приключение»</c1>,',
    tutorial_press_craft_slot_click: 'Нажми кнопку <c1>«+»</c1>.',
    tutorial_collect_quest_click: 'Нажми кнопку <c1>«Забрать»</c1>.',
    tutorial_press_adventurers_click: 'Нажми кнопку <c1>«Перс.»</c1>',
    tutorial_press_armor_click: 'Нажми кнопку <c1>«Доспехи»</c1>.',
    tutorial_press_back_click: 'Нажми кнопку <c1>«Назад»</c1>.',
    tutorial_press_rack_click:
      'Нажми на <c1>стойку</c1>, чтобы посмотреть действия.',
    tutorial_press_info_click: 'Нажми кнопку <c1>«Сведения»</c1>.',
    tutorial_press_city_search_click: 'Нажми кнопку <c1>«Поиск гильдий»</c1>.',
    tutorial_press_vert_rack_click: 'Нажми на <c1>вертикальную стойку</c1>.',
    tutorial_press_generic_click: 'Нажми: <c1>{0}</c1>.',
    tutorial_press_upgrade_click: 'Нажми кнопку <c1>«Улучшить»</c1>.',
    tutorial_press_argon_click: 'Нажми на <c1>Аргона</c1>.',
    tutorial_press_edit_click: 'Нажми кнопку <c1>«Дизайн»</c1>.',
    tutorial_press_trade_click: 'Нажми кнопку <c1>«Рынок»</c1>.',
    tutorial_press_plant_click: 'Нажми на <c1>растение</c1>.',
    tutorial_press_chest_click: 'Нажми кнопку <c1>«Сундук»</c1>.',
    tutorial_press_expand_click: 'Нажми кнопку <c1>«Расширение»</c1>.',
    tutorial_press_open_chest_click:
      'Нажми кнопку <c1>«Магическое открытие»</c1>. Это за мой счет!',
    tutorial_press_city_management_click: 'Нажми кнопку <c1>«Гильдия»</c1>.',
    tutorial_press_hire_argon_click: 'Нажми кнопку <c1>«Золото»</c1>.',
    tutorial_press_jackslot_click: 'Нажми кнопку <c1>«Задания»</c1>.',
    tutorial_press_spin_click: 'Нажми кнопку <c1>«Вращать»</c1>.',
    tutorial_press_objective_click: 'Нажми кнопку <c1>«Задания»</c1>.',
    tutorial_press_surcharge_click: 'Нажми кнопку <c1>«Повысить цену»</c1>.',
    tutorial_press_discount_click: 'Нажми кнопку <c1>«Понизить цену»</c1>.',
    tutorial_press_help_click: 'Нажми на облачко <c1>«Помощь»</c1>.',
    tutorial_press_bubble_king_click:
      'Теперь можешь нажать на мое <c1>облачко</c1>.',
    tutorial_press_store_click: 'Нажми кнопку <c1>«Магазин»</c1>.',
    tutorial_press_crafting_unlock_click: 'Нажми на <c1>карточку чертежа</c1>.',
    tutorial_press_unlock_blueprint_click:
      'Нажми кнопку <c1>«Подтвердить»</c1>, чтобы открыть чертеж.',
    tutorial_press_forest_location_click: 'Нажми на <c1>Воющий лес</c1>.',
    tutorial_speedup_craft_click:
      'Нажми на <c1>облачко изготовления</c1> для ускорения.',
    tutorial_press_ascension_tab_click: 'Нажми на вкладку <c1>«Усиление»</c1>.',
    tutorial_press_ascend_click: 'Нажми кнопку <c1>«Усилить»</c1>.',
    tutorial_press_inventory_click: 'Нажми кнопку <c1>«Инвентарь»</c1>.',
    tutorial_press_fatring_click: 'Нажми на <c1>кольцо</c1>.',
    tutorial_press_bubble_generic_click:
      'Нажми на <c1>облачко</c1>, чтобы поговорить с персонажем {0}.',
    tutorial_press_bubble_engineer_click:
      'Нажми на <c1>облачко</c1>, чтобы поговорить с инженером.',
    tutorial_press_bubble_scholar_click:
      'Нажми на <c1>облачко</c1>, чтобы поговорить с ученым.',
    tutorial_firstpet_intro_01: 'Дружище! Ты мне не поможешь?',
    tutorial_firstpet_intro_02:
      'У меня тут два бродячих зверя, у которых нет дома...',
    tutorial_firstpet_intro_03: 'Не приютишь ли одного из них?',
    tutorial_firstpet_popup_01:
      'Посмотри, какие они милые! Как тут можно отказать?',
    tutorial_firstpet_popup_02: 'Давай, выбери одного!',
    tutorial_firstpet_cat_01:
      'Не волнуйся насчет собаки, я об этом малыше позабочусь!',
    tutorial_firstpet_cat_02:
      'А кошку свою обязательно окружи любовью... и не забывай кормить!',
    tutorial_firstpet_cat_03: 'Она наверняка будет ладить с твоими клиентами!',
    tutorial_firstpet_dog_01:
      'Не волнуйся насчет кошки, в доме Уоллесов такой пушистик не помешает!',
    tutorial_firstpet_dog_02:
      'А собаку свою обязательно окружи любовью... и не забывай кормить!',
    tutorial_firstpet_dog_03: 'Она наверняка будет ладить с твоими клиентами!',
    loginevent_anniversary_01:
      'Процветающий лавочных дел мастер, прибыл твой король!',
    loginevent_anniversary_02:
      'Смотри не упади, у меня захватывающая новость...',
    loginevent_anniversary_03:
      'Игре <c1>Shop Titans</c1> официально исполняется 4 года! Невероятно!',
    loginevent_anniversary_04:
      'Да-да, мы сейчас ломаем четвертую стену, но это знаменательное событие!',
    loginevent_anniversary_05:
      'В его честь твой самый щедрый король будет раздавать подарки всем лавочникам в течение следующих дней!',
    loginevent_anniversary_06:
      'Моя щедрость воистину не знает границ! Не нужно меня благодарить!',
    dialog_king_event_hot_item:
      '<c1>{0}</c1> нужны мне позарез! Несколько недель уже о них мечтаю!',
    dialog_king_event_hot_item_02:
      '<c1>{0}</c1> есть у меня в коллекции, но та еще не полная... Помоги мне исправить это побыстрее!',
    dialog_king_event_hot_item_03:
      '<c1>{0}</c1>! <c1>{0}</c1>! <c1>{0}</c1>, полцарства за них!',
    dialog_king_event_hot_item_04:
      '<c1>{0}</c1>... Можешь ли ты представить мир без них? Я содрогаюсь при одной мысли!',
    dialog_king_event_hot_item_05:
      'Я, король Рейнгольд, настоящим объявляю такую вещь, как <c1>{0}</c1>, крутым предметом сего часа!',
    dialog_king_event_hot_item_06:
      'Я отказываюсь показываться на публике без такой вещи, как <c1>{0}</c1>! Положение обязывает!',
    dialog_king_event_hot_item_07:
      '<c1>{0}</c1> — думаю, их стоит объявить королевским геральдическим символом. Что скажешь?',
    dialog_king_event_hot_item_08:
      'Все, что тебе довелось делать до этого, уже в прошлом! <c1>{0}</c1> — вот что популярно сейчас!',
    dialog_king_event_hot_item_09:
      'Кто когда-либо слышал о короле без такой вещи, как <c1>{0}</c1>?',
    dialog_king_event_hot_item_10:
      'Твоему блистательному королю требуются не менее блистательные <c1>{0}</c1>!',
    dialog_king_event_type_ws:
      'Нет более благородного оружия, чем <c1>меч</c1>! Такой, чтобы подошел великолепному королю!',
    dialog_king_event_type_wa:
      'Да, <c1>топоры</c1>! Оружие рабочего класса! Я хочу, чтобы все знали, что я человек из народа!',
    dialog_king_event_type_wd:
      'Количество или качество? Я выбираю и то и другое! Миллионы <c1>кинжалов</c1>, шевелись!',
    dialog_king_event_type_wm:
      'До моих ушей дошли жалобы о слишком большом количестве <c1>булав</c1>. Они необоснованные!',
    dialog_king_event_type_wp:
      'Сегодня судьба требует <c1>копий</c1>! Было бы глупо с моей стороны ее игнорировать!',
    dialog_king_event_type_wb:
      'Точно прицелиться, сделать умелый выстрел...<c1>Луки</c1> — такое поэтичное оружие!',
    dialog_king_event_type_wt:
      'Посмотрим, насколько хорош этот Аллан на самом деле. <c1>Посохи</c1>! Их не счесть!',
    dialog_king_event_type_ww:
      '<c1>Волшебные палочки</c1> обладают великой силой, а с великой силой приходит великая ответственность. Мне подходит идеально!',
    dialog_king_event_type_wc:
      '<c1>Арбалеты</c1>? <c1>Арбалеты</c1> — это хорошо. Твори свою магию, лавочных дел мастер.',
    dialog_king_event_type_wg:
      'Мое королевство в авангарде. Мощные <c1>ружья</c1> должны быть моими!',
    dialog_king_event_type_uh:
      'Королевская кладовая оскудела! <c1>Лечебные травы</c1> будут выполнять роль импровизированных специй!',
    dialog_king_event_type_up:
      '<c1>Зелья</c1>, тоники, эликсиры... Много никогда не бывает, особенно мне!',
    dialog_king_event_type_us:
      'Я хочу научиться магии! Засыпь меня <c1>заклинаниями</c1>, и я обещаю, что они НЕ будут использованы со всей ответственностью!',
    dialog_king_event_type_ah:
      'Меня лихорадит... и единственным лекарством станет <c1>тяжелый доспех</c1>!',
    dialog_king_event_type_am:
      'Мне мало шедевров Джулии! Больше <c1>легких доспехов</c1>, пожалуйста!',
    dialog_king_event_type_al:
      'Пышная <c1>одежда</c1> мне очень к лицу! Одари меня своими лучшими тканями!',
    dialog_king_event_type_hh:
      'Чудесные <c1>шлемы</c1> плохо подходят человеку в короне, но это жертва, которую должны приносить люди моего звания.',
    dialog_king_event_type_hm:
      'Какие щегольские <c1>шапки странника</c1>! Мой гардероб позарез нужно украсить чем-то подобным!',
    dialog_king_event_type_hl:
      'Может быть, у меня и нет способностей чародея, но за деньги можно купить все! Принеси мне <c1>шляпы мага</c1>!',
    dialog_king_event_type_gh:
      'Чтобы править железной рукой... надену-ка я латные <c1>рукавицы</c1>. Или это слишком буквально?',
    dialog_king_event_type_gl:
      'Эти нежные руки должны быть защищены любой ценой! Моя жизнь зависит от этих <c1>перчаток</c1>!',
    dialog_king_event_type_bh:
      'Я пройду хоть сто миль, если нужно, но самые лучшие <c1>сапоги</c1> должны быть моими!',
    dialog_king_event_type_bl:
      'Моя любимая пара <c1>башмаков</c1> износилась! Я требую несколько сотен новых!',
    dialog_king_event_type_xs:
      'Нет лучшего холста, чем <c1>щиты</c1>, для демонстрации герба!',
    dialog_king_event_type_xc:
      'Кто когда-либо слышал о <c1>плащах</c1>? Я — нет!',
    dialog_king_event_type_xc_02:
      'Срочно нужны <c1>плащи</c1>! Моя сиятельная особа вскоре должна будет путешествовать инкогнито!',
    dialog_king_event_type_xr:
      'Уже давно пора сделать роскошный подарок Сиа! Я требую тысячу <c1>колец</c1> как можно скорее.',
    dialog_king_event_type_xa:
      'Твоему королю нужны побрякушки! Подойдут только самые экстравагантные <c1>амулеты</c1>!',
    dialog_king_event_type_xu:
      'Я желаю <c1>рунные камни</c1>!.. Напомни-ка, для чего они нужны?',
    dialog_king_event_type_xm:
      '<c1>Лунные камни</c1>... Такие таинственные и красивые... Хочу целую груду сию же минуту!',
    dialog_king_event_type_xf:
      'Королевство требует притока <c1>фамильяров</c1> для всеобщего удовольствия, но прежде всего я!',
    dialog_king_event_type_fm:
      'Намечается пир! Соберите всех поваров, чтобы приготовить бесчисленное множество <c1>блюд</c1>!',
    dialog_king_event_type_fd:
      'Все <c1>десерты</c1> мира, да что там — вселенной — должны быть моими! Моя любовь к сладкому неутолима!',
    dialog_king_event_type_spirits:
      'Мой королевский дух полон огня желания! Желания заполучить лучших <c1>духов</c1>, которых можно купить за деньги!',
    dialog_king_event_type_elements:
      'Все это немного стихийно, мой лавочных дел мастер, но я требую <c1>стихии</c1>, и <c1>стихии</c1> я получу!',
    dialog_king_event_category_enchantments:
      'Всемогущие <c1>чары</c1>... Они существуют! У меня много банных халатов, которые нужно заколдовать!',
    dialog_king_event_category_w:
      '<c1>Оружие</c1> — вот что действительно имеет значение! И мне не важно, какого типа!',
    dialog_king_event_category_armors:
      'Я хочу, чтобы моя армия облачилась в ослепительные <c1>доспехи</c1>! Сию же минуту!',
    dialog_king_event_category_x:
      'О человеке судят по <c1>аксессуарам</c1>, как говаривал мой отец. А я, так уж вышло, человек очень богатый.',
    dialog_king_event_category_stones:
      'Все мои эксперты говорят, что богатство дают <c1>камни</c1>! Изготовь побольше каждого вида!',
    dialog_king_event_next_reward:
      'Ты только взгляни! <c1>{0}</c1>! Красиво же, правда?',
    dialog_king_event_next_reward_02:
      'Что же дальше? <c1>{0}</c1>?! Возможно, я слишком щедр!',
    dialog_king_event_next_reward_03:
      'Смелых лавочников, которые выполняют мои просьбы, ждет такая щедрая награда, как <c1>{0}</c1>!',
    dialog_king_event_next_reward_04:
      '<c1>{0}</c1> прямо из сундуков королевства... но обещай никому не говорить!',
    dialog_king_event_next_reward_05:
      '<c1>{0}</c1>, да? Только не растрать все в одном месте.',
    dialog_king_event_generic: 'Вокруг ведь нет страшных ниндзя, а?',
    dialog_king_event_generic_02:
      'Интересно, когда Потерянный город золота появится снова?..',
    dialog_king_event_generic_03:
      'Кто поставил там Башню титанов? За нее платят налоги на недвижимость?',
    dialog_king_event_generic_04:
      'А вот и мои капризы! Исполняй их и получишь справедливое вознаграждение!',
    dialog_king_event_generic_05:
      'Сиа была НЕ в восторге от этой затеи. Но, уверен, она передумает.',
    dialog_king_event_generic_06:
      'Хорошая погода, не так ли? В королевстве Рейнгольда всегда солнечно!',
    dialog_king_event_generic_07:
      'Мне приснился ужасный сон... в котором все мои награды забрали дважды! Будь проклят каприз, я никогда не чувствовал себя таким ограбленным!',
    tutorial_press_basement: 'Коснись кнопки <c1>«Подвал»</c1>.',
    tutorial_press_basement_expansion:
      'Коснись карточки <c1>«Расширение подвала»</c1>.',
    tutorial_press_gems: 'Коснись кнопки <c1>«Кристаллы»</c1>.',
    tutorial_press_bin_select: 'Коснись <c1>корзины</c1>, чтобы ее выбрать',
    tutorial_press_basement_store:
      'Коснись кнопки <c1>«Хранить в подвале»</c1>.',
    tutorial_press_furniture_edit: 'Коснись кнопки <c1>«Редактировать»</c1>.',
    tutorial_press_basement_click: 'Нажми кнопку <c1>«Подвал»</c1>.',
    tutorial_press_basement_expansion_click:
      'Нажми на карточку <c1>«Расширение подвала»</c1>.',
    tutorial_press_gems_click: 'Нажми кнопку <c1>«Кристаллы»</c1>.',
    tutorial_press_bin_select_click:
      'Нажми на <c1>корзину</c1>, чтобы ее выбрать',
    tutorial_press_basement_store_click:
      'Нажми кнопку <c1>«Хранить в подвале»</c1>.',
    tutorial_press_furniture_edit_click:
      'Нажми кнопку <c1>«Редактировать»<c/1>.',
    dialog_sundragon_unhiredgreetings: 'Приветствую, человеческое дитя!',
    dialog_sundragon_unhiredgreetings_02:
      'Я Солдра, дракон-ремесленник с далекого Востока. Я с удовольствием поделюсь с тобой секретами своего ремесла.',
    dialog_sundragon_unhiredgreetings_03:
      'Я искусен в превращениях. Твоя раса иногда называет их <c1>синтезом</c1>.',
    dialog_sundragon_hirequestion:
      'Хочешь начать свой путь к овладению этим прекрасным искусством?',
    dialog_moondragon_unhiredgreetings:
      'Синтез — изящное ремесло, но оно требует источника лунной энергии.',
    dialog_moondragon_unhiredgreetings_02:
      'Я могу использовать сущность Луны и вложить ее в <c1>лунные камни</c1>.',
    dialog_moondragon_unhiredgreetings_03:
      'А еще я создаю особое снаряжение с <c1>противобоссовыми</c1> свойствами, которое твои герои могли бы носить.',
    dialog_moondragon_unhiredgreetings_04:
      'Пока я здесь и делаю подходящие предложения, ты можешь раскрыть настоящую силу <c1>синтеза</c1>.',
    dialog_elven_unhiredgreetings: 'Рада снова тебя видеть.',
    dialog_elven_unhiredgreetings_02:
      'Я призывательница Иоланда, мать милых фамильяров.',
    dialog_elven_unhiredgreetings_03:
      'Для ответственного лавочника я могу призвать яйца <c1>фамильяров</c1>, которые заберут защитники.',
    dialog_elven_unhiredgreetings_04:
      'А вечерами я вяжу модные <c1>плащи</c1>.',
    dialog_elven_hirequestion: 'Не хочешь ли приютить моих милых фамильяров?',
    tutorial_press_alt_fusion:
      'Коснись, чтобы выбрать <c1>кнопку «Синтез»</c1>.',
    tutorial_press_fusion_card: 'Нажми на <c1>карту синтеза</c1>.',
    tutorial_press_begin_fusion_click:
      'Нажми на кнопку <c1>«Начать синтез»</c1>.',
    tutorial_press_main_fusion: 'Нажми на <c1>кнопку «Синтез»</c1>.',
    tutorial_press_alt_fusion_click:
      'Нажми, чтобы выбрать <c1>кнопку «Синтез»</c1>.',
    tutorial_press_fusion_card_click: 'Нажми на <c1>карту синтеза</c1>.',
    tutorial_press_begin_fusion_click_click:
      'Нажми на кнопку <c1>«Начать синтез»</c1>.',
    tutorial_fusion1_01:
      'Думаю, ты уже можешь начать изучение прекрасного искусства <c1>синтеза</c1>, человеческое дитя.',
    tutorial_fusion2_01:
      'Сначала тебе нужно выбрать несколько предметов, достойных синтеза.',
    tutorial_fusion3_01:
      'Чтобы наделить силой котел синтеза, тебе также потребуется лунная энергия в <c1>лунных камнях</c1>.',
    tutorial_fusion4_01:
      'Прекрасный синтез. Ты заслуживаешь силы котла синтеза!',
    tutorial_fusion4_02:
      '<c1>Лунные камни</c1> встречаются нечасто, но, возможно, ты уговоришь мою сестру создать их для тебя.',
    tutorial_press_main_fusion_click: 'Нажми на <c1>кнопку «Синтез»</c1>.',
    offer_superiorpack_king_01: 'Приветствую!',
    offer_superiorpack_king_02:
      'Похоже, у тебя пристрастие ко всему прекрасному, что есть в жизни...',
    offer_superiorpack_king_03:
      '...причем настолько сильное, что ты покупаешь почти всю роскошь, которую только можно иметь!',
    offer_superiorpack_king_04:
      'Полагаю, небольшой бонус такой утонченной личности не помешает...',
    offer_superiorpack_king_05:
      'Только держи это в тайне! Это тайна двух блистательных аристократов!',
    offer_superiorpack_king_06:
      'Надеюсь, что уж кто-кто, а ты можешь оценить столь толковое предложение!',
    offer_superiorpack_king_07:
      'Немного времени на раздумья есть, но возможность эта будет недолгой!',
    tutorial_throwback_pack1_01: 'Мой доблестный лавочных дел мастер!',
    tutorial_throwback_pack2_01:
      'Я рылся в старых гроссбухах и наткнулся на нечто важное...',
    tutorial_throwback_pack2_02:
      'Все прошлые предложения от горожан! Настоящий клад, вызывающий ностальгию!',
    tutorial_throwback_pack2_03:
      'Если, по-твоему, что-то было тобой упущено, вот шанс все исправить!',
    tutorial_throwback_pack3_01:
      'Прекрасный выбор! Я бы поступил точно так же!',
    tutorial_throwback_pack4_01:
      'Обстоятельное раздумывание не принадлежит к числу моих добродетелей, но оно все-таки заслуживает уважения!',
    visitor_thought_ghosted: 'Х-холодно!',
    visitor_thought_ghosted_02: 'Просто как-то холодно стало!..',
    visitor_thought_ghosted_03: 'Странное ощущение...',
    visitor_thought_ghosted_04: 'А?',
    visitor_thought_ghosted_05: 'Бр-р-р!',
    dialog_essence_chat_11: 'Не забывай оказывать уважение... мертвым...',
    dialog_essence_chat_12:
      'Я не могу держать в руках ничего материального... Уборку проводить... трудно.',
    shortsword_name: 'Меч сквайра',
    shortsword_desc: 'Прочный, легкий клинок, какой найдется у любого воина.',
    longsword_name: 'Рыцарский меч',
    longsword_desc: 'Вооружен и готов.',
    broadsword_name: 'Гладиус',
    broadsword_desc: 'Стоит своего веса солью.',
    grottosword_name: 'Древесный клинок',
    grottosword_desc: 'А вы не забываете поливать свой меч?',
    zweihander_name: 'Двуручный меч',
    zweihander_desc:
      'Длинный-длинный меч для сильных сердцем и готовых на подвиги.',
    cutlass_name: 'Абордажная сабля',
    cutlass_desc: 'Осталось только повязку на глаз найти. И деревянную ногу.',
    fallsword_name: 'Зазубренный чинкуэда',
    fallsword_desc:
      'Уникальный дизайн по мотивам одной из многочисленных пород деревьев, растущих в Воющем лесу.',
    rapier_name: 'Рапира',
    rapier_desc: 'Как у тебя с ответными ударами?',
    herosword_name: 'Меч героя',
    herosword_desc:
      'Откуда бы он ни взялся, ему суждено когда-нибудь спасти мир.',
    candysword_name: 'Шоколакомый клинок',
    candysword_desc:
      'Он мог бы быть на несколько рангов выше, если бы кое-кто не откусил кусочек.',
    katana_name: 'Катана',
    katana_desc:
      'Из сложенной более тысячи раз стали. Легко разрубает рыцарей.',
    scimitar_name: 'Королевский клинок',
    scimitar_desc: 'Этот шедевр так и просит, чтобы на него наложили чары.',
    cyclopssword_name: 'Рунный клинок Кроноса',
    cyclopssword_desc:
      'Этот тяжелый каменный меч не очень хорошо режет, но оставляет раны, которые не сможет залечить время.',
    celesteelblade_name: 'Небестальной клинок',
    celesteelblade_desc:
      'Ангелы сохраняли этот особый металл в тайне в течение столетий. А потом какой-то Прометей проболтался.',
    ruinssword_name: 'Дамоклов меч',
    ruinssword_desc: 'В воздухе зависло ощущение беспокойства...',
    goldsword_name: 'Роскошный макуауитль',
    goldsword_desc:
      'Усилен зазубренными осколками Кристалла времени, чтобы гарантировать, что тому, по ком придется удар, точно не поздоровится.',
    newyearsword_name: 'Кристаллическая рапира',
    newyearsword_desc:
      'Раз в тысячелетие этот кристалл необъяснимым образом становится очень пластичным. Это единственная возможность для кузнеца создать такой шедевр!',
    falchion_name: 'Мифриловое лезвие',
    falchion_desc:
      'Эти мастерски изготовленные клинки имеются только у элитных телохранителей императора Керна.',
    poloniacutlass_name: 'Клинок морехода',
    poloniacutlass_desc:
      'Искусно выкованное оружие, принадлежавшее, как говорят, самому Бароссу Дель Монте.',
    canesword_name: 'Клинок джентльмена',
    canesword_desc:
      'Истинный джентльмен знает, когда нужно убрать свой клинок.',
    cleaver_name: 'Большущий тесак',
    cleaver_desc: 'Как это вообще можно поднять?',
    templesword_name: 'Каладболг',
    templesword_desc: 'Наносите удар с силой обрушивающейся волны.',
    dragonsword_name: 'Дао дракона',
    dragonsword_desc:
      'Только друзья драконов могут владеть этим клинком без риска драконьего возмездия.',
    lunarsword_name: 'Клинок тигра',
    lunarsword_desc:
      'Клинок справедливого императора давней эпохи. Говорят, что он был свиреп и коварен, как тигр.',
    vorpalsword_name: 'Стрижающий меч',
    vorpalsword_desc: 'Покатятся головы.',
    victoriansword_name: 'Изящная рапира',
    victoriansword_desc: 'Модная и смертоносная!',
    moonsword_name: 'Цукуёми',
    moonsword_desc:
      'Участвовал в крупном бою с Аматэрасу, из-за чего ночь и день навсегда разделились.',
    t12sword_name: 'Экскалибур',
    t12sword_desc:
      'Тот, кто вытащит его из камня, станет королем. Поскольку вещь изготовлена вами, не считается.',
    shoprsword_name: 'Меч титана',
    shoprsword_desc:
      'Возраст титанов... Три года, что ли? Как такое вообще возможно?',
    goldsword2_name: 'Люксовый клинок',
    goldsword2_desc: 'С таким толстым клинком лучше не связываться.',
    musketeersword_name: 'Шпага д\'Артаньяна',
    musketeersword_desc:
      'Эта стремительная и надежная шпага предназначена для умелых и достойных рук.',
    pumpkinsword_name: 'Шип презрения',
    pumpkinsword_desc: 'Изображение тыквенного лица, искаженного насмешкой...',
    t13sword_name: 'Костер джинна',
    t13sword_desc:
      'Там есть джинн, с которым сначала нужно подружиться. У него горячий нрав.',
    spacesword_name: 'Плазменная сабля',
    spacesword_desc:
      'Требуется одна ядерная батарейка АА, входящая в комплект. Работает целых пять минут!',
    boysaxe_name: 'Топор дровосека',
    boysaxe_desc: 'Пора колоть дрова... то бишь черепа врагов.',
    hatchet_name: 'Секира',
    hatchet_desc: 'Уходя из дома, возьми с собой!',
    doublebitted_name: 'Железный колун',
    doublebitted_desc: 'Одно лезвие — хорошо, а два — лучше.',
    exploreraxe_name: 'Топор исследователя',
    exploreraxe_desc:
      'Отправляйтесь туда, куда не ступала нога исследователя, и не забудьте прихватить этот верный топор!',
    halberd_name: 'Бердыш',
    halberd_desc: 'Не рекомендуется детям до 10 лет.',
    grottoaxe_name: 'Расплавленный вульж',
    grottoaxe_desc:
      'Жутко эффективное оружие, но может прижечь раны, которые сам же нанес.',
    epicaxe_name: 'Кирка демонтажника',
    epicaxe_desc: 'Если бы утилизация всегда была такой простой!',
    tomahawk_name: 'Томагавк',
    tomahawk_desc: 'При броске не возвращается.',
    battleaxe_name: 'Боевой топор',
    battleaxe_desc: 'Гномы всегда держат такой под подушкой.',
    birdaxe_name: 'Хищный жнец',
    birdaxe_desc: 'И кто здесь пожинает плоды теперь?',
    starteraxe_name: 'Топор-спутник',
    starteraxe_desc:
      'Может похвастаться идеальной рукоятью для опытного искателя приключений!',
    snowaxe_name: 'Топор из холодного железа',
    snowaxe_desc:
      'Выкован магическим образом без какого-либо нагрева. Особенно не нравится феям и им подобным.',
    steelaxe_name: 'Гномий двуручный топор',
    steelaxe_desc: 'Это здор-р-р-р-р-р-рово!',
    pyramidaxe_name: 'Камнедробитель',
    pyramidaxe_desc:
      'С этим прекрасным нефритовым топором гномьей работы вы никогда не будете рубить сплеча.',
    raideraxe_name: 'Топор налетчика',
    raideraxe_desc: 'Меньше разговоров, больше налетов!',
    lunaraxe_name: 'Колун Цянь',
    lunaraxe_desc: 'Примените жесткий подход к коммерции и урезайте цены!',
    executioner_name: 'Палач',
    executioner_desc: 'Часто работает с судьёй и присяжными.',
    castleaxe_name: 'Чистилище',
    castleaxe_desc: 'Правосудие определяется теми, кто его требует.',
    cincoaxe2020_name: 'Топор Синко',
    cincoaxe2020_desc:
      'Яркий и красочный. Вызывает некоторую тревогу, что он при замахе направляется в вас.',
    crescentaxe_name: 'Затмение',
    crescentaxe_desc: 'Быстро прикончите обитателей ночи.',
    templeaxe_name: 'Земля тирана',
    templeaxe_desc:
      'Владельцу этого свирепого первобытного оружия не чужда ирония.',
    goldaxe_name: 'Люксовая секира',
    goldaxe_desc: 'Лучший способ отсечь все плохое.',
    coupleaxe_name: 'Тесак кадьяка',
    coupleaxe_desc:
      'Может разрубить все что угодно... кроме двух влюбленных мускулистых берсерков.',
    manticoreaxe_name: 'Истребитель мантикор',
    manticoreaxe_desc:
      'Шипы на этом свирепом орудии иногда приходят в действие сами по себе, пытаясь дотянуться до жертвы.',
    beeaxe_name: 'Топрополис',
    beeaxe_desc:
      'Безжалостно липкий. Может призвать ко владельцу окрестных медведей.',
    fallaxe_name: 'Грибная гибель',
    fallaxe_desc:
      'Говорят, этот безжалостный топор, некогда принадлежавший легендарному варвару, наводил ужас на всех грибандитов. Требуется постоянный уход, если вы не хотите, чтобы к нему прилипли случайные споры!',
    aegiraxe_name: 'Гроза йотунов',
    aegiraxe_desc: 'Берегитесь, великаны, ибо вернулась Гроза йотунов.',
    t12axe_name: 'Полумесяц завоевания',
    t12axe_desc:
      'Династии и легионы падут перед неудержимой яростью этого легендарного топора.',
    chessaxe_name: 'Топор ониксового короля',
    chessaxe_desc:
      'Считается, что этот ужасный проклятый топор не способен никого победить в бою. Однако его страшная сила заключается в том, что он приказывает другим сражаться за него.',
    valentineaxe_name: 'Раздор влюбленных',
    valentineaxe_desc: 'Хотите помочь вашей паре распасться? Ну что же...',
    dinoaxe_name: 'Хищный кровотопор',
    dinoaxe_desc: 'С этим можно устроить настоящий бедлам.',
    t13axe_name: 'Вечное лезвие',
    t13axe_desc:
      'Вырезано из лунного камня. Таинственным образом его материал не похож на нашу собственную луну.',
    firecrabaxe_name: 'Раскаленный краболом',
    firecrabaxe_desc: 'Даже война не защищена от ракообразных.',
    kitchenknife_name: 'Самодельный нож',
    kitchenknife_desc: 'Голь на выдумки хитра.',
    forestdagger_name: 'Ледоруб',
    forestdagger_desc:
      'Ледоруб, сделанный изо льда, который вырубили, чтобы сделать ледоруб.',
    dagger_name: 'Быстрый клинок',
    dagger_desc: 'Бесшумный и смертелный.',
    kunai_name: 'Кунай',
    kunai_desc:
      'Удивительный многоцелевой инструмент из далекой страны. Для его изготовления требуется особая техника, известная немногим.',
    switchblade_name: 'Потайной нож',
    switchblade_desc: 'Надежный и незаметный.',
    butterflyknife_name: 'Балисонг',
    butterflyknife_desc:
      'Как нож в масло входит в спину ничего не подозревающего союзника.',
    kris_name: 'Ритуальный кинжал',
    kris_desc: 'Церемониальное оружие королевских особи и священников.',
    atlab_boomerang_name: 'Бумеранг Сокки',
    atlab_boomerang_desc: '«Бумеранг! Ты действительно всегда возвращаешься!»',
    yamidagger_name: 'Танто убийцы',
    yamidagger_desc: 'Серийный номер спилен...',
    schooldagger2020_name: 'Вектор эрудита',
    schooldagger2020_desc: 'Всегда под правильным углом.',
    dirk_name: 'Королевский кинжал',
    dirk_desc:
      'Безжалостное оружие королевских стражей, используемое, как последнее средство.',
    lunardagger_name: 'Чакрам облачного странника',
    lunardagger_desc: 'Летит к цели на крыльях ветра...',
    guttingknife_name: 'Рыбник',
    guttingknife_desc: 'Хорошо разбирается в кишках.',
    beachdagger_name: 'Фруктовый лед',
    beachdagger_desc: 'Вдвойне вкуснее в жаркие летние дни, но быстро тает.',
    junimaydagger_name: 'Совок Джунипер',
    junimaydagger_desc:
      'Этот садовый инструмент использовался много раз, но не потерял своей остроты.',
    trolltooth_name: 'Зуб тролля',
    trolltooth_desc:
      'Девять из десяти дантистов рекомендуют чистить его два раза в день.',
    ruinsdagger_name: 'Клык левии',
    ruinsdagger_desc:
      'От какого бы зверя это ни досталось, возможно, он захочет его вернуть! Будьте осторожны!',
    misericordia_name: 'Мизерикордия',
    misericordia_desc: 'Для милосердного избавления.',
    golddagger_name: 'Роскошный кинжал',
    golddagger_desc:
      'Выглядит так, будто готов на свою ежедневную долю кровавых жертвоприношений!',
    sultandagger_name: 'Кинжал султана',
    sultandagger_desc: 'Держите такой под подушкой, чтобы лучше спалось.',
    katar_name: 'Церемониальный катар',
    katar_desc: 'Не дайте украшениям обмануть вас, бьет эта штука неслабо.',
    templedagger_name: 'Кошмарный клинок',
    templedagger_desc: 'Трижды оскверненный в безлунную ночь.',
    siadagger_name: 'Лебединое лезвие',
    siadagger_desc:
      'Когда забрел далеко на вражескую территорию, единственным союзником становится острый клинок.',
    stpatrickdagger_name: 'Удар удачи',
    stpatrickdagger_desc:
      'Прячьте его в рукаве, чтобы иметь возможность нанести максимальный критический удар.',
    heartseeker_name: 'Искатель сердец',
    heartseeker_desc: 'Советую не бегать с ним без ножен!',
    peakdagger_name: 'Последний вздох',
    peakdagger_desc: 'Последние объятия с легкой лаской.',
    halloweendagger_name: 'Стрела Ночного крыла',
    halloweendagger_desc:
      'Обычно используется в качестве боеприпаса для самострела Ночного крыла, но может служить и крутым кинжалом.',
    atlab_kyoshifan_name: 'Веер Воина Киоши',
    atlab_kyoshifan_desc:
      'Уникальное оружие, которым владеют только Воины Киоши. Острее, чем кажется!',
    wiccandagger_name: 'Викканский кинжал',
    wiccandagger_desc:
      'В глубине Ведьминого леса проводятся таинственные ритуалы... Этот кинжал, возможно, много чего повидал!',
    t12dagger_name: 'Шестой грех',
    t12dagger_desc:
      'Как повезло всем этим людям. Если бы только все это могло быть вашим...',
    mushroomdagger_name: 'Небестальной кастет',
    mushroomdagger_desc: 'Кто хочет зуботычину?',
    golddagger2_name: 'Люксовый текпатль',
    golddagger2_desc:
      'Клинок состоит из впечатляющего и изысканного сплава обсидиана с золотом. Неожиданное сочетание, но оно окупилось!',
    lunardagger2_name: 'Боевой веер императрицы',
    lunardagger2_desc:
      'При дворе, окутанном интригами, многое может скрываться за вежливой улыбкой и изящным аксессуаром.',
    t13dagger_name: 'Ноктюрн',
    t13dagger_desc:
      'Украшенный витражным стеклом из павших соборов, этот кинжал является мощным катализатором во многих темных ритуалах.',
    campingdagger_name: 'Мультитул охотника',
    campingdagger_desc:
      'Режет, кромсает, рубит и разрывает. И не стоит забывать о специальном штопоре, которым можно прикончить врага.',
    club_name: 'Дубина',
    club_desc: 'Примитивная и на удивление эффективная.',
    spikedclub_name: 'Палица с шипами',
    spikedclub_desc: 'Говори мягко... и носи с собой здоровенную палку.',
    forestmace_name: 'Ветка темного дерева',
    forestmace_desc:
      'Говори мягко... и носи с собой большую проклятую палку с гигантского дерева-людоеда.',
    hammer_name: 'Боевой молот',
    hammer_desc: 'Слава Сигмару.',
    libertymace_name: 'Булава свободы',
    libertymace_desc: 'Ярко сияет от света свободы.',
    morningstar_name: 'Моргенштерн',
    morningstar_desc: 'Не совсем то, что хочется увидеть спросонья.',
    summermace_name: 'Надувной молот',
    summermace_desc: 'Не дайте веселым цветам обмануть вас — это не игрушка.',
    heavymace_name: 'Шестопер',
    heavymace_desc: 'Не слишком ли, а?',
    newyearmace_name: 'Звезда первого утра',
    newyearmace_desc:
      'Может рассыпаться на конфетти при ударе. Подходящее вооружение для первого утра нового года!',
    ancienthammer_name: 'Череполом',
    ancienthammer_desc: 'Варвары с севера обычно не лечат, а калечат.',
    troblinmace_name: 'Палица гиблина',
    troblinmace_desc:
      'Еще одно чудесное изобретение Толстокожа. Шипы наносят больше урона, а лозы добавляют порцию зелени.',
    midnightstar_name: 'Абендштерн',
    midnightstar_desc: 'Вечерняя звезда. Давно потерянный брат моргенштерна.',
    pyramidmace_name: 'Метеорный молот',
    pyramidmace_desc: 'Крайне эффективен против динозавров.',
    arcademace_name: 'Кнопочный крушитель',
    arcademace_desc:
      'Не хватает еще нескольких кнопок, чтобы ввести тот чит-код.',
    powderkeg_name: 'Пороховая бочка',
    powderkeg_desc:
      'Вот что происходит, когда у Роксаны в руках оказывается чертеж булавы.',
    pumpkinmace_name: 'Тумак-фонарь',
    pumpkinmace_desc: 'Сладость или гадость.',
    candyhammer_name: 'Мятная колотушка',
    candyhammer_desc:
      'Когда обычной мяты не хватает, чтобы донести свою мысль...',
    tenderizer_name: 'Размягчитель',
    tenderizer_desc: 'Чтоб помягче стали.',
    smithhammer_name: 'Киянка Уоллеса',
    smithhammer_desc: 'Создавайте и уничтожайте одинаково легко!',
    freedommace_name: 'Полосатая звезда',
    freedommace_desc: 'После удара ею это единственное, что вы увидите.',
    nautilus_name: 'Наутилус',
    nautilus_desc: 'Легко погружается во что угодно.',
    goldhammer_name: 'Люксовый молот',
    goldhammer_desc: 'Заплатите кому-нибудь, чтобы носили его вместо вас.',
    mundrahammer_name: 'Избиватель Лундры',
    mundrahammer_desc:
      'Боссы в любом подземелье начинают нервно потеть при одном упоминании этого молота.',
    emeraldhammer_name: 'Ториевый молот',
    emeraldhammer_desc: 'Будущее уже наступило, Тор.',
    peakhammer_name: 'Удар солнечного света',
    peakhammer_desc:
      'Требуется примерно 7 лет непрерывного солнечного света, чтобы раскрыть весь потенциал.',
    naturehammer_name: 'Молот Геи',
    naturehammer_desc: 'В ударе чувствуется вес всего мира.',
    atlab_mace_name: 'Булава Сокки',
    atlab_mace_desc: 'Прочная булава из кости, изготовленная самим Соккой.',
    t12mace_name: 'Мьёльнир',
    t12mace_desc: 'Звонил Тор, просил назад свой молот.',
    eastermace_name: 'Сокрушитель яиц',
    eastermace_desc: 'О нет! Тут острые края!',
    christmasmace_name: 'Рождественская булава',
    christmasmace_desc: 'С Рождеством и Новым годом!',
    t13mace_name: 'Страж утесов',
    t13mace_desc:
      'В давние времена существа ростом с гору защищали сушу и море. Их долг продолжает жить в этом священном молоте.',
    musicmace_name: 'Сногсшибательный хит',
    musicmace_desc:
      'Это не воображаемая гитара, и, возможно, у нее есть связь со стихией воздуха.',
    platinummace_name: 'Платиновый молот',
    platinummace_desc: 'После сногсшибательного хита следует платиновый сингл.',
    woodspear_name: 'Метательное копье',
    woodspear_desc:
      'Простое оружие, использующееся воинами не первое тысячелетие.',
    ironspear_name: 'Охотничье копье',
    ironspear_desc: 'Колоть или метать — дело твое!',
    bladedspear_name: 'Копье с лезвием',
    bladedspear_desc: 'Что если насадить меч на палку? Гениально!',
    pitchfork_name: 'Крепкие вилы',
    pitchfork_desc:
      'Лучший инструмент для того, чтобы класть сено, колоть монстров в задницы и прогонять проклятых детишек с вашей лужайки!',
    ranseur_name: 'Рунка',
    ranseur_desc: 'Длинное колющее оружие, используемое королевскими стражами.',
    trident_name: 'Тришула',
    trident_desc: 'Кому рыбных палочек?',
    easterspear_name: 'Копье Крокро',
    easterspear_desc:
      'Буквально морковка на палочке. Такое любят лошади (и вроде бы феи).',
    pike_name: 'Спетум',
    pike_desc: 'Три зубца означают тройной урон, верно?',
    heavenlyhalberd_name: 'Алебарда военачальника',
    heavenlyhalberd_desc:
      'Поднимите ее над собой и нанесите удар благодаря гневу небес.',
    desertspear_name: 'Копье Тритона',
    desertspear_desc:
      'Наложенные чары делают возможным метание и дыхание под водой. К сожалению, эти способности являются взаимоисключающими.',
    celticspear_name: 'Копье Кухулина',
    celticspear_desc:
      'Также известное как «копье смертельной боли». Остальное пусть домысливает ваше воображение.',
    partisan_name: 'Королевская алебарда',
    partisan_desc: 'Для гордых и самоуверенных!',
    yetispear_name: 'Копье Сосульки',
    yetispear_desc:
      'Йети умеют изготавливать предметы изо льда. Копье Сосульки — одно из простейших, его могут сделать даже новички.',
    africanspear_name: 'Ассегай воина',
    africanspear_desc:
      'Предназначен для использования в ближнем бою или метания. Причиняет боль, когда вонзается и когда извлекается.',
    championlance_name: 'Копье защитника',
    championlance_desc:
      'Победитель бесчисленных поединков. Кто бы им ни владел, у него есть богатое наследие, которому нужно соответствовать.',
    fallspear_name: 'Яри заката',
    fallspear_desc: 'Багровое солнце заходит над изобильными землями.',
    wyvernglaive_name: 'Глефа «Виверна»',
    wyvernglaive_desc: 'Голова дракона... постоянно вопит. Безостановочно.',
    sylvanspear_name: 'Копье белозуба',
    sylvanspear_desc:
      'Аллан предпочитает работать по дереву, но кости фантастического зверя стали для него уникальным испытанием.',
    goldspear_name: 'Роскошное копье',
    goldspear_desc: 'Монарх неизбежно вас достанет.',
    stellarspear_name: 'Стеллария',
    stellarspear_desc: 'Гарантирует блестящий результат.',
    templespear_name: 'Копье Лонгина',
    templespear_desc:
      'Очевидно, давным-давно им ткнули в кого-то очень важного.',
    thanksgivingspear_name: 'Алебарда птичьей погибели',
    thanksgivingspear_desc:
      'Над этим оружием смеются только те глупцы, которых никогда не заклевывали почти до смерти грифоны.',
    feyspear_name: 'Дар Титании',
    feyspear_desc:
      'Дар королевы фей. Придется заплатить какую-то цену? Определенно.',
    circusspear_name: 'Копье шута-поединщика',
    circusspear_desc:
      'Очевидно, это оружие было создано Боровицем в шутку. Правда, рыцарям не было смешно, когда оно одерживало победу за победой.',
    christmasspear_name: 'Копье святого вечера',
    christmasspear_desc:
      'Во время Первого Рождества Санта-Клаус с помощью него победил Уробороса Пожирателя Солнца. Эта часть истории держится в секрете от широкой публики.',
    t12spear_name: 'Гнев небес',
    t12spear_desc: 'Высшие силы редко скрывают свое разочарование.',
    airshipspear_name: 'Пика-пропеллер',
    airshipspear_desc:
      'При броске пики активируется пропеллер, обеспечивая дополнительную дальность поражения. Топливом служит вода, так что это очень экологичная штука!',
    beachspear_name: 'Колющий зонт',
    beachspear_desc:
      'Ремень можно ослабить, чтобы использовать зонт в качестве щита. А вот вернуть его в режим копья — это та еще морока.',
    goldspear2_name: 'Люксовая алебарда',
    goldspear2_desc: 'Дается священным хранителям теотля.',
    t13spear_name: 'Почерневший бердыш',
    t13spear_desc:
      'Когда-то этот бердыш был приятного серебристого оттенка, но теперь он навсегда обожжен жутким пламенем, которое из него вырывается.',
    riftspear_name: 'Бурное течение',
    riftspear_desc:
      'Один удар сродный тому, как если бы вас унесло безжалостное море.',
    inuitspear_name: 'Ужасный какивак',
    inuitspear_desc: 'Рыбы боятся. Мужчины хотят.',
    shortbow_name: 'Тренировочный лук',
    shortbow_desc: 'Хлипкий лук, сделанный из прочной ветки и тонкой бечевки.',
    forestbow_name: 'Попутный ветер',
    forestbow_desc:
      'При стрельбе из этого лука вам совершенно нипочем встречный ветер.',
    longbow_name: 'Вязовый лук',
    longbow_desc: 'Больше не промажешь.',
    hunterbow_name: 'Рефлексивный лук',
    hunterbow_desc: 'Запомни, острый конец — наружу.',
    siabow_name: 'Великолепная арфа',
    siabow_desc:
      'Изящный лук, созданный самой Сиа. Обладает замечательной силой, но в то же время легок в использовании.',
    strikerbow_name: 'Составной лук',
    strikerbow_desc: 'Бьет по цели со зверской меткостью.',
    spikedbow_name: 'Шипастый лук',
    spikedbow_desc: 'Яблокам каюк.',
    halloweenbow_name: 'Ива всех святых',
    halloweenbow_desc:
      'Страшно проклятый лук, ставший пригодным для использования благодаря многочисленным благословениям. Однако неизвестно, как долго они продержатся.',
    wingedbow_name: 'Крылатый лук',
    wingedbow_desc: 'Стрелы из этого лука летят подобно хищным птицам.',
    desertbow_name: 'Крыло квезаля',
    desertbow_desc: 'С таким названием он точно должен быть мощным, верно?',
    compositebow_name: 'Арабеска',
    compositebow_desc: 'Неоспоримо смертоносное произведение искусства.',
    westernbow_name: 'Арбалет мастака',
    westernbow_desc:
      'Местные законы об охоте разрешают луки? У нас есть оружие для вас!',
    yumi_name: 'Юми',
    yumi_desc:
      'Асимметричная конструкция требует некоторого времени для привыкания, но нельзя отрицать мастерскую работу.',
    valentinebow_name: 'Лук Купидона',
    valentinebow_desc:
      'По-купидонски эффективный лук двойного назначения: для любви и войны.',
    mundrabow_name: 'Роговой лук Лундры',
    mundrabow_desc:
      'Сила натяжения предназначена для мускулистых драконов. Если уделяете внимание ловкости, ищите что-то другое!',
    bramblebane_name: 'Колючая гроза',
    bramblebane_desc: 'Станьте в прямом смысле занозой для врага!',
    fallbow_name: 'Кленовая буря',
    fallbow_desc:
      'Ветер обрывает листья с деревьев, оставляя те в оцепенении перед грядущей зимой.',
    twinstrand_name: 'Удар Близнецов',
    twinstrand_desc: 'Два по цене одного.',
    christmasbow_name: 'Веселый охотник',
    christmasbow_desc: 'Такое праздничное время.',
    stormrend_name: 'Терзающая буря',
    stormrend_desc: 'Услышь меня, гром! Возопи, молния!',
    goldbow_name: 'Люксовый длинный лук',
    goldbow_desc:
      'В основном для церемониальных целей. Золото не очень хорошо гнется.',
    cincobow_name: 'Охотник за пиньятами',
    cincobow_desc: 'Пусть сыплются конфеты. Пусть сыплются конфеты.',
    newyearbow_name: 'Восходящая звезда',
    newyearbow_desc:
      'Стремитесь к звездам и луне. Остерегайтесь незащищенного возвращения в атмосферу.',
    frogbow_name: 'Веточный лук',
    frogbow_desc:
      'Несмотря на свой заурядный внешний вид, для топи это нечто нестандартное.',
    t12bow_name: 'Олимп',
    t12bow_desc:
      'Необычайно мощный лук, давным-давно украденный у богов. Они, вероятно, уже забыли о нем, но вам, вероятно, не стоит выставлять его напоказ.',
    arcadebow_name: 'Мощный флиппер',
    arcadebow_desc:
      'Всего четвертак за выстрел, и мир становится вашим автоматом для игры в пинбол.',
    thanksgivingbow_name: 'Элитный следопыт',
    thanksgivingbow_desc:
      'Этот богато украшенный лук, скорее всего, принадлежал прославленному охотнику.',
    t13bow_name: 'Великий монарх',
    t13bow_desc:
      'Он жаждет свободы. Некомпетентные владельцы могут внезапно обнаружить однажды утром, что их обезоружили.',
    blossombow_name: 'Дзиндай-закура',
    blossombow_desc:
      'Лук вырезан из ветки самого старого вишневого дерева в мире. Сейчас есть законы, запрещающие это делать.',
    riftbow_name: 'Привязанные к земле крылья',
    riftbow_desc: 'Там, где поет этот лук, земля раскалывается на части.',
    woodbranch_name: 'Деревянная клюка',
    woodbranch_desc:
      'Грубый посох, вырезанный из ветки со слабой магической аурой.',
    cane_name: 'Прочная трость',
    cane_desc: 'Хорошую трость найти непросто.',
    oakstaff_name: 'Дубовый посох',
    oakstaff_desc: 'Напитан эссенцией древнего дерева.',
    lilustaff_name: 'Совиный насест',
    lilustaff_desc:
      'Замысловатый посох, вырезанный в форме Поллукса, совы Лилу. Слишком сильно размахивать не надо.',
    goldstaff_name: 'Роскошная палка',
    goldstaff_desc:
      'Самая обычная, но отполированная и позолоченная до неприличия.',
    starterstaff_name: 'Посох ученицы',
    starterstaff_desc: 'В сущности накладывает заклинания вместо вас.',
    bo_name: 'Посох странника',
    bo_desc: 'Для волшебников, предпочитающих ближний бой.',
    scepter_name: 'Нефритовый скипетр',
    scepter_desc:
      'Наделенный немалой силой посох, обычно используемый для самоутверждения.',
    swampstaff_name: 'Янтарный посох',
    swampstaff_desc:
      'В этом янтаре покоится что-то древнее. Что-то достаточно мощное, чтобы усиливать заклинания чародея...',
    wiccanstaff_name: 'Викканская метла',
    wiccanstaff_desc:
      'Обычно виккане заколдовывают свою метлу, чтобы она служила им запасным посохом.',
    wizardstaff_name: 'Посох чародея',
    wizardstaff_desc: 'Теперь вам подвластна вся мощь элементов.',
    celestialrod_name: 'Небесный посох',
    celestialrod_desc:
      'Говорят, раскрывает свой тайный потенциал, когда звезды выстраиваются в линию.',
    imperialaquila_name: 'Имперская аквила',
    imperialaquila_desc:
      'Престижный посох, используемый имперскими боевыми магами.',
    mundrastaff_name: 'Скипетр Лундры',
    mundrastaff_desc:
      'В руках кого-то еще, кроме Лундры, выглядит слишком аляповато.',
    ruinsstaff_name: 'Посох феникса',
    ruinsstaff_desc:
      'Каким бы вечным ни казался этот посох, он сломается, как и любой другой. Учимся смирению.',
    leafstaff_name: 'Посох времен года',
    leafstaff_desc: 'Со временем появляется седина, а рассудок уходит.',
    transcendance_name: 'Запредельность',
    transcendance_desc:
      'Звон всякий раз напоминает, что лучшее оружие — разум.',
    castlestaff_name: 'Вестник приливов',
    castlestaff_desc:
      'Медленно, но верно океан забирает то, что принадлежит ему.',
    lemonstaff_name: 'Пикантный скипетр',
    lemonstaff_desc:
      'Если жизнь преподносит вам лимоны... используйте их для заклинаний.',
    conflux_name: 'Серафим',
    conflux_desc: 'Слышите зов рога?',
    christmasstaff_name: 'Посох веселья',
    christmasstaff_desc:
      'Воздержитесь от его использования в течение оставшихся одиннадцати месяцев года.',
    penumbra_name: 'Полутень',
    penumbra_desc: 'Постоянный рассвет на границе между светом и тьмой.',
    yetistaff_name: 'Скипетр Ледяной королевы',
    yetistaff_desc:
      'Говорят, что Ледяная королева, которую можно увидеть лишь изредка, правит йети. Возможно, власть над ними дает этот посох!',
    valentinestaff_name: 'Посох пленителя сердец',
    valentinestaff_desc:
      'Мое сердце пленено! Разве там не было что-то про карты?',
    t12staff_name: 'Скипетр Ксолимнула',
    t12staff_desc:
      'Чтобы владеть этим ужасным скипетром, требуется заключить самый темный договор. Только задайте себе вопрос, действительно ли все у вас под контролем.',
    goldstaff2_name: 'Люксовый посох',
    goldstaff2_desc:
      'Этот великолепный посох с перьями когда-то принадлежал исключительно ведущим древних церемоний.',
    hemmadonstaff_name: 'Посох стража душ',
    hemmadonstaff_desc: 'Блаженный вечный сон... Чудесно, не правда ли?',
    anubisstaff_name: 'Анх фараона',
    anubisstaff_desc:
      'Владеть этим посохом — это с совершенной легкостью использовать страшные проклятия.',
    t13staff_name: 'Девятое небо',
    t13staff_desc:
      'Формирование облаков меняется в зависимости от настроения владельца. Остерегайтесь кучевых!',
    riftstaff_name: 'Регулятор гравитации',
    riftstaff_desc:
      'Требует мага, владеющего телекинезом, так как посох весит несколько тысяч фунтов.',
    musicstaff_name: 'Флейтозвон',
    musicstaff_desc:
      'Эту вещь чуть было не назвали «Посохтава», но сочли, что звучит глупо.',
    collectionstaff_name: 'Скипетр вечной мудрости',
    collectionstaff_desc:
      'Скипетр дорого обходится владельцу, постоянно забирая воспоминания того и добавляя их к собственным... пока не остается ничего.',
    baton_name: 'Жезл',
    baton_desc:
      'Основа сделана из качественных материалов, но это не перо феникса!',
    twirled_name: 'Эльфийская палочка',
    twirled_desc:
      'Свойства эльфийского дерева остаются неизменными, как и его форма.',
    backscratcher_name: 'Палочка чернокнижника',
    backscratcher_desc: 'Можно также почесать спину.',
    steelrod_name: 'Стальной прут',
    steelrod_desc: 'Не стоит использовать его для наложения заклинаний молнии.',
    christmaswand_name: 'Мятная трость',
    christmaswand_desc: 'У вас будет свежее дыхание! Обязательно будет!',
    starrod_name: 'Звездный прут',
    starrod_desc: 'Особый товар, привезенный из мира грез.',
    sylvan_name: 'Сильванель',
    sylvan_desc: 'Дирижируй магической симфонией природы.',
    fallwand2_name: 'Гохэй-тётин',
    fallwand2_desc:
      'Удобная вещь, два в одном: можно освещать ночь и очищать все остальное! Правда, ленты немного жестковаты.',
    rubyrod_name: 'Рубиновая палочка',
    rubyrod_desc: 'Это вовсе не секрет, что прекрасней палки нет!',
    liluwand_name: 'Палочка совиного крыла',
    liluwand_desc: 'Попробуйте сказать это очень быстро пять раз подряд.',
    goldwand_name: 'Волшебная палочка Мидаса',
    goldwand_desc:
      'Все, чего вы касаетесь, превращается в золото. В переносном смысле. Верьте в себя!',
    flowerwand_name: 'Вечнозеленая палочка',
    flowerwand_desc: 'Крокус-покус.',
    fallwand_name: 'Прут равноденствия',
    fallwand_desc: 'Предвестник смены времен года.',
    moonwand_name: 'Звездный дирижер',
    moonwand_desc: 'Небесным танцем должен кто-то управлять.',
    wizardwand_name: 'Великая палочка Гримара',
    wizardwand_desc: 'Бесследно утерянная... до недавнего времени!',
    dragoneye_name: 'Драконий глаз',
    dragoneye_desc:
      'Отводите взгляд, если не хотите привлечь ненужное внимание.',
    lcogwand_name: 'Люксовая палочка',
    lcogwand_desc:
      'Эксперты говорят, что, возможно, это древний забытый вариант волшебной палочки Мидаса.',
    sailorwand_name: 'Лунная палочка',
    sailorwand_desc:
      'Для надлежащей активации ее скрытых способностей требуется длительная трансформационная последовательность.',
    t12wand_name: 'Вестник конца',
    t12wand_desc:
      'Из одного из своих многочисленных ртов Вестник спел песню, которая положит конец привычной нам жизни.',
    chesswand_name: 'Жезл королевы слоновой кости',
    chesswand_desc: 'Погибель для коней, слонов и ладей во всем мире.',
    maplewand_name: 'Жарочная вилка',
    maplewand_desc:
      'Эта мистическая вилка способна поджарить любое мясо, в которое она воткнута, при условии наличия источника тепла.',
    t13wand_name: 'Пирокварцевый жезл',
    t13wand_desc:
      'Магический кварц, перегретый до уровня чуть ниже безопасного. Превращает в адское пламя даже заклинания огня, используемые новичками, поэтому прочтите руководство по технике безопасности.',
    flintlockpistol_name: 'Кремневый револьвер',
    flintlockpistol_desc: 'Часто дает осечку. Думаешь, тебе повезет?',
    pistol_name: 'Пистолет',
    pistol_desc: 'Твоя новая любимая игрушка.',
    flintlockrifle_name: 'Кремневое ружье',
    flintlockrifle_desc: 'Разнеси проблемы в клочья.',
    huntingrifle_name: 'Охотничье ружье',
    huntingrifle_desc: 'Разносит все в пределах пятисот ярдов.',
    wintergun_name: 'Снежкомет',
    wintergun_desc: 'Весело, пока кому-нибудь глаз не выбьют.',
    blunderbuss_name: 'Мушкетон',
    blunderbuss_desc: 'Тук-тук...',
    doublebarrel_name: 'Двустволка',
    doublebarrel_desc:
      'Приклад из орехового дерева, дуло из кобальтовой стали, легкий спусковой крючок. Отличная покупка.',
    fruitgun_name: 'Банановый пистолет',
    fruitgun_desc: 'Дзынь-дзынь-дзынь-дзынь-дзынь-дзынь-дзынь...',
    arcadegun_name: 'Пистонная хлопушка',
    arcadegun_desc:
      'Кажется, он может уничтожать привидения, но руководство по эксплуатации утеряно давным-давно.',
    kenora_name: 'Кенора вер. IV',
    kenora_desc: 'Так и хочется крикнуть: «Ух ты!»',
    gatling_name: 'Прототип пулемета Гатлинга',
    gatling_desc: 'Вы же рады, что у вас бесконечные боеприпасы?',
    dragonator_name: 'Драгонатор',
    dragonator_desc: 'Для любителей аромата серы по утрам.',
    barrelgun_name: 'Бочонок \'65',
    barrelgun_desc: 'Выдержан как хорошее вино.',
    hermes_name: 'Посланник',
    hermes_desc: 'Приносит только плохие вести.',
    lunargun_name: 'Удар быка',
    lunargun_desc: 'Это не первое родео этого ружья.',
    disintegratorgun_name: 'Омега-дезинтегратор',
    disintegratorgun_desc: 'Истребить. Истребить. Истребить.',
    goldgun_name: 'Люксовый пистолет',
    goldgun_desc: 'Содержит одну крайне смертоносную золотую пулю.',
    doggun_name: 'Бластер собачьей кости',
    doggun_desc:
      'Можно бросить, чтобы отвлечь собаку, волка или даже оборотня. Правда, всего один раз.',
    t12gun_name: 'Пушка Черной Бороды',
    t12gun_desc: 'Эта штука может затопить корабль. Помните об отдаче.',
    westerngun_name: 'Карабин мастака',
    westerngun_desc:
      'Спорим, что вы не попадете вон в ту бутылку в двух милях отсюда.',
    christmasgun_name: 'Подарочное ружье',
    christmasgun_desc: 'Что это за таинственный подарок?',
    t13gun_name: 'Тщеславие',
    t13gun_desc:
      'Это ружье настолько точное, что его владелец неизбежно проявит беспечность и совершит роковой промах.',
    musicgun_name: 'Звуковой трубач',
    musicgun_desc: 'Ту-ду.',
    lightcrossbow_name: 'Легкий арбалет',
    lightcrossbow_desc: 'Выбор лучника-путешественника.',
    handcrossbow_name: 'Ручной арбалет',
    handcrossbow_desc: 'Удобная переносная конструкция!',
    crossbow_name: 'Cамострел',
    crossbow_desc: 'Стреляет с умопомрачительной скоростью.',
    heavycrossbow_name: 'Тяжелый арбалет',
    heavycrossbow_desc: 'Поздоровайся с моим другом.',
    thanksgivingcrossbow_name: 'Кудахтун',
    thanksgivingcrossbow_desc:
      'Отличная скорострельность, но настоящий довод в пользу покупки — это звук.',
    huntercrossbow_name: 'Охотничий арбалет',
    huntercrossbow_desc: 'Один укол — и труп обеспечен.',
    scorpion_name: 'Скорпион',
    scorpion_desc: 'Один укол — и труп обеспечен.',
    troblincrossbow_name: 'Примитехническая рогатка',
    troblincrossbow_desc:
      'Величайшие изобретатели среди гиблинов вместе создали это технологическое чудо, объединив неточность рогатки и громоздкость арбалета.',
    doublecrossbow_name: 'Двойной арбалет',
    doublecrossbow_desc: 'Славится двойными стандартами.',
    chukonu_name: 'Чо-ко-ну',
    chukonu_desc:
      'Оснащен революционным автоматическим механизмом перезарядки, благодаря чему можно без проблем брать его в обе руки.',
    dragoncrossbow_name: 'Дра-Ко-Ну',
    dragoncrossbow_desc:
      'Может стрелять только запатентованными огненными драконьими стрелами, которые драконы, естественно, продают по завышенной цене.',
    triplecrossbow_name: 'Тройной арбалет',
    triplecrossbow_desc: 'Что натворила наука?!',
    handballista_name: 'Суперарбалет',
    handballista_desc: 'СКОЛЬКО стрел в минуту?!',
    batcrossbow_name: 'Самострел Ночного крыла',
    batcrossbow_desc: 'Может также стрелять мышебомбами и мышебумерангами.',
    wyrmthrower_name: 'Левиафан',
    wyrmthrower_desc: 'Кто знает, что таится в темных и бесконечных глубинах?',
    goldcrossbow_name: 'Люксовый арбалет',
    goldcrossbow_desc:
      'Может похвастаться особенно низкой скорострельностью, но с учетом стоимости боеприпасов... так даже лучше!',
    titancrossbow_name: 'Титановый арбалет',
    titancrossbow_desc:
      'На самом деле стреляет концентрированной энергией души, которую дает пользователь, так что тетива лишь для показухи.',
    t12crossbow_name: 'Cамострел «Богоубийца»',
    t12crossbow_desc: 'Запрещен в большей части небес и астральных планов.',
    airshipcrossbow_name: 'Запасной вариант Баросса',
    airshipcrossbow_desc:
      'Точное воссоздание любимого личного оружия дедушки Полонии. Хотя он любил огнестрельное оружие, в открытом море порох имеет свойство сыреть!',
    t13crossbow_name: 'Примароза',
    t13crossbow_desc: 'Стреляет точно, как ни назови.',
    dreamcrossbow_name: 'Снотворный стреломет',
    dreamcrossbow_desc:
      'В стрелы добавлено усыпляющее вещество, но это скорее прикол, если учесть, насколько они смертоносны.',
    grass_name: 'Зубровка',
    grass_desc: 'Хочешь верь, хочешь — нет, но кое-где эту траву запретили.',
    antidote_name: 'Мята',
    antidote_desc: 'Ошеломляющая свежесть. Справится с любым ядом.',
    powder_name: 'Лунный порошок',
    powder_desc: 'Мерцающая пыль. Входит в состав большинства зелий.',
    christmasflower_name: 'Магическая омела',
    christmasflower_desc:
      'Пусть дух новогодних праздников сопровождает вас повсюду в течение всего года.',
    healingsalve_name: 'Целебная мазь',
    healingsalve_desc: 'Прямо на глазах залечивает любые раны.',
    thanksgivingherb_name: 'Почтенный овес',
    thanksgivingherb_desc: 'Это вы еще не видели его брата — ленивого овса.',
    silverthistle_name: 'Чертополох',
    silverthistle_desc: 'Редкая эльфийская травка. Незаменима в алхимии.',
    harvest_name: 'Щедрый урожай',
    harvest_desc:
      'Сохраняйте здоровье благодаря этой... приличной порции овощей.',
    thanksgivingherb2_name: 'Комплект твердой кукурузы',
    thanksgivingherb2_desc:
      'Значительно жестче, чем обычная сладкая кукуруза, но лучшее лекарство редко бывает самым простым.',
    bouquet_name: 'Ароматный букет',
    bouquet_desc:
      'Различные цветы и свежесрезанные травы. Фирменный букет от «Лекарственной лавки Марибель».',
    mushroomherb_name: 'Маленький кактус',
    mushroomherb_desc:
      'Выращивать такое на ходу очень удобно! А вот брать в руки... уже сложнее.',
    bloodvine_name: 'Кровавая лоза',
    bloodvine_desc: 'Обращаться крайне осторожно!',
    mandragoraroot_name: 'Корень мандрагоры',
    mandragoraroot_desc: 'Ужасно сварливый и несговорчивый по утрам.',
    valentineherb_name: 'Неувядающая роза',
    valentineherb_desc:
      'Как бы вы ни старались, пока в мире существует любовь, этот цветок будет оставаться прекрасным.',
    wolfsbane_name: 'Волчья отрава',
    wolfsbane_desc: 'Скажите все на языке цветов.',
    ruinsherb_name: 'Туманоцвет',
    ruinsherb_desc:
      'Растет на вершинах заснеженных гор и цветет лишь раз в десять лет. Как он до сих пор не вымер, это загадка.',
    yggdrasilbranch_name: 'Ветвь Иггдрассиля',
    yggdrasilbranch_desc: 'Древо жизни связует всё вместе.',
    goldenclover_name: 'Лучший клевер на удачу',
    goldenclover_desc:
      'Очень редко встречается на полях, где растет клевер из чистого золота.',
    goldherb_name: 'Роскошное снадобье',
    goldherb_desc:
      'Меняет змеиное масло на золотые чешуйки с тем же эффектом, но втрое дороже!',
    wyrmblood_name: 'Мазь из крови змея',
    wyrmblood_desc: 'Крепитесь, возможно, будет слегка жечь...',
    couplebouquet_name: 'Дикая дубовая роза',
    couplebouquet_desc:
      'Вырезана из самого прочного дерева в честь самой сильной любви на свете.',
    eldritchtendrils_name: 'Демоническая флора',
    eldritchtendrils_desc: 'Не ставьте такое на ночной столик.',
    peakherbs_name: 'Корни Мрачного Шпиля',
    peakherbs_desc:
      'Даже в самом уединенном и суровом месте жизнь... находит себе дорогу.',
    circusherb_name: 'Сельтерский сюрприз',
    circusherb_desc:
      'Позор тебе, глупец! Попасться на такой классический фарс!',
    candyherb_name: 'Супернабор закусок',
    candyherb_desc:
      'Сделайте так, чтобы ваш разгрузочный день действительно имел значение.',
    t12herb_name: 'Философский камень',
    t12herb_desc:
      'Донован утверждает, что он не настоящий, но, возможно, просто завидует.',
    blossomherb_name: 'Настойка сакуры',
    blossomherb_desc:
      'Строго безалкогольная, чтобы сохранить наш возрастной рейтинг.',
    goldherb2_name: 'Люксовый отвар',
    goldherb2_desc:
      'Золотистая жидкость, содержащаяся в нем, способствует росту растений. Целебные свойства несравненны, но вырастить такое очень трудно!',
    fruitherb_name: 'Премиальное варенье',
    fruitherb_desc:
      'Часто переименовывается в зелье или травяную настойку в зависимости от того, что в данный момент более выгодно в связи с торговыми пошлинами.',
    t13herb_name: 'Корни пустоцвета',
    t13herb_desc:
      'Для роста требуется антиматерия, поэтому необходим контролируемый портал в Пустоту.',
    musicherb_name: 'Скользкое масло',
    musicherb_desc: 'Вы... вы ведь это не ели?',
    dreamherb_name: 'Мешочек Песочного человечка',
    dreamherb_desc:
      'Песочного человечка редко упоминают в таком качестве, но именно он изобрел атаку «Песок из кармана».',
    tea_name: 'Теплый чай',
    tea_desc: 'У тебя будто камень с плеч свалился.',
    smallhealthpotion_name: 'Целебное зелье',
    smallhealthpotion_desc: 'Вкус омерзительный. Но оно точно сработает.',
    smallmanapotion_name: 'Магическое зелье',
    smallmanapotion_desc: 'Сделано из загадок и мармеладок.',
    scienceexperiment_name: 'Научный проект',
    scienceexperiment_desc: 'Дегустация вслепую — это тоже часть науки.',
    healthpotion_name: 'Целебное зелье XL',
    healthpotion_desc: 'Проверено героями. Одобрено мамами.',
    victorianpotion_name: 'Изящный чайный сервиз',
    victorianpotion_desc:
      'Также снимает с плеч тяжкое бремя, причем делает это стильно!',
    magicpotion_name: 'Магическое зелье XL',
    magicpotion_desc: 'Сделано из загадок и мармеладок.',
    shoprpotion_name: 'Зелье титана',
    shoprpotion_desc:
      'Такое начали продавать в сувенирной лавке Башни титанов. Правда, это уже не то, что было раньше.',
    poloniapotion_name: 'Варево морского волка',
    poloniapotion_desc: 'Содержит рекомендуемую ежедневную норму витамина C.',
    largehealthpotion_name: 'Тоник феникса',
    largehealthpotion_desc: 'Оживляет, если тебя вырубили.',
    cincopotion_name: 'Пикантная гранита',
    cincopotion_desc:
      'Срок годности заканчивается приблизительно через 5 минут.',
    pumpkinpotion_name: 'Тыквенный эликсир',
    pumpkinpotion_desc: 'Лекарство из бахчевой культуры.',
    nayapotion_name: 'Особое зелье Найи',
    nayapotion_desc: 'Вот для тебя подарочек, дорогуша.',
    vialofrenewal_name: 'Зелье возобновления',
    vialofrenewal_desc:
      'Немедленно дарит ощущение целой ночи здорового сна. Также лечит от похмелья.',
    pyramidpotion_name: 'Веселье в бутылке',
    pyramidpotion_desc:
      'Дистиллированная радость и счастье в жидкой форме... Лучшее лекарство в понедельник!',
    epicpotion_name: 'Бутылка с шипучкой',
    epicpotion_desc:
      'Нам сказали, что она не содержит значительного количества токсичных отходов.',
    purplebomb_name: 'Фиолетовая бомба',
    purplebomb_desc:
      'Имеет очень сильное послевкусие... Погодите, а фитиль здесь зачем?',
    soulfireextract_name: 'Экстракт пламени души',
    soulfireextract_desc:
      'Доза чистой страсти. Поставит на ноги, что бы ни случилось.',
    lemonpotion_name: 'Терпкий отвар',
    lemonpotion_desc:
      'Сияющая искра лета, помещенная во флакон на черный день.',
    carpenterpotion_name: 'Дубовая эссенция',
    carpenterpotion_desc: 'Гордая и непоколебимая. Прямо как ее изобретатель.',
    cincopotion2_name: 'Особый сазонадо',
    cincopotion2_desc:
      'Бутылочка маленькая не просто так. Не переусердствуйте.',
    gaiatonic_name: 'Тоник Геи',
    gaiatonic_desc: 'Мать лучше знает!',
    batpotion_name: 'Полночное масло',
    batpotion_desc: 'Почему крылья все машут?!',
    goldpotion_name: 'Люксовый эликсир',
    goldpotion_desc:
      'Какой бы ни был у тебя недуг, он мигом исчезнет, оставив лишь дыру в кошельке.',
    starextract_name: 'Экстракт туманности',
    starextract_desc:
      'Внимание! Не храните более трех (3) бутылок вместе из-за риска сингулярности.',
    yetipotion_name: 'Весенняя вода Авроры',
    yetipotion_desc:
      'Один глоток охлаждает до самой души. Исцеляет ожоги, как ничто другое.',
    frogpotion_name: 'Кувшин с нектаром',
    frogpotion_desc: 'Вероятно, это не ловушка. Вероятно.',
    t12potion_name: 'Амброзия',
    t12potion_desc: 'Вызывает привыкание.',
    volcanopotion_name: 'Квинтэссенция чистоты',
    volcanopotion_desc:
      'Чистейшая вода, незапятнанная пребыванием на материальном плане. Обладает удивительными целебными свойствами, несмотря на простоту.',
    mundrapotion_name: 'Снадобье Лундры',
    mundrapotion_desc:
      'В оригинальном рецепте Лундры используются кристаллы, но алмазы — вполне подходящая замена.',
    beachpotion_name: 'Величайший солнцезащитный крем',
    beachpotion_desc:
      'Похвастайтесь отличным и совершенно безопасным загаром благодаря этой новой формуле, одобренной Солдрой!',
    maplepotion_name: 'Кленовый Святой Грааль',
    maplepotion_desc:
      'Реликвия, которой поклоняются канадийцы. Питье священного сиропа из него входит в обряд инициации их народа.',
    t13potion_name: 'Кровь завоевателя',
    t13potion_desc:
      'Сохраненная кровь известного предателя из Багряной стражи, которую он отложил для собственного воскрешения. Мы просто ее выпьем.',
    arcadepotion_name: 'Сумка первой помощи',
    arcadepotion_desc:
      'Зелья здоровья, маны и выносливости, подходящие для любого героя. Что из них какого цвета? Так-так...',
    scrolloffire_name: 'Свиток искр',
    scrolloffire_desc:
      'В засуху не использовать. Предотвратить лесной пожар можешь лишь ты.',
    forestscroll_name: 'Свиток очистки',
    forestscroll_desc: 'Все преимущества, даваемые мятой, но без послевкусия!',
    scrollofthunder_name: 'Свиток доспехов',
    scrollofthunder_desc:
      'Невесомые доспехи из эфира для тех, кто не может носить настоящую сталь.',
    scrollofprotection_name: 'Свиток бури',
    scrollofprotection_desc: 'Решение всех проблем. Но только на улице.',
    songofvalor_name: 'Песнь доблести',
    songofvalor_desc:
      'Отвязный ритм волшебным образом вдохновляет союзников на героические поступки.',
    correspondence_name: 'Обычная почта',
    correspondence_desc: 'Также называемая «улиточной».',
    scrollofice_name: 'Справочник чудовищ',
    scrollofice_desc: 'Все, что требуется для призыва чудовищ и демонов.',
    swampscroll_name: 'Фолиант ночи',
    swampscroll_desc:
      'Одна из любимых вещей у монстров, живущих у вас под кроватью.',
    tomeofknowledge_name: 'Фолиант знаний',
    tomeofknowledge_desc:
      'Магическая энциклопедия, в которой страниц больше, чем физически возможно. Одно только содержание занимает 300!',
    scrollofinvisibility_name: 'Фолиант секретов',
    scrollofinvisibility_desc:
      'Содержит самые потаенные секреты (и немного заклинаний!) некоего чародея.',
    christmascroll_name: 'Список послушных и непослушных',
    christmascroll_desc: 'Тебя включили в список послушных!',
    scrolloflevitation_name: 'Восемь триграмм',
    scrolloflevitation_desc: 'Мир, земля, их сыновья и дочери.',
    fireworksscroll_name: 'Комплект фейерверков',
    fireworksscroll_desc: 'Магии здесь нет, но ощущение такое, будто есть!',
    unicornscroll_name: 'Сказка о единороге',
    unicornscroll_desc:
      'Любимая сказка на ночь среди йети. Искусные заклинатели могут заметить, что в (довольно детский) текст искусно вплетены заклинания.',
    tarot_name: 'Колода карт Таро',
    tarot_desc: '10 мечей — это же хороший знак, да?',
    vipscroll_name: 'Свиток Фортуны',
    vipscroll_desc:
      'Удача сопутствует смелым... и обладателю этого позолоченного пергамента.',
    grimoireaeternum_name: 'Гримуар Этернум',
    grimoireaeternum_desc:
      'Если начать с конца, то придёте в начало, но не наоборот...',
    waxtablet_name: 'Имперский указ',
    waxtablet_desc: 'Veni. Vidi. Vici.',
    goldscroll_name: 'Роскошная скрижаль',
    goldscroll_desc:
      'Древние разрушительные заклинания вырезаны в камне с инкрустацией золотом. В трудную минуту можно использовать как щит.',
    celestialscroll_name: 'Атлас предков',
    celestialscroll_desc: 'Тщательно составлен еще на заре появления магии.',
    priestbook_name: 'Молитвенник',
    priestbook_desc: 'Богине есть что сказать.',
    batscroll_name: 'Проклятие ночи',
    batscroll_desc: 'Какая ужасная ночь для того, чтобы быть проклятым.',
    celticscroll_name: 'Друидский гримуар',
    celticscroll_desc:
      'Особое внимание уделяется заклинаниям превращения. Что это еще за вороны?',
    angelscroll_name: 'Небесный хор',
    angelscroll_desc: 'Надеюсь, вы сопрано!',
    peakscroll_name: 'Литания феникса',
    peakscroll_desc: 'Почему пахнет гренками?',
    freedomscroll_name: 'Декларация с печатью',
    freedomscroll_desc:
      'Копия высокого качества. Оригинал хранится в секретном хранилище, чтобы его никогда нельзя было украсть.',
    lunarscroll_name: 'Указ тигриного императора',
    lunarscroll_desc:
      'Настоящим император объявляет, что тигры милые и пушистые. На этом все.',
    allknowledge_name: 'Фолиант всезнания',
    allknowledge_desc:
      'Говорят, этот массивный фолиант содержит знания буквально обо всем, что когда-либо существовало. [Нужна цитата]',
    t12scroll_name: 'Зверь в переплете',
    t12scroll_desc:
      'В этой книге заключено мифологическое чудовище, которое, как говорят, впитало в себя знания всех, кого съело. Его голод не ослабевает даже спустя тысячелетия.',
    anubisscroll_name: 'Договор фараона',
    anubisscroll_desc:
      'Несомненно, он содержит давно утраченные тайные знания... но вам нужно еще и разбираться в лингвистике!',
    newyearscroll_name: 'Карта с потерянной звездой',
    newyearscroll_desc: 'Одна из этих звезд, похоже, пропала с неба...',
    valentinescroll_name: 'Признание в любви',
    valentinescroll_desc: 'Все-таки любовь — это самая сильная магия.',
    goldscroll2_name: 'Люксовое заклинание',
    goldscroll2_desc:
      'Катализатор из чистого золота позволяет добиться впечатляющих результатов.',
    t13scroll_name: 'Ящик Пандоры',
    t13scroll_desc: 'В случае срыва печати гарантирована утрата эффективности.',
    riftscroll_name: 'Запечатанный ураган',
    riftscroll_desc: 'Внешнее затишье выдает внутренний хаос.',
    cuirass_name: 'Нагрудник',
    cuirass_desc:
      'Защищает от дробящего оружия, пронзающих ударов и злых шуток.',
    ringmail_name: 'Железная кольчуга',
    ringmail_desc: 'Сплошные кольца. Потому что нравится.',
    chainmail_name: 'Кольчуга',
    chainmail_desc: 'Прочная броня, способная выдержать самые жестокие удары.',
    scalemail_name: 'Чешуйчатый доспех',
    scalemail_desc: 'Не снимать.',
    breastplate_name: 'Нагрудник рыцаря',
    breastplate_desc: 'Защищает самое главное.',
    swampheavyarmor_name: 'Церемониальный нагрудник',
    swampheavyarmor_desc: 'Вот такой я стильный...',
    winterarmor_name: 'Метельный доспех',
    winterarmor_desc: 'Всегда холодный на ощупь... но не для вас.',
    centurionarmor_name: 'Доспех центуриона',
    centurionarmor_desc:
      'Рельефный пресс — это зрелище, которое должны увидеть все. По крайней мере, так говорит император.',
    halfplate_name: 'Латы паладина',
    halfplate_desc: 'Полная защита. Прочная сталь.',
    pumpkinheavyarmor_name: 'Доспех Барона Шипов',
    pumpkinheavyarmor_desc: 'Здоровая доза шипов делает все круче.',
    beeheavyarmor_name: 'Латы стража улья',
    beeheavyarmor_desc:
      'Вы чувствуете внезапный порыв защищать королеву любой ценой.',
    ronindo_name: 'До самурая',
    ronindo_desc: 'Наряд странствующего самурая, который не спутаешь ни с чем.',
    pyramidheavyarmor_name: 'Доспех глубин',
    pyramidheavyarmor_desc: 'В тихом омуте черти водятся.',
    atlab_heavyarmor_name: 'Доспех Воина Киоши',
    atlab_heavyarmor_desc:
      'Характерная форма Воинов Киоши, которые поклялись защищать остров Киоши.',
    generalplate_name: 'Латы генерала',
    generalplate_desc:
      'Говорят, что не одежда красит человека, но доспех генерала — важное исключение.',
    oktoberfestplate_name: 'Латы ландскнехта',
    oktoberfestplate_desc: 'Отличительный доспех элитного наемника.',
    christmasheavyarmor_name: 'Латы стража Санты',
    christmasheavyarmor_desc:
      'Хо-хо-хо! Готовы защищать Северный полюс, детишки?',
    lunararmor_name: 'Латы военачальника',
    lunararmor_desc:
      'Доспех легендарного Летающего генерала, известного своими ратными подвигами и вспыльчивым характером.',
    berserkerarmor_name: 'Доспех берсерка',
    berserkerarmor_desc: 'Немного защищает при буйстве на поле боя.',
    castleheavyarmor_name: 'Воитель Геи',
    castleheavyarmor_desc:
      'Естественный порядок вещей должен сохраняться. Любой ценой.',
    freedomplate_name: 'Звездные латы',
    freedomplate_desc: 'Отступать нельзя, позади королевство!',
    juggernaut_name: 'Крепость Джаггернаута',
    juggernaut_desc: 'Да, мимо никто не проберется.',
    templearmor_name: 'Янтарная цитадель',
    templearmor_desc:
      'Легко заполнит ваше личное пространство обжигающим теплом.',
    goldplate_name: 'Люксовый нагрудник',
    goldplate_desc:
      'Если уж хвастаетесь таким богатством... пусть от него хотя бы польза будет!',
    celticheavyarmor_name: 'Милезская кольчуга',
    celticheavyarmor_desc:
      'На удивление легкая и позволяющая спокойно дышать. Свобода!',
    celestialplate_name: 'Небестальные латы',
    celestialplate_desc: 'Звенят особенно гармонично.',
    dogheavyarmor_name: 'Псовые латы',
    dogheavyarmor_desc: 'Можете погладить пузико.',
    atlab_fireplate_name: 'Доспех Народа Огня',
    atlab_fireplate_desc: 'Отличительная форма солдата Народа Огня.',
    t12heavyarmor_name: 'Латы Багряной стражи',
    t12heavyarmor_desc:
      'Багряная стража — это тайный орден, призванный защищать скрытые места гнездования фениксов.',
    fallheavyarmor_name: 'Доспех они-ронина',
    fallheavyarmor_desc:
      'Дизайн подразумевает, что его первоначальный владелец мог быть намного больше вас.',
    darkknightheavyarmor_name: 'Латы тирана',
    darkknightheavyarmor_desc:
      'Этот громоздкий, громыхающий доспех на 100% одержим духом. Готовы ли вы рискнуть?',
    cyclopsheavyarmor_name: 'Латы пропавшего воина',
    cyclopsheavyarmor_desc:
      'Лозам, прикрепленным к доспеху, уже много веков, но сам доспех кажется совершенно новым...',
    t13heavyarmor_name: 'Доспех гибельного огня',
    t13heavyarmor_desc:
      'Проклятый огонь прожигает этот доспех насквозь, поглощая любые остатки сострадания, которые могут быть у его владельца.',
    riftheavyarmor_name: 'Латы из магмового ядра',
    riftheavyarmor_desc:
      'Огненная страсть и пылающая ярость... Используйте их, направив их в нужное русло!',
    shoprheavyarmor_name: 'Латы титана',
    shoprheavyarmor_desc:
      'Возможно, они сделаны из всех доспехов, которые не были проданы в вашей лавке.',
    ironcap_name: 'Прочный шлем',
    ironcap_desc: 'Защищает в 60% случаев. Всегда.',
    helmet_name: 'Шлем воина',
    helmet_desc: 'Безопасность прежде всего!',
    horned_name: 'Рогатый шлем',
    horned_desc: 'Можно использовать в качестве оружия.',
    vikinghelm_name: 'Шлем викинга',
    vikinghelm_desc: 'Вселяет страх в сердца врагов.',
    knighthelm_name: 'Шлем рыцаря',
    knighthelm_desc: 'В нем почти ничего не видно, но зато какой вид!',
    swamphelm_name: 'Шлем глубин',
    swamphelm_desc:
      'Усовершенствованная нержавеющая модель, позаимствованная у русалок.',
    eaglecowl_name: 'Шлем орла',
    eaglecowl_desc: 'Скрежет по нему похож на крик орла!',
    couplehelmet_name: 'Шлем кадьяка',
    couplehelmet_desc:
      'Как и свирепого зверя, такую страстную любовь ничто не сдержит.',
    atlab_firehelm_name: 'Шлем Народа Огня',
    atlab_firehelm_desc:
      'Вы не увидите ни одного солдата Народа Огня без него!',
    darkknighthelm_name: 'Корона тирании',
    darkknighthelm_desc: 'Абсолютная власть развращает абсолютно.',
    paladinhelm_name: 'Шлем паладина',
    paladinhelm_desc: 'Оснащен системой обнаружения зла.',
    shoprhelm_name: 'Шлем титана',
    shoprhelm_desc: 'Драгоценные камни, позумент, крылья — здесь есть все!',
    kabuto_name: 'Кабуто самурая',
    kabuto_desc: 'После победы в войне следует потуже завязать шнур кабуто.',
    dinohelm_name: 'Зубастая пасть рекса',
    dinohelm_desc:
      'Давление силой более 35 тысяч ньютонов! Без реальных мышц... вероятно, ближе к 0.',
    gladiatorhelm_name: 'Шлем гладиатора',
    gladiatorhelm_desc:
      'Вам это развлечение не по душе? Вы здесь не для этого?',
    beachhelm_name: 'Шлем с песчаным замком',
    beachhelm_desc:
      'Песчаный замок учит нас тому, что все, что создает человек, со временем рассыпается в прах. А пока можете носить это изящное ведерко!',
    generalheaume_name: 'Топфхельм генерала',
    generalheaume_desc: 'Все будет тип-топф.',
    ruinshelm_name: 'Шлем крестоносца',
    ruinshelm_desc: 'Когда богиня призывает, праведные отвечают.',
    lunarhelmet_name: 'Шлем военачальника',
    lunarhelmet_desc:
      'Не имеет себе равных по прочности (и стилю!) в трех царствах.',
    berserkerhelm_name: 'Шлем берсерка',
    berserkerhelm_desc:
      'Все воины знают, что в разгаре боя нужно использовать голову. Только вот у берсерков с метафорами не очень хорошо.',
    centurionhelmet_name: 'Шлем центуриона',
    centurionhelmet_desc:
      'Его гениальная конструкция позволяет эффективно чистить низкие потолки, когда вы входите и выходите.',
    juggernauthelm_name: 'Печь Джаггернаута',
    juggernauthelm_desc: 'Защита? Потрясающая. Видимость? Не очень.',
    thanksgivinghelmet_name: 'Шлем кулдыкающего повелителя',
    thanksgivinghelmet_desc:
      'Утвердите свое превосходство над самыми разными жирными птицами.',
    goldhelmet_name: 'Люксовый шлем',
    goldhelmet_desc: 'Не защитит от импульсивных покупок.',
    celestialhelm_name: 'Небестальной шлем',
    celestialhelm_desc:
      'Честно говоря, гораздо более практичная вещь, чем нимб.',
    doghelm_name: 'Псовый шлем',
    doghelm_desc: 'Больше лает, чем кусает.',
    valentinehelm_name: 'Шлем Купидона',
    valentinehelm_desc:
      'Даже Купидон соблюдает технику безопасности на работе.',
    t12helm_name: 'Шлем Багряной стражи',
    t12helm_desc:
      'Считается, что благодаря преданности фениксам, которым она служит, Багряная стража может избавиться даже от самых ужасных ран.',
    chesshelm_name: 'Шлем ониксового короля',
    chesshelm_desc:
      'Плохая видимость сильно замедлит ваше движение, но вы будете выглядеть по-настоящему важной персоной.',
    pumpkinhelm_name: 'Шлем Барона Шипов',
    pumpkinhelm_desc:
      'Повелитель всех тыквенных полей. Берегитесь, похитители овощей!',
    t13helm_name: 'Взор гибельного огня',
    t13helm_desc:
      'Надеть этот шлем — это предрешить свою судьбу. Всех поглотит его тотальная ненависть.',
    blossomhelm_name: 'Кабуто тайсегуна',
    blossomhelm_desc:
      'Для верховного главнокомандующего армии требуется головной убор, соответствующий его званию.',
    platinumhelm_name: 'Платиновый шлем',
    platinumhelm_desc:
      'Прибавляет вам целый фут роста. И несколько десятков фунтов веса.',
    ironbracers_name: 'Железные наручи',
    ironbracers_desc: 'Простая броня для небогатого воина.',
    lightgauntlets_name: 'Латные рукавицы',
    lightgauntlets_desc: 'Кажется, будто ничего и не надето.',
    gauntlets_name: 'Рукавицы воина',
    gauntlets_desc: 'Безразмерные!',
    demigauntlets_name: 'Полурукавицы',
    demigauntlets_desc:
      'Не закрывают пальцы, позволяя оскорблять противника жестами.',
    pumpkingauntlets_name: 'Тыквенные рукавицы',
    pumpkingauntlets_desc: 'Похоже, поблизости от них исчезают конфеты...',
    knightgauntlets_name: 'Рукавицы рыцаря',
    knightgauntlets_desc: 'Чуть дороже рыцарских варежек.',
    christmasgauntlets_name: 'Рукавицы стража Санты',
    christmasgauntlets_desc:
      'Не стоит недооценивать. Ими можно хорошо ударить, пусть даже и колокольчики звенят.',
    cincogauntlets_name: 'Перчатки Синко',
    cincogauntlets_desc: '«Синко» — это пять, и да, здесь пять пальцев.',
    paladingauntlets_name: 'Рукавицы паладина',
    paladingauntlets_desc: 'Свет небес в вашей ладони.',
    indiadaygauntlets_name: 'Рукавицы Республики',
    indiadaygauntlets_desc: 'Хватайтесь за любую возможность.',
    fruitgauntlets_name: 'Фруктовые рукавицы',
    fruitgauntlets_desc:
      'В них есть неустрашимая энергия ароматического попурри.',
    desertgauntlets_name: 'Рукавицы солнечной хватки',
    desertgauntlets_desc: 'Также известны как дневные рукавицы.',
    oktoberfestgauntlets_name: 'Рукавицы ландскнехта',
    oktoberfestgauntlets_desc:
      'Легко хватают пику, алебарду, аркебузу или жалование за день.',
    kote_name: 'Котэ самурая',
    kote_desc: 'Обладает восемью добродетелями. Всю битву и до смерти.',
    generalgauntlets_name: 'Рукавицы генерала',
    generalgauntlets_desc: 'Упал и отжался двадцать раз.',
    berserkergauntlets_name: 'Рукавицы берсерка',
    berserkergauntlets_desc:
      'Придают пикантность вашим сомнительным комплиментам.',
    goldgauntlets_name: 'Роскошные рукавицы',
    goldgauntlets_desc: 'Половина ваших проблем уйдет по щелчку пальцами.',
    juggernautgauntlets_name: 'Хватка Джаггернаута',
    juggernautgauntlets_desc:
      'Держите друзей близко, а врагов еще ближе. Намного ближе.',
    wintergauntlets_name: 'Прикосновение зимы',
    wintergauntlets_desc:
      'Ваш напиток останется ледяным, нравится вам это или нет.',
    celestialgauntlets_name: 'Небестальные рукавицы',
    celestialgauntlets_desc: 'Спасение в наших руках!',
    naturegauntlets_name: 'Хватка Геи',
    naturegauntlets_desc: 'Мягкое прикосновение матери, заключенное в базальт.',
    fallgauntlets_name: 'Рукавицы авангарда',
    fallgauntlets_desc:
      'Наука говорила, что невозможно сделать рукавицы из кожи, железа и трав. Наука оказалась не права.',
    aegirgauntlets_name: 'Рукавицы хускарла',
    aegirgauntlets_desc: 'Имеют особую тягу к топорам и веслам.',
    t12gauntlets_name: 'Мощь Багряной стражи',
    t12gauntlets_desc:
      'Держа руку на мече и сердце, Багряная стража с каждым восходом солнца заново клянется в верности.',
    mushroomgauntlets_name: 'Мордовороты грибандита',
    mushroomgauntlets_desc:
      'Если ожидается кулачный бой, лучше к нему подготовиться!',
    goldgauntlets2_name: 'Люксовые дракулаки',
    goldgauntlets2_desc:
      'Посмотрите, как он улыбается. Малышу определенно нравится!',
    darkknightgauntlets_name: 'Кулаки тирана',
    darkknightgauntlets_desc:
      'Надев эти перчатки, вы будете править железным кулаком, и это не фигура речи.',
    t13gauntlets_name: 'Рукавицы гибельного огня',
    t13gauntlets_desc:
      'Одушевленные собственной волей, они отказываются держать что-либо, кроме инструментов войны.',
    arcadegauntlets_name: 'Силовая рукавица',
    arcadegauntlets_desc: 'Не такая уж плохая штука.',
    shinguards_name: 'Наголенники',
    shinguards_desc: 'Защищают от мосек и младенцев.',
    longboots_name: 'Высокие сапоги',
    longboots_desc: 'Стрела в колено вам ни к чему, не так ли?',
    grieveboots_name: 'Железные поножи',
    grieveboots_desc: 'Защищают ваши голени от карликов.',
    heroboots_name: 'Сапоги героя',
    heroboots_desc: 'Защищают хорошо, но помешают убежать от боя с боссом.',
    heavyboots_name: 'Усиленные поножи',
    heavyboots_desc: 'От звука шагов этих поножей у врагов трясутся поджилки.',
    circusboots_name: 'Латные пищалки',
    circusboots_desc: 'Пользы от них при нападении почти никакой, но...',
    knightboots_name: 'Солереты рыцаря',
    knightboots_desc: 'Прекрасно подходят, чтобы пинать камушки.',
    swampboots_name: 'Пепельные подошвы',
    swampboots_desc:
      'Выжигает ваши следы на любой поверхности! Станьте самым легким для выслеживания героем во всем королевстве!',
    lunargreaves_name: 'Сапоги военачальника',
    lunargreaves_desc:
      'Эти сапоги носил легендарный Летающий Генерал, о котором говорили, что он постоянно находится в поисках достойного испытания.',
    paladinboots_name: 'Сапоги паладина',
    paladinboots_desc: 'Небесное блаженство во время работы на благо небес.',
    dogboots_name: 'Псовые лапы',
    dogboots_desc:
      'Когти могут нуждаться в периодической стрижке, если не носятся регулярно.',
    firecrabboots_name: 'Пеплодавы',
    firecrabboots_desc:
      'Они пришли сюда давить пепел и жевать жвачку. И жвачка у них закончилась.',
    haidate_name: 'Хайдатэ самурая',
    haidate_desc:
      'Почтенный самурай никогда не сворачивает назад. Путь один — только вперед.',
    bfheavyboots_name: 'Топазовые поножи',
    bfheavyboots_desc:
      'В другой игре они, вероятно, повысили бы ваше сопротивление молнии.',
    generalgreaves_name: 'Поножи генерала',
    generalgreaves_desc:
      'Все будут оповещены о вашем приближении благодаря этим супертяжелым и мегашумным штукам.',
    berserkerboots_name: 'Топтуны берсерка',
    berserkerboots_desc:
      'Когда вы отдавливаете другим пальцы на ногах, раздается удовлетворительный хруст.',
    goldboots_name: 'Роскошные сапоги',
    goldboots_desc: 'Симпатичные сапожки весом около 25 фунтов. Каждый.',
    fallboots_name: 'Поножи авангарда',
    fallboots_desc: 'Всегда на шаг впереди.',
    juggernautboots_name: 'Поножи Джаггернаута',
    juggernautboots_desc: 'Могут сами по себе считаться дробящим оружием.',
    bunnyboots_name: 'Сапожки Крокро',
    bunnyboots_desc:
      'Кроличий стиль не особо подходил воину, но его дух по-прежнему защищает владельца.',
    cincoboots_name: 'Сапоги Синко',
    cincoboots_desc:
      '«Синко» — это пять, и да, здесь пять пальцев... Ой, мы так уже шутили, да?',
    celestialboots_name: 'Небестальные сапоги',
    celestialboots_desc: 'Ходите там, куда боятся ступать ангелы.',
    freedomboots_name: 'Звездные поножи',
    freedomboots_desc:
      'Снимая их после долгого дня приключений, чувствуешь себя свободным.',
    christmasboots_name: 'Сапоги стража Санты',
    christmasboots_desc:
      'Санта нанимает эльфов в свою элитную стражу, которая защищает его от всех, кто пытается испортить Рождество.',
    t12boots_name: 'Поножи Багряной стражи',
    t12boots_desc:
      'Багряная стража должна стоять в карауле несколько дней без отдыха, поэтому их сапоги, естественно, довольно удобны.',
    volcanoboots_name: 'Сапоги рвения',
    volcanoboots_desc:
      'Когда праведный паладин отправляется в паломничество, эти сапоги даруют ему абсолютную неутомимость.',
    fruitboots_name: 'Фруктовые сапожки',
    fruitboots_desc: 'Усиленный мощью дракона... фрукт! (Без пестицидов!)',
    goldboots2_name: 'Люксовые поножи',
    goldboots2_desc:
      'ВНИМАНИЕ, КРИТИЧЕСКИЙ НЕДОСТАТОК ДИЗАЙНА! Перья вызывают сильный зуд!',
    dinoboots_name: 'Лапищи рекса',
    dinoboots_desc: 'Как в программе «Паркур юрского периода»!',
    t13boots_name: 'Поножи гибельного огня',
    t13boots_desc: 'Ступайте по бесплодной пустоши, которую сами же создали.',
    shoprboots_name: 'Сапоги титана',
    shoprboots_desc:
      'Отлично подходят для приключений, но не для того, чтобы стоять за прилавком успешной лавки.',
    leatherarmor_name: 'Кожаный доспех',
    leatherarmor_desc:
      'Легкие доспехи для тех, кто предпочитает в бою сохранять подвижность.',
    padded_name: 'Гамбезон',
    padded_desc: 'Подбитая войлоком куртка — выбор дуэлянтов и лучников.',
    studded_name: 'Шипованный доспех',
    studded_desc: 'Простые кожаные доспехи, усиленные толстыми заклепками.',
    doublet_name: 'Дублет',
    doublet_desc:
      'Модный дублет, который будто кричит «посмотрите на меня, я — важная персона!»',
    hide_name: 'Доспех из шкуры',
    hide_desc: 'Берегись красной краски.',
    mundraarmor_name: 'Накидка Лундры',
    mundraarmor_desc:
      'Более легкая версия доспеха Лундры, предназначенная для людей... с более хрупким телосложением.',
    airshiparmor_name: 'Наряд небесного пирата',
    airshiparmor_desc:
      'Сегодня вы простой пират, но нет ни одного небесного пирата, который бы не мечтал о великом...',
    savagearmor_name: 'Наряд дикаря',
    savagearmor_desc: 'Теперь ты — высший хищник.',
    desertmediumarmor_name: 'Доспех стража поляны',
    desertmediumarmor_desc:
      'Священный доспех, который носят эльфийские охотники, охраняющие самые секретные уголки.',
    pumpkinarmor_name: 'Тыквенный доспех',
    pumpkinarmor_desc: 'Нельзя быть слишком старым для Хэллоуина.',
    ninjagarb_name: 'Костюм ниндзя',
    ninjagarb_desc:
      'Всегда помни правило ниндзя № 1: чем больше ниндзя, тем слабее они становятся!',
    batarmor_name: 'Накидка Ночного крыла',
    batarmor_desc: 'Вы ничего не слышали?',
    windrunnerarmor_name: 'Доспех бегущего с ветром',
    windrunnerarmor_desc:
      'Станьте единым целым с ветром, посвятите свою душу погоне.',
    atlab_armor_name: 'Наряд Сокки',
    atlab_armor_desc:
      'Традиционный наряд Племени Воды, измененный для более теплой погоды.',
    dragonarmor_name: 'Доспех из драконьей кожи',
    dragonarmor_desc:
      'Предлагает великолепную защиту за счет чересчур завышенной самооценки.',
    smithapron_name: 'Наряд кузнеца',
    smithapron_desc: 'Иногда товар нуждается в полевых испытаниях.',
    captainarmor_name: 'Накидка корсара',
    captainarmor_desc:
      'Эффектно развевается, когда достойный владелец управляет могучим судном.',
    siaarmor_name: 'Лебединое одеяние',
    siaarmor_desc:
      'Бесспорно ослепительное, завораживающее и сражающее наповал.',
    lunarmedarmor_name: 'Доспех облачного странника',
    lunarmedarmor_desc:
      'Позволит плавно спланировать на землю независимо от высоты.',
    jackalopearmor_name: 'Наряд лорда Вечных Сумерек',
    jackalopearmor_desc:
      'Секта Вечных Сумерек имеет особую тягу к демоническим существам, особенно бесам.',
    goldroguearmor_name: 'Люксовый доспех',
    goldroguearmor_desc:
      'Неоправданно изысканный. Как раз так, как вам нравится.',
    troblinarmor_name: 'Доспех Толстокожа',
    troblinarmor_desc:
      'Когда-то он принадлежал самому умному вождю гиблинов, Толстокожу. Тот был настолько умен, что догадался носить доспех, чтобы прожить дольше. Правда, его все время жуют лошади и другие животные.',
    catarmor_name: 'Наряд кота-взломщика',
    catarmor_desc: 'Кошачью мяту не уберечь.',
    t12mediumarmor_name: 'Форма Рассветного вора',
    t12mediumarmor_desc:
      'Рассветный вор был настолько эффективен, что, по мнению многих, это, возможно, была целая организация.',
    volcanomedarmor_name: 'Покров пропавших',
    volcanomedarmor_desc:
      'Теперь в его руках солнце, и что еще остается делать Рассветному вору?..',
    cincomedarmor_name: 'Стильное пончо',
    cincomedarmor_desc:
      'Называть это пончо — довольно смело, но это дань моде.',
    westernarmor_name: 'Наряд отчаянного',
    westernarmor_desc: 'Где-то в мире сейчас полдень.',
    t13mediumarmor_name: 'Кожаный доспех дикой охоты',
    t13mediumarmor_desc:
      'В качестве добычи для дикой охоты выбираются только самые опасные звери.',
    dreammediumarmor_name: 'Одеяние Морфея',
    dreammediumarmor_desc:
      'Этот наряд настолько же удобный, насколько и опасный. Можно заснуть прямо в нем!',
    boarmediumarmor_name: 'Наряд проводника',
    boarmediumarmor_desc:
      'Ремни и пряжки пользуются большим успехом у главных героев.',
    tunic_name: 'Рубаха',
    tunic_desc: 'Лучше, чем ничего...',
    robe_name: 'Черная мантия',
    robe_desc: 'Для привыкшего к изяществу искателя приключений.',
    druidic_name: 'Мантия друида',
    druidic_desc: 'Украшена древними орнаментами друидов.',
    disciplerobe_name: 'Мантия ученика',
    disciplerobe_desc: 'Простые одеяния, созданные для служения богам.',
    exploreroutfit_name: 'Наряд исследователя',
    exploreroutfit_desc:
      'Имеет множество удобных карманов и отделений для бывалого путешественника.',
    tailorarmor_name: 'Накидка швеи',
    tailorarmor_desc: 'Шедевр, о котором все время говорит Джулия.',
    witchrobe_name: 'Наряд колдуна',
    witchrobe_desc: 'Из одного из карманов слышно кваканье.',
    wizardrobe_name: 'Наряд чародея',
    wizardrobe_desc: 'Как, говорите, он к вам попал?',
    desertlightarmor_name: 'Одеяние весталки',
    desertlightarmor_desc:
      'Хранители священного огня надевают эту магическую одежду для священных ритуалов.',
    scholartunic_name: 'Туника ученого',
    scholartunic_desc:
      'Простой способ выглядеть умнее, чем ты есть на самом деле.',
    midnightapparel_name: 'Полночное одеяние',
    midnightapparel_desc:
      'Осуществи свою мечту и стань самым красивым магом на балу.',
    atlab_robe_name: 'Мантия Аанга',
    atlab_robe_desc:
      'Простая мантия, которую носят монахи из Воздушных кочевников. Джулии потребовалось немного подправить ее, чтобы она подходила взрослым.',
    shamanvestment_name: 'Облачение шамана',
    shamanvestment_desc:
      'Каждый шаман носит уникальную одежду, сделанную из частей представителей местной фауны.',
    valentinerobe_name: 'Мантия Купидона',
    valentinerobe_desc: 'В город прибыл сводник.',
    goldvestments_name: 'Роскошный наряд',
    goldvestments_desc: 'Трудно быть богом.',
    astravestimenta_name: 'Астравестимента',
    astravestimenta_desc: 'Объятия космоса и тяжесть всего мира на плечах.',
    easterarmor2020_name: 'Доспех Остары',
    easterarmor2020_desc:
      'С надлежащими доспехами даже не нужно думать о том, как менять ход боя.',
    arabiantunic_name: 'Пустынное одеяние',
    arabiantunic_desc: 'Стильно защищает от палящего зноя и лютого холода.',
    majesticraiments_name: 'Одеяние архимага',
    majesticraiments_desc:
      'Отражает летящие снаряды, отклоняет атаки и даже содержит себя в блестящей чистоте!',
    indiadayarmor_name: 'Одеяние Республики',
    indiadayarmor_desc: 'Цветастое и мощное!',
    djinnrobes_name: 'Одеяние джинна',
    djinnrobes_desc:
      'Дает мощную защиту. Только больше никогда не говорите вслух «Желаю».',
    moonrobes_name: 'Лунное кимоно',
    moonrobes_desc: 'Подходящий наряд для приветствия луны щедрого урожая.',
    lunarrobe_name: 'Мантия тигриного императора',
    lunarrobe_desc: 'Наконец-то император в одежде!',
    t12robe_name: 'Одеяние экзарха',
    t12robe_desc:
      'Носится только высшими представителями духовенства и соответствует высшим стандартам как для божественных, так и для простых людей.',
    goldrobe2_name: 'Люксовое облачение',
    goldrobe2_desc: 'Сегодня вечером вы будете самым красивым героем на балу.',
    volcanorobe_name: 'Первобытный наряд',
    volcanorobe_desc:
      'Настолько стар, что, несомненно, застал динозавра, а то и двух.',
    hemmadonrobe_name: 'Одеяние стража душ',
    hemmadonrobe_desc:
      'Носить это как-то неудобно, но не в физическом смысле. Некроманты вроде бы не возражают.',
    unicornrobe_name: 'Шёлковый наряд Ледяной королевы',
    unicornrobe_desc:
      'Холодно летом и тепло зимой. Карманы выполняют роль мини-холодильников.',
    t13robe_name: 'Облачение собора Пустоты',
    t13robe_desc:
      'Безграничная сила космоса — это не то, к чему должен иметь доступ любой чародей.',
    leathercap_name: 'Кожаная шапка',
    leathercap_desc: 'Простая шапка из обрезков кожи.',
    forestroguehat_name: 'Небесный купол',
    forestroguehat_desc:
      'Лучше свежего воздуха быть не может. Ваш скальп скажет вам спасибо!',
    hat_name: 'Широкополая шляпа',
    hat_desc: 'Просто шляпа. Ничего особенного. Проходи, не задерживайся.',
    featheredhat_name: 'Шапка с пером',
    featheredhat_desc: 'Шедевр вычурности.',
    tricorn_name: 'Треуголка',
    tricorn_desc: 'Снова в моде!',
    hood_name: 'Шелковый капюшон',
    hood_desc: 'Вы в режиме инкогнито! Можете наслаждаться частной жизнью.',
    christmasroguehat_name: 'Эльфийская шапка Санты',
    christmasroguehat_desc:
      'Та сторона эльфийской моды, которую Исмаил не одобряет.',
    anubisroguehat_name: 'Немес фараона',
    anubisroguehat_desc:
      'Однако вы все равно не играете такую важную роль, как боги.',
    piratehat_name: 'Пиратская шляпа',
    piratehat_desc:
      'Несанкционированное воспроизведение или распространение работ, защищенных авторским правом, является незаконным.',
    valentinehat_name: 'Романтическая шапка',
    valentinehat_desc: 'Идеально подходит для ночного свидания (или рейда!)',
    nightcowl_name: 'Ночной капюшон',
    nightcowl_desc: 'Ночной капюшон для ночной птицы.',
    pyramidroguehat_name: 'Платок кочевника',
    pyramidroguehat_desc: 'Посмейтесь над любым суровым климатом.',
    farmerhood_name: 'Капюшон собирателя',
    farmerhood_desc:
      'Надевайте его, когда наступает сезон сбора урожая! Будь то овощей... или душ.',
    windrunnerhat_name: 'Шляпа бегущего с ветром',
    windrunnerhat_desc:
      'Превосходные аэродинамические свойства повышают скорость примерно на 1%.',
    atlab_spiritmask_name: 'Синяя маска',
    atlab_spiritmask_desc:
      'Маска, которую носил таинственный и неожиданный союзник, освободивший Аанга из крепости Похай.',
    musketeerroguehat_name: 'Шляпа мушкетера',
    musketeerroguehat_desc:
      'Лихому герою плаща и шпаги больше ничего искать не нужно, ведь эта шляпа не имеет себе равных!',
    dragonhat_name: 'Драконья маска',
    dragonhat_desc:
      'Взгляните на мир глазами мудреца. Опасайтесь зеркала, что отражает жажду большего.',
    castleroguehat_name: 'Маска дракона бури',
    castleroguehat_desc:
      'Уже один вид чешуи дракона бури служит ужасным знаком для прибрежных городов и рыбацких деревень.',
    oktoberhat_name: 'Великолепный тиролец',
    oktoberhat_desc:
      'Усиливает боевой клич, словно вы находитесь среди гор. Не рекомендуется для выполнения тайных заданий.',
    captainhat_name: 'Треуголка корсара',
    captainhat_desc: 'По этой шляпе сразу видно, кто здесь капитан.',
    indiadayhat_name: 'Капюшон Республики',
    indiadayhat_desc: 'Знания в изобилии, если они поступают отовсюду.',
    jackalopehat_name: 'Капюшон Вечных Сумерек',
    jackalopehat_desc:
      'В темных сектах ранг иногда обозначается не рогами, а капюшоном.',
    peakroguehat_name: 'Капюшон бездны',
    peakroguehat_desc:
      'Дается в награду высшему представителю секты Вечных Сумерек. Позволяет управлять демонами, если вы осмелитесь их призвать...',
    cincoroguehat_name: 'Сомбреро странника',
    cincoroguehat_desc:
      'Скрытность не является его сильной стороной, но вы можете быть душой любой вечеринки!',
    goldroguehat_name: 'Люксовый салад',
    goldroguehat_desc:
      'Дизайн основан на старых чертежах, найденных в древних руинах.',
    catroguehat_name: 'Капюшон кота-взломщика',
    catroguehat_desc:
      'Элегантный приглушенный дизайн. Встроенная система ночного видения. Если бы еще и колокольчик не звенел...',
    t12roguehat_name: 'Капюшон Рассветного вора',
    t12roguehat_desc:
      'Знаменитая маска Рассветного вора. Редко можно увидеть ее без этой фирменной самодовольной улыбки.',
    easterroguehat_name: 'Маскарад Крокро',
    easterroguehat_desc:
      'Можно решительно снять в нужный момент, чтобы прервать свадьбу или раскрыть кровное родство с главным героем.',
    airshiproguehat_name: 'Очки искателя драконов',
    airshiproguehat_desc:
      'Эй, капитан! Чую дракона в пяти милях по правому борту!',
    fallroguehat_name: 'Маска духа кицунэ',
    fallroguehat_desc: 'Под ней, несомненно, скрывается самый хитрый плут.',
    t13roguehat_name: 'Череп дикой охоты',
    t13roguehat_desc:
      'В черепе живет дух крупного хищника, который побуждает владельца охотиться вечно.',
    inuitroguehat_name: 'Снежные очки с капюшоном',
    inuitroguehat_desc:
      'Солнечный свет, отражаемый от снега, бьет в глаза! Поэтому и были изобретены эти стильные, но необычные очки от солнца.',
    boarroguehat_name: 'Капюшон кабана-оборотня',
    boarroguehat_desc: 'Трепещите, трюфели.',
    leathercone_name: 'Сшитый колпак',
    leathercone_desc: 'Для перспективного мага... или школьного дурачка.',
    blackcowl_name: 'Черный капюшон',
    blackcowl_desc: 'Ты никого не обманешь, некромант.',
    laurels_name: 'Лавры друида',
    laurels_desc: 'Только не почивай на них — они довольно хрупкие.',
    startertiara_name: 'Тиара ученицы',
    startertiara_desc:
      'Стандартный аксессуар Академии. Каждая ученица должна создать такую сама!',
    circlet_name: 'Бронзовый обруч',
    circlet_desc: 'Усиливает концентрацию, хотя в целом довольно неудобный.',
    wizardcone_name: 'Шляпа чародея',
    wizardcone_desc: 'Высокая и поношенная. Чародеи как раз такое любят.',
    swamphat_name: 'Шляпа четырех ветров',
    swamphat_desc: 'Куда бы ни дул ветер, этой шляпе все равно.',
    bunnyhat_name: 'Шляпа Остары',
    bunnyhat_desc: 'Ушки иногда подергиваются.',
    liluhat_name: 'Совиный капюшон',
    liluhat_desc: 'Мудрость Поллукса и его очарование.',
    witchhat_name: 'Шляпа колдуна',
    witchhat_desc: 'С широкими полями. Чтобы скрывать злобную ухмылку.',
    frogmagehat_name: 'Кувшинковая шляпа',
    frogmagehat_desc:
      'Как вода стекает со шляпы, так и шляпа легко соскальзывает с головы.',
    midnighthat_name: 'Шляпа тактика',
    midnighthat_desc: 'Поставьте в романтических отношениях шах и мат.',
    summonerhat_name: 'Шляпа призывателя',
    summonerhat_desc:
      'Шляпа призывателя лучше всего делает одну вещь — показывает всем, кто здесь главный.',
    chessmagehat_name: 'Корона королевы слоновой кости',
    chessmagehat_desc:
      'Дарует большую силу и скорость, но старайтесь не перенапрягаться.',
    shamanhat_name: 'Шапка шамана',
    shamanhat_desc: 'Духи говорят. Вы ответите?',
    goldhat_name: 'Роскошный головной убор',
    goldhat_desc: 'С благословенным хвостовым пером самого Кетцалькоатля.',
    astralhat_name: 'Астральная шапка',
    astralhat_desc:
      'Наделяет носителя мистическим знанием, полученным от самых звёзд.',
    plaguemask_name: 'Воронья маска',
    plaguemask_desc: 'Здесь хорошо пахнет!',
    schoolhat2020_name: 'Шляпа магической защиты',
    schoolhat2020_desc:
      'В эту шляпу было добавлено много тайных заклинаний для защиты от зла и плохих оценок.',
    tiara_name: 'Опаловая диадема',
    tiara_desc: 'Камень мерцает в присутствии могучей магии.',
    bathat_name: 'Ночная корона',
    bathat_desc: 'Лучше не пытаться с этим летать.',
    djinnhat_name: 'Вуаль джинна',
    djinnhat_desc: 'Драгоценный камень в середине иногда мерцает от злобы.',
    victorianmagehat_name: 'Изящный цилиндр',
    victorianmagehat_desc: 'Нет, кролика вы из него не вытащите.',
    snowmagehat_name: 'Летняя шляпа Ледяной королевы',
    snowmagehat_desc:
      'Голова будет мерзнуть даже при жарком солнечном свете. Крайне неудобно для всех, кроме Ледяной королевы.',
    t12magehat_name: 'Митра экзарха',
    t12magehat_desc:
      'Для того, кто озвучивает волю богов, требуется причудливый головной убор.',
    volcanomagehat_name: 'Гребень феникса',
    volcanomagehat_desc:
      'Только аватар феникса, носящий гребень феникса, может произнести литанию феникса и поддержать цикл вечной жизни.',
    blossommagehat_name: 'Эбоси сакуры',
    blossommagehat_desc: 'Чем выше шапка, тем вы могущественнее.',
    goldhat2_name: 'Люксовый головной убор',
    goldhat2_desc:
      'Прибережен для высших священников на случай самых важных церемоний.',
    lunarmagehat_name: 'Заколки императрицы',
    lunarmagehat_desc:
      'В этих эффектных заколках заключена большая сила. Парик предоставляется бесплатно, но обсуждению не подлежит.',
    t13magehat_name: 'Шляпа собора Пустоты',
    t13magehat_desc:
      'Слишком долго смотреть в глаза — это навлечь на себя безумие. К счастью, кристаллы громко звенят и сильно отвлекают внимание.',
    riftmagehat_name: 'Нимб Элизиума',
    riftmagehat_desc: 'Имеет прямую линию связи с небесами.',
    beachmagehat_name: 'Приливная тиара',
    beachmagehat_desc:
      'Продемонстрируйте свой статус элитного землевладельца! Подводная недвижимость наверняка взлетит в ближайшем будущем!',
    leathergloves_name: 'Кожаные перчатки',
    leathergloves_desc: 'Не стоит пачкать руки.',
    bracers_name: 'Наручи',
    bracers_desc: 'Защита для рук, обычно используемая лучниками.',
    thiefgloves_name: 'Перчатки вора',
    thiefgloves_desc: 'Скидка на пять пальцев — без проблем.',
    grottogloves_name: 'Рукавицы проворства',
    grottogloves_desc:
      'Порождают злобное статическое электричество, если потереть руки как злодей.',
    patchworkgloves_name: 'Наручи из лоскутной кожи',
    patchworkgloves_desc: 'Тепло и безопасность.',
    studdedleathergloves_name: 'Шипованные перчатки',
    studdedleathergloves_desc: 'Врежет так, что мало не покажется.',
    lunargloves_name: 'Королевские браслеты',
    lunargloves_desc:
      'Закрепить на руках можно только с помощью магии, поэтому героям без Искры придется обращаться за помощью к маме.',
    vambraces_name: 'Эльфийские наручи',
    vambraces_desc: 'Незаменимы для любого уважающего себя дуэлянта.',
    eastergloves2020_name: 'Перчатки Остары',
    eastergloves2020_desc:
      'Мягкое прикосновение... Идеально подходят для охоты на яйца!',
    savagegloves_name: 'Когти дикаря',
    savagegloves_desc: 'Пробей себе дорогу на вершину пищевой цепочки.',
    desertgloves_name: 'Когти черного змея',
    desertgloves_desc: 'В большинстве королевств считаются оружием!',
    ninjagloves_name: 'Перчатки ниндзя',
    ninjagloves_desc:
      'Слегка подбиты войлоком, чтобы можно было без опаски обращаться с отравленным сюрикэном.',
    catgloves_name: 'Когти кота-взломщика',
    catgloves_desc:
      'Позволяют хорошо лазить по деревьям и добавляют силы удару.',
    windrunnergloves_name: 'Перчатки бегущего с ветром',
    windrunnergloves_desc:
      'Подходят для скалолазания, плавания, верховой езды и раскачивания на деревьях.',
    dragongloves_name: 'Перчатки из драконьей кожи',
    dragongloves_desc:
      'От природы искусно обращаются с золотыми монетами и прочей ценной валютой.',
    oktobergloves_name: 'Перчатки горца',
    oktobergloves_desc:
      'Специально заколдованы, чтобы вы не выпускали кружку даже посреди боя!',
    captaingloves_name: 'Перчатки корсара',
    captaingloves_desc:
      'Остаются теплыми и сухими даже после многих недель в море.',
    vipgloves_name: 'Удачные перчатки',
    vipgloves_desc: 'Идеально подходят для того, чтобы зловеще крутить монету!',
    goldgloves_name: 'Люксовая хватка',
    goldgloves_desc:
      'Эта вещица не особо вяжется со скрытностью. Но выглядит хорошо.',
    jackalopegloves_name: 'Перчатки Вечных Сумерек',
    jackalopegloves_desc: 'Запах серы никак не выветривается.',
    birdgloves_name: 'Крылья хищной птицы',
    birdgloves_desc: 'Для боя или полета? И того и другого.',
    halloweengloves_name: 'Наручи Ночного крыла',
    halloweengloves_desc:
      'Не найти более удобных перчаток для перелистывания многочисленных страниц почты от поклонников: а вампиры получают много, с тех пор как вышла кое-какая книга.',
    t12gloves_name: 'Прикосновение Рассветного вора',
    t12gloves_desc:
      'Это было на нем во время его лучшего ограбления. Рассветный вор сорвал второе солнце с неба так же легко, как цветок.',
    volcanogloves_name: 'Пальцы мошенника',
    volcanogloves_desc:
      'Настоящий ловкач может прикарманить ветер так же легко, как и золото.',
    cincogloves_name: 'Перчатки ритма',
    cincogloves_desc:
      'Щелкать пальцами крайне важно, поэтому эти перчатки беспалые.',
    musketeergloves_name: 'Перчатки д\'Артаньяна',
    musketeergloves_desc:
      'Если ударить кого-нибудь одной из них, это вызовет много агрессии!',
    t13gloves_name: 'Перчатки дикой охоты',
    t13gloves_desc: 'Скрытность. Когти. Клыки. Острые ощущения.',
    blossomgloves_name: 'Манжеты сакуры',
    blossomgloves_desc: 'Не стоит класть в стиральную машину или сушилку.',
    campinggloves_name: 'Компас туриста',
    campinggloves_desc: 'Теперь, если заблудитесь, оправдываться нечем.',
    leatherboots_name: 'Кожаные сапоги',
    leatherboots_desc: 'Защищают от осколков стекла и торчащих гвоздей.',
    forestshoes_name: 'Первопроходцы',
    forestshoes_desc:
      'Есть те, кто идут проторенной тропой, а есть те, кто ее создают.',
    sandals_name: 'Сандалии',
    sandals_desc: 'Носки продаются отдельно.',
    softshoes_name: 'Мягкие ботинки',
    softshoes_desc: 'Будто по облакам идешь!',
    romansandals_name: 'Сандалии легионера',
    romansandals_desc: 'Все дороги ведут в Рим. Остается только туда дойти.',
    thiefshoes_name: 'Ботинки вора',
    thiefshoes_desc:
      'Настолько бесшумны, что можно с вечеринки уйти незамеченным.',
    flowersandals_name: 'Многолетние сандалии',
    flowersandals_desc: 'Будете скакать, словно пришла весна.',
    elvenshoes_name: 'Эльфийские ботинки',
    elvenshoes_desc: 'По слухам, в них можно ходить в паре дюймов над землей.',
    moonshoes_name: 'Лунные дзори',
    moonshoes_desc: 'Доберитесь до луны.',
    savageshoes_name: 'Поступь дикаря',
    savageshoes_desc: 'Бег со скоростью ветра.',
    blossomshoes_name: 'Таби сакуры',
    blossomshoes_desc:
      'Чтобы не потревожить дары вишневого дерева, требуется аккуратно стоять.',
    arabianshoes_name: 'Обувь султана',
    arabianshoes_desc:
      'Эти прекрасные зачарованные туфли могут выдержать обжигающий жар раскаленных солнцем песков.',
    inuitshoes_name: 'Компактные снегоступы',
    inuitshoes_desc:
      'Их крошечный размер делает вероятным то, что вы просто утонете в снегу, но в городе все равно не бывает много снега.',
    ninjatabi_name: 'Таби ниндзя',
    ninjatabi_desc:
      'Легендарная скрытность ниндзя начинается с крадущейся поступи.',
    batshoes_name: 'Ботинки Ночного крыла',
    batshoes_desc: 'Отправляйтесь в сумерки и окунитесь в мир ощущений!',
    windrunnerboots_name: 'Сапоги бегущего с ветром',
    windrunnerboots_desc:
      'Особенные сапоги, предназначенные для дальних путешествий. Не изнашиваются в дороге.',
    ruinsshoes_name: 'Альпийская поступь',
    ruinsshoes_desc: 'Шаги такие широкие, что вы практически летите.',
    dragonshoes_name: 'Сапоги из драконьей кожи',
    dragonshoes_desc:
      'На самом деле изготовлены из убедительных синтетических материалов в целях борьбы с браконьерами, охотящимися на драконов.',
    castleshoe_name: 'Чумные башмаки',
    castleshoe_desc:
      'Естественный порядок вещей должен нарушаться. Любой ценой.',
    cincoshoes2020_name: 'Стильные ботинки',
    cincoshoes2020_desc:
      'Будьте осторожнее, когда надеваете их в бою, а то они утанцуют прочь.',
    captainshoes_name: 'Сапоги корсара',
    captainshoes_desc: 'Не дадут неудачно поскользнуться и замочиться.',
    lunarboots_name: 'Обувь облачного странника',
    lunarboots_desc:
      'Перистые облака, кучевые, слоистые... Это все в вашей компетенции.',
    jackalopeshoes_name: 'Сапоги Вечных Сумерек',
    jackalopeshoes_desc:
      'Секта Вечных Сумерек стремится погрузить мир во тьму, в которой единственным светом будут огни, находящиеся у них под контролем.',
    birdshoes_name: 'Когти хищной птицы',
    birdshoes_desc:
      'Могут показаться неуместными, если носить их без крыльев хищной птицы.',
    goldshoes_name: 'Люксовые портянки',
    goldshoes_desc:
      'Отличаются запатентованной технологией защиты пальцев Теночтитлана.',
    t12shoes_name: 'Путь Рассветного вора',
    t12shoes_desc:
      'Обувь, которую носил Рассветный вор из легенд. Говорят, они позволили ему подняться на небо, и даже боги не смогли вмешаться.',
    westernshoes_name: 'Шпоры отчаянного',
    westernshoes_desc: 'Эти шпоры приятно звенят.',
    thanksgivingshoes_name: 'Мокасины охотника',
    thanksgivingshoes_desc:
      'Простые произведения искусства позволяют по-настоящему раскрыть талант их создателя.',
    junimayshoes_name: 'Сандалии феи',
    junimayshoes_desc:
      'Подошвы почти совсем не износились. А вы бы ходили, если бы могли летать?',
    t13shoes_name: 'Сандалии дикой охоты',
    t13shoes_desc:
      'Позволяют абсолютно бесшумно ходить независимо от местности. Пусть добыча и не надеется убежать.',
    platinumshoes_name: 'Платиновые башмаки',
    platinumshoes_desc: 'Когда захотят, гномы обзаводятся потрясными шузами.',
    woodshield_name: 'Деревянный щит',
    woodshield_desc: 'Когда-то был (или не был) дверью.',
    buckler_name: 'Тяжелый круглый щит',
    buckler_desc: 'Прочный и надежный. Что еще надо?',
    oakshield_name: 'Дубовый щит',
    oakshield_desc: 'По слухам, сделан из сердцевины столетних дубов.',
    ironshield_name: 'Железный щит',
    ironshield_desc:
      'Качественный железный щит выдержит не менее сотни сражений.',
    grottoshield_name: 'Золотой оберег',
    grottoshield_desc:
      'К такому блеску прилагается тяжелый вес. И цена. Но вес заметнее.',
    kiteshield_name: 'Каплевидный щит',
    kiteshield_desc: 'Опасайся сильного ветра.',
    imperialscutum_name: 'Имперский щит',
    imperialscutum_desc: 'Построиться «черепахой»!',
    argonshield_name: 'Вахта защитника',
    argonshield_desc:
      'Создан с двойной целью: для защиты слабых и борьбы со злом.',
    largebuckler_name: 'Мифриловый аспис',
    largebuckler_desc:
      'Сделан из благородного сплава мифрила и золота. Под стать и королю!',
    aegirshield_name: 'Круглый щит налетчика',
    aegirshield_desc:
      'В трудную минуту подуйте в рог, и подкрепление обязательно придет.',
    natureshield_name: 'Эгида Геи',
    natureshield_desc: 'Редкий товар рекламируют как «ароматную защиту»!',
    towershield_name: 'Змеиный страж',
    towershield_desc: 'Впечатляющий щит мифических наездников драконов.',
    fallshield_name: 'Круглый щит авангарда',
    fallshield_desc: 'Не из настоящих листьев. Иначе щит получился бы плохим.',
    ancestortotem_name: 'Тотем предков',
    ancestortotem_desc: 'Пусть предки-воины направляют вас в бою.',
    zealouspavise_name: 'Ангельская павеза',
    zealouspavise_desc:
      'Вера — великий щит. Но небестальная пластина тоже неплоха.',
    castleshield_name: 'Прометеон',
    castleshield_desc: 'Выкован падшим ангелом на вулканическом огне.',
    stpatrickshield_name: 'Милезский щит',
    stpatrickshield_desc:
      'Реликвия из прошлых веков, благословенная тремя богинями из далеких земель.',
    eastershield2020_name: 'Круглый щит Крокро',
    eastershield2020_desc: 'Крокро любит. Крокро защищает.',
    goldshield_name: 'Роскошная эгида',
    goldshield_desc:
      'Обеспечивает превосходную защиту от всего, кроме электричества.',
    prideshield_name: 'Златогривый Страж',
    prideshield_desc:
      'Если хотите быть во главе стаи, то почему бы не делать это стильно?',
    dragonshield_name: 'Змеиный страж императора',
    dragonshield_desc:
      'Используется только командиром наездников драконов. Само его существование считается мифом.',
    paragon_name: 'Бородатый идеал',
    paragon_desc: 'Клянусь своими предками!',
    beeshield_name: 'Защитник сот',
    beeshield_desc:
      'Согласно всем известным законам авиации, этот щит ни в коем случае не может летать. И действительно не летает.',
    snowshield_name: 'Кристалледяной тарджет',
    snowshield_desc:
      'Чтобы охлаждающая аура этого щита работала, необходим мощный самоцвет — кристаллед.',
    t12shield_name: 'Артефакт бездны',
    t12shield_desc:
      'Таинственная штуковина из самых глубоких и темных уголков океана. Это хороший щит, но, похоже, он может быть чем-то большим.',
    shoprshield_name: 'Щит титана',
    shoprshield_desc:
      'Размещать зелье перед щитом — это далеко не самый оптимальный вариант, но отдел исследований и разработок настоял.',
    goldshield2_name: 'Люксовый круглый щит',
    goldshield2_desc: 'Не прогадайте с ценой.',
    newyearshield_name: 'Бастион рассвета',
    newyearshield_desc:
      'У падших ангелов есть улетное снаряжение, если вам удастся его заполучить.',
    africanshield_name: 'Щит элонго',
    africanshield_desc:
      'Защищен чарами, обеспечивающими безопасное возвращение воина.',
    t13shield_name: 'Хлорощит',
    t13shield_desc:
      'Восстанавливает здоровье практически после любого урона за считанные секунды, хотя со временем его форма может значительно измениться.',
    mundrashield_name: 'Эгида Лундры',
    mundrashield_desc:
      'Услышав о монстре-боссе из другого измерения, Лундра трудилась дни и ночи, чтобы создать этот шедевр.',
    beachshield_name: 'Потрясный волновой наездник',
    beachshield_desc:
      'Ни человек, ни прилив не одолеют владельца волнового наездника.',
    boarshield_name: 'Тарджет Кровоборова',
    boarshield_desc:
      'С этим щитом вы можете внезапно обнаружить, что безрассудно несетесь в бой, не заметив этого.',
    squirecloak_name: 'Накидка искателя приключений',
    squirecloak_desc:
      'Простая и дешевая накидка для бедных искателей приключений или детей с большими мечтами.',
    wanderercloak_name: 'Плащ администратора',
    wanderercloak_desc: 'Простой символ статуса для всех бумажных душ.',
    druidcloak_name: 'Плащ друида',
    druidcloak_desc: 'Полностью органический, если это для вас важно.',
    empirecloak_name: 'Имперская накидка',
    empirecloak_desc:
      'Не слишком полезная, но, конечно, выглядит круто. В настоящее время последний писк моды среди имперской знати.',
    batcloak_name: 'Крылья вампира',
    batcloak_desc:
      'Наденьте их и поразмышляйте о секретах человека и других тайнах из жалкой кучки.',
    elvencloak_name: 'Эльфийский плащ',
    elvencloak_desc:
      'Изящный эльфийский дизайн со сложной золотой филигранью. Каким-то образом усиливает скрытность владельца.',
    darkknightcloak_name: 'Плащ тирана',
    darkknightcloak_desc: 'Ужас следует по стопам своего злого хозяина.',
    furcloak_name: 'Плащ короля зверей',
    furcloak_desc:
      'Первоначально это была работа известного варвара — повелителя зверей. С тех пор появилось множество подражаний.',
    scalecloak_name: 'Гребень владыки драконов',
    scalecloak_desc:
      'Дарует большую власть над драконами, но остерегайтесь тех, кто ее не заслужил!',
    bishopcloak_name: 'Мантия архиепископа',
    bishopcloak_desc:
      'Носится высокопоставленными священнослужителями. Кристалл хрупкий!',
    starcloak_name: 'Потусторонняя ткань пустоты',
    starcloak_desc: 'Если долго смотреть в бездну...',
    goldcloak_name: 'Люксовый плащ',
    goldcloak_desc:
      'Тяжело бремя монарха. Возможно, что и из-за этого плаща тоже.',
    frogcloak_name: 'Кувшинковый плащ',
    frogcloak_desc:
      'В священных рощах, в глубине тайных болот, растут гигантские кувшинки. Плетение из них одежды — искусство, которое считается давно утраченным.',
    t12cloak_name: 'Накидка Вестника',
    t12cloak_desc:
      'Облаченный в темные шелка, Вестник заявил о своем присутствии среди плачущих смертных.',
    blossomcloak_name: 'Плащ сакуры',
    blossomcloak_desc: 'Изящно развевается даже при легком дуновении ветерка.',
    junimaycloak_name: 'Крылья феи',
    junimaycloak_desc:
      'Если вы в них верите, то они летают! Действительно летают!',
    t13cloak_name: 'Вечномокрый плащ',
    t13cloak_desc:
      'Никто не знает, откуда берется вода, но она просто разливается... повсюду.',
    beachcloak_name: 'Пляжное полотенце',
    beachcloak_desc: 'Не подведет ни в море, ни на берегу.',
    ironring_name: 'Железное кольцо',
    ironring_desc: 'Недорогой способ избавиться от поклонников.',
    doublering_name: 'Двойное кольцо',
    doublering_desc: 'Золото и серебро сплетаются в богатом орнаменте.',
    fatring_name: 'Рубиновое кольцо',
    fatring_desc: 'И не покупайте дешевые копии с гранатами.',
    grottoring_name: 'Изумрудное кольцо',
    grottoring_desc: 'И не покупайте дешевые копии с перидотами.',
    silverband_name: 'Серебряный перстень',
    silverband_desc: 'Популярность среди оборотней очень сильно упала.',
    valentinering_name: 'Кольцо страсти',
    valentinering_desc:
      'Его истинная сила пробуждается, если отдать его родственной душе.',
    noblering_name: 'Кольцо дворянина',
    noblering_desc:
      'Надень его — тут же ощутишь чувство превосходства и самодовольства.',
    cincoring2020_name: 'Кольцо ритма',
    cincoring2020_desc:
      'Усиливает музыкальные способности владельца и временно снимает проклятие ритмической и тональной глухоты.',
    hemmadonring_name: 'Воля Клода',
    hemmadonring_desc: 'Там... Там кто-то есть? Это законно?',
    knightsignet_name: 'Перстень рыцаря',
    knightsignet_desc: 'Имеется печать самого короля Рейнгольда.',
    freedomring_name: 'Кольцо свободы',
    freedomring_desc: 'Не подходит в качестве обручального кольца.',
    lunarring_name: 'Кольцо Цянь',
    lunarring_desc: 'Хорошо иметь под рукой мелочь на всякий случай!',
    batrachitestone_name: 'Батрахит',
    batrachitestone_desc:
      'Этот уникальный самоцвет можно найти только в желудке гигантских лягушек.',
    pyramidring_name: 'Кольцо с камнем души',
    pyramidring_desc: 'В этом самоцвете что-то шевелится...',
    chosenring_name: 'Кольцо избранного',
    chosenring_desc:
      'Знак особого героя, выбранного судьбой. Также позволяет проникать в чужие дома и красть чужое добро.',
    sulfuritecoil_name: 'Серное кольцо',
    sulfuritecoil_desc: 'Оснащено современной технологией «потри и понюхай».',
    batring_name: 'Кольцо вампирского рода',
    batring_desc:
      'Хотя обычно вампиры ведут затворнический образ жизни, это кольцо позволит вам попасть во все крутые места, где они тусуются.',
    campingring_name: 'Ремешок для выживания',
    campingring_desc: 'Он точно не ослабнет, но немного коротковат.',
    feyring_name: 'Кольцо маленького народца',
    feyring_desc: 'Символ редкого союза между человеком и феями.',
    springflower_name: 'Кольцо зарецвета',
    springflower_desc: 'Не лижите самоцвет. Вы точно об этом подумали.',
    schoolring2020_name: 'Кольцо отличника',
    schoolring2020_desc:
      'Награда, которую Эвелин весьма скупо выдает своим самым выдающимся ученикам.',
    goldring_name: 'Роскошный перстень',
    goldring_desc: 'Со знаком величия.',
    borealis_name: 'Бореалис',
    borealis_desc:
      'Говорят, в каждом из таких колец хранится кусочек северного сияния.',
    templering_name: 'Самоцвет северного ветра',
    templering_desc:
      'Холодный ветер севера — это и благословение, и проклятие.',
    winterring_name: 'Кольцо метели',
    winterring_desc:
      'Не любит, чтобы его носили вместе с рукавицами, но, похоже, вполне терпит Прикосновение зимы.',
    bunnyring_name: 'Кольцо Крокро',
    bunnyring_desc: 'Заставляет все время бешено топать ногами.',
    mysteriousring_name: 'Рубиконовая темница',
    mysteriousring_desc:
      'Что там шевелится среди множества граней этого кольца?',
    candyring_name: 'Хрустящий браслет',
    candyring_desc:
      'Не должен быть таким мощным, но феи-сладкоежки заколдовали его по полной программе.',
    newyearring_name: 'Кольцо новых начинаний',
    newyearring_desc: 'Итак, о ваших планах на прошлый год...',
    t12ring_name: 'Перламутровое совершенство',
    t12ring_desc:
      'Среди множества жемчужин, украшающих это кольцо, нет ни одного изъяна. Его ценность не поддается сравнению.',
    unicornring_name: 'Кристалледяное кольцо',
    unicornring_desc:
      'Когда-то это было просто кольцо изо льда, но теперь оно благословлено единорогом, наделившим его невообразимой силой.',
    africanring_name: 'Кольцо Огуна с ониксом',
    africanring_desc:
      'Первоначально его выковал древний бог железа. Смелый дизайн превосходит другие некачественные украшения.',
    maplering_name: 'Мини-доза кленового сиропа',
    maplering_desc:
      'Удобное тонизирующее средство, которое легко носить с собой. Замерзает медленно, поэтому отлично подходит для прогулок на свежем воздухе!',
    goldring2_name: 'Люксовый перстень',
    goldring2_desc:
      'С его помощью Сиа ставит печать на бланки чеков Рейнгольда. Отсюда и гримаса.',
    t13ring_name: 'Знак уробороса',
    t13ring_desc:
      'Змей шепчет вам на ушко приятные слова. Да еще и с таким кокетством.',
    ironamulet_name: 'Сувенир',
    ironamulet_desc: 'Фрагмент в центре можно заменить на чей-то портрет.',
    pendant_name: 'Нефритовая подвеска',
    pendant_desc:
      'Слабо светится зеленым, если рядом есть энергетические линии.',
    chain_name: 'Железная цепочка',
    chain_desc:
      'Солдаты дарят такие цепи своим возлюбленным, надеясь вернуться живыми и здоровыми.',
    mundraamulet_name: 'Амулет Лундры',
    mundraamulet_desc: 'Любимая вещь ликантропов.',
    sunpendant_name: 'Подвеска с солнцем',
    sunpendant_desc: 'Солнце у тебя в руках!',
    grottoamulet_name: 'Амулет затмения',
    grottoamulet_desc:
      'Пробуждает скрытые силы, если надеть его во время затмения. Солнечного или лунного? В руководстве не уточняется.',
    noblechain_name: 'Цепь дворянина',
    noblechain_desc:
      'Для максимального самодовольства носить с кольцом дворянина.',
    medal_name: 'Медаль Почета',
    medal_desc: 'Престижная награда, выдаваемая за благородные деяния.',
    stpatrickmedallion_name: 'Медальон удачи',
    stpatrickmedallion_desc: 'Реально работает, клянусь!',
    goldamulet_name: 'Роскошный амулет',
    goldamulet_desc:
      'Говорят, приносит удачу своему владельцу. Который в ней явно не нуждается.',
    holysymbol_name: 'Священный символ',
    holysymbol_desc:
      'Используется священнослужителями. Внешний вид символа зависит от религии.',
    oktoberamulet_name: 'Удобная подвеска',
    oktoberamulet_desc: 'Это зелье или амулет? Кто-нибудь знает?',
    cincoamulet_name: 'Брелок Синко',
    cincoamulet_desc:
      'Имеет несколько бусин, издающих приятный дребезжащий звук при встряхивании.',
    titaniumtorc_name: 'Титановый шейный обруч',
    titaniumtorc_desc: 'Как раз подойдет для титана торговли.',
    timelocket_name: 'Магический хронометр',
    timelocket_desc:
      'Автоматически подстраивается под часовые пояса и переводит стрелки на летнее или зимнее время. Удобно!',
    dreamamulet_name: 'Ловец снов',
    dreamamulet_desc:
      'Требуется немного повозиться, чтобы он ловил только плохие сны.',
    magatama_name: 'Ожерелье из магатам',
    magatama_desc:
      'Гарантирует удачу и успех в жизни воинов (и иногда юриста).',
    dragonamulet_name: 'Подвеска драконьей души',
    dragonamulet_desc:
      'Наделяет владельца большой силой и невероятной жаждой золота.',
    archivistglasses_name: 'Очки архивариуса',
    archivistglasses_desc: 'Надевайте серьезные очки и займитесь бухгалтерией.',
    phylactery_name: 'Стигийский филактерий',
    phylactery_desc: 'Для обращения с ним не помешает святая вода...',
    rudoamulet_name: 'Тотем Урсы',
    rudoamulet_desc:
      'Неодушевленный предмет, но излучает такую злобу, что лучше его связать.',
    spaceamulet_name: 'Портативный ПИТОМЕЦ',
    spaceamulet_desc:
      'Все неудобства настоящего домашнего питомца без каких-либо преимуществ!',
    alimyriad_name: 'Алимириад',
    alimyriad_desc: 'Все едино, едино все.',
    priestamulet_name: 'Талисман Фрейи',
    priestamulet_desc: 'Как будто кто-то всегда наблюдает за вами...',
    vipamulet_name: 'Талисман VIP',
    vipamulet_desc:
      'С этим предметом от Майи можно прийти даже во двор к самому Рейнгольду!',
    brisingamen_name: 'Брисингамен',
    brisingamen_desc:
      'Эти самоцветы яростно сверкают, словно бушующее адское пламя.',
    peakamulet_name: 'Сердце муссона',
    peakamulet_desc: 'Вы всегда в безопасности, если вы и есть око бури.',
    troblinamulet_name: 'Забытый желудь',
    troblinamulet_desc:
      'Гиблины Воющего леса тщательно собирают желуди, не давшие ростки, считая, что те обладают мощной магией.',
    atlab_amulet_name: 'Амулет Гияцо',
    atlab_amulet_desc: 'На память о Гияцо, любимом учителе Аанга.',
    wiccanamulet_name: 'Талисман Лютой рощи',
    wiccanamulet_desc:
      'Недавно был заряжен магической энергией с помощью языческих ритуалов.',
    t12amulet_name: 'Подарок Титании',
    t12amulet_desc:
      'Лишь однажды за всю свою долгую жизнь Титания дала что-то, не ожидая ничего взамен.',
    goldamulet2_name: 'Люксовый карканет',
    goldamulet2_desc: 'Немного показушно. Совсем чуть-чуть.',
    arcadeamulet_name: 'Мини-геймпад',
    arcadeamulet_desc:
      'Играйте во что угодно и где угодно. Правда, придется использовать немного воображение.',
    cyclopsamulet_name: 'Вечные песочные часы',
    cyclopsamulet_desc:
      'Песок внутри притягивает взгляд, приглашая владельца смотреть на него веками.',
    junimayamulet_name: 'Венок феи',
    junimayamulet_desc:
      'Традиционный венок феи, созданный под влиянием увлечения Мэйли драгоценностями.',
    t13amulet_name: 'Хронометр Титании',
    t13amulet_desc:
      'У фей жесткий график. В 9 они проказничают, а в 10 бездельничают... Опаздывать нельзя!',
    platinumamulet_name: 'Платиновый знак',
    platinumamulet_desc:
      'Несомненно, это знак очень богатого главы гномьей гильдии.',
    baconeggs_name: 'Яичница с беконом',
    baconeggs_desc:
      'Квинтэссенция классического завтрака. Начните день правильно!',
    baobun_name: 'Гуа-бао',
    baobun_desc:
      'Также известны как булочки бао. На начинке здесь не экономят.',
    spacemeal_name: 'Космические хлопья',
    spacemeal_desc:
      'Если бы кто-нибудь потрудился посмотреть ингредиенты, они бы точно были отнесены к категории десертов.',
    poutine_name: 'Путин',
    poutine_desc:
      'Картофель фри, подливка и курдючный сыр. Если это не лучшее, что есть на свете, то идите вон.',
    maplemeal_name: 'Фирменное блюдо кленоварни',
    maplemeal_desc:
      'Обычный канадийский завтрак. Здесь тоже есть кленовый сироп.',
    burger_name: 'Чизбургер',
    burger_desc:
      'Несомненный король фастфуда. Сыр на 100% плавленый и совершенно ненатуральный, как и должно быть!',
    patechinois_name: 'Пастуший пирог',
    patechinois_desc:
      'В канайском Кебаке по сей день идет война по поводу добавления кетчупа в это блюдо. Современная наука считает, что добавлять кетчуп — это мерзость.',
    pizza_name: 'Пицца «Маргарита»',
    pizza_desc: 'Иногда простоты более чем достаточно.',
    arcademeal_name: 'Пирог усиления',
    arcademeal_desc:
      'Ешьте овощи, чтобы вырасти большим и сильным. Возможно, будет забавный звуковой эффект.',
    ahipoke_name: 'Ахи-поке',
    ahipoke_desc:
      'Это блюдо из сырой рыбы является фирменным у Туту Мано. Попасть в него достойны только самые свежие ингредиенты!',
    newyearmeal_name: 'Гребешки со сливками',
    newyearmeal_desc: 'Дары моря!',
    sushi_name: 'Суши',
    sushi_desc: 'Свежий утренний улов!',
    coconutcurry_name: 'Суперострый карри',
    coconutcurry_desc:
      'Семейный рецепт карри из рыбы махи-махи. Однако Туту Мано любит ПООСТРЕЕ! Предупреждаем клиентов!',
    kebabs_name: 'Вегетарианский кебаб',
    kebabs_desc:
      'Вегетарианский вариант, позволяющий употребить дневную норму овощей. Тарелку тоже можно съесть!',
    ramen_name: 'Роскошный рамэн',
    ramen_desc:
      'Вечная классика и любимец публики. Следите за употреблением соли.',
    t12meal_name: 'Курица хули-хули',
    t12meal_desc:
      'Курица-гриль, щедро политая одноименным сладким соусом хули-хули.',
    goldmeal_name: 'Люксовые тамале',
    goldmeal_desc: 'Знаете ли вы, что золото теоретически можно съесть?',
    lunarmeal_name: 'Морской окунь на пару',
    lunarmeal_desc: 'Что за кислый вид?',
    t13meal_name: 'Горшок паэльи',
    t13meal_desc: 'Лучшее сочетание даров суши и моря.',
    firecrabmeal_name: 'Очень горячий горшок',
    firecrabmeal_desc: 'Вы не забыли выключить вулканную плиту?',
    cookies_name: 'Тарелка с печеньем',
    cookies_desc:
      'Нет, это не обычное дешевое печенье. Вон какие БОЛЬШИЕ куски!',
    shavedice_name: 'Ледяная стружка',
    shavedice_desc:
      'Отличный совет для лавочника! Храните ее в инвентаре рядом с весенней водой Авроры, чтобы не растаяла!',
    taiyaki_name: 'Тайяки',
    taiyaki_desc: 'Внутри каждого есть таинственная (но вкусная!) начинка.',
    cheesecake_name: 'Чизкейк',
    cheesecake_desc:
      'Трудно остановиться на одном кусочке, но это только на пользу.',
    valentinedessert_name: 'Коробка шоколадных конфет',
    valentinedessert_desc: 'Немного банально, но не зря же это классика!',
    macaron_name: 'Набор макаронов',
    macaron_desc: 'Нет, говорить «макарун» неправильно.',
    daifuku_name: 'Моти с клубникой',
    daifuku_desc: 'Каждый кусочек содержит сладкий клубничный сюрприз.',
    spiralcake_name: 'Рулет с чаем маття',
    spiralcake_desc:
      'Восхитительно пористый, с манящей кремовой спиралью. Хочется же, чтобы это была не просто 3D-модель?',
    pineapplecake_name: 'Ананасовый пирог',
    pineapplecake_desc: 'Ананасы же не в пицце, так что все в порядке, верно?',
    tuxedocake_name: 'Торт «Смокинг»',
    tuxedocake_desc: 'Обычный торт, если есть его в праздничном костюме.',
    t12dessert_name: 'Хало-хало',
    t12dessert_desc:
      'Каипо с удовольствием придаст этому десерту причудливую форму по желанию заказчика.',
    coconutcake_name: 'Торт с кокосовым кремом',
    coconutcake_desc:
      'Коконечно, перед этим кремовым тортом невозможно устоять.',
    golddessert_name: 'Люксовые чуррос',
    golddessert_desc: 'Это не съешь, не обляпавшись, но кто может устоять?',
    newyeardessert_name: 'Торт с зеркальной глазурью',
    newyeardessert_desc:
      'Словами не передать, насколько этот десерт божественный.',
    t13dessert_name: 'Лучший фруктовый салат',
    t13dessert_desc:
      'Является ли блюдо веганским, если в нем есть арбуз в форме акулы?',
    campingdessert_name: '',
    campingdessert_desc: '',
    chipped_name: 'Сколотый камень',
    chipped_desc:
      'Неуклюжий рунный камень со слабыми следами силы. Жрица или чародей может сделать из него магическую руну.',
    flawed_name: 'Ущербный камень',
    flawed_desc:
      'Неуклюжий рунный камень, обладающий потенциалом. Жрица или чародей может сделать из него магическую руну.',
    chiseled_name: 'Точеный камень',
    chiseled_desc:
      'Изящно вырезанный рунный камень, дрожащий от энергии. Жрица или чародей могут использовать его для колдовства.',
    perfect_name: 'Идеальный камень',
    perfect_desc:
      'Искусно ограненный рунный камень, идеально настроенный на магию. Жрица или чародей могут использовать его для колдовства.',
    lessermoon_name: 'Малый лунный камень',
    lessermoon_desc:
      'Часть лунного камня с небольшими магическими свойствами. Важный компонент синтеза.',
    flawedmoon_name: 'Превосходный лунный камень',
    flawedmoon_desc:
      'Фрагмент лунного метеорита, замерзший в камне. Важный компонент синтеза.',
    greatermoon_name: 'Большой лунный камень',
    greatermoon_desc:
      'Лунный цикл в физической форме. Важный компонент синтеза.',
    perfectmoon_name: 'Идеальный лунный камень',
    perfectmoon_desc:
      'Кристаллизованная лунная магия, наполненная силой. Необходимый компонент для синтеза.',
    ember_name: 'Стихия углей',
    ember_name_o: 'Углей',
    ember_desc:
      'Приятно теплая на ощупь. Кладите ее в карман во время суровой зимы.',
    flaming_name: 'Стихия пламени',
    flaming_name_o: 'Пламени',
    flaming_desc:
      'Справочник чародея настоятельно рекомендует не хранить ее в библиотеке или близко от любых легковоспламеняющихся веществ.',
    blaze_name: 'Стихия огня',
    blaze_name_o: 'Огня',
    blaze_desc: 'Сжигающая страсть.',
    inferno_name: 'Стихия ада',
    inferno_name_o: 'Ада',
    inferno_desc:
      'Сильное тепло, излучаемое этой руной, вполне может превратить в пепел менее ценные предметы.',
    bubble_name: 'Стихия пузырьков',
    bubble_name_o: 'Пузырьков',
    bubble_desc: 'Постоянно капает чистая и свежая вода.',
    tidal_name: 'Стихия прилива',
    tidal_name_o: 'Прилива',
    tidal_desc:
      'Пахнет морем. Если приложить к уху, то и по звуку похоже на море.',
    flood_name: 'Стихия потопа',
    flood_name_o: 'Потопа',
    flood_desc: 'Льет, не переставая. Лучше держать на улице!',
    torrent_name: 'Стихия ливня',
    torrent_name_o: 'Ливня',
    torrent_desc:
      'Неумолимая, сокрушительная мощь океана в свое время одержит победу.',
    breeze_name: 'Стихия бриза',
    breeze_name_o: 'Бриза',
    breeze_desc:
      'Постоянно создает прохладный бриз. Из нескольких штук можно сделать импровизированный кондиционер.',
    gale_name: 'Стихия ветра',
    gale_name_o: 'Ветра',
    gale_desc:
      'Вызывает сильные порывы ветра. Можно использовать для быстрого высушивания одежды.',
    tempest_name: 'Стихия бури',
    tempest_name_o: 'Бури',
    tempest_desc:
      'Если нечаянно разбить, на волю может вырваться буря, которая не будет утихать неделями!',
    hurricane_name: 'Стихия урагана',
    hurricane_name_o: 'Урагана',
    hurricane_desc:
      'Непревзойденная выкорчевывающая сила, редко встречающаяся в этом мире. Если посеешь ветер...',
    natural_name: 'Стихия природы',
    natural_name_o: 'Природы',
    natural_desc:
      'Выращивается в этих краях без искусственных веществ или консервантов.',
    wild_name: 'Стихия дикости',
    wild_name_o: 'Дикости',
    wild_desc: 'Этот камень мечтает о свободе! Не держите его на привязи!',
    primal_name: 'Стихия примитивности',
    primal_name_o: 'Примитивности',
    primal_desc: 'Камень напитан непоколебимой силой гор.',
    primeval_name: 'Стихия первобытности',
    primeval_name_o: 'Первобытности',
    primeval_desc:
      'Неизменная. Непоколебимая. Таковой жизнь была, есть и будет. Независимо от того, что происходит.',
    light_name: 'Стихия света',
    light_name_o: 'Света',
    light_desc: 'Батарейки в комплект не входят.',
    holy_name: 'Стихия святости',
    holy_name_o: 'Святости',
    holy_desc: 'Если попросить, монотонно цитирует святые заповеди.',
    sacred_name: 'Стихия священности',
    sacred_name_o: 'Священности',
    sacred_desc:
      'Эта руна начинает петь подобно хору ангелов, если ее держит кто-то чистый и набожный.',
    divine_name: 'Стихия божественности',
    divine_name_o: 'Божественности',
    divine_desc:
      'Чистая и непорочная, духовно и морально. Однако она будет требовать того же от своего владельца.',
    corrupted_name: 'Стихия испорченности',
    corrupted_name_o: 'Испорченности',
    corrupted_desc: 'Из руны иногда раздается хихиканье. А что смешного-то?',
    unholy_name: 'Стихия нечестивости',
    unholy_name_o: 'Нечестивости',
    unholy_desc: 'Легко высасывает из помещения весь свет.',
    nightmare_name: 'Стихия кошмара',
    nightmare_name_o: 'Кошмара',
    nightmare_desc: 'Не оставляйте это на ночь на тумбочке.',
    abyssal_name: 'Стихия пучины',
    abyssal_name_o: 'Пучины',
    abyssal_desc:
      'Полностью испорченная и не поддающаяся искуплению. Даже самый благородный паладин должен держаться подальше от этого жалкого творения.',
    luxuriouselement_name: 'Роскошная стихия',
    luxuriouselement_name_o: 'Роскошный',
    luxuriouselement_desc:
      'Магический камень большой ценности. Бесполезен для героев, но явно придает предметам более дорогой вид!',
    opulentelement_name: 'Люксовая стихия',
    opulentelement_name_o: 'Люксовый',
    opulentelement_desc:
      'Сама суть богатства. Может быть, это и есть легендарный философский камень?',
    freshspirit_name: 'Дух свежести',
    freshspirit_name_o: 'Свежести',
    freshspirit_desc: 'Еда вкуснее, когда она свежая!',
    turtle_name: 'Дух барана',
    turtle_name_o: 'Барана',
    turtle_desc: 'Просто держа это в руке, вы уже хотите бодать все головой.',
    wolf_name: 'Дух волка',
    wolf_name_o: 'Волка',
    wolf_desc: 'Не ладит с духом барана. Совершенно.',
    ox_name: 'Дух быка',
    ox_name_o: 'Быка',
    ox_desc:
      'У предметов, заколдованных при помощи этого духа, иногда появляется таинственная неприязнь к красному цвету.',
    eagle_name: 'Дух орла',
    eagle_name_o: 'Орла',
    eagle_desc: 'Некоторые говорят, что это дух самой свободы.',
    viper_name: 'Дух гадюки',
    viper_name_o: 'Гадюки',
    viper_desc: 'Характер довольно тяжелый.',
    cat_name: 'Дух кошки',
    cat_name_o: 'Кошки',
    cat_desc: 'Где только можно, там и сядет.',
    rabbitspirit_name: 'Дух кролика',
    rabbitspirit_name_o: 'Кролик',
    rabbitspirit_desc:
      'Если вы гонитесь за двумя зайцами... возможно, вам нужен шанс изготовить несколько предметов!',
    luxuriousspirit_name: 'Дух Шолотля',
    luxuriousspirit_name_o: 'Шолотль',
    luxuriousspirit_desc:
      'Предвестник смерти солнца. К счастью, оно вернется на следующее утро.',
    rhino_name: 'Дух носорога',
    rhino_name_o: 'Носорога',
    rhino_desc: 'При создании этого духа ни одного носорога не пострадало.',
    owl_name: 'Дух совы',
    owl_name_o: 'Совы',
    owl_desc: 'Сколько раз нужно лизнуть, чтобы добраться до центра этой руны?',
    armadillo_name: 'Дух броненосца',
    armadillo_name_o: 'Броненосца',
    armadillo_desc:
      'Если игнорировать проблемы, то, возможно, они просто возьмут и уйдут.',
    lizard_name: 'Дух ящерицы',
    lizard_name_o: 'Ящерицы',
    lizard_desc:
      'Если оставить это на солнце, магическим образом восстанавливаются трещины и зазубрины.',
    crab_name: 'Дух лошади',
    crab_name_o: 'Лошади',
    crab_desc: 'Несокрушимая выносливость дикого жеребца в форме духа.',
    hippo_name: 'Дух бегемота',
    hippo_name_o: 'Бегемота',
    hippo_desc:
      'Какой бы предмет ни заколдовали при помощи этого духа, он будет казаться... гораздо толще.',
    walrus_name: 'Дух моржа',
    walrus_name_o: 'Моржа',
    walrus_desc: 'Такого трудно сдвинуть с места.',
    shark_name: 'Дух акулы',
    shark_name_o: 'Акулы',
    shark_desc: 'Высший хищник с незапамятных времен.',
    lion_name: 'Дух льва',
    lion_name_o: 'Льва',
    lion_desc: 'Все, на что падает свет, — владения этого духа.',
    bear_name: 'Дух медведя',
    bear_name_o: 'Медведя',
    bear_desc: 'Неудивительно, что Рудо их любит.',
    mammoth_name: 'Дух мамонта',
    mammoth_name_o: 'Мамонта',
    mammoth_desc: 'Память у него дли-и-и-и-инная.',
    dinosaur_name: 'Дух динозавра',
    dinosaur_name_o: 'Динозавра',
    dinosaur_desc:
      'На самом деле в основном здесь лягушачья ДНК. Динозавры ведь все-таки вымерли.',
    dragon_name: 'Дух Лундры',
    dragon_name_o: 'Лундра',
    dragon_desc:
      'Надменность сохраняется даже у духа. Похоже, ему очень хочется показать этим боссам, кто здесь главный.',
    tiger_name: 'Дух тигра',
    tiger_name_o: 'Тигр',
    tiger_desc: 'Особенно любит мюсли. Они великолепны!',
    opulentspirit_name: 'Дух Кетцалькоатля',
    opulentspirit_name_o: 'Кетцалькоатль',
    opulentspirit_desc:
      'Ветер, проводник и подметальщик дорог для богов дождя.',
    phoenix_name: 'Дух феникса',
    phoenix_name_o: 'Феникс',
    phoenix_desc:
      'Эта руна не должна существовать. Это памятник человеческому высокомерию.',
    hydra_name: 'Дух гидры',
    hydra_name_o: 'Гидра',
    hydra_desc:
      'Рубите, рубите. Чем больше рубите, тем больше голов вырастает...',
    tarrasque_name: 'Дух тараска',
    tarrasque_name_o: 'Тараск',
    tarrasque_desc: 'Когда тараск восстает, государства рушатся.',
    carbuncle_name: 'Дух карбункула',
    carbuncle_name_o: 'Карбункул',
    carbuncle_desc:
      'Милое и безобидное на вид существо, обладающее неописуемой силой.',
    chimera_name: 'Дух химеры',
    chimera_name_o: 'Химера',
    chimera_desc:
      'Нечестивый гибрид льва, козла, дракона и змеи. И, ожидаемо, такой же покладистый.',
    kraken_name: 'Дух кракена',
    kraken_name_o: 'Кракен',
    kraken_desc:
      'Он гоняется за кораблями, раскалывая их, словно орех, своими многочисленными щупальцами.',
    santa_name: 'Дух Рождества',
    santa_name_o: 'Рождество',
    santa_desc:
      'Веселый дух зимних праздников. Его истинный потенциал раскрывается при заколдовывании рождественских предметов.',
    elvenwood_name: 'Эльфийская древесина',
    elvenwood_desc: 'Характерная форма этого талисмана лишь кажется сложной.',
    redpinecone_name: 'Шишка железной сосны',
    redpinecone_desc: 'Любимое лакомство стальных белок.',
    glowingmushrooms_name: 'Светящийся гриб',
    glowingmushrooms_desc: 'Может выступить в роли экологичного факела.',
    arcanedust_name: 'Серебряная пыль',
    arcanedust_desc:
      'Дает свойственную серебру защиту от магии обсыпанным ей объектам.',
    webbedwings_name: 'Перепончатое крыло',
    webbedwings_desc:
      'Большие гибкие крылья, когда-то принадлежавшие гигантской летучей мыши.',
    preciousgem_name: 'Драгоценный камень',
    preciousgem_desc: 'В нем дремлет магическая энергия неизвестной природы.',
    livingroots_name: 'Живой корень',
    livingroots_desc:
      'Чем больше он похож на человека, тем вкуснее. Жуть, правда?',
    darkscale_name: 'Чешуя ржавого змея',
    darkscale_desc:
      'Юные ржавые змеи нуждаются в хорошо сбалансированной металлической диете.',
    icypearl_name: 'Жемчужина глубин',
    icypearl_desc: 'Жемчужина гигантского болотного моллюска.',
    whitesand_name: 'Белый песок',
    whitesand_desc: 'Ослепительно яркий песок без следа примесей.',
    sharpfang_name: 'Бронзовый клык',
    sharpfang_desc:
      'Размер этого клыка отбивает всякую охоту узнать, откуда он взялся.',
    mooncrystal_name: 'Лунный кристалл',
    mooncrystal_desc: 'Заключенная в нем тьма будто пожирает свет.',
    evileye_name: 'Дурной глаз',
    evileye_desc:
      'Дурные глаза иногда моргают одновременно, словно принадлежат одному существу.',
    silkscarab_name: 'Шелковый скарабей',
    silkscarab_desc:
      'Экзотический материал, оставшийся от какой-то погибшей цивилизации и якобы имеющий божественное происхождение.',
    starmetal_name: 'Звездный металл',
    starmetal_desc: 'Мифический металл с другой планеты.',
    ancientmarble_name: 'Древний мрамор',
    ancientmarble_desc: 'Такого, как раньше, уже не делают!',
    overgrownvine_name: 'Разросшаяся лоза',
    overgrownvine_desc:
      'Магический углеродный анализ утверждает, что этой лозе... более тысячи лет?!?',
    timecrystal_name: 'Кристалл Кроноса',
    timecrystal_desc:
      'Говорят, что этот кристалл имеет власть над самим временем.',
    ectoplasm_name: 'Жуткая эктоплазма',
    ectoplasm_desc: 'Кто тут кого боится?',
    sewingthread_name: 'Нить судьбы',
    sewingthread_desc:
      'Говорят, что эта изысканная золотая нить символизирует продолжительность жизни. Слишком коротко не режьте!',
    ghostflag_name: 'Жуткий вымпел',
    ghostflag_desc: 'Герб давно сгинувшего дома. Этот флаг много чего повидал.',
    deepcoral_name: 'Глубоководный коралл',
    deepcoral_desc:
      'Вынутое из воды, это вещество становится твердым как сталь!',
    sirenshard_name: 'Кристальная колыбельная',
    sirenshard_desc:
      'Слышится тихая песня... И так трудно положить на место...',
    goldshell_name: 'Драгоценная ракушка',
    goldshell_desc:
      'Как эта ракушка оказалась покрытой червонным золотом, никто не знает.',
    grimtalon_name: 'Мрачный коготь',
    grimtalon_desc: 'Острый и напоминает сталь.',
    crystalegg_name: 'Циркониевая яичная скорлупа',
    crystalegg_desc: 'Эти осколки почти вечны.',
    icybreeze_name: 'Северный шторм',
    icybreeze_desc:
      'Этот заряженный магией северный ветер особенно полезен для закалки расплавленного металла.',
    crabclaw_name: 'Крушащая клешня',
    crabclaw_desc:
      'Совершенно непробиваемая и пышущая жаром снаружи, вкусная внутри.',
    obsidian_name: 'Необработанный обсидиан',
    obsidian_desc:
      'Известные чародеи считают, что с помощью этого материала можно создать мощный магический портал. Куда? Они предпочитают не говорить.',
    magmacore_name: 'Магмовое ядро',
    magmacore_desc:
      'Вопреки всем известным законам физики, это ядро никогда не остывает.',
    voidcrystal_name: 'Коготь чужака',
    voidcrystal_desc:
      'Из чего бы этот предмет ни был сделан, он явно не из нашей реальности.',
    voideye_name: 'Всевидящее око',
    voideye_desc:
      'Оно видит тебя... Оно приближается... Оно видит тебя... Оно приближается... Оно видит тебя... Оно приближается...',
    voidcloth_name: 'Астральная ткань',
    voidcloth_desc:
      'Достаточно благочестивый портной мог бы из этого создать новый мир.',
    platinumjewel_name: 'Платиновые браслеты',
    platinumjewel_desc:
      'Переплавка такого шедевра требует мастера своего дела, чтобы не испортить этот драгоценный металл.',
    tabletjewel_name: 'Люксовый камень',
    tabletjewel_desc:
      'Невероятно дефицитный компонент, используемый для изготовления люксовых предметов. Его также можно продать на рынке по высокой цене.',
    tabletfragment_name: 'Золотой артефакт',
    tabletfragment_desc:
      'Очень редкий компонент, используемый для изготовления роскошных предметов. Его также можно продать на рынке по высокой цене.',
    puregold_name: 'Слиток чистого золота',
    puregold_desc:
      'Ужасно тяжелый слиток, содержащий 99% золота. Король щедро вознаградит гильдии, которые отдадут ему такой.',
    mightsigil_name: 'Знак мощи',
    mightsigil_desc:
      'Грубая сила, сосредоточенная в символе таинственной мощи. Используется для создания очень мощных предметов.',
    gracesigil_name: 'Знак изящества',
    gracesigil_desc:
      'Чистая ловкость, сосредоточенная в символе таинственной мощи. Используется для создания очень мощных предметов.',
    sparksigil_name: 'Знак искры',
    sparksigil_desc:
      'Неограниченный магический потенциал, сконцентрированный в символе таинственной мощи. Используется для создания очень мощных предметов.',
    mightsigil2_name: 'Знак истинной мощи',
    mightsigil2_desc:
      'Истинная сила, переработанная в кристаллизованную таинственную мощь. Используется для создания невообразимо мощных предметов.',
    gracesigil2_name: 'Знак истинного изящества',
    gracesigil2_desc:
      'Истинная ловкость, переработанная в кристаллизованную таинственную мощь. Используется для создания невообразимо мощных предметов.',
    sparksigil2_name: 'Знак истинной искры',
    sparksigil2_desc:
      'Истинный магический потенциал, переработанный в кристаллизованную таинственную мощь. Используется для создания невообразимо мощных предметов.',
    xpdrink1_name: 'Напиток опыта',
    xpdrink1_desc: 'Дайте его персонажу для мгновенной прибавки опыта.',
    xpdrink2_name: 'Сверхнапиток опыта',
    xpdrink2_desc: 'Дайте его персонажу для значительной прибавки опыта.',
    xpdrink3_name: 'Меганапиток опыта',
    xpdrink3_desc: 'Дайте его персонажу для невероятной прибавки опыта.',
    xpdrink4_name: 'Мифический напиток опыта',
    xpdrink4_desc:
      'Повышает уровень героя на 1, независимо от требуемого опыта!',
    sitems_xpdrink4_effect_desc: 'Повышает уровень героя на 1.',
    seedhp1_name: 'Зерно жизни',
    seedhp1_desc: 'Навсегда увеличивает запас здоровья персонажа.',
    seedattack1_name: 'Зерно силы',
    seedattack1_desc: 'Навсегда увеличивает силу атаки персонажа.',
    seeddef1_name: 'Зерно сопротивления',
    seeddef1_desc: 'Навсегда увеличивает защиту персонажа.',
    seedhp1_plus_name: 'Цветок жизни',
    seedhp1_plus_desc:
      'Навсегда увеличивает максимальное количество зерен жизни, которые может расходовать герой, на 5.',
    seedattack1_plus_name: 'Цветок силы',
    seedattack1_plus_desc:
      'Навсегда увеличивает максимальное количество зерен силы, которые может расходовать герой, на 5.',
    seeddef1_plus_name: 'Цветок сопротивления',
    seeddef1_plus_desc:
      'Навсегда увеличивает максимальное количество зерен сопротивления, которые может расходовать герой, на 5.',
    bp_upgrade_name: 'Свиток восхождения',
    bp_upgrade_desc: 'Используется для улучшения чертежей.',
    bp_unlock_name: 'Свиток чертежа',
    bp_unlock_desc: 'Используется для получения особых чертежей.',
    hpdrink1_name: 'Напиток выносливости',
    hpdrink1_desc: 'Полностью исцеляет отдыхающего персонажа.',
    hpdrink1_shortdesc: 'Исцеляет персонажа',
    revivekit1_name: 'Перо феникса',
    revivekit1_desc: 'Полностью исцеляет всех отдыхающих персонажей.',
    revivekit1_shortdesc: 'Исцеляет всех',
    repairkit_name: 'Ремкомплект',
    repairkit_desc:
      'Ремонтирует поврежденные в ходе прохождения приключений предметы.',
    atkbooster1_name: 'Усилитель мощи',
    atkbooster1_desc: 'Прибавка к силе на одно приключение.',
    atkbooster2_name: 'Сверхусилитель мощи',
    atkbooster2_desc: 'Значительная прибавка к силе на одно приключение.',
    atkbooster3_name: 'Мегаусилитель мощи',
    atkbooster3_desc: 'Потрясающая прибавка к силе на одно приключение.',
    xpbooster1_name: 'Усилитель опыта',
    xpbooster1_desc: 'Увеличивает зарабатываемый всей командой опыт на 50%!',
    xpbooster2_name: 'Сверхусилитель опыта',
    xpbooster2_desc: 'Удваивает зарабатываемый всей командой опыт!',
    xpbooster3_name: 'Мегаусилитель опыта',
    xpbooster3_desc:
      'Утраивает зарабатываемый всей командой опыт! Пошли гриндить!',
    compass1_name: 'Бронзовый компас',
    compass1_desc: 'Ускоряет прохождение приключений и отдых на 25%.',
    compass1_shortdesc: 'Ускорение приключений и отдыха на 25%.',
    compass2_name: 'Серебряный компас',
    compass2_desc: 'Ускоряет прохождение приключений и отдых на 50%.',
    compass2_shortdesc: 'Ускорение приключений и отдыха на 50%.',
    compass3_name: 'Золотой компас',
    compass3_desc:
      'Ускоряет прохождение приключений и отдых на 80%! Вот это скорость!',
    compass3_shortdesc: 'Ускорение приключений на 80% и отдыха на 99%.',
    bugle1_name: 'Рог изобилия',
    bugle1_desc: 'Увеличивает количество найденных компонентов на 50%.',
    bugle2_name: 'Сверхрог изобилия',
    bugle2_desc: 'Удваивает количество компонентов!',
    bugle3_name: 'Мегарог изобилия',
    bugle3_desc:
      'Увеличивает количество найденных компонентов на 150%! Добычи просто завались!',
    skillreroller1_name: 'Базовый кубик навыков',
    skillreroller1_desc: 'Может заменить навык в первой ячейке навыка героя.',
    skillreroller2_name: 'Суперкубик навыков',
    skillreroller2_desc:
      'Может заменить навык во второй ячейке навыка героя или ниже.',
    skillreroller3_name: 'Мегакубик навыков',
    skillreroller3_desc:
      'Может заменить навык в третьей ячейке навыка героя или ниже.',
    skillreroller4_name: 'Идеальный кубик навыков',
    skillreroller4_desc: 'Может заменить навык в любой ячейке навыка героя!',
    titansoul_name: 'Душа титана',
    titansoul_desc:
      'Навсегда улучшает класс героя, делая его сильнее и открывая все ячейки навыков.',
    limitededition_name: 'Ограниченная серия!',
    limitededition_desc:
      'Особая печать, позволяющая повышать цену на один и тот же товар дважды.',
    discounttoken_name: 'За счет заведения!',
    discounttoken_desc:
      'Специальная печать, которая позволяет отдать предмет бесплатно, но дает в 10 раз больше энергии скидки.',
    sitem_discounttoken_effect_desc:
      '100% скидка для получения в 10 раз больше энергии!',
    special_item_type_discounttoken: 'Рекламный товар',
    antique_shard_name: 'Жетон древностей',
    antique_shard_desc:
      'Редкая валюта, полученная вместе с королевской милостью. Позволяет покупать древности во время события «Каприз короля».',
    antique_shard_shortdesc: 'Валюта магазина древностей',
    dragonemblem_name: 'Драконий знак',
    dragonemblem_desc:
      'Редкая валюта, полученная во время события «Вторжение драконов». Можно использовать для улучшения драконьего клада, улучшения мебели выше 15 уровня или ускорения улучшения мебели.',
    dragonemblem_shortdesc: 'Валюта улучшения мебели',
    special_item_type_dragonemblem: 'Валюта для улучшения',
    cannonball_name: 'Пушечное ядро змеиной кары',
    special_item_type_cannonball: 'Усилитель события',
    cannonball_desc:
      'Особый усилитель, который можно использовать только во время события «Вторжение драконов». Мощнее, чем любой другой усилитель!',
    cannonball_shortdesc: 'Усилитель особого события',
    strefund_any_name: 'Жетон возврата для талантов',
    strefund_any_desc:
      'Позволяет один раз бесплатно вернуть очки на любом из трех деревьев талантов.',
    strefund_any_shortdesc: 'Жетон возврата',
    strefund_bartering_name: 'Жетон возврата для торговли',
    strefund_bartering_desc:
      'Позволяет один раз бесплатно вернуть очки на дереве талантов торговли.',
    strefund_bartering_shortdesc: 'Жетон возврата',
    strefund_questing_name: 'Жетон возврата для приключений',
    strefund_questing_desc:
      'Позволяет один раз бесплатно вернуть очки на дереве талантов приключений.',
    strefund_questing_shortdesc: 'Жетон возврата',
    strefund_crafting_name: 'Жетон возврата для изготовления',
    strefund_crafting_desc:
      'Позволяет один раз бесплатно вернуть очки на дереве талантов изготовления.',
    strefund_crafting_shortdesc: 'Жетон возврата',
    spinticket_name: 'Игорный билет',
    spinticket_desc: '',
    spintoken_name: 'Игорный жетон',
    spintoken_desc: '',
    chest_forest_name: 'Деревянный сундук',
    chest_forest_desc:
      'Прочный сундук, найденный в Воющем лесу. Можно открыть деревянным ключом.',
    chest_grotto_name: 'Замерзший сундук',
    chest_grotto_desc:
      'Загадочно холодный сундук, найденный в пещерах Авроры. Можно открыть ледяным ключом.',
    chest_swamp_name: 'Скользкий сундук',
    chest_swamp_desc:
      'Неприятный сундук, найденный в Шепчущей топи. Можно открыть скользким ключом.',
    chest_desert_name: 'Известняковый сундук',
    chest_desert_desc:
      'Аккуратный сундук, найденный в Безжизненной пустоши. Можно открыть известняковым ключом.',
    chest_pyramid_name: 'Древний сундук',
    chest_pyramid_desc:
      'Величественный сундук, найденный в Гробнице бога солнца. Можно открыть древним ключом.',
    chest_ruins_name: 'Потрепанный сундук',
    chest_ruins_desc:
      'Потрепанный временем сундук, найденный в руинах Кроноса. Можно открыть потрепанным ключом.',
    chest_castle_name: 'Сундук покинутых',
    chest_castle_desc:
      'Древний сундук, найденный в замке с привидениями. Можно открыть ключом покинутых.',
    chest_temple_name: 'Затонувший сундук',
    chest_temple_desc:
      'Пропитанный соленой водой сундук, найденный в Затонувшем храме. Можно открыть затонувшим ключом.',
    chest_peak_name: 'Унылый сундук',
    chest_peak_desc:
      'Ужасный сундук, найденный на пике Мрачный Шпиль. Можно открыть унылым ключом.',
    chest_volcano_name: 'Расплавленный сундук',
    chest_volcano_desc:
      'Раскаленный сундук, найденный в вулкане Пепельного озера. Можно открыть расплавленным ключом.',
    chest_rift_name: 'Сундук Пустоты',
    chest_rift_desc:
      'Внепространственный сундук, найденный в измерении Пустоты. Можно открыть ключом Пустоты.',
    chest_goldcity_name: 'Роскошный сундук',
    chest_goldcity_desc:
      'Крайне тяжелый сундук, найденный в Потерянном городе золота. Можно открыть роскошным ключом.',
    chest_goldcity2_name: 'Люксовый сундук',
    chest_goldcity2_desc:
      'Крайне тяжелый сундук, найденный в Потерянном городе золота. Можно открыть люксовым ключом.',
    chest_goldcity3_name: 'Платиновый сундук',
    chest_goldcity3_desc:
      'До абсурда тяжелый сундук, найденный в Потерянном городе золота. Можно открыть платиновым ключом.',
    key_forest_name: 'Деревянный ключ',
    key_forest_name_plural: 'Деревянные ключи',
    key_forest_desc:
      'Волшебный ключ, вырезанный из эльфийской древесины. Открыть деревянные сундуки можно только им.',
    key_grotto_name: 'Ледяной ключ',
    key_grotto_name_plural: 'Ледяные ключи',
    key_grotto_desc:
      'Волшебный ключ из вечного льда. Открыть замерзшие сундуки можно только им.',
    key_swamp_name: 'Скользкий ключ',
    key_swamp_name_plural: 'Скользкие ключи',
    key_swamp_desc:
      'Волшебный ключ, покрытый какой-то слизью. Открыть скользкие сундуки можно только им.',
    key_desert_name: 'Известняковый ключ',
    key_desert_name_plural: 'Известняковые ключи',
    key_desert_desc:
      'Неожиданно тяжелый волшебный ключ из чистого известняка. Открыть известняковые сундуки можно только им.',
    key_pyramid_name: 'Древний ключ',
    key_pyramid_name_plural: 'Древние ключи',
    key_pyramid_desc:
      'Волшебный ключ, который, возможно, проклят (но необязательно). Открыть древние сундуки можно только им.',
    key_ruins_name: 'Потрепанный ключ',
    key_ruins_name_plural: 'Потрепанные ключи',
    key_ruins_desc:
      'Волшебный ключ, окутанный магией времени. Открыть потрепанные сундуки можно только им.',
    key_castle_name: 'Ключ покинутых',
    key_castle_name_plural: 'Ключи покинутых',
    key_castle_desc:
      'Волшебный ключ, мерцающий от славы давних лет. Открыть сундуки покинутых можно только им.',
    key_temple_name: 'Затонувший ключ',
    key_temple_name_plural: 'Затонувшие ключи',
    key_temple_desc:
      'Волшебный ключ, из которого сочится соленая вода. Открыть затонувшие сундуки можно только им.',
    key_peak_name: 'Унылый ключ',
    key_peak_name_plural: 'Унылые ключи',
    key_peak_desc:
      'Волшебный ключ, источающий уныние. Открыть унылые сундуки можно только им.',
    key_volcano_name: 'Расплавленный ключ',
    key_volcano_name_plural: 'Расплавленные ключи',
    key_volcano_desc:
      'Волшебный ключ, пышущий жаром. Открыть расплавленные сундуки можно только им.',
    key_rift_name: 'Ключ Пустоты',
    key_rift_name_plural: 'Ключи Пустоты',
    key_rift_desc:
      'Волшебный ключ, который, кажется, существует между измерениями. Открыть сундуки Пустоты можно только им.',
    key_goldcity_name: 'Роскошный ключ',
    key_goldcity_name_plural: 'Роскошные ключи',
    key_goldcity_desc:
      'Волшебный ключ из чистого золота. Открыть роскошные сундуки можно только им.',
    key_goldcity2_name: 'Люксовый ключ',
    key_goldcity2_name_plural: 'Люксовые ключи',
    key_goldcity2_desc:
      'Волшебный ключ из чистого золота. Открыть люксовые сундуки можно только им.',
    key_goldcity3_name: 'Платиновый ключ',
    key_goldcity3_name_plural: 'Платиновые ключи',
    key_goldcity3_desc:
      'Волшебный ключ из чистой платины. Открыть платиновые сундуки можно только им.',
    token_argon_name: 'Монета Аргона',
    token_argon_name_plural: 'Монеты Аргона',
    token_argon_desc:
      'Неистовая решимость, сосредоточенная в монете. Собирай эти монеты, чтобы сделать Аргона сильнее.',
    token_lilu_name: 'Монета Лилу',
    token_lilu_name_plural: 'Монеты Лилу',
    token_lilu_desc:
      'Неутолимое любопытство, сосредоточенное в монете. Собирай эти монеты, чтобы сделать Лилу сильнее.',
    token_sia_name: 'Монета Сиа',
    token_sia_name_plural: 'Монеты Сиа',
    token_sia_desc:
      'Аура таинственности, сосредоточенная в монете. Собирай эти монеты, чтобы сделать Сиа сильнее.',
    token_yami_name: 'Монета Ями',
    token_yami_name_plural: 'Монеты Ями',
    token_yami_desc:
      'Хладнокровная мстительность, сосредоточенная в монете. Собирай эти монеты, чтобы сделать Ями сильнее.',
    token_rudo_name: 'Монета Рудо',
    token_rudo_name_plural: 'Монеты Рудо',
    token_rudo_desc:
      'Безжалостная свирепость, сосредоточенная в монете. Собирай эти монеты, чтобы сделать Рудо сильнее.',
    token_polonia_name: 'Монета Полонии',
    token_polonia_name_plural: 'Монеты Полонии',
    token_polonia_desc:
      'Жажда приключений, сосредоточенная в монете. Собирай эти монеты, чтобы сделать Полонию сильнее.',
    token_donovan_name: 'Монета Донована',
    token_donovan_name_plural: 'Монеты Донована',
    token_donovan_desc:
      'Нестареющие знания, сосредоточенные в монете. Собирай эти монеты, чтобы сделать Донована сильнее.',
    token_hemma_name: 'Монета Хеммы',
    token_hemma_name_plural: 'Монеты Хеммы',
    token_hemma_desc:
      'Нестареющие знания, сосредоточенные в монете. Собирай эти монеты, чтобы сделать Хемму сильнее.',
    token_ashley_name: 'Монета Эшли',
    token_ashley_name_plural: 'Монеты Эшли',
    token_ashley_desc:
      'Непоколебимая воля, сосредоточенная в монете. Собирай эти монеты, чтобы сделать Эшли сильнее.',
    token_atlab_aang_name: 'Монета Аанга',
    token_atlab_aang_name_plural: 'Монеты Аанга',
    token_atlab_aang_desc:
      'Сила всех четырех стихий, сосредоточенная в монете. Собирайте эти монеты, чтобы сделать гостевого защитника Аанга сильнее.',
    token_atlab_sokka_name: 'Монета Сокки',
    token_atlab_sokka_name_plural: 'Монеты Сокки',
    token_atlab_sokka_desc:
      'Сарказм и изобретательность, сосредоточенные в монете. Собирайте эти монеты, чтобы сделать гостевого защитника Сокку сильнее.',
    token_leather_name: 'Монета Тамаса',
    token_leather_name_plural: 'Монеты Тамаса',
    token_leather_desc:
      'Грубость и простота Тамаса, сосредоточенные в монете. Собирайте эти монеты, чтобы сделать гостевого защитника Тамаса сильнее.',
    token_king_name: 'Монета Рейнгольда',
    token_king_name_plural: 'Монеты Рейнгольда',
    token_king_desc:
      'Непревзойденное величие, сосредоточенное в монете. Собирайте эти монеты, чтобы сделать гостевого защитника Рейнгольда сильнее.',
    ascension_upgrade_ws_00: 'Приверженец меча',
    ascension_upgrade_ws_03: 'Дилетант меча',
    ascension_upgrade_ws_05: 'Новичок меча',
    ascension_upgrade_ws_07: 'Адепт меча',
    ascension_upgrade_ws_09: 'Специалист меча',
    ascension_upgrade_ws_11: 'Знаток меча',
    ascension_upgrade_ws_13: 'Эксперт меча',
    ascension_upgrade_ws_15: 'Босс меча',
    ascension_upgrade_ws_17: 'Профи меча',
    ascension_upgrade_ws_19: 'Ас меча',
    ascension_upgrade_ws_21: 'Принц меча',
    ascension_upgrade_ws_23: 'Мастер меча',
    ascension_upgrade_ws_25: 'Король меча',
    ascension_upgrade_ws_27: 'Гроссмейстер меча',
    ascension_upgrade_ws_29: 'Легенда меча',
    ascension_upgrade_ws_31: 'Титан меча',
    ascension_upgrade_wa_00: 'Приверженец топора',
    ascension_upgrade_wa_03: 'Дилетант топора',
    ascension_upgrade_wa_05: 'Новичок топора',
    ascension_upgrade_wa_07: 'Адепт топора',
    ascension_upgrade_wa_09: 'Специалист топора',
    ascension_upgrade_wa_11: 'Знаток топора',
    ascension_upgrade_wa_13: 'Эксперт топора',
    ascension_upgrade_wa_15: 'Босс топора',
    ascension_upgrade_wa_17: 'Профи топора',
    ascension_upgrade_wa_19: 'Ас топора',
    ascension_upgrade_wa_21: 'Принц топора',
    ascension_upgrade_wa_23: 'Мастер топора',
    ascension_upgrade_wa_25: 'Король топора',
    ascension_upgrade_wa_27: 'Гроссмейстер топора',
    ascension_upgrade_wa_29: 'Легенда топора',
    ascension_upgrade_wa_31: 'Титан топора',
    ascension_upgrade_wd_00: 'Приверженец кинжала',
    ascension_upgrade_wd_03: 'Дилетант кинжала',
    ascension_upgrade_wd_05: 'Новичок кинжала',
    ascension_upgrade_wd_07: 'Адепт кинжала',
    ascension_upgrade_wd_09: 'Спец по кинжалам',
    ascension_upgrade_wd_11: 'Знаток кинжала',
    ascension_upgrade_wd_13: 'Эксперт кинжала',
    ascension_upgrade_wd_15: 'Босс кинжала',
    ascension_upgrade_wd_17: 'Профи кинжала',
    ascension_upgrade_wd_19: 'Ас кинжала',
    ascension_upgrade_wd_21: 'Принц кинжала',
    ascension_upgrade_wd_23: 'Мастер кинжала',
    ascension_upgrade_wd_25: 'Король кинжала',
    ascension_upgrade_wd_27: 'Гроссмейстер кинжала',
    ascension_upgrade_wd_29: 'Легенда кинжала',
    ascension_upgrade_wd_31: 'Титан кинжала',
    ascension_upgrade_wm_00: 'Приверженец булавы',
    ascension_upgrade_wm_03: 'Дилетант булавы',
    ascension_upgrade_wm_05: 'Новичок булавы',
    ascension_upgrade_wm_07: 'Адепт булавы',
    ascension_upgrade_wm_09: 'Специалист булавы',
    ascension_upgrade_wm_11: 'Знаток булавы',
    ascension_upgrade_wm_13: 'Эксперт булавы',
    ascension_upgrade_wm_15: 'Босс булавы',
    ascension_upgrade_wm_17: 'Профи булавы',
    ascension_upgrade_wm_19: 'Ас булавы',
    ascension_upgrade_wm_21: 'Принц булавы',
    ascension_upgrade_wm_23: 'Мастер булавы',
    ascension_upgrade_wm_25: 'Король булавы',
    ascension_upgrade_wm_27: 'Гроссмейстер булавы',
    ascension_upgrade_wm_29: 'Легенда булавы',
    ascension_upgrade_wm_31: 'Титан булавы',
    ascension_upgrade_wp_00: 'Приверженец копья',
    ascension_upgrade_wp_03: 'Дилетант копья',
    ascension_upgrade_wp_05: 'Новичок копья',
    ascension_upgrade_wp_07: 'Адепт копья',
    ascension_upgrade_wp_09: 'Специалист копья',
    ascension_upgrade_wp_11: 'Знаток копья',
    ascension_upgrade_wp_13: 'Эксперт копья',
    ascension_upgrade_wp_15: 'Босс копья',
    ascension_upgrade_wp_17: 'Профи копья',
    ascension_upgrade_wp_19: 'Ас копья',
    ascension_upgrade_wp_21: 'Принц копья',
    ascension_upgrade_wp_23: 'Мастер копья',
    ascension_upgrade_wp_25: 'Король копья',
    ascension_upgrade_wp_27: 'Гроссмейстер копья',
    ascension_upgrade_wp_29: 'Легенда копья',
    ascension_upgrade_wp_31: 'Титан копья',
    ascension_upgrade_wb_00: 'Приверженец лука',
    ascension_upgrade_wb_03: 'Дилетант лука',
    ascension_upgrade_wb_05: 'Новичок лука',
    ascension_upgrade_wb_07: 'Адепт лука',
    ascension_upgrade_wb_09: 'Специалист лука',
    ascension_upgrade_wb_11: 'Знаток лука',
    ascension_upgrade_wb_13: 'Эксперт лука',
    ascension_upgrade_wb_15: 'Босс лука',
    ascension_upgrade_wb_17: 'Профи лука',
    ascension_upgrade_wb_19: 'Ас лука',
    ascension_upgrade_wb_21: 'Принц лука',
    ascension_upgrade_wb_23: 'Мастер лука',
    ascension_upgrade_wb_25: 'Король лука',
    ascension_upgrade_wb_27: 'Гроссмейстер лука',
    ascension_upgrade_wb_29: 'Легенда лука',
    ascension_upgrade_wb_31: 'Титан лука',
    ascension_upgrade_wt_00: 'Приверженец посоха',
    ascension_upgrade_wt_03: 'Дилетант посоха',
    ascension_upgrade_wt_05: 'Новичок посоха',
    ascension_upgrade_wt_07: 'Адепт посоха',
    ascension_upgrade_wt_09: 'Специалист посоха',
    ascension_upgrade_wt_11: 'Знаток посоха',
    ascension_upgrade_wt_13: 'Эксперт посоха',
    ascension_upgrade_wt_15: 'Босс посоха',
    ascension_upgrade_wt_17: 'Профи посоха',
    ascension_upgrade_wt_19: 'Ас посоха',
    ascension_upgrade_wt_21: 'Принц посоха',
    ascension_upgrade_wt_23: 'Мастер посоха',
    ascension_upgrade_wt_25: 'Король посоха',
    ascension_upgrade_wt_27: 'Гроссмейстер посоха',
    ascension_upgrade_wt_29: 'Легенда посоха',
    ascension_upgrade_wt_31: 'Титан посоха',
    ascension_upgrade_ww_00: 'Приверженец палочки',
    ascension_upgrade_ww_03: 'Дилетант палочки',
    ascension_upgrade_ww_05: 'Новичок палочки',
    ascension_upgrade_ww_07: 'Адепт палочки',
    ascension_upgrade_ww_09: 'Специалист палочки',
    ascension_upgrade_ww_11: 'Знаток палочки',
    ascension_upgrade_ww_13: 'Эксперт палочки',
    ascension_upgrade_ww_15: 'Босс палочки',
    ascension_upgrade_ww_17: 'Профи палочки',
    ascension_upgrade_ww_19: 'Ас палочки',
    ascension_upgrade_ww_21: 'Принц палочки',
    ascension_upgrade_ww_23: 'Мастер палочки',
    ascension_upgrade_ww_25: 'Король палочки',
    ascension_upgrade_ww_27: 'Гроссмейстер палочки',
    ascension_upgrade_ww_29: 'Легенда палочки',
    ascension_upgrade_ww_31: 'Титан палочки',
    ascension_upgrade_wc_00: 'Приверженец арбалета',
    ascension_upgrade_wc_03: 'Дилетант арбалета',
    ascension_upgrade_wc_05: 'Новичок арбалета',
    ascension_upgrade_wc_07: 'Адепт арбалета',
    ascension_upgrade_wc_09: 'Специалист арбалета',
    ascension_upgrade_wc_11: 'Знаток арбалета',
    ascension_upgrade_wc_13: 'Эксперт арбалета',
    ascension_upgrade_wc_15: 'Босс арбалета',
    ascension_upgrade_wc_17: 'Профи арбалета',
    ascension_upgrade_wc_19: 'Ас арбалета',
    ascension_upgrade_wc_21: 'Принц арбалета',
    ascension_upgrade_wc_23: 'Мастер арбалета',
    ascension_upgrade_wc_25: 'Король арбалета',
    ascension_upgrade_wc_27: 'Гроссмейстер арбалета',
    ascension_upgrade_wc_29: 'Легенда арбалета',
    ascension_upgrade_wc_31: 'Титан арбалета',
    ascension_upgrade_wg_00: 'Приверженец ружья',
    ascension_upgrade_wg_03: 'Дилетант ружья',
    ascension_upgrade_wg_05: 'Новичок ружья',
    ascension_upgrade_wg_07: 'Адепт ружья',
    ascension_upgrade_wg_09: 'Специалист ружья',
    ascension_upgrade_wg_11: 'Знаток ружья',
    ascension_upgrade_wg_13: 'Эксперт ружья',
    ascension_upgrade_wg_15: 'Босс ружья',
    ascension_upgrade_wg_17: 'Профи ружья',
    ascension_upgrade_wg_19: 'Ас ружья',
    ascension_upgrade_wg_21: 'Принц ружья',
    ascension_upgrade_wg_23: 'Мастер ружья',
    ascension_upgrade_wg_25: 'Король ружья',
    ascension_upgrade_wg_27: 'Гроссмейстер ружья',
    ascension_upgrade_wg_29: 'Легенда ружья',
    ascension_upgrade_wg_31: 'Титан ружья',
    ascension_upgrade_uh_00: 'Приверженец лекарств',
    ascension_upgrade_uh_03: 'Дилетант лекарств',
    ascension_upgrade_uh_05: 'Новичок лекарств',
    ascension_upgrade_uh_07: 'Адепт лекарств',
    ascension_upgrade_uh_09: 'Специалист лекарств',
    ascension_upgrade_uh_11: 'Знаток лекарств',
    ascension_upgrade_uh_13: 'Эксперт лекарств',
    ascension_upgrade_uh_15: 'Босс лекарств',
    ascension_upgrade_uh_17: 'Профи лекарств',
    ascension_upgrade_uh_19: 'Ас лекарств',
    ascension_upgrade_uh_21: 'Принц лекарств',
    ascension_upgrade_uh_23: 'Мастер лекарств',
    ascension_upgrade_uh_25: 'Король лекарств',
    ascension_upgrade_uh_27: 'Гроссмейстер лекарств',
    ascension_upgrade_uh_29: 'Легенда лекарств',
    ascension_upgrade_uh_31: 'Титан лекарств',
    ascension_upgrade_up_00: 'Приверженец зелий',
    ascension_upgrade_up_03: 'Дилетант зелий',
    ascension_upgrade_up_05: 'Новичок зелий',
    ascension_upgrade_up_07: 'Адепт зелий',
    ascension_upgrade_up_09: 'Специалист зелий',
    ascension_upgrade_up_11: 'Знаток зелий',
    ascension_upgrade_up_13: 'Эксперт зелий',
    ascension_upgrade_up_15: 'Босс зелий',
    ascension_upgrade_up_17: 'Профи зелий',
    ascension_upgrade_up_19: 'Ас зелий',
    ascension_upgrade_up_21: 'Принц зелий',
    ascension_upgrade_up_23: 'Мастер зелий',
    ascension_upgrade_up_25: 'Король зелий',
    ascension_upgrade_up_27: 'Гроссмейстер зелий',
    ascension_upgrade_up_29: 'Легенда зелий',
    ascension_upgrade_up_31: 'Титан зелий',
    ascension_upgrade_us_00: 'Приверженец заклинаний',
    ascension_upgrade_us_03: 'Дилетант заклинаний',
    ascension_upgrade_us_05: 'Новичок заклинаний',
    ascension_upgrade_us_07: 'Адепт заклинаний',
    ascension_upgrade_us_09: 'Специалист заклинаний',
    ascension_upgrade_us_11: 'Знаток заклинаний',
    ascension_upgrade_us_13: 'Эксперт заклинаний',
    ascension_upgrade_us_15: 'Босс заклинаний',
    ascension_upgrade_us_17: 'Профи заклинаний',
    ascension_upgrade_us_19: 'Ас заклинаний',
    ascension_upgrade_us_21: 'Принц заклинаний',
    ascension_upgrade_us_23: 'Мастер заклинаний',
    ascension_upgrade_us_25: 'Король заклинаний',
    ascension_upgrade_us_27: 'Гроссмейстер заклинаний',
    ascension_upgrade_us_29: 'Легенда заклинаний',
    ascension_upgrade_us_31: 'Титан заклинаний',
    ascension_upgrade_ah_00: 'Приверженец тяжелых доспехов',
    ascension_upgrade_ah_03: 'Дилетант тяжелых доспехов',
    ascension_upgrade_ah_05: 'Новичок тяжелых доспехов',
    ascension_upgrade_ah_07: 'Адепт тяжелых доспехов',
    ascension_upgrade_ah_09: 'Специалист тяжелых доспехов',
    ascension_upgrade_ah_11: 'Знаток тяжелых доспехов',
    ascension_upgrade_ah_13: 'Эксперт тяжелых доспехов',
    ascension_upgrade_ah_15: 'Босс тяжелых доспехов',
    ascension_upgrade_ah_17: 'Профи тяжелых доспехов',
    ascension_upgrade_ah_19: 'Ас тяжелых доспехов',
    ascension_upgrade_ah_21: 'Принц тяжелых доспехов',
    ascension_upgrade_ah_23: 'Мастер тяжелых доспехов',
    ascension_upgrade_ah_25: 'Король тяжелых доспехов',
    ascension_upgrade_ah_27: 'Гроссмейстер тяжелых доспехов',
    ascension_upgrade_ah_29: 'Легенда тяжелых доспехов',
    ascension_upgrade_ah_31: 'Титан тяжелых доспехов',
    ascension_upgrade_am_00: 'Приверженец легких доспехов',
    ascension_upgrade_am_03: 'Дилетант легких доспехов',
    ascension_upgrade_am_05: 'Новичок легких доспехов',
    ascension_upgrade_am_07: 'Адепт легких доспехов',
    ascension_upgrade_am_09: 'Специалист легких доспехов',
    ascension_upgrade_am_11: 'Знаток легких доспехов',
    ascension_upgrade_am_13: 'Эксперт легких доспехов',
    ascension_upgrade_am_15: 'Босс легких доспехов',
    ascension_upgrade_am_17: 'Профи легких доспехов',
    ascension_upgrade_am_19: 'Ас легких доспехов',
    ascension_upgrade_am_21: 'Принц легких доспехов',
    ascension_upgrade_am_23: 'Мастер легких доспехов',
    ascension_upgrade_am_25: 'Король легких доспехов',
    ascension_upgrade_am_27: 'Гроссмейстер легких доспехов',
    ascension_upgrade_am_29: 'Легенда легких доспехов',
    ascension_upgrade_am_31: 'Титан легких доспехов',
    ascension_upgrade_al_00: 'Приверженец одежды',
    ascension_upgrade_al_03: 'Дилетант одежды',
    ascension_upgrade_al_05: 'Новичок одежды',
    ascension_upgrade_al_07: 'Адепт одежды',
    ascension_upgrade_al_09: 'Специалист одежды',
    ascension_upgrade_al_11: 'Знаток одежды',
    ascension_upgrade_al_13: 'Эксперт одежды',
    ascension_upgrade_al_15: 'Босс одежды',
    ascension_upgrade_al_17: 'Профи одежды',
    ascension_upgrade_al_19: 'Ас одежды',
    ascension_upgrade_al_21: 'Принц одежды',
    ascension_upgrade_al_23: 'Мастер одежды',
    ascension_upgrade_al_25: 'Король одежды',
    ascension_upgrade_al_27: 'Гроссмейстер одежды',
    ascension_upgrade_al_29: 'Легенда одежды',
    ascension_upgrade_al_31: 'Титан одежды',
    ascension_upgrade_hh_00: 'Приверженец шлемов',
    ascension_upgrade_hh_03: 'Дилетант шлемов',
    ascension_upgrade_hh_05: 'Новичок шлемов',
    ascension_upgrade_hh_07: 'Адепт шлемов',
    ascension_upgrade_hh_09: 'Специалист шлемов',
    ascension_upgrade_hh_11: 'Знаток шлемов',
    ascension_upgrade_hh_13: 'Эксперт шлемов',
    ascension_upgrade_hh_15: 'Босс шлемов',
    ascension_upgrade_hh_17: 'Профи шлемов',
    ascension_upgrade_hh_19: 'Ас шлемов',
    ascension_upgrade_hh_21: 'Принц шлемов',
    ascension_upgrade_hh_23: 'Мастер шлемов',
    ascension_upgrade_hh_25: 'Король шлемов',
    ascension_upgrade_hh_27: 'Гроссмейстер шлемов',
    ascension_upgrade_hh_29: 'Легенда шлемов',
    ascension_upgrade_hh_31: 'Титан шлемов',
    ascension_upgrade_hm_00: 'Приверженец шапок странника',
    ascension_upgrade_hm_03: 'Дилетант шапок странника',
    ascension_upgrade_hm_05: 'Новичок шапок странника',
    ascension_upgrade_hm_07: 'Адепт шапок странника',
    ascension_upgrade_hm_09: 'Специалист шапок странника',
    ascension_upgrade_hm_11: 'Знаток шапок странника',
    ascension_upgrade_hm_13: 'Эксперт шапок странника',
    ascension_upgrade_hm_15: 'Босс шапок странника',
    ascension_upgrade_hm_17: 'Профи шапок странника',
    ascension_upgrade_hm_19: 'Ас шапок странника',
    ascension_upgrade_hm_21: 'Принц шапок странника',
    ascension_upgrade_hm_23: 'Мастер шапок странника',
    ascension_upgrade_hm_25: 'Король шапок странника',
    ascension_upgrade_hm_27: 'Гроссмейстер шапок странника',
    ascension_upgrade_hm_29: 'Легенда шапок странника',
    ascension_upgrade_hm_31: 'Титан шапок странника',
    ascension_upgrade_hl_00: 'Приверженец шляп мага',
    ascension_upgrade_hl_03: 'Дилетант шляп мага',
    ascension_upgrade_hl_05: 'Новичок шляп мага',
    ascension_upgrade_hl_07: 'Адепт шляп мага',
    ascension_upgrade_hl_09: 'Специалист шляп мага',
    ascension_upgrade_hl_11: 'Знаток шляп мага',
    ascension_upgrade_hl_13: 'Эксперт шляп мага',
    ascension_upgrade_hl_15: 'Босс шляп мага',
    ascension_upgrade_hl_17: 'Профи шляп мага',
    ascension_upgrade_hl_19: 'Ас шляп мага',
    ascension_upgrade_hl_21: 'Принц шляп мага',
    ascension_upgrade_hl_23: 'Мастер шляп мага',
    ascension_upgrade_hl_25: 'Король шляп мага',
    ascension_upgrade_hl_27: 'Гроссмейстер шляп мага',
    ascension_upgrade_hl_29: 'Легенда шляп мага',
    ascension_upgrade_hl_31: 'Титан шляп мага',
    ascension_upgrade_gh_00: 'Приверженец рукавиц',
    ascension_upgrade_gh_03: 'Дилетант рукавиц',
    ascension_upgrade_gh_05: 'Новичок рукавиц',
    ascension_upgrade_gh_07: 'Адепт рукавиц',
    ascension_upgrade_gh_09: 'Специалист рукавиц',
    ascension_upgrade_gh_11: 'Знаток рукавиц',
    ascension_upgrade_gh_13: 'Эксперт рукавиц',
    ascension_upgrade_gh_15: 'Босс рукавиц',
    ascension_upgrade_gh_17: 'Профи рукавиц',
    ascension_upgrade_gh_19: 'Ас рукавиц',
    ascension_upgrade_gh_21: 'Принц рукавиц',
    ascension_upgrade_gh_23: 'Мастер рукавиц',
    ascension_upgrade_gh_25: 'Король рукавиц',
    ascension_upgrade_gh_27: 'Гроссмейстер рукавиц',
    ascension_upgrade_gh_29: 'Легенда рукавиц',
    ascension_upgrade_gh_31: 'Титан рукавиц',
    ascension_upgrade_gl_00: 'Приверженец перчаток',
    ascension_upgrade_gl_03: 'Дилетант перчаток',
    ascension_upgrade_gl_05: 'Новичок перчаток',
    ascension_upgrade_gl_07: 'Адепт перчаток',
    ascension_upgrade_gl_09: 'Специалист перчаток',
    ascension_upgrade_gl_11: 'Знаток перчаток',
    ascension_upgrade_gl_13: 'Эксперт перчаток',
    ascension_upgrade_gl_15: 'Босс перчаток',
    ascension_upgrade_gl_17: 'Профи перчаток',
    ascension_upgrade_gl_19: 'Ас перчаток',
    ascension_upgrade_gl_21: 'Принц перчаток',
    ascension_upgrade_gl_23: 'Мастер перчаток',
    ascension_upgrade_gl_25: 'Король перчаток',
    ascension_upgrade_gl_27: 'Гроссмейстер перчаток',
    ascension_upgrade_gl_29: 'Легенда перчаток',
    ascension_upgrade_gl_31: 'Титан перчаток',
    ascension_upgrade_bh_00: 'Приверженец сапог',
    ascension_upgrade_bh_03: 'Дилетант сапог',
    ascension_upgrade_bh_05: 'Новичок сапог',
    ascension_upgrade_bh_07: 'Адепт сапог',
    ascension_upgrade_bh_09: 'Специалист сапог',
    ascension_upgrade_bh_11: 'Знаток сапог',
    ascension_upgrade_bh_13: 'Эксперт сапог',
    ascension_upgrade_bh_15: 'Босс сапог',
    ascension_upgrade_bh_17: 'Профи сапог',
    ascension_upgrade_bh_19: 'Ас сапог',
    ascension_upgrade_bh_21: 'Принц сапог',
    ascension_upgrade_bh_23: 'Мастер сапог',
    ascension_upgrade_bh_25: 'Король сапог',
    ascension_upgrade_bh_27: 'Гроссмейстер сапог',
    ascension_upgrade_bh_29: 'Легенда сапог',
    ascension_upgrade_bh_31: 'Титан сапог',
    ascension_upgrade_bl_00: 'Приверженец ботинок',
    ascension_upgrade_bl_03: 'Дилетант ботинок',
    ascension_upgrade_bl_05: 'Новичок ботинок',
    ascension_upgrade_bl_07: 'Адепт ботинок',
    ascension_upgrade_bl_09: 'Специалист ботинок',
    ascension_upgrade_bl_11: 'Знаток ботинок',
    ascension_upgrade_bl_13: 'Эксперт ботинок',
    ascension_upgrade_bl_15: 'Босс ботинок',
    ascension_upgrade_bl_17: 'Профи ботинок',
    ascension_upgrade_bl_19: 'Ас ботинок',
    ascension_upgrade_bl_21: 'Принц ботинок',
    ascension_upgrade_bl_23: 'Мастер ботинок',
    ascension_upgrade_bl_25: 'Король ботинок',
    ascension_upgrade_bl_27: 'Гроссмейстер ботинок',
    ascension_upgrade_bl_29: 'Легенда ботинок',
    ascension_upgrade_bl_31: 'Титан ботинок',
    ascension_upgrade_xs_00: 'Приверженец щита',
    ascension_upgrade_xs_03: 'Дилетант щита',
    ascension_upgrade_xs_05: 'Новичок щита',
    ascension_upgrade_xs_07: 'Адепт щита',
    ascension_upgrade_xs_09: 'Специалист щита',
    ascension_upgrade_xs_11: 'Знаток щита',
    ascension_upgrade_xs_13: 'Эксперт щита',
    ascension_upgrade_xs_15: 'Босс щита',
    ascension_upgrade_xs_17: 'Профи щита',
    ascension_upgrade_xs_19: 'Ас щита',
    ascension_upgrade_xs_21: 'Принц щита',
    ascension_upgrade_xs_23: 'Мастер щита',
    ascension_upgrade_xs_25: 'Король щита',
    ascension_upgrade_xs_27: 'Гроссмейстер щита',
    ascension_upgrade_xs_29: 'Легенда щита',
    ascension_upgrade_xs_31: 'Титан щита',
    ascension_upgrade_xc_00: 'Приверженец плаща',
    ascension_upgrade_xc_03: 'Дилетант плаща',
    ascension_upgrade_xc_05: 'Новичок плаща',
    ascension_upgrade_xc_07: 'Адепт плаща',
    ascension_upgrade_xc_09: 'Специалист плаща',
    ascension_upgrade_xc_11: 'Знаток плаща',
    ascension_upgrade_xc_13: 'Эксперт плаща',
    ascension_upgrade_xc_15: 'Босс плаща',
    ascension_upgrade_xc_17: 'Профи плаща',
    ascension_upgrade_xc_19: 'Ас плаща',
    ascension_upgrade_xc_21: 'Принц плаща',
    ascension_upgrade_xc_23: 'Мастер плаща',
    ascension_upgrade_xc_25: 'Король плаща',
    ascension_upgrade_xc_27: 'Гроссмейстер плаща',
    ascension_upgrade_xc_29: 'Легенда плаща',
    ascension_upgrade_xc_31: 'Титан плаща',
    ascension_upgrade_xr_00: 'Приверженец колец',
    ascension_upgrade_xr_03: 'Дилетант колец',
    ascension_upgrade_xr_05: 'Новичок колец',
    ascension_upgrade_xr_07: 'Адепт колец',
    ascension_upgrade_xr_09: 'Специалист колец',
    ascension_upgrade_xr_11: 'Знаток колец',
    ascension_upgrade_xr_13: 'Эксперт колец',
    ascension_upgrade_xr_15: 'Босс колец',
    ascension_upgrade_xr_17: 'Профи колец',
    ascension_upgrade_xr_19: 'Ас колец',
    ascension_upgrade_xr_21: 'Принц колец',
    ascension_upgrade_xr_23: 'Мастер колец',
    ascension_upgrade_xr_25: 'Король колец',
    ascension_upgrade_xr_27: 'Гроссмейстер колец',
    ascension_upgrade_xr_29: 'Легенда колец',
    ascension_upgrade_xr_31: 'Титан колец',
    ascension_upgrade_xa_00: 'Приверженец амулетов',
    ascension_upgrade_xa_03: 'Дилетант амулетов',
    ascension_upgrade_xa_05: 'Новичок амулетов',
    ascension_upgrade_xa_07: 'Адепт амулетов',
    ascension_upgrade_xa_09: 'Специалист амулетов',
    ascension_upgrade_xa_11: 'Знаток амулетов',
    ascension_upgrade_xa_13: 'Эксперт амулетов',
    ascension_upgrade_xa_15: 'Босс амулетов',
    ascension_upgrade_xa_17: 'Профи амулетов',
    ascension_upgrade_xa_19: 'Ас амулетов',
    ascension_upgrade_xa_21: 'Принц амулетов',
    ascension_upgrade_xa_23: 'Мастер амулетов',
    ascension_upgrade_xa_25: 'Король амулетов',
    ascension_upgrade_xa_27: 'Гроссмейстер амулетов',
    ascension_upgrade_xa_29: 'Легенда амулетов',
    ascension_upgrade_xa_31: 'Титан амулетов',
    ascension_upgrade_xf_00: 'Новичок фамильяров',
    ascension_upgrade_xf_03: 'Адепт фамильяров',
    ascension_upgrade_xf_05: 'Специалист фамильяров',
    ascension_upgrade_xf_07: 'Знаток фамильяров',
    ascension_upgrade_xf_09: 'Эксперт фамильяров',
    ascension_upgrade_xf_11: 'Босс фамильяров',
    ascension_upgrade_xf_13: 'Профи фамильяров',
    ascension_upgrade_xf_15: 'Ас фамильяров',
    ascension_upgrade_xf_17: 'Принц фамильяров',
    ascension_upgrade_xf_19: 'Мастер фамильяров',
    ascension_upgrade_xf_21: 'Гроссмейстер фамильяров',
    ascension_upgrade_xf_23: 'Легенда фамильяров',
    ascension_upgrade_xf_25: 'Титан фамильяров',
    ascension_upgrade_fm_00: 'Приверженец блюд',
    ascension_upgrade_fm_03: 'Дилетант блюд',
    ascension_upgrade_fm_05: 'Новичок блюд',
    ascension_upgrade_fm_07: 'Адепт блюд',
    ascension_upgrade_fm_09: 'Специалист блюд',
    ascension_upgrade_fm_11: 'Знаток блюд',
    ascension_upgrade_fm_13: 'Эксперт блюд',
    ascension_upgrade_fm_15: 'Босс блюд',
    ascension_upgrade_fm_17: 'Профи блюд',
    ascension_upgrade_fm_19: 'Ас блюд',
    ascension_upgrade_fm_21: 'Принц блюд',
    ascension_upgrade_fm_23: 'Мастер блюд',
    ascension_upgrade_fm_25: 'Король блюд',
    ascension_upgrade_fm_27: 'Гроссмейстер блюд',
    ascension_upgrade_fm_29: 'Легенда блюд',
    ascension_upgrade_fm_31: 'Титан блюд',
    ascension_upgrade_fd_00: 'Приверженец десертов',
    ascension_upgrade_fd_03: 'Дилетант десертов',
    ascension_upgrade_fd_05: 'Новичок десертов',
    ascension_upgrade_fd_07: 'Адепт десертов',
    ascension_upgrade_fd_09: 'Специалист десертов',
    ascension_upgrade_fd_11: 'Знаток десертов',
    ascension_upgrade_fd_13: 'Эксперт десертов',
    ascension_upgrade_fd_15: 'Босс десертов',
    ascension_upgrade_fd_17: 'Профи десертов',
    ascension_upgrade_fd_19: 'Ас десертов',
    ascension_upgrade_fd_21: 'Принц десертов',
    ascension_upgrade_fd_23: 'Мастер десертов',
    ascension_upgrade_fd_25: 'Король десертов',
    ascension_upgrade_fd_27: 'Гроссмейстер десертов',
    ascension_upgrade_fd_29: 'Легенда десертов',
    ascension_upgrade_fd_31: 'Титан десертов',
    ascension_upgrade_stone_00: 'Дилетант камня',
    ascension_upgrade_stone_03: 'Новичок камня',
    ascension_upgrade_stone_05: 'Специалист камня',
    ascension_upgrade_stone_07: 'Профи камня',
    ascension_upgrade_stone_09: 'Мастер камня',
    ascension_upgrade_stone_11: 'Легенда камня',
    ascension_upgrade_stone_13: 'Титан камня',
    ascension_upgrade_z_2_00: 'Новичок стихий',
    ascension_upgrade_z_2_03: 'Адепт стихий',
    ascension_upgrade_z_2_05: 'Специалист стихий',
    ascension_upgrade_z_2_07: 'Знаток стихий',
    ascension_upgrade_z_2_09: 'Эксперт стихий',
    ascension_upgrade_z_2_11: 'Босс стихий',
    ascension_upgrade_z_2_13: 'Профи стихий',
    ascension_upgrade_z_2_15: 'Ас стихий',
    ascension_upgrade_z_2_17: 'Мастер стихий',
    ascension_upgrade_z_2_19: 'Гроссмейстер стихий',
    ascension_upgrade_z_2_21: 'Легенда стихий',
    ascension_upgrade_z_2_23: 'Титан стихий',
    ascension_upgrade_z_3_00: 'Новичок духов',
    ascension_upgrade_z_3_03: 'Адепт духов',
    ascension_upgrade_z_3_05: 'Специалист духов',
    ascension_upgrade_z_3_07: 'Знаток духов',
    ascension_upgrade_z_3_09: 'Эксперт духов',
    ascension_upgrade_z_3_11: 'Босс духов',
    ascension_upgrade_z_3_13: 'Профи духов',
    ascension_upgrade_z_3_15: 'Эксперт духов',
    ascension_upgrade_z_3_17: 'Мастер духов',
    ascension_upgrade_z_3_19: 'Гроссмейстер духов',
    ascension_upgrade_z_3_21: 'Легенда духов',
    ascension_upgrade_z_3_23: 'Титан духов',
    troblin_name: 'Гиблин',
    troblin_desc: 'Вечно нарывается на неприятности. Чего и следовало ожидать.',
    yeti_name: 'Йети',
    yeti_desc:
      'Местный специалист по лепке снеговиков. Однако его снежные ангелы не так хороши.',
    toadwitch_name: 'Жаба-ведьма',
    toadwitch_desc:
      'Не поддавайтесь на уловки и не целуйте ее, ведь ничего не случится. А еще она ужасно готовит.',
    mushgoon_name: 'Грибандит',
    mushgoon_desc:
      'Большой задира. Дерется со всеми, кто меньше его, а таких, честно говоря, не так уж и много.',
    anubis_name: 'Анубис',
    anubis_desc:
      'Ведите себя наилучшим образом рядом с ним. Ему судить — раз плюнуть.',
    cyclops_name: 'Циклоп',
    cyclops_desc:
      'В целом классный чувак. Имеет проблемы с восприятием глубины.',
    tyrantking_name: 'Тиран',
    tyrantking_desc:
      'Не уверен в себе из-за маленького роста. Не любит магниты.',
    mermaid_name: 'Русалка',
    mermaid_desc:
      'Большую часть дня нежится на солнышке. Солнечные ванны превыше всего!',
    harpy_name: 'Гарпия',
    harpy_desc:
      'Как она вообще умудряется летать — загадка. Любит все блестящее.',
    firecrab_name: 'Сокрушкраб',
    firecrab_desc:
      'Незаменимый приятель в холодные зимние месяцы. Приветствуется в кузницах по всему миру.',
    goldgolem_name: 'Люксовый голем',
    goldgolem_desc:
      'Сверкает как золото. Потому что действительно золотой. Тщательно поддерживает этот красивый блеск благодаря постоянному уходу.',
    voidling_name: 'Пустотыш',
    voidling_desc:
      'Если воспитывать этого монстра из другого мира с раннего возраста, он будет не против обнимашек!',
    troblin_argon_nickname: 'Гиббо',
    yeti_argon_nickname: 'Самородок',
    toadwitch_argon_nickname: 'Жаби',
    mushgoon_argon_nickname: 'Большой мальчик',
    anubis_argon_nickname: 'Густав II',
    cyclops_argon_nickname: 'Билл Найи',
    tyrantking_argon_nickname: 'Ремус',
    mermaid_argon_nickname: 'Лагуна',
    harpy_argon_nickname: 'Кондор',
    firecrab_argon_nickname: 'Хрустик',
    goldgolem_argon_nickname: 'Златор',
    voidling_argon_nickname: 'Куся',
    troblin_lilu_nickname: 'Грызун',
    yeti_lilu_nickname: 'Пушистик',
    toadwitch_lilu_nickname: 'Викка',
    mushgoon_lilu_nickname: 'Тейк',
    anubis_lilu_nickname: 'Варфоломей',
    cyclops_lilu_nickname: 'Милый пони',
    tyrantking_lilu_nickname: 'Гайст',
    mermaid_lilu_nickname: 'Крокет',
    harpy_lilu_nickname: 'Клю-Клю',
    firecrab_lilu_nickname: 'Отшельник',
    goldgolem_lilu_nickname: 'Блеск',
    voidling_lilu_nickname: 'Богомол',
    troblin_sia_nickname: 'Малыш',
    yeti_sia_nickname: 'Мистер Обнимашки',
    toadwitch_sia_nickname: 'Леди Батрасия',
    mushgoon_sia_nickname: 'Грибон Первый',
    anubis_sia_nickname: 'Лорд Трепас',
    cyclops_sia_nickname: 'Дестриэ',
    tyrantking_sia_nickname: 'Сэр Балфир',
    mermaid_sia_nickname: 'Королева Эльзасская',
    harpy_sia_nickname: 'Мисс Фланнери',
    firecrab_sia_nickname: 'Господин Бузотер',
    goldgolem_sia_nickname: 'Сияющий принц',
    voidling_sia_nickname: 'Гость',
    troblin_yami_nickname: 'Паразит',
    troblin_yami_nickname_r10: 'Они',
    yeti_yami_nickname: 'Шуба',
    yeti_yami_nickname_r10: 'Хибагон',
    toadwitch_yami_nickname: 'Слизь',
    toadwitch_yami_nickname_r10: 'Огама',
    mushgoon_yami_nickname: 'Тупая башка',
    mushgoon_yami_nickname_r10: 'Мацутакэ',
    anubis_yami_nickname: 'Дворняга',
    anubis_yami_nickname_r10: 'Инугами',
    cyclops_yami_nickname: 'Бремя',
    cyclops_yami_nickname_r10: 'Онихитокути',
    tyrantking_yami_nickname: 'Жестяная банка',
    tyrantking_yami_nickname_r10: 'Горё',
    mermaid_yami_nickname: 'Слизняк',
    mermaid_yami_nickname_r10: 'Акугё',
    harpy_yami_nickname: 'Птичьи мозги',
    harpy_yami_nickname_r10: 'Ятагарасу',
    firecrab_yami_nickname: 'Балласт',
    firecrab_yami_nickname_r10: 'Хэйкэгани',
    goldgolem_yami_nickname: 'Груда камней',
    goldgolem_yami_nickname_r10: 'Кинцуги',
    voidling_yami_nickname: 'Жуть',
    voidling_yami_nickname_r10: 'Энэнра',
    troblin_rudo_nickname: 'Джимми',
    yeti_rudo_nickname: 'Бигфут',
    toadwitch_rudo_nickname: 'Шанго',
    mushgoon_rudo_nickname: 'Младший',
    anubis_rudo_nickname: 'Боб',
    cyclops_rudo_nickname: 'Толстоклоп',
    tyrantking_rudo_nickname: 'Гробовщик',
    mermaid_rudo_nickname: 'Королева Крок',
    harpy_rudo_nickname: 'Робби',
    firecrab_rudo_nickname: 'Король Шмяк',
    goldgolem_rudo_nickname: 'Скала',
    voidling_rudo_nickname: 'Монстеро',
    troblin_polonia_nickname: 'Морячок',
    yeti_polonia_nickname: 'Морячок',
    toadwitch_polonia_nickname: 'Квакен',
    mushgoon_polonia_nickname: 'Перекус-забияка',
    anubis_polonia_nickname: 'Веселый Роджер',
    cyclops_polonia_nickname: 'Большой Слив',
    tyrantking_polonia_nickname: 'Галета',
    mermaid_polonia_nickname: 'Леди Джонс',
    harpy_polonia_nickname: 'Полли',
    firecrab_polonia_nickname: 'Балянус',
    goldgolem_polonia_nickname: 'Дублон',
    voidling_polonia_nickname: 'Левиафан',
    troblin_donovan_nickname: 'Зигснафф',
    yeti_donovan_nickname: 'Гнусь',
    toadwitch_donovan_nickname: 'Прыщиха',
    mushgoon_donovan_nickname: 'Лисичка',
    anubis_donovan_nickname: 'Шакал',
    cyclops_donovan_nickname: 'Полифем',
    tyrantking_donovan_nickname: 'Барон',
    mermaid_donovan_nickname: 'Атаргатис',
    harpy_donovan_nickname: 'Эриния',
    firecrab_donovan_nickname: 'Юбер',
    goldgolem_donovan_nickname: 'Мидас',
    voidling_donovan_nickname: 'Мизерион',
    troblin_hemma_nickname: 'Толстокож',
    yeti_hemma_nickname: 'Морозный скрытень',
    toadwitch_hemma_nickname: 'Антисглаз',
    mushgoon_hemma_nickname: 'Токсичный воин',
    anubis_hemma_nickname: 'Адская гончая',
    cyclops_hemma_nickname: 'Кошмар',
    tyrantking_hemma_nickname: 'Клод',
    mermaid_hemma_nickname: 'Хищница',
    harpy_hemma_nickname: 'Крона',
    firecrab_hemma_nickname: 'Жуткий ползун',
    goldgolem_hemma_nickname: 'Золотая угроза',
    voidling_hemma_nickname: 'Загадка',
    troblin_ashley_nickname: 'Хитрюга',
    yeti_ashley_nickname: 'Снежок',
    toadwitch_ashley_nickname: 'Матрона',
    mushgoon_ashley_nickname: 'Лихой',
    anubis_ashley_nickname: 'Бонни',
    cyclops_ashley_nickname: 'Досанко',
    tyrantking_ashley_nickname: 'Капитан',
    mermaid_ashley_nickname: 'Драконова',
    harpy_ashley_nickname: 'Воробушек',
    firecrab_ashley_nickname: 'Обсидиан',
    goldgolem_ashley_nickname: 'Суровый',
    voidling_ashley_nickname: 'Спектраль',
    troblin_bjorn_nickname: 'Клыкастик',
    yeti_bjorn_nickname: 'Боулинг',
    toadwitch_bjorn_nickname: 'Мерзоблиц',
    mushgoon_bjorn_nickname: 'Коротышка',
    anubis_bjorn_nickname: 'Легавый',
    cyclops_bjorn_nickname: 'Мустанг',
    tyrantking_bjorn_nickname: 'Владыка',
    mermaid_bjorn_nickname: 'Певунья',
    harpy_bjorn_nickname: 'Кругляшка',
    firecrab_bjorn_nickname: 'Бернард',
    goldgolem_bjorn_nickname: 'Пирит',
    voidling_bjorn_nickname: 'Фрик',
    troblin_leather_nickname: 'Дружище гоблин',
    yeti_leather_nickname: 'Лучший дружище',
    toadwitch_leather_nickname: 'Дружище лягушка',
    mushgoon_leather_nickname: 'Дружище гриб',
    anubis_leather_nickname: 'Дружище пес',
    cyclops_leather_nickname: 'Дружище конь',
    tyrantking_leather_nickname: 'Дружище призрак',
    mermaid_leather_nickname: 'Дружище крок',
    harpy_leather_nickname: 'Дружище птица',
    firecrab_leather_nickname: 'Дружище краб',
    goldgolem_leather_nickname: 'Золотой дружище',
    voidling_leather_nickname: 'Странный Дружище',
    troblin_king_nickname: 'Нищий',
    yeti_king_nickname: 'Нал',
    toadwitch_king_nickname: 'Мажор',
    mushgoon_king_nickname: 'Трюфель',
    anubis_king_nickname: 'Тутанхамонет',
    cyclops_king_nickname: 'Большой Бакс',
    tyrantking_king_nickname: 'Король Дзынь',
    mermaid_king_nickname: 'Жемчуг',
    harpy_king_nickname: 'Золотой гусь',
    firecrab_king_nickname: 'Краб-крохобор',
    goldgolem_king_nickname: 'Дорогой Мидас',
    voidling_king_nickname: 'Поглотитель Золота',
    powderkeg_no_tattoo_name: 'Пороховая бочка',
    powderkeg_no_tattoo_desc:
      'Вот что происходит, когда у Роксаны в руках оказывается чертеж булавы.',
    freedommace_no_tattoo_name: 'Шипастая дубинка',
    freedommace_no_tattoo_desc: 'Врежьте шипастым концом по неугодным.',
    christmascroll_no_tattoo_name: 'Список послушных и непослушных',
    christmascroll_no_tattoo_desc: 'Тебя включили в список послушных!',
    fireworksscroll_no_tattoo_name: 'Комплект фейерверков',
    fireworksscroll_no_tattoo_desc:
      'Магии здесь нет, но ощущение такое, будто есть!',
    vipscroll_no_tattoo_name: 'Свиток Фортуны',
    vipscroll_no_tattoo_desc:
      'Удача сопутствует смелым... и обладателю этого позолоченного пергамента.',
    waxtablet_no_tattoo_name: 'Имперский указ',
    waxtablet_no_tattoo_desc: 'Veni. Vidi. Vici.',
    batscroll_no_tattoo_name: 'Проклятие ночи',
    batscroll_no_tattoo_desc:
      'Какая ужасная ночь для того, чтобы быть проклятым.',
    freedomscroll_no_tattoo_name: 'Карта звездного света',
    freedomscroll_no_tattoo_desc:
      'Даже самым опытным звездным командирам время от времени требуются справочные материалы.',
    freedomplate_no_tattoo_name: 'Латы звездного света',
    freedomplate_no_tattoo_desc: 'Нагрудник звездных командиров.',
    eaglecowl_no_tattoo_name: 'Шлем орла',
    eaglecowl_no_tattoo_desc: 'Скрежет по нему похож на крик орла!',
    freedomboots_no_tattoo_name: 'Поножи звездного света',
    freedomboots_no_tattoo_desc: 'Перед вами лежит путь среди звезд.',
    freedomring_no_tattoo_name: 'Черный орел',
    freedomring_no_tattoo_desc:
      'Тайный знак среди звездных командиров-изгоев, обычно с позором уволенных после оглушительного провала.',
    schoolring2020_no_tattoo_name: 'Кольцо отличника',
    schoolring2020_no_tattoo_desc:
      'Награда, которую Эвелин весьма скупо выдает своим самым выдающимся ученикам.',
    newyearring_no_tattoo_name: 'Кольцо новых начинаний',
    newyearring_no_tattoo_desc: 'Итак, о ваших планах на прошлый год...',
    medal_no_tattoo_name: 'Медаль звездного света',
    medal_no_tattoo_desc:
      'Знак авторитета среди звездных командиров. Для его получения требуется исключительная доблесть на поле боя.',
    special_item_type_skilltreerefund: ' ',
    token_bjorn_name: 'Bjorn Coin',
    token_bjorn_name_plural: 'Bjorns Coins',
    token_bjorn_desc:
      'An unbreakable bond minted into a coin. Collect them to empower Bjorn.',
    skill_tree: 'Дерево талантов',
    skill_point_new: 'Новое очко талантов',
    player_level_up_detail_skilltree_title: 'Открыто дерево талантов',
    player_level_up_detail_skilltree_desc:
      'Вы открыли <color=#FFB72C>дерево талантов</color>! Перейти к нему можно из меню.',
    player_level_up_detail_skillpoint_title: 'Получено очко талантов',
    player_level_up_detail_skillpoint_desc:
      'Теперь можно купить новое очко талантов за <sprite name="Gold"><color=#FFB72C> золотых</color>!',
    skill_tree_node_locked_desc: 'Требуется {0} оч. талантов в смежных узлах.',
    skill_tree_upgrade_gold: 'Открыть за золото',
    skill_tree_upgrade_gems: 'Открыть за кристаллы',
    skill_start_node_progression_title: 'Прогресс на дереве',
    skill_tree_reset_branch_title: 'Сброс дерева',
    skill_tree_reset_branch_description_top:
      'Вы собираетесь вернуть очки на дереве талантов <color=#FFB72C>«{0}»</color>. Возвращенные очки талантов можно назначить бесплатно.',
    skill_tree_reset_branch_description_bot:
      'Очки на каждом дереве можно один раз вернуть бесплатно. За последующие возвращения очков придется заплатить кристаллами.',
    skill_tree_reset_branch_description_bot_alt: '',
    skill_tree_reset_branch_free_reset_button: 'Бесплатно (осталось {0})',
    skill_tree_reset_branch_free_reset_warning:
      'На этом дереве нет очков талантов.',
    skill_tree_bartering_tab: 'Торговля',
    skill_tree_questing_tab: 'Приключения',
    skill_tree_crafting_tab: 'Изготовление',
    skill_tree_info_popup_top_desc_title: 'Специализация',
    skill_tree_info_popup_top_description:
      'Откройте таланты на избранном дереве талантов и получите полезные способности, связанные с торговлей, приключениями и изготовлением.',
    skill_tree_info_popup_mid_desc_title: 'Повышение уровня',
    skill_tree_info_popup_mid_description:
      'На каждом уровне можно купить за золото одно очко талантов при максимуме в 60! Очки талантов также можно купить раньше за кристаллы.',
    skill_tree_info_popup_bot_desc_title: 'Возвращение очков',
    skill_tree_info_popup_bot_description:
      'Можно вернуть все очки талантов, назначенные на дерево, за кристаллы, а затем переназначить очки бесплатно.',
    skill_tree_main_menu_title: 'Дерево талантов',
    skill_tree_main_menu_description:
      'Покупайте особые таланты, чтобы специализироваться на предпочитаемом стиле игры!',
    skill_node_mastered: 'Освоено',
    skill_node_maxedout:
      'Все очки талантов назначены! Можно сбросить дерево талантов, чтобы переназначить их.',
    skill_start_node_bartering_title: 'Торговля',
    skill_start_node_bartering_desc:
      'Освойте искусство торговли и получайте максимум от каждого клиента!',
    skill_node_maxEnergy_title: 'Энергичность',
    skill_node_maxEnergy_desc:
      'Максимум энергии повышается на фиксированную величину.',
    skill_node_maxEnergy01_desc: 'Максимум энергии:',
    skill_node_sellEnergy_title: 'Харизматичность',
    skill_node_sellEnergy_desc: 'Каждая продажа дает дополнительную энергию.',
    skill_node_sellEnergy01_desc: 'Дополнительная энергия:',
    skill_node_npcVisitorDoubleSale_title: 'Удвоение или ничего',
    skill_node_npcVisitorDoubleSale_desc:
      'Есть шанс, что мастера города принесут вдвое больше предметов. Не применяется к мастерам по ресурсам!',
    skill_node_npcVisitorDoubleSale01_desc: 'Шанс удвоения комплектов:',
    skill_node_bonusWarrior_title: 'Военторг',
    skill_node_bonusWarrior_desc:
      'Повышает вероятность того, что клиенты будут воинами.',
    skill_node_bonusWarrior01_desc: 'Клиенты-воины:',
    skill_node_bonusRogue_title: 'Странники — это мы',
    skill_node_bonusRogue_desc:
      'Повышает вероятность того, что клиенты будут странниками.',
    skill_node_bonusRogue01_desc: 'Клиенты-странники:',
    skill_node_bonusMage_title: 'Заклинания, мантия и прочее',
    skill_node_bonusMage_desc:
      'Повышает вероятность того, что клиенты будут магами.',
    skill_node_bonusMage01_desc: 'Клиенты-маги:',
    skill_node_staminaRegen_title: 'Крепкий сон',
    skill_node_staminaRegen_desc:
      'Энергия пассивно восполняется, пока вы не в сети.',
    skill_node_staminaRegen01_desc: 'Выносливость в час:',
    skill_node_staminaIncrease_title: 'Гипер',
    skill_node_staminaIncrease_desc: 'Максимум энергии умножается!',
    skill_node_staminaIncrease01_desc: 'Максимум энергии:',
    skill_node_discountValue_title: 'Без учета налога',
    skill_node_discountValue_desc:
      'Товары со скидкой продаются по более высокой цене.',
    skill_node_discountValue01_desc: 'Бонус к стоимости:',
    skill_node_resourceWorkerQty_title: 'Доставка за день',
    skill_node_resourceWorkerQty_desc:
      'Посетители из числа мастеров по ресурсам приносят дополнительные ресурсы.',
    skill_node_resourceWorkerQty01_desc: 'Дополнительные ресурсы:',
    skill_node_tradeSlotCapacity_title: 'Груз с собой',
    skill_node_tradeSlotCapacity_desc:
      'Все торговые ячейки могут вмещать больше предметов.',
    skill_node_tradeSlotCapacity01_desc: 'Вместимость торговых ячеек:',
    skill_node_championRequestDemand_title: 'Дружеское расположение',
    skill_node_championRequestDemand_desc:
      'Защитники запрашивают меньше предметов в обмен на монеты защитника.',
    skill_node_championRequestDemand01_desc: 'Запрошенные предметы:',
    skill_node_championRequestDemand03_desc:
      'Защитники запрашивают только один предмет!',
    skill_node_bountyExtraTrophy_title: 'Бухгалтерские махинации',
    skill_node_bountyExtraTrophy_desc:
      'Выполненные поручения дают бонусные трофеи.',
    skill_node_bountyExtraTrophy01_desc: 'Бонусные трофеи:',
    skill_node_freeSurcharge_title: 'Лояльность к бренду',
    skill_node_freeSurcharge_desc:
      'Есть шанс, что клиенты сами заплатят больше, подойдя к прилавку.',
    skill_node_freeSurcharge01_desc: 'Шанс повышения цены:',
    skill_node_npcSellEnchanted_title: 'Лучшие из лучших',
    skill_node_npcSellEnchanted_desc:
      'Предметы, продаваемые посетителями-мастерами, всегда заколдованы.',
    skill_node_npcSellEnchanted01_desc: 'Колдовство у предметов:',
    skill_node_kingSuggest_title: 'Королевский советник',
    skill_node_kingSuggest_desc:
      'Благодаря чрезвычайной харизме теперь можно предложить королю Рейнгольду другой предмет, но на это уйдет больше энергии.',
    skill_node_kingSuggest01_desc: 'Повышенный расход энергии:',
    skill_node_kingSuggest03_desc: 'Без повышенного расхода энергии!',
    skill_node_enchantedSurchargeValue_title: 'Укоренившееся колдовство',
    skill_node_enchantedSurchargeValue_desc:
      'Предметы, заколдованные дважды, приносят больше золота при повышении цены.',
    skill_node_enchantedSurchargeValue01_desc: 'Повышение цены:',
    skill_node_kingReturn_title: 'Возвращение короля',
    skill_node_kingReturn_desc:
      'Есть шанс, что после покупки товара и ухода король Рейнгольд немедленно вернется.',
    skill_node_kingReturn01_desc:
      'Шанс на забывание Рейнгольдом факта недавней покупки:',
    skill_start_node_crafting_title: 'Изготовление',
    skill_start_node_crafting_desc:
      'Овладейте искусством изготовления, синтеза и управлением ресурсами!',
    skill_node_t1Capacity_title: 'Бездонные корзины',
    skill_node_t1Capacity_desc:
      'Увеличена максимальная вместимость для железа, древесины, кожи и трав.',
    skill_node_t1Capacity01_desc: 'Вместимость ресурсов:',
    skill_node_t1Regen_title: 'Союз с Тамасом',
    skill_node_t1Regen_desc:
      'Ускорено восстановление ресурсов для железа, древесины, кожи и трав.',
    skill_node_t1Regen01_desc: 'Производство ресурсов:',
    skill_node_betterRecycle_title: 'Забота об экологии',
    skill_node_betterRecycle_desc: 'Утилизация предметов дает больше ресурсов.',
    skill_node_betterRecycle01_desc: 'Утилизированные ресурсы:',
    skill_node_workerXpGain_title: 'Наставничество',
    skill_node_workerXpGain_desc: 'Все мастера получают больше опыта.',
    skill_node_workerXpGain01_desc: 'Бонус к опыту:',
    skill_node_t2Capacity_title: 'Складская эстетика',
    skill_node_t2Capacity_desc:
      'Увеличена максимальная вместимость для стали, железного дерева, ткани и масла.',
    skill_node_t2Capacity01_desc: 'Вместимость ресурсов:',
    skill_node_noQuality_title: 'Массовое производство',
    skill_node_noQuality_desc:
      'Есть шанс изготовить несколько низкоранговых предметов, но они всегда будут обычного качества.',
    skill_node_noQuality01_desc:
      'Повышение шанса изготовить несколько предметов:',
    skill_node_noQuality02_desc: 'Применяется к следующему рангу и ниже:',
    skill_node_t2Regen_title: 'Объединенный город',
    skill_node_t2Regen_desc:
      'Ускорено восстановление ресурсов для стали, железного дерева, ткани и масла.',
    skill_node_t2Regen01_desc: 'Производство ресурсов:',
    skill_node_resourceRefundChance_title: 'Без отходов',
    skill_node_resourceRefundChance_desc:
      'При изготовлении предмета есть шанс не расходовать ресурсы.',
    skill_node_resourceRefundChance01_desc: 'Шанс изготовления без ресурсов:',
    skill_node_inventoryIncrease_title: 'Сертифицированный погрузчик',
    skill_node_inventoryIncrease_desc: 'Бонусное место в инвентаре.',
    skill_node_inventoryIncrease01_desc: 'Место в инвентаре:',
    skill_node_componentRefundChance_title: 'Хитрость ремесленника',
    skill_node_componentRefundChance_desc:
      'При изготовлении предмета есть шанс не расходовать компоненты.',
    skill_node_componentRefundChance01_desc:
      'Шанс изготовления без компонентов:',
    skill_node_superiorFlawlessFusionCost_title: 'Безупречный процесс',
    skill_node_superiorFlawlessFusionCost_desc:
      'Синтез безупречных предметов требует меньше превосходных компонентов.',
    skill_node_superiorFlawlessFusionCost01_desc: 'Превосходное снаряжение:',
    skill_node_ignoreWorkerLevel_title: 'Без подготовки',
    skill_node_ignoreWorkerLevel_desc:
      'Снижен минимальный уровень мастера, необходимый для работы по чертежам. Однако мастеров все равно нужно нанимать!',
    skill_node_ignoreWorkerLevel01_desc: 'Требование к уровню мастера:',
    skill_node_commonSuperiorFusionCost_title: 'Здравый смысл',
    skill_node_commonSuperiorFusionCost_desc:
      'Синтез превосходных предметов требует меньше обычного снаряжения.',
    skill_node_commonSuperiorFusionCost01_desc: 'Обычное снаряжение:',
    skill_node_manaCapacity_title: 'Содержание эфира',
    skill_node_manaCapacity_desc:
      'Увеличена максимальная вместимость для эфира.',
    skill_node_manaCapacity01_desc: 'Вместимость эфира:',
    skill_node_gemsCapacity_title: 'Высшая безопасность',
    skill_node_gemsCapacity_desc:
      'Увеличена максимальная вместимость для алмазов.',
    skill_node_gemsCapacity01_desc: 'Вместимость алмазов:',
    skill_node_gemsRegen_title:
      '<sprite tint=1 name=Note3>Подруга-фея<sprite tint=1 name=Note4>',
    skill_node_gemsRegen_desc: 'Ускорено восстановление ресурсов для алмазов.',
    skill_node_gemsRegen01_desc: 'Производство алмазов:',
    skill_node_manaRegen_title: 'Благословение Серены',
    skill_node_manaRegen_desc: 'Ускорено восстановление ресурсов для эфира.',
    skill_node_manaRegen01_desc: 'Производство эфира:',
    skill_node_essenceCapacity_title: 'Самая суть',
    skill_node_essenceCapacity_desc:
      'Увеличена максимальная вместимость для сущности.',
    skill_node_essenceCapacity01_desc: 'Вместимость сущности:',
    skill_node_fusionRush_title: 'Вливание энергии',
    skill_node_fusionRush_desc:
      'Открывает возможность ускорять синтез за энергию!',
    skill_node_fusionRush01_desc: 'Расход за ускорение синтеза:',
    skill_node_fusionRush02_desc: 'Без дополнительного расхода за ускорение',
    skill_node_essenceRegen_title: 'Пробуждение мертвых',
    skill_node_essenceRegen_desc:
      'Ускорено восстановление ресурсов для сущности.',
    skill_node_essenceRegen01_desc: 'Производство сущности:',
    skill_node_craftSlot_title: 'Дополнительное производство',
    skill_node_craftSlot_desc:
      'Получите дополнительную производственную ячейку! Производственные ячейки, которыми вы еще не владеете, также не подорожают.',
    skill_node_craftSlot01_desc: 'Производственные ячейки:',
    skill_start_node_questing_title: 'Приключения',
    skill_start_node_questing_desc:
      'Освойте искусство приключений и получайте богатую добычу, собранную героями!',
    skill_node_primaryComponent_title: 'Обычный фермер',
    skill_node_primaryComponent_desc:
      'Находите больше обычных приключенческих компонентов, таких как эльфийская древесина, серебряная пыль и т. д.',
    skill_node_primaryComponent01_desc: 'Найденные обычные компоненты:',
    skill_node_secondaryComponent_title: 'Необычные ингредиенты',
    skill_node_secondaryComponent_desc:
      'Находите больше необычных приключенческих компонентов, таких как шишки железной сосны, перепончатые крылья и т. д.',
    skill_node_secondaryComponent01_desc: 'Найденные необычные компоненты:',
    skill_node_tertiaryComponent_title: 'Изысканный вкус',
    skill_node_tertiaryComponent_desc:
      'Находите больше редких приключенческих компонентов, таких как светящиеся грибы, драгоценные камни и т. д.',
    skill_node_tertiaryComponent01_desc: 'Найденные редкие компоненты:',
    skill_node_fasterBossQuest_title: 'Напористость Эшли',
    skill_node_fasterBossQuest_desc: 'Ускорение приключений с боссами.',
    skill_node_fasterBossQuest01_desc: 'Сокращение времени приключения:',
    skill_node_heroHire_title: 'Лучшие товарищи Аргона',
    skill_node_heroHire_desc:
      'Герои нанимаются с более высоким начальным уровнем.',
    skill_node_heroHire01_desc: 'Уровень нанимаемых героев:',
    skill_node_fasterFlashQuest_title: 'Тропы Бьорна',
    skill_node_fasterFlashQuest_desc: 'Ускорение блиц-приключений.',
    skill_node_fasterFlashQuest01_desc: 'Сокращение времени приключения:',
    skill_node_instantQuest_title: 'Случайные приготовления',
    skill_node_instantQuest_desc:
      'Есть шанс, что приключение, начатое вами, будет пройдено немедленно.',
    skill_node_instantQuest01_desc: 'Шанс автопрохождения:',
    skill_node_betterOverflowValue_title: 'Придорожный опт',
    skill_node_betterOverflowValue_desc:
      'Награды за приключения при переполнении продаются по более высокой цене. Базовая цена продажи при переполнении составляет 25% от стоимости предмета.',
    skill_node_betterOverflowValue01_desc: 'Цена продажи при переполнении:',
    skill_node_noEquipmentBundle_title: 'Необычная концентрация',
    skill_node_noEquipmentBundle_desc:
      'Во время приключений вы никогда не найдете комплекты снаряжения, а будете с большей вероятностью получать другие награды. (Не применяется к блиц-приключениям.)',
    skill_node_noEquipmentBundle01_desc:
      'Приключения без комплекта снаряжения!',
    skill_node_deadHeroesRest_title: 'Молитва Фрейи',
    skill_node_deadHeroesRest_desc: 'Герои, павшие в бою, отдыхают быстрее.',
    skill_node_deadHeroesRest01_desc: 'Сокращение времени отдыха:',
    skill_node_noSingularItem_title: 'Барахольщик',
    skill_node_noSingularItem_desc:
      'Во время приключений вы никогда не найдете качественные предметы, а будете с большей вероятностью получать другие награды. (Не применяется к блиц-приключениям.)',
    skill_node_noSingularItem01_desc:
      'Приключения без качественного снаряжения!',
    skill_node_singularItemQuality_title: 'Все блестит',
    skill_node_singularItemQuality_desc:
      'Качество превосходного снаряжения, найденного в награду за прохождение приключений, повышается до безупречного.',
    skill_node_singularItemQuality01_desc:
      'Качество предметов не ниже безупречного.',
    skill_node_doubleBundle_title: 'Удвоение комплектов',
    skill_node_doubleBundle_desc:
      'Шанс УДВОИТЬ количество комплектов снаряжения, найденных во время приключений.',
    skill_node_doubleBundle01_desc: 'Шанс удвоения:',
    skill_node_bonusChestChance_title: 'Охотники за сокровищами',
    skill_node_bonusChestChance_desc:
      'Шанс найти бонусный сундук в приключениях, в которых он может выпасть.',
    skill_node_bonusChestChance01_desc: 'Шанс на бонусный сундук:',
    skill_node_doubleComponents_title: 'Прикосновение Сиа',
    skill_node_doubleComponents_desc:
      'Шанс УДВОИТЬ количество компонентов, найденных в награду во время приключений. Действует на необычные компоненты, такие как лунные и рунные камни, но не на чистое золото.',
    skill_node_doubleComponents01_desc: 'Шанс удвоения компонентов:',
    skill_node_doubleKeyChance_title: 'Ключ к успеху',
    skill_node_doubleKeyChance_desc:
      'При нахождении ключа во время приключения есть шанс найти еще один такой же.',
    skill_node_doubleKeyChance01_desc: 'Шанс на бонусный ключ:',
    skill_node_questSlot_title: 'Дополнительное приключение',
    skill_node_questSlot_desc:
      'Получите дополнительную ячейку приключений! Ячейки приключений, которыми вы еще не владеете, также не подорожают.',
    skill_node_questSlot01_desc: 'Ячейки приключений:',
    skill_node_extraTitanSoul_title: 'Настройка на титана',
    skill_node_extraTitanSoul_desc:
      'Есть шанс, что последний босс из Башни титанов оставит после себя дополнительную душу титана.',
    skill_node_extraTitanSoul01_desc: 'Шанс выпадения души титана:',
    skill_node_poloniaExtraLoot_title: 'Ценная добыча',
    skill_node_poloniaExtraLoot_desc:
      'Есть шанс, что предметы, украденные Полонией, будут на ранг выше даже в случае превышения ее обычного максимума.',
    skill_node_poloniaExtraLoot01_desc: 'Шанс на добычу получше:',
    skilltree_point_unlock_format: '{0}–{1} оч.',
    skilltree_points_unlocks: 'Разблокировка очков',
    skilltree_points_unlocks_up_to: 'До {0} оч.',
    forest_name: 'Воющий лес',
    forest_name_desc:
      'Распространенное место назначения для отправляющихся в приключения новичков, желающих доказать, на что они способны.',
    forest_boss_name: 'Вождь гиблинов',
    forest_boss_area_name: 'Логово гиблинов',
    fight_forest_boss: 'Сразитесь с вождем гиблинов!',
    forest_boss_story:
      'Поскольку гиблины тупые и подлые, для их вождя вполне естественно быть самым тупым и подлым.',
    notification_boss_forest_respawn_complete_title: 'Босс Воющего леса',
    notification_boss_forest_respawn_complete_body: 'Вождь гиблинов вернулся!',
    grotto_name: 'Пещеры Авроры',
    grotto_name_desc:
      'Звездное небо красиво отражается на ледяных стенах этих холодных пещер.',
    grotto_boss_name: 'Король йети',
    grotto_boss_area_name: 'Трон короля йети',
    fight_grotto_boss: 'Сразитесь с королем йети!',
    grotto_boss_story:
      'Тот йети, что присвоит самую большую сосульку, становится королем. Заодно она служит ему эффективным и экологически чистым оружием!',
    notification_boss_grotto_respawn_complete_title: 'Босс пещер Авроры',
    notification_boss_grotto_respawn_complete_body: 'Король йети вернулся!',
    swamp_name: 'Шепчущая топь',
    swamp_name_desc:
      'Сыро. Грязно. Пахнет гнилью. Идеально подходит для приключений!',
    swamp_boss_name: 'Жаба-ведьма',
    swamp_boss_area_name: 'Хижина проклятой ведьмы',
    fight_swamp_boss: 'Сразитесь с жабой-ведьмой!',
    swamp_boss_story:
      'Ведьма, которая когда-то была человеком, превратилась в земноводное после неудачного эксперимента. Это ее так разозлило, что она проклинает путников, оказавшихся в ее владениях, обрекая их на такую же судьбу.',
    notification_boss_swamp_respawn_complete_title: 'Босс Шепчущей топи',
    notification_boss_swamp_respawn_complete_body: 'Жаба-ведьма вернулась!',
    desert_name: 'Безжизненная пустошь',
    desert_name_desc:
      'Монстры свирепо защищают эту обширную территорию, где... вообще ничего нет.',
    desert_boss_name: 'Грибандит',
    desert_boss_area_name: 'Арена грибандитов',
    fight_desert_boss: 'Сразитесь с грибандитом!',
    desert_boss_story:
      'Грибандит, пятикратный чемпион Безжизненной пустоши в тяжелом весе, принимает ваш вызов!',
    notification_boss_desert_respawn_complete_title:
      'Босс Безжизненной пустоши',
    notification_boss_desert_respawn_complete_body: 'Грибандит вернулся!',
    pyramid_name: 'Гробница бога солнца',
    pyramid_name_desc:
      'Древний монумент в честь древнего бога. Полон ловушек и опасностей.',
    pyramid_boss_name: 'Великий Анубис',
    pyramid_boss_area_name: 'Страж бога солнца',
    fight_pyramid_boss: 'Сразитесь с великим Анубисом!',
    pyramid_boss_story:
      'Те, кто решат ограбить не ту могилу, могут удостоиться встречи с ее самым большим и несговорчивым стражем.',
    notification_boss_pyramid_respawn_complete_title:
      'Босс Гробницы бога солнца',
    notification_boss_pyramid_respawn_complete_body: 'Великий Анубис вернулся!',
    ruins_name: 'Руины Кроноса',
    ruins_name_desc:
      'Таинственные руины великой империи, исчезнувшей в одночасье. Ведущая гипотеза называет причиной этой трагедии эксперименты с магией времени.',
    ruins_boss_name: 'Повелитель циклопов',
    ruins_boss_area_name: 'Чертог королей',
    fight_ruins_boss: 'Сразитесь с повелителем циклопов!',
    ruins_boss_story:
      'Повелитель циклопов топает и шлепает среди руин Кроноса в поисках достойных искателей приключений, которым можно бросить вызов.',
    notification_boss_ruins_respawn_complete_title: 'Босс руин Кроноса',
    notification_boss_ruins_respawn_complete_body:
      'Повелитель циклопов вернулся!',
    castle_name: 'Замок с привидениями',
    castle_name_desc:
      'Призрачные обитатели по-прежнему занимаются управлением и содержанием разрушенного замка, когда-то известного в этих землях.',
    castle_boss_name: 'Король-Тиран',
    castle_boss_area_name: 'Покинутый трон',
    fight_castle_boss: 'Сразитесь с Королем-Тираном!',
    castle_boss_story:
      'Кто осмелится бросить вызов абсолютной власти Короля-Тирана?',
    notification_boss_castle_respawn_complete_title:
      'Босс замка с привидениями',
    notification_boss_castle_respawn_complete_body: 'Король-Тиран вернулся!',
    temple_name: 'Затонувший храм',
    temple_name_desc: 'Это определенно место поклонения... но чему?',
    temple_boss_name: 'Королева русалок',
    temple_boss_area_name: 'Глубоководный питомник',
    fight_temple_boss: 'Сразитесь с королевой русалок',
    temple_boss_story: 'Королева яростно охраняет свой питомник.',
    notification_boss_temple_respawn_complete_title: 'Босс Затонувшего храма',
    notification_boss_temple_respawn_complete_body:
      'Королева русалок вернулась!',
    goldcity_name: 'Потерянный город золота',
    goldcity_boss_name: 'Ключеносец',
    fight_goldcity_boss: 'Сразитесь с Ключеносцем!',
    goldcity_boss_story:
      'В легендах говорится о Ключеносце — золотом големе, бродящем по залам Потерянного города золота...',
    peak_name: 'Пик Мрачный Шпиль',
    peak_name_desc:
      'На этой зловещей скалистой горе заправляют пернатые демоны.',
    peak_boss_name: 'Гарпия-матриарх ',
    peak_boss_area_name: 'Гнездо на Мрачном Шпиле',
    fight_peak_boss: 'Сразитесь с гарпией-матриархом!',
    peak_boss_story:
      'Матриарх поработила других гарпий и командует ими в основном с помощью громкого визга.',
    notification_boss_peak_respawn_complete_title: 'Пик Мрачный Шпиль',
    notification_boss_peak_respawn_complete_body: 'Гарпия-матриарх вернулась!',
    volcano_name_desc:
      'Исследование обжигающего нутра действующего вулкана предназначено только для самых опытных (или глупых) искателей приключений.',
    volcano_boss_name: 'Старший сокрушкраб',
    volcano_boss_area_name: 'Недра горы',
    fight_volcano_boss: 'Сразитесь со старшим сокрушкрабом!',
    volcano_boss_story:
      'Самый большой и самый старый из сокрушкрабов жует вкуснейший камень. С таким морепродуктом лучше не сталкиваться!',
    notification_boss_volcano_respawn_complete_title: 'Вулкан Пепельного озера',
    notification_boss_volcano_respawn_complete_body:
      'Старший сокрушкраб вернулся!',
    rift_name: 'Измерение Пустоты',
    rift_name_desc:
      'Этот портал ведет в мир, непостижимый для человеческого разума. Расколотые земли за ним враждебны для всего живого.',
    rift_boss_name: 'Существо Пустоты',
    rift_boss_area_name: 'Сплавленный Материум',
    fight_rift_boss: 'Сразитесь с существом Пустоты!',
    rift_boss_story:
      'Вопреки всякой логике, одна форма жизни бродит по раздробленным останкам этого измерения. Одно можно сказать наверняка... дружелюбием она не отличается!',
    notification_boss_rift_respawn_complete_title: 'Измерение Пустоты',
    notification_boss_rift_respawn_complete_body: 'Существо Пустоты вернулось!',
    tower_titan_name: 'Башня титанов',
    quest_lenght_format: 'Длительность: {0}',
    quest_boss_respawn_format: 'Босс локации {0} будет доступен через {1}',
    new_accessible_quest: 'Новое место приключений: {0}',
    new_quest_unlocked: 'Открыто новое приключение',
    skill_rarity_bronze: 'Обычный',
    skill_rarity_silver: 'Редкий',
    skill_rarity_gold: 'Эпический',
    elvenwood_area_name: 'Эльфийская поляна',
    redpinecone_area_name: 'Роща железных сосен',
    glowingmushrooms_area_name: 'Радужные пещеры',
    arcanedust_area_name: 'Блестящее озеро',
    webbedwings_area_name: 'Колония летучих мышей',
    preciousgem_area_name: 'Заброшенный рудник',
    livingroots_area_name: 'Дикие сады',
    darkscale_area_name: 'Край ржавого змея',
    icypearl_area_name: 'Глубокая топь',
    whitesand_area_name: 'Высохшее озеро',
    sharpfang_area_name: 'Охотничьи угодья',
    mooncrystal_area_name: 'Лунносветные равнины',
    evileye_area_name: 'Зал саркофагов',
    silkscarab_area_name: 'Сокровищница',
    starmetal_area_name: 'Святилище метеорита',
    ancientmarble_area_name: 'Разрушенный двор',
    overgrownvine_area_name: 'Заросшие сады',
    timecrystal_area_name: 'Колодец времени',
    ectoplasm_area_name: 'Чертоги призраков',
    sewingthread_area_name: 'Самая высокая башня',
    ghostflag_area_name: 'Тронный зал',
    deepcoral_area_name: 'Мрачные глубины',
    sirenshard_area_name: 'Эхокамера',
    goldshell_area_name: 'Храмовый склад',
    grimtalon_area_name: 'Ужасный горный перевал',
    crystalegg_area_name: 'Гнездо гарпии',
    icybreeze_area_name: 'Вершина Мрачного Шпиля',
    crabclaw_area_name: 'Места линьки',
    obsidian_area_name: 'Обсидиановые трубки',
    magmacore_area_name: 'Магмовые тоннели',
    voideye_area_name: 'Логово пустотышей',
    voidcrystal_area_name: 'Дрейфующий остров',
    voidcloth_area_name: 'Звездная пропасть',
    dragoninvasion_name: 'Вторжение драконов',
    miniboss_agile: 'Проворный',
    miniboss_huge: 'Огромный',
    miniboss_dire: 'Ужасный',
    miniboss_wealthy: 'Богатый',
    miniboss_epic: 'Легендарный',
    Prefix: 'Космоса',
    Prefix_02: 'Болот',
    Prefix_03: 'Планет',
    Prefix_04: 'Крайности',
    Prefix_05: 'Экстрима',
    Prefix_06: 'Токсичности',
    Prefix_07: 'Легенд',
    Prefix_08: 'Убийств',
    Prefix_09: 'Демонов',
    Prefix_10: 'Ада',
    Prefix_11: 'Пустоты',
    Prefix_12: 'Черепов',
    Prefix_13: 'Коварства',
    Prefix_14: 'Проворства',
    Prefix_15: 'Холмов',
    Prefix_16: 'Величия',
    Prefix_17: 'Песков',
    Prefix_18: 'Моды',
    Prefix_19: 'Остроумия',
    Prefix_20: 'Кинжалов',
    Prefix_21: 'Пауков',
    Prefix_22: 'Страха',
    Prefix_23: 'Злобы',
    Prefix_24: 'Ветров',
    Prefix_25: 'Звезд',
    Prefix_26: 'Ужаса',
    Prefix_27: 'Змей',
    Prefix_28: 'Крови',
    Prefix_29: 'Хаоса',
    Prefix_30: 'Пучины',
    Prefix_31: 'Знаний',
    Prefix_32: 'Мрака',
    Prefix_33: 'Тьмы',
    Prefix_34: 'Битбокса',
    Prefix_35: 'Бриллиантов',
    Prefix_36: 'Камня',
    Prefix_37: 'Сияния',
    Prefix_38: 'Мхов',
    Prefix_39: 'Чумы',
    Prefix_40: 'Без Глютена',
    Prefix_41: 'Призраков',
    Prefix_42: 'Вечности',
    Prefix_43: 'Ненависти',
    Prefix_44: 'Злорадства',
    Prefix_45: 'Астрала',
    Prefix_46: 'Стикса',
    Prefix_47: 'Забытья',
    Prefix_48: 'Печали',
    Prefix_49: 'Высоты',
    Prefix_50: 'Жары',
    Prefix_51: 'Холода',
    Prefix_52: 'Блеска',
    Prefix_53: 'Взрывчатки',
    Prefix_54: 'Мучений',
    Prefix_55: 'Гор',
    Prefix_56: 'Крыс',
    Prefix_57: 'Единственный и неповторимый',
    Suffix: 'Принцесса',
    Suffix_02: 'Принц',
    Suffix_03: 'Повелитель',
    Suffix_04: 'Владыка',
    Suffix_05: 'Король',
    Suffix_06: 'Королева',
    Suffix_07: 'Приспешник',
    Suffix_08: 'Плохиш',
    Suffix_09: 'Злодей',
    Suffix_10: 'Демон',
    Suffix_11: 'Босс',
    Suffix_12: 'Большой босс',
    Suffix_13: 'Гангстер',
    Suffix_14: 'Властелин',
    Suffix_15: 'Герцогиня',
    Suffix_16: 'Барон',
    Suffix_17: 'Рыцарь',
    Suffix_18: 'Бес',
    Suffix_19: 'Прислужник',
    Suffix_20: 'Фанатик',
    Suffix_21: 'Мастер',
    Suffix_22: 'Преследователь',
    Suffix_23: 'Охотник',
    Suffix_24: 'Лотос',
    Suffix_25: 'Хранитель',
    Suffix_26: 'Копитель',
    Suffix_27: 'Захватчик',
    Suffix_28: 'Стив',
    Suffix_29: 'Бич',
    Suffix_30: 'Чудовище',
    Suffix_31: 'Зверь',
    Suffix_32: 'Ползун',
    Suffix_33: 'Животное',
    Suffix_34: 'Монстр',
    Suffix_35: 'Чернокнижник',
    Suffix_36: 'Претендент',
    titantower_floor_desc:
      'Дорога освещена, и путь чист. Чтобы идти по нему, нужна только сила.',
    titantower_floor_desc_02:
      'Сверху слышится позвякивание множества монет. Может быть, это сокровище?',
    titantower_floor_desc_03:
      'Безумное гоготанье доносится из комнаты сверху...',
    titantower_floor_desc_04:
      'Через дверь эхом отзываются быстрые шаги и удары оружия. Местный обитатель усердно тренируется!',
    titantower_floor_desc_05:
      'Похожее на транс пение пронизывает воздух вокруг, наполняя его гудением...',
    titantower_floor_desc_06:
      'Тоненький голос у вас в голове умоляет повернуть назад...',
    titantower_floor_desc_07: 'На двери висит табличка с надписью: «Обед».',
    titantower_floor_desc_08:
      'Вы тщательно проверяете наличие ловушек... Ничего. Или так кажется.',
    titantower_floor_desc_09: 'Тихо... Слишком тихо.',
    titantower_floor_desc_10:
      'Отзвуки тихой колыбельной доносятся с этажа выше...',
    titantower_floor_desc_11:
      'Ступеньки, ступеньки и снова ступеньки. Есть ли конец у этой башни?',
    titantower_floor_desc_12:
      'Воздух влажный, но неприятным его назвать нельзя. Что это может значить?',
    titantower_floor_desc_13:
      'Следующая дверь покрыта таинственными рунами. Осмелитесь ли вы войти?',
    titantower_floor_desc_14:
      'Эта дверь закрыта на очень прочный навесной замок. Ваши герои справятся с чем угодно, но не без шума...',
    titantower_floor_desc_15:
      'В этой комнате начинает пахнуть дымом. Лучше поднимитесь наверх!',
    titantower_floor_desc_16:
      'За пределами вашего поля зрения, кажется, таятся какие-то тени... Не теряйте бдительность.',
    titantower_floor_desc_17:
      'Когда вы поднимаетесь на следующий этаж, сверху слышится ритмичный стук.',
    titantower_floor_desc_18:
      'Странно, но когда вы приближаетесь к двери, то слышите человеческую речь, хотя значение слов ускользает от вас.',
    titantower_floor_desc_19:
      'Эта лестница выглядит в точности так же, как на предыдущем этаже... вплоть до мельчайших трещин и царапин!',
    titantower_floor_desc_20:
      'Странно, но эта комната выглядит нетронутой. И пахнет очень приятно!',
    titantower_floor_desc_21:
      'Вокруг вас свистит сильный ветер. И источника его не видно...',
    titantower_floor_desc_23:
      'У вас по спине бегут мурашки. Должно быть, рядом могучий противник...',
    titantower_floor_desc_24: 'Запах... Морской воды, здесь?!',
    hate_fighter_name: 'Ненавидящий воинов',
    hate_fighter_desc: 'С большей вероятностью атакует воинов.',
    canopener_name: 'Вскрыватель доспехов',
    canopener_desc:
      'С большей вероятностью атакует воинов. Также игнорирует некоторую долю их защиты.',
    hate_rogue_name: 'Ненавидящий странников',
    hate_rogue_desc: 'С большей вероятностью атакует странников.',
    ratcatcher_name: 'Крысолов',
    ratcatcher_desc:
      'С большей вероятностью атакует странников. Также игнорирует некоторую долю их уклонения.',
    hate_spellcaster_name: 'Ненавидящий заклинателей',
    hate_spellcaster_desc: 'С большей вероятностью атакует заклинателей.',
    magiceater_name: 'Поглотитель магии',
    magiceater_desc:
      'С большей вероятностью атакует заклинателей. Также получает от них меньше урона.',
    magic_resistant_name: 'Устойчивый к магии',
    magic_resistant_desc: 'Получает меньше урона от заклинателей.',
    stoneskinned_name: 'Толстокожий',
    stoneskinned_desc: 'Получает меньше урона от воинов и странников.',
    powerful_name: 'Могучий',
    powerful_desc: 'Бонус ко всем параметрам.',
    mythical_name: 'Мифический',
    mythical_desc: 'Большой бонус ко всем параметрам.',
    swift_name: 'Проворный',
    swift_desc: 'Первая атака наносит двойной урон.',
    lightningswift_name: 'Молниеносный',
    lightningswift_desc:
      'Первая атака наносит двойной урон и с большей вероятностью может стать критическим ударом.',
    fortified_name: 'Укрепленный',
    fortified_desc: 'С меньшей вероятностью получает критические удары.',
    noweakness_name: 'Не имеющий слабости',
    noweakness_desc:
      'С гораздо меньшей вероятностью получает критические удары.',
    armored_name: 'В броне',
    armored_desc: 'Получает меньше урона, особенно от критических ударов.',
    heavyarmored_name: 'В броне и латах',
    heavyarmored_desc:
      'Получает гораздо меньше урона, особенно от критических ударов.',
    eagleeyed_name: 'Остроглазый',
    eagleeyed_desc: 'С большей легкостью попадает по уклончивым целям.',
    sniper_name: 'Снайпер',
    sniper_desc: 'Может легко попасть по уклончивым целям.',
    slayer_name: 'Истребитель',
    slayer_desc: 'Более часто выполняет критические удары.',
    bloodthirsty_name: 'Кровожадный',
    bloodthirsty_desc: 'Гораздо чаще выполняет критические удары.',
    deadly_name: 'Смертоносный',
    deadly_desc: 'Выполняет более сильные критические удары немного чаще.',
    assassin_name: 'Убийца',
    assassin_desc:
      'Выполняет гораздо более сильные критические удары немного чаще.',
    slippery_name: 'Увертливый',
    slippery_desc: 'С некоторой вероятностью может избежать атак.',
    elusive_name: 'Уклончивый',
    elusive_desc: 'С большой вероятностью может избежать атак.',
    vigorous_name: 'Энергичный',
    vigorous_desc: 'Имеет дополнительное здоровье.',
    supertough_name: 'Суперкрепкий',
    supertough_desc: 'Имеет много здоровья.',
    large_name: 'Массивный',
    large_desc:
      'С большей вероятностью использует групповые атаки. К тому же, они сильнее.',
    absoluteunit_name: 'Абсолютный воин',
    absoluteunit_desc:
      'С гораздо большей вероятностью использует групповые атаки. К тому же, они гораздо сильнее.',
    ambusher_name: 'Эксперт по засадам',
    ambusher_desc: 'Атаки не могут промахнуться в первых 3 раундах.',
    steamroller_name: 'Сокрушитель',
    steamroller_desc: 'Наносит все больше урона с каждым раундом.',
    destroyer_name: 'Уничтожитель',
    destroyer_desc: 'С большей вероятностью повреждает снаряжение.',
    skill_rarity_titan: 'Навык титана',
    dragon_name_format: '{0} {1} {2}',
    dragon_color: 'белый',
    dragon_color_02: 'зеленый',
    dragon_color_03: 'синий',
    dragon_color_04: 'черный',
    dragon_color_05: 'красный',
    dragon_family: 'Молодой',
    dragon_family_02: 'Взрослый',
    dragon_family_03: 'Великий',
    dragon_family_04: 'Старейший',
    dragon_family_05: 'Древний',
    dragon_species_name: 'дракон',
    fq_research_name: 'Библиотека замка',
    fq_monster_research_name: 'Ученая гарпия',
    fq_research_desc:
      'Пусть она самая умная среди гарпий, но читать она все-таки не умеет! Поэтому пропажу этих <c1>свитков исследования</c1> не заметит!',
    fq_training_name: 'Тренировочная площадка',
    fq_monster_training_name: 'Инструктор',
    fq_training_desc:
      'Инструктор ищет спарринг-партнеров! Победите его, чтобы заработать много <c1>опыта героев</c1>!',
    fq_gear_name: 'Купеческий караван',
    fq_monster_gear_name: 'Циклоп-торговец',
    fq_gear_desc:
      'Этот торговец может стать конкурентом! Разграбьте его караван, чтобы перепродать <c1>различное снаряжение</c1>!',
    fq_chest_name: 'Затонувший корабль',
    fq_monster_chest_name: 'Гиблин-пират',
    fq_chest_desc:
      'Пират Гибборода — известный кладоискатель, но он так просто ничего не отдаст! На более высоких уровнях сложности выпадают более ценные <c1>сундуки</c1>!',
    fq_rune_name: 'Рунная каменоломня',
    fq_monster_rune_name: 'Голем из рунных камней',
    fq_rune_desc:
      'Редкий голем, сделанный полностью из рунных камней! На более высоких уровнях сложности выпадают более ценные <c1>рунные камни</c1>!',
    fq_moon_name: 'Лунносветный грот',
    fq_monster_moon_name: 'Голем из лунных камней',
    fq_moon_desc:
      'Редкий голем, сделанный полностью из лунных камней! На более высоких уровнях сложности выпадают более ценные <c1>лунные камни</c1>!',
    fq_lcog_name: 'Позолоченная гробница',
    fq_monster_lcog_name: 'Золотой голем',
    fq_lcog_desc:
      'Открылось секретное дополнение к Потерянному городу золота! На более высоких уровнях сложности могут выпасть <c1>люксовые камни</c1>!',
    fq_enchant_name: 'Башня чародея',
    fq_monster_enchant_name: 'Жаба-мудрец',
    fq_enchant_desc:
      'Ворчливая жаба засела в Башне, чтобы заняться <c1>колдовством</c1>! Возьмите и себе немного!',
    fq_champion_name: 'Логово защитника',
    fq_monster_champion_name: 'Защитник Анубис',
    fq_champion_desc:
      'Еще один собиратель монет защитника! На более высоких уровнях сложности могут выпасть более редкие <c1>монеты защитника</c1>!',
    fq_key_name: 'Сокровищница',
    fq_monster_key_name: 'Грибандит-гробокопатель',
    fq_key_desc:
      'Пойманный на месте преступления гробокопатель! Тряхните его, чтобы получить несколько ключей. На более высоких уровнях сложности выпадают <c1>ключи</c1> получше!',
    fq_repair_name: 'Магмовая мастерская',
    fq_monster_repair_name: 'Гиблин-кузнец',
    fq_repair_desc:
      'Этого трудолюбивого гиблина можно убедить поделиться некоторыми из его <c1>ремкомплектов</c1>!',
    fq_fancygear_name: 'Тайник генерала',
    fq_monster_fancygear_name: 'Генерал циклопов',
    fq_fancygear_desc:
      'Генерал циклопов копит <c1>экзотическое снаряжение</c1> чисто для себя! Перераспределите богатство!',
    fq_sigil_name: 'Убежище йети',
    fq_monster_sigil_name: 'Ниндзя со знаками',
    fq_sigil_desc:
      'Бросьте вызов этому неуловимому ниндзя, чтобы украсть несколько <c1>знаков</c1>! На более высоких уровнях сложности могут выпасть <c1>истинные знаки</c1>!',
    fq_ascension_name: 'Недра вулкана',
    fq_monster_ascension_name: 'Древний сокрушкраб',
    fq_ascension_desc:
      '<c1>Осколки усиления</c1>, созданные в недрах земли! Соберите их, пока пещера не обрушилась!',
    fq_cndragon_name: 'Логово речных духов',
    fq_monster_cndragon_name: 'Королева драконов',
    fq_cndragon_desc:
      'Сразитесь со злобным речным духом ради шанса получить <c1>чертежи</c1> предков!',
    class_alchemist_name: 'Алхимик',
    class_alchemist_desc:
      'Мастера-травники, знатоки оккультных наук и эксперты по реагентам.',
    class_barbarian_name: 'Варвар',
    class_barbarian_desc:
      'Яростные бойцы, для которых «осторожность» — пустой звук.',
    class_chieftain_name: 'Вождь',
    class_chieftain_desc:
      'Когда племя призывает предводителя, среди храбрецов, конечно же, встает и вождь.',
    class_berserker_name: 'Берсерк',
    class_berserker_desc:
      'Воины, наслаждающиеся резней, и в трансе становящиеся воплощением насилия и чистой ярости.',
    class_jarl_name: 'Ярл',
    class_jarl_desc: 'Даже самые могучие берсерки в страхе шепчут имя ярла.',
    class_cleric_name: 'Священник',
    class_cleric_desc:
      'Служители церкви, с божественной помощью защищающие верующих.',
    class_bishop_name: 'Епископ',
    class_bishop_desc:
      'Являясь ревнивыми поборниками веры, епископы воплощают волю Богини в мире смертных.',
    class_dancer_name: 'Танцор',
    class_dancer_desc:
      'Подвижные ловкачи, следящие за ходом сражения и грациозно танцующие под его ритм.',
    class_acrobat_name: 'Акробат',
    class_acrobat_desc:
      'Смертельное выступление акробата никогда не требует повтора на бис.',
    class_dragoon_name: 'Драгун',
    class_dragoon_desc:
      'Бесстрашные победители драконов, способные выполнять невероятные прыжки.',
    class_druid_name: 'Друид',
    class_druid_desc: 'Воплощение щедрости и свирепости природы.',
    class_archdruid_name: 'Архидруид',
    class_archdruid_desc:
      'Архидруиды, которых можно увидеть лишь в минуту крайней нужды, являются последней линией обороны в мире природы.',
    class_jester_name: 'Шут',
    class_jester_desc:
      'Неожиданные союзники, с одинаковым искусством обращающиеся с острым словом и клинком.',
    class_knight_name: 'Рыцарь',
    class_knight_desc:
      'Благородные воины, способные противостоять натиску любого противника.',
    class_lord_name: 'Лорд',
    class_lord_desc:
      'Полководцы, командиры и завоеватели в бесчисленных битвах.',
    class_mage_name: 'Маг',
    class_mage_desc:
      'Заклинатели, подчинившие себе великую силу четырех магических элементов.',
    class_archmage_name: 'Архимаг',
    class_archmage_desc:
      'Заклинатели подчиняются законам магии, чтобы применять заклинания. Архимаги нарушают и переписывают эти правила в соответствии со своими потребностями.',
    class_monk_name: 'Монах',
    class_monk_desc:
      'Воины-аскеты, без устали тренирующие и свои тела, и силу духа.',
    class_mastermonk_name: 'Великий магистр',
    class_mastermonk_desc: 'Баланс. Мысль. Действие. Отражение.',
    class_ninja_name: 'Ниндзя',
    class_ninja_desc:
      'Эти наемные убийцы берутся за любое задание и выполняют его.',
    class_sensei_name: 'Сэнсэй',
    class_sensei_desc:
      'Немногие видели сэнсэя в действии. Еще меньше тех, кто выжил, чтобы об этом рассказать.',
    class_paladin_name: 'Паладин',
    class_paladin_desc:
      'Благословенные воины, сделавшие своей миссией искоренение всего зла.',
    class_pirate_name: 'Пират',
    class_pirate_desc:
      'Грубоватый народец, предпочитающий ружья и обожающий спиртное, пивнушки и богатую добычу.',
    class_ranger_name: 'Охотник',
    class_ranger_desc:
      'Исследователи диких мест, не имеющие себе равных в охоте.',
    class_warden_name: 'Хранитель',
    class_warden_desc:
      'Хранители защищают дикие земли, которых не коснулась цивилизация.',
    class_redmage_name: 'Чудотворец',
    class_redmage_desc:
      'Мастера на все руки, которым подвластны любые навыки и занятия.',
    class_spellknight_name: 'Рыцарь чар',
    class_spellknight_desc:
      'Нет такой сферы деятельности, в которой не могут преуспеть эти убеленные сединами бойцы и знатоки магии.',
    class_geomancer_name: 'Геомант',
    class_geomancer_desc: 'Мудрые ученые, управляющие скрытой силой земли.',
    class_astramancer_name: 'Астромант',
    class_astramancer_desc:
      'Полностью овладев магией земли, некоторые обращают свой взгляд на небо и за его пределы.',
    class_soldier_name: 'Солдат',
    class_soldier_desc:
      'Профессиональные бойцы и непревзойденные эксперты в обращении с любым оружием ближнего боя.',
    class_mercenary_name: 'Наемник',
    class_mercenary_desc:
      'Ветераны бесчисленных войн, которые могут похвастаться непоколебимой преданностью... по приемлемой цене.',
    class_sorcerer_name: 'Волшебник',
    class_sorcerer_desc:
      'Заклинатели, получившие мощные способности после заключения договора с темными силами.',
    class_warlock_name: 'Чернокнижник',
    class_warlock_desc:
      'Ужасные силы за пределами понимания. Чернокнижник действительно все контролирует?',
    class_swordmaster_name: 'Самурай',
    class_swordmaster_desc:
      'Странствующие воины, способные выиграть бой одним движением меча.',
    class_daimyo_name: 'Дайме',
    class_daimyo_desc:
      'Когда князь самураев идет на войну, народы дрожат, и лепестки осыпаются с деревьев.',
    class_thief_name: 'Вор',
    class_thief_desc: 'Скользкие типы, за которыми нужен глаз да глаз.',
    class_trickster_name: 'Ловкач',
    class_trickster_desc: 'Сладость или гадость? Не совсем.',
    class_wanderer_name: 'Бродяга',
    class_wanderer_desc:
      'Загадочные незнакомцы, у которых почему-то всегда есть то, что требуется.',
    class_pathfinder_name: 'Следопыт',
    class_pathfinder_desc:
      'Непревзойденные исследователи неизведанного и запретного.',
    class_musketeer_name: 'Мушкетер',
    class_musketeer_desc:
      'Нахальные бандиты, мастера фехтования. Они также прекрасно обращаются с огнестрельным оружием.',
    class_conquistador_name: 'Конкистадор',
    class_conquistador_desc:
      'Для проблем на расстоянии — порох. Для проблем поблизости — сталь.',
    class_witch_name: 'Колдун',
    class_witch_desc:
      'Отлученные от школ волшебства неразборчивые в средствах чародеи, чьи цели непостижимы.',
    hero_class_argon_01: 'Начинающий рыцарь',
    hero_class_argon_02: 'Золотой страж',
    hero_class_argon_03: 'Паладин',
    hero_argon_bio_01:
      'Юный рыцарь-кадет, старающийся проявить себя. Верен до гроба.',
    hero_argon_bio_02:
      'Свеженазначенный золотой страж. Горячо предан идеалам добра, правосудия и героизма.',
    hero_argon_bio_03: 'Полноправный паладин, поклявшийся защищать слабых.',
    hero_class_sia_01: 'Тень короля',
    hero_class_sia_02: 'Тень короля?',
    hero_class_sia_03: 'Тайный агент',
    hero_sia_bio_01:
      'Изящная женщина, о которой никто ничего не знает, а сама она, похоже, знает все и всех.',
    hero_sia_bio_02:
      'Изящная женщина, о которой никто ничего не знает. Неужто она и впрямь заботится прежде всего о безопасности королевства?',
    hero_sia_bio_03:
      'Тайная телохранительница короля. Готова на все ради безопасности своего господина.',
    hero_class_lilu_01: 'Ученица',
    hero_class_lilu_02: 'Одаренная',
    hero_lilu_bio_01:
      'Юная ученица школы волшебства. Весьма талантлива, несмотря на свой возраст.',
    hero_lilu_bio_02:
      'Талантливая девушка-маг с безграничным потенциалом. Теперь, когда учитель снова вместе с ней, она способна на все!',
    hero_class_yami_01: 'Куноити',
    hero_class_yami_02: 'Изгнанница-убийца',
    hero_class_yami_03: 'Клинок короля',
    hero_yami_bio_01:
      'Неразговорчивая наемная убийца, жаждущая мести. На вечеринки лучше не приглашать.',
    hero_yami_bio_02:
      'Когда-то она была убийцей Ордена, но больше нет. Неудач там не терпят.',
    hero_yami_bio_03:
      'Любой, кто покушается на жизнь короля, должен дважды подумать, ведь его Клинок таится в каждой тени.',
    hero_class_rudo_01: 'Одинокий воин',
    hero_rudo_bio_01:
      'Неприветливый бывалый берсерк. Не любит толочь воду в ступе, но вполне может намять вам бока.',
    hero_class_polonia_01: 'Сухопутник',
    hero_class_polonia_02: 'Капитан пиратов',
    hero_polonia_bio_01:
      'Еще не совсем пират, так как не имеет корабля. Мечтает о разудалых приключениях на морских просторах.',
    hero_polonia_bio_02:
      'Кара морская, старающаяся сохранить легендарное наследие Баросса Дель Монте.',
    hero_class_donovan_01: 'Имперский алхимик',
    hero_donovan_bio_01:
      'Искусный алхимик из империи Керн. Известный мастер всех форм превращения и алхимии.',
    hero_class_donovan_02: 'Прославленный алхимик',
    hero_donovan_bio_02:
      'Искусный алхимик из империи Керн. Известный мастер всех форм превращения и алхимии.',
    hero_class_hemma_01: 'Некромант',
    hero_hemma_bio_01:
      'Окутанная тенями и тайнами, Хемма управляет темными силами, которые мало кому понятны.',
    hero_class_hemma_02: 'Одержимая некромантка',
    hero_hemma_bio_02:
      'Похоже, внутри Хеммы поселилось какое-то таинственное существо. Неужели они состоят во взаимовыгодных отношениях?',
    hero_class_hemma_03: 'Возвышенная некромантка',
    hero_hemma_bio_03:
      'Бывают судьбы хуже смерти. Чтобы пережить такое испытание, нужна невообразимая стойкость и решимость.',
    hero_class_ashley_01: 'Ронин',
    hero_ashley_bio_01:
      'Странствующая девушка-самурай из Азумы. Потеряла руку, но не позволяет, чтобы это как-то ей мешало.',
    hero_class_atlab_aang_01: 'Аватар',
    hero_atlab_aang_bio_01:
      'Последний повелитель воздуха, а также Аватар, способный управлять четырьмя стихиями. Ему судьбой предназначено спасти мир.',
    hero_class_atlab_sokka_01: 'Воин Племени Воды',
    hero_atlab_sokka_bio_01:
      'Остроумный и верный. Присоединился к Аангу для спасения мира.',
    hero_class_leather_01: 'Кожевник',
    hero_leather_bio_01: 'Почин у поистине легендарных героев скромный.',
    hero_class_king_01: 'Король',
    hero_king_bio_01:
      'Силен больше в любви, чем в драке. Требует наличия большой свиты, если отправляется за пределы города.',
    hero_class_bjorn_01: 'Проводник',
    hero_bjorn_bio_01:
      'Неразлучный дуэт лесных охотников. Знаменитые эксперты по исследованию неизведанных подземелий.',
    classtype_fighter: 'Воин',
    classtype_rogue: 'Странник',
    classtype_spellcaster: 'Заклинатель',
    hero_skill_argonleader1_name: 'Аура храбрости',
    hero_skill_argonleader1_desc: '«Все ко мне!»',
    hero_skill_argonleader1_effect:
      '+10% к атаке всей команды. +10% к защите всей команды.',
    hero_skill_argonleader1_effect_0:
      '<sprite=4> <color=#24E656FF> +10%</color> к атаке всей команды.',
    hero_skill_argonleader1_effect_1:
      '<sprite=5> <color=#24E656FF> +10%</color> к защите всей команды.',
    hero_skill_argonleader2_name: 'Аура решимости',
    hero_skill_argonleader2_desc: '«Я стану щитом для тех, у кого его нет.»',
    hero_skill_argonleader2_effect:
      '+20% к атаке всей команды. +20% к защите всей команды.',
    hero_skill_argonleader2_effect_0:
      '<sprite=4> <color=#24E656FF> +20%</color> к атаке всей команды.',
    hero_skill_argonleader2_effect_1:
      '<sprite=5> <color=#24E656FF> +20%</color> к защите всей команды.',
    hero_skill_argonleader3_name: 'Аура героизма',
    hero_skill_argonleader3_desc:
      '«Моя воля тверда и незыблема. Я иду туда, где ждет зло».',
    hero_skill_argonleader3_effect: '+30% к атаке и защите всей команды.',
    hero_skill_argonleader3_effect_0:
      '<sprite=4> <color=#24E656FF>+30%</color> к атаке всей команды.',
    hero_skill_argonleader3_effect_1:
      '<sprite=5> <color=#24E656FF>+30%</color> к защите всей команды.',
    hero_skill_argonleader4_name: 'Аура паладина',
    hero_skill_argonleader4_desc:
      '«Пока я жив, никто и ничто не пройдет мимо меня!»',
    hero_skill_argonleader4_effect:
      '+40% к атаке всей команды. +40% к защите всей команды.',
    hero_skill_argonleader4_effect_0:
      '<sprite=4> <color=#24E656FF> +40%</color> к атаке всей команды.',
    hero_skill_argonleader4_effect_1:
      '<sprite=5> <color=#24E656FF> +40%</color> к защите всей команды.',
    hero_skill_sialeader1_name: 'Глаз на наживу',
    hero_skill_sialeader1_desc: '«Что это там за красивая штучка?»',
    hero_skill_sialeader1_effect:
      '+25% к количеству компонентов, найденных в приключениях. +10% к атаке всей команды.',
    hero_skill_sialeader1_effect_0:
      '<sprite=13> <color=#24E656FF>+25%</color> к количеству компонентов, найденных в приключениях.',
    hero_skill_sialeader1_effect_1:
      '<sprite=4> <color=#24E656FF>+10%</color> к атаке всей команды.',
    hero_skill_sialeader2_name: 'Тренированный глаз',
    hero_skill_sialeader2_desc: '«Это уж как посмотреть».',
    hero_skill_sialeader2_effect:
      '+50% к количеству компонентов, найденных в приключениях. +15% к атаке всей команды.',
    hero_skill_sialeader2_effect_0:
      '<sprite=13> <color=#24E656FF>+50%</color> к количеству компонентов, найденных в приключениях.',
    hero_skill_sialeader2_effect_1:
      '<sprite=4> <color=#24E656FF>+15%</color> к атаке всей команды.',
    hero_skill_sialeader3_name: 'Глаз-алмаз',
    hero_skill_sialeader3_desc:
      '«Выкидывай этот мусор, нам надо только лучшее!»',
    hero_skill_sialeader3_effect:
      '+75% к количеству компонентов, найденных в приключениях. +20% к атаке всей команды.',
    hero_skill_sialeader3_effect_0:
      '<sprite=13> <color=#24E656FF>+75%</color> к количеству компонентов, найденных в приключениях.',
    hero_skill_sialeader3_effect_1:
      '<sprite=4> <color=#24E656FF>+20%</color> к атаке всей команды.',
    hero_skill_sialeader4_name: 'Глаз смотрителя',
    hero_skill_sialeader4_desc: '«Чушь! Оно как новое!»',
    hero_skill_sialeader4_effect:
      '+100% к количеству компонентов, найденных в приключениях. +25% к атаке всей команды.',
    hero_skill_sialeader4_effect_0:
      '<sprite=13> <color=#24E656FF>+100%</color> к количеству компонентов, найденных в приключениях.',
    hero_skill_sialeader4_effect_1:
      '<sprite=4> <color=#24E656FF>+25%</color> к атаке всей команды.',
    hero_skill_liluleader1_name: 'Исцеление ран',
    hero_skill_liluleader1_desc: '«Немного поболит, но пройдет!»',
    hero_skill_liluleader1_effect:
      'Каждый ход исцеляет команду на 3 очка здоровья. +10% здоровья. Отдых ускорен на +10%.',
    hero_skill_liluleader1_effect_0:
      '<sprite=24> Каждый ход исцеляет команду на <color=#24E656FF>3 очка здоровья</color>.',
    hero_skill_liluleader1_effect_1:
      '<sprite=3> <color=#24E656FF>+10%</color> к здоровью для всей команды.',
    hero_skill_liluleader1_effect_2:
      '<sprite=11> Отдых ускорен на <color=#24E656FF>10%</color>.',
    hero_skill_liluleader2_name: 'Божественная сила',
    hero_skill_liluleader2_desc: '«Духи света, молю вас о помощи!»',
    hero_skill_liluleader2_effect:
      'Каждый ход исцеляет команду на 5 очков здоровья. +15% здоровья. Отдых ускорен на +20%.',
    hero_skill_liluleader2_effect_0:
      '<sprite=24> Каждый ход исцеляет команду на <color=#24E656FF>5 очков здоровья</color>.',
    hero_skill_liluleader2_effect_1:
      '<sprite=3> <color=#24E656FF>+15%</color> к здоровью для всей команды.',
    hero_skill_liluleader2_effect_2:
      '<sprite=11> Отдых ускорен на <color=#24E656FF>20%</color>.',
    hero_skill_liluleader3_name: 'Восстановление сил',
    hero_skill_liluleader3_desc:
      '«Как себя чувствуешь? Это мое собственное заклинание!»',
    hero_skill_liluleader3_effect:
      'Каждый ход исцеляет команду на 10 очков здоровья. +20% здоровья. Отдых ускорен на +35%.',
    hero_skill_liluleader3_effect_0:
      '<sprite=24> Каждый ход исцеляет команду на <color=#24E656FF>10 очков здоровья</color>.',
    hero_skill_liluleader3_effect_1:
      '<sprite=3> <color=#24E656FF>+20%</color> к здоровью для всей команды.',
    hero_skill_liluleader3_effect_2:
      '<sprite=11> Отдых ускорен на <color=#24E656FF>35%</color>.',
    hero_skill_liluleader4_name: 'Круг святости',
    hero_skill_liluleader4_desc: '«Он немного неустойчив... но какая сила!»',
    hero_skill_liluleader4_effect:
      'Каждый ход исцеляет команду на 20 очков здоровья. +25% здоровья. Отдых ускорен на +50%.',
    hero_skill_liluleader4_effect_0:
      '<sprite=24> Каждый ход исцеляет команду на <color=#24E656FF>20 очков здоровья</color>.',
    hero_skill_liluleader4_effect_1:
      '<sprite=3> <color=#24E656FF>+25%</color> к здоровью для всей команды.',
    hero_skill_liluleader4_effect_2:
      '<sprite=11> Отдых ускорен на <color=#24E656FF>50%</color>.',
    hero_skill_yamileader1_name: 'Засада',
    hero_skill_yamileader1_desc:
      '«Ты идешь со мной. А вы ведите себя, как обычно. То есть шумно».',
    hero_skill_yamileader1_effect:
      'Приключения ускорены на 10%. +5% к шансу крит. удара всей команды. +5% к уклонению для всей команды.',
    hero_skill_yamileader1_effect_0:
      '<sprite=18> Приключения ускорены на <color=#24E656FF>10%</color>.',
    hero_skill_yamileader1_effect_1:
      '<sprite=19> <color=#24E656FF>+5%</color> к шансу крит. удара всей команды.',
    hero_skill_yamileader1_effect_2:
      '<sprite=6> <color=#24E656FF>+5%</color> к уклонению для всей команды.',
    hero_skill_yamileader2_name: 'Спланированная засада',
    hero_skill_yamileader2_desc:
      '«Сосредоточьтесь на задаче и забудьте обо всем остальном.»',
    hero_skill_yamileader2_effect:
      'Приключения ускорены на 20%. +10% к шансу крит. удара всей команды. +10% к уклонению для всей команды.',
    hero_skill_yamileader2_effect_0:
      '<sprite=18> Приключения ускорены на <color=#24E656FF>20%</color>.',
    hero_skill_yamileader2_effect_1:
      '<sprite=19> <color=#24E656FF>+10%</color> к шансу крит. удара всей команды.',
    hero_skill_yamileader2_effect_2:
      '<sprite=6> <color=#24E656FF>+10%</color> к уклонению для всей команды.',
    hero_skill_yamileader3_name: 'Сверхъестественная засада',
    hero_skill_yamileader3_desc:
      '«Лиши их восприятия, и их тут же настигнет страх.»',
    hero_skill_yamileader3_effect:
      'Приключения ускорены на 35%. +15% к шансу крит. удара всей команды. +15% к уклонению для всей команды.',
    hero_skill_yamileader3_effect_0:
      '<sprite=18> Приключения ускорены на <color=#24E656FF>35%</color>.',
    hero_skill_yamileader3_effect_1:
      '<sprite=19> <color=#24E656FF>+15%</color> к шансу крит. удара всей команды.',
    hero_skill_yamileader3_effect_2:
      '<sprite=6> <color=#24E656FF>+15%</color> к уклонению для всей команды.',
    hero_skill_yamileader4_name: 'Идеальная засада',
    hero_skill_yamileader4_desc: '«Не отставайте, иначе уйду вперед.»',
    hero_skill_yamileader4_effect:
      'Приключения ускорены на +50%. +20% к шансу крит. удара всей команды. +20% к уклонению для всей команды.',
    hero_skill_yamileader4_effect_0:
      '<sprite=18> Приключения ускорены на <color=#24E656FF>50%</color>.',
    hero_skill_yamileader4_effect_1:
      '<sprite=19> <color=#24E656FF>+20%</color> к шансу крит. удара всей команды.',
    hero_skill_yamileader4_effect_2:
      '<sprite=6> <color=#24E656FF>+20%</color> к уклонению для всей команды.',
    hero_skill_rudoleader1_name: 'Блиц-атака Рудо',
    hero_skill_rudoleader1_desc: '«Хватит болтать! Пора что-нибудь прибить!»',
    hero_skill_rudoleader1_effect:
      '+30% к шансу крит. удара и -100% от шанса повреждения для всей команды. Действует 2 раунда.',
    hero_skill_rudoleader1_effect_0:
      '<sprite=19> <color=#24E656FF>+30%</color> к шансу крит. удара',
    hero_skill_rudoleader1_effect_1:
      '<sprite=12> <color=#24E656FF>-50%</color> от шанса повреждения для всей команды.',
    hero_skill_rudoleader1_effect_2: '<sprite=17> длится 2 хода.',
    hero_skill_rudoleader2_name: 'Настойчивый штурм',
    hero_skill_rudoleader2_desc:
      '«Держись от меня на безопасном расстоянии. Для собственной же безопасности».',
    hero_skill_rudoleader2_effect:
      '+40% к шансу крит. удара и -100% от шанса повреждения для всей команды. Действует 2 раунда.',
    hero_skill_rudoleader2_effect_0:
      '<sprite=19> <color=#24E656FF>+40%</color> к шансу крит. удара',
    hero_skill_rudoleader2_effect_1:
      '<sprite=12> <color=#24E656FF>-100%</color> от шанса повреждения для всей команды.',
    hero_skill_rudoleader2_effect_2: '<sprite=17> длится 2 хода.',
    hero_skill_rudoleader3_name: 'Буйство берсерка',
    hero_skill_rudoleader3_desc:
      '«Думаешь, у тебя есть все необходимое, чтобы быть воином? ДОКАЖИ!»',
    hero_skill_rudoleader3_effect:
      'Бонусы к шансу критического удара и урону стихийным барьерам, и отсутствие повреждений предметов на 3 раунда.',
    hero_skill_rudoleader3_effect_0:
      '<sprite=19><sprite=12> <color=#24E656FF>+40%</color> к шансу критического удара и <color=#24E656FF>-100%</color> от шанса повреждения для всей команды.',
    hero_skill_rudoleader3_effect_1:
      '<sprite=4><color=#24E656FF>+50%</color> к урону, наносимого командой стихийным барьерам.',
    hero_skill_rudoleader3_effect_2: '<sprite=17> длится 3 хода.',
    hero_skill_rudoleader4_name: 'Полный беспредел',
    hero_skill_rudoleader4_desc: '«ГУА-ХА-ХА-ХА!!!»',
    hero_skill_rudoleader4_effect:
      'Бонусы к шансу критическоно удара и урону стихийным барьерам, и отсутствие повреждений предметов на 4 раунда.',
    hero_skill_rudoleader4_effect_0:
      '<sprite=19><sprite=12> <color=#24E656FF>+50%</color> к шансу критического удара и <color=#24E656FF>-100%</color> от шанса повреждения для всей команды.',
    hero_skill_rudoleader4_effect_1:
      '<sprite=4><color=#24E656FF>+50%</color> к урону, наносимого командой стихийным барьерам.',
    hero_skill_rudoleader4_effect_2: '<sprite=17> длится 4 хода.',
    hero_skill_polonialeader1_name: 'Пиратская награда',
    hero_skill_polonialeader1_desc: '«Полундра, братцы! Обчистим их до нитки!»',
    hero_skill_polonialeader1_effect:
      '30% шанс украсть у монстров. +10% к защите всей команды. +5% к уклонению для всей команды.',
    hero_skill_polonialeader1_effect_0:
      '<sprite=13> <color=#24E656FF>30%</color> шанс украсть у монстров.',
    hero_skill_polonialeader1_effect_1:
      '<sprite=5> <color=#24E656FF>+10%</color> к защите всей команды.',
    hero_skill_polonialeader1_effect_2:
      '<sprite=6> <color=#24E656FF>+5%</color> к уклонению для всей команды.',
    hero_skill_polonialeader2_name: 'Водный бандитизм',
    hero_skill_polonialeader2_desc: '«Берем все, что не прибито гвоздями!»',
    hero_skill_polonialeader2_effect:
      '35% шанс украсть у монстров. +15% к защите всей команды. +5% к уклонению для всей команды.',
    hero_skill_polonialeader2_effect_0:
      '<sprite=13> <color=#24E656FF>35%</color> шанс украсть ценные предметы у монстров.',
    hero_skill_polonialeader2_effect_1:
      '<sprite=5> <color=#24E656FF>+15%</color> к защите всей команды.',
    hero_skill_polonialeader2_effect_2:
      '<sprite=6> <color=#24E656FF>+5%</color> к уклонению для всей команды.',
    hero_skill_polonialeader3_name: 'Морской грабеж',
    hero_skill_polonialeader3_desc: '«Я чую запах дублонов, cалаги! За дело!»',
    hero_skill_polonialeader3_effect:
      '40% шанс украсть очень ценные предметы у монстров. +20% к защите всей команды. +10% к уклонению для всей команды.',
    hero_skill_polonialeader3_effect_0:
      '<sprite=13> <color=#24E656FF>40%</color> шанс украсть очень ценные предметы у монстров.',
    hero_skill_polonialeader3_effect_1:
      '<sprite=5> <color=#24E656FF>+20%</color> к защите всей команды.',
    hero_skill_polonialeader3_effect_2:
      '<sprite=6> <color=#24E656FF>+10%</color> к уклонению для всей команды.',
    hero_skill_polonialeader4_name: 'Капитанская доля',
    hero_skill_polonialeader4_desc: '«Две доли мне будет по-честному!»',
    hero_skill_polonialeader4_effect:
      '50% шанс украсть суперценные предметы у монстров. +25% к защите всей команды. +10% к уклонению для всей команды.',
    hero_skill_polonialeader4_effect_0:
      '<sprite=13> <color=#24E656FF>50%</color> шанс украсть суперценные предметы у монстров.',
    hero_skill_polonialeader4_effect_1:
      '<sprite=5> <color=#24E656FF>+25%</color> к защите всей команды.',
    hero_skill_polonialeader4_effect_2:
      '<sprite=6> <color=#24E656FF>+10%</color> к уклонению для всей команды.',
    hero_skill_donovanleader1_name: 'Тоник алхимика',
    hero_skill_donovanleader1_desc:
      '«На время придаст сил. В основном это к лучшему».',
    hero_skill_donovanleader1_effect:
      'Бонусы команды к атаке, здоровью, шансу крит. удара и уклонению в зависимости от состава команды.',
    hero_skill_donovanleader1_effect_0:
      '<sprite=4> <color=#24E656FF>+5%</color> к атаке команды за каждого заклинателя.',
    hero_skill_donovanleader1_effect_1:
      '<sprite=3> <color=#24E656FF>+5%</color> к здоровью команды за каждого воина.',
    hero_skill_donovanleader1_effect_2:
      '<sprite=19> <sprite=6> <color=#24E656FF>+3%</color> к шансу крит. удара и уклонению за каждого странника.',
    hero_skill_donovanleader2_name: 'Эссенция изменений',
    hero_skill_donovanleader2_desc:
      '«Приветствуйте изменения внутри вас. Все равно вы не сможете их предотвратить».',
    hero_skill_donovanleader2_effect:
      'Бонусы команды к атаке, здоровью, шансу крит. удара и уклонению в зависимости от состава команды.',
    hero_skill_donovanleader2_effect_0:
      '<sprite=4> <color=#24E656FF>+8%</color> к атаке команды за каждого заклинателя.',
    hero_skill_donovanleader2_effect_1:
      '<sprite=3> <color=#24E656FF>+6%</color> к здоровью команды за каждого воина.',
    hero_skill_donovanleader2_effect_2:
      '<sprite=19> <sprite=6> <color=#24E656FF>+4%</color> к шансу крит. удара и уклонению за каждого странника. ',
    hero_skill_donovanleader3_name: 'Мастер превращений',
    hero_skill_donovanleader3_desc:
      '«Мне еще не доводилось видеть, чтобы что-то при правильном подходе нельзя было переделать».',
    hero_skill_donovanleader3_effect:
      'Бонусы команды к атаке, здоровью, шансу крит. удара и уклонению в зависимости от состава команды.',
    hero_skill_donovanleader3_effect_0:
      '<sprite=4> <color=#24E656FF>+10%</color> к атаке команды за каждого заклинателя.',
    hero_skill_donovanleader3_effect_1:
      '<sprite=3> <color=#24E656FF>+7%</color> к здоровью команды за каждого воина.',
    hero_skill_donovanleader3_effect_2:
      '<sprite=19> <sprite=6> <color=#24E656FF>+5%</color> к шансу крит. удара и уклонению за каждого странника.',
    hero_skill_donovanleader4_name: 'Философский камень',
    hero_skill_donovanleader4_desc:
      '«Пусть ваша слабость станет силой... десятикратной!»',
    hero_skill_donovanleader4_effect:
      'Бонусы команды к атаке, здоровью, шансу крит. удара и уклонению в зависимости от состава команды.',
    hero_skill_donovanleader4_effect_0:
      '<sprite=4> <color=#24E656FF>+14%</color> к атаке команды за каждого заклинателя.',
    hero_skill_donovanleader4_effect_1:
      '<sprite=3> <color=#24E656FF>+10%</color> к здоровью команды за каждого воина.',
    hero_skill_donovanleader4_effect_2:
      '<sprite=19> <sprite=6> <color=#24E656FF>+7%</color> к шансу крит. удара и уклонению за каждого странника.',
    hero_skill_hemmaleader1_name: 'Усиливающее высасывание',
    hero_skill_hemmaleader1_desc: '«Мы позаимствуем это на минутку...»',
    hero_skill_hemmaleader1_effect:
      'Из ваших спутников высасывается жизнь, усиливая атаки Хеммы. +5% к здоровью для всей команды. ',
    hero_skill_hemmaleader1_effect_0:
      'На каждом ходу вы высасываете <color=#24E656FF>10%</color> ЗДР у каждого союзника, восстанавливая <color=#24E656FF>2 ЗДР</color> себе.',
    hero_skill_hemmaleader1_effect_1:
      '<sprite=4><color=#24E656FF>Атака</color> Хеммы усиливается на <color=#24E656FF>6%</color> при каждом высасывании.',
    hero_skill_hemmaleader1_effect_2:
      '<sprite=3> <color=#24E656FF>+5%</color> к здоровью для всей команды.',
    hero_skill_hemmaleader2_name: 'Жуткий удар',
    hero_skill_hemmaleader2_desc: '«Недолго ты будешь стоять у нас на пути...»',
    hero_skill_hemmaleader2_effect:
      'Из ваших спутников высасывается жизнь, усиливая атаки Хеммы. +10% к здоровью для всей команды. ',
    hero_skill_hemmaleader2_effect_0:
      'На каждом ходу вы высасываете <color=#24E656FF>9%</color> ЗДР у каждого союзника, восстанавливая <color=#24E656FF>3 ЗДР</color> себе.',
    hero_skill_hemmaleader2_effect_1:
      '<sprite=4><color=#24E656FF>Атака</color> Хеммы увеличивается на <color=#24E656FF>8%</color> при каждом высасывании.',
    hero_skill_hemmaleader2_effect_2:
      '<sprite=3> <color=#24E656FF>+10%</color> к здоровью для всей команды.',
    hero_skill_hemmaleader3_name: 'Копье некроманта',
    hero_skill_hemmaleader3_desc: '«Твоя сила теперь принадлежит нам!»',
    hero_skill_hemmaleader3_effect:
      'Из ваших спутников высасывается жизнь, усиливая атаки Хеммы. +15% к здоровью для всей команды. ',
    hero_skill_hemmaleader3_effect_0:
      'На каждом ходу вы высасываете <color=#24E656FF>8%</color> ЗДР у каждого союзника, восстанавливая <color=#24E656FF>5 ЗДР</color> себе.',
    hero_skill_hemmaleader3_effect_1:
      '<sprite=4><color=#24E656FF>Атака</color> Хеммы увеличивается на <color=#24E656FF>10%</color> при каждом высасывании.',
    hero_skill_hemmaleader3_effect_2:
      '<sprite=3> <color=#24E656FF>+15%</color> к здоровью для всей команды.',
    hero_skill_hemmaleader4_name: 'Поглощение сущности',
    hero_skill_hemmaleader4_desc:
      '«Каждая отнятая жизнь придает нам смелости. А мы поглотили немало».',
    hero_skill_hemmaleader4_effect:
      'Из ваших спутников высасывается жизнь, усиливая атаки Хеммы. +25% к здоровью для всей команды. ',
    hero_skill_hemmaleader4_effect_0:
      'На каждом ходу вы высасываете <color=#24E656FF>7%</color> ЗДР у каждого союзника, восстанавливая <color=#24E656FF>8 ЗДР</color> себе.',
    hero_skill_hemmaleader4_effect_1:
      '<sprite=4><color=#24E656FF>Атака</color> Хеммы увеличивается на <color=#24E656FF>12%</color> при каждом высасывании.',
    hero_skill_hemmaleader4_effect_2:
      '<sprite=3> <color=#24E656FF>+25%</color> к здоровью для всей команды.',
    hero_skill_ashleyleader1_name: 'Тактика иайдзюцу',
    hero_skill_ashleyleader1_desc:
      '«Мне достаточно сделать один удар. Сможешь доказать, что я не права?»',
    hero_skill_ashleyleader1_effect:
      'Бонусы к АТК, ЗАЩ и скорости приключений всей команды. Двойные бонусы против боссов.',
    hero_skill_ashleyleader1_effect_0:
      '<sprite=4><sprite=5> <color=#24E656FF>+10%</color> к атаке и защите всей команды.',
    hero_skill_ashleyleader1_effect_1:
      '<sprite=18> Приключения ускорены на <color=#24E656FF>5%</color>.',
    hero_skill_ashleyleader1_effect_2:
      'Бонусы удваиваются в бою против боссов.',
    hero_skill_ashleyleader2_name: 'Уловка Эшли',
    hero_skill_ashleyleader2_desc:
      '«Если это может быть искуплением... я поставлю на кон все!»',
    hero_skill_ashleyleader2_effect:
      'Бонусы к АТК, ЗАЩ и скорости приключений всей команды. Двойные бонусы против боссов.',
    hero_skill_ashleyleader2_effect_0:
      '<sprite=4><sprite=5> <color=#24E656FF>+15%</color> к атаке и защите всей команды.',
    hero_skill_ashleyleader2_effect_1:
      '<sprite=18> Приключения ускорены на <color=#24E656FF>10%</color>.',
    hero_skill_ashleyleader2_effect_2:
      'Бонусы удваиваются в бою против боссов.',
    hero_skill_ashleyleader3_name: 'Пал величайший враг',
    hero_skill_ashleyleader3_desc: '«Моя честь на кону! Не подведи меня!»',
    hero_skill_ashleyleader3_effect:
      'Бонусы к АТК, ЗАЩ и скорости приключений всей команды. Двойные бонусы против боссов.',
    hero_skill_ashleyleader3_effect_0:
      '<sprite=4><sprite=5> <color=#24E656FF>+20%</color> к атаке и защите всей команды.',
    hero_skill_ashleyleader3_effect_1:
      '<sprite=18> Приключения ускорены на <color=#24E656FF>15%</color>.',
    hero_skill_ashleyleader3_effect_2:
      'Бонусы удваиваются в бою против боссов.',
    hero_skill_ashleyleader4_name: 'Сила бусидо',
    hero_skill_ashleyleader4_desc:
      '«Проверь, каков мой предел! Срази меня, если сможешь!»',
    hero_skill_ashleyleader4_effect:
      'Бонусы к АТК, ЗАЩ и скорости приключений всей команды. Двойные бонусы против боссов.',
    hero_skill_ashleyleader4_effect_0:
      '<sprite=4><sprite=5> <color=#24E656FF>+25%</color> к атаке и защите всей команды.',
    hero_skill_ashleyleader4_effect_1:
      '<sprite=18> Приключения ускорены на <color=#24E656FF>20%</color>.',
    hero_skill_ashleyleader4_effect_2:
      'Бонусы удваиваются в бою против боссов.',
    hero_skill_bjornleader1_name: 'Двойные неприятности',
    hero_skill_bjornleader1_desc: '"Regular monsters? Go get \'em, Bamham!"',
    hero_skill_bjornleader1_effect:
      'Bonuses to party\'s ATK, HP and Critical Damage. Bonuses are doubled during Flash Quests. Grants an extra Flash Quest attempt.',
    hero_skill_bjornleader1_effect_0:
      '<sprite=4><color=#24E656FF>+10%</color> Attack, <sprite=3><color=#24E656FF>+5%</color> HP and <color=#24E656FF>+20%</color> Critical Damage for the party.',
    hero_skill_bjornleader1_effect_1:
      'Bonuses are doubled during Flash Quests.',
    hero_skill_bjornleader1_effect_2: 'Grants an extra Flash Quest attempt.',
    hero_skill_bjornleader2_name: 'Ударная блиц-группа',
    hero_skill_bjornleader2_desc:
      '"In and out, leaving no trace behind! That\'s the ranger\'s way!"',
    hero_skill_bjornleader2_effect:
      'Bonuses to party\'s ATK, HP and Critical Damage. Bonuses are doubled during Flash Quests. Grants an extra Flash Quest attempt.',
    hero_skill_bjornleader2_effect_0:
      '<sprite=4><color=#24E656FF>+15%</color> Attack, <sprite=3><color=#24E656FF>+10%</color> HP and <color=#24E656FF>+30%</color> Critical Damage for the party.',
    hero_skill_bjornleader2_effect_1:
      'Bonuses are doubled during Flash Quests.',
    hero_skill_bjornleader2_effect_2: 'Grants an extra Flash Quest attempt.',
    hero_skill_bjornleader3_name: 'Born to be Wild',
    hero_skill_bjornleader3_desc:
      '"I know this place like the back of my hand. We\'ll set up an ambush here!"',
    hero_skill_bjornleader3_effect:
      'Bonuses to party\'s ATK, HP and Critical Damage. Bonuses are doubled during Flash Quests. Grants an extra Flash Quest attempt.',
    hero_skill_bjornleader3_effect_0:
      '<sprite=4><color=#24E656FF>+20%</color> Attack, <sprite=3><color=#24E656FF>+15%</color> HP and <color=#24E656FF>+40%</color> Critical Damage for the party.',
    hero_skill_bjornleader3_effect_1:
      'Bonuses are doubled during Flash Quests.',
    hero_skill_bjornleader3_effect_2: 'Grants an extra Flash Quest attempt.',
    hero_skill_bjornleader4_name: 'Клыкастая пара',
    hero_skill_bjornleader4_desc: '"Go for the eyes, Bamham! Go for the eyes!"',
    hero_skill_bjornleader4_effect:
      'Bonuses to party\'s ATK, HP and Critical Damage. Bonuses are doubled during Flash Quests. Grants an extra Flash Quest attempt.',
    hero_skill_bjornleader4_effect_0:
      '<sprite=4><color=#24E656FF>+30%</color> Attack, <sprite=3><color=#24E656FF>+20%</color> HP and <color=#24E656FF>+50%</color> Critical Damage for the party.',
    hero_skill_bjornleader4_effect_1:
      'Bonuses are doubled during Flash Quests.',
    hero_skill_bjornleader4_effect_2: 'Grants an extra Flash Quest attempt.',
    hero_skill_leatherleader1_name: 'Пробуждение кожи',
    hero_skill_leatherleader1_desc:
      '«О, привет, не заметил тебя, дружище. Я был занят тренировками, чтобы раскрыть весь потенциал кожи».',
    hero_skill_leatherleader1_effect:
      'Крайне случайный бонус к ATK команды. Также позволяет находить некоторые кожаные предметы после приключений.',
    hero_skill_leatherleader1_effect_0:
      '<sprite=4>Крайне случайный бонус к атаке команды, до <color=#24E656FF>+50%</color>.',
    hero_skill_leatherleader1_effect_1:
      'Позволяет находить несколько кожаных предметов в качестве бонусной добычи.',
    hero_skill_leatherleader2_name: 'Сила кожи',
    hero_skill_leatherleader2_desc: '«Это сила, скрытая внутри, дружище».',
    hero_skill_leatherleader2_effect:
      'Крайне случайный бонус к ATK команды. Также позволяет находить кучу кожаных предметов после приключений.',
    hero_skill_leatherleader2_effect_0:
      '<sprite=4>Крайне случайный бонус к атаке команды, до <color=#24E656FF>+100%</color>.',
    hero_skill_leatherleader2_effect_1:
      'Позволяет находить некоторое количество кожаных предметов в качестве бонусной добычи.',
    hero_skill_leatherleader3_name: 'Лига кожи',
    hero_skill_leatherleader3_desc:
      '«Дружище, мы все сделаны из кожи. Я так думаю».',
    hero_skill_leatherleader3_effect:
      'Крайне случайный бонус к ATK команды. Также позволяет находить кучу кожаных предметов после приключений.',
    hero_skill_leatherleader3_effect_0:
      '<sprite=4>Крайне случайный бонус к атаке команды, до <color=#24E656FF>+150%</color>.',
    hero_skill_leatherleader3_effect_1:
      'Позволяет находить некоторое количество кожаных предметов в качестве бонусной добычи.',
    hero_skill_leatherleader4_name: 'Абсолютный мастер кожи',
    hero_skill_leatherleader4_desc:
      '«Быть защитником не так уж и трудно, дружище».',
    hero_skill_leatherleader4_effect:
      'Крайне случайный бонус к ATK команды. Также позволяет находить множество кожаных предметов после приключений!',
    hero_skill_leatherleader4_effect_0:
      '<sprite=4>Крайне случайный бонус к атаке команды до <color=#24E656FF>+200%</color>.',
    hero_skill_leatherleader4_effect_1:
      'Позволяет находить множество кожаных предметов в качестве бонусной добычи.',
    hero_skill_kingleader1_name: 'Вояж Рейнгольда',
    hero_skill_kingleader1_desc:
      '«Королевская стража! Защитите своего короля!»',
    hero_skill_kingleader1_effect:
      'Маленькие бонусы к параметрам команды. Позволяет находить некоторое количество необычных предметов, если Рейнгольд выживает в приключениях.',
    hero_skill_kingleader1_effect_0:
      '<sprite=4><sprite=5><sprite=3>Бонус <color=#24E656FF>+10%</color> к АТК, ЗАЩ и ЗДР всей команды.',
    hero_skill_kingleader1_effect_1:
      '<sprite=13>Позволяет находить множество необычных предметов в качестве бонусной добычи, если Рейнгольд выживает.',
    hero_skill_kingleader2_name: 'Королевская вылазка',
    hero_skill_kingleader2_desc:
      '«Нет ничего лучше утренней прогулки по подземелью, чтобы размять ноги!»',
    hero_skill_kingleader2_effect:
      'Бонусы к параметрам команды. Позволяет находить необычные предметы, если Рейнгольд выживает в приключениях.',
    hero_skill_kingleader2_effect_0:
      '<sprite=4><sprite=5><sprite=3>Бонус <color=#24E656FF>+15%</color> к АТК, ЗАЩ и ЗДР всей команды.',
    hero_skill_kingleader2_effect_1:
      '<sprite=13>Позволяет находить множество необычных предметов в качестве бонусной добычи, если Рейнгольд выживает.',
    hero_skill_kingleader3_name: 'Деньги к деньгам',
    hero_skill_kingleader3_desc:
      '«Отправляемся в путь! Мой королевский дух не может так долго бездействовать!»',
    hero_skill_kingleader3_effect:
      'Повышенные бонусы к параметрам команды. Позволяет находить немало необычных предметов, если Рейнгольд выживает в приключениях.',
    hero_skill_kingleader3_effect_0:
      '<sprite=4><sprite=5><sprite=3>Бонус <color=#24E656FF>+20%</color> к АТК, ЗАЩ и ЗДР всей команды.',
    hero_skill_kingleader3_effect_1:
      '<sprite=13>Позволяет находить множество необычных предметов в качестве бонусной добычи, если Рейнгольд выживает.',
    hero_skill_kingleader4_name: 'Богатство мира',
    hero_skill_kingleader4_desc:
      '«Какие чудесные приспособления! А если я коснусь этого рычага?..»',
    hero_skill_kingleader4_effect:
      'Значительные бонусы к параметрам команды. Позволяет находить множество необычных предметов, если Рейнгольд выживает в приключениях.',
    hero_skill_kingleader4_effect_0:
      '<sprite=4><sprite=5><sprite=3>Бонус <color=#24E656FF>+30%</color> к АТК, ЗАЩ и ЗДР всей команды.',
    hero_skill_kingleader4_effect_1:
      '<sprite=13>Позволяет находить множество необычных предметов в качестве бонусной добычи, если Рейнгольд выживает.',
    hero_skill_atlab_aangleader1_name: 'Повелитель стихий',
    hero_skill_atlab_aangleader1_desc: '«Я все время смеюсь над гравитацией!»',
    hero_skill_atlab_aangleader1_effect:
      'Маленький бонус к АТК и ЗАЩ команды. Также позволяет находить некоторые стихии после приключений.',
    hero_skill_atlab_aangleader1_effect_0:
      '<sprite=4> <color=#24E656FF> +20%</color> к атаке всей команды.',
    hero_skill_atlab_aangleader1_effect_1:
      '<sprite=5> <color=#24E656FF> +20%</color> к защите всей команды.',
    hero_skill_atlab_aangleader1_effect_2:
      'Получите несколько стихий в качестве бонусной добычи.',
    hero_skill_atlab_aangleader2_name: 'Мастерство стихий',
    hero_skill_atlab_aangleader2_desc: '«Я все время смеюсь над гравитацией!»',
    hero_skill_atlab_aangleader2_effect:
      'Бонус к АТК и ЗАЩ команды. Также позволяет находить кучу стихий после приключений.',
    hero_skill_atlab_aangleader2_effect_0:
      '<sprite=4> <color=#24E656FF> +30%</color> к атаке всей команды.',
    hero_skill_atlab_aangleader2_effect_1:
      '<sprite=5><color=#24E656FF> +30%</color> к защите всей команды.',
    hero_skill_atlab_aangleader2_effect_2:
      'Получите некоторые стихии в качестве бонусной добычи.',
    hero_skill_atlab_aangleader3_name: 'Состояние Аватара',
    hero_skill_atlab_aangleader3_desc: '«Мы все связаны. Все связано».',
    hero_skill_atlab_aangleader3_effect:
      'Значительный бонус к АТК и ЗАЩ команды. Также позволяет находить кучу стихий после приключений.',
    hero_skill_atlab_aangleader3_effect_0:
      '<sprite=4> <color=#24E656FF> +40%</color> к атаке всей команды.',
    hero_skill_atlab_aangleader3_effect_1:
      '<sprite=5> <color=#24E656FF> +40%</color> к защите всей команды.',
    hero_skill_atlab_aangleader3_effect_2:
      'Получите некоторые стихии в качестве бонусной добычи.',
    hero_skill_atlab_aangleader4_name: 'Гнев Аватара',
    hero_skill_atlab_aangleader4_desc: '«Мы все связаны. Все связано».',
    hero_skill_atlab_aangleader4_effect:
      'Большой бонус к АТК и ЗАЩ команды. Также позволяет находить множество стихий после приключений.',
    hero_skill_atlab_aangleader4_effect_0:
      '<sprite=4> <color=#24E656FF> +50%</color> к атаке всей команды.',
    hero_skill_atlab_aangleader4_effect_1:
      '<sprite=5> <color=#24E656FF> +50%</color> к защите всей команды.',
    hero_skill_atlab_aangleader4_effect_2:
      'Получите множество стихий в качестве бонусной добычи.',
    hero_skill_atlab_sokkaleader1_name: 'Охотник-собиратель',
    hero_skill_atlab_sokkaleader1_desc:
      '«Бумеранг! Ты действительно всегда возвращаешься!»',
    hero_skill_atlab_sokkaleader1_effect:
      '+10% к атаке и ЗДР всей команды. +50% к ОП всей команды. +50% к найденным компонентам.',
    hero_skill_atlab_sokkaleader1_effect_0:
      '<sprite=4><sprite=3><color=#24E656FF> +10%</color> к атаке и ЗДР всей команды.',
    hero_skill_atlab_sokkaleader1_effect_1:
      '<sprite=13> <color=#24E656FF>+50%</color> к количеству компонентов, найденных в приключениях.',
    hero_skill_atlab_sokkaleader1_effect_2:
      '<color=#24E656FF>+50%</color> к ОП, заработанному всей командой.',
    hero_skill_atlab_sokkaleader2_name: 'Полевая разведка',
    hero_skill_atlab_sokkaleader2_desc:
      '«Бумеранг! Ты действительно всегда возвращаешься!»',
    hero_skill_atlab_sokkaleader2_effect:
      '+15% к атаке и ЗДР всей команды. +50% к ОП всей команды. +50% к найденным компонентам.',
    hero_skill_atlab_sokkaleader2_effect_0:
      '<sprite=4><sprite=3><color=#24E656FF> +15%</color> к атаке и ЗДР всей команды.',
    hero_skill_atlab_sokkaleader2_effect_1:
      '<sprite=13> <color=#24E656FF>+50%</color> к количеству компонентов, найденных в приключениях.',
    hero_skill_atlab_sokkaleader2_effect_2:
      '<color=#24E656FF>+50%</color> к ОП, заработанному всей командой.',
    hero_skill_atlab_sokkaleader3_name: 'Тактика команды Аватара',
    hero_skill_atlab_sokkaleader3_desc:
      '«Это практически вся моя личность. Сокка, любитель мяса и сарказма».',
    hero_skill_atlab_sokkaleader3_effect:
      '+20% к атаке и ЗДР всей команды. +75% к ОП всей команды. +75% к найденным компонентам.',
    hero_skill_atlab_sokkaleader3_effect_0:
      '<sprite=4><sprite=3><color=#24E656FF> +20%</color> к атаке и ЗДР всей команды.',
    hero_skill_atlab_sokkaleader3_effect_1:
      '<sprite=13> <color=#24E656FF>+75%</color> к количеству компонентов, найденных в приключениях.',
    hero_skill_atlab_sokkaleader3_effect_2:
      '<color=#24E656FF>+75%</color> к ОП, заработанному всей командой.',
    hero_skill_atlab_sokkaleader4_name: 'Идеальный план',
    hero_skill_atlab_sokkaleader4_desc:
      '«Это практически вся моя личность. Сокка, любитель мяса и сарказма».',
    hero_skill_atlab_sokkaleader4_effect:
      '+30% к атаке и ЗДР всей команды. +100% к ОП всей команды. +75% к найденным компонентам.',
    hero_skill_atlab_sokkaleader4_effect_0:
      '<sprite=4><sprite=3><color=#24E656FF> +30%</color> к атаке и ЗДР всей команды.',
    hero_skill_atlab_sokkaleader4_effect_1:
      '<sprite=13> <color=#24E656FF>+75%</color> к количеству компонентов, найденных в приключениях.',
    hero_skill_atlab_sokkaleader4_effect_2:
      '<color=#24E656FF>+100%</color> к ОП, заработанному всей командой.',
    skill_p_cleave1_name: 'Рассекание',
    skill_p_cleave2_name: 'Обжигающее рассекание',
    skill_p_cleave3_name: 'Опаляющий циклон',
    skill_p_cleave4_name: 'Адская буря',
    skill_p_cleave_desc:
      'Еще можно рубить, шинковать и кромсать. Разборчивость тут ни к чему.',
    skill_p_perforate1_name: 'Протыкание',
    skill_p_perforate2_name: 'Прокалывание',
    skill_p_perforate3_name: 'Звуковой удар',
    skill_p_perforate4_name: 'Сверхзвуковой удар',
    skill_p_perforate_desc:
      'Если в противнике наделать дырок, есть шанс сократить продолжительность его жизни.',
    skill_p_smite1_name: 'Удар небес',
    skill_p_smite2_name: 'Праведный удар',
    skill_p_smite3_name: 'Освобождение',
    skill_p_smite4_name: 'Гнев богини',
    skill_p_smite_desc: 'Иногда и Богиня бывает мстительной.',
    skill_p_onguard1_name: 'На страже',
    skill_p_onguard2_name: 'Морозная стойка',
    skill_p_onguard3_name: 'Неудержимый ледник',
    skill_p_onguard4_name: 'Первобытный ледник',
    skill_p_onguard_desc: 'Мы на страже ради тебя.',
    skill_p_backstab1_name: 'Удар в спину',
    skill_p_backstab2_name: 'Темный преследователь',
    skill_p_backstab3_name: 'Вдоводел',
    skill_p_backstab4_name: 'Отнятие жизни',
    skill_p_backstab_desc: 'Немного бесчестно.',
    skill_p_magicdarts1_name: 'Магические дротики',
    skill_p_magicdarts2_name: 'Магический снаряд',
    skill_p_magicdarts3_name: 'Кинетическое копье',
    skill_p_magicdarts4_name: 'Копье пустоты',
    skill_p_magicdarts_desc: 'Весьма популярны в барах.',
    skill_p_parry1_name: 'Парирование',
    skill_p_parry2_name: 'Парирование и укол',
    skill_p_parry3_name: 'Клинковый барьер',
    skill_p_parry4_name: 'Непробиваемая защита',
    skill_p_parry_desc:
      'Трюк, выполняемый квалифицированным специалистом. Не повторяйте этого дома.',
    skill_p_arcaneblast1_name: 'Магический взрыв',
    skill_p_arcaneblast2_name: 'Огненный взрыв',
    skill_p_arcaneblast3_name: 'Жуткий взрыв',
    skill_p_arcaneblast4_name: 'Заряд великого старца',
    skill_p_arcaneblast_desc:
      'Размазывает магическую энергию по физиономии противника.',
    skill_p_hex1_name: 'Ворожба',
    skill_p_hex2_name: 'Губительная ворожба',
    skill_p_hex3_name: 'Запретная ворожба',
    skill_p_hex4_name: 'Жестокая ворожба',
    skill_p_hex_desc:
      'Накладывает на врага проклятие, лишающее того удачи. Смотри, сейчас на ровном месте споткнется!',
    skill_p_lifedrain1_name: 'Вампиризм',
    skill_p_lifedrain2_name: 'Высасывание сущности',
    skill_p_lifedrain3_name: 'Высасывание души',
    skill_p_lifedrain4_name: 'Раздирание души',
    skill_p_lifedrain_desc:
      'Высасывает жизненные силы из противников, восполняя ваше здоровье. Имеет фруктовый вкус.',
    skill_p_magearmor1_name: 'Доспехи мага',
    skill_p_magearmor2_name: 'Магический панцирь',
    skill_p_magearmor3_name: 'Мощный магический панцирь',
    skill_p_magearmor4_name: 'Величайший магический панцирь',
    skill_p_magearmor_desc: 'Не очень подходящая замена солнцезащитного крема.',
    skill_p_powerattack1_name: 'Мощная атака',
    skill_p_powerattack2_name: 'Ледокол',
    skill_p_powerattack3_name: 'Сокрушение айсберга',
    skill_p_powerattack4_name: 'Титановый удар',
    skill_p_powerattack_desc:
      'Если сильный удар не помог, надо ударить еще сильнее.',
    skill_p_caltrops1_name: 'Шипы',
    skill_p_caltrops2_name: 'Ловушка с шипами',
    skill_p_caltrops3_name: 'Шрапнельная граната',
    skill_p_caltrops4_name: 'Динамит',
    skill_p_caltrops_desc: 'Тут потребуются сапоги с толстенной подошвой...',
    skill_p_flamebrand1_name: 'Огненный клинок',
    skill_p_flamebrand2_name: 'Священный огонь',
    skill_p_flamebrand3_name: 'Крещение огнем',
    skill_p_flamebrand4_name: 'Опаляющее очищение',
    skill_p_flamebrand_desc: 'Одновременно и режет хлеб, и поджаривает!',
    skill_p_thickskin1_name: 'Толстая кожа',
    skill_p_thickskin2_name: 'Каменная кожа',
    skill_p_thickskin3_name: 'Твердая кожа',
    skill_p_thickskin4_name: 'Титановая кожа',
    skill_p_thickskin_desc: 'Один из способов идти по жизни каждый день.',
    skill_p_finesse1_name: 'Ловкость',
    skill_p_finesse2_name: 'Сноровка',
    skill_p_finesse3_name: 'Изящность поединка',
    skill_p_finesse4_name: 'Небесная благодать',
    skill_p_finesse_desc:
      'Захватывающе жонглирование ножами. В бою не особо помогает, но выглядит убедительно.',
    skill_p_curse1_name: 'Проклятие',
    skill_p_curse2_name: 'Древнее проклятие',
    skill_p_curse3_name: 'Вечное проклятие',
    skill_p_curse4_name: 'Проклятие рода',
    skill_p_curse_desc: 'Грубости и ругань опциональны.',
    skill_p_glancingblows1_name: 'Скользящие удары',
    skill_p_glancingblows2_name: 'Удача негодяя',
    skill_p_glancingblows3_name: 'Баловень судьбы',
    skill_p_glancingblows4_name: 'Сюжетный доспех',
    skill_p_glancingblows_desc:
      'Некоторые могли бы даже назвать это сюжетной броней.',
    skill_p_electricarc1_name: 'Электрическая дуга',
    skill_p_electricarc2_name: 'Удар молнии',
    skill_p_electricarc3_name: 'Цепной удар молнии',
    skill_p_electricarc4_name: 'Молния Зевса',
    skill_p_electricarc_desc: 'Разряд в 10 киловольт!',
    skill_p_shadows1_name: 'Объятия теней',
    skill_p_shadows2_name: 'Всепоглощающая тьма',
    skill_p_shadows3_name: 'Хватка бездны',
    skill_p_shadows4_name: 'Размах Ксолимнула',
    skill_p_shadows_desc: 'Так холодно и одиноко.',
    skill_p_dragonbreath1_name: 'Дыхание дракона',
    skill_p_dragonbreath2_name: 'Дыхание змея',
    skill_p_dragonbreath3_name: 'Дыхание Бахамута',
    skill_p_dragonbreath4_name: 'Гнев Бахамута',
    skill_p_dragonbreath_desc:
      'К счастью, драконы пока не владеют авторским правом на этот прием.',
    skill_p_wallofforce1_name: 'Стена силы',
    skill_p_wallofforce2_name: 'Стена зеркал',
    skill_p_wallofforce3_name: 'Крепость мага',
    skill_p_wallofforce4_name: 'Измерение магов',
    skill_p_wallofforce_desc:
      'Почти неприступная, но полностью просматриваемая, поэтому не ставьте их повсюду.',
    skill_p_sunder1_name: 'Раскол',
    skill_p_sunder2_name: 'Разбивание',
    skill_p_sunder3_name: 'Сокрушитель земли',
    skill_p_sunder4_name: 'Землетрясение',
    skill_p_sunder_desc:
      'Твоих соратников вряд ли порадует уничтоженное снаряжение (и добыча).',
    skill_p_throwing1_name: 'Метание кинжалов',
    skill_p_throwing2_name: 'Метание сюрикэнов',
    skill_p_throwing3_name: 'Буря сюрикэнов',
    skill_p_throwing4_name: 'Буря двух сюрикэнов',
    skill_p_throwing_desc: 'Удар в спину с безопасного расстояния.',
    skill_p_shiningblade1_name: 'Сияющий клинок',
    skill_p_shiningblade2_name: 'Благословенный клинок',
    skill_p_shiningblade3_name: 'Святой воин',
    skill_p_shiningblade4_name: 'Избранный',
    skill_p_shiningblade_desc: 'Этот клинок излучает потрясающую мощь!..',
    skill_p_hinder1_name: 'Помеха',
    skill_p_hinder2_name: 'Препятствие',
    skill_p_hinder3_name: 'Технический нокаут',
    skill_p_hinder4_name: 'Одноразовый успех',
    skill_p_hinder_desc: 'Это точно ниже пояса.',
    skill_p_antimagicnet1_name: 'Сеть против магии',
    skill_p_antimagicnet2_name: 'Бич волшебства',
    skill_p_antimagicnet3_name: 'Сверхъестественная темница',
    skill_p_antimagicnet4_name: 'Вечное изгнание',
    skill_p_antimagicnet_desc: 'Чародеи это просто НЕНАВИДЯТ.',
    skill_p_fireball1_name: 'Огненный шар',
    skill_p_fireball2_name: 'Магмовый шар',
    skill_p_fireball3_name: 'Ядерный взрыв',
    skill_p_fireball4_name: 'Метеорный рой',
    skill_p_fireball_desc: 'Единственный и неповторимый.',
    skill_p_disintegrate1_name: 'Дезинтеграция',
    skill_p_disintegrate2_name: 'Полное уничтожение',
    skill_p_disintegrate3_name: 'Забвение',
    skill_p_disintegrate4_name: 'Обнуление пустоты',
    skill_p_disintegrate_desc: 'Превращает в пыль почти все, что угодно.',
    skill_p_summondemon1_name: 'Призыв демонов',
    skill_p_summondemon2_name: 'Призыв армии демонов',
    skill_p_summondemon3_name: 'Призыв повелителя демонов',
    skill_p_summondemon4_name: 'Призыв забытого',
    skill_p_summondemon_desc:
      'Призванных демонов не придется упрашивать устроить тарарам.',
    skill_p_batteringblows1_name: 'Избиение',
    skill_p_batteringblows2_name: 'Безжалостный штурм',
    skill_p_batteringblows3_name: 'Метеорный натиск',
    skill_p_batteringblows4_name: 'Безостановочный натиск',
    skill_p_batteringblows_desc:
      'Прямо как по барабану бить, только барабан при этом вопит.',
    skill_p_whirlwind1_name: 'Вихрь',
    skill_p_whirlwind2_name: 'Удар бури',
    skill_p_whirlwind3_name: 'Многочисленные порывы',
    skill_p_whirlwind4_name: 'Пятикратный ультраудар',
    skill_p_whirlwind_desc:
      'Забудь о грациозности — вертись, как обезумевший волчок!',
    skill_p_perfectform1_name: 'Идеальная стойка',
    skill_p_perfectform2_name: 'Возвышенная форма',
    skill_p_perfectform3_name: 'Божественная форма',
    skill_p_perfectform4_name: 'Форма дэва',
    skill_p_perfectform_desc: 'Вдох. Выдох.',
    skill_p_extraplating1_name: 'Дополнительные латы',
    skill_p_extraplating2_name: 'Многослойный доспех',
    skill_p_extraplating3_name: 'Усиленные доспехи',
    skill_p_extraplating4_name: 'Пуленепробиваемый',
    skill_p_extraplating_desc: 'Побольше лат перед дорогой.',
    skill_p_assassinate1_name: 'Убийство',
    skill_p_assassinate2_name: 'Ликвидация',
    skill_p_assassinate3_name: 'Лишение жизни',
    skill_p_assassinate4_name: 'Беспощадная казнь',
    skill_p_assassinate_desc: 'Зачем выпендриваться, если главное — результат?',
    skill_p_bladedance1_name: 'Танец клинков',
    skill_p_bladedance2_name: 'Танец смерти',
    skill_p_bladedance3_name: 'Смертельный финал',
    skill_p_bladedance4_name: 'Смертельный финал (часть 2)',
    skill_p_bladedance_desc: 'Яростный вихрь, секущий и рубящий все вокруг.',
    skill_p_cloakndagger1_name: 'Плащ и кинжал',
    skill_p_cloakndagger2_name: 'Туз в рукаве',
    skill_p_cloakndagger3_name: 'Секретное оружие',
    skill_p_cloakndagger4_name: 'Неожиданное спасение',
    skill_p_cloakndagger_desc:
      'Лихой жулик попадает в самые разные неприятности, но каким-то образом всегда остается невредимым.',
    skill_p_megapunch1_name: 'Разрушительные удары',
    skill_p_megapunch2_name: 'Фатальные удары',
    skill_p_megapunch3_name: 'Гибель врагов',
    skill_p_megapunch4_name: 'Десять тысяч кулаков',
    skill_p_megapunch_desc:
      'В искусстве ведения боя великий магистр превзошел смертных.',
    skill_p_doublecast1_name: 'Двойное заклинание',
    skill_p_doublecast2_name: 'Тройное заклинание',
    skill_p_doublecast3_name: 'Четверное заклинание',
    skill_p_doublecast4_name: 'Пятикратное заклинание',
    skill_p_doublecast_desc: 'Два заклинания по цене одного? С руками оторву!',
    skill_p_petrify1_name: 'Окаменение',
    skill_p_petrify2_name: 'Отвердевание',
    skill_p_petrify3_name: 'Погребение',
    skill_p_petrify4_name: 'Соляное опустошение',
    skill_p_petrify_desc: 'Если повезет, удастся поймать смешную позу.',
    skill_p_poisoncloud1_name: 'Ядовитое облако',
    skill_p_poisoncloud2_name: 'Токсичное облако',
    skill_p_poisoncloud3_name: 'Облако-убийца',
    skill_p_poisoncloud4_name: 'Сдирающее душу облако',
    skill_p_poisoncloud_desc: 'Также прекрасно справляется с вредителями.',
    skill_p_manashield1_name: 'Щит маны',
    skill_p_manashield2_name: 'Щит души',
    skill_p_manashield3_name: 'Щит пустоты',
    skill_p_manashield4_name: 'Щит Ничто',
    skill_p_manashield_desc: 'Для чародея, у которого маны больше, чем друзей.',
    skill_s_break1_name: 'Обслуживание',
    skill_s_break2_name: 'Полировка',
    skill_s_break3_name: 'Зеркальный блеск',
    skill_s_break4_name: 'Самоделкин',
    skill_s_break_desc: 'Домоведение для «чайников».',
    skill_s_megabreak1_name: 'Расширенная гарантия',
    skill_s_megabreak2_name: 'Сохраненная квитанция',
    skill_s_megabreak3_name: 'Пожизненная гарантия',
    skill_s_megabreak4_name: 'Премиальный бренд',
    skill_s_megabreak_desc:
      'Но запланированного морального износа не избежать.',
    skill_s_xp1_name: 'Быстрое обучение',
    skill_s_xp2_name: 'Лучший в классе',
    skill_s_xp3_name: 'Гений',
    skill_s_xp4_name: 'Ученый',
    skill_s_xp_desc: 'Опыт — сын ошибок трудных.',
    skill_s_megaxp1_name: 'Сверхгениальность',
    skill_s_megaxp2_name: 'Ультрагениальность',
    skill_s_megaxp3_name: 'Большой умник',
    skill_s_megaxp4_name: 'Умнее всех',
    skill_s_megaxp_desc: 'Четырехзначный коэффициент интеллекта.',
    skill_s_superhpregen1_name: 'Быстрое исцеление',
    skill_s_superhpregen2_name: 'Кожа тролля',
    skill_s_superhpregen3_name: 'Регенерация',
    skill_s_superhpregen4_name: 'Из пепла',
    skill_s_superhpregen_desc: 'Бинт — лучшее лекарство.',
    skill_s_megahpregen1_name: 'Выживший',
    skill_s_megahpregen2_name: 'Как и не было',
    skill_s_megahpregen3_name: 'Крепкое здоровье',
    skill_s_megahpregen4_name: 'Долговечность',
    skill_s_megahpregen_desc: 'Не волнуйся, ребра еще срастутся.',
    skill_s_hphpregen1_name: 'Стойкость',
    skill_s_hphpregen2_name: 'Устойчивость',
    skill_s_hphpregen3_name: 'Выносливость героя',
    skill_s_hphpregen4_name: 'Выносливость титана',
    skill_s_hphpregen_desc:
      'Ушибить мизинец на ноге и не пикнуть — вот где стойкость.',
    skill_s_hp1_name: 'Прочность',
    skill_s_hp2_name: 'Крепкий орешек',
    skill_s_hp3_name: 'Несокрушимость',
    skill_s_hp4_name: 'Лучше гранита',
    skill_s_hp_desc: 'Вздрагивают только слабаки.',
    skill_s_megahp1_name: 'Непробиваемость',
    skill_s_megahp2_name: 'Неуязвимость',
    skill_s_megahp3_name: 'Бессмертие',
    skill_s_megahp4_name: 'За гранью бессмертия',
    skill_s_megahp_desc: 'Ну да, у меня из черепа топор торчит, и что?',
    skill_s_hpdef1_name: 'Джаггернаут',
    skill_s_hpdef2_name: 'Исполин',
    skill_s_hpdef3_name: 'Титан',
    skill_s_hpdef4_name: 'Старший титан',
    skill_s_hpdef_desc: 'Что там под доспехами? Доспехи, конечно.',
    skill_s_evasion1_name: 'Акробатика',
    skill_s_evasion2_name: 'Увертливость',
    skill_s_evasion3_name: 'Невероятная грация',
    skill_s_evasion4_name: 'Сверхъестественная форма',
    skill_s_evasion_desc: 'С такой ловкостью и доспехи не нужны.',
    skill_s_megaevasion1_name: 'Размытость',
    skill_s_megaevasion2_name: 'Сверхчеловеческая скорость',
    skill_s_megaevasion3_name: 'Скорость звука',
    skill_s_megaevasion4_name: 'Смехотворная скорость',
    skill_s_megaevasion_desc: 'Как бы за превышение скорости не схлопотать.',
    skill_s_defevasion1_name: 'Дополнительная отработка',
    skill_s_defevasion2_name: 'Геройская тренировка',
    skill_s_defevasion3_name: 'Богатырские усилия',
    skill_s_defevasion4_name: 'Закалка Атласа',
    skill_s_defevasion_desc: 'Не стоит забывать о спортзале.',
    skill_s_evasioncrit1_name: 'Обман',
    skill_s_evasioncrit2_name: 'Идеальная уловка',
    skill_s_evasioncrit3_name: 'Дымовая шашка',
    skill_s_evasioncrit4_name: 'Теневой поход',
    skill_s_evasioncrit_desc:
      'Своевременное отвлечение внимания дает больше шансов.',
    skill_s_crit1_name: 'Орлиный глаз',
    skill_s_crit2_name: 'Фиксация на цели',
    skill_s_crit3_name: 'Мысленный взор',
    skill_s_crit4_name: 'Видение будущего',
    skill_s_crit_desc:
      'Необычная способность выискивать светящиеся красные точки.',
    skill_s_megacrit1_name: 'Природный дар',
    skill_s_megacrit2_name: 'Жизнь по жребию',
    skill_s_megacrit3_name: 'Последний гамбит',
    skill_s_megacrit4_name: 'Преимущество игрока',
    skill_s_megacrit_desc:
      'Наступает такой момент, когда обычный урон становится сюрпризом.',
    skill_s_critmult1_name: 'Смертельные критические удары',
    skill_s_critmult2_name: 'Завершающий удар',
    skill_s_critmult3_name: 'Палач',
    skill_s_critmult4_name: 'Завершающий штрих',
    skill_s_critmult_desc: 'Извлекайте пользу из них.',
    skill_s_megacritmult1_name: 'Торговец смертью',
    skill_s_megacritmult2_name: 'Ва-банк',
    skill_s_megacritmult3_name: 'Азартная игра',
    skill_s_megacritmult4_name: 'Все или ничего',
    skill_s_megacritmult_desc: 'Дубль или ничего.',
    skill_s_critcritmult1_name: 'Говорящие удары',
    skill_s_critcritmult2_name: 'Погасить свет',
    skill_s_critcritmult3_name: 'Истинный удар',
    skill_s_critcritmult4_name: 'Фирменный прием',
    skill_s_critcritmult_desc: 'Положись на удачу (и шулерскую колоду).',
    skill_s_swordmaster1_name: 'Мечник',
    skill_s_swordmaster2_name: 'Повелитель меча',
    skill_s_swordmaster3_name: 'Титан меча',
    skill_s_swordmaster4_name: 'Верховный титан меча',
    skill_s_swordmaster_desc:
      'Грация, с которой мечник обращается с мечом, просто поражает.',
    skill_s_axemaster1_name: 'Повелитель топоров',
    skill_s_axemaster2_name: 'Повелитель топора',
    skill_s_axemaster3_name: 'Титан топора',
    skill_s_axemaster4_name: 'Верховный титан топора',
    skill_s_axemaster_desc:
      'Отточенные движения? Нет уж, спасибо. Мне бы урона, и ПОБОЛЬШЕ.',
    skill_s_daggermaster1_name: 'Эксперт по кинжалам',
    skill_s_daggermaster2_name: 'Повелитель кинжала',
    skill_s_daggermaster3_name: 'Титан кинжала',
    skill_s_daggermaster4_name: 'Верховный титан кинжала',
    skill_s_daggermaster_desc:
      'Для тех, кто любит быть близко. Чересчур близко.',
    skill_s_macemaster1_name: 'Мастер булавы',
    skill_s_macemaster2_name: 'Повелитель булавы',
    skill_s_macemaster3_name: 'Титан булавы',
    skill_s_macemaster4_name: 'Верховный титан булавы',
    skill_s_macemaster_desc:
      'Желаете что-нибудь размозжить? Всегда пожалуйста.',
    skill_s_spearmaster1_name: 'Мастер копья',
    skill_s_spearmaster2_name: 'Повелитель копья',
    skill_s_spearmaster3_name: 'Титан копья',
    skill_s_spearmaster4_name: 'Верховный титан копья',
    skill_s_spearmaster_desc: 'Протяни другому копье... но не для помощи.',
    skill_s_bowmaster1_name: 'Мастер-лучник',
    skill_s_bowmaster2_name: 'Повелитель лука',
    skill_s_bowmaster3_name: 'Титан лука',
    skill_s_bowmaster4_name: 'Верховный титан лука',
    skill_s_bowmaster_desc: 'Дает способность сбивать яблоки с деревьев.',
    skill_s_staffmaster1_name: 'Мастер посоха',
    skill_s_staffmaster2_name: 'Повелитель посоха',
    skill_s_staffmaster3_name: 'Титан посоха',
    skill_s_staffmaster4_name: 'Верховный титан посоха',
    skill_s_staffmaster_desc:
      'Дает способность общаться с духами, обитающими в посохах.',
    skill_s_crossbowmaster1_name: 'Мастер-арбалетчик',
    skill_s_crossbowmaster2_name: 'Повелитель арбалета',
    skill_s_crossbowmaster3_name: 'Титан арбалета',
    skill_s_crossbowmaster4_name: 'Верховный титан арбалета',
    skill_s_crossbowmaster_desc:
      'Арбалетом пользоваться несложно, а вот освоить по-настоящему — отнюдь.',
    skill_s_gunmaster1_name: 'Мастер-стрелок',
    skill_s_gunmaster2_name: 'Повелитель ружья',
    skill_s_gunmaster3_name: 'Титан ружья',
    skill_s_gunmaster4_name: 'Верховный титан ружья',
    skill_s_gunmaster_desc: 'Всяческая защита прав. И оружия.',
    skill_s_wandmaster1_name: 'Мастер палочки',
    skill_s_wandmaster2_name: 'Повелитель палочки',
    skill_s_wandmaster3_name: 'Титан палочки',
    skill_s_wandmaster4_name: 'Верховный титан палочки',
    skill_s_wandmaster_desc:
      'Тот, кто понял, что именно палочка выбирает себе чародея.',
    skill_s_shieldmaster1_name: 'Король щитов',
    skill_s_shieldmaster2_name: 'Повелитель щита',
    skill_s_shieldmaster3_name: 'Титан щита',
    skill_s_shieldmaster4_name: 'Верховный титан щита',
    skill_s_shieldmaster_desc: 'Непробиваемый, и этим все сказано.',
    skill_s_warmaster1_name: 'Военачальник',
    skill_s_warmaster2_name: 'Мастер войны',
    skill_s_warmaster3_name: 'Бог войны',
    skill_s_warmaster4_name: 'Старший бог войны',
    skill_s_warmaster_desc:
      'Невероятное мастерство в использовании всех видов оружия.',
    skill_s_snipermaster1_name: 'Снайпер',
    skill_s_snipermaster2_name: 'Снайпер-мастер',
    skill_s_snipermaster3_name: 'Меткий стрелок',
    skill_s_snipermaster4_name: 'Элитный меткий стрелок',
    skill_s_snipermaster_desc: 'Абсолютная меткость стрельбы.',
    skill_s_arcanemaster1_name: 'Архимаг',
    skill_s_arcanemaster2_name: 'Архимаг',
    skill_s_arcanemaster3_name: 'Старший архимаг',
    skill_s_arcanemaster4_name: 'Возвышенный архимаг',
    skill_s_arcanemaster_desc: 'Ты же не отберешь у старика его трость?',
    skill_c_soldier1_name: 'Военная подготовка',
    skill_c_soldier2_name: 'Эксперт по военному делу',
    skill_c_soldier3_name: 'Мастер военного дела',
    skill_c_soldier4_name: 'Повелитель наемников',
    skill_c_soldier_effect:
      'Осваивает навыки быстрее по сравнению с другими классами.',
    skill_c_soldier1_effect:
      'Осваивает навыки быстрее по сравнению с другими классами.',
    skill_c_soldier2_effect:
      'Осваивает навыки быстрее по сравнению с другими классами.',
    skill_c_soldier3_effect:
      'Осваивает навыки быстрее по сравнению с другими классами.',
    skill_c_soldier4_effect:
      'Осваивает навыки быстрее по сравнению с другими классами.',
    skill_c_soldier_desc:
      'Армейское прошлое солдата позволяет ему быстрее других выработать свой собственный стиль ведения боя.',
    skill_c_mercenary1_name: 'Помощник командира',
    skill_c_mercenary2_name: 'Элитный наемник',
    skill_c_mercenary3_name: 'Величайший наемник',
    skill_c_mercenary4_name: 'Повелитель наемников',
    skill_c_mercenary_desc:
      'Поприще наемника требует от него быстрее других выработать свой собственный стиль ведения боя.',
    skill_c_mercenary_effect_details:
      '+{0} к повышению эффекта от навыков защитников.',
    skill_c_mercenary1_effect:
      'Осваивает навыки быстрее по сравнению с другими классами.',
    skill_c_mercenary2_effect:
      'Осваивает навыки быстрее по сравнению с другими классами.',
    skill_c_mercenary3_effect:
      'Осваивает навыки быстрее по сравнению с другими классами.',
    skill_c_mercenary4_effect:
      'Осваивает навыки быстрее по сравнению с другими классами.',
    skill_c_barbarian1_name: 'Львиный рык',
    skill_c_barbarian2_name: 'Зов вождя',
    skill_c_barbarian3_name: 'Рев дракона',
    skill_c_barbarian4_name: 'Мощь предков',
    skill_c_barbarian_desc:
      'В бою варвары отличаются жестокостью, эффективностью, а также дикими воплями. На них сложно не отвлечься.',
    skill_c_barbarian_effect:
      'Привлекает к себе внимание врагов. Очень вынослив.',
    skill_c_chieftain1_name: 'Зов племени',
    skill_c_chieftain2_name: 'Авторитет вождя',
    skill_c_chieftain3_name: 'Боевой клич',
    skill_c_chieftain4_name: 'Мощь предков',
    skill_c_chieftain_desc:
      'Чтобы защитить свое племя, вожди сражаются на передовой с абсолютной жестокостью.',
    skill_c_chieftain_effect:
      'Привлекает к себе внимание врагов. Очень вынослив.',
    skill_c_chieftain_effect_details_1: '{0} ЗДР',
    skill_c_chieftain_effect_details_2:
      '{0} ед. угрозы. {1} от всей угрозы добавляется в качестве множителя атаки.',
    skill_c_knight1_name: 'Твердыня',
    skill_c_knight2_name: 'Цитадель',
    skill_c_knight3_name: 'Бастион',
    skill_c_knight4_name: 'Бескорыстный герой',
    skill_c_knight_desc:
      'Даже паладины завидуют тому, как рыцари без особого труда носят свои доспехи.',
    skill_c_knight_effect:
      'Дополнительная защита при использовании тяжелых доспехов.',
    skill_c_lord1_name: 'Телохранитель',
    skill_c_lord2_name: 'Спасатель',
    skill_c_lord3_name: 'Лорд-рыцарь',
    skill_c_lord4_name: 'Бескорыстный герой',
    skill_c_lord_desc: 'Для защиты слабых уместны любые жертвы.',
    skill_c_lord_effect:
      'Дополнительная защита при ношении тяжелых доспехов. Может защитить слабых союзников от атак.',
    skill_c_lord_effect_details:
      'Защитите союзника от смертельной атаки один раз за бой.',
    skill_c_ranger1_name: 'Самодостаточность',
    skill_c_ranger2_name: 'Подножный корм',
    skill_c_ranger3_name: 'Охотник-отшельник',
    skill_c_ranger4_name: 'Дух леса',
    skill_c_ranger_desc:
      'Живя в одиночестве в глуши, можно научиться делать многое малыми средствами.',
    skill_c_ranger_effect: 'Ускоренное исцеление во время отдыха.',
    skill_c_swordmaster1_name: 'Иайдо',
    skill_c_swordmaster2_name: 'Молниеносное иайдо',
    skill_c_swordmaster3_name: 'Мастер иайдо',
    skill_c_swordmaster4_name: 'Гроссмейстер иайдо',
    skill_c_swordmaster_desc:
      'Самурай знает, что бой окончен, как только обнажает клинок.',
    skill_c_swordmaster_effect:
      'Во время первой атаки самурай всегда наносит критический удар, который игнорирует стихийные барьеры.',
    skill_c_swordmaster1_effect:
      'Во время первой атаки самурай всегда наносит критический удар, который игнорирует стихийные барьеры.',
    skill_c_swordmaster2_effect:
      'Во время первой атаки самурай всегда наносит критический удар, который игнорирует стихийные барьеры.',
    skill_c_swordmaster3_effect:
      'Во время первой атаки самурай всегда наносит критический удар, который игнорирует стихийные барьеры.',
    skill_c_swordmaster4_effect:
      'Во время первой атаки самурай всегда наносит критический удар, который игнорирует стихийные барьеры.',
    skill_c_daimyo1_name: 'Иайдо дайме',
    skill_c_daimyo2_name: 'Божественное иайдо',
    skill_c_daimyo3_name: 'Идеальное иайдо',
    skill_c_daimyo4_name: 'Гроссмейстер иайдо',
    skill_c_daimyo_desc:
      'Причинять смерть — это ожидать ее в ответ. Дайме хорошо об этом знают.',
    skill_c_daimyo_effect:
      'Гарантированное уклонение и критический удар в первом раунде. Эта атака игнорирует стихийные барьеры.',
    skill_c_daimyo1_effect:
      'Гарантированное уклонение и критический удар в первом раунде. Эта атака игнорирует стихийные барьеры.',
    skill_c_daimyo2_effect:
      'Гарантированное уклонение и критический удар в первом раунде. Эта атака игнорирует стихийные барьеры.',
    skill_c_daimyo3_effect:
      'Гарантированное уклонение и критический удар в первом раунде. Эта атака игнорирует стихийные барьеры.',
    skill_c_daimyo4_effect:
      'Гарантированное уклонение и критический удар в первом раунде. Эта атака игнорирует стихийные барьеры.',
    skill_c_berserker1_name: 'Ярость берсерка',
    skill_c_berserker2_name: 'Точка гнева',
    skill_c_berserker3_name: 'Зверь внутри',
    skill_c_berserker4_name: 'Зверь на свободе',
    skill_c_berserker_desc:
      'Большинство воинов серьезная рана выводит из строя. Но для берсерка она всего лишь означает начало хорошего боя.',
    skill_c_berserker_effect: 'Сила увеличивается при низком здоровье.',
    skill_c_berserker_effect_details_1: '{0} и {1} при падении ЗДР ниже {2}.',
    skill_c_berserker_effect_details_2:
      'Эффект удваивается при ЗДР ниже {0} и утраивается при ЗДР ниже {1}.',
    skill_c_thief1_name: 'Негодяй',
    skill_c_thief2_name: 'Лихой жулик',
    skill_c_thief3_name: 'Мастер-вор',
    skill_c_thief4_name: 'Легендарная ловкость',
    skill_c_thief_desc: 'Кинжал и вор — будто единое целое.',
    skill_c_thief_effect: 'Усиление атаки кинжалов.',
    skill_c_trickster1_name: 'Преимущество ловкача',
    skill_c_trickster2_name: 'Убийственная шутка',
    skill_c_trickster3_name: 'Последний трюк',
    skill_c_trickster4_name: 'Легендарная ловкость',
    skill_c_trickster_desc: 'Ловкачи предпочитают сложные трюки.',
    skill_c_trickster_effect: 'Усиление атаки кинжалов. Поддержка Полонии.',
    skill_c_trickster_effect_details_1_1: '{0}',
    skill_c_trickster_effect_details_1_2: '{0} {1} ЗДР.',
    skill_c_trickster_effect_details_2:
      '+{0} к шансу украсть для Полонии. +{1} к максимуму украденных предметов.',
    skill_c_musketeer1_name: 'В яблочко',
    skill_c_musketeer2_name: 'Щегольство',
    skill_c_musketeer3_name: 'Любитель острых ощущений',
    skill_c_musketeer4_name: 'Критический звон',
    skill_c_musketeer_desc:
      'Щегольство мушкетеров вынуждает их наносить лишь самые дерзкие удары.',
    skill_c_musketeer_effect: 'Высокая вероятность критического удара.',
    skill_c_conquistador1_name: 'Комбо критических ударов',
    skill_c_conquistador2_name: 'Цепь критических ударов',
    skill_c_conquistador3_name: 'Король критических ударов',
    skill_c_conquistador4_name: 'Критический звон',
    skill_c_conquistador_desc: 'Постоянные попадания в яблочко — это норма.',
    skill_c_conquistador_effect:
      'Бонус к критическому урону за критические удары подряд.',
    skill_c_conquistador_effect_details_1_1:
      '{0} ед. шанса критического удара ',
    skill_c_conquistador_effect_details_1_2:
      '{0} ед. шанса критического удара. {1} ед. критического урона.',
    skill_c_conquistador_effect_details_2:
      '+{0} к критическому урону за критические удары подряд. Суммируется {1} р.',
    skill_c_monk1_name: 'Болевые точки',
    skill_c_monk2_name: 'Уязвимые места',
    skill_c_monk3_name: 'Запрещенный прием',
    skill_c_monk4_name: 'Жертвоприношение',
    skill_c_monk_desc:
      'Целясь в невидимые глазу уязвимые точки тела противника, монахи способны наносить мощнейшие удары.',
    skill_c_monk_effect: 'Мощные критические удары. Кольца дают бонус.',
    skill_c_mastermonk1_name: 'Секрет великого магистра',
    skill_c_mastermonk2_name: 'Нарушенные клятвы',
    skill_c_mastermonk3_name: 'Последнее средство',
    skill_c_mastermonk4_name: 'Жертвоприношение',
    skill_c_mastermonk_desc:
      'Великий магистр в совершенстве овладел редко используемым и крайне разрушительным навыком.',
    skill_c_mastermonk_effect:
      'Кольца дают бонус. Может выучить очень мощный эксклюзивный навык.',
    skill_c_mastermonk_effect_details_1: '{0}. {1} ед. критического урона.',
    skill_c_mastermonk_effect_details_2:
      'Может выучить очень мощный эксклюзивный навык.',
    skill_c_wanderer1_name: 'Уклончивость',
    skill_c_wanderer2_name: 'В движении',
    skill_c_wanderer3_name: 'Самый длинный путь',
    skill_c_wanderer4_name: 'Несущественное',
    skill_c_wanderer_desc:
      'Бродяги не сидят на месте, будь то в пути или в бою.',
    skill_c_wanderer_effect: 'Высокая вероятность уклонения.',
    skill_c_pathfinder1_name: 'Изящность следопыта',
    skill_c_pathfinder2_name: 'Самый непроторенный путь',
    skill_c_pathfinder3_name: 'За пределами уклонения',
    skill_c_pathfinder4_name: 'Несущественное',
    skill_c_pathfinder_desc:
      'Быть несравненным вполне входит в амбиции следопыта.',
    skill_c_pathfinder_effect_details_2: '{0} к макс. уклонению',
    skill_c_ninja1_name: 'Теневой убийца',
    skill_c_ninja2_name: 'Угроза из темноты',
    skill_c_ninja3_name: 'Единое целое с тьмой',
    skill_c_ninja4_name: 'Оно из темноты',
    skill_c_ninja_desc:
      'Ниндзя крадутся в тени, чтобы обеспечить быструю и незаметную гибель своей цели.',
    skill_c_ninja_effect:
      'Бонус к шансу уклонению и шансу критического урона, пока не нанесен урон в бою. Мощные критические удары',
    skill_c_ninja_stat_postfix: 'до получения урона',
    skill_c_sensei1_name: 'Истинный убийца',
    skill_c_sensei2_name: 'Глушитель',
    skill_c_sensei3_name: 'Тенерожденный',
    skill_c_sensei4_name: 'Оно из темноты',
    skill_c_sensei_desc:
      'Мастер среди мастеров оставляет врагов в недоумении, а существовал ли он на самом деле.',
    skill_c_sensei_effect:
      'Бонус к уклонению и шансу критического урона, пока не нанесен урон в бою. Бонусы восстанавливаются после получения урона.',
    skill_c_sensei_effect_details_1: '{0} и {1} до получения урона.',
    skill_c_sensei_effect_details_2:
      'Получайте бонусы снова после {0} раундов.',
    skill_c_dancer1_name: 'Жалящий удар в ответ',
    skill_c_dancer2_name: 'Жалящий удар в ответ',
    skill_c_dancer3_name: 'Сокрушительный удар в ответ',
    skill_c_dancer4_name: 'Прилавок крещендо',
    skill_c_dancer_effect:
      'Гарантирует критический удар после уклонения от атаки.',
    skill_c_dancer_desc: 'Порхай как бабочка и жаль как пчела.',
    skill_c_dancer1_effect:
      'Гарантирует критический удар после уклонения от атаки.',
    skill_c_dancer2_effect:
      'Гарантирует критический удар после уклонения от атаки.',
    skill_c_dancer3_effect:
      'Гарантирует критический удар после уклонения от атаки.',
    skill_c_dancer4_effect:
      'Гарантирует критический удар после уклонения от атаки.',
    skill_c_mage1_name: 'Чародейство',
    skill_c_mage2_name: 'Высокое чародейство',
    skill_c_mage3_name: 'Чудотворство',
    skill_c_mage4_name: 'Магическое откровение',
    skill_c_mage_desc:
      'Жги. Взрывай. Бей молниями. Маги пользуются своими разрушительными способностями даже для решения бытовых проблем.',
    skill_c_mage_effect: 'Высокая сила атаки.',
    skill_c_archmage1_name: 'Архимагия',
    skill_c_archmage2_name: 'Магические знания',
    skill_c_archmage3_name: 'Магические секреты',
    skill_c_archmage4_name: 'Магическое откровение',
    skill_c_archmage_desc: 'Переделывайте реальность одним мановением руки.',
    skill_c_archmage_effect: 'Высокая сила атаки.',
    skill_c_cleric1_name: 'Избранник Богини',
    skill_c_cleric2_name: 'Избранный',
    skill_c_cleric3_name: 'Крестоносец',
    skill_c_cleric4_name: 'Праведное провидение',
    skill_c_cleric_desc:
      'Богиня любит всех своих детей одинаково, но священникам отдается предпочтение.',
    skill_c_cleric_effect:
      'Выдерживает смертельный удар один раз за приключение.',
    skill_c_bishop1_name: 'Истинный избранный',
    skill_c_bishop2_name: 'Божественная милость',
    skill_c_bishop3_name: 'Чудо',
    skill_c_bishop4_name: 'Праведное провидение',
    skill_c_bishop_desc:
      'Пользуясь поддержкой самой Богини, этот боец так просто не сдастся.',
    skill_c_bishop_effect:
      'Выдерживает смертельный удар один раз за приключение. Восстанавливает здоровье в каждом раунде.',
    skill_c_druid1_name: 'Первобытная магия',
    skill_c_druid2_name: 'Сила предков',
    skill_c_druid3_name: 'Могущество земли',
    skill_c_druid4_name: 'Гнев Геи',
    skill_c_druid_desc:
      'Друиды прекрасно разбираются в природе и магии, и потому знают все о волшебных травах.',
    skill_c_druid_effect: 'Бонусы к травам.',
    skill_c_sorcerer1_name: 'Темный договор',
    skill_c_sorcerer2_name: 'Кровавый договор',
    skill_c_sorcerer3_name: 'Страшный договор',
    skill_c_sorcerer4_name: 'Невыразимый контракт',
    skill_c_sorcerer_desc:
      'Волшебники, заключив контракты с потусторонними существами, обретают поистине ужасающую мощь.',
    skill_c_sorcerer_effect: 'Есть шанс выучить мощные эксклюзивные навыки.',
    skill_c_sorcerer1_effect: 'Есть шанс выучить мощные эксклюзивные навыки.',
    skill_c_sorcerer2_effect: 'Есть шанс выучить мощные эксклюзивные навыки.',
    skill_c_sorcerer3_effect: 'Есть шанс выучить мощные эксклюзивные навыки.',
    skill_c_sorcerer4_effect: 'Есть шанс выучить мощные эксклюзивные навыки.',
    skill_c_redmage1_name: 'Мастер на все руки',
    skill_c_redmage2_name: 'Ас на все руки',
    skill_c_redmage3_name: 'Мастер на все руки',
    skill_c_redmage4_name: 'Всеискусность',
    skill_c_redmage_desc: 'Слова «специализация» в словаре чудотворца нет.',
    skill_c_redmage_effect:
      '+{0} к бонусным параметрам от всего снаряжения. Используется любая стихия.',
    skill_c_redmage_effect_details:
      'Используется любая стихия, но может нанести урон любому стихийному барьеру при силе 30%.',
    skill_c_spellknight1_name: 'Спец на все руки',
    skill_c_spellknight2_name: 'Ас на все руки',
    skill_c_spellknight3_name: 'Мастер на все руки',
    skill_c_spellknight4_name: 'Всеискусность',
    skill_c_spellknight_desc: 'Рыцарь чар не знает слова «специализация».',
    skill_c_spellknight_effect:
      'Дополнительный бонус +{0} от снаряжения со внутренними стихиями.',
    skill_c_spellknight1_effect:
      'Дополнительный бонус +{0} от снаряжения со внутренними стихиями.',
    skill_c_spellknight2_effect:
      'Дополнительный бонус +{0} от снаряжения со внутренними стихиями.',
    skill_c_spellknight3_effect:
      'Дополнительный бонус +{0} от снаряжения со внутренними стихиями.',
    skill_c_spellknight4_effect:
      'Дополнительный бонус +{0} от снаряжения со внутренними стихиями.',
    skill_c_spellknight_effect_details:
      'Используется любая стихия, но может нанести урон любому стихийному барьеру при силе 30%.',
    skill_c_geomancer1_name: 'Геомантия',
    skill_c_geomancer2_name: 'Гармония земли',
    skill_c_geomancer3_name: 'Чистота земли',
    skill_c_geomancer4_name: 'Повелитель энергетических линий',
    skill_c_geomancer_desc:
      'Скрытая энергия земли, воды и воздуха подчиняется воле геоманта.',
    skill_c_geomancer_effect: 'Получает силу от используемых стихий.',
    elemental_attack: 'к атаке за очко для любой стихии',
    skill_i_turtle_name: 'Крепость барана',
    skill_i_turtle_plus_name: 'Крепость барана+',
    skill_i_turtle_desc: 'Это вам не баран начихал.',
    skill_i_wolf_name: 'Свирепость волка',
    skill_i_wolf_plus_name: 'Свирепость волка+',
    skill_i_wolf_desc: 'Ответьте на зов стаи.',
    skill_i_ox_name: 'Выносливость быка',
    skill_i_ox_plus_name: 'Выносливость быка+',
    skill_i_ox_desc: 'Пропалывайте поля и ряды врагов.',
    skill_i_eagle_name: 'Меткость орла',
    skill_i_eagle_plus_name: 'Меткость орла+',
    skill_i_eagle_desc: 'Моя оценка меткости — «Да».',
    skill_i_viper_name: 'Смертоносность гадюки',
    skill_i_viper_plus_name: 'Смертоносность гадюки+',
    skill_i_viper_desc: 'Достаточно одного удара.',
    skill_i_cat_name: 'Грация кошки',
    skill_i_cat_plus_name: 'Грация кошки+',
    skill_i_cat_desc: 'Даже не пытайтесь погладить пузико.',
    skill_i_rabbitspirit_name: 'Кроличья прыгучесть',
    skill_i_rabbitspirit_plus_name: 'Кроличья прыгучесть+',
    skill_i_rabbitspirit_desc: 'Не успеешь оглянуться, как он уже исчез!',
    skill_i_luxuriousspirit_name: 'Дух Шолотля',
    skill_i_luxuriousspirit_plus_name: 'Дух Шолотля+',
    skill_i_luxuriousspirit_desc:
      'Брат-близнец Кетцалькоатля. Сопровождает умирающее солнце каждый вечер.',
    skill_i_freshspirit_name: 'Дух свежести',
    skill_i_freshspirit_plus_name: 'Дух свежести+',
    skill_i_freshspirit_desc: 'Ничто не сравнится со свежей выпечкой из печи!',
    skill_i_rhino_name: 'Натиск носорога',
    skill_i_rhino_plus_name: 'Натиск носорога+',
    skill_i_rhino_desc: 'Невозмутимость вызывает неистовую зависть.',
    skill_i_owl_name: 'Мудрость совы',
    skill_i_owl_plus_name: 'Мудрость совы+',
    skill_i_owl_desc: 'Вам не хочется спать всю ночь?',
    skill_i_armadillo_name: 'Упорство броненосца',
    skill_i_armadillo_plus_name: 'Упорство броненосца+',
    skill_i_armadillo_desc:
      'Свернитесь в клубок, чтобы защитить самое главное.',
    skill_i_lizard_name: 'Регенерация ящерицы',
    skill_i_lizard_plus_name: 'Регенерация ящерицы+',
    skill_i_lizard_desc:
      'Если вы потеряли что-то, но до сих пор живы, то, вероятно, это было что-то незначительное.',
    skill_i_crab_name: 'Выносливость лошади',
    skill_i_crab_plus_name: 'Выносливость лошади+',
    skill_i_crab_desc: 'У вас бывают всплески активности?',
    skill_i_hippo_name: 'Апатичность бегемота',
    skill_i_hippo_plus_name: 'Апатичность бегемота+',
    skill_i_hippo_desc: 'Относитесь ко всему проще!',
    skill_i_walrus_name: 'Морж',
    skill_i_walrus_plus_name: 'Невозмутимость моржа+',
    skill_i_walrus_desc: 'Такого трудно сдвинуть с места.',
    skill_i_lion_name: 'Львиный прайд',
    skill_i_lion_plus_name: 'Львиный прайд+',
    skill_i_lion_desc: 'Все, на что падает свет, — владения этого духа.',
    skill_i_tiger_name: 'Сила тигра',
    skill_i_tiger_plus_name: 'Сила тигра+',
    skill_i_tiger_desc: 'Мощь одинокого охотника.',
    skill_i_dinosaur_name: 'Динозавр',
    skill_i_dinosaur_plus_name: 'Чувства динозавра+',
    skill_i_dinosaur_desc:
      'На самом деле в основном здесь лягушачья ДНК. Динозавры ведь все-таки вымерли.',
    skill_i_shark_name: 'Акулья кровожадность',
    skill_i_shark_plus_name: 'Акулья кровожадность+',
    skill_i_shark_desc: 'Высший хищник с незапамятных времен.',
    skill_i_bear_name: 'Живучесть медведя',
    skill_i_bear_plus_name: 'Живучесть медведя+',
    skill_i_bear_desc: 'Неудивительно, что Рудо их любит.',
    skill_i_mammoth_name: 'Мамонт',
    skill_i_mammoth_plus_name: 'Присутствие мамонта+',
    skill_i_mammoth_desc: 'Память у него дли-и-и-и-инная.',
    skill_i_opulentspirit_name: 'Дух Кетцалькоатля',
    skill_i_opulentspirit_plus_name: 'Дух Кетцалькоатля+',
    skill_i_opulentspirit_desc:
      'Брат-близнец Шолотля. Каждое утро возвещает о возрождении солнца.',
    skill_i_dragon_name: 'Злость Лундры',
    skill_i_dragon_plus_name: 'Злость Лундры',
    skill_i_dragon_desc:
      'Надменность сохраняется даже у духа. Похоже, ему не терпится показать этим боссам, кто здесь главный.',
    skill_i_phoenix_name: 'Бессмертие феникса',
    skill_i_phoenix_plus_name: 'Бессмертие феникса+',
    skill_i_phoenix_desc: 'Восстаньте из пепла и сражайтесь снова.',
    skill_i_hydra_name: 'Натиск гидры',
    skill_i_hydra_plus_name: 'Натиск гидры+',
    skill_i_hydra_desc: 'Целая куча голов, которые не ладят друг с другом!',
    skill_i_tarrasque_name: 'Террор тараска',
    skill_i_tarrasque_plus_name: 'Террор тараска+',
    skill_i_tarrasque_desc: 'Остались только мусор и руины.',
    skill_i_carbuncle_name: 'Причуда карбункула',
    skill_i_carbuncle_plus_name: 'Причуда карбункула+',
    skill_i_carbuncle_desc: 'Там и сям, туда-сюда, безо всяких забот.',
    skill_i_chimera_name: 'Злоба химеры',
    skill_i_chimera_plus_name: 'Злоба химеры+',
    skill_i_chimera_desc:
      'Три думающие головы, но ни у одной из них нет ни грамма доброты.',
    skill_i_kraken_name: 'Жестокость кракена',
    skill_i_kraken_plus_name: 'Жестокость кракена+',
    skill_i_kraken_desc: 'Что же все эти корабли с тобой сделали?',
    skill_i_santa_name: 'Дух Рождества',
    skill_i_santa_plus_name: 'Дух Рождества+',
    skill_i_santa_desc:
      'Весёлый дух зимних праздников. Его истинный потенциал раскрывается при зачаровании рождественских предметов.',
    requires_titan_class: 'Эксклюзив для титана',
    bonus_skill_stat_from_equipments: 'к параметрам для всего снаряжения.',
    skill_i_dragon_stat_postfix: 'против боссов.',
    skill_i_luxuriousspirit_effect_details:
      '{0} снижает затраты энергии на повышение цены.',
    skill_i_luxuriousspirit_plus_effect_details:
      '{0} снижает затраты энергии на повышение цены.',
    skill_i_opulentspirit_effect_details:
      '{0} снижает затраты энергии на повышение цены.',
    skill_i_opulentspirit_plus_effect_details:
      '{0} снижает затраты энергии на повышение цены.',
    skill_i_freshspirit_effect_details:
      '{0} снижает затраты энергии на повышение цены.',
    skill_i_freshspirit_plus_effect_details:
      '{0} снижает затраты энергии на повышение цены.',
    skill_i_dinosaur_stat_postfix: 'в первом раунде.',
    skill_i_shark_effect_details: '{0} при здоровье противника ниже {1}.',
    skill_i_shark_effect_details_short: 'Бонусная атака на раненых врагов.',
    tutorial_name: 'Первые шаги титана',
    tutorial_desc: 'Заверши обучение.',
    level01_name: 'Многообещающий торговец',
    level02_name: 'Известный торговец',
    level03_name: 'Успешный торговец',
    level04_name: 'Прославленный торговец',
    level_desc:
      'Достигни <color=#3AFF5DFF>{0}</color> уровня торгового мастерства.',
    gold01_name: 'Звон монет',
    gold02_name: 'Кругленькая сумма',
    gold03_name: 'Золотые горы',
    gold04_name: 'Королевский выкуп',
    gold05_name: 'Поклонник Маммоны',
    gold06_name: 'Неизмеримые богатства',
    gold_desc:
      'Заработай за всю свою карьеру торговца <color=#3AFF5DFF>{0}</color> ед. золота.',
    sell01_name: 'Даешь капитализм!',
    sell02_name: 'Черная пятница',
    sell03_name: 'Только наличные',
    sell_desc: 'Продай предметы: <color=#3AFF5DFF>{0}</color> шт.',
    craft01_name: 'Отказ от посредничества',
    craft02_name: 'Насыщение рынка',
    craft03_name: 'Разлетаются, как пирожки',
    craft04_name: 'Индустриальная эволюция',
    craft05_name: 'Монарх-олигарх',
    craft_desc: 'Изготовь предметы: <color=#3AFF5DFF>{0}</color> шт.',
    quest01_name: 'А обед не забыли?',
    quest02_name: 'Изнурительное задание',
    quest03_name: 'Крестовый поход в вечность',
    quest04_name: 'В ад и обратно',
    quest_desc: 'Пройди приключения: <color=#3AFF5DFF>{0}</color>.',
    bigsale01_name: 'Один легкий платеж',
    bigsale02_name: 'Без учета налога',
    bigsale03_name: 'Каприз короля',
    bigsale_desc:
      'Заработай <color=#3AFF5DFF>{0}</color> золотых за одну сделку.',
    champions01_name: 'Предопределенные встречи',
    champions02_name: 'Великие судьбы',
    champions03_name: 'Герои меча и магии',
    heroeshired_desc: 'Найми защитников: <color=#3AFF5DFF>{0}</color>',
    advlevel01_name: 'Ежедневная рутина',
    advlevel02_name: 'На пути к величию',
    advlevel03_name: 'На голову выше остальных',
    advlevel04_name: 'Сливки общества',
    advlevel05_name: 'На вершине мира',
    adventurerlevel_desc:
      'Достигни с героем <color=#3AFF5DFF>{0}</color>-го уровня.',
    workers01_name: 'Владение средствами производства',
    workers02_name: 'Таинственные отрасли',
    workers03_name: 'Полная монополия',
    workershired_desc: 'Найми мастеров: <color=#3AFF5DFF>{0}</color>',
    furniture01_name: 'Эффективно занятая площадь',
    furniture02_name: 'Преодоление предела',
    furniture03_name: 'Достойно короля',
    furniturelevel_desc:
      'Улучши предмет мебели до <color=#3AFF5DFF>{0}</color>-го уровня.',
    energy01_name: 'Еще один довольный клиент',
    energy02_name: 'Сила любви',
    energy03_name: 'Усиленный заряд',
    energy_desc:
      'Потрать за всю свою карьеру торговца <color=#3AFF5DFF>{0}</color> ед. энергии.',
    decoration01_name: 'Художественный штрих',
    decoration02_name: 'Глобальный декор',
    decoration03_name: 'Высший класс',
    decoration04_name: 'Экстравагантная роскошь',
    decorationspurchased_desc:
      'Купи за всю свою карьеру торговца <color=#3AFF5DFF>{0}</color> ед. декора.',
    commonres01_name: 'В основном скопом',
    commonres02_name: 'Спрос на припасы',
    commonres03_name: 'Из местного источника',
    commonres04_name: 'Наполняем корзину',
    commonresources_desc:
      'Потрать за всю свою карьеру торговца <color=#3AFF5DFF>{0}</color> ед. обычных ресурсов.',
    uncommonres01_name: 'Особый ингредиент',
    uncommonres02_name: 'Экзотические товары',
    uncommonres03_name: 'Экономика в условиях дефицита',
    uncommonresources_desc:
      'Потрать за всю свою карьеру торговца <color=#3AFF5DFF>{0}</color> ед. необычных ресурсов.',
    rareres01_name: 'Блестящая коллекция',
    rareres02_name: 'Настоящий клад',
    rareres03_name: 'Чудеса со всего света',
    rareresources_desc:
      'Потрать за всю свою карьеру торговца <color=#3AFF5DFF>{0}</color> ед. редких ресурсов.',
    loot01_name: 'Вещи из подземелий',
    loot02_name: 'Тяжелая добыча',
    loot03_name: 'Щедрый урожай',
    loot04_name: 'Все, что не прибито гвоздями',
    loot_desc:
      'Принеси из приключений <color=#3AFF5DFF>{0}</color> шт. компонентов.',
    boss01_name: 'Охотники за наживой',
    boss02_name: 'Охота на крупную дичь',
    boss03_name: 'Бедствие из легенд',
    bosses_desc: 'Убей босса <color=#3AFF5DFF>{0}</color> р.',
    chest01_name: 'При содействии Аргона',
    chest02_name: 'Про запас!',
    chest03_name: 'Куча трофеев',
    chestsopened_desc: 'Открой сундук <color=#3AFF5DFF>{0}</color> р.',
    blueprint01_name: 'Требуется сборка',
    blueprint02_name: 'Исследования и разработки',
    blueprint03_name: 'Жажда знаний',
    research_desc: 'Исследуй чертеж <color=#3AFF5DFF>{0}</color> р.',
    ascend01_name: 'Усилено',
    ascend02_name: 'Больше нормы',
    ascend03_name: 'До самых звезд',
    ascend_desc: 'Полностью усиль предмет <color=#3AFF5DFF>{0}</color> р.',
    master01_name: 'Практика ведет к совершенству',
    master02_name: 'Природное везение',
    master03_name: 'Бывалый ремесленник',
    master04_name: 'Точные технологии',
    master05_name: 'Величайшая рутина',
    master_desc:
      'Получи все улучшения для чертежа <color=#3AFF5DFF>{0}</color> р.',
    expansion01_name: 'Свободная недвижимость',
    expansion02_name: 'Выполняется расширение',
    expansion03_name: 'Спасибо, Том Нук!',
    expansions_desc:
      'Достигни <color=#3AFF5DFF>{0}</color>-го уровня расширения.',
    damage01_name: 'По больному месту',
    damage02_name: 'Вот так уровень силы!',
    damage03_name: 'Ты уже мертв',
    damage_desc: 'Нанеси одной атакой <color=#3AFF5DFF>{0}</color> ед. урона.',
    jackslot01_name: 'Однорукий бандит',
    jackslot02_name: 'Джекпот!',
    jackslot03_name: 'ДЖЕКПОТ!!!',
    jackslot_desc:
      'Заработай <color=#3AFF5DFF>{0}</color> шт. жетонов на «Колесе Фортуны».',
    help01_name: 'Рука помощи',
    help02_name: 'Команда для подмоги',
    help03_name: 'Товарищи в условиях капитализма',
    help_desc: 'Помоги <color=#3AFF5DFF>{0}</color> членам гильдии.',
    enchant01_name: 'Скрытый потенциал',
    enchant02_name: 'Выпущенный потенциал',
    enchant_desc: 'Заколдуй предметы: <color=#3AFF5DFF>{0}</color> шт.',
    twinenchant01_name: 'Магическая гармония',
    enchanttwin_desc:
      'Заколдуй предмет при помощи <color=#3AFF5DFF>духа</color> и <color=#3AFF5DFF>стихии</color>.',
    legenchant01_name: 'Известный предмет',
    enchantlegendary_desc:
      'Заколдуй <color=#3AFF5DFF>легендарный предмет</color>.',
    typeunlock01_name: 'Все учтено',
    typeunlock_desc: 'Открой <color=#3AFF5DFF>все</color> типы предметов.',
    argon_a_name: 'Гордый паладин',
    argon_a_desc: 'Достигни <color=#3AFF5DFF>{0}</color>-го ранга с Аргоном.',
    sia_a_name: 'Тень короля',
    sia_a_desc: 'Достигни <color=#3AFF5DFF>{0}</color>-го ранга с Сиа.',
    yami_a_name: 'Клинок короля',
    yami_a_desc: 'Достигни <color=#3AFF5DFF>{0}</color>-го ранга с Ями.',
    lilu_a_name: 'Чудесный алхимик',
    lilu_a_desc: 'Достигни <color=#3AFF5DFF>{0}</color>-го ранга с Лилу.',
    rudo_a_name: 'Время на передышку',
    rudo_a_desc: 'Достигни <color=#3AFF5DFF>{0}</color>-го ранга с Рудо.',
    polonia_a_name: 'Бич морей',
    polonia_a_desc:
      'Достигни <color=#3AFF5DFF>{0}</color>-го ранга с Полонией.',
    donovan_a_name: 'Мастер превращений',
    donovan_a_desc:
      'Достигни <color=#3AFF5DFF>{0}</color>-го ранга с Донованом.',
    hemma_a_name: 'Повелительница нежити',
    hemma_a_desc: 'Достигни <color=#3AFF5DFF>{0}</color>-го ранга с Хеммой.',
    ashley_a_name: 'Возрожденный ронин',
    ashley_a_desc: 'Достигни <color=#3AFF5DFF>{0}</color>-го ранга с Эшли.',
    bjorn_a_name: 'Дом, милый дом',
    bjorn_a_desc: 'Достигните <color=#3AFF5DFF>{0}</color>-го ранга с Бьорном.',
    gemsspent_name: 'Эксперт кристаллов',
    gemsspent_desc:
      'Потратьте <color=#3AFF5DFF>{0}</color> кристаллов (на улучшения, декор, вложения, сундуки и пр.)',
    fuseuncommon_name: 'Мусор становится сокровищем',
    fuseuncommon_desc: 'Создайте при синтезе превосходный предмет.',
    fuseflawless_name: 'Безупречно, как всегда',
    fuseflawless_desc: 'Создайте при синтезе безупречный предмет.',
    fuseepic_name: 'Объединив свои силы...',
    fuseepic_desc: 'Создайте при синтезе эпический предмет.',
    fuselegendary_name: 'Рождение легенды',
    fuselegendary_desc: 'Создайте при синтезе легендарный предмет.',
    masterpiece_name: 'Все или ничего!',
    masterpiece_desc:
      'Создайте при синтезе легендарный предмет от 10-го ранга и выше.',
    fullmoon_name: 'При бледном свете луны',
    fullmoon_desc: 'Используйте рецепт полнолуния во время полнолуния.',
    transmuter_name: 'Мастер-утилизатор',
    transmuter_desc: 'Выполните синтез с помощью котла синтеза 100 раз.',
    collection01_name: 'Синдром пустой страницы',
    collection02_name: 'Собиратель газетных вырезок',
    collection03_name: 'Архивариус',
    collection04_name: 'Свободная энциклопедия',
    collection05_name: 'Абсолютный коллекционер',
    collection_desc: 'Наберите {0} коллекционных очков',
    bookentry_name: 'Эффективное декоративное',
    bookentry_desc: 'Заполните страницу в коллекционной книге.',
    sp_collection01_name: 'Соперник Гримара',
    sp_collection_desc: 'Наберите {0} оч. коллекционного счета.',
    sp_pets01_name: 'Повелитель щенков',
    sp_pets_desc: 'Достигните {0}-го уровня с 3 питомцами.',
    sp_vip01_name: 'Королевские преимущества',
    sp_vip_desc: 'Станьте королевским торговцем.',
    sp_mastery01_name: '',
    sp_mastery_desc:
      'Получите все улучшения для <color=#3AFF5DFF>{0}</color>чертежа(-ей).',
    sp_terror01_name: 'Избранные бесстрашные',
    sp_terror_desc: 'Пройдите <color=#3AFF5DFF>Башню ужаса</color>.',
    argon_a_title_m_name: 'Паладин',
    argon_a_title_f_name: 'Паладин',
    argon_a_title_desc: 'Достигните {0}-го ранга защитника с Аргоном.',
    lilu_a_title_m_name: 'Колдун',
    lilu_a_title_f_name: 'Колдунья',
    lilu_a_title_desc: 'Достигните {0}-го ранга защитника с Лилу.',
    sia_a_title_m_name: 'Танцор',
    sia_a_title_f_name: 'Танцор',
    sia_a_title_desc: 'Достигните {0}-го ранга защитника с Сиа.',
    yami_a_title_m_name: 'Ниндзя',
    yami_a_title_f_name: 'Ниндзя',
    yami_a_title_desc: 'Достигните {0}-го ранга защитника с Ями.',
    rudo_a_title_m_name: 'Берсерк',
    rudo_a_title_f_name: 'Берсерк',
    rudo_a_title_desc: 'Достигните {0}-го ранга защитника с Рудо.',
    polonia_a_title_m_name: 'Пират',
    polonia_a_title_f_name: 'Пират',
    polonia_a_title_desc: 'Достигните {0}-го ранга защитника с Полонией.',
    donovan_a_title_m_name: 'Алхимик',
    donovan_a_title_f_name: 'Алхимик',
    donovan_a_title_desc: 'Достигните {0}-го ранга защитника с Донованом.',
    hemma_a_title_m_name: 'Некромант',
    hemma_a_title_f_name: 'Некромантка',
    hemma_a_title_desc: 'Достигните {0}-го ранга защитника с Хеммой.',
    ashley_a_title_m_name: 'Самурай',
    ashley_a_title_f_name: 'Самурай',
    ashley_a_title_desc: 'Достигните {0}-го ранга защитника с Эшли.',
    bjorn_a_title_m_name: 'Охотник',
    bjorn_a_title_f_name: 'Охотница',
    bjorn_a_title_desc: 'Достигните {0}-го ранга защитника с Бьорном.',
    workershired_title_m_name: 'Мэр',
    workershired_title_f_name: 'Мэр',
    workershired_title_desc: 'Наймите {0} мастеров.',
    collection_title_m_name: 'Архивариус',
    collection_title_f_name: 'Архивариус',
    collection_title_desc: 'Наберите {0} оч. по коллекционным книгам.',
    bigsale_title_m_name: 'Одаренный',
    bigsale_title_f_name: 'Одаренная',
    bigsale_title_desc: 'Заработайте {0} золотых за одну продажу.',
    gemsspent_title_m_name: 'Прелесть',
    gemsspent_title_f_name: 'Прелесть',
    gemsspent_title_desc: 'Потратьте {0} кристаллов за всю игру.',
    dollarspent_title_m_name: 'Поддержка',
    dollarspent_title_f_name: 'Поддержка',
    dollarspent_title_desc: 'Потратьте ${0} на игру. Спасибо!',
    gold_title_m_name: 'Миллионер',
    gold_title_f_name: 'Миллионерша',
    gold_title_desc: 'Достигните стоимости в размере {0} золотых.',
    level_title_m_name: 'Торговец',
    level_title_f_name: 'Торговец',
    level_title_desc: 'Достигните {0}-го уровня торгового мастерства.',
    title_title_m_name: 'Знаменитость',
    title_title_f_name: 'Знаменитость',
    title_title_desc: 'Получите титулы или ранги титулов: {0}.',
    craft_goldcity_title_m_name: 'Любитель роскоши',
    craft_goldcity_title_f_name: 'Любительница роскоши',
    craft_goldcity_title_desc:
      'Изготовьте предметы Потерянного города золота стоимостью {0} золотых.',
    bounty_title_m_name: 'Вытягивающий в одиночку',
    bounty_title_f_name: 'Вытягивающая в одиночку',
    bounty_title_desc: 'Заработайте трофеи за поручения: {0}.',
    pet_title_m_name: 'Любитель питомцев',
    pet_title_f_name: 'Любитель питомцев',
    pet_title_desc: 'Повысьте 3 питомцев до {0}-го уровня.',
    customization_title_m_name: 'Модник',
    customization_title_f_name: 'Модник',
    customization_title_desc:
      'Заполучите {0} шт. предметов персонализации лавочника.',
    decoration_title_m_name: 'Декоромант',
    decoration_title_f_name: 'Декоромант',
    decoration_title_desc:
      'Заполучите {0} шт. различных предметов декора, включая обои, полы и темы лавки!',
    talk_s_title_m_name: 'Везучий',
    talk_s_title_f_name: 'Везучий',
    talk_s_title_desc: 'Успешно поболтайте {0} раз(-а) подряд.',
    talk_f_title_m_name: 'Невезучий',
    talk_f_title_f_name: 'Невезучий',
    talk_f_title_desc: 'Безуспешно поболтайте {0} раз(-а) подряд.',
    power_title_m_name: 'Могучий',
    power_title_f_name: 'Могучий',
    power_title_desc:
      'Достигните {0} ед. объединенной силы героев и защитников.',
    market_gold_title_m_name: 'Азартный игрок',
    market_gold_title_f_name: 'Азартный игрок',
    market_gold_title_desc:
      'Получите {0} золотых за одну торговую операцию на рынке.',
    market_gems_title_m_name: 'Дневной трейдер',
    market_gems_title_f_name: 'Дневной трейдер',
    market_gems_title_desc:
      'Получите {0} шт. кристаллов за одну торговую операцию на рынке.',
    sell_food_k_title_m_name: 'Знаток',
    sell_food_k_title_f_name: 'Знаток',
    sell_food_k_title_desc: 'Продайте королю Рейнгольду еду на {0}.',
    login_title_m_name: 'Завсегдатай',
    login_title_f_name: 'Завсегдатай',
    login_title_desc: 'Заходите в игру ежедневно в течение {0} дней.',
    beta_title_m_name: 'Бета-тестер',
    beta_title_f_name: 'Бета-тестер',
    beta_title_desc: 'Для тех, кто был с нами до того, как все стало круто!',
    lny2023_title_m_name: 'Кролик',
    lny2023_title_f_name: 'Кролик',
    lny2023_title_desc:
      'Полностью завершите пропуск на контент «Китайский Новый год».',
    space2023_title_m_name: 'Астронавт',
    space2023_title_f_name: 'Астронавтка',
    space2023_title_desc:
      'Полностью завершите пропуск на контент «Космический путешественник».',
    bjorn2023_title_m_name: 'Исследователь',
    bjorn2023_title_f_name: 'Исследовательница',
    bjorn2023_title_desc: 'Выполните все задания пропуска на контент Бьорна.',
    account_title_m_name: 'Древний',
    account_title_f_name: 'Древний',
    account_title_desc: 'Оставайтесь с нами в течение {0} дней!',
    lcog_title_m_name: 'Копитель',
    lcog_title_f_name: 'Копительница',
    lcog_title_desc:
      'Получите {0} оч. во время одного события «Потерянный город золота».',
    tkc_title_m_name: 'Капризный',
    tkc_title_f_name: 'Капризная',
    tkc_title_desc: 'Получите {0} оч. во время одного события «Каприз короля».',
    di_title_m_name: 'Убийца драконов',
    di_title_f_name: 'Убийца драконов',
    di_title_desc:
      'Получите {0} оч. во время одного события «Вторжение драконов».',
    tot_title_m_name: 'Убийца титанов',
    tot_title_f_name: 'Убийца титанов',
    tot_title_desc: 'Пройдите уровень сложности «{0}» в Башне титанов.',
    vip_title_m_name: 'Королевская особа',
    vip_title_f_name: 'Королевская особа',
    vip_title_desc: 'Побудьте королевским торговцем {0} мес.',
    help_title_m_name: 'Друг',
    help_title_f_name: 'Подруга',
    help_title_desc: 'Помогите членам гильдии {0} раз(-а).',
    ascend_item_ws_title_m_name: 'Мастер меча',
    ascend_item_ws_title_f_name: 'Мастер меча',
    ascend_item_ws_title_desc: 'Усильте мечи {0} раз(-а).',
    ascend_item_wd_title_m_name: 'Мастер кинжала',
    ascend_item_wd_title_f_name: 'Мастер кинжала',
    ascend_item_wd_title_desc: 'Усильте кинжалы {0} раз(-а).',
    ascend_item_wa_title_m_name: 'Мастер топора',
    ascend_item_wa_title_f_name: 'Мастер топора',
    ascend_item_wa_title_desc: 'Усильте топоры {0} раз(-а).',
    ascend_item_wm_title_m_name: 'Мастер булавы',
    ascend_item_wm_title_f_name: 'Мастер булавы',
    ascend_item_wm_title_desc: 'Усильте булавы {0} раз(-а).',
    ascend_item_wp_title_m_name: 'Мастер копья',
    ascend_item_wp_title_f_name: 'Мастер копья',
    ascend_item_wp_title_desc: 'Усильте копья {0} раз(-а).',
    ascend_item_wb_title_m_name: 'Мастер лука',
    ascend_item_wb_title_f_name: 'Мастер лука',
    ascend_item_wb_title_desc: 'Усильте луки {0} раз(-а).',
    ascend_item_wt_title_m_name: 'Мастер посоха',
    ascend_item_wt_title_f_name: 'Мастер посоха',
    ascend_item_wt_title_desc: 'Усильте посохи {0} раз(-а).',
    ascend_item_ww_title_m_name: 'Мастер палочки',
    ascend_item_ww_title_f_name: 'Мастер палочки',
    ascend_item_ww_title_desc: 'Усильте палочки {0} раз(-а).',
    ascend_item_wc_title_m_name: 'Мастер арбалета',
    ascend_item_wc_title_f_name: 'Мастер арбалета',
    ascend_item_wc_title_desc: 'Усильте арбалеты {0} раз(-а).',
    ascend_item_wg_title_m_name: 'Мастер ружья',
    ascend_item_wg_title_f_name: 'Мастер ружья',
    ascend_item_wg_title_desc: 'Усильте ружья {0} раз(-а). ',
    ascend_item_uh_title_m_name: 'Мастер трав',
    ascend_item_uh_title_f_name: 'Мастер трав',
    ascend_item_uh_title_desc: 'Усильте травы {0} раз(-а).',
    ascend_item_up_title_m_name: 'Мастер зелий',
    ascend_item_up_title_f_name: 'Мастер зелий',
    ascend_item_up_title_desc: 'Усильте зелья {0} раз(-а).',
    ascend_item_us_title_m_name: 'Мастер заклинаний',
    ascend_item_us_title_f_name: 'Мастер заклинаний',
    ascend_item_us_title_desc: 'Усильте заклинания {0} раз(-а).',
    ascend_item_ah_title_m_name: 'Мастер тяжелых доспехов',
    ascend_item_ah_title_f_name: 'Мастер тяжелых доспехов',
    ascend_item_ah_title_desc: 'Усильте тяжелые доспехи {0} раз(-а).',
    ascend_item_am_title_m_name: 'Мастер легких доспехов',
    ascend_item_am_title_f_name: 'Мастер легких доспехов',
    ascend_item_am_title_desc: 'Усильте легкие доспехи {0} раз(-а).',
    ascend_item_al_title_m_name: 'Мастер одежды',
    ascend_item_al_title_f_name: 'Мастер одежды',
    ascend_item_al_title_desc: 'Усильте одежду {0} раз(-а).',
    ascend_item_hh_title_m_name: 'Мастер шлемов',
    ascend_item_hh_title_f_name: 'Мастер шлемов',
    ascend_item_hh_title_desc: 'Усильте шлемы {0} раз(-а).',
    ascend_item_hm_title_m_name: 'Мастер шапок странника',
    ascend_item_hm_title_f_name: 'Мастер шапок странника',
    ascend_item_hm_title_desc: 'Усильте шапки странника {0} раз(-а).',
    ascend_item_hl_title_m_name: 'Мастер шляп мага',
    ascend_item_hl_title_f_name: 'Мастер шляп мага',
    ascend_item_hl_title_desc: 'Усильте шляпы мага {0} раз(-а).',
    ascend_item_gh_title_m_name: 'Мастер рукавиц',
    ascend_item_gh_title_f_name: 'Мастер рукавиц',
    ascend_item_gh_title_desc: 'Усильте рукавицы {0} раз(-а).',
    ascend_item_gl_title_m_name: 'Мастер перчаток',
    ascend_item_gl_title_f_name: 'Мастер перчаток',
    ascend_item_gl_title_desc: 'Усильте перчатки {0} раз(-а).',
    ascend_item_bh_title_m_name: 'Мастер сапог',
    ascend_item_bh_title_f_name: 'Мастер сапог',
    ascend_item_bh_title_desc: 'Усильте сапоги {0} раз(-а).',
    ascend_item_bl_title_m_name: 'Мастер ботинок',
    ascend_item_bl_title_f_name: 'Мастер ботинок',
    ascend_item_bl_title_desc: 'Усильте ботинки {0} раз(-а).',
    ascend_item_xs_title_m_name: 'Мастер щита',
    ascend_item_xs_title_f_name: 'Мастер щита',
    ascend_item_xs_title_desc: 'Усильте щиты {0} раз(-а).',
    ascend_item_xc_title_m_name: 'Мастер плаща',
    ascend_item_xc_title_f_name: 'Мастер плаща',
    ascend_item_xc_title_desc: 'Усильте плащи {0} раз(-а).',
    ascend_item_xr_title_m_name: 'Мастер колец',
    ascend_item_xr_title_f_name: 'Мастер колец',
    ascend_item_xr_title_desc: 'Усильте кольца {0} раз(-а).',
    ascend_item_xa_title_m_name: 'Мастер амулетов',
    ascend_item_xa_title_f_name: 'Мастер амулетов',
    ascend_item_xa_title_desc: 'Усильте амулеты {0} раз(-а).',
    ascend_item_stone_title_m_name: 'Мастер камней',
    ascend_item_stone_title_f_name: 'Мастер камней',
    ascend_item_stone_title_desc: 'Усильте камни {0} раз(-а).',
    ascend_item_xf_title_m_name: 'Мастер фамильяров',
    ascend_item_xf_title_f_name: 'Мастер фамильяров',
    ascend_item_xf_title_desc: 'Усильте фамильяров {0} раз(-а).',
    ascend_item_fm_title_m_name: 'Мастер блюд',
    ascend_item_fm_title_f_name: 'Мастер блюд',
    ascend_item_fm_title_desc: 'Усильте блюда {0} раз(-а).',
    ascend_item_fd_title_m_name: 'Мастер десертов',
    ascend_item_fd_title_f_name: 'Мастер десертов',
    ascend_item_fd_title_desc: 'Усильте десерты {0} раз(-а).',
    ascend_item_z_2_title_m_name: 'Мастер стихий',
    ascend_item_z_2_title_f_name: 'Мастер стихий',
    ascend_item_z_2_title_desc: 'Усильте стихии {0} раз(-а).',
    ascend_item_z_3_title_m_name: 'Мастер духов',
    ascend_item_z_3_title_f_name: 'Мастер духов',
    ascend_item_z_3_title_desc: 'Усильте духов {0} раз(-а).',
    dragon_gold_title_m_name: 'Золотой дракон',
    dragon_gold_title_f_name: 'Золотой дракон',
    dragon_gold_title_desc:
      'Для абсолютных победителей события «Вторжение драконов».',
    dragon_silver_title_m_name: 'Серебряный дракон',
    dragon_silver_title_f_name: 'Серебряный дракон',
    dragon_silver_title_desc:
      'Для серебряных призеров события «Вторжение драконов».',
    dragon_bronze_title_m_name: 'Бронзовый дракон',
    dragon_bronze_title_f_name: 'Бронзовый дракон',
    dragon_bronze_title_desc:
      'Для бронзовых призеров события «Вторжение драконов».',
    king_gold_title_m_name: 'Золотой король',
    king_gold_title_f_name: 'Золотая королева',
    king_gold_title_desc: 'Для абсолютных победителей события «Каприз короля».',
    king_silver_title_m_name: 'Серебряный король',
    king_silver_title_f_name: 'Серебряная королева',
    king_silver_title_desc: 'Для серебряных призеров события «Каприз короля».',
    king_bronze_title_m_name: 'Бронзовый король',
    king_bronze_title_f_name: 'Бронзовая королева',
    king_bronze_title_desc: 'Для бронзовых призеров события «Каприз короля».',
    goldcity_gold_title_m_name: 'Золотой голем',
    goldcity_gold_title_f_name: 'Золотой голем',
    goldcity_gold_title_desc:
      'Для абсолютных победителей события «Потерянный город золота».',
    goldcity_silver_title_m_name: 'Серебряный голем',
    goldcity_silver_title_f_name: 'Серебряный голем',
    goldcity_silver_title_desc:
      'Для серебряных призеров события «Потерянный город золота».',
    goldcity_bronze_title_m_name: 'Бронзовый голем',
    goldcity_bronze_title_f_name: 'Бронзовый голем',
    goldcity_bronze_title_desc:
      'Для бронзовых призеров события «Потерянный город золота».',
    share_shop_title: 'Покажите свою лавку!',
    share_shop_text:
      'Используйте социальные сети, чтобы пригласить других игроков посмотреть вашу лавку!',
    view_shop_title: 'Приглашение посетить лавку',
    view_shop_description: 'Откройте ссылку для просмотра лавки игрока {0}',
    view_shop_in_tutorial:
      'Прежде чем смотреть лавку этого игрока, вам нужно завершить обучение.',
    share_profile_title: 'Покажите профиль',
    share_profile_text:
      'Поделитесь этим профилем в социальных сетях, чтобы другие могли его увидеть!',
    view_profile_title: 'Профиль игрока',
    view_profile_description:
      'Откройте ссылку для просмотра профиля игрока {0}',
    view_profile_in_tutorial:
      'Вы не сможете посмотреть профиль этого игрока по данной ссылке, пока не закончите обучение.',
    share_city_title: 'Покажите гильдию',
    share_city_text: 'Приглашайте других в свою гильдию в социальных сетях!',
    view_city_title: 'Вступить в гильдию',
    view_city_description: 'Откройте ссылку для вступления в гильдию «{0}»',
    view_city_confirmation: 'Вы уверены, что хотите вступить в эту гильдию?',
    share_button_options: 'Показать',
    share_popup_share: 'Опубликовать',
    share_popup_title: 'Опции публикации',
    share_popup_location_name_shop: 'Лавка',
    share_popup_location_description_shop:
      'Используйте социальные сети, чтобы пригласить других игроков посмотреть вашу лавку!',
    share_popup_location_name_profile: 'Профиль',
    share_popup_location_description_profile:
      'Поделитесь этим профилем в социальных сетях, чтобы другие могли его увидеть!',
    share_popup_location_name_city: 'Гильдия',
    share_popup_location_description_city:
      'Приглашайте других в свою гильдию в социальных сетях!',
    steam_banner_title: 'Игра Shop Titans выходит в Steam!',
    steam_banner_desc:
      'Скоро выйдет версия для ПК в Steam. Добавьте Shop Titans в список желаемого и дождитесь ранней регистрации в бета-версии!',
    copyright_text:
      'Авторские права за {0} принадлежат Kabam Games, Inc. Все права сохранены.',
    privacy_notice: 'Уведомление о соблюдении конфиденциальности',
    level: 'Уровень',
    level_plural: 'Уровни',
    fetching_data: 'Поиск данных...',
    fetch_data_error:
      'При поиске данных произошла ошибка. Проверьте свое Интернет-соединение.',
    app_download_text: 'Теперь в App Store и Google Play!',
    app_download_text_china: 'Доступно для загрузки!',
    home_description_app:
      'Создайте процветающую лавку в этом приключенческом симуляторе торговли! Снаряжайте персонажей и отправляйте их в приключения, чтобы победить могучих боссов!',
    home_title_app: 'Станьте искусным торговцем',
    home_app_description_01: 'Создайте и персонализируйте сказочную лавку.',
    home_app_description_02:
      'Научитесь изготавливать более 150 различных предметов, закрепив за собой звание эксперта.',
    home_app_description_03:
      'Объединитесь с друзьями в гильдию, чтобы построить преуспевающий город.',
    home_app_description_04: 'Торгуйте с игроками со всего мира.',
    home_app_description_05:
      'Нанимайте и персонализируйте героев, каждый из которых имеет свое снаряжение и навыки.',
    home_app_description_06:
      'Сражайтесь с потрясающими боссами в уникальных подземельях и получайте редчайшую добычу!',
    home_description_slider:
      'Shop Titans — это игра в жанре ролевого симулятора, где вы создаете собственную лавку в сказочном королевстве. Вы можете изготавливать и продавать доспехи, мечи, зелья и разное снаряжение героям, чтобы помочь им в приключениях. На вырученные деньги вы можете расширять и менять свою лавку, чтобы стать важной персоной в области торговли. К вам будут приходить разные герои: воины, чародеи, гномы... даже ниндзя! Помогайте своим героям и защитникам в боях с могучими боссами!',
    home_title_news: 'Свежие новости',
    home_package_desc: 'Покупайте уникальные наборы, пока они в наличии!',
    home_title_social: 'Подпишитесь на нас в соцсетях!',
    home_title_fankit:
      'Загрузите официальный фанатский набор для проектов в Shop Titans!',
    home_fankit_download: 'Загрузить фанатский набор',
    browse_header_title: 'Обзор: {0}',
    browse_title: 'Обзор',
    browse_desc: 'Смотрите подборку контента из Shop Titans.',
    settings_title: 'Настройка игрового прогресса',
    settings_save: 'Сохранить изменения',
    settings_clear: 'Сбросить изменения',
    settings_workers_description:
      'Установите уровни своих мастеров для более точного отображения времени изготовления предметов.',
    settings_items_description:
      'Используйте обычные модификаторы для изменения времени изготовления, стоимости предметов и т.д.',
    settings_localstorage_notice:
      'Данные изменения сохраняются локально только в этом браузере.',
    settings_tab_workers: 'Уровни мастеров',
    settings_tab_items: 'Модификаторы предметов',
    merchant_xp: 'Опыт торговца',
    worker_xp: 'Опыт мастера',
    favor: 'Милость',
    stat_locale_title_package: 'Набор',
    stat_locale_title_event: 'Событие',
    stat_element_detail_none: 'Нет',
    stat_header_item_stats: 'Параметры предмета',
    stat_header_extra_details: 'Дополнительные сведения',
    stat_header_energy_costs: 'Расход энергии',
    footer_header_games: 'Наши игры',
    footer_header_legal: 'Помощь и юридическая информация',
    footer_header_resources: 'Ресурсы',
    footer_link_more_games: 'Другие игры...',
    footer_link_support: 'Связаться с поддержкой',
    footer_link_fankit: 'Фанатский набор игровых средств',
    footer_link_guidelines: 'Правила сообщества',
    footer_link_faq_long: 'Часто задаваемые вопросы',
    results_none: 'Нет результатов',
    offers_date_start_vip: 'Доступно для королевского торговца с {0} по {1}',
    offers_date_start: 'Доступно для всех игроков с {0} по {1}',
    offers_search_title: 'Поиск предложений',
    offers_search_desc_1:
      'Вы можете поискать несколько предложений одновременно, отделив каждый UID запятой.',
    offers_search_desc_2:
      'Можно искать несколько предложений по их UID. Например, набрав «offermay2021», вы увидите все предложения за май 2021 года.',
    offers_search_query: 'UID предложения',
    offers_search_button: 'Поиск',
    offers_warning_banner_desc:
      'Эта страница не оптимизирована для коммерческого использования, поэтому производительность может упасть. Она предназначена только для внутреннего использования, поэтому не выкладывайте в открытый доступ.',
    offers_search_none: 'Предложений не найдено',
    offers_search_none_button: 'Показать все предложения',
    offers_details_button: 'Посмотреть содержимое набора',
    offers_details_cost: 'Стоимость набора',
    footer_link_faq: 'ЧаВо',
    footer_link_spreadsheet: 'Таблица данных Shop Titans',
    footer_download_play: 'Загрузите и играйте!',
    home_call_to_action_trailer: 'Смотреть трейлер',
    home_call_to_action_download: 'Загрузите!',
    home_call_to_action_download_alt_1: 'Загрузите и играйте',
    home_call_to_action_download_alt_2: 'Загрузите бесплатно',
    home_call_to_action_download_alt_3: 'Бесплатная загрузка',
    news_title_latest: 'Последние новости',
    news_title_recent: 'Свежие новости',
    news_title_related: 'Связанные с игрой новости',
    news_title_featured: 'Рекомендованные новости',
    news_button_view_all: 'Посмотреть все',
    news_button_view_all_long: 'Посмотреть все новости',
    news_button_view_article: 'Посмотреть статью',
    news_button_show_more: 'Еще',
    news_category_all: 'Все',
    news_category_updates_title: 'Обновления',
    news_category_updates_desc: 'Статьи, касающиеся обновлений Shop Titans.',
    news_category_guides_title: 'Руководства',
    news_category_guides_desc:
      'Руководства, относящиеся к Shop Titans или соответствующим ресурсам.',
    news_category_community_title: 'Сообщество',
    news_category_community_desc:
      'Статьи, касающиеся сообщества, событий, проектов или ресурсов Shop Titans.',
    news_article_metadata: 'Опубликовано: {0}, {1}',
    blueprint_browser_title: 'Просмотр чертежей',
    blueprint_browser_desc:
      'Узнайте о каждом чертеже в игре, включая все параметры, источники комплектации и приобретения, улучшения и прочее, и все это без необходимости запускать игру!',
    blueprint_browser_button: 'Смотреть все чертежи',
    customization_browser_title: 'Просмотр предметов персонализации',
    customization_browser_desc:
      'Узнайте о каждом предмете персонализации, включая источники комплектации и приобретения, стоимость и многое другое, и все это без необходимости запускать игру!',
    customization_browser_button: 'Посмотреть все предметы персонализации',
    content_language_limited: 'Данных материалов пока нет на этом языке.',
    content_language_limited_english:
      'Данные материалы пока есть только на английском.',
    content_language_limited_partial:
      'Лишь часть данных материалов есть на выбранном вами языке.',
    cookie_data_notice:
      'Мы используем файлы cookie для оптимизации нашего сайта, аналитики и маркетинга. Для получения дополнительной информации о том, как мы используем файлы cookie, ознакомьтесь с нашим Уведомлением о соблюдении конфиденциальности. Продолжая просматривать наш сайт, вы соглашаетесь с тем, что мы используем файлы cookie.',
    cookie_data_notice_affirm: 'Я понимаю',
    content_error_title: 'Произошла ошибка',
    content_error_desc:
      'Возникла проблема с загрузкой запрашиваемых материалов. Проверьте соединение и попробуйте снова.',
    content_not_found_title: 'Материалы не найдены',
    content_not_found_desc:
      'Запрашиваемых материалов не существует, или они не найдены.',
    content_not_found_button: 'Перейти на главную страницу',
    nav_route_events: 'События',
    nav_route_achievements: 'Достижения',
    home_featured_banner_description:
      'Отличный симулятор магазина с элементами ролевой игры.',
    home_featured_content_app_title: 'Shop Titans: дизайн и торговля',
    home_featured_content_app_description:
      'Shop Titans — отличный симулятор магазина с элементами ролевой игры. Изготавливайте мощное снаряжение, наполняйте лавку товарами и продавайте их начинающим героям. Игра бесплатна! Спешите установить на ПК или мобильном устройстве.',
    home_champion_select_title: 'Встречайте защитников',
    footer_download_button_apple: 'Загрузить в App Store',
    footer_download_button_google: 'Загрузить в Google Play',
    footer_download_button_steam: 'Загрузить в Steam',
    footer_button_kabam_logo: 'Посетить официальный сайт Kabam',
    general_button_back: 'Назад',
    general_button_close: 'Закрыть',
    blueprints_button_view_item_details: 'Посмотреть сведения о чертеже',
    stat_header_premium_sources: 'Премиальные источники',
    stat_header_blueprint_stats: 'Параметры чертежа',
    stat_header_blueprint_economy: 'Экономика чертежа',
    contentpass_rewards_orginal: 'Показать начальную награду',
    contentpass_rewards_alt: 'Показать альтернативную награду',
    pagination_page_count: 'Страница {0} из {1}',
    pagination_page_first: 'Первая страница',
    pagination_page_prev: 'Предыдущая страница',
    pagination_page_next: 'Следующая страница',
    pagination_page_last: 'Последняя страница',
    events_starts_in: 'До начала: {0}',
    events_header_live: 'Текущие события',
    events_header_passive: 'Пассивные события',
    events_header_upcoming: 'Предстоящие события',
    avatar_customization: 'Персонализация аватара',
    image_full_size_button: 'Посмотреть полное изображение',
    clipboard_copy_package: 'Скопировать ссылку на набор',
    clipboard_copy_blueprint: 'Скопировать ссылку на чертеж',
    footer_link_creatorprogram: 'Программа для создателей контента',
    changelog_title: 'Журнал изменений',
    footer_download_button_epicgames: 'Загрузить из магазина Epic Games',
    offers_details_cost_original: 'Стоимость набора (начальная)',
    stat_header_power_invasion: 'Сила вторжения',
    stat_header_power_airship: 'Сила воздушного корабля',
    events_time_until: 'До начала этого события:',
    footer_link_issues: 'Известные неполадки',
    stat_header_antique_shop: 'В магазине древностей с',
    item_source_antique_shop_valid: 'Доступно в магазине древностей',
    item_source_antique_shop_invalid: 'Еще не доступно в магазине древностей',
    nav_route_home: 'Главная',
    nav_route_browse: 'Обзор',
    nav_route_support: 'Помощь',
    nav_route_news: 'Новости',
    nav_route_news_desc: 'Последние новости и статьи об игре Shop Titans.',
    nav_route_blueprints: 'Чертежи',
    nav_route_blueprints_desc:
      'Интерактивный список всех чертежей, доступных в игре.',
    nav_route_customizations: 'Персонализация',
    nav_route_customizations_desc:
      'Список сезонных, наборных и других предметов персонализации аватара, доступных в игре.',
    nav_route_avatarcustomizations: 'Персонализация аватара',
    nav_route_avatarcustomizations_desc:
      'Вы можете посмотреть список всех предметов персонализации аватара, доступных в игре.',
    nav_route_furniture: 'Мебель',
    nav_route_furniture_desc:
      'Вы можете посмотреть список всех предметов мебели, доступных в игре.',
    nav_route_decorations: 'Декор',
    nav_route_decorations_desc:
      'Вы можете посмотреть список всех обоев, полов и тем, доступных в игре.',
    nav_route_offers: 'Предложения',
    nav_route_offers_desc: 'Список всех временных сезонных наборов в игре.',
    nav_kabam_othergames_title: 'Другие игры Kabam',
    nav_kabam_button: 'Смотреть игры Kabam',
    locale_selector_title: 'Предпочитаемый язык',
    locale_selector_desc:
      'Выберите язык, на котором вы предпочитаете просматривать приложение. Этот язык будет приоритетным, если он поддерживается.',
    customization_source_giveaway: 'Подарок или приз',
    customization_source_restricted: 'Запрещено',
    nav_button_view_all_page: 'Посмотреть все страницы «{0}»',
    nav_button_back_to_page: 'Назад к страницам «{0}»',
    nav_button_back: 'Назад',
    nav_button_next: 'Далее',
    oauth_login: 'Авторизация',
    oauth_login_msg:
      'Для доступа к этому приложению необходимо авторизоваться.',
    oauth_login_provider_google: 'Авторизоваться через Google',
    oauth_login_provider_discord: 'Авторизоваться через Discord',
    oauth_logout: 'Выйти',
    oauth_logout_msg: 'Вы сейчас в системе.',
    oauth_go_home: 'На главную',
    oauth_retry: 'Повторить',
    oauth_error: 'Произошла ошибка',
    oauth_error_msg_generic: 'У хомяка закончился салат латук.',
    oauth_error_msg_config: 'Возникла проблема с сервером.',
    oauth_error_msg_denied:
      'У вас нет разрешения на доступ к этому приложению.',
    oauth_error_msg_invalid: 'Подлинность учетной записи не проверена.',
    oauth_banner_notice_alpha:
      'Это ранняя сборка; не передавайте ее посторонним. Производительность может быть снижена, а функции могут быть неполными или не работать.',
    oauth_banner_notice_beta:
      'Это ранняя бета-сборка; не передавайте ее посторонним.',
    stat_header_stats_general: 'Общие параметры',
    stat_header_stats_item: 'Параметры предмета',
  },
};
