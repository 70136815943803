import { Button } from 'antd';
import React from 'react';
import { IProfileImport } from 'src/Types/profile';
import { useBlueprints } from 'src/hooks/useBlueprints';
import { useProfile } from 'src/hooks/useProfile';

export const ExportProfileButton: React.FC = () => {
  const [profile] = useProfile();
  const [blueprints] = useBlueprints();

  const onExportClick = () => {
    const exportData: IProfileImport = {
      profile,
      blueprints,
    };
    const exportDataString = JSON.stringify(exportData);

    const element = document.createElement('a');
    element.setAttribute('href', `data:application/json;charset=utf-8,${exportDataString}`);
    element.setAttribute('download', 'profile.json');
    element.style.display = 'none';

    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  return <Button onClick={onExportClick}>Export</Button>;
};
