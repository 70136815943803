import { Table } from 'antd';
import React, { useMemo } from 'react';
import { energyTableColumns } from 'src/Components/EnergyTable/columns';
import { normalBlueprintItems } from 'src/Constants/itemsByBlueprintQuality';
import { FilterByShowcase } from 'src/Types/enums';
import { ITableItem } from 'src/Utills/apiData';
import { useAppSelector } from 'src/hooks/redux';
import { ApiDataStore } from 'src/redux/apiData';
import { EnergyPageStore } from 'src/redux/energyTable';

export const EnergyTable: React.FC = () => {
  const showcaseFilter = useAppSelector(EnergyPageStore.Select.showcaseFilter);
  const minimumEnergy = useAppSelector(EnergyPageStore.Select.minimumEnergy);
  const filters = useAppSelector(EnergyPageStore.Select.filters);

  const filterTypes = useMemo(() => showcaseFilter.flatMap((showcase) => FilterByShowcase[showcase]), [showcaseFilter]);

  const dataSource = useAppSelector(ApiDataStore.Select.data);
  const isLoading = useAppSelector(ApiDataStore.Select.isLoading);

  const filteredData = useMemo(
    () =>
      dataSource
        .filter((item) => filterTypes.includes(item.type))
        .filter((item) => typeof minimumEnergy === 'number' && item.discount >= minimumEnergy)
        .filter((item) => {
          const { onlyNormalBlueprintItems, onlyEmptyRequests, onlyProfitableRequests, onlyProfitableOffers } = filters;

          let result = true;

          if (result && onlyNormalBlueprintItems) result = normalBlueprintItems.includes(item.uid);
          if (result && onlyEmptyRequests) result = item.requests.length === 0;
          if (result && onlyProfitableRequests)
            result = item.requests[0]?.price * 1.1 <= item.value / 2 || item.requests.length === 0;
          if (result && onlyProfitableOffers) result = item.offers[0]?.price <= item.value / 2;

          return result;
        }),
    [dataSource, filterTypes, minimumEnergy, filters],
  );

  return (
    <Table<ITableItem>
      size={'small'}
      rowKey={'uid'}
      dataSource={filteredData}
      loading={isLoading}
      columns={energyTableColumns}
      pagination={{ defaultPageSize: 10 }}
    />
  );
};
