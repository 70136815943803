import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import { ApiDataStore } from 'src/redux/apiData';
import { EnergyPageStore } from 'src/redux/energyTable';
import { ExpPageStore } from 'src/redux/expTable';
import { rootSaga } from 'src/redux/saga';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    apiData: ApiDataStore.Slice.reducer,
    expPage: ExpPageStore.Slice.reducer,
    energyPage: EnergyPageStore.Slice.reducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
