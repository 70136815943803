import { Button, Radio, Table } from 'antd';
import React, { useMemo, useState } from 'react';
import { ItemType, ItemTypeToString } from 'src/Types/enums';
import { IEnrichedItem } from 'src/Types/types';
import { enrichedItems } from 'src/Utills/items';
import { useBlueprints } from 'src/hooks/useBlueprints';

export const Blueprints: React.FC = () => {
  const [blueprints, setBlueprints] = useBlueprints();

  const onSelectAll = () => setBlueprints(enrichedItems.map(({ uid }) => uid));
  const onDeselectAll = () => setBlueprints([]);
  const onSelectItem = (item: IEnrichedItem) => {
    if (blueprints.includes(item.uid)) setBlueprints((state) => state.filter((bp) => bp !== item.uid));
    else setBlueprints((state) => [...state, item.uid]);
  };

  const [selectedType, setSelectedType] = useState<ItemType | '_ALL_'>('_ALL_');

  const filteredData = useMemo(
    () => enrichedItems.filter(({ type }) => (selectedType !== '_ALL_' ? type === selectedType : true)),
    [selectedType],
  );

  return (
    <div className={'max-h-full flex flex-col'}>
      <div className={'mb-2 flex gap-2 justify-end'}>
        <div>
          <Radio.Group value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
            <Radio.Button value={'_ALL_'}>All</Radio.Button>

            {Object.entries(ItemType).map(([key, value]) => (
              <Radio.Button value={value} key={value}>
                {key}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>

        <div className={'flex-none flex flex-col gap-1'}>
          <Button danger onClick={onSelectAll}>
            Select Everything
          </Button>
          <Button danger onClick={onDeselectAll}>
            Deselect Everything
          </Button>
        </div>
      </div>

      <Table
        className={'overflow-auto'}
        dataSource={filteredData}
        rowKey={'uid'}
        rowSelection={{
          type: 'checkbox',
          onSelect: onSelectItem,
          hideSelectAll: true,
          selectedRowKeys: blueprints,
        }}
        pagination={{
          showSizeChanger: true,
          hideOnSinglePage: true,
          total: filteredData.length,
          defaultPageSize: 10,
          pageSizeOptions: [10, 15, 20],
        }}
        columns={[
          {
            title: 'T',
            dataIndex: 'tier',
            key: 'tier',
            width: 80,
            ellipsis: true,
            sorter: (a, b) => a.tier - b.tier,
            filters: [...new Array(13)].map((_, idx) => ({
              text: idx + 1,
              value: idx + 1,
            })),
            onFilter: (value, item) => item.tier === value,
          },
          {
            title: 'Item',
            dataIndex: 'nameEN',
            key: 'nameEN',
            ellipsis: true,
          },
          {
            title: 'Предмет',
            dataIndex: 'nameRU',
            key: 'nameRU',
            ellipsis: true,
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            ellipsis: true,
            render: (type: IEnrichedItem['type']) => ItemTypeToString(type as ItemType),
          },
          {
            title: 'Price',
            key: 'value',
            dataIndex: 'value',
            width: 140,
          },
          {
            title: 'Multiplier',
            key: 'valueMultiplier',
            dataIndex: 'valueMultiplier',
            width: 100,
          },
        ]}
      />
    </div>
  );
};
